import { isId } from '../utils';
import { nameToClassMap } from '../../recipeActions';
import { captureException } from '../../sentry';

export const getRecipeUrlQuery = (actions) => {
  try {
    const encoded = actions.map(a => [
      a.constructor.name,
      ...a.inputs.filter(input => !input.skipArg).map((i) => {
        const pointerId = i.value.toString().substr(1);
        if (isId(pointerId)) {
          const index = actions.findIndex(({ id }) => id === pointerId);
          if (index === -1) {
            console.error('Action pointing to non-existent return value');
            return '';
          }
          return `$${index + 1}`;
        }
        return i.value;
      }),
    ].join(',')).join(';');
    // return `${window.location.origin}${window.location.pathname}?recipe=${btoa(encoded)}`;
    return `?recipe=${encodeURIComponent(btoa(encoded))}`;
  } catch (err) {
    captureException(err);
    return [];
  }
};

export const updateUrlState = (actions) => {
  try {
    window.history.replaceState(null, null, getRecipeUrlQuery(actions));
  } catch (err) {
    console.error(err);
  }
};

export const parseRecipeQuery = async (getState) => {
  try {
    if (document.location.search.substr(0, 8) !== '?recipe=') return [];
    const decodedRecipe = atob(decodeURIComponent(document.location.search.substr(8)));
    const actionArrays = decodedRecipe
      .split(';')
      .map(a => a.split(','));
    const actions = [];
    if (!decodedRecipe) return [];
    for (let i = 0; i < actionArrays.length; i++) {
      const a = actionArrays[i];
      const constructor = nameToClassMap[a[0]];
      const args = a.slice(1)
        .map((arg) => {
          if (new RegExp(/\$\d+/).test(arg)) {
            const pointerIndex = arg.substr(1) - 1;
            return `$${actions[pointerIndex].id}`;
          }
          return arg;
        });
      // eslint-disable-next-line no-await-in-loop
      actions.push(await constructor.prepareAndInstantiate({ getState, args }));
    }
    return actions;
  } catch (err) {
    captureException(err);
    return [];
  }
};

export const encodePremadeRecipeTitle = (title) => title
  .toLowerCase()
  .replace(/ /g, '-')
  .replace(/[^0-9a-z-]/g, '');
