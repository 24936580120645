import React, { useEffect, useState } from 'react';
import Dec from 'decimal.js';
import t from 'translate';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { resetBoostModal } from '../../../../actions/makerActions/makerManageActions/makerManageActions';
import { aaveBoostAction, getBoostModalData } from '../../../../actions/aaveActions/aaveManageActions';
import { setAaveBorrowInterestRateAction } from '../../../../actions/aaveActions/aaveManageActionsV2';

import { AaveBoostSlippageLimitForm as SlippageLimitForm } from '../../BoostRepayCommon/SlippageLimitForm/SlippageLimitForm';
import ModalHeader from '../../ModalHeader';
import ModalBody from '../../ModalBody';
import Loader from '../../../Loader/Loader';
import ErrorBox from '../../../Decorative/ErrorBox/ErrorBox';
import TooltipWrapper from '../../../TooltipWrapper/TooltipWrapper';
import WarningBox from '../../../Decorative/WarningBox/WarningBox';
import TradeSizeImpactWarning from '../../../TradeSizeImpactWarning/TradeSizeImpactWarning';
import InfoIcon from '../../../Decorative/InfoIcon';
import ActionInfo from '../../BoostRepayCommon/ActionInfo';
import AdvancedOptions from '../../BoostRepayCommon/AdvancedOptions/AdvancedOptions';
import AdditionalInfo from '../../../AdditionalInfo/AdditionalInfo';

const AaveBoostModal = ({
  closeModal, gettingBoostModalData, gettingBoostModalDataError, inputAmount, boostAmount, boostExchangeRate, boosting,
  boostAction, tradeSizeImpact, borrowAsset, supplyAsset, boostingError, resetBoostModal, selectedMarket, assetsData,
  activeTab, setAaveBorrowInterestRateAction, getBoostModalData, maxBorrow, useFl, flProtocol, exchangeSource, flFee,
}) => {
  const [advanced, setAdvanced] = useState(false);
  useEffect(() => {
    getBoostModalData(inputAmount, borrowAsset, supplyAsset);
    return resetBoostModal;
  }, []);

  const interestRateOptions = [
    { value: 1, name: 'Stable' },
    { value: 2, name: 'Variable' },
  ];

  useEffect(() => { setAaveBorrowInterestRateAction(interestRateOptions[1]); }, []);

  const offerInterestRateChoice = selectedMarket.value !== 'v1';

  const shouldFlip = new Dec(boostExchangeRate).lt(new Dec(1).div(boostExchangeRate).toString());
  const exchangeLabel = shouldFlip
    ? `${borrowAsset}/${supplyAsset}`
    : `${supplyAsset}/${borrowAsset}`;
  const correctedBoostExchangeRate = shouldFlip
    ? new Dec(1).div(boostExchangeRate).toString()
    : boostExchangeRate;

  const isSameAsset = borrowAsset === supplyAsset || (supplyAsset === 'stETH' && borrowAsset === 'ETH' && +boostExchangeRate <= 1);
  // If user doesn't have max borrow, but has max boost that means the supplying asset is not enabled as collateral
  // which later results in FL error on the contract side
  const isMaxBorrowZero = new Dec(maxBorrow).lte(0);

  const unsupportedStableBorrowAsset = assetsData[borrowAsset].borrowRateStable.toString() === '0' || assetsData[borrowAsset].disabledStableBorrowing;

  return (
    <div className="boost-repay-modal">
      <ModalHeader closeModal={closeModal} />

      <ModalBody>
        <div className="new-modal-top-wrapper">
          <h1>
            <TooltipWrapper title={t('compound.boost_modal_description', { '%asset1': borrowAsset, '%asset2': supplyAsset })}>
              <InfoIcon /> {t('common.boost')}
            </TooltipWrapper>
          </h1>
        </div>

        <div className="new-modal-content-wrapper">

          {gettingBoostModalData && (<div className="container"><div className="loading-wrapper"><Loader /></div></div>)}

          {!gettingBoostModalData && gettingBoostModalDataError && (<ErrorBox>{gettingBoostModalDataError}</ErrorBox>)}

          {!gettingBoostModalData && !gettingBoostModalDataError && (
            <>
              <ActionInfo
                firstAsset={borrowAsset}
                firstAmount={inputAmount}
                secondAsset={supplyAsset}
                secondAmount={boostAmount}
                isSameAsset={isSameAsset}
                exchangeRate={correctedBoostExchangeRate}
                exchangeLabel={exchangeLabel}
              />

              <div className="advanced__wrapper container">
                {!isSameAsset && (
                  <>
                    <TradeSizeImpactWarning tradeSizeImpact={tradeSizeImpact} />
                    {isMaxBorrowZero && (<><br /><WarningBox>{t('errors.boost_no_collateral_enabled')}</WarningBox></>)}
                  </>
                )}
                {offerInterestRateChoice && (
                  <>
                    {unsupportedStableBorrowAsset && (<div><br /><WarningBox>{t('aave.unsupported_stable_borrow_asset')}</WarningBox></div>)}
                    <div className={`Switch ${unsupportedStableBorrowAsset ? 'disabled' : ''}`}>
                      { interestRateOptions.map(option => (
                        <div
                          key={option.value}
                          className={`tab ${activeTab.value === option.value ? 'active' : ''}`}
                          onClick={() => {
                            setAaveBorrowInterestRateAction(option);
                          }}
                        >
                          {option.name}
                        </div>
                      ))}
                    </div>
                  </>
                )}
                {!isSameAsset && (
                  <AdvancedOptions
                    borrowAsset={borrowAsset}
                    supplyAsset={supplyAsset}
                    advanced={advanced}
                    setAdvanced={setAdvanced}
                    exchangeRate={boostExchangeRate}
                    SlippageLimitForm={SlippageLimitForm}
                  />
                )}
                <AdditionalInfo
                  isSameAsset={isSameAsset}
                  flashloanSource={useFl ? flProtocol : ''}
                  flashloanFee={flFee}
                  exchangeSource={exchangeSource}
                  asset1={supplyAsset}
                  asset2={borrowAsset}
                />
                {boostingError && (<ErrorBox marginTop>{boostingError}</ErrorBox>)}
              </div>
            </>
          )}
        </div>
      </ModalBody>

      <div className="modal-controls">
        <button
          form="aave-boost-modal-form"
          type="button"
          disabled={boosting || !Object.keys(activeTab).length || isMaxBorrowZero || gettingBoostModalData}
          onClick={() => boostAction(inputAmount, closeModal)}
          className="button green"
        >
          {boosting ? t('common.boosting') : t('common.boost')}
        </button>
      </div>
    </div>
  );
};

AaveBoostModal.defaultProps = {
  activeTab: {},
};

AaveBoostModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  tradeSizeImpact: PropTypes.string.isRequired,

  gettingBoostModalData: PropTypes.bool.isRequired,
  gettingBoostModalDataError: PropTypes.string.isRequired,

  inputAmount: PropTypes.string.isRequired,
  boostAmount: PropTypes.string.isRequired,
  boostExchangeRate: PropTypes.string.isRequired,

  boostAction: PropTypes.func.isRequired,
  resetBoostModal: PropTypes.func.isRequired,
  boosting: PropTypes.bool.isRequired,

  borrowAsset: PropTypes.string.isRequired,
  supplyAsset: PropTypes.string.isRequired,

  boostingError: PropTypes.string.isRequired,
  selectedMarket: PropTypes.object.isRequired,
  assetsData: PropTypes.object.isRequired,
  setAaveBorrowInterestRateAction: PropTypes.func.isRequired,
  activeTab: PropTypes.object,
  getBoostModalData: PropTypes.func.isRequired,
  maxBorrow: PropTypes.string.isRequired,

  useFl: PropTypes.bool.isRequired,
  flProtocol: PropTypes.string.isRequired,
  flFee: PropTypes.string.isRequired,
  exchangeSource: PropTypes.string.isRequired,
};

const mapStateToProps = ({ aaveManage }) => ({
  tradeSizeImpact: aaveManage.tradeSizeImpact,
  gettingBoostModalData: aaveManage.gettingBoostModalData,
  gettingBoostModalDataError: aaveManage.gettingBoostModalDataError,
  boostAmount: aaveManage.boostAmount,
  boostExchangeRate: aaveManage.boostExchangeRate,
  boosting: aaveManage.boosting,
  boostingError: aaveManage.boostingError,
  borrowAsset: aaveManage.actionsSelectValues.boostBorrow.label,
  supplyAsset: aaveManage.actionsSelectValues.boostSupply.label,
  selectedMarket: aaveManage.selectedMarket,
  assetsData: aaveManage[aaveManage.selectedMarket.value].assetsData,
  activeTab: aaveManage.selectedBorrowInterestRate,
  maxBorrow: aaveManage.maxBorrow,
  useFl: aaveManage.useFl,
  flProtocol: aaveManage.flProtocol,
  flFee: aaveManage.flFee,
  exchangeSource: aaveManage.exchangeSource,
});

const mapDispatchToProps = {
  resetBoostModal,
  getBoostModalData,
  setAaveBorrowInterestRateAction,
  boostAction: aaveBoostAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AaveBoostModal);
