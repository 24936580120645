import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { connect } from 'react-redux';
import {
  SelectOption,
  SelectValueContainer,
} from 'components/Forms/SelectComponent/SelectComponent';
import withErrorFallback from '../../../../../../../ErrorFallback/ErrorFallback';
import { formatNumber } from '../../../../../../../../services/utils';
import TooltipWrapper from '../../../../../../../TooltipWrapper/TooltipWrapper';
import InfoIcon from '../../../../../../../Decorative/InfoIcon';

const AccountActionField = ({
  type, i, actualValue, isPointer, originalValue, handleChange, account, proxyAddress,
  includeProxy, proxyBalance, accountBalance, noCustomInputs,
}) => {
  const accountOptions = useMemo(() => {
    const accounts = [
      {
        value: account,
        label: 'Wallet',
        secondLabel: `${!noCustomInputs ? 'Wallet ' : ''}${accountBalance && `Balance: ${accountBalance}`}`,
      },
    ];
    if (includeProxy) {
      accounts.push({
        value: proxyAddress,
        label: 'Smart Wallet',
        secondLabel: `${!noCustomInputs ? 'Smart Wallet ' : ''}${proxyBalance && `Balance: ${proxyBalance}`}`,
      });
    }

    return accounts;
  }, [account, proxyAddress, proxyBalance, accountBalance, includeProxy]);

  if (noCustomInputs) {
    return (
      <Select
        className="select box"
        classNamePrefix="select"
        value={accountOptions.find(i => i.value === actualValue) || null}
        components={{
          Option: SelectOption,
          ValueContainer: SelectValueContainer,
        }}
        onChange={(e) => handleChange({ target: e }, i)}
        options={accountOptions}
        onBlur={event => event.preventDefault()}
      />
    );
  }

  const userAddress = accountOptions.find(i => i.value === actualValue);
  return (
    <>
      <div className="previous-values-wrapper">
        <TooltipWrapper title="Select your addresses">
          <InfoIcon />
        </TooltipWrapper>
        <Select
          isSearchable={false}
          className="select box previous-values-select"
          classNamePrefix="select"
          value={userAddress || {
            secondLabel: 'Select account',
            value: '0x',
          }}
          components={{
            Option: SelectOption,
            ValueContainer: SelectValueContainer,
          }}
          onChange={(e) => handleChange({ target: e }, i)}
          options={accountOptions}
          onBlur={event => event.preventDefault()}
        />
      </div>
      <input
        className={`form-item ${isPointer ? 'pointing' : ''}`}
        autoComplete="off"
        placeholder="0x"
        id={type.name}
        type="text"
        value={actualValue}
        onChange={(e) => handleChange(e, i)}
      />
      <span className="label-replacement" />
    </>
  );
};

AccountActionField.defaultProps = {
  actualValue: '',
  isPointer: false,
  proxyAddress: '',
  accountBalance: '',
  proxyBalance: '',
  includeProxy: false,
  noCustomInputs: false,
};

AccountActionField.propTypes = {
  handleChange: PropTypes.func.isRequired,
  type: PropTypes.object.isRequired,
  i: PropTypes.number.isRequired,
  originalValue: PropTypes.string.isRequired,
  account: PropTypes.string.isRequired,
  proxyAddress: PropTypes.string,
  isPointer: PropTypes.bool,
  actualValue: PropTypes.string,
  accountBalance: PropTypes.string,
  proxyBalance: PropTypes.string,
  includeProxy: PropTypes.bool,
  noCustomInputs: PropTypes.bool,
};

const mapStateToProps = ({ general, maker }) => ({
  account: general.account,
  proxyAddress: maker.proxyAddress,
});

const mapDispatchToProps = {};

export default withErrorFallback(connect(mapStateToProps, mapDispatchToProps)(AccountActionField));
