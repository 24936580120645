import React, { useEffect, useState } from 'react';
import t from 'translate';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { formValueSelector, change } from 'redux-form';
import Dec from 'decimal.js';
import AnimateHeight from 'react-animate-height';
import { closeConfirmationDialog } from '../../../actions/txNotificationActions';

import { protocolIcons } from '../../../constants/general';
import { weiToEth } from '../../../services/ethService';
import { get1559GasPrice } from '../../../services/apiService';
import { useInterval } from '../../../services/hooks';

import Loader from '../../Loader/Loader';
import TooltipWrapper from '../../TooltipWrapper/TooltipWrapper';
import WarningBox from '../../Decorative/WarningBox/WarningBox';
import ErrorBox from '../../Decorative/ErrorBox/ErrorBox';
import ArrowCaret from '../../Decorative/ArrowCaret';
import GasPriceValues from './GasPriceValues';
import GasPriceEIP1599ModalForm, { GasPriceEIP1599FormName } from './GasPriceEIP1599ModalForm/GasPriceEIP1599ModalForm';

import '../TxConfirm/TxConfirm.scss';

const BASE_FEE_THRESHOLD = 100;
const NETWORK_CONGESTED_THRESHOLD = 150;
const DEFAULT_TIP = '1.5';

const TxConfirmEIP1559 = ({
  txData, ethBalance, ethPrice, maxFeeForm, priorityFeeForm, gasLimitForm, closeConfirmationDialog, change,
}) => {
  const dispatch = useDispatch();
  const [advanced, setAdvanced] = useState(false);
  const [gettingBaseFee, setGettingBaseFee] = useState(true);
  const [gettingBaseFeeErr, setGettingBaseFeeErr] = useState('');
  const [formWasChanged, setFormWasChanged] = useState(false);
  const [maxFee, setMaxFee] = useState('');
  const [priorityFee, setPriorityFee] = useState(DEFAULT_TIP);

  const baseFeeGetter = async () => {
    let baseFeePerGas;
    try {
      const blockData = await window._web3.eth.getBlock('latest');
      baseFeePerGas = new Dec(weiToEth(blockData.baseFeePerGas, 'gwei')).toFixed(2).toString();

      setGettingBaseFeeErr('');
      if (formWasChanged) return baseFeePerGas;

      const _maxFee = new Dec(baseFeePerGas).times(2).toString();
      setMaxFee(_maxFee);
      dispatch(change(GasPriceEIP1599FormName, 'maxFee', _maxFee));
    } catch (err) { setGettingBaseFeeErr(err.message); }
    if (formWasChanged) return baseFeePerGas;
    try {
      if (baseFeePerGas && new Dec(baseFeePerGas).gte(BASE_FEE_THRESHOLD)) {
        const blockNativeEst = await get1559GasPrice();
        const tip = blockNativeEst.estimatedPrices[0].maxPriorityFeePerGas.toString();
        setPriorityFee(tip);
        dispatch(change(GasPriceEIP1599FormName, 'priorityFee', tip));
      } else {
        setPriorityFee(DEFAULT_TIP);
        dispatch(change(GasPriceEIP1599FormName, 'priorityFee', DEFAULT_TIP));
      }
    } catch (err) { setPriorityFee(DEFAULT_TIP); }
    setGettingBaseFee(false);
    return baseFeePerGas;
  };

  const [baseFee, clearBlockDataInterval] = useInterval(baseFeeGetter, 10000);

  const {
    realGas, title, resolve, id, protocol, isType2,
  } = txData;

  useEffect(() => () => clearBlockDataInterval(), []);

  useEffect(() => { if (!gettingBaseFee && !formWasChanged) baseFeeGetter(); }, [formWasChanged]);

  useEffect(() => { if (gettingBaseFeeErr) setAdvanced(true); }, [gettingBaseFeeErr]);

  const txPrice = new Dec(maxFeeForm || 0).mul(gasLimitForm || 0).mul(10 ** -9).toString();
  const txPriceUsd = new Dec(txPrice).mul(ethPrice).toString();

  const realPrice = new Dec(baseFee || 0).mul(realGas).mul(10 ** -9).toString();
  const realPriceUsd = new Dec(realPrice).mul(ethPrice).toString();

  const insufficientBalance = new Dec(txPrice).gt(ethBalance);
  const lowGasPrice = new Dec(maxFeeForm || 0).lt(baseFee || 0);

  const showAdvanced = advanced || gettingBaseFeeErr || !maxFeeForm;

  const isSubmitDisabled =
    gettingBaseFee || !priorityFeeForm || !maxFeeForm || !gasLimitForm || insufficientBalance
    || new Dec(maxFeeForm || 0).lte(0) || new Dec(priorityFeeForm || 0).lte(0);

  return (
    <div className="tx-confirm-wrapper eip1559">
      <div className="content-wrapper">
        <div className="header">
          {protocol && protocolIcons[protocol] && <img src={protocolIcons[protocol]} alt="" />}
          <div className="title">{title}</div>
        </div>

        <div className="tx-content">
          {gettingBaseFee && <div className="loader-wrapper"><Loader /></div>}

          {!gettingBaseFee && gettingBaseFeeErr && (
            <ErrorBox>
              {gettingBaseFeeErr}<br />
              Please check <a href="https://www.blocknative.com/gas-estimator" target="_blank" rel="noopener noreferrer">Blocknative</a> for gas estimation.
            </ErrorBox>
          )}

          {!gettingBaseFee && (
            <>
              {!!+baseFee && <GasPriceValues symbol="Gwei" title={t('misc.base_fee')} tooltip={t('misc.base_fee_desc')} value={baseFee} />}

              <div className="advanced-wrapper">
                <div className="title-wrapper" onClick={() => setAdvanced(ps => !ps)}>
                  <div className="title-advanced">{ t('misc.customize_gas') }</div>
                  <ArrowCaret to={showAdvanced ? 'up' : 'down'} />
                </div>

                <AnimateHeight duration={300} height={showAdvanced ? 'auto' : 0}>
                  <div className="advanced-content">
                    <GasPriceEIP1599ModalForm
                      onSubmit={(formData) => {
                        resolve({ ...formData, isType2 });
                        closeConfirmationDialog(id);
                      }}
                      maxFee={maxFee}
                      priorityFee={priorityFee}
                      gasLimit={txData.gas.toString()}
                      setFormWasChanged={setFormWasChanged}
                    />

                    {formWasChanged && <span className="restart-form-update" onClick={() => setFormWasChanged(false)}>Reset changes</span>}

                    {!!+realGas && <GasPriceValues title={t('misc.real_gas_estimate')} tooltip={t('misc.real_gas_estimate_desc')} value={realGas.toString()} />}
                  </div>
                </AnimateHeight>
              </div>

              <div className="under-tabs-wrapper">
                {!!+realPrice && new Dec(maxFeeForm || 0).gt(baseFee) && <GasPriceValues symbol="ETH" title={t('misc.real_gas_fee')} tooltip={t('misc.real_gas_fee_desc')} value={realPrice} usdValue={realPriceUsd} />}
                {!!+txPrice && <GasPriceValues symbol="ETH" title={t('misc.max_tx_fee')} tooltip={t('misc.max_tx_fee_desc')} value={txPrice} usdValue={txPriceUsd} />}

                {txData.failing && (<ErrorBox>{ t('misc.tx_failing_notification') }</ErrorBox>)}
                {lowGasPrice && (<WarningBox>{ t('misc.low_max_fee_warning') }</WarningBox>)}

                {!gettingBaseFee && !gettingBaseFeeErr && new Dec(baseFee || 0).gte(NETWORK_CONGESTED_THRESHOLD) && (
                  <WarningBox>Network seems to be congested currently. We suggest sending transaction later if possible.</WarningBox>
                )}
              </div>
            </>
          )}

        </div>
      </div>

      <div className="bottom-controls">
        <button
          onClick={() => {
            resolve(false);
            closeConfirmationDialog(id);
          }}
          type="button"
          className="button"
        >
          { t('common.cancel') }
        </button>
        <TooltipWrapper copy title={t('common.insufficient_balance')} disabled={!insufficientBalance}>
          <button
            type="submit"
            disabled={isSubmitDisabled}
            className="button green"
            form="gas-price-1559-modal-form"
          >
            { t('common.accept') }
          </button>
        </TooltipWrapper>
      </div>
    </div>
  );
};

TxConfirmEIP1559.defaultProps = {
  gasLimitForm: 0,
};

TxConfirmEIP1559.propTypes = {
  txData: PropTypes.object.isRequired,
  ethBalance: PropTypes.string.isRequired,
  ethPrice: PropTypes.string.isRequired,
  gasLimitForm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxFeeForm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  priorityFeeForm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  closeConfirmationDialog: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
};

const selector = formValueSelector(GasPriceEIP1599FormName);

const mapStateToProps = state => ({
  ethBalance: state.assets.ETH.balance,
  ethPrice: state.assets.ETH.marketPrice,
  gasLimitForm: selector(state, 'gasLimit'),
  maxFeeForm: selector(state, 'maxFee'),
  priorityFeeForm: selector(state, 'priorityFee'),
});

const mapDispatchToProps = { closeConfirmationDialog, change };

export default connect(mapStateToProps, mapDispatchToProps)(TxConfirmEIP1559);
