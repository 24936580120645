import { ZERO_ADDRESS } from '../constants/general';

/**
 * Exchange wrappers to be used from recipes
 */
export const KYBER_WRAPPER = '0x2BBaeB65E185eB3BF21FC17BA62Bf628B9c11471';
export const UNISWAP_WRAPPER = '0x6cb48F0525997c2C1594c89e0Ca74716C99E3d54';
export const SUSHISWAP_WRAPPER = '0x50E08820982a88bd28Fc3BBb569971028Db4e091';
export const UNISWAPV3_WRAPPER = {
  1: '0xA250D449e8246B0be1ecF66E21bB98678448DEF5',
  10: '0xc6F57b45c20aE92174b8B7F86Bb51A1c8e4AD357',
  42161: '0x48eF488054b5c570cF3A2AC0A0697B0b0D34c431',
};
export const ZEROX_WRAPPER = {
  1: '0x8C82d963eb282cB7A751E551E3997ba66c2C8237',
  10: '0xa34452B833fCEfA71E1555d1f654e54eBFFD6022',
};
export const SCP_WRAPPER = '0xE5043F268Bd5508959Ba55123D74553124D9E4D5';
export const CURVE_WRAPPER = '0x3788B4Db5e99fF555e22a08241EB3cFc3a0ac149';

export const standardExchangeWrappers = {
  [KYBER_WRAPPER]: '0x71C8dc1d6315a48850E88530d18d3a97505d2065',
  [UNISWAP_WRAPPER]: '0x6403BD92589F825FfeF6b62177FCe9149947cb9f',
  [SUSHISWAP_WRAPPER]: '0xB038B11eBf62cC0991600F4D09fde3dc22B71c21',
  [UNISWAPV3_WRAPPER[1]]: '',
  [ZEROX_WRAPPER[1]]: '0x0c4e16899f2059F4e41ddB164317414a5c0d2988',
  [SCP_WRAPPER]: '0x5f3e654EdeeDF8F236b8a8BC4B459Ae4fED7f44d',
  [ZERO_ADDRESS]: ZERO_ADDRESS,
};
