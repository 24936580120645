import t from 'translate';
import Dec from 'decimal.js';
import {
  assetAmountInEth,
  assetAmountInWei,
  getAssetInfo,
  compoundAsset,
  getAssetInfoByAddress,
} from '@defisaver/tokens';
import dfs from '@defisaver/sdk';
import { compoundCollateralAssets } from 'constants/assets';

import {
  BLOCKS_IN_A_YEAR, ZERO_ADDRESS, MAXUINT, CONTINUOUS_FEE_MULTIPLIER,
} from '../../constants/general';
import {
  compAddress, CompBalanceContract, CompoundLoanInfoContract, comptrollerContract, getCompoundAssetContract, repAddress,
} from '../contractRegistryService';

import {
  isLeveragedPos,
  calculateBorrowingAssetLimit,
  calculateNetApy,
  getAssetsTotal,
  aprToApy,
  calcLeverageLiqPrice,
} from '../moneymarketCommonService';
import callTx from '../txService';
import { getAssetBalance } from '../assetsService';
import { getExchangeOrder, getEmptyExchangeOrder } from '../exchangeServiceV3';
import {
  compareAddresses, fetchWithTimeout, isWalletTypeProxy, requireAddress, numberWithCommas,
} from '../utils';
import { callViaProxy } from '../contractCallService';
import { isSubscribedToMonitor } from './compoundSaverService';
import { trackEvent } from '../analyticsService';

export const handleWbtcLegacy = (asset) => (asset === 'WBTC Legacy' ? 'WBTC' : asset);

const COMP_DISABLED_BORROW_ASSETS = [repAddress];
const COMP_DISABLED_COLL_ASSETS = [repAddress];

export const compoundManageTrackEventWrapper = (getState, from, method, message = '') => {
  trackEvent(from, method, message);
};

/**
 * @param usedAssets
 * @param assetsData
 * @param assets
 * @return {{liquidationLimitUsd: *, suppliedCollateralUsd: *, borrowedUsd: *, suppliedUsd: *, borrowLimitUsd: *, leftToBorrowUsd: *, ratio: *, collRatio: *, netApy: *}}
 */
export const getAggregatedPositionData = (usedAssets, assetsData, assets) => {
  const payload = {
    suppliedUsd: getAssetsTotal(usedAssets, ({ isSupplied }) => isSupplied, ({ suppliedUsd }) => suppliedUsd),
    suppliedCollateralUsd: getAssetsTotal(usedAssets, ({ isSupplied, collateral }) => isSupplied && collateral, ({ suppliedUsd }) => suppliedUsd),
    borrowedUsd: getAssetsTotal(usedAssets, ({ isBorrowed }) => isBorrowed, ({ borrowedUsd }) => borrowedUsd),
    borrowLimitUsd: getAssetsTotal(usedAssets, ({ isSupplied, collateral }) => isSupplied && collateral, ({ symbol, suppliedUsd }) => new Dec(suppliedUsd).mul(assetsData[symbol].collateralFactor)),
  };
  payload.liquidationLimitUsd = payload.borrowLimitUsd;
  payload.leftToBorrowUsd = new Dec(payload.borrowLimitUsd).sub(payload.borrowedUsd).toString();
  payload.ratio = payload.suppliedUsd
    ? new Dec(payload.borrowLimitUsd).div(payload.borrowedUsd).mul(100).toString()
    : '0';
  payload.collRatio = payload.suppliedUsd
    ? new Dec(payload.suppliedCollateralUsd).div(payload.borrowedUsd).mul(100).toString()
    : '0';
  const { netApy, incentiveUsd, totalInterestUsd } = calculateNetApy(usedAssets, assetsData);
  payload.netApy = netApy;
  payload.incentiveUsd = incentiveUsd;
  payload.totalInterestUsd = totalInterestUsd;
  payload.liqRatio = new Dec(payload.borrowLimitUsd).div(payload.liquidationLimitUsd).toString();
  payload.liqPercent = new Dec(payload.borrowLimitUsd).div(payload.liquidationLimitUsd).mul(100).toString();
  const { leveragedType, leveragedAsset } = isLeveragedPos(usedAssets);
  payload.leveragedType = leveragedType;
  if (leveragedType !== '') {
    payload.leveragedAsset = leveragedAsset;
    const assetPrice = assets[handleWbtcLegacy(leveragedAsset)].compoundPrice;
    payload.liquidationPrice = calcLeverageLiqPrice(leveragedType, assetPrice, payload.borrowedUsd, payload.liquidationLimitUsd);
  }
  return payload;
};
/**
 * Checks if the user is supplying or borrowing this asset
 *
 * @param asset {String}
 * @param account {String}
 */
export const checkAsset = async (asset, account) => {
  const assetContract = await getCompoundAssetContract(getAssetInfo(compoundAsset(asset)));

  const supplyBalance = await assetContract.methods.balanceOf(account).call();
  const borrowBalance = await assetContract.methods.borrowBalanceCurrent(account).call();

  if (supplyBalance.toString() !== '0') return 'supply';
  if (borrowBalance.toString() !== '0') return 'borrow';

  return 'none';
};

/**
 * Gets the total amount of supplied asset to Compound
 *
 * @param account {String}
 * @param asset {String}
 * @return {Promise<String>}
 */
export const getSupplied = async (account, asset) => {
  const assetContract = await getCompoundAssetContract(getAssetInfo(compoundAsset(asset)));
  const balanceUnderlying = (await assetContract.methods.balanceOfUnderlying(account).call()).toString();

  return assetAmountInEth(balanceUnderlying, asset);
};

/**
 * Fetches the supply rate
 *
 * @param asset {String}
 * @return {Promise<*>}
 */
export const getSupplyRate = async (asset) => {
  const assetContract = await getCompoundAssetContract(getAssetInfo(compoundAsset(asset)));

  const supplyRatePB = Dec((await assetContract.methods.supplyRatePerBlock().call()).toString()).div(1e18).toString();
  return Dec(BLOCKS_IN_A_YEAR).times(supplyRatePB).times(100).toString();
};


/**
 * Returns an array of asset adresses that are enabled as collateral
 *
 * @param account {String}
 * @return {Promise<array<string>>}
 */
export const getCollateralAssetsAddresses = async (account) => {
  const contract = await comptrollerContract();

  return contract.methods.getAssetsIn(account).call();
};

/**
 * Enables an asset to be used as borrowing collateral
 *
 * @param accountType {String}
 * @param path {String}
 * @param sendTxFunc {Function}
 * @param account {String}
 * @param proxyAddress {String} user's DSProxy
 * @param walletType {Object}
 * @param assetAddress {String}
 * @return {Promise<unknown>}
 */
export const enableAssetAsCollateral = async (accountType, path, sendTxFunc, account, proxyAddress, walletType, assetAddress) => {
  const contract = await comptrollerContract();

  return !isWalletTypeProxy(walletType)
    ? callTx(accountType, path, sendTxFunc, contract, 'enterMarkets', [[assetAddress]], { from: account })
    : callViaProxy(accountType, sendTxFunc, proxyAddress, account, 'CompoundBasicProxy', 'enterMarket', [assetAddress], 0);
};

/**
 * Disables an asset to be used as borrowing collateral
 *
 * @param accountType {String}
 * @param path {String}
 * @param sendTxFunc {Function}
 * @param account {String}
 * @param proxyAddress {String} user's DSProxy
 * @param walletType {Object}
 * @param assetAddress {String}
 * @return {Promise<unknown>}
 */
export const disableAssetAsCollateral = async (accountType, path, sendTxFunc, account, proxyAddress, walletType, assetAddress) => {
  const contract = await comptrollerContract();

  return !isWalletTypeProxy(walletType)
    ? callTx(accountType, path, sendTxFunc, contract, 'exitMarket', [assetAddress], { from: account })
    : callViaProxy(accountType, sendTxFunc, proxyAddress, account, 'CompoundBasicProxy', 'exitMarket', [assetAddress], 0);
};

/**
 * Calculates the max amount of an asset that the user can withdaw from compound
 * Has 1% buffer if user is borrowing funds
 *
 * @param usedAssetData {Object}
 * @param assetPrice {String} in USD
 * @param assetData {Object}
 * @param borrowedUsd {String}
 * @param borrowLimitUsd {String}
 * @param ratio {Number}
 * @return {String}
 */
export const getMaxWithdraw = (usedAssetData, assetPrice, assetData, borrowedUsd, borrowLimitUsd, ratio = 1.01) => {
  if (!usedAssetData) return '0';
  const {
    supplied, collateral,
  } = usedAssetData;
  const { collateralFactor } = assetData;

  if (supplied.toString() === '0') return '0';
  if (!collateral) return supplied;
  if (borrowedUsd === '0') return supplied;

  const _borrowedUsd = new Dec(borrowedUsd).mul(ratio);

  let leftToBorrowBeforeLiqUsd = new Dec(borrowLimitUsd).sub(_borrowedUsd);
  if (leftToBorrowBeforeLiqUsd.lt(0)) leftToBorrowBeforeLiqUsd = new Dec(0);
  const withdrawableBeforeLiq = new Dec(leftToBorrowBeforeLiqUsd).div(collateralFactor).div(assetPrice);

  if (withdrawableBeforeLiq.greaterThan(supplied)) return supplied;
  return withdrawableBeforeLiq.toDP(getAssetInfo(assetData.symbol).decimals).toString();
};

/**
 * Calculates the max amount of an asset that the user can borrow from compound
 * Has optional buffer
 *
 * @param account {String}
 * @param asset {String}
 * @param assetPrice {String}
 * @param bufferPercent {Number}
 * @param leftToBorrowUsd {String} Left to borrow for user's position
 * @param leftToBorrowGlobally {String | undefined} Left to borrow up to the global borrow cap for the asset
 * @return {string}
 */
export const getMaxBorrow = (account, asset, assetPrice = '0', bufferPercent = 0, leftToBorrowUsd, leftToBorrowGlobally) => {
  // TODO remove redundant args => account
  const borrowingPowerInToken = new Dec(leftToBorrowUsd).div(assetPrice).toString();

  const maxBorrow = new Dec(borrowingPowerInToken).mul((100 - bufferPercent) / 100)
    .toDP(getAssetInfo(asset).decimals).toString();

  if (leftToBorrowGlobally) {
    if (new Dec(leftToBorrowGlobally).lessThanOrEqualTo('0') || new Dec(maxBorrow).lessThanOrEqualTo('0')) return '0';
    return Dec.min(leftToBorrowGlobally, maxBorrow).toString();
  }

  return maxBorrow;
};

/**
 * Calls the supply action on the compound contract
 *
 * @param accountType {String}
 * @param path {String}
 * @param sendTxFunc {Function}
 * @param account {String}
 * @param proxyAddress {String} user's DSProxy
 * @param walletType {Object}
 * @param _amount {String}
 * @param asset {String}
 * @param alreadyInMarket {boolean}
 * @return {Promise<any>}
 */
export const supply = async (
  accountType, path, sendTxFunc, account, proxyAddress, walletType, _amount, asset, alreadyInMarket,
) => {
  const cAssetContract = await getCompoundAssetContract(getAssetInfo(compoundAsset(asset)));
  const assetContract = getAssetInfo(asset).address;

  let txParams = {};
  let funcParams = [];

  const value = assetAmountInWei(_amount, asset);

  if (asset !== 'ETH') {
    txParams = { from: account, value: 0 };
    funcParams = [value];
  } else {
    txParams = { from: account, value };
  }

  return !isWalletTypeProxy(walletType)
    ? callTx(accountType, path, sendTxFunc, cAssetContract, 'mint', funcParams, txParams, 'mint', 400000)
    : callViaProxy(accountType, sendTxFunc, proxyAddress, account, 'CompoundBasicProxy', 'deposit', [assetContract, cAssetContract.options.address, value, alreadyInMarket], txParams.value, 400000);
};

/**
 * Calls the redeemUnderlying action on the compound contract
 *
 * @param accountType {String}
 * @param path {String}
 * @param sendTxFunc {Function}
 * @param account {String}
 * @param proxyAddress {String} user's DSProxy
 * @param walletType {Object}
 * @param _amount {String}
 * @param asset {String}
 * @param supplied {String}
 * @return {Promise<any>}
 */
export const withdraw = async (
  accountType, path, sendTxFunc, account, proxyAddress, walletType, _amount, asset, supplied,
) => {
  const cAssetContract = await getCompoundAssetContract(getAssetInfo(compoundAsset(asset)));
  const assetContract = getAssetInfo(asset).address;
  const wholeSupply = Dec(_amount.toString()).equals(supplied);
  const walletAddr = isWalletTypeProxy(walletType) ? proxyAddress : account;

  let methodName = 'redeemUnderlying';

  let value = assetAmountInWei(_amount, asset);

  let isCTokens = false;

  if (wholeSupply) {
    value = assetAmountInWei(await getAssetBalance(`c${asset}`, walletAddr), `c${asset}`);
    methodName = 'redeem';
    isCTokens = true;
  }

  return !isWalletTypeProxy(walletType)
    ? callTx(accountType, path, sendTxFunc, cAssetContract, methodName, [value], { from: account }, '', 0, 50000)
    : callViaProxy(accountType, sendTxFunc, proxyAddress, account, 'CompoundBasicProxy', 'withdraw', [assetContract, cAssetContract.options.address, value, isCTokens], 0, 0, 50000);
};

/**
 * Calls the borrow action on the compound contract
 *
 * @param accountType {String}
 * @param path {String}
 * @param sendTxFunc {Function}
 * @param account {String}
 * @param proxyAddress {String} user's DSProxy
 * @param walletType {Object}
 * @param _amount {String}
 * @param asset {String}
 * @param alreadyInMarket {boolean}
 * @return {Promise<any>}
 */
export const borrow = async (
  accountType, path, sendTxFunc, account, proxyAddress, walletType, _amount, asset, alreadyInMarket,
) => {
  const address = getAssetInfo(compoundAsset(asset)).address;
  const amountWei = assetAmountInWei(_amount, asset);
  const loanInfo = (await CompoundLoanInfoContract().methods.getFullTokensInfo([address]).call())[0];
  const borrowCap = loanInfo.borrowCap.toString();
  const totalBorrow = loanInfo.totalBorrow.toString();
  const liquidity = loanInfo.marketLiquidity.toString();
  const liqAfterBorrow = new Dec(liquidity).sub(amountWei);

  if (liqAfterBorrow.lt('0')) {
    throw new Error(`Not enough liquidity. The maximum amount available for borrowing is ${numberWithCommas(Dec.floor(liquidity).toString())} ${asset}`);
  }
  if (Number(borrowCap)) {
    const leftToBorrow = new Dec(borrowCap).minus(totalBorrow).toString();
    if (new Dec(leftToBorrow).lt(amountWei)) {
      throw new Error(`Borrow cap limit exceeded. The maximum amount available for borrowing is ${numberWithCommas(Dec.floor(leftToBorrow).toString())} ${asset}`);
    }
  }

  const cAssetContract = await getCompoundAssetContract(getAssetInfo(compoundAsset(asset)));
  const assetContract = getAssetInfo(asset).address;

  return !isWalletTypeProxy(walletType)
    ? callTx(accountType, path, sendTxFunc, cAssetContract, 'borrow', [amountWei], { from: account })
    : callViaProxy(accountType, sendTxFunc, proxyAddress, account, 'CompoundBasicProxy', 'borrow', [assetContract, cAssetContract.options.address, amountWei, alreadyInMarket], 0);
};

/**
 * Calls the repay action on the compound contract
 *
 * @param accountType {String}
 * @param path {String}
 * @param sendTxFunc {Function}
 * @param account {String}
 * @param proxyAddress {String} user's DSProxy
 * @param walletType {Object}
 * @param _amount {String}
 * @param asset {String}
 * @param borrowed {String}
 * @return {Promise<any>}
 */
export const payback = async (
  accountType, path, sendTxFunc, account, proxyAddress, walletType, _amount, asset, borrowed,
) => {
  const cAssetContract = await getCompoundAssetContract(getAssetInfo(compoundAsset(asset)));
  const assetContract = getAssetInfo(asset).address;

  let txParams = {};
  let funcParams = [];

  const balance = await getAssetBalance(asset, account);
  const wholeDebt = Dec(_amount).equals(borrowed) && Dec(balance).gt(borrowed);

  let value;
  let amount;

  if (wholeDebt) {
    // uint(-1)
    value = assetAmountInWei(Dec(_amount).mul(CONTINUOUS_FEE_MULTIPLIER).toString(), asset);
    amount = MAXUINT;
  } else {
    value = assetAmountInWei(_amount, asset);
    amount = assetAmountInWei(_amount, asset);
  }

  if (asset !== 'ETH') {
    txParams = { from: account };
    funcParams = [amount];
  } else {
    txParams = { from: account, value };
  }

  return !isWalletTypeProxy(walletType)
    ? callTx(accountType, path, sendTxFunc, cAssetContract, 'repayBorrow', funcParams, txParams)
    : callViaProxy(accountType, sendTxFunc, proxyAddress, account, 'CompoundBasicProxy', 'payback', [assetContract, cAssetContract.options.address, amount, wholeDebt], txParams.value);
};

/**
 * Gets the APR for the Compound SAI asset after amount is deposited/withdrawn
 * amount should be negative if withdrawing
 *
 * @param amount {String}
 * @return {Promise<void>}
 */
export const getNextInterestRate = async (amount) => {
  /* We calculate the supply rate:
   *  underlying = totalSupply × exchangeRate
   *  borrowsPer = [totalBorrows ÷ underlying
   *  supplyRate = borrowRate × (1-reserveFactor) × borrowsPer
   */

  const assetContract = await getCompoundAssetContract(getAssetInfo('cDAI'));
  const borrowRate = Dec((await assetContract.methods.borrowRatePerBlock().call()).toString()).div(1e18).toString();
  const totalSupplied = (await assetContract.methods.totalSupply().call()).toString();
  const exchangeRate = Dec((await assetContract.methods.exchangeRateCurrent().call()).toString()).div(1e18).toString();
  const totalBorrows = Dec((await assetContract.methods.totalBorrowsCurrent().call()).toString()).div(1e18).toString();
  const reserveFactorMantissa = (await assetContract.methods.reserveFactorMantissa().call()).toString();

  const totalSuppliedUnderlying = Dec(totalSupplied).times(exchangeRate).plus(Dec(amount.toString())).div(1e18)
    .toString();
  const borrowsPer = Dec(totalBorrows).div(totalSuppliedUnderlying).toString();

  const nextInterestRatePB = Dec(borrowRate).times(Dec('1').times(1e18).minus(Dec(reserveFactorMantissa))).times(borrowsPer).div(1e18)
    .toString();

  return Dec(BLOCKS_IN_A_YEAR).times(Dec(nextInterestRatePB)).times(100).toString();
};

/**
 * Helper method to find compound assets by the symbol of their
 * underlying asset
 *
 * @param _underlyingAsset {String}
 * @param property {String}
 * @return {Object|String}
 */
export const findCompoundAssetByUnderlying = (_underlyingAsset, property = '') => {
  let asset = {};
  if (_underlyingAsset === 'WBTC') {
    asset = compoundCollateralAssets.find(({ symbol }) => symbol === 'cWBTC');
  } else if (_underlyingAsset === 'WBTC Legacy') {
    asset = compoundCollateralAssets.find(({ symbol }) => symbol === 'cWBTC Legacy');
  } else {
    asset = compoundCollateralAssets.find(({ underlyingAsset }) => _underlyingAsset === underlyingAsset);
  }

  if (property) return asset ? asset[property] : undefined;

  return asset;
};
export const EMPTY_COMPOUND_DATA = {
  usedAssets: {},
  suppliedUsd: '0',
  borrowedUsd: '0',
  borrowLimitUsd: '0',
  leftToBorrowUsd: '0',
  ratio: '0',
  minRatio: '0',
  netApy: '0',
  incentiveUsd: '0',
  totalInterestUsd: '0',
  isSubscribedToAutomation: false,
  automationResubscribeRequired: false,
  borrowStableSupplyUnstable: false,
  lastUpdated: Date.now(),
};

/**
 * Fetch all compound data for address
 *
 * @param address {string}
 * @param assetsData {object} getState().compoundManage.assetsData
 * @param assets {object} getState().assets
 * @param walletType {object} getState().general.walletType.value
 * @returns {Promise<{borrowedUsd: *, suppliedUsd: *, usedAssets}>}
 */
export const getCompoundAccountData = async (address, assetsData, assets, walletType = 'proxy') => {
  if (!address) return null;
  // api key is currently optional
  const accApiDataPromise = fetchWithTimeout(`https://api.compound.finance/api/v2/account?addresses[]=${address}`);

  let payload = { ...EMPTY_COMPOUND_DATA };

  const loanInfoContract = CompoundLoanInfoContract();
  const loanInfo = await loanInfoContract.methods.getTokenBalances(address, compoundCollateralAssets.map(a => a.address)).call();
  const collateralAssetsAddresses = await getCollateralAssetsAddresses(address);

  const usedAssets = {};

  loanInfo.balances.forEach((weiAmount, i) => {
    const asset = compoundCollateralAssets[i].symbol === 'cWBTC Legacy'
      ? `${compoundCollateralAssets[i].underlyingAsset} Legacy`
      : compoundCollateralAssets[i].underlyingAsset;
    const amount = assetAmountInEth(weiAmount.toString(), asset);
    const collateral = !!collateralAssetsAddresses.find(a => compareAddresses(a, compoundCollateralAssets[i].address));
    if (weiAmount.toString() === '0' && !collateral) return;
    if (!usedAssets[asset]) usedAssets[asset] = {};
    usedAssets[asset] = {
      ...usedAssets[asset],
      symbol: asset,
      supplied: amount,
      suppliedUsd: Dec(amount).mul(assets[handleWbtcLegacy(asset)].compoundPrice).toString(),
      isSupplied: +amount > 0,
      earned: '0',
      earnedUsd: '0',
      collateral,
    };
  });

  loanInfo.borrows.forEach((weiAmount, i) => {
    if (weiAmount.toString() === '0') return;
    const asset = compoundCollateralAssets[i].symbol === 'cWBTC Legacy'
      ? `${compoundCollateralAssets[i].underlyingAsset} Legacy`
      : compoundCollateralAssets[i].underlyingAsset;
    const amount = assetAmountInEth(weiAmount.toString(), asset);
    if (!usedAssets[asset]) usedAssets[asset] = {};
    usedAssets[asset] = {
      ...usedAssets[asset],
      symbol: asset,
      borrowed: amount,
      borrowedUsd: Dec(amount).mul(assets[handleWbtcLegacy(asset)].compoundPrice).toString(),
      isBorrowed: true,
      debt: '0',
      debtUsd: '0',
      collateral: !!usedAssets[asset].collateral,
    };
  });

  try {
    const _accApiData = await accApiDataPromise;
    const accApiData = await _accApiData.json();
    if (accApiData.accounts.length > 0) {
      accApiData.accounts[0].tokens.forEach((token) => {
        const {
          address,
          symbol: _symbol,
          supply_balance_underlying: { value: tokenSupplying },
          borrow_balance_underlying: { value: tokenBorrowing },
          lifetime_supply_interest_accrued: { value: earned },
          lifetime_borrow_interest_accrued: { value: debt },
        } = token;
        let symbol = _symbol.substr(1);

        symbol = address.toLowerCase() === '0xc11b1268c1a384e55c48c2391d8d480264a3a7f4'.toLowerCase()
          ? 'WBTC Legacy'
          : symbol;
        symbol = symbol === 'WBTC2' ? 'WBTC' : symbol;

        const usedAsset = usedAssets[symbol];

        const parseApiValue = val => val === '0.0' ? '0' : val; // eslint-disable-line

        if (usedAsset) {
          usedAsset.debt = parseApiValue(debt);
          usedAsset.debtUsd = Dec(parseApiValue(debt)).mul(assets[handleWbtcLegacy(symbol)].compoundPrice).toString();
          usedAsset.earned = parseApiValue(earned);
          usedAsset.earnedUsd = Dec(parseApiValue(earned)).mul(assets[handleWbtcLegacy(symbol)].compoundPrice).toString();
        }
      });
    }
  } catch (err) {
    console.warn(err);
    console.warn('Fallback: Ignoring');
  }

  payload = {
    ...payload,
    usedAssets,
    suppliedUsd: getAssetsTotal(usedAssets, ({ isSupplied }) => isSupplied, ({ suppliedUsd }) => suppliedUsd),
    suppliedCollateralUsd: getAssetsTotal(usedAssets, ({ isSupplied, collateral }) => isSupplied && collateral, ({ suppliedUsd }) => suppliedUsd),
    borrowedUsd: getAssetsTotal(usedAssets, ({ isBorrowed }) => isBorrowed, ({ borrowedUsd }) => borrowedUsd),
    borrowLimitUsd: getAssetsTotal(usedAssets, ({ isSupplied, collateral }) => isSupplied && collateral, ({ symbol, suppliedUsd }) => new Dec(suppliedUsd).mul(assetsData[symbol].collateralFactor)),
  };
  const leftToBorrowUsd = new Dec(payload.borrowLimitUsd).sub(payload.borrowedUsd).toString();

  payload.leftToBorrowUsd = leftToBorrowUsd;
  payload.borrowLimitUsd = Dec(leftToBorrowUsd).add(payload.borrowedUsd).toString();

  payload.liquidationLimitUsd = payload.borrowLimitUsd;
  payload.ratio = payload.borrowedUsd && payload.borrowedUsd !== '0'
    ? Dec(payload.borrowLimitUsd).div(payload.borrowedUsd).mul(100).toString()
    : '0';
  payload.minRatio = '100';
  payload.collRatio = payload.borrowedUsd && payload.borrowedUsd !== '0'
    ? Dec(payload.suppliedCollateralUsd).div(payload.borrowedUsd).mul(100).toString()
    : '0';

  // Calculate borrow limits per asset
  Object.values(payload.usedAssets).forEach((item) => {
    if (item.isBorrowed) {
      // eslint-disable-next-line no-param-reassign
      item.limit = calculateBorrowingAssetLimit(item.borrowedUsd, payload.borrowLimitUsd);
    }
  });

  const { netApy, incentiveUsd, totalInterestUsd } = calculateNetApy(usedAssets, assetsData);
  payload.netApy = netApy;
  payload.incentiveUsd = incentiveUsd;
  payload.totalInterestUsd = totalInterestUsd;


  payload.isSubscribedToAutomation = false;
  payload.automationResubscribeRequired = false;
  if (walletType === 'proxy') {
    const monitorData = await isSubscribedToMonitor(address);
    payload.isSubscribedToAutomation = monitorData.isSubscribedToAutomation;
    payload.automationResubscribeRequired = monitorData.automationResubscribeRequired;
  }

  const { leveragedType, leveragedAsset } = isLeveragedPos(payload.usedAssets);
  payload.leveragedType = leveragedType;
  if (leveragedType !== '') {
    payload.leveragedAsset = leveragedAsset;
    const assetPrice = assets[handleWbtcLegacy(leveragedAsset)].compoundPrice;
    payload.liquidationPrice = calcLeverageLiqPrice(leveragedType, assetPrice, payload.borrowedUsd, payload.liquidationLimitUsd);
  }

  return payload;
};

export const getMarketsData = async (cAddresses) => {
  const loanInfoContract = CompoundLoanInfoContract();
  const loanInfo = await loanInfoContract.methods.getFullTokensInfo(cAddresses).call();

  const compPrice = loanInfo.find(m => compareAddresses(m.underlyingTokenAddress, compAddress)).price.toString();

  return loanInfo
    .map((market, i) => {
      let symbol = getAssetInfoByAddress(cAddresses[i]).underlyingAsset;
      let isWbtcLegacy = false;
      const totalSupply = Dec(market.totalSupply.toString()).div(1e18).times(market.exchangeRate.toString());
      const totalBorrow = market.totalBorrow.toString();
      const borrowCap = market.borrowCap.toString();
      const compSupplySpeeds = market.compSupplySpeeds.toString();
      const compBorrowSpeeds = market.compBorrowSpeeds.toString();
      const assetPrice = market.price.toString();

      // compSupplySpeeds/compBorrowSpeeds is per block per market (borrow & supply are separate markets)
      const incentiveSupplyApy = aprToApy((100 * BLOCKS_IN_A_YEAR * compSupplySpeeds * compPrice) / assetPrice / totalSupply).toString();
      const incentiveBorrowApy = aprToApy((100 * BLOCKS_IN_A_YEAR * compBorrowSpeeds * compPrice) / assetPrice / totalBorrow).toString();

      if (cAddresses[i].toLowerCase() === '0xc11b1268c1a384e55c48c2391d8d480264a3a7f4'.toLowerCase()) {
        symbol = 'WBTC Legacy';
        isWbtcLegacy = true;
      }
      return {
        symbol,
        underlyingTokenAddress: market.underlyingTokenAddress,
        supplyRate: aprToApy(new Dec(BLOCKS_IN_A_YEAR).times(market.supplyRate.toString()).div(1e16).toString()).toString(),
        borrowRate: aprToApy(new Dec(BLOCKS_IN_A_YEAR).times(market.borrowRate.toString()).div(1e16).toString()).toString(),
        incentiveSupplyToken: 'COMP',
        incentiveBorrowToken: 'COMP',
        incentiveSupplyApy,
        incentiveBorrowApy,
        collateralFactor: new Dec(market.collateralFactor.toString()).div(1e18).toString(),
        marketLiquidity: assetAmountInEth(market.marketLiquidity.toString(), handleWbtcLegacy(symbol)),
        utilization: new Dec(market.totalBorrow.toString()).div(totalSupply).times(100).toString(),
        totalSupply: assetAmountInEth(totalSupply, handleWbtcLegacy(symbol)),
        totalBorrow: assetAmountInEth(totalBorrow, handleWbtcLegacy(symbol)),
        exchangeRate: new Dec(market.exchangeRate.toString()).div(1e28).toString(),
        borrowCap: assetAmountInEth(borrowCap, handleWbtcLegacy(symbol)),
        canBeBorrowed: !isWbtcLegacy ? !COMP_DISABLED_BORROW_ASSETS.find(a => compareAddresses(a, market.underlyingTokenAddress)) : false,
        canBeSupplied: !isWbtcLegacy ? !COMP_DISABLED_COLL_ASSETS.find(a => compareAddresses(a, market.underlyingTokenAddress)) : false,
        // price: market.price.toString(),
      };
    });
};

const oldMarkets = ['WBTC Legacy', 'REP'];

export const getCompBalance = async (holderAddr) => {
  try { requireAddress(holderAddr); } catch (err) { return '0'; }
  const contract = CompBalanceContract();
  const affectedAssets = oldMarkets.map(i => `c${i}`);
  const cTokens = compoundCollateralAssets.filter(i => !affectedAssets.includes(i.symbol)).map(a => a.address);
  const balance = await contract.methods.getBalance(holderAddr, cTokens).call();
  return new Dec(balance.toString()).div(1e18).toString();
};

const getCompClaimableTokens = (usedAssets) => {
  let suppliedTokens = [];
  let borrowedTokens = [];
  const cETH = getAssetInfo('cETH').address;
  const assets = usedAssets && Object.values(usedAssets);

  if (assets && assets.length) {
    assets.forEach(({ isSupplied, isBorrowed }, index) => {
      const asset = Object.keys(usedAssets)[index];
      if (oldMarkets.includes(asset)) return;
      if (isSupplied) suppliedTokens = [...suppliedTokens, getAssetInfo(`c${asset}`).address];
      if (isBorrowed) borrowedTokens = [...borrowedTokens, getAssetInfo(`c${asset}`).address];
    });

    return { suppliedTokens, borrowedTokens };
  }
  return { suppliedTokens: [cETH], borrowedTokens: [] };
};

export const claimAndSellCompBalance = async ({
  supply, swap, toAsset, slippage, price, amount, proxyAddress, usedAssets, account, sendTxFunc, accountType,
}) => {
  const fromAsset = 'COMP';
  const { suppliedTokens, borrowedTokens } = await getCompClaimableTokens(usedAssets);

  const exchangeOrder = swap
    ? await getExchangeOrder(
      fromAsset,
      toAsset,
      amount,
      price,
      slippage,
      account,
    )
    : getEmptyExchangeOrder();

  const depositAddress = supply ? getAssetInfo(`c${toAsset}`)?.address : ZERO_ADDRESS;
  const inMarket = supply && !!usedAssets[toAsset]?.collateral;

  const params = [exchangeOrder.orderData, suppliedTokens, borrowedTokens, depositAddress, inMarket];

  return callViaProxy(accountType, sendTxFunc, proxyAddress, account, 'CompLeverage', 'claimAndSell', params);
};

export const claimCompBalance = async (accountType, path, sendTxFunc, account, proxyAddress, claimFromAccount, usedAssets) => {
  const { suppliedTokens, borrowedTokens } = await getCompClaimableTokens(usedAssets);

  const compBalanceContract = await CompBalanceContract();
  return claimFromAccount
    ? callTx(accountType, path, sendTxFunc, compBalanceContract, 'claimComp', [account, suppliedTokens, borrowedTokens], { from: account })
    : callViaProxy(accountType, sendTxFunc, proxyAddress, account, 'CompBalance', 'claimComp', [proxyAddress, suppliedTokens, borrowedTokens], 0);
};

export const getAction = async (action, inputAmount, cTokenAddr, account, proxyAddress, usedAssets) => {
  let instantiatedAction;
  const underlyingAsset = getAssetInfoByAddress(cTokenAddr).underlyingAsset;
  const isAssetETH = underlyingAsset === 'ETH';
  switch (action) {
    case 'collateral': {
      if (isAssetETH) {
        instantiatedAction = [
          new dfs.actions.basic.WrapEthAction(inputAmount),
          new dfs.actions.compound.CompoundSupplyAction(cTokenAddr, inputAmount, proxyAddress),
        ];
      } else {
        instantiatedAction = [new dfs.actions.compound.CompoundSupplyAction(cTokenAddr, inputAmount, account)];
      }
      break;
    }
    case 'borrow': {
      const loanInfo = (await CompoundLoanInfoContract().methods.getFullTokensInfo([cTokenAddr]).call())[0];
      const liquidity = loanInfo.marketLiquidity.toString();
      const liqAfterBorrow = new Dec(liquidity).sub(inputAmount);

      if (liqAfterBorrow.lt('0')) {
        throw new Error(
          t('compound.missing_liquidity',
            { '%assetAmount': `${numberWithCommas(Dec.floor(assetAmountInEth(liquidity, underlyingAsset)).toString())} ${underlyingAsset}` },
          ),
        );
      }

      instantiatedAction = [new dfs.actions.compound.CompoundBorrowAction(cTokenAddr, inputAmount, isAssetETH ? proxyAddress : account)];
      if (isAssetETH) instantiatedAction.push(new dfs.actions.basic.UnwrapEthAction(inputAmount, account));
      break;
    }
    case 'withdraw': {
      const amount = assetAmountInWei(usedAssets[underlyingAsset].supplied, underlyingAsset) === inputAmount
        ? MAXUINT
        : inputAmount;
      if (isAssetETH) {
        instantiatedAction = [
          new dfs.actions.compound.CompoundWithdrawAction(cTokenAddr, amount, proxyAddress),
          new dfs.actions.basic.UnwrapEthAction(amount, account),
        ];
      } else {
        instantiatedAction = [new dfs.actions.compound.CompoundWithdrawAction(cTokenAddr, amount, account)];
      }
      break;
    }
    case 'payback': {
      const wholeDebt = assetAmountInWei(usedAssets[underlyingAsset].borrowed, underlyingAsset) === inputAmount;
      const amount = wholeDebt ? MAXUINT : inputAmount;
      if (isAssetETH) {
        const wrapAmount = wholeDebt ? new Dec(inputAmount).mul(CONTINUOUS_FEE_MULTIPLIER).floor().toString() : inputAmount;
        instantiatedAction = [
          new dfs.actions.basic.WrapEthAction(wrapAmount),
          new dfs.actions.compound.CompoundPaybackAction(cTokenAddr, amount, proxyAddress),
          // Unwrap makes sense only for paybacks over 1.5mil at 100gw
          // and the tradeoff is potentially unexpected behavior, as it would have to unwrap uint(-1)
        ];
      } else {
        instantiatedAction = [new dfs.actions.compound.CompoundPaybackAction(cTokenAddr, amount, account)];
      }
      break;
    }
    default:
      throw new Error('Unknown action');
  }
  return instantiatedAction;
};

export const getCompoundRecipe = async (primaryAction, primaryInput, secondaryAction, secondaryInput, primaryToken, secondaryToken, account, proxyAddress, usedAssets) => {
  requireAddress(account);
  requireAddress(proxyAddress);
  const recipeActions =
    await Promise.all([{ action: primaryAction, input: primaryInput, token: primaryToken }, { action: secondaryAction, input: secondaryInput, token: secondaryToken }]
      .filter(a => a.action && a.action !== 'send')
      .map(action => getAction(action.action, action.input, action.token, account, proxyAddress, usedAssets))
      .flat(),
    );

  const name = 'recCompoundDashAction';
  return new dfs.Recipe(name, recipeActions.flat());
};
