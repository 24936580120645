import React, { Fragment } from 'react';
import t from 'translate';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { ACCOUNT_TYPES, DEFAULT_TREZOR_PATH } from '../../../constants/general';
import { normalLogin } from '../../../actions/accountActions';
import InputComponent from '../../Forms/InputComponent';
import ModalBody from '../../Modals/ModalBody';

import '../Connect.scss';

const ConnectTrezor = ({
  connectingProvider, formValue, handleSubmit, onSubmit,
}) => (
  <>
    <ModalBody>
      <div className="connect-login-wrapper trezor-wrapper">
        <h2>{t('account.connect_message', { '%item': 'Trezor' })}</h2>
        <p>{t('account.secure_way')}</p>

        <form onSubmit={handleSubmit(() => { onSubmit(ACCOUNT_TYPES.trezor, formValue); })} className="form-wrapper">
          <Field
            id="connect-trezor-path"
            name="path"
            labelText={t('common.path')}
            component={InputComponent}
            showErrorText
          />
        </form>
      </div>
    </ModalBody>
    <div className="modal-controls">
      <button
        disabled={connectingProvider || !formValue}
        type="submit"
        className="button uppercase green"
        onClick={() => onSubmit(ACCOUNT_TYPES.trezor, formValue)}
      >
        { connectingProvider ? t('common.connecting') : t('common.connect') }
      </button>
    </div>
  </>
);

ConnectTrezor.defaultProps = {
  formValue: '',
};

ConnectTrezor.propTypes = {
  connectingProvider: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  formValue: PropTypes.string,
};

const ConnectTrezorFormComp = reduxForm({
  form: 'connectTrezorForm',
})(ConnectTrezor);

const selector = formValueSelector('connectTrezorForm');

const mapStateToProps = state => ({
  connectingProvider: state.general.connectingProvider,
  initialValues: {
    path: DEFAULT_TREZOR_PATH,
  },
  formValue: selector(state, 'path'),
});

const mapDispatchToProps = {
  onSubmit: normalLogin,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectTrezorFormComp);
