import dfs from '@defisaver/sdk';
import Dec from 'decimal.js';
import cloneDeep from 'lodash/cloneDeep';
import RecipeAction from '../RecipeAction';
import { Amount, AssetAmount, Source } from '../../components/Recipes/RecipeCreator/inputTypes';
import {
  findInsertPositionFromContract,
  getApproxHint,
  getNumTrials,
  getRedemptionFee,
  getRedemptionFeePercentage,
  getRedemptionHints,
  getTroveInfoMulticall,
} from '../../services/liquityServices/liquityService';
import { ethToWei, weiToEth } from '../../services/ethService';
import { changeBalance } from '../../services/recipeCreator/recipeActionUtils';
import RedeemIcon from '../recipeIcons/Redeem.svg';


export default class LiquityRedeemAction extends RecipeAction {
  static prettyName = 'Redeem ETH from LUSD';

  static protocol = 'liquity';

  static protocolPrettyName = 'Liquity';

  static description = 'Redeems WETH from LUSD at rate of 1 LUSD = $1 WETH.';

  constructor(from = 'wallet', amount = '', to = 'wallet') {
    super();
    this.inputs = [
      new Source('From', from),
      new AssetAmount('Amount', amount, 'LUSD'),
      new Source('To', to, true),
    ];
    this.output = new Amount('output', '0');
    this.asset = 'WETH';
  }

  async toDfsAction(getState) {
    const {
      general: { account },
      maker: { proxyAddress },
      recipeCreator: { actionCalls, actions },
    } = getState();
    const args = this.mapReturnValuesToArgs(actionCalls.map(a => a.returnValue), actions);
    const from = args[0] === 'wallet' ? account : proxyAddress;
    const to = args[2] === 'wallet' ? account : proxyAddress;
    const [{ assetPrice }] = await getTroveInfoMulticall([proxyAddress]);
    const ethPriceWei = ethToWei(assetPrice);
    const lusdAmountWei = ethToWei(args[1]);
    const { 0: firstRedemptionHint, 1: partialRedemptionNewICR, 2: truncatedLUSDAmount } = await getRedemptionHints(lusdAmountWei, ethPriceWei, 50); // iterations ?
    const numTrials = await getNumTrials();
    const { hintAddress: approxPartialRedemptionHint } = await getApproxHint(partialRedemptionNewICR, numTrials, 42);
    const exactPartialRedemptionHint = await findInsertPositionFromContract(partialRedemptionNewICR, approxPartialRedemptionHint, approxPartialRedemptionHint);
    const { amount: maxFeePercentage, showWarning } = await getRedemptionFeePercentage();

    return new dfs.actions.liquity.LiquityRedeemAction(truncatedLUSDAmount, from, to, firstRedemptionHint, exactPartialRedemptionHint[0], exactPartialRedemptionHint[1], partialRedemptionNewICR, 0, ethToWei(maxFeePercentage));
  }

  async getAfterValues(_balances = {}, returnValues = [], actions = [], _positions = {}, getState) {
    const positions = { ..._positions };
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const {
      general: { account },
      maker: { proxyAddress },
      liquity: { proxy },
      liquityStaking: { proxy: stakingProxy },
    } = getState();
    if (!positions.liquity) positions.liquity = { ...proxy, ...stakingProxy };
    const lusdAmount = args[1] || '0';
    const _wethAmount = new Dec(lusdAmount).div(proxy.assetPrice).toString();
    const fee = new Dec(_wethAmount).gt(0) ? weiToEth(await getRedemptionFee(ethToWei(_wethAmount))) : weiToEth('0');
    const wethAmount = new Dec(_wethAmount).minus(fee).toString();
    const balances = cloneDeep(_balances);
    await changeBalance(balances, args[0], 'LUSD', new Dec(lusdAmount).mul(-1).toString(), args[0] === 'wallet' ? account : proxyAddress);
    await changeBalance(balances, args[2], 'WETH', new Dec(_wethAmount).minus(fee).toString(), args[2] === 'wallet' ? account : proxyAddress);
    this.output.value = wethAmount;
    return { returnValue: this.output, balances, positions };
  }

  setAsset(asset) {
    this.asset = asset;
  }

  getAsset() {
    return this.asset;
  }

  static getIcon() {
    return RedeemIcon;
  }
}
