import memoize from 'memoizee';
import dfs from '@defisaver/sdk';
import { getIlkInfo } from '@defisaver/tokens';
import { CdpId, Amount, Source } from 'components/Recipes/RecipeCreator/inputTypes';
import RecipeAction from 'recipeActions/RecipeAction';
import { getFullCdpInfoFromId as _getFullCdpInfoFromId } from 'services/makerServices/makerManageServices/makerManageService';
import { withdrawAfterValues } from 'actions/makerActions/makerManageActions/makerManageAfterValues';
import WithdrawIcon from '../recipeIcons/Withdraw.svg';
import { formatNumber } from '../../services/utils';
import { assetAmountInWeiIgnorePointer } from '../../services/recipeCreator/recipeActionUtils';
import { MAXUINT } from '../../constants/general';
import { getCdpManagerForType } from '../../services/makerServices/makerMcdService';

const getFullCdpInfoFromId = memoize(_getFullCdpInfoFromId, { maxAge: 2 * 60 * 1000 });

export default class MakerWithdrawAction extends RecipeAction {
  static prettyName = 'Withdraw collateral from Vault';

  static protocol = 'maker';

  static protocolPrettyName = 'Maker';

  static description = 'Withdraws collateral from the selected Vault.';

  constructor(cdpID = 0, amount = '', to = 'wallet') {
    super();
    this.inputs = [
      new CdpId('CDP ID', cdpID),
      new Amount('Amount', amount),
      new Source('To', to, true),
    ];
    this.output = new Amount('output', 0);
    this.asset = '';
  }

  async getAfterValues(_balances = {}, returnValues = [], actions = [], _positions = {}, getState) {
    const positions = { ..._positions };
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const cdpId = args[0];
    if (!positions[`maker_${cdpId}`]) positions[`maker_${cdpId}`] = await getFullCdpInfoFromId(cdpId);
    let amount = args[1];
    if (amount === 'All available') amount = positions[`maker_${cdpId}`]?.collateral || '0';
    const { afterPosition, balances, returnValue } = await withdrawAfterValues(
      {
        amount,
        to: args[2],
      },
      {
        cdp: positions[`maker_${cdpId}`],
        assets: getState().assets,
        account: getState().general.account,
        proxyAddress: getState().maker.proxyAddress,
      },
      _balances,
    );
    positions[`maker_${cdpId}`] = afterPosition;
    this.output.value = returnValue;
    return { returnValue: this.output, balances, positions };
  }


  async toDfsAction(getState, actions, returnValues, positions) {
    const {
      general: { account },
      maker: { proxyAddress },
    } = getState();
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const to = this.inputs[2].value === 'wallet' ? account : proxyAddress;
    const cdp = positions[`maker_${args[0]}`] || await getFullCdpInfoFromId(args[0]);
    const managerAddress = getCdpManagerForType(positions[`maker_${args[0]}`].type);
    const amount = this.inputs[1].value === 'All available' ? MAXUINT : assetAmountInWeiIgnorePointer(this.inputs[1].value, cdp.asset);
    return new dfs.actions.maker.MakerWithdrawAction(this.inputs[0].value, amount, getIlkInfo(cdp.ilkLabel).join, to, managerAddress);
  }

  static getIcon() {
    return WithdrawIcon;
  }

  _getPrettyName(actionCalls, actions) {
    const args = this.mapReturnValuesToArgs(actionCalls.map(a => a.returnValue), actions);
    const positions = actionCalls[actionCalls.length - 1]?.positions;
    const cdp = positions?.[`maker_${args[0]}`];
    if (!cdp) return this.constructor.prettyName;
    return `Withdraw ${args[1] === 'All available' ? 'all' : formatNumber(args[1])} ${cdp?.asset} from Vault #${args[0]}`;
  }

  setAsset(asset) {
    this.asset = asset;
  }

  getAsset() {
    return this.asset;
  }
}
