/* eslint-disable */
import React from 'react';

export default function Alert(props) {
  return (
    <svg width={52} height={47} viewBox="0 0 52 47" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M25.9993 33.0278C24.5455 33.0278 23.3269 34.2326 23.3269 35.67C23.3269 37.1074 24.5455 38.3122 25.9993 38.3122C27.3996 38.3122 28.6716 37.1074 28.6075 35.7334C28.6716 34.222 27.4637 33.0278 25.9993 33.0278Z" fill="white" />
      <path d="M50.7346 42.3919C52.4128 39.5278 52.4235 36.114 50.756 33.2604L34.0164 4.59748C32.3595 1.71217 29.3665 0 26.01 0C22.6536 0 19.6605 1.72273 18.0037 4.58691L1.24269 33.2815C-0.424853 36.1669 -0.414163 39.6018 1.27476 42.4659C2.94231 45.2984 5.92465 47 9.25974 47H42.6962C46.042 47 49.0457 45.2773 50.7346 42.3919ZM47.1002 40.3204C46.1702 41.9058 44.5241 42.8464 42.6855 42.8464H9.24905C7.43186 42.8464 5.79638 41.9269 4.88778 40.3733C3.96849 38.7985 3.9578 36.9172 4.87709 35.3319L21.6381 6.64785C22.5467 5.07308 24.1715 4.14302 26.01 4.14302C27.8379 4.14302 29.4734 5.08365 30.382 6.65842L47.1323 35.3425C48.0302 36.8855 48.0195 38.7457 47.1002 40.3204Z" fill="white" />
      <path d="M25.3367 14.4794C24.0647 14.8387 23.2737 15.9802 23.2737 17.3647C23.3378 18.1997 23.3913 19.0452 23.4554 19.8801C23.6371 23.0614 23.8188 26.1792 24.0006 29.3604C24.0647 30.4385 24.9092 31.2206 25.9995 31.2206C27.0898 31.2206 27.9449 30.3856 27.9984 29.297C27.9984 28.6418 27.9984 28.0393 28.0625 27.3735C28.1801 25.3337 28.3084 23.2939 28.426 21.2541C28.4901 19.933 28.6077 18.6118 28.6718 17.2907C28.6718 16.8151 28.6077 16.3924 28.426 15.9696C27.8808 14.7859 26.6088 14.1835 25.3367 14.4794Z" fill="white" />
    </svg>
  );
}
