import React from 'react';
import PropTypes from 'prop-types';
import t from 'translate';

import { formatNumber } from '../../../services/utils';
import TooltipWrapper from '../../TooltipWrapper/TooltipWrapper';
import InfoIcon from '../../Decorative/InfoIcon';

const GasPriceValues = ({
  title, tooltip, value, usdValue, loading, symbol,
}) => (
  <div className="section-wrapper">
    <div className="label">{tooltip && <TooltipWrapper title={tooltip}><InfoIcon /></TooltipWrapper>} <span>{title}:</span></div>
    <div className={`value ${+usdValue ? 'with-sub' : ''}`}>
      {loading ? t('common.loading_no_dots') : <TooltipWrapper copy title={value}>{formatNumber(value, 2, true)} {symbol}</TooltipWrapper>}
      {!!+usdValue && <span className="subvalue">${formatNumber(usdValue, 2, true)}</span>}
    </div>
  </div>
);

GasPriceValues.defaultProps = {
  tooltip: '',
  usdValue: '0',
  symbol: '',
  loading: false,
};

GasPriceValues.propTypes = {
  tooltip: PropTypes.string,
  usdValue: PropTypes.string,
  symbol: PropTypes.string,
  loading: PropTypes.bool,
  value: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default GasPriceValues;
