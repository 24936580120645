import React from 'react';

export default function SmartSavingsIcon(props) {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="30" height="30" rx="3" fill="#61717E" />
      <path d="M22.3528 10.4167V8.58333C22.3528 7.57225 21.5305 6.75 20.5195 6.75H8.60278C7.08662 6.75 5.85278 7.98383 5.85278 9.5V20.5C5.85278 22.5176 7.49728 23.25 8.60278 23.25H22.3528C23.3639 23.25 24.1861 22.4278 24.1861 21.4167V12.25C24.1861 11.2389 23.3639 10.4167 22.3528 10.4167ZM20.5195 18.6667H18.6861V15H20.5195V18.6667ZM8.60278 10.4167C8.36676 10.4061 8.1439 10.3049 7.98062 10.1342C7.81733 9.96341 7.7262 9.73626 7.7262 9.5C7.7262 9.26374 7.81733 9.03659 7.98062 8.86583C8.1439 8.69508 8.36676 8.59389 8.60278 8.58333H20.5195V10.4167H8.60278Z" fill="#626D75" />
    </svg>
  );
}
