import dfs from '@defisaver/sdk';
import { CdpId } from '../../components/Recipes/RecipeCreator/inputTypes';
import RecipeAction from '../RecipeAction';
import { mcdCdpManagerAddress } from '../../services/contractRegistryService';
import { getFullCdpInfoFromId } from '../../services/makerServices/makerManageServices/makerManageService';
import { mergeAfterValues } from '../../actions/makerActions/makerManageActions/makerManageAfterValues';

const emptyCdp = {
  collateral: 0,
  collateralUsd: 0,
  debtDai: 0,
  debtUsd: 0,
  ignore: true,
  ratio: 0,
};

export default class MakerMergeAction extends RecipeAction {
  static prettyName = 'Merge two Vaults';

  static protocol = 'maker';

  static protocolPrettyName = 'Maker';

  static description = 'Merges two vaults of the same type into one.';

  constructor(firstCdpId = 0, secondCdpId = 0) {
    super();
    this.inputs = [
      new CdpId('First CDP ID', firstCdpId, 'maker', false),
      new CdpId('Second CDP ID', secondCdpId, 'maker', false),
    ];
    this.output = new CdpId('output', 0);
  }

  // TODO remove first CDP from cdpOptions
  async getAfterValues(_balances = {}, returnValues = [], actions = [], _positions = {}) {
    const positions = { ..._positions };
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const cdpIdSrc = args[0];
    const cdpIdDest = args[1];
    let cdpSrc = await getFullCdpInfoFromId(cdpIdSrc);
    const cdpDest = await getFullCdpInfoFromId(cdpIdDest);
    console.log(cdpDest);
    if (!positions[`maker_${cdpIdSrc}`]) {
      positions[`maker_${cdpIdSrc}`] = cdpSrc;
    } else {
      // use already altered data for that cdp
      cdpSrc = positions[`maker_${cdpIdSrc}`];
    }
    if (!positions[`maker_${cdpIdDest}`]) positions[`maker_${cdpIdDest}`] = cdpDest;
    const { afterPosition, balances, returnValue } = await mergeAfterValues(
      {
        collAmount: cdpSrc.collateral,
        debtAmount: cdpSrc.debtDai,
      },
      {
        cdp: positions[`maker_${cdpIdDest}`],
      },
      _balances,
    );
    positions[`maker_${cdpIdDest}`] = afterPosition;
    positions[`maker_${cdpIdSrc}`] = { ...cdpSrc, ...emptyCdp };
    this.output.value = cdpIdDest;
    return { returnValue: this.output, balances, positions };
  }

  async toDfsAction() {
    return new dfs.actions.maker.MakerMergeAction(this.inputs[0].value, this.inputs[1].value, mcdCdpManagerAddress);
  }

  _getPrettyName(actionCalls, actions) {
    const args = this.mapReturnValuesToArgs(actionCalls.map(a => a.returnValue), actions);
    return `Merge Vaults #${args[0]} and #${args[1]}`;
  }
}
