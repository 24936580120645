import React, { Component } from 'react';
import PropTypes from 'prop-types';
import t from 'translate';
import ModalBody from '../ModalBody';

import './SmartWalletPickerModal.scss';
import { shortAddress } from '../../../services/utils';
import walletIcon from '../UserWalletModal/wallet-icon-circle.svg';

class SmartWalletPickerModal extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { addresses, resolve, closeModal } = this.props;

    return (
      <div className="smart-wallet-picker-modal">
        <ModalBody>
          <div className="new-modal-top-wrapper" style={{ backgroundImage: `url(${walletIcon})` }}>
            <h1>Choose your Smart Wallet</h1>
          </div>
          <div className="new-modal-content-wrapper">
            {
              addresses.map(addr => (
                <button type="button" className="button wallet-option" onClick={() => resolve(addr)} key={addr}>
                  <span className="label">Smart Wallet</span>
                  <span className="address">{shortAddress(addr)}</span>
                </button>
              ))
            }
          </div>
        </ModalBody>
      </div>
    );
  }
}


SmartWalletPickerModal.propTypes = {
  addresses: PropTypes.array.isRequired,
  resolve: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

SmartWalletPickerModal.defaultProps = {};

export default SmartWalletPickerModal;
