import PropTypes from 'prop-types';
import React from 'react';
import { WALLET_ICONS } from '../../../constants/wallet';

const WalletIcon = ({ accData }) => {
  const Icon = WALLET_ICONS[accData.walletName?.toLowerCase() || accData.accountType] || WALLET_ICONS.browser;
  return (<Icon />);
};

WalletIcon.propTypes = { accData: PropTypes.object.isRequired };

export default WalletIcon;
