import Dec from 'decimal.js';
import cloneDeep from 'lodash/cloneDeep';
import { getBestExchangePrice } from 'services/exchangeServiceV3';
import { change } from 'redux-form';
import * as rflxManageTypes from '../../actionTypes/reflexerActionTypes/reflexerManageActionTypes';
import { ALLOWED_PERCENT_OVER_LIQ_RATIO_MAKER } from '../../constants/general';
import { formName } from '../../components/DashboardActions/dashboardActionUtils';

import { captureException } from '../../sentry';
import { changeBalance } from '../../services/recipeCreator/recipeActionUtils';
import {
  getBoostAmount, getMaxBoost, getMaxBorrow, getMaxPayback, getRepayAmount, getMaxWithdraw,
} from '../../services/vaultCommonService';

// Following functions should be reusable between Maker & Reflexer, with the exception of setAfterValue
export const getAggregatedPositionData = (vault) => {
  const {
    liqPercent, collateral, assetPrice, debtInAsset, debtAssetPrice, minDebt,
  } = vault;

  const debtUsd = new Dec(debtInAsset).mul(debtAssetPrice).toString();
  const collateralUsd = new Dec(collateral).mul(assetPrice).toString();

  const liquidationPrice = new Dec(debtUsd).times(liqPercent / 100).div(collateral).toNumber();
  if (liquidationPrice === 0) {
    return {
      ...vault, liquidationPrice: 0, ratio: 0, debtInAsset: 0, debtTooLow: false,
    };
  }

  const ratio = new Dec(collateralUsd)
    .div(debtUsd)
    .times(100)
    .toDecimalPlaces(2, Dec.ROUND_HALF_EVEN)
    .toNumber();

  // if (ratio > 1e10) ratio = 0;
  const debtTooLow = new Dec(debtInAsset).gt(0) && new Dec(debtInAsset).lt(minDebt);

  return {
    ...vault,
    liquidationPrice,
    ratio,
    debtUsd,
    collateralUsd,
    debtTooLow,
    disabledBecauseDusty: debtTooLow,
  };
};

const fullPosition = (getAfterValues) => async (input, stateData, balances = {}) => {
  const result = await getAfterValues.apply(this, [input, stateData, balances]);
  return {
    balances: result.balances,
    returnValue: result.returnValue,
    afterPosition: getAggregatedPositionData(result.vault),
  };
};

export const generateAfterValues = fullPosition(async ({ amount, to = 'wallet' }, {
  vault: _vault, assets, account, proxyAddress,
}, _balances = {}) => {
  const vault = { ..._vault };
  const balances = cloneDeep(_balances);
  const _amount = amount || '0';
  vault.debtInAsset = new Dec(vault.debtInAsset).plus(_amount).toString();
  await changeBalance(balances, to, vault.debtAsset, _amount, to === 'wallet' ? account : proxyAddress);
  return { vault, balances, returnValue: _amount };
});

export const paybackAfterValues = fullPosition(async ({ amount, from = 'wallet' }, {
  vault: _vault, assets, account, proxyAddress,
}, _balances = {}) => {
  const vault = { ..._vault };
  const balances = cloneDeep(_balances);
  const _amount = amount || '0';
  vault.debtInAsset = Dec.max(0, new Dec(vault.debtInAsset).minus(_amount)).toString();
  await changeBalance(balances, from, vault.debtAsset, new Dec(_amount).times(-1), from === 'wallet' ? account : proxyAddress);
  return { vault, balances, returnValue: _amount };
});

export const addCollateralAfterValues = fullPosition(async ({ amount, from = 'wallet' }, {
  vault: _vault, assets, account, proxyAddress,
}, _balances = {}) => {
  const vault = { ..._vault };
  const balances = cloneDeep(_balances);
  const _amount = amount || '0';
  vault.collateral = new Dec(vault.collateral).plus(_amount).toString();
  vault.collateralUsd = new Dec(vault.collateral).mul(vault.assetPrice).toString();
  await changeBalance(balances, from, vault.asset.replace(/^ETH/, 'WETH'), new Dec(_amount).times(-1), from === 'wallet' ? account : proxyAddress);
  return { vault, balances, returnValue: _amount };
});

export const withdrawAfterValues = fullPosition(async ({ amount, to = 'wallet' }, {
  vault: _vault, assets, account, proxyAddress,
}, _balances = {}) => {
  const vault = { ..._vault };
  const balances = cloneDeep(_balances);
  const _amount = amount || '0';
  vault.collateral = new Dec(vault.collateral).minus(_amount).toString();
  await changeBalance(balances, to, vault.asset.replace(/^ETH/, 'WETH'), _amount, to === 'wallet' ? account : proxyAddress);
  return { vault, balances, returnValue: _amount };
});

export const boostAfterValues = fullPosition(async ({ amount }, { proxyAddress, vault: _vault }, _balances = {}) => {
  const vault = { ..._vault };
  const balances = cloneDeep(_balances);
  const { price: rate } = await getBestExchangePrice(amount.toString(), vault.debtAsset, vault.asset, proxyAddress);
  vault.debtInAsset = new Dec(vault.debtInAsset).plus(amount).toString();
  vault.collateral = new Dec(vault.collateral).plus(new Dec(amount).times(rate)).toString();
  return { vault, balances, returnValue: amount };
});

export const repayAfterValues = fullPosition(async ({ amount }, { proxyAddress, vault: _vault }, _balances = {}) => {
  const vault = { ..._vault };
  const balances = cloneDeep(_balances);
  const { price: rate } = await getBestExchangePrice(amount.toString(), vault.asset, vault.debtAsset, proxyAddress);
  vault.collateral = new Dec(vault.collateral).minus(amount).toString();
  vault.debtInAsset = new Dec(vault.debtInAsset).minus(new Dec(amount).times(rate)).toString();
  if (new Dec(vault.debtInAsset).lt(0)) vault.debtInAsset = '0';
  return { vault, balances, returnValue: amount };
});

export const mergeAfterValues = fullPosition(({ collAmount, debtAmount }, { vault: _vault }, _balances = {}) => {
  const vault = { ..._vault };
  const balances = cloneDeep(_balances);
  vault.collateral = new Dec(vault.collateral).plus(collAmount).toString();
  vault.debtInAsset = new Dec(vault.debtInAsset).plus(debtAmount).toString();
  return { vault, balances, returnValue: vault };
});

const getAfterValue = async (action, name, inputs, data, assets, account, proxyAddress, isSecondary = false) => {
  let afterValues;
  switch (action) {
    case 'collateral': {
      afterValues = (await addCollateralAfterValues({ amount: inputs[name] }, { vault: data, account, assets })).afterPosition;
      if (!isSecondary || !afterValues.max) afterValues.max = {};
      afterValues.max.boost = await getMaxBoost(afterValues, proxyAddress);
      afterValues.max.generate = getMaxBorrow(afterValues);
      break;
    }
    case 'payback': {
      afterValues = (await paybackAfterValues({ amount: inputs[name] }, { vault: data, account, assets })).afterPosition;
      if (!isSecondary || !afterValues.max) afterValues.max = {};
      afterValues.max.withdraw = getMaxWithdraw(afterValues);
      break;
    }
    case 'repay': {
      afterValues = (await repayAfterValues({ amount: inputs[name] }, { vault: data, proxyAddress })).afterPosition;
      if (!isSecondary || !afterValues.max) afterValues.max = {};
      if (name.split('-')[0] === 'repay') { // Don't calculate max withdraw and borrow when repay is additional action
        afterValues.max.withdraw = getMaxWithdraw(afterValues);
        afterValues.max.generate = getMaxBorrow(afterValues);
      }
      break;
    }
    case 'withdraw': {
      afterValues = (await withdrawAfterValues({ amount: inputs[name] }, { vault: data, account, assets })).afterPosition;
      // we send data instead of afterValues to getMaxPayback because we need withdraw + payback before
      if (!isSecondary || !afterValues.max) afterValues.max = {};
      afterValues.max.payback = getMaxPayback(data, assets.DAI.balance, false);
      afterValues.max.send = inputs[name];
      afterValues.max.sell = inputs[name];
      break;
    }
    case 'generate': {
      afterValues = (await generateAfterValues({ amount: inputs[name] }, { vault: data, account, assets })).afterPosition;
      if (!isSecondary || !afterValues.max) afterValues.max = {};
      afterValues.max.collateral = assets.ETH.balance;
      afterValues.max.send = inputs[name];
      afterValues.max.sell = inputs[name];
      break;
    }
    case 'boost': {
      afterValues = (await boostAfterValues({ amount: inputs[name] }, { vault: data, proxyAddress })).afterPosition;
      if (!isSecondary || !afterValues.max) afterValues.max = {};
      afterValues.max.collateral = assets.ETH.balance;
      afterValues.max.payback = getMaxPayback(afterValues, assets.DAI.balance, false);
      break;
    }
    default:
      afterValues = data;
      break;
  }
  return afterValues;
};

/**
 * Calculates the changed vault value
 *
 * @param amount {String}
 * @param type {String}
 * @return {Function}
 */
export const setAfterValue = (amount, type) => async (dispatch, getState) => {
  const reflexerManage = getState().reflexerManage;
  const contextAction = reflexerManage.selectedAction?.value;
  const additionalAction = reflexerManage.selectedAdditionalActions[contextAction]?.value;
  const flipActions = !reflexerManage.selectedAction?.goesFirst;
  const firstAction = flipActions ? additionalAction : contextAction;
  const secondAction = flipActions ? contextAction : additionalAction;
  const inputs = {
    ...getState().form[formName]?.values,
    // form.dashboardActions.values[type] propagation hasn't reached the reducer state yet,
    // so we replace it with the value passed via onChange
    [`${contextAction}-${type}`]: amount,
  };
  const firstLabel = `${contextAction}-${firstAction}`;
  const secondLabel = `${contextAction}-${secondAction}`;

  if (type === 'clear' || (!+inputs[firstLabel] && !+inputs[secondLabel])) {
    return dispatch({ type: rflxManageTypes.RFL_GET_AFTER_CDP_SUCCESS, payload: { afterCdp: null, afterType: '' } });
  }

  dispatch({ type: rflxManageTypes.RFL_GET_AFTER_CDP_REQUEST });

  try {
    const {
      maker: { proxyAddress },
      reflexer: { safes, selectedSafeId, graphData },
      general: { account },
      assets,
    } = getState();
    const safe = safes[selectedSafeId];

    const minRatio = graphData?.minRatio || 0;
    const maxRatio = graphData?.maxRatio || Infinity;
    const boostEnabled = graphData?.boostEnabled || false;
    const targetRatio = safe.ratio / 100;

    const payload = {
      afterType: contextAction,
      afterCdp: { afterType: `${contextAction}-${additionalAction}`, ...safe },
    };

    if (additionalAction === 'repay' && secondAction && inputs[secondLabel]) {
      const tmpAfter = await getAfterValue(secondAction, secondLabel, inputs, payload.afterCdp, assets, account, proxyAddress);
      const label = `${contextAction}-${additionalAction}`;
      inputs[label] = await getRepayAmount(tmpAfter, proxyAddress, targetRatio);
      dispatch(change(formName, label, inputs[label]));
    }
    if (firstAction && inputs[firstLabel]) {
      payload.afterCdp = await getAfterValue(firstAction, firstLabel, inputs, payload.afterCdp, assets, account, proxyAddress);
    }
    if (additionalAction === 'boost') {
      const label = `${contextAction}-${additionalAction}`;
      inputs[label] = await getBoostAmount(payload.afterCdp, proxyAddress, targetRatio);
      dispatch(change(formName, label, inputs[label]));
    }
    if (secondAction && inputs[secondLabel]) {
      payload.afterCdp = await getAfterValue(secondAction, secondLabel, inputs, payload.afterCdp, assets, account, proxyAddress, true);
    }

    if (payload.afterCdp && payload.afterCdp.ratio) {
      payload.ratioTooLow = parseFloat(payload.afterCdp.ratio) < minRatio;
      payload.ratioTooHigh = boostEnabled && parseFloat(payload.afterCdp.ratio) > maxRatio;
    }

    if (payload.afterCdp && payload.afterCdp.ratio && safe.isSubscribedToAutomation) {
      payload.afterCdp.disabledBecauseTooLowForRepay = parseFloat(payload.afterCdp.ratio) < ((safe.liqRatio * 100) + ALLOWED_PERCENT_OVER_LIQ_RATIO_MAKER);
    }

    dispatch({ type: rflxManageTypes.RFL_GET_AFTER_CDP_SUCCESS, payload });
  } catch (err) {
    dispatch({ type: rflxManageTypes.RFL_GET_AFTER_CDP_FAILURE, payload: err.message });
    captureException(err);
  }
};
