import React, { useState } from 'react';
import t from 'translate';
import PropTypes from 'prop-types';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ACCOUNT_TYPES, routeMeta } from '../../constants/general';
import { featureAvailable } from '../../services/utils';

import CompoundIcon from './CompoundIcon';
import MakerIcon from './MakerIcon';
import LiquityIcon from './LiquityIcon';
import AaveIcon from './AaveIcon';
import SmartSavingIcon from './SmartSavingsIcon';
import ExchangeIcon from './ExchangeIcon';
import PortfolioIcon from './PortfolioIcon';
import ShifterIcon from './ShifterIcon';
import WhatsNewIcon from './WhatsNewIcon';
import ReflexerIcon from './ReflexerIcon';
import RecipesIcon from './RecipesIcon';
import OutsideAlerter from '../OutsideAlerter/OutsideAlerter';
import withErrorFallback from '../ErrorFallback/ErrorFallback';
import LanguageSwitch from '../LanguageSwitch/LanguageSwitch';
import SimulationIcon from './SimulationIcon';
import ForkState from '../ForkState/ForkState';
import BridgeIcon from './BridgeIcon';

import './Sidebar.scss';

const Sidebar = ({ location, accountType, network }) => {
  const [checked, setChecked] = useState(false);

  const changeChecked = () => { setChecked(!checked); };

  const { name, icon, label } = routeMeta(location.pathname);

  return (
    <div className="vertical-header-wrapper">
      <OutsideAlerter onClickOutside={() => { setChecked(false); }} className={`header-inner-wrapper ${checked && 'checked'}`}>
        <Link className="logo-wrapper" to="/">
          {/* <img className="dfs-icon" src={logo} alt="" width="120px" height="40px" /> */}
        </Link>

        <div className="mobile-title-wrapper">
          { name }

          { label && <span>{label}</span>}
        </div>

        <input className="menu-btn" onChange={() => {}} type="checkbox" id="menu-btn" checked={checked} />
        <label className="menu-icon" htmlFor="menu-btn" onClick={changeChecked}><span className="navicon" /></label>

        <div className="links-wrapper">
          <div className="links-inner-wrapper Flex FlexColumn">
            <NavLink exact activeClassName="active" to="/" onClick={changeChecked}>
              <div className="nav-option portfolio-nav">
                <PortfolioIcon />
                <span className="route-name"> { t('pages.portfolio') } </span>
              </div>
            </NavLink>
            <NavLink activeClassName="active" to="/makerdao" onClick={changeChecked}>
              <div className={`nav-option maker-nav ${featureAvailable('maker', network).isAvailable ? '' : 'unavailable'}`}>
                <MakerIcon />
                <span className="route-name"> { t('pages.maker_dao') } </span>
              </div>
            </NavLink>
            <NavLink activeClassName="active" to="/compound" onClick={changeChecked}>
              <div className={`nav-option compound-nav ${featureAvailable('compound', network).isAvailable ? '' : 'unavailable'}`}>
                <CompoundIcon />
                <span className="route-name">{ t('pages.compound') } </span>
              </div>
            </NavLink>
            <NavLink activeClassName="active" to="/aave" onClick={changeChecked}>
              <div className={`nav-option aave-nav ${featureAvailable('aave', network).isAvailable ? '' : 'unavailable'}`}>
                <AaveIcon />
                <span className="route-name">{ t('pages.aave') } </span>
              </div>
            </NavLink>
            <NavLink activeClassName="active" to="/reflexer" onClick={changeChecked}>
              <div className={`nav-option reflexer-nav ${featureAvailable('reflexer', network).isAvailable ? '' : 'unavailable'}`}>
                <ReflexerIcon />
                <span className="route-name">{ t('pages.reflexer') } </span>
              </div>
            </NavLink>
            <NavLink activeClassName="active" to="/liquity" onClick={changeChecked}>
              <div className={`nav-option liquity-nav ${featureAvailable('liquity', network).isAvailable ? '' : 'unavailable'}`}>
                <LiquityIcon />
                <span className="route-name"> { t('pages.liquity') } </span>
              </div>
            </NavLink>
            <NavLink activeClassName="active" to="/smart-savings" onClick={changeChecked}>
              <div className={`nav-option smart-savings-nav ${featureAvailable('ss', network).isAvailable ? '' : 'unavailable'}`}>
                <SmartSavingIcon />
                <span className="route-name">{ t('pages.smart_savings') }</span>
              </div>
            </NavLink>
            <NavLink activeClassName="active" to="/exchange" onClick={changeChecked}>
              <div className={`nav-option exchange-nav ${featureAvailable('exchange', network).isAvailable ? '' : 'unavailable'}`}>
                <ExchangeIcon />
                <span className="route-name">{ t('pages.exchange') } </span>
              </div>
            </NavLink>
            <NavLink activeClassName="active" to="/shifter" onClick={changeChecked}>
              <div className={`nav-option shifter-nav ${featureAvailable('shifter', network).isAvailable ? '' : 'unavailable'}`}>
                <ShifterIcon />
                <span className="route-name">{ t('pages.shifter') } </span>
              </div>
            </NavLink>
            <NavLink activeClassName="active" to="/recipes" onClick={changeChecked}>
              <div className={`nav-option ${featureAvailable('recipes', network).isAvailable ? '' : 'unavailable'}`}>
                <RecipesIcon />
                <span className="route-name">{ t('pages.recipe_creator') }</span>
              </div>
            </NavLink>
            <NavLink activeClassName="active" to="/bridge" onClick={changeChecked}>
              <div className="nav-option">
                <BridgeIcon />
                <span className="route-name">{ t('pages.bridge') }</span>
              </div>
            </NavLink>

            <div className="spacer" />

            <a className={`${accountType === ACCOUNT_TYPES.fork && 'active'}`}>
              <div className={`simulation-wrapper nav-option ${accountType === ACCOUNT_TYPES.fork && 'active'}`}>
                <SimulationIcon />
                <ForkState />
              </div>
            </a>
            <NavLink className="whats-new-link" activeClassName="active" to="/whats-new" onClick={changeChecked}>
              <div className="nav-option whats-new-nav">
                <WhatsNewIcon />
                <span className="route-name">{ t('pages.whats_new') } </span>
              </div>
            </NavLink>
            <div className="lang">
              <LanguageSwitch />
            </div>
          </div>
        </div>
      </OutsideAlerter>
    </div>
  );
};

Sidebar.propTypes = {
  location: PropTypes.object.isRequired,
  accountType: PropTypes.string.isRequired,
  network: PropTypes.number.isRequired,
};

const mapStateToProps = ({ general }) => ({
  accountType: general.accountType,
  network: general.network,
});

const mapDispatchToProps = {};

export default withErrorFallback(connect(mapStateToProps, mapDispatchToProps)(withRouter(Sidebar)));
