/* eslint-disable */
import React from 'react';

export default function TrackAddressIcon(props) {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M7.75743 9.17158C6.96699 9.69511 6.0191 10 5 10C2.23858 10 0 7.76142 0 5C0 2.23858 2.23858 0 5 0C7.76142 0 10 2.23858 10 5C10 6.01907 9.69513 6.96693 9.17163 7.75735L12.0105 10.5962C12.401 10.9867 12.401 11.6199 12.0105 12.0104C11.62 12.4009 10.9868 12.4009 10.5963 12.0104L7.75743 9.17158ZM8 5C8 6.65685 6.65685 8 5 8C3.34315 8 2 6.65685 2 5C2 3.34315 3.34315 2 5 2C6.65685 2 8 3.34315 8 5Z" fill={props.fill || '#61717E'} />
    </svg>
  );
}
