import Dec from 'decimal.js';
import { getAssetInfo } from '@defisaver/tokens';
import * as compManageTypes from '../../actionTypes/compoundActionTypes/compoundManageActionTypes';
import { getMaxBoostUsd } from '../../services/moneymarketCommonService';
import { captureException } from '../../sentry';
import { getMaxBorrow, getMaxWithdraw } from '../../services/compoundServices/compoundManageService';
import { getAssetBalance } from '../../services/assetsService';
import { getAddressForWalletType } from '../../services/utils';
import { getDashboardInputs } from '../dashboardActions';

export const handleWbtcLegacy = (asset) => (asset === 'WBTC Legacy' ? 'WBTC' : asset);

/**
 * Handles redux actions for when the number of max amount of selected asset that can be borrowed is calculating
 *
 * @return {Function}
 */
export const getMaxBoostAction = () => async (dispatch, getState) => {
  const forAction = 'boost';
  dispatch({ type: compManageTypes.COMP_GET_MAX_VALUE_REQUEST, forAction });

  try {
    const {
      borrowedUsd, borrowLimitUsd, assetsData, usedAssets, selectedAdditionalActions, selectedAction,
    } = getState().compoundManage;

    const {
      firstAction,
      firstInputSelect,
      firstInputSecondSelect,
      secondInputSelect,
      secondInputSecondSelect,
    } = dispatch(getDashboardInputs(selectedAction, selectedAdditionalActions[selectedAction.value]));

    const borrowAsset = firstAction.value === forAction ? firstInputSelect?.value : secondInputSelect?.value;
    const supplyAsset = firstAction.value === forAction ? firstInputSecondSelect?.value : secondInputSecondSelect?.value;

    if (!borrowAsset || !supplyAsset) return dispatch({ type: compManageTypes.COMP_GET_MAX_VALUE_SUCCESS, forAction, payload: '0' });

    const supplyAssetData = assetsData[supplyAsset];
    const supplyUsedAssetData = usedAssets[supplyAsset] || {};

    // borrow limit after enabling supplyAsset as collateral
    const newBorrowLimitUsd = (supplyUsedAssetData.collateral)
      ? borrowLimitUsd
      : new Dec(borrowLimitUsd).plus(new Dec(supplyUsedAssetData?.suppliedUsd || '0').mul(supplyAssetData?.collateralFactor || '0')).toString();

    const flashLoanAvailable = true;

    let maxBoostUsd = flashLoanAvailable ?
      getMaxBoostUsd(supplyAssetData?.collateralFactor || '0', newBorrowLimitUsd, borrowedUsd)
      : newBorrowLimitUsd - borrowedUsd;

    const assetPrice = getState().assets[handleWbtcLegacy(borrowAsset)].compoundPrice;

    const borrowAssetData = assetsData[borrowAsset];
    let leftToBorrow;

    if (Number(borrowAssetData.borrowCap)) {
      leftToBorrow = new Dec(borrowAssetData.borrowCap).minus(borrowAssetData.totalBorrow).toString();
      if (leftToBorrow) {
        if (new Dec(leftToBorrow).lessThanOrEqualTo('0') || new Dec(maxBoostUsd).lessThanOrEqualTo('0')) maxBoostUsd = '0';
        else maxBoostUsd = Dec.min(new Dec(leftToBorrow).times(assetPrice).toString(), maxBoostUsd).toString();
      }
    }

    const payload = new Dec(maxBoostUsd).div(assetPrice).toDP(getAssetInfo(borrowAsset).decimals).toString();

    dispatch({ type: compManageTypes.COMP_GET_MAX_VALUE_SUCCESS, forAction, payload });
  } catch (err) {
    dispatch({ type: compManageTypes.COMP_GET_MAX_VALUE_FAILURE, forAction, payload: err.message });
    captureException(err);
  }
};

/**
 * Handles redux actions for when the number of max amount of selected asset that can be repaid is calculating
 *
 * @return {Function}
 */
export const getMaxRepayAction = () => async (dispatch, getState) => {
  const forAction = 'repay';
  dispatch({ type: compManageTypes.COMP_GET_MAX_VALUE_REQUEST, forAction });

  try {
    const {
      usedAssets, assetsData, borrowedUsd, borrowLimitUsd, selectedAction, selectedAdditionalActions,
    } = getState().compoundManage;

    const {
      firstAction,
      firstInputSelect,
      firstInputSecondSelect,
      secondInputSelect,
      secondInputSecondSelect,
    } = dispatch(getDashboardInputs(selectedAction, selectedAdditionalActions[selectedAction.value]));

    const collAsset = firstAction.value === forAction ? firstInputSelect?.value : secondInputSelect?.value;
    const debtAsset = firstAction.value === forAction ? firstInputSecondSelect?.value : secondInputSecondSelect?.value;

    let payload = '0';

    if (collAsset && debtAsset) {
      // const flashLoanAvailable = aaveCollateralAssets.find(token => token.underlyingAsset === collAsset);
      const flashLoanAvailable = true;
      const assetPrice = getState().assets[handleWbtcLegacy(collAsset)].compoundPrice;

      const totalDebtUsd = new Dec(usedAssets[debtAsset].borrowedUsd).mul(1.02).toString();
      const totalDebt = new Dec(totalDebtUsd).div(assetPrice).toDP(getAssetInfo(collAsset).decimals).toString();

      if (flashLoanAvailable) {
        payload = Dec.min(usedAssets[collAsset].supplied, totalDebt).toString();
      } else {
        const usedAssetData = usedAssets[collAsset];
        const assetData = assetsData[collAsset];
        const maxWithdraw = getMaxWithdraw(usedAssetData, assetPrice, assetData, borrowedUsd, borrowLimitUsd);

        payload = Dec.min(maxWithdraw, totalDebt).toString();
      }
    }

    dispatch({ type: compManageTypes.COMP_GET_MAX_VALUE_SUCCESS, forAction, payload });
  } catch (err) {
    dispatch({ type: compManageTypes.COMP_GET_MAX_VALUE_FAILURE, forAction, payload: err.message });
    captureException(err);
  }
};

/**
 * Handles redux actions for when the number of max amount of selected asset that can be withdrawn is calculating
 *
 * @return {Function}
 */
export const getMaxWithdrawAction = (forRepayCheck = false) => async (dispatch, getState) => {
  const forAction = 'withdraw';
  dispatch({ type: compManageTypes.COMP_GET_MAX_VALUE_REQUEST, forAction });

  const {
    usedAssets, assetsData, borrowedUsd, borrowLimitUsd, selectedAction, selectedAdditionalActions,
  } = getState().compoundManage;

  const {
    firstAction,
    firstInputSelect,
    secondInputSelect,
  } = dispatch(getDashboardInputs(selectedAction, selectedAdditionalActions[selectedAction.value]));

  let fromAsset = firstAction.value === forAction ? firstInputSelect?.value : secondInputSelect?.value;
  if (forRepayCheck && typeof forRepayCheck !== 'object') fromAsset = firstInputSelect?.value;

  try {
    let payload = '0';

    if (fromAsset) {
      const usedAssetData = usedAssets[fromAsset];
      const { compoundPrice } = getState().assets[handleWbtcLegacy(fromAsset)];
      const assetData = assetsData[fromAsset];
      const { isBorrowed, isSupplied } = usedAssetData;

      let ratio;
      const onlyOneAssetUsed = Object.keys(usedAssets).length === 1;
      if (isBorrowed && isSupplied && onlyOneAssetUsed) ratio = 1.00011;
      else ratio = 1.01;

      payload = getMaxWithdraw(usedAssetData, compoundPrice, assetData, borrowedUsd, borrowLimitUsd, ratio);
    }

    dispatch({ type: compManageTypes.COMP_GET_MAX_VALUE_SUCCESS, forAction, payload });
  } catch (err) {
    dispatch({ type: compManageTypes.COMP_GET_MAX_VALUE_FAILURE, forAction, payload: err.message });
    captureException(err);
  }
};

/**
 * Handles redux actions for when the number of max amount of selected asset that can be supplied is calculating
 *
 * @return {Function}
 */
export const getMaxSupplyAction = () => async (dispatch, getState) => {
  const forAction = 'collateral';
  dispatch({ type: compManageTypes.COMP_GET_MAX_VALUE_REQUEST, forAction });

  try {
    const state = getState();
    const address = state.general.account;

    const { selectedAction, selectedAdditionalActions } = state.compoundManage;
    const {
      firstAction,
      firstInputSelect,
      secondInputSelect,
    } = dispatch(getDashboardInputs(selectedAction, selectedAdditionalActions[selectedAction.value]));

    let payload = '0';

    const asset = firstAction.value === forAction ? firstInputSelect?.value : secondInputSelect?.value;

    if (asset) payload = await getAssetBalance(asset, address);

    dispatch({ type: compManageTypes.COMP_GET_MAX_VALUE_SUCCESS, forAction, payload });
  } catch (err) {
    dispatch({ type: compManageTypes.COMP_GET_MAX_VALUE_FAILURE, forAction, payload: err.message });
    captureException(err);
  }
};

/**
 * Handles redux actions for when the number of max amount of selected asset that can be borrowed is calculating
 *
 * @return {Function}
 */
export const getMaxBorrowAction = (forBoostCheck = false) => (dispatch, getState) => {
  const forAction = 'borrow';
  dispatch({ type: compManageTypes.COMP_GET_MAX_VALUE_REQUEST, forAction });

  try {
    const address = getAddressForWalletType(getState);
    const {
      borrowLimitUsd, borrowedUsd, selectedAction, selectedAdditionalActions,
    } = getState().compoundManage;

    const {
      firstAction,
      firstInputSelect,
      secondInputSelect,
    } = dispatch(getDashboardInputs(selectedAction, selectedAdditionalActions[selectedAction.value]));

    let asset = firstAction.value === forAction ? firstInputSelect?.value : secondInputSelect?.value;
    if (forBoostCheck && typeof forBoostCheck !== 'object') asset = firstInputSelect?.value;

    let payload = '0';

    if (asset) {
      const { compoundPrice } = getState().assets[handleWbtcLegacy(asset)];
      const { assetsData } = getState().compoundManage;
      const leftToBorrowUsd = new Dec(borrowLimitUsd).minus(borrowedUsd).toString();

      const fromAssetData = assetsData[asset];
      let leftToBorrowGlobally;
      if (Number(fromAssetData.borrowCap)) {
        leftToBorrowGlobally = new Dec(fromAssetData.borrowCap).minus(fromAssetData.totalBorrow).toString();
      }

      payload = getMaxBorrow(address, asset, compoundPrice, 1, leftToBorrowUsd, leftToBorrowGlobally);
    }

    dispatch({ type: compManageTypes.COMP_GET_MAX_VALUE_SUCCESS, forAction, payload });
  } catch (err) {
    dispatch({ type: compManageTypes.COMP_GET_MAX_VALUE_FAILURE, forAction, payload: err.message });
    captureException(err);
  }
};

/**
 * Handles redux actions for when the number of max amount of selected asset that can be repaid is calculating
 *
 * @return {Function}
 */
export const getMaxPaybackAction = () => async (dispatch, getState) => {
  const forAction = 'payback';
  dispatch({ type: compManageTypes.COMP_GET_MAX_VALUE_REQUEST, forAction });

  try {
    const { usedAssets, selectedAction, selectedAdditionalActions } = getState().compoundManage;
    const {
      firstAction,
      firstInputSelect,
      secondInputSelect,
    } = dispatch(getDashboardInputs(selectedAction, selectedAdditionalActions[selectedAction.value]));

    const fromAsset = firstAction.value === forAction ? firstInputSelect?.value : secondInputSelect?.value;

    let payload = '0';

    if (fromAsset) {
      const { borrowed } = usedAssets[fromAsset];
      const assetBalance = await getAssetBalance(fromAsset, getState().general.account);

      payload = new Dec(borrowed).gt(assetBalance) ? assetBalance : borrowed;
    }

    dispatch({ type: compManageTypes.COMP_GET_MAX_VALUE_SUCCESS, forAction, payload });
  } catch (err) {
    dispatch({ type: compManageTypes.COMP_GET_MAX_VALUE_FAILURE, forAction, payload: err.message });
    captureException(err);
  }
};
