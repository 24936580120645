import React from 'react';

export default function WhatsNewIcon(props) {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="30" height="30" rx="3" fill="#34765B" />
      <path d="M13.5726 18.3271C13.5726 19.2334 12.8486 20.1484 11.9555 20.1484C11.0625 20.1484 10.3385 19.4137 10.3385 18.5073C10.3385 18.0868 10.5261 17.6715 10.7824 17.3811M8.46774 17.3811V12.6831H11.1946L21.4677 8.46774V21.4677L11.1946 17.3811H8.46774Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
