import React from 'react';

export default function CompoundIcon(props) {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="30" height="30" rx="3" fill="#61717E" />
      <path fillRule="evenodd" clipRule="evenodd" d="M8.32625 20.3079C7.8531 20.0185 7.56372 19.5031 7.56372 18.9497V15.8594C7.56372 15.7411 7.5954 15.6271 7.65455 15.5257C7.83831 15.2089 8.24598 15.1012 8.56282 15.287L15.5375 19.3531C15.9452 19.5918 16.1965 20.027 16.1965 20.5001V23.7023C16.1965 23.848 16.1564 23.9938 16.0804 24.1184C15.8501 24.4944 15.3601 24.6127 14.9841 24.3824L8.32625 20.3079ZM18.7228 14.44C19.1304 14.6787 19.3818 15.1139 19.3818 15.587V22.0843C19.3818 22.2765 19.2783 22.4539 19.1114 22.5469L17.5842 23.4066C17.5652 23.4171 17.5441 23.4256 17.523 23.4319V19.8242C17.523 19.3573 17.278 18.9243 16.8767 18.6835L10.7511 15.0188V10.9463C10.7511 10.8281 10.7828 10.714 10.8419 10.6126C11.0257 10.2958 11.4334 10.188 11.7502 10.3739L18.7228 14.44ZM21.775 9.64097C22.1848 9.87754 22.4361 10.3169 22.4361 10.79V20.2805C22.4361 20.4747 22.3284 20.6543 22.1573 20.7472L20.7104 21.5288V14.9216C20.7104 14.4548 20.4654 14.0239 20.0662 13.7831L13.8054 10.0275V6.16419C13.8054 6.04591 13.8371 5.93185 13.8942 5.83046C14.0779 5.51362 14.4856 5.40589 14.8024 5.58966L21.775 9.64097Z" fill="#626D75" />
    </svg>
  );
}
