import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RefreshButton from '../../RefreshButton/RefreshButton';
import { changeSelectedCdp } from '../../../actions/actionsInModals';
import { getCdpProfitability } from '../../../actions/makerActions/makerActions';

const MakerRefreshButton = ({ cdp, changeSelectedCdp, getCdpProfitability }) => (
  <RefreshButton
    refresh={async () => {
      await changeSelectedCdp({ value: cdp });
      await getCdpProfitability(cdp.id);
    }}
    lastUpdated={cdp?.lastUpdated}
  />
);

MakerRefreshButton.propTypes = {
  cdp: PropTypes.object,
  changeSelectedCdp: PropTypes.func.isRequired,
  getCdpProfitability: PropTypes.func.isRequired,
};

const mapStateToProps = ({ maker }) => ({
  cdp: maker.cdp,
});
const mapDispatchToProps = {
  changeSelectedCdp,
  getCdpProfitability,
};

export default connect(mapStateToProps, mapDispatchToProps)(MakerRefreshButton);
