/**
 * Higher order function for initiating block name in BEM.
 * Returns another function for adding BEM elements and modifiers to the CSS class.
 *
 * Usage:
 * - Initiate block by assigning it to const blockClass = bemifyClass('block-name');
 * - Add it to className={blockClass('elementName', array ? ['mod1', 'mod2'] : 'mod')}
 *
 * @param {string} block
 * @return {function(element: string=, modifier: string|string[]=): string}
 */
export const bemifyClass = (block) => (element, modifier) => {
  let bemifiedClassName = `${block}`;
  if (element) bemifiedClassName = `${bemifiedClassName}__${element}`;
  if (modifier) {
    if (Array.isArray(modifier)) {
      let classWithModifiers = '';
      modifier.forEach((mod, i) => {
        classWithModifiers = i === 0 ? `${bemifiedClassName}--${mod}` : `${classWithModifiers} ${bemifiedClassName}--${mod}`;
      });
      bemifiedClassName = classWithModifiers;
    } else {
      bemifiedClassName = `${bemifiedClassName}--${modifier}`;
    }
  }
  return bemifiedClassName;
};

export const gridBlock = bemifyClass('grid');
export const flexBlock = bemifyClass('flex');
