import React from 'react';
import PropTypes from 'prop-types';
import Arrow from '../../../Decorative/Arrow';
import { formatNumber } from '../../../../services/utils';

const SwapToken = ({ token, amount }) => (
  <div className="swaps-made-swap-token">
    <div className="swaps-made-swap-token-name-wrapper">
      <div className="swaps-made-swap-token-icon">
        <token.icon width="23" height="23" />
      </div>
      <span>{token.symbol}</span>
    </div>
    <span>{formatNumber(amount)}</span>
  </div>
);
SwapToken.propTypes = {
  token: PropTypes.object.isRequired,
  amount: PropTypes.string.isRequired,
};
const SwapsMade = ({ swaps }) => (
  <>
    <div className="recipe-success-modal-subtitle">Swaps made:</div>
    <div className="swaps-made-wrapper">
      {swaps.map(({
        buyToken, sellToken, buyAmount, sellAmount, wrapper,
      }) => (
        <div key={wrapper} className="swaps-made-swap-wrapper">
          <SwapToken amount={sellAmount} token={sellToken} />
          <Arrow to="right" size="14" className="swaps-made-arrow" />
          <SwapToken amount={buyAmount} token={buyToken} />
        </div>
      ))}
    </div>
  </>
);
SwapsMade.propTypes = {
  swaps: PropTypes.array.isRequired,
};
export default SwapsMade;
