import React from 'react';
import PropTypes from 'prop-types';

const DeadlineActionField = ({
  type, i, handleChange, actualValue,
}) => (
  <div className="slippage-item">
    <div className="value">
      <input
        className="form-item"
        autoComplete="off"
        placeholder="0"
        id={type.name}
        type="text"
        value={actualValue}
        onChange={(e) => {
          handleChange(e, i);
        }}
      />
      <span className="percentage">Min</span>
    </div>
  </div>
);

DeadlineActionField.propTypes = {
  handleChange: PropTypes.func.isRequired,
  type: PropTypes.object.isRequired,
  i: PropTypes.number.isRequired,
  actualValue: PropTypes.string.isRequired,
};

export default DeadlineActionField;
