import dfs, { Recipe } from '@defisaver/sdk';
import Dec from 'decimal.js';
import { isWalletTypeProxy } from '../utils';
import { MAXUINT, ZERO_ADDRESS } from '../../constants/general';
import {
  LiquityLQTYStakingAddress,
  LiquityLQTYStakingContract,
  LiquityStabilityPoolAddress,
  LiquityStabilityPoolContract,
} from '../contractRegistryService';
import { callRecipeViaProxy } from '../contractCallService';
import callTx from '../txService';
import { aggregate, ethToWei, weiToEth } from '../ethService';
import { findInsertPosition } from './liquityService';

export const stakeLQTY = async (getState, sendTxFunc, lqtyAmount) => {
  const { general: { account, accountType, walletType }, maker: { proxyAddress } } = getState();
  const isProxy = isWalletTypeProxy(walletType);
  const lqtyAmountWei = ethToWei(lqtyAmount);
  const lqtyStakingContract = LiquityLQTYStakingContract();

  if (isProxy) {
    const actions = [
      new dfs.actions.liquity.LiquityStakeAction(lqtyAmountWei, account, proxyAddress, account),
      new dfs.actions.basic.UnwrapEthAction(MAXUINT, account),
    ];
    const recipe = new Recipe('LiquityStakeLUSD', actions);
    console.log(recipe);
    return callRecipeViaProxy(accountType, sendTxFunc, proxyAddress, account, recipe);
  }
  const params = [lqtyAmountWei];
  return callTx(accountType, 'not used', sendTxFunc, lqtyStakingContract, 'stake', params, { from: account }, 'stake');
};

export const unstakeLQTY = async (getState, sendTxFunc, lqtyAmount) => {
  const { general: { account, accountType, walletType }, maker: { proxyAddress } } = getState();
  const isProxy = isWalletTypeProxy(walletType);
  const lqtyAmountWei = ethToWei(lqtyAmount);
  const lqtyStakingContract = LiquityLQTYStakingContract();

  if (isProxy) {
    const actions = [
      new dfs.actions.liquity.LiquityUnstakeAction(lqtyAmountWei, account, proxyAddress, account),
      new dfs.actions.basic.UnwrapEthAction(MAXUINT, account),
    ];
    const recipe = new Recipe('LiquityStakeLUSD', actions);
    console.log(recipe);
    return callRecipeViaProxy(accountType, sendTxFunc, proxyAddress, account, recipe);
  }
  const params = [lqtyAmountWei];
  return callTx(accountType, 'not used', sendTxFunc, lqtyStakingContract, 'unstake', params, { from: account }, 'unstake');
};

export const stakeLUSD = async (getState, sendTxFunc, lusdAmount) => {
  const { general: { account, accountType, walletType }, maker: { proxyAddress } } = getState();
  const isProxy = isWalletTypeProxy(walletType);
  const lusdAmountWei = ethToWei(lusdAmount);
  const stabilityPoolContract = LiquityStabilityPoolContract();

  if (isProxy) {
    const actions = [
      new dfs.actions.liquity.LiquitySPDepositAction(lusdAmountWei, account, proxyAddress, account),
      new dfs.actions.basic.UnwrapEthAction(MAXUINT, account),
    ];
    const recipe = new Recipe('LiquityStakeLUSD', actions);
    console.log(recipe);
    return callRecipeViaProxy(accountType, sendTxFunc, proxyAddress, account, recipe);
  }
  const params = [lusdAmountWei, ZERO_ADDRESS];
  return callTx(accountType, 'not used', sendTxFunc, stabilityPoolContract, 'provideToSP', params, { from: account }, 'provideToSP');
};

export const unstakeLUSD = async (getState, sendTxFunc, lusdAmount) => {
  const { general: { account, accountType, walletType }, maker: { proxyAddress } } = getState();
  const isProxy = isWalletTypeProxy(walletType);
  const lusdAmountWei = ethToWei(lusdAmount);
  const stabilityPoolContract = LiquityStabilityPoolContract();

  if (isProxy) {
    const actions = [
      new dfs.actions.liquity.LiquitySPWithdrawAction(lusdAmountWei, account, proxyAddress, account),
      new dfs.actions.basic.UnwrapEthAction(MAXUINT, account),
    ];
    const recipe = new Recipe('LiquityUnstakeLUSD', actions);
    console.log(recipe);
    return callRecipeViaProxy(accountType, sendTxFunc, proxyAddress, account, recipe);
  }
  const params = [lusdAmountWei];
  return callTx(accountType, 'not used', sendTxFunc, stabilityPoolContract, 'withdrawFromSP', params, { from: account }, 'withdrawFromSP');
};

/**
 *
 * @param getState
 * @param sendTxFunc
 * @param trove
 * @param withdrawAmount
 * @return {Promise|Promise<*>}
 */
export const withdrawETHGainToTrove = async (getState, sendTxFunc, trove, withdrawAmount) => {
  const { general: { account, accountType, walletType }, maker: { proxyAddress } } = getState();
  const isProxy = isWalletTypeProxy(walletType);
  const stabilityPoolContract = LiquityStabilityPoolContract();
  const collateralAmountWei = ethToWei(trove.collateral);
  const debtAmountWei = ethToWei(trove.debtInAsset);
  const withdrawAmountWei = ethToWei(withdrawAmount);

  const newCollAmountWei = new Dec(collateralAmountWei).minus(withdrawAmountWei).toString();
  const { upperHint, lowerHint } = await findInsertPosition(newCollAmountWei, debtAmountWei, isProxy ? proxyAddress : account);
  if (isProxy) {
    const actions = [
      new dfs.actions.liquity.LiquityEthGainToTroveAction(account, upperHint, lowerHint),
    ];
    const recipe = new Recipe('LiquityWithdrawETHGainRecipe', actions);
    console.log(recipe);
    return callRecipeViaProxy(accountType, sendTxFunc, proxyAddress, account, recipe);
  }
  const params = [upperHint, lowerHint];
  return callTx(accountType, 'not used', sendTxFunc, stabilityPoolContract, 'withdrawETHGainToTrove', params, { from: account }, 'withdrawETHGainToTrove');
};

export const getStakedBalancesAndRewards = async (addresses) => {
  const _addresses = addresses.filter(i => i);
  const addressesObject = _addresses.filter(i => i).map((address, index) => ([
    {
      target: LiquityLQTYStakingAddress,
      call: ['stakes(address)(uint256)', address],
      returns: [
        [`stakedLQTY${index}`, val => weiToEth(val.toString())],
      ],
    },
    {
      target: LiquityLQTYStakingAddress,
      call: ['getPendingETHGain(address)(uint256)', address],
      returns: [
        [`rewardETH${index}`, val => weiToEth(val.toString())],
      ],
    },
    {
      target: LiquityLQTYStakingAddress,
      call: ['getPendingLUSDGain(address)(uint256)', address],
      returns: [
        [`rewardLUSD${index}`, val => weiToEth(val.toString())],
      ],
    },
    {
      target: LiquityStabilityPoolAddress,
      call: ['getDepositorETHGain(address)(uint256)', address],
      returns: [
        [`stabilityRewardETH${index}`, val => weiToEth(val.toString())],
      ],
    },
    {
      target: LiquityStabilityPoolAddress,
      call: ['getDepositorLQTYGain(address)(uint256)', address],
      returns: [
        [`stabilityRewardLQTY${index}`, val => weiToEth(val.toString())],
      ],
    },
    {
      target: LiquityStabilityPoolAddress,
      call: ['getCompoundedLUSDDeposit(address)(uint256)', address],
      returns: [
        [`stakedLUSD${index}`, val => weiToEth(val.toString())],
      ],
    },
    {
      target: LiquityStabilityPoolAddress,
      call: ['getTotalLUSDDeposits()(uint256)'],
      returns: [
        [`totalLUSDDeposited${index}`, val => weiToEth(val.toString())],
      ],
    },
    {
      target: LiquityLQTYStakingAddress,
      call: ['totalLQTYStaked()(uint256)'],
      returns: [
        [`totalLQTYStaked${index}`, val => weiToEth(val.toString())],
      ],
    },
  ]
  )).flat();

  const res = await aggregate(addressesObject);
  const { results: { transformed } } = res;

  return _addresses.map((a, index) => ({
    totalLUSDDeposited: transformed[`totalLUSDDeposited${index}`],
    totalLQTYStaked: transformed[`totalLQTYStaked${index}`],
    stakedLQTYBalance: transformed[`stakedLQTY${index}`],
    stakedLUSDBalance: transformed[`stakedLUSD${index}`],
    rewardETH: transformed[`rewardETH${index}`],
    rewardLUSD: transformed[`rewardLUSD${index}`],
    stabilityRewardETH: transformed[`stabilityRewardETH${index}`],
    stabilityRewardLQTY: transformed[`stabilityRewardLQTY${index}`],
    showStakingBalances: !!(
      +transformed[`stakedLQTY${index}`] || +transformed[`stakedLUSD${index}`]
      || +transformed[`rewardETH${index}`] || +transformed[`rewardLUSD${index}`]
      || +transformed[`stabilityRewardETH${index}`] || +transformed[`stabilityRewardLQTY${index}`]),
  }));
};
