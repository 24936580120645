export const FETCH_COMPOUND_MANAGE_DATA_REQUEST = 'FETCH_COMPOUND_MANAGE_DATA_REQUEST';
export const FETCH_COMPOUND_MANAGE_DATA_SUCCESS = 'FETCH_COMPOUND_MANAGE_DATA_SUCCESS';
export const FETCH_COMPOUND_MANAGE_DATA_FAILURE = 'FETCH_COMPOUND_MANAGE_DATA_FAILURE';

export const FETCH_COMPOUND_ASSETS_DATA_REQUEST = 'FETCH_COMPOUND_ASSETS_DATA_REQUEST';
export const FETCH_COMPOUND_ASSETS_DATA_SUCCESS = 'FETCH_COMPOUND_ASSETS_DATA_SUCCESS';
export const FETCH_COMPOUND_ASSETS_DATA_FAILURE = 'FETCH_COMPOUND_ASSETS_DATA_FAILURE';

export const FETCH_COMPOUND_ACCOUNT_DATA_REQUEST = 'FETCH_COMPOUND_ACCOUNT_DATA_REQUEST';
export const FETCH_COMPOUND_ACCOUNT_DATA_SUCCESS = 'FETCH_COMPOUND_ACCOUNT_DATA_SUCCESS';
export const FETCH_COMPOUND_ACCOUNT_DATA_FAILURE = 'FETCH_COMPOUND_ACCOUNT_DATA_FAILURE';

export const GET_COMPOUND_AFTER_VALUE_REQUEST = 'GET_COMPOUND_AFTER_VALUE_REQUEST';
export const GET_COMPOUND_AFTER_VALUE_SUCCESS = 'GET_COMPOUND_AFTER_VALUE_SUCCESS';
export const GET_COMPOUND_AFTER_VALUE_FAILURE = 'GET_COMPOUND_AFTER_VALUE_FAILURE';

export const ENABLE_COMPOUND_ASSET_AS_COLLATERAL_REQUEST = 'ENABLE_COMPOUND_ASSET_AS_COLLATERAL_REQUEST';
export const ENABLE_COMPOUND_ASSET_AS_COLLATERAL_SUCCESS = 'ENABLE_COMPOUND_ASSET_AS_COLLATERAL_SUCCESS';
export const ENABLE_COMPOUND_ASSET_AS_COLLATERAL_FAILURE = 'ENABLE_COMPOUND_ASSET_AS_COLLATERAL_FAILURE';

export const DISABLE_COMPOUND_ASSET_AS_COLLATERAL_REQUEST = 'DISABLE_COMPOUND_ASSET_AS_COLLATERAL_REQUEST';
export const DISABLE_COMPOUND_ASSET_AS_COLLATERAL_SUCCESS = 'DISABLE_COMPOUND_ASSET_AS_COLLATERAL_SUCCESS';
export const DISABLE_COMPOUND_ASSET_AS_COLLATERAL_FAILURE = 'DISABLE_COMPOUND_ASSET_AS_COLLATERAL_FAILURE';

export const COMPOUND_SUPPLY_REQUEST = 'COMPOUND_SUPPLY_REQUEST';
export const COMPOUND_SUPPLY_SUCCESS = 'COMPOUND_SUPPLY_SUCCESS';
export const COMPOUND_SUPPLY_FAILURE = 'COMPOUND_SUPPLY_FAILURE';

export const COMPOUND_WITHDRAW_REQUEST = 'COMPOUND_WITHDRAW_REQUEST';
export const COMPOUND_WITHDRAW_SUCCESS = 'COMPOUND_WITHDRAW_SUCCESS';
export const COMPOUND_WITHDRAW_FAILURE = 'COMPOUND_WITHDRAW_FAILURE';

export const COMPOUND_BORROW_REQUEST = 'COMPOUND_BORROW_REQUEST';
export const COMPOUND_BORROW_SUCCESS = 'COMPOUND_BORROW_SUCCESS';
export const COMPOUND_BORROW_FAILURE = 'COMPOUND_BORROW_FAILURE';

export const COMP_GET_MAX_VALUE_REQUEST = 'COMP_GET_MAX_VALUE_REQUEST';
export const COMP_GET_MAX_VALUE_SUCCESS = 'COMP_GET_MAX_VALUE_SUCCESS';
export const COMP_GET_MAX_VALUE_FAILURE = 'COMP_GET_MAX_VALUE_FAILURE';

export const COMPOUND_PAYBACK_REQUEST = 'COMPOUND_PAYBACK_REQUEST';
export const COMPOUND_PAYBACK_SUCCESS = 'COMPOUND_PAYBACK_SUCCESS';
export const COMPOUND_PAYBACK_FAILURE = 'COMPOUND_PAYBACK_FAILURE';

export const GET_BOOST_MODAL_DATA_REQUEST = 'GET_BOOST_MODAL_DATA_REQUEST';
export const GET_BOOST_MODAL_DATA_SUCCESS = 'GET_BOOST_MODAL_DATA_SUCCESS';
export const GET_BOOST_MODAL_DATA_FAILURE = 'GET_BOOST_MODAL_DATA_FAILURE';
export const RESET_BOOST_MODAL = 'RESET_BOOST_MODAL';

export const COMPOUND_BOOST_REQUEST = 'COMPOUND_BOOST_REQUEST';
export const COMPOUND_BOOST_SUCCESS = 'COMPOUND_BOOST_SUCCESS';
export const COMPOUND_BOOST_FAILURE = 'COMPOUND_BOOST_FAILURE';

export const GET_REPAY_MODAL_DATA_FAILURE = 'GET_REPAY_MODAL_DATA_FAILURE';
export const GET_REPAY_MODAL_DATA_REQUEST = 'GET_REPAY_MODAL_DATA_REQUEST';
export const GET_REPAY_MODAL_DATA_SUCCESS = 'GET_REPAY_MODAL_DATA_SUCCESS';
export const RESET_REPAY_MODAL = 'RESET_REPAY_MODAL';

export const COMPOUND_REPAY_REQUEST = 'COMPOUND_REPAY_REQUEST';
export const COMPOUND_REPAY_SUCCESS = 'COMPOUND_REPAY_SUCCESS';
export const COMPOUND_REPAY_FAILURE = 'COMPOUND_REPAY_FAILURE';

export const COMPOUND_MANAGE_SET_ACTIVE_TAB = 'COMPOUND_MANAGE_SET_ACTIVE_TAB';

export const COMPOUND_MANAGE_SET_ACTION_SELECT_VALUES = 'COMPOUND_MANAGE_SET_ACTION_SELECT_VALUES';

export const COMP_BALANCE_REQUEST = 'COMP_BALANCE_REQUEST';
export const COMP_BALANCE_SUCCESS = 'COMP_BALANCE_SUCCESS';
export const COMP_BALANCE_FAILURE = 'COMP_BALANCE_FAILURE';

export const FETCH_COMPOUND_PROXY_DATA_REQUEST = 'FETCH_COMPOUND_PROXY_DATA_REQUEST';
export const FETCH_COMPOUND_PROXY_DATA_SUCCESS = 'FETCH_COMPOUND_PROXY_DATA_SUCCESS';
export const FETCH_COMPOUND_PROXY_DATA_FAILURE = 'FETCH_COMPOUND_PROXY_DATA_FAILURE';

export const COMP_SET_DASHBOARD_ACTION = 'COMP_SET_DASHBOARD_ACTION';
export const COMP_SET_ADDITIONAL_DASHBOARD_ACTION = 'COMP_SET_ADDITIONAL_DASHBOARD_ACTION';

export const COMP_ACTION_EXEC_REQUEST = 'COMP_ACTION_EXEC_REQUEST';
export const COMP_ACTION_EXEC_SUCCESS = 'COMP_ACTION_EXEC_SUCCESS';
export const COMP_ACTION_EXEC_FAILURE = 'COMP_ACTION_EXEC_FAILURE';
