import {
  CET_CLOSE_CDP_DATA_REQUEST,
  CET_CLOSE_CDP_DATA_SUCCESS,
  CET_CLOSE_CDP_DATA_FAILURE,
  RECLAIM_COLLATERAL_REQUEST,
  RECLAIM_COLLATERAL_SUCCESS,
  RECLAIM_COLLATERAL_FAILURE,
} from 'actionTypes/makerActionTypes/makerManageActionTypes/makerManageMcdActionTypes';

const INITIAL_STATE = {
  gettingCloseCdpData: false,
  gettingCloseCdpDataError: '',
  closeData: null,

  reclaimingCollateral: false,
  reclaimingCollateralError: '',
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case CET_CLOSE_CDP_DATA_REQUEST:
      return { ...state, gettingCloseCdpData: true, gettingCloseCdpDataError: '' };

    case CET_CLOSE_CDP_DATA_SUCCESS:
      return {
        ...state,
        gettingCloseCdpData: false,
        gettingCloseCdpDataError: '',
        closeData: payload,
      };

    case CET_CLOSE_CDP_DATA_FAILURE:
      return {
        ...state,
        gettingCloseCdpData: false,
        gettingCloseCdpDataError: payload,
      };

    case RECLAIM_COLLATERAL_REQUEST:
      return { ...state, reclaimingCollateral: true, reclaimingCollateralError: '' };

    case RECLAIM_COLLATERAL_SUCCESS:
      return {
        ...state,
        reclaimingCollateral: false,
        reclaimingCollateralError: '',
      };

    case RECLAIM_COLLATERAL_FAILURE:
      return {
        ...state,
        reclaimingCollateral: false,
        reclaimingCollateralError: payload,
      };
    default:
      return state;
  }
};
