import {
  CREATE_COMPOUND_REQUEST,
  CREATE_COMPOUND_SUCCESS,
  CREATE_COMPOUND_FAILURE,
  CREATE_COMPOUND_CALCULATING_VALUES,
  SET_CREATE_COMPOUND_CALC_VALUES,
  RESET_CREATE_COMPOUND_FORM,
} from '../../actionTypes/compoundActionTypes/compoundCreateActionTypes';

const INITIAL_STATE = {
  creatingCompound: false,
  creatingCompoundError: '',
  calculating: false,

  newNetApy: '0',
  newSafetyRatio: '0',
  usedAssetsChanged: {},
  afterAssets: {},
  newSupplied: '0',
  newBorrowed: '0',
  changedStats: false,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case CREATE_COMPOUND_REQUEST:
      return { ...state, creatingCompound: true, creatingCompoundError: '' };
    case CREATE_COMPOUND_SUCCESS:
      return { ...state, creatingCompound: false, creatingCompoundError: '' };
    case CREATE_COMPOUND_FAILURE:
      return { ...state, creatingCompound: false, creatingCompoundError: payload };
    case CREATE_COMPOUND_CALCULATING_VALUES:
      return { ...state, calculating: true };
    case SET_CREATE_COMPOUND_CALC_VALUES:
      return {
        ...state,
        newNetApy: payload.netApy,
        newSafetyRatio: payload.ratio,
        usedAssetsChanged: payload.usedAssetsChanged,
        afterAssets: payload.afterAssets,
        newSupplied: payload.suppliedUsd,
        newBorrowed: payload.borrowedUsd,
        changedStats: true,
        calculating: false,
      };
    case RESET_CREATE_COMPOUND_FORM:
      return {
        ...state,
        creatingCompound: false,
        creatingCompoundError: '',
        newNetApy: '0',
        newSafetyRatio: '0',
        usedAssetsChanged: {},
        afterAssets: {},
        newSupplied: '0',
        newBorrowed: '0',
        changedStats: false,
        calculating: false,
      };
    default:
      return state;
  }
};
