import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * Component that alerts if you click outside of it
 */
class OutsideAlerter extends Component {
  constructor(props) {
    super(props);

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleMouseDownOutside = this.handleMouseDownOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
    document.addEventListener('mousedown', this.handleMouseDownOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
    document.removeEventListener('mousedown', this.handleMouseDownOutside);
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.onClickOutside();
    }
  }

  handleMouseDownOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.onMousedownOutside();
    }
  }

  render() {
    return (
      <div ref={this.setWrapperRef} className={this.props.className} onClick={this.props.handleClick}>
        {this.props.children}
      </div>
    );
  }
}

OutsideAlerter.defaultProps = {
  className: '',
  handleClick: () => {},
  onClickOutside: () => {},
  onMousedownOutside: () => {},
};

OutsideAlerter.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  onClickOutside: PropTypes.func,
  onMousedownOutside: PropTypes.func,
  handleClick: PropTypes.func,
  className: PropTypes.string,
};

export default OutsideAlerter;
