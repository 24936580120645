import Dec from 'decimal.js';
import { getAssetInfo } from '@defisaver/tokens';
import flatMap from 'lodash/flatMap';
import {
  computePoolAddress, encodeRouteToPath, Pool, Route, Position,
  priceToClosestTick, tickToPrice, nearestUsableTick, TICK_SPACINGS, TickMath,
} from '@uniswap/v3-sdk';
import {
  Ether, Token, CurrencyAmount, WETH9, Percent, Price,
} from '@uniswap/sdk-core';
import callTx from './txService';
import { aggregate, ethToWei } from './ethService';
import {
  UniV3NonfungiblePositionManagerAddress,
  UniV3NonfungiblePositionManagerContract,
  UniV3FactoryAddress,
} from './contractRegistryService';
import { captureException } from '../sentry';
import { IUniswapV3PoolState, UniswapQuoter } from '../config/config.json';
import { multicall } from './multicallService';
import { getWeiAmountForDecimals } from './utils';
import { EMPTY_ACCOUNT, MAXUINT128 } from '../constants/general';
import { getERC20TokenData } from './erc20Service';

const V3_CORE_FACTORY_ADDRESS = '0x1F98431c8aD98523631AE4a59f267346ea31F984';

const USDC_TOKEN = chainId => new Token(chainId, window._web3.utils.toChecksumAddress(getAssetInfo('USDC').address), getAssetInfo('USDC').decimals, getAssetInfo('USDC').symbol, getAssetInfo('USDC').name);
const DAI_TOKEN = chainId => new Token(chainId, window._web3.utils.toChecksumAddress(getAssetInfo('DAI').address), getAssetInfo('DAI').decimals, getAssetInfo('DAI').symbol, getAssetInfo('DAI').name);
const WBTC_TOKEN = chainId => new Token(chainId, window._web3.utils.toChecksumAddress(getAssetInfo('WBTC').address), getAssetInfo('WBTC').decimals, getAssetInfo('WBTC').symbol, getAssetInfo('WBTC').name);

const BASES_TO_CHECK_TRADES_AGAINST = chainId => [WETH9[chainId], DAI_TOKEN(chainId), USDC_TOKEN(chainId), WBTC_TOKEN(chainId)];
const CUSTOM_BASES = (chainId) => ({ '0xD46bA6D942050d489DBd938a2C909A5d5039A161': [DAI_TOKEN(chainId), WETH9[chainId]] });

const isEth = (address) => address.toLowerCase() === getAssetInfo('ETH').address.toLowerCase();

function wrappedCurrency(currency, chainId) {
  return currency === Ether.onChain(chainId) ? WETH9[chainId] : currency instanceof Token ? currency : undefined;
}

function poolEquals(poolA, poolB) {
  return (
    poolA === poolB ||
    (poolA.token0.equals(poolB.token0) && poolA.token1.equals(poolB.token1) && poolA.fee === poolB.fee)
  );
}

const wrappedToken = (asset, chainId) => (isEth(asset.address) ? WETH9[chainId] : new Token(
  chainId,
  window._web3.utils.toChecksumAddress(asset.address),
  +asset.decimals,
  asset.symbol,
  asset.name,
));

const getAllCurrencyCombinations = (tokenA, tokenB, chainId) => {
  const bases = [...BASES_TO_CHECK_TRADES_AGAINST(chainId)];
  const tokenAIndex = bases.findIndex(t => t.symbol === tokenA.symbol);
  if (tokenAIndex !== -1) bases.splice(tokenAIndex, 1);
  const tokenBIndex = bases.findIndex(t => t.symbol === tokenB.symbol);
  if (tokenBIndex !== -1) bases.splice(tokenBIndex, 1);
  const basePairs = flatMap(bases, (base) => bases.map((otherBase) => [base, otherBase]));

  return tokenA && tokenB
    ? [
      [tokenA, tokenB],
      ...bases.map((base) => [tokenA, base]),
      ...bases.map((base) => [tokenB, base]),
      ...basePairs,
    ]
      .filter((tokens) => Boolean(tokens[0] && tokens[1]))
      .filter(([t0, t1]) => t0.address !== t1.address)
      .filter(([tokenA, tokenB]) => {
        const customBases = CUSTOM_BASES(chainId);

        const customBasesA = customBases?.[tokenA.address];
        const customBasesB = customBases?.[tokenB.address];

        if (!customBasesA && !customBasesB) return true;

        if (customBasesA && !customBasesA.find((base) => tokenB.equals(base))) return false;
        return !(customBasesB && !customBasesB.find((base) => tokenA.equals(base)));
      })
    : [];
};

const FeeAmount = {
  LOWEST: 100,
  LOW: 500,
  MEDIUM: 3000,
  HIGH: 10000,
};

const PoolState = {
  LOADING: 0,
  NOT_EXISTS: 1,
  EXISTS: 2,
  INVALID: 3,
};

const multicallPairFactoryMultimethod = async (pairAddresses, abi, methodList) => {
  const calls = [];

  const abiItems = methodList.map((method) => abi.find((item) => item.name === method));

  abiItems.forEach((abiItem) => {
    pairAddresses.forEach((address) => {
      calls.push({
        abiItem,
        target: address,
        params: [],
      });
    });
  });
  return multicall(calls).then((data) => {
    const length = pairAddresses.length;
    return abiItems.map((val, i) => data.slice(i * length, (i + 1) * length));
  });
};

const multicallQuote = async (quotes, abi, method, address) => {
  let calls = [];

  const abiItem = abi.find((item) => item.name === method);

  quotes.forEach((params) => {
    calls = [...calls, {
      abiItem,
      params,
      target: address,
    }];
  });

  return multicall(calls);
};


const getPools = async (poolKeys, chainId) => {
  const transformed = poolKeys.map(([currencyA, currencyB, feeAmount]) => {
    if (!currencyA || !currencyB || !feeAmount) return null;

    const tokenA = wrappedCurrency(currencyA, chainId);
    const tokenB = wrappedCurrency(currencyB, chainId);

    if (!tokenA || !tokenB || tokenA.equals(tokenB)) return null;
    const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA];
    return [token0, token1, feeAmount];
  });

  // console.log(transformed);

  const poolAddresses = transformed.map((value) => {
    if (!V3_CORE_FACTORY_ADDRESS || !value) return undefined;
    return computePoolAddress({
      factoryAddress: V3_CORE_FACTORY_ADDRESS,
      tokenA: value[0],
      tokenB: value[1],
      fee: value[2],
    });
  });

  // console.log(poolAddresses);
  const [slot0s, liquidities] = await multicallPairFactoryMultimethod(poolAddresses, IUniswapV3PoolState.abi, ['slot0', 'liquidity']);
  // console.log(slot0s, liquidities);

  return poolKeys.map((_key, index) => {
    const [token0, token1, fee] = transformed[index] || [];
    if (!token0 || !token1 || !fee) return undefined;

    const slot0 = slot0s[index];
    const liquidity = liquidities[index];

    if (!slot0 || !liquidity || liquidity[0] === '0') return undefined;

    if (!slot0.sqrtPriceX96 || new Dec(slot0.sqrtPriceX96).eq(0)) return undefined;

    try {
      return new Pool(token0, token1, fee, slot0.sqrtPriceX96, liquidity[0], +slot0.tick);
    } catch (error) {
      console.error('Error when constructing the pool', error);
      return [PoolState.NOT_EXISTS, null];
    }
  });
};

const getSwapPools = async (tokenIn, tokenOut, chainId) => {
  const allCurrencyCombinations = getAllCurrencyCombinations(tokenIn, tokenOut, chainId);

  const allCurrencyCombinationsWithAllFees =
      allCurrencyCombinations.reduce((list, [tokenA, tokenB]) => list.concat([
        [tokenA, tokenB, FeeAmount.LOWEST],
        [tokenA, tokenB, FeeAmount.LOW],
        [tokenA, tokenB, FeeAmount.MEDIUM],
        [tokenA, tokenB, FeeAmount.HIGH],
      ]), []);

  const pools = await getPools(allCurrencyCombinationsWithAllFees, chainId);

  return pools.filter((item) => !!item);
};

const computeAllRoutes = (currencyIn, currencyOut, pools, currentPath, allPaths, startCurrencyIn, maxHops) => {
  for (const pool of pools) {
    if (!pool.involvesToken(currencyIn) || currentPath.find(pathPool => poolEquals(pool, pathPool))) continue;

    const outputToken = pool.token0.equals(currencyIn) ? pool.token1 : pool.token0;
    if (outputToken.equals(currencyOut)) {
      allPaths.push(new Route([...currentPath, pool], startCurrencyIn, currencyOut));
    } else if (maxHops > 1) {
      computeAllRoutes(
        outputToken,
        currencyOut,
        pools,
        [...currentPath, pool],
        allPaths,
        startCurrencyIn,
        maxHops - 1,
      );
    }
  }
  return allPaths;
};

const bestTradeExactIn = async (amountIn, currencyOut, maxHops, chainId, singleHop) => {
  const pools = await getSwapPools(amountIn.currency, currencyOut, chainId);

  const routes = computeAllRoutes(amountIn.currency, currencyOut, pools, [], [], amountIn.currency, singleHop ? 1 : maxHops);

  const quoteExactInInputs = routes.map((route) => [
    encodeRouteToPath(route, false),
    amountIn ? `0x${amountIn.quotient.toString(16)}` : undefined,
  ]);

  const quotesResults = await multicallQuote(quoteExactInInputs, UniswapQuoter.abi, 'quoteExactInput', UniswapQuoter.networks[1].address);

  return quotesResults.reduce(
    (currentBest, a, i) => {
      const result = a;
      if (result.amountOut === '3963877391197344453575983046348115674221700746820753546331534351508065746944') {
        // weird solidity error number
        // Issue: https://github.com/ethereum/go-ethereum/issues/19027
        // returned for some routes and chooses the wrong route
        // most likely an error on contract
        return currentBest;
      }
      if (!currentBest) return currentBest;

      if (currentBest.amountOut === null) {
        return {
          bestRoute: routes[i],
          amountOut: result.amountOut,
        };
      } if (new Dec(currentBest.amountOut).lt(result.amountOut)) {
        return {
          bestRoute: routes[i],
          amountOut: result.amountOut,
        };
      }

      return currentBest;
    },
    { bestRoute: null, amountOut: null },
  );
};

const bestTradeExactOut = async (amountOut, currencyIn, maxHops, chainId, singleHop) => {
  const pools = await getSwapPools(currencyIn, amountOut.currency, chainId);
  // console.log(pools);

  const routes = computeAllRoutes(currencyIn, amountOut.currency, pools, [], [], currencyIn, singleHop ? 1 : maxHops);
  // console.log(routes);

  const quoteExactOutInputs = routes.map((route) => [
    encodeRouteToPath(route, true),
    amountOut ? `0x${amountOut.quotient.toString(16)}` : undefined,
  ]);
  // console.log(quoteExactInInputs);

  const quotesResults = await multicallQuote(quoteExactOutInputs, UniswapQuoter.abi, 'quoteExactOutput', UniswapQuoter.networks[1].address);
  // console.log(quotesResults);

  return quotesResults.reduce(
    (currentBest, a, i) => {
      const result = a;
      if (result.amountOut === '3963877391197344453575983046348115674221700746820753546331534351508065746944') {
        // weird solidity error number
        // Issue: https://github.com/ethereum/go-ethereum/issues/19027
        // returned for some routes and chooses the wrong route
        // most likely an error on contract
        return currentBest;
      }
      if (!currentBest) return currentBest;
      if (currentBest.amountIn === null) {
        return {
          bestRoute: routes[i],
          amountIn: result.amountIn,
        };
      } if (new Dec(currentBest.amountIn).gt(result.amountIn)) {
        return {
          bestRoute: routes[i],
          amountIn: result.amountIn,
        };
      }

      return currentBest;
    },
    { bestRoute: null, amountIn: null },
  );
};

export const getUniswapV3EncodedPath = async (amount, fromAsset, toAsset, shouldSell, chainId, maxHops = 2, singleHop = false) => {
  try {
    if (!amount || !fromAsset || !toAsset) return [];
    const assetIn = await getERC20TokenData(window._web3.utils.toChecksumAddress(fromAsset));
    const assetOut = await getERC20TokenData(window._web3.utils.toChecksumAddress(toAsset));

    const tokenIn = wrappedToken(assetIn, chainId);
    const tokenOut = wrappedToken(assetOut, chainId);

    const parsedAmount = new CurrencyAmount(shouldSell ? tokenIn : tokenOut, getWeiAmountForDecimals(amount, shouldSell ? assetIn.decimals : assetOut.decimals));

    const route = shouldSell
      ? await bestTradeExactIn(parsedAmount, tokenOut, maxHops, chainId)
      : await bestTradeExactOut(parsedAmount, tokenIn, maxHops, chainId);

    if (route?.bestRoute) {
      return {
        route: route.bestRoute.tokenPath.map(({ symbol }) => symbol),
        encodedRoute: encodeRouteToPath(route.bestRoute, !shouldSell),
      };
    }
    return {
      route: [],
      encodedRoute: '0x0',
    };
  } catch (error) {
    console.error(error);
    captureException(error);
    return {
      route: [],
      encodedRoute: '0x0',
    };
  }
};

export const getNftIds = async (addresses) => {
  const contract = UniV3NonfungiblePositionManagerContract();
  const _addresses = addresses.filter(i => i);
  const numOfPositions = await Promise.all(_addresses.map(async i => +(await contract.methods.balanceOf(i).call())));
  let multicallCallsObject = [];
  _addresses.filter(i => i).forEach((address, index) => {
    for (let i = 0; i < numOfPositions[index]; i++) {
      multicallCallsObject = [
        ...multicallCallsObject,
        {
          target: UniV3NonfungiblePositionManagerAddress,
          abiItem: contract.options.jsonInterface.find(({ name }) => name === 'tokenOfOwnerByIndex'),
          params: [address, i],
        },
      ];
    }
  });

  const multiRes = (await multicall(multicallCallsObject)).map(i => i[0]);

  return numOfPositions.map((_, i) => multiRes.slice(
    i === 0 ? 0 : numOfPositions.slice(0, i).reduce((p, c) => p + c),
    numOfPositions.slice(0, i + 1).reduce((p, c) => p + c),
  ));
};

export const getFeeLabel = (fee) => {
  if (fee === '0.01') return 'LOWEST';
  if (fee === '0.05') return 'LOW';
  if (fee === '0.3') return 'MEDIUM';
  return 'HIGH';
};

export const getTokens = (tokenA, tokenB, chainId = 1) => {
  const _tokenA = new Token(chainId, window._web3.utils.toChecksumAddress(tokenA.address), parseInt(tokenA.decimals, 10), tokenA.symbol, tokenA.name);
  const _tokenB = new Token(chainId, window._web3.utils.toChecksumAddress(tokenB.address), parseInt(tokenB.decimals, 10), tokenB.symbol, tokenB.name);
  return _tokenA.sortsBefore(_tokenB) ? [tokenA, tokenB, _tokenA, _tokenB] : [tokenB, tokenA, _tokenB, _tokenA];
};

export const getPool = async (firstToken, secondToken, _fee) => {
  const fee = getFeeLabel(_fee);
  const firstIsBase = firstToken.sortsBefore(secondToken);
  const poolAddress = computePoolAddress({
    factoryAddress: UniV3FactoryAddress,
    tokenA: firstIsBase ? firstToken : secondToken,
    tokenB: firstIsBase ? secondToken : firstToken,
    fee: FeeAmount[fee],
  });
  const multicallCallObject = [
    {
      target: poolAddress,
      call: ['slot0()(uint160,int24,uint16,uint16,uint16,uint8,bool)'],
      returns: [
        ['sqrtPriceX96', val => val.toString()],
        ['tick', val => val],
        ['observationIndex', val => val],
        ['observationCardinality', val => val],
        ['observationCardinalityNext', val => val],
        ['feeProtocol', val => val],
        ['unlocked', val => val],
      ],
    },
    {
      target: poolAddress,
      call: ['liquidity()(uint128)'],
      returns: [
        ['liquidity', val => val.toString()],
      ],
    },
  ];

  const multiRes = await aggregate(
    multicallCallObject,
  );
  const { results: { transformed } } = multiRes;
  const pool = new Pool(firstIsBase ? firstToken : secondToken, firstIsBase ? secondToken : firstToken, FeeAmount[fee], transformed.sqrtPriceX96, transformed.liquidity, transformed.tick);

  return [pool, poolAddress];
};

export const getPoolLiq = async (firstToken, secondToken, _fee, liquidity) => {
  const fee = getFeeLabel(_fee);
  const firstIsBase = firstToken.sortsBefore(secondToken);
  const poolAddress = computePoolAddress({
    factoryAddress: UniV3FactoryAddress,
    tokenA: firstIsBase ? firstToken : secondToken,
    tokenB: firstIsBase ? secondToken : firstToken,
    fee: FeeAmount[fee],
  });
  const multicallCallObject = [
    {
      target: poolAddress,
      call: ['slot0()(uint160,int24,uint16,uint16,uint16,uint8,bool)'],
      returns: [
        ['sqrtPriceX96', val => val.toString()],
        ['tick', val => val],
        ['observationIndex', val => val],
        ['observationCardinality', val => val],
        ['observationCardinalityNext', val => val],
        ['feeProtocol', val => val],
        ['unlocked', val => val],
      ],
    },
    {
      target: poolAddress,
      call: ['liquidity()(uint128)'],
      returns: [
        ['liquidity', val => val.toString()],
      ],
    },
  ];
  const multiRes = await aggregate(
    multicallCallObject,
  );
  const { results: { transformed } } = multiRes;
  const pool = new Pool(firstIsBase ? firstToken : secondToken, firstIsBase ? secondToken : firstToken, FeeAmount[fee], transformed.sqrtPriceX96, liquidity, transformed.tick);

  return [pool, poolAddress];
};

export const getPositions = async (ids, isProxy, account, proxyAddress) => {
  if (!ids?.length) return [];
  const contract = UniV3NonfungiblePositionManagerContract();
  const _idsMulticallObject = ids.filter(i => i).flatMap((id, index) => ([
    {
      target: contract.options.address,
      abiItem: contract.options.jsonInterface.find(({ name }) => name === 'positions'),
      params: [id],
    },
  ]),
  );
  // {
  //   target: contract.options.address,
  //   abiItem: contract.options.jsonInterface.find(({ name }) => name === 'tokenURI'),
  //   params: [id],
  // }, // TODO Fix this
  // returns: [
  //   [`tokenURI${index}`, val => {
  //     const searchString = 'base64,';
  //     const index = val.indexOf(searchString);
  //     return atob(val.substring(index + searchString.length));
  //   }],
  // ],

  const res = await multicall(_idsMulticallObject);

  return res.map((item, index) => ({
    id: ids[index],
    fee: item.fee,
    feeGrowthInside0LastX128: item.feeGrowthInside0LastX128,
    feeGrowthInside1LastX128: item.feeGrowthInside1LastX128,
    liquidity: item.liquidity,
    nonce: item.nonce,
    operator: item.operator,
    tickLower: item.tickLower,
    tickUpper: item.tickUpper,
    tokenFirst: item.token0,
    tokenSecond: item.token1,
    tokensOwedFirst: item.tokensOwed0,
    tokensOwedSecond: item.tokensOwed1,
    owner: isProxy ? proxyAddress : account,
    tokenURI: '',
    // tokenURI: transformed[`tokenURI${index}`],
  }));
};

export const getPrice = (baseAsset, quoteAsset, baseAssetInfo, quoteAssetInfo, _amount) => {
  const baseAmount = CurrencyAmount.fromRawAmount(baseAsset, getWeiAmountForDecimals('1', baseAssetInfo.decimals));
  const amount = CurrencyAmount.fromRawAmount(quoteAsset, getWeiAmountForDecimals(_amount, quoteAssetInfo.decimals));
  return new Price(baseAsset, quoteAsset, baseAmount.quotient, amount.quotient);
};

export const getPercent = (percent) => new Percent(percent, 100);

export const getTokenAmountPercent = (position, token, percent, isFirst) => {
  const positionAmount = isFirst ? position.amount0.quotient : position.amount1.quotient;
  return CurrencyAmount.fromRawAmount(
    token,
    percent.multiply(positionAmount).quotient,
  );
};
export const getNearestTickPrice = (baseAsset, quoteAsset, baseAssetInfo, quoteAssetInfo, _fee, _amount, invert) => {
  if (!+_amount) return '';
  const fee = getFeeLabel(_fee);
  const price = invert ? getPrice(quoteAsset, baseAsset, quoteAssetInfo, baseAssetInfo, _amount) : getPrice(baseAsset, quoteAsset, baseAssetInfo, quoteAssetInfo, _amount);
  const closestTick = priceToClosestTick(price);
  const nearestTick = nearestUsableTick(closestTick, TICK_SPACINGS[FeeAmount[fee]]);
  return tickToPrice(invert ? quoteAsset : baseAsset, invert ? baseAsset : quoteAsset, nearestTick).toSignificant();
};

export const getNextTickPrice = (baseAsset, quoteAsset, baseAssetInfo, quoteAssetInfo, forward, price, _fee, pool) => {
  const fee = getFeeLabel(_fee);
  const tick = price ? priceToClosestTick(getPrice(baseAsset, quoteAsset, baseAssetInfo, quoteAssetInfo, price)) : pool.currentTick;
  const nearestTick = nearestUsableTick(tick, TICK_SPACINGS[FeeAmount[fee]]);
  return tickToPrice(baseAsset, quoteAsset, forward ? new Dec(nearestTick).plus(TICK_SPACINGS[FeeAmount[fee]]).toNumber() : new Dec(nearestTick).minus(TICK_SPACINGS[FeeAmount[fee]]).toNumber()).toSignificant();
};

export const pricesToTicks = (priceFirst, priceSecond, firstAsset, secondAsset, firstAssetInfo, secondAssetInfo, _fee, invert) => {
  const fee = getFeeLabel(_fee);
  const [baseAsset, quoteAsset, baseAssetInfo, quoteAssetInfo] = invert ? [secondAsset, firstAsset, secondAssetInfo, firstAssetInfo] : [firstAsset, secondAsset, firstAssetInfo, secondAssetInfo];
  const firstTick = priceToClosestTick(getPrice(baseAsset, quoteAsset, baseAssetInfo, quoteAssetInfo, priceFirst));
  const secondTick = priceToClosestTick(getPrice(baseAsset, quoteAsset, baseAssetInfo, quoteAssetInfo, priceSecond));
  return [nearestUsableTick(invert ? secondTick : firstTick, TICK_SPACINGS[FeeAmount[fee]]), nearestUsableTick(invert ? firstTick : secondTick, TICK_SPACINGS[FeeAmount[fee]])];
};

export const getSqrtRatio = (firstAsset, secondAsset, firstAssetInfo, secondAssetInfo, price, invert) => {
  const [baseAsset, quoteAsset, baseAssetInfo, quoteAssetInfo] = invert ? [secondAsset, firstAsset, secondAssetInfo, firstAssetInfo] : [firstAsset, secondAsset, firstAssetInfo, secondAssetInfo];
  const currentTick = priceToClosestTick(getPrice(baseAsset, quoteAsset, baseAssetInfo, quoteAssetInfo, price));
  return TickMath.getSqrtRatioAtTick(currentTick);
};

export const mockPool = (firstAsset, secondAsset, firstAssetInfo, secondAssetInfo, _fee, price, invert, liquidity = '') => {
  const fee = getFeeLabel(_fee);
  const [baseAsset, quoteAsset, baseAssetInfo, quoteAssetInfo] = invert ? [secondAsset, firstAsset, secondAssetInfo, firstAssetInfo] : [firstAsset, secondAsset, firstAssetInfo, secondAssetInfo];
  const currentTick = priceToClosestTick(getPrice(baseAsset, quoteAsset, baseAssetInfo, quoteAssetInfo, price));
  const currentSqrt = TickMath.getSqrtRatioAtTick(currentTick);
  return new Pool(firstAsset, secondAsset, FeeAmount[fee], currentSqrt, liquidity ? ethToWei(0) : liquidity, currentTick, []);
};

export const mockPosition = (baseAsset, quoteAsset, baseAssetInfo, quoteAssetInfo, tickLower, tickUpper, pool, amount, isFirst) => (
  isFirst ?
    Position.fromAmount0({
      pool,
      tickLower,
      tickUpper,
      amount0: CurrencyAmount.fromRawAmount(baseAsset, getWeiAmountForDecimals(amount, baseAssetInfo.decimals)).quotient,
      useFullPrecision: true,
    })
    : Position.fromAmount1({
      tickLower,
      tickUpper,
      pool,
      amount1: CurrencyAmount.fromRawAmount(quoteAsset, getWeiAmountForDecimals(amount, quoteAssetInfo.decimals)).quotient,
    }));

export const getPositionForID = async (id) => {
  const contract = UniV3NonfungiblePositionManagerContract();

  return contract.methods.positions(id).call();
};

export const approveUniNft = async (accountType, path, sendTxFunc, from, spender, nftId) => {
  const contract = UniV3NonfungiblePositionManagerContract();
  return callTx(accountType, path, sendTxFunc, contract, 'approve', [spender, nftId], { from });
};

export const getCollectAmounts = async (tokenId, account) => {
  const contract = await UniV3NonfungiblePositionManagerContract();
  const params = {
    tokenId,
    recipient: EMPTY_ACCOUNT,
    amount0Max: MAXUINT128,
    amount1Max: MAXUINT128,
  };
  return contract.methods.collect(params).call({ from: account });
};
