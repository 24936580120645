/* eslint-disable */
import React from 'react';

export default function CurveProtocolIcon(props) {
  return (
    <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M6.1437 23C1.88665 23 0.00691717 16.2061 0.00691717 10.0077C-0.103655 6.93012 1.06841 0.631167 6.64127 0.0561898C13.6073 -0.662532 20.8568 5.6954 22.3426 8.73614C23.8284 11.7769 23.7247 14.3201 19.5783 15.7022C16.2611 16.8079 11.9112 17.8584 10.1509 18.2454C9.75786 21.1217 8.74642 23 6.1437 23ZM10.4007 11.7769L10.3735 11.7282C10.3094 11.7627 10.3312 11.7769 10.4007 11.7769ZM10.3735 11.7282C8.81944 8.93627 9.1388 7.1319 11.9971 7.1319C14.872 7.13191 22.2873 8.90197 15.874 10.3394C11.881 11.2344 10.5983 11.607 10.3735 11.7282ZM6.42975 21.4645C8.28713 21.0985 9.34299 18.5189 8.78808 15.7029C8.23318 12.8869 6.27764 10.9007 4.42026 11.2667C2.56288 11.6327 1.50702 14.2123 2.06193 17.0283C2.61683 19.8443 4.57237 21.8305 6.42975 21.4645Z" fill="white"/>
    </svg>
  );
}

