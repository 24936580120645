import dfs from '@defisaver/sdk';
import cloneDeep from 'lodash/cloneDeep';
import Dec from 'decimal.js';
import { assetAmountInWei } from '@defisaver/tokens';
import RecipeAction from '../RecipeAction';
import {
  Amount, Source, AssetAmount,
} from '../../components/Recipes/RecipeCreator/inputTypes';
import SellIcon from '../recipeIcons/Swap.svg';
import { formatAcc, formatNumber } from '../../services/utils';
import {
  assetAmountInWeiIgnorePointer,
  changeBalance,
} from '../../services/recipeCreator/recipeActionUtils';
import { MAXUINT } from '../../constants/general';

export default class UnwrapEthAction extends RecipeAction {
  static prettyName = 'Unwrap WETH';

  static description = 'Unwraps WETH back into ETH.';

  constructor(amount = '') {
    super();
    this.inputs = [
      new AssetAmount('Amount', amount, 'WETH'),
      // new Source('To', to, true),
    ];
    this.output = new AssetAmount('output', 0, 'ETH');
  }

  async getAfterValues(_balances = {}, returnValues = [], actions = [], positions = {}, getState) {
    const { proxyAddress } = getState().maker;
    const { account } = getState().general;
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    let amount = args[0];
    const balances = cloneDeep(_balances);
    if (amount === 'All available') amount = balances?.recipe?.WETH || 0;
    await changeBalance(balances, 'recipe', 'WETH', new Dec(amount || 0).mul(-1), proxyAddress);
    // if (args[1] === 'wallet') {
    await changeBalance(balances, 'wallet', 'ETH', amount || 0, account);
    // }
    this.output.value = amount;
    return { returnValue: this.output, balances, positions };
  }

  async toDfsAction(getState) {
    const {
      general: { account },
      maker: { proxyAddress },
    } = getState();
    // const to = this.inputs[1].value === 'wallet' ? account : proxyAddress;
    const to = account;
    const amount = this.inputs[0].value === 'All available' ? MAXUINT : assetAmountInWeiIgnorePointer(this.inputs[0].value, 'ETH');
    return new dfs.actions.basic.UnwrapEthAction(amount, to);
  }

  static getIcon() {
    return SellIcon;
  }

  _getPrettyName(actionCalls, actions) {
    const args = this.mapReturnValuesToArgs(actionCalls.map(a => a.returnValue), actions);
    let amount = formatNumber(args[0]);
    if (args[0] === 'Infinity' || args[0] === 'All available') amount = 'all';
    return `Unwrap ${amount} WETH to ETH`;
  }
}
