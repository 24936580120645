import React from 'react';
import { getAssetInfo } from '@defisaver/tokens';

import './Showcase.scss';

const Showcase = () => (
  <div className="showcase-wrapper dashboard-page-wrapper">
    <div className="content-wrapper">
      <div className="width-container">
        <div className="main-section-wrapper">

          <h1>Icons</h1>
          <div>
            {getAssetInfo('DAI').icon()}
            {getAssetInfo('DAI').icon({ width: 50, height: 50 })}
            {getAssetInfo('DAI').icon({ width: 20, height: 20 })}
            <br />
            {getAssetInfo('DAI').icon()}
            {getAssetInfo('OPA').icon()}
            {getAssetInfo('NEAR').icon()}
            {getAssetInfo('WWWW').icon()}
            {getAssetInfo('12345').icon()}
          </div>

        </div>
      </div>
    </div>
  </div>
);

export default Showcase;
