/* eslint-disable */
import React from 'react';

export default function TxReplacedIcon(props) {
  return (
    <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M50.4196 25.5489C50.4196 39.356 39.2267 50.5489 25.4196 50.5489C11.6124 50.5489 0.419556 39.356 0.419556 25.5489C0.419556 11.7418 11.6124 0.548889 25.4196 0.548889C39.2267 0.548889 50.4196 11.7418 50.4196 25.5489Z" fill="#939DA7"/>
      <path d="M25.372 15.4254C22.9639 15.4254 20.8252 16.2017 18.9557 17.7543C17.0863 19.3068 15.9298 21.2238 15.4862 23.5052H11.3987C11.9057 20.0831 13.4741 17.1998 16.104 14.855C18.7339 12.5103 21.8233 11.338 25.372 11.338C29.301 11.338 32.6597 12.7321 35.448 15.5204L39.6305 11.338V23.5052H27.4633L32.5963 18.3721C30.5684 16.4076 28.1603 15.4254 25.372 15.4254ZM25.372 39.7598C21.443 39.7598 18.1161 38.3657 15.3911 35.5773L11.2086 39.7598V27.5926H23.3758L18.2428 32.7256C20.2073 34.6901 22.5837 35.6724 25.372 35.6724C27.8435 35.6724 29.9981 34.9119 31.8359 33.391C33.6736 31.8701 34.846 29.9373 35.353 27.5926H39.4404C38.5172 34.4244 32.1694 39.7598 25.372 39.7598Z" fill="white"/>
    </svg>
  );
}
