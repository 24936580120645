import dfs from '@defisaver/sdk';
import cloneDeep from 'lodash/cloneDeep';
import Dec from 'decimal.js';

import RecipeAction from '../RecipeAction';
import { assetAmountInWeiIgnorePointer, changeBalance } from '../../services/recipeCreator/recipeActionUtils';
import { formatNumber } from '../../services/utils';
import { weiToEth } from '../../services/ethService';

import { AssetAmount, Amount, Source } from '../../components/Recipes/RecipeCreator/inputTypes';
import StakeIcon from '../recipeIcons/Stake.svg';

export default class LidoStakeAction extends RecipeAction {
  static prettyName = 'Stake ETH';

  static protocol = 'lido';

  static protocolPrettyName = 'Lido';

  static description = 'Stakes ETH using Lido.';

  constructor(from = 'wallet', amount = '', to = 'wallet') {
    super();
    this.inputs = [
      new Source('From', from),
      new AssetAmount('Amount', amount, 'WETH'),
      new Source('To', to, true),
    ];
    this.output = new AssetAmount('output', 0, 'stETH');
  }

  async toDfsAction(getState, i) {
    const {
      general: { account },
      maker: { proxyAddress },
    } = getState();
    const from = this.inputs[0].value === 'wallet' ? account : proxyAddress;
    const to = this.inputs[2].value === 'wallet' ? account : proxyAddress;
    const amount = assetAmountInWeiIgnorePointer(this.inputs[1].value, 'WETH');
    return new dfs.actions.lido.LidoStakeAction(amount, from, to);
  }

  async getAfterValues(_balances = {}, returnValues = [], actions = [], positions = {}, getState) {
    const {
      general: { account },
      maker: { proxyAddress },
    } = getState();
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const stEthamount = new Dec(args[1]).minus(weiToEth(args[2] === 'wallet' ? 2 : 1)).toString() || '0';
    const amount = args[1] || '0';
    const balances = cloneDeep(_balances);
    await changeBalance(balances, args[0], 'WETH', new Dec(amount).mul(-1).toString(), args[0] === 'wallet' ? account : proxyAddress);
    await changeBalance(balances, args[2], 'stETH', stEthamount, args[2] === 'wallet' ? account : proxyAddress);
    this.output.value = amount;
    return {
      returnValue: this.output,
      balances,
      positions,
    };
  }

  _getPrettyName(actionCalls, actions) {
    const args = this.mapReturnValuesToArgs(actionCalls.map(a => a.returnValue), actions);
    return `Stake ${formatNumber(args[1])} WETH`;
  }

  static getIcon() {
    return StakeIcon;
  }
}
