import React from 'react';
import PropTypes from 'prop-types';

import './CheckboxComponent.scss';
import InfoTooltip from '../../Decorative/InfoTooltip';

const CheckboxComponent = ({
  input, wrapperClassName, inputClassName, type,
  id, labelText, meta: { touched, error }, focus, disabled, descriptionText,
}) => (
  <div className={`
      ${wrapperClassName}
      ${touched && error ? 'wrapper-error' : ''}
      ${disabled ? 'disabled' : ''}
      ${type}
    `}
  >
    <input
      {...input}
      id={id || ''}
      className={`${inputClassName}`}
      type={type}
      autoFocus={focus}
      disabled={disabled}
    />
    <label htmlFor={id || ''} className="label-wrapper">
      <div className="checkbox-wrapper">
        <div className="box" />
      </div>

      <div className="label-text">
        {labelText}
      </div>
      {
        descriptionText &&
        <InfoTooltip message={descriptionText} />
      }
    </label>
  </div>
);

CheckboxComponent.defaultProps = {
  labelText: '',
  id: '',
  focus: false,
  disabled: false,
  type: 'checkbox',
  wrapperClassName: 'form-item-wrapper checkbox',
  inputClassName: 'form-item',
  descriptionText: '',
};

CheckboxComponent.propTypes = {
  input: PropTypes.any.isRequired,
  wrapperClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  id: PropTypes.string,
  labelText: PropTypes.string,
  meta: PropTypes.object.isRequired,
  focus: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  descriptionText: PropTypes.string,
};

export default CheckboxComponent;
