import { Transaction } from '@ethereumjs/tx';
import { OVMGasPriceOracleContract } from './contractRegistryService';
import { ethToWei, weiToEth } from './ethService';

/**
 * Get fee that will be paid on mainnet when sending tx on Optimism
 *
 * @param {string} data
 * @param {string} gasLimit
 * @param {string} gasPrice
 * @returns {Promise<string|*>}
 */
export const getOptimismLayerOneGas = async (data, gasLimit, gasPrice) => {
  try {
    if (!gasLimit || !gasPrice) return '0';
    const contract = OVMGasPriceOracleContract();

    const tx = new Transaction({
      data,
      gasLimit,
      gasPrice: window._web3.utils.numberToHex(ethToWei(gasPrice, 'gwei')),
    });

    const gas = await contract.methods.getL1Fee(`0x${tx.serialize().toString('hex')}`).call();

    return weiToEth(gas);
  } catch (err) {
    console.error(err);
    return '0';
  }
};
