import React, { useState, useEffect } from 'react';
import Dec from 'decimal.js';
import t from 'translate';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector, change } from 'redux-form';
import { closeCdpAction } from 'actions/makerActions/makerManageActions/makerManageMcdActions';
import { formatNumber, removeCommnaAndSpace, round } from 'services/utils';
import { getAssetInfo } from '@defisaver/tokens';
import { getCloseCdpAssets } from '../../../../../constants/assets';
import './CloseMcdCdpForm.scss';
import { MakerCloseSlippageLimitForm as SlippageLimitForm } from '../../../BoostRepayCommon/SlippageLimitForm/SlippageLimitForm';
import InfoBox from '../../../../Decorative/InfoBox/InfoBox';
import TradeSizeImpactWarning from '../../../../TradeSizeImpactWarning/TradeSizeImpactWarning';
import DataItem from '../../../../DataItem/DataItem';
import TogglableSection from '../../../../TogglableSection/TogglableSection';

const INPUT_DECIMALS = 4;

const CloseMcdCdpForm = ({
  handleSubmit, onSubmit, submittingForm, closeModal, cdpAsset,
  closeData, inputVal, initialValues, change, dispatch,
}) => {
  const collMinusDebtInAssetShortLabel = removeCommnaAndSpace(formatNumber(closeData.collMinusDebtInAsset, INPUT_DECIMALS));
  const collMinusDebtInDaiShortLabel = removeCommnaAndSpace(formatNumber(closeData.collMinusDebtInDai, INPUT_DECIMALS));

  const collMinusDebtInAssetShort = round(closeData.collMinusDebtInAsset, INPUT_DECIMALS);
  const collMinusDebtInDaiShort = round(closeData.collMinusDebtInDai, INPUT_DECIMALS);
  const percentOfTotalDebt = Dec(inputVal).times('100').div(collMinusDebtInAssetShort).toString();
  const percentLoss = Dec('100').minus(percentOfTotalDebt).toString();
  const assets = getCloseCdpAssets(cdpAsset);
  const tradeSizeImpactNum = Dec(closeData.tradeSizeImpact).toNumber();

  const [activeTab, setActiveTab] = useState(cdpAsset);
  const [advanced, setAdvanced] = useState(false);

  const AssetIcon = getAssetInfo(cdpAsset).icon;
  const DaiIcon = getAssetInfo('DAI').icon;

  const toDai = activeTab === 'DAI';

  return (
    <form
      id="close-mcd-cdp-form"
      onSubmit={handleSubmit(() => { onSubmit(toDai, closeModal); })}
      className="form-wrapper close-mcd-cdp-form-wrapper"
    >
      <div className="form-width-container">
        <div className="text-item margin">{ t('maker.close_to') }:</div>
        <div className="Switch">
          <div
            onClick={() => {
              dispatch(change('toDai', false, true));
              setActiveTab(cdpAsset);
            }}
            className={`tab ${activeTab === cdpAsset ? 'active' : ''}`}
          >
            <AssetIcon width={25} height={25} /> <span className="small">{t('common.withdraw')} {cdpAsset}</span>
          </div>
          <div
            onClick={() => {
              dispatch(change('toDai', true, true));
              setActiveTab('DAI');
            }}
            className={`tab ${toDai ? 'active' : ''}`}
          >
            <DaiIcon width={25} height={25} /> <span className="small">Sell for DAI</span>
          </div>
        </div>

        <DataItem
          label="Est. sent to account"
          value={toDai ? collMinusDebtInDaiShortLabel : collMinusDebtInAssetShortLabel}
          symbol={activeTab}
          type="small"
          smallSymbol
        />

        <br />
        <TradeSizeImpactWarning tradeSizeImpact={tradeSizeImpactNum} />

        <TogglableSection advanced={advanced} toggleAdvanced={setAdvanced} title={t('common.advanced')}>
          <div className="advanced-content">
            <SlippageLimitForm
              estimatedPrice={toDai ? collMinusDebtInDaiShort : collMinusDebtInAssetShort}
              type="close"
              supplyAsset={toDai ? 'DAI' : cdpAsset}
              borrowAsset={toDai ? cdpAsset : 'DAI'}
            />
            <InfoBox message={t('common.slippage_tolerance_info')} />
          </div>
        </TogglableSection>

        {/* <DescItem label={t('maker.maximum_loss_closing')} val={percentLoss} valLabel="%" /> */}
        <div className="text-item">{ t('maker.close_fee_no_brackets') }</div>
      </div>
    </form>
  );
};

const CloseMcdCdpFormComp = reduxForm({
  form: 'closeMcdCdpForm',
})(CloseMcdCdpForm);

CloseMcdCdpForm.defaultProps = {
  inputVal: '0',
};

CloseMcdCdpForm.propTypes = {
  cdpAsset: PropTypes.string.isRequired,
  submittingForm: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  closeData: PropTypes.object.isRequired,
  inputVal: PropTypes.string,
  initialValues: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const selector = formValueSelector('closeMcdCdpForm');

const mapStateToProps = state => ({
  initialValues: {
    toDai: false,
  },
  cdpAsset: state.maker.cdp.asset,
  toDai: selector(state, 'toDai'),
  submittingForm: state.makerManage.closingCdp,
  closeData: state.makerManageMcd.closeData,
});

const mapDispatchToProps = {
  onSubmit: closeCdpAction, change,
};

export default connect(mapStateToProps, mapDispatchToProps)(CloseMcdCdpFormComp);
