import Dec from 'decimal.js';
import dfs from '@defisaver/sdk';
import {
  assetAmountInEth, assetAmountInWei, getAssetInfo, getAssetInfoByAddress,
} from '@defisaver/tokens';
import cloneDeep from 'lodash/cloneDeep';
import RecipeAction from '../RecipeAction';
import {
  Amount, Asset, AssetAmount, Source,
} from '../../components/Recipes/RecipeCreator/inputTypes';
import { formatNumber } from '../../services/utils';
import {
  changeBalance,
  getAmountDfsAction,
  getSourceAddress,
} from '../../services/recipeCreator/recipeActionUtils';
import { MStableSaveGetter } from '../../services/savingsServices/protocols/MStableSaveGetters';
import {
  MStableImUsdTokenAddress,
  MStableImUsdVaultAddress,
  MStableMUsdTokenAddress,
} from '../../services/contractRegistryService';
import { MAXUINT } from '../../constants/general';
import { MStableVaultGetter } from '../../services/savingsServices/protocols/MStableVaultGetters';
import WithdrawIcon from '../recipeIcons/Withdraw.svg';

export default class MStableVaultWithdrawAction extends RecipeAction {
  static prettyName = 'Withdraw from mStable Vault';

  static protocol = 'mstable';

  static protocolPrettyName = 'mStable';

  static description = 'Withdraws selected asset from mStable Vault';

  constructor(asset = 'DAI', amount = '0', to = 'recipe') {
    super();
    this.isVault = true;
    this.inputs = [
      new Asset('Asset', asset, ['DAI', 'USDC', 'USDT', 'mUSD', 'imUSD'].map(symbol => getAssetInfo(symbol))),
      new Amount('Amount', amount),
      new Source('To', to, true),
    ];
    this.output = new AssetAmount('output', 0, asset);
  }

  static getIcon() {
    return WithdrawIcon;
  }

  _getPrettyName(actionCalls, actions) {
    const args = this.mapReturnValuesToArgs(actionCalls.map(a => a.returnValue), actions);
    return `Withdraw ${args[1] === 'All available' ? 'all' : formatNumber(args[1])} ${this.inputs[0].asset} from mStable Vault`;
  }

  async getAfterValues(_balances = {}, returnValues = [], actions = [], _positions = {}, getState) {
    const positions = { ..._positions };
    const balances = cloneDeep(_balances);
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const from = 'recipe';
    const to = args[2];
    const fromAddress = getSourceAddress(from, getState);
    const toAddress = getSourceAddress(to, getState);
    const asset = getAssetInfoByAddress(args[0]);
    if (!positions.mstableVault) positions.mstableVault = assetAmountInEth(await MStableVaultGetter._getSuppliedImUsd(fromAddress), 'imUSD');

    let amountOut;
    let mUsdAmount;
    let imUsdAmount;
    if (args[1] === 'All available') {
      imUsdAmount = positions.mstableVault;
      if (asset.symbol === 'imUSD') {
        amountOut = imUsdAmount;
      } else {
        mUsdAmount = await MStableSaveGetter._imUsdToMUsd(assetAmountInWei(imUsdAmount, 'imUSD'));
        amountOut = assetAmountInEth(
          asset.symbol === 'mUSD' ? mUsdAmount : await MStableSaveGetter._mUsdToAsset(asset.address, mUsdAmount),
          asset.symbol,
        );
      }
    } else {
      amountOut = args[1];
      if (asset.symbol === 'imUSD') {
        imUsdAmount = amountOut;
      } else {
        mUsdAmount = asset.symbol === 'mUSD' ? assetAmountInWei(amountOut, 'mUSD') : await MStableSaveGetter._assetOutToMUsd(asset.address, assetAmountInWei(amountOut, asset.symbol));
        imUsdAmount = assetAmountInEth(await MStableSaveGetter._mUsdToImUsd(mUsdAmount), 'imUSD');
      }
    }

    await changeBalance(balances, to, asset.symbol, new Dec(amountOut), toAddress, '');
    positions.mstableVault = new Dec(positions.mstableVault).sub(imUsdAmount).toString();

    this.output.value = amountOut;
    this.output.asset = asset.symbol;
    return { returnValue: this.output, balances, positions };
  }

  async toDfsAction(getState, actions, returnValues) {
    const asset = getAssetInfo(this.inputs[0].asset);
    const assetAmount = getAmountDfsAction(this.inputs[1].value, asset.symbol);
    const minOut = assetAmountInWei(this.output.value, this.output.asset);
    let imUsdAmount;
    if (assetAmount === MAXUINT) {
      imUsdAmount = MAXUINT;
    } else if (asset.symbol === 'imUSD') {
      imUsdAmount = assetAmount;
    } else {
      const mUsdAmount = asset.symbol === 'mUSD' ? minOut : await MStableSaveGetter._assetOutToMUsd(asset.address, minOut);
      imUsdAmount = await MStableSaveGetter._mUsdToImUsd(mUsdAmount);
    }
    const from = getSourceAddress('recipe', getState);
    const to = getSourceAddress(this.inputs[2].value, getState);
    const assetPair = MStableSaveGetter._getAssetPair(asset, true);
    return new dfs.actions.mstable.MStableWithdrawAction(asset.address, MStableMUsdTokenAddress, MStableImUsdTokenAddress, MStableImUsdVaultAddress, from, to, imUsdAmount, MStableSaveGetter._setMinoutSlippage(minOut), assetPair);
  }
}
