import { change, formValueSelector } from 'redux-form';
import Dec from 'decimal.js';
import { getAssetInfo } from '@defisaver/tokens';
import { setAfterValue } from './aaveManageAfterValues';
import {
  GET_MAX_AAVE_BOOST_FAILURE,
  GET_MAX_AAVE_BOOST_REQUEST,
  GET_MAX_AAVE_BOOST_SUCCESS,
  GET_MAX_AAVE_BORROW_FAILURE,
  GET_MAX_AAVE_BORROW_REQUEST,
  GET_MAX_AAVE_BORROW_SUCCESS,
  GET_MAX_AAVE_PAYBACK_FAILURE,
  GET_MAX_AAVE_PAYBACK_REQUEST,
  GET_MAX_AAVE_PAYBACK_SUCCESS,
  GET_MAX_AAVE_REPAY_FAILURE,
  GET_MAX_AAVE_REPAY_REQUEST,
  GET_MAX_AAVE_REPAY_SUCCESS,
  GET_MAX_AAVE_SUPPLY_FAILURE,
  GET_MAX_AAVE_SUPPLY_REQUEST,
  GET_MAX_AAVE_SUPPLY_SUCCESS,
  GET_MAX_AAVE_WITHDRAW_FAILURE,
  GET_MAX_AAVE_WITHDRAW_REQUEST,
  GET_MAX_AAVE_WITHDRAW_SUCCESS,
} from '../../actionTypes/aaveActionTypes/aaveManageActionTypes';
import { captureException } from '../../sentry';
import { getAddressForWalletType } from '../../services/utils';
import { getMaxBorrow, getMaxWithdraw } from '../../services/aaveServices/aaveManageService';
import { getAssetBalance } from '../../services/assetsService';

/**
 * Checks if the action item has a value and if the new max value is smaller
 * than the action item value and changes the action item value and calls setAfterValue accordingly
 *
 * @param newMaxValue {String}
 * @param formName {String}
 * @param inputName {String}
 * @param afterType {String}
 * @param selectValue {Object}
 * @param secondSelectValue {Object}
 * @return {function(...[*]=)}
 */
// move this to a general service with the dispatch and getState as props if
// actions with with 1 or 2 selects are used on more pages
export const checkActionItemInputValueAndAfter = (newMaxValue, formName, inputName, afterType, selectValue, secondSelectValue = undefined) => (dispatch, getState) => {
  const selector = formValueSelector(formName);
  const actionValue = selector(getState(), inputName);

  // if the action item value is empty or invalid do nothing
  if (!actionValue || parseFloat(actionValue) <= 0) return;

  // if the new max value is 0 or the frst select and second select values are the same
  // then set the action item value to '' and invalidate the afterValue
  const newMaxIsZero = newMaxValue === '0';
  const selectInputsAreTheSame = selectValue && secondSelectValue ? selectValue.value === secondSelectValue.value : false;
  if (newMaxIsZero || selectInputsAreTheSame) return dispatch(setAfterValue(0, ''));

  let newActionValue = actionValue;

  // if the action item value is greater than the new max value set the action item input
  // to the new max value and call setAfterValue
  if (Dec(actionValue).gt(newMaxValue)) {
    newActionValue = newMaxValue;
    dispatch(change(formName, inputName, newActionValue));
  }

  dispatch(setAfterValue(newActionValue, afterType, selectValue, secondSelectValue));
};

/**
 * Handles redux actions for when the number of max amount of selected asset that can be borrowed is calculating
 *
 * @return {Function}
 */
export const getMaxBoostAction = () => async (dispatch, getState) => {
  dispatch({ type: GET_MAX_AAVE_BOOST_REQUEST });
  try {
    const { general: { walletType } } = getState();
    const { actionsSelectValues } = getState().aaveManage;
    const { assetsData } = getState().aaveManage.v1;
    const { borrowedUsd, borrowLimitUsd, usedAssets } = getState().aaveManage[walletType.value].v1;

    const borrowAsset = actionsSelectValues.boostBorrow;
    const supplyAsset = actionsSelectValues.boostSupply;

    const supplyAssetData = assetsData[supplyAsset.value];
    const supplyUsedAssetData = usedAssets[supplyAsset.value] || {};

    // borrow limit after enabling supplyAsset as collateral
    const newBorrowLimitUsd = (supplyUsedAssetData.collateral)
      ? borrowLimitUsd
      : Dec(borrowLimitUsd).plus(Dec(supplyUsedAssetData?.suppliedUsd || '0').mul(supplyAssetData.collateralFactor)).toString();

    const _maxBoostUsd = newBorrowLimitUsd - borrowedUsd;
    const maxBoostUsd = parseFloat(_maxBoostUsd) < 0 ? '0' : _maxBoostUsd;
    const assetPrice = getState().assets[borrowAsset.value].aavePrice;
    const payload = new Dec(maxBoostUsd).div(assetPrice).toDP(getAssetInfo(borrowAsset.value).decimals).toString();

    dispatch(checkActionItemInputValueAndAfter(payload, 'aaveManageAdvancedForm', 'boostAmount', 'boost', borrowAsset, supplyAsset));

    dispatch({ type: GET_MAX_AAVE_BOOST_SUCCESS, payload });
  } catch (err) {
    dispatch({ type: GET_MAX_AAVE_BOOST_FAILURE, payload: err.message });
    captureException(err);
  }
};

/**
 * Handles redux actions for when the number of max amount of selected asset that can be repaid is calculating
 *
 * @return {Function}
 */
export const getMaxRepayAction = () => (dispatch, getState) => {
  dispatch({ type: GET_MAX_AAVE_REPAY_REQUEST });

  try {
    const { general: { walletType } } = getState();
    const { actionsSelectValues } = getState().aaveManage;
    const { assetsData } = getState().aaveManage.v1;
    const { usedAssets, borrowedUsd, liquidationLimitUsd } = getState().aaveManage[walletType.value].v1;

    const collAsset = actionsSelectValues.repayWithdraw;
    const debtAsset = actionsSelectValues.repayPayback;

    let payload = '0';
    let totalDebtUsd = '0';

    if (collAsset && debtAsset) {
      const assetPrice = getState().assets[collAsset.value].aavePrice;

      const {
        borrowedUsd: borrowedUsdDebtAsset, borrowedUsdVariable, borrowedUsdStable, interestMode,
      } = usedAssets[debtAsset.value];

      // Adding 2% slippage bc of swap
      const overestimatePrice = debtAsset.value === collAsset.value ? 1 : 1.02;

      if (interestMode === 'both') {
        totalDebtUsd = debtAsset.additionalLabel === 'Stable'
          ? new Dec(borrowedUsdStable).mul(overestimatePrice).toString()
          : new Dec(borrowedUsdVariable).mul(overestimatePrice).toString();
      } else {
        totalDebtUsd = new Dec(borrowedUsdDebtAsset).mul(overestimatePrice).toString();
      }
      const totalDebt = new Dec(totalDebtUsd).div(assetPrice).toDP(getAssetInfo(collAsset.value).decimals).toString();

      const usedAssetData = usedAssets[collAsset.label];
      const assetData = assetsData[collAsset.value];
      const maxWithdraw = getMaxWithdraw(usedAssetData, assetPrice, assetData, borrowedUsd, liquidationLimitUsd);
      payload = Dec.min(maxWithdraw, totalDebt).toString();

      dispatch(checkActionItemInputValueAndAfter(payload, 'aaveManageAdvancedForm', 'repayAmount', 'repay', collAsset, debtAsset));
    }

    dispatch({ type: GET_MAX_AAVE_REPAY_SUCCESS, payload });
  } catch (err) {
    dispatch({ type: GET_MAX_AAVE_REPAY_FAILURE, payload: err.message });
    captureException(err);
  }
};

/**
 * Handles redux actions for when the number of max amount of selected asset that can be withdrawn is calculating
 *
 * @return {Function}
 */
export const getMaxWithdrawAction = (forRepayCheck = false) => (dispatch, getState) => {
  dispatch({ type: GET_MAX_AAVE_WITHDRAW_REQUEST });

  const { general: { walletType } } = getState();
  const { actionsSelectValues } = getState().aaveManage;
  const { assetsData } = getState().aaveManage.v1;
  const { usedAssets, borrowedUsd, liquidationLimitUsd } = getState().aaveManage[walletType.value].v1;
  const fromAsset = forRepayCheck ? actionsSelectValues.repayWithdraw : actionsSelectValues.withdraw;

  try {
    let payload = '0';

    if (fromAsset) {
      const { isBorrowed, isSupplied, value } = fromAsset;
      const usedAssetData = usedAssets[value];
      const { aavePrice } = getState().assets[value];
      const assetData = assetsData[value];

      let ratio;
      const onlyOneAssetUsed = Object.keys(usedAssets).length === 1;
      if (isBorrowed && isSupplied && onlyOneAssetUsed) ratio = 1.00011;
      else ratio = 1.01;

      payload = getMaxWithdraw(usedAssetData, aavePrice, assetData, borrowedUsd, liquidationLimitUsd, ratio);

      dispatch(checkActionItemInputValueAndAfter(payload, 'aaveManageCollateralForm', 'withdrawAmount', 'withdraw', fromAsset));
    }

    dispatch({ type: GET_MAX_AAVE_WITHDRAW_SUCCESS, payload });
  } catch (err) {
    dispatch({ type: GET_MAX_AAVE_WITHDRAW_FAILURE, payload: err.message });
    captureException(err);
  }
};

/**
 * Handles redux actions for when the number of max amount of selected asset that can be supplied is calculating
 *
 * @return {Function}
 */
export const getMaxSupplyAction = () => async (dispatch, getState) => {
  dispatch({ type: GET_MAX_AAVE_SUPPLY_REQUEST });

  try {
    const address = getState().general.account;
    const { actionsSelectValues } = getState().aaveManage;

    const fromAsset = actionsSelectValues.supply;

    const payload = await getAssetBalance(fromAsset.value, address);

    dispatch(checkActionItemInputValueAndAfter(payload, 'aaveManageCollateralForm', 'supplyAmount', 'supply', fromAsset));

    dispatch({ type: GET_MAX_AAVE_SUPPLY_SUCCESS, payload });
  } catch (err) {
    dispatch({ type: GET_MAX_AAVE_SUPPLY_FAILURE, payload: err.message });
    captureException(err);
  }
};

/**
 * Handles redux actions for when the number of max amount of selected asset that can be borrowed is calculating
 *
 * @return {Function}
 */
export const getMaxBorrowAction = (forBoostCheck = false) => async (dispatch, getState) => {
  dispatch({ type: GET_MAX_AAVE_BORROW_REQUEST });

  try {
    const address = getAddressForWalletType(getState);
    const { actionsSelectValues } = getState().aaveManage;
    const fromAsset = forBoostCheck ? actionsSelectValues.boostBorrow : actionsSelectValues.borrow;

    const { aavePrice } = getState().assets[fromAsset.value];
    const ethPrice = getState().assets.ETH.aavePrice;

    const payload = await getMaxBorrow(address, fromAsset.value, aavePrice, ethPrice, 1);


    dispatch(checkActionItemInputValueAndAfter(payload, 'aaveManageDebtForm', 'borrowAmount', 'borrow', fromAsset));

    dispatch({ type: GET_MAX_AAVE_BORROW_SUCCESS, payload });
  } catch (err) {
    dispatch({ type: GET_MAX_AAVE_BORROW_FAILURE, payload: err.message });
    captureException(err);
  }
};

/**
 * Handles redux actions for when the number of max amount of selected asset that can be repaid is calculating
 *
 * @return {Function}
 */
export const getMaxPaybackAction = () => async (dispatch, getState) => {
  dispatch({ type: GET_MAX_AAVE_PAYBACK_REQUEST });

  try {
    const { general: { walletType } } = getState();
    const { actionsSelectValues } = getState().aaveManage;
    const { usedAssets } = getState().aaveManage[walletType.value].v1;
    const fromAsset = actionsSelectValues.payback;

    let payload = '0';

    if (fromAsset) {
      const {
        borrowed, borrowedVariable, borrowedStable, interestMode,
      } = usedAssets[fromAsset.value];
      const assetBalance = await getAssetBalance(fromAsset.value, getState().general.account);

      if (interestMode === 'both') {
        payload = fromAsset.additionalLabel === 'Stable'
          ? (Dec(borrowedStable).gt(assetBalance) ? assetBalance : borrowedStable)
          : (Dec(borrowedVariable).gt(assetBalance) ? assetBalance : borrowedVariable);
      } else {
        payload = Dec(borrowed)
          .gt(assetBalance) ? assetBalance : borrowed;
      }
      dispatch(checkActionItemInputValueAndAfter(payload, 'aaveManageDebtForm', 'paybackAmount', 'payback', fromAsset));
    }

    dispatch({ type: GET_MAX_AAVE_PAYBACK_SUCCESS, payload });
  } catch (err) {
    dispatch({ type: GET_MAX_AAVE_PAYBACK_FAILURE, payload: err.message });
    captureException(err);
  }
};

