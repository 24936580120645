import React from 'react';
import PropTypes from 'prop-types';
import { getColorForRatio } from '../../../services/utils';
import withErrorFallback from '../../ErrorFallback/ErrorFallback';

import './RatioCircle.scss';

const RatioCircle = ({ ratio, min }) => (
  <div className="ratio-circle" style={{ backgroundColor: getColorForRatio(ratio, min) }} />
);

RatioCircle.defaultProps = {
  ratio: 0,
  min: 0,
};

RatioCircle.propTypes = {
  ratio: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default withErrorFallback(RatioCircle);
