import dfs from '@defisaver/sdk';
import { getAssetInfoByAddress, assetAmountInWei, getAssetInfo } from '@defisaver/tokens';
import cloneDeep from 'lodash/cloneDeep';
import RecipeAction from '../RecipeAction';
import {
  Amount, Asset, AssetAmount, InterestMode, Market, Source,
} from '../../components/Recipes/RecipeCreator/inputTypes';
import { getAaveMarketInfoFromValue } from '../../constants/aaveMarkets';
import { paybackAfterValues } from '../../actions/aaveActions/aaveManageAfterValues';
import { formatNumber } from '../../services/utils';
import PaybackIcon from '../recipeIcons/Payback.svg';
import { assetAmountInWeiIgnorePointer } from '../../services/recipeCreator/recipeActionUtils';
import { MAXUINT } from '../../constants/general';

export default class AaveV2PaybackAction extends RecipeAction {
  static prettyName = 'Pay debt back to Aave';

  static protocol = 'aavev2';

  static protocolPrettyName = 'Aave';

  static description = 'Pays back selected asset debt to Aave.';

  constructor(market = 'v2default', asset = 'DAI', from = 'wallet', amount = '', interestMode = '2') {
    super();
    this.inputs = [
      new Market('Market', market),
      new Asset('Asset', asset, getAaveMarketInfoFromValue(market).assets.map(asset => getAssetInfo(asset.replace(/^ETH/, 'WETH')))),
      new Source('Source', from),
      new Amount('Amount', amount),
      new InterestMode('Interest Mode', interestMode),
    ];
    this.output = new AssetAmount('output', 0, asset);
  }

  async getAfterValues(_balances = {}, returnValues = [], actions = [], _positions = {}, getState) {
    const positions = { ..._positions };
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const market = args[0];
    const proxy = 'proxy';
    const source = args[2];
    const asset = getAssetInfoByAddress(args[1]);
    const interestMode = args[4];
    if (!positions[`aaveV2_${market}`]) {
      const { assetsData } = getState().aaveManage[`${market}`];
      const { usedAssets } = getState().aaveManage[`${proxy}`][`${market}`];
      positions[`aaveV2_${market}`] = { usedAssets, assetsData };
    }
    const _asset = asset.symbol.replace('WETH', 'ETH');
    let amount = args[3];
    if (amount === 'All available') amount = positions[`aaveV2_${market}`].usedAssets[_asset]?.borrowed || '0';
    const { afterPosition, balances, returnValue } = await paybackAfterValues(
      {
        amount,
        firstAsset: _asset,
        from: args[2],
        interestMode,
      },
      {
        usedAssets: positions[`aaveV2_${market}`].usedAssets,
        assetsData: positions[`aaveV2_${market}`].assetsData,
        assets: getState().assets,
        source,
        walletType: { value: 'proxy' },
        account: getState().general.account,
        proxyAddress: getState().maker.proxyAddress,
      },
      _balances,
    );
    positions[`aaveV2_${market}`] = afterPosition;
    this.output.value = returnValue;
    this.output.asset = asset.symbol;
    return { returnValue: this.output, balances, positions };
  }

  async toDfsAction(getState) {
    const {
      general: { account },
      maker: { proxyAddress },
    } = getState();
    const from = this.inputs[2].value === 'wallet' ? account : proxyAddress;
    const amount = this.inputs[3].value === 'All available' ? MAXUINT : assetAmountInWeiIgnorePointer(this.inputs[3].value, this.inputs[1].asset);
    return new dfs.actions.aave.AavePaybackAction(
      getAaveMarketInfoFromValue(this.inputs[0].value).providerAddress,
      this.inputs[1].value,
      amount,
      this.inputs[4].value,
      from,
      proxyAddress,
    );
  }

  static getIcon() {
    return PaybackIcon;
  }

  _getPrettyName(actionCalls, actions) {
    const args = this.mapReturnValuesToArgs(actionCalls.map(a => a.returnValue), actions);
    return `Pay ${args[3] === 'All available' ? 'all' : formatNumber(args[3])} ${this.inputs[1].asset} back to Aave`;
  }

  getAsset() {
    return this.inputs[1].asset;
  }
}
