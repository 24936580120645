import Dec from 'decimal.js';
import dfs from '@defisaver/sdk';
import {
  assetAmountInWei, getAssetInfo, MAXUINT,
} from '@defisaver/tokens';
import { getExchangeOrder } from 'services/exchangeServiceV3';
import { ethToWeth } from '../services/utils';

import { getRaiIlkInfo } from '../services/reflexerServices/reflexerService';
import { getFLAction } from '../services/assetsService';

/**
 * Standard Reflexer Boost
 */
export const boost = async (debtAmount, price, slippagePercent, safe, proxyAddress, additionalActions) => {
  const collAsset = ethToWeth(safe.asset);
  const { orderData, value, extraGas } = await getExchangeOrder(safe.debtAsset, collAsset, debtAmount, price, slippagePercent, proxyAddress, false, false, true);
  const debtAmountWei = assetAmountInWei(debtAmount, safe.debtAsset);

  const recipe = new dfs.Recipe('recReflexerBoost', [
    ...additionalActions,
    new dfs.actions.reflexer.ReflexerGenerateAction(safe.id, debtAmountWei, proxyAddress),
    new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value),
  ]);
  const previousActionId = `$${recipe.actions.length}`;
  recipe.addAction(new dfs.actions.reflexer.ReflexerSupplyAction(safe.id, previousActionId, getRaiIlkInfo(safe.collType).join, proxyAddress));
  recipe.extraGas = extraGas;
  return recipe;
};

/**
 * Reflexer FL Boost
 */
export const boostWithDebtLoan = async (debtAmount, price, slippagePercent, safe, proxyAddress, flProtocol, additionalActions) => {
  const collAsset = ethToWeth(safe.asset);
  const { orderData, value, extraGas } = await getExchangeOrder(safe.debtAsset, collAsset, debtAmount, price, slippagePercent, proxyAddress, false, false, true, flProtocol === 'balancer' ? ['Balancer_V2'] : []);
  const debtAmountWei = assetAmountInWei(debtAmount, safe.debtAsset);

  const { FLAction, paybackAddress } = getFLAction(flProtocol, debtAmountWei, safe.debtAsset);

  const recipe = new dfs.Recipe('recReflexerFLBoost', [
    FLAction,
    new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value),
    new dfs.actions.reflexer.ReflexerSupplyAction(safe.id, '$2', getRaiIlkInfo(safe.collType).join, proxyAddress),
    ...additionalActions,
    new dfs.actions.reflexer.ReflexerGenerateAction(safe.id, '$1', paybackAddress),
  ]);
  recipe.extraGas = extraGas;
  return recipe;
};


/**
 * Standard Reflexer Repay
 */
export const repay = async (collAmount, price, slippagePercent, safe, proxyAddress, account, additionalActions) => {
  const collAsset = ethToWeth(safe.asset);
  const { orderData, value, extraGas } = await getExchangeOrder(collAsset, safe.debtAsset, collAmount, price, slippagePercent, proxyAddress, false, false, true);
  const collAmountWei = assetAmountInWei(collAmount, safe.asset);
  const payingBackAllDebt = new Dec(collAmount * price).gte(safe.debtInAsset);

  const recipe = new dfs.Recipe('recReflexerRepay', [
    new dfs.actions.reflexer.ReflexerWithdrawAction(safe.id, collAmountWei, getRaiIlkInfo(safe.collType).join, proxyAddress),
    new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value),
    new dfs.actions.reflexer.ReflexerPaybackAction(safe.id, '$2', proxyAddress),
  ]);
  // If we're repaying the whole debt, we'll be buying a bit more than the required debt amount
  // ReflexerPaybackAction will pay back an amount up to the total debt, and leave the rest on the proxy
  if (payingBackAllDebt) recipe.addAction(new dfs.actions.basic.SendTokenAction(getAssetInfo(safe.debtAsset).address, account, MAXUINT));
  additionalActions.forEach((a) => recipe.addAction(a));
  recipe.extraGas = extraGas;
  return recipe;
};


/**
 * Reflexer FL Repay
 */
export const repayWithCollLoan = async (collAmount, maxWithdraw, price, slippagePercent, safe, proxyAddress, account, flProtocol, additionalActions) => {
  const collAsset = ethToWeth(safe.asset);
  const { orderData, value, extraGas } = await getExchangeOrder(collAsset, safe.debtAsset, collAmount, price, slippagePercent, proxyAddress, false, false, true, flProtocol === 'balancer' ? ['Balancer_V2'] : []);

  const collAmountWei = assetAmountInWei(collAmount, safe.asset);
  const maxWithdrawWei = assetAmountInWei(maxWithdraw, safe.asset);
  const payingBackAllDebt = new Dec(collAmount * price).gte(safe.debtInAsset);

  const recipe = new dfs.Recipe('recReflexerFLRepayViaColl', []);

  // Aave flashloans have a fee, so we minimize the loan amount/fee, at the cost of gas
  // (except when cdp is under dust limit because withdraw is then impossible)
  const partialFl = flProtocol === 'aave' && !safe.debtTooLow;

  let flPaybackAddr;
  if (partialFl) {
    const flAmount = new Dec(collAmountWei).sub(maxWithdrawWei).toString();
    const { FLAction, paybackAddress } = getFLAction(flProtocol, flAmount, ethToWeth(safe.asset));
    flPaybackAddr = paybackAddress;
    recipe.addAction(FLAction);
    recipe.addAction(new dfs.actions.reflexer.ReflexerWithdrawAction(safe.id, maxWithdrawWei, getRaiIlkInfo(safe.collType).join, proxyAddress));
  } else {
    const { FLAction, paybackAddress } = getFLAction(flProtocol, collAmountWei, ethToWeth(safe.asset));
    flPaybackAddr = paybackAddress;
    recipe.addAction(FLAction);
  }
  recipe.addAction(new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value));
  const previousActionId = `$${recipe.actions.length}`;
  recipe.addAction(new dfs.actions.reflexer.ReflexerPaybackAction(safe.id, previousActionId, proxyAddress));
  recipe.addAction(new dfs.actions.reflexer.ReflexerWithdrawAction(safe.id, '$1', getRaiIlkInfo(safe.collType).join, flPaybackAddr));
  if (payingBackAllDebt) recipe.addAction(new dfs.actions.basic.SendTokenAction(getAssetInfo(safe.debtAsset).address, account, MAXUINT));
  additionalActions.forEach((a) => recipe.addAction(a));
  recipe.extraGas = extraGas;
  return recipe;
};
