import t from 'translate';
import { getInstaAccount, getInstaV2Account } from '../services/contractRegistryService';
import { sendTx } from './txNotificationActions';
import callTx from '../services/txService';
import { requireAddress, requireOwner } from '../services/utils';

const authorizeProxyForInstadappAccountV1 = async (dsaAccount, account, accountType, proxyAddress, path, sendTxFunc) => {
  const instaAccount = getInstaAccount(dsaAccount);

  if (!(await instaAccount.methods.isAuth(proxyAddress).call())) {
    const funcParams = [[], [], '0x0000000000000000000000000000000000000000'];
    funcParams[0].push('0xd1aff9f2acf800c876c409100d6f39aea93fc3d9');
    const authCallData = window._web3.eth.abi.encodeFunctionCall(
      {
        inputs: [{ internalType: 'address', name: 'authority', type: 'address' }], name: 'add', outputs: [], stateMutability: 'payable', type: 'function',
      },
      [proxyAddress],
    );
    funcParams[1].push(authCallData);
    const txParams = { from: account };
    return callTx(accountType, path, sendTxFunc, instaAccount, 'cast', funcParams, txParams);
  }
};
const authorizeProxyForInstadappAccountV2 = async (dsaAccount, account, accountType, proxyAddress, path, sendTxFunc) => {
  const instaAccount = getInstaV2Account(dsaAccount);

  if (!(await instaAccount.methods.isAuth(proxyAddress).call())) {
    const funcParams = [[], [], account];
    funcParams[0].push('AUTHORITY-A');
    const authCallData = window._web3.eth.abi.encodeFunctionCall(
      {
        inputs: [{ internalType: 'address', name: 'authority', type: 'address' }], name: 'add', outputs: [], stateMutability: 'payable', type: 'function',
      },
      [proxyAddress],
    );
    funcParams[1].push(authCallData);
    const txParams = { from: account };
    return callTx(accountType, path, sendTxFunc, instaAccount, 'cast', funcParams, txParams);
  }
};

/**
 * Checks if DSProxy is authorized, and if not authorizes it for using DSA
 *
 * @param dsaAccount {string}
 * @param dsaVersion {number}
 * @return {function(...[*]=)}
 */
export const authorizeProxyForInstadappAccount = (dsaAccount, dsaVersion) => async (dispatch, getState) => {
  const { general: { account, accountType, path }, maker: { proxyAddress } } = getState();
  requireAddress(dsaAccount);
  await requireOwner(proxyAddress, account);
  const sendTxFunc = promise => sendTx(promise, t('insta.authorize_proxy_for_dsa'), 'Instadapp', dispatch, getState);
  if (dsaVersion === 1) {
    return authorizeProxyForInstadappAccountV1(dsaAccount, account, accountType, proxyAddress, path, sendTxFunc);
  }
  return authorizeProxyForInstadappAccountV2(dsaAccount, account, accountType, proxyAddress, path, sendTxFunc);
};
