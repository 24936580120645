// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./nexus-icon.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nexus-shield-wrapper{display:inline-block;height:29px;width:29px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-position:center}\n", "",{"version":3,"sources":["webpack://src/components/Decorative/NexusShield/NexusShield.scss"],"names":[],"mappings":"AAAA,sBAAsB,oBAAoB,CAAC,WAAW,CAAC,UAAU,CAAC,wDAAwC,CAAC,2BAA2B,CAAC,0BAA0B","sourcesContent":[".nexus-shield-wrapper{display:inline-block;height:29px;width:29px;background-image:url(\"./nexus-icon.svg\");background-repeat:no-repeat;background-position:center}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
