import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Dec from 'decimal.js';
import t from 'translate';
import { getBoostModalData, resetBoostModal, boostAction } from '../../../../actions/liquityActions/liquityManageActions';

import { LiquitySlippageLimitForm as SlippageLimitForm } from '../../BoostRepayCommon/SlippageLimitForm/SlippageLimitForm';
import TradeSizeImpactWarning from '../../../TradeSizeImpactWarning/TradeSizeImpactWarning';
import TooltipWrapper from '../../../TooltipWrapper/TooltipWrapper';
import ModalHeader from '../../ModalHeader';
import ModalBody from '../../ModalBody';
import Loader from '../../../Loader/Loader';
import ErrorBox from '../../../Decorative/ErrorBox/ErrorBox';
import InfoIcon from '../../../Decorative/InfoIcon';
import ActionInfo from '../../BoostRepayCommon/ActionInfo';
import AdvancedOptions from '../../BoostRepayCommon/AdvancedOptions/AdvancedOptions';
import AdditionalInfo from '../../../AdditionalInfo/AdditionalInfo';

const LiquityBoostModal = ({
  getBoostModalData, resetBoostModal, closeModal, gettingBoostModalData, gettingBoostModalDataError, inputAmount,
  boostAmount, boostExchangeRate, boosting, boostAction, tradeSizeImpact, supplyAsset, borrowAsset, boostingError,
  useFl, flProtocol, exchangeSource, flFee,
}) => {
  useEffect(() => {
    getBoostModalData(inputAmount);
    return resetBoostModal;
  }, []);
  const [advanced, setAdvanced] = useState(false);

  const shouldFlip = new Dec(boostExchangeRate).lt(new Dec(1).div(boostExchangeRate).toString());

  const exchangeLabel = shouldFlip
    ? `${borrowAsset}/${supplyAsset}`
    : `${supplyAsset}/${borrowAsset}`;
  const correctedBoostExchangeRate = shouldFlip
    ? new Dec(1).div(boostExchangeRate).toString()
    : boostExchangeRate;

  return (
    <div className="boost-repay-modal">
      <ModalHeader closeModal={closeModal} />

      <ModalBody>
        <div className="new-modal-top-wrapper">
          <h1>
            <TooltipWrapper title={t('maker.boost_modal_description', { '%daiLabel': borrowAsset, '%asset': supplyAsset, CDP: 'Trove' })}>
              <InfoIcon /> {t('common.boost')}
            </TooltipWrapper>
          </h1>
        </div>

        <div className="new-modal-content-wrapper">

          {gettingBoostModalData && (<div className="container"><div className="loading-wrapper"><Loader /></div></div>)}

          {!gettingBoostModalData && gettingBoostModalDataError && (<ErrorBox>{gettingBoostModalDataError}</ErrorBox>)}

          {!gettingBoostModalData && !gettingBoostModalDataError && (
            <>
              <ActionInfo
                firstAsset={borrowAsset}
                firstAmount={inputAmount}
                secondAsset={supplyAsset}
                secondAmount={boostAmount}
                exchangeRate={correctedBoostExchangeRate}
                exchangeLabel={exchangeLabel}
              />

              <div className="advanced__wrapper container">
                <TradeSizeImpactWarning tradeSizeImpact={tradeSizeImpact} />
                <AdvancedOptions
                  borrowAsset={borrowAsset}
                  supplyAsset={supplyAsset}
                  advanced={advanced}
                  setAdvanced={setAdvanced}
                  exchangeRate={boostExchangeRate}
                  SlippageLimitForm={SlippageLimitForm}
                />
                <AdditionalInfo
                  flashloanSource={useFl ? flProtocol : ''}
                  flashloanFee={flFee}
                  exchangeSource={exchangeSource}
                  asset1={borrowAsset}
                  asset2={supplyAsset}
                />
                {boostingError && (<ErrorBox marginTop>{boostingError}</ErrorBox>)}
              </div>
            </>
          )}
        </div>
      </ModalBody>

      <div className="modal-controls">
        <button
          form="liquity-boost-modal-form"
          type="button"
          disabled={boosting || gettingBoostModalData}
          onClick={() => boostAction(inputAmount, closeModal)}
          className="button green"
        >
          {boosting ? t('common.boosting') : t('common.boost')}
        </button>
      </div>
    </div>
  );
};

LiquityBoostModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  tradeSizeImpact: PropTypes.string.isRequired,

  getBoostModalData: PropTypes.func.isRequired,
  gettingBoostModalData: PropTypes.bool.isRequired,
  gettingBoostModalDataError: PropTypes.string.isRequired,

  inputAmount: PropTypes.string.isRequired,
  boostAmount: PropTypes.string.isRequired,
  boostExchangeRate: PropTypes.string.isRequired,

  boostAction: PropTypes.func.isRequired,
  resetBoostModal: PropTypes.func.isRequired,
  boosting: PropTypes.bool.isRequired,

  borrowAsset: PropTypes.string.isRequired,
  supplyAsset: PropTypes.string.isRequired,

  boostingError: PropTypes.string.isRequired,

  useFl: PropTypes.bool.isRequired,
  flProtocol: PropTypes.string.isRequired,
  flFee: PropTypes.string.isRequired,
  exchangeSource: PropTypes.string.isRequired,
};

const mapStateToProps = ({ liquityManage, liquity }) => ({
  tradeSizeImpact: liquityManage.tradeSizeImpact,
  gettingBoostModalData: liquityManage.gettingBoostModalData,
  gettingBoostModalDataError: liquityManage.gettingBoostModalDataError,
  boostAmount: liquityManage.boostAmount,
  boostExchangeRate: liquityManage.boostExchangeRate,
  boosting: liquityManage.boosting,
  boostingError: liquityManage.boostingError,
  borrowAsset: liquity.proxy.debtAsset,
  supplyAsset: liquity.proxy.asset,
  useFl: liquityManage.useFl,
  flProtocol: liquityManage.flProtocol,
  flFee: liquityManage.flFee,
  exchangeSource: liquityManage.exchangeSource,
});

const mapDispatchToProps = {
  getBoostModalData, resetBoostModal, boostAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(LiquityBoostModal);
