import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { connect } from 'react-redux';
import { getAssetInfo, reflexerCollTypes } from '@defisaver/tokens';
import { SelectOption, SelectValueContainer } from 'components/Forms/SelectComponent/SelectComponent';
import { getOpenCdpOptions } from '../../../../../../../../actions/makerActions/makerManageActions/makerManageMcdActions';
import withErrorFallback from '../../../../../../../ErrorFallback/ErrorFallback';

const IlkActionField = ({
  i, actualValue, handleChange, getOpenCdpOptions, cdpOptions, protocol,
}) => {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    (async () => {
      let ilkOptions;
      if (protocol === 'maker') ilkOptions = cdpOptions;
      if (!ilkOptions?.length) {
        if (protocol === 'maker') ilkOptions = await getOpenCdpOptions();
        if (protocol === 'reflexer') {
          ilkOptions = reflexerCollTypes.map((ilk) => ({ ...ilk, ...getAssetInfo(ilk.asset) })).map((o) => ({
            ...o,
            value: o.ilkLabel,
            label: o.ilkLabel,
            meta: { icon: o.icon },
          }));
        }
      }
      setOptions(ilkOptions);
    })();
  }, [cdpOptions]);

  return (
    <Select
      className="select box"
      classNamePrefix="select"
      value={options.find(i => i.value === actualValue) || null}
      components={{
        Option: SelectOption,
        ValueContainer: SelectValueContainer,
      }}
      onChange={(e) => handleChange({ target: e }, i)}
      options={options}
      onBlur={event => event.preventDefault()}
    />
  );
};

IlkActionField.defaultProps = {
  actualValue: '',
};

IlkActionField.propTypes = {
  handleChange: PropTypes.func.isRequired,
  i: PropTypes.number.isRequired,
  actualValue: PropTypes.string,
  getOpenCdpOptions: PropTypes.func.isRequired,
  cdpOptions: PropTypes.array.isRequired,
  protocol: PropTypes.string.isRequired,
};

const mapStateToProps = ({ maker }) => ({
  cdpOptions: maker.cdpOptions,
});

const mapDispatchToProps = {
  getOpenCdpOptions,
};

export default withErrorFallback(connect(mapStateToProps, mapDispatchToProps)(IlkActionField));
