import dfs from '@defisaver/sdk';
import { getAssetInfo, getAssetInfoByAddress } from '@defisaver/tokens';
import { exchangeAssets } from 'constants/assets';
import RecipeAction from '../RecipeAction';
import {
  Amount, Asset, Source, AssetAmount, Slippage,
} from '../../components/Recipes/RecipeCreator/inputTypes';
import { exchangeSellAfterValues } from '../../actions/exchangeActions';
import { getBestExchangePrice, getExchangeOrder } from '../../services/exchangeServiceV3';
import SellIcon from '../recipeIcons/Sell.svg';
import { formatNumber } from '../../services/utils';
import { assetAmountInWeiIgnorePointer } from '../../services/recipeCreator/recipeActionUtils';
import { DEFAULT_SLIPPAGE_PERCENT } from '../../constants/general';
import BalancerFlashLoanAction from '../flashloan/BalancerFlashLoanAction';

const _exchangeAssets = [...exchangeAssets()];
_exchangeAssets[0] = getAssetInfo('WETH');

export default class SellAction extends RecipeAction {
  static prettyName = 'Sell';

  static description = 'Sells selected amount of chosen token for the target token.';

  constructor(from = 'DAI', source = 'wallet', amount = '', to = 'WETH', toSource = 'recipe', slippage = DEFAULT_SLIPPAGE_PERCENT) {
    super();
    this.inputs = [
      new Asset('Sell token', from, _exchangeAssets),
      new Source('From', source),
      new Amount('Sell', amount),
      new Asset('Buy token', to, _exchangeAssets),
      new Source('To', toSource, true),
      new Slippage('Slippage tolerance', slippage),
    ];
    this.output = new AssetAmount('output', 0, to);
  }

  async getAfterValues(_balances = {}, returnValues = [], actions = [], positions = {}, getState) {
    const { proxyAddress } = getState().maker;
    const { account } = getState().general;
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const amount = args[2];
    const fromAsset = getAssetInfoByAddress(args[0]).symbol;
    const toAsset = getAssetInfoByAddress(args[3]).symbol;
    const { returnValue, balances, cached } = await exchangeSellAfterValues(
      {
        amount,
        firstAsset: fromAsset,
        secondAsset: toAsset,
        source: args[1],
        toSource: args[4],
        slippage: args[5],
      },
      { proxyAddress, account },
      _balances,
    );
    if (!cached) this.updatedAt = Date.now();
    this.output.value = returnValue;
    this.output.asset = toAsset;
    return { returnValue: this.output, balances, positions };
  }


  async toDfsAction(getState, actions, returnValues) {
    const {
      general: { account },
      maker: { proxyAddress },
    } = getState();
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const isFLBalancer = actions[0].constructor === BalancerFlashLoanAction;
    const { price } = await getBestExchangePrice(args[2], this.inputs[0].asset, this.inputs[3].asset, account, true, true, true);
    const { orderData, value } = await getExchangeOrder(this.inputs[0].asset, this.inputs[3].asset, args[2], price, this.inputs[5].value, account, false, false, true, isFLBalancer ? ['Balancer_V2'] : []);
    console.log(value);
    orderData[2] = assetAmountInWeiIgnorePointer(this.inputs[2].value, this.inputs[0].asset);
    const from = this.inputs[1].value === 'wallet' ? account : proxyAddress;
    const to = this.inputs[4].value === 'wallet' ? account : proxyAddress;
    return new dfs.actions.basic.SellAction(orderData, from, to, value);
  }

  static getIcon() {
    return SellIcon;
  }

  _getPrettyName(actionCalls, actions) {
    const args = this.mapReturnValuesToArgs(actionCalls.map(a => a.returnValue), actions);
    const id = this.id;
    const thisActionIndex = actions.findIndex((a) => a.id === id);
    const returnOfThisAction = actionCalls[thisActionIndex]?.returnValue;
    if (thisActionIndex !== -1 && returnOfThisAction) {
      return `Sell ${formatNumber(args[2])} ${this.inputs[0].asset} for min. ${formatNumber(returnOfThisAction.value)} ${this.inputs[3].asset}`;
    }
    return `Sell ${formatNumber(args[2])} ${this.inputs[0].asset} for ${this.inputs[3].asset}`;
  }

  getExpires() {
    if (this.updatedAt) {
      return this.updatedAt + (2 * 60 * 1000);
    }
    return Infinity;
  }
}
