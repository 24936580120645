/* eslint-disable */
import React from 'react';

const ArrowCaretThick = ({ size = 18, color = '#61717E', to = 'down', className = '' }) => (
  <svg
    className={`arrow-caret-thick-icon ${className}`}
    style={{ transform: `rotate(${{ up: 0, left: 90, down: 180, right: 270 }[to]}deg)` }}
    width={size} height={size-4} viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 5L4.92929 1.07071C4.96834 1.03166 5.03166 1.03166 5.07071 1.07071L9 5" stroke={color} strokeLinecap="round" strokeWidth={2}/>
  </svg>
);

export default ArrowCaretThick;
