import { CONNECT_PROVIDER_SUCCESS } from '../../actionTypes/generalActionTypes';
import * as rflxManageTypes from '../../actionTypes/reflexerActionTypes/reflexerManageActionTypes';
import {
  RESET_BR_SLIPPAGE_VALUES,
  SET_BR_SLIPPAGE_PERCENT,
} from '../../actionTypes/modalActionTypes';

const INITIAL_STATE = {
  generating: false,
  generatingError: '',

  maxGenerate: '0',
  gettingMaxGenerate: false,
  gettingMaxGenerateError: '',

  withdrawing: false,
  withdrawingError: '',

  maxWithdraw: '0',
  gettingMaxWithdraw: false,
  gettingMaxWithdrawError: '',

  addingCollateral: false,
  addingCollateralError: '',

  maxAddCollateral: '0',
  gettingMaxAddCollateral: false,
  gettingMaxAddCollateralError: '',

  payingBack: false,
  payingBackError: '',

  repaying: false,
  repayingError: '',

  maxRepay: '0',
  gettingMaxRepay: false,
  gettingMaxRepayError: '',

  maxPayback: '0',
  gettingMaxPayback: false,
  gettingMaxPaybackError: '',

  repayAmount: '0',
  repayExchangeRate: '0',
  gettingRepayModalData: false,
  gettingRepayModalDataError: '',

  boosting: false,
  boostingError: '',

  maxBoost: '0',
  gettingMaxBoost: false,
  gettingMaxBoostError: '',

  boostAmount: '0',
  boostExchangeRate: '0',
  gettingBoostModalData: false,
  gettingBoostModalDataError: '',

  slippagePercent: '0',
  slippageRate: '0',
  tradeSizeImpact: '0',

  afterType: '',
  afterCdp: null,
  gettingAfterCdp: false,
  gettingAfterCdpFailure: '',
  ratioTooLow: false,
  ratioTooHigh: false,

  useFl: false,
  flProtocol: 'none',
  flFee: '0',
  useAltRecipe: false,
  exchangeSource: '',

  maxValues: {},
  loadingMaxValues: {},
  maxValuesErrors: {},

  selectedAction: { label: 'None', value: '' },
  selectedAdditionalActions: {},

  executing: {},
  executingErrors: {},
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case rflxManageTypes.RFL_GET_BOOST_MODAL_DATA_REQUEST:
      return {
        ...state,
        gettingBoostModalData: true,
        gettingBoostModalDataError: '',
      };

    case rflxManageTypes.RFL_GET_BOOST_MODAL_DATA_SUCCESS:
      return {
        ...state,
        gettingBoostModalData: false,
        gettingBoostModalDataError: '',
        ...payload,
      };

    case rflxManageTypes.RFL_GET_BOOST_MODAL_DATA_FAILURE:
      return {
        ...state,
        gettingBoostModalData: false,
        gettingBoostModalDataError: payload,
      };

    case rflxManageTypes.RFL_RESET_BOOST_MODAL:
      return {
        ...state,
        boosting: false,
        boostingError: '',
        boostAmount: '0',
        boostExchangeRate: '0',
        gettingBoostModalData: false,
        gettingBoostModalDataError: '',
      };

    case rflxManageTypes.GET_REPAY_MODAL_DATA_REQUEST:
      return {
        ...state,
        gettingRepayModalData: true,
        gettingRepayModalDataError: '',
      };

    case rflxManageTypes.GET_REPAY_MODAL_DATA_SUCCESS:
      return {
        ...state,
        gettingRepayModalData: false,
        gettingRepayModalDataError: '',
        ...payload,
      };

    case rflxManageTypes.GET_REPAY_MODAL_DATA_FAILURE:
      return {
        ...state,
        gettingRepayModalData: false,
        gettingRepayModalDataError: payload,
      };

    case rflxManageTypes.RFL_RESET_REPAY_MODAL:
      return {
        ...state,
        repaying: false,
        repayingError: '',
        gettingRepayModalData: false,
        gettingRepayModalDataError: '',
      };

    case SET_BR_SLIPPAGE_PERCENT:
      return {
        ...state,
        slippagePercent: payload.slippagePercent,
        slippageRate: payload.slippageRate,
      };

    case RESET_BR_SLIPPAGE_VALUES:
      return {
        ...state,
        slippagePercent: '0',
        slippageRate: '0',
        tradeSizeImpact: '0',
      };

    case rflxManageTypes.RFL_SET_DASHBOARD_ACTION:
      return {
        ...state,
        selectedAction: payload,
        afterCdp: null,
      };

    case rflxManageTypes.RFL_GET_MAX_VALUE_REQUEST:
      return {
        ...state,
        loadingMaxValues: {
          ...state.loadingMaxValues,
          [action.forAction]: true,
        },
        maxValuesErrors: {
          ...state.loadingMaxValues,
          [action.forAction]: '',
        },
      };

    case rflxManageTypes.RFL_GET_MAX_VALUE_SUCCESS:
      return {
        ...state,
        loadingMaxValues: {
          ...state.loadingMaxValues,
          [action.forAction]: false,
        },
        maxValues: {
          ...state.maxValues,
          [action.forAction]: payload,
        },
      };

    case rflxManageTypes.RFL_GET_MAX_VALUE_FAILURE:
      return {
        ...state,
        loadingMaxValues: {
          ...state.loadingMaxValues,
          [action.forAction]: false,
        },
        maxValuesErrors: {
          ...state.loadingMaxValues,
          [action.forAction]: payload,
        },
      };

    case rflxManageTypes.RFL_SET_ADDITIONAL_DASHBOARD_ACTION:
      return {
        ...state,
        selectedAdditionalActions: {
          ...state.selectedAdditionalActions,
          [state.selectedAction.value]: payload,
        },
        afterCdp: null,
      };

    case rflxManageTypes.RFL_ACTION_EXEC_REQUEST:
      return {
        ...state,
        executing: {
          ...state.executing,
          [action.action]: true,
        },
        executingErrors: {
          ...state.executingErrors,
          [action.action]: '',
        },
      };

    case rflxManageTypes.RFL_ACTION_EXEC_SUCCESS:
      return {
        ...state,
        executing: {
          ...state.executing,
          [action.action]: false,
        },
      };

    case rflxManageTypes.RFL_ACTION_EXEC_FAILURE:
      return {
        ...state,
        executing: {
          ...state.executing,
          [action.action]: false,
        },
        executingErrors: {
          ...state.executingErrors,
          [action.action]: payload,
        },
      };

    case rflxManageTypes.RFL_GET_AFTER_CDP_REQUEST:
      return { ...state, gettingAfterCdp: true, gettingAfterCdpError: '' };

    case rflxManageTypes.RFL_GET_AFTER_CDP_SUCCESS:
      return {
        ...state,
        gettingAfterCdp: false,
        gettingAfterCdpError: '',
        ...payload,
      };

    case rflxManageTypes.RFL_GET_AFTER_CDP_FAILURE:
      return {
        ...state,
        gettingAfterCdp: false,
        gettingAfterCdpError: payload,
      };

    case CONNECT_PROVIDER_SUCCESS:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};
