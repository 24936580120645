import Dec from 'decimal.js';
import { getAssetInfo, getAssetInfoByAddress } from '@defisaver/tokens';
import { isAddress } from './utils';

/**
 * @param price {String} Price returned by contract or getOffchainPrice
 * @param from {String} Symbol for asset being sold
 * @param to {String} Symbol for asset being bought
 * @return {String} Price in expected format (11000.00 for WBTC->USDT, 0.98 for USDc->DAI, etc)
 */
export const parsePriceFromContract = (price, from, to) => new Dec(price)
  .div(10 ** getAssetInfo(to).decimals)
  .div(10 ** (18 - getAssetInfo(from).decimals))
  .toString();

export const parsePriceWithDecimalsFromContract = (price, fromDecimals, toDecimals) => new Dec(price)
  .div(10 ** toDecimals)
  .div(10 ** (18 - fromDecimals))
  .toString();

/**
 * @param price {String} Price returned by parsePriceFromContract
 * @param from {String} Symbol for asset being sold
 * @param to {String} Symbol for asset being bought
 * @return {String} Price formatted like contract output (can be used for contract input for exchange v2)
 */
export const formatPriceForContract = (price, from, to) => new Dec(price)
  .mul(10 ** getAssetInfo(to).decimals)
  .mul(10 ** (18 - getAssetInfo(from).decimals))
  .floor()
  .toString();

export const formatPriceWithDecimalForContract = (price, fromDecimals, toDecimals) => new Dec(price)
  .mul(10 ** toDecimals)
  .mul(10 ** (18 - fromDecimals))
  .floor()
  .toString();

/**
 * Used for `getSlippageThreshold` and `shouldSkipToOnchain`
 * @param _from {string} asset ('ETH')
 * @returns {number}
 */
export const getThresholdAmountForAsset = (_from) => {
  const from = isAddress(_from) ? getAssetInfoByAddress(_from).symbol : _from;
  let amount = 5000;
  if (from === 'MANA') amount = 2000;
  if (['LRC', 'ENJ', 'BAT', 'REN'].includes(from)) amount = 1000;
  if (from === 'ZRX') amount = 750;
  if (from === 'ENS') amount = 100;
  if (from === 'REP' || from === 'LINK' || from === 'UNI') amount = 25;
  if (from === 'INST') amount = 50;
  if (from === 'COMP' || from === 'AAVE') amount = 10;
  if (from === 'ETH' || from === 'MKR' || from === 'WETH' || from === 'stETH' || from === 'wstETH') amount = 2;
  if (from === 'WBTC' || from === 'YFI' || from === 'RENBTC') amount = 0.015;
  return amount;
};

export const setSlippagePercent = (slippagePercent, estimatedPrice) => new Dec(estimatedPrice)
  .minus(new Dec(estimatedPrice).div(100).times(slippagePercent))
  .toString();

export const normalizeFunc = (_val) => {
  const val = +_val;
  if (val < 0) return 0;
  if (val > 100) return 100;
  return val;
};
