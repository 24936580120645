import React, { useState, useEffect } from 'react';
import t from 'translate';
import PropTypes from 'prop-types';
import Dec from 'decimal.js';
import { connect } from 'react-redux';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';

import { parseTimestamp } from '../../services/utils';
import { getGasPrices } from '../../actions/generalActions';
import { get1559GasPriceHistoryApi, get1559GasPrice, get1559GasPriceStatus } from '../../services/apiService';
import { useInterval } from '../../services/hooks';

import { chartOptions } from './graphSettings';

import OutsideAlerter from '../OutsideAlerter/OutsideAlerter';
import Loader from '../Loader/Loader';
import ErrorBox from '../Decorative/ErrorBox/ErrorBox';
import withErrorFallback from '../ErrorFallback/ErrorFallback';
import Toggle from '../Toggle/Toggle';
import InfoIcon from '../Decorative/InfoIcon';
import TooltipWrapper from '../TooltipWrapper/TooltipWrapper';
import Caret from '../Decorative/Caret';

import './GasPriceStatusWidget.scss';

const getGasPriceStatus = (fastPrice) => {
  if (!fastPrice) return '';
  if (fastPrice < 40) return 'low';
  if (fastPrice < 80) return 'mid';
  return 'high';
};

const getEIP1559StatusIconAndColor = (status) => {
  if (status === 'Surging') return { icon: <Caret size={9} orientation="up" color="#C02727" />, color: '#C02727', tooltip: 'The base fee has grown by more than 50% within the last 5 minutes.' };
  if (status === 'Growing') return { icon: <Caret size={9} orientation="up" color="#F55858" />, color: '#F55858', tooltip: 'The base fee has grown by more than 25% within the last 5 minutes.' };
  if (status === 'Stable') return { icon: <></>, color: '#939DA7', tooltip: 'The base fee hasn\'t increased or decreased by more than 25% within the last 5 minutes.' };
  if (status === 'Declining') return { icon: <Caret size={9} color="#37B06F" />, color: '#37B06F', tooltip: 'The base fee has dropped by more than 25% within the last 5 minutes.' };
  if (status === 'Falling') return { icon: <Caret size={9} color="#239C5B" />, color: '#239C5B', tooltip: 'The base fee has dropped by more than 50% within the last 5 minutes.' };
  return { icon: <></>, color: '', tooltip: '' };
};

const extensionStoreLink = navigator.userAgent.includes('Firefox')
  ? 'https://addons.mozilla.org/en-US/firefox/addon/defi-saver-gas-prices/'
  : 'https://chrome.google.com/webstore/detail/defi-saver-gas-prices-ext/afgfdkloegmghldbalmenklokhlifphe';

const FeeBoxItem = ({
  label, value, small, hasLoader, clicked, inList,
}) => {
  const item = (
    <>{+value === 0 && <Loader size={small ? 3 : 4} />}
      {+value !== 0 && (
      <>
        <p>{value}</p>
        {label && <span>{label}</span>}
      </>
      )}
    </>
  );
  const cssClasses = `fee-box__item ${+value !== 0 && hasLoader ? 'hasLoader' : ''} ${small ? 'small' : ''}`;
  if (inList) {
    return (<li onClick={clicked} className={cssClasses}>{item}</li>);
  }
  return (<div onClick={clicked} className={cssClasses}>{item}</div>);
};

FeeBoxItem.defaultProps = {
  label: '',
  small: false,
  hasLoader: false,
  inList: false,
  clicked: () => {},
};

FeeBoxItem.propTypes = {
  label: PropTypes.string,
  small: PropTypes.bool,
  hasLoader: PropTypes.bool,
  clicked: PropTypes.func,
  inList: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

const FeeBox = ({
  title, tooltip, status, children,
}) => (
  <div className="fee-box__wrapper">
    <div className="fee-box__header">
      <h4 className="fee-box__title">
        {tooltip && <TooltipWrapper title={tooltip}><InfoIcon /></TooltipWrapper>} <p>{title}</p>
      </h4>
      {status && (
        <div className="fee-box__status" style={{ color: getEIP1559StatusIconAndColor(status).color }}>
          <TooltipWrapper title={getEIP1559StatusIconAndColor(status).tooltip}>
            {getEIP1559StatusIconAndColor(status).icon}{status}
          </TooltipWrapper>
        </div>
      )}
    </div>
    {children}
  </div>
);

FeeBox.defaultProps = {
  tooltip: '',
  status: '',
};

FeeBox.propTypes = {
  status: PropTypes.string,
  tooltip: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const GasPriceStatusWidget = ({
  gasPrices, gettingGasPrices, gettingGasPricesError, gasPriceHistory, gettingGasPriceHistory, gettingGasPriceHistoryError,
  getGasPrices,
}) => {
  const [open, setOpen] = useState(false);
  const [isEIP1559, setIsEIP1559] = useState(true);
  const [baseFee, setBaseFee] = useState(0);
  const [EIP1559Status, setEIP1559Status] = useState('');
  const [history, setHistory] = useState([]);
  const [historyEIP1559, setHistoryEIP1559] = useState([]);
  const [estimatedPrices, setEstimatedPrices] = useState([
    { confidence: 99, maxPriorityFeePerGas: 0 },
    { confidence: 90, maxPriorityFeePerGas: 0 },
    { confidence: 70, maxPriorityFeePerGas: 0 },
  ]);

  const fetchData = async (getHistory) => {
    let _history = historyEIP1559;
    if (isEIP1559) {
      try {
        const gas = await get1559GasPrice();
        setBaseFee(gas.baseFee);
        setEstimatedPrices(gas.estimatedPrices);
      } catch (error) {
        console.error(error);
      }
      if (open) {
        try {
          const status = await get1559GasPriceStatus();
          setEIP1559Status(status);
        } catch (err) {
          console.log(err);
        }
      }
      if (!getHistory) {
        try {
          const history1559 = await get1559GasPriceHistoryApi();
          _history = history1559.history;
          setHistoryEIP1559(_history);
        } catch (error) {
          console.error(error);
        }
      }
    } else {
      getGasPrices(getHistory || !gasPriceHistory?.length);
    }
    setHistory(isEIP1559 ? _history : gasPriceHistory);
  };

  useInterval(fetchData, open ? 10000 : 3 * 60000);

  useEffect(() => { fetchData(true); }, []);
  useEffect(() => { if (!isEIP1559) setHistory(gasPriceHistory); }, [gasPriceHistory]);

  useEffect(() => { fetchData(); }, [open, isEIP1559]);

  const gasPriceStatus = getGasPriceStatus(isEIP1559 ? baseFee : gasPrices?.fast);
  chartOptions.series[0].data = history;
  if (isEIP1559) chartOptions.series[0].name = 'Base fee';
  else chartOptions.series[0].name = 'Average gas price';
  const orderedGasPrice = [...history].sort((a, b) => a[1] - b[1]);
  const lowestGasPrice = orderedGasPrice[0];
  const highestGasPRice = orderedGasPrice[orderedGasPrice.length - 1];

  return (
    <OutsideAlerter
      className="dropdown-list-panel-wrapper"
      onClickOutside={() => { setOpen(false); }}
    >
      <div
        className={`icon-wrapper ${gasPriceStatus || 'no-status'}`}
        onClick={() => { setOpen(!open); }}
      >
        {!isEIP1559 && gasPrices?.fast && <span className={gasPrices?.fast >= 100 ? 'smaller' : ''}>{Math.floor(gasPrices?.fast)}</span>}
        {isEIP1559 && !!+baseFee && <span className={baseFee >= 100 ? 'smaller' : ''}>{baseFee}</span>}
        <svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M12.6154 0.0494385C10.9798 0.0494385 9.65386 1.37537 9.65386 3.01098V3.85712H7.9231V2.73434C7.9231 2.50477 7.73699 2.31867 7.50742 2.31867H2.33877C2.1092 2.31867 1.9231 2.50477 1.9231 2.73434V4.08312C1.23851 4.43006 0.769287 5.14044 0.769287 5.96042V21.2154C0.769287 22.377 1.71096 23.3187 2.87258 23.3187H18.1275C19.2891 23.3187 20.2308 22.377 20.2308 21.2154V5.96042C20.2308 5.95626 20.2308 5.9521 20.2308 5.94794V3.01098C20.2308 1.37536 18.9049 0.0494385 17.2692 0.0494385H12.6154ZM17.6923 3.85712V3.01098C17.6923 2.77732 17.5029 2.5879 17.2692 2.5879H12.6154C12.3817 2.5879 12.1923 2.77732 12.1923 3.01098V3.85712H17.6923Z" />
        </svg>
      </div>

      <div
        className={`dropdown-wrapper ${open ? 'opened' : 'closed'}`}
      >
        <h3>
          <span>{t('misc.recommended_gas_prices')}</span>
          <span className="gas-toggle-wrapper">
            <Toggle value={isEIP1559} label="EIP 1559" onClick={() => setIsEIP1559(prev => !prev)} readOnly />
            <p onClick={() => setIsEIP1559(prev => !prev)} className={`${isEIP1559 ? '' : 'dimmed'}`}>EIP 1559</p>
          </span>
        </h3>
        <div className={`dropdown-container ${isEIP1559 ? 'regular' : 'smaller'}`}>

          <div className="gas-price-status-widget-container">
            {isEIP1559 && (
              <>
                <FeeBox
                  title={t('misc.base_fee')}
                  tooltip={t('misc.base_fee_desc')}
                  status={EIP1559Status}
                >
                  <FeeBoxItem hasLoader value={baseFee} />
                </FeeBox>
                <FeeBox
                  title={t('misc.priority_fee')}
                  tooltip={t('misc.priority_fee_desc')}
                >
                  <ul className="fee-box__list">
                    {estimatedPrices.map(({ confidence, maxPriorityFeePerGas }) => (
                      <FeeBoxItem
                        key={confidence}
                        inList
                        value={new Dec(maxPriorityFeePerGas || 0).toFixed(2).toString()}
                        label={`${confidence}%`}
                      />
                    ))}
                  </ul>
                </FeeBox>
                <FeeBox
                  title="Max base fee"
                  tooltip="During periods of high network usage the base fee can quickly rise in consequent blocks, with the maximum increase being 12.5% from one block to the next one."
                >
                  <ul className="fee-box__list">
                    {[5, 10, 15].map((i) => (
                      <FeeBoxItem
                        key={i}
                        small
                        value={new Dec(baseFee || 0).times(new Dec(1.125).pow(i)).toFixed(0).toString()}
                        label={`in ${i} blocks`}
                      />
                    ))}
                  </ul>
                </FeeBox>
                {!!history.length && lowestGasPrice && (
                  <div className="gas-price-history">
                    <h3>{isEIP1559 ? 'Base fee' : 'Gas price'} history <span>(7 days)</span></h3>
                    <div className="gas-price-history-chart">
                      <HighchartsReact
                        highcharts={Highcharts}
                        constructorType="stockChart"
                        options={chartOptions}
                      />
                    </div>
                    <p className="gas-price-chart-info">
                      <span>Lowest: {lowestGasPrice[1]} ({parseTimestamp(lowestGasPrice[0])})</span>
                      <span>Highest: {highestGasPRice[1]} ({parseTimestamp(highestGasPRice[0])})</span>
                    </p>
                  </div>
                )}
              </>
            )}
            {!isEIP1559 && (
              <>
                {gettingGasPrices && gettingGasPricesError === '' && (<Loader />)}
                {!gettingGasPrices && gettingGasPricesError !== '' && (<ErrorBox>{gettingGasPricesError}</ErrorBox>)}

                {open && !gettingGasPrices && gettingGasPricesError === '' && (
                <ul className="gas-price-options">
                  <li>
                    <p><strong>{Math.floor(gasPrices?.cheap || 0)}</strong><span>Gwei</span></p>
                    <p><span>&lt;10min</span></p>
                  </li>
                  <li>
                    <p><strong>{Math.floor(gasPrices?.regular || 0)}</strong><span>Gwei</span></p>
                    <p><span>&lt;3min</span></p>
                  </li>
                  <li>
                    <p><strong>{Math.floor(gasPrices?.fast || 0)}</strong><span>Gwei</span></p>
                    <p><span>&lt;1min</span></p>
                  </li>
                </ul>
                )}

                {gettingGasPriceHistory && gettingGasPriceHistoryError === '' && (
                <div className="MarginTop10"><Loader /></div>)}
                {!gettingGasPriceHistory && gettingGasPriceHistoryError !== '' && (
                <ErrorBox>{gettingGasPriceHistoryError}</ErrorBox>)}

                {!gettingGasPriceHistory && gettingGasPriceHistoryError === '' && lowestGasPrice && (
                <div className="gas-price-history">
                  <h3>Gas price history <span>(7 days)</span></h3>
                  <div className="gas-price-history-chart">
                    <HighchartsReact
                      highcharts={Highcharts}
                      constructorType="stockChart"
                      options={chartOptions}
                    />
                  </div>
                  <p className="gas-price-chart-info">
                    <span>Lowest: {lowestGasPrice[1]} ({parseTimestamp(lowestGasPrice[0])})</span>
                    <span>Highest: {highestGasPRice[1]} ({parseTimestamp(highestGasPRice[0])})</span>
                  </p>
                </div>
                )}
              </>
            )}
            <a
              className="gas-price-extension-link"
              href={extensionStoreLink}
              target="_blank"
              rel="noreferrer noopener"
            >Get the browser extension here.
            </a>
          </div>
        </div>
      </div>
    </OutsideAlerter>
  );
};

GasPriceStatusWidget.defaultProps = {
  gasPriceHistory: [],
  gasPrices: {},
};

GasPriceStatusWidget.propTypes = {
  getGasPrices: PropTypes.func.isRequired,
  gasPrices: PropTypes.object,
  gettingGasPrices: PropTypes.bool.isRequired,
  gettingGasPricesError: PropTypes.string.isRequired,
  gasPriceHistory: PropTypes.array,
  gettingGasPriceHistory: PropTypes.bool.isRequired,
  gettingGasPriceHistoryError: PropTypes.string.isRequired,
};

const mapStateToProps = ({ general }) => ({
  gasPrices: general.gasPrices,
  gettingGasPrices: general.gettingGasPrices,
  gettingGasPricesError: general.gettingGasPricesError,
  gasPriceHistory: general.gasPriceHistory,
  gettingGasPriceHistory: general.gettingGasPriceHistory,
  gettingGasPriceHistoryError: general.gettingGasPriceHistoryError,
});

const mapDispatchToProps = {
  getGasPrices,
};

export default withErrorFallback(connect(mapStateToProps, mapDispatchToProps)(GasPriceStatusWidget));
