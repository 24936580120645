import t from 'translate';
import Web3 from 'web3';
import ProviderEngine from 'web3-provider-engine';
import RpcSubProvider from 'web3-provider-engine/subproviders/rpc';
import CacheProvider from 'web3-provider-engine/subproviders/cache';
import SubscriptionSubprovider from 'web3-provider-engine/subproviders/subscriptions';
import { errors } from 'web3-core-helpers';
import clientConfig from '../config/clientConfig.json';

export const getMetamaskWeb3 = () => {
  if (!window.ethereum && !window.web3?.currentProvider) throw new Error(t('errors.no_wallet'));
  return new Web3(window.ethereum || window.web3.currentProvider);
};

export const setWeb3toMetamask = () => {
  window._web3 = getMetamaskWeb3();
};

export const setWeb3Object = (network) => {
  window._web3Object[network] = window._web3;
};

export const setupWeb3 = (network) => {
  window._web3 = new Web3(clientConfig.rpcHttpProviders[network]);
  window._web3Object = {
    1: new Web3(clientConfig.rpcHttpProviders[1]),
    10: new Web3(clientConfig.rpcHttpProviders[10]),
    42161: new Web3(clientConfig.rpcHttpProviders[42161]),
  };
};

export const setupWeb3WithSubprovider = (subprovider, network = clientConfig.supportedNetworks[0]) => {
  const engine = new ProviderEngine({
    pollingInterval: 10 * 1000,
  });
  engine.on('error', console.error);
  engine.addProvider(new CacheProvider());
  engine.addProvider(new SubscriptionSubprovider());

  if (subprovider) engine.addProvider(subprovider);
  engine.addProvider(new RpcSubProvider({ rpcUrl: clientConfig.rpcHttpProviders[network] }));
  engine.start();

  window._web3 = new Web3(engine);
  window._web3.eth.transactionConfirmationBlocks = 5;
};

export const setupFormatic = async () => {
  const network = clientConfig.network === 42 ? 'kovan' : '';
  const { default: Fortmatic } = await import(/* webpackChunkName: "wallets" */ 'fortmatic');
  const fm = new Fortmatic(clientConfig.fortmatic_api_key, network);
  window._web3 = new Web3(fm.getProvider());
  return fm;
};

export const setupWalletConnect = async () => {
  const { default: WalletConnectSubprovider } = await import(/* webpackChunkName: "wallets" */ '@walletconnect/web3-subprovider');
  WalletConnectSubprovider.prototype.updateState = () => {};
  WalletConnectSubprovider.prototype.emit = () => {};
  setupWeb3WithSubprovider(new WalletConnectSubprovider({
    bridge: 'https://defisaver.bridge.walletconnect.org',
  }));
};

export const setupWalletLink = async (network) => {
  const { default: WalletLink } = await import(/* webpackChunkName: "wallets" */ 'walletlink');
  const WalletLinkInstance = new WalletLink({
    appName: 'DeFi Saver',
    appLogoUrl: 'https://app.defisaver.com/assets/icons/android-chrome-512x512.png',
    darkMode: true,
  });

  const ethereum = WalletLinkInstance.makeWeb3Provider(clientConfig.rpcHttpProviders[network], network);
  window._web3 = new Web3(ethereum);

  const accounts = await ethereum.enable();
  return accounts[0];
};

export const setupGnosisSafe = async () => new Promise(async (res, rej) => {
  try {
    const { default: Sdk } = await import(/* webpackChunkName: "wallets" */ '@gnosis.pm/safe-apps-sdk');
    setTimeout(() => rej(new Error('Gnosis Safe did not respond')), 300);
    const sdk = new Sdk();
    const safe = await sdk.safe.getInfo();
    const { safeAddress } = safe;
    const { SafeAppProvider } = await import(/* webpackChunkName: "wallets" */ '@gnosis.pm/safe-apps-provider');
    window._web3 = new Web3(new SafeAppProvider(safe, sdk));
    res(safeAddress);
  } catch (err) {
    rej(err);
  }
});

export const setupLocalWeb3 = (tenderlyForkId) => {
  const customizedHttpProvider = new Web3.providers.HttpProvider(`https://rpc.tenderly.co/fork/${tenderlyForkId}`);
  /* eslint-disable */
  customizedHttpProvider.send = function (payload, callback) {
    const _this = this;
    const request = this._prepareRequest();

    request.onreadystatechange = function () {
      if (request.readyState === 4 && request.timeout !== 1) {
        let result = request.responseText;
        let error = null;

        try {
          result = JSON.parse(result);
        } catch (e) {
          error = errors.InvalidResponse(request.responseText);
        }
        if (result?.result && typeof result?.result === 'object') {
          result.result.txId = request.getResponseHeader('head');
        }
        _this.connected = true;
        callback(error, result);
      }
    };

    request.ontimeout = function () {
      _this.connected = false;
      callback(errors.ConnectionTimeout(this.timeout));
    };

    try {
      request.send(JSON.stringify(payload));
    } catch (error) {
      this.connected = false;
      callback(errors.InvalidConnection(this.host));
    }
  };
  /* eslint-enable */
  window._web3 = new Web3(customizedHttpProvider);
  window._web3.eth.transactionConfirmationBlocks = 1;
  window._web3.isFork = true;
  window._web3.forkId = tenderlyForkId;
};
