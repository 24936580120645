import React from 'react';

export default function BellIcon(props) {
  return (
    <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M18 17V18H0V17L2 15V9C1.99961 7.49223 2.48607 6.02464 3.38698 4.81562C4.28788 3.60661 5.55509 2.72079 7 2.29C6.99508 2.1934 6.99508 2.0966 7 2C7 1.46957 7.21071 0.960859 7.58579 0.585786C7.96086 0.210714 8.46957 0 9 0C9.53043 0 10.0391 0.210714 10.4142 0.585786C10.7893 0.960859 11 1.46957 11 2C11.005 2.097 11.005 2.193 11 2.29C12.4449 2.72079 13.7121 3.60661 14.613 4.81562C15.5139 6.02464 16.0004 7.49223 16 9V15L18 17ZM11 19C11 19.5304 10.7893 20.0391 10.4142 20.4142C10.0391 20.7893 9.53043 21 9 21C8.46957 21 7.96086 20.7893 7.58579 20.4142C7.21071 20.0391 7 19.5304 7 19" fill="#61717E" />
    </svg>
  );
}


