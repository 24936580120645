/* eslint-disable max-classes-per-file */
import Dec from 'decimal.js';
import { assetAmountInEth, assetAmountInWei, getAssetInfo } from '@defisaver/tokens';
import dfs from '@defisaver/sdk';
import t from 'translate';
import {
  MStableImUsdTokenAddress,
  MStableImUsdTokenContract,
  MStableImUsdVaultAddress, MStableImUsdVaultContract, MStableMUsdTokenAddress,
} from '../../contractRegistryService';
import { multicall } from '../../multicallService';
import { MStableSaveGetter } from './MStableSaveGetters';
import { claimMta, importMStableVaultPosition } from '../../../actions/savingsActions/savingsRecipeActions';
import { basicAmountValidation } from '../../vaultCommonService';
import { MAXUINT } from '../../../constants/general';
import { getBestExchangePrice, getExchangeOrder, getSlippageThreshold } from '../../exchangeServiceV3';
import { calculateTradeSizeImpact } from '../../makerServices/makerManageServices/makerManageService';
import { openSmartSavingsClaimAndSupplyModal } from '../../../actions/modalActions';

export class MStableVaultGetter extends MStableSaveGetter {
  static supplyRecipeName = 'mStableVaultDeposit';

  static withdrawRecipeName = 'mStableVaultWithdraw';

  static moveRecipeName = 'mStableVaultMove';

  constructor(slug, name) {
    super(slug, name);
    this.description = t('savings.description_mstable_vault');
    this.isVault = true;
  }

  static async _getSuppliedImUsd(account) {
    const imUSDVault = MStableImUsdVaultContract();
    return imUSDVault.methods.rawBalanceOf(account).call();
  }

  static async _getSupplied(account) {
    const imUsdBalance = await this._getSuppliedImUsd(account);
    const mUsdBalance = await this._imUsdToMUsd(imUsdBalance);
    return assetAmountInEth(mUsdBalance, 'mUSD');
  }

  async getSupplied(account) {
    return this.constructor._getSupplied(account);
  }

  async getClaimable(account) {
    return {
      asset: 'MTA',
      amount: assetAmountInEth((await MStableImUsdVaultContract().methods.unclaimedRewards(account).call()).amount, 'MTA'),
    };
  }

  static async _getMStablePoolData() {
    const calls = [
      {
        target: MStableImUsdVaultAddress,
        params: [],
        abiItem: { inputs: [], name: 'totalSupply', outputs: [{ name: 'totalSupply', type: 'uint256' }] },
      },
      {
        target: MStableImUsdTokenAddress,
        params: [],
        abiItem: { inputs: [], name: 'exchangeRate', outputs: [{ name: 'exchangeRate', type: 'uint256' }] },
      },
    ];
    const [{ totalSupply }, { exchangeRate }] = await multicall(calls);
    return {
      totalSupply,
      exchangeRate: assetAmountInEth(exchangeRate, 'ETH'),
      fundBalance: new Dec(assetAmountInEth(totalSupply, 'imUSD')).mul(assetAmountInEth(exchangeRate, 'ETH')).toString(),
    };
  }

  static _getMStableImportRecipe() {
    return async (firstAction, firstInput, account, proxyAddress, firstInputSelect, isMax) => {
      const withdrawAmount = await this._mUsdToImUsd(assetAmountInWei(firstInput, 'mUSD'));
      const amount = isMax ? MAXUINT : withdrawAmount;
      return {
        amount: withdrawAmount,
        recipe: new dfs.Recipe('mStableVaultImport', [
          new dfs.actions.mstable.MStableDepositAction(MStableImUsdTokenAddress, MStableImUsdTokenAddress, MStableImUsdTokenAddress, MStableImUsdVaultAddress, account, proxyAddress, amount, 0, dfs.utils.mstableAssetPairs.IMASSET_IMASSETVAULT),
        ]),
      };
    };
  }

  static _getMStableClaimRecipe() {
    return async (firstAction, firstInput, account, proxyAddress, firstInputSelect, isMax) => {
      const { first, last } = await MStableImUsdVaultContract().methods.unclaimedRewards(proxyAddress).call();
      return new dfs.Recipe('mStableVaultClaim', [
        new dfs.actions.mstable.MStableClaimAction(MStableImUsdVaultAddress, account, first, last),
      ]);
    };
  }

  static _getMStableClaimReinvestRecipe() {
    return async (firstAction, firstInput, account, proxyAddress, firstInputSelect, isMax, slippagePercent, _toAsset, exchangeRate) => {
      const toAsset = getAssetInfo(_toAsset);
      const { first, last, amount } = await MStableImUsdVaultContract().methods.unclaimedRewards(proxyAddress).call();
      const mtaAmount = assetAmountInEth(amount, 'MTA');
      const price = new Dec(exchangeRate).mul(new Dec(100).sub(slippagePercent).div(100)).toString();
      const toAssetAmount = assetAmountInWei(new Dec(mtaAmount).mul(price).toString(), toAsset.symbol);
      const { orderData, value, extraGas } = await getExchangeOrder('MTA', toAsset.symbol, mtaAmount, exchangeRate, slippagePercent, proxyAddress, false, false, true);
      orderData[2] = '$1';
      const minOut = toAsset.symbol === 'mUSD' ? toAssetAmount : await this._assetToMUsd(toAsset.address, toAssetAmount);
      const assetPair = this._getAssetPair(toAsset, true);
      const recipe = new dfs.Recipe('mStableVaultClaim', [
        new dfs.actions.mstable.MStableClaimAction(MStableImUsdVaultAddress, proxyAddress, first, last),
        new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value),
        new dfs.actions.mstable.MStableDepositAction(toAsset.address, MStableMUsdTokenAddress, MStableImUsdTokenAddress, MStableImUsdVaultAddress, proxyAddress, proxyAddress, '$2', minOut, assetPair),
      ]);
      recipe.extraGas = extraGas;
      return recipe;
    };
  }

  static _getMStableClaimReinvestInfo(availableAssets) {
    return async (firstAction, firstInput, account, proxyAddress, firstInputSelect, isMax) => {
      const { amount } = await MStableImUsdVaultContract().methods.unclaimedRewards(proxyAddress).call();
      const mtaAmount = assetAmountInEth(amount, 'MTA');
      const promises = availableAssets.map(async (asset) => {
        const data = await getBestExchangePrice(mtaAmount, 'MTA', asset, account, true, true, true);
        return {
          price: data.price,
          asset: getAssetInfo(asset),
          amount: new Dec(mtaAmount).mul(data.price).toString(),
          source: data.source,
        };
      });
      const assets = (await Promise.all(promises)).sort((a, b) => b.price - a.price);
      const bestAsset = assets[0];
      const assetPrice = await getSlippageThreshold('MTA', bestAsset.asset.symbol);
      return {
        fromAsset: 'MTA',
        toAsset: bestAsset.asset.symbol,
        fromAmount: mtaAmount,
        toAmount: bestAsset.amount,
        tradeSizeImpact: calculateTradeSizeImpact(assetPrice, bestAsset.price),
        exchangeRate: bestAsset.price,
        exchangeSource: bestAsset.source,
      };
    };
  }

  getImportAction(account, proxyAddress, hasSmartWallet, dispatch) {
    let action = (contextAction) => dispatch(importMStableVaultPosition(contextAction, this.constructor._getMStableImportRecipe()));
    if (!hasSmartWallet) {
      action = this.getCreateSmartWalletAction(dispatch);
    }
    return {
      value: 'import',
      label: t('common.import'),
      executingLabel: t('common.importing'),
      description: t('savings.import_info'),
      symbol: 'mUSD',
      approve: this.tokenContract,
      getMaxValue: () => this.getSupplied(account),
      validate: basicAmountValidation(),
      action,
      otherProps: {
        allowOverMax: false,
        debounce: true,
        actionDesc: '',
      },
      additionalActions: [],
    };
  }

  getClaimAction(account, proxyAddress, hasSmartWallet, dispatch) {
    return {
      value: 'claim',
      label: t('common.claim'),
      executingLabel: t('common.claiming'),
      description: t('savings.claim_info'),
      symbol: 'MTA',
      getMaxValue: async () => (await this.getClaimable(proxyAddress)).amount,
      validate: basicAmountValidation(),
      action: (contextAction) => dispatch(claimMta(contextAction, this.constructor._getMStableClaimRecipe(), false)),
      setInputAsMax: true,
      readOnlyInput: true,
      otherProps: {
        allowOverMax: false,
        debounce: true,
        actionDesc: '',
      },
      additionalActions: [
        {
          label: t('common.supply'),
          executingLabel: t('common.supplying'),
          value: 'claimsupply',
          inputType: 'button',
          description: t('savings.claim_supply'),
          goesFirst: true,
          action: (contextAction) => dispatch(
            openSmartSavingsClaimAndSupplyModal(contextAction, {
              getInfo: this.constructor._getMStableClaimReinvestInfo(this.availableAssets),
              execute: (toAsset, exchangeRate) => dispatch(claimMta(contextAction, this.constructor._getMStableClaimReinvestRecipe(), true, toAsset, exchangeRate)),
            })),
        },
      ],
    };
  }
}
