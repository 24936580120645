import memoize from 'memoizee';
import dfs from '@defisaver/sdk';
import {
  CdpId, AssetAmount, Source,
} from 'components/Recipes/RecipeCreator/inputTypes';
import { getSafeInfo as _getSafeInfo } from '../../services/reflexerServices/reflexerService';
import { paybackAfterValues } from '../../actions/reflexerActions/reflexerManageAfterValues';
import { formatNumber } from '../../services/utils';
import { assetAmountInWeiIgnorePointer } from '../../services/recipeCreator/recipeActionUtils';
import { MAXUINT } from '../../constants/general';

import RecipeAction from '../RecipeAction';
import PaybackIcon from '../recipeIcons/Payback.svg';

export default class ReflexerPaybackAction extends RecipeAction {
  static prettyName = 'Pay RAI back to Safe';

  static protocol = 'reflexer';

  static protocolPrettyName = 'Reflexer';

  static description = 'Pays back RAI debt to the selected Safe.';

  constructor(safeId = 0, from = 'wallet', amount = '') {
    super();
    this.inputs = [
      new CdpId('Safe ID', safeId, 'reflexer'),
      new Source('From', from),
      new AssetAmount('Amount', amount, 'RAI'),
    ];
    this.output = new AssetAmount('output', 0, 'RAI');
  }

  async getAfterValues(_balances = {}, returnValues = [], actions = [], _positions = {}, getState) {
    const positions = { ..._positions };
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const safeId = args[0];
    if (!positions[`reflexer_${safeId}`]) positions[`reflexer_${safeId}`] = getState().reflexer.safes[safeId];
    const asset = 'RAI';
    let amount = args[2];
    if (amount === 'All available') amount = positions[`reflexer_${safeId}`]?.debtInAsset || '0';
    const { afterPosition, balances, returnValue } = await paybackAfterValues(
      {
        amount,
        from: args[1],
      },
      {
        vault: positions[`reflexer_${safeId}`],
        assets: getState().assets,
        account: getState().general.account,
        proxyAddress: getState().maker.proxyAddress,
      },
      _balances,
    );
    positions[`reflexer_${safeId}`] = afterPosition;
    this.output.value = returnValue;
    return { returnValue: this.output, balances, positions };
  }


  async toDfsAction(getState) {
    const {
      general: { account },
      maker: { proxyAddress },
    } = getState();
    const from = this.inputs[1].value === 'wallet' ? account : proxyAddress;
    const amount = this.inputs[2].value === 'All available' ? MAXUINT : assetAmountInWeiIgnorePointer(this.inputs[2].value, 'RAI');
    return new dfs.actions.reflexer.ReflexerPaybackAction(this.inputs[0].value, amount, from);
  }

  static getIcon() {
    return PaybackIcon;
  }

  _getPrettyName(actionCalls, actions) {
    const args = this.mapReturnValuesToArgs(actionCalls.map(a => a.returnValue), actions);
    return `Pay ${args[2] === 'All available' ? 'all' : formatNumber(args[2])} RAI back to Safe #${args[0]}`;
  }
}
