/* eslint-disable */
import React from 'react';

export default function FrameIcon(props) {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.868 10.3798V2.41542C19.8678 2.2479 19.8348 2.08205 19.7707 1.92735C19.7066 1.77264 19.6128 1.63211 19.4946 1.51378C19.3764 1.39545 19.2361 1.30164 19.0818 1.23769C18.9274 1.17375 18.762 1.14093 18.595 1.14111H10.6287C10.5488 1.14084 10.4722 1.10931 10.4151 1.05323L9.45698 0.0920075C9.42825 0.0626697 9.39394 0.0394115 9.35608 0.0236087C9.31823 0.00780598 9.27761 -0.000220333 9.23661 4.60003e-06H1.27435C1.10736 -0.000356445 0.94193 0.0322826 0.787514 0.0960583C0.633098 0.159834 0.492715 0.253497 0.374381 0.3717C0.256047 0.489903 0.162079 0.630331 0.0978431 0.784966C0.0336069 0.939601 0.000360072 1.10541 9.53966e-07 1.27294V9.23736C-9.9422e-05 9.27701 0.00772232 9.31627 0.0230039 9.35282C0.0382855 9.38938 0.0607169 9.42249 0.0889724 9.45021L1.04713 10.4114C1.07538 10.4391 1.09781 10.4723 1.11309 10.5088C1.12838 10.5454 1.1362 10.5846 1.1361 10.6243V18.5887C1.13628 18.7562 1.16935 18.9221 1.23342 19.0768C1.29749 19.2315 1.3913 19.372 1.50951 19.4903C1.62772 19.6087 1.768 19.7025 1.92235 19.7664C2.07669 19.8304 2.24208 19.8632 2.40907 19.863H10.3754C10.4553 19.8633 10.5319 19.8948 10.589 19.9509L11.5471 20.9121C11.6042 20.9682 11.6808 20.9997 11.7607 21H19.7257C19.8926 21.0004 20.0581 20.9677 20.2125 20.9039C20.3669 20.8402 20.5073 20.7465 20.6256 20.6283C20.744 20.5101 20.8379 20.3697 20.9022 20.215C20.9664 20.0604 20.9996 19.8946 21 19.7271V11.7626C21.0001 11.723 20.9923 11.6837 20.977 11.6472C20.9617 11.6106 20.9393 11.5775 20.911 11.5498L19.9529 10.5886C19.8984 10.5328 19.868 10.4579 19.868 10.3798ZM15.4468 15.6734H5.56414C5.5041 15.6724 5.44686 15.6478 5.40466 15.6049C5.36246 15.5621 5.33864 15.5044 5.33829 15.4441V5.56549C5.33865 5.50478 5.36285 5.44666 5.40564 5.40373C5.44843 5.36081 5.50636 5.33653 5.56688 5.33617H15.4468C15.5073 5.33653 15.5653 5.36081 15.6081 5.40373C15.6508 5.44666 15.675 5.50478 15.6754 5.56549V15.4441C15.675 15.5048 15.6508 15.563 15.6081 15.6059C15.5653 15.6488 15.5073 15.6731 15.4468 15.6734Z" fill="white"/>
    </svg>
  );
}
