import React from 'react';
import PropTypes from 'prop-types';
import t from 'translate';
import ModalHeader from '../../ModalHeader';
import ModalBody from '../../ModalBody';
import '../../Maker/MakerCreateCdpSuccessModal/MakerCreateCdpSuccessModal.scss';
import success from '../../../Exchange/exchange-success.svg';

function ReflexerSafeCreatedModal(props) {
  const { closeModal, safeId } = props;
  return (
    <div className="maker-create-cdp-success-modal-wrapper">

      <ModalHeader closeModal={closeModal} />

      <ModalBody>
        <div className="modal-content">
          <img src={success} alt="" />

          <div className="title">{t('reflexer.safe_created_successfully', { '%safeId': safeId })}</div>

          {/* <div className="container"> */}
          {/*  <div className="description WhiteSpacePre"> */}
          {/*    {t('reflexer.safe_created_successfully_desc')} */}
          {/*  </div> */}
          {/* </div> */}
        </div>
      </ModalBody>
      <div className="modal-controls">
        <button
          type="button"
          className="button green"
          onClick={closeModal}
        >
          {t('common.continue')}
        </button>
        {/* <button */}
        {/*  type="button" */}
        {/*  className="button" */}
        {/*  form="gas-price-modal-form" */}
        {/*  onClick={closeModal} */}
        {/* > */}
        {/*  { t('common.set_up_later') } */}
        {/* </button> */}

        {/* <button */}
        {/*  type="button" */}
        {/*  className="button green" */}
        {/*  onClick={() => { */}
        {/*    history.push('/makerdao/strategies/'); */}
        {/*    closeModal(); */}
        {/*  }} */}
        {/* > */}
        {/*  { t('common.automate') } */}
        {/* </button> */}
      </div>
    </div>
  );
}

ReflexerSafeCreatedModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  safeId: PropTypes.number.isRequired,
};

ReflexerSafeCreatedModal.defaultProps = {};

export default ReflexerSafeCreatedModal;
