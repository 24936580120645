import set from 'lodash/set';
import Dec from 'decimal.js';
import { momoize } from 'services/memoization';
import { assetAmountInWei } from '@defisaver/tokens';
import { getAssetBalance as _getAssetBalance, getAssetBalanceByAddress } from '../assetsService';
import { Amount, Asset, AssetAmount } from '../../components/Recipes/RecipeCreator/inputTypes';
import { defined, isId } from '../utils';
import { MAXUINT } from '../../constants/general';

const getAssetBalance = momoize(_getAssetBalance, { maxAge: 2 * 60 * 1000, promise: true, resetOnAccount: true });

export const assetAmountInWeiIgnorePointer = (amount, asset) => {
  if (isId(amount?.toString().substr(1))) return amount;
  return assetAmountInWei(amount, asset);
};

export const changeBalance = async (balances, wallet, asset, amount, address = '', lpTokenAddress = '') => {
  if (asset && address && !balances?.[wallet]?.[asset]) {
    const func = await (lpTokenAddress ? getAssetBalanceByAddress(lpTokenAddress, address) : getAssetBalance(asset, address));
    set(balances, [wallet, asset], func);
  }
  const currentBalance = balances?.[wallet]?.[asset] || 0;
  set(balances, [wallet, asset], new Dec(currentBalance).add(amount).toString());
};

export const getAmount = async (balances, wallet, asset, amount, address = '', lpTokenAddress = '', getState) => {
  if (amount === 'All available') {
    if (asset && address && !balances?.[wallet]?.[asset]) {
      if (wallet === 'wallet') {
        const stateBalance = getState().assets[asset]?.balance;
        if (stateBalance !== undefined) return stateBalance;
      }
      return (lpTokenAddress ? getAssetBalanceByAddress(lpTokenAddress, address) : getAssetBalance(asset, address));
    }
    return balances?.[wallet]?.[asset] || 0;
  }
  return amount;
};
export const getAmountDfsAction = (amount, asset) => (amount === 'All available' ? MAXUINT : assetAmountInWeiIgnorePointer(amount, asset));
export const getSourceAddress = (source, getState) => {
  const { general: { account }, maker: { proxyAddress } } = getState();
  return source === 'wallet' ? account : proxyAddress;
};

export const formatPositionId = (positionId) => {
  if (positionId.substr(0, 5) === 'maker') return `CDP ${positionId.substr(6)}`;
  if (positionId.substr(0, 8) === 'reflexer') return `Safe ${positionId.substr(9)}`;
  if (positionId.substr(0, 8) === 'compound') return 'Compound';
  if (positionId.substr(0, 7) === 'aaveV2_') return 'Aave v2';
  if (positionId.substr(0, 5) === 'aave_') return 'Aave v1';
  if (positionId.substr(0, 7) === 'liquity') return 'Liquity Trove';
  return positionId;
};

/**
 * @returns {boolean | string} false or asset symbol
 */
export const containsAsset = (value) => value && ((value.constructor === Asset && value.asset) || (value.constructor === AssetAmount && value.asset));

export const containsAmount = (value) => value && ((value.constructor === Amount && value.value) || (value.constructor === AssetAmount && value.value));

export const filterAssetOptions = (asset, options) => options.filter(option => option.asset === asset);

const getOutputValueForId = (_id, actions) => actions.find(({ id }) => `$${id}` === _id).output.value;

export const getRecipeFormItemActualValue = (formData, returnValues, actions) => {
  let actualValue =
    isId(formData && formData && typeof formData === 'string' ? formData?.slice(1) : '')
      ? getOutputValueForId(formData, actions)
      : formData;

  if (defined(formData) && isId(formData?.toString().substr(1))) {
    const index = actions.findIndex(({ id }) => id === formData.substr(1));
    if (index !== -1 && returnValues[index]) actualValue = returnValues[index].value;
  }
  return actualValue;
};


// TODO: use this in uniswap actions
export const isAmountInvalidFromFormData = (formData, i) => Number.isNaN(+formData[i]) || !formData[i];

export const amountFromFormOrZero = (formData, i) => (isAmountInvalidFromFormData(formData, i) ? '0' : formData[i]);

export const replaceId = (args, i, actions) => {
  const arg = args[i];
  if (Array.isArray(arg)) return arg.map((a, j) => replaceId(arg, j, actions));
  if (isId(arg.substr?.(1))) {
    const index = actions.findIndex(({ id }) => id === arg.substr(1));
    if (index === -1) throw new Error('Invalid pointer');
    // eslint-disable-next-line no-param-reassign
    args[i] = `$${index + 1}`;
  }
};
export const recipeActionsToDfsActions = async (recipeActions, getState, actionCalls) => {
  const returnValues = actionCalls.map(a => a.returnValue);
  const positions = actionCalls[actionCalls.length - 1]?.positions || {};
  const dfsActions = await Promise.all(recipeActions.map((a, i) => a.toDfsAction(getState, recipeActions, returnValues, positions, i > 0 ? actionCalls[i - 1] : {}, actionCalls[i] || {}))).catch((err) => {
    console.error(err);
    throw err;
  });
  for (const action of dfsActions) { // eslint-disable-line
    action.args.forEach((arg, i) => {
      replaceId(action.args, i, recipeActions);
    });
    action.mappableArgs.forEach((arg, i) => {
      replaceId(action.mappableArgs, i, recipeActions);
    });
  }
  return dfsActions;
};
