import React from 'react';


export default function ShortIcon(props) {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="15" cy="15" r="15" transform="rotate(90 15 15)" fill="#F2C94C" />
      <path d="M10 10L20 20M20 20L15.2381 20M20 20L20 15.2381" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
