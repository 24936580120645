import memoize from 'memoizee';
import dfs from '@defisaver/sdk';
import { CdpId, CdpIlk } from 'components/Recipes/RecipeCreator/inputTypes';
import { ilkToAsset } from '@defisaver/tokens';
import { stringToBytes } from 'services/utils';
import {
  latestSafeId as _latestSafeId, getRaiIlkInfo, getCollateralInfoMemoized,
} from '../../services/reflexerServices/reflexerService';

import RecipeAction from '../RecipeAction';
import CreateIcon from '../recipeIcons/Create.svg';

const latestSafeId = memoize(_latestSafeId, { promise: true, maxAge: 60 * 1000 });
const getCollateralInfo = getCollateralInfoMemoized;

const emptySafe = {
  collateral: 0,
  collateralUsd: 0,
  debtInAsset: 0,
  debtUsd: 0,
  liqRatio: 0,
  assetPrice: 0,
  debtAssetPrice: 0,
  type: 'standard',
  debtAsset: 'RAI',
};

export default class ReflexerOpenSafeAction extends RecipeAction {
  static prettyName = 'Create New Safe';

  static protocol = 'reflexer';

  static protocolPrettyName = 'Reflexer';

  static description = 'Creates new, blank Reflexer Safe.';

  constructor(ilk = 'ETH-A') {
    super();
    this.inputs = [
      new CdpIlk('Adapter', ilk),
    ];
    this.output = new CdpId('output', 0, 'reflexer');
  }

  async getAfterValues(_balances = {}, returnValues = [], actions = [], _positions = {}, getState) {
    const positions = { ..._positions };
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const latestLiveSafeId = await latestSafeId();
    const latestRecipeSafeId = Object.keys(positions)
      .filter(id => id.substr(0, 8) === 'reflexer')
      .map(id => +id.substr(9));
    const id = Math.max(latestLiveSafeId, ...latestRecipeSafeId) + 1;
    const collTypeData = await getCollateralInfo(stringToBytes(args[0]));
    positions[`reflexer_${id}`] = {
      ...emptySafe,
      id,
      asset: ilkToAsset(args[0]),
      ilk: stringToBytes(args[0]),
      owner: getState().maker.proxyAddress,
      collType: args[0],
      assetPrice: collTypeData.assetPrice,
      liqPercent: collTypeData.liqPercent,
      debtAssetPrice: collTypeData.raiTargetPrice,
      minDebt: collTypeData.minDebt,
      value: `$${this.id}`,
    };
    return {
      returnValue: new CdpId('output', id, 'reflexer'),
      balances: _balances,
      positions,
    };
  }

  async toDfsAction() {
    return new dfs.actions.reflexer.ReflexerOpenSafeAction(getRaiIlkInfo(this.inputs[0].value).join);
  }

  static getIcon() {
    return CreateIcon;
  }

  _getPrettyName(actionCalls, actions) {
    const id = this.id;
    const thisActionIndex = actions.findIndex((a) => a.id === id);
    const returnOfThisAction = actionCalls[thisActionIndex]?.returnValue;
    const args = this.mapReturnValuesToArgs(actionCalls.map(a => a.returnValue), actions);
    if (thisActionIndex !== -1 && returnOfThisAction) {
      return `Create New Reflexer Safe #${returnOfThisAction.value}`;
    }
    return 'Create New Reflexer Safe';
  }
}
