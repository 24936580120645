import t from 'translate';
import { TOGGLE_MODAL } from '../actionTypes/modalActionTypes';
import {
  TRANSFER_CDP_MODAL,
  MAKER_REPAY_MODAL,
  MAKER_BOOST_MODAL,
  SHORT_MODAL,
  LEVERAGE_MODAL,
  COMPOUND_USER_WALLET_MODAL,
  AAVE_PROXY_V1_MIGRATION_MODAL,
  AAVE_USER_WALLET_MODAL,
  AAVE_EMODE_MODAL,
  SMART_SAVINGS_CLAIM_AND_SUPPLY,
  AUTOMATION_UNSUB_NEXT_STEPS_MODAL,
  LEDGER_CONNECT_MODAL,
  TREZOR_CONNECT_MODAL,
  AUTOMATION_UNSUB_FEEDBACK_MODAL,
  MIGRATE_CDP_FROM_INSTADAPP_MODAL,
  CONFIRMATION_MODAL,
  INFO_MODAL,
  SEND_CONFIRMATION_MODAL,
  CLOSE_MCD_CDP_MODAL,
  CDP_CREATED_MODAL,
  COMPOUND_COLLATERAL_REQUIRED_MODAL,
  COMPOUND_BOOST_MODAL,
  COMPOUND_REPAY_MODAL,
  COMPOUND_PROXY_MIGRATION_MODAL,
  COMP_REDEEM_MODAL,
  ADDRESS_MONITOR_MODAL,
  LOGIN_MODAL,
  AAVE_PROXY_MIGRATION_MODAL,
  AAVE_BOOST_MODAL,
  AAVE_REPAY_MODAL,
  AAVE_BOOST_MODAL_V2,
  AAVE_REPAY_MODAL_V2,
  AAVE_COLLATERAL_REQUIRED_MODAL,
  SHIFTER_SUCCESS_MODAL,
  AAVE_BORROW_MODAL,
  AAVE_STAKE_MODAL,
  SMARTWALLET_PICKER_MODAL,
  RFL_BOOST_MODAL,
  RFL_REPAY_MODAL,
  RFL_SAFE_CREATED_MODAL,
  CHANGE_NETWORK_MODAL,
  SIM_START_MODAL,
  SIM_END_MODAL,
  RECIPE_BOOK_ITEM_MODAL,
  LQTY_CLOSE_TROVE_MODAL,
  LQTY_BOOST_MODAL,
  LQTY_REPAY_MODAL,
  LQTY_STAKING_MODAL,
  LQTY_REDEEM_MODAL,
  MM_CHANGE_MODAL,
  MANAGE_WALLETS_MODAL,
  RECIPE_SUCCESS_MODAL,
  MIGRATE_COMPOUND_FROM_INSTADAPP_MODAL,
  MIGRATE_AAVE_FROM_INSTADAPP_MODAL,
  CREATE_SMART_WALLET_MODAL,
  TROVE_CREATED_MODAL,
  UNISWAP_V3_IMAGE_MODAL,
  // EMERGENCY_MESSAGE_MODAL,
} from '../components/Modals/modalTypes';
import {
  wait, isWalletTypeProxy,
} from '../services/utils';

/**
 * Dispatches action to toggle modal.
 *
 * @param {String} modalType
 * @param {Object} modalProps
 * @param {Boolean} action - to close or to open
 */
export const toggleModal = (modalType, modalProps, action) => (dispatch) => {
  dispatch({
    type: TOGGLE_MODAL,
    payload: { modalType, modalProps, action },
  });
};

/**
 * Closes the modal that is currently open
 */
export const closeModal = () => (dispatch) => {
  dispatch({
    type: TOGGLE_MODAL,
    payload: { modalType: '', modalProps: {}, action: false },
  });
};

export const confirmViaModal = content => async (dispatch) => {
  let resolve;
  const modalPromise = new Promise((res) => { resolve = res; });
  dispatch(toggleModal(CONFIRMATION_MODAL, { width: 420, content, resolve }, true));
  const result = await modalPromise;
  await dispatch(closeModal());
  await wait(500);
  return result;
};

export const showInfoModal = ({ title, content }) => async (dispatch) => {
  dispatch(toggleModal(INFO_MODAL, {
    width: 420, title, content, closeModal,
  }, true));
};

export const sendConfirmViaModal = ({ sendingTo, token, amount }) => async (dispatch, getState) => {
  let resolve;
  const { general: { account, network }, maker: { proxyAddress } } = getState();
  const modalPromise = new Promise((res) => { resolve = res; });
  dispatch(toggleModal(SEND_CONFIRMATION_MODAL, {
    width: 420, sendingTo, account, proxyAddress, resolve, token, amount, network,
  }, true));
  const result = await modalPromise;
  await dispatch(closeModal());
  await wait(500);
  return result;
};

export const pickSmartWalletViaModal = addresses => async (dispatch) => {
  let resolve;
  const modalPromise = new Promise((res) => { resolve = res; });
  dispatch(toggleModal(SMARTWALLET_PICKER_MODAL, {
    width: 420, addresses, resolve, closable: false,
  }, true));
  const result = await modalPromise;
  await dispatch(closeModal());
  await wait(500);
  return result;
};

/**
 * Opens close cdp modal when the user wants to close his cdp
 */
export const openCloseCdpModal = history => (dispatch) => {
  dispatch(toggleModal(CLOSE_MCD_CDP_MODAL, { history, width: 500, className: 'close-mcd-cdp-modal' }, true));
};

/**
 * Opens close trove modal when the user wants to close his trove
 */
export const openCloseTroveModal = history => (dispatch) => {
  dispatch(toggleModal(LQTY_CLOSE_TROVE_MODAL, { history, width: 481, className: 'close-trove-modal' }, true));
};

/**
 * Opens the transfer cdp modal when the user whats to send it someone else
 *
 * @param {Object} history
 */
export const openTransferCdpModal = history => (dispatch) => {
  dispatch(toggleModal(TRANSFER_CDP_MODAL, { width: 500, history }, true));
};

/**
 * Opens the repay modal where the user confirms if he wants to proceed
 *
 * @param contextAction {Object}
 * @param additionalAction {Object}
 */
export const openMakerRepayModal = (contextAction, additionalAction) => async (dispatch, getState) => {
  const { ratioTooLow, ratioTooHigh } = getState().makerManage;
  if (ratioTooLow || ratioTooHigh) {
    const confirmed = await dispatch(confirmViaModal(t('errors.automatic_trigger')));
    if (!confirmed) return false;
  }

  dispatch(toggleModal(MAKER_REPAY_MODAL, { width: 500, contextAction, additionalAction }, true));
};

/**
 * Opens the boost modal where the user confirms if he wants to proceed
 *
 * @param contextAction {Object}
 * @param additionalAction {Object}
 */
export const openMakerBoostModal = (contextAction, additionalAction) => async (dispatch, getState) => {
  const { ratioTooLow, ratioTooHigh } = getState().makerManage;
  if (ratioTooLow || ratioTooHigh) {
    const confirmed = await dispatch(confirmViaModal(t('errors.automatic_trigger')));
    if (!confirmed) return false;
  }

  dispatch(toggleModal(MAKER_BOOST_MODAL, { width: 500, contextAction, additionalAction }, true));
};

/**
 * Opens the Compound short action modal
 * @return {Function}
 */
export const openShortModal = () => (dispatch) => {
  dispatch(toggleModal(SHORT_MODAL, { width: 481 }, true));
};

/**
 * Opens the Compound short action modal
 * @return {Function}
 */
export const openLeverageModal = () => (dispatch) => {
  dispatch(toggleModal(LEVERAGE_MODAL, { width: 481 }, true));
};

/**
 * Opens the Ledger connect modal.
 * @returns {Function}
 */
export const openLedgerConnectModal = () => (dispatch) => {
  dispatch(toggleModal(LEDGER_CONNECT_MODAL, { width: 700 }, true));
};

/**
 * Opens the Trezor connect modal.
 * @returns {Function}
 */
export const openTrezorConnectModal = () => (dispatch) => {
  dispatch(toggleModal(TREZOR_CONNECT_MODAL, { width: 700 }, true));
};

/**
 * Opens the Smart Wallet info modal.
 * @returns {Function}
 */
export const openCompoundUserWalletModal = props => (dispatch) => {
  dispatch(toggleModal(COMPOUND_USER_WALLET_MODAL, { width: 550, ...props }, true));
};

/**
 * Opens the Smart Wallet info modal.
 * @returns {Function}
 */
export const openAaveUserWalletModal = props => (dispatch) => {
  dispatch(toggleModal(AAVE_USER_WALLET_MODAL, { width: 550, ...props }, true));
};

export const openAaveEModeModal = props => async dispatch => {
  dispatch(toggleModal(AAVE_EMODE_MODAL, { width: 550, ...props }, true));
};

/**
 * Opens the modal that handles migrate from instadapp
 * @return {Function}
 */
export const openMigrateCdpFromInstadappModal = () => async (dispatch) => {
  dispatch(toggleModal(MIGRATE_CDP_FROM_INSTADAPP_MODAL, {
    width: 481, className: 'migrate-cdp-from-instadapp-modal',
  }, true));
};

export const openAutomationUnsubscribeFeedbackModal = ({
  protocol,
  strategyName,
  action,
}) => (dispatch) => {
  dispatch(toggleModal(AUTOMATION_UNSUB_FEEDBACK_MODAL, {
    width: 500, protocol, strategyName, action,
  }, true));
};

export const openCdpCreatedModal = () => (dispatch) => {
  dispatch(toggleModal(CDP_CREATED_MODAL, { width: 486 }, true));
};

/**
 * Opens the CompoundCollateralRequiredModal
 * which informs the user that his funds will be liquidated
 * if he continues with that action
 *
 * @param borrowValuesWithoutColl
 * @return {function(*): unknown}
 */
export const openCompoundCollateralRequiredModal = borrowValuesWithoutColl => async (dispatch) => {
  let resolve;
  const modalPromise = new Promise((res) => { resolve = res; });

  dispatch(toggleModal(COMPOUND_COLLATERAL_REQUIRED_MODAL, {
    width: 464, resolve, borrowValuesWithoutColl, className: 'compound-collateral-required-modal',
  }, true));

  const result = await modalPromise;
  dispatch(closeModal());

  return result;
};

export const openAaveCollateralRequiredModal = borrowValuesWithoutColl => async (dispatch) => {
  let resolve;
  const modalPromise = new Promise((res) => { resolve = res; });

  dispatch(toggleModal(AAVE_COLLATERAL_REQUIRED_MODAL, {
    width: 464, resolve, borrowValuesWithoutColl, className: 'aave-collateral-required-modal',
  }, true));

  const result = await modalPromise;
  dispatch(closeModal());

  return result;
};

export const openCompoundProxyMigrationModal = () => (dispatch) => {
  dispatch(toggleModal(COMPOUND_PROXY_MIGRATION_MODAL, { width: 600 }, true));
};

export const openCompoundBoostModal = (contextAction, additionalAction) => async (dispatch, getState) => {
  const { general: { walletType } } = getState();
  if (!isWalletTypeProxy(walletType)) return dispatch(openCompoundProxyMigrationModal());

  const { ratioTooLow, ratioTooHigh } = getState().compoundManage;
  if (ratioTooLow || ratioTooHigh) {
    const confirmed = await dispatch(confirmViaModal(t('errors.automatic_trigger')));
    if (!confirmed) return false;
  }

  dispatch(toggleModal(COMPOUND_BOOST_MODAL, { width: 500, contextAction, additionalAction }, true));
};

export const openCompoundRepayModal = (contextAction, additionalAction) => async (dispatch, getState) => {
  const { general: { walletType } } = getState();
  if (!isWalletTypeProxy(walletType)) return dispatch(openCompoundProxyMigrationModal());

  const { ratioTooLow, ratioTooHigh } = getState().compoundManage;
  if (ratioTooLow || ratioTooHigh) {
    const confirmed = await dispatch(confirmViaModal(t('errors.automatic_trigger')));
    if (!confirmed) return false;
  }

  dispatch(toggleModal(COMPOUND_REPAY_MODAL, { width: 500, contextAction, additionalAction }, true));
};

/**
 * Opens the COMP redeem modal.
 * @returns {Function}
 */
export const openCompRedeemModal = props => (dispatch) => {
  dispatch(toggleModal(COMP_REDEEM_MODAL, { width: 550, ...props }, true));
};

export const openAddressMonitorModal = () => (dispatch) => {
  dispatch(toggleModal(ADDRESS_MONITOR_MODAL, { width: 520 }, true));
};

export const openLoginModal = () => (dispatch) => {
  dispatch(toggleModal(LOGIN_MODAL, { width: 570 }, true));
};

export const openAaveProxyMigrationModal = () => (dispatch, getState) => {
  const { selectedMarket } = getState().aaveManage;
  if (selectedMarket.value === 'v1') {
    dispatch(toggleModal(AAVE_PROXY_V1_MIGRATION_MODAL, { width: 850 }, true));
  } else if (selectedMarket.value === 'v2default') {
    dispatch(toggleModal(AAVE_PROXY_MIGRATION_MODAL, { width: 600 }, true));
  }
};

export const openAaveBoostModal = inputAmount => async (dispatch, getState) => {
  const { general: { walletType } } = getState();
  if (!isWalletTypeProxy(walletType)) return dispatch(openAaveProxyMigrationModal());

  const { ratioTooLow, ratioTooHigh, actionsSelectValues } = getState().aaveManage;

  const borrowAsset = actionsSelectValues.boostBorrow.value;
  const supplyAsset = actionsSelectValues.boostSupply.value;

  if (borrowAsset === 'xSUSHI' || supplyAsset === 'xSUSHI') {
    const confirmed = await dispatch(confirmViaModal(t('errors.liquidity_warning', { '%asset': 'xSUSHI' })));
    if (!confirmed) throw new Error('error');
  }


  if (ratioTooLow || ratioTooHigh) {
    const confirmed = await dispatch(confirmViaModal(t('errors.automatic_trigger')));
    if (!confirmed) return false;
  }

  dispatch(toggleModal(AAVE_BOOST_MODAL, { width: 500, inputAmount }, true));
};

export const openAaveRepayModal = inputAmount => async (dispatch, getState) => {
  const { general: { walletType } } = getState();
  if (!isWalletTypeProxy(walletType)) return dispatch(openAaveProxyMigrationModal());

  const { ratioTooLow, ratioTooHigh, actionsSelectValues } = getState().aaveManage;
  const withdrawAsset = actionsSelectValues.repayWithdraw.value;
  const paybackAsset = actionsSelectValues.repayPayback.value;

  if (withdrawAsset === 'xSUSHI' || paybackAsset === 'xSUSHI') {
    const confirmed = await dispatch(confirmViaModal(t('errors.liquidity_warning', { '%asset': 'xSUSHI' })));
    if (!confirmed) return false;
  }

  if (ratioTooLow || ratioTooHigh) {
    const confirmed = await dispatch(confirmViaModal(t('errors.automatic_trigger')));
    if (!confirmed) return false;
  }

  dispatch(toggleModal(AAVE_REPAY_MODAL, { width: 500, inputAmount }, true));
};

export const openAaveBoostModalV2 = (contextAction, additionalAction) => async (dispatch, getState) => {
  const { general: { walletType } } = getState();
  if (!isWalletTypeProxy(walletType)) return dispatch(openAaveProxyMigrationModal());

  const { ratioTooLow, ratioTooHigh } = getState().compoundManage;
  if (ratioTooLow || ratioTooHigh) {
    const confirmed = await dispatch(confirmViaModal(t('errors.automatic_trigger')));
    if (!confirmed) return false;
  }

  dispatch(toggleModal(AAVE_BOOST_MODAL_V2, { width: 500, contextAction, additionalAction }, true));
};

export const openAaveRepayModalV2 = (contextAction, additionalAction) => async (dispatch, getState) => {
  const { general: { walletType } } = getState();
  if (!isWalletTypeProxy(walletType)) return dispatch(openAaveProxyMigrationModal());

  const { ratioTooLow, ratioTooHigh } = getState().compoundManage;
  if (ratioTooLow || ratioTooHigh) {
    const confirmed = await dispatch(confirmViaModal(t('errors.automatic_trigger')));
    if (!confirmed) return false;
  }

  dispatch(toggleModal(AAVE_REPAY_MODAL_V2, { width: 500, contextAction, additionalAction }, true));
};

// export const openEmergencyMessageModal = () => (dispatch) => {
//   // localStorage.setItem(LS_EMERGENCY_RESUB, 'true');
//   dispatch(toggleModal(EMERGENCY_MESSAGE_MODAL, { width: 486 }, true));
// };

export const openShifterSuccessModal = () => (dispatch, getState) => {
  const {
    fromCollAmount, fromDebtAmount, fromPosition, fromColl, fromDebt,
    toCollAmount, toDebtAmount, toPosition, toColl, toDebt,
  } = getState().form.shifter.values;
  const fromSupplyAsset = fromColl.helpValue;
  const fromBorrowAsset = fromDebt.helpValue;
  const toSupplyAsset = toColl.helpValue;
  const toBorrowAsset = toDebt.helpValue;
  const _fromPosition = fromPosition.label;
  const _toPosition = toPosition.label;
  const meta = toPosition.meta;
  const fromProtocol = fromPosition.meta.protocol;
  const toProtocol = toPosition.meta.protocol;
  dispatch(toggleModal(SHIFTER_SUCCESS_MODAL, {
    width: 650,
    fromSupplyAsset,
    fromBorrowAsset,
    toSupplyAsset,
    toBorrowAsset,
    meta,
    fromPosition: _fromPosition,
    toPosition: _toPosition,
    fromSupply: fromCollAmount,
    fromBorrow: fromDebtAmount,
    toSupply: toCollAmount,
    toBorrow: toDebtAmount,
    fromProtocol,
    toProtocol,
  }, true));
};

export const openAaveBorrowModal = (borrowAmount) => async (dispatch) => {
  dispatch(toggleModal(AAVE_BORROW_MODAL, { width: 486, borrowAmount }, true));
};

export const openAaveStakeModal = props => (dispatch) => {
  dispatch(toggleModal(AAVE_STAKE_MODAL, { width: 550, ...props }, true));
};

export const openReflexerBoostModal = (contextAction, additionalAction) => async (dispatch, getState) => {
  const { ratioTooLow, ratioTooHigh } = getState().reflexerManage;
  if (ratioTooLow || ratioTooHigh) {
    const confirmed = await dispatch(confirmViaModal(t('errors.automatic_trigger')));
    if (!confirmed) return false;
  }
  dispatch(toggleModal(RFL_BOOST_MODAL, { width: 500, contextAction, additionalAction }, true));
};

export const openReflexerRepayModal = (contextAction, additionalAction) => async (dispatch, getState) => {
  const { ratioTooLow, ratioTooHigh } = getState().reflexerManage;
  if (ratioTooLow || ratioTooHigh) {
    const confirmed = await dispatch(confirmViaModal(t('errors.automatic_trigger')));
    if (!confirmed) return false;
  }
  dispatch(toggleModal(RFL_REPAY_MODAL, { width: 500, contextAction, additionalAction }, true));
};

export const openLiquityBoostModal = inputAmount => async (dispatch, getState) => {
  const { ratioTooLow, ratioTooHigh } = getState().liquityManage;
  if (ratioTooLow || ratioTooHigh) {
    const confirmed = await dispatch(confirmViaModal(t('errors.automatic_trigger')));
    if (!confirmed) return false;
  }
  dispatch(toggleModal(LQTY_BOOST_MODAL, { width: 500, inputAmount }, true));
};

export const openLiquityRepayModal = inputAmount => async (dispatch, getState) => {
  const { ratioTooLow, ratioTooHigh } = getState().liquityManage;
  if (ratioTooLow || ratioTooHigh) {
    const confirmed = await dispatch(confirmViaModal(t('errors.automatic_trigger')));
    if (!confirmed) return false;
  }
  dispatch(toggleModal(LQTY_REPAY_MODAL, { width: 500, inputAmount }, true));
};

export const openLiquityStakingModal = () => async (dispatch, getState) => {
  dispatch(toggleModal(LQTY_STAKING_MODAL, { width: 500 }, true));
};

export const openLiquityRedeemModal = () => async (dispatch, getState) => {
  dispatch(toggleModal(LQTY_REDEEM_MODAL, { width: 500 }, true));
};

export const openSafeCreatedModal = safeId => async (dispatch, getState) => {
  dispatch(toggleModal(RFL_SAFE_CREATED_MODAL, { width: 500, safeId }, true));
};

export const openSimulationStartModal = () => async (dispatch, getState) => {
  dispatch(toggleModal(SIM_START_MODAL, { width: 500 }, true));
};

export const openChangeNetworkModal = () => async (dispatch, getState) => {
  dispatch(toggleModal(CHANGE_NETWORK_MODAL, { width: 500 }, true));
};

export const openSimulationEndModal = () => async (dispatch, getState) => {
  dispatch(toggleModal(SIM_END_MODAL, { width: 500 }, true));
};

export const openRecipeBookItemModal = (data) => async (dispatch, getState) => {
  dispatch(toggleModal(RECIPE_BOOK_ITEM_MODAL, { width: 800, data }, true));
};

export const openRecipeSuccessModal = (data) => async (dispatch) => {
  dispatch(toggleModal(RECIPE_SUCCESS_MODAL, { width: 760, data }, true));
};

export const openChangeMetamaskAccountModal = props => (dispatch) => {
  dispatch(toggleModal(MM_CHANGE_MODAL, { width: 550, ...props }, true));
};

export const openManageWalletsModal = props => (dispatch) => {
  dispatch(toggleModal(MANAGE_WALLETS_MODAL, { width: 550, ...props }, true));
};
export const openMigrateCompoundFromInstadappModal = (props) => (dispatch) => {
  dispatch(toggleModal(MIGRATE_COMPOUND_FROM_INSTADAPP_MODAL, { width: 600, ...props }, true));
};
export const openMigrateAaveFromInstadappModal = (props) => (dispatch) => {
  dispatch(toggleModal(MIGRATE_AAVE_FROM_INSTADAPP_MODAL, { width: 600, ...props }, true));
};
export const openCreateSmartWalletModal = (props) => (dispatch) => {
  dispatch(toggleModal(CREATE_SMART_WALLET_MODAL, { width: 440, ...props }, true));
};
export const openSmartSavingsClaimAndSupplyModal = (contextAction, actions) => (dispatch) => {
  dispatch(toggleModal(SMART_SAVINGS_CLAIM_AND_SUPPLY, { width: 500, contextAction, actions }, true));
};
export const openAutomationUnsubNextStepsModal = (props) => (dispatch) => {
  dispatch(toggleModal(AUTOMATION_UNSUB_NEXT_STEPS_MODAL, { width: 650, ...props }, true));
};
export const openTroveCreatedModal = props => (dispatch) => {
  dispatch(toggleModal(TROVE_CREATED_MODAL, { width: 486, ...props }, true));
};
export const openUniV3Image = props => (dispatch) => {
  dispatch(toggleModal(UNISWAP_V3_IMAGE_MODAL, { width: 600, ...props }, true));
};
