import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { connectTo } from '../../../actions/accountActions';

const ConnectWalletButtonCustomOption = props => (
  <div
    className={`button-option ${props.option.label} ${props.length === 4 ? 'mobile' : ''}`}
    onClick={() => props.connectTo(props.option)}
  >
    { props.option.LargeIcon ? <props.option.LargeIcon width="40px" height="25px" /> : <props.option.Icon width="40px" height="25px" /> }
    <p className="connect-wallet-button-name">{props.option.label}</p>
  </div>
);

ConnectWalletButtonCustomOption.propTypes = {
  option: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    Icon: PropTypes.func.isRequired,
    LargeIcon: PropTypes.func,
  }).isRequired,
  length: PropTypes.number.isRequired,
  connectTo: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  connectTo,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectWalletButtonCustomOption);
