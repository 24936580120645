import React from 'react';
import PropTypes from 'prop-types';
import TooltipWrapper from '../TooltipWrapper/TooltipWrapper';
import InfoIcon from './InfoIcon';

const InfoTooltip = ({ message }) => (
  <TooltipWrapper title={message}>
    <span className="info-tooltip-wrapper">
      <InfoIcon style={{ verticalAlign: 'text-top' }} />
    </span>
  </TooltipWrapper>
);

InfoTooltip.defaultProps = {
  message: '',
};

InfoTooltip.propTypes = {
  message: PropTypes.string,
};

export default InfoTooltip;
