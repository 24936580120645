import React from 'react';
import t from 'translate';
import './WhatsNew.scss';
import ExternalLink from '../Decorative/ExternalLink/ExternalLink';

const WhatsNew = () => (
  <div className="dashboard-page-wrapper automation-stats-wrapper">
    <div className="content-wrapper">
      <div className="width-container">
        <div className="whats-new-wrapper">
          <div className="whats-new-header">
            <div className="whats-new-title">
              <h1>{t('misc.whats_new_title')}</h1>
            </div>
          </div>
          <div className="whats-new-body">
            <div className="whats-new-items-wrapper">

              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 5.0.0
                  </div>
                  <div className="date">
                    May 31 2022
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li>
                      Introducing support for exchange and Aave V3 on Arbitrum and Optimism.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 4.5.49
                  </div>
                  <div className="date">
                    May 16 2022
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li>
                      Recipe quick access from the Recipe Book now features a full preview of balances and position state post-recipe.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 4.5.41
                  </div>
                  <div className="date">
                    May 5 2022
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li>
                      Recipe Creator now supports mStable actions.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 4.5.35
                  </div>
                  <div className="date">
                    April 28 2022
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li className="bolded">
                      DeFi Saver Automation comes to Liquity
                      <ul>
                        <li className="nested">
                          You can now use the new Stop Loss & Take Profit strategies to automatically close your Trove when ETH price reaches a certain point.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 4.5.29
                  </div>
                  <div className="date">
                    April 15 2022
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li>
                      Stop Loss and Take Profit Automated Strategies are now able to take the remaining position balance in both the collateral asset and Dai.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 4.5.20
                  </div>
                  <div className="date">
                    April 4 2022
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li>
                      We&apos;ve partnered with Lido to bring additional LDO rewards for positions staking ETH. <ExternalLink href="https://blog.defisaver.com/use-steth-in-makerdao-and-aave-and-win-ldo/">Learn more</ExternalLink>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 4.5.18
                  </div>
                  <div className="date">
                    March 31 2022
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li>
                      Aave dashboard has been optimized for stETH/ETH positions, including stETH rewards in APY estimate.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 4.5.14
                  </div>
                  <div className="date">
                    March 29 2022
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li className="bolded">
                      Stop Loss and Take Profit Automated Strategies
                      <ul>
                        <li className="nested">
                          Long-awaited Stop Loss and Take Profit strategies are finally here. You can now completely close your CDP when the price reaches a certain price, high or low.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 4.5.0
                  </div>
                  <div className="date">
                    March 7 2022
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li className="bolded">
                      New Automated Strategies
                      <ul>
                        <li className="nested">
                          Our completely new modular automation system is now live with the goal of greatly expanding availability.
                        </li>
                        <li className="nested">
                          The first new strategy that is now available is automated debt payback for MakerDAO users from Smart Savings integrated protocols (mStable, Yearn and Rari).
                        </li>
                        <li className="nested">
                          The update also includes an overhauled Automation setup dashboard interface.
                        </li>
                      </ul>
                    </li>
                    <li className="bolded">
                      Smart Savings dashboard updated for better APY clarity and improved protocols overview.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 4.4.0
                  </div>
                  <div className="date">
                    February 7 2022
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li className="bolded">
                      New actions interface is now available in Compound dashboard
                    </li>
                  </ul>
                </div>
              </div>
              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 4.3.0
                  </div>
                  <div className="date">
                    December 30 2021
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li className="bolded">
                      New Smart Savings featuring Yearn, Rari and mStable
                      <ul>
                        <li className="nested">
                          You can now access new options for earning yield on your stablecoins in our completely overhauled Smart Savings dashboard that includes completely new protocol integrations.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 4.2.0
                  </div>
                  <div className="date">
                    December 15 2021
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li className="bolded">
                      New Exchange
                      <ul>
                        <li className="nested">
                          The new Exchange features a completely new, redesigned interface, allows users to swap any wanted token (by entering token contract address) and allows anyone to easily send tokens received from the swap to a different address.
                        </li>
                      </ul>
                    </li>
                    <li className="bolded">
                      Send tokens action added as a secondary option when combining actions in our lending dashboards.
                    </li>
                    <li className="bolded">
                      New actions interface in dashboards
                      <ul>
                        <li className="nested">
                          We have completely redesigned our actions interface to provide quicker access to all controls, as well as allow users to seamlessly combine multiple actions in a single transaction.
                        </li>
                        <li className="nested">
                          Currently released for MakerDAO only, this feature will be expanded to other dashboards in the coming weeks.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 4.1.8
                  </div>
                  <div className="date">
                    November 17 2021
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li className="bolded">
                      Liquity & Reflexer support in the updated Loan Shifter
                      <ul>
                        <li className="nested">
                          In addition to MakerDAO, Compound & Aave, shifting between protocols is now also available
                          for Liquity and Reflexer positions. New wstETH-type MakerDAO vaults are supported too.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 4.1.7
                  </div>
                  <div className="date">
                    November 15 2021
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li>
                      Support for MakerDAO & Balancer 0-fee flashloans.
                    </li>
                    <li>
                      More details in Boost/Repay modals, including detailed fee breakdown & swap source.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 4.1.0
                  </div>
                  <div className="date">
                    November 4 2021
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li className="bolded">
                      New Portfolio
                      <ul>
                        <li className="nested">
                          Your DeFi Saver homepage has been redesigned to show more details about your positions.
                          You can now see aggregated stats for all your wallets using the &quot;Bundle&quot; option.
                        </li>
                      </ul>
                    </li>
                    <li className="bolded">
                      EIP 1559 support
                      <ul>
                        <li className="nested">
                          Send type 2 transactions using your hardware wallet, as well as other supported software wallets.
                        </li>
                      </ul>
                    </li>
                    <li className="bolded">
                      New Shifter options
                      <ul>
                        <li className="nested">
                          Our Loan Shifter now supports Aave positions, as well as previously disabled shifts like zero-debt positions.
                        </li>
                      </ul>
                    </li>
                    <li className="bolded">
                      Compound create
                      <ul>
                        <li className="nested">
                          Our Compound create form now has improved UI, with an option added for easier creation of COMP farming positions.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 4.0.122
                  </div>
                  <div className="date">
                    September 29 2021
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li className="">Add EIP-1559 data to gas price widget</li>
                  </ul>
                </div>
              </div>
              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 4.0.109
                  </div>
                  <div className="date">
                    September 6 2021
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li className="bolded">
                      Updated wallet management system
                      <ul>
                        <li className="nested">
                          Easily switch between recent wallets.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 4.0.90
                  </div>
                  <div className="date">
                    August 16 2021
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li className="">Yearn support in Recipe Creator</li>
                  </ul>
                </div>
              </div>
              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 4.0.74
                  </div>
                  <div className="date">
                    July 22 2021
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li className="bolded">Uniswap v3 liquidity providing
                      <ul>
                        <li className="nested">
                          Support for Uniswap v3 has been added to the Recipe Creator. You can now create new Uniswap v3 positions (NFTs), provide liquidity to or withdraw liquidity from existing positions, claim accrued rewards, as well as combine these actions with any other protocol interactions.
                        </li>
                      </ul>
                    </li>
                    <li className="bolded">Lido Support
                      <ul>
                        <li className="nested">
                          Lido is a liquid staking solution for ETH that allows users to stake any amount of ETH  without the need to maintain complex infrastructure. Lido introduced stETH tokens on a 1:1 basis representing your staked ETH. Using the Recipe Creator you can now stake your ETH, receiving stETH balance which you can use like regular ETH.
                        </li>
                      </ul>
                    </li>
                    <li className="bolded">Instadapp $INST airdrop claim for MakerDAO users
                      <ul>
                        <li className="nested">
                          We&lsquo;ve introduced the process of migrating the Maker Vault to Instadapp, claiming the airdrop, and migrating the vault back to the standard DSProxy for continued use in DeFi Saver, Oasis, and other Maker apps, with all steps completed seamlessly in a single transaction.
                          You can also select to keep the airdrop as is, swap it into any other asset or use it to add collateral, or pay back some debt of the Maker Vault in question.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 4.0.52
                  </div>
                  <div className="date">
                    June 18 2021
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li className="bolded">Liquity support
                      <ul>
                        <li className="nested">
                          The Liquity lending protocol introduced LUSD, an ETH-only stablecoin, together with new and unique protocol mechanisms, such as one-time borrowing fees.
                          You can now manage and create Liquity Troves in DeFi Saver, as well as create unique transactions with Liquity actions in our Recipe Creator.
                        </li>
                      </ul>
                    </li>
                    <li className="bolded">MakerDAO Vaults migration option added for Instadapp DSA2 proxies. </li>
                  </ul>
                </div>
              </div>
              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 4.0.45
                  </div>
                  <div className="date">
                    May 21 2021
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li className="bolded">Uniswap v2 liquidity providing
                      <ul>
                        <li className="nested">
                          Support for Uniswap v2 has been added to the Recipe Creator where you can now provide liquidity to or withdraw liquidity from Uniswap v2, as well as combine these actions with any other protocol interactions.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 4.0.40
                  </div>
                  <div className="date">
                    May 20 2021
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li>Smart Savings updated with Aave V2 support, lower gas usage, monthly stats and a visual refresh</li>
                  </ul>
                </div>
              </div>

              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 4.0.20
                  </div>
                  <div className="date">
                    April 29 2021
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li>Built-in Gnosis Safe support</li>
                  </ul>
                </div>
              </div>

              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 4.0.17
                  </div>
                  <div className="date">
                    April 27 2021
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li className="bolded">Aave v1 to v2 migration for Smart Wallet users</li>
                    <li className="bolded">Aave liquidity mining rewards estimation & redeeming UI</li>
                    <li>Changed Net APY to be based of off position balance (equity) and include AAVE/COMP rewards</li>
                  </ul>
                </div>
              </div>

              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 4.0.13
                  </div>
                  <div className="date">
                    April 26 2021
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li>Added stkAAVE mining recipe.</li>
                  </ul>
                </div>
              </div>

              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 4.0.0
                  </div>
                  <div className="date">
                    April 19 2021
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li className="bolded">Simulation mode
                      <ul>
                        <li className="nested">
                          Experiment with all DeFi Saver features that you know and love, inside a sandbox with a starting balance of 100 ETH.
                        </li>
                      </ul>
                    </li>
                    <li className="bolded">Recipe Creator
                      <ul>
                        <li className="nested">
                          Leverage the power of flash loans and the flexibility of DeFi Legos to create complex transactions including multiple protocols.
                        </li>
                      </ul>
                    </li>
                    <li>Various UI improvements</li>
                  </ul>
                </div>
              </div>

              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 3.8.0
                  </div>
                  <div className="date">
                    March 4 2021
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li className="bolded">Reflexer support
                      <ul>
                        <li className="nested">
                          The Reflexer protocol introduced RAI as a decentralized, stable, non-pegged asset backed by ETH and you can now manage Reflexer Safes in DeFi Saver.
                        </li>
                      </ul>
                    </li>
                    <li>Add support for all new UNI LP collateral types in MakerDAO auctions UI</li>
                    <li>Enable actions preview globally during portfolio monitoring</li>
                    <li>Add AAVE, 1INCH, BADGER, DIGG, MIR, SUSHI, CREAM, COVER, GUSD, BAL, UMA, REBTC, SNT, RPL, TORN, BNT and RAI to Exchange</li>
                  </ul>
                </div>
              </div>

              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 3.7.86
                  </div>
                  <div className="date">
                    February 25 2021
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li className="bolded">Enable AAVE-A, RENBTC-A and COMP-A Automation</li>
                  </ul>
                </div>
              </div>

              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 3.7.79
                  </div>
                  <div className="date">
                    February 16 2021
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li className="bolded">Add xSUSHI support to Aave v2.</li>
                  </ul>
                </div>
              </div>
              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 3.7.70
                  </div>
                  <div className="date">
                    February 8 2021
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li className="bolded">Add transaction history
                      <ul>
                        <li className="nested">
                          You can now check your account&apos;s full transaction history at DeFi Saver.
                        </li>
                      </ul>
                    </li>
                    <li className="bolded">Add BAL support to Aave v2.</li>
                  </ul>
                </div>
              </div>
              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 3.7.54
                  </div>
                  <div className="date">
                    January 21 2021
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li className="bolded">Add support for B.Protocol in MakerDAO
                      <ul>
                        <li className="nested">
                          You can now manage all MakerDAO positions imported into B.Protocol using DeFi Saver. As a reminder, B.Protocol is an alternative MakerDAO liquidations engine promising an improved liquidation process with reduced penalties for liquidated positions.
                        </li>
                      </ul>
                    </li>
                    <li className="bolded">Add support for AAVE, UNI and RENBTC CDPs.</li>
                    <li className="bolded">Add GUSD support to Aave v2.</li>
                  </ul>
                </div>
              </div>
              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 3.7.31
                  </div>
                  <div className="date">
                    December 29 2020
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li className="bolded">Add CRV support to Aave v2.</li>
                  </ul>
                </div>
              </div>
              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 3.7.19
                  </div>
                  <div className="date">
                    December 21 2020
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li className="bolded">Enable YFI-A Automation</li>
                  </ul>
                </div>
              </div>
              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 3.7.0
                  </div>
                  <div className="date">
                    December 3 2020
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li className="bolded">Aave v2 support</li>
                    <ul>
                      <li className="nested">
                        Learn more at{' '}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://medium.com/defi-saver/aave-v2-is-now-live-at-defi-saver-4cba29019e5d"
                        >our blog
                        </a>
                      </li>
                    </ul>
                  </ul>
                </div>
              </div>

              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 3.6.28
                  </div>
                  <div className="date">
                    November 30 2020
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li>Add AAVE Staking support for Aave</li>
                  </ul>
                </div>
              </div>

              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 3.6.17
                  </div>
                  <div className="date">
                    November 18 2020
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li>Add support for BAL and YFI CDPs</li>
                    <li>Add liquidation point indicator for Aave</li>
                    <li>Add show owned only option for Compound and Aave market info spreadsheets</li>
                  </ul>
                </div>
              </div>
              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 3.6.15
                  </div>
                  <div className="date">
                    November 13 2020
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li className="bolded">Add sorting options to market info spreadsheets in Compound and Aave dashboards</li>
                  </ul>
                </div>
              </div>
              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 3.6.13
                  </div>
                  <div className="date">
                    November 9 2020
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li className="bolded">Enable MANA-A and LINK-A Automation</li>
                    <li>Add AAVE support to Aave and Portfolio</li>
                    <li>Add stkAAVE to Portfolio</li>
                    <li>Show previously entered accounts for address tracking</li>
                  </ul>
                </div>
              </div>

              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 3.6.9
                  </div>
                  <div className="date">
                    November 5 2020
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li className="bolded">Add new options for rewarded COMP tokens withdrawing and instant swapping or supplying</li>
                    <li className="bolded">Add COMP rewards estimate</li>
                  </ul>
                </div>
              </div>

              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 3.6.7
                  </div>
                  <div className="date">
                    October 28 2020
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li className="bolded">Add stable borrowing option to Aave</li>
                    <li className="bolded">Enable ETH-B Automation</li>
                    <li>Add UNI support to Aave</li>
                    <li>Optimize the Loan Shifter UI and add more information for collateral and debt shifting</li>
                  </ul>
                </div>
              </div>
              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 3.6.5
                  </div>
                  <div className="date">
                    October 23 2020
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li>Show 7-day average gas price history in gas price widget.</li>
                  </ul>
                </div>
              </div>
              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 3.6.0
                  </div>
                  <div className="date">
                    October 20 2020
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li className="bolded">Shifter
                      <ul>
                        <li className="nested">Shift your current position between different lending protocols or change your collateral and debt assets.</li>
                      </ul>
                    </li>
                    <li className="bolded">Gas price widget
                      <ul>
                        <li className="nested">Check the current gas prices at any time, with data aggregated from multiple sources, including EthGasStation and GasNow.</li>
                      </ul>
                    </li>
                    <li>Improve MakerDAO CDPs loading time.</li>
                  </ul>
                </div>
              </div>

              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 3.5.12
                  </div>
                  <div className="date">
                    October 19 2020
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li>Add COMP support to Compound</li>
                  </ul>
                </div>
              </div>

              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 3.5.10
                  </div>
                  <div className="date">
                    October 15 2020
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li className="bolded">Update the MakerDAO Close feature
                      <ul>
                        <li className="nested">The fully reworked Close option for MakerDAO Vaults now lets you select whether you want to exit to DAI or the collateral used for the Vault and includes an improved, more streamlined interface</li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 3.5.9
                  </div>
                  <div className="date">
                    October 14 2020
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li className="bolded">Update Boost and Repay for Aave to support flash loans
                      <ul>
                        <li className="nested">
                          This means you will now be able to Boost or Repay much greater amounts in one transaction compared to before
                        </li>
                      </ul>
                    </li>
                    <li className="bolded">Update logic for Boost, Repay and all other features including swaps to give precedence to Uniswap as on-chain exchange fallback
                      <ul>
                        <li className="nested">
                          This should very noticeably reduce required gas and transaction costs in many cases. Applies to all protocols
                        </li>
                      </ul>
                    </li>
                    <li>Update the migration process for Aave to resolve issues happening in some specific cases</li>
                  </ul>
                </div>
              </div>

              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 3.5.3
                  </div>
                  <div className="date">
                    October 6 2020
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li>Add support for creation of same asset (like DAI/DAI) leveraged Compound positions</li>
                  </ul>
                </div>
              </div>

              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 3.5.2
                  </div>
                  <div className="date">
                    October 5 2020
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li>Add UNI support to Compound</li>
                  </ul>
                </div>
              </div>

              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 3.5.0
                  </div>
                  <div className="date">
                    October 1 2020
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li className="bolded">Aave automation
                      <ul>
                        <li className="nested">
                          You can now finally automate your Aave position to keep it safe against liquidation{' '}
                          in case of market drops, as well as leverage it automatically in case of market uptrends.<br />
                          <a target="_blank" rel="noopener noreferrer" href="https://medium.com/defi-saver/aavetomation-is-now-live-with-automatic-liquidation-protection-and-leveraging-available-c5c293f3f16e">Read more here.</a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 3.4.26
                  </div>
                  <div className="date">
                    October 1 2020
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li>Add support for COMP/LRC/LINK CDPs</li>
                  </ul>
                </div>
              </div>

              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 3.4.20
                  </div>
                  <div className="date">
                    September 17 2020
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li>Add support for Uniswap token on Portfolio/Exchange</li>
                  </ul>
                </div>
              </div>

              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 3.4.16
                  </div>
                  <div className="date">
                    September 15 2020
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li>Add support for DeFiPulse Index on Portfolio/Exchange</li>
                  </ul>
                </div>
              </div>
              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 3.4.15
                  </div>
                  <div className="date">
                    September 15 2020
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li>Add support for PAX CDPs</li>
                  </ul>
                </div>
              </div>
              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 3.4.14
                  </div>
                  <div className="date">
                    September 10 2020
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li className="bolded">Add Uniswap v2 integration
                      <ul>
                        <li className="nested">
                          All actions that include token swaps now support Uniswap v2, alongside previously integrated 0x, Kyber, Oasis and Uniswap v1s.
                        </li>
                      </ul>
                    </li>
                    <li>Update MakerDAO Flipper bidding contract</li>
                  </ul>
                </div>
              </div>
              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 3.4.13
                  </div>
                  <div className="date">
                    September 7 2020
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li>Update Aave import contracts</li>
                    <li>Update trade size impact detection in the Exchange</li>
                  </ul>
                </div>
              </div>
              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 3.4.12
                  </div>
                  <div className="date">
                    September 3 2020
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li className="bolded">Add DeFi Saver newsletter subscriptions
                      <ul>
                        <li className="nested">
                          You can now receive DeFi Saver news straight to your inbox - the option to subscribe is in the app’s footer.
                        </li>
                      </ul>
                    </li>
                    <li>Update MakerDAO Flipper contract addresses</li>
                    <li>Minor visual and copy fixes</li>
                  </ul>
                </div>
              </div>
              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 3.4.11
                  </div>
                  <div className="date">
                    September 1 2020
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li className="bolded">Add YFI support to Aave
                      <ul>
                        <li className="nested">
                          The Aave team added YFI support to their protocol and so have we. You can now Boost/Repay YFI based positions in our Aave dashboard.
                        </li>
                      </ul>
                    </li>
                    <li>Update Automation high gas price conditions information</li>
                  </ul>
                </div>
              </div>
              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 3.4.8
                  </div>
                  <div className="date">
                    August 27 2020
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li className="bolded">
                      Add COMP, YFI and CRV to Exchange and Portfolio
                      <ul>
                        <li className="nested">
                          The COMP, YFI and CRV tokens are all now available in the Exchange and their balance will be shown in your Portfolio.
                        </li>
                      </ul>
                    </li>
                    <li>Update Portfolio to only show &gt;$1 balances</li>
                    <li>Move language menu to the sidebar</li>
                  </ul>
                </div>
              </div>
              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 3.4.7
                  </div>
                  <div className="date">
                    August 26 2020
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li className="bolded">
                      Add Create long/short position option for Compound
                      <ul>
                        <li className="nested">
                          You can now create an instantly leveraged long or short position with any of the supported assets in the Compound protocol using DeFi Saver.
                        </li>
                      </ul>
                    </li>
                    <li className="bolded">
                      Add new Exchange
                      <ul>
                        <li className="nested">
                          After countless security reviews, our Exchange is back online - now with more tokens supported and better optimized gas usage.
                        </li>
                      </ul>
                    </li>
                    <li>Update Spanish translation</li>
                  </ul>
                </div>
              </div>
              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 3.4.6
                  </div>
                  <div className="date">
                    August 26 2020
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li>
                      Update trade size impact indicators on all actions with token swaps
                    </li>
                    <li>Update USDT behaviour in Aave
                    </li>
                    <li>Update USDT behaviour in Compound</li>
                    <li>Update Maker leveraged open contract</li>
                  </ul>
                </div>
              </div>
              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 3.4.1
                  </div>
                  <div className="date">
                    August 24 2020
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li className="bolded">
                      Add Spanish translation
                      <ul>
                        <li className="nested">
                          DeFi Saver is now available in Spanish thanks to the help from the members of the LATAM DeFi community. Let us know in our Discord if you would like to see DeFi Saver available in more languages or if you would like to help out with some of the translations.
                        </li>
                      </ul>
                    </li>
                    <li>Update Chinese translation</li>
                  </ul>
                </div>
              </div>
              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 3.4.0
                  </div>
                  <div className="date">
                    August 24 2020
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li>
                      Update MakerDAO behaviour for updated dust parameter (100DAI)
                    </li>
                    <li>Update Boost/Repay flash loan contracts for MakerDAO and Compound</li>
                    <li>Minor visual updates for improved consistency</li>
                  </ul>
                </div>
              </div>
              <div className="whats-new-item">
                <div className="whats-new-item-header">
                  <div className="title">
                    Version 3.3
                  </div>
                  <div className="date">
                    August 2020
                  </div>
                </div>
                <div className="whats-new-item-body">
                  <ul>
                    <li className="bolded">
                      Aave dashboard
                    </li>
                    <li className="bolded">Introduce Automation conditions as a response to high gas prices</li>
                    <li>Update core exchange contracts for all actions including token swaps</li>
                    <li>Update Portfolio to include Aave positions</li>
                    <li>Update Compound and Aave dashboards with differentiating colours</li>
                    <li>Update market stats for Compound and Aave to show total size and available liquidity</li>
                    <li>Update MakerDAO Flipper contract addresses</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default WhatsNew;
