import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { connect } from 'react-redux';
import {
  SelectOption,
  SelectValueContainer,
} from 'components/Forms/SelectComponent/SelectComponent';
import withErrorFallback from '../../../../../../../ErrorFallback/ErrorFallback';

const InterestModeActionField = ({
  type, i, actualValue, isPointer, originalValue, handleChange, interestModeOptions,
}) => (
  <Select
    className="select box"
    classNamePrefix="select"
    value={interestModeOptions.find(i => i.value === actualValue) || null}
    components={{
      Option: SelectOption,
      ValueContainer: SelectValueContainer,
    }}
    onChange={(e) => {
      handleChange({ target: e }, i);
    }}
    options={interestModeOptions}
    onBlur={event => event.preventDefault()}
  />
);

InterestModeActionField.defaultProps = {
  actualValue: '',
  isPointer: false,
};

InterestModeActionField.propTypes = {
  interestModeOptions: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  type: PropTypes.object.isRequired,
  i: PropTypes.number.isRequired,
  originalValue: PropTypes.string.isRequired,
  isPointer: PropTypes.bool,
  actualValue: PropTypes.string,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default withErrorFallback(connect(mapStateToProps, mapDispatchToProps)(InterestModeActionField));
