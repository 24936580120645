/* eslint-disable */
import React from 'react';

export default function UniswapV3ProtocolIcon(props) {
  return (
    <svg width="29" height="32" viewBox="0 0 29 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g>
        <path d="M8.05462 5.01971C7.68982 4.96347 7.67444 4.95688 7.8461 4.93065C8.17508 4.88037 8.9519 4.9489 9.4872 5.0754C10.7369 5.37065 11.874 6.12698 13.0879 7.47024L13.4104 7.82709L13.8717 7.75338C15.8152 7.44296 17.7923 7.68967 19.4459 8.44893C19.9008 8.65782 20.6182 9.07363 20.7078 9.18047C20.7363 9.21452 20.7887 9.43372 20.8242 9.66763C20.947 10.4769 20.8855 11.0972 20.6366 11.5604C20.501 11.8126 20.4934 11.8925 20.5846 12.1082C20.6574 12.2804 20.8601 12.4078 21.0608 12.4075C21.4717 12.407 21.914 11.7467 22.119 10.828L22.2005 10.4631L22.3617 10.6447C23.2463 11.6408 23.9411 12.9993 24.0605 13.9662L24.0915 14.2183L23.9429 13.9892C23.687 13.5948 23.4298 13.3263 23.1006 13.1097C22.507 12.7194 21.8795 12.5866 20.2176 12.4995C18.7165 12.4209 17.867 12.2935 17.0246 12.0205C15.5914 11.556 14.8689 10.9375 13.1664 8.71745C12.4102 7.73138 11.9428 7.18581 11.4779 6.74646C10.4215 5.74813 9.3834 5.22456 8.05462 5.01971Z" fill="white"/>
      </g>
      <g>
        <path d="M21.0462 7.23567C21.084 6.57492 21.174 6.13909 21.3554 5.74107C21.4272 5.58353 21.4943 5.45459 21.5048 5.45459C21.5151 5.45459 21.4838 5.57087 21.4353 5.71298C21.3036 6.09924 21.2819 6.62758 21.3727 7.24229C21.4879 8.02223 21.5535 8.13476 22.383 8.97726C22.7719 9.37245 23.2245 9.87083 23.3884 10.0848L23.6867 10.4739L23.3884 10.1958C23.0237 9.8558 22.1849 9.19266 21.9996 9.09786C21.8753 9.03429 21.8569 9.03539 21.7802 9.1112C21.7096 9.18106 21.6947 9.28602 21.685 9.78228C21.6697 10.5557 21.5636 11.0521 21.3077 11.5485C21.1692 11.817 21.1473 11.7597 21.2726 11.4567C21.3661 11.2304 21.3756 11.1309 21.375 10.3822C21.3735 8.87784 21.1938 8.51617 20.1401 7.89662C19.8732 7.73967 19.4332 7.51332 19.1626 7.3936C18.8919 7.27387 18.677 7.16958 18.6848 7.16178C18.7146 7.13228 19.7422 7.43039 20.156 7.58855C20.7713 7.82378 20.8728 7.85427 20.9475 7.82589C20.9977 7.80686 21.022 7.66188 21.0462 7.23567Z" fill="white"/>
      </g>
      <g>
        <path d="M8.7634 9.81056C8.02284 8.79722 7.56463 7.24353 7.66383 6.08207L7.6945 5.72266L7.86308 5.75323C8.17964 5.81061 8.72546 6.01253 8.98104 6.16683C9.68241 6.59018 9.98606 7.14758 10.295 8.57889C10.3855 8.99813 10.5042 9.47256 10.5588 9.63318C10.6467 9.8917 10.9791 10.4956 11.2492 10.8878C11.4438 11.1702 11.3146 11.3041 10.8844 11.2655C10.2274 11.2065 9.3375 10.5961 8.7634 9.81056Z" fill="white"/>
      </g>
      <g>
        <path d="M20.1485 17.3638C16.6877 15.9789 15.4688 14.7767 15.4688 12.7483C15.4688 12.4498 15.4791 12.2056 15.4917 12.2056C15.5043 12.2056 15.6382 12.3041 15.7892 12.4245C16.491 12.984 17.2769 13.2229 19.4525 13.5384C20.7328 13.724 21.4532 13.874 22.1178 14.093C24.2301 14.7893 25.537 16.2023 25.8488 18.1269C25.9394 18.6862 25.8862 19.7349 25.7394 20.2876C25.6235 20.7242 25.2697 21.5111 25.1758 21.5414C25.1498 21.5497 25.1244 21.4506 25.1176 21.3157C25.0821 20.593 24.7147 19.8895 24.0976 19.3624C23.396 18.7632 22.4534 18.2861 20.1485 17.3638Z" fill="white"/>
      </g>
      <g>
        <path d="M17.7182 17.9219C17.6748 17.6655 17.5996 17.338 17.5511 17.1941L17.4628 16.9326L17.6268 17.1156C17.8538 17.3688 18.0332 17.6928 18.1852 18.1244C18.3012 18.4538 18.3143 18.5518 18.3134 19.087C18.3125 19.6125 18.298 19.7227 18.1909 20.0191C18.0219 20.4867 17.8123 20.8182 17.4604 21.174C16.8282 21.8135 16.0154 22.1676 14.8425 22.3144C14.6386 22.3398 14.0444 22.3829 13.522 22.4098C12.2054 22.4779 11.3389 22.6186 10.5603 22.8904C10.4484 22.9293 10.3484 22.9532 10.3383 22.9431C10.3068 22.9119 10.8369 22.5978 11.2747 22.3881C11.8921 22.0925 12.5067 21.9313 13.8838 21.7034C14.564 21.5906 15.2665 21.4541 15.4449 21.3997C17.1294 20.8864 17.9953 19.5617 17.7182 17.9219Z" fill="white"/>
      </g>
      <g>
        <path d="M19.3052 20.7288C18.8453 19.7463 18.7398 18.7977 18.9917 17.9131C19.0187 17.8186 19.062 17.7412 19.088 17.7412C19.1141 17.7412 19.2226 17.7995 19.3291 17.8707C19.5411 18.0125 19.9661 18.2513 21.0982 18.8648C22.511 19.6304 23.3166 20.2233 23.8644 20.9006C24.3442 21.4937 24.641 22.1693 24.7839 22.9932C24.8648 23.4597 24.8173 24.5825 24.6968 25.0523C24.317 26.534 23.4342 27.6977 22.175 28.3769C21.9905 28.4764 21.8249 28.5581 21.807 28.5584C21.789 28.5588 21.8563 28.3889 21.9564 28.181C22.3799 27.3008 22.4282 26.4448 22.1079 25.4919C21.9118 24.9084 21.512 24.1965 20.7048 22.9932C19.7661 21.5944 19.536 21.2221 19.3052 20.7288Z" fill="white"/>
      </g>
      <g>
        <path d="M6.30619 26.0428C7.5905 24.965 9.18849 24.1994 10.6441 23.9642C11.2714 23.863 12.3165 23.9031 12.8974 24.051C13.8285 24.2878 14.6615 24.8184 15.0947 25.4504C15.518 26.068 15.6997 26.6063 15.8888 27.804C15.9634 28.2765 16.0445 28.7508 16.0691 28.8583C16.2111 29.4791 16.4874 29.9751 16.8299 30.2244C17.3738 30.6201 18.3104 30.6445 19.2318 30.2874C19.3882 30.2267 19.5239 30.1848 19.5334 30.1943C19.567 30.2273 19.1029 30.5359 18.7756 30.6985C18.3352 30.9171 17.985 31.0017 17.5197 31.0017C16.6758 31.0017 15.9752 30.5751 15.3906 29.7051C15.2756 29.5338 15.017 29.0211 14.816 28.5654C14.1988 27.1662 13.894 26.74 13.1773 26.2735C12.5537 25.8676 11.7493 25.7949 11.1443 26.0897C10.3494 26.4771 10.1277 27.4866 10.6969 28.1264C10.9232 28.3807 11.3451 28.5999 11.6901 28.6426C12.3356 28.7225 12.8903 28.2345 12.8903 27.5867C12.8903 27.1662 12.7276 26.9262 12.3182 26.7425C11.759 26.4917 11.158 26.785 11.1608 27.307C11.1621 27.5297 11.2596 27.6694 11.4842 27.7704C11.6282 27.8352 11.6316 27.8403 11.5141 27.816C11.001 27.7103 10.8808 27.0956 11.2934 26.6874C11.7886 26.1976 12.8128 26.4137 13.1645 27.0826C13.3122 27.3635 13.3294 27.9229 13.2006 28.2606C12.9123 29.0168 12.0716 29.4143 11.2189 29.198C10.6383 29.0506 10.4019 28.8911 9.70194 28.1745C8.48561 26.9291 8.01341 26.6878 6.25985 26.4158L5.92383 26.3637L6.30619 26.0428Z" fill="white"/>
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M0.59822 3.74487C4.66022 8.64491 7.45792 10.6666 7.76883 11.0937C8.02553 11.4464 7.92892 11.7634 7.48916 12.0119C7.2446 12.1501 6.74181 12.2901 6.49007 12.2901C6.20536 12.2901 6.10759 12.1809 6.10759 12.1809C5.94251 12.0253 5.84953 12.0525 5.00173 10.5568C3.82474 8.74161 2.83974 7.23582 2.81287 7.2106C2.75074 7.15231 2.75181 7.15427 4.88173 10.942C5.22586 11.7315 4.95017 12.0213 4.95017 12.1337C4.95017 12.3625 4.8874 12.4827 4.6035 12.7974C4.13023 13.3222 3.91867 13.9118 3.76595 15.1321C3.59475 16.5 3.11332 17.4662 1.77915 19.12C0.998178 20.0881 0.870388 20.2655 0.673325 20.6557C0.425108 21.147 0.356858 21.4222 0.329196 22.0426C0.299958 22.6987 0.356901 23.1225 0.558551 23.7497C0.735088 24.2989 0.919361 24.6614 1.39045 25.3866C1.79699 26.0124 2.03107 26.4775 2.03107 26.6595C2.03107 26.8042 2.05889 26.8044 2.68886 26.663C4.19646 26.3247 5.42063 25.7295 6.1091 25.0001C6.53521 24.5487 6.63525 24.2995 6.63849 23.681C6.64062 23.2764 6.62631 23.1916 6.51626 22.9588C6.33713 22.58 6.01102 22.2649 5.29227 21.7767C4.35052 21.1368 3.94827 20.6216 3.83718 19.9132C3.74602 19.3319 3.85176 18.9218 4.37275 17.8365C4.91199 16.7132 5.04563 16.2345 5.13603 15.1022C5.19442 14.3706 5.27527 14.0821 5.48674 13.8506C5.7073 13.6091 5.90584 13.5273 6.45166 13.4532C7.34153 13.3324 7.90817 13.1035 8.37393 12.677C8.77796 12.3069 8.94704 11.9503 8.97297 11.4136L8.99268 11.0067L8.76688 10.7451C7.94921 9.79748 0.0506309 3 0.000311224 3C-0.0104385 3 0.258632 3.33522 0.59822 3.74487ZM2.49008 22.785C2.67495 22.4595 2.57671 22.0413 2.26748 21.8368C1.9753 21.6438 1.52139 21.7348 1.52139 21.9864C1.52139 22.0632 1.56411 22.1191 1.6604 22.1683C1.82252 22.2512 1.83429 22.3445 1.70673 22.535C1.57755 22.728 1.58798 22.8977 1.73616 23.0129C1.97497 23.1988 2.31303 23.0966 2.49008 22.785Z" fill="white"/>
      <g>
        <path fillRule="evenodd" clipRule="evenodd" d="M9.5544 13.6784C9.13665 13.806 8.73056 14.246 8.60486 14.7074C8.52818 14.9889 8.57169 15.4827 8.68655 15.6352C8.87212 15.8815 9.05157 15.9464 9.53749 15.943C10.4888 15.9364 11.3159 15.5309 11.412 15.0239C11.4908 14.6083 11.1276 14.0324 10.6273 13.7796C10.3691 13.6491 9.82005 13.5974 9.5544 13.6784ZM10.6665 14.5428C10.8132 14.3356 10.749 14.1117 10.4995 13.9602C10.0244 13.6718 9.30581 13.9105 9.30581 14.3566C9.30581 14.5787 9.68053 14.821 10.024 14.821C10.2526 14.821 10.5655 14.6855 10.6665 14.5428Z" fill="white"/>
      </g>
      <circle cx="22.5" cy="6.5" r="6.5" fill="white"/>
      <path d="M22.7677 5.3535L21.3011 9.00016H19.7411L18.2744 5.3535H19.8211L20.5544 7.30683L21.3411 5.3535H22.7677Z" fill="#E71A6A" />
      <path d="M25.3432 6.18016C25.7032 6.26905 25.9788 6.43127 26.1699 6.66683C26.3654 6.89794 26.4632 7.17794 26.4632 7.50683C26.4632 7.78683 26.3854 8.04905 26.2299 8.2935C26.0788 8.5335 25.8432 8.72905 25.5232 8.88016C25.2032 9.03127 24.8054 9.10683 24.3299 9.10683C24.001 9.10683 23.6721 9.07127 23.3432 9.00016C23.0188 8.92905 22.7343 8.82461 22.4899 8.68683L23.0232 7.52683C23.2143 7.63794 23.4188 7.72239 23.6365 7.78016C23.8588 7.83794 24.0721 7.86683 24.2765 7.86683C24.4588 7.86683 24.6054 7.83572 24.7165 7.7735C24.8277 7.71127 24.8832 7.62239 24.8832 7.50683C24.8832 7.2935 24.6988 7.18683 24.3299 7.18683H23.7099V6.22016L24.3965 5.52016H22.7299V4.3335H26.2099V5.30016L25.3432 6.18016Z" fill="#E71A6A"/>
    </svg>

  );
}
