import {
  GET_UNIV3_NFT_REQUEST,
  GET_UNIV3_NFT_SUCCESS,
  GET_UNIV3_NFT_FAILURE,
  APPROVE_NFT_ON_ADDRESS_REQUEST,
  APPROVE_NFT_ON_ADDRESS_SUCCESS,
  APPROVE_NFT_ON_ADDRESS_FAILURE,
  UNISWAP_V3_MOCK_POSITION_REQUEST,
  UNISWAP_V3_MOCK_POSITION_SUCCESS,
  UNISWAP_V3_MOCK_POSITION_FAILURE,
  UNISWAP_V3_POOL_REQUEST,
  UNISWAP_V3_POOL_SUCCESS,
  UNISWAP_V3_POOL_FAILURE,
  UNISWAP_V3_MOCK_POOL_REQUEST,
  UNISWAP_V3_MOCK_POOL_SUCCESS,
  UNISWAP_V3_MOCK_POOL_FAILURE,
} from 'actionTypes/uniswapV3ActionTypes';

const INITIAL_STATE = {
  account: [],
  proxy: [],
  gettingIds: false,
  gettingIdsError: '',
  approvingNft: false,
  approvingNftError: '',
  pool: {},
  gettingPool: false,
  gettingPoolError: '',
  mockingPosition: false,
  mockingPositionError: '',
  mockingPool: false,
  mockingPoolError: '',
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_UNIV3_NFT_REQUEST:
      return {
        ...state,
        gettingIds: true,
        gettingIdsError: '',
      };
    case GET_UNIV3_NFT_SUCCESS:
      return {
        ...state,
        gettingIds: false,
        proxy: payload.proxy,
        account: payload.account,
      };
    case GET_UNIV3_NFT_FAILURE:
      return {
        ...state,
        gettingIds: false,
        gettingIdsError: payload,
      };
    case APPROVE_NFT_ON_ADDRESS_REQUEST:
      return {
        ...state,
        approvingNft: true,
        approvingNftError: '',
      };
    case APPROVE_NFT_ON_ADDRESS_SUCCESS:
      return {
        ...state,
        approvingNft: false,
        approvingNftError: '',
      };
    case APPROVE_NFT_ON_ADDRESS_FAILURE:
      return {
        ...state,
        approvingNft: false,
        approvingNftError: payload,
      };
    case UNISWAP_V3_POOL_REQUEST:
      return {
        ...state,
        gettingPool: true,
        gettingPoolError: '',
      };
    case UNISWAP_V3_POOL_SUCCESS:
      return {
        ...state,
        gettingPool: false,
        gettingPoolError: '',
        pool: { ...payload.pool },
      };
    case UNISWAP_V3_POOL_FAILURE:
      return {
        ...state,
        gettingPool: false,
        gettingPoolError: payload,
        pool: {},
      };
    case UNISWAP_V3_MOCK_POOL_REQUEST:
      return {
        ...state,
        mockingPool: true,
        mockingPoolError: '',
      };
    case UNISWAP_V3_MOCK_POOL_SUCCESS:
      return {
        ...state,
        mockingPool: false,
        mockingPoolError: '',
      };
    case UNISWAP_V3_MOCK_POOL_FAILURE:
      return {
        ...state,
        mockingPool: false,
        mockingPoolError: payload,
      };
    case UNISWAP_V3_MOCK_POSITION_REQUEST:
      return {
        ...state,
        mockingPosition: true,
        mockingPositionError: '',
      };
    case UNISWAP_V3_MOCK_POSITION_SUCCESS:
      return {
        ...state,
        mockingPosition: false,
        mockingPositionError: '',
      };
    case UNISWAP_V3_MOCK_POSITION_FAILURE:
      return {
        ...state,
        mockingPosition: false,
        mockingPositionError: payload,
      };
    default:
      return state;
  }
};
