/* eslint-disable */
import React from 'react';
import { v4 as uuid } from 'uuid';

export default function AnyswapBridgeIcon(props) {
  const id = `anyswap_bridge_icon${uuid()}`;
  const fill = `url(#${id})`;
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z" fill={fill} />
      <path fillRule="evenodd" clipRule="evenodd" d="M6.45503 13.3777C5.46754 14.3652 5.04433 15.7759 5.39701 17.116C5.74968 18.4562 6.73717 19.4437 8.07734 19.7964C9.4175 20.149 10.8282 19.7258 11.8157 18.7383L18.446 12.1786C19.3629 11.2616 20.9147 11.2616 21.7611 12.108C22.6075 12.9545 22.6781 14.5062 21.6906 15.4232L19.5745 17.5392L18.446 16.3401L17.5996 20.6428L21.9022 19.7964L20.7031 18.5973L22.8192 16.4812C23.8066 15.4937 24.2299 14.083 23.8772 12.7429C23.5245 11.4027 22.537 10.4152 21.1968 10.0625C19.8567 9.70985 18.446 10.1331 17.4585 11.1206L10.8282 17.6803C9.91125 18.5973 8.35948 18.5973 7.51306 17.7508C6.66664 16.9044 6.5961 15.3527 7.58359 14.4357L9.55857 12.4607L10.7577 13.6598L11.6041 9.35718L7.30145 10.2036L8.50055 11.4027L6.45503 13.3777Z" fill="white"/>
      <defs>
        <linearGradient id={id} x1="15" y1="2.59634e-10" x2="14.9813" y2="30" gradientUnits="userSpaceOnUse">
          <stop stopColor="#5F6BFB"/>
          <stop offset="1" stopColor="#464EAF"/>
        </linearGradient>
      </defs>
    </svg>

  );
}
