import memoize from 'memoizee';
import dfs from '@defisaver/sdk';
import { CdpId, CdpIlk } from 'components/Recipes/RecipeCreator/inputTypes';
import { ilkToAsset, getIlkInfo } from '@defisaver/tokens';
import RecipeAction from 'recipeActions/RecipeAction';
import {
  getCdpManagerForType,
  getCollateralInfo as _getCollateralInfo,
  latestCdpId as _latestCdpId,
} from 'services/makerServices/makerMcdService';
import { stringToBytes } from 'services/utils';
import CreateIcon from '../recipeIcons/Create.svg';

const latestCdpId = memoize(_latestCdpId);
const getCollateralInfo = memoize(_getCollateralInfo);

const emptyCdp = {
  collateral: 0,
  collateralUsd: 0,
  debtDai: 0,
  debtUsd: 0,
  liqRatio: 1.5,
  assetPrice: 600,
  debtAssetPrice: 1,
};

export default class MakerOpenVaultAction extends RecipeAction {
  static prettyName = 'Create New Vault';

  static protocol = 'maker';

  static protocolPrettyName = 'Maker';

  static description = 'Creates new, blank Maker Vault.';

  constructor(ilk = 'ETH-A') {
    super();
    this.inputs = [
      new CdpIlk('Ilk', ilk),
    ];
    this.output = new CdpId('output', 0);
  }

  async getAfterValues(_balances = {}, returnValues = [], actions = [], _positions = {}) {
    const positions = { ..._positions };
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const latestLiveCdpId = await latestCdpId();
    const latestRecipeCdpId = Object.keys(positions)
      .filter(id => id.substr(0, 5) === 'maker')
      .map(id => +id.substr(6));
    const id = Math.max(latestLiveCdpId, ...latestRecipeCdpId) + 1;
    const ilkInfo = getIlkInfo(args[0]);
    positions[`maker_${id}`] = {
      ...emptyCdp,
      asset: ilkInfo.asset,
      ilk: stringToBytes(args[0]),
      ilkLabel: args[0],
      debtAsset: 'DAI',
      ...(await getCollateralInfo(stringToBytes(args[0]))),
      id,
      value: `$${this.id}`,
      type: ilkInfo.isCrop ? 'crop' : 'mcd',
    };
    return {
      returnValue: new CdpId('output', id),
      balances: _balances,
      positions,
    };
  }

  async toDfsAction() {
    const ilkData = getIlkInfo(this.inputs[0].value);
    const managerAddress = getCdpManagerForType(ilkData.isCrop ? 'crop' : 'mcd');
    return new dfs.actions.maker.MakerOpenVaultAction(getIlkInfo(this.inputs[0].value).join, managerAddress);
  }

  static getIcon() {
    return CreateIcon;
  }

  _getPrettyName(actionCalls, actions) {
    const id = this.id;
    const thisActionIndex = actions.findIndex((a) => a.id === id);
    const returnOfThisAction = actionCalls[thisActionIndex]?.returnValue;
    const args = this.mapReturnValuesToArgs(actionCalls.map(a => a.returnValue), actions);
    if (thisActionIndex !== -1 && returnOfThisAction) {
      return `Create New ${args[0]} Vault #${returnOfThisAction.value}`;
    }
    return `Create New ${args[0]} Vault`;
  }
}
