import React from 'react';
import t from 'translate';
import Select from 'react-select';
import PropTypes from 'prop-types';
import DataItem from '../../../../DataItem/DataItem';
import {
  filterBorrowedAssetsFromUsedAssets,
  filterCollateralAssetsFromUsedAssets,
} from '../../../../../services/moneymarketCommonService';
import AssetContainer from '../AssetContainer/AssetContainer';

import './MigrateModalContent.scss';

const MigrateModalContent = ({
  selectOptions,
  selected,
  setSelected,
  suppliedUsd,
  borrowedUsd,
  usedAssets,
}) => {
  const hasSelect = selectOptions.length > 0;
  return (
    <div className="Flex FlexColumn FlexTop position-container">
      {hasSelect > 0 && (
        <div className="Flex FlexStart Width100">
          <span className="select-description">{t('compound.migrate_choose_position')}:</span>
          <Select
            className="select box"
            classNamePrefix="select"
            value={selected}
            onChange={(option) => setSelected(option)}
            options={selectOptions}
            isSearchable={false}
          />
        </div>
      )}
      <div className={`Flex SpaceBetween FlexWrap FlexTop Width100 selected-position ${hasSelect ? 'has-select' : ''}`}>
        <div className="Flex FlexColumn FlexTop selected-position__column">
          <DataItem
            label={t('compound.supply_balance')}
            description={t('compound.supply_balance_desc')}
            symbol="$"
            symbolAfter={false}
            value={suppliedUsd}
            additionalClasses="selected-position__data-item"
          />
          {usedAssets !== undefined && filterCollateralAssetsFromUsedAssets(usedAssets)
            .map(({
              supplied,
              symbol,
            }) => (
              <AssetContainer
                key={symbol}
                assetAmount={supplied}
                assetSymbol={symbol}
                additionalClasses="selected-position__asset"
              />
            ))}
        </div>
        <div className="Flex FlexColumn FlexTop selected-position__column">
          <DataItem
            label={t('compound.borrow_balance')}
            description={t('compound.borrow_balance_desc')}
            symbol="$"
            symbolAfter={false}
            value={borrowedUsd}
            additionalClasses="selected-position__data-item"
          />
          {usedAssets !== undefined && filterBorrowedAssetsFromUsedAssets(usedAssets)
            .map(({
              borrowed,
              symbol,
            }) => (
              <AssetContainer
                key={symbol}
                assetAmount={borrowed}
                assetSymbol={symbol}
                additionalClasses="selected-position__asset"
              />
            ))}
        </div>
      </div>
    </div>
  );
};
MigrateModalContent.defaultProps = {
  selectOptions: [],
  selected: undefined,
  setSelected: () => {},
  usedAssets: undefined,
  suppliedUsd: '0',
  borrowedUsd: '0',
};
MigrateModalContent.propTypes = {
  selectOptions: PropTypes.array,
  selected: PropTypes.object,
  setSelected: PropTypes.func,
  suppliedUsd: PropTypes.string,
  borrowedUsd: PropTypes.string,
  usedAssets: PropTypes.object,
};
export default MigrateModalContent;
