import t from 'translate';
import capitalize from 'lodash/capitalize';
import PageIconAave from '../components/Decorative/PageIcons/page-aave.svg';
import PageIconCompound from '../components/Decorative/PageIcons/page-compound.svg';
import PageIconExchange from '../components/Decorative/PageIcons/page-exchange.svg';
import PageIconMaker from '../components/Decorative/PageIcons/page-maker.svg';
import PageIconLiquity from '../components/Decorative/PageIcons/page-liquity.svg';
import PageIconPortfolio from '../components/Decorative/PageIcons/page-portfolio.svg';
import PageIconSavings from '../components/Decorative/PageIcons/page-savings.svg';
import PageIconWhatsNew from '../components/Decorative/PageIcons/page-whats-new.svg';
import PageIconShifter from '../components/Decorative/PageIcons/page-shifter.svg';
import PageIconReflexer from '../components/Decorative/PageIcons/page-reflexer.svg';
import PageIconLido from '../components/Decorative/PageIcons/page-lido.svg';
import PageIconUniswap from '../components/Decorative/PageIcons/page-uniswap.svg';
import PageIconAll from '../components/Decorative/PageIcons/page-all.svg';
import PageIconYearn from '../components/Decorative/PageIcons/page-yearn.svg';
import PageIconRecipe from '../components/Decorative/PageIcons/page-recipe.svg';
import PageIconMstable from '../components/Decorative/PageIcons/page-mstable.svg';
import PageIconBridge from '../components/Decorative/PageIcons/page-bridge.svg';
import LongIcon from '../components/Decorative/LongIcon';
import ShortIcon from '../components/Decorative/ShortIcon';

import MakerProtocolIcon from '../components/Decorative/Protocols/MakerProtocolIcon';
import CompoundProtocolIcon from '../components/Decorative/Protocols/CompoundProtocolIcon';
import AaveProtocolIcon from '../components/Decorative/Protocols/AaveProtocolIcon';
import ReflexerProtocolIcon from '../components/Decorative/Protocols/ReflexerProtocolIcon';
import LiquityProtocolIcon from '../components/Decorative/Protocols/LiquityProtocolIcon';
import LidoProtocolIcon from '../components/Decorative/Protocols/LidoProtocolIcon';
import YearnProtocolIcon from '../components/Decorative/Protocols/YearnProtocolIcon';
import ArbitrumIcon from '../components/Decorative/NetworkIcons/ArbitrumIcon';
import ZkSyncIcon from '../components/Decorative/NetworkIcons/ZkSyncIcon';
import OptimismIcon from '../components/Decorative/NetworkIcons/OptimismIcon';
import MainnetIcon from '../components/Decorative/NetworkIcons/MainnetIcon';
import DefaultProtocolIcon from '../components/Decorative/Protocols/DefaultProtocolIcon';
import SushiswapIcon from '../components/Decorative/Protocols/SushiswapIcon';
import BalancerProtocolIcon from '../components/Decorative/Protocols/BalancerProtocolIcon';
import CurveProtocolIcon from '../components/Decorative/Protocols/CurveProtocolIcon';
import UniswapV3ProtocolIcon from '../components/Decorative/Protocols/UniswapV3ProtocolIcon';
import UniswapV2ProtocolIcon from '../components/Decorative/Protocols/UniswapV2ProtocolIcon';
import RariProtocolIcon from '../components/Decorative/Protocols/RariProtocolIcon';
import MStableProtocolIcon from '../components/Decorative/Protocols/MStableProtocolIcon';

import AnyswapBridgeIcon from '../components/Decorative/BridgeIcons/AnyswapBridgeIcon';
import ArbitrumBridgeIcon from '../components/Decorative/BridgeIcons/ArbitrumBridgeIcon';
import AvalancheIcon from '../components/Decorative/BridgeIcons/AvalancheIcon';
import BiconomyIcon from '../components/Decorative/BridgeIcons/BiconomyIcon';
import CbridgeIcon from '../components/Decorative/BridgeIcons/CbridgeIcon';
import ConnextBridgeIcon from '../components/Decorative/BridgeIcons/ConnextBridgeIcon';
import HopIcon from '../components/Decorative/BridgeIcons/HopIcon';
import MultichainIcon from '../components/Decorative/BridgeIcons/MultichainIcon';
import PolygonIcon from '../components/Decorative/BridgeIcons/PolygonIcon';
import OptimismBridgeIcon from '../components/Decorative/BridgeIcons/OptimismBridgeIcon';
import MainnetIconForBridge from '../components/Decorative/BridgeIcons/MainnetIconForBridge';

import OneInchIcon from '../components/Decorative/SwapsIcons/1inch';
import DodoIcon from '../components/Decorative/SwapsIcons/DodoIcon';
import HoneyswapIcon from '../components/Decorative/SwapsIcons/HoneyswapIcon';
import OpenoceanIcon from '../components/Decorative/SwapsIcons/OpenoceanIcon';
import PancakeswapIcon from '../components/Decorative/SwapsIcons/PancakeswapIcon';
import ParaswapIcon from '../components/Decorative/SwapsIcons/ParaswapIcon';
import QuickswapIcon from '../components/Decorative/SwapsIcons/QuickswapIcon';
import SolarbeamIcon from '../components/Decorative/SwapsIcons/SolarbeamIcon';
import SpiritswapIcon from '../components/Decorative/SwapsIcons/SpiritswapIcon';
import SpookyswapIcon from '../components/Decorative/SwapsIcons/SpookyswapIcon';
import SushiswapSwapIcon from '../components/Decorative/SwapsIcons/SushiswapIcon';
import UniIcon from '../components/Decorative/SwapsIcons/UniIcon';
import ViperswapIcon from '../components/Decorative/SwapsIcons/ViperswapIcon';
import ZeroXIcon from '../components/Decorative/SwapsIcons/ZeroXIcon';

export const EMAIL_REGEX =  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line

export const LS_ACCOUNT = 'Y2Rwc2F2ZXItYWNjb3VudC10eXBl';
export const LS_ADDRESS = '7gImma7kRgkmlwP9bswm';
export const LS_CDP_SAVER_STATE = 'ZGVjZW50cmFsaXphdGlvbiByb2Nrcw==';
export const LS_RECENT_ACCOUNTS = 'Y29vbCBwb3J0Zm9saW8gYWNjb3VudHM=';

export const SPACE_COMMA_REGEX = /[, ]+/g;

export const MM_DENIED_TX_ERROR = 'User denied transaction signature';

export const FLASH_LOAN_FEE = 0.0025 + 0.0009;

export const getSellFee = (asset1, asset2) => 0.0025;

export const DEFAULT_TREZOR_PATH = "m/44'/60'/0'/0/0";
export const DEFAULT_LEDGER_PATH = "44'/60'/0'/0";

export const LEDGER_ACC_TYPES = [
  { label: t('common.legacy'), value: 'legacy' },
  { label: t('common.live'), value: 'live' },
  { label: t('common.custom_type'), value: 'custom' },
];

export const MANAGER_MOBILE_ACTION_VIEWS = [
  { label: t('common.info'), value: 'info', form: 'None' },
  { label: t('common.boost'), value: 'boost', form: 'Advanced' },
  { label: t('common.repay'), value: 'repay', form: 'Advanced' },
  { label: t('common.add_collateral'), value: 'collateral', form: 'Collateral' },
  { label: t('common.withdraw'), value: 'withdraw', form: 'Collateral' },
  { label: t('common.generate'), value: 'generate', form: 'Debt' },
  { label: t('common.payback'), value: 'payback', form: 'Debt' },
];

export const COMPOUND_MOBILE_ACTION_VIEWS = [
  { label: t('common.info'), value: 'info', form: 'None' },
  { label: t('common.boost'), value: 'boost', form: 'Advanced' },
  { label: t('common.repay'), value: 'repay', form: 'Advanced' },
  { label: t('common.supply'), value: 'supply', form: 'Collateral' },
  { label: t('common.withdraw'), value: 'withdraw', form: 'Collateral' },
  { label: t('common.borrow'), value: 'borrow', form: 'Debt' },
  { label: t('common.payback'), value: 'payback', form: 'Debt' },
];

export const ALLOWED_PERCENT_OVER_LIQ_RATIO = 20;
export const ALLOWED_PERCENT_OVER_LIQ_RATIO_MAKER = 10;
export const AUTOMATION_MIN_DIFFERENCE_BETWEEN_RATIOS = {
  0: 5,
  500000: 4, // 500k -> 4%
  1000000: 2, // 1m -> 2%
};
export const LOWER_AUTOMATION_WHITELIST = {
  '0xe84adc0964ee34ce0319df3418636ed6a4117b97': 2, // aburb
  '0x329EFA8aa86abA140F0bf3d069A8265EF1938ADB': 2, // aburb x2
};

export const SECONDS_PER_YEAR = 365 * 24 * 60 * 60;
export const AVG_BLOCK_TIME = 13.15;
export const BLOCKS_IN_A_YEAR = SECONDS_PER_YEAR / AVG_BLOCK_TIME;

export const EMPTY_ACCOUNT = '0x90D03beD29b693ebF57a06b8eC2868454B5C3f7C';

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';

export const RATIO_COLORS = [
  [0, [181, 26, 26]],
  [49, [255, 141, 0]],
  [100, [55, 176, 110]],
];

export const TSI_COLORS = [...RATIO_COLORS].reverse();

export const AAVE_AUTOMATION_SUPPORTED_MARKETS = ['v1', 'v2default'];

export const WALLET_TYPES_OPTIONS = [
  { value: 'account', label: capitalize(t('common.account')) },
  { value: 'proxy', label: t('account.user_wallet') },
];

/**
 *
 * @type {{trust: string, metaMask: string, coinbase: string, opera: string, xdefi: string, browser: string, imToken: string, gnosisSafe: string, tokenary: string, rabby: string, status: string, frame: string}}
 */
export const WALLET_NAMES = { // Can't move it to constants/wallet.js bc of circular dependency issue
  browser: 'Browser',
  status: 'Status',
  imToken: 'imToken',
  trust: 'Trust',
  coinbase: 'Coinbase',
  tokenary: 'Tokenary',
  opera: 'Opera',
  rabby: 'Rabby',
  frame: 'Frame',
  xdefi: 'XDEFI',
  metaMask: 'MetaMask',
  gnosisSafe: 'Gnosis Safe',
  tally: 'Tally',
  brave: 'Brave',
};

/**
 *
 * @type {{browser: string, ledger: string, fortmatic: string, fork: string, coinbase: string, debug: string, walletConnect: string, viewOnly: string, gnosisSafe: string, trezor: string}}
 */
export const ACCOUNT_TYPES = {
  browser: 'metamask',
  ledger: 'ledger',
  trezor: 'trezor',
  fortmatic: 'fortmatic',
  walletConnect: 'walletconnect',
  coinbase: 'coinbase',
  gnosisSafe: 'gnosissafe',
  debug: 'debug',
  viewOnly: 'view-only',
  fork: 'fork',
  changeNetwork: 'change-network',
};

export const ROUTE_NAMES = [
  ['/terms-of-service', t('pages.terms_of_service')],
  ['/privacy-policy', t('pages.privacy_policy')],
  ['/compound', t('pages.compound')],
  ['/smart-savings', t('pages.smart_savings')],
  ['/makerdao', t('pages.maker_dao')],
  ['/liquity', t('pages.liquity')],
  ['/exchange', t('pages.exchange')],
  ['/safeguard', t('pages.funds_safeguard')],
  ['/aave', t('pages.aave')],
  ['/shifter', t('pages.shifter')],
  ['/portfolio', t('pages.portfolio')],
  ['/transaction-history', t('pages.portfolio')],
  ['/whats-new', t('pages.whats_new')],
  ['/reflexer', t('pages.reflexer')],
  ['/recipe', t('pages.recipe_creator')],
  ['/bridge', t('pages.bridge')],
];

export const ROUTE_ICONS = [
  ['/makerdao', PageIconMaker],
  ['/compound', PageIconCompound],
  ['/smart-savings', PageIconSavings],
  ['/exchange', PageIconExchange],
  ['/aave', PageIconAave],
  ['/portfolio', PageIconPortfolio],
  ['/transaction-history', PageIconPortfolio],
  ['/whats-new', PageIconWhatsNew],
  ['/shifter', PageIconShifter],
  ['/reflexer', PageIconReflexer],
  ['/liquity', PageIconLiquity],
  ['/recipes', PageIconRecipe],
  ['/bridge', PageIconBridge],
];

export const routeMeta = (pathname) => {
  if (pathname === '/') pathname = '/portfolio';  // eslint-disable-line
  const name = ROUTE_NAMES.find(route => pathname.startsWith(route[0]))?.[1] || '';
  const icon = ROUTE_ICONS.find(route => pathname.startsWith(route[0]))?.[1] || '';
  const label = ROUTE_NAMES.find(route => pathname.startsWith(route[0]))?.[2] || '';
  return {
    name,
    icon,
    label,
  };
};

export const REGIMES = [{
  value: 'Long',
  label: 'Long',
  icon: LongIcon,
},
{
  value: 'Short',
  label: 'Short',
  icon: ShortIcon,
}];

export const MAXUINT = '115792089237316195423570985008687907853269984665640564039457584007913129639935';
export const MAXUINT128 = '340282366920938463463374607431768211455';

export const AUTOMATION_MIN_AAVE_V2 = 115;
export const AUTOMATION_MIN_COMP = 115;

export const protocolIcons = {
  maker: PageIconMaker,
  compound: PageIconCompound,
  aave: PageIconAave,
  exchange: PageIconExchange,
  reflexer: PageIconReflexer,
  shifter: PageIconShifter,
  savings: PageIconSavings,
  liquity: PageIconLiquity,
  lido: PageIconLido,
  uniswapV3: PageIconUniswap,
  uniswapV2: PageIconUniswap,
  yearn: PageIconYearn,
  recipe: PageIconRecipe,
  all: PageIconAll,
  mstable: PageIconMstable,
  // balancer: PageIconBalancer,
};

export const DEFAULT_SLIPPAGE_PERCENT = '0.5'; // 0.5%

export const DEFAULT_DEADLINE = '20'; // 20 minutes

export const PROTOCOL_SYMBOLS = {
  Maker: MakerProtocolIcon,
  Compound: CompoundProtocolIcon,
  Aave: AaveProtocolIcon,
  Reflexer: ReflexerProtocolIcon,
  Liquity: LiquityProtocolIcon,
  'Uniswap V3': UniswapV3ProtocolIcon,
  'Uniswap V2': UniswapV2ProtocolIcon,
  Lido: LidoProtocolIcon,
  Yearn: YearnProtocolIcon,
  Savings: DefaultProtocolIcon,
  SushiSwap: SushiswapIcon,
  Balancer: BalancerProtocolIcon,
  Curve: CurveProtocolIcon,
  Rari: RariProtocolIcon,
  'mStable Save': MStableProtocolIcon,
  Default: DefaultProtocolIcon,
};

export const NETWORKS = {
  arbitrum: {
    key: 'arbitrum',
    chainName: 'Arbitrum One',
    chainId: 42161,
    Icon: ArbitrumIcon,
    BridgeIcon: ArbitrumBridgeIcon,
    color: '#215476',
    accent: '40, 160, 240',
  },
  zk: {
    key: 'zk',
    chainName: 'zkSync',
    Icon: ZkSyncIcon,
    BridgeIcon: ZkSyncIcon,
    color: '#4e529a',
  },
  optimism: {
    key: 'optimism',
    chainName: 'Optimism',
    chainId: 10,
    Icon: OptimismIcon,
    BridgeIcon: OptimismBridgeIcon,
    color: '#6B2C2D',
    accent: '240, 40, 40',
  },
  mainnet: {
    key: 'mainnet',
    chainName: 'Ethereum',
    chainId: 1,
    Icon: MainnetIcon,
    BridgeIcon: MainnetIconForBridge,
    color: '#4F5F92',
  },
};

export const NETWORK_OPTIONS = Object.values(NETWORKS).map(network => {
  if (network.chainName === 'zkSync') return '';
  return ({
    label: network.chainName,
    value: network.chainId,
    meta: {
      icon: network.BridgeIcon,
    },
  });
}).filter(a => a);

export const BRIDGE_ICONS = {
  anyswap: AnyswapBridgeIcon,
  arbitrum: ArbitrumBridgeIcon,
  avalanche: AvalancheIcon,
  biconomy: BiconomyIcon,
  cbridge: CbridgeIcon,
  connext: ConnextBridgeIcon,
  hop: HopIcon,
  multichain: MultichainIcon,
  optimism: OptimismBridgeIcon,
  polygon: PolygonIcon,
};

export const SWAP_ICONS = {
  '0x': ZeroXIcon,
  '1inch': OneInchIcon,
  dodo: DodoIcon,
  honey: HoneyswapIcon,
  openocean: OpenoceanIcon,
  pancakeswap: PancakeswapIcon,
  paraswap: ParaswapIcon,
  quickswap: QuickswapIcon,
  solarbeam: SolarbeamIcon,
  spiritswap: SpiritswapIcon,
  spookyswap: SpookyswapIcon,
  'sushiswap-eth': SushiswapSwapIcon,
  'sushiswap-arb': SushiswapSwapIcon,
  'uniswap-eth': UniIcon,
  viperswap: ViperswapIcon,
};

export const SWAP_LABELS = {
  '1inch': '1inch',
  dodo: 'Dodo',
  honeyswap: 'Honeyswap',
  openocean: 'Openocean',
  pancakeswap: 'Pancakeswap',
  paraswap: 'Paraswap',
  quickswap: 'Quickswap',
  solarbeam: 'Solarbeam',
  spiritswap: 'Spritswap',
  spookyswap: 'Spookyswap',
  'sushiswap-arb': 'Sushiswap',
  'sushiswap-eth': 'Sushiswap',
  'uniswap-eth': 'Uniswap',
  viperswap: 'Viperswap',
};

export const POSITION_OPTIONS = [
  {
    label: 'All',
  },
  {
    label: 'Lending',
  },
  {
    label: 'LP',
  },
  {
    label: 'Other',
  },
];
export const PORTFOLIO_WALLET_OPTIONS = [
  {
    label: 'Connected',
  },
  {
    label: 'Bundle',
  },
];
export const PORTFOLIO_MOBILE_ACTION_VIEWS = [
  { label: 'Positions' },
  { label: 'Assets' },
];

export const SCAMCOINS = [
  '0xC12D1c73eE7DC3615BA4e37E4ABFdbDDFA38907E',
  '0x4DC3643DbC642b72C158E7F3d2ff232df61cb6CE',
  '0x174BfA6600Bf90C885c7c01C7031389ed1461Ab9',
  '0x426CA1eA2406c07d75Db9585F22781c096e3d0E0',
  '0x912a0A41B820E1fA660FB6eC07Fff493e015F3B2',
  '0x0c334506E0bcb7C17b583CB7c0ec2F5857BF9e6F',
  '0x2CEeE24f8D03fC25648c68C8e6569AA0512F6Ac3',
];

export const IGNORECOINS = [
  '0xc9bc48c72154ef3e5425641a3c747242112a46af',
];

export const BLOCK_EXPLORER_URL = {
  1: 'https://etherscan.io/',
  42: 'https://kovan.etherscan.io/',
  10: 'https://optimistic.etherscan.io/',
  42161: 'https://arbiscan.io/',
};

/**
 * Multiply debt with this multiplier when you need an amount to cover the whole constantly-increasing collateral/debt
 *     1.0001x should cover the tx pending for ~3h at a 3% APY
 */
export const CONTINUOUS_FEE_MULTIPLIER = 1.0001;
