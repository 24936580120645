/* eslint-disable */
import React from 'react';

export default function ExternalArrowLinkIcon () {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.004 7.66462V11.6662C11.004 12.02 10.8635 12.3593 10.6133 12.6094C10.3632 12.8596 10.0239 13.0001 9.67013 13.0001H2.33387C1.9801 13.0001 1.64083 12.8596 1.39068 12.6094C1.14053 12.3593 1 12.02 1 11.6662V4.32996C1 3.9762 1.14053 3.63692 1.39068 3.38677C1.64083 3.13663 1.9801 2.99609 2.33387 2.99609H6.33546" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.00342 1H13.005V5.0016" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5.66846 8.33626L13.0047 1" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

