import React from 'react';
import { v4 as uuid } from 'uuid';

export default function HoneyswapIcon(props) {
  const id = `honeyswap_icon${uuid()}`;
  const fill = `url(#${id})`;

  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z" fill={fill} />
      <path d="M14.4821 9.7372C14.3879 9.7372 14.2976 9.69883 14.231 9.63056C14.1643 9.56229 14.1269 9.46969 14.1269 9.37313C14.1264 8.93701 13.9572 8.51891 13.6563 8.21052C13.3555 7.90212 12.9475 7.72864 12.5221 7.72813C12.4278 7.72813 12.3375 7.68976 12.2709 7.62149C12.2043 7.55321 12.1669 7.46061 12.1669 7.36406C12.1669 7.2675 12.2043 7.1749 12.2709 7.10662C12.3375 7.03835 12.4278 7 12.5221 7C13.1358 7.00077 13.7242 7.25102 14.1583 7.69586C14.5922 8.1407 14.8364 8.7438 14.8371 9.3729C14.8372 9.46946 14.7998 9.56208 14.7333 9.63039C14.6667 9.69872 14.5763 9.73714 14.4821 9.7372Z" fill="white" />
      <path d="M15.956 9.73734C15.8618 9.73733 15.7714 9.69897 15.7048 9.6307C15.6382 9.56242 15.6007 9.46982 15.6007 9.37326C15.6015 8.74412 15.8457 8.14098 16.2798 7.69613C16.7138 7.25129 17.3023 7.00107 17.9161 7.00037C18.0104 7.00037 18.1007 7.03874 18.1673 7.10701C18.2339 7.17529 18.2713 7.26789 18.2713 7.36444C18.2713 7.461 18.2339 7.5536 18.1673 7.62188C18.1007 7.69015 18.0104 7.7285 17.9161 7.7285C17.4906 7.72899 17.0827 7.90246 16.7818 8.21085C16.4809 8.51925 16.3117 8.93738 16.3112 9.37352C16.3111 9.47004 16.2737 9.56256 16.2071 9.63079C16.1404 9.69901 16.0501 9.73734 15.956 9.73734Z" fill="white" />
      <path d="M10.5059 19.2811C9.20765 19.758 8.11814 19.7097 7.39615 19.5665C6.40169 19.3694 5.8223 18.936 5.73318 18.7478C5.686 18.6479 5.72442 18.6194 5.76102 18.5959L10.2637 15.9225V15.0808L5.40067 17.9682L5.39242 17.9729C5.01663 18.2102 4.89675 18.6491 5.09383 19.0653C5.33972 19.5843 6.21059 20.0727 7.26105 20.2809C7.64355 20.3561 8.03219 20.3936 8.42166 20.393C9.18627 20.393 10.1104 20.2509 11.1321 19.8114L10.9506 19.7039C10.7735 19.5987 10.6216 19.4542 10.5059 19.2811Z" fill="white" />
      <path d="M25.0009 17.9734C24.9981 17.9717 24.9954 17.97 24.9926 17.9687L20.1296 15.0813V15.923L24.6322 18.5964C24.6691 18.6198 24.7076 18.6487 24.6603 18.7483C24.5712 18.9365 23.9918 19.3699 22.9974 19.5669C22.2755 19.71 21.1861 19.7584 19.8881 19.2816C19.7732 19.4542 19.6224 19.5985 19.4465 19.704L19.2626 19.8128C20.2838 20.252 21.2073 20.3939 21.9717 20.3939C22.3612 20.3945 22.7498 20.3569 23.1323 20.2818C24.1827 20.0736 25.0536 19.5852 25.2995 19.0662C25.4966 18.6496 25.3766 18.2107 25.0009 17.9734Z" fill="white" />
      <path d="M19.4449 13.0543L15.8838 10.9477C15.6755 10.824 15.4389 10.7588 15.1981 10.7588C14.9573 10.7587 14.7207 10.8238 14.5124 10.9474L10.9503 13.0545C10.7423 13.178 10.5694 13.3552 10.4489 13.5685C10.3285 13.7818 10.2647 14.0237 10.2639 14.2701V18.4862C10.2645 18.7331 10.3282 18.9754 10.4487 19.1892C10.5692 19.403 10.7422 19.5806 10.9506 19.7044L14.5107 21.8105C14.7191 21.9345 14.9558 21.9998 15.1968 22C15.4378 22.0001 15.6745 21.935 15.8831 21.8113L19.4462 19.7036C19.6536 19.5793 19.8257 19.4015 19.9455 19.188C20.0653 18.9745 20.1287 18.7326 20.1293 18.4863V14.2702C20.1284 14.0239 20.0648 13.7822 19.9448 13.569C19.8248 13.3557 19.6524 13.1783 19.4449 13.0543ZM11.3059 13.6849L12.4581 13.0033H17.9373L19.0882 13.6841C19.1882 13.7439 19.2715 13.8294 19.3294 13.9321C19.3874 14.0349 19.4182 14.1514 19.4187 14.2702V15.1945H10.9744V14.2703C10.975 14.1516 11.0058 14.035 11.064 13.9323C11.1222 13.8296 11.2056 13.7443 11.3059 13.6849ZM19.0899 19.0739L17.9494 19.7485H12.4463L11.3063 19.0741C11.2056 19.0144 11.1221 18.9287 11.0638 18.8256C11.0056 18.7224 10.9748 18.6054 10.9744 18.4863V17.5575H19.4189V18.4863C19.4187 18.6051 19.3882 18.7218 19.3304 18.8249C19.2727 18.9279 19.1898 19.0137 19.0899 19.0739Z" fill="white" />
      <defs>
        <linearGradient id={id} x1="15" y1="0" x2="15" y2="30" gradientUnits="userSpaceOnUse">
          <stop stopColor="#2D3436" />
          <stop offset="1" stopColor="#1E2324" />
        </linearGradient>
      </defs>
    </svg>

  );
}
