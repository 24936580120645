import dfs from '@defisaver/sdk';
import {
  assetAmountInWei, getAssetInfo, getAssetInfoByAddress,
} from '@defisaver/tokens';
import { exchangeAssets } from 'constants/assets';
import RecipeAction from '../RecipeAction';
import SupplyIcon from '../recipeIcons/Supply.svg';
import {
  Address,
  Amount, Asset, Deadline, Slippage, Source,
} from '../../components/Recipes/RecipeCreator/inputTypes';
import { assetAmountInWeiIgnorePointer } from '../../services/recipeCreator/recipeActionUtils';
import { supplyLPAfterValues } from '../../actions/lpActions/lpActions';
import { normalizeFunc, setSlippagePercent } from '../../services/exchangeServiceCommon';

const _exchangeAssets = [...exchangeAssets()];
_exchangeAssets[0] = getAssetInfo('WETH');

export default class UniswapV2SupplyAction extends RecipeAction {
  static prettyName = 'Supply to Uniswap V2';

  static protocol = 'uniswap';

  static protocolPrettyName = 'Uniswap';

  static description = 'Supplies assets to the selected Uniswap v2 liquidity pool.';

  constructor(firstAsset = 'WETH', firstAmount = '', secondAsset = 'DAI', secondAmount = '', source = 'wallet', to = 'wallet', slippage = '2', deadline = '20') {
    super();
    this.inputs = [
      new Asset('First token', firstAsset, _exchangeAssets),
      new Amount('Amount', firstAmount),
      new Asset('Second token', secondAsset, _exchangeAssets),
      new Amount('Amount', secondAmount),
      new Source('From', source),
      new Source('Send LP token to', to, true),
      new Slippage('Slippage tolerance', slippage),
      new Deadline('Deadline', deadline),
    ];
    this.output = new Amount('output', 0);
    this.error = '';
  }

  static getIcon() {
    return SupplyIcon;
  }

  setError(e) {
    this.error = e;
  }

  getError() {
    return this.error;
  }

  getExpires() {
    if (this.updatedAt) {
      return this.updatedAt + (2 * 60 * 1000);
    }
    return Infinity;
  }

  async assetsToApprove(returnValues = [], actions = []) {
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    return (args[3] === 'wallet') ? [args[0], args[2]] : [];
  }

  async getAfterValues(_balances = {}, returnValues = [], actions = [], positions = {}, getState) {
    const { proxyAddress } = getState().maker;
    const { account } = getState().general;
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const source = args[4];
    const { balances, returnValue, cached } = await supplyLPAfterValues({
      _firstAsset: this.inputs[0].asset,
      _firstAmount: args[1],
      _secondAsset: this.inputs[2].asset,
      _secondAmount: args[3],
      source,
      to: args[5],
    },
    {
      proxyAddress, account,
    }, _balances);
    if (!cached) this.updatedAt = Date.now();
    this.output.value = returnValue;
    return { returnValue: this.output, balances, positions };
  }

  async toDfsAction(getState) {
    const {
      general: { account },
      maker: { proxyAddress },
      recipeCreator: { returnValues, actions },
    } = getState();
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const firstAmount = assetAmountInWeiIgnorePointer(this.inputs[1].value, this.inputs[0].asset);
    const secondAmount = assetAmountInWeiIgnorePointer(this.inputs[3].value, this.inputs[2].asset);
    const from = this.inputs[4].value === 'wallet' ? account : proxyAddress;
    const minFirstAmount = setSlippagePercent(normalizeFunc(args[6]), args[1]);
    const minSecondAmount = setSlippagePercent(normalizeFunc(args[6]), args[3]);
    const minFirstAmountWei = assetAmountInWei(minFirstAmount, this.inputs[0].asset);
    const minSecondAmountWei = assetAmountInWei(minSecondAmount, this.inputs[2].asset);
    const to = this.inputs[5].value === 'wallet' ? account : proxyAddress;
    return new dfs.actions.uniswap.UniswapSupplyAction(
      args[0],
      args[2],
      from,
      to,
      firstAmount,
      secondAmount,
      minFirstAmountWei,
      minSecondAmountWei,
      Date.now() + (1000 * 60 * args[7]),
    );
  }

  _getPrettyName(actionCalls, actions) {
    const args = this.mapReturnValuesToArgs(actionCalls.map(a => a.returnValue), actions);
    return `Supply to ${getAssetInfoByAddress(args[0]).symbol}-${getAssetInfoByAddress(args[2]).symbol} pool`;
  }
}
