import dfs from '@defisaver/sdk';
import cloneDeep from 'lodash/cloneDeep';
import { compoundCollateralAssets } from 'constants/assets';
import RecipeAction from '../RecipeAction';
import {
  Address, Amount, AssetAmount, Source,
} from '../../components/Recipes/RecipeCreator/inputTypes';
import { compareAddresses, formatNumber } from '../../services/utils';
import { changeBalance } from '../../services/recipeCreator/recipeActionUtils';


export default class CompoundClaimAction extends RecipeAction {
  static prettyName = 'Claim COMP';

  static protocol = 'compound';

  static protocolPrettyName = 'Compound';

  static description = 'Claims accrued COMP rewards.';

  constructor(from = '', to = 'wallet') {
    super();
    this.inputs = [
      new Address('From', from),
      new Source('To', to),
    ];
    this.output = new AssetAmount('output', 0, 'COMP');
    this._fromLabel = '';
  }

  async getAfterValues(_balances = {}, returnValues = [], actions = [], _positions = {}, getState) {
    const positions = { ..._positions };
    const balances = cloneDeep(_balances);
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const { account } = getState().general;
    const address = args[0];
    const isAccount = compareAddresses(address, account);
    const balance = isAccount ? getState().compoundManage.compBalance?.account || '0' : getState().compoundManage.compBalance?.smartWallet || '0';
    await changeBalance(balances, args[1], 'COMP', balance, args[0]);
    this.output.value = balance;
    this._fromLabel = isAccount ? 'Wallet' : 'Smart Wallet';
    return { returnValue: this.output, balances, positions };
  }

  async toDfsAction(getState) {
    const cTokens = compoundCollateralAssets.map(a => a.address);
    const to = this.inputs[1].value === 'wallet' ? getState().general.account : getState().maker.proxyAddress;
    console.log(to);
    return new dfs.actions.compound.CompoundClaimAction(cTokens, cTokens, this.inputs[0].value, to);
  }

  _getPrettyName(actionCalls, actions) {
    const args = this.mapReturnValuesToArgs(actionCalls.map(a => a.returnValue), actions);
    const id = this.id;
    const thisActionIndex = actions.findIndex((a) => a.id === id);
    const returnOfThisAction = actionCalls[thisActionIndex]?.returnValue;
    const amount = returnOfThisAction?.value;
    return `Claim ${formatNumber(amount, 5)} COMP from ${this._fromLabel}`;
  }
}
