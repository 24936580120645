import dfs from '@defisaver/sdk';
import { assetAmountInWei } from '@defisaver/tokens';
import Dec from 'decimal.js';
import RecipeAction from '../RecipeAction';
import { AssetAmount, Source } from '../../components/Recipes/RecipeCreator/inputTypes';
import { paybackAfterValues } from '../../actions/liquityActions/liquityManageAfterValues';
import { findInsertPosition, getTroveInfo } from '../../services/liquityServices/liquityService';
import PaybackIcon from '../recipeIcons/Payback.svg';
import { formatNumber } from '../../services/utils';
import { MAXUINT } from '../../constants/general';

export default class LiquityPaybackAction extends RecipeAction {
  static prettyName = 'Payback LUSD to Trove';

  static protocol = 'liquity';

  static protocolPrettyName = 'Liquity';

  static description = 'Pays back part of LUSD debt to a Trove.';

  constructor(from = 'wallet', debt = '') {
    super();
    this.inputs = [
      new Source('From', from),
      new AssetAmount('Debt', debt, 'LUSD'),
    ];
    this.output = new AssetAmount('output', 0, 'LUSD');
  }

  async getAfterValues(_balances = {}, returnValues = [], actions = [], _positions = {}, getState) {
    const positions = { ..._positions };
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const {
      liquity: { proxy },
      liquityStaking: { proxy: stakingProxy },
    } = getState();
    if (!positions.liquity) positions.liquity = { ...proxy, ...stakingProxy };
    const source = args[0];
    let amount = args[1] || '0';
    if (amount === 'All available') amount = positions.liquity.debtInAsset || '0';
    const { afterPosition, balances, returnValue } = await paybackAfterValues(
      {
        amount,
        from: source,
      },
      {
        vault: positions.liquity,
        assets: getState().assets,
        account: getState().general.account,
        proxyAddress: getState().maker.proxyAddress,
      },
      _balances);
    positions.liquity = afterPosition;
    this.output.value = returnValue;
    return { returnValue: this.output, balances, positions };
  }

  async toDfsAction(getState, actions, returnValues, positions, actionBeforeState, actionCurrentState) {
    const {
      general: { account },
      maker: { proxyAddress },
    } = getState();
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const from = this.inputs[0].value === 'wallet' ? account : proxyAddress;
    const trove = actionCurrentState?.positions?.liquity || await getTroveInfo(proxyAddress);
    const amount = args[1] === 'All available' ? MAXUINT : assetAmountInWei(args[1], 'LUSD');
    const { lowerHint, upperHint } = await findInsertPosition(assetAmountInWei(trove.collateral, 'WETH'), assetAmountInWei(trove.debtInAsset, 'LUSD'), proxyAddress);
    return new dfs.actions.liquity.LiquityPaybackAction(amount, from, upperHint, lowerHint);
  }

  _getPrettyName(actionCalls, actions) {
    const args = this.mapReturnValuesToArgs(actionCalls.map(a => a.returnValue), actions);
    return `Payback ${formatNumber(args[1])} LUSD to Trove`;
  }

  static getIcon() {
    return PaybackIcon;
  }
}
