import {
  Asset, AssetAmount, CdpId, Address,
} from 'components/Recipes/RecipeCreator/inputTypes';
import { RC_EXECUTE_RECIPE_REQUEST, RC_EXECUTE_RECIPE_SUCCESS, RC_EXECUTE_RECIPE_FAILURE } from 'actionTypes/recipeCreatorActionTypes';
import cloneDeep from 'lodash/cloneDeep';
import { notify } from './noitificationActions';
import { containsAsset, recipeActionsToDfsActions } from '../services/recipeCreator/recipeActionUtils';
import { validateActions } from './recipeCreatorActions/recipeCreatorValidation';
import { parseRecipeQuery, updateUrlState } from '../services/recipeCreator/recipeCreatorShare';
import { nameToClassMap } from '../recipeActions';
import { getActionFromPrediction, parseUtterance } from '../services/nlpService';
import { fetchLuisPrediction } from '../services/apiService';
import { openRecipeSuccessModal } from './modalActions';
import { executeRecipe } from './recipeCreatorActions/recipeActions';


// TODO move file to recipeCreatorActions


export const simulateActions = () => async (dispatch, getState) => {
  dispatch({ type: 'RC_UPDATE_AFTER_REQUEST' });
  const actions = getState().recipeCreator.actions;
  updateUrlState(actions);
  let balances = {};
  const returnValues = [];
  const actionCalls = [];
  let expires = Infinity;
  // eslint-disable-next-line no-restricted-syntax
  for (const action of actions) {
    try {
      const currentPositionState = actionCalls[actionCalls.length - 1]?.positions || {};
      // (loop iterations are dependant on previous iterations)
      // eslint-disable-next-line no-await-in-loop
      const res = await action.getAfterValues(balances, returnValues, actions, currentPositionState, getState);
      balances = res.balances;
      returnValues.push(res.returnValue);
      actionCalls.push({
        args: action.mapReturnValuesToArgs(returnValues, actions),
        returnValue: res.returnValue,
        balances: { ...balances },
        positions: res.positions,
      });
      // console.log('✅ Params ok: ', action.constructor.name);
      const _expires = action.getExpires();
      if (_expires < expires) expires = _expires;
      dispatch({
        type: 'RC_UPDATE_AFTER_STEP',
        payload: {
          // balances,
          returnValues,
          actionCalls,
        },
      });
    } catch (err) {
      console.log('🚧 Params bad: ', action.constructor.name);
      console.error(err);
      break;
    }
  }
  await dispatch({
    type: 'RC_UPDATE_AFTER_SUCCESS',
    payload: {
      // balances, // unused
      returnValues, // deprecated
      actionCalls,
      expires,
    },
  });
  dispatch(validateActions());
  return actions.length === returnValues.length;
};

export const setActions = (actions) => async (dispatch, getState) => {
  await dispatch({ type: 'RC_SET_ACTIONS', payload: actions });
  dispatch(simulateActions());
};

export const updateAction = ({ id, args }, calculateAfter = true) => async (dispatch, getState) => {
  await dispatch({ type: 'RC_UPDATE_ACTION_REQUEST' });
  await dispatch({ type: 'RC_UPDATE_ACTION', payload: { id, args } });
  if (calculateAfter) dispatch(simulateActions());
};

export const openForm = (actionId) => ({ type: 'RC_OPEN_FORM', payload: actionId });

export const closeForm = () => ({ type: 'RC_CLOSE_FORM' });

export const addInstantiatedAction = (action, index) => (dispatch, getState) => {
  const { actions, actionCalls } = getState().recipeCreator;
  actions.splice(index, 0, action);
  dispatch(setActions(actions));
};

export const addAction = (Action, index) => async (dispatch, getState) => {
  const { general: { account }, recipeCreator: { actions, actionCalls } } = getState();
  const action = await Action.prepareAndInstantiate({ getState });
  let lastAsset = '';

  // Try to "guess" value
  action.inputs = action.inputs.map((_input) => {
    const input = _input;
    if (input.constructor === CdpId) { // set first found CDP ID
      let i = 0;
      while (i < index) {
        if (actionCalls[i] && actionCalls[i].returnValue.constructor === CdpId) {
          // input.value = actionCalls[i].returnValue.value;
          input.value = `$${actions[i].id}`;
          return input;
        }
        i++;
      }
    }
    if (input.constructor === Asset || (input.constructor === AssetAmount && !input.asset)) {
      // Set asset to asset of previous action's return value
      let i = index - 1;
      while (i >= 0) {
        const previousAction = actionCalls[i];
        const asset = containsAsset(previousAction?.returnValue);
        if (asset && asset !== lastAsset && input.availableAssets.find(({ symbol }) => symbol === asset)) {
          lastAsset = asset;
          input.value = asset;
          return input;
        }
        i--;
      }
    }
    if (input.constructor === AssetAmount && input.asset) {
      // If AssetAmount with specified asset, set amount to return value of function that returns AssetAmount of same asset
      // (Currently unused because inputs are either Asset or Amount, never AssetAmount)
      let i = index - 1;
      while (i >= 0) {
        const previousAction = actionCalls[i];
        if (previousAction?.returnValue?.constructor === AssetAmount && previousAction?.returnValue?.asset === input.asset) {
          // input.value = containsAmount(previousAction.returnValue);
          input.value = `$${actions[i].id}`;
          return input;
        }
        i--;
      }
    }
    // Always set account as default value
    if (input.constructor === Address) {
      input.value = account;
    }
    return input;
  });

  dispatch(addInstantiatedAction(action, index));
  dispatch(openForm(action.id));
};

export const removeActionById = (_id) => (dispatch, getState) => {
  const { actions } = getState().recipeCreator;
  const _actions = [...actions];
  const index = actions.findIndex(({ id }) => id === _id);
  _actions.splice(index, 1);

  const deletedActionId = actions[index].id;

  _actions.forEach((action) => { action.removeReference(deletedActionId); });

  dispatch(setActions(_actions));
};

export const loadStateFromUrl = () => async (dispatch, getState) => {
  const actions = await parseRecipeQuery(getState);
  dispatch(setActions(actions));
};


export const executeCreatedRecipe = () => async (dispatch, getState) => {
  try {
    dispatch({ type: RC_EXECUTE_RECIPE_REQUEST });
    const { recipeCreator: { actions, actionCalls, returnValues } } = getState();
    const clonedActions = cloneDeep(actions);
    const clonedActionCalls = cloneDeep(actionCalls);
    const receipts = await dispatch(executeRecipe(actions, actionCalls));
    dispatch({ type: RC_EXECUTE_RECIPE_SUCCESS });
    dispatch(openRecipeSuccessModal({ receipts, actions: clonedActions, actionCalls: clonedActionCalls }));
  } catch (err) {
    console.error(err);
    dispatch({ type: RC_EXECUTE_RECIPE_FAILURE, payload: err.message });
  }
};

export const reorderActions = (result) => (dispatch, getState) => {
  const { source, destination, draggableId } = result;
  if (!destination) return;

  const { actions } = getState().recipeCreator;
  const _actions = [...actions];

  if (source.droppableId === 'recipe-actions') { // reorder
    if (source.index === destination.index) return;
    const [removed] = _actions.splice(source.index, 1);
    _actions.splice(destination.index, 0, removed);

    _actions.forEach((action, i) => {
      if (source.index < destination.index && i < destination.index) {
        action.removeReference(removed.id);
      }

      if (source.index > destination.index && i > destination.index) {
        removed.args?.forEach((item) => {
          if (item === `$${action.id}`) removed.removeReference(action.id);
        });
      }
    });

    dispatch(setActions(_actions));
  }

  if (source.droppableId === 'actions-explorer') { // add new
    const ActionClass = nameToClassMap[draggableId];
    dispatch(addAction(ActionClass, destination.index));
  }
};

export const predictAndLoadActions = (_utterances) => async (dispatch, getState) => {
  try {
    console.log(_utterances);
    const utterances = parseUtterance(_utterances).split(',');
    console.log(utterances);
    if (!utterances.length) return; // TODO: handle case

    let predictions = [];
    let actions = [];

    const state = getState();
    let failedParsings = [];

    /* eslint-disable no-await-in-loop */
    for (const utterance of utterances) {
      const _utterance = utterance.trim();
      if (_utterance) {
        const data = await fetchLuisPrediction(_utterance);
        const action = await getActionFromPrediction(data.prediction, predictions, actions, state);
        if (action) {
          actions = [...actions, action];
          await dispatch(setActions(actions));
        } else {
          failedParsings = [...failedParsings, _utterance];
        }

        predictions = [...predictions, data.prediction];
      }
    }
    /* eslint-disable no-await-in-loop */

    if (failedParsings.length) dispatch(notify(`Failed to recognize following actions: ${failedParsings.join(', ')}`, 'error'));

    console.log(predictions);
    console.log(actions);
  } catch (error) {
    console.log(error);
  }
};

export const openRecipeInCreator = (actions, closeModal, history) => async (dispatch) => {
  await dispatch(setActions(actions));
  history.push(`create${window.location.search}`);
  closeModal();
};
