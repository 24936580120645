import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import Dec from 'decimal.js';
import { tickToPrice } from '@uniswap/v3-sdk';
import { getNearestTickPrice, getNextTickPrice, getTokens } from '../../../../../../../../services/uniswapV3Services';

const TickActionField = ({
  type, i, actualValue, originalValue, handleChange, formData,
  handlePointerChange, action, firstToken, secondToken, fee, pool, firstTokenInfo, secondTokenInfo, disabled,
}) => (
  <>
    <input
      className="form-item"
      autoComplete="off"
      placeholder="0"
      id={type.name}
      type="text"
      value={actualValue}
      disabled={disabled}
      onChange={(e) => handleChange(e, i)}
      onBlur={
          () => {
            const nearestTick = getNearestTickPrice(firstToken, secondToken, firstTokenInfo, secondTokenInfo, fee, actualValue || '0', formData[3]);
            handleChange({ target: { value: nearestTick } }, i);
          }
        }
    />
    <>
      <div
        className="tick-percent minus"
        onClick={() => {
          const tick = getNextTickPrice(firstToken, secondToken, firstTokenInfo, secondTokenInfo, false, actualValue || '0', fee, pool, formData[3]);
          handleChange({ target: { value: tick } }, i);
        }}
      >
        {new Dec(fee).mul(2).mul(-1).toString()}%
      </div>
      <div
        className="tick-percent"
        onClick={() => {
          const tick = getNextTickPrice(firstToken, secondToken, firstTokenInfo, secondTokenInfo, true, actualValue || '0', fee, pool, formData[3]);
          handleChange({ target: { value: tick } }, i);
        }}
      >
        +{new Dec(fee).mul(2).toString()}%
      </div>
    </>
  </>
);


TickActionField.defaultProps = {
  actualValue: '',
  fee: '0',
  pool: {},
  disabled: false,
};

TickActionField.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handlePointerChange: PropTypes.func.isRequired,
  type: PropTypes.object.isRequired,
  i: PropTypes.number.isRequired,
  originalValue: PropTypes.string.isRequired,
  action: PropTypes.object.isRequired,
  firstToken: PropTypes.object.isRequired,
  secondToken: PropTypes.object.isRequired,
  firstTokenInfo: PropTypes.object.isRequired,
  secondTokenInfo: PropTypes.object.isRequired,
  formData: PropTypes.array.isRequired,

  disabled: PropTypes.bool,
  actualValue: PropTypes.string,
  fee: PropTypes.string,
  pool: PropTypes.object,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TickActionField);
