import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import t from 'translate';
import {
  change, formValueSelector, reduxForm,
} from 'redux-form';
import Dec from 'decimal.js';

import { redeemCollateralAction } from '../../../../actions/liquityActions/liquityManageActions';
import { getRedemptionFeePercentage } from '../../../../services/liquityServices/liquityService';
import { formatNumber } from '../../../../services/utils';

import ActionItem from '../../../ActionItem/ActionItem';
import ModalHeader from '../../ModalHeader';
import ModalBody from '../../ModalBody';
import ErrorBox from '../../../Decorative/ErrorBox/ErrorBox';
import DataItem from '../../../DataItem/DataItem';
import WarningBox from '../../../Decorative/WarningBox/WarningBox';

import './LiquityRedeemModal.scss';

const LiquityRedeemModal = ({
  closeModal, LUSD, redeeming, redeemingErr, dispatch, redeemCollateralAction, formValues, ethPriceFeed,
}) => {
  const { redeemAmount } = formValues;

  const [fee, setFee] = useState('0');
  const [showFeeWarning, setShowFeeWarning] = useState(false);
  const canRedeem = new Dec(LUSD.balance).gt(0) && new Dec(redeemAmount || 0).gt(0);
  const ethAmount = new Dec(redeemAmount || 0).dividedBy(ethPriceFeed).toString();
  const redemptionFee = new Dec(ethAmount).mul(fee).toString();
  const ethReceived = new Dec(ethAmount).minus(redemptionFee).toString();

  useEffect(() => {
    getRedemptionFeePercentage().then(({ amount, showWarning }) => {
      setFee(amount);
      setShowFeeWarning(showWarning);
    });
  }, []);

  return (
    <div className="action-modal-wrapper lqty-redeem-modal-wrapper">
      <ModalHeader closeModal={closeModal} />

      <ModalBody>
        <div className="new-modal-top-wrapper">
          <h1>{t('common.redeem')}</h1>
        </div>

        <div className="new-modal-content-wrapper">
          <p className="info">{t('liquity.redeem_lusd_desc')}</p>

          <div className="container">
            <form
              className="lqty-redeem-form form-wrapper"
              noValidate
            >
              <ActionItem
                type="lqty-redeem-form__item"
                disabled={!canRedeem || redeeming}
                actionExecuting={redeeming}
                setValToMax={() => {
                  dispatch(change('liquityRedeemForm', 'redeemAmount', LUSD.balance));
                }}
                selectValueOnChange={(val) => {
                  dispatch(change('liquityRedeemForm', 'redeemAmount', val));
                }}
                maxVal={LUSD.balance}
                gettingMaxVal={LUSD.gettingBalance}
                toExecuteLabel={t('common.redeem')}
                executingLabel={t('common.redeeming')}
                name="redeemAmount"
                id="redeem-LUSD-input"
                symbol="LUSD"
                errorText={!canRedeem ? t('common.insufficient_balance') : ''}
                executeAction={() => {
                  redeemCollateralAction(redeemAmount, ethReceived, fee, closeModal);
                }}
              />
            </form>

            <div className="stats">
              <DataItem
                label="Max. redemption fee"
                description={t('liquity.redemption_fee_info')}
                symbol="ETH"
                value={redemptionFee}
                type="small"
              />
              <DataItem
                label="Approx. min. ETH received"
                symbol="ETH"
                value={ethReceived}
                type="small"
              />
            </div>
          </div>

          {showFeeWarning && <WarningBox>Redemption fee is currently: ${formatNumber(fee * 100, 2, true)}%</WarningBox> }
          {redeemingErr && <ErrorBox marginTop>{redeemingErr}</ErrorBox>}
        </div>
      </ModalBody>
    </div>
  );
};

const LiquityRedeemForm = reduxForm({
  form: 'liquityRedeemForm',
})(LiquityRedeemModal);

LiquityRedeemModal.propTypes = {
  formValues: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,

  LUSD: PropTypes.object.isRequired,

  redeeming: PropTypes.bool.isRequired,
  redeemingErr: PropTypes.string.isRequired,
  redeemCollateralAction: PropTypes.func.isRequired,

  ethPriceFeed: PropTypes.string.isRequired,
};

const selector = formValueSelector('liquityRedeemForm');

const mapStateToProps = state => ({
  initialValues: {
    redeemAmount: '',
  },
  formValues: {
    redeemAmount: selector(state, 'redeemAmount'),
  },

  ethPriceFeed: state.liquity.ethPriceFeed,

  LUSD: state.assets.LUSD,

  redeeming: state.liquityManage.redeeming,
  redeemingErr: state.liquityManage.redeemingErr,
});

const mapDispatchToProps = { redeemCollateralAction };

export default connect(mapStateToProps, mapDispatchToProps)(LiquityRedeemForm);
