import { getAssetInfo } from '@defisaver/tokens';
import { getATokenAddress, getDebtTokenAddress } from './aaveManageServiceV2';
import { ethToWeth } from '../utils';

export const getRecipeDebtAssetsWithInfoArray = async (debtAssets, market) => {
  if (debtAssets.length === 0) {
    return [];
  }
  const len = debtAssets.length;
  const debtAssetsExpanded = [];
  for (let i = 0; i < len; i++) {
    const { symbol, interestMode } = debtAssets[i];
    const assetAddress = getAssetInfo(ethToWeth(symbol)).address;
    if (interestMode === '1') {
      debtAssetsExpanded.push({ symbol, assetAddress, isStable: true });
    } else if (interestMode === '2') {
      debtAssetsExpanded.push({ symbol, assetAddress, isStable: false });
    } else {
      debtAssetsExpanded.push({ symbol, assetAddress, isStable: true });
      debtAssetsExpanded.push({ symbol, assetAddress, isStable: false });
    }
  }
  const promises = debtAssetsExpanded.map(async ({ symbol, assetAddress, isStable }) => {
    const debtAssetAddress = await getDebtTokenAddress(assetAddress, market, isStable);
    return {
      symbol, assetAddress, debtAssetAddress, rateMode: isStable ? 1 : 2,
    };
  });
  return Promise.all(promises);
};
export const getCollAssetsHelperArrays = async (_collAssets, market) => {
  const promises = _collAssets.map(async ({ symbol, collateral }) => {
    const assetAddress = getAssetInfo(ethToWeth(symbol)).address;
    const aAssetAddress = await getATokenAddress(assetAddress, market);
    return {
      assetAddress,
      aAssetAddress,
      collateral,
    };
  });
  const data = await Promise.all(promises);
  const aCollAssets = [];
  const collAssets = [];
  const collEnabledAssets = [];
  data.forEach(({ assetAddress, aAssetAddress, collateral }) => {
    aCollAssets.push(aAssetAddress);
    collAssets.push(assetAddress);
    if (collateral) {
      collEnabledAssets.push(assetAddress);
    }
  });
  return {
    aCollAssets, collAssets, collEnabledAssets,
  };
};
