import { getAssetInfoByAddress } from '@defisaver/tokens';
import cloneDeep from 'lodash/cloneDeep';
import dfs from '@defisaver/sdk';
import Dec from 'decimal.js';
import { yearnCollateralAssets } from 'constants/assets';
import RecipeAction from '../RecipeAction';
import { Amount, Asset, Source } from '../../components/Recipes/RecipeCreator/inputTypes';
import SupplyIcon from '../recipeIcons/Supply.svg';
import { formatNumber } from '../../services/utils';
import { assetAmountInWeiIgnorePointer, changeBalance } from '../../services/recipeCreator/recipeActionUtils';
import { getVaultAddressForToken } from '../../services/yearnServices/yearnService';
import { MAXUINT } from '../../constants/general';

const deprecatedVaults = ['HEGIC', 'crvUSDT', 'crvTricrypto'];

export default class YearnSupplyAction extends RecipeAction {
  static prettyName = 'Supply to Yearn Vault';

  static protocol = 'yearn';

  static protocolPrettyName = 'Yearn';

  static description = 'Deposits selected asset into Yearn.';

  constructor(asset = 'WETH', from = 'wallet', amount = '', to = 'wallet') {
    super();
    this.inputs = [
      new Asset('Asset', asset, yearnCollateralAssets.filter(coll => !deprecatedVaults.includes(coll.symbol))),
      new Source('From', from),
      new Amount('Amount', amount),
      new Source('To', to, true),
    ];
    this.output = new Amount('output', 0);
    this.asset = '';
    this.yAssetAddress = '';
  }

  static getIcon() {
    return SupplyIcon;
  }

  setAsset(asset) {
    this.asset = asset;
  }

  getAsset() {
    return this.asset;
  }

  _getPrettyName(actionCalls, actions) {
    const args = this.mapReturnValuesToArgs(actionCalls.map(a => a.returnValue), actions);
    const amount = args[2];
    const asset = getAssetInfoByAddress(args[0]).symbol;
    return `Supply ${amount === 'All available' ? 'all' : formatNumber(amount)} ${asset.replace(/^ETH/, 'WETH')} to Yearn Vault`;
  }

  async getAfterValues(_balances = {}, returnValues = [], actions = [], _positions = {}, getState) {
    const positions = { ..._positions };
    const balances = cloneDeep(_balances);
    const {
      general: { account },
      maker: { proxyAddress },
      assets,
    } = getState();
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const asset = getAssetInfoByAddress(args[0]).symbol;
    let amount = args[2] || '0';
    if (amount === 'All available') {
      amount = (
        args[1] === 'wallet'
          ? (balances?.wallet?.[asset] || assets?.[asset]?.balance)
          : (balances?.recipe?.[asset] || '0'))
        || '0';
    }
    await changeBalance(balances, args[1], asset, new Dec(amount).mul(-1).toString(), args[1] === 'wallet' ? account : proxyAddress);
    const vaultAddress = await getVaultAddressForToken(args[0]);
    await changeBalance(balances, args[3], `YV-${asset}`, amount, args[3] === 'wallet' ? account : proxyAddress, vaultAddress);
    this.output.value = amount;
    return {
      returnValue: this.output,
      balances,
      positions,
    };
  }

  async toDfsAction(getState, i) {
    const {
      general: { account },
      maker: { proxyAddress },
    } = getState();
    const from = this.inputs[1].value === 'wallet' ? account : proxyAddress;
    const to = this.inputs[3].value === 'wallet' ? account : proxyAddress;
    const amount = this.inputs[2].value === 'All available' ? MAXUINT : assetAmountInWeiIgnorePointer(this.inputs[2].value, this.inputs[0]._asset);
    return new dfs.actions.yearn.YearnSupplyAction(this.inputs[0].value, amount, from, to);
  }
}
