/* eslint-disable */
import React from 'react';

export default function AmbireIcon(props) {
  return (
    <svg width="16" height="27" viewBox="0 0 16 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M7.35928 1.98259L5.11014 7.78658C5.0875 7.8545 5.09505 7.92243 5.12524 7.99036L7.22343 11.7339L1.46473 14.7076C1.3968 14.7453 1.30623 14.7151 1.27604 14.6548L0.0307114 12.2849C-0.0145732 12.2094 -0.00702603 12.1113 0.0458061 12.0358L7.30645 1.96749C7.32909 1.9373 7.36683 1.95995 7.35928 1.98259Z" fill="#6000FF" />
      <path fillRule="evenodd" clipRule="evenodd" d="M5.10207 7.85448C5.10207 7.83183 5.10962 7.80919 5.11717 7.78655L7.36631 1.99766C7.37386 1.97502 7.32857 1.95237 7.31348 1.97502L0.0377372 12.0433C0.0226423 12.0584 0.0150949 12.0886 0 12.1037L5.10207 7.85448Z" fill="url(#paint0_linear_19237_60962)" />
      <g opacity="0.4">
        <g opacity="0.4">
          <g opacity="0.4">
            <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M7.35831 1.98259L5.10917 7.78657C5.08652 7.8545 5.09407 7.92243 5.12426 7.99036L7.22245 11.7339L1.46375 14.7076C1.39582 14.7453 1.30525 14.7151 1.27506 14.6548L0.0297349 12.2849C-0.0155498 12.2094 -0.00800259 12.1113 0.0448295 12.0358L7.30547 1.96749C7.32812 1.9373 7.36585 1.95995 7.35831 1.98259Z" fill="#6000FF" />
          </g>
          <mask id="mask0_19237_60962" style={{ maskType:'alpha' }} maskUnits="userSpaceOnUse" x="0" y="1" width="8" height="14">
            <g opacity="0.4">
              <g opacity="0.4">
                <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M7.35831 1.98259L5.10917 7.78657C5.08652 7.8545 5.09407 7.92243 5.12426 7.99036L7.22245 11.7339L1.46375 14.7076C1.39582 14.7453 1.30525 14.7151 1.27506 14.6548L0.0297349 12.2849C-0.0155498 12.2094 -0.00800259 12.1113 0.0448295 12.0358L7.30547 1.96749C7.32812 1.9373 7.36585 1.95995 7.35831 1.98259Z" fill="white" />
              </g>
            </g>
          </mask>
          <g mask="url(#mask0_19237_60962)" />
        </g>
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M9.28343 26.9647L10.5967 22.6551C10.6193 22.5871 10.6118 22.5192 10.5816 22.4513L8.4834 18.7077L14.2421 15.7341C14.31 15.6963 14.4006 15.7265 14.4308 15.7869L15.6761 18.1492C15.7214 18.2247 15.7139 18.3228 15.661 18.3983L9.32871 26.9873C9.31362 27.0175 9.26833 26.9873 9.28343 26.9647Z" fill="#6000FF" />
      <path fillRule="evenodd" clipRule="evenodd" d="M9.3286 26.9873L15.6609 18.4058C15.676 18.3907 15.6835 18.3606 15.6986 18.3455L10.6117 22.5871C10.6117 22.6098 10.6041 22.6324 10.5966 22.6551L9.28331 26.9646C9.26822 26.9873 9.3135 27.0175 9.3286 26.9873Z" fill="url(#paint1_linear_19237_60962)" />
      <g opacity="0.59">
        <g opacity="0.59">
          <g opacity="0.59">
            <path opacity="0.59" fillRule="evenodd" clipRule="evenodd" d="M9.28245 26.9646L10.5957 22.655C10.6183 22.5871 10.6108 22.5191 10.5806 22.4512L8.48242 18.7077L14.2411 15.734C14.3091 15.6963 14.3996 15.7264 14.4298 15.7868L15.6751 18.1492C15.7204 18.2246 15.7129 18.3228 15.66 18.3982L9.32774 26.9872C9.31264 27.0174 9.26736 26.9872 9.28245 26.9646Z" fill="#6000FF" />
          </g>
          <mask id="mask1_19237_60962" style={{ maskType:'alpha' }} maskUnits="userSpaceOnUse" x="8" y="15" width="8" height="12">
            <g opacity="0.59">
              <g opacity="0.59">
                <path opacity="0.59" fillRule="evenodd" clipRule="evenodd" d="M9.28245 26.9646L10.5957 22.655C10.6183 22.5871 10.6108 22.5191 10.5806 22.4512L8.48242 18.7077L14.2411 15.734C14.3091 15.6963 14.3996 15.7264 14.4298 15.7868L15.6751 18.1492C15.7204 18.2246 15.7129 18.3228 15.66 18.3982L9.32774 26.9872C9.31264 27.0174 9.26736 26.9872 9.28245 26.9646Z" fill="white" />
              </g>
            </g>
          </mask>
          <g mask="url(#mask1_19237_60962)" />
        </g>
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M10.3931 0.0353304L15.6914 9.61304C15.714 9.67342 15.714 9.74135 15.6914 9.81682L4.67209 25.3646C4.63435 25.44 4.51359 25.4702 4.44567 25.4098L0.0907904 19.8851C-0.0148739 19.7945 -0.0224213 19.6511 0.0530532 19.553L10.227 6.97898C10.2648 6.9337 10.2798 6.88087 10.2798 6.82803V0.0504256C10.2874 -0.00995394 10.378 -0.0175017 10.3931 0.0353304Z" fill="url(#paint2_linear_19237_60962)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M15.7069 9.7036C15.7069 9.68095 15.6993 9.64322 15.6918 9.61303L10.3935 0.0353159C10.3708 -0.0175162 10.2878 -0.002421 10.2878 0.0504111V6.82802C10.2878 6.85066 10.2803 6.88085 10.2803 6.90349L15.7069 9.7036Z" fill="url(#paint3_linear_19237_60962)" />
      <path opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M3.68342 9.03943L0.339901 11.6282L0.0455497 12.0358C0.0304548 12.0509 0.0229074 12.081 0.0078125 12.0961L3.68342 9.03943Z" fill="white" />
      <path opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M12.0312 21.4022L15.3597 18.8209L15.6767 18.4058C15.6918 18.3907 15.6993 18.3606 15.7144 18.3455L12.0312 21.4022Z" fill="white" />
      <path opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M15.7064 9.70363C15.7064 9.68099 15.6989 9.64325 15.6913 9.61307L15.5932 9.44702L10.4609 7.00165L15.7064 9.70363Z" fill="white" />
      <defs>
        <linearGradient id="paint0_linear_19237_60962" x1="6.9204" y1="3.4025" x2="-1.30326" y2="9.93069" gradientUnits="userSpaceOnUse">
          <stop stopColor="#AF50FF" stopOpacity="0" />
          <stop offset="1" stopColor="#AF50FF" />
        </linearGradient>
        <linearGradient id="paint1_linear_19237_60962" x1="9.60886" y1="25.8043" x2="16.7161" y2="19.9236" gradientUnits="userSpaceOnUse">
          <stop stopColor="#AF50FF" stopOpacity="0" />
          <stop offset="1" stopColor="#AF50FF" />
        </linearGradient>
        <linearGradient id="paint2_linear_19237_60962" x1="-9.56963" y1="36.8702" x2="17.7593" y2="22.1174" gradientUnits="userSpaceOnUse">
          <stop stopColor="#AF50FF" />
          <stop offset="1" stopColor="#6000FF" />
        </linearGradient>
        <linearGradient id="paint3_linear_19237_60962" x1="10.456" y1="3.61084" x2="17.1966" y2="6.12711" gradientUnits="userSpaceOnUse">
          <stop stopColor="#AF50FF" stopOpacity="0" />
          <stop offset="1" stopColor="#AF50FF" />
        </linearGradient>
      </defs>
    </svg>

  );
}
