import t from 'translate';
import {
  ADD_SERVER_TX,
  GET_SERVER_TX_REQUEST,
  GET_SERVER_TX_SUCCESS,
  GET_SERVER_TX_FAILURE,
} from 'actionTypes/txActionTypes';
import { getServerTxApiCall } from '../services/apiService';
import { addNotification, changeNotification } from './txNotificationActions';

/**
 * Gets notifications from the server
 * @return {Function}
 */
export const getServerTx = () => async (dispatch, getState) => {
  const { account, forkId } = getState().general;
  if (!account) return;

  const currentTx = getState().tx.transactions;
  if ((currentTx.length > 0 && !currentTx.filter(t => t.status === 'pending').length) || getState().general.network !== 1) return;

  dispatch({ type: GET_SERVER_TX_REQUEST });

  try {
    const res = await getServerTxApiCall(account, forkId);

    let payload = res.transactions;

    if (!payload) payload = [];

    const { notifications } = getState().notifications;
    payload.forEach((tx) => {
      tx.fork = !!forkId; // eslint-disable-line

      const formatTx = hash => `${hash.slice(0, 8)}...`;
      let description = `Status: ${tx.status}`;
      if (tx.status === 'pending') description = t('misc.tx_pending', { '%txHash': formatTx(tx.txHash) });
      if (tx.status === 'confirmed') description = t('misc.tx_confirmed', { '%txHash': formatTx(tx.txHash) });
      if (tx.status === 'failed') description = t('misc.tx_rejected', { '%txHash': formatTx(tx.txHash) });
      if (tx.status === 'speedup') description = t('misc.tx_replaced', { '%txHash': formatTx(tx.txHash) });
      if (tx.status === 'cancel') description = t('misc.tx_canceled', { '%txHash': formatTx(tx.txHash) });

      const index = notifications.findIndex(n => n.hash.toLowerCase() === tx.txHash.toLowerCase());
      if (index === -1 && tx.status === 'pending') {
        const id = `${getState().notifications.notifications.length}-${tx.title}`;
        dispatch(addNotification(id, tx.status, tx.title, description, tx.txHash));
      }
      if (index >= 0 && notifications[index].status !== tx.status && notifications[index].status !== 'failed') {
        dispatch(changeNotification(notifications[index].id, { status: tx.status, description }));
      }
    });

    dispatch({ type: GET_SERVER_TX_SUCCESS, payload });
  } catch (err) {
    dispatch({ type: GET_SERVER_TX_FAILURE, payload: err.message });
  }
};

/**
 * Fetches server notifications on call and then again every one minute
 * @return {Function}
 */
export const updateServerTxInterval = () => (dispatch) => {
  dispatch(getServerTx());
  return setTimeout(() => dispatch(updateServerTxInterval()), 10 * 1000);
};
