import t from 'translate';
import { addApproveTxIfNeeded, approveAddressOnAssetAction, approveTypeToLabelMap } from './assetsActions';
import { APPROVE_TYPE_DS_PROXY } from '../actionTypes/assetsActionTypes';
import { isAddress } from '../services/utils';

/**
 * Parses inputs from dashboard form so that collateral+generate can be called
 * using both addCollateral(collateral, generate) and addCollateral(generate, collateral)
 * @param contextAction {Object}
 * @param [additionalAction] {Object}
 * @returns {function(*, *): {firstAction: object, secondAction: object, firstInput: *, secondInput: *}}
 */
export const getDashboardInputs = (contextAction, additionalAction) => (dispatch, getState) => {
  const formValues = getState().form[contextAction.formName].values;

  let firstAction = additionalAction?.goesFirst ? additionalAction : contextAction;
  let secondAction = additionalAction?.goesFirst ? contextAction : additionalAction;

  if (contextAction.value === 'boost') {
    firstAction = contextAction;
    secondAction = additionalAction;
  }
  if (additionalAction?.value === 'boost') {
    firstAction = additionalAction;
    secondAction = contextAction;
  }

  const firstInput = formValues?.[`${contextAction.value}-${firstAction.value}`];
  const firstInputSelect = formValues?.[`${contextAction.value}-${firstAction.value}-select1`];
  const firstInputSecondSelect = formValues?.[`${contextAction.value}-${firstAction.value}-select2`];

  const secondInput = formValues?.[`${contextAction.value}-${secondAction?.value}`];
  const secondInputSelect = formValues?.[`${contextAction.value}-${secondAction?.value}-select1`];
  const secondInputSecondSelect = formValues?.[`${contextAction.value}-${secondAction?.value}-select2`];

  return {
    firstAction,
    firstInput,
    firstInputSelect,
    firstInputSecondSelect,
    secondAction,
    secondInput,
    secondInputSelect,
    secondInputSecondSelect,
  };
};

/**
 *
 * @param {Object} state
 * @param {String} protocol
 * @return {[undefined, undefined, undefined, undefined]}
 */
export const getDashboardSelectAssets = (state, protocol) => {
  const { selectedAction, selectedAdditionalActions } = state[`${protocol}Manage`];
  const inputs = { ...state.form[selectedAction.formName]?.values };

  const contextAction = selectedAction?.value;
  const additionalAction = selectedAdditionalActions[contextAction]?.value;
  const firstLabel = `${contextAction}-${contextAction}`;
  const secondLabel = `${contextAction}-${additionalAction}`;

  return [inputs[`${firstLabel}-select1`], inputs[`${firstLabel}-select2`], inputs[`${secondLabel}-select1`], inputs[`${secondLabel}-select2`]];
};

const addApproveIfNeeded = (asset, amount, proxyAddress, txToExecute, dispatch) => {
  const assetAddress = isAddress(asset) ? asset : undefined;
  const approveFunctionObject = {
    func: () => dispatch(approveAddressOnAssetAction(asset, proxyAddress, APPROVE_TYPE_DS_PROXY, assetAddress)),
    expectReturn: true,
    type: 'approve',
    notifMessage: `${t('common.approve')} ${approveTypeToLabelMap[APPROVE_TYPE_DS_PROXY]} ${t('common.for')} ${asset}`,
  };
  return dispatch(addApproveTxIfNeeded(asset, proxyAddress, amount, APPROVE_TYPE_DS_PROXY, txToExecute, approveFunctionObject, assetAddress));
};
export const addApproveForActionIfNeeded = (action, input, proxyAddress, txToExecute) => async (dispatch) => {
  if (action?.approve) {
    return addApproveIfNeeded(action.approve, input, proxyAddress, txToExecute, dispatch);
  }
};
export const addApproveForActionWithSelectIfNeeded = (action, input, selectInput, proxyAddress, txToExecute) => async (dispatch) => {
  if (action?.otherProps?.approveSelect) {
    return addApproveIfNeeded(selectInput.value, input, proxyAddress, txToExecute, dispatch);
  }
};

