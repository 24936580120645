/* eslint-disable */
import React from 'react';

export default function LidoProtocolIcon(props) {
  return (
    <svg width="76" height="106" viewBox="0 0 76 106" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M38.0393 0L64.2733 41.0627L38.0393 56.3473L11.8054 41.0627L38.0393 0ZM19.8346 39.1177L38.0393 10.6261L56.2388 39.1177L38.034 49.7279L19.8346 39.1124V39.1177Z" fill="white"/>
      <path d="M38.0021 65.3729L7.56547 47.6451L6.73341 48.9435C2.18484 56.0649 0.204439 64.5259 1.12003 72.9262C2.03561 81.3265 5.79228 89.1622 11.7682 95.1364C18.7254 102.093 28.161 106.001 37.9995 106.001C47.838 106.001 57.2735 102.093 64.2308 95.1364C70.2067 89.1622 73.9633 81.3265 74.8789 72.9262C75.7945 64.5259 73.8141 56.0649 69.2655 48.9435L68.4282 47.6451L38.0021 65.3729Z" fill="white"/>
    </svg>
  );
}
