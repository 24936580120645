import React, { Component } from 'react';
import t from 'translate';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import OutsideAlerter from '../OutsideAlerter/OutsideAlerter';
import Loader from '../Loader/Loader';
import ErrorBox from '../Decorative/ErrorBox/ErrorBox';

import '../ServerNotifications/ServerNotifications.scss';

import emptyInbox from '../ServerNotifications/empty-inbox.svg';
import txIcon from './tx-icon.svg';
import SingleServerTx from './SingleServerTx/SingleServerTx';

class ServerTxMonitor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };

    this.setOpen = this.setOpen.bind(this);
  }

  setOpen(payload) { this.setState({ open: payload }); }

  render() {
    const {
      setOpen,
      state: { open },
      props: {
        txs, gettingTxs, gettingTxsError, ethPrice,
      },
    } = this;

    const hasPending = txs.filter(({ status }) => status === 'pending').length;

    return (
      <OutsideAlerter
        className="dropdown-list-panel-wrapper"
        onClickOutside={() => { setOpen(false); }}
      >
        <div
          className="icon-wrapper"
          onClick={() => { setOpen(!open); }}
        >
          {
            hasPending > 0 && <span className="unread-count pending" />
          }
          <i><img src={txIcon} alt="" /></i>
        </div>

        <div
          className={`dropdown-wrapper ${open ? 'opened' : 'closed'}`}
        >
          <h3>{t('misc.recent_txs')}</h3>
          <div className="dropdown-container taller">
            {gettingTxs && txs.length === 0 && (<Loader />)}

            {
              gettingTxsError && (
                <ErrorBox>{gettingTxsError}</ErrorBox>
              )
            }

            {
              !gettingTxs && !gettingTxsError && txs.length === 0 && (
                <div className="empty-wrapper">
                  <div className="title">{t('misc.no_recent_txs')}</div>
                  <img src={emptyInbox} alt={t('misc.no_recent_txs')} />
                </div>
              )
            }

            {
              txs.length > 0 && (
                <div className="notifications-container">
                  {txs.map(t => <SingleServerTx key={t.txHash} ethPrice={ethPrice} {...t} />)}
                </div>
              )
            }
          </div>
        </div>
      </OutsideAlerter>
    );
  }
}

ServerTxMonitor.propTypes = {
  txs: PropTypes.array.isRequired,
  gettingTxs: PropTypes.bool.isRequired,
  gettingTxsError: PropTypes.string.isRequired,
  ethPrice: PropTypes.string.isRequired,
};

const mapStateToProps = ({ tx, assets }) => ({
  txs: tx.transactions,
  gettingTxs: tx.gettingTxs,
  gettingTxsError: tx.gettingTxsError,
  ethPrice: assets.ETH.marketPrice,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ServerTxMonitor);
