import {
  ADD_SERVER_TX,
  GET_SERVER_TX_REQUEST,
  GET_SERVER_TX_SUCCESS,
  GET_SERVER_TX_FAILURE,
} from '../actionTypes/txActionTypes';
import { CONNECT_PROVIDER_SUCCESS } from '../actionTypes/generalActionTypes';

const INITIAL_STATE = {
  gettingTxs: false,
  gettingTxsError: '',
  transactions: [],
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_SERVER_TX_REQUEST:
      return { ...state, gettingTxs: true, gettingTxsError: '' };

    case GET_SERVER_TX_SUCCESS:
      return { ...state, gettingTxs: false, transactions: [...payload] };

    case GET_SERVER_TX_FAILURE:
      return { ...state, gettingTxs: false, gettingTxsError: payload };

    case ADD_SERVER_TX:
      return { ...state, transactions: [payload, ...state.transactions] };

    case CONNECT_PROVIDER_SUCCESS:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};
