import React from 'react';
import t from 'translate';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import TooltipWrapper from '../../../TooltipWrapper/TooltipWrapper';
import InputComponent from '../../../Forms/InputComponent';
import InfoIcon from '../../../Decorative/InfoIcon';

const GasPriceEIP1599ModalForm = ({ handleSubmit, onSubmit, setFormWasChanged }) => (
  <form
    id="gas-price-1559-modal-form"
    onSubmit={handleSubmit(onSubmit)}
    className="form-wrapper"
  >
    <Field
      id="priority-fee"
      name="priorityFee"
      type="number"
      placeholder="0"
      onChange={() => setFormWasChanged(true)}
      labelText={(
        <span>
          <TooltipWrapper title={t('misc.priority_fee_desc')}>
            <InfoIcon />
          </TooltipWrapper>
          {t('misc.priority_fee')}
        </span>
        )}
      secondLabelText="Gwei"
      component={InputComponent}
      showErrorText
      additional={{
        min: 0,
        step: 'any',
      }}
    />
    <Field
      id="max-fee"
      name="maxFee"
      type="number"
      placeholder="0"
      onChange={() => setFormWasChanged(true)}
      labelText={(
        <span>
          <TooltipWrapper title={t('misc.max_fee_desc')}>
            <InfoIcon />
          </TooltipWrapper>
          {t('misc.max_fee')}
        </span>
        )}
      secondLabelText="Gwei"
      component={InputComponent}
      showErrorText
      additional={{
        min: 0,
        step: 'any',
      }}
    />
    <Field
      id="gas-limit"
      name="gasLimit"
      type="number"
      placeholder="0"
      labelText={(
        <span>
          <TooltipWrapper title={t('misc.gas_limit_desc')}>
            <InfoIcon />
          </TooltipWrapper>
          {t('misc.gas_limit')}
        </span>
        )}
      secondLabelText=""
      component={InputComponent}
      showErrorText
      additional={{
        min: 0,
        // max: 10e6,
      }}
    />
  </form>
);

export const GasPriceEIP1599FormName = 'gasPriceEIP1599ModalForm';

const GasPriceEIP1599ModalFormComp = reduxForm({
  form: GasPriceEIP1599FormName,
})(GasPriceEIP1599ModalForm);

GasPriceEIP1599ModalForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    maxFee: PropTypes.string.isRequired,
    priorityFee: PropTypes.string.isRequired,
    gasLimit: PropTypes.string.isRequired,
  }).isRequired,
  setFormWasChanged: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { maxFee, priorityFee, gasLimit }) => ({
  initialValues: { maxFee, priorityFee, gasLimit },
});

export default connect(mapStateToProps)(GasPriceEIP1599ModalFormComp);
