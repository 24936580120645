import React from 'react';

export default function RecipesIcon(props) {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="30" height="30" rx="3" fill="#3A4750" />
      <path d="M10 16.9646C8.30371 16.7219 7 15.2634 7 13.5C7 11.5669 8.56689 10 10.5 10C11.0381 10 11.5479 10.1216 12.0034 10.3386C12.0879 8.48047 13.6211 7 15.5 7C17.3789 7 18.9121 8.48047 18.9966 10.3386C19.4521 10.1216 19.9619 10 20.5 10C22.4331 10 24 11.5669 24 13.5C24 15.2634 22.6963 16.7219 21 16.9646V23C21 23.5522 20.5522 24 20 24H11C10.4478 24 10 23.5522 10 23C10 22.4478 10.4478 22 11 22H18.5C18.7764 22 19 21.7761 19 21.5C19 21.2239 18.7764 21 18.5 21H11C10.4478 21 10 20.5522 10 20V16.9646Z" fill="#626D75" />
    </svg>
  );
}
