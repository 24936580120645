/* eslint-disable */
import React from 'react';

export default function ChangeNetworkIcon(props) {
  return (
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 4H4" stroke="#61717E" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.22217 11.4721C7.88135 11.4721 6.59545 10.9394 5.64735 9.99132C4.69925 9.04322 4.16661 7.75732 4.16661 6.4165" stroke="#61717E" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14.9999 13.9999L14.9999 8.94434L9.94432 8.94434L9.94432 13.9999L14.9999 13.9999Z" stroke="#61717E" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M15.0555 6.05556L15.0555 1L9.99999 1L9.99999 6.05556L15.0555 6.05556Z" fill="#61717E" stroke="#61717E" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3.52776 1C2.13171 1 0.999986 2.13172 0.999986 3.52778C0.999986 4.92383 2.13171 6.05556 3.52776 6.05556C4.92382 6.05556 6.05554 4.92383 6.05554 3.52778C6.05554 2.13172 4.92382 1 3.52776 1Z" fill="#61717E" stroke="#61717E" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}
