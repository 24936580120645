import React from 'react';
import { useDispatch } from 'react-redux';
import image from './l2-decoration.png';

import { nameOfNetwork } from '../../services/ethService';
import { openChangeNetworkModal } from '../../actions/modalActions';

import './FeatureBlocker.scss';
import { featureAvailable } from '../../services/utils';

const FeatureBlocker = (availableChains, selectedNetwork) => () => {
  const dispatch = useDispatch();
  return (
    <div className="feature-blocker-wrapper dashboard-page-wrapper">
      <div className="content-wrapper">
        <div className="width-container">
          <div className="main-section-wrapper">
            <div>
              <h1 className="title">Feature unavailable on {nameOfNetwork(selectedNetwork)}</h1>
              <p className="description">Available on: <span>{availableChains.map(nameOfNetwork)}</span></p>
              <button
                type="button"
                className="button green"
                onClick={() => dispatch(openChangeNetworkModal())}
              >
                Change network
              </button>
            </div>
            <div className="decoration"><img src={image} alt="" /></div>
          </div>
        </div>
      </div>
    </div>
  );
};

const featureBlocker = (selectedNetwork, featureName, Component) => {
  const { availableChains, isAvailable } = featureAvailable(featureName, selectedNetwork);
  if (isAvailable) return Component;
  return FeatureBlocker(availableChains, selectedNetwork);
};

export default featureBlocker;
