import React, { useEffect, useState } from 'react';
import t from 'translate';
import PropTypes from 'prop-types';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import InputComponent from '../../../Forms/InputComponent';
import { DEFAULT_DEADLINE } from '../../../../constants/general';
import { setDeadline, resetDeadline } from '../../../../actions/actionsInModals';

const DeadlineLimitForm = ({ setDeadline, resetDeadline, initialValues }) => {
  const [id, setId] = useState('');

  useEffect(() => {
    setId(`deadline-value-${Math.floor(Math.random() * 10000)}`);
    setDeadline(initialValues.deadline);
    return resetDeadline;
  }, []);

  return (
    <form
      onSubmit={() => {}}
      className="deadline-wrapper form-wrapper"
      noValidate
    >
      <div className="data-wrapper">
        <Field
          id={id}
          name="deadline"
          placeholder="20"
          type="number"
          additional={{ min: 0 }}
          labelText={t('misc.deadline')}
          onChange={(e) => {
            setDeadline(e.target.value);
          }}
          secondLabelText="min"
          component={InputComponent}
          showErrorText
        />
      </div>
    </form>

  );
};

DeadlineLimitForm.defaultProps = {};

DeadlineLimitForm.propTypes = {
  setDeadline: PropTypes.func.isRequired,
  resetDeadline: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
};

const DeadlineLimitFormComp = reduxForm({
  form: 'deadlineLimitForm',
})(DeadlineLimitForm);

const selector = formValueSelector('deadlineLimitForm');

const mapDispatchToProps = {
  setDeadline, resetDeadline,
};

export const MakerDeadlineLimitForm = connect(
  state => ({
    initialValues: {
      deadline: DEFAULT_DEADLINE,
    },
    formValues: {
      deadline: selector(state, 'deadline'),
    },
  }), mapDispatchToProps,
)(DeadlineLimitFormComp);
