import { assetAmountInWei, getAssetInfo, MAXUINT } from '@defisaver/tokens';
import dfs from '@defisaver/sdk';
import Dec from 'decimal.js';
import { getExchangeOrder } from '../services/exchangeServiceV3';
import { ethToWeth } from '../services/utils';
import { findInsertPosition } from '../services/liquityServices/liquityService';
import { ZERO_ADDRESS } from '../constants/general';
import { getFLAction } from '../services/assetsService';

export const boost = async (debtAmount, price, slippagePercent, trove, maxFeePercentage, proxyAddress) => {
  const collAsset = ethToWeth(trove.asset);
  const troveDebtAmountWei = assetAmountInWei(trove.debtInAsset, trove.asset);
  const troveCollAmountWei = assetAmountInWei(trove.collateral, trove.asset);
  const boostDebtAmountWei = assetAmountInWei(debtAmount, trove.debtAsset);
  const boostCollAmountWei = assetAmountInWei(new Dec(debtAmount).mul(price), trove.asset);
  const maxFeePercentageWei = assetAmountInWei(maxFeePercentage, 'ETH');

  const newDebtAmount = new Dec(troveDebtAmountWei).plus(boostDebtAmountWei).toString();
  const newCollAmount = new Dec(troveCollAmountWei).plus(boostCollAmountWei).toString();
  const findInsertPositionPromise = await Promise.all([
    findInsertPosition(troveCollAmountWei, newDebtAmount, proxyAddress),
    findInsertPosition(newCollAmount, newDebtAmount, proxyAddress),
  ]);
  const { upperHint: upperHintBorr, lowerHint: lowerHintBorr } = findInsertPositionPromise[0];
  const { upperHint: upperHintSupp, lowerHint: lowerHintSupp } = findInsertPositionPromise[1];

  const { orderData, value, extraGas } = await getExchangeOrder(trove.debtAsset, collAsset, debtAmount, price, slippagePercent, proxyAddress, false, false, true);
  orderData[2] = '$1';

  const recipe = new dfs.Recipe('recLiquityBoost', [
    new dfs.actions.liquity.LiquityBorrowAction(maxFeePercentageWei, boostDebtAmountWei, proxyAddress, upperHintBorr, lowerHintBorr),
    new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value),
    new dfs.actions.liquity.LiquitySupplyAction('$2', proxyAddress, upperHintSupp, lowerHintSupp),
  ]);
  recipe.extraGas = extraGas;
  return recipe;
};


export const boostWithCollLoan = async (debtAmount, price, slippagePercent, trove, maxFeePercentage, proxyAddress, flProtocol) => {
  const collAsset = ethToWeth(trove.asset);
  const troveDebtAmountWei = assetAmountInWei(trove.debtInAsset, trove.asset);
  const troveCollAmountWei = assetAmountInWei(trove.collateral, trove.asset);
  const boostDebtAmountWei = assetAmountInWei(debtAmount, trove.debtAsset);
  const boostCollAmountWei = assetAmountInWei(new Dec(debtAmount).mul(price), trove.asset);
  const maxFeePercentageWei = assetAmountInWei(maxFeePercentage, 'ETH');

  const newCollAmountWei1 = new Dec(troveCollAmountWei).plus(boostCollAmountWei).toString();
  const newDebtAmountWei = new Dec(troveDebtAmountWei).plus(boostDebtAmountWei).toString();
  const newCollAmountWei2 = new Dec(newCollAmountWei1).plus(boostCollAmountWei).toString();

  // should new debt be passed in findInsertPos with fee ?
  const findInsertPositionPromise = await Promise.all([
    findInsertPosition(newCollAmountWei1, troveDebtAmountWei, proxyAddress),
    findInsertPosition(newCollAmountWei1, newDebtAmountWei, proxyAddress),
    findInsertPosition(newCollAmountWei2, newDebtAmountWei, proxyAddress),
    findInsertPosition(newCollAmountWei1, newDebtAmountWei, proxyAddress),
  ]);
  const { upperHint: upperHintSupp1, lowerHint: lowerHintSupp1 } = findInsertPositionPromise[0];
  const { upperHint: upperHintBorr, lowerHint: lowerHintBorr } = findInsertPositionPromise[1];
  const { upperHint: upperHintSupp2, lowerHint: lowerHintSupp2 } = findInsertPositionPromise[2];
  const { upperHint: upperHintWith, lowerHint: lowerHintWith } = findInsertPositionPromise[3];

  const { orderData, value, extraGas } = await getExchangeOrder(trove.debtAsset, collAsset, debtAmount, price, slippagePercent, proxyAddress, false, false, true, flProtocol === 'balancer' ? ['Balancer_V2'] : []);

  const { FLAction, paybackAddress: flPaybackAddress } = getFLAction(flProtocol, boostCollAmountWei, collAsset);
  const recipe = new dfs.Recipe('recLiquityFLBoostViaColl', [
    // [getAssetInfo(asset).address], [amount], ZERO_ADDRESS, []
    FLAction,
    new dfs.actions.liquity.LiquitySupplyAction('$1', proxyAddress, upperHintSupp1, lowerHintSupp1),
    new dfs.actions.liquity.LiquityBorrowAction(maxFeePercentageWei, boostDebtAmountWei, proxyAddress, upperHintBorr, lowerHintBorr),
    new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value),
    new dfs.actions.liquity.LiquitySupplyAction('$4', proxyAddress, upperHintSupp2, lowerHintSupp2),
    new dfs.actions.liquity.LiquityWithdrawAction('$1', flPaybackAddress, upperHintWith, lowerHintWith),
  ]);
  recipe.extraGas = extraGas;
  return recipe;
};


export const repay = async (collAmount, price, slippagePercent, trove, proxyAddress) => {
  const collAsset = ethToWeth(trove.asset);
  const { orderData, value, extraGas } = await getExchangeOrder(collAsset, trove.debtAsset, collAmount, price, slippagePercent, proxyAddress, false, false, true);
  const repayCollAmountWei = assetAmountInWei(collAmount, trove.asset);
  const repayDebtAmountWei = assetAmountInWei(new Dec(collAmount).mul(price), trove.debtAsset);
  const troveDebtAmountWei = assetAmountInWei(trove.debtInAsset, trove.asset);
  const troveCollAmountWei = assetAmountInWei(trove.collateral, trove.asset);

  const newCollAmountWei = new Dec(troveCollAmountWei).minus(repayCollAmountWei).toString();
  const newDebtAmountWei = new Dec(troveDebtAmountWei).minus(repayDebtAmountWei).toString();
  const findInsertPositionPromise = await Promise.all([
    findInsertPosition(newCollAmountWei, troveDebtAmountWei, proxyAddress),
    findInsertPosition(newCollAmountWei, newDebtAmountWei, proxyAddress),
  ]);

  const { upperHint: upperHintWith, lowerHint: lowerHintWith } = findInsertPositionPromise[0];
  const { upperHint: upperHintPayback, lowerHint: lowerHintPayback } = findInsertPositionPromise[1];

  const recipe = new dfs.Recipe('recLiquityRepay', [
    new dfs.actions.liquity.LiquityWithdrawAction(repayCollAmountWei, proxyAddress, upperHintWith, lowerHintWith),
    new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value),
    new dfs.actions.liquity.LiquityPaybackAction('$2', proxyAddress, upperHintPayback, lowerHintPayback),
  ]);

  recipe.extraGas = extraGas;
  return recipe;
};

export const repayWithCollLoan = async (collAmount, price, slippagePercent, trove, proxyAddress) => {
  const collAsset = ethToWeth(trove.asset);
  const repayCollAmountWei = assetAmountInWei(collAmount, trove.asset);
  const repayDebtAmountWei = assetAmountInWei(new Dec(collAmount).mul(price), trove.debtAsset);
  const troveDebtAmountWei = assetAmountInWei(trove.debtInAsset, trove.asset);
  const troveCollAmountWei = assetAmountInWei(trove.collateral, trove.asset);

  const newDebtAmountWei = new Dec(troveDebtAmountWei).minus(repayDebtAmountWei).toString();
  const newCollAmountWei = new Dec(troveCollAmountWei).minus(repayCollAmountWei).toString();

  const findInsertPositionPromise = await Promise.all([
    findInsertPosition(troveCollAmountWei, newDebtAmountWei, proxyAddress),
    findInsertPosition(newCollAmountWei, newDebtAmountWei, proxyAddress),
  ]);

  const { upperHint: upperHintPayback, lowerHint: lowerHintPayback } = findInsertPositionPromise[0];
  const { upperHint: upperHintWith, lowerHint: lowerHintWith } = findInsertPositionPromise[1];

  const { orderData, value, extraGas } = await getExchangeOrder(collAsset, trove.debtAsset, collAmount, price, slippagePercent, proxyAddress, false, false, true, ['Balancer_V2']);
  orderData[2] = '$1';
  const recipe = new dfs.Recipe('recLiquityFLRepayViaColl', [
    new dfs.actions.flashloan.BalancerFlashLoanAction([getAssetInfo(collAsset).address], [repayCollAmountWei], ZERO_ADDRESS, []),
    new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value),
    new dfs.actions.liquity.LiquityPaybackAction('$2', proxyAddress, upperHintPayback, lowerHintPayback),
    new dfs.actions.liquity.LiquityWithdrawAction('$1', dfs.actionAddresses().FLBalancer, upperHintWith, lowerHintWith),
  ]);

  recipe.extraGas = extraGas;
  return recipe;
};
