import memoize from 'memoizee';
import dfs from '@defisaver/sdk';
import { CdpId, Amount, Source } from 'components/Recipes/RecipeCreator/inputTypes';
import { getSafeInfo as _getSafeInfo, getRaiIlkInfo } from '../../services/reflexerServices/reflexerService';
import { withdrawAfterValues } from '../../actions/reflexerActions/reflexerManageAfterValues';
import { formatNumber } from '../../services/utils';
import { assetAmountInWeiIgnorePointer } from '../../services/recipeCreator/recipeActionUtils';
import { MAXUINT } from '../../constants/general';

import RecipeAction from '../RecipeAction';
import WithdrawIcon from '../recipeIcons/Withdraw.svg';

const getSafeInfo = memoize(_getSafeInfo, { maxAge: 2 * 60 * 1000 });

export default class ReflexerWithdrawAction extends RecipeAction {
  static prettyName = 'Withdraw collateral from Safe';

  static protocol = 'reflexer';

  static protocolPrettyName = 'Reflexer';

  static description = 'Withdraws collateral from the selected Safe.';

  constructor(safeId = 0, amount = '', to = 'wallet') {
    super();
    this.inputs = [
      new CdpId('Safe ID', safeId, 'reflexer'),
      new Amount('Amount', amount),
      new Source('To', to, true),
    ];
    this.output = new Amount('output', 0);
    this.asset = '';
  }

  async getAfterValues(_balances = {}, returnValues = [], actions = [], _positions = {}, getState) {
    const positions = { ..._positions };
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const safeId = args[0];
    if (!positions[`reflexer_${safeId}`]) positions[`reflexer_${safeId}`] = getState().reflexer.safes[safeId];
    let amount = args[1];
    if (amount === 'All available') amount = positions[`reflexer_${safeId}`]?.collateral || '0';
    const { afterPosition, balances, returnValue } = await withdrawAfterValues(
      {
        amount,
        to: args[2],
      },
      {
        vault: positions[`reflexer_${safeId}`],
        assets: getState().assets,
        account: getState().general.account,
        proxyAddress: getState().maker.proxyAddress,
      },
      _balances,
    );
    positions[`reflexer_${safeId}`] = afterPosition;
    console.log(positions);
    this.output.value = returnValue;
    return { returnValue: this.output, balances, positions };
  }


  async toDfsAction(getState) {
    const {
      general: { account },
      maker: { proxyAddress },
      recipeCreator: { actionCalls, actions },
      reflexer: { safes },
    } = getState();
    const args = this.mapReturnValuesToArgs(actionCalls.map(a => a.returnValue), actions);
    const positions = actionCalls[actionCalls.length - 1]?.positions || {};
    const to = this.inputs[2].value === 'wallet' ? account : proxyAddress;
    const safe = positions[`reflexer_${args[0]}`] || await getSafeInfo(safes[args[0]]);
    const amount = this.inputs[1].value === 'All available' ? MAXUINT : assetAmountInWeiIgnorePointer(this.inputs[1].value, safe.asset);
    return new dfs.actions.reflexer.ReflexerWithdrawAction(this.inputs[0].value, amount, getRaiIlkInfo(safe.collType).join, to);
  }

  static getIcon() {
    return WithdrawIcon;
  }

  _getPrettyName(actionCalls, actions) {
    const args = this.mapReturnValuesToArgs(actionCalls.map(a => a.returnValue), actions);
    const positions = actionCalls[actionCalls.length - 1]?.positions;
    const safe = positions?.[`reflexer_${args[0]}`];
    if (!safe) return this.constructor.prettyName;
    return `Withdraw ${args[1] === 'All available' ? 'all' : formatNumber(args[1])} ${safe?.asset} from Safe #${args[0]}`;
  }

  setAsset(asset) {
    this.asset = asset;
  }

  getAsset() {
    return this.asset;
  }
}
