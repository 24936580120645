import { assetAmountInWei } from '@defisaver/tokens';
import dfs from '@defisaver/sdk';
import Dec from 'decimal.js';
import cloneDeep from 'lodash/cloneDeep';
import RecipeAction from '../RecipeAction';
import {
  Amount, AssetAmount, Source,
} from '../../components/Recipes/RecipeCreator/inputTypes';
import {
  changeBalance, getAmountDfsAction,
  getAmount,
  getSourceAddress,
} from '../../services/recipeCreator/recipeActionUtils';
import SupplyIcon from '../recipeIcons/Supply.svg';
import { getLpEstimate } from '../../services/curveServices/curveService';
import { getWeiAmountForDecimals } from '../../services/utils';
import { setSlippagePercent } from '../../services/exchangeServiceCommon';
import { DEFAULT_SLIPPAGE_PERCENT } from '../../constants/general';

export default class CurveStethPoolSupplyAction extends RecipeAction {
  static prettyName = 'Supply assets to Curve';

  static protocol = 'curve';

  static protocolPrettyName = 'Curve';

  static description = 'Deposit assets into Curve ETHSTETH pool.';

  constructor(from = 'wallet', to = 'recipe', ethAmount = '0', stEthAmount = '0') {
    super();
    this.inputs = [
      new Source('Source', from),
      new Source('Source', to),
      new Amount('ETHAmount', ethAmount),
      new Amount('stETHAmount', stEthAmount),
    ];
    this.output = new AssetAmount('output', 0, 'steCRV');
  }

  async getAfterValues(_balances = {}, returnValues = [], actions = [], positions = {}, getState) {
    const balances = cloneDeep(_balances);
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const from = args[0];
    const to = args[1];
    const fromAddress = getSourceAddress(from, getState);
    const toAddress = getSourceAddress(from, getState);
    const ethAmount = await getAmount(balances, from, 'WETH', args[2], fromAddress, '', getState);
    const stEthAmount = await getAmount(balances, from, 'stETH', args[3], fromAddress, '', getState);
    const steCrvAmount = await getLpEstimate(assetAmountInWei(ethAmount, 'ETH'), assetAmountInWei(stEthAmount, 'stETH'));
    const steCrvAmountSlippage = setSlippagePercent(DEFAULT_SLIPPAGE_PERCENT, steCrvAmount);
    await changeBalance(balances, from, 'WETH', new Dec(ethAmount).mul('-1').toString(), fromAddress);
    await changeBalance(balances, from, 'stETH', new Dec(stEthAmount).mul('-1').toString(), fromAddress);
    await changeBalance(balances, to, 'steCRV', steCrvAmountSlippage, toAddress);
    this.output.value = steCrvAmountSlippage;
    return { returnValue: this.output, balances, positions };
  }

  async toDfsAction(getState, actions, returnValues) {
    const ethAmount = getAmountDfsAction(this.inputs[2].value, 'WETH');
    const stEthAmount = getAmountDfsAction(this.inputs[3].value, 'stETH');
    const steCrvAmount = getWeiAmountForDecimals(this.output.value, 18);
    const from = getSourceAddress(this.inputs[0].value, getState);
    const to = getSourceAddress(this.inputs[1].value, getState);
    return new dfs.actions.curve.CurveStethPoolDepositAction(from, to, [ethAmount, stEthAmount], steCrvAmount);
  }

  static getIcon() {
    return SupplyIcon;
  }

  _getPrettyName(actionCalls, actions) {
    return 'Curve Supply';
  }
}
