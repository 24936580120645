/* eslint-disable */
import React from 'react';

export default function ArgentIcon(props) {
  return (
    <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.7771 0H6.36568C6.21824 0 6.10015 0.117158 6.09693 0.262785C6.00782 4.35621 3.84033 8.24134 0.109648 10.9932C-0.00879494 11.0805 -0.0357789 11.2447 0.0508138 11.3629L2.6319 14.8892C2.71971 15.0092 2.89079 15.0363 3.01123 14.9481C5.34391 13.2387 7.22021 11.1765 8.57141 8.89084C9.92261 11.1765 11.799 13.2387 14.1317 14.9481C14.252 15.0363 14.4231 15.0092 14.5111 14.8892L17.0921 11.3629C17.1786 11.2447 17.1516 11.0805 17.0333 10.9932C13.3025 8.24134 11.135 4.35621 11.046 0.262785C11.0428 0.117158 10.9246 0 10.7771 0Z" fill="#FF875B"/>
    </svg>
  );
}
