import t from 'translate';
import dfs from '@defisaver/sdk';
import Dec from 'decimal.js';
import {
  assetAmountInEth, assetAmountInWei, getAssetInfo, getAssetInfoByAddress,
} from '@defisaver/tokens';
import { getAssetBalance } from 'services/assetsService';
import { calculateBorrowingAssetLimit, calculateNetApy } from 'services/moneymarketCommonService';
import {
  ethToWeth, isWalletTypeProxy, numberWithCommas, requireAddress,
} from 'services/utils';
import { callViaProxy } from 'services/contractCallService';
import callTx from 'services/txService';
import {
  AaveIncentivesControllerAddress, AaveIncentivesControllerContract, AaveLoanInfoV2Contract, AaveWETHAdapterAddress,
  AaveWETHAdapterContract, WETHIStableDebtTokenContract, WETHIVariableDebtTokenContract,
} from '../contractRegistryService';

import { isSubscribedToMonitor } from './aaveSaverService';
import { getAggregatedPositionData } from './aaveManageService';
import { CONTINUOUS_FEE_MULTIPLIER, MAXUINT } from '../../constants/general';
import config from '../../config/config.json';
import { aggregate } from '../ethService';
import { REWARDABLE_ASSETS } from '../../constants/aaveMarkets';
import { getStETHApr } from '../lidoService';

const AAVE_REFERRAL_CODE = 64;

export const EMPTY_AAVE_DATA = {
  usedAssets: {},
  suppliedUsd: '0',
  borrowedUsd: '0',
  borrowLimitUsd: '0',
  leftToBorrowUsd: '0',
  ratio: '0',
  minRatio: '0',
  netApy: '0',
  incentiveUsd: '0',
  totalInterestUsd: '0',
  isSubscribedToAutomation: false,
  automationResubscribeRequired: false,
  eModeCategory: 0,
};

/**
 * Get all assets available in Aave v2 for specific market
 *
 * @param market
 * @returns {Promise<*>}
 */

export const getAaveMarketAssets = async (market) => {
  const contract = new window._web3.eth.Contract(config[market.protocolData].abi, market.protocolDataAddress);

  const res = await contract.methods.getAllReservesTokens().call();

  return res.map(asset => ({
    symbol: asset[0],
    assetAddress: asset[1],
  }));
};

// https://app.aave.com/governance/34-QmePHWTUk1bgh7RqpgtSpBj3kBEPEsLoPhiYjCen4y1obL
function _recalculateEmission(_emission) {
  const emission = new Dec(_emission).times(1000000000000000000).toString();
  const PROPOSAL_EXE_TIMESTAMP = 1631718350;
  const DISTRIBUTION_DURATION = 7776000;
  const INITIAL_DISTRIBUTION_TIMESTAMP = 1629797400;

  // recalculatedEmission is per second
  const recalculatedEmission = new Dec(new Dec(emission).times(90))
    .dividedBy(
      new Dec(DISTRIBUTION_DURATION).minus(new Dec(PROPOSAL_EXE_TIMESTAMP).minus(INITIAL_DISTRIBUTION_TIMESTAMP)),
    ).toString();
  return new Dec(recalculatedEmission).dividedBy(1000000000000000000).times(86400).toString();
}


/**
 * Get token data for a specific market
 *
 * @param selectedMarket
 * @returns {Promise<*>}
 */
export const getAaveMarketsDataV2 = async (selectedMarket) => {
  try {
    const _addresses = selectedMarket.assets.map(a => getAssetInfo(ethToWeth(a)).address);
    const loanInfoContract = AaveLoanInfoV2Contract();
    const marketAddress = selectedMarket.providerAddress;
    const loanInfo = await loanInfoContract.methods.getFullTokensInfo(marketAddress, _addresses).call();
    const markets = loanInfo
      .map((market, i) => ({
        symbol: selectedMarket.assets[i],
        underlyingTokenAddress: market.underlyingTokenAddress,
        supplyRate: Dec(market.supplyRate.toString()).div(1e25),
        borrowRate: Dec(market.borrowRateVariable.toString()).div(1e25),
        borrowRateStable: Dec(market.borrowRateStable.toString()).div(1e25),
        collateralFactor: Dec(market.collateralFactor.toString()).div(10000).toString(),
        liquidationRatio: Dec(market.liquidationRatio.toString()).div(10000).toString(),
        marketLiquidity: assetAmountInEth(Dec(market.totalSupply.toString())
          .sub(market.totalBorrow.toString())
          .toString(), selectedMarket.assets[i]),
        utilization: Dec(market.totalBorrow.toString())
          .div(Dec(market.totalSupply.toString()))
          .times(100)
          .toString(),
        usageAsCollateralEnabled: market.usageAsCollateralEnabled,
        totalSupply: assetAmountInEth(market.totalSupply.toString(), selectedMarket.assets[i]),
        canBeBorrowed: market.borrowinEnabled,
        canBeSupplied: true,
        disabledStableBorrowing: !market.stableBorrowRateEnabled,
        totalBorrow: assetAmountInEth(market.totalBorrow.toString(), selectedMarket.assets[i]),
        totalBorrowVar: assetAmountInEth(market.totalBorrowVar.toString(), selectedMarket.assets[i]),
        priceInEth: market.price.toString() / 1e18,
        supplyAaveAprPerAsset: 0,
        borrowAaveAprPerAsset: 0,
        incentiveSupplyToken: 'AAVE',
        incentiveBorrowToken: 'AAVE',
      }));
    const incentivizedAssets = [];

    const thirdIncentivizedAssets = [];

    const DAILY_EMISSIONS = {
      DAI: 223.1,
      GUSD: 0,
      SUSD: 5.97,
      TUSD: 16.63,
      USDC: 493.37,
      USDP: 2.44,
      USDT: 180.1,
      BAL: 0.42,
      ETH: 80.61,
      LINK: 23.59,
      MKR: 9.5,
      RAI: 2.55,
      UNI: 0,
      WBTC: 21.79,
      xSUSHI: 2.48,
      YFI: 2,
      BUSD: 5.5,
      FRAX: 2.16,
      CRV: 3.85,
      DPI: 1.94,
      FEI: 0,
    };
    const aavePrice = markets.find(m => m.symbol === 'AAVE').priceInEth;
    markets.forEach((market) => {
      /* eslint-disable no-param-reassign */
      const poolShareUsd = DAILY_EMISSIONS[market.symbol] * 365;
      if (incentivizedAssets.includes(market.symbol)) {
        market.incentiveSupplyApy = 100 * poolShareUsd / market.totalSupply * aavePrice / market.priceInEth;
        market.incentiveBorrowApy = 0;
      }
      if (thirdIncentivizedAssets.includes(market.symbol)) {
        market.incentiveSupplyApy = 33.33 * poolShareUsd / market.totalSupply * aavePrice / market.priceInEth;
        market.incentiveBorrowApy = 66.66 * poolShareUsd / market.totalBorrowVar * aavePrice / market.priceInEth;
      }
      /* eslint-enable no-param-reassign */
    });
    const stEthMarket = markets.find(({ symbol }) => symbol === 'stETH');
    stEthMarket.incentiveSupplyApy = await getStETHApr();
    stEthMarket.incentiveSupplyToken = 'stETH';
    return markets;
  } catch (err) {
    console.error(err.message);
  }
};


/**
 * Fetch all aave data for address in a market
 *
 * @param address {string}
 * @param assetsData {object} getState().aaveManage[getState().aaveManage.selectedMarket].assetsData
 * @param assets {object} getState().assets
 * @param market
 * @param walletType
 * @returns {Promise<{borrowedUsd: *, suppliedUsd: *, usedAssets}>}
 */
export const getAaveAccountDataV2 = async (address, assetsData, assets, market, walletType = 'proxy') => {
  if (!address) return null;

  let payload = {
    ...EMPTY_AAVE_DATA,
    lastUpdated: Date.now(),
  };

  const loanInfoContract = AaveLoanInfoV2Contract();
  const marketAddress = market.providerAddress;
  const _addresses = market.assets.map(a => getAssetInfo(ethToWeth(a)).address);
  const loanInfo = await loanInfoContract.methods.getTokenBalances(marketAddress, address, _addresses).call();
  const usedAssets = {};
  loanInfo.forEach((_tokenInfo, i) => {
    const asset = market.assets[i];
    const tokenInfo = { ..._tokenInfo };

    // known bug: stETH leaves 1 wei on every transfer
    if (asset === 'stETH' && tokenInfo.balance.toString() === '1') tokenInfo.balance = '0';

    const isSupplied = tokenInfo.balance.toString() !== '0';
    const isBorrowed = tokenInfo.borrowsStable.toString() !== '0' || tokenInfo.borrowsVariable.toString() !== '0';
    if (!isSupplied && !isBorrowed) return;

    const supplied = assetAmountInEth(tokenInfo.balance.toString(), asset);
    const borrowedStable = assetAmountInEth(tokenInfo.borrowsStable.toString(), asset);
    const borrowedVariable = assetAmountInEth(tokenInfo.borrowsVariable.toString(), asset);
    const enabledAsCollateral = assetsData[asset].usageAsCollateralEnabled ? tokenInfo.enabledAsCollateral : false;
    let interestMode;
    if (borrowedVariable === '0' && borrowedStable !== '0') {
      interestMode = '1';
    } else if (borrowedVariable !== '0' && borrowedStable === '0') {
      interestMode = '2';
    } else {
      interestMode = 'both';
    }
    if (!usedAssets[asset]) usedAssets[asset] = {};
    usedAssets[asset] = {
      ...usedAssets[asset],
      symbol: asset,
      supplied,
      suppliedUsd: new Dec(supplied).mul(assets[asset].aavePrice).toString(),
      isSupplied,
      earned: '0',
      earnedUsd: '0',
      collateral: enabledAsCollateral,
      stableBorrowRate: new Dec(tokenInfo.stableBorrowRate).div(1e25),
      borrowedStable,
      borrowedVariable,
      borrowedUsdStable: new Dec(borrowedStable).mul(assets[asset].aavePrice).toString(),
      borrowedUsdVariable: new Dec(borrowedVariable).mul(assets[asset].aavePrice).toString(),
      borrowed: new Dec(borrowedStable).add(borrowedVariable).toString(),
      borrowedUsd: new Dec(new Dec(borrowedVariable).add(borrowedStable)).mul(assets[asset].aavePrice).toString(),
      isBorrowed,
      debt: '0',
      debtUsd: '0',
      interestMode,
    };
  });

  payload = {
    ...payload,
    usedAssets,
    ...getAggregatedPositionData(usedAssets, assetsData, assets),
  };

  // payload.leftToBorrowUsd = new Dec(payload.borrowLimitUsd).sub(payload.borrowedUsd).toString();

  payload.ratio = payload.borrowedUsd && payload.borrowedUsd !== '0'
    ? Dec(payload.borrowLimitUsd).div(payload.borrowedUsd).mul(100).toString()
    : '0';
  payload.minRatio = '100';
  payload.collRatio = payload.borrowedUsd && payload.borrowedUsd !== '0'
    ? Dec(payload.suppliedCollateralUsd).div(payload.borrowedUsd).mul(100).toString()
    : '0';

  // Calculate borrow limits per asset
  Object.values(payload.usedAssets).forEach((item) => {
    if (item.isBorrowed) {
      // eslint-disable-next-line no-param-reassign
      item.stableLimit = calculateBorrowingAssetLimit(item.borrowedUsdStable, payload.borrowLimitUsd);
      // eslint-disable-next-line no-param-reassign
      item.variableLimit = calculateBorrowingAssetLimit(item.borrowedUsdVariable, payload.borrowLimitUsd);
      // eslint-disable-next-line no-param-reassign
      item.limit = calculateBorrowingAssetLimit(item.borrowedUsd, payload.borrowLimitUsd);
    }
  });

  const { netApy, incentiveUsd, totalInterestUsd } = calculateNetApy(usedAssets, assetsData);
  payload.netApy = netApy;
  payload.incentiveUsd = incentiveUsd;
  payload.totalInterestUsd = totalInterestUsd;

  payload.isSubscribedToAutomation = false;
  payload.automationResubscribeRequired = false;
  if (walletType === 'proxy') {
    const monitorData = await isSubscribedToMonitor(address, 'v2default');
    payload.isSubscribedToAutomation = monitorData.isSubscribedToAutomation;
    payload.automationResubscribeRequired = monitorData.automationResubscribeRequired;
  }

  return payload;
};

/**
 * Calculate max borrow
 *
 * @param account
 * @param asset
 * @param assetPrice
 * @param bufferPercent {Number}
 * @param leftToBorrowUsd {String} Left to borrow for user's position
 * @param leftToBorrowGlobally {String | undefined} Left to borrow up to the global borrow cap for the asset
 * @returns {String}
 */
export const getMaxBorrowV2 = (account, asset, assetPrice = '0', bufferPercent = 0, leftToBorrowUsd, leftToBorrowGlobally) => {
  const borrowingPowerInToken = new Dec(leftToBorrowUsd).div(assetPrice).toString();

  let maxBorrow = new Dec(borrowingPowerInToken).mul((100 - bufferPercent) / 100)
    .toDP(getAssetInfo(asset).decimals).toString();

  if (leftToBorrowGlobally) maxBorrow = Dec.min(leftToBorrowGlobally, maxBorrow).toString();

  return Dec.max(maxBorrow, '0').toString();
};

/**
 * Calls the supply action on the compound contract
 *
 * @param accountType {String}
 * @param path {String}
 * @param sendTxFunc {Function}
 * @param account {String}
 * @param walletType {Object}
 * @param _amount {String}
 * @param asset {String}
 * @param market
 * @return {Promise<any>}
 */
export const supplyV2 = async (
  accountType, path, sendTxFunc, account, walletType, _amount, asset, market,
) => {
  const isProxy = isWalletTypeProxy(walletType);
  const value = assetAmountInWei(_amount, asset);
  if (asset === 'ETH' && !isProxy) {
    const wethAdapter = AaveWETHAdapterContract();
    const funcParams = [account, AAVE_REFERRAL_CODE];
    const txParams = {
      from: account,
      value,
    };
    return callTx(accountType, path, sendTxFunc, wethAdapter, 'depositETH', funcParams, txParams, 'depositETH');
  }
  const assetAddress = getAssetInfo(asset).address;
  const lendingPool = new window._web3.eth.Contract(config[market.lendingPool].abi, market.lendingPoolAddress);
  const marketAddress = market.providerAddress;

  const txParams = {
    from: account,
    value: asset === 'ETH' ? value : 0,
  };

  const funcParams = [];
  if (isProxy) funcParams.push(marketAddress);

  funcParams.push(assetAddress, value);

  if (!isProxy) funcParams.push(account, AAVE_REFERRAL_CODE);


  return callTx(accountType, path, sendTxFunc, lendingPool, 'deposit', funcParams, txParams, 'deposit');
};

/**
 * Calls the borrow action on the compound contract
 *
 * @param accountType {String}
 * @param path {String}
 * @param sendTxFunc {Function}
 * @param account {String}
 * @param walletType {Object}
 * @param _amount {String}
 * @param interestRateValue {Number}
 * @param asset {String} underlying asset symbol
 * @param market
 * @return {Promise<any>}
 */
export const borrowV2 = async (
  accountType, path, sendTxFunc, account, walletType, _amount, interestRateValue, asset, market,
) => {
  const _address = getAssetInfo(ethToWeth(asset)).address;
  const loanInfoContract = AaveLoanInfoV2Contract();
  const marketAddress = market.providerAddress;
  const loanInfo = await loanInfoContract.methods.getFullTokensInfo(marketAddress, [_address]).call();
  const availableLiq = assetAmountInEth(loanInfo[0].availableLiquidity.toString(), asset);
  const liqAfterBorrow = Dec(availableLiq).minus(_amount).toString();
  if (Dec(liqAfterBorrow).lt('0')) {
    throw new Error(`Not enough liquidity. The maximum amount available for borrowing is ${numberWithCommas(Dec.floor(availableLiq).toString())} ${asset}`);
  }
  const value = assetAmountInWei(_amount, asset);
  if (asset === 'ETH') {
    const wethAdapter = AaveWETHAdapterContract();
    const funcParams = [value, interestRateValue, AAVE_REFERRAL_CODE];
    const txParams = {
      from: account,
    };
    return callTx(accountType, path, sendTxFunc, wethAdapter, 'borrowETH', funcParams, txParams, 'borrowETH');
  }

  const assetAddress = getAssetInfo(asset).address;
  const lendingPool = new window._web3.eth.Contract(config[market.lendingPool].abi, market.lendingPoolAddress);


  const funcParams = [];
  funcParams.push(assetAddress, value, interestRateValue); // 3rd param is 2 for variable rate and 1 for fixed rate
  funcParams.push(AAVE_REFERRAL_CODE, account);


  return callTx(accountType, path, sendTxFunc, lendingPool, 'borrow', funcParams, { from: account }, 'borrow');
};

/**
 * Calls the repay action on the aave v2
 *
 * @param accountType {String}
 * @param path {String}
 * @param sendTxFunc {Function}
 * @param account {String}
 * @param walletType {Object}
 * @param _amount {String}
 * @param asset {String}
 * @param borrowed {String}
 * @param interestRate{Number}
 * @param market
 * @return {Promise<any>}
 */
export const paybackV2 = async (
  accountType, path, sendTxFunc, account, walletType, _amount, asset, borrowed, interestRate, market,
) => {
  let amount = assetAmountInWei(_amount, asset);
  const balance = await getAssetBalance(asset, account);
  let value = assetAmountInWei(new Dec(_amount).toString(), asset);
  if (asset === 'ETH') {
    const wethAdapter = AaveWETHAdapterContract();
    const funcParams = [amount, interestRate, account];
    const txParams = {
      from: account,
      value: amount,
    };

    return callTx(accountType, path, sendTxFunc, wethAdapter, 'repayETH', funcParams, txParams, 'repayETH');
  }
  const wholeDebt = new Dec(_amount).equals(borrowed) && new Dec(balance).gt(borrowed);

  if (wholeDebt) {
    // uint(-1)
    value = assetAmountInWei(Dec(_amount).mul(CONTINUOUS_FEE_MULTIPLIER).toString(), asset);
    amount = MAXUINT;
  }

  const assetAddress = getAssetInfo(asset).address;

  const lendingPool = new window._web3.eth.Contract(config[market.lendingPool].abi, market.lendingPoolAddress);

  const txParams = {
    from: account,
    value: asset === 'ETH' ? value : 0,
  };

  const funcParams = [];
  funcParams.push(assetAddress, amount, interestRate);
  funcParams.push(account);

  return callTx(accountType, path, sendTxFunc, lendingPool, 'repay', funcParams, txParams, 'repay');
};

/**
 * Calls the redeemUnderlying action on the compound contract
 *
 * @param accountType {String}
 * @param path {String}
 * @param sendTxFunc {Function}
 * @param account {String}
 * @param walletType {Object}
 * @param _amount {String}
 * @param asset {String}
 * @param supplied {String}
 * @param market
 * @return {Promise<any>}
 */
export const withdrawV2 = async (
  accountType, path, sendTxFunc, account, walletType, _amount, asset, supplied, market,
) => {
  let amount = assetAmountInWei(_amount, asset);

  const wholeSupply = new Dec(_amount.toString()).equals(supplied);
  if (wholeSupply) amount = MAXUINT;

  if (asset === 'ETH') {
    const wethAdapter = AaveWETHAdapterContract();
    const funcParams = [amount, account];
    const txParams = { from: account };
    return callTx(accountType, path, sendTxFunc, wethAdapter, 'withdrawETH', funcParams, txParams, 'withdrawETH');
  }

  const assetAddress = getAssetInfo(asset).address;

  const lendingPool = new window._web3.eth.Contract(config[market.lendingPool].abi, market.lendingPoolAddress);

  const funcParams = [];
  funcParams.push(assetAddress, amount);
  funcParams.push(account);

  return callTx(accountType, path, sendTxFunc, lendingPool, 'withdraw', funcParams, { from: account }, 'withdraw');
};

/**
 * Swap interest rate for borrowing asset
 *
 * @param accountType
 * @param path
 * @param sendTxFunc
 * @param account
 * @param proxyAddress
 * @param walletType
 * @param assetAddress
 * @param intRate
 * @param market
 * @returns {Promise<*>}
 */
export const swapInterestRateModeV2 = async (
  accountType, path, sendTxFunc, account, proxyAddress, walletType, assetAddress, intRate, market,
) => {
  const lendingPool = new window._web3.eth.Contract(config[market.lendingPool].abi, market.lendingPoolAddress);
  const marketAddress = market.providerAddress;
  const isProxy = isWalletTypeProxy(walletType);
  const funcName = 'swapBorrowRateMode';
  const params = [];
  if (isProxy) params.push(marketAddress);
  params.push(assetAddress, intRate);


  return (!isWalletTypeProxy(walletType)
    ? callTx(accountType, path, sendTxFunc, lendingPool, funcName, params, { from: account }, funcName)
    : callViaProxy(accountType, sendTxFunc, proxyAddress, account, 'AaveBasicProxyV2', funcName, params, 0));
};

/**
 * Enable or disable asset as collateral
 *
 * @param accountType
 * @param path
 * @param sendTxFunc
 * @param account
 * @param proxyAddress
 * @param walletType
 * @param assetAddress
 * @param enable
 * @param market
 * @returns {Promise<*>}
 */
export const setAssetAsCollateralV2 = async (accountType, path, sendTxFunc, account, proxyAddress, walletType, assetAddress, enable, market) => {
  const lendingPool = new window._web3.eth.Contract(config[market.lendingPool].abi, market.lendingPoolAddress);
  const marketAddress = market.providerAddress;
  const isProxy = isWalletTypeProxy(walletType);
  const funcName = 'setUserUseReserveAsCollateral';
  const params = [];
  if (isProxy) params.push(marketAddress);
  params.push(assetAddress, enable);
  return (!isWalletTypeProxy(walletType)
    ? callTx(accountType, path, sendTxFunc, lendingPool, funcName, params, { from: account }, funcName)
    : callViaProxy(accountType, sendTxFunc, proxyAddress, account, 'AaveBasicProxyV2', funcName, params, 0));
};

/**
 * Approve WETH adapter
 *
 * @param accountType
 * @param path
 * @param sendTxFunc
 * @param account
 * @param stable
 * @returns {Promise<void>}
 */

export const approveWethAdapter = async (accountType, path, sendTxFunc, account, stable) => {
  const contract = stable ? WETHIStableDebtTokenContract() : WETHIVariableDebtTokenContract();
  const allowance = await contract.methods.borrowAllowance(account, AaveWETHAdapterAddress).call();
  if (allowance.toString() === '0') {
    const funcParams = [AaveWETHAdapterAddress, MAXUINT];
    return callTx(accountType, path, sendTxFunc, contract, 'approveDelegation', funcParams, { from: account }, 'approveDelegation');
  }
};

export const addApproveWethAdapterIfNeeded = async (account, stable, txToExecute, approveFunctionObject) => {
  const contract = stable ? WETHIStableDebtTokenContract() : WETHIVariableDebtTokenContract();
  const allowance = await contract.methods.borrowAllowance(account, AaveWETHAdapterAddress).call();
  if (allowance.toString() === '0') {
    txToExecute.push(approveFunctionObject);
    return true;
  }
  return false;
};
/**
 * Get new aToken address for specific token
 *
 * @param tokenAddress
 * @param market
 * @returns {Promise<*>}
 */

export const getATokenAddress = async (tokenAddress, market) => {
  const contract = new window._web3.eth.Contract(config[market.protocolData].abi, market.protocolDataAddress);
  try {
    const tokenAddresses = await contract.methods.getReserveTokensAddresses(tokenAddress).call();
    return tokenAddresses.aTokenAddress;
  } catch (err) {
    throw new Error(err.message);
  }
};
/**
 * Get stable or variable debt token address for specific token
 *
 * @param tokenAddress
 * @param market
 * @param isStable {boolean}
 * @returns {Promise<*>}
 */

export const getDebtTokenAddress = async (tokenAddress, market, isStable) => {
  const contract = new window._web3.eth.Contract(config[market.protocolData].abi, market.protocolDataAddress);
  try {
    const tokenAddresses = await contract.methods.getReserveTokensAddresses(tokenAddress).call();
    if (isStable) {
      return tokenAddresses.stableDebtTokenAddress;
    }
    return tokenAddresses.variableDebtTokenAddress;
  } catch (err) {
    throw new Error(err.message);
  }
};

const getAssetsWithRedeemableBalance = async (address) => {
  const multicallCallsObject = [{
    target: AaveIncentivesControllerAddress,
    call: ['getUserUnclaimedRewards(address) (uint256)', address],
    returns: [
      ['base', val => assetAmountInEth(val, 'stkAAVE')],
    ],
  }];
  REWARDABLE_ASSETS.forEach(token => {
    multicallCallsObject.push({
      target: AaveIncentivesControllerAddress,
      call: ['getRewardsBalance(address[],address)(uint256)', [token], address],
      returns: [
        [token, val => assetAmountInEth(val, 'stkAAVE')],
      ],
    });
  });
  const multiRes = await aggregate(multicallCallsObject);

  // This debt is accrued in the contract memory, and is redeemed automatically in all claimRewards.
  // It is also added to all the getRewardsBalance results, so it should be ignored when filtering assets
  const base = multiRes.results.transformed.base;

  return Object.keys(multiRes.results.transformed)
    .filter(t => t !== 'base' && new Dec(multiRes.results.transformed[t]).gt(base));
};

export const claimStkAaveRewards = async (accountType, path, sendTxFunc, account, proxyAddress, from) => {
  const contract = AaveIncentivesControllerContract();
  const funcName = 'claimRewards';
  const address = from === 'account' ? account : proxyAddress;
  const assets = await getAssetsWithRedeemableBalance(address);
  const params = [assets, MAXUINT, account];
  if (from === 'account') return callTx(accountType, path, sendTxFunc, contract, funcName, params, { from: account }, funcName);
  return callViaProxy(accountType, sendTxFunc, proxyAddress, account, 'AaveClaimProxy', funcName, params);
};

export const getAaveV2Action = async (action, market, inputAmount, tokenAddr, rateMode, account, proxyAddress, usedAssets) => {
  let instantiatedAction;
  const marketAddress = market.providerAddress;
  const asset = getAssetInfoByAddress(tokenAddr).symbol;
  const isAssetETH = asset === 'ETH';
  const _tokenAddr = isAssetETH ? getAssetInfo('WETH').address : tokenAddr;
  switch (action) {
    case 'collateral': {
      if (isAssetETH) {
        instantiatedAction = [
          new dfs.actions.basic.WrapEthAction(inputAmount),
          new dfs.actions.aave.AaveSupplyAction(marketAddress, _tokenAddr, inputAmount, proxyAddress, proxyAddress, true),
        ];
      } else {
        instantiatedAction = [new dfs.actions.aave.AaveSupplyAction(marketAddress, _tokenAddr, inputAmount, account, proxyAddress, true)];
      }
      break;
    }
    case 'borrow': {
      const loanInfo = (await AaveLoanInfoV2Contract().methods.getFullTokensInfo(marketAddress, [_tokenAddr]).call())[0];
      const liquidity = new Dec(loanInfo.totalSupply.toString()).sub(loanInfo.totalBorrow.toString());
      const liqAfterBorrow = new Dec(liquidity).sub(inputAmount);

      if (liqAfterBorrow.lt('0')) {
        throw new Error(
          t('compound.missing_liquidity',
            { '%assetAmount': `${numberWithCommas(Dec.floor(assetAmountInEth(liquidity, asset)).toString())} ${asset}` },
          ),
        );
      }
      instantiatedAction = [new dfs.actions.aave.AaveBorrowAction(marketAddress, _tokenAddr, inputAmount, rateMode, isAssetETH ? proxyAddress : account, proxyAddress)];
      if (isAssetETH) instantiatedAction.push(new dfs.actions.basic.UnwrapEthAction(inputAmount, account));
      break;
    }
    case 'withdraw': {
      const amount = assetAmountInWei(usedAssets[asset].supplied, asset) === inputAmount
        ? MAXUINT
        : inputAmount;
      if (isAssetETH) {
        instantiatedAction = [
          new dfs.actions.aave.AaveWithdrawAction(marketAddress, _tokenAddr, amount, proxyAddress),
          new dfs.actions.basic.UnwrapEthAction(amount, account),
        ];
      } else {
        instantiatedAction = [new dfs.actions.aave.AaveWithdrawAction(marketAddress, _tokenAddr, amount, account)];
      }
      break;
    }
    case 'payback': {
      const amountBorrowed = rateMode === 1 ? usedAssets[asset].borrowedStable : usedAssets[asset].borrowedVariable;
      const wholeDebt = assetAmountInWei(amountBorrowed, asset) === inputAmount;
      const amount = wholeDebt ? MAXUINT : inputAmount;
      if (isAssetETH) {
        // 1.0001x should cover the tx pending for ~3h at a 3% borrow APY
        const wrapAmount = wholeDebt ? new Dec(inputAmount).mul(1.0001).floor().toString() : inputAmount;
        instantiatedAction = [
          new dfs.actions.basic.WrapEthAction(wrapAmount),
          new dfs.actions.aave.AavePaybackAction(marketAddress, _tokenAddr, amount, rateMode, proxyAddress, proxyAddress),
          // Unwrap makes sense only for paybacks over 1.5mil at 100gw
          // and the tradeoff is potentially unexpected behavior, as it would have to unwrap uint(-1)
        ];
      } else {
        instantiatedAction = [new dfs.actions.aave.AavePaybackAction(marketAddress, _tokenAddr, amount, rateMode, account, proxyAddress)];
      }
      break;
    }
    default:
      throw new Error('Unknown action');
  }
  return instantiatedAction;
};

export const getAaveV2Recipe = async (
  primaryAction, primaryInput, secondaryAction, secondaryInput, primaryToken, primaryRateMode, secondaryToken, secondaryRateMode, market, account, proxyAddress, usedAssets,
) => {
  requireAddress(account);
  requireAddress(proxyAddress);
  const recipeActions =
    await Promise.all(
      [{
        market, action: primaryAction, input: primaryInput, token: primaryToken, rateMode: primaryRateMode,
      },
      {
        market, action: secondaryAction, input: secondaryInput, token: secondaryToken, rateMode: secondaryRateMode,
      }]
        .filter(a => a.action && a.action !== 'send')
        .map(action => getAaveV2Action(action.action, action.market, action.input, action.token, action.rateMode, account, proxyAddress, usedAssets))
        .flat(),
    );

  const name = 'recAaveV2DashAction';
  return new dfs.Recipe(name, recipeActions.flat());
};
