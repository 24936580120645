import t from 'translate';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ConnectWalletButtons from '../../Connect/ConnectWalletButtons/ConnectWalletButtons';
import './NotConnectedBlocker.scss';
import { connectViewOnlyAccount, getViewOnlyAccountsFromLs } from '../../../actions/accountActions';
import TooltipWrapper from '../../TooltipWrapper/TooltipWrapper';
import { setPageTitle } from '../../../services/utils';
import ConnectWallet from '../../Connect/ConnectWallet/ConnectWallet';
import TrackAddress from '../../Connect/TrackAddress/TrackAddress';
import ManageWallets from '../../Connect/ManageWallets/ManageWallets';

const NotConnectedBlocker = ({ connectViewOnlyAccount, getViewOnlyAccountsFromLs, connectingViewOnly }) => {
  useEffect(() => {
    setPageTitle(t('common.welcome'));
  }, []);
  return (
    <div className="not-connected-wrapper">
      <div className="left-panel">
        <ConnectWallet />
      </div>
      <div className="right-panel">
        <TrackAddress />
        <ManageWallets />
      </div>
    </div>
  );
};

NotConnectedBlocker.propTypes = {
  connectViewOnlyAccount: PropTypes.func.isRequired,
  getViewOnlyAccountsFromLs: PropTypes.func.isRequired,
  connectingViewOnly: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ general }) => ({
  connectingViewOnly: general.connectingViewOnly,
});
const mapDispatchToProps = {
  connectViewOnlyAccount,
  getViewOnlyAccountsFromLs,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotConnectedBlocker);
