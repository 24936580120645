import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { assetAmountInEth } from '@defisaver/tokens';
import ModalHeader from '../ModalHeader';
import ModalBody from '../ModalBody';
import { logOutFork } from '../../../actions/accountActions';
import { formatNumber } from '../../../services/utils';

const SimulationEndModal = ({
  closeModal, logOutFork, txs, ethPrice,
}) => {
  const totalEth = txs.reduce((sum, t) => sum + +assetAmountInEth(t.gasUsed * t.gasPrice * 1e9, 'ETH'), 0);
  return (
    <div className="action-modal-wrapper repay-modal-wrapper">
      <ModalHeader closeModal={closeModal} />

      <ModalBody>

        <div className="new-modal-top-wrapper">
          <h1>Exit simulation</h1>
        </div>

        <div className="new-modal-content-wrapper">
          <p>
            Ending the Simulation will clear your account&apos;s state and put you back on Mainnet.{' '}
            You can always create a new simulation but you won&apos;t be able to get back to this one again.
          </p>
          <p>
            You have made {txs.length} transactions in this simulated session, costing a total of {formatNumber(totalEth)} ETH (${formatNumber(totalEth * ethPrice)}) in transaction fees.
          </p>
        </div>
      </ModalBody>

      <div className="modal-controls">
        <button
          type="button"
          onClick={() => logOutFork()}
          className="button green"
        >
          End Simulation
        </button>
      </div>
    </div>
  );
};

SimulationEndModal.propTypes = {
  logOutFork: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  txs: PropTypes.array.isRequired,
  ethPrice: PropTypes.string.isRequired,
};

const mapStateToProps = ({ general, tx, assets }) => ({
  account: general.account,
  accountType: general.accountType,
  txs: tx.transactions,
  ethPrice: assets.ETH.marketPrice,
});

const mapDispatchToProps = {
  logOutFork,
};

export default connect(mapStateToProps, mapDispatchToProps)(SimulationEndModal);
