import { UniMulticallContract } from './contractRegistryService';

/**
 * Returns undefined for calls that failed
 *
 * @param calls {{target: string, params: Array, abiItem: any}[]}
 * @param [blockNumber] {string}
 * @returns {Promise<*[]>}
 */
export const multicall = async (calls, blockNumber = 'latest') => {
  const multicallContract = await UniMulticallContract();
  const formattedCalls = calls.map((call) => {
    const callData = window._web3.eth.abi.encodeFunctionCall(call.abiItem, call.params);
    return { callData, target: call.target || '0x0', gasLimit: 1e6 };
  });
  const callResult = await multicallContract.methods.multicall(formattedCalls.filter(item => item.target !== '0x0')).call({}, blockNumber);

  let formattedResult = [];

  callResult.returnData.forEach(([success, gasUsed, result], i) => {
    const formattedRes = result !== '0x'
      ? window._web3.eth.abi.decodeParameters(calls[i].abiItem.outputs, result)
      : undefined;
    formattedResult = [...formattedResult, formattedRes];
  });

  return formattedResult;
};
