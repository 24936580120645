import Dec from 'decimal.js';
import dfs from '@defisaver/sdk';
import {
  assetAmountInEth, assetAmountInWei, getAssetInfo, getAssetInfoByAddress,
} from '@defisaver/tokens';
import cloneDeep from 'lodash/cloneDeep';
import RecipeAction from '../RecipeAction';
import {
  Amount, Asset, AssetAmount, Source,
} from '../../components/Recipes/RecipeCreator/inputTypes';
import SupplyIcon from '../recipeIcons/Supply.svg';
import { formatNumber } from '../../services/utils';
import {
  changeBalance,
  getAmount,
  getAmountDfsAction,
  getSourceAddress,
} from '../../services/recipeCreator/recipeActionUtils';
import { MStableSaveGetter } from '../../services/savingsServices/protocols/MStableSaveGetters';
import {
  MStableImUsdTokenAddress,
  MStableImUsdVaultAddress,
  MStableMUsdTokenAddress,
} from '../../services/contractRegistryService';
import { MStableVaultGetter } from '../../services/savingsServices/protocols/MStableVaultGetters';

export default class MStableSaveSupplyAction extends RecipeAction {
  static prettyName = 'Supply to mStable Save';

  static protocol = 'mstable';

  static protocolPrettyName = 'mStable';

  static description = 'Deposits selected asset into mStable Save';

  constructor(asset = 'DAI', from = 'wallet', amount = '0', to = 'recipe', isVault = false) {
    super();
    this.isVault = isVault;
    this.inputs = [
      new Asset('Asset', asset, ['DAI', 'USDC', 'USDT', 'mUSD', ...(this.isVault ? ['imUSD'] : [])].map(symbol => getAssetInfo(symbol))),
      new Source('From', from),
      new Amount('Amount', amount),
    ];
    this.output = new AssetAmount('output', 0, 'imUSD');
  }

  static getIcon() {
    return SupplyIcon;
  }

  _getPrettyName(actionCalls, actions) {
    const args = this.mapReturnValuesToArgs(actionCalls.map(a => a.returnValue), actions);
    return `Supply ${args[2] === 'All available' ? 'all' : formatNumber(args[2])} ${this.inputs[0].asset} to mStable ${this.isVault ? 'Vault' : 'Save'}`;
  }

  async getAfterValues(_balances = {}, returnValues = [], actions = [], _positions = {}, getState) {
    const positions = { ..._positions };
    const balances = cloneDeep(_balances);
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const from = args[1];
    const to = 'recipe';
    const fromAddress = getSourceAddress(from, getState);
    const toAddress = getSourceAddress(to, getState);
    const asset = getAssetInfoByAddress(args[0]);

    if (this.isVault && !positions.mstableVault) positions.mstableVault = assetAmountInEth(await MStableVaultGetter._getSuppliedImUsd(toAddress), 'imUSD');
    const assetAmount = await getAmount(balances, from, asset.symbol, args[2], fromAddress, '', getState);
    let amount = assetAmount;

    if (asset.symbol !== 'imUSD') {
      // converting to imUSD, that is output of supply
      if (asset.symbol !== 'mUSD') {
        amount = await MStableSaveGetter._assetToMUsd(asset.address, assetAmountInWei(amount, asset.symbol));
      }
      amount = assetAmountInEth(await MStableSaveGetter._mUsdToImUsd(amount, 'mUSD'), 'imUSD');
    }

    await changeBalance(balances, from, asset.symbol, new Dec(assetAmount).mul('-1'), fromAddress, '');
    if (!this.isVault) await changeBalance(balances, to, 'imUSD', amount, toAddress, '');
    else positions.mstableVault = new Dec(positions.mstableVault).add(amount).toString();
    this.output.value = amount;
    return { returnValue: this.output, balances, positions };
  }

  async toDfsAction(getState, actions, returnValues, positions, actionBeforeState) {
    const asset = getAssetInfo(this.inputs[0].asset);
    const assetAmount = getAmountDfsAction(this.inputs[2].value, asset.symbol);
    const from = getSourceAddress(this.inputs[1].value, getState);
    const to = getSourceAddress('recipe', getState);
    let minOut;
    if (asset.symbol === 'imUSD') {
      minOut = 0;
    } else if (asset.symbol === 'mUSD') {
      minOut = assetAmount;
    } else {
      const args = this.mapReturnValuesToArgs(returnValues, actions);
      const assetAmountResolved = await getAmount(actionBeforeState?.balances, args[1], asset.symbol, args[2], from, '', getState);
      minOut = await MStableSaveGetter._assetToMUsd(asset.address, assetAmountInWei(assetAmountResolved, asset.symbol));
    }
    console.log(assetAmount, minOut);
    const assetPair = MStableSaveGetter._getAssetPair(asset, this.isVault);
    return new dfs.actions.mstable.MStableDepositAction(asset.address, MStableMUsdTokenAddress, MStableImUsdTokenAddress, MStableImUsdVaultAddress, from, to, assetAmount, MStableSaveGetter._setMinoutSlippage(minOut), assetPair);
  }
}
