import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import t from 'translate';
import TooltipWrapper from '../../TooltipWrapper/TooltipWrapper';
import { connectViewOnlyAccount, getViewOnlyAccountsFromLs } from '../../../actions/accountActions';

import './TrackAddress.scss';

import LinkIcon from './link-icon.svg';
import TrackAddressIcon from '../../Decorative/ConnectionWalletIcons/TrackAddressIcon';

function TrackAddress() {
  const [value, setValue] = useState('');
  const [valid, setValid] = useState(false);
  const [addressOptions, setAddressOptions] = useState([]);
  const dispatch = useDispatch();
  const connectingViewOnly = useSelector(({ general }) => general.connectingViewOnly);
  useEffect(() => {
    setAddressOptions(getViewOnlyAccountsFromLs().reverse());
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(connectViewOnlyAccount(value));
  };
  const handleChange = (e) => {
    setValue(e.target.value);
    setValid(new RegExp(/^\s*0x[0-9a-fA-F]{40}\s*$|^\s*[0-9a-zA-Z.].*((\.eth)|(\.xyz)\s*$)/).test(e.target.value));
  };
  return (
    <div className="track-address-form-wrapper form-wrapper">
      <div className="address-monitor-inputs">
        <h2 className="enter-address-description">Track address</h2>
        <p>Preview an account’s positions.</p>
        <div className="form-item-wrapper">
          <div className="input-values">
            <form
              id="get-address-for-monitoring"
              onSubmit={handleSubmit}
            >
              <label className="address-label" htmlFor="address-for-monitoring">{t('account.address_or_ens')}</label>
              <input
                className="form-item"
                type="text"
                spellCheck="false"
                id="address-for-monitoring"
                list="address-options"
                autoComplete="off"
                placeholder="0x"
                value={value}
                name="address-for-monitoring"
                onChange={handleChange}
              />
              <datalist id="address-options">
                {addressOptions.map((option, i) => (
                  <option value={option} key={option} />
                ),
                )}
              </datalist>
            </form>
          </div>
          <TooltipWrapper
            disabled={valid}
            useText={!valid}
            title="Invalid input. Must be ethereum address or ens name ending in '.eth' or '.xyz'"
          >
            <button
              type="submit"
              className="button green"
              disabled={!valid || connectingViewOnly}
              form="get-address-for-monitoring"
            >
              <TrackAddressIcon height={20} width={20} fill="white" />
            </button>
          </TooltipWrapper>
        </div>
      </div>
    </div>
  );
}

export default TrackAddress;
