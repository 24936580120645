import { LS_CDP_SAVER_STATE, LS_RECENT_ACCOUNTS } from '../constants/general';
import { compareAddresses } from './utils';

/**
 * Related to a specific connection medium of an address
 * (same address can be connected via Metamask and WalletConnect simultaneously)
 * @typedef {Object} RecentAccount
 * @property {string} account
 * @property {string} accountType
 * @property {string} walletName
 * @property {number} lastConnected
 * @property {boolean} [owned]
 */

/**
 * State data relating to a specific address, regardless of connection medium
 * @typedef {Object} AccountState
 * @property {string} account
 * @property {string} [accountType]
 * @property {number} [lastConnected]
 * @property {string} [ensName]
 * @property {string} [path]
 * @property {string} [proxy]
 * @property {string} [aaveMarket]
 * @property {string} [forkId]
 * @property {string} [forkAccountType]
 * @property {string} [forkCreated]
 * @property {string} [selectedId]
 * @property {object} [cdp]
 */

/**
 * @returns {RecentAccount[]}
 */
export const getRecentAccounts = () => JSON.parse(localStorage.getItem(LS_RECENT_ACCOUNTS) || '[]')
  .sort((a, b) => b.lastConnected - a.lastConnected);

/**
 * @param accounts {RecentAccount[]}
 */
export const setRecentAccounts = (accounts) => {
  const ownedAccs = accounts.filter(({ owned }) => owned);
  let notOwnedAccs = accounts.filter(({ owned }) => !owned);
  if (notOwnedAccs.length > 10) {
    notOwnedAccs.sort((a, b) => b.lastConnected - a.lastConnected);
    notOwnedAccs = notOwnedAccs.slice(0, 10);
  }
  const _accounts = [...ownedAccs, ...notOwnedAccs];
  localStorage.setItem(LS_RECENT_ACCOUNTS, JSON.stringify(_accounts));
};

/**
 * @param accountData {RecentAccount}
 */
export const addToRecentAccounts = (accountData) => {
  const recentAccounts = getRecentAccounts();
  const existingDataIndex = recentAccounts
    .findIndex(a => compareAddresses(a.account, accountData.account) && a.accountType === accountData.accountType);
  if (existingDataIndex === -1) {
    setRecentAccounts([...recentAccounts, accountData]);
  } else {
    recentAccounts[existingDataIndex] = { ...recentAccounts[existingDataIndex], ...accountData };
    setRecentAccounts(recentAccounts);
  }
};


/**
 * @returns {AccountState[]}
 */
export const getAccountStates = () => JSON.parse(localStorage.getItem(LS_CDP_SAVER_STATE) || '[]')
  .sort((a, b) => b.lastConnected - a.lastConnected);

/**
 * @param accounts {AccountState[]}
 */
export const setAccountStates = (accounts) => localStorage.setItem(LS_CDP_SAVER_STATE, JSON.stringify(accounts));


/**
 * @param account {string}
 * @returns {AccountState}
 */
export const getAccountStateFromLs = (account) => {
  const state = getAccountStates().find(item => item.account === account.toLowerCase());
  if (state?.walletName) delete state.walletName;
  return state;
};

const mergeLsItemDuplicates = (account) => {
  const accounts = getAccountStates();
  const duplicates = accounts
    .filter(item => item.account === account.toLowerCase())
    .sort((a, b) => a.lastConnected - b.lastConnected);
  if (duplicates.length) {
    const newAccState = duplicates.reduce((newAcc, acc) => ({
      ...newAcc,
      ...acc,
    }), {});
    const newAccounts = [
      ...accounts.filter(item => !compareAddresses(item.account, account)),
      newAccState,
    ];
    setAccountStates(newAccounts);
  }
};

/**
 * Fetches set ls state if it exists and existing item if it exists
 * TODO Rename & move usages to setLsValuesToReducer
 * @param account
 * @return {{oldStateLsVal: AccountState[], existingItem: (AccountState || null), existingItemIndex: number}}
 */
export const getLsExistingItemAndState = (account) => {
  mergeLsItemDuplicates(account);
  let existingItem = null;

  const oldStateLsVal = getAccountStates();

  const existingItemIndex = oldStateLsVal.findIndex(item => compareAddresses(item.account, account));
  if (existingItemIndex >= 0) existingItem = oldStateLsVal[existingItemIndex];

  return { oldStateLsVal, existingItem, existingItemIndex };
};

/**
 * Change or set a state item for each address/account
 *
 * @param change {AccountState}
 * @param replace {Boolean}
 */
export const addToLsState = (change, replace = false) => {
  if (!change.account) throw new Error('You must send account in order to change ls state item value');

  const data = getLsExistingItemAndState(change.account);

  const { existingItem, existingItemIndex } = data;
  let { oldStateLsVal } = data;
  let newStateVal = [];

  if (!oldStateLsVal) oldStateLsVal = [];

  const _change = { ...change };
  delete _change.walletName;
  _change.account = _change.account.toLowerCase();

  if (!existingItem) {
    newStateVal = [...oldStateLsVal, _change];
  } else {
    oldStateLsVal[existingItemIndex] = replace ? { ..._change } : { ...existingItem, ..._change };
    newStateVal = [...oldStateLsVal];
  }

  setAccountStates(newStateVal);
};

export const getRecentAccountsWithState = () => {
  const accounts = getRecentAccounts();
  return accounts.map((accData) => ({
    ...(getAccountStateFromLs(accData.account)),
    ...accData,
  }));
};
