import {
  useEffect, useState, useRef, useCallback, useMemo,
} from 'react';
import { useLocation } from 'react-router-dom';

export const useLoad = (func, deps = []) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  useEffect(() => {
    func()
      .catch((err) => setError(err.message))
      .finally(() => setLoading(false));
  }, deps);
  return {
    loading,
    error,
  };
};

export const useActionWithLoading = (func, deps = []) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const action = (...args) => {
    setLoading(true);
    return func(...args)
      .catch((err) => setError(err.message))
      .finally(() => setLoading(false));
  };
  return {
    action,
    loading,
    error,
  };
};

export const useStickyState = (defaultValue, key) => {
  const [value, setValue] = useState(() => {
    const stickyValue = localStorage.getItem(key);
    return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
  });
  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);
  return [value, setValue];
};

/**
 * Creates setInterval like hook using requestAnimationFrame and setTimeout for more performant intervals
 *
 * @param {function} callback - Callback to run every interval
 * @param {number} [intervalTime=1000] - Delay to run every interval on
 * @return {[any, (function(): void)]} - Returns data returned from the callback and function to clear interval
 */
export const useInterval = (callback, intervalTime = 1000) => {
  const refCallback = useRef();
  const refRAF = useRef();
  const refTimeout = useRef();
  const [callbackData, setCallbackData] = useState(null);

  useEffect(() => { refCallback.current = callback; }, [callback]);

  useEffect(() => {
    if (intervalTime && refCallback.current) {
      refRAF.current = () => window.requestAnimationFrame(async () => {
        try { setCallbackData(await refCallback.current()); } catch (err) { console.error(err); }
        refTimeout.current = setTimeout(() => refRAF.current(), intervalTime);
      });

      refRAF.current();

      return () => clearTimeout(refTimeout.current);
    }
  }, [intervalTime]);

  return [callbackData, () => clearTimeout(refTimeout.current)];
};

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useToggle = (initialState = false) => {
  const [state, setState] = useState(initialState);
  const toggle = useCallback(() => setState(state => !state), []);
  return [state, toggle];
};

/**
 * Hook that handles clicks outside the passed ref
 *
 * @param hookSetter {Function}
 * @param excludeSelectPortal {boolean}
 * @return {{ref: React.MutableRefObject<null>}}
 */
export const useOutsideRefClick = (hookSetter, excludeSelectPortal = true) => {
  const ref = useRef(null);
  const handleClickOutside =
    (event) => {
      const shouldTrigger = excludeSelectPortal || !(
        event.target.className.includes('option-value')
        || event.target.className.includes('select__option')
      );
      return (shouldTrigger && ref.current && !ref.current.contains(event.target)) && hookSetter(false);
    };
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });
  return { ref };
};

/**
 * Returns URLSearchParams
 *
 * @returns {module:url.URLSearchParams}
 */
export const useQueryParams = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};
