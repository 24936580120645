import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import t from 'translate';
import { useDispatch, useSelector } from 'react-redux';
import { openLoginModal } from '../../../../actions/modalActions';
import ModalHeader from '../../ModalHeader';
import ModalBody from '../../ModalBody';
import headerBg from '../../Compound/CompoundProxyMigrationModal/upgrade-modal-bg.svg';
import MigrateModalContent from '../../Compound/CompoundInstadappMigrationModal/MigrateModalContent/MigrateModalContent';
import MigrateModalStatusSteps from '../../Compound/CompoundInstadappMigrationModal/MigrateModalStatusSteps/MigrateModalStatusSteps';
import { migrateAaveFromEoa } from '../../../../actions/aaveActions/aaveMigrateActions';
import { AAVE_MIGRATE_PROXY_RESET_STATE } from '../../../../actionTypes/aaveActionTypes/aaveMigrateActionTypes';
import '../../Compound/CompoundInstadappMigrationModal/CompoundInstadappMigrationModal.scss';

const AaveProxyMigrationModal = ({ closeModal }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: AAVE_MIGRATE_PROXY_RESET_STATE });
  }, []);
  const accountType = useSelector(({ general }) => general.accountType);
  const aaveData = useSelector(({ aaveManage, general }) => ({
    suppliedUsd: aaveManage[general.walletType.value][aaveManage.selectedMarket.value].suppliedUsd,
    borrowedUsd: aaveManage[general.walletType.value][aaveManage.selectedMarket.value].borrowedUsd,
    usedAssets: aaveManage[general.walletType.value][aaveManage.selectedMarket.value].usedAssets,
  }));
  const step1Status = useSelector(({
    maker: {
      proxyAddress,
      creatingDSProxy,
      creatingDSProxyError,
    },
  }) => {
    let step1Status = 'empty';
    if (proxyAddress) step1Status = 'done';
    if (creatingDSProxy) step1Status = 'loading';
    if (creatingDSProxyError) step1Status = 'error';
    return step1Status;
  });
  const {
    step2Status,
    step3Status,
  } = useSelector(({ aaveMigrate }) => {
    let step2Status = 'empty';
    let step3Status = 'empty';
    if (aaveMigrate.approvalsDone) step2Status = 'done';
    if (aaveMigrate.approvalsLoading) step2Status = 'loading';
    if (aaveMigrate.approvalsErrors) step2Status = 'error';

    if (aaveMigrate.migrateEoaDone) step3Status = 'done';
    if (aaveMigrate.migrateEoaLoading) step3Status = 'loading';
    if (aaveMigrate.migrateEoaErrors) step3Status = 'error';
    return {
      step2Status,
      step3Status,
    };
  });
  const disabled = step1Status === 'loading' || step2Status === 'loading' || step3Status === 'loading';
  const migrating = step3Status === 'loading';
  return (
    <div className="action-modal-wrapper migrate-compound-from-instadapp-modal">
      <ModalHeader closeModal={closeModal} />
      <ModalBody>
        <div className="new-modal-top-wrapper" style={{ backgroundImage: `url(${headerBg})` }}>
          <h1>{t('compound.migrate_to_proxy')}</h1>
        </div>
        <div className="new-modal-content-wrapper">
          <div className="modal-description">
            <div>{t('aave.aave_proxy_migrate_modal_info1', { '%protocol': 'Aave' })}</div>
            <div>{t('aave.aave_proxy_migrate_modal_info2')}</div>
            <div>{t('aave.aave_proxy_migrate_modal_info3')}</div>
            <div>
              <a target="_blank" rel="noopener noreferrer" href="https://help.defisaver.com/aave">{t('aave.aave_proxy_migrate_modal_info4')}</a>
            </div>
          </div>
          <MigrateModalContent borrowedUsd={aaveData?.borrowedUsd} usedAssets={aaveData.usedAssets} suppliedUsd={aaveData.suppliedUsd} />
          <MigrateModalStatusSteps steps={[
            { title: t('common.create_dsproxy'), status: step1Status },
            { title: t('common.token_approvals'), status: step2Status },
            { title: t('common.migrate_position'), status: step3Status },
          ]}
          />
        </div>
      </ModalBody>
      <div className="modal-controls">
        <button
          type="button"
          className="button green"
          disabled={disabled}
          onClick={() => (accountType !== 'view-only' ?
            dispatch(migrateAaveFromEoa(aaveData, closeModal)) :
            dispatch(openLoginModal()))}
        >
          {migrating ? t('common.migrating') : t('common.migrate')}
        </button>
      </div>
    </div>
  );
};

AaveProxyMigrationModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default AaveProxyMigrationModal;
