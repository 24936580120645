import cloneDeep from 'lodash/cloneDeep';
import t from 'translate';
import { Recipe } from '@defisaver/sdk';
import { getAssetInfoByAddress } from '@defisaver/tokens';
import capitalize from 'lodash/capitalize';
import { recipeActionsToDfsActions } from '../../services/recipeCreator/recipeActionUtils';
import { compareAddresses } from '../../services/utils';
import {
  addApproveTxIfNeeded,
  approveAddressOnAssetAction,
  approveNewAaveTokenActionIfNeeded,
  approveTypeToLabelMap,
} from '../assetsActions';
import { APPROVE_TYPE_DS_PROXY } from '../../actionTypes/assetsActionTypes';
import { approveUniNftAction, approveUniNftIfNeeded } from '../uniswapV3Actions';
import { callMultipleTxAction, sendTx } from '../txNotificationActions';
import { callRecipeViaProxy } from '../../services/contractCallService';
import { simulateActions } from '../recipeCreatorActions';

export const executeRecipe = (actions, actionCalls) => async (dispatch, getState) => {
  const {
    general: { account, accountType },
    maker: { proxyAddress },
  } = getState();
  const lastCall = actionCalls[actionCalls.length - 1];
  const balances = lastCall?.balances?.recipe || {};
  const _actions = [...actions];
  Object.keys(balances)
    .forEach((asset) => {
      if (+balances[asset] > 0) {
        console.warn(`Balance of ${asset} remaining on recipe`);
        // console.log(`adding action to transfer ${asset}`);
        // _actions.push(new SendTokenAction(MAXUINT, asset, account));
      }
    });
  const successful = await dispatch(simulateActions());
  if (!successful) return;
  const recipeActions = await recipeActionsToDfsActions(_actions, getState, actionCalls);
  const recipe = new Recipe('Custom Recipe', recipeActions);
  console.log(recipe);
  // debugger; // eslint-disable-line
  const approvals = await recipe.getAssetsToApprove();
  const txToExecute = [];
  console.log(approvals);
  for (const approval of approvals) {
    if (compareAddresses(approval.owner, account)) {
      console.log(approval.owner, account);
      if (Object.keys(approval).includes('nft')) {
        const notifMessage = `${t('common.approve')} ${approveTypeToLabelMap[APPROVE_TYPE_DS_PROXY]} ${t('common.for')} NFT${approval.specialApproveLabel === 'uniswap v3' ? ` #${approval.tokenId}` : ''}`;
        const approveFunciontObject = {
          type: 'approve',
          notifMessage,
          func: () => dispatch(approveUniNftAction(proxyAddress, approval.tokenId, notifMessage)),
        };
        // eslint-disable-next-line no-await-in-loop
        await dispatch(approveUniNftIfNeeded(proxyAddress, approval.tokenId, approveFunciontObject, txToExecute));
        continue;
      }
      const asset = getAssetInfoByAddress(approval.asset);
      const approveFunctionObject = {
        type: 'approve',
        notifMessage: `${t('common.approve')} ${approveTypeToLabelMap[APPROVE_TYPE_DS_PROXY]} ${t('common.for')} ${asset.symbol}`,
        func: () => dispatch(approveAddressOnAssetAction(asset.symbol, proxyAddress, APPROVE_TYPE_DS_PROXY)),
      };
      if (asset.symbol === '?') {
        // currently only LP and yearn tokens are not in defisaver-tokens
        asset.symbol = capitalize(approval.specialApproveLabel);
        asset.address = approval.asset;
        asset.decimals = 18;
        approveFunctionObject.notifMessage = `${t('common.approve')} ${approveTypeToLabelMap[APPROVE_TYPE_DS_PROXY]} ${t('common.for')} ${asset.symbol}`;
        // eslint-disable-next-line no-await-in-loop
        await dispatch(approveNewAaveTokenActionIfNeeded(account, accountType, '', asset.symbol, {}, { supplied: 1 }, asset.symbol, approval.asset, txToExecute, approveFunctionObject));
        continue;
      }
      console.log(asset);
      // eslint-disable-next-line no-await-in-loop
      await dispatch(addApproveTxIfNeeded(asset.symbol, proxyAddress, '1', APPROVE_TYPE_DS_PROXY, txToExecute, approveFunctionObject));
    }
  }
  const sendTxFunc = (promise) => sendTx(promise, 'Custom Recipe', 'Recipe Creator', dispatch, getState, {
    protocol: 'recipe_creator',
    actions: _actions.map(a => a.toJSON()),
  });
  const executeRecipeFunctionObject = {
    func: () => callRecipeViaProxy(accountType, sendTxFunc, proxyAddress, account, recipe),
    type: 'recipe',
    notifMessage: 'Custom Recipe',
  };
  txToExecute.push(executeRecipeFunctionObject);
  console.log(txToExecute);
  return dispatch(callMultipleTxAction(txToExecute));
};
