import Dec from 'decimal.js';
import dfs, { Recipe } from '@defisaver/sdk';
import {
  assetAmountInWei, getAssetInfo, MAXUINT, compoundAsset,
} from '@defisaver/tokens';
import { getExchangeOrder } from 'services/exchangeServiceV3';
import {
  addToArrayIf, ethToWeth, requireAddress,
} from '../services/utils';
import { ZERO_ADDRESS } from '../constants/general';
import { callRecipeViaProxy } from '../services/contractCallService';
import { getFLAction } from '../services/assetsService';

/**
 * Standard Compound Boost
 */
export const boost = async (_collAsset, _debtAsset, debtAmount, price, slippagePercent, proxyAddress, additionalActions) => {
  const collAsset = ethToWeth(_collAsset);
  const cCollAddress = getAssetInfo(compoundAsset(_collAsset)).address;
  const debtAsset = ethToWeth(_debtAsset);
  const cDebtAddress = getAssetInfo(compoundAsset(_debtAsset)).address;
  const debtAmountWei = assetAmountInWei(debtAmount, debtAsset);

  const recipe = new dfs.Recipe('recCompoundBoost', [
    ...additionalActions,
    new dfs.actions.compound.CompoundBorrowAction(cDebtAddress, debtAmountWei, proxyAddress),
  ]);

  if (collAsset !== debtAsset) {
    const {
      orderData,
      value,
      extraGas,
    } = await getExchangeOrder(debtAsset, collAsset, debtAmount, price, slippagePercent, proxyAddress, false, false, true);
    recipe.addAction(new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value));
    const previousActionId = `$${recipe.actions.length}`;
    recipe.addAction(new dfs.actions.compound.CompoundSupplyAction(cCollAddress, previousActionId, proxyAddress, true));
    recipe.extraGas = extraGas;
  } else {
    const previousActionId = `$${recipe.actions.length}`;
    recipe.addAction(new dfs.actions.compound.CompoundSupplyAction(cCollAddress, previousActionId, proxyAddress, true));
  }
  return recipe;
};

/**
 * Compound FL Boost
 * @returns {Promise<Recipe>}
 */
export const boostWithDebtLoan = async (_collAsset, _debtAsset, debtAmount, price, slippagePercent, proxyAddress, flProtocol, additionalActions) => {
  const collAsset = ethToWeth(_collAsset);
  const cCollAddress = getAssetInfo(compoundAsset(_collAsset)).address;
  const debtAsset = ethToWeth(_debtAsset);
  const cDebtAddress = getAssetInfo(compoundAsset(_debtAsset)).address;

  const debtAmountWei = assetAmountInWei(debtAmount, debtAsset);

  const { FLAction, paybackAddress } = getFLAction(flProtocol, debtAmountWei, debtAsset);

  const recipe = new dfs.Recipe('recCompoundFLBoostViaDebt', [FLAction]);

  additionalActions.forEach((action) => recipe.addAction(action));

  if (collAsset !== debtAsset) {
    const { orderData, value, extraGas } = await getExchangeOrder(debtAsset, collAsset, debtAmount, price, slippagePercent, proxyAddress, false, false, true, flProtocol === 'balancer' ? ['Balancer_V2'] : []);
    recipe.addAction(new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value));
    const previousActionId = `$${recipe.actions.length}`;
    recipe.addAction(new dfs.actions.compound.CompoundSupplyAction(cCollAddress, previousActionId, proxyAddress, true));
    recipe.extraGas = extraGas;
  } else {
    recipe.addAction(new dfs.actions.compound.CompoundSupplyAction(cCollAddress, debtAmountWei, proxyAddress, true));
  }
  recipe.addAction(new dfs.actions.compound.CompoundBorrowAction(cDebtAddress, '$1', paybackAddress));

  return recipe;
};

/**
 * Compound FL Boost
 * @returns {Promise<Recipe>}
 */
export const boostWithCollLoan = async (_collAsset, _debtAsset, debtAmount, price, slippagePercent, proxyAddress, inputAmountInDai, additionalActions) => {
  const collAsset = ethToWeth(_collAsset);
  const cCollAddress = getAssetInfo(compoundAsset(_collAsset)).address;
  const debtAsset = ethToWeth(_debtAsset);
  const cDebtAddress = getAssetInfo(compoundAsset(_debtAsset)).address;

  const debtAmountWei = assetAmountInWei(debtAmount, debtAsset);

  const cFlAddress = getAssetInfo(compoundAsset('DAI')).address;
  const flAmountWei = assetAmountInWei(new Dec(inputAmountInDai).mul(2), 'DAI');
  // we have sell action, so we can't use Balancer FL
  const flPaybackAddr = dfs.actionAddresses().FLMaker;

  const recipe = new dfs.Recipe('recCompoundFLBoostViaColl', [
    new dfs.actions.flashloan.MakerFlashLoanAction(flAmountWei, ZERO_ADDRESS, []),
    new dfs.actions.compound.CompoundSupplyAction(cFlAddress, '$1', proxyAddress, true),
    new dfs.actions.compound.CompoundBorrowAction(cDebtAddress, debtAmountWei, proxyAddress),
  ]);

  additionalActions.forEach((action) => recipe.addAction(action));
  if (collAsset !== debtAsset) {
    const { orderData, value, extraGas } = await getExchangeOrder(debtAsset, collAsset, debtAmount, price, slippagePercent, proxyAddress, false, false, true);
    recipe.addAction(new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value));
    const previousActionId = `$${recipe.actions.length}`;
    recipe.addAction(new dfs.actions.compound.CompoundSupplyAction(cCollAddress, previousActionId, proxyAddress, true));
    recipe.extraGas = extraGas;
  } else {
    recipe.addAction(new dfs.actions.compound.CompoundSupplyAction(cCollAddress, '$3', proxyAddress, true));
  }
  recipe.addAction(new dfs.actions.compound.CompoundWithdrawAction(cFlAddress, '$1', flPaybackAddr));
  // if (!ethIsColl) recipe.addAction(new dfs.actions.compound.CompoundSetCollateral(cFlAddress, false)); // Action doesn't exist

  return recipe;
};

/**
 * Standard Compound Repay
 */
export const repay = async (_collAsset, _debtAsset, collAmount, price, slippagePercent, proxyAddress, account, supplied, borrowed, additionalActions) => {
  const collAsset = ethToWeth(_collAsset);
  const cCollAddress = getAssetInfo(compoundAsset(_collAsset)).address;
  const debtAsset = ethToWeth(_debtAsset);
  const cDebtAddress = getAssetInfo(compoundAsset(_debtAsset)).address;

  const payingBackAllDebt = new Dec(collAmount * price).gte(borrowed);
  const sellingAllSupplied = supplied === collAmount;
  const differentAssets = collAsset !== debtAsset;

  const collAmountWei = sellingAllSupplied ? MAXUINT : assetAmountInWei(collAmount, collAsset);

  const recipe = new dfs.Recipe('recCompoundRepay', [
    new dfs.actions.compound.CompoundWithdrawAction(cCollAddress, collAmountWei, proxyAddress),
  ]);

  if (differentAssets) {
    const { orderData, value, extraGas } = await getExchangeOrder(collAsset, debtAsset, collAmount, price, slippagePercent, proxyAddress, false, false, true);
    if (sellingAllSupplied) orderData[2] = '$1';
    recipe.addAction(new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value));
    recipe.addAction(new dfs.actions.compound.CompoundPaybackAction(cDebtAddress, '$2', proxyAddress, proxyAddress));
    recipe.extraGas = extraGas;
  } else {
    recipe.addAction(new dfs.actions.compound.CompoundPaybackAction(cDebtAddress, '$1', proxyAddress, proxyAddress));
  }

  // CompoundPaybackAction will pay back an amount up to the total debt, and leave the rest on the proxy
  if (payingBackAllDebt) {
    recipe.addAction(
      debtAsset === 'WETH'
        ? new dfs.actions.basic.UnwrapEthAction(MAXUINT, account)
        : new dfs.actions.basic.SendTokenAction(getAssetInfo(debtAsset).address, account, MAXUINT),
    );
  }
  additionalActions.forEach((a) => recipe.addAction(a));
  return recipe;
};

export const repayWithCollLoan = async (_collAsset, _debtAsset, collAmount, price, slippagePercent, proxyAddress, account, flProtocol, borrowed, maxWithdraw, additionalActions) => {
  const collAsset = ethToWeth(_collAsset);
  const cCollAddress = getAssetInfo(compoundAsset(_collAsset)).address;
  const debtAsset = ethToWeth(_debtAsset);
  const cDebtAddress = getAssetInfo(compoundAsset(_debtAsset)).address;
  const collAmountWei = assetAmountInWei(collAmount, collAsset);

  const payingBackAllDebt = new Dec(collAmount * price).gte(borrowed);
  const differentAssets = collAsset !== debtAsset;

  const maxWithdrawWei = assetAmountInWei(maxWithdraw, collAsset);
  const useAave = flProtocol === 'aave';
  const recipe = new dfs.Recipe('recCompoundFLRepayViaColl', []);
  let flPaybackAddr;
  if (useAave) {
    // Aave flashloans have a fee, so we minimize the loan amount/fee, at the cost of gas
    const flAmount = new Dec(collAmountWei).sub(maxWithdrawWei).toString();
    const { FLAction, paybackAddress } = getFLAction(flProtocol, flAmount, collAsset);
    flPaybackAddr = paybackAddress;
    recipe.addAction(FLAction);
    recipe.addAction(new dfs.actions.compound.CompoundWithdrawAction(cCollAddress, maxWithdrawWei, proxyAddress));
  } else {
    // other flashloans are free, so we FL the full amount
    const { FLAction, paybackAddress } = getFLAction(flProtocol, collAmountWei, collAsset);
    flPaybackAddr = paybackAddress;
    recipe.addAction(FLAction);
  }

  if (differentAssets) {
    const { orderData, value, extraGas } = await getExchangeOrder(collAsset, debtAsset, collAmount, price, slippagePercent, proxyAddress, false, false, true, flProtocol === 'balancer' ? ['Balancer_V2'] : []);
    recipe.addAction(new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value));
    const previousActionId = `$${recipe.actions.length}`;
    recipe.addAction(new dfs.actions.compound.CompoundPaybackAction(cDebtAddress, previousActionId, proxyAddress, proxyAddress));
    recipe.extraGas = extraGas;
  } else {
    recipe.addAction(new dfs.actions.compound.CompoundPaybackAction(cDebtAddress, collAmountWei, proxyAddress, proxyAddress));
  }
  recipe.addAction(new dfs.actions.compound.CompoundWithdrawAction(cCollAddress, '$1', flPaybackAddr));

  // CompoundPaybackAction will pay back an amount up to the total debt, and leave the rest on the proxy
  if (payingBackAllDebt) {
    recipe.addAction(
      debtAsset === 'WETH'
        ? new dfs.actions.basic.UnwrapEthAction(MAXUINT, account)
        : new dfs.actions.basic.SendTokenAction(getAssetInfo(debtAsset).address, account, MAXUINT),
    );
  }
  additionalActions.forEach((a) => recipe.addAction(a));
  return recipe;
};

export const repayWithDebtLoan = async (_collAsset, _debtAsset, collAmount, price, slippagePercent, proxyAddress, account, inputAmountInDai, borrowed, additionalActions) => {
  const collAsset = ethToWeth(_collAsset);
  const cCollAddress = getAssetInfo(compoundAsset(_collAsset)).address;
  const debtAsset = ethToWeth(_debtAsset);
  const cDebtAddress = getAssetInfo(compoundAsset(_debtAsset)).address;
  const collAmountWei = assetAmountInWei(collAmount, collAsset);

  const cFlAddress = getAssetInfo(compoundAsset('DAI')).address;
  const flAmountWei = assetAmountInWei(new Dec(inputAmountInDai).mul(2), 'DAI');
  // we have sell action, so we can't use Balancer FL
  const flPaybackAddr = dfs.actionAddresses().FLMaker;

  const payingBackAllDebt = new Dec(collAmount * price).gte(borrowed);
  const differentAssets = collAsset !== debtAsset;

  const recipe = new dfs.Recipe('recCompoundFLRepayViaDebt', [
    new dfs.actions.flashloan.MakerFlashLoanAction(flAmountWei, ZERO_ADDRESS, []),
    new dfs.actions.compound.CompoundSupplyAction(cFlAddress, '$1', proxyAddress, true),
    new dfs.actions.compound.CompoundWithdrawAction(cCollAddress, collAmountWei, proxyAddress),
  ]);

  if (differentAssets) {
    const { orderData, value, extraGas } = await getExchangeOrder(collAsset, debtAsset, collAmount, price, slippagePercent, proxyAddress, false, false, true);
    recipe.addAction(new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value));
    recipe.addAction(new dfs.actions.compound.CompoundPaybackAction(cDebtAddress, '$4', proxyAddress, proxyAddress));
    recipe.extraGas = extraGas;
  } else {
    recipe.addAction(new dfs.actions.compound.CompoundPaybackAction(cDebtAddress, collAmountWei, proxyAddress, proxyAddress));
  }
  recipe.addAction(new dfs.actions.compound.CompoundWithdrawAction(cFlAddress, '$1', flPaybackAddr));
  // if (!ethIsColl) recipe.addAction(new dfs.actions.compound.CompoundSetCollateral(cFlAddress, false)); // Action doesn't exist

  // CompoundPaybackAction will pay back an amount up to the total debt, and leave the rest on the proxy
  if (payingBackAllDebt) {
    recipe.addAction(
      debtAsset === 'WETH'
        ? new dfs.actions.basic.UnwrapEthAction(MAXUINT, account)
        : new dfs.actions.basic.SendTokenAction(getAssetInfo(debtAsset).address, account, MAXUINT),
    );
  }
  additionalActions.forEach((a) => recipe.addAction(a));
  return recipe;
};


/**
 * @param accountType
 * @param sendTxFunc
 * @param account
 * @param proxyAddress
 * @param collAsset {string} Asset symbol
 * @param debtAsset {string} Asset symbol
 * @param collAmount {string} Amount in Eth (not Wei)
 * @param debtAmount {string} Amount in Eth (not Wei)
 * @param minPrice
 * @param slippage {string|Number} Slippage percentage tolerated [0-100]
 * @param useFl {boolean}
 * @param leveraged {boolean}
 * @param flProtocol {string}
 * @return {Promise<unknown>}
 */
export const openPosition = async (
  accountType, sendTxFunc, account, proxyAddress, collAsset, debtAsset, collAmount, debtAmount, minPrice, slippage, useFl, leveraged, flProtocol,
) => {
  requireAddress(proxyAddress);

  const isSameAssetPosition = collAsset === debtAsset;

  const collAmountWei = assetAmountInWei(collAmount.toString(), collAsset);
  const debtAmountWei = assetAmountInWei(debtAmount.toString(), debtAsset);

  const collAssetAddress = getAssetInfo(`c${collAsset}`).address;
  const debtAssetAddress = getAssetInfo(`c${debtAsset}`).address;

  const isEthColl = collAsset === 'ETH';
  const isEthDebt = debtAsset === 'ETH';

  const getTokenAction = isEthColl
    ? new dfs.actions.basic.WrapEthAction(collAmountWei)
    : new dfs.actions.basic.PullTokenAction(getAssetInfo(collAsset).address, account, collAmountWei);

  let actions;
  if (leveraged) {
    if (useFl) {
      if (flProtocol === 'none') throw new Error('Cannot create Compound position, flashloan not available.');

      const { orderData, value } = !isSameAssetPosition
        ? await getExchangeOrder(ethToWeth(debtAsset), ethToWeth(collAsset), debtAmount.toString(), minPrice, slippage, account, false, false, true, flProtocol === 'balancer' ? ['Balancer_V2'] : [])
        : ({ orderData: [], value: '0' });

      const { FLAction, paybackAddress } = getFLAction(flProtocol, debtAmountWei, ethToWeth(debtAsset));

      actions = [
        FLAction,
        getTokenAction,
        ...addToArrayIf(!isSameAssetPosition,
          new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value),
        ),
        new dfs.actions.compound.CompoundSupplyAction(collAssetAddress, MAXUINT, proxyAddress),
        new dfs.actions.compound.CompoundBorrowAction(debtAssetAddress, '$1', paybackAddress),
      ];
    } else {
      const { orderData, value } = !isSameAssetPosition
        ? await getExchangeOrder(ethToWeth(debtAsset), ethToWeth(collAsset), debtAmount.toString(), minPrice, slippage, account, false, false, true)
        : ({ orderData: [], value: '0' });

      actions = [
        ...addToArrayIf(isEthColl, new dfs.actions.basic.WrapEthAction(collAmountWei)),
        new dfs.actions.compound.CompoundSupplyAction(collAssetAddress, collAmountWei, isEthColl ? proxyAddress : account),
        new dfs.actions.compound.CompoundBorrowAction(debtAssetAddress, debtAmountWei, proxyAddress),
        ...addToArrayIf(!isSameAssetPosition, new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value)),
        new dfs.actions.compound.CompoundSupplyAction(collAssetAddress, MAXUINT, proxyAddress),
      ];
    }
  } else {
    actions = [
      ...addToArrayIf(isEthColl, new dfs.actions.basic.WrapEthAction(collAmountWei)),
      new dfs.actions.compound.CompoundSupplyAction(collAssetAddress, collAmountWei, isEthColl ? proxyAddress : account),
      new dfs.actions.compound.CompoundBorrowAction(debtAssetAddress, debtAmountWei, isEthDebt ? proxyAddress : account),
      ...addToArrayIf(isEthDebt, new dfs.actions.basic.UnwrapEthAction(MAXUINT, account)),
    ];
  }
  const recipe = new Recipe(`CompoundOpen${leveraged ? 'Leveraged' : ''}Recipe`, actions);
  return callRecipeViaProxy(accountType, sendTxFunc, proxyAddress, account, recipe);
};

/**
 * Creates recipe for migrating instadapp or EOA position to DSProxy
 *
 * @param totalDebtInDai {number} - USD debt
 * @param debtAssets {Array.<string>} - Array of debt assets in instadapp position
 * @param collAssets {Array.<{symbol:string,collateral:boolean}>} - Array of collateral assets where symbol is asset's symbol, and collateral describes whether asset is enabled as collateral
 * @param account {string} - User wallet address
 * @param proxyAccount {string} - User DSProxy address
 * @param onBehalfOf {string} - On behalf of DSA or EOA
 * @param isDSA {boolean}
 * @return {Recipe}
 */
export const migrate = (totalDebtInDai, debtAssets, collAssets, account, proxyAccount, onBehalfOf, isDSA = false) => {
  const flAmountWei = assetAmountInWei(new Dec(totalDebtInDai).mul(2), 'DAI');
  const cFlAssetAddress = getAssetInfo(compoundAsset('DAI')).address;
  const flPaybackAddr = dfs.actionAddresses().FLMaker;
  const flActionNumber = 1;

  const cCollAssets = [];
  const cCollEnabledAssets = [];
  collAssets.forEach(({ symbol, collateral }) => {
    const addr = getAssetInfo(compoundAsset(symbol)).address;
    cCollAssets.push(addr);
    if (collateral) {
      cCollEnabledAssets.push(addr);
    }
  });

  const recipe = new dfs.Recipe(`recCompoundMigrateFrom${isDSA ? 'Instadapp' : 'EOA'}`, []);
  if (totalDebtInDai > 0) {
    const getDebtActionNumberOffset = 2;
    const debtAssetsWithInfo = debtAssets.map((asset, index) => {
      const cAssetAddress = getAssetInfo(compoundAsset(asset)).address;
      return {
        asset,
        cAssetAddress,
        getDebtActionNumber: getDebtActionNumberOffset + index,
      };
    });
    recipe.addAction(new dfs.actions.flashloan.MakerFlashLoanAction(flAmountWei, ZERO_ADDRESS, []),
    );
    debtAssetsWithInfo.forEach(({ cAssetAddress }) => {
      recipe.addAction(new dfs.actions.compound.CompoundGetDebtAction(cAssetAddress, onBehalfOf));
    });
    recipe.addAction(new dfs.actions.compound.CompoundSupplyAction(cFlAssetAddress, flAmountWei, proxyAccount, true));

    debtAssetsWithInfo.forEach(({ cAssetAddress, getDebtActionNumber }) => {
      recipe.addAction(new dfs.actions.compound.CompoundBorrowAction(cAssetAddress, `$${getDebtActionNumber}`, proxyAccount));
      recipe.addAction(new dfs.actions.compound.CompoundPaybackAction(cAssetAddress, `$${getDebtActionNumber}`, proxyAccount, onBehalfOf));
    });
  }

  const assetAmountsToPullFromDsa = cCollAssets.map(() => MAXUINT);
  if (isDSA) {
    recipe.addAction(new dfs.actions.insta.InstPullTokensAction(onBehalfOf, cCollAssets, assetAmountsToPullFromDsa, proxyAccount));
  } else {
    cCollAssets.forEach((cToken) => {
      recipe.addAction(new dfs.actions.basic.PullTokenAction(cToken, onBehalfOf, MAXUINT));
    });
  }

  recipe.addAction(new dfs.actions.compound.CompoundCollateralSwitchAction(cCollEnabledAssets, cCollEnabledAssets.map(() => true)));

  if (totalDebtInDai > 0) {
    recipe.addAction(new dfs.actions.compound.CompoundWithdrawAction(cFlAssetAddress, `$${flActionNumber}`, flPaybackAddr));
  }
  return recipe;
};
