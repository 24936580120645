import * as makerStrategiesActionTypes from '../../actionTypes/strategiesActionTypes/makerStrategiesActionTypes';

const INITIAL_STATE = {
  makerSubscribedStrategies: [],
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case makerStrategiesActionTypes.GET_MAKER_SUBSCRIBED_STRATEGIES_SUCCESS:
      return { ...state, makerSubscribedStrategies: payload };

    default:
      return state;
  }
};
