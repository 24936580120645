import React from 'react';
import PropTypes from 'prop-types';
import ManageWallets from '../../Connect/ManageWallets/ManageWallets';
import ModalHeader from '../ModalHeader';
import ModalBody from '../ModalBody';
import './ManageWalletsModal.scss';

const ManageWalletsModal = ({ closeModal }) => (
  <div className="manage-wallets-modal-wrapper">

    <ModalHeader closeModal={closeModal} />
    <ModalBody>
      <div className="new-modal-top-wrapper">
        <h1>Manage wallets</h1>
      </div>

      <div className="new-modal-content-wrapper">
        <ManageWallets showAddButton />
      </div>
    </ModalBody>
  </div>
);

ManageWalletsModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default ManageWalletsModal;
