import { getAssetInfo } from '@defisaver/tokens';
import Dec from 'decimal.js';
import clientConfig from '../config/clientConfig.json';
import { compareAddresses, fetchWithTimeout, getEthAmountForDecimals } from './utils';
import { AAVEAddress, ethAddress, stkAAVEAddress } from './contractRegistryService';
import { IGNORECOINS, SCAMCOINS } from '../constants/general';
import { captureException } from '../sentry';

const API_URL = clientConfig.apiUrl;
const EXPLORE_API_URL = clientConfig.exploreApiUrl;
const PORTFOLIO_API_URL = clientConfig.portfolioApiUrl;

export const subscribeComingSoonApiCall = async (email) => {
  const res = await fetch(`${API_URL}/api/subscribe`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      email,
    }),
  });
  if (!res.ok) throw new Error(await res.text());
};

export const contactUsApiCall = async (data) => {
  const res = await fetch(`${API_URL}/api/send_email`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });
  if (!res.ok) throw new Error(await res.text());
};

export const sendErrorReport = async (action, txHash) => fetch(
  `${API_URL}/api/report_error/${action}/hash/${txHash}`,
  {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  },
);

export const getServerNotificationsApiCall = async (address, isDebug) => {
  const res = isDebug ? await fetch(
    `${API_URL}/api/notifications/debug/${address}`,
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    },
  ) : await fetch(
    `${API_URL}/api/notifications/${address}`,
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    },
  );

  if (!res.ok) throw new Error(await res.text());

  return res.json();
};

export const markServerNotificationsAsReadApiCall = async (address, id) => {
  const res = await fetch(
    `${API_URL}/api/notifications/read/${address}/${id}`,
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    },
  );

  if (!res.ok) throw new Error(await res.text());
};

export const sendAutomationUnsubscribeApiCall = async (protocol, address, positionId, feedbackCode, additionalData, strategy = 'legacy') => {
  const res = await fetch(`${API_URL}/api/automation-feedback`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        address, positionId, protocol, feedbackCode, additionalData, strategy,
      }),
    },
  );

  if (!res.ok) throw new Error((await res.json()).message);
};

export const addTxHashForMonitoringApiCall = async (address, txHash, title, category, forkId = '') => {
  const endpoint = forkId
    ? `${API_URL}/api/fork-tx-monitor/register`
    : `${API_URL}/api/tx-monitor/register`;
  const res = await fetch(endpoint,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        address, txHash, title, category, forkId,
      }),
    },
  );
};

export const getServerTxApiCall = async (address, forkId) => {
  const endpoint = forkId
    ? `${API_URL}/api/fork-tx-monitor/${forkId}/${address}/`
    : `${API_URL}/api/tx-monitor/${address}/`;
  const res = await fetch(
    endpoint,
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    },
  );

  if (!res.ok) throw new Error(await res.text());

  return res.json();
};

export const getCdpHistoryApiCall = async (cdpId) => {
  const res = await fetch(`${EXPLORE_API_URL}/api/cdps/${cdpId}/`);
  const payload = await res.json();
  return [...payload.events, ...payload.transfers].sort((a, b) => b.blockNumber - a.blockNumber);
};

export const getTxData = async (txHash) => {
  const res = await fetch(`${EXPLORE_API_URL}/api/tx/${txHash}`);
  return res.json();
};

export const getCdpHistoryGraphDataApiCall = async (ilk) => {
  const asset = ilk.replace(/-.*/, '');
  const _exchPrices = await fetch(`${EXPLORE_API_URL}/api/otc/get-prices?type=${asset}&fromBlock=${'0'}&toBlock=${'150000000'}`);
  const exchPrices = await _exchPrices.json();
  const _makerPrices = await fetch(`${EXPLORE_API_URL}/api/maker/get-prices?type=${asset}&fromBlock=${'0'}&toBlock=${'150000000'}`);
  const makerPrices = await _makerPrices.json();
  const _rates = await fetch(`${EXPLORE_API_URL}/api/maker/get-rates?type=${ilk}&fromBlock=${'0'}&toBlock=${'150000000'}`);
  const rates = await _rates.json();
  if (!makerPrices.length && rates.length) {
    // fill USDC maker prices
    const minBlock = rates[0][0];
    const maxBlock = rates[rates.length - 1][0];
    for (let i = minBlock; i < maxBlock; i += 200) makerPrices.push([i, 1]);
  }
  return { exchPrices, makerPrices, rates };
};

export const getLiqudationDataApiCall = async (cdpType, liqId, biteHash) => {
  const res = await fetch(`${EXPLORE_API_URL}/api/liquidation/id/${cdpType}/${liqId}/${biteHash}`);
  if (!res.ok) throw new Error(await res.text());
  return res.json();
};

export const getCDPLiqudationsApiCall = async (cdpId) => {
  const res = await fetch(`${EXPLORE_API_URL}/api/liquidation/cdp/${cdpId}`);
  if (!res.ok) throw new Error(await res.text());
  return res.json();
};

export const getSavingsProjectsApiCall = async () => {
  const res = await fetch(`${API_URL}/api/savings/apys`,
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    },
  );
  if (!res.ok) throw new Error(await res.text());
  return res.json();
};

export const getSavingsAvgApyApiCall = async () => {
  const res = await fetch(`${API_URL}/api/savings/apys`,
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    },
  );
  if (!res.ok) throw new Error(await res.text());
  return res.json();
};

export const getGasPriceHistoryApi = async (daysOfHistory = 7) => {
  const res = await fetch(`${API_URL}/api/gas-price/history?days=${daysOfHistory}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
  if (!res.ok) throw new Error(await res.text());
  return res.json();
};

export const get1559GasPrice = async () => {
  const res = await fetch(`${API_URL}/api/gas-price/1559/current`);
  const data = await res.json();
  if (data.ok) {
    return {
      baseFee: Math.floor(data.blockPrices[0].baseFeePerGas),
      estimatedPrices: [
        data.blockPrices[0].estimatedPrices[0],
        data.blockPrices[0].estimatedPrices[2],
        data.blockPrices[0].estimatedPrices[4],
      ],
    };
  }
  return {
    baseFee: 0,
    estimatedPrices: [
      { confidence: 99, maxPriorityFeePerGas: 0 },
      { confidence: 90, maxPriorityFeePerGas: 0 },
      { confidence: 70, maxPriorityFeePerGas: 0 },
    ],
  };
};

export const get1559GasPriceHistoryApi = async (daysOfHistory = 7) => {
  const res = await fetch(`${API_URL}/api/gas-price/1559/history?days=${daysOfHistory}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
  if (!res.ok) throw new Error(await res.text());
  return res.json();
};

export const get1559GasPriceStatus = async () => {
  const res = await fetch(`${API_URL}/api/gas-price/1559/status`);
  const data = await res.json();
  const STATUSES = ['Surging', 'Growing', 'Stable', 'Declining', 'Falling'];
  if (res.ok) return STATUSES[data.status];
  return '';
};

export const userDeapprovedApiCall = async (address, protocol) => {
  const cdpId = 0;
  const field = 'Other (please describe briefly):';
  const description = 'User removed approval';
  const res = await fetch(`${API_URL}/api/cdp-automation-unsubscribed`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        address, cdpId, protocol: `✅ ${protocol}`, field, description,
      }),
    },
  );

  if (!res.ok) throw new Error((await res.json()).message);
};

export const getTxHistoryApiCall = async (address, page = 1, offset = 100) => {
  const call = await fetch(`${PORTFOLIO_API_URL}/transaction-history?address=${address}&page=${page}&offset=${offset}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
  const res = await call.json();
  if (call.status !== 200) throw new Error(res.message);
  return res;
};

export const getTokensApiCall = async (address) => {
  const call = await fetch(`${PORTFOLIO_API_URL}/tokens?address=${address}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
  const res = await call.json();
  if (call.status !== 200) return { payload: [] };
  return res;
};

export const fetchLuisPrediction = async (query) => {
  const response = await fetch(`${API_URL}/api/nlp?utterance=${encodeURIComponent(query)}`);
  if (response.status === 200) return (await response.json()).data;
  throw new Error('Failed to predict');
};

export const getTokensForAddress = async (address) => {
  const response = await fetch(`${API_URL}/api/tokens/${address}`, {
    method: 'GET',
  });
  const { data: res } = await response.json();
  const returnObject = {
    assets: [{
      symbol: 'ETH',
      amount: res.ETH.balance,
      price: res.ETH.price.rate,
      icon: getAssetInfo('ETH').icon,
      address: ethAddress,
      decimals: 18,
      usdValue: new Dec(res.ETH.balance).mul(res.ETH.price.rate).toString(),
    }],
    uniTokens: [],
    yearnTokens: [],
    crvTokens: [],
    balancerTokens: [],
    sushiTokens: [],
  };
  // Uni v2, yearn, crv tokens separate to show in protocols
  res.tokens
    .filter(token => !SCAMCOINS.some(scamcoin => compareAddresses(token.tokenInfo.address, scamcoin)))
    .filter(token => !IGNORECOINS.some(scamcoin => compareAddresses(token.tokenInfo.address, scamcoin)))
    .filter(token => +token.tokenInfo.decimals) // scamcoins have no decimals
    .filter(token => !token.tokenInfo.estimatedDecimals) // real tokens don't need estimated decimals
    .filter(token => token.tokenInfo.symbol)
    .filter(token => !token.tokenInfo.name.toLowerCase().startsWith('aave interest') && !token.tokenInfo.name.toLowerCase().startsWith('aave weth') && !token.tokenInfo.name.toLowerCase().startsWith('aave usdc'))
    .filter(token => !token.tokenInfo.symbol.toLowerCase().startsWith('variabledebt') && !token.tokenInfo.symbol.toLowerCase().startsWith('stabledebt')) // filter out aave variable and stable debt tokens
    .filter(token => !token.tokenInfo.publicTags || !token.tokenInfo.publicTags.includes('Asset-backed') || token.tokenInfo.symbol === 'WBTC') // exclude asset-backed tokens
    .filter(token => !token.tokenInfo.alert)
    .forEach(token => {
      const amount = getEthAmountForDecimals(token.balance, token.tokenInfo.decimals);
      const usdValue = new Dec(amount).mul(token.tokenInfo.price.rate || 0).toString();
      if (token.tokenInfo.symbol === 'UNI-V2') {
        returnObject.uniTokens.push({
          address: token.tokenInfo.address,
          usdValue,
          amount,
          decimals: token.tokenInfo.decimals,
        });
        return;
      }
      if (token.tokenInfo.symbol === 'SLP') {
        returnObject.sushiTokens.push({
          address: token.tokenInfo.address,
          usdValue,
          amount,
          decimals: token.tokenInfo.decimals,
        });
        return;
      }
      if (token.tokenInfo.symbol.startsWith('yv') || token.tokenInfo.symbol === 'YVBOOST') {
        returnObject.yearnTokens.push({
          usdValue,
          amount,
          address: token.tokenInfo.address,
          symbol: token.tokenInfo.symbol,
          decimals: token.tokenInfo.decimals,
        });
        return;
      }
      if (token.tokenInfo.name.includes('Curve.fi')) {
        returnObject.crvTokens.push({
          usdValue,
          amount,
          address: token.tokenInfo.address,
          symbol: token.tokenInfo.symbol,
          decimals: token.tokenInfo.decimals,
        });
        return;
      }
      if (token.tokenInfo.name === 'Balancer Pool Token' || token.tokenInfo.symbol === 'BPT' || token.tokenInfo.name.match(/^Balancer .*/gi)) {
        returnObject.balancerTokens.push({
          usdValue,
          amount,
          address: token.tokenInfo.address,
          symbol: token.tokenInfo.symbol,
          decimals: token.tokenInfo.decimals,
        });
        return;
      }
      returnObject.assets.push({
        symbol: token.tokenInfo.symbol,
        amount,
        price: token.tokenInfo.price.rate,
        icon: getAssetInfo(token.tokenInfo.symbol).icon,
        address: token.tokenInfo.address,
        usdValue,
        decimals: token.tokenInfo.decimals,
      });
    });
  return returnObject;
};

export const getPricesForPortfolioTokensFromCoingecko = async (tokens, isLayer2) => {
  // coingecko doesn't return stkAAVE price, so need to send AAVE address instead and handle it after fetching
  const tokensData = tokens.assets
    .map(tokens => (tokens.symbol !== 'ETH'
      ? (
        compareAddresses(tokens[isLayer2 ? 'addressOnMainnet' : 'address'], stkAAVEAddress)
          ? { address: AAVEAddress, symbol: tokens.symbol }
          : { address: tokens[isLayer2 ? 'addressOnMainnet' : 'address'].toLowerCase(), symbol: tokens.symbol })
      : ({ address: getAssetInfo('WETH').addresses[1], symbol: 'WETH' })));
  if (!tokensData.length) {
    return tokens.assets.map(token => ((!token.price)
      ? { ...token, price: 0 }
      : token));
  }
  try {
    const response = await fetchWithTimeout(`${API_URL}/api/tokens/prices`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          tokensData,
        }),
      },
      20000,
    );

    if (!response.ok && response.status !== 200) {
      throw new Error('Failed fetching prices');
    }
    const data = await response.json();
    return tokens.assets.map(token => (
      {
        ...token,
        price: data?.[token.symbol !== 'ETH'
          ? (token.symbol === 'stkAAVE'
            ? AAVEAddress.toLowerCase()
            : token[isLayer2 ? 'addressOnMainnet' : 'address'].toLowerCase())
          : getAssetInfo('WETH').addresses[1].toLowerCase()]?.usd || 0,
      }));
  } catch (error) {
    console.error(error);
    captureException(error);
    throw new Error(error.message);
  }
};

export const getRoutingFromApiCall = async (data) => {
  const res = await fetch(`${API_URL}/api/exchange/get-best-price`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });
  if (!res.ok) throw new Error(await res.text());
  return res.json();
};

export const subscribeToNewsletter = async (data) => {
  const res = await fetch(`${API_URL}/api/newsletter/subscribe/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });
  if (!res.ok) throw new Error('Error while subscribing.');
  return res.json();
};

