import Dec from 'decimal.js';
import cloneDeep from 'lodash/cloneDeep';
import { changeBalance } from '../services/recipeCreator/recipeActionUtils';

const AAVE_FLASH_LOAN_FEE = 0.0009;

export const dydxFlashBorrowAfterValues = async ({ amount, asset = 'DAI' }, _, _balances = {}) => {
  const balances = cloneDeep(_balances);
  await changeBalance(balances, 'recipe', asset.replace(/^ETH/, 'WETH'), amount || '0');
  await changeBalance(balances, 'dydx', asset.replace(/^ETH/, 'WETH'), new Dec(amount || '0').mul(-1));
  return {
    balances,
    returnValue: amount || '0',
  };
};
export const balancerFlashBorrowAfterValues = async ({ amount, asset }, _, _balances = {}) => {
  const balances = cloneDeep(_balances);
  await changeBalance(balances, 'recipe', asset.replace(/^ETH/, 'WETH'), amount || '0');
  await changeBalance(balances, 'balancer', asset.replace(/^ETH/, 'WETH'), new Dec(amount || '0').mul(-1));
  return {
    balances,
    returnValue: amount || '0',
  };
};
export const makerFlashBorrowAfterValues = async ({ amount, asset = 'DAI' }, _, _balances = {}) => {
  const balances = cloneDeep(_balances);
  await changeBalance(balances, 'recipe', asset, amount || '0');
  await changeBalance(balances, 'maker', asset, new Dec(amount || '0').mul(-1));
  return {
    balances,
    returnValue: amount || '0',
  };
};
export const flashPaybackAfterValues = (provider) => async ({ amount, asset = 'DAI' }, _, _balances = {}) => {
  const balances = cloneDeep(_balances);
  await changeBalance(balances, 'recipe', asset.replace(/^ETH/, 'WETH'), new Dec(amount || '0').mul(-1));
  await changeBalance(balances, provider, asset.replace(/^ETH/, 'WETH'), amount || '0');
  return {
    balances,
    returnValue: amount || '0',
  };
};

export const aaveV2FlashBorrowAfterValues = async ({ amount, asset }, _, _balances = {}) => {
  const balances = cloneDeep(_balances);
  const amountToReturn = new Dec(amount || '0').times(1 + AAVE_FLASH_LOAN_FEE).toString();
  await changeBalance(balances, 'recipe', asset.replace(/^ETH/, 'WETH'), amount || '0');
  await changeBalance(balances, 'aaveV2', asset.replace(/^ETH/, 'WETH'), new Dec(amountToReturn).mul(-1));
  return {
    balances,
    returnValue: amountToReturn,
  };
};

export const dydxFlashPaybackAfterValues = flashPaybackAfterValues('dydx');
export const aaveV2FlashPaybackAfterValues = flashPaybackAfterValues('aaveV2');
export const makerFlashPaybackAfterValues = flashPaybackAfterValues('maker');
export const balancerFlashPaybackAfterValues = flashPaybackAfterValues('balancer');
