import React from 'react';

export default function LiquityIcon(props) {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="30" height="30" rx="3" fill="#3A4750" />
      <path d="M13.8025 22.4091L13.7832 22.4091H13.7629C6.52632 22.4091 3.67036 12.6835 10.0623 8.58486L10.0609 8.59961C11.1542 7.92866 12.4343 7.54285 13.8025 7.54285C17.8209 7.54285 21.0786 10.8708 21.0786 14.976C21.0786 15.8276 20.9383 16.6457 20.6802 17.4076C23.5116 18.9029 23.5142 22.4178 23.5142 22.4178H13.947C13.8716 22.4178 13.7965 22.4168 13.722 22.4147C13.7705 22.4133 13.8189 22.4114 13.8671 22.4091H13.8218L13.8025 22.4091Z" fill="#626D75" />
    </svg>
  );
}
