import React from 'react';
import { useStickyState } from '../../services/hooks';

import './BookmarkBanner.scss';
import CloseIcon from '../Decorative/CloseIcon';

const BookmarkBanner = () => {
  const [show, setShow] = useStickyState(true, 'bookmark-banner-hidden');
  if (show) {
    return (
      <div className="Flex bookmark-banner">
        <span>
          Please make sure that the URL you&apos;re visiting is <b>app.defisaver.com</b>. Consider bookmarking our page to stay safe or{' '}
          <a
            href="https://help.defisaver.com/general/how-to-stay-safe-using-de-fi-saver"
            target="_blank"
            rel="noopener noreferrer"
          >learn more here
          </a>.
        </span>
        <CloseIcon onClick={() => setShow(false)} />
      </div>
    );
  }

  return null;
};

export default BookmarkBanner;
