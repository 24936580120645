import * as types from '../../actionTypes/liquityActionTypes/liquityStakingActionTypes';

const emptyStakeBalancesAndRewards = {
  stakedLQTYBalance: '0',
  stakedLUSDBalance: '0',
  rewardETH: '0',
  rewardLUSD: '0',
  stabilityRewardETH: '0',
  stabilityRewardLQTY: '0',
};

const INITIAL_STATE = {
  stakingLQTY: false,
  stakingLQTYErr: '',

  unstakingLQTY: false,
  unstakingLQTYErr: '',

  stakingLUSD: false,
  stakingLUSDErr: '',

  unstakingLUSD: false,
  unstakingLUSDErr: '',

  gettingBalancesAndRewards: false,
  gettingBalancesAndRewardsErr: '',

  withdrawingETHGainToTrove: false,
  withdrawingETHGainToTroveErr: '',

  totalLUSDDeposited: '0',
  totalLQTYStaked: '0',

  proxy: { ...emptyStakeBalancesAndRewards },
  account: { ...emptyStakeBalancesAndRewards },
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.LQTY_STAKE_REQUEST: return {
      ...state,
      stakingLQTY: true,
      stakingLQTYErr: '',
    };

    case types.LQTY_STAKE_SUCCESS: return {
      ...state,
      stakingLQTY: false,
      stakingLQTYErr: '',
    };

    case types.LQTY_STAKE_FAILURE: return {
      ...state,
      stakingLQTY: false,
      stakingLQTYErr: payload,
    };

    case types.LQTY_UNSTAKE_REQUEST: return {
      ...state,
      unstakingLQTY: true,
      unstakingLQTYErr: '',
    };

    case types.LQTY_UNSTAKE_SUCCESS: return {
      ...state,
      unstakingLQTY: false,
      unstakingLQTYErr: '',
    };

    case types.LQTY_UNSTAKE_FAILURE: return {
      ...state,
      unstakingLQTY: false,
      unstakingLQTYErr: payload,
    };

    case types.LQTY_LUSD_STAKE_REQUEST: return {
      ...state,
      stakingLUSD: true,
      stakingLUSDErr: '',
    };

    case types.LQTY_LUSD_STAKE_SUCCESS: return {
      ...state,
      stakingLUSD: false,
      stakingLUSDErr: '',
    };

    case types.LQTY_LUSD_STAKE_FAILURE: return {
      ...state,
      stakingLUSD: false,
      stakingLUSDErr: payload,
    };

    case types.LQTY_LUSD_UNSTAKE_REQUEST: return {
      ...state,
      unstakingLUSD: true,
      unstakingLUSDErr: '',
    };

    case types.LQTY_LUSD_UNSTAKE_SUCCESS: return {
      ...state,
      unstakingLUSD: false,
      unstakingLUSDErr: '',
    };

    case types.LQTY_LUSD_UNSTAKE_FAILURE: return {
      ...state,
      unstakingLUSD: false,
      unstakingLUSDErr: payload,
    };

    case types.LQTY_GET_STAKE_BALANCES_AND_REWARDS_REQUEST:
      return {
        ...state,
        gettingBalancesAndRewards: true,
        gettingBalancesAndRewardsErr: '',
      };

    case types.LQTY_GET_STAKE_BALANCES_AND_REWARDS_SUCCESS:
      return {
        ...state,
        gettingBalancesAndRewards: false,
        gettingBalancesAndRewardsErr: '',
        totalLUSDDeposited: payload.totalLUSDDeposited,
        totalLQTYStaked: payload.totalLQTYStaked,
        proxy: { ...payload.proxy },
        account: { ...payload.account },
      };

    case types.LQTY_GET_STAKE_BALANCES_AND_REWARDS_FAILURE:
      return {
        ...state,
        gettingBalancesAndRewards: true,
        gettingBalancesAndRewardsErr: payload,
      };

    case types.LQTY_WITHDRAW_ETH_GAIN_TO_TROVE_REQUEST: return {
      ...state,
      withdrawingETHGainToTrove: true,
      withdrawingETHGainToTroveErr: '',
    };

    case types.LQTY_WITHDRAW_ETH_GAIN_TO_TROVE_SUCCESS: return {
      ...state,
      withdrawingETHGainToTrove: false,
      withdrawingETHGainToTroveErr: '',
    };

    case types.LQTY_WITHDRAW_ETH_GAIN_TO_TROVE_FAILURE: return {
      ...state,
      withdrawingETHGainToTrove: false,
      withdrawingETHGainToTroveErr: payload,
    };

    default:
      return state;
  }
};

