import React from 'react';

export default function PortfolioIcon(props) {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="30" height="30" rx="3" fill="#61717E" />
      <path d="M22.796 15.7589L15.5952 7.75811C15.292 7.42047 14.7096 7.42047 14.4063 7.75811L7.20561 15.7589C7.10196 15.8738 7.03393 16.0163 7.00978 16.1692C6.98564 16.322 7.00642 16.4786 7.06959 16.6198C7.19761 16.9086 7.48404 17.0942 7.80007 17.0942H9.40023V22.6948C9.40023 22.907 9.48452 23.1105 9.63457 23.2605C9.78461 23.4106 9.98811 23.4949 10.2003 23.4949H12.6005C12.8127 23.4949 13.0162 23.4106 13.1663 23.2605C13.3163 23.1105 13.4006 22.907 13.4006 22.6948V19.4945H16.6009V22.6948C16.6009 22.907 16.6852 23.1105 16.8353 23.2605C16.9853 23.4106 17.1888 23.4949 17.401 23.4949H19.8013C20.0135 23.4949 20.217 23.4106 20.367 23.2605C20.5171 23.1105 20.6013 22.907 20.6013 22.6948V17.0942H22.2015C22.3565 17.0949 22.5083 17.0505 22.6384 16.9663C22.7685 16.8822 22.8714 16.7621 22.9344 16.6205C22.9974 16.479 23.0179 16.3221 22.9933 16.1691C22.9687 16.0161 22.9001 15.8736 22.796 15.7589V15.7589Z" fill="#626D75" />
    </svg>
  );
}
