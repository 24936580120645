import React, { useEffect, useState } from 'react';
import t from 'translate';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { connect } from 'react-redux';
import {
  SelectOption,
  SelectValueContainer,
} from 'components/Forms/SelectComponent/SelectComponent';
import TooltipWrapper from '../../../../../../../TooltipWrapper/TooltipWrapper';
import { formatNumber } from '../../../../../../../../services/utils';
import '../../../../../../../Forms/CheckboxComponent/CheckboxComponent.scss';
import InfoIcon from '../../../../../../../Decorative/InfoIcon';

const AmountActionField = ({
  type, i, actualValue, isPointer, originalValue, handleChange, amountOptions, maxValue, disabled,
  estimateAmount, estimateAsset, iAsset, handlePointerChange, hideEstimateAmount, action, hasWholeBalanceOption, wholeBalanceInfo,
}) => {
  const usingWholeBalance = actualValue === 'All available';
  useEffect(() => {
    if (!hasWholeBalanceOption && usingWholeBalance) handleChange({ target: { value: '0' } }, i);
  }, [hasWholeBalanceOption]);
  return (
    <>
      {maxValue && (
        <div
          className={`max-wrapper ${usingWholeBalance ? 'use-whole-balance' : ''}`}
          onClick={() => handleChange({ target: { value: maxValue } }, i)}
        >
          <TooltipWrapper title={maxValue}>
            {
              `(${t('common.max_val', { '%value': formatNumber(maxValue, 2) })})`
            }
          </TooltipWrapper>
        </div>
      )}
      {
        hasWholeBalanceOption && (
          <div
            className={`form-item-wrapper checkbox ${usingWholeBalance ? 'active' : ''}`}
            onClick={() => {
              handleChange({ target: { value: usingWholeBalance ? '0' : 'All available' } }, i);
            }}
          >
            <label className="label-wrapper">
              <TooltipWrapper title={t(`recipes.whole_balance_${wholeBalanceInfo}`)}>
                <InfoIcon />
              </TooltipWrapper>
              <div className="label-text">
                {t(`recipes.use_whole_balance_${wholeBalanceInfo}`)}
              </div>
              <div className="checkbox-wrapper">
                <div className="box" />
              </div>
            </label>
          </div>
        )
      }
      {
        amountOptions.length > 0 && (
          <div className="previous-values-wrapper">
            <TooltipWrapper title="Reference amounts from previous actions">
              <InfoIcon />
            </TooltipWrapper>
            <Select
              isSearchable={false}
              className="select box previous-values-select"
              classNamePrefix="select"
              value={isPointer ? amountOptions.find(i => i.meta.id === originalValue.substr(1)) : { secondLabel: 'Custom', value: 0 }}
              components={{
                Option: SelectOption,
                ValueContainer: SelectValueContainer,
              }}
              onChange={(e) => handlePointerChange({ target: e }, i, iAsset)}
              options={amountOptions}
              onBlur={event => event.preventDefault()}
            />
          </div>
        )
      }
      <input
        className={`form-item ${isPointer ? 'pointing' : ''} ${estimateAmount ? 'has-estimate' : ''} ${usingWholeBalance ? 'use-whole-balance' : ''}`}
        autoComplete="off"
        placeholder="0"
        id={type.name}
        type="text"
        value={usingWholeBalance ? 'All available' : actualValue}
        onChange={(e) => handleChange(e, i)}
        disabled={disabled}
      />
      {!hideEstimateAmount && estimateAmount && (
        <label className="estimate-amount">{`~${formatNumber(estimateAmount, 2)} ${estimateAsset}`}</label>
      )}
      {
        (type.asset || action?.getAsset())
          ? !hideEstimateAmount && (<span className={`second-label ${isPointer ? 'pointing' : ''}`}><span>{type.asset || action?.getAsset()}</span></span>)
          : !hideEstimateAmount && (<span className="label-replacement" />)
      }
    </>
  );
};
AmountActionField.defaultProps = {
  isPointer: false,
  maxValue: '',
  estimateAmount: '',
  estimateAsset: '',
  iAsset: -1,
  hideEstimateAmount: false,
  hasWholeBalanceOption: false,
  wholeBalanceInfo: 'send',
  disabled: false,
};

AmountActionField.propTypes = {
  handleChange: PropTypes.func.isRequired,
  type: PropTypes.object.isRequired,
  i: PropTypes.number.isRequired,
  actualValue: PropTypes.string.isRequired, // if pointer, this will be the return value of selected action
  originalValue: PropTypes.string.isRequired, // raw value from formData (can be pointer ID)
  handlePointerChange: PropTypes.func.isRequired,
  amountOptions: PropTypes.array.isRequired, // pointer options

  action: PropTypes.object,
  isPointer: PropTypes.bool,
  maxValue: PropTypes.string,
  estimateAmount: PropTypes.string,
  estimateAsset: PropTypes.string,
  iAsset: PropTypes.number,
  hideEstimateAmount: PropTypes.bool,
  hasWholeBalanceOption: PropTypes.bool,
  wholeBalanceInfo: PropTypes.string,
  disabled: PropTypes.bool,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AmountActionField);
