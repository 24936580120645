export const RFL_GET_MAX_VALUE_REQUEST = 'RFL_GET_MAX_VALUE_REQUEST';
export const RFL_GET_MAX_VALUE_SUCCESS = 'RFL_GET_MAX_VALUE_SUCCESS';
export const RFL_GET_MAX_VALUE_FAILURE = 'RFL_GET_MAX_VALUE_FAILURE';

export const RFL_SET_ADDITIONAL_DASHBOARD_ACTION = 'RFL_SET_ADDITIONAL_DASHBOARD_ACTION';
export const RFL_SET_DASHBOARD_ACTION = 'RFL_SET_DASHBOARD_ACTION';

export const RFL_ACTION_EXEC_REQUEST = 'RFL_ACTION_EXEC_REQUEST';
export const RFL_ACTION_EXEC_SUCCESS = 'RFL_ACTION_EXEC_SUCCESS';
export const RFL_ACTION_EXEC_FAILURE = 'RFL_ACTION_EXEC_FAILURE';

export const RFL_GET_BOOST_MODAL_DATA_REQUEST = 'RFL_GET_BOOST_MODAL_DATA_REQUEST';
export const RFL_GET_BOOST_MODAL_DATA_SUCCESS = 'RFL_GET_BOOST_MODAL_DATA_SUCCESS';
export const RFL_GET_BOOST_MODAL_DATA_FAILURE = 'RFL_GET_BOOST_MODAL_DATA_FAILURE';
export const RFL_RESET_BOOST_MODAL = 'RFL_RESET_BOOST_MODAL';

export const GET_REPAY_MODAL_DATA_REQUEST = 'GET_REPAY_MODAL_DATA_REQUEST';
export const GET_REPAY_MODAL_DATA_SUCCESS = 'GET_REPAY_MODAL_DATA_SUCCESS';
export const GET_REPAY_MODAL_DATA_FAILURE = 'GET_REPAY_MODAL_DATA_FAILURE';
export const RFL_RESET_REPAY_MODAL = 'RFL_RESET_REPAY_MODAL';

export const RFL_GET_AFTER_CDP_REQUEST = 'RFL_GET_AFTER_CDP_REQUEST';
export const RFL_GET_AFTER_CDP_SUCCESS = 'RFL_GET_AFTER_CDP_SUCCESS';
export const RFL_GET_AFTER_CDP_FAILURE = 'RFL_GET_AFTER_CDP_FAILURE';
