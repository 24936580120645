import React, { useState } from 'react';
import t from 'translate';
import { connect } from 'react-redux';
import Select from 'react-select';
import PropTypes from 'prop-types';
import TooltipWrapper from '../TooltipWrapper/TooltipWrapper';
import { changeSelectedCdp } from '../../actions/actionsInModals';
import OutsideAlerter from '../OutsideAlerter/OutsideAlerter';
import CdpSelectCustomIconOption from './CdpSelectCustomIconOption';
import CdpSelectCustomValueContainer from './CdpSelectCustomValueContainer';
import CdpSelectLoadingIndicator from './CdpSelectLoadingIndicator';

import './CdpSelect.scss';

const CdpSelect = ({
  cdps, cdp, labelText, customCdps, customCdp, additionalClasses,
  disabled, changeSelectedCdpFunc, changeSelectedCdp, loadingCdp,
}) => {
  const onChange = changeSelectedCdpFunc || changeSelectedCdp;
  const [open, setOpen] = useState(false);

  const formItem = additionalClasses.includes('form-item');

  const cdpsArray = customCdps.length > 0 ? customCdps : cdps;
  const loadedCdp = customCdp || cdp;

  const options = cdpsArray
    .sort((a, b) => a.id - b.id)
    .map(_cdp => ({ label: _cdp.id, value: _cdp }));

  const optionVal = options.find(({ value: { id } }) => id === loadedCdp.id);
  const customVal = {
    label: loadedCdp.id,
    value: { id: loadedCdp.id, type: loadedCdp.type, asset: loadedCdp.asset },
  };
  const value = optionVal || customVal;

  const additionalProps = {};
  if (formItem) additionalProps.menuIsOpen = open;

  const hasMoreThanOnceCdp = options.length > 1;

  let components = {
    Option: CdpSelectCustomIconOption,
    ValueContainer: CdpSelectCustomValueContainer,
  };

  if (loadingCdp) {
    components = { ...components, DropdownIndicator: CdpSelectLoadingIndicator };
  }

  const Render = () => (
    <OutsideAlerter
      className={`
        cdp-select-wrapper ${additionalClasses}
        ${formItem && open ? 'opened' : 'closed'}
        ${disabled ? 'disabled' : ''}
        ${!hasMoreThanOnceCdp ? 'single-cdp' : ''}
      `}
      onClickOutside={() => { if (formItem && open) setOpen(false); }}
      handleClick={() => { if (formItem && !disabled) setOpen(!open); }}
    >
      <div className="label">{ labelText }</div>

      {
        hasMoreThanOnceCdp && (
          <Select
            {...additionalProps}
            components={components}
            className="select box"
            classNamePrefix="select"
            value={value}
            onChange={onChange}
            options={options}
            onBlur={event => event.preventDefault()}
            isDisabled={disabled}
            isSearchable={false}
          />
        )
      }

      {
        !hasMoreThanOnceCdp && (
          <CdpSelectCustomValueContainer
            options={options}
            cx={() => {}}
            getStyles={() => {}}
            getValue={() => [value]}
          />
        )
      }
    </OutsideAlerter>
  );

  if (loadingCdp) {
    return (
      <TooltipWrapper title={t('maker.loading_cdp')}><Render /></TooltipWrapper>
    );
  }

  return <Render />;
};

CdpSelect.defaultProps = {
  labelText: `${t('maker.cdp_id')}:`,
  customCdps: [],
  customCdp: null,
  additionalClasses: '',
  disabled: false,
  changeSelectedCdpFunc: null,
  loadingCdp: false,
};

CdpSelect.propTypes = {
  cdps: PropTypes.array.isRequired,
  cdp: PropTypes.object.isRequired,
  customCdp: PropTypes.object,
  changeSelectedCdpFunc: PropTypes.func,
  changeSelectedCdp: PropTypes.func.isRequired,
  customCdps: PropTypes.array,
  labelText: PropTypes.string,
  additionalClasses: PropTypes.string,
  disabled: PropTypes.bool,
  loadingCdp: PropTypes.bool,
};

const mapStateToProps = ({ maker }) => ({
  cdps: maker.cdps,
  cdp: maker.cdp,
});

const mapDispatchToProps = {
  changeSelectedCdp,
};

export default connect(mapStateToProps, mapDispatchToProps)(CdpSelect);
