import assets from 'constants/assets';
import {
  GET_ASSET_PRICE_REQUEST,
  GET_ASSET_PRICE_SUCCESS,
  GET_ASSET_PRICE_FAILURE,
  UPDATE_ASSET_PRICE_TIMEOUT_ID,

  GET_ASSETS_BALANCE_REQUEST,
  GET_ASSETS_BALANCE_SUCCESS,
  GET_ASSETS_BALANCE_FAILURE,

  GET_ASSET_BALANCE_REQUEST,
  GET_ASSET_BALANCE_SUCCESS,
  GET_ASSET_BALANCE_FAILURE,
  UPDATE_ASSET_BALANCE_TIMEOUT_ID,

  GET_ASSETS_PRICE_REQUEST,
  GET_ASSETS_PRICE_SUCCESS,
  GET_ASSETS_PRICE_FAILURE,

  APPROVE_ADDRESS_ON_ASSET_REQUEST,
  APPROVE_ADDRESS_ON_ASSET_SUCCESS,
  APPROVE_ADDRESS_ON_ASSET_FAILURE,

  GET_IS_ADDRESS_APPROVED_ON_ASSET_REQUEST,
  GET_IS_ADDRESS_APPROVED_ON_ASSET_SUCCESS,
  GET_IS_ADDRESS_APPROVED_ON_ASSET_FAILURE,
  CLEAR_ASSET_APPROVE_ERROR,

  GET_FUTURE_PRICES_SUCCESS,

  GET_ASSET_PROXY_BALANCE_SUCCESS,

} from 'actionTypes/assetsActionTypes';
import { CONNECT_PROVIDER_SUCCESS } from '../actionTypes/generalActionTypes';

const INITIAL_STATE = assets.reduce((acc, t) => {
  acc[t.symbol] = {
    balance: '0',
    // price: '0',
    marketPrice: '0',
    compoundPrice: '0',
    aavePrice: '0',
    proxyBalance: '0',
    address: t.address,
    // makerPrice: '0', // maker.cdp.assetPrice is used
  };

  return acc;
}, {});

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ASSET_BALANCE_REQUEST:
      return {
        ...state,
        [action.asset]: {
          ...state[action.asset], address: action.address, gettingBalance: true, gettingBalanceError: '',
        },
      };

    case GET_ASSET_BALANCE_SUCCESS:
      return {
        ...state,
        [action.asset]: {
          ...state[action.asset],
          address: action.address,
          gettingBalance: false,
          gettingBalanceError: '',
          balance: payload,
        },
      };

    case GET_ASSET_BALANCE_FAILURE:
      return {
        ...state,
        [action.asset]: {
          ...state[action.asset],
          address: action.address,
          gettingBalance: false,
          gettingBalanceError: payload,
        },
      };

    case GET_ASSET_PROXY_BALANCE_SUCCESS:
      return {
        ...state,
        [action.asset]: {
          ...state[action.asset],
          proxyBalance: payload,
        },
      };

    case GET_ASSETS_BALANCE_REQUEST:
      return {
        ...state,
        ...payload.assets.reduce((acc, symbol, i) => {
          acc[symbol] = {
            ...state[symbol],
            address: payload.addresses[i],
            gettingBalance: true,
            gettingBalanceError: '',
          };
          return acc;
        }, {}),
      };

    case GET_ASSETS_BALANCE_SUCCESS:
      return {
        ...state,
        ...payload.assets.reduce((acc, symbol, i) => {
          acc[symbol] = {
            ...state[symbol],
            address: payload.addresses[i],
            gettingBalance: false,
            gettingBalanceError: '',
            balance: payload.balances[i],
          };
          return acc;
        }, {}),
      };

    case GET_ASSETS_BALANCE_FAILURE:
      return {
        ...state,
        ...payload.assets.reduce((acc, symbol, i) => {
          acc[symbol] = {
            ...state[symbol],
            gettingBalance: false,
            gettingBalanceError: payload.error,
          };
          return acc;
        }, {}),
      };

    case UPDATE_ASSET_BALANCE_TIMEOUT_ID:
      return {
        ...state, [action.asset]: { ...state[action.asset], balanceTimeoutId: payload },
      };

    case GET_ASSET_PRICE_REQUEST:
      return {
        ...state,
        [action.asset]: { ...state[action.asset], gettingPrice: true, gettingPriceError: '' },
      };

    case GET_ASSET_PRICE_SUCCESS:
      return {
        ...state,
        [action.asset]: {
          ...state[action.asset],
          gettingPrice: false,
          gettingPriceError: '',
          // price: payload,
          [`${action.priceType}Price`]: payload,
        },
      };

    case GET_ASSET_PRICE_FAILURE:
      return {
        ...state,
        [action.asset]: {
          ...state[action.asset],
          gettingPrice: false,
          gettingPriceError: payload,
        },
      };


    case GET_ASSETS_PRICE_REQUEST:
      return {
        ...state,
        ...payload.assets.reduce((acc, symbol, i) => {
          acc[symbol] = {
            ...state[symbol],
            gettingPrice: true,
            gettingPriceError: '',
          };
          return acc;
        }, {}),
      };

    case GET_ASSETS_PRICE_SUCCESS:
      return {
        ...state,
        ...payload.assets.reduce((acc, symbol, i) => {
          acc[symbol] = {
            ...state[symbol],
            gettingPrice: false,
            gettingPriceError: '',
            [`${payload.priceType}Price`]: payload.prices[i],
          };
          return acc;
        }, {}),
      };

    case GET_ASSETS_PRICE_FAILURE:
      return {
        ...state,
        ...payload.assets.reduce((acc, symbol, i) => {
          acc[symbol] = {
            ...state[symbol],
            gettingPriceError: payload.error,
          };
          return acc;
        }, {}),
      };

    case UPDATE_ASSET_PRICE_TIMEOUT_ID:
      return {
        ...state, [action.asset]: { ...state[action.asset], priceTimeoutId: payload },
      };

    case APPROVE_ADDRESS_ON_ASSET_REQUEST:
      return {
        ...state,
        [action.asset]: {
          ...state[action.asset],
          [action.propName]: true,
          [`${action.propName}Error`]: '',
        },
      };

    case APPROVE_ADDRESS_ON_ASSET_SUCCESS:
      return {
        ...state,
        [action.asset]: {
          ...state[action.asset],
          [action.propName]: false,
          [`${action.propName}Error`]: '',
          [action.isApprovedPropName]: true,
        },
      };

    case APPROVE_ADDRESS_ON_ASSET_FAILURE:
      return {
        ...state,
        [action.asset]: {
          ...state[action.asset],
          [action.propName]: false,
          [`${action.propName}Error`]: payload,
        },
      };

    case CLEAR_ASSET_APPROVE_ERROR:
      return {
        ...state,
        [action.asset]: {
          ...state[action.asset],
          [`${action.propName}Error`]: '',
        },
      };

    case GET_IS_ADDRESS_APPROVED_ON_ASSET_REQUEST:
      return {
        ...state,
        [action.asset]: {
          ...state[action.asset],
          [action.gettingPropName]: true,
          [`${action.gettingPropName}Error`]: '',
        },
      };

    case GET_IS_ADDRESS_APPROVED_ON_ASSET_SUCCESS:
      return {
        ...state,
        [action.asset]: {
          ...state[action.asset],
          [action.gettingPropName]: false,
          [`${action.gettingPropName}Error`]: '',
          [action.isApprovedPropName]: payload,
        },
      };

    case GET_IS_ADDRESS_APPROVED_ON_ASSET_FAILURE:
      return {
        ...state,
        [action.asset]: {
          ...state[action.asset],
          [action.gettingPropName]: true,
          [`${action.gettingPropName}Error`]: payload,
        },
      };

    case GET_FUTURE_PRICES_SUCCESS:
      return {
        ...state,
        [action.asset]: {
          ...state[action.asset],
          futurePrice: action.payload.futurePrice,
          futurePriceUpdateTimestamp: action.payload.futurePriceUpdateTimestamp,
        },
      };

    case CONNECT_PROVIDER_SUCCESS:
      const _state = { ...state }; // eslint-disable-line
      Object.keys(state).forEach((asset) => { _state[asset].balance = '0'; });
      return _state;

    default:
      return state;
  }
};
