/* eslint-disable */
import React from 'react';
import { v4 as uuid } from 'uuid';

export default function MultichainIcon(props) {
  const id = `multichain_icon${uuid()}`;
  const fill = `url(#${id})`;
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z" fill={fill} />
      <path fillRule="evenodd" clipRule="evenodd" d="M18.1958 11.1009C18.4978 11.1976 18.7554 11.386 19.435 12.0074C20.2282 12.7327 20.2929 12.8122 20.2929 13.0615C20.2929 13.2675 20.2454 13.3662 20.0919 13.4795C19.7001 13.7689 19.5047 13.6944 18.6937 12.9462C17.626 11.9613 17.3413 11.9311 16.501 12.713L16.0211 13.1597L15.5848 12.75L15.1485 12.3404L15.6427 11.8687C15.9145 11.6093 16.3077 11.3148 16.5167 11.2141C17.0072 10.9778 17.6713 10.9331 18.1958 11.1009ZM12.324 11.0983C12.6676 11.206 13.048 11.5377 16.175 14.4563C19.7274 17.7721 19.8254 17.8514 20.3691 17.8514C20.8635 17.8514 21.5258 17.4395 21.715 17.0144C21.9377 16.5139 21.7633 15.8068 21.3208 15.4165L21.1299 15.2482L20.864 15.4903L20.5979 15.7323V14.8233V13.9143H21.5661H22.5342L22.2811 14.1595L22.028 14.4047L22.3342 14.7502C22.9047 15.394 23 15.6433 23 16.4919C23 17.1679 22.9796 17.28 22.7969 17.6049C22.5222 18.0934 22.0199 18.5517 21.513 18.7763C21.1533 18.9355 20.9902 18.962 20.3691 18.962C19.125 18.962 19.2619 19.0592 15.3717 15.4134C13.0862 13.2715 11.9417 12.2474 11.7876 12.2068C11.4076 12.1067 11.1403 12.2366 10.498 12.8335C9.82683 13.4573 9.60393 13.5562 9.25299 13.3858C8.99829 13.2621 8.9305 13.1443 8.9305 12.8257C8.9305 12.6502 9.04923 12.4939 9.54414 12.0177C10.315 11.2763 10.7594 11.0386 11.4394 11.0043C11.7715 10.9876 12.0724 11.0195 12.324 11.0983ZM8.70173 14.6448V15.5901L8.40928 15.3221L8.11691 15.0539L7.79342 15.3834C7.26748 15.9191 7.1174 16.4785 7.35586 17.0144C7.58097 17.5202 8.34499 17.9202 8.87971 17.8122C9.35868 17.7154 9.605 17.5249 11.3142 15.9302L13.0498 14.311L13.4876 14.7228L13.9254 15.1347L12.0189 16.9135C10.2887 18.5279 10.0717 18.7064 9.67111 18.8451C9.08042 19.0495 8.25127 19.0519 7.67881 18.8507C7.09681 18.6463 6.55035 18.1633 6.24746 17.5857C6.02593 17.1633 6 17.0487 6 16.4919C6 15.9381 6.02646 15.8195 6.2412 15.4111C6.37381 15.1588 6.65559 14.7927 6.86743 14.5974L7.25245 14.2422L6.9703 13.9709L6.68807 13.6995H7.6949H8.70173V14.6448Z" fill="white"/>
      <defs>
        <linearGradient id={id} x1="15" y1="0" x2="15" y2="30" gradientUnits="userSpaceOnUse">
          <stop stopColor="#616AF2"/>
          <stop offset="1" stopColor="#343988"/>
        </linearGradient>
      </defs>
    </svg>
  );
}
