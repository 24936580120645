import { getAssetInfo } from '@defisaver/tokens';
import {
  Trade, Token, Pair, TokenAmount, currencyEquals, Percent,
} from '@sushiswap/sdk';
import { captureException } from '../sentry';
import { multicall } from './multicallService';
import { SushiViewAddress } from './contractRegistryService';
import { aggregate } from './ethService';
import { getWeiAmountForDecimals } from './utils';
import { getERC20TokenData } from './erc20Service';

const ETH = getAssetInfo('ETH');
const WETH = getAssetInfo('WETH');
const USDC = getAssetInfo('USDC');
const DAI = getAssetInfo('DAI');
const USDT = getAssetInfo('USDT');
const COMP = getAssetInfo('COMP');
const MKR = getAssetInfo('MKR');

const WETH_TOKEN = new Token(1, window._web3.utils.toChecksumAddress(WETH.address), WETH.decimals, WETH.symbol, WETH.name);
const USDC_TOKEN = new Token(1, window._web3.utils.toChecksumAddress(USDC.address), USDC.decimals, USDC.symbol, USDC.name);
const DAI_TOKEN = new Token(1, window._web3.utils.toChecksumAddress(DAI.address), DAI.decimals, DAI.symbol, DAI.name);
const USDT_TOKEN = new Token(1, window._web3.utils.toChecksumAddress(USDT.address), USDT.decimals, USDT.symbol, USDT.name);
const COMP_TOKEN = new Token(1, window._web3.utils.toChecksumAddress(COMP.address), COMP.decimals, COMP.symbol, COMP.name);
const MKR_TOKEN = new Token(1, window._web3.utils.toChecksumAddress(MKR.address), MKR.decimals, MKR.symbol, MKR.name);
const AMPL_TOKEN = new Token(1, window._web3.utils.toChecksumAddress('0xD46bA6D942050d489DBd938a2C909A5d5039A161'), 9, 'AMPL', 'Ampleforth');

const ALLOWED_TRADE_AGAINST = [WETH_TOKEN, USDC_TOKEN, DAI_TOKEN, USDT_TOKEN, COMP_TOKEN, MKR_TOKEN];

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
const CUSTOM_BASES = {
  [AMPL_TOKEN.address]: [DAI, WETH_TOKEN],
};

const isEth = (address) => address.toLowerCase() === ETH.address.toLowerCase();

const wrappedToken = (asset) => (isEth(asset.address) ? WETH_TOKEN : new Token(
  1,
  window._web3.utils.toChecksumAddress(asset.address),
  +asset.decimals,
  asset.symbol,
  asset.name,
));

const allPairCombinations = (tokenA, tokenB) => {
  const bases = ALLOWED_TRADE_AGAINST;

  const basePairs = () => bases.flatMap((base) => bases.map(otherBase => [base, otherBase])).filter(
    ([t0, t1]) => t0.address !== t1.address,
  );

  return [
    // the direct pair
    [tokenA, tokenB],
    // token A against all bases
    ...bases.map((base) => [tokenA, base]),
    // token B against all bases
    ...bases.map((base) => [tokenB, base]),
    // each base against all bases
    ...basePairs(),
  ]
    .filter((tokens) => Boolean(tokens[0] && tokens[1]))
    .filter(([t0, t1]) => t0.address !== t1.address)
    .filter(([tokenA, tokenB]) => {
      const customBases = CUSTOM_BASES;
      if (!customBases) return true;

      const customBasesA = customBases[tokenA.address];
      const customBasesB = customBases[tokenB.address];

      if (!customBasesA && !customBasesB) return true;

      if (customBasesA && !customBasesA.find(base => tokenB.equals(base))) return false;
      return !(customBasesB && !customBasesB.find(base => tokenA.equals(base)));
    });
};

const reservesFunctionAbiItem = {
  constant: true,
  inputs: [],
  name: 'getReserves',
  outputs: [
    { internalType: 'uint112', name: '_reserve0', type: 'uint112' },
    { internalType: 'uint112', name: '_reserve1', type: 'uint112' },
    { internalType: 'uint32', name: '_blockTimestampLast', type: 'uint32' },
  ],
  payable: false,
  stateMutability: 'view',
  type: 'function',
};

const multicallPairReserves = async (pairAddresses) => {
  let calls = [];

  pairAddresses.forEach((address) => {
    calls = [...calls, {
      abiItem: reservesFunctionAbiItem,
      target: address,
      params: [],
    }];
  });

  return multicall(calls);
};

const PairState = {
  LOADING: 0,
  NOT_EXISTS: 1,
  EXISTS: 2,
  INVALID: 3,
};

const getPairs = async (combinations) => {
  const pairAddresses = combinations.map(([tokenA, tokenB]) => (tokenA && tokenB && !tokenA.equals(tokenB) ? Pair.getAddress(tokenA, tokenB) : undefined));

  const results = await multicallPairReserves(pairAddresses);

  return results.map((result, i) => {
    const tokenA = combinations[i][0];
    const tokenB = combinations[i][1];

    if (!tokenA || !tokenB || tokenA.equals(tokenB)) return [PairState.INVALID, null];
    if (!result) return [PairState.NOT_EXISTS, null];
    const { _reserve0, _reserve1 } = result;
    const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA];
    return [
      PairState.EXISTS,
      new Pair(new TokenAmount(token0, _reserve0.toString()), new TokenAmount(token1, _reserve1.toString())),
    ];
  });
};

const getAllowedPairs = async (tokenA, tokenB) => {
  const combinations = allPairCombinations(tokenA, tokenB);

  const allPairs = await getPairs(combinations);

  return Object.values(
    allPairs
      // filter out invalid pairs
      .filter((result) => Boolean(result[0] === PairState.EXISTS && result[1]))
      // filter out duplicated pairs
      .reduce((memo, [, curr]) => {
        // eslint-disable-next-line no-param-reassign
        memo[curr.liquidityToken.address] =
          memo[curr.liquidityToken.address] === null || memo[curr.liquidityToken.address] === undefined
            ? curr
            : memo[curr.liquidityToken.address];
        return memo;
      }, {}),
  );
};

const isTradeBetter = (tradeA, tradeB, minimumDelta) => {
  if (tradeA && !tradeB) return false;
  if (tradeB && !tradeA) return true;
  if (!tradeA || !tradeB) return undefined;

  if (
    tradeA.tradeType !== tradeB.tradeType ||
    !currencyEquals(tradeA.inputAmount.currency, tradeB.inputAmount.currency) ||
    !currencyEquals(tradeB.outputAmount.currency, tradeB.outputAmount.currency)
  ) {
    throw new Error('Trades are not comparable');
  }

  if (minimumDelta.equalTo(new Percent('0'))) {
    return tradeA.executionPrice.lessThan(tradeB.executionPrice);
  }
  return tradeA.executionPrice.raw.multiply(minimumDelta.add(new Percent('1'))).lessThan(tradeB.executionPrice);
};

/**
 *
 * @param {string} amount
 * @param {string} fromAsset
 * @param {string} toAsset
 * @param {boolean} shouldSell
 * @param {number} maxHops
 * @return {Promise<string[]>}
 */
export const getSushiswapRoute = async (amount, fromAsset, toAsset, shouldSell, maxHops = 3) => {
  try {
    if (!amount || !fromAsset || !toAsset) return [];
    const assetIn = await getERC20TokenData(window._web3.utils.toChecksumAddress(fromAsset));
    const assetOut = await getERC20TokenData(window._web3.utils.toChecksumAddress(toAsset));

    const tokenIn = wrappedToken(assetIn);
    const tokenOut = wrappedToken(assetOut);

    const allowedPairs = await getAllowedPairs(tokenIn, tokenOut);

    const amountIn = new TokenAmount(tokenIn, getWeiAmountForDecimals(amount, assetIn.decimals));
    const amountOut = new TokenAmount(tokenOut, getWeiAmountForDecimals(amount, assetOut.decimals));

    let bestTradeSoFar = null;

    for (let i = 1; i <= maxHops; i++) {
      let trade;
      if (shouldSell) {
        trade = Trade.bestTradeExactIn(
          allowedPairs,
          amountIn,
          tokenOut,
          { maxHops: i, maxNumResults: 3 },
        )[0];
      } else {
        trade = Trade.bestTradeExactOut(
          allowedPairs,
          tokenIn,
          amountOut,
          { maxHops: i, maxNumResults: 3 },
        )[0];
      }
      const currentTrade = trade === null || trade === undefined ? null : trade;
      // if current trade is best yet, save it
      if (isTradeBetter(bestTradeSoFar, currentTrade, new Percent('50', '10000'))) {
        bestTradeSoFar = currentTrade;
      }
    }

    if (bestTradeSoFar && bestTradeSoFar.route && bestTradeSoFar.route.path) {
      return bestTradeSoFar.route.path.map(item => item.address);
    }
    return [];
  } catch (error) {
    console.log(error);
    captureException(error);
    return [];
  }
};


export const getSushiDataForPools = async (tokens) => {
  const multicallObject = tokens.map(token => ({
    target: SushiViewAddress,
    call: ['getPairInfo(address)(address,address,uint112,uint112)', token.address],
    returns: [
      [`token0Address${token.address}`, val => val.toString()],
      [`token1Address${token.address}`, val => val.toString()],
      [`reserve0${token.address}`, val => val.toString()],
      [`reserve1${token.address}`, val => val.toString()],
    ],
  }));

  const res = await aggregate(multicallObject);

  const {
    results: {
      transformed,
    },
  } = res;
  return tokens.map(token => ({
    address: token.address,
    tokens: [transformed[`token0Address${token.address}`], transformed[`token1Address${token.address}`]],
    reserves: [transformed[`reserve0${token.address}`], transformed[`reserve1${token.address}`]],
  }));
};
