import Dec from 'decimal.js';
import {
  LiquityCollSurplusPoolAddress,
  LiquityHintHelpersContract,
  LiquityPriceFeedAddress,
  LiquitySortedTrovesContract,
  LiquityTroveManagerAddress,
  LiquityTroveManagerContract,
  LiquityViewAddress,
  LiquityViewContract,
} from '../contractRegistryService';
import { MAXUINT, ZERO_ADDRESS } from '../../constants/general';
import { LIQUITY_MIN_DEBT_LUSD, LIQUITY_NORMAL_MODE_RATIO, LIQUITY_TROVE_STATUS_ENUM } from '../../constants/liquity';
import { aggregate, ethToWei, weiToEth } from '../ethService';

/**
 * @typedef {Object} LiquityTroveInfo
 * @property {string} asset
 * @property {string} collateral
 * @property {string} collateralUsd
 * @property {string} assetPrice
 * @property {string} debtAsset
 * @property {string} debtInAsset
 * @property {string} debtUsd
 * @property {string} debtAssetPrice
 * @property {string} liquidationPrice
 * @property {number} ratio
 * @property {number} liqPercent - Replacing liqRatio * 100
 * @property {number} minDebt
 * @property {number} lastUpdated
 * @property {*} troveStatus
 * @property {*} claimableCollateral
 * @property {string} balance
 */

/**
 * @typedef {Object} LiquityPartialTroveInfo
 * @property {string} collateral
 * @property {string} debtInAsset
 * @property {string} troveStatus
 * @property {string} claimableCollateral
 * @property {string} assetPrice
 */

/**
 * Returns expected borrowing fee in wei
 *
 * @param lusdAmountWei {string}
 * @return {Promise<String>}
 */
export const getExpectedFee = async (lusdAmountWei) => {
  const troveManagerContract = LiquityTroveManagerContract();
  return troveManagerContract.methods.getBorrowingFeeWithDecay(lusdAmountWei).call();
};

export const getRedemptionFee = async (ethAmountWei) => {
  const troveManagerContract = LiquityTroveManagerContract();
  return troveManagerContract.methods.getRedemptionFeeWithDecay(ethAmountWei).call();
};

/**
 *
 * @param address
 * @param accumulatedSum
 * @param iterations
 * @return {Promise<{ debt: String, next: String }>}
 * @private
 */
export const _getDebtInFront = async (address, accumulatedSum = '0', iterations = 2000) => {
  const liquityViewContract = LiquityViewContract();
  return liquityViewContract.methods.getDebtInFront(address, accumulatedSum, iterations).call();
};

/**
 *
 * @param address
 * @param accumulatedSum
 * @param iterations
 * @return {Promise<String>}
 */
export const getDebtInFront = async (address, accumulatedSum = '0', iterations = 2000) => {
  const { debt, next } = await _getDebtInFront(address, accumulatedSum, iterations);
  if (next === ZERO_ADDRESS) return debt;
  return getDebtInFront(next, debt, iterations);
};

/**
 * Returns expected borrowing rate with decay in wei
 *
 * @return {Promise<String>}
 */
export const getBorrowingRateWithDecay = async () => {
  const troveManagerContract = LiquityTroveManagerContract();
  return troveManagerContract.methods.getBorrowingRateWithDecay().call();
};

/**
 * Returns expected redemption rate with decay in wei
 *
 * @return {Promise<String>}
 */
export const getRedemptionRateWithDecay = async () => {
  const troveManagerContract = LiquityTroveManagerContract();
  return troveManagerContract.methods.getRedemptionRateWithDecay().call();
};

/**
 *
 * @param decay
 * @return {{amount: string, showWarning: boolean}}
 */
export const getFeePercentage = (decay) => {
  const maxFeePercentage = ethToWei('0.05');
  const overestimatedDecay = new Dec(decay).times('1.2').floor().toString();
  const showWarning = new Dec(overestimatedDecay).gt(ethToWei('0.01'));
  if (new Dec(overestimatedDecay).gt(maxFeePercentage)) return { amount: maxFeePercentage, showWarning };
  return { amount: overestimatedDecay, showWarning };
};

/**
 *
 * @return {Promise<{amount: string, showWarning: boolean}>}
 */
export const getBorrowingFeePercentage = async () => {
  const data = getFeePercentage(await getBorrowingRateWithDecay());
  return { amount: weiToEth(data.amount), showWarning: data.showWarning };
};

/**
 *
 * @return {Promise<{amount: string, showWarning: boolean}>}
 */
export const getRedemptionFeePercentage = async () => {
  const data = getFeePercentage(await getRedemptionRateWithDecay());
  return { amount: weiToEth(data.amount), showWarning: data.showWarning };
};

/**
 * Gets multiple troves at once
 *
 * @param addresses
 * @return {Promise<LiquityPartialTroveInfo[]>}
 */
export const getTroveInfoMulticall = async (addresses) => {
  const _addresses = addresses.filter(i => i);
  const addressesObject = _addresses.filter(i => i).map((address, index) => ([
    {
      target: LiquityViewAddress,
      call: ['getTroveInfo(address)(uint256,uint256,uint256,uint256,uint256,bool)', address],
      returns: [
        [`troveStatus${index}`, val => LIQUITY_TROVE_STATUS_ENUM[+val.toString()]],
        [`collateral${index}`, val => weiToEth(val.toString())],
        [`debtInAsset${index}`, val => weiToEth(val.toString())],
        [`assetPrice${index}`, val => weiToEth(val.toString())],
        [`TCRatio${index}`, val => weiToEth(val.toString())],
        [`recoveryMode${index}`, val => val],
      ],
    },
    {
      target: LiquityCollSurplusPoolAddress,
      call: ['getCollateral(address)(uint256)', address],
      returns: [
        [`claimableCollateral${index}`, val => weiToEth(val.toString())],
      ],
    },
    {
      target: LiquityTroveManagerAddress,
      call: ['getBorrowingRateWithDecay()(uint256)'],
      returns: [
        [`borrowingRateWithDecay${index}`, val => weiToEth(+val.toString() * 100)],
      ],
    },
    {
      target: LiquityPriceFeedAddress,
      call: ['fetchPrice()(uint256)'],
      returns: [
        [`price${index}`, val => weiToEth(val.toString())],
      ],
    },
  ]
  )).flat();

  const res = await aggregate(addressesObject);
  const { results: { transformed } } = res;
  return _addresses.map((a, index) => ({
    troveStatus: transformed[`troveStatus${index}`],
    collateral: transformed[`collateral${index}`],
    debtInAsset: transformed[`debtInAsset${index}`],
    assetPrice: transformed[`price${index}`],
    TCRatio: transformed[`TCRatio${index}`],
    recoveryMode: transformed[`recoveryMode${index}`],
    claimableCollateral: transformed[`claimableCollateral${index}`],
    borrowingRateWithDecay: transformed[`borrowingRateWithDecay${index}`],
  }));
};

/**
 * Gets trove info for the given address
 *
 * @param address
 * @return {Promise<{assetPrice: (*|string), recoveryMode: (boolean|*), debtInAsset: (*|string), collateral: (*|string), TCRatio: (*|string)}>}
 */
export const getTroveInfo = async (address) => {
  const liquityViewContract = LiquityViewContract();
  const data = await liquityViewContract.methods.getTroveInfo(address).call();
  return {
    troveStatus: LIQUITY_TROVE_STATUS_ENUM[+data.troveStatus],
    recoveryMode: data.recoveryMode,
    collateral: weiToEth(data.collAmount),
    debtInAsset: weiToEth(data.debtAmount),
    assetPrice: weiToEth(data.collPrice),
    TCRatio: weiToEth(data.TCRatio),
  };
};

export const getRedemptionHints = async (lusdAmount, price, iterations = 50) => {
  const hintHelpersContract = LiquityHintHelpersContract();
  return hintHelpersContract.methods.getRedemptionHints(lusdAmount, price, iterations).call();
};

export const getApproxHint = async (NICR, numTrials, randomSeed = 42) => {
  const hintHelpersContract = LiquityHintHelpersContract();
  return hintHelpersContract.methods.getApproxHint(NICR, numTrials, randomSeed).call();
};

export const findInsertPositionFromContract = async (NICR, hintAddress1, hintAddress2) => {
  const sortedTrovesContract = LiquitySortedTrovesContract();
  return sortedTrovesContract.methods.findInsertPosition(NICR, hintAddress1, hintAddress2).call();
};

export const getNumberOfTroves = async () => {
  const sortedTrovesContract = LiquitySortedTrovesContract();
  return sortedTrovesContract.methods.getSize().call();
};

export const getNumTrials = async (multiplier = 15) => {
  const numTroves = await getNumberOfTroves();
  return new Dec(numTroves).squareRoot().mul(multiplier).toFixed(0)
    .toString();
};

const fixFindInsertPosBug = async (insertPos) => {
  console.log('HAS_BUG => fixFindInsertPosBug');
  const sortedTrovesContract = LiquitySortedTrovesContract();
  const upperHint = await sortedTrovesContract.methods.getPrev(insertPos.upperHint).call();
  return {
    upperHint,
    lowerHint: insertPos.lowerHint,
  };
};

export const findInsertPosition = async (collAmountWei, debtAmountWei, address, numOfTrials = 400, randomSeed = 42) => {
  const liquityViewContract = LiquityViewContract();
  console.time('findInsertPosition');
  const insertPos = await liquityViewContract.methods.getInsertPosition(collAmountWei, debtAmountWei, numOfTrials, randomSeed).call();
  console.timeEnd('findInsertPosition');
  if (insertPos.upperHint.toLowerCase() === address.toLowerCase() && insertPos.lowerHint === ZERO_ADDRESS) {
    return fixFindInsertPosBug(insertPos);
  }
  return insertPos;
};

export const _findInsertPosition = async (collateralAmountWei, debtAmountWei, address) => {
  const NICR = new Dec(debtAmountWei).gt(0)
    ? new Dec(collateralAmountWei).mul(1e20).div(debtAmountWei)
      .toFixed(0)
      .toString()
    : MAXUINT;
  const numTrials = await getNumTrials();
  const { hintAddress } = await getApproxHint(NICR, numTrials, 42);
  const insertPosition = await findInsertPositionFromContract(NICR, hintAddress, hintAddress);
  if (insertPosition[0].toLowerCase() === address.toLowerCase() && insertPosition[1] === ZERO_ADDRESS) {
    return fixFindInsertPosBug({
      upperHint: insertPosition[0],
      lowerHint: insertPosition[1],
    });
  }
  return {
    upperHint: insertPosition[0],
    lowerHint: insertPosition[1],
  };
};
/**
 * @param {LiquityPartialTroveInfo} troveInfo
 * @param {number} minCollateralRatio
 * @param {string} debtAssetPrice
 * @returns {LiquityTroveInfo}
 */
export const getTrovePayload = (troveInfo, minCollateralRatio, debtAssetPrice = '1') => {
  const liquidationPrice = new Dec(troveInfo.debtInAsset).times(debtAssetPrice)
    .times(minCollateralRatio / 100)
    .div(troveInfo.collateral)
    .toNumber();

  const collateralUsd = new Dec(troveInfo.collateral).times(troveInfo.assetPrice)
    .toString();

  const ratio = troveInfo.debtInAsset && troveInfo.debtInAsset !== '0'
    ? new Dec(troveInfo.collateral).times(troveInfo.assetPrice)
      .dividedBy(troveInfo.debtInAsset)
      .times(100)
      .toString()
    : '0';

  return {
    asset: 'ETH',
    collateral: troveInfo.collateral,
    collateralUsd,
    assetPrice: troveInfo.assetPrice,
    debtAsset: 'LUSD',
    debtInAsset: troveInfo.debtInAsset,
    debtUsd: new Dec(troveInfo.debtInAsset).times(debtAssetPrice)
      .toString(),
    debtAssetPrice,
    liquidationPrice: liquidationPrice.toString(),
    ratio,
    liqPercent: minCollateralRatio,
    minDebt: LIQUITY_MIN_DEBT_LUSD,
    troveStatus: troveInfo.troveStatus,
    claimableCollateral: troveInfo.claimableCollateral,
    balance: new Dec(collateralUsd).minus(troveInfo.debtInAsset)
      .toString(),
    lastUpdated: Date.now(),
  };
};
export const getMockedTrove = (ethPrice) => getTrovePayload(
  {
    assetPrice: ethPrice, collateral: 0, debtInAsset: 0, troveStatus: 'active', claimableCollateral: 0,
  },
  LIQUITY_NORMAL_MODE_RATIO,
);
