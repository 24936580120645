/* eslint-disable */

export default {
  common: {
    loading: '加载中',
    loading_no_dots: '加载中',
    load: '加载',
    loading_asset_data: '正在加载资产数据',
    loading_positions: 'Loading positions...',
    create: '创建',
    creating: '正在创建',
    generate: '生成',
    generating: '正在生成',
    connect: '连接',
    connecting: '正在连接',
    connected: '已连接',
    buy: '购买',
    buying: '正在购买',
    enable: '启用',
    enabling: '启用中',
    exchange: '交易',
    exchanging: '正在交易',
    supply: '供应',
    supplying: '供应中',
    borrow: '贷款',
    borrowing: '贷款中',
    borrow_desc: 'Borrowing lets you deposit and borrow funds in one transaction. All borrowed funds are sent to your account.',
    supplied: '已供应',
    withdraw: '取款',
    withdrawing: '取款中',
    transfer: '转移',
    transferring: '正在转移',
    boost: '加杠杆',
    boosting: '加杠杆中',
    repay: '偿还',
    repaying: '偿还中',
    repay_from: 'Repay from:',
    payback: '还款',
    pay_back: 'Pay back',
    keep_ratio: 'Keep ratio',
    sending: '正在发送',
    send: '发送',
    sent: 'Sent',
    subscribe: '订阅',
    subscribing: '订阅中',
    paying_back: '还款中',
    add_collateral: '添加抵押物',
    adding_collateral: '抵押物添加中',
    migrate: '迁移',
    migrating: '迁移中',
    move: '移动',
    moving: '移动中',
    after: '之后',
    before: 'Before',
    manage: '管理',
    success: '成功！',
    coming_soon: '%feature 即将到来。。。',
    short: '做空',
    leverage: '杠杆',
    leverage_desc: 'Leveraging lets you create a leveraged position in one transaction. All borrowed funds are used to buy more of the used collateral asset.',
    in_a_week: '一周内',
    in_a_month: '一个月内',
    in_a_year: '一年内',
    farm: 'Farm',
    farm_desc: 'Farming lets you create a same asset leveraged position that maximizes rewards received from the protocol.',
    price: '价格',
    collateral_factor: '抵押因素',
    collateral_factor_short: '抵押因素',
    market_liquidity: '市场流动性',
    utilization: '利用率',
    utilization_short: '利用率',
    utilization_desc: 'Percentage of market supply utilized by borrowers',
    market_size: '市场规模',
    market_size_short: '市场规模',
    market_info: '市场信息',
    insured: '被保险：',
    supply_rate: '供应率：',
    borrow_rate: '借款率：',
    choose_asset: '选择资产：',
    stability_fee: '稳定费用',
    liquidation_price: '清算价格',
    liquidation_price_short: 'Liq price:',
    trade_size_impact: '交易规模价格影响',
    trade_size_impact_info: '当前市场价格与交易规模造成的估价之间的差异',
    slippage_tolerance_info: '如果价格变动超过此百分比，您的交易将恢复至原状态',
    from_source_info: 'Choose from which balance should asset come',
    to_source_info: 'Choose to which balance should asset be sent',
    buying_asset_at: '正在购买 %asset',
    action: '操作',
    overview: '概览',
    ratio: '比率',
    pages: '页面：',
    choose: '选择',
    debt: '债务',
    account_type: '帐户类型：',
    path: '路径：',
    minimum_amount: '最低金额：',
    from_token: '来自%token',
    to_token: '至 %token',
    to: '至',
    from: '来自',
    amount: '数额',
    name: '名称',
    balance: '余额',
    current_balance: 'Current balance',
    approve: '核准',
    approving: '核准中',
    approved: '已核准',
    remove_approval: '移除批准',
    removing_approval_on: '正在移除批准于',
    close: '关闭',
    closing: '关闭中',
    logging_in: '登录中...',
    safe: '安全',
    risky: '有风险',
    gamble: '高风险',
    amount_of_token: '%token 的数量：',
    token_amount_in: '%second_token 中的 %first_token 金额：',
    executing_action: '正在执行操作',
    new_owner: '新的所有者',
    contact_us: '联系我们',
    with: '与',
    at_price: '按价格',
    automate: '自动处理',
    reminder: '提醒',
    info: '信息',
    legacy: 'Legacy',
    live: 'Live',
    custom_type: '自定义类型',
    max: '最大值',
    min: '最小值',
    max_val: '最大值 %value',
    min_val: '最小值 %value',
    email: '电子邮件',
    email_address: '邮件地址',
    follow_us: '关注我们：',
    protocols: '协议',
    wallets: '钱包',
    repay_to: '偿还至',
    ratio_over: '比率超过',
    boost_to: '加杠杆到',
    current_price: '当前价格',
    collateral: '抵押物',
    collateral_in: '抵押在',
    set_up: '设置',
    how_does_it_work: '它是如何工作的？',
    history: '历史记录',
    type: '类型',
    date: '日期',
    transaction: '交易',
    load_more: '加载更多',
    protection: '保护',
    update: '更新',
    updating: '更新中',
    disable: '停用',
    disabling: '停用中',
    advanced: '高级',
    cancel: '取消',
    message: '消息',
    accept: '接受',
    import: '导入',
    importing: '导入中',
    soon: '立即',
    no_notifications_received: '未收到通知',
    go_back_to_dashboard: '返回到控制面板',
    back_to_dashboard: 'Back to dashboard',
    your_feedback_helps_us: '您的反馈有助于我们改善DeFi Saver',
    create_dsproxy: '创建DS代理',
    for: 'for',
    account: '帐户',
    ds_proxy: 'DS代理',
    upgrade: '升级',
    upgrading: '升级中',
    migration: '迁移',
    your_asset_balance: '您的 %aset 余额',
    ratio_after: '变动后比率',
    convert: '转换',
    converting: '转换中',
    back: '后退',
    next: '下一步',
    borrowed: '已借贷',
    select: '选择',
    selected: '已选择',
    subscribed: '已订阅',
    confirm: '确认',
    continue: '继续',
    next_price: '下个价格',
    next_price_explanation: 'MakerDAO 将在 %timestamp 之后用这个价格更新\n下一个价格：$%price\n下一个比率： %ratio%',
    settings: '设置',
    required: '必填',
    received: '已接收',
    exposure: '敞口',
    borrowed_asset: '已借出的 %token',
    set_up_later: '稍后设置',
    claim: '获取',
    claiming: '获取中',
    claimable: '可在',
    bid: '出价',
    bidding: '出价中',
    insufficient_balance: '余额不足',
    empty: '空的',
    learn_more: '了解更多',
    warning: '警告',
    token_swap: 'Token 兑换',
    asset: '资产',
    earned: '已赚取',
    limit: '限制',
    total: '总计',
    supply_apy: '供应 APY',
    comp_supply_apy: 'COMP 供应 APY',
    supply_apy_short: '供应 APY',
    borrow_apy: '贷款 APY',
    comp_borrow_apy: 'COMP 贷款 APY',
    borrow_apy_short: '贷款 APY',
    stats: '统计信息',
    address: '地址',
    in_total: '总计',
    contract: '合约',
    notifications: '通知',
    safety_ratio: '安全比率',
    safety_ratio_desc: '您的借款限额与借贷资金的比率低于liqPercent%将被清算',
    deposit: 'Deposit',
    automation: '自动化',
    wallet_type: '钱包类型',
    account_capital: '帐户',
    enabled: '启用',
    disabled: '停用',
    total_balance: '总余额',
    unavailable: '不可用',
    large_tsi_warning: '由于交易的规模，您将获得一个不利的价格 (%tsi% 低于市场价格)。请考虑使用较低金额。',
    large_tsi_warning_exact_amount: '由于交易的规模，您将获得一个不利的价格 (%tsi% 低于市场价格)。',
    accrued: '应计',
    protocol: 'Protocol',
    swap: 'Swap',
    swapping: 'Swapping',
    leveraging: 'Leveraging',
    liquidation: 'Liquidation',
    locked: 'Locked',
    unlocked: 'Unlocked',
    unlock: 'Unlock',
    liquidation_price_desc: 'Collateral price below which your position will face liquidation.',
    leveraged_long_position_desc: 'Your position is currently long %asset and will benefit from %asset going up in value.',
    leveraged_short_position_desc: 'Your position is currently short %asset and will benefit from %asset going down in value.',
    create_and_migrate: 'Create and migrate',
    received_asset: 'Received %asset',
    debt_available_from: '从资产中可获取%debtAsset',
    debt_available_from_desc: '根据全球协议债务上限，可从使用的抵押品类型生成的 %debtAsset 的量',
    add_liquidity: 'Add liquidity',
    cancel_transaction: 'Cancel',
    contract_creation: 'Create contract',
    contract_execution: 'Execute contract',
    send_eth: 'Sent',
    receive_eth: 'Received',
    remove_liquidity: 'Remove liquidity',
    mint: 'Mint',
    token_transfer: 'Token transfer',
    transfers: 'Transfers',
    receiver: 'Receiver',
    tx_hash: 'Tx hash',
    tx_fee: 'Tx fee',
    nonce: 'Nonce',
    block: 'Block',
    burn: 'Burn',
    emergency: 'Emergency',
    stake: 'Stake',
    staking: 'Staking',
    unstaking: 'Unstaking',
    swap_and_burn: 'Swap and burn',
    vote: 'Vote',
    add: 'Add',
    add_pool: 'Add pool',
    allow: 'Allow',
    collect: 'Collect',
    finalize: 'Finalize',
    join_pool: 'Join pool',
    delegate: 'Delegate',
    execute: 'Execute',
    execute_recipe: 'Execute recipe',
    propose: 'Propose',
    queue: 'Queue',
    trade: 'Trade',
    transfer_from: 'Transfer from',
    init: 'Initialize',
    lock: 'Lock',
    open: 'Open',
    wipe: 'Wipe',
    exit: 'Exit',
    deny: 'Deny',
    batch: 'Batch',
    sell: 'Sell',
    sold: 'Sold',
    claim_and_sell: 'Claim and sell',
    remove: 'Remove',
    redeem: 'Redeem',
    redeeming: 'Redeeming',
    shift: 'Shift',
    unsubscribe: 'Unsubscribe',
    stop: 'Stop',
    set_owner: 'Set owner',
    set_name: 'Set name',
    set_authority: 'Set authority',
    push: 'Push',
    start: 'Start',
    pull: 'Pull',
    set_cache: 'Set cache',
    enter: 'Enter',
    draw: 'Draw',
    give: 'Give',
    long_loading: 'This might take a while',
    error: 'Error',
    welcome: 'Welcome',
    defi_saver: 'DeFi Saver',
    share: 'Share',
    docs: 'Docs',
    simulation: 'Simulation',
    deadline_info: 'The transaction will revert if not confirmed within selected deadline',
    unstake: 'Unstake',
    staked: 'Staked',
    authorize_dsproxy:'Authorize DSProxy',
    migrate_position:'Migrate position',
    manage_wallets: 'Manage Wallets',
    additional_info: 'Additional info',
    with_swap_rate: 'at rate',
    from_account: 'from account',
    to_account: 'to account',
    depositing: 'Depositing',
    repay_details: 'Repay details',
    boost_details: 'Boost details',
    deadline: 'Deadline',
    and: 'and',
    you_pay: 'You pay',
    you_receive: 'You receive',
    import_token: 'Import token',
    no_token: 'No token found',
    sending_funds: 'Sending funds',
    sending_to: 'Sending to',
    summary:'Summary',
    positions:'Positions',
    token:'Token',
    tokens:'Tokens',
    complexity:'Complexity',
    day:'day',
    days:'days',
    low:'Low',
    medium:'Medium',
    high:'High',
    actions:'Actions',
    liquidation_protection: '清算保护:',
    if_below: '如果低于',
    leverage_increase: '杠杆增加:',
    if_above: '如果超过',
    position_info: 'Position Info',
    token_approvals:'Token approvals',
    min_debt:'Min. debt',
    debt_ceiling:'Debt ceiling',
    liquidation_fee:'Liquidation fee',
    zero_fee_notice: 'No fee is taken by DeFi Saver for this action.',
    bridge: 'Bridge',
    bridging: 'Bridging',
    you_send: 'You send',
    filters: 'Filters',
  },
  account: {
    create_position: 'All positions at DeFi Saver are created on a Smart Wallet in order to enable advanced features. %missing_sw',
    missing_sw: 'You currently don\'t have a Smart Wallet and the first, required step in the process will be to create one.',
    connect_wallet: '连接您的钱包',
    connect_message: '连接%item',
    secure_way: '这是一种访问钱包的安全方式',
    account_connected: '此帐户目前已连接',
    load_accounts: '%s更多账户',
    user_wallet: '智能钱包',
    savings_user_wallet_info: '智能储蓄中的资金由您的个人智能钱包控制，这是一个标准的MakerDAO DS代理钱包，能更好地与借贷协议交互。',
    savings_user_wallet_info_2: '使用智能钱包(DS代理)，您的资金在其他控制面板上不可见(例如Compound控制面板)。',
    compound_user_wallet_info: '通过您的智能钱包使用Compound可以进行高级操作，如加杠杆、偿还和闪电贷。 智能钱包是一个标准的 MakerDAO DS代理钱包，能更好地与借贷协议交互。',
    compound_user_wallet_info_2: '这些资金在Compound控制面板上不可见。',
    wallet_info_learn_more: '了解更多关于智能钱包的信息',
    user_wallet_address_info: '请不要直接向此地址发送资金。',
    create_user_wallet: '创建智能钱包',
    create_user_wallet_to_continue: 'Create Smart Wallet to continue',
    creating_user_wallet: '正在创建智能钱包',
    user_wallet_information: '智能钱包信息',
    create_ds_proxy: '创建 DS代理',
    account_found: '%type 帐户已连接 %account',
    monitor_account_modal: '无需连接您的钱包，只需输入您的地址即可追踪您的资产和去中心化金融投资组合。',
    address_or_ens: '以太坊地址或ENS',
    use_demo_address: '使用demo地址',
    demo_address: 'test.defisaver.eth',
    enter_address_to_monitor: '或者输入您的地址来监控您的投资组合',
    debugging_account: '已加载调试账户',
    monitoring_account: '已加载用于投资组合监控的帐户',
    your_smart_wallet: '您的智能钱包',
    switch_to_smart_wallet: '切换至智能钱包',
    migration_unavailable: '迁移不可用',
    user_wallet_description_blocker: '为了使用 %protocol 的高级功能，您首先需要创建一个智能钱包 —— 您的个人智能合约钱包可以使用高级功能。 请注意，使用智能钱包，您将无法与其他 app 兼容(如 %protocol 的默认 app)。',
    repay_remainder_returned: 'Remainder of collateral will be returned as %token',
    repay_remainder_returned_desc: 'If debt is fully paid off by Repay, the remainder of the Repay amount will be returned to your wallet as %token.',
    repay_action_item_desc: 'Withdraw %supply → Swap → Payback %debt',
    boost_action_item_desc: 'Borrow %debt → Swap → Supply %supply',
    repay_action_item_same_asset_desc: 'Withdraw %asset → Payback %asset',
    boost_action_item_same_asset_desc: 'Borrow %asset → Supply %asset',
    smart_wallet_create_modal_info1:'Some of our advanced features include interactions with multiple smart contracts in one transaction, which is not possible when using a standard Ethereum account.',
    smart_wallet_create_modal_info2:'Smart Wallet is your personal smart contract proxy wallet that holds your funds and makes these advanced features possible.',
  },
  maker: {
    create_cdp: '创建 CDP',
    loading_cdp: '正在加载 CDP',
    cdp_id: '当前 CDP ID',
    cdp_lower_bound: '如果低于 %lower_bound% 偿还至 %lowerbound_target%',
    cdp_upper_bound: '如果超过 %upper_bound% ，则加杠杆至 %upperbound_target%',
    no_cdp_found: '未找到 CDP',
    close_cdp: '关闭 CDP',
    transfer_cdp: '转移CDP',
    migrate_cdp: '迁移CDP',
    transfer_cdp_description: '正在转移您的 CDP 从您当前的地址移动到所选地址。 一旦转移完成，当前地址不再有所有权或管理权。',
    transfer_cdp_automation: '请注意转移后您需要从新地址重新启用自动化。',
    transfer_cdp_warning: 'Once completed this action cannot be undone. Please manually check the entered data:',
    close_cdp_automation: '此操作会使您无法正常使用自动配置。如果您决定再次使用此CDP，需要您重启自动化。',
    create_cdp_description: 'Creating a CDP allows you to generate DAI by depositing one of supported collateral types',
    minimum_ratio: '最低接受利率为 %min_ratio。\n我们建议超过  %suggested_ratio 以保持 CDP 的清算安全性。',
    ratio_warning: '您的比率必须超过最小比率',
    tsi_too_high: '交易规模影响较大',
    generate_description: '从您的 CDP 中生成更多 %daiLabel',
    withdraw_description: '从您的 CDP 中取出 %asset 抵押品',
    boost_description: '在一个交易中便可完成生成 %daiLabel 去购买 %asset 并将其添加到您的 CDP 中这三步操作。',
    boost_modal_description: '加杠杆是从您的 CDP 中生成 %daiLabel 以获取更多的 %asset。这会以提高清算价格和降低担保比率为代价，增加了你的杠杆。',
    payback_description: '偿还您的 CDP %daiLabel 债务',
    add_collateral_description: '在您的 CDP 中添加更多的 %asset 抵押品',
    repay_description: '在一个交易中便可完成取出 %asset 购买 %daiLabel 并偿还债务这三步操作。',
    repay_modal_description: '偿还是使用您 CDP 中的 %asse 偿还 %daiLabel 债务。这会降低您的清算价格并提高您的抵押率。',
    all_debt_repay: '所有债务将被偿还，额外的 DAI 将被退回给您',
    migrate_description: '迁移能让您与 DeFi Saver 平台交互。 通过迁移，您将使用标准的 MakerDAO DS代理接口，并且也能与其他 CDP 平台进行交互。',
    saver_subscribe: '正在启用 CDP 自动化',
    saver_unsubscribe: '正在停用CDP自动化',
    no_cdp_blocker: 'There doesn\'t seem to be a MakerDAO CDP (Vault) associated with this account. You can create a MakerDAO CDP now to borrow DAi or leverage assets using our signature Boost and Repay features.',
    cdp_automation: 'CDP 自动化',
    cdp_automation_description: '将您的 CDP 保持在某一比率，避免因市场变化导致清算或增加杠杆。',
    no_actions_executed: '尚未执行任何操作。',
    cdp_automation_status: 'CDP 自动化状态',
    keep_ratio_at: '保持比率在：',
    if_ratio_bellow: '如果比率低于：',
    repay_it_to: '偿还至:',
    will_hit_ratio_at: '将在 %value 触达此比率',
    if_ratio_goes_over: '如果比率超过：',
    boost_it_to: '加杠杆至：',
    automation_boost_disclaimer: '只有交易费低于提升额的10%时才能进行自动加杠杆。',
    approx_sold_for: '大约 %sold 将以 %bought 的价格出售',
    cdp_automation_setup: 'CDP 自动设置',
    cdp_automation_setup_description: '一旦启用，DeFi Saver将监控您的 CDP 比率，并在您的 CDP 达到设定下限时自动激活偿还， 当达到上限时加杠杆。 您只需输入目标比率，或在下方手动设置。',
    loading_saver_data: '正在加载自动化数据...',
    automatic_boost_will_be_triggered: '启用当前参数自动化后自动加杠杆',
    automatic_repay_will_be_triggered: '自动偿还将在启用当前参数时被触发',
    automation_form_beta_warning: '该功能还在测试阶段。使用风险自担。',
    import_description: 'Using this tool will migrate your CDP from the proprietary InstaDApp proxy wallet to the standard Maker DSProxy. \n\n' +
      'By migrating you gain access to our advanced features such as Automation, Boost and Repay, as well as other dashboards such as Maker\'s Oasis app and Zerion.\n\n' +
      'Once you migrate your CDP to a DSProxy you will not be able to use InstaDApp again unless you migrate back to their proxy wallet.',
    import_cdp: '导入 CDP',
    migrating_cdp_from_instadapp: '从 InstADApp 迁移 CDP',
    cdp_managed_by_instadapp: '该 CDP 目前在 InstaDApp 代理钱包中。',
    why_disabling_automation: '您能告诉我们为什么要停用自动化吗？',
    saver_unsubscribe_reason_1: '我想在我当前的自动化配置外进行调整。',
    saver_unsubscribe_reason_5: '其他',
    saver_unsubscribe_reason_6: 'I\'m closing my position',
    saver_unsubscribe_reason_7: 'I\'m moving funds to a different protocol',
    saver_unsubscribe_reason_8: 'I have questions about the Automation system',
    liquidation_protection_if_bellow: '清算保护：如果低于',
    leverage_increase_if_above: '杠杆增加：如果超过',
    liquidation_ratio: '清算比率',
    min_ratio: '最小比率',
    ratio_not_safe: '此操作后比率将不安全',
    cdp_protected: '自动化已启用并激活。',
    cdp_not_protected: '启用清算保护和自动杠杆自动化',
    stability_fee_explanation: 'CDP的年借贷利率，以您未偿CDP债务为基础计算，并且很可能会增加到您的当前债务。 费率是可变的，是由MKR 持有者在 Maker 社区治理中投票决定的。',
    liquidation_ratio_explanation: 'MakerDAO中的最低抵押比率。任何低于这一比率的CDP都会被清算。',
    price_explanation: 'MakerDAO 协议中的当前价格。',
    buying_asset_at_explanation: 'DAI 将从 CDP 中被借出并用该价格去购买更多的抵押品',
    cdp_is_empty: 'CDP 是空的',
    cdp_is_empty_desc: '此 CDP 目前是空的，没有任何抵押或债务。 您可以随时添加新的抵押品，重新使用它。',
    close_mcd_cdp_description: '关闭您的抵押债务仓位，使用当前锁定的抵押品偿还当前的债务，并将剩余的抵押品提取至您的帐户。',
    total_coll_minus_dai_debt: '总抵押品 - DAI 债务值：',
    dai_debt_value: 'DAI 债务值：',
    minimum_accepted_asset_closing: '关闭后已接受的最小 %asset 金额：',
    maximum_loss_closing: '关闭后已接受的最大损失：',
    close_fee: '(包括0.25%的服务费)',
    close_fee_no_brackets: 'Includes 0.25% service fee',
    mcd_close_can_repay: '您目前的 CDP 中有足够的抵押品，可以完全使用我们的标准偿还功能来偿还债务。 在适用的情况下，还款是比较理想的选择，它与不同协议的交互较少，所需费用较低，因而利润较高。',
    collateral_in_usd: '以美元抵押',
    coll_ratio: '抵押比率',
    received_dai: '已收 DAI',
    leveraged: '杠杆式',
    leveraged_desc: 'All generated DAI will be used to buy more ETH which will be added to your CDP for increased leverage.',
    value_invested: '已投资值',
    value_invested_desc: '添加的全部抵押品 + DAI 还款',
    value_withdrawn: '提款值',
    value_withdrawn_desc: '总共生成的 DAI + 提取的抵押品',
    lifetime_profit: '总收益',
    balance_desc: '当前抵押价值—— 债务',
    lifetime_profit_desc: 'CDP 余额 + 提取值 - 投资值',
    repay_remainder_returned: '抵押品的剩余部分将以DAI的形式返还',
    repay_remainder_returned_desc: '如果债务以偿还的方式全额还清，偿还金额的剩余部分将以 DAI 的形式返还至您的钱包。',
    cdp_created_successfully: 'CDP cdpId 已成功创建',
    cdp_created_successfully_desc: '我们建议使用我们的 CDP 自动化系统为您的仓位提供清算保护。\n您也可以在今后任何时候进行使用。',
    unclaimed_collateral: '未领取 %amount',
    unclaimed_collateral_desc: '您有未领取的抵押品。这很可能是因为您的 CDP 正在被清算。 认领至您的 CDP，即可提取。',
    close_auction_desc: '您是本次拍卖的获胜者。结束拍卖以领取您的奖金。',
    close_auction: '关闭拍卖',
    close_auction_dai: '关闭并转换为 DAI',
    vat_dai_balance: '您之前的拍卖出价还有 %amount DAI 未使用。您可以随时提取，也可在日后的出价中使用。',
    vat_coll_balance: '您的 VAT 有一笔抵押余额。如果有人为您关闭出价，就会发生这种情况。 \n您可以随时提取这笔余额。',
    bid_on_liq: '清算拍卖出价',
    liq_auction_warning: '该功能仍在测试阶段，不保证利润。使用风险自担。',
    automation_boost_subheader: '价格上涨时加杠杆',
    automation_repay_subheader: '价格下跌时进行偿还',
    automation_unsupported_asset: '%asset 和 %last_asset CDPs 目前支持自动化。',
    strategy_unsupported_asset: 'This automated strategy is currently supported for %assets and %last_asset CDPs.',
    vaults_subbed: '订阅金库：',
    vault_id: '金库 ID',
    close_to: 'Close to',
    leveraged_create_warning_1: 'The generated DAI will not be added to your wallet - they will be used to buy additional collateral for your CDP to increase leverage.',
    leveraged_create_warning_2: 'If you\'re not sure what the difference between a standard and leveraged CDP is, please see',
    leveraged_create_warning_3: 'this article',
    repay_withdraw_hint: 'Withdrawing %asset to pay back debt?\nDo it in a single transaction using Repay!',
    repay_payback_hint: 'Not enough %asset to pay back debt?\nPay it back with collateral using Repay.',
    boost_borrow_hint: 'Borrowing %asset to supply more collateral?\nDo it in a single transaction using Boost!',
    cdp_history: 'CDP #%id History',
    dash_boost_collateral: 'Add %collAsset from your account to make the position safer, then generate %debtAsset to buy %collAsset and add it to the CDP.',
    dash_boost_payback: 'Pay back debt using %debtAsset from your account, then generate %debtAsset to buy %collAsset and add it to the CDP.',
    dash_repay_withdraw: 'Take out %collAsset to buy %debtAsset and pay back debt, while also withdrawing some of the remaining collateral to your account.',
    dash_repay_generate: 'Take out %collAsset to buy %debtAsset and pay back debt, while also generating more %debtAsset to your account.',
    dash_collateral_generate: 'Add more %collAsset to your CDP and generate %debtAsset to your account.',
    dash_collateral_boost: 'Add more %collAsset from your account and apply leverage boost to the same, previous ratio.',
    dash_withdraw_payback: 'Pay back part of your %debtAsset debt and withdraw %collAsset collateral to your account.',
    dash_withdraw_repay: 'Deleverage your position and withdraw %collAsset collateral to your account, while keeping the same, previous ratio.',
    dash_withdraw_send: 'Withdraw %collAsset collateral and send it to another account',
    dash_generate_collateral: 'Add more %collAsset collateral and generate more %debtAsset to your account.',
    dash_generate_repay: 'Deleverage your position and generate more %debtAsset to your account, while keeping the same, previous ratio.',
    dash_generate_send: 'Generate %debtAsset and send it to another account',
    dash_payback_withdraw: 'Pay back debt using %debtAsset from your account and withdraw %collAsset.',
    dash_payback_boost: 'Pay back debt using %debtAsset from your account and apply boost to keep the same, previous ratio.',
    crop_cdp_already_exists: 'A %ilk CDP (%cdpId) already exists. There can only be a single CDP of %ilk type per account',
    advanced_create: 'Your %asset will be deposited into the %pool pool to receive %lptoken.',
    advanced_create_wsteth_steth: 'Your %asset will be wrapped into %lptoken.',
    advanced_create_wsteth_other: 'Your %asset will be staked with Lido and wrapped into %lptoken.',
    min_debt_explanation:'Minimum amount of debt that this vault type has to maintain. This value (also known as "dust") is managed by MakerDAO governance and positions below the minimum debt will have limited management options.',
    debt_ceiling_explanation:'Maximum amount of DAI that can be generated from a specific vault type (and currently utilized percentage).',
    liquidation_fee_explanation:'A liquidation penalty applied to your debt amount in case of liquidation.',
  },
  maker_actions: {
    'add-collateral': '添加抵押品',
    'create': '创建',
    'withdraw-collateral': '提取抵押品',
    'generate-dai': '生成 DAI',
    'payback-dai': '偿还 DAI',
    'add-collateral-draw-dai': '添加 & 生成',
    'payback-dai-withdraw-collateral': '还款 & 取款',
    'boost': '加杠杆',
    'repay': '偿还',
    'liquidation': '清算',
    'close': 'Close',
    'borrow_supply': 'Borrow & Supply',
    'withdraw_payback': 'Withdraw & Pay Back',
    'transfer': 'Transfer',
    'payback': '还款',
  },
  compound: {
    supply_assets_info: '储蓄您的资产并开始赚取收益',
    supply_rate_description: '储蓄资金的年化率根据市场供求的变化而变化。',
    withdraw_info: '从您的 Coumpound 储蓄中提取资产',
    borrow_info: '从 Compound 中借贷。在借款前您需要储蓄资产作为抵押物。',
    interest_estimate: '利息估算：',
    interest_estimate_info: '这是基于当前利率的估计数。',
    borrow_limit: '贷款限额',
    net_apy: '网络 APY',
    supply_balance: '储蓄余额',
    borrow_balance: '贷款余额',
    left_to_borrow: '剩余可借款额',
    boost_description: 'Increase leverage by borrowing, swapping to collateral asset and depositing to your position in a single transaction.',
    repay_description: 'Deleverage by withdrawing collateral, swapping to debt asset and paying back debt in a single transaction.',
    boost_modal_description: '加杠杆是借入更多 %asset1 以获得更多的 %asset2。这将会以增加你的借贷能力为代价增加了你的杠杆。',
    repay_modal_description: '偿还是使用储蓄的 %asset2 还款 %asset1债务。这降低了您的杠杆，但也降低了您的借贷能力，让您的仓位更安全。',
    proxy_required_click_to_migrate: '高级操作需要智能钱包',
    borrow_power_used: '已使用借款额度',
    migrating_to_proxy: '迁移至代理',
    supply_balance_desc: '您提供的抵押品总数。',
    borrow_balance_desc: '您的借贷资金总额。',
    borrow_limit_desc: '您可以借贷的最大金额。',
    payback_info: '偿还借贷资产。',
    collateral_required: '所需的抵押品',
    collateral_required_desc: '该资产是被用来支撑您所借的资金。停用资产作为抵押品会使您的仓位被清算。 在停用该资产前，请偿还所贷款或提供不同的资产作为抵押品。',
    enable_collateral: '启用 %asset 作为抵押品',
    disable_collateral: '停用 %asset 作为抵押品',
    comp_tokens: 'COMP 代币',
    comp_tooltip: '提取已赚取的 COMP 代币',
    comp_modal_desc: 'COMP tokens are automatically rewarded to all users of the Compound protocol.',
    comp_estimate_title_account: 'COMP rewards estimate for your account:',
    comp_estimate_title_smart_wallet: 'COMP rewards estimate for smart wallet:',
    warning_borrowing_supplied: '您正在借用您已储蓄的资产。您确定要这样做吗？',
    warning_supplying_borrowed: '您正在储蓄您已经借过的资产。您确定要这样做吗？',
    loading_compound_saver_data: '正在加载Compound自动化数据...',
    compound_automation: 'Compound自动化',
    compound_automation_description: '将您的 Compound 保持在某一比率，避免因市场变化导致清算或增加杠杆。',
    compound_automation_setup: 'Compound 自动化设置',
    compound_automation_setup_description: '一旦启用，DeFi Saver将监控您的 Compound 比率，并在您的 Compound 达到设定下限时自动激活还款， 当达到上限时增加杠杆。 您只需输入目标比率，或在下方手动设置。',
    compound_automation_status: 'Compound 自动化状态',
    compound_saver_subscribe: '正在启用 Compound 自动化',
    compound_saver_unsubscribe: '正在停用Compound自动化',
    migrate_to_proxy: '迁移至智能钱包',
    migrate_to_proxy_description: '只有在 DeFi Saver 智能钱包使用Compound时才能启用自动化。 \n如果您尚未准备好，请切换至您的智能钱包或迁移您的Compound仓位。',
    no_smart_wallet_automation: '您需要在智能钱包上有 Compound 仓位才能使用自动化功能。',
    compound_unsubscribe_reason: '我的 Compound 比率发生了一个我不希望发生的变化。',
    create_position: 'Open long or short position using the Compound protocol',
    open_position: 'Open position',
    safety_ratio_create_desc: 'The ratio of your borrow limit versus borrowed funds. Minimum ratio excepted is 100%. We recommend a ratio over 150% to keep your position from liquidation',
    current_and_after_supply: 'Your current supply of selected asset and supply after finishing the create process',
    current_and_after_borrow: 'Your current borrow of selected asset and borrow after finishing the create process',
    comp_smart_wallet_title: 'Smart Wallet position:',
    comp_eoa_title: 'Account position:',
    claim_from_account: 'Claim your allocated tokens to withdraw them to your main account.',
    claim_from_smart_wallet: 'Claim your COMP tokens.',
    claim_and_supply_from_smart_wallet: 'Claim and supply COMP to Compound in one transaction.',
    claim_and_swap_from_smart_wallet: 'Claim and swap COMP into a different asset in one transaction. Includes a 0.25% service fee.',
    claim_swap_and_supply_from_smart_wallet: 'Claim COMP, swap and supply the new asset to Compound in one transaction. Includes a 0.25% service fee.',
    compound_migrate_modal_info1: '我们的一些高级功能可以在一个交易中与多个智能合约交互，这在使用普通的以太坊帐户时是不可能做到的。',
    compound_migrate_modal_info2: '智能钱包是您的个人智能合约代理钱包，它持有您的 Compound 资金并能使用高级功能。',
    compound_migrate_modal_info3: '您可以将您储蓄的抵押品或抵押品债务迁移至您的智能钱包。',
    compound_migrate_not_supplied: '只有在 Compound 账户储蓄和借贷至少一项资产时才能迁移。',
    compound_migrate_withdraw: '要迁移您储蓄的资金，请先提款再手动储蓄至您的智能钱包。',
    compound_migrate_no_assets: '您的帐户上没有资产可以迁移。\n通常是因为您 Account Compound 储蓄的资金是从您的智能钱包里借来的，反之亦然。',
    buying_asset_at_explanation: '%borrow will be borrowed from the pool and used to buy more %supply at this price',
    create_smart_wallet_warning: 'You can only open a position when using Compound via the DeFi Saver Smart Wallet.',
    price_explanation: 'The current price in the Compound protocol.',
    compound_create: 'Compound Create',
    migrate_from_instadapp:'Compound DSA Migration',
    migrate_choose_position:'Select DSA',
    migrate_modal_description_1:'Using this tool will migrate your whole Compound position from DSA, the proprietary InstaDApp proxy wallet, to a standard DSProxy.',
    migrate_modal_description_2:'By migrating you gain access to our advanced features such as Automation, Boost and Repay, as well as our Recipe Creator.',
    migrate_modal_description_3:'Once you migrate your position to a DSProxy you will not be able to manage it at InstaDApp again unless you migrate back to their proxy wallet.',
    migrating_position_from_instadapp:'Migrating Compound from InstaDApp',
    dash_boost_collateral: 'Add collateral from your account to make the position safer, then generate debt to buy more collateral and add it to your position.',
    dash_boost_payback: 'Pay back debt from your account, then generate debt to buy more collateral and add it to your position.',
    dash_repay_withdraw: 'Withdraw selected asset and apply repay to keep your ratio unchanged.',
    dash_repay_generate: 'Borrow selected asset and deleverage your position to keep the same ratio.',
    add_collateral_description: 'Deposit the selected asset into Compound.',
    dash_collateral_generate: 'Add more collateral and borrow selected asset to your account.',
    dash_collateral_boost: 'Add more collateral from your position and apply boost to keep the ratio unchanged.',
    withdraw_description: 'Withdraw selected asset from Compound.',
    dash_withdraw_payback: 'Pay back part of your debt and withdraw collateral to your account.',
    dash_withdraw_repay: 'Deleverage your position and withdraw collateral to your account, while keeping the same, previous ratio.',
    generate_description: 'Borrow selected asset from Compound.',
    dash_generate_collateral: 'Add more collateral and generate more debt to your account.',
    dash_generate_repay: 'Deleverage your position and generate more debt to your account, while keeping the same, previous ratio.',
    payback_description: 'Pay back the %daiLabel borrowed asset.',
    dash_payback_withdraw: 'Pay back your debt and withdraw selected asset in one transaction.',
    dash_payback_boost: 'Pay back debt and apply boost to keep the ratio unchanged.',
    borrow_cap_exceeded: 'Borrow cap exceeded for %debtAsset market.',
    missing_liquidity: 'Not enough liquidity. The maximum amount available for borrowing is %assetAmount',
    migrating_position_from_account:'Migrating Compound from Account',
  },
  savings: {
    balance: '这是您当前的余额，包括到目前为止在所有集成协议中获得的利息。',
    average_interest_rate: '这是您当前基于每个协议提供的 DAI 数量的平均利率。',
    curr_average_interest_rate: '当前利率：',
    user_wallet_description: '为了使用我们的储蓄功能，您首先需要创建一个智能钱包。 这将是您的个人的合约钱包，它将启用此高级功能，同时您还可以在任何时候提取资金。 请注意，我们储蓄功能中使用的任何资金都不会在其他控制面板上显示(例如Compound的控制面板)。',
    import_description: 'Importing lets you move your already supplied assets to your Smart Wallet so that you can use them in Smart Savings.',
    import_modal_description: '我们检测到您已经为其中一个支持协议储蓄了资金。您想要将这些资金导入智能储蓄吗？',
    monthly_avg_short: '月平均',
    monthly_avg_description: '上个月的平均利率',
    supply_asset_to_project: '储蓄 %asset 至 %project',
    move_asset_from_to_project: '将 %asset 从 %project1 移至 %project2',
    withdraw_asset_from_project: '从 %project 提取 %asset',
    import_asset_to_project: '导入 %asset 至 %project',
    withdraw_asset_to_account: '提取 %asset 至帐户',
    emergency_withdraw: '紧急提取 %token',
    accidentally_sent_withdraw: '您意外地将 %token 发送到您的智能钱包，点击下面的按钮提取。 此操作将把指定代币的所有资金退回到您的地址中。',
    liquidity: '流动性',
    total_supply: '总储蓄',
    total_utilization: '利用率',
    deposited_amount: '存入金额',
    deposited: '已存入',
    log_in_to_manage_your_savings: '登录以管理您的智能储蓄',
    info_no_smart_wallet: 'Your assets in Smart Savings will be kept on your Smart Wallet. This will enable use of advanced features, but may not be compatible with other frontends for these protocols. You currently don\'t have a Smart Wallet and the first, required step will be to create one.',
    info_no_deposits: 'Your assets in Smart Savings will be kept on your Smart Wallet. This will enable use of advanced features, but may not be compatible with other frontends for these protocols.',
    supplied_info: 'Your current balance in this protocol, including interest earned so far.',
    supplied_info_summary: 'This is your current balance including interest earned so far, across all positions.',
    current_apy: 'Current APY',
    apy_info: 'A 7-day moving average APY, updated every 6 hours.',
    apy_info_summary: 'This is your current net APY for all deposits and protocols used.',
    pool_size: 'Pool size',
    import_info: 'Import your existing position to your Smart Wallet so you can use them in Smart Savings.',
    supply_info: 'Supply assets to the selected option to start earning yield.',
    withdraw_info: 'Withdraw assets back to your account.',
    description_yearn: 'You deposit and withdraw one and the same token. No additional actions or gas costs. Well handled security incidents. Lowest gas costs among available options.',
    description_rari: 'You deposit and withdraw one and the same token. No additional actions or gas costs. Some history of security incidents. Highest gas costs among available options.',
    description_mstable_save:'You can deposit and withdraw a variety of stablecoins, including DAI, USDC, USDT and mUSD. Depositing into mStable Save includes converting DAI, USDC and USDT to mUSD at 1:1 and depositing the mUSD into their Save contract to receive interest bearing imUSD. No history of security incidents. Medium gas costs compared to other options.',
    description_mstable_vault: 'You can deposit and withdraw a variety of stablecoins, including DAI, USDC, USDT and mUSD. Depositing into mStable Save includes converting DAI, USDC and USDT to mUSD at 1:1 and depositing the mUSD into their Save contract to receive interest bearing imUSD. The imUSD is then staked into their Vault contract to receive additional MTA rewards. No history of security incidents. Medium gas costs overall, with additional manual claiming of MTA rewards needed.',
    move_info:'Move currently deposited assets to the selected protocol.',
    mstable_unfavorable_exchange_rate:'Exchange rate between mUSD and %asset% seems to be different from 1:1 (%mUSDAmount% mUSD = %bAssetAmount% %asset%), resulting in potential loss. Withdrawing to a different asset might be more profitable. Are you sure you want to continue?',
    claim_info: 'Claim accrued protocol rewards.',
    claim_supply: 'Claim, swap and add the additional assets to your existing deposit.',
    claimable_info: 'Current amount of claimable reward tokens.',
    moving_affects_strategies: 'Moving funds to %protocol will require you to update your Smart Savings automation strategy settings as these are stored on-chain.',
    pool_size_info:'Total amount of assets deposited in the pool.',
    liquidity_info:'Currently available amount of assets that can be withdrawn from the pool.',
    liquidity_info_mstable:'Currently available amount of assets that can be withdrawn from the pool. Note: in mStable all assets can be withdrawn in mUSD at any point in time, while availability of specific stablecoins varies.',
    withrawable_info: 'The balances shown are withdrawable balances, based on the currently available liquidity in the used protocol and can differ from your total deposited amounts.',
  },
  misc: {
    logging_in: '正在登录，请稍候...',
    connecting_provider: '正在连接您的帐户，请稍候...',
    getting_cdp: '正在获取您的 CDP，请稍候...',
    insurance_label: '已为 %sumAssured %currency 投保，有效期至 %validUntil',
    exchange_slippage: '滑点容忍度',
    exchange_slippage_limit: '限制额外的价格滑点：',
    exchange_for: '将 %from 兑换成 %to',
    exchange_disclaimer: '预估的最优价格来自0x，Kyber，Uniswap和Oasis。 包括0.125％的服务费。',
    exchange_fee: 'Includes 0.125% service fee.',
    exchange_receipt: 'Check your swap receipt',
    boost_repay_exchange_disclaimer: '预估的最优价格来自0x，Kyber，Uniswap和SushiSwap。 包括%fee％的服务费。',
    exchanging_funds: '正在兑换您的资金...',
    exchange_funds: 'Please enter amount and select the tokens you want to exchange.',
    exchanging_select: '请选择您想要兑换的代币。',
    exchanging_at_least: '您正在为 %value2 %token2 出售 %value1 %token1',
    copied_to_clipboard: '复制到剪贴板',
    short_description: '做空功能可将借入所选的代币兑换为稳定币。 整个过程仅需在一次单击和一次交易中完成。即将呈现！',
    leverage_description: '杠杆功能可将借入的稳定币兑换为目标代币。 整个过程仅需在一次点击与一次交易中完成。即将呈现！',
    non_web3: '非 web3 浏览器',
    web3_browser_info: '为了使用 DeFi Saver 并与区块链交互，您需要使用 Web3 浏览器。 我们推荐使用 imToken、Coinbase、Trust、Opera 或 Status。',
    feature_in_development: '此功能目前正在开发中。',
    subscribe_notifications: '您在下方订阅后可立即收到通知!',
    subscribe_success: '感谢订阅！此功能一上线我们将立即通知您。',
    confirm_transaction: '请确认您的交易。',
    tx_sent: '交易 %txHash 已创建，目前正在处理中。',
    tx_pending: '交易 %txHash 处理中...',
    tx_confirmed: '交易 %txHash 已完成。',
    tx_rejected: '交易 %txHash 被拒绝。',
    tx_failed: '交易 被拒绝。',
    tx_replaced: '交易 %txHash 被替换。',
    tx_canceled: '交易 %txHash 被取消。',
    transaction_settings: '交易设置',
    fast: '快速的',
    regular: '常规的',
    cheap: '便宜的',
    gas_price: 'Gas price',
    gas_limit: 'Gas limit',
    total_price: '总价：',
    recent_txs: '最近的交易',
    no_recent_txs: '最近无交易',
    recommended_gas_prices: 'Recommended gas prices',
    customize_gas: '自定义 Gas 设置',
    real_gas_estimate: '预估 Gas',
    real_gas_fee: '估算费用',
    max_tx_fee: '最高交易费用',
    total: '总计',
    automation_stats: '自动化统计',
    automation_contract_upgrades: '自动化合约升级',
    explore_maker_stats: '浏览 MakerDAO 的统计数据',
    gas_price_desc: '用于转账交易的每单位 Gas。',
    gas_limit_desc: '交易允许使用的最大 Gas',
    real_gas_estimate_desc: '交易预计使用的 Gas',
    real_gas_fee_desc: '当前配置的预估交易费用。',
    base_fee: 'Base fee',
    base_fee_desc: 'The base fee is a required gas fee that gets burned upon transaction execution. It is automatically adjusted by the network, decreasing or increasing based on current network usage.',
    priority_fee: 'Priority fee',
    priority_fee_desc: 'The priority fee (often called the "miner tip") is an additional fee that is paid directly to the miners in order to incentivize inclusion of your transaction.',
    max_fee: 'Max fee',
    max_fee_desc: 'The max fee is the limit to how much you\'ll pay for both base fee and tip.',
    max_tx_fee_desc: '交易支付的最高费用。交易中未使用的 Gas 将退还给用户。',
    tx_failing_notification: '这笔交易很可能失败了。如果这个错误持续存在，请联系我们寻求帮助。',
    welcome_to_dfs: '欢迎使用 DeFi Saver',
    withdrawing_from_safeguard: '从安全卫士中提取资金',
    low_gas_price_warning: 'Gas 价格过低会导致交易不顺畅。',
    low_max_fee_warning: 'Using a max fee close to the current base fee may result in stuck transactions.',
    current_ratio: '当前比率',
    log_in_to_manage_your_app: '登录以管理您的 %app 仓位',
    supply_to_enable_collateral: '作为抵押开启供应。通过智能钱包供应将自动启用该资产。',
    automation_congestion_desc_1: '为了使自动化能够在矿工费价格暴涨期间对所有仓位都有利，我们执行了以下规定：',
    automation_congestion_rule_1: 'Automated Boosting will only be executed if the transaction cost is less than 5% of the Boost amount.',
    automation_congestion_rule_2: 'Automated Repay will be executed if the transaction cost is less than 5% of the Repay amount or as soon as you\'re less than 15% above liquidation ratio.',
    automation_congestion_desc_2: 'Automation always sends all transactions using current fast gas, tracks them and re-sends with faster gas if any get stuck. The maximum gas price that can be charged to the user is currently %gweiAmount Gwei.',
    automation_congestion_desc_3: 'We continue to make gas usage related optimizations to our smart contracts in order to reduce gas usage in as many instances as possible. (Last updated Mar 2nd 2022).',
    automation_congestion_desc_4: '如果您想和我们一起检查任何有关自动化的内容，请随时加入我们的 https://discord.gg/XGDJhZ',
    footer_desc: 'DeFi Saver is a one-stop management solution for decentralized finance protocols.',
    newsletter_sub: 'Subscribe to the DeFi Saver newsletter',
    whats_new_title: 'What\'s new on DeFi Saver',
    low_ratio_warn: 'Safety ratio lower than currRatio. Please be mindful of liquidation risks that come with high leverage levels.',
    high_borrow_warn: 'Borrow power higher than currRatio. Please be mindful of liquidation risks that come with high leverage levels.',
    feature_unavailable_in_simulation: 'Feature unavailable in simulation',
    feature_unavailable_in_simulation_desc: 'Some features relying on external systems are disabled while you\'re inside the simulation. %feature is one of them. ',
    positions_subbed: 'Positions subscribed',
    vault_min_debt_warning: 'The minimum debt for a %protocol is currently %amount %asset. While below minimum debt, you can only pay off all debt or generate more debt.',
    exchange_option_info: 'The decentralized exchange used for your wanted swap.',
    dfs_service_fee_info: 'The DeFi Saver service fee for this transaction.',
    fl_protocol_info: 'The protocol used for the needed flash loan.',
    deadline: 'Deadline:',
    ens_or_addr_not_found: 'Couldn\'t find address or ENS',
    ens_not_found: 'There is no address with this ENS',
    exchange_advanced_subtitle: '(Slippage limit, liquidity sources)',
    exchange_best_rate_via: 'Best rate found via',
    received_token_sent: 'Received token sent to',
    send_to_different_addr: 'Send to a different address',
    token_addr_not_found: 'Token for the given address not found.',
    wrong_addr: 'You entered wrong address value',
    sending_to_your_acc: 'Can\'t send received tokens to your account',
    sending_to_your_proxy: 'Can\'t send received tokens to your smart wallet',
    send_tokens_warning: 'No funds will be sent to your account!',
    sending_tokens_details: 'Send tokens received from the swap to a different address.',
    tsi_for_assets: ' for %pair'
  },
  errors: {
    error_occurred: '发生错误',
    required: '必需的',
    over_max: '数值大于最大值',
    under_zero: '数值不能小于0',
    no_value: '未输入值',
    invalid_value: '无效 %value',
    positive_value: '必须是正值',
    must_be_over: '必须超过 %value',
    wallet_not_connected: '钱包未连接',
    nothing_supplied: '未储蓄',
    swap_same_project: 'Please select different protocols for moving assets.',
    no_local_storage_state: '无本地存储状态',
    no_ledger_item: '没有上次使用过的 Ledger',
    wrong_network: '网络错误 - 请将 %type 设置为 %network',
    provider_not_approved: '提供者未被预先批准',
    denied_login: '您已拒绝登录',
    denied_transaction: '您已拒绝交易',
    email_fail: '无法发送邮件',
    user_denied_tx: '用户拒绝了该交易',
    not_enough_funds: '资金不足，交易未完成。',
    tx_repeat: '检测到有重复的交易。',
    tx_cancel: '交易已被取消。',
    compound_tx_fail: 'Compound交易失败',
    no_accounts_locked: '无帐户 (可能已锁定)',
    unknown_network: '未知网络',
    ledger_locked: 'Ledger 已被锁定',
    ledger_contract_data: '请在您的 Ledger 上启用"合约数据"',
    user_canceled: '用户取消了交易',
    automatic_trigger: '根据您的自动化设置启动自动调整操作。',
    no_wallet: '未检测到钱包',
    connect_access: '连接您的钱包以访问此页面',
    over_value: '必须超过 %value',
    under_value: '必须低于%value',
    value_less_than: '值必须小于 %label',
    value_less_than_percent: 'Value must be %percent% under %label',
    target_limit_difference: '目标和限制比率之间的差值必须大于 %value%',
    positive_number: '必须是正数',
    page_not_found: '页面未找到',
    debt_below_min: 'Your CDP debt is under the required minimum of %amount %asset. You can only increase your debt pay back the full amount.',
    debt_below_min_after: 'This action would cause debt to be under the required minimum of %amount %asset. ',
    reverted_transaction: '该交易因错误而被还原。交易哈希： %txhash',
    invalid_ds_proxy: '无效 DS代理地址',
    ratio_too_low_for_repay: '为保证自动化正常工作，比率必须至少保持在清算比率之上的 %percent％',
    gas_price_very_high: '选定的 Gas Price (%gasPrice Gwei) 可能过高。您确定要继续吗？',
    action_select_no_options: '此操作无可用的抵押品或借贷资产。',
    dai_debt_ceiling_hit: '已达到全球 MakerDAO 抵押物的债务上限。',
    borrow_power_used_high: 'Borrow power used is too close or over 100%',
    minimum_dai_debt: 'Minimum debt for %ilk CDPs is %min DAI.',
    initial_dai_debt:'Selected CDP is under the minimum required debt (%min DAI).',
    minimum_rai_debt: 'Minimum debt for %ilk Safes is %min RAI.',
    initial_rai_debt:'Selected Safe is under the minimum required debt (%min RAI).',
    minimum_liquity_debt: 'Minimum debt for Liquity Troves is %min LUSD.',
    invalid_address: 'Invalid address',
    own_address: 'You have entered your own address',
    liquidity_warning: '%asset currently has low available liquidity on exchanges and you may see high slippage with any larger Boosts and Repays.',
    boost_no_collateral_enabled: 'Boost feature is unavailable until you enable your supplied asset as collateral in the main dashboard below.',
    v1_to_v2_lend_error: 'Migrating positions with LEND supplied is not supported.',
    trying_sub_under_min_debt: 'Your current debt is under the minimum debt required for enabling Automation.',
    exchange_proxy_warning: 'Using the Exchange requires a Smart Wallet created. Exchanged funds are regularly sent to your account.',
    over_debt_ceiling: 'Not enough DAI available for %ilk (%available DAI available).',
    additional_action_selected: 'Additional action selected',
    sending_to_asset_contract: 'You seem to have entered the %asset token contract address as the recipient. Please recheck the address you\'re sending to.',
    value_must_be_above: 'Value must be above "%label"',
    value_must_be_above_percent: 'Value must be %percent% above %label',
  },
  portfolio: {
    show_empty_positions: 'Show empty positions',
    hide_empty_positions: 'Hide empty positions',
    current_protocols: '当前协议',
    estimated_earnings: '预估收益',
    net_worth: '净值',
    assets: '资产',
    tokens: 'Tokens',
    no_owned_tokens: 'This account currently doesn\'t own any tokens',
    token_assets: 'Token Assets',
    portfolio_value: 'Portfolio Value',
    collateral: 'Collateral',
    debt: 'Debt',
    claimable: 'Claimable',
    supplied: 'Supplied',
    borrowed: 'Borrowed',
    on_smart_wallet: 'On Smart Wallet',
    failed_fetch: 'Failed to fetch %protocol Positions for address: %address',
  },
  aave: {
    aave_migrate_modal_info1: '我们的一些高级功能可以在一个交易中与多个智能合约交互，这在使用普通的以太坊帐户时是不可能做到的。',
    aave_migrate_modal_info2: '智能钱包是您的个人智能合约代理钱包，它持有您的Aave资金并能使用高级功能。',
    aave_migrate_modal_info3: '您可以将您储蓄的抵押品或抵押品债务迁移至您的智能钱包。',
    aave_proxy_migrate_modal_info1:'By migrating your %protocol position to a Smart Wallet you gain access to our advanced management options such as Boost, Repay, Automated strategies and Loan Shifting.',
    aave_proxy_migrate_modal_info2:'A Smart Wallet is your personal smart contract proxy wallet that is created, owned and manageable by you and you only.',
    aave_proxy_migrate_modal_info3:'Potential steps of the migration process can include creating a Smart Wallet, providing needed token approvals and the migration transaction itself.',
    aave_proxy_migrate_modal_info4:'Learn more about the migration process and the Smart Wallet here.',
    aave_migrate_not_supplied: '只有在Aave账户供应和借贷至少一项资产时才能迁移。',
    aave_migrate_withdraw: '要迁移您供应的资金，请提取它们并手动供应至您的智能钱包。',
    aave_migrate_no_assets: '您的帐户上没有可用的资产可以迁移。\n通常是因为Account Aave提供的资金是从您的智能钱包里借来的，反之亦然。',
    smart_wallet_info: '通过您的智能钱包使用Aave可以进行高级操作，如加杠杆、还款和闪电贷。 智能钱包是一个标准的 MakerDAO DS代理钱包，能更好地与借贷协议交互。',
    smart_wallet_info_2: '这些资金在Aave app上不可见。',
    withdraw_info: '从您的 Aave 储蓄中提取资产',
    supply_assets_info: '储蓄您的资产开始赚取收益',
    borrow_info: '从Aave中借贷。在借款前您需要储蓄资产作为抵押物。',
    payback_info: '还贷资产。',
    aave_automation: 'Aave Automation',
    aave_automation_description: 'Keeps your Aave position at a certain ratio to protect it from liquidation or increase leverage based on market changes.',
    aave_automation_setup: 'Aave Automation Setup',
    aave_automation_setup_description: 'Once Enabled, DeFi Saver will monitor your Aave ratio and automatically activate Repay if your Aave reaches the lower configured limit, or Boost if it reaches the upper one. Simply enter your target ratio, or configure manually below.',
    aave_automation_status: 'Aave Automation Status',
    aave_automation_active_account: 'Aave Automation is currently active for this Aave and the graph shows your current configuration.',
    aave_saver_subscribe: 'Enabling Aave Automation',
    aave_saver_unsubscribe: 'Disabling Aave Automation',
    aave_state: 'Current Aave ratio: %current%',
    no_smart_wallet_automation: 'You need to have a Aave position on a Smart Wallet in order to use Automation.',
    loading_aave_saver_data: 'Loading Aave Automation data...',
    migrate_to_proxy_description: 'Automation can only be enabled when using Aave via the DeFi Saver Smart Wallet. \nPlease switch to your Smart Wallet or migrate your Aave position if you haven\'t already.',
    aave_unsubscribe_reason: 'My Aave ratio was changed in a way I didn\'t expect would happen.',
    orig_fee: 'Origination Fee',
    migration_orig_fee_desc: 'Origination fee is a 0.00001% fee collected by Aave from borrowers. It has to be paid back directly by the borrower, so asset approval is required for migration. ',
    borrow_apy_var: 'Variable B. APY',
    borrow_apy_stable: 'Stable B. APY',
    interest_rate: 'Interest Rate',
    swap_interest_rate: 'Swap interest rate for %asset',
    change_rate: 'To %rate',
    select_rate: 'Select your interest rate',
    stable_desc: ' would be a good choice if you need to plan around a non-volatile rate over a longer period of time.',
    variable_desc: ' is based on the supply and demand for the selected asset on Aave and is re-calculated every second, meaning the rate will be lower when there is less demand for the asset.',
    can_switch: 'You can also switch between the two rates at any point in the future.',
    cant_stable_borrow: 'You can only use the stable borrow rate if you borrow more of the same asset than you have supplied.',
    unsupported_stable_borrow_asset: 'This asset is unsupported for stable rate borrow',
    change_interest_rate: 'Change interest rate',
    stake: 'Stake',
    unstake: 'unstake',
    stake_aave: 'Stake AAVE: %amount',
    unstake_aave: 'Unstake AAVE: %amount',
    activate_cooldown: 'Unstake AAVE: activate cooldown',
    migrate_lend_aave: 'Migrate %amount LEND to AAVE',
    migrate_lend_aave_desc: 'The LEND token is migrating to AAVE.',
    claim_rewards: 'Claim accrued AAVE: %amount',
    claim_stkaave: 'Claim accrued stkAAVE: %amount',
    currently_staked: 'Currently staked',
    stake_modal_title: 'AAVE Staking',
    stake_modal_desc: 'Staking AAVE in the Safety Module helps to secure the protocol in exchange for protocol incentives.',
    reward_estimates: 'Reward estimates',
    claimable_rewards: 'Claimable rewards',
    token_actions: 'Token Actions',
    stake_action_desc: 'Stake your AAVE to secure the Aave protocol and earn rewards.',
    unstake_action_desc: 'Unlocking staked funds activates a 10-day cooldown after which you can withdraw your AAVE during a specific unstaked time frame.',
    staking: 'Staking',
    stake_desc: 'Stake your AAVE',
    aave_token: 'AAVE',
    liquidation_ratio: 'Liquidation ratio',
    liquidation_ratio_desc: 'Safety ratio below which your position will face liquidation.',
    liquidation_limit: 'Liquidation limit',
    liquidation_limit_desc: 'Value of borrowed funds above which your position will face liquidation.',
    migrate_to_v2: 'Migrate from v1 to v2',
    migrate_v2_modal_title: 'Migrate to Aave v2',
    migrate_v2_modal_description: 'Migrating to Aave v2 will instantly move your whole portfolio (ie. all supplied and borrowed assets) from Aave v1 to Aave v2.',
    migrate_v2_modal_note: 'Note that the migration is only possible to your Smart Wallet on Aave v2 and there is no reverse migration back to v1 available.',
    migrate_v2_modal_automation_note: 'If you have Automation enabled on v1, it will be enabled and configured to the same settings on v2.',
    aave_v1_automation_deprication_title: 'Aave v1 Automation is being deprecated',
    aave_v1_automation_deprication_description: 'If you would like to use Automation for your Aave position, we strongly suggest migrating to Aave v2 which has lower transaction fees, as well as better liquidity and rates. You can find the migration button in the Aave dashboard.',
    migrate_from_instadapp:'Aave DSA Migration',
    migrate_modal_description_1:'Using this tool will migrate your whole Aave position from DSA, the proprietary InstaDApp proxy wallet, to a standard DSProxy.',
    migrating_position_from_instadapp:'Migrating Aave from InstaDApp',
    migrating_position_from_account:'Migrating Aave from Account',
    current_steth_eth_ratio_desc: 'Current stETH/ETH price ratio (should be pegged to 1)',
    liquidation_steth_eth_ratio_desc: 'stETH/ETH price ratio at which your position would liquidate',
    add_collateral_description: 'Deposit the selected asset into Aave.',
    withdraw_description: 'Withdraw selected asset from Aave.',
    generate_description: 'Borrow selected asset from Aave.',
    isolation_mode_description: 'Isolation mode allows to list new assets as Isolated which has a specific debt ceiling and can be only used to borrow stablecoins that have been permitted, by Aave Governance, to be borrowable in isolation mode.\nIsolated assets can only be used as collateral if there are no other supplied assets or none of the supplied assets is enabled as collateral.\nIf there are other assets supplied as collateral, the asset supported for isolation mode will not be counted as collateral.\nOnly certain assets can be borrowed against assets supported as collateral in isolation mode.',
    supplying_while_siloed: 'You\'re supplying a siloed asset which will not be counted towards your collateral value for borrowing against.',
    supplying_while_isolated: 'The asset you\'re supplying will not be counted as collateral because you are in isolation mode.',
    siloed_borrowing_desc: 'Siloed mode allows borrowing certain assets labelled as risky by Aave Governance, with the limitation being that you cannot borrow any other assets at the same time.',
    siloed_asset_desc: 'This is a siloed asset. Borrowing it will block you from borrowing any other asset at the same time.',
    isolation_asset_desc: 'This is an isolated asset. Using it as collateral will block you from using any other asset as collateral at the same time.\n',
    supplying_isolated_asset_disabled_coll: 'The asset you\'re supplying is an isolated asset and will not be counted towards your already supplied collateralized assets.',
    supplying_isolated_asset: 'You\'re entering isolated mode. In isolation mode, you can\'t supply other assets as collateral. A global debt ceiling limits the borrowing power of the isolated asset.',
    e_mode_desc: 'Efficiency mode allows supplying and borrowing assets correlated in price under preferential terms, which will usually include an increased LTV, higher liquidation threshold and a decreased liquidation bonus.\nGroups of assets that can be used in efficiency mode can be stablecoins or ETH and various liquid staking derivatives.',
    e_mode_active: 'Efficiency mode is activated',
    e_mode_activate: 'Activate efficiency mode',
    isolation_mode_activated: 'You are in isolation mode.',
    siloed_borrowing_activated: 'You are in siloed borrowing mode.',
    aave_create: 'Aave Create',
    open_position: 'Open position',
  },
  shifter: {
    shift: 'Shift',
    description: 'Shift your position between protocols or change your collateral and debt assets.',
    shift_protocol_desc: 'Shift your position between different protocols or Maker Vault types (e.g. ETH-A to ETH-B).',
    shift_coll_desc: 'Change your collateral asset to an asset with a lower Stability fee or a higher supply APY.',
    shift_debt_desc: 'Change your debt asset to a different one, for example one with a lower borrow APY.',
    error_shifting_asset_unavailable: 'Shifting %unavailable asset is not possible while shifting %shift_type',
    error_same_asset: 'Select a different %shift_type asset',
    no_position_selected: 'No position selected.',
    choose_shift_type: 'Choose what you want to shift:',
    smart_wallet_required: 'Loan Shifter can only work with positions that are on a Smart Wallet. Would you like to create a Smart Wallet and migrate your Compound position now? ',
    learn_more: 'Learn more about Loan Shifter here',
    position_on_main_acc_1: 'Have a %protocol position on your main account?',
    position_on_main_acc_2: 'Migrate it to your Smart Wallet to use the Loan Shifter',
    error_from_pos_no_coll: 'From position does not have collateral.',
    error_no_swap_coll_and_debt: 'Shifting both collateral and debt assets at the same time is not possible.',
    shift_type_not_supported: 'Shift type not supported.',
    error_shift_not_available_for_asset: 'Shift not available for %asset (no flash loan available).',
    error_shift_not_enough_liquidity: 'Not enough liquidity to execute exchange.',
  },
  reflexer: {
    safe: 'Safe',
    redemption_rate: 'Redemption Rate',
    rai_system_price_desc: 'RAI\'s moving peg or the current target price of RAI, also known as the redemption price.',
    rai_market_price_desc: 'Price at which RAI is currently trading.',
    redemption_rate_desc: 'Annual rate at which RAI is devalued or revalued, based on the difference between RAI redemption and market prices. A positive rate means the redemption price is increasing and vice-versa.',
    stability_fee_desc: 'The annual borrow rate for Reflexer Safes, calculated based on your outstanding debt and continuously added to your current debt. ',
    no_safe_found: 'No Safe Found',
    no_safe_blocker: 'There doesn\'t seem to be a Reflexer Safe associated with this account. You can create a Reflexer Safe now to borrow RAI or leverage ETH using our signature Boost and Repay features.',
    create_safe: 'Create a Safe',
    create_safe_description: 'Creating a Safe allows you to generate RAI by depositing ETH',
    safe_created_successfully: 'Safe #%safeId created successfully',
    safe_created_successfully_desc: '-',
    liquidation_ratio_explanation: 'The minimum collateralization ratio for %collType Safes. Any Safe that drops below this ratio faces liquidation.',
  },
  liquity: {
    recovery_mode_warning: 'Liquity is currently in Recovery mode, any trove with collateral ratio below 150% will get liquidated.',
    recovery_mode: 'Recovery mode',
    normal_mode: 'Normal mode',
    trove: 'Trove',
    create_trove: 'Create Trove',
    create_trove_warning: 'Your Trove will be created on your Smart Wallet. This enables the use of advanced features, but may not be compatible with other Liquity frontends. %missing_sw',
    close_trove: 'Close Trove',
    no_trove_found: 'No trove found',
    no_trove_blocker: 'There doesn\'t seem to be a Liquity Trove associated with this account.',
    no_trove_blocker_sw: 'There doesn\'t seem to be a Liquity Trove associated with this account. You can create a Liquity Trove now to borrow LUSD or leverage ETH using our signature Boost and Repay features.',
    create_trove_description: 'Creating a Trove allows you to generate LUSD by depositing ETH',
    minimum_ratio: 'Minimum accepted ratio in %mode is %min_ratio. \nWe recommend a ratio over %suggested_ratio to keep the Trove safe from liquidation',
    liquidation_ratio_explanation: 'The minimum collateralization ratio in %mode. Any Troves that fall below this ratio face liquidation.',
    liquidation_reserve_info: ' This fee covers the gas cost a liquidator would pay to liquidate your Trove. You are refunded this fee when you pay back your debt.',
    borrowing_fee_info: 'A one time fee charged as a percentage of the borrowed amount (in LUSD) and added to your Trove\'s debt. The fee varies between 0.5% and 5% depending on LUSD redemption volumes.',
    total_debt_info: 'The total amount of LUSD your Trove will hold.',
    liquidation_reserve: 'Liquidation reserve',
    borrowing_fee: 'Borrowing fee',
    total_debt: 'Total debt',
    tcr: 'Total collateralization ratio',
    tcr_short: 'TCR',
    tcr_info: 'The ratio of the Dollar value of the entire system collateral at the current ETH:USD price, to the entire system debt.',
    kickback_rate: 'Kickback rate',
    kickback_rate_info: 'A rate set by the frontend operator that determines the fraction of LQTY that will be paid out as a kickback to the Stability Providers using the frontend.',
    collateral_ratio_suggestion: 'Keeping your Collateral ratio above 150% can help avoid liquidation under Recovery mode.',
    proxy_required: 'Smart Wallet required for advanced actions.',
    lusd_market_price_info: 'Current LUSD market price in dollars',
    borrowing_fee_stats_info: 'Current fee percentage',
    debt_in_front_info: 'Amount of LUSD available for redemption in Troves with lower collateralization ratio than yours. The lower the amount, the more likely it becomes for your Trove to be redeemed against.',
    lusd_balance_info: 'LUSD balance on your wallet',
    lqty_balance_info: 'LQTY balance on your wallet',
    redemption_fee_info: 'The Redemption Fee is charged as a percentage of the redeemed Ether up to 1%. The Redemption Fee depends on LUSD redemption volumes and is 0.5% at minimum.',
    close_account: 'Closing your Trove will pay back the debt using LUSD from your account and withdraw the remaining ETH collateral to your account in the same transaction.',
    claimable_collateral_info_liq: 'Your trove has been liquidated during the Recovery mode.\nYou have %amount ETH to claim.',
    claimable_collateral_info_redeemed: 'Your trove has been fully redeemed.\nYou have %amount ETH to claim.',
    stake_action: 'Stake your LQTY',
    stake_action_desc: 'Stake your LQTY to earn a share of borrowing and redemption fee.',
    redeem_action_desc: 'Redeem ETH from LUSD.',
    redeem_lusd_desc: 'Redeem LUSD for ETH at $1 of ETH per 1 LUSD. An additional redemption fee is charged on the redeemed amount.',
    gains_info: 'Earned %asset balance',
    debt_below_min: 'Your Trove debt is under the required minimum of 2000 LUSD.',
    stake_lqty_desc: 'Stake LQTY and earn LUSD and ETH revenue from borrowing and redemption fees. Your LQTY stake will earn a share of the fees equal to your share of the total LQTY staked.',
    stake_lusd_desc: 'Deposit LUSD to the Stability Pool and earn ETH liquidation bonuses and LQTY rewards.',
    buying_asset_at_explanation: 'LUSD will be borrowed from the Trove and used to buy more collateral at this price',
    trove_created_successfully: 'Trove created successfully',
    trove_created_successfully_desc: 'We suggest enabling liquidation protection for your position using our Automated Strategies system.\nYou can also do this at any moment in the future.',
  },
  strategies: {
    short_description: 'Set up automated strategies to automatically adjust your positions, including automated liquidation protection, leverage management and position closing or opening.',
    legacy_title: 'Automated Leverage Management',
    legacy_description: 'Configure automated leverage management for your position, including auto-Repay for liquidation protection and (optional) auto-Boost for leverage increase.',
    smart_savings_payback_title: 'Savings Liquidation Protection',
    smart_savings_payback_description: 'Protect your %protocol from liquidation with automated debt payback using %asset deposited into Smart Savings.',
    stop_loss_title: 'Stop Loss',
    stop_loss_description: 'Fully close your position automatically once collateral asset reaches a configured minimum target price.',
    take_profit_title: 'Take Profit',
    take_profit_description: 'Fully close your position once collateral asset reaches a configured maximum target price.',
    enable_action_title: 'Enable automated strategy',
    disable_action_title: 'Disable automated strategy',
    update_action_title: 'Update automated strategy',
    no_smart_savings_selected: 'No Smart Savings protocol selected',
    warning_smart_savings_zero_balance: 'Your currently deposited balance in the selected Smart Savings protocol is 0 which would make this automated strategy impossible to run, unless you deposit funds afterwards. Are you sure you want to continue?',
    warning_smart_savings_not_enough_for_payback: 'Your currently deposited balance in the selected Smart Savings protocol is not sufficient to reach the target ratio in case your configured threshold is reached. Are you sure you want to continue?',
    strategy_unavailable_title: 'Additional strategy unavailable',
    strategy_unavailable_description: 'Enabling more than one automated strategy is currently not possible.',
    savings_strategies_fee_info: 'Fees for using Savings Liquidation Protection include transaction fees and a %feePercentage% service fee for any automated transactions that occur. There are no fees for simply keeping this automated strategy enabled and your position monitored.',
    close_strategies_fee_info: 'Fees for using Stop Loss and Take Profit strategies include a transaction fee and a %feePercentage% service fee in case the strategy gets executed and your position closed. There are no fees for simply keeping this automated strategy enabled and your position monitored.',
    leverage_strategies_fee_info: 'Fees for using Automated Leverage Management include a transaction fee and a %feePercentage% service fee on the repaid/leveraged amount. There are no fees for simply keeping this automated strategy enabled and your position monitored.',
    close_if_price_below: 'Close if price below',
    close_if_price_above: 'Close if price above',
    debt_at_least: 'Due to high transaction fees, especially during significant market movements, Automation is not recommended for smaller positions. A minimum of $%value in debt is required to enable %protocol Automation.',
  },
  recipes: {
    create_recipe: 'Create recipe',
    recipe_book: 'Recipe book',
    click_to_copy: 'Copy a link to your recipe',
    recipe_has_errors: 'Recipe contains errors',
    recipe_has_suggestions: 'Recipe contains suggestions you may want to check.',
    create_sw_description: 'A Smart Wallet is required to execute a recipe. It enables executing complex transactions and adds a layer of security protecting you from contract exploits.',
    refresh_prices_to_continue: 'Fetched prices have expired. Refresh prices to continue.',
    refresh_prices: 'Refresh prices',
    use_whole_balance_payback: 'Pay back whole debt',
    use_whole_balance_supply: 'Use whole balance',
    use_whole_balance_send: 'Use whole balance',
    use_whole_balance_withdraw: 'Use whole balance',
    whole_balance_payback: 'By checking this option, the recipe creator will use the exact amount needed to payback the debt',
    whole_balance_supply: 'By checking this option, the recipe creator will supply the whole balance from selected wallet',
    whole_balance_send: 'By checking this option, the recipe creator will send the whole balance from recipe wallet to specified address',
    whole_balance_withdraw: 'By checking this option, the recipe creator will withdraw all collateral specified address',
    nlp_header: 'Create a recipe using words - click here to try it out',
    nlp_input: 'Recipe sentence',
    nlp_parse: 'Prepare recipe',
    rc_header_desc: 'Execute complex transactions composed of multiple actions easily and safely',
    recipe_automation: 'Recipe Automation',
    quick_access: 'Quick Access',
    params_automatic: 'Recipe parameters are automatically calculated',
    recipe_fee: 'This recipe contains a sell action which includes a service fee of %fee%.'
  },
  faq: {
    what_is_automation: 'What is DFS Automation?',
    what_is_automation_answer: 'Automation is a trustless system for automatic liquidation protection or automated leverage management of collateralized debt positions that monitors users’ positions and automatically makes adjustments once the configured conditions are met.',
    what_are_requirements: 'What are the requirements for using Automation?',
    what_are_requirements_answer: 'The only two requirements for running Automation are that the user’s position is on a Smart Wallet (which is already the default behaviour for MakerDAO and Reflexer) and that a minimum debt requirement is met. Currently, the minimum required debt for \tenabling Automation is $40,000 of debt for MakerDAO and Liquity or $60,000 total debt balance in case of Compound and Aave.',
    how_does_automation_work: 'How does Automation work?',
    how_does_automation_work_answer: 'By enabling Automation, a user gives Automation smart contracts the rights to make adjustments on their position. Once Automation is enabled for any position, Automation bots will start continuously monitoring it and executing any needed adjustments as soon \tas the configured thresholds are reached.',
    when_are_tx_made: 'When are Automation transactions made?',
    when_are_tx_made_answer: 'Automated transactions are executed as soon as the position’s collateralization ratio reaches configured thresholds. This is something that will be affected by the used protocol’s price oracles, so a good way to look at it is that Automation for any given protocol runs on that protocol’s price oracles (not on any external price feed).',
    what_are_automation_fees: 'What are the fees for using Automation?',
    what_are_automation_fees_answer: 'There are two types of fees that users should know in regards to using Automation: the service fee of 0.3% (of the transaction size, \tper automated adjustment) and transaction fees (charged to the position, or, effectively, to the user\'s collateral).',
    what_are_automation_risks: 'What are the risks of using Automation?',
    what_are_automation_risks_answer: 'There are different types of risks that users should consider. Firstly, there is smart contract risk, although Automation has been audited with no issues found. Secondly, there is technical risk, where network conditions or other issues may prevent \tautomated-adjustments from being made in a timely manner. And, lastly, there is economic risk, which would ultimately be caused by the market moving against the user’s position, but should be considered in either case.',
  },
  pages: {
    terms_of_service: '服务条款',
    faq: 'FAQ',
    faq_long: 'Frequently Asked Questions',
    compound: 'Compound',
    smart_savings: '智能储蓄',
    maker_dao: 'MakerDAO',
    liquity: 'Liquity',
    privacy_policy: '隐私条款',
    blog: '博客',
    help: '帮助',
    exchange: '交易',
    portfolio: '投资组合',
    transaction_history: 'History',
    funds_safeguard: '资金保障',
    aave: 'Aave',
    whats_new: 'What\'s new',
    shifter: 'Loan Shifter',
    reflexer: 'Reflexer',
    recipe_creator: 'Recipe Creator',
    bridge: 'Bridge',
  },
  error: {
    slippage_hit: 'The transaction hit the slippage limit, hence execution reverted.',
    out_of_gas: 'Transaction ran out of gas.',
  },
  uniswap: {
    create_pool: 'You are the first liquidity provider for this Uniswap V3 pool. The transaction fee will be much higher as it includes gas costs for creating a new pool.',
    withdrawing_from_pool: 'Withdrawing %amount %asset from Uni V%version',
  },
  insta: {
    authorize_proxy_for_dsa:'Authorize DSProxy on DSA'
  },
  bridge: {
    outcome: 'Final Bridge outcome',
    auto_refresh: 'Routes will be auto refresh in ',
    none_available: 'No bridges are available at the moment with these parameters',
    transfered_funds: 'Your funds will be transfered in approximately ~%time minutes',
    searching: 'Searching for routes',
    current_status: 'Current status: %status',
    missing_params: 'Enter amount you want to transfer to another network'
  }
};
