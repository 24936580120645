import t from 'translate';
import { SubmissionError } from 'redux-form';
import dfs from '@defisaver/sdk';
import { sendTx } from 'actions/txNotificationActions';
import { openShifterSuccessModal } from 'actions/modalActions';
import { trackEvent } from '../services/analyticsService';
import { captureException } from '../sentry';
import { callRecipeViaProxy } from '../services/contractCallService';
import { recipeActionsToDfsActions } from '../services/recipeCreator/recipeActionUtils';

export const shiftLoanAction = (formValues) => async (dispatch, getState) => {
  try {
    trackEvent('shifter', 'shift', 'start');
    const {
      general: { account, accountType },
      maker: { proxyAddress },
    } = getState();
    const { recipeActions } = formValues;
    const dfsActions = await recipeActionsToDfsActions(recipeActions, getState, formValues.simulationResults.actionCalls);
    const recipe = new dfs.Recipe('Shift', dfsActions);
    const sendTxFunc = (promise) => sendTx(promise, t('shifter.shift'), 'Shifter', dispatch, getState, { protocol: 'shifter' });
    await callRecipeViaProxy(accountType, sendTxFunc, proxyAddress, account, recipe);
    dispatch(openShifterSuccessModal());
    trackEvent('shifter', 'shift', 'success');
  } catch (err) {
    captureException(err, { formValues });
    trackEvent('shifter', 'shift', 'error', err.message);
    throw new SubmissionError({ _error: err.message });
  }
};

export const simulateActions = (actions) => async (dispatch, getState) => {
  let balances = {};
  const returnValues = [];
  const actionCalls = [];
  let expires = Infinity;
  for (const action of actions) {
    try {
      const currentPositionState = actionCalls[actionCalls.length - 1]?.positions || {};
      // (loop iterations are dependant on previous iterations)
      // eslint-disable-next-line no-await-in-loop
      const res = await action.getAfterValues(balances, returnValues, actions, currentPositionState, getState);
      balances = res.balances;
      returnValues.push(res.returnValue);
      actionCalls.push({
        args: action.mapReturnValuesToArgs(returnValues, actions),
        returnValue: res.returnValue,
        balances: { ...balances },
        positions: res.positions,
      });
      // console.log('✅ Params ok: ', action.constructor.name);
      const _expires = action.getExpires();
      if (_expires < expires) expires = _expires;
    } catch (err) {
      console.log('🚧 Params bad: ', action.constructor.name);
      console.error(err);
      break;
    }
  }
  return { actionCalls, returnValues };
};
