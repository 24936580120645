import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import t from 'translate';
import ModalHeader from '../../ModalHeader';
import ModalBody from '../../ModalBody';
import success from '../../../Exchange/exchange-success.svg';
import '../../Maker/MakerCreateCdpSuccessModal/MakerCreateCdpSuccessModal.scss';


const LiquityOpenTroveModal = ({ closeModal, history }) => (
  <div className="maker-create-cdp-success-modal-wrapper liquity-create-cdp-success-modal-wrapper">
    <ModalHeader closeModal={closeModal} />
    <ModalBody>
      <div className="modal-content">
        <img src={success} alt="" />

        <div className="title">{t('liquity.trove_created_successfully')}</div>
        <div className="container">
          <div className="description WhiteSpacePre">
            {t('liquity.trove_created_successfully_desc')}
          </div>
        </div>
      </div>
    </ModalBody>
    <div className="modal-controls">
      <button
        type="button"
        className="button"
        form="gas-price-modal-form"
        onClick={closeModal}
      >
        { t('common.set_up_later') }
      </button>

      <button
        type="button"
        className="button green"
        onClick={() => {
          history.push('/liquity/strategies/');
          closeModal();
        }}
      >
        { t('common.automate') }
      </button>
    </div>
  </div>
);

LiquityOpenTroveModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(LiquityOpenTroveModal);
