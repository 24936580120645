import React from 'react';

export default function MakerIcon(props) {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="30" height="30" rx="3" fill="#61717E" />
      <path fillRule="evenodd" clipRule="evenodd" d="M6.54528 11.1136C6.72752 11.0159 6.93287 10.9694 7.13945 10.979C7.34603 10.9887 7.54612 11.0542 7.71843 11.1686L7.73601 11.1803L13.5185 15.5973C13.7365 15.7467 13.9157 15.9463 14.0406 16.1795C14.1693 16.4196 14.2366 16.6877 14.2366 16.9601C14.2366 16.9601 14.2366 16.96 14.2366 16.9601V20.0225H12.9652V16.9601C12.9652 16.8973 12.9497 16.8355 12.9201 16.7802C12.8904 16.7249 12.8476 16.6778 12.7953 16.6431L12.7779 16.6315L7.21356 12.3811V20.0225H5.94214V12.1215C5.94218 11.9147 5.99828 11.7116 6.10449 11.5342C6.2107 11.3567 6.36303 11.2114 6.54528 11.1136Z" fill="#626D75" />
      <path fillRule="evenodd" clipRule="evenodd" d="M23.4581 11.1136C23.2759 11.0159 23.0706 10.9694 22.864 10.979C22.6574 10.9887 22.4573 11.0542 22.285 11.1686L22.2674 11.1803L16.4849 15.5973C16.2669 15.7467 16.0878 15.9463 15.9628 16.1795C15.8341 16.4196 15.7668 16.6877 15.7668 16.9601C15.7668 16.9601 15.7668 16.96 15.7668 16.9601V20.0225H17.0382V16.9601C17.0382 16.8973 17.0537 16.8355 17.0834 16.7802C17.113 16.7249 17.1558 16.6778 17.2081 16.6431L17.2255 16.6315L22.7899 12.3811V20.0225H24.0613V12.1215C24.0612 11.9147 24.0051 11.7116 23.8989 11.5342C23.7927 11.3567 23.6404 11.2114 23.4581 11.1136Z" fill="#626D75" />
    </svg>
  );
}
