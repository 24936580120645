import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { toChecksumAddress } from 'ethereumjs-util';
import { ACCOUNT_TYPES } from '../../../constants/general';
import { compareAddresses, shortAddress } from '../../../services/utils';
import { switchToAccount } from '../../../actions/accountActions';
import {
  openAddressMonitorModal, openChangeNetworkModal, openLoginModal, openManageWalletsModal, openSimulationEndModal,
  openSimulationStartModal,
} from '../../../actions/modalActions';
import WalletIcon from './WalletIcon';
import OutsideAlerter from '../../OutsideAlerter/OutsideAlerter';

const NewWalletIcon = () => (
  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.5 3V1.5C13.5 0.67275 12.8273 0 12 0H2.25C1.0095 0 0 1.0095 0 2.25V11.25C0 12.9008 1.3455 13.5 2.25 13.5H13.5C14.3273 13.5 15 12.8273 15 12V4.5C15 3.67275 14.3273 3 13.5 3ZM12 9.75H10.5V6.75H12V9.75ZM2.25 3C2.05689 2.99136 1.87455 2.90857 1.74096 2.76886C1.60736 2.62916 1.5328 2.4433 1.5328 2.25C1.5328 2.0567 1.60736 1.87084 1.74096 1.73114C1.87455 1.59143 2.05689 1.50864 2.25 1.5H12V3H2.25Z" fill="#61717E" />
  </svg>
);
const ManageIcon = () => (
  <svg width="14" height="3" viewBox="0 0 14 3" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.75 3C7.57843 3 8.25 2.32843 8.25 1.5C8.25 0.671573 7.57843 0 6.75 0C5.92157 0 5.25 0.671573 5.25 1.5C5.25 2.32843 5.92157 3 6.75 3Z" fill="#626D75" />
    <path d="M12 3C12.8284 3 13.5 2.32843 13.5 1.5C13.5 0.671573 12.8284 0 12 0C11.1716 0 10.5 0.671573 10.5 1.5C10.5 2.32843 11.1716 3 12 3Z" fill="#626D75" />
    <path d="M1.5 3C2.32843 3 3 2.32843 3 1.5C3 0.671573 2.32843 0 1.5 0C0.671573 0 0 0.671573 0 1.5C0 2.32843 0.671573 3 1.5 3Z" fill="#626D75" />
  </svg>
);

const ConnectWalletHover = ({ close }) => {
  const dispatch = useDispatch();
  const recentAccounts = useSelector(state => state.general.recentAccounts);
  let ownedAccounts = recentAccounts.filter(a => a.owned);
  const account = useSelector(state => state.general.account);
  const accountType = useSelector(state => state.general.accountType);
  const additionalAccountData = useSelector(state => state.general.additionalAccountData);

  if (account) {
    const currentAccountData = recentAccounts.find((o) => compareAddresses(o.account, account) && o.accountType === accountType);
    if (!ownedAccounts.includes(currentAccountData)) {
      if (currentAccountData) ownedAccounts = [currentAccountData, ...ownedAccounts];
      else ownedAccounts = [{ account, accountType, ...additionalAccountData }, ...ownedAccounts];
    }
  }

  return (
    <OutsideAlerter onClickOutside={close}>
      <div className="connect-wallet-hover-wrapper" onClick={close}>
        {
          ownedAccounts.slice(0, 5).map(accData => (
            <div
              className="Flex FlexStart list-item owned-wallet-option"
              onClick={() => dispatch(switchToAccount(accData.accountType, accData.account))}
              key={`${accData.account}-${accData.accountType}`}
            >
              <div className="item-icon">
                <WalletIcon accData={accData} />
                {
                  compareAddresses(accData.account, account) && <div className={`connected-indicator ${accountType !== accData.accountType ? ACCOUNT_TYPES.viewOnly : ''}`} />
                }
              </div>
              <div>
                <h3>{accData.ensName || shortAddress(toChecksumAddress(accData.account))}</h3>
                <h4>{accData.walletName}</h4>
              </div>
            </div>
          ))
        }
        { ownedAccounts.length > 0 && <hr /> }
        <div>
          <div className="Flex FlexStart list-item action" onClick={() => dispatch(openLoginModal())}>
            <div className="item-icon smaller"><NewWalletIcon /></div>
            <div>
              <h3>Connect</h3>
            </div>
          </div>
          {/* {accountType !== ACCOUNT_TYPES.fork && ( */}
          {/*  <div className="Flex FlexStart list-item action" onClick={() => dispatch(openChangeNetworkModal())}> */}
          {/*    <div className="item-icon smaller"><WalletIcon accData={{ accountType: ACCOUNT_TYPES.changeNetwork }} /></div> */}
          {/*    <div> */}
          {/*      <h3>Change network</h3> */}
          {/*    </div> */}
          {/*  </div> */}
          {/* )} */}
          {
            accountType === ACCOUNT_TYPES.fork
              ? (
                <div className="Flex FlexStart list-item action" onClick={() => dispatch(openSimulationEndModal())}>
                  <div className="item-icon smaller"><WalletIcon accData={{ accountType: ACCOUNT_TYPES.fork }} /></div>
                  <div>
                    <h3>Exit Simulation</h3>
                  </div>
                </div>
              )
              : (
                <div className="Flex FlexStart list-item action" onClick={() => dispatch(openSimulationStartModal())}>
                  <div className="item-icon smaller"><WalletIcon accData={{ accountType: ACCOUNT_TYPES.fork }} /></div>
                  <div>
                    <h3>Simulate</h3>
                  </div>
                </div>
              )
          }
          <div className="Flex FlexStart list-item action" onClick={() => dispatch(openAddressMonitorModal())}>
            <div className="item-icon smaller"><WalletIcon accData={{ accountType: 'viewonly' }} /></div>
            <div>
              <h3>Track</h3>
            </div>
          </div>
          <div className="Flex FlexStart list-item action" onClick={() => dispatch(openManageWalletsModal())}>
            <div className="item-icon smaller"><ManageIcon /></div>
            <div>
              <h3>Manage</h3>
            </div>
          </div>
        </div>
      </div>
    </OutsideAlerter>
  );
};

ConnectWalletHover.propTypes = {
  close: PropTypes.func.isRequired,
};

export default ConnectWalletHover;
