import t from 'translate';
import Dec from 'decimal.js';
import { BLOCKS_IN_A_YEAR } from '../constants/general';
import { STABLE_ASSETS } from '../constants/assets';

/**
 * Calculates earned interest based on the users compound actions
 *
 * @param principal {String}
 * @param interest {String}
 * @param type {String}
 * @param apy {Boolean}
 * @return {number}
 */
export const calculateInterestEarned = (principal, interest, type, apy = false) => {
  let interval = 1;

  if (interest === 0) return 0;

  if (type === 'month') interval = 1 / 12;
  if (type === 'week') interval = 1 / 52.1429;

  if (apy) {
    // interest rate already compounded
    return (principal * (1 + (interest / 100 * interval))) - principal;
  }

  return (principal * (((1 + (interest / 100) / BLOCKS_IN_A_YEAR)) ** (BLOCKS_IN_A_YEAR * interval))) - principal; // eslint-disable-line
};

/**
 * Formula source: http://www.linked8.com/blog/158-apy-to-apr-and-apr-to-apy-calculation-methodologies
 *
 * @param interest {Number} APY as percentage (ie. 6)
 * @param frequency {Number} Compounding frequency (times a year)
 * @returns {Number} APR as percentage (ie. 5.82 for APY of 6%)
 */
export const apyToApr = (interest, frequency = BLOCKS_IN_A_YEAR) => ((1 + (interest / 100)) ** (1 / frequency) - 1) * frequency * 100; // eslint-disable-line

/**
 * Formula source: http://www.linked8.com/blog/158-apy-to-apr-and-apr-to-apy-calculation-methodologies
 *
 * @param interest {Number} APR as percentage (ie. 5.82)
 * @param frequency {Number} Compounding frequency (times a year)
 * @returns {Number} APY as percentage (ie. 6 for APR of 5.82%)
 */
export const aprToApy = (interest, frequency = BLOCKS_IN_A_YEAR) => ((1 + (interest / 100) / frequency) ** frequency - 1) * 100; // eslint-disable-line

/**
 * Gets the max borrowAsset that the user is able to borrow and sell for supplyAsset
 * [Result could slightly underestimate due to slippage (ie. ratio could be higher so it's safe)]
 * BorrowLimit + (BoostAmountUSD * CollFactor) = Ratio * (Debt + BoostAmountUSD)
 * BoostAmountUSD = ((Ratio * Debt) - BorrowLimit) / (CollFactor - targetRatio)
 *
 * @param supplyingAssetCollFactor {number}
 * @param borrowLimit {number}
 * @param debt {number}
 * @param targetRatio {number}
 * @param bufferPercent {number}
 * @return {String}
 */
export const getMaxBoostUsd = (supplyingAssetCollFactor, borrowLimit, debt, targetRatio = 1, bufferPercent = 1) => new Dec(targetRatio).mul(debt).sub(borrowLimit)
  .div(new Dec(supplyingAssetCollFactor).sub(targetRatio).toString())
  .mul((100 - bufferPercent) / 100)
  .toString();


export const getMaxDebtWithdraw = (borrowedUsd, borrowLimitUsd, minRatio, collAmount, collFactor, collAssetPrice, debtAssetPrice, debtToCollMarketPrice) => {
  const up = new Dec(borrowLimitUsd).sub(new Dec(minRatio).mul(borrowedUsd)).add(new Dec(collAmount).mul(collFactor).mul(collAssetPrice));
  const down = new Dec(minRatio).mul(debtAssetPrice).sub(new Dec(debtToCollMarketPrice).mul(collFactor).mul(collAssetPrice));
  return up.div(down).toString();
};
/**
 * Calculates borrowing assets percentage of the borrow limit
 *
 * @param assetBorrowedUsd {String}
 * @param borrowLimitUsd {String}
 * @return {String}
 */
export const calculateBorrowingAssetLimit = (assetBorrowedUsd, borrowLimitUsd) => Dec(assetBorrowedUsd).div(borrowLimitUsd).times(100).toString();

/**
 * Goes through all supplying and borrowing assets and calculates the net apy
 *
 * @param usedAssets {Object}
 * @param assetsData {Object}
 * @return {{totalInterestUsd: string, netApy: string, incentiveUsd}}
 */
export const calculateNetApy = (usedAssets, assetsData) => {
  const sumValues = Object.values(usedAssets).reduce((_acc, usedAsset) => {
    const acc = { ..._acc };
    const assetData = assetsData[usedAsset.symbol];

    if (usedAsset.isSupplied) {
      const amount = usedAsset.suppliedUsd;
      acc.suppliedUsd = new Dec(acc.suppliedUsd).add(amount).toString();
      const rate = assetData.supplyRate;
      const supplyInterest = calculateInterestEarned(amount, rate, 'year', true);
      acc.supplyInterest = new Dec(acc.supplyInterest).add(supplyInterest.toString()).toString();
      if (assetData.incentiveSupplyApy) {
        // take COMP/AAVE yield into account
        const incentiveInterest = calculateInterestEarned(amount, assetData.incentiveSupplyApy, 'year', true);
        acc.incentiveUsd = new Dec(acc.incentiveUsd).add(incentiveInterest).toString();
      }
    }

    if (usedAsset.isBorrowed) {
      const amount = usedAsset.borrowedUsd;
      acc.borrowedUsd = new Dec(acc.borrowedUsd).add(amount).toString();
      const rate = usedAsset?.interestMode === '1' ? usedAsset.stableBorrowRate : assetData.borrowRate;
      const borrowInterest = calculateInterestEarned(amount, rate, 'year', true);
      acc.borrowInterest = new Dec(acc.borrowInterest).sub(borrowInterest.toString()).toString();
      if (assetData.incentiveBorrowApy) {
        // take COMP/AAVE yield into account
        const incentiveInterest = calculateInterestEarned(amount, assetData.incentiveBorrowApy, 'year', true);
        acc.incentiveUsd = new Dec(acc.incentiveUsd).add(incentiveInterest).toString();
      }
    }

    return acc;
  }, {
    borrowInterest: '0', supplyInterest: '0', incentiveUsd: '0', borrowedUsd: '0', suppliedUsd: '0',
  });

  const {
    borrowedUsd, suppliedUsd, borrowInterest, supplyInterest, incentiveUsd,
  } = sumValues;

  const totalInterestUsd = new Dec(borrowInterest).add(supplyInterest).add(incentiveUsd).toString();
  const balance = new Dec(suppliedUsd).sub(borrowedUsd);
  const netApy = new Dec(totalInterestUsd).div(balance).times(100).toString();

  return { netApy, totalInterestUsd, incentiveUsd };
};

/**
 * Calculates the borrow limit and borrow power used after
 * an asset enabled as collateral is disabled
 *
 * @param _underlyingAsset {String}
 * @param usedAssets {Array}
 * @param assetsData {Array}
 * @param borrowLimitUsd {String}
 * @param borrowedUsd {String}
 * @param eModeCategory {Number}
 */
export const calcBorrowValuesWithoutColl = (_underlyingAsset, usedAssets, assetsData, borrowLimitUsd, borrowedUsd, eModeCategory = 0) => {
  const usedAsset = usedAssets[_underlyingAsset];
  const assetData = assetsData[_underlyingAsset];

  const collateralFactor = (eModeCategory === 0 || new Dec(assetData.eModeCategoryData.collateralFactor).eq(0))
    ? assetData.collateralFactor
    : assetData.eModeCategoryData.collateralFactor;

  const givingToBorrowing = new Dec(usedAsset.suppliedUsd).mul(collateralFactor);
  const newBorrowLimitUsd = new Dec(borrowLimitUsd.toString()).minus(givingToBorrowing).toString();

  const borrowPowerUsed = new Dec(borrowedUsd).div(newBorrowLimitUsd).times(100).toNumber();

  return {
    borrowPowerUsed,
    borrowLimitUsd: newBorrowLimitUsd,
  };
};

/**
 * Liq. price for Long position
 * @param assetPrice {String|Number} position of unstable asset (eg. ETH)
 * @param borrowedUsd {String|Number}
 * @param borrowLimitUsd {String|Number} Can be either borrowLimitUsd or liquidationLimitUsd
 * @returns {String}
 */
export const calcLongLiqPrice = (assetPrice, borrowedUsd, borrowLimitUsd) => new Dec(assetPrice).mul(borrowedUsd).div(borrowLimitUsd).toString();

/**
 * Liq. price for Short position
 * @param assetPrice {String|Number} position of unstable asset (eg. ETH)
 * @param borrowedUsd {String|Number}
 * @param borrowLimitUsd {String|Number} Can be either borrowLimitUsd or liquidationLimitUsd
 * @returns {String}
 */
export const calcShortLiqPrice = (assetPrice, borrowedUsd, borrowLimitUsd) => new Dec(assetPrice).div(borrowedUsd).mul(borrowLimitUsd).toString();

export const calcLeverageLiqPrice = (leverageType, assetPrice, borrowedUsd, borrowLimitUsd) => {
  if (leverageType === 'short') return calcShortLiqPrice(assetPrice, borrowedUsd, borrowLimitUsd);
  if (leverageType === 'long' || leverageType === 'steth-leverage') return calcLongLiqPrice(assetPrice, borrowedUsd, borrowLimitUsd);
  console.error('invalid leverageType', leverageType);
  return '0';
};

/**
 * Checks if position is a leveraged long or short
 * (Long: borrowing only stablecoins and supplying an unstable asset)
 * (Short: borrowing only unstable asset and supplying only stablecoins)
 *
 * @param usedAssets
 * @returns {{leveragedType: string, leveragedAsset: string}}
 */
export const isLeveragedPos = (usedAssets) => {
  let borrowUnstable = 0;
  let supplyStable = 0;
  let borrowStable = 0;
  let supplyUnstable = 0;
  let longAsset = '';
  let shortAsset = '';
  Object.values(usedAssets).forEach(({
    symbol, suppliedUsd, borrowedUsd, collateral,
  }) => {
    const isSupplied = (+suppliedUsd) > 5; // ignore dust like <$5 leftover supply
    const isBorrowed = (+borrowedUsd) > 5; // ignore dust like <$5 leftover supply
    if (isSupplied && STABLE_ASSETS.includes(symbol) && collateral) supplyStable += 1;
    if (isBorrowed && STABLE_ASSETS.includes(symbol)) borrowStable += 1;
    if (isBorrowed && !STABLE_ASSETS.includes(symbol)) {
      borrowUnstable += 1;
      shortAsset = symbol;
    }
    if (isSupplied && !STABLE_ASSETS.includes(symbol) && collateral) {
      supplyUnstable += 1;
      longAsset = symbol;
    }
  });
  const isLong = borrowStable > 0 && borrowUnstable === 0 && supplyUnstable === 1 && supplyStable === 0;
  const isShort = supplyStable > 0 && supplyUnstable === 0 && borrowUnstable === 1 && borrowStable === 0;
  const isStEth = supplyUnstable === 1 && borrowUnstable === 1 && shortAsset === 'ETH' && longAsset === 'stETH';
  if (isLong) {
    return {
      leveragedType: 'long',
      leveragedAsset: longAsset,
    };
  }
  if (isShort) {
    return {
      leveragedType: 'short',
      leveragedAsset: shortAsset,
    };
  }
  if (isStEth) {
    return {
      leveragedType: 'steth-leverage',
      leveragedAsset: longAsset,
    };
  }
  return {
    leveragedType: '',
    leveragedAsset: '',
  };
};
/**
 * Calculates Repay and Boost values in $ for Aave and Compound based on leveraged position type
 * If leveraged position is not short or long, values can not be calculated
 *
 * @param leveragedType {string}
 * @param leveragedAsset {string}
 * @param usedAssets {object}
 * @param assetsData {object}
 * @param minRatio {number}
 * @param maxRatio {number}
 * @returns {{repayPrice: number, boostPrice: number}|false}
 */
export const calcAutomationPrices = (leveragedType, leveragedAsset, usedAssets, assetsData, minRatio, maxRatio) => {
  let repayPrice = 0;
  let boostPrice = 0;
  let currentUsd = 0;
  if (leveragedType === 'long') {
    for (const asset in usedAssets) {
      if (usedAssets[asset].isBorrowed) {
        currentUsd += +usedAssets[asset].borrowedUsd;
      }
    }

    repayPrice = currentUsd * minRatio / (assetsData[leveragedAsset].collateralFactor * 100) / usedAssets[leveragedAsset].supplied;
    boostPrice = currentUsd * maxRatio / (assetsData[leveragedAsset].collateralFactor * 100) / usedAssets[leveragedAsset].supplied;
  } else if (leveragedType === 'short') {
    for (const asset in usedAssets) {
      if (usedAssets[asset].isSupplied) {
        currentUsd += +usedAssets[asset].suppliedUsd * assetsData[asset].collateralFactor;
      }
    }

    repayPrice = currentUsd * 100 / minRatio / usedAssets[leveragedAsset].borrowed;
    boostPrice = currentUsd * 100 / maxRatio / usedAssets[leveragedAsset].borrowed;
  } else {
    return false;
  }
  return {
    repayPrice,
    boostPrice,
  };
};

export const getAssetsTotal = (assets, filter, transform) => Object.values(assets)
  .filter(filter)
  .map(transform)
  .reduce((acc, data) => new Dec(acc).add(data), '0')
  .toString();

export const filterCollateralAssetsFromUsedAssets = (usedAssets) => Object.values(usedAssets).filter(({ suppliedUsd }) => suppliedUsd > 0);
export const filterBorrowedAssetsFromUsedAssets = (usedAssets) => Object.values(usedAssets).filter(({ borrowedUsd }) => borrowedUsd > 0);

export const debtCreatingValidation = (firstAsset, secondAsset, usedAssets, assetsData) => (amount, max, afterValues, executing, loadingAfter) => {
  const fromAssetData = assetsData[firstAsset];
  let leftToBorrowGlobally;
  if (+fromAssetData?.borrowCap) {
    leftToBorrowGlobally = new Dec(fromAssetData.borrowCap).minus(fromAssetData.totalBorrow).toString();
    if (new Dec(leftToBorrowGlobally).lt(amount)) return t('compound.borrow_cap_exceeded');
  }
};

export const validate = (firstActionFirstAsset, firstActionSecondAsset, secondActionFirstAsset, secondActionSecondAsset, usedAssets, assetsData, validators) => (amount, max, afterValues, executing, loadingAfter, isAdditional = false) => {
  const firstAsset = !isAdditional ? firstActionFirstAsset : secondActionFirstAsset;
  const secondAsset = !isAdditional ? firstActionSecondAsset : secondActionSecondAsset;

  for (let i = 0; i < validators.length; i++) {
    const error = validators[i](firstAsset, secondAsset, usedAssets, assetsData)(amount, max, afterValues, executing, loadingAfter);
    if (error) return error;
  }
  return '';
};
