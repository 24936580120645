import {
  ADD_COMPOUND_MIGRATE_INSTADAPP_DATA,
  ALLOW_COMPOUND_MIGRATE_INSTADAPP_DATA,
  COMPOUND_MIGRATE_FROM_INSTADAPP_ERROR,
  COMPOUND_MIGRATE_FROM_INSTADAPP_REQUEST,
  COMPOUND_MIGRATE_FROM_INSTADAPP_SUCCESS,
  COMPOUND_MIGRATE_PROXY_AUTH_ERROR,
  COMPOUND_MIGRATE_PROXY_AUTH_REQUEST,
  COMPOUND_MIGRATE_PROXY_AUTH_SUCCESS,
  DENY_COMPOUND_MIGRATE_INSTADAPP_DATA,
  RESET_COMPOUND_MIGRATE_INSTADAPP_DATA,
  COMPOUND_MIGRATE_APPROVALS_FAILURE,
  COMPOUND_MIGRATE_APPROVALS_REQUEST,
  COMPOUND_MIGRATE_APPROVALS_SUCCESS,
  COMPOUND_MIGRATE_PROXY_RESET_STATE,
  MIGRATE_COMPOUND_TO_PROXY_FAILURE,
  MIGRATE_COMPOUND_TO_PROXY_REQUEST,
  MIGRATE_COMPOUND_TO_PROXY_SUCCESS,
} from '../../actionTypes/compoundActionTypes/compoundMigrateActionTypes';

const INITIAL_STATE = {
  accountData: [],
  allowData: false,

  approvalsLoading: false,
  approvalsDone: false,
  approvalsErrors: '',

  migrateEoaLoading: false,
  migrateEoaDone: false,
  migrateEoaErrors: '',
};
export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case RESET_COMPOUND_MIGRATE_INSTADAPP_DATA:
      return {
        ...state,
        accountData: [],
      };
    case ALLOW_COMPOUND_MIGRATE_INSTADAPP_DATA:
      return {
        ...state,
        allowData: true,
      };
    case DENY_COMPOUND_MIGRATE_INSTADAPP_DATA:
      return {
        ...state,
        allowData: false,
      };
    case ADD_COMPOUND_MIGRATE_INSTADAPP_DATA:
      if (!state.allowData) {
        return state;
      }
      return {
        ...state,
        accountData: payload.map(({ dsa, data }) => ({
          dsa: {
            ...dsa,
            loading: false,
          },
          data,
          migration: {
            loading: false,
            done: false,
          },
        })),
      };
    case COMPOUND_MIGRATE_PROXY_AUTH_REQUEST:
      return {
        ...state,
        accountData: state.accountData.map(({ dsa, data, migration }) => {
          const newDsa = { ...dsa };
          if (newDsa.account === payload) {
            newDsa.loading = true;
            newDsa.error = undefined;
          }
          return {
            dsa: newDsa,
            data,
            migration,
          };
        }),
      };
    case COMPOUND_MIGRATE_PROXY_AUTH_SUCCESS:
      return {
        ...state,
        accountData: state.accountData.map(({ dsa, data, migration }) => {
          const newDsa = { ...dsa };
          if (newDsa.account === payload) {
            newDsa.isAuthorizedForProxy = true;
            newDsa.loading = false;
          }
          return {
            dsa: newDsa,
            data,
            migration,
          };
        }),
      };
    case COMPOUND_MIGRATE_PROXY_AUTH_ERROR:
      return {
        ...state,
        accountData: state.accountData.map(({ dsa, data, migration }) => {
          const newDsa = { ...dsa };
          if (newDsa.account === payload.account) {
            newDsa.error = payload.error;
            newDsa.loading = false;
          }
          return {
            dsa: newDsa,
            data,
            migration,
          };
        }),
      };
    case COMPOUND_MIGRATE_FROM_INSTADAPP_REQUEST:
      return {
        ...state,
        accountData: state.accountData.map(({ dsa, data, migration }) => {
          const newMigration = { ...migration };
          if (dsa.account === payload) {
            newMigration.loading = true;
            newMigration.error = undefined;
          }
          return { dsa, data, migration: newMigration };
        }),
      };
    case COMPOUND_MIGRATE_FROM_INSTADAPP_SUCCESS: {
      return {
        ...state,
        accountData: state.accountData.map(({ dsa, data, migration }) => {
          const newMigration = { ...migration };
          if (dsa.account === payload) {
            newMigration.loading = false;
            newMigration.done = true;
          }
          return { dsa, data, migration: newMigration };
        }),
      };
    }
    case COMPOUND_MIGRATE_FROM_INSTADAPP_ERROR: {
      return {
        ...state,
        accountData: state.accountData.map(({ dsa, data, migration }) => {
          const newMigration = { ...migration };
          if (dsa.account === payload.account) {
            newMigration.loading = false;
            newMigration.error = payload.error;
          }
          return { dsa, data, migration: newMigration };
        }),
      };
    }
    case COMPOUND_MIGRATE_APPROVALS_REQUEST: {
      return {
        ...state,
        approvalsLoading: true,
        approvalsDone: false,
        approvalsErrors: '',
      };
    }
    case COMPOUND_MIGRATE_APPROVALS_SUCCESS: {
      return {
        ...state,
        approvalsLoading: false,
        approvalsDone: true,
      };
    }
    case COMPOUND_MIGRATE_APPROVALS_FAILURE: {
      return {
        ...state,
        approvalsLoading: false,
        approvalsErrors: payload,
      };
    }
    case MIGRATE_COMPOUND_TO_PROXY_REQUEST: {
      return {
        ...state,
        migrateEoaLoading: true,
        migrateEoaDone: false,
        migrateEoaErrors: '',
      };
    }
    case MIGRATE_COMPOUND_TO_PROXY_SUCCESS: {
      return {
        ...state,
        migrateEoaLoading: false,
        migrateEoaDone: true,
      };
    }
    case MIGRATE_COMPOUND_TO_PROXY_FAILURE: {
      return {
        ...state,
        migrateEoaLoading: false,
        migrateEoaErrors: payload,
      };
    }
    case COMPOUND_MIGRATE_PROXY_RESET_STATE: {
      return {
        ...state,
        approvalsLoading: false,
        approvalsDone: false,
        approvalsErrors: '',

        migrateEoaLoading: false,
        migrateEoaDone: false,
        migrateEoaErrors: '',
      };
    }
    default:
      return state;
  }
};
