import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';

const LanguageSwitchCustomValueContainer = (props) => {
  const option = props.getValue()[0];
  return (
    <components.ValueContainer {...props}>
      <div className="value-container">
        <span className="option-value">{ option.abrLabel }</span>
      </div>

      { props.children }
    </components.ValueContainer>
  );
};

LanguageSwitchCustomValueContainer.propTypes = {
  getValue: PropTypes.func.isRequired,
  selectOption: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  getStyles: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default LanguageSwitchCustomValueContainer;
