import Dec from 'decimal.js';
import cloneDeep from 'lodash/cloneDeep';
import { change } from 'redux-form';
import { assetAmountInEth, assetAmountInWei, getIlkInfo } from '@defisaver/tokens';
import { getBestExchangePrice } from '../../../services/exchangeServiceV3';
import { ethToWeth, getEthAmountForDecimals, resetFields } from '../../../services/utils';
import {
  GET_AFTER_CDP_FAILURE,
  GET_AFTER_CDP_REQUEST,
  GET_AFTER_CDP_SUCCESS,
} from '../../../actionTypes/makerActionTypes/makerManageActionTypes/makerManageActionTypes';
import { captureException } from '../../../sentry';
import { changeBalance } from '../../../services/recipeCreator/recipeActionUtils';
import { ALLOWED_PERCENT_OVER_LIQ_RATIO_MAKER, LOWER_AUTOMATION_WHITELIST } from '../../../constants/general';
import {
  getBoostAmount, getMaxBoost, getMaxBorrow, getMaxPayback, getRepayAmount, getMaxWithdraw,
} from '../../../services/vaultCommonService';
import { formName } from '../../../components/DashboardActions/dashboardActionUtils';
import {
  getLPLiquidityMintedEstimate,
  getLPLiquidityValueEstimate,
  getUniswapPrice,
} from '../../../services/uniswapServices';
import { ASSETS_FROM_MAKER_ASSET } from '../../../constants/assets';
import { normalizeFunc, setSlippagePercent } from '../../../services/exchangeServiceCommon';
import { getActualMintAmounts, getBurnAmounts } from '../../../services/gelatoService';
import { getBurnAmountOneToken, getLpEstimate } from '../../../services/curveServices/curveService';

export const getAggregatedPositionData = (cdp) => {
  const {
    liqRatio, debtDai, collateral, assetPrice,
  } = cdp;

  const liquidationPrice = new Dec(debtDai).times(liqRatio).div(collateral).toNumber();
  if (liquidationPrice === 0) {
    return {
      ...cdp, liquidationPrice: 0, ratio: 0, debtInAsset: 0, debtTooLow: false,
    };
  }

  const ratio = new Dec(collateral)
    .times(assetPrice)
    .div(debtDai)
    .times(100)
    .toDecimalPlaces(2, Dec.ROUND_HALF_EVEN)
    .toNumber();

  const debtInAsset = debtDai;
  const debtTooLow = new Dec(debtInAsset).gt(0) && new Dec(debtInAsset).lt(cdp.minDebt);
  const collateralUsd = new Dec(collateral).mul(assetPrice).toString();
  const debtUsd = debtDai;
  // if (ratio > 1e10) ratio = 0;

  return {
    ...cdp,
    liquidationPrice,
    ratio,
    debtInAsset,
    debtTooLow,
    collateralUsd,
    debtUsd,
    disabledBecauseDusty: debtTooLow, // TODO rename disabledBecauseDusty
  };
};

const fullPosition = (getAfterValues) => async (input, stateData, balances = {}) => {
  const result = await getAfterValues.apply(this, [input, stateData, balances]);
  return {
    balances: result.balances,
    returnValue: result.returnValue,
    afterPosition: getAggregatedPositionData(result.cdp),
  };
};
export const generateAfterValues = fullPosition(async ({ amount, to = 'wallet' }, {
  cdp: _cdp, assets, account, proxyAddress,
}, _balances = {}) => {
  const cdp = { ..._cdp };
  const balances = cloneDeep(_balances);
  cdp.debtDai = new Dec(cdp.debtDai).plus(amount).toString();
  cdp.debtUsd = cdp.debtDai;
  await changeBalance(balances, to, 'DAI', amount, to === 'wallet' ? account : proxyAddress);
  return { cdp, balances, returnValue: amount };
});

export const paybackAfterValues = fullPosition(async ({ amount, from = 'wallet' }, {
  cdp: _cdp, assets, account, proxyAddress,
}, _balances = {}) => {
  const cdp = { ..._cdp };
  const balances = cloneDeep(_balances);
  cdp.debtDai = Dec.max(0, new Dec(cdp.debtDai).minus(amount || '0')).toString();
  cdp.debtUsd = cdp.debtDai;
  await changeBalance(balances, from, 'DAI', new Dec(amount || '0').times(-1), from === 'wallet' ? account : proxyAddress);
  return { cdp, balances, returnValue: amount };
});

export const addCollateralAfterValues = fullPosition(async ({ amount, from = 'wallet' }, {
  cdp: _cdp, assets, account, proxyAddress,
}, _balances = {}) => {
  const cdp = { ..._cdp };
  const balances = cloneDeep(_balances);
  cdp.collateral = new Dec(cdp.collateral).plus(amount).toString();
  cdp.collateralUsd = new Dec(cdp.collateral).mul(cdp.assetPrice).toString();
  await changeBalance(balances, from, cdp.asset.replace(/^ETH/, 'WETH'), new Dec(amount).times(-1), from === 'wallet' ? account : proxyAddress);
  return { cdp, balances, returnValue: amount };
});

export const withdrawAfterValues = fullPosition(async ({ amount, to = 'wallet' }, {
  cdp: _cdp, assets, account, proxyAddress,
}, _balances = {}) => {
  const cdp = { ..._cdp };
  const balances = cloneDeep(_balances);
  cdp.collateral = new Dec(cdp.collateral).minus(amount).toString();
  cdp.collateralUsd = new Dec(cdp.collateral).mul(cdp.assetPrice).toString();
  await changeBalance(balances, to, cdp.asset.replace(/^ETH/, 'WETH'), amount, to === 'wallet' ? account : proxyAddress);
  return { cdp, balances, returnValue: amount };
});

export const boostAfterValues = fullPosition(async ({ amount }, { proxyAddress, cdp: _cdp }, _balances = {}) => {
  const cdp = { ..._cdp };
  const balances = cloneDeep(_balances);
  const { price: rate } = await getBestExchangePrice(amount.toString(), 'DAI', cdp.asset, proxyAddress);
  cdp.debtDai = new Dec(cdp.debtDai).plus(amount).toString();
  cdp.collateral = new Dec(cdp.collateral).plus(new Dec(amount).times(rate)).toString();
  return { cdp, balances, returnValue: amount };
});

export const boostDAILPAfterValues = fullPosition(async ({ amount }, { proxyAddress, cdp: _cdp }, _balances = {}) => {
  const cdp = { ..._cdp };
  const balances = cloneDeep(_balances);
  const assets = ASSETS_FROM_MAKER_ASSET(cdp.asset);
  const daiAmount = new Dec(amount).div(2).toString();

  const otherAsset = assets.isFirstDAI ? assets.secondAsset : assets.firstAsset;

  const { price, source } = await getBestExchangePrice(daiAmount, 'DAI', otherAsset, proxyAddress);

  const otherAmount = new Dec(daiAmount).mul(price).toString();

  const [liquidityEstimate, lpAddress] = await getLPLiquidityMintedEstimate(ethToWeth(assets.firstAsset), assets.isFirstDAI ? daiAmount : otherAmount, ethToWeth(assets.secondAsset), assets.isFirstDAI ? otherAmount : daiAmount);
  cdp.debtDai = new Dec(cdp.debtDai).plus(amount).toString();
  cdp.collateral = new Dec(cdp.collateral).plus(liquidityEstimate).toString();
  return { cdp, balances, returnValue: amount };
});

export const boostWithoutDAILPAfterValues = fullPosition(async ({ amount }, { proxyAddress, cdp: _cdp }, _balances = {}) => {
  const cdp = { ..._cdp };
  const balances = cloneDeep(_balances);
  const assets = ASSETS_FROM_MAKER_ASSET(cdp.asset);
  const daiAmount = new Dec(amount).div(2).toString();

  const { price: rate } = await getBestExchangePrice(daiAmount, 'DAI', ethToWeth(assets.firstAsset), proxyAddress);
  const { price: rateSecond } = await getBestExchangePrice(daiAmount, 'DAI', ethToWeth(assets.secondAsset), proxyAddress);

  const firstAmount = new Dec(daiAmount).mul(rate).toString();
  const secondAmount = new Dec(daiAmount).mul(rateSecond).toString();

  const [liquidityEstimate, lpAddress] = await getLPLiquidityMintedEstimate(ethToWeth(assets.firstAsset), firstAmount, ethToWeth(assets.secondAsset), secondAmount);
  cdp.debtDai = new Dec(cdp.debtDai).plus(amount).toString();
  cdp.collateral = new Dec(cdp.collateral).plus(liquidityEstimate).toString();
  return { cdp, balances, returnValue: amount };
});

export const boostGUNIAfterValues = fullPosition(async ({ amount }, { proxyAddress, cdp: _cdp }, _balances = {}) => {
  const cdp = { ..._cdp };
  const balances = cloneDeep(_balances);

  const amounts = await getActualMintAmounts(getIlkInfo(cdp.ilk).assetAddress, amount);
  const lpLiquidity = getEthAmountForDecimals(amounts.mintAmount, 18);

  cdp.collateral = new Dec(cdp.collateral).plus(lpLiquidity).toString();
  cdp.debtDai = new Dec(cdp.debtDai).plus(amount).toString();
  return { cdp, balances, returnValue: amount };
});

export const boostCurveAfterValues = fullPosition(async ({ amount }, { proxyAddress, cdp: _cdp }, _balances = {}) => {
  const cdp = { ..._cdp };
  const balances = cloneDeep(_balances);

  const { price: rate } = await getBestExchangePrice(amount, 'DAI', 'WETH', proxyAddress);
  const ethAmount = new Dec(rate).mul(amount).toString();

  const lpLiquidity = await getLpEstimate(assetAmountInWei(ethAmount, 'WETH'));

  cdp.collateral = new Dec(cdp.collateral).plus(lpLiquidity).toString();
  cdp.debtDai = new Dec(cdp.debtDai).plus(amount).toString();
  return { cdp, balances, returnValue: amount };
});

export const repayAfterValues = fullPosition(async ({ amount }, { proxyAddress, cdp: _cdp }, _balances = {}) => {
  const cdp = { ..._cdp };
  const balances = cloneDeep(_balances);
  const { price: rate } = await getBestExchangePrice(amount.toString(), cdp.asset, 'DAI', proxyAddress);
  cdp.collateral = new Dec(cdp.collateral).minus(amount).toString();
  cdp.debtDai = new Dec(cdp.debtDai).minus(new Dec(amount).times(rate)).toString();
  if (new Dec(cdp.debtDai).lt(0)) cdp.debtDai = '0';
  return { cdp, balances, returnValue: amount };
});

export const repayDAILPAfterValues = fullPosition(async ({ amount }, { proxyAddress, cdp: _cdp }, _balances = {}) => {
  const cdp = { ..._cdp };
  const balances = cloneDeep(_balances);
  const assets = ASSETS_FROM_MAKER_ASSET(cdp.asset);
  const [firstAmount, secondAmount, lpAddress] = await getLPLiquidityValueEstimate(ethToWeth(assets.firstAsset), ethToWeth(assets.secondAsset), amount);
  const minFirstAmount = setSlippagePercent(normalizeFunc(0.5), firstAmount);
  const minSecondAmount = setSlippagePercent(normalizeFunc(0.5), secondAmount);

  const sellAmount = assets.isFirstDAI ? minSecondAmount : minFirstAmount;
  const sellAsset = assets.isFirstDAI ? assets.secondAsset : assets.firstAsset;

  // dai amount
  const daiAmount = assets.isFirstDAI ? minFirstAmount : minSecondAmount;

  const { price: exchangeRate, source } = await getBestExchangePrice(sellAmount, sellAsset, 'DAI', proxyAddress);
  const repayAmount = new Dec(exchangeRate).times(sellAmount).toString();

  cdp.collateral = new Dec(cdp.collateral).minus(amount).toString();
  cdp.debtDai = new Dec(cdp.debtDai).minus(daiAmount).minus(repayAmount).toString();

  if (new Dec(cdp.debtDai).lt(0)) cdp.debtDai = '0';
  return { cdp, balances, returnValue: amount };
});

export const repayWithoutDAILPAfterValues = fullPosition(async ({ amount }, { proxyAddress, cdp: _cdp }, _balances = {}) => {
  const cdp = { ..._cdp };
  const balances = cloneDeep(_balances);
  const assets = ASSETS_FROM_MAKER_ASSET(cdp.asset);
  const [firstAmount, secondAmount, lpAddress] = await getLPLiquidityValueEstimate(ethToWeth(assets.firstAsset), ethToWeth(assets.secondAsset), amount);
  const minFirstAmount = setSlippagePercent(0.5, firstAmount);
  const minSecondAmount = setSlippagePercent(0.5, secondAmount);

  const { price: rate } = await getBestExchangePrice(firstAmount.toString(), ethToWeth(assets.firstAsset), 'DAI', proxyAddress);
  const { price: rateSecond } = await getBestExchangePrice(secondAmount.toString(), ethToWeth(assets.secondAsset), 'DAI', proxyAddress);

  const daiFirstAmount = new Dec(minFirstAmount).mul(rate).toString();
  const daiSecondAmount = new Dec(minSecondAmount).mul(rateSecond).toString();


  cdp.collateral = new Dec(cdp.collateral).minus(amount).toString();
  cdp.debtDai = new Dec(cdp.debtDai).minus(daiFirstAmount).minus(daiSecondAmount).toString();

  if (new Dec(cdp.debtDai).lt(0)) cdp.debtDai = '0';
  return { cdp, balances, returnValue: amount };
});

export const repayGUNIAfterValues = fullPosition(async ({ amount }, { proxyAddress, cdp: _cdp }, _balances = {}) => {
  const cdp = { ..._cdp };
  const balances = cloneDeep(_balances);
  const assets = ASSETS_FROM_MAKER_ASSET(cdp.asset);

  const amounts = await getBurnAmounts(getIlkInfo(cdp.ilk).assetAddress, proxyAddress, amount, getIlkInfo(cdp.ilk).join);
  const firstAmount = assetAmountInEth(amounts.amount0, assets.firstAsset);
  const secondAmount = assetAmountInEth(amounts.amount1, assets.secondAsset);

  const minFirstAmount = setSlippagePercent(normalizeFunc(0.5), firstAmount);
  const minSecondAmount = setSlippagePercent(normalizeFunc(0.5), secondAmount);

  const [[sellAmount, sellAsset], [daiAmount, daiAsset]] = assets.isFirstDAI ? [[minSecondAmount, assets.secondAsset], [minFirstAmount, assets.firstAsset]] : [[minFirstAmount, assets.firstAsset], [minSecondAmount, assets.secondAsset]];

  const { price: rate } = await getBestExchangePrice(sellAmount, sellAsset, 'DAI', proxyAddress);

  const daiFromOtherAssetAmount = new Dec(sellAmount).mul(rate).toString();


  cdp.collateral = new Dec(cdp.collateral).minus(amount).toString();
  cdp.debtDai = new Dec(cdp.debtDai).minus(daiAmount).minus(daiFromOtherAssetAmount).toString();

  if (new Dec(cdp.debtDai).lt(0)) cdp.debtDai = '0';
  return { cdp, balances, returnValue: amount };
});

// only for ilk ETH/stETH
export const repayCurveAfterValues = fullPosition(async ({ amount }, { proxyAddress, cdp: _cdp }, _balances = {}) => {
  const cdp = { ..._cdp };
  const balances = cloneDeep(_balances);
  const burnAmount = await getBurnAmountOneToken(amount, 0);

  const { price: rate } = await getBestExchangePrice(burnAmount, 'WETH', 'DAI', proxyAddress);

  const daiAmount = new Dec(burnAmount).mul(rate).toString();

  cdp.collateral = new Dec(cdp.collateral).minus(amount).toString();
  cdp.debtDai = new Dec(cdp.debtDai).minus(daiAmount).toString();

  if (new Dec(cdp.debtDai).lt(0)) cdp.debtDai = '0';
  return { cdp, balances, returnValue: amount };
});


export const mergeAfterValues = fullPosition(({ collAmount, debtAmount }, { cdp: _cdp }, _balances = {}) => {
  const cdp = { ..._cdp };
  const balances = cloneDeep(_balances);
  cdp.collateral = new Dec(cdp.collateral).plus(collAmount).toString();
  cdp.collateralUsd = new Dec(cdp.collateral).mul(cdp.assetPrice).toString();
  cdp.debtDai = new Dec(cdp.debtDai).plus(debtAmount).toString();
  cdp.debtUsd = new Dec(cdp.debtDai).mul(cdp.debtAssetPrice).toString();
  return { cdp, balances, returnValue: cdp };
});

const getRepayAfterValues = (cdpAsset) => {
  if (cdpAsset.startsWith('UNIV2')) {
    const assets = ASSETS_FROM_MAKER_ASSET(cdpAsset);
    return assets.hasDAI ? repayDAILPAfterValues : repayWithoutDAILPAfterValues;
  }
  if (cdpAsset.startsWith('GUNI')) return repayGUNIAfterValues;
  if (cdpAsset.startsWith('steCRV')) return repayCurveAfterValues;
  return repayAfterValues;
};

const getBoostAfterValues = (cdpAsset) => {
  if (cdpAsset.startsWith('UNIV2')) {
    const assets = ASSETS_FROM_MAKER_ASSET(cdpAsset);
    return assets.hasDAI ? boostDAILPAfterValues : boostWithoutDAILPAfterValues;
  }
  if (cdpAsset.startsWith('GUNI')) return boostGUNIAfterValues;
  if (cdpAsset.startsWith('steCRV')) return boostCurveAfterValues;
  return boostAfterValues;
};

const getAfterValue = async (action, name, inputs, data, assets, account, proxyAddress, isSecondary = false) => {
  let afterValues;
  switch (action) {
    case 'collateral': {
      afterValues = (await addCollateralAfterValues({ amount: inputs[name] }, { cdp: data, account, assets })).afterPosition;
      if (!isSecondary || !afterValues.max) afterValues.max = {};
      afterValues.max.boost = await getMaxBoost(afterValues, proxyAddress);
      afterValues.max.generate = getMaxBorrow(afterValues);
      break;
    }
    case 'payback': {
      afterValues = (await paybackAfterValues({ amount: inputs[name] }, { cdp: data, account, assets })).afterPosition;
      if (!isSecondary || !afterValues.max) afterValues.max = {};
      afterValues.max.withdraw = getMaxWithdraw(afterValues);
      break;
    }
    case 'repay': {
      const afterValuesFunc = getRepayAfterValues(data.asset);
      afterValues = (await afterValuesFunc({ amount: inputs[name] }, { cdp: data, proxyAddress })).afterPosition;
      if (!isSecondary || !afterValues.max) afterValues.max = {};
      if (name.split('-')[0] === 'repay') { // Don't calculate max withdraw and borrow when repay is additional action
        afterValues.max.withdraw = getMaxWithdraw(afterValues);
        afterValues.max.generate = getMaxBorrow(afterValues);
      }
      break;
    }
    case 'withdraw': {
      afterValues = (await withdrawAfterValues({ amount: inputs[name] }, { cdp: data, account, assets })).afterPosition;
      // we send data instead of afterValues to getMaxPayback because we need withdraw + payback before
      if (!isSecondary || !afterValues.max) afterValues.max = {};
      afterValues.max.payback = getMaxPayback(data, assets.DAI.balance, false);
      afterValues.max.send = inputs[name];
      afterValues.max.sell = inputs[name];
      break;
    }
    case 'generate': {
      afterValues = (await generateAfterValues({ amount: inputs[name] }, { cdp: data, account, assets })).afterPosition;
      if (!isSecondary || !afterValues.max) afterValues.max = {};
      afterValues.max.collateral = assets.ETH.balance;
      afterValues.max.send = inputs[name];
      afterValues.max.sell = inputs[name];
      break;
    }
    case 'boost': {
      const afterValuesFunc = getBoostAfterValues(data.asset);
      afterValues = (await afterValuesFunc({ amount: inputs[name] }, { cdp: data, proxyAddress })).afterPosition;
      if (!isSecondary || !afterValues.max) afterValues.max = {};
      afterValues.max.collateral = assets.ETH.balance;
      afterValues.max.payback = getMaxPayback(afterValues, assets.DAI.balance, false);
      break;
    }
    default:
      afterValues = data;
      break;
  }
  return afterValues;
};

/**
 * Calculates the changed cdp value
 * Called on ActionItem input
 *
 * @param amount {String} input.value
 * @param type {String} input.name
 * @return {Function}
 */
export const setAfterValue = (amount, type) => async (dispatch, getState) => {
  const makerManage = getState().makerManage;
  const contextAction = makerManage.selectedAction?.value;
  const additionalAction = makerManage.selectedAdditionalActions[contextAction]?.value;
  const flipActions = !makerManage.selectedAction?.goesFirst;
  const firstAction = flipActions ? additionalAction : contextAction;
  const secondAction = flipActions ? contextAction : additionalAction;
  const inputs = {
    ...getState().form[formName]?.values,
    // form.dashboardActions.values[type] propagation hasn't reached the reducer state yet,
    // so we replace it with the value passed via onChange
    [`${contextAction}-${type}`]: amount,
  };
  const firstLabel = `${contextAction}-${firstAction}`;
  const secondLabel = `${contextAction}-${secondAction}`;

  if (type === 'clear' || (!+inputs[firstLabel] && !+inputs[secondLabel])) {
    return dispatch({ type: GET_AFTER_CDP_SUCCESS, payload: { afterCdp: null, afterType: '' } });
  }

  dispatch({ type: GET_AFTER_CDP_REQUEST });

  try {
    const {
      maker: { cdp, proxyAddress, graphData },
      makerStrategies: { makerSubscribedStrategies },
      general: { account },
      assets,
    } = getState();

    const minRatio = graphData?.minRatio || 0;
    const maxRatio = graphData?.maxRatio || Infinity;
    const boostEnabled = graphData?.boostEnabled || false;
    const targetRatio = cdp.ratio / 100;

    const payload = {
      afterType: contextAction,
      afterCdp: { afterType: `${contextAction}-${additionalAction}`, ...cdp },
    };

    if (additionalAction === 'repay' && secondAction && inputs[secondLabel]) {
      const tmpAfter = await getAfterValue(secondAction, secondLabel, inputs, payload.afterCdp, assets, account, proxyAddress);
      const label = `${contextAction}-${additionalAction}`;
      inputs[label] = await getRepayAmount(tmpAfter, proxyAddress, targetRatio);
      dispatch(change(formName, label, inputs[label]));
    }
    if (firstAction && inputs[firstLabel]) {
      payload.afterCdp = await getAfterValue(firstAction, firstLabel, inputs, payload.afterCdp, assets, account, proxyAddress);
    }
    if (additionalAction === 'boost') {
      const label = `${contextAction}-${additionalAction}`;
      inputs[label] = await getBoostAmount(payload.afterCdp, proxyAddress, targetRatio);
      dispatch(change(formName, label, inputs[label]));
    }
    if (secondAction && inputs[secondLabel]) {
      payload.afterCdp = await getAfterValue(secondAction, secondLabel, inputs, payload.afterCdp, assets, account, proxyAddress, true);
    }

    if (payload.afterCdp && payload.afterCdp.ratio) {
      let strategiesRatioTooLow = false; // TODO should probably notify about specific strategy once we add more of them
      let strategiesRatioTooHigh = false;
      makerSubscribedStrategies.forEach((strategy) => {
        if (strategy.graphData.repayEnabled && parseFloat(payload.afterCdp.ratio) < strategy.graphData.minRatio) strategiesRatioTooLow = true;
        if (strategy.graphData.boostEnabled && parseFloat(payload.afterCdp.ratio) > strategy.graphData.maxRatio) strategiesRatioTooHigh = true;
      });

      payload.ratioTooLow = parseFloat(payload.afterCdp.ratio) < minRatio || strategiesRatioTooLow;
      payload.ratioTooHigh = (boostEnabled && parseFloat(payload.afterCdp.ratio) > maxRatio) || strategiesRatioTooHigh;
    }

    if (payload.afterCdp && payload.afterCdp.ratio && cdp.isSubscribedToAutomation) {
      const liqBuffer = LOWER_AUTOMATION_WHITELIST[account.toLowerCase()] || ALLOWED_PERCENT_OVER_LIQ_RATIO_MAKER;
      payload.afterCdp.disabledBecauseTooLowForRepay = parseFloat(payload.afterCdp.ratio) < ((cdp.liqRatio * 100) + liqBuffer);
    }

    dispatch({ type: GET_AFTER_CDP_SUCCESS, payload });
  } catch (err) {
    dispatch({ type: GET_AFTER_CDP_FAILURE, payload: err.message });
    captureException(err);
  }
};
