import React from 'react';
import t from 'translate';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';

import { getAssetInfo } from '@defisaver/tokens';
import Dec from 'decimal.js';
import ArrowCaret from '../../../Decorative/ArrowCaret';
import InfoBox from '../../../Decorative/InfoBox/InfoBox';
import TooltipWrapper from '../../../TooltipWrapper/TooltipWrapper';
import { formatNumber, numberWithCommas } from '../../../../services/utils';

import './LPInfo.scss';

const swapTooltip = (rate, label) => `${numberWithCommas(rate, 4)} ${label} = ${numberWithCommas(new Dec(1).div(rate).toString(), 4)} ${label.split('/').reverse().join('/')}`;

const LPInfoItem = ({
  amount, asset, actionName, exchangeRate, exchangeLabel, actionNameAdditional,
}) => (
  <div className="action-item">
    <div className="action-item__name">
      {actionName}
    </div>
    <TooltipWrapper className="action-item__asset" copy title={amount}>
      {getAssetInfo(asset).icon()}
      <p className="action-item__asset__amount">{formatNumber(parseFloat(amount), 2)}
        <span className="action-item__asset__symbol">{asset}</span>
        <span className="action-item__asset__symbol">{actionNameAdditional}</span>
      </p>
    </TooltipWrapper>
  </div>
);

LPInfoItem.defaultProps = {
  exchangeRate: '',
  exchangeLabel: '',
  actionNameAdditional: '',
};

LPInfoItem.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  asset: PropTypes.string.isRequired,
  actionName: PropTypes.string.isRequired,
  exchangeRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  exchangeLabel: PropTypes.string,
  actionNameAdditional: PropTypes.string,
};

const SwapInfo = ({
  sellAmount, sellAsset, otherAsset, amountAfterSelling, exchangeLabel, exchangeRate,
}) => (
  <div className="swap-label">
    <span>{'Swapping '}</span>
    <TooltipWrapper
      useText
      title={sellAmount}
    >
      {`${formatNumber(sellAmount)} ${sellAsset}`}
    </TooltipWrapper>
    <span>{' for '}</span>
    <TooltipWrapper
      useText
      title={amountAfterSelling}
    >
      {`${formatNumber(amountAfterSelling)} ${otherAsset}`}
    </TooltipWrapper>
    <br />
    <span>{' (with rate '}</span>
    <TooltipWrapper
      useText
      title={numberWithCommas(exchangeRate)}
      className="highlighted"
    >
      {formatNumber(exchangeRate)}
    </TooltipWrapper>
    <TooltipWrapper
      useText
      title={swapTooltip(exchangeRate, exchangeLabel)}
    >
      {` ${exchangeLabel}`}
    </TooltipWrapper>
    <span>)</span>
  </div>
);
SwapInfo.defaultProps = {
  sellAmount: '',
  sellAsset: '',
  otherAsset: '',
  amountAfterSelling: '',
  exchangeLabel: '',
  exchangeRate: '',
};

SwapInfo.propTypes = {
  sellAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  amountAfterSelling: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sellAsset: PropTypes.string,
  otherAsset: PropTypes.string,
  exchangeLabel: PropTypes.string,
  exchangeRate: PropTypes.string,
};

const LPInfo = ({
  advanced, setAdvanced, exchangeRate, exchangeLabel, isRepay, lpAction, exchangeRateSecond, exchangeLabelSecond,
}) => (
  <div className="advanced__wrapper-lp">
    <div className="advanced">
      <div className="advanced__title-wrapper" onClick={() => setAdvanced(prev => !prev)}>
        <div className="advanced__title">{ isRepay ? t('common.repay_details') : t('common.boost_details') }</div>
        <ArrowCaret to={advanced ? 'up' : 'down'} />
      </div>
      <AnimateHeight duration={300} height={advanced ? 'auto' : 0}>
        <div className="advanced__content">
          <div className="advanced__content__section">
            {!isRepay && (
            <>
              <SwapInfo
                sellAmount={lpAction.lpSellAmount}
                sellAsset="DAI"
                otherAsset={lpAction.lpSellAsset}
                amountAfterSelling={lpAction.boostAmount}
                exchangeLabel={exchangeLabel}
                exchangeRate={exchangeRate}
              />
              {
                    exchangeRateSecond && (
                      <SwapInfo
                        sellAmount={lpAction.lpSellAmountSecond}
                        sellAsset="DAI"
                        otherAsset={lpAction.lpSellAssetSecond}
                        amountAfterSelling={lpAction.boostAmountSecond}
                        exchangeLabel={exchangeLabelSecond}
                        exchangeRate={exchangeRateSecond}
                      />
                    )
                  }
            </>
            )}
            <LPInfoItem
              amount={lpAction.firstAmount}
              actionName={isRepay ? t('common.withdrawing') : t('common.supplying')}
              asset={lpAction.firstAsset}
              actionNameAdditional={isRepay ? `from ${lpAction.lpProtocol}` : `to ${lpAction.lpProtocol}`}
            />
            {!lpAction.lpOneAsset && (
            <LPInfoItem
              amount={lpAction.secondAmount}
              actionName={isRepay ? t('common.withdrawing') : t('common.supplying')}
              asset={lpAction.secondAsset}
              actionNameAdditional={isRepay ? `from ${lpAction.lpProtocol}` : `to ${lpAction.lpProtocol}`}
            />
            )}
            {isRepay && (
            <>
              <SwapInfo
                sellAmount={lpAction.lpSellAmount}
                sellAsset={lpAction.lpSellAsset}
                otherAsset="DAI"
                amountAfterSelling={lpAction.repayAmount}
                exchangeLabel={exchangeLabel}
                exchangeRate={exchangeRate}
              />
              {
                    exchangeRateSecond && (
                      <SwapInfo
                        sellAmount={lpAction.lpSellAmountSecond}
                        sellAsset={lpAction.lpSellAssetSecond}
                        otherAsset="DAI"
                        amountAfterSelling={lpAction.repayAmountSecond}
                        exchangeLabel={exchangeLabelSecond}
                        exchangeRate={exchangeRateSecond}
                      />
                    )
                  }
            </>
            )}
          </div>
        </div>
      </AnimateHeight>
    </div>
  </div>
);

LPInfo.defaultProps = {
  isRepay: false,
  lpAction: {},
  exchangeRateSecond: '',
  exchangeLabelSecond: '',
};

LPInfo.propTypes = {
  advanced: PropTypes.bool.isRequired,
  setAdvanced: PropTypes.func.isRequired,
  exchangeRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  exchangeLabel: PropTypes.string.isRequired,
  isRepay: PropTypes.bool,
  lpAction: PropTypes.object,
  exchangeRateSecond: PropTypes.string,
  exchangeLabelSecond: PropTypes.string,
};

export default LPInfo;
