import React, { useEffect, useState } from 'react';
import t from 'translate';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import ModalHeader from '../../ModalHeader';
import ModalBody from '../../ModalBody';
import headerBg from '../CompoundProxyMigrationModal/upgrade-modal-bg.svg';
import { migrateCompoundFromInstadapp } from '../../../../actions/compoundActions/compoundMigrateActions';
import { openLoginModal } from '../../../../actions/modalActions';

import './CompoundInstadappMigrationModal.scss';
import MigrateModalContent from './MigrateModalContent/MigrateModalContent';
import MigrateModalStatusSteps from './MigrateModalStatusSteps/MigrateModalStatusSteps';

const CompoundInstadappMigrationModal = ({ closeModal }) => {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState(undefined);
  const accountType = useSelector(({ general }) => general.accountType);
  const step1Status = useSelector(({
    maker: {
      proxyAddress,
      creatingDSProxy,
      creatingDSProxyError,
    },
  }) => {
    let step1Status = 'empty';
    if (proxyAddress) step1Status = 'done';
    if (creatingDSProxy) step1Status = 'loading';
    if (creatingDSProxyError) step1Status = 'error';
    return step1Status;
  });
  const {
    instaPositions,
    step2Status,
    step3Status,
  } = useSelector(({ compoundMigrate }) => {
    const instaPositions = compoundMigrate.accountData;
    let step2Status = 'empty';
    let step3Status = 'empty';
    if (selectedOption?.value.dsa.account) {
      const currentlySelected = instaPositions.find(({ dsa }) => dsa.account === selectedOption.value.dsa.account);
      if (currentlySelected) {
        if (currentlySelected.dsa.isAuthorizedForProxy) step2Status = 'done';
        if (currentlySelected.dsa.loading) step2Status = 'loading';
        if (currentlySelected.dsa.error) step2Status = 'error';

        if (currentlySelected.migration.done) step3Status = 'done';
        if (currentlySelected.migration.loading) step3Status = 'loading';
        if (currentlySelected.migration.error) step3Status = 'error';
      }
    }
    return {
      instaPositions,
      step2Status,
      step3Status,
    };
  });
  const instaPositionsSelectOptions = instaPositions.map(({
    dsa,
    data,
  }) => ({
    label: dsa.id,
    value: {
      dsa,
      data,
    },
  }));
  useEffect(() => {
    setSelectedOption(instaPositionsSelectOptions[0]);
  }, []);
  const disabled = step1Status === 'loading' || step2Status === 'loading' || step3Status === 'loading';
  const migrating = step3Status === 'loading';
  return (
    <div className="action-modal-wrapper migrate-compound-from-instadapp-modal">
      <ModalHeader closeModal={closeModal} />
      <ModalBody>
        <div className="new-modal-top-wrapper" style={{ backgroundImage: `url(${headerBg})` }}>
          <h1>{t('compound.migrate_from_instadapp')}</h1>
        </div>
        <div className="new-modal-content-wrapper">
          <div className="modal-description">
            <div>{t('compound.migrate_modal_description_1')}</div>
            <div>{t('compound.migrate_modal_description_2')}</div>
            <div>{t('compound.migrate_modal_description_3')}</div>
          </div>
          <MigrateModalContent selected={selectedOption} selectOptions={instaPositionsSelectOptions} setSelected={setSelectedOption} borrowedUsd={selectedOption?.value.data.borrowedUsd} usedAssets={selectedOption?.value.data.usedAssets} suppliedUsd={selectedOption?.value.data.suppliedUsd} />
          <MigrateModalStatusSteps steps={[
            { title: t('common.create_dsproxy'), status: step1Status },
            { title: t('common.authorize_dsproxy'), status: step2Status },
            { title: t('common.migrate_position'), status: step3Status },
          ]}
          />
        </div>
      </ModalBody>
      <div className="modal-controls">
        <button
          type="button"
          className="button green"
          disabled={disabled}
          onClick={() => (accountType !== 'view-only' ?
            dispatch(migrateCompoundFromInstadapp(selectedOption.value.dsa, selectedOption.value.data, closeModal)) :
            dispatch(openLoginModal()))}
        >
          {migrating ? t('common.migrating') : t('common.migrate')}
        </button>
      </div>
    </div>
  );
};

CompoundInstadappMigrationModal.defaultProps = {};
CompoundInstadappMigrationModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default CompoundInstadappMigrationModal;
