import * as types from '../../actionTypes/liquityActionTypes/liquityActionTypes';
import { LIQUITY_NORMAL_MODE_RATIO } from '../../constants/liquity';

const INITIAL_STATE = {
  proxy: {},
  account: {},
  gettingTrove: false,
  gettingTroveErr: '',

  gettingInitData: false,

  debtInFront: '0',
  gettingRedemptionRisk: false,
  gettingRedemptionRiskErr: '',

  recoveryMode: false,
  minCollateralRatio: LIQUITY_NORMAL_MODE_RATIO,
  ethPriceFeed: '0',
  totalCollateralizationRatio: '0',
  borrowingRateWithDecay: '0',
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.LQTY_GET_TROVE_REQUEST: return {
      ...state,
      gettingTrove: true,
      gettingTroveErr: '',
    };

    case types.LQTY_GET_TROVE_SUCCESS: return {
      ...state,
      gettingTrove: false,
      gettingTroveErr: '',
      account: { ...payload.account },
      proxy: { ...payload.proxy },
      ethPriceFeed: payload.ethPriceFeed,
      recoveryMode: payload.recoveryMode,
      totalCollateralizationRatio: payload.totalCollateralizationRatio,
      minCollateralRatio: payload.minCollateralRatio,
      borrowingRateWithDecay: payload.borrowingRateWithDecay,
    };

    case types.LQTY_GET_TROVE_FAILURE: return {
      ...state,
      gettingTrove: false,
      gettingTroveErr: payload,
    };

    case types.LQTY_GET_INIT_DATA_REQUEST: return {
      ...state,
      gettingInitData: true,
    };

    case types.LQTY_GET_INIT_DATA_SUCCESS: return {
      ...state,
      gettingInitData: false,
    };

    case types.LQTY_GET_REDEMPTION_RISK_REQUEST: return {
      ...state,
      gettingRedemptionRisk: true,
      gettingRedemptionRiskErr: '',
    };

    case types.LQTY_GET_REDEMPTION_RISK_SUCCESS: return {
      ...state,
      debtInFront: payload,
      gettingRedemptionRisk: false,
      gettingRedemptionRiskErr: '',
    };

    case types.LQTY_GET_REDEMPTION_RISK_FAILURE: return {
      ...state,
      gettingRedemptionRisk: false,
      gettingRedemptionRiskErr: payload,
    };

    default: return state;
  }
};
