import Dec from 'decimal.js';
import dfs, { Recipe } from '@defisaver/sdk';
import { assetAmountInWei, getAssetInfo, MAXUINT } from '@defisaver/tokens';
import { getExchangeOrder } from 'services/exchangeServiceV3';
import { ZERO_ADDRESS } from '../constants/general';
import { addToArrayIf, ethToWeth, requireAddress } from '../services/utils';
import { getFLAction } from '../services/assetsService';
import { callRecipeViaProxy } from '../services/contractCallService';
import { getCollAssetsHelperArrays, getRecipeDebtAssetsWithInfoArray } from '../services/aaveServices/aaveMigrateService';
import { isStethOnPeg } from '../services/recipeActionsService';

/**
 * Standard Aave Boost
 */
export const boost = async (_collAsset, _debtAsset, debtAmount, market, rate, price, slippagePercent, proxyAddress, additionalActions) => {
  const collAsset = ethToWeth(_collAsset);
  const collAddress = getAssetInfo(collAsset).address;
  const debtAsset = ethToWeth(_debtAsset);
  const debtAddress = getAssetInfo(debtAsset).address;
  const debtAmountWei = assetAmountInWei(debtAmount, debtAsset);
  const marketAddress = market.providerAddress;

  const recipe = new dfs.Recipe('recAaveBoost', [
    new dfs.actions.aave.AaveBorrowAction(marketAddress, debtAddress, debtAmountWei, rate, proxyAddress, proxyAddress),
  ]);

  additionalActions.forEach((action) => recipe.addAction(action));

  if (collAsset !== debtAsset) {
    if (collAsset === 'stETH' && debtAsset === 'WETH' && await isStethOnPeg(debtAmount)) {
      recipe.addAction(new dfs.actions.lido.LidoStakeAction(MAXUINT, proxyAddress, proxyAddress));
    } else {
      const { orderData, value, extraGas } = await getExchangeOrder(debtAsset, collAsset, debtAmount, price, slippagePercent, proxyAddress, false, false, true);
      recipe.addAction(new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value));
      recipe.extraGas = extraGas;
    }
    const previousActionId = `$${recipe.actions.length}`;
    recipe.addAction(new dfs.actions.aave.AaveSupplyAction(marketAddress, collAddress, previousActionId, proxyAddress, proxyAddress, true));
  } else {
    recipe.addAction(new dfs.actions.aave.AaveSupplyAction(marketAddress, collAddress, '$1', proxyAddress, proxyAddress, true));
  }
  return recipe;
};

/**
 * Aave traditional FL Boost
 * @returns {Promise<Recipe>}
 */
export const boostWithDebtLoan = async (_collAsset, _debtAsset, debtAmount, market, rate, price, slippagePercent, proxyAddress, flProtocol, additionalActions) => {
  const collAsset = ethToWeth(_collAsset);
  const collAddress = getAssetInfo(collAsset).address;
  const debtAsset = ethToWeth(_debtAsset);
  const debtAddress = getAssetInfo(debtAsset).address;
  const marketAddress = market.providerAddress;

  const debtAmountWei = assetAmountInWei(debtAmount, debtAsset);

  const { FLAction, paybackAddress } = getFLAction(flProtocol, debtAmountWei, debtAsset);

  const recipe = new dfs.Recipe('recAaveFLBoost', [FLAction]);

  additionalActions.forEach((action) => recipe.addAction(action));

  if (collAsset !== debtAsset) {
    if (collAsset === 'stETH' && debtAsset === 'WETH' && await isStethOnPeg(debtAmount)) {
      recipe.addAction(new dfs.actions.lido.LidoStakeAction(MAXUINT, proxyAddress, proxyAddress));
    } else {
      const { orderData, value, extraGas } = await getExchangeOrder(debtAsset, collAsset, debtAmount, price, slippagePercent, proxyAddress, false, false, true, flProtocol === 'balancer' ? ['Balancer_V2'] : []);
      recipe.addAction(new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value));
      recipe.extraGas = extraGas;
    }
    const previousActionId = `$${recipe.actions.length}`;
    recipe.addAction(new dfs.actions.aave.AaveSupplyAction(marketAddress, collAddress, previousActionId, proxyAddress, proxyAddress, true));
  } else {
    recipe.addAction(new dfs.actions.aave.AaveSupplyAction(marketAddress, collAddress, debtAmountWei, proxyAddress, proxyAddress, true));
  }
  recipe.addAction(new dfs.actions.aave.AaveBorrowAction(marketAddress, debtAddress, '$1', rate, paybackAddress, proxyAddress));

  return recipe;
};

/**
 * Standard Aave Repay
 */
export const repay = async (_collAsset, _debtAsset, collAmount, market, rate, price, slippagePercent, proxyAddress, account, supplied, borrowed, additionalActions) => {
  const collAsset = ethToWeth(_collAsset);
  const collAddress = getAssetInfo(collAsset).address;
  const debtAsset = ethToWeth(_debtAsset);
  const debtAddress = getAssetInfo(debtAsset).address;
  const marketAddress = market.providerAddress;

  const payingBackAllDebt = new Dec(collAmount * price).gte(borrowed);
  const sellingAllSupplied = supplied === collAmount;
  const differentAssets = collAsset !== debtAsset;

  const collAmountWei = sellingAllSupplied ? MAXUINT : assetAmountInWei(collAmount, collAsset);

  const recipe = new dfs.Recipe('recAaveRepay', [
    new dfs.actions.aave.AaveWithdrawAction(marketAddress, collAddress, collAmountWei, proxyAddress),
  ]);

  if (differentAssets) {
    const { orderData, value, extraGas } = await getExchangeOrder(collAsset, debtAsset, collAmount, price, slippagePercent, proxyAddress, false, false, true);
    if (sellingAllSupplied) orderData[2] = '$1';
    recipe.addAction(new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value));
    recipe.addAction(new dfs.actions.aave.AavePaybackAction(marketAddress, debtAddress, '$2', rate, proxyAddress, proxyAddress));
    recipe.extraGas = extraGas;
  } else {
    recipe.addAction(new dfs.actions.aave.AavePaybackAction(marketAddress, debtAddress, '$1', rate, proxyAddress, proxyAddress));
  }

  // AavePaybackAction will pay back an amount up to the total debt, and leave the rest on the proxy
  if (payingBackAllDebt) {
    recipe.addAction(
      debtAsset === 'WETH'
        ? new dfs.actions.basic.UnwrapEthAction(MAXUINT, account)
        : new dfs.actions.basic.SendTokenAction(getAssetInfo(debtAsset).address, account, MAXUINT),
    );
  }
  additionalActions.forEach((a) => recipe.addAction(a));
  return recipe;
};

export const repayWithCollLoan = async (_collAsset, _debtAsset, collAmount, market, rate, price, slippagePercent, proxyAddress, account, flProtocol, borrowed, maxWithdraw, additionalActions) => {
  const collAsset = ethToWeth(_collAsset);
  const collAddress = getAssetInfo(collAsset).address;
  const debtAsset = ethToWeth(_debtAsset);
  const debtAddress = getAssetInfo(debtAsset).address;
  const collAmountWei = assetAmountInWei(collAmount, collAsset);
  const marketAddress = market.providerAddress;

  const payingBackAllDebt = new Dec(collAmount * price).gte(borrowed);
  const differentAssets = collAsset !== debtAsset;

  const maxWithdrawWei = assetAmountInWei(maxWithdraw, collAsset);
  const useAave = flProtocol === 'aave';
  const recipe = new dfs.Recipe('recAaveFLRepay', []);
  let flPaybackAddr;
  if (useAave && new Dec(maxWithdrawWei).gt(0)) {
    // Aave flashloans have a fee, so we minimize the loan amount/fee, at the cost of gas
    const flAmount = new Dec(collAmountWei).sub(maxWithdrawWei).toString();
    const { FLAction, paybackAddress } = getFLAction(flProtocol, flAmount, collAsset);
    flPaybackAddr = paybackAddress;
    recipe.addAction(FLAction);
    recipe.addAction(new dfs.actions.aave.AaveWithdrawAction(marketAddress, collAddress, maxWithdrawWei, proxyAddress));
  } else {
    // other flashloans are free, so we FL the full amount
    const { FLAction, paybackAddress } = getFLAction(flProtocol, collAmountWei, collAsset);
    flPaybackAddr = paybackAddress;
    recipe.addAction(FLAction);
  }

  if (differentAssets) {
    const { orderData, value, extraGas } = await getExchangeOrder(collAsset, debtAsset, collAmount, price, slippagePercent, proxyAddress, false, false, true, flProtocol === 'balancer' ? ['Balancer_V2'] : []);
    recipe.addAction(new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value));
    const previousActionId = `$${recipe.actions.length}`;
    recipe.addAction(new dfs.actions.aave.AavePaybackAction(marketAddress, debtAddress, previousActionId, rate, proxyAddress, proxyAddress));
    recipe.extraGas = extraGas;
  } else {
    recipe.addAction(new dfs.actions.aave.AavePaybackAction(marketAddress, debtAddress, collAmountWei, rate, proxyAddress, proxyAddress));
  }
  recipe.addAction(new dfs.actions.aave.AaveWithdrawAction(marketAddress, collAddress, '$1', flPaybackAddr));

  // AavePaybackAction will pay back an amount up to the total debt, and leave the rest on the proxy
  if (payingBackAllDebt) {
    recipe.addAction(
      debtAsset === 'WETH'
        ? new dfs.actions.basic.UnwrapEthAction(MAXUINT, account)
        : new dfs.actions.basic.SendTokenAction(getAssetInfo(debtAsset).address, account, MAXUINT),
    );
  }
  additionalActions.forEach((a) => recipe.addAction(a));
  return recipe;
};

/**
 * Creates recipe for migrating instadapp or EOA position to DSProxy
 *
 * @param totalDebtInDai {number} - USD debt
 * @param debtAssets {Array.<{symbol:string,interestMode:string}>} - Array of debt assets in instadapp position
 * @param _collAssets {Array.<{symbol:string,collateral:boolean}>} - Array of collateral assets where symbol is asset's symbol, and collateral describes whether asset is enabled as collateral
 * @param market {object} - Aave market object
 * @param account {string} - User wallet address
 * @param proxyAccount {string} - User DSProxy address
 * @param onBehalfOf {string} - On behalf of DSA or EOA
 * @param isDSA {boolean}
 * @return {Recipe}
 */
export const migrate = async (totalDebtInDai, debtAssets, _collAssets, market, account, proxyAccount, onBehalfOf, isDSA = false) => {
  const flAmountWei = assetAmountInWei(new Dec(totalDebtInDai).mul(2), 'DAI');
  const flAssetAddress = getAssetInfo('DAI').address;
  // we don't have sell here so it's fine to use Balancer
  const flPaybackAddr = dfs.actionAddresses().FLBalancer;
  const flActionNumber = 1;
  const { aCollAssets, collEnabledAssets } = await getCollAssetsHelperArrays(_collAssets, market);

  const recipe = new dfs.Recipe(`recAaveMigrateFrom${isDSA ? 'Instadapp' : 'EOA'}`, []);
  if (totalDebtInDai > 0) {
    const getDebtActionNumberOffset = 2;
    const debtAssetsWithInfo = (await getRecipeDebtAssetsWithInfoArray(debtAssets, market)).map((data, index) => ({
      ...data,
      getDebtActionNumber: getDebtActionNumberOffset + index,
    }));

    recipe.addAction(new dfs.actions.flashloan.BalancerFlashLoanAction([flAssetAddress], [flAmountWei], ZERO_ADDRESS, []));
    debtAssetsWithInfo.forEach(({ debtAssetAddress }) => {
      recipe.addAction(new dfs.actions.basic.TokenBalanceAction(debtAssetAddress, onBehalfOf));
    });
    recipe.addAction(new dfs.actions.aave.AaveSupplyAction(market.providerAddress, flAssetAddress, flAmountWei, proxyAccount, proxyAccount, true));

    debtAssetsWithInfo.forEach(({ rateMode, getDebtActionNumber, assetAddress }) => {
      recipe.addAction(new dfs.actions.aave.AaveBorrowAction(market.providerAddress, assetAddress, `$${getDebtActionNumber}`, rateMode, proxyAccount, proxyAccount));
      recipe.addAction(new dfs.actions.aave.AavePaybackAction(market.providerAddress, assetAddress, `$${getDebtActionNumber}`, rateMode, proxyAccount, onBehalfOf));
    });
  }

  const assetAmountsToPullFromDsa = aCollAssets.map(() => MAXUINT);
  if (isDSA) {
    recipe.addAction(new dfs.actions.insta.InstPullTokensAction(onBehalfOf, aCollAssets, assetAmountsToPullFromDsa, proxyAccount));
  } else {
    aCollAssets.forEach((aToken) => {
      recipe.addAction(new dfs.actions.basic.PullTokenAction(aToken, onBehalfOf, MAXUINT));
    });
  }
  recipe.addAction(new dfs.actions.aave.AaveCollateralSwitchAction(market.providerAddress, collEnabledAssets, collEnabledAssets.map(() => true)));

  if (totalDebtInDai > 0) {
    recipe.addAction(new dfs.actions.aave.AaveWithdrawAction(market.providerAddress, flAssetAddress, `$${flActionNumber}`, flPaybackAddr));
  }
  return recipe;
};

/**
 *
 * @param accountType
 * @param sendTxFunc
 * @param account
 * @param proxyAddress
 * @param market
 * @param collAsset
 * @param debtAsset
 * @param collAmount
 * @param debtAmount
 * @param minPrice
 * @param borrowInterestRateMode
 * @param slippage
 * @param useFl
 * @param leveraged
 * @param flProtocol
 * @return {Promise<*>}
 */
export const openPosition = async (
  accountType, sendTxFunc, account, proxyAddress, market, collAsset, debtAsset, collAmount, debtAmount, borrowInterestRateMode, minPrice, slippage, useFl, leveraged, flProtocol,
) => {
  requireAddress(proxyAddress);

  const collAmountWei = assetAmountInWei(collAmount.toString(), collAsset);
  const debtAmountWei = assetAmountInWei(debtAmount.toString(), debtAsset);

  const collAssetAddress = getAssetInfo(ethToWeth(collAsset)).address;
  const debtAssetAddress = getAssetInfo(ethToWeth(debtAsset)).address;

  const isEthColl = collAsset === 'ETH';
  const isEthDebt = debtAsset === 'ETH';

  const isSameAssetPosition = collAsset === debtAsset;
  const stakeInsteadOfSell = collAsset === 'stETH' && debtAsset === 'ETH' && await isStethOnPeg(debtAmount);
  const { orderData, value } = !isSameAssetPosition
    ? await getExchangeOrder(ethToWeth(debtAsset), ethToWeth(collAsset), debtAmount.toString(), minPrice, slippage, account, false, false, true)
    : ({ orderData: [], value: '0' });
  const sellOrLidoWrap = [
    ...addToArrayIf(!isSameAssetPosition && !stakeInsteadOfSell,
      new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value)),
    ...addToArrayIf(stakeInsteadOfSell,
      new dfs.actions.lido.LidoStakeAction(MAXUINT, proxyAddress, proxyAddress)),
  ];

  let actions;
  if (leveraged) {
    if (useFl) {
      if (flProtocol === 'none') throw new Error('Cannot create Aave position, flashloan not available.');
      const flAmountWei = assetAmountInWei(debtAmount, ethToWeth(debtAsset));
      const { FLAction, paybackAddress } = getFLAction(flProtocol, flAmountWei, ethToWeth(debtAsset));

      const getTokenAction = isEthColl
        ? new dfs.actions.basic.WrapEthAction(collAmountWei)
        : new dfs.actions.basic.PullTokenAction(getAssetInfo(collAsset).address, account, collAmountWei);

      actions = [
        FLAction,
        getTokenAction,
        ...sellOrLidoWrap,
        new dfs.actions.aave.AaveSupplyAction(market, collAssetAddress, MAXUINT, proxyAddress, proxyAddress, true),
        new dfs.actions.aave.AaveBorrowAction(market, debtAssetAddress, '$1', borrowInterestRateMode, paybackAddress, proxyAddress),
      ];
    } else {
      actions = [
        ...addToArrayIf(isEthColl, new dfs.actions.basic.WrapEthAction(collAmountWei)),
        new dfs.actions.aave.AaveSupplyAction(market, collAssetAddress, collAmountWei, isEthColl ? proxyAddress : account, proxyAddress, true),
        new dfs.actions.aave.AaveBorrowAction(market, debtAssetAddress, debtAmountWei, borrowInterestRateMode, proxyAddress, proxyAddress),
        ...sellOrLidoWrap,
        new dfs.actions.aave.AaveSupplyAction(market, collAssetAddress, MAXUINT, proxyAddress, proxyAddress, true),
      ];
    }
  } else {
    actions = [
      ...addToArrayIf(isEthColl, new dfs.actions.basic.WrapEthAction(collAmountWei)),
      new dfs.actions.aave.AaveSupplyAction(market, collAssetAddress, collAmountWei, isEthColl ? proxyAddress : account, proxyAddress, true),
      new dfs.actions.aave.AaveBorrowAction(market, debtAssetAddress, debtAmountWei, borrowInterestRateMode, isEthDebt ? proxyAddress : account, proxyAddress),
      ...addToArrayIf(isEthDebt, new dfs.actions.basic.UnwrapEthAction(MAXUINT, account)),
    ];
  }
  const recipe = new Recipe(`AaveOpen${leveraged ? 'Leveraged' : ''}Recipe`, actions);
  return callRecipeViaProxy(accountType, sendTxFunc, proxyAddress, account, recipe);
};
