/* eslint-disable */
import React from 'react';
import { v4 as uuid } from 'uuid';

export default function MainnetIcon(props) {
  const id = `mainnet_icon${uuid()}`;
  const fill = `url(#${id})`;
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle opacity="0.9" cx="15" cy="15" r="15" fill={fill} fillOpacity="0.9"/>
      <path d="M14.9976 5.58154L14.8757 5.99579V18.0163L14.9976 18.138L20.5774 14.8398L14.9976 5.58154Z" fill="#DEDEDE"/>
      <path d="M14.9984 5.58142L9.41858 14.8396L14.9984 18.1379V12.3035V5.58142Z" fill="white"/>
      <path d="M14.9982 19.1945L14.9296 19.2782V23.5602L14.9982 23.7608L20.5814 15.8979L14.9982 19.1945Z" fill="#DEDEDE"/>
      <path d="M14.9984 23.7606V19.1943L9.41858 15.8978L14.9984 23.7606Z" fill="white"/>
      <path d="M14.999 18.1379L20.5787 14.8398L14.999 12.3036V18.1379Z" fill="#BDBDBD"/>
      <path d="M9.41858 14.8398L14.9983 18.138V12.3036L9.41858 14.8398Z" fill="#DEDEDE"/>
      <defs>
        <linearGradient id={id} x1="30" y1="0.382883" x2="30" y2="30" gradientUnits="userSpaceOnUse">
          <stop stopColor="#9EA6BF"/>
          <stop offset="1" stopColor="#565F86"/>
        </linearGradient>
      </defs>
    </svg>
  );
}
