import dfs from '@defisaver/sdk';
import Dec from 'decimal.js';
import cloneDeep from 'lodash/cloneDeep';
import { getAssetInfo } from '@defisaver/tokens';
import { exchangeAssets } from 'constants/assets';
import RecipeAction from '../RecipeAction';
import {
  Amount, Asset, AssetAmount, Address,
} from '../../components/Recipes/RecipeCreator/inputTypes';
import { formatNumber } from '../../services/utils';
import {
  assetAmountInWeiIgnorePointer,
  changeBalance,
} from '../../services/recipeCreator/recipeActionUtils';
import { MAXUINT } from '../../constants/general';
import SendIcon from '../recipeIcons/Send.svg';

const _exchangeAssets = [...exchangeAssets()];
_exchangeAssets[0] = getAssetInfo('WETH');


export default class PullTokenAction extends RecipeAction {
  static prettyName = 'Pull token';

  static description = 'Pulls tokens into the recipe balance.';

  constructor(asset = 'DAI', amount = '') {
    super();
    this.inputs = [
      new Asset('Asset', asset, _exchangeAssets),
      new Amount('Amount', amount),
    ];
    this.output = new AssetAmount('output', amount, asset);
  }

  async getAfterValues(_balances = {}, returnValues = [], actions = [], positions = {}, getState) {
    const {
      general: { account },
      maker: { proxyAddress },
      assets,
    } = getState();
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    let amount = args[1];
    const asset = this.inputs[0].asset;
    const balances = cloneDeep(_balances);
    if (amount === 'All available') amount = balances?.wallet?.[asset] || assets[asset].balance || 0;
    await changeBalance(balances, 'wallet', asset, new Dec(amount || 0).mul(-1), account);
    await changeBalance(balances, 'recipe', asset, amount || 0, proxyAddress);
    this.output.value = amount;
    return { returnValue: this.output, balances, positions };
  }

  async toDfsAction(getState) {
    const {
      general: { account },
    } = getState();
    const from = account;
    const tokenAddr = this.inputs[0].value;
    const amount = this.inputs[1].value === 'All available' ? MAXUINT : assetAmountInWeiIgnorePointer(this.inputs[1].value, this.inputs[0].asset);
    return new dfs.actions.basic.PullTokenAction(tokenAddr, from, amount);
  }

  static getIcon() {
    return SendIcon;
  }

  _getPrettyName(actionCalls, actions) {
    const args = this.mapReturnValuesToArgs(actionCalls.map(a => a.returnValue), actions);
    const asset = this.inputs[0].asset;
    let amount = formatNumber(args[1]);
    if (args[1] === 'Infinity' || args[1] === 'All available') amount = 'all';
    return `Pull ${amount} ${asset} to Temporary balance`;
  }

  getAsset() {
    return this.inputs[0].asset;
  }
}
