import Dec from 'decimal.js';
import { LidoOracleContract, wstETHContract } from './contractRegistryService';
import { SECONDS_PER_YEAR } from '../constants/general';

export const getWstETHByStETH = (stETHAmount) => wstETHContract().methods.getWstETHByStETH(stETHAmount).call();

export const getStETHByWstETH = (wstETHAmount) => wstETHContract().methods.getStETHByWstETH(wstETHAmount).call();

export const getStETHApr = async () => {
  const lastReport = await LidoOracleContract().methods.getLastCompletedReportDelta().call();

  const a = new Dec(lastReport.postTotalPooledEther).sub(lastReport.preTotalPooledEther).mul(SECONDS_PER_YEAR);
  const b = new Dec(lastReport.preTotalPooledEther).mul(lastReport.timeElapsed);
  const LIDO_FEE = 0.1;
  const apr = new Dec(a).div(b).mul(100).mul(1 - LIDO_FEE)
    .toString();
  return apr;
};
