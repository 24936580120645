import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getAssetInfo } from '@defisaver/tokens';
import t from 'translate';
import './RecipeBookItemModalForm.scss';
import getInput from '../../Recipes/RecipeCreator/RecipeActionList/RecipeSingleAction/ActionForm/ActionFormItems/Inputs';
import withErrorFallback from '../../ErrorFallback/ErrorFallback';

const RecipeBookItemModalForm = ({
  recipe, formData, setFormData, afterValues,
}) => {
  const assets = useSelector(state => state.assets);
  const cdps = useSelector(state => state.maker.cdps);
  const dispatch = useDispatch();
  const cdpOptions = useMemo(() => (
    [
      ...cdps
        .filter(cdp => !cdp.external)
        .map(cdp => ({
          ...cdp,
          value: cdp.id.toString(),
          label: `#${cdp.id} (${cdp.ilkLabel})`,
          meta: {
            icon: getAssetInfo(cdp.asset).icon,
          },
        })),
    ]), [cdps]);
  return (
    <div className="recipe-quick-access-form-wrapper form-wrapper">
      <h2>{t('recipes.quick_access')}:</h2>

      {!recipe?.quickAccessInputs?.length && (
        <p>{t('recipes.params_automatic')}.</p>
      )}

      {recipe?.quickAccessInputs?.map((type, i) => {
        const maxValue = recipe.quickAccessInputData?.[i]?.getMaxValue
          ? dispatch(recipe.quickAccessInputData[i].getMaxValue(afterValues))
          : assets[type.asset]?.balance;
        const inputProps = {
          originalValue: formData[i],
          actualValue: formData[i], // no pointers in quick access for now
          handleChange: (e, add = 0) => {
            const newArgs = [...formData];
            newArgs[i] = e.target.value;
            // if (input.combined) newArgs[index][add] = val;
            // else newArgs[index] = e.target.value;
            setFormData(newArgs);
          },
          handlePointerChange: () => {},
          amountOptions: [],
          i,
          type,
          isPointer: false,
          maxValue,
          cdpOptions,
        };

        return (
          // eslint-disable-next-line react/no-array-index-key
          <div className="form-item-wrapper" key={`${i}-${type.name}`}>
            <div className="input-values">
              {type.name && <label htmlFor={type.name}>{type.name}: </label>}
              {getInput(inputProps)}
            </div>
          </div>
        );
      })}
    </div>
  );
};

RecipeBookItemModalForm.defaultProps = {};

RecipeBookItemModalForm.propTypes = {
  recipe: PropTypes.object.isRequired,
  formData: PropTypes.array.isRequired,
  setFormData: PropTypes.func.isRequired,
  afterValues: PropTypes.object,
};

export default withErrorFallback(RecipeBookItemModalForm);
