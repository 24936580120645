import React from 'react';


export default function MediumIcon(props) {
  return (
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="13.5" cy="13.5" r="13.5" fill="#939DA7" />
      <path d="M19.0278 18.2707L20.012 19.33V19.3631H15.0176V19.33L16.0165 18.2707C16.1193 18.1713 16.1781 18.0224 16.1487 17.8569V11.0707C16.1487 10.7893 16.1487 10.392 16.1928 10.061L12.8436 19.4955H12.7995L9.31818 10.8224C9.24473 10.6072 9.21535 10.5907 9.17128 10.4417V16.1355C9.12722 16.5162 9.18597 16.8969 9.33287 17.2444L10.7283 19.33V19.3631H7.01196V19.33L8.40744 17.2444C8.55434 16.8969 8.61309 16.5162 8.56903 16.1355V9.84583C8.5984 9.53135 8.51027 9.23341 8.334 8.98514L7.34982 7.52859V7.49548H10.9046L13.8865 14.8444L16.5159 7.49548H19.9973V7.52859L19.0131 8.75341C18.8956 8.85272 18.8368 9.01824 18.8662 9.18376V17.8569C18.8515 18.0058 18.9103 18.1713 19.0278 18.2707Z" fill="white" />
    </svg>
  );
}
