/* eslint-disable */
import React from 'react';

export default function XdefiIcon(props) {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 3C0 1.34315 1.34315 0 3 0H18C19.6569 0 21 1.34315 21 3V18C21 19.6569 19.6569 21 18 21H3C1.34315 21 0 19.6569 0 18V3Z" fill="url(#paint0_linear)"/>
      <path d="M11.7979 11.3719C10.7442 12.0341 9.3344 12.3752 7.90614 12.3023C6.70508 12.2428 5.72041 11.8056 5.12504 11.0872C4.60163 10.4447 4.39876 9.59706 4.53606 8.62172C4.58272 8.29751 4.67802 7.98287 4.81925 7.68789L4.8389 7.64663C6.20379 4.98612 9.24377 3.68165 12.0821 4.53762C14.9212 5.39409 16.7596 8.16954 16.4636 11.1521C16.1677 14.1351 13.8206 16.4851 10.8702 16.7528L10.9361 17.5128C14.2484 17.2141 16.8844 14.5759 17.2162 11.2267C17.548 7.87775 15.4822 4.76212 12.2941 3.80397C9.10598 2.84558 5.69512 4.31436 4.16665 7.30399L4.14037 7.35852C3.96535 7.72479 3.84733 8.11566 3.79037 8.51758C3.62679 9.70636 3.88026 10.7642 4.54293 11.5789C5.2675 12.4695 6.44719 12.9993 7.86218 13.0688C9.58468 13.1568 11.2976 12.6815 12.5129 11.8074L11.7982 11.3719H11.7979Z" fill="white"/>
      <path d="M13.3738 12.3549C12.6865 12.9178 11.089 13.9386 8.43885 14.0779C5.47181 14.2326 4.23539 13.3231 4.22409 13.314L3.98437 13.5923L4.22556 13.3184L3.7417 13.8661C3.79426 13.9079 4.97985 14.811 7.76488 14.811C7.99257 14.811 8.23204 14.811 8.48134 14.7923C11.6844 14.6239 13.4443 13.3061 14.0576 12.7432L13.3738 12.3549Z" fill="white"/>
      <path d="M14.6248 13.0981C14.2122 13.6248 13.7075 14.0723 13.1351 14.4187C11.1088 15.691 8.53133 15.8556 6.72703 15.7669L6.68896 16.5094C6.9812 16.5234 7.27373 16.5303 7.56631 16.5303C12.655 16.5303 14.7113 14.2755 15.2855 13.4702L14.6233 13.0917" fill="white"/>
      <path d="M14.4261 10.1443C14.7652 10.1443 15.0401 9.86936 15.0401 9.53024C15.0401 9.19111 14.7652 8.9162 14.4261 8.9162C14.0869 8.9162 13.812 9.19111 13.812 9.53024C13.812 9.86936 14.0869 10.1443 14.4261 10.1443Z" fill="white"/>
      <defs>
        <linearGradient id="paint0_linear" x1="10.5" y1="0" x2="10.5" y2="21" gradientUnits="userSpaceOnUse">
          <stop stopColor="#2E66E6"/>
          <stop offset="1" stopColor="#1B3FAD"/>
        </linearGradient>
      </defs>
    </svg>
  );
}
