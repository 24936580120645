import React from 'react';
import PropTypes from 'prop-types';

const RecipeActionBox = ({ action, actionCalls, actions }) => (
  <div className={`action-draggable-outer-wrapper ${action.constructor.protocol}`}>
    <div className="action-draggable-wrapper">
      <div className="protocol-icon" />
      <div className="icon"><img src={action.constructor.getIcon()} alt="" /></div>
      <div className="inner-content-wrapper">
        <div>
          <b>{action.getPrettyName(actionCalls, actions)}</b>
        </div>
      </div>
    </div>
  </div>
);
RecipeActionBox.propTypes = {
  action: PropTypes.object.isRequired,
  actionCalls: PropTypes.array.isRequired,
  actions: PropTypes.array.isRequired,
};

export default RecipeActionBox;
