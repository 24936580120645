import Dec from 'decimal.js';
import dfs from '@defisaver/sdk';
import {
  assetAmountInEth, compoundAsset, getAssetInfo,
} from '@defisaver/tokens';
import callTx from './txService';
import clientConfig from '../config/clientConfig.json';
import { aggregate, getNetwork, isLayer2Network } from './ethService';
import {
  AaveLoanInfoContract,
  AaveLoanInfoV2Contract,
  BalancerVaultAddress,
  BalanceScannerContract,
  CompoundLoanInfoContract,
  dydxAddress,
  FundSafeguardContract,
  getErc20Contract,
  MakerFlashLoanInfoContract,
  AaveV3ViewL2Contract,
} from './contractRegistryService';
import {
  ethToWeth, getEthAmountForDecimals, isAddress, requireAddress,
} from './utils';
import {
  balancerFlashLoanAssets, dydxFlashLoanAssets, getAaveV2Assets, getAaveV3Assets,
} from '../constants/assets';
import { getAvailableLiqForAsset, getAvailableLiqForAssetV3 } from './aaveServices/aaveFlashloanService';
import { ZERO_ADDRESS } from '../constants/general';
import { getERC20TokenData } from './erc20Service';

/**
 * Fetches the allowance amount on a asset
 *
 * @param asset {String} Token symbol
 * @param owner {String}
 * @param spender {String}
 * @param addressOfAsset {String}
 * @param networkId {}
 * @return {Promise<string>}
 */
export const getAssetAllowanceForAddress = async (asset, owner, spender, addressOfAsset, networkId) => {
  const contract = await getErc20Contract(addressOfAsset || getAssetInfo(asset).address, networkId);
  const data = await contract.methods.allowance(owner, spender).call();

  return assetAmountInEth(data, asset);
};

/**
 * Checks if an asset is approved for some amount
 *
 * @param asset {String}
 * @param account {String}
 * @param spender {String}
 * @param amount {String}
 * @param addressOfAsset {String}
 * @return {Promise<boolean>}
 */
export const isAddressApprovedOnAsset = async (asset, account, spender, amount, addressOfAsset = '') => {
  if (asset === 'ETH') return true;

  const allowance = await getAssetAllowanceForAddress(asset, account, spender, addressOfAsset);
  return Dec(allowance).gte(amount);
};

/**
 * Approves Erc20 asset for an address
 *
 * @param asset {String} Token symbol
 * @param accountType {String}
 * @param path {String}
 * @param from {String}
 * @param spender {String} Spender address
 * @param sendTxFunc {Function}
 * @param address for dynamically approving assets not saved in app
 * @param networkId
 * @return {Promise<Boolean>}
 */
export const approveAddressOnAsset = async (
  asset, accountType, path, from, spender, sendTxFunc, address = '', networkId,
) => {
  const assetContract = await getErc20Contract(address || getAssetInfo(asset).address, networkId);
  const num = '115792089237316195423570985008687907853269984665640564039457584007913129639935'; // uint256(-1)

  return callTx(accountType, path, sendTxFunc, assetContract, 'approve', [spender, num], { from });
};

export const deapproveAddressOnAsset = async (
  asset, accountType, path, from, approveAddress, sendTxFunc,
) => {
  const assetContract = await getErc20Contract(getAssetInfo(asset).address);
  const num = '0';
  return callTx(accountType, path, sendTxFunc, assetContract, 'approve', [approveAddress, num], { from });
};

/**
 * Gets address balance for a Erc20 asset (optional argument is chainId)
 *
 * @param asset {String}
 * @param address {String}
 * @param chainId {number}
 * @return {Promise<String>}
 */
export const getAssetBalance = async (asset, address, chainId) => {
  if (!address) return '0';
  let data = '';

  const isAssetAddress = isAddress(asset);

  const network = chainId || await getNetwork();
  const isOptimism = network === 10;

  if (!isOptimism && (asset === 'ETH' || asset.toLowerCase() === '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee')) {
    data = await window._web3Object[network].eth.getBalance(address);
  } else {
    let _asset = isAssetAddress ? asset : getAssetInfo(asset).address;
    if (isOptimism && _asset.toLowerCase() === '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee') _asset = '0xDeadDeAddeAddEAddeadDEaDDEAdDeaDDeAD0000';
    const contract = await getErc20Contract(_asset, network);
    data = (await contract.methods.balanceOf(address).call()).toString();
  }

  return isAssetAddress ? getEthAmountForDecimals(data, (await getERC20TokenData(asset)).decimals) : assetAmountInEth(data, asset);
};

export const getAssetBalancesFromAddresses = async (account, tokens) => {
  const contract = BalanceScannerContract();

  let balances = [...await contract.methods.tokensBalance(account, tokens.map(i => i.address)).call()];
  balances = balances.map((item, i) => (item.success ? new Dec(window._web3.utils.hexToNumberString(item.data)).div(10 ** tokens[i].decimals).toString() : '0'));

  const ethIndex = tokens.findIndex(a => a.address.toLowerCase() === '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee');

  if (ethIndex !== -1) { balances[ethIndex] = await getAssetBalance('ETH', account); }
  return balances;
};

export const getAssetsBalances = async (assets, address, networkId) => {
  const contract = BalanceScannerContract(networkId);

  let balances = [...await contract.methods.tokensBalance(address, assets.map(a => getAssetInfo(a, networkId).address)).call()];
  balances = balances.map((item, i) => (item.success ? assetAmountInEth(window._web3.utils.hexToNumberString(item.data), assets[i]) : '0'));

  const ethIndex = assets.indexOf('ETH');
  if (ethIndex !== -1) { balances[ethIndex] = await getAssetBalance('ETH', address, networkId); }

  return balances;
};

export const getAddressesBalances = async (asset, addresses) => {
  const contract = BalanceScannerContract();
  const balances = await contract.methods.tokenBalances(addresses, getAssetInfo(asset).address).call();
  return balances.map(item => (item.success ? assetAmountInEth(window._web3.utils.hexToNumberString(item.data), asset) : '0'));
};

/**
 * Parse price returned from Compound price oracle
 * @param assetPrice {*} asset price in ETH
 * @param asset {string} symbol
 * @returns {string|*}
 */
export const parseCompoundOraclePrice = (assetPrice, asset) => {
  const _price = Dec(assetPrice.toString()).div(1e18).toString();
  // Looks like WBTC price is returned with extra decimal places
  if (asset === 'WBTC') return Dec(_price).div(1e10).toString();
  if (asset === 'USDC' || asset === 'USDT') return Dec(_price).div(1e12).toString();

  return _price;
};

/**
 * Gets the price of an asset in dollar value
 *
 * @param asset {String}
 * @param fetchFrom {String}
 * @param [assetAddress] {String}
 * @return {Promise<String>}
 */
export const getAssetUsdPrice = async (asset, fetchFrom, assetAddress) => {
  if (clientConfig.network === 42) {
    switch (asset) {
      case 'ETH':
        return '183.94';
      case 'DAI':
      case 'USDC':
        return '1';
      case 'MKR':
        return '516.54';
      case 'BAT':
        return '0.225405';
      case 'ZRX':
        return '0.265773';
      case 'REP':
        return '8.95';
      case 'WBTC':
        return '9091.77';
      case 'DGD':
        return '12.23';

      default:
        return '-1';
    }
  }

  let addr = assetAddress && (assetAddress.toLowerCase() === '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee' ? getAssetInfo('WETH').address : assetAddress);

  if (fetchFrom === 'market') {
    addr = addr || getAssetInfo(ethToWeth(asset)).address;
    if (
      [
        '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1'.toUpperCase(), // weth arbitrum
        '0x4200000000000000000000000000000000000006'.toUpperCase(), // weth optimism
      ].includes(addr.toUpperCase())
    ) addr = '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2';

    const res = await fetch(`https://defiexplore.com/api/market/prices?addresses=${addr}`);
    const prices = await res.json();
    return prices[0].toString();
  }

  if (fetchFrom === 'compound') {
    const loanInfoContract = CompoundLoanInfoContract();
    const prices = await loanInfoContract.methods.getPrices([addr || getAssetInfo(compoundAsset(asset)).address]).call();
    return parseCompoundOraclePrice(prices[0], asset);
  }
};

export const getAssetsUsdPrices = async (assets, fetchFrom, selectedMarket, fromAddresses) => {
  if (assets.length === 0) {
    return [];
  }
  if (fetchFrom === 'market') {
    let addresses;
    if (fromAddresses) addresses = assets;
    // set network as 1 for the addresses on mainnet (price is the same on every network)
    else addresses = assets.map(asset => getAssetInfo(ethToWeth(asset).replace(/^REP$/, 'REPv2'), 1).address);
    const res = await fetch(`https://defiexplore.com/api/market/prices?addresses=${addresses.join(',')}`);
    const prices = await res.json();
    return prices.map(p => p.toString());
  }
  if (fetchFrom === 'compound') {
    const loanInfoContract = CompoundLoanInfoContract();
    const prices = await loanInfoContract.methods.getPrices(assets.map(a => getAssetInfo(compoundAsset(a)).address)).call();
    return assets.map((asset, i) => parseCompoundOraclePrice(prices[i], asset));
  }
  if (fetchFrom === 'aave') {
    if (selectedMarket.value === 'v1') {
      const loanInfoContract = AaveLoanInfoContract();
      const prices = await loanInfoContract.methods.getPrices(['USDC', ...assets].map(a => getAssetInfo(a).address)).call();
      const usdcEthPrice = prices[0].toString();
      const ethPrice = Dec(1e18).div(usdcEthPrice).toString();
      return assets.map((asset, i) => Dec(prices[i + 1].toString()).div(1e18).times(ethPrice.toString()).toString());
    }
    if (selectedMarket.value === 'v2default') {
      const loanInfoContract = AaveLoanInfoV2Contract();
      const prices = await loanInfoContract.methods.getPrices(selectedMarket.providerAddress, ['DAI', ...assets].map(a => getAssetInfo(ethToWeth(a)).address)).call();
      const daiEthPrice = prices[0].toString();
      const ethPrice = Dec(1e18).div(daiEthPrice).toString();
      return assets.map((asset, i) => Dec(prices[i + 1].toString()).div(1e18).times(ethPrice.toString()).toString());
    }
    if (selectedMarket.value === 'v3default') {
      const loanInfoContract = AaveV3ViewL2Contract();
      const prices = await loanInfoContract.methods.getPrices(selectedMarket.providerAddress, ['DAI', ...assets].map(a => getAssetInfo(ethToWeth(a)).address)).call();
      const daiEthPrice = prices[0].toString();
      const ethPrice = new Dec(1e18).div(daiEthPrice).toString();
      return assets.map((asset, i) => new Dec(prices[i + 1].toString()).div(1e18).times(ethPrice.toString()).toString());
    }
  }
  return Promise.all(assets.map(a => getAssetUsdPrice(a, fetchFrom)));
};

/**
 * Transforms the approve type to the prop name in the reducer for when
 * an asset is being approved
 *
 * @param approveType
 * @return {string}
 */
export const getApprovingReducerPropName = approveType => `approving${approveType}`;

/**
 * Transforms the approve type to the prop name in the reducer when
 * checking if an asset is approved
 *
 * @param approveType
 * @return {string}
 */
export const getGettingIsApprovedReducerPropName = approveType => `gettingIsApproved${approveType}`;

/**
 * Transforms the approve type to the prop name in the reducer when
 * checking if an asset is approved
 *
 * @param approveType
 * @return {string}
 */
export const getIsApprovedReducerPropName = approveType => `approved${approveType}`;

export const CONTRACTS_WITH_EXPLOIT = {
  exchange: {
    '0x606e9758a39d2d7fA7e70BC68E6E7D9b02948962': ['BAT', 'DAI', 'USDC', 'MKR', 'WBTC'],
    '0x64C5cc449bD253D7fd57751c9080ACcd0216126d': ['BAT', 'DAI', 'USDC', 'MKR'],
    '0x862F3dcF1104b8a9468fBb8B843C37C31B41eF09': ['DAI'],
  },
  compoundImport: {
    '0xaf9f8781A4c39Ce2122019fC05F22e3a662B0A32': ['cETH', 'cDAI', 'cBAT', 'cZRX', 'cUSDC', 'cWBTC', 'cUNI'],
    '0x0a9238e14d5A20CDb03811B12D1984587C3CE9a0': ['cETH', 'cDAI', 'cBAT', 'cZRX', 'cUSDC', 'cWBTC', 'cUNI'],
    '0xF8A122d8603353Aa478690A3e9aBB4F920C9617e': ['cETH', 'cDAI', 'cBAT', 'cZRX', 'cUSDC', 'cWBTC', 'cUNI'],
    '0xfD7F89640fe62a4353cfC7521509FC5c4276F2a5': ['cETH', 'cDAI', 'cBAT', 'cZRX', 'cUSDC', 'cWBTC', 'cUNI'],
    '0x06AbaC6fe0e49e57763aAa79c0D79e3e42c1894F': ['cETH', 'cDAI', 'cBAT', 'cZRX', 'cUSDC', 'cWBTC', 'cUNI'],
    '0x92ec5a03Fac2E482292eCdD9642a7BF86d6658C3': ['cETH', 'cDAI', 'cBAT', 'cZRX', 'cUSDC', 'cWBTC', 'cUNI'],
  },
};

export const ASSETS_WITH_EXPLOIT = ['SAI', 'BAT', 'DAI', 'USDC', 'ZRX', 'MKR', 'WBTC', 'REP'];

const checkExploitableContract = async (holder, contract) => Promise.all(CONTRACTS_WITH_EXPLOIT[contract].map(asset => isAddressApprovedOnAsset(asset, holder, contract, '1')));

export const checkExploitableApprovals = async holder => {
  const multicallCallsObject = Object.keys(CONTRACTS_WITH_EXPLOIT)
    .map(exploit => Object.keys(CONTRACTS_WITH_EXPLOIT[exploit]).map(
      contract => CONTRACTS_WITH_EXPLOIT[exploit][contract].map(
        asset => (
          {
            target: getAssetInfo(asset).address,
            call: ['allowance(address,address)(uint256)', holder, contract],
            returns: [
              [`${contract}${asset}exploit`, val => assetAmountInEth(val, asset)],
            ],
          }),
      ),
    ).flat())
    .flat();
  const multiRes = await aggregate(
    multicallCallsObject,
  );
  const { results: { transformed } } = multiRes;
  const approvalsArr = Object.keys(CONTRACTS_WITH_EXPLOIT)
    .map(exploit => Object.keys(CONTRACTS_WITH_EXPLOIT[exploit])
      .map(contract => CONTRACTS_WITH_EXPLOIT[exploit][contract]
        .map(asset => (Dec(transformed[`${contract}${asset}exploit`]).gte('1'))),
      ),
    );
  const approvalsObj = {};
  Object.keys(CONTRACTS_WITH_EXPLOIT).forEach((exploit, i) => { approvalsObj[exploit] = approvalsArr[i]; });
  return approvalsObj;
};

export const withdrawAllFromSafeguard = (account, accountType, path, sendTxFunc) => {
  const contract = FundSafeguardContract();
  const funcParams = [ASSETS_WITH_EXPLOIT.map(asset => getAssetInfo(asset).address)];
  const txParams = {
    from: account,
  };
  return callTx(accountType, path, sendTxFunc, contract, 'redeemAllTokens', funcParams, txParams, 'redeemAllTokens');
};

export const balancesOnSafeguard = async (holder) => {
  if (!holder) return [];
  const contract = FundSafeguardContract();
  return (await Promise.all(ASSETS_WITH_EXPLOIT.map(asset => contract.methods.balances(holder, getAssetInfo(asset).address).call())))
    .map((balance, i) => assetAmountInEth(balance, ASSETS_WITH_EXPLOIT[i]));
};

export const transferErc20 = async (accountType, path, sendTxFunc, account, proxyAddress, tokenAddress, receiver, _amount, fullBalance = false) => {
  requireAddress(tokenAddress);
  requireAddress(receiver);
  const contract = await getErc20Contract(tokenAddress);
  let amount = _amount;
  if (fullBalance) {
    amount = await contract.methods.balanceOf(account).call();
  }
  const funcParams = [receiver, amount];
  const txParams = { from: account };
  return callTx(accountType, path, sendTxFunc, contract, 'transfer', funcParams, txParams, 'transfer');
};

export const getAssetBalanceByAddress = async (address, account, asset = 'USD', decimals = '18') => {
  if (!account) return '0';
  const multicallObject = [
    {
      target: address,
      call: ['balanceOf(address)(uint256)', account],
      returns: [
        ['balance', val => val.toString()],
      ],
    },
    {
      target: address,
      call: ['decimals()(uint256)'],
      returns: [
        ['decimals', val => val.toString()],
      ],
    },
  ];
  const res = await aggregate(multicallObject);
  const { results: { transformed } } = res;

  return getEthAmountForDecimals(transformed.balance, transformed.decimals);
};

/**
 * @returns {Promise<boolean>}
 */
export const useDydxForFl = async (amount, asset = 'DAI') => {
  const availableLiq = await getAssetBalance(asset, dydxAddress);
  return new Dec(availableLiq).gt(amount);
};

/**
 * @returns {Promise<boolean>}
 */
export const useMakerForFl = async (amount, asset = 'DAI') => {
  const contract = await MakerFlashLoanInfoContract();
  const availableLiq = await contract.methods.max().call();
  return new Dec(assetAmountInEth(availableLiq, asset)).gt(amount);
};

/**
 * @returns {Promise<boolean>}
 */
export const useBalancerForFl = async (amount, asset = 'DAI') => {
  const availableLiq = await getAssetBalance(asset, BalancerVaultAddress);
  return new Dec(availableLiq).gt(amount);
};

/**
 * @returns {Promise<boolean>}
 */
export const useAaveForFl = async (amount, asset = 'DAI', aaveMarket, network) => {
  let availableLiq = '0';
  if (aaveMarket === 'v2default') availableLiq = await getAvailableLiqForAsset(asset);
  if (aaveMarket === 'v3default') availableLiq = await getAvailableLiqForAssetV3(asset, network);

  return new Dec(availableLiq).gt(amount);
};

export const flProtocolFor = async (amount, asset, network) => {
  const aaveMarket = isLayer2Network(network) ? 'v3default' : 'v2default';
  if (aaveMarket !== 'v3default') { // TODO handle this when v3 is released to mainnet
    const balancerAssetAvailable = balancerFlashLoanAssets.find(({ symbol }) => symbol === ethToWeth(asset));
    if (balancerAssetAvailable && await useBalancerForFl(amount, ethToWeth(asset))) return 'balancer';
    if (asset === 'DAI' && await useMakerForFl(amount, ethToWeth(asset))) return 'maker';
    const dydxAssetAvailable = dydxFlashLoanAssets.find(({ symbol }) => symbol === ethToWeth(asset));
    if (dydxAssetAvailable && await useDydxForFl(amount, ethToWeth(asset))) return 'dydx';
  }

  const getAaveAssets = aaveMarket === 'v3default' ? getAaveV3Assets : getAaveV2Assets;
  const aaveFLAvailable = getAaveAssets(network).find(({ symbol }) => symbol === asset);
  if (aaveFLAvailable && await useAaveForFl(amount, asset, aaveMarket, network)) {
    if (aaveMarket === 'v2default') return 'aave';
    if (aaveMarket === 'v3default') return 'aaveV3';
  }
  return 'none';
};

export const flProtocolAndFeeFor = async (amount, asset, network) => {
  const protocol = await flProtocolFor(amount, asset, network);
  if (protocol === 'none') {
    return {
      protocol, feeMultiplier: '1', flFee: '0', paybackAddress: '0x0', useAltRecipe: true,
    };
  }
  const paybackAddresses = {
    dydx: dfs.actionAddresses().FLDyDx,
    aave: dfs.actionAddresses().FLAaveV2,
    aaveV3: dfs.actionAddresses().FLAaveV3,
    maker: dfs.actionAddresses().FLMaker,
    balancer: dfs.actionAddresses().FLBalancer,
  };
  const paybackAddress = paybackAddresses[protocol];
  const feeToProtocol = {
    aave: '1.0009',
    aaveV3: '1.0005',
  };
  const feeMultiplier = feeToProtocol[protocol] || '1';
  const flFee = new Dec(feeMultiplier).minus(1).times(100).toString();
  return {
    protocol, feeMultiplier, flFee, paybackAddress, useAltRecipe: false,
  };
};

export const getFLAction = (protocol, amount, asset) => {
  let FLAction;
  let paybackAddress;
  let feeMultiplier = '1';
  switch (protocol) {
    case 'dydx': {
      FLAction = new dfs.actions.flashloan.DyDxFlashLoanAction(amount, getAssetInfo(asset).address);
      paybackAddress = dfs.actionAddresses().FLDyDx;
      break;
    }
    case 'aave': {
      FLAction = new dfs.actions.flashloan.AaveV2FlashLoanAction([amount], [getAssetInfo(asset).address], [0], ZERO_ADDRESS, ZERO_ADDRESS, []);
      paybackAddress = dfs.actionAddresses().FLAaveV2;
      feeMultiplier = '1.0009';
      break;
    }
    case 'aaveV3': {
      FLAction = new dfs.actions.flashloan.AaveV3FlashLoanAction([amount], [getAssetInfo(asset).address], [0], ZERO_ADDRESS, ZERO_ADDRESS, []);
      paybackAddress = dfs.actionAddresses().FLAaveV3;
      feeMultiplier = '1.0005';
      break;
    }
    case 'maker': {
      FLAction = new dfs.actions.flashloan.MakerFlashLoanAction(amount, ZERO_ADDRESS, []);
      paybackAddress = dfs.actionAddresses().FLMaker;
      break;
    }
    case 'balancer': {
      FLAction = new dfs.actions.flashloan.BalancerFlashLoanAction([getAssetInfo(asset).address], [amount], ZERO_ADDRESS, []);
      paybackAddress = dfs.actionAddresses().FLBalancer;
      break;
    }
    default: throw new Error('Unknown FL Action');
  }
  return { FLAction, paybackAddress, feeMultiplier };
};


export const getBalance = (address, tokenAddress, networkId) => {
  const contract = BalanceScannerContract(networkId);
  const balance = contract.methods.tokensBalance(address, [tokenAddress]).call();
  return assetAmountInEth(window._web3Object[networkId].utils.hexToNumberString(balance.data), 'ETH');
};
