import { assetAmountInWei, getAssetInfoByAddress } from '@defisaver/tokens';
import dfs from '@defisaver/sdk';
import Dec from 'decimal.js';
import cloneDeep from 'lodash/cloneDeep';
import RecipeAction from '../RecipeAction';
import {
  Amount, Asset, AssetAmount, Source,
} from '../../components/Recipes/RecipeCreator/inputTypes';
import {
  changeBalance, getAmountDfsAction,
  getAmount,
  getSourceAddress,
} from '../../services/recipeCreator/recipeActionUtils';
import { getBurnAmountOneToken } from '../../services/curveServices/curveService';
import WithdrawIcon from '../recipeIcons/Withdraw.svg';

export default class CurveStethPoolWithdrawAction extends RecipeAction {
  static prettyName = 'Withdraw assets from Curve';

  static protocol = 'curve';

  static protocolPrettyName = 'Curve';

  static description = 'Withdraw assets from Curve ETHSTETH pool.';

  constructor(from = 'wallet', to = 'recipe', steCrvAmount = '0', toAsset = 'WETH', returnAsset = 'WETH') {
    super();
    // toAsset is either WETH or stETH
    this.inputs = [
      new Source('Source', from),
      new Source('Source', to),
      new Amount('steCRVAmount', steCrvAmount),
      new Asset('WithdrawAsset', toAsset, ['WETH', 'stETH']),
      new Asset('ReturnValueAsset', returnAsset, ['WETH', 'stETH']),
    ];
    this.output = new AssetAmount('output', 0, toAsset);
  }

  async getAfterValues(_balances = {}, returnValues = [], actions = [], positions = {}, getState) {
    const balances = cloneDeep(_balances);
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const from = args[0];
    const to = args[1];
    const returnAsset = getAssetInfoByAddress(args[4]);
    const fromAddress = getSourceAddress(from, getState);
    const toAddress = getSourceAddress(from, getState);
    const toAsset = getAssetInfoByAddress(args[3]);
    const steCrvAmount = await getAmount(balances, from, 'steCRV', args[2], fromAddress, '', getState);
    const ethAmount = toAsset.symbol === 'WETH' ? await getBurnAmountOneToken(steCrvAmount, 0) : '0';
    const stEthAmount = toAsset.symbol === 'stETH' ? await getBurnAmountOneToken(steCrvAmount, 1) : '0';
    await changeBalance(balances, from, 'steCRV', new Dec(steCrvAmount).mul(-1).toString(), fromAddress);
    await changeBalance(balances, to, 'WETH', ethAmount, toAddress);
    await changeBalance(balances, to, 'stETH', stEthAmount, toAddress);
    this.output.value = returnAsset.symbol === 'WETH' ? ethAmount : stEthAmount;
    this.output.asset = returnAsset.symbol;
    return { returnValue: this.output, balances, positions };
  }

  async toDfsAction(getState, actions, returnValues) {
    const steCrvAmount = getAmountDfsAction(this.inputs[2].value, 'steCRV');
    const ethAmount = this.output.asset === 'WETH' ? assetAmountInWei(this.output.value, this.output.asset) : '0';
    const stEthAmount = this.output.asset === 'stETH' ? assetAmountInWei(this.output.value, this.output.asset) : '0';
    const returnValue = this.output.asset === 'WETH' ? '0' : '1';
    const from = getSourceAddress(this.inputs[0].value, getState);
    const to = getSourceAddress(this.inputs[1].value, getState);
    return new dfs.actions.curve.CurveStethPoolWithdrawAction(from, to, [ethAmount, stEthAmount], steCrvAmount, returnValue);
  }

  static getIcon() {
    return WithdrawIcon;
  }

  _getPrettyName(actionCalls, actions) {
    return 'Curve Withdraw';
  }
}
