export const COMPOUND_SAVER_IS_SUBSCRIBED_REQUEST = 'COMPOUND_SAVER_IS_SUBSCRIBED_REQUEST';
export const COMPOUND_SAVER_IS_SUBSCRIBED_SUCCESS = 'COMPOUND_SAVER_IS_SUBSCRIBED_SUCCESS';
export const COMPOUND_SAVER_IS_SUBSCRIBED_FAILURE = 'COMPOUND_SAVER_IS_SUBSCRIBED_FAILURE';

export const COMPOUND_SAVER_SUBSCRIBE_REQUEST = 'COMPOUND_SAVER_SUBSCRIBE_REQUEST';
export const COMPOUND_SAVER_SUBSCRIBE_SUCCESS = 'COMPOUND_SAVER_SUBSCRIBE_SUCCESS';
export const COMPOUND_SAVER_SUBSCRIBE_FAILURE = 'COMPOUND_SAVER_SUBSCRIBE_FAILURE';
export const COMPOUND_SAVER_SUBSCRIBE_RESET = 'COMPOUND_SAVER_SUBSCRIBE_RESET';

export const COMPOUND_SAVER_UNSUBSCRIBE_REQUEST = 'COMPOUND_SAVER_UNSUBSCRIBE_REQUEST';
export const COMPOUND_SAVER_UNSUBSCRIBE_SUCCESS = 'COMPOUND_SAVER_UNSUBSCRIBE_SUCCESS';
export const COMPOUND_SAVER_UNSUBSCRIBE_FAILURE = 'COMPOUND_SAVER_UNSUBSCRIBE_FAILURE';

export const SET_COMPOUND_SAVER_UPDATING = 'SET_COMPOUND_SAVER_UPDATING';

export const GET_COMPOUND_SAVER_SUBSCRIBE_HISTORY_REQUEST = 'GET_COMPOUND_SAVER_SUBSCRIBE_HISTORY_REQUEST';
export const GET_COMPOUND_SAVER_SUBSCRIBE_HISTORY_SUCCESS = 'GET_COMPOUND_SAVER_SUBSCRIBE_HISTORY_SUCCESS';
export const GET_COMPOUND_SAVER_SUBSCRIBE_HISTORY_LOADMORE_SUCCESS = 'GET_COMPOUND_SAVER_SUBSCRIBE_HISTORY_LOADMORE_SUCCESS';
export const GET_COMPOUND_SAVER_SUBSCRIBE_HISTORY_FAILURE = 'GET_COMPOUND_SAVER_SUBSCRIBE_HISTORY_FAILURE';
export const GET_COMPOUND_SAVER_SUBSCRIBE_GRAPH_DATA_REQUEST = 'GET_COMPOUND_SAVER_SUBSCRIBE_GRAPH_DATA_REQUEST';
export const GET_COMPOUND_SAVER_SUBSCRIBE_GRAPH_DATA_SUCCESS = 'GET_COMPOUND_SAVER_SUBSCRIBE_GRAPH_DATA_SUCCESS';
export const GET_COMPOUND_SAVER_SUBSCRIBE_GRAPH_DATA_FAILURE = 'GET_COMPOUND_SAVER_SUBSCRIBE_GRAPH_DATA_FAILURE';
