import React from 'react';
import PropTypes from 'prop-types';
import t from 'translate';
import { Link } from 'react-router-dom';
import RecipeProtocolCircleIcon from '../../../Decorative/Protocols/RecipeProtocolCircleIcon';

const PositionsChanged = ({ positions, onPositionClick }) => (
  <>
    <div className="recipe-success-modal-subtitle">Positions changed:</div>
    <div className="positions-changed-main-protocol-wrapper">
      {positions.map((position) => {
        const key = position.keyVal;
        if (key.substr(0, 5) === 'maker') {
          return (
            <Link
              onClick={onPositionClick}
              className="positions-changed-protocol-wrapper"
              to={`/makerdao/manage/${key.substr(6)}`}
              key={key}
            >
              <RecipeProtocolCircleIcon protocol="maker" />
              <span>Manage CDP #{key.substr(6)}</span>
            </Link>
          );
        }
        if (key.substr(0, 8) === 'reflexer') {
          return (
            <Link
              onClick={onPositionClick}
              className="positions-changed-protocol-wrapper"
              to={`/reflexer/manage/${key.substr(9)}`}
              key={key}
            >
              <RecipeProtocolCircleIcon protocol="reflexer" />
              <span>Manage Safe #{key.substr(9)}</span>
            </Link>
          );
        }
        if (key.substr(0, 8) === 'compound') {
          return (
            <Link
              onClick={onPositionClick}
              className="positions-changed-protocol-wrapper"
              to="/compound/manage/smartWallet"
              key={key}
            >
              <RecipeProtocolCircleIcon protocol="compound" />
              <span>Manage Compound</span>
            </Link>
          );
        }
        if (key.substr(0, 7) === 'aaveV2_') {
          return (
            <Link
              onClick={onPositionClick}
              className="positions-changed-protocol-wrapper"
              to="/aave/manage/smartWallet/v2"
              key={key}
            >
              <RecipeProtocolCircleIcon protocol="aavev2" />
              <span>Manage Aave</span>
            </Link>
          );
        }
        if (key.substr(0, 7) === 'liquity') {
          return (
            <Link
              onClick={onPositionClick}
              className="positions-changed-protocol-wrapper"
              to="/liquity/manage/smartWallet"
              key={key}
            >
              <RecipeProtocolCircleIcon protocol="liquity" />
              <span>Manage Liquity {t('liquity.trove')}</span>
            </Link>
          );
        }
        if (key.substr(0, 9) === 'uniswapV3') {
          return (
            <div className="positions-changed-protocol-wrapper" key={key}>
              <RecipeProtocolCircleIcon protocol="maker" />
              <span>{key.substr(9) === 'New position' ? 'New Uniswap V3 NFT' : `Uniswap V3 NFT #${key.substr(9)}`}</span>
            </div>
          );
        }
        return '';
      })}
    </div>
  </>
);
PositionsChanged.propTypes = {
  positions: PropTypes.array.isRequired,
  onPositionClick: PropTypes.func.isRequired,
};
export default PositionsChanged;
