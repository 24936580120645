import t from 'translate';

export const RATIO_STATE_OVER = 0;
export const RATIO_STATE_UNDER = 1;

export const MAKER_CLOSE_ON_PRICE_TO_DAI_STRATEGY = 7;
export const MAKER_CLOSE_ON_PRICE_TO_COLL_STRATEGY = 9;
export const LIQUITY_CLOSE_ON_PRICE_TO_COLL_STRATEGY = 10;

export const BUNDLE_IDS = { // TODO possibly move to sdk?
  0: {
    bundle: 'yearn',
    bundleName: 'Yearn',
    strategyName: 'smart-savings-liquidation-protection',
    protocol: 'makerdao',
  },
  1: {
    bundle: 'mstable',
    bundleName: 'mStable',
    strategyName: 'smart-savings-liquidation-protection',
    protocol: 'makerdao',
  },
  2: {
    bundle: 'rari',
    bundleName: 'Rari',
    strategyName: 'smart-savings-liquidation-protection',
    protocol: 'makerdao',
  },
};

export const STRATEGY_IDS = {
  [MAKER_CLOSE_ON_PRICE_TO_DAI_STRATEGY]: {
    strategyName: 'close-on-price-to-debt',
    protocol: 'makerdao',
  },
  [MAKER_CLOSE_ON_PRICE_TO_COLL_STRATEGY]: {
    strategyName: 'close-on-price-to-coll',
    protocol: 'makerdao',
  },
  [LIQUITY_CLOSE_ON_PRICE_TO_COLL_STRATEGY]: {
    strategyName: 'close-on-price-to-coll',
    protocol: 'liquity',
  },
};

export const MAKER_PREDEFINED_STRATEGIES = [
  { // Keep legacy automation first always
    title: t('strategies.legacy_title'),
    description: t('strategies.legacy_description'),
    strategyName: 'legacy',
    protocol: 'makerdao',
    isComingSoon: false,
    disabled: false,
  },
  {
    title: t('strategies.smart_savings_payback_title'),
    description: t('strategies.smart_savings_payback_description', { '%protocol': 'MakerDAO Vault', '%asset': 'DAI' }),
    strategyName: 'smart-savings-liquidation-protection',
    protocol: 'makerdao',
    isComingSoon: false,
    isBeta: true,
    disabled: false,
  },
  {
    title: t('strategies.stop_loss_title'),
    description: t('strategies.stop_loss_description'),
    strategyName: 'stop-loss',
    protocol: 'makerdao',
    isComingSoon: false,
    isBeta: true,
    disabled: false,
  },
  {
    title: t('strategies.take_profit_title'),
    description: t('strategies.take_profit_description'),
    strategyName: 'take-profit',
    protocol: 'makerdao',
    isComingSoon: false,
    isBeta: true,
    disabled: false,
  },
];

export const AAVE_PREDEFINED_STRATEGIES = [
  { // Keep legacy automation first always
    title: t('strategies.legacy_title'),
    description: t('strategies.legacy_description'),
    strategyName: 'legacy',
    protocol: 'aave',
    isComingSoon: false,
    disabled: false,
  },
  {
    title: t('strategies.smart_savings_payback_title'),
    description: t('strategies.smart_savings_payback_description', { '%protocol': 'Aave position', '%asset': 'DAI' }),
    strategyName: 'smart-savings-liquidation-protection',
    protocol: 'aave',
    isComingSoon: true,
    isBeta: true,
    disabled: false,
  },
  {
    title: t('strategies.stop_loss_title'),
    description: t('strategies.stop_loss_description'),
    strategyName: 'stop-loss',
    protocol: 'aave',
    isComingSoon: true,
    isBeta: true,
    disabled: false,
  },
  {
    title: t('strategies.take_profit_title'),
    description: t('strategies.take_profit_description'),
    strategyName: 'take-profit',
    protocol: 'aave',
    isComingSoon: true,
    isBeta: true,
    disabled: false,
  },
];

export const COMPOUND_PREDEFINED_STRATEGIES = [
  { // Keep legacy automation first always
    title: t('strategies.legacy_title'),
    description: t('strategies.legacy_description'),
    strategyName: 'legacy',
    protocol: 'compound',
    isComingSoon: false,
    disabled: false,
  },
  {
    title: t('strategies.smart_savings_payback_title'),
    description: t('strategies.smart_savings_payback_description', { '%protocol': 'Compound position', '%asset': 'DAI' }),
    strategyName: 'smart-savings-liquidation-protection',
    protocol: 'compound',
    isComingSoon: true,
    isBeta: true,
    disabled: false,
  },
  {
    title: t('strategies.stop_loss_title'),
    description: t('strategies.stop_loss_description'),
    strategyName: 'stop-loss',
    protocol: 'compound',
    isComingSoon: true,
    isBeta: true,
    disabled: false,
  },
  {
    title: t('strategies.take_profit_title'),
    description: t('strategies.take_profit_description'),
    strategyName: 'take-profit',
    protocol: 'compound',
    isComingSoon: true,
    isBeta: true,
    disabled: false,
  },
];

export const LIQUITY_PREDEFINED_STRATEGIES = [
  {
    title: t('strategies.stop_loss_title'),
    description: t('strategies.stop_loss_description'),
    strategyName: 'stop-loss',
    protocol: 'liquity',
    isComingSoon: false,
    isBeta: true,
    disabled: false,
  },
  {
    title: t('strategies.take_profit_title'),
    description: t('strategies.take_profit_description'),
    strategyName: 'take-profit',
    protocol: 'liquity',
    isComingSoon: false,
    isBeta: true,
    disabled: false,
  },
];

