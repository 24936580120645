import { ethToWeth } from './utils';
import DyDxFlashLoanAction from '../recipeActions/flashloan/DyDxFlashLoanAction';
import AaveV2FlashLoanAction from '../recipeActions/flashloan/AaveV2FlashLoanAction';
import MakerWithdrawAction from '../recipeActions/maker/MakerWithdrawAction';
import CompoundWithdrawAction from '../recipeActions/compound/CompoundWithdrawAction';
import AaveV2WithdrawAction from '../recipeActions/aaveV2/AaveV2WithdrawAction';
import MakerSupplyAction from '../recipeActions/maker/MakerSupplyAction';
import CompoundSupplyAction from '../recipeActions/compound/CompoundSupplyAction';
import AaveV2SupplyAction from '../recipeActions/aaveV2/AaveV2SupplyAction';
import MakerGenerateAction from '../recipeActions/maker/MakerGenerateAction';
import CompoundBorrowAction from '../recipeActions/compound/CompoundBorrowAction';
import AaveV2BorrowAction from '../recipeActions/aaveV2/AaveV2BorrowAction';
import MakerPaybackAction from '../recipeActions/maker/MakerPaybackAction';
import CompoundPaybackAction from '../recipeActions/compound/CompoundPaybackAction';
import AaveV2PaybackAction from '../recipeActions/aaveV2/AaveV2PaybackAction';
import ReflexerWithdrawAction from '../recipeActions/reflexer/ReflexerWithdrawAction';
import ReflexerSupplyAction from '../recipeActions/reflexer/ReflexerSupplyAction';
import ReflexerGenerateAction from '../recipeActions/reflexer/ReflexerGenerateAction';
import ReflexerPaybackAction from '../recipeActions/reflexer/ReflexerPaybackAction';
import LiquityWithdrawAction from '../recipeActions/liquity/LiquityWithdrawAction';
import LiquitySupplyAction from '../recipeActions/liquity/LiquitySupplyAction';
import LiquityBorrowAction from '../recipeActions/liquity/LiquityBorrowAction';
import LiquityPaybackAction from '../recipeActions/liquity/LiquityPaybackAction';
import BalancerFlashLoanAction from '../recipeActions/flashloan/BalancerFlashLoanAction';
import MakerFlashLoanAction from '../recipeActions/flashloan/MakerFlashLoanAction';
import LidoStakeAction from '../recipeActions/lido/LidoStakeAction';
import LidoWrapAction from '../recipeActions/lido/LidoWrapAction';
import CurveStethPoolSupplyAction from '../recipeActions/curve/CurveStethPoolSupplyAction';
import LidoUnwrapAction from '../recipeActions/lido/LidoUnwrapAction';
import CurveStethPoolWithdrawAction from '../recipeActions/curve/CurveStethPoolWithdrawAction';
import { getBestExchangePriceCached } from './exchangeServiceV3';
import { setSlippagePercent } from './exchangeServiceCommon';

export const getFlRecipeAction = (asset, amount, protocol) => {
  switch (protocol) {
    case 'dydx':
      return new DyDxFlashLoanAction(asset, amount);
    case 'aave':
      return new AaveV2FlashLoanAction(asset, amount);
    case 'maker':
      return new MakerFlashLoanAction(amount);
    case 'balancer':
      return new BalancerFlashLoanAction(asset, amount);
    default:
      throw new Error('Invalid flashloan protocol');
  }
};


export const getWithdrawForProtocol = (position, coll, to, amount = 'All available') => {
  if (position.meta.protocol === 'maker') return new MakerWithdrawAction(position.meta.id, amount, to);
  if (position.meta.protocol === 'reflexer') return new ReflexerWithdrawAction(position.meta.id, amount, to);
  if (position.meta.protocol === 'liquity') return new LiquityWithdrawAction(amount, to);
  if (position.meta.protocol === 'compound') return new CompoundWithdrawAction(ethToWeth(coll.helpValue), amount, to);
  if (position.meta.protocol === 'aave') return new AaveV2WithdrawAction('v2default', ethToWeth(coll.helpValue), amount, to);
};

export const getSupplyForProtocol = (position, coll, from, amount = 'All available') => {
  if (position.meta.protocol === 'maker') return new MakerSupplyAction(position.meta.id, from, amount);
  if (position.meta.protocol === 'reflexer') return new ReflexerSupplyAction(position.meta.id, from, amount);
  if (position.meta.protocol === 'liquity') return new LiquitySupplyAction(from, amount);
  if (position.meta.protocol === 'compound') return new CompoundSupplyAction(ethToWeth(coll.helpValue), from, amount);
  if (position.meta.protocol === 'aave') return new AaveV2SupplyAction('v2default', ethToWeth(coll.helpValue), from, amount);
};

export const getBorrowForProtocol = (position, debt, to, amount = 'All available') => {
  if (position.meta.protocol === 'maker') return new MakerGenerateAction(position.meta.id, amount, to);
  if (position.meta.protocol === 'reflexer') return new ReflexerGenerateAction(position.meta.id, amount, to);
  if (position.meta.protocol === 'liquity') return new LiquityBorrowAction(amount, to);
  if (position.meta.protocol === 'compound') return new CompoundBorrowAction(ethToWeth(debt.helpValue), amount, to);
  if (position.meta.protocol === 'aave') return new AaveV2BorrowAction('v2default', ethToWeth(debt.helpValue), amount, to, debt.interestMode);
};

export const getPaybackForProtocol = (position, debt, from, amount = 'All available') => {
  if (position.meta.protocol === 'maker') return new MakerPaybackAction(position.meta.id, from, amount);
  if (position.meta.protocol === 'reflexer') return new ReflexerPaybackAction(position.meta.id, from, amount);
  if (position.meta.protocol === 'liquity') return new LiquityPaybackAction(from, amount);
  if (position.meta.protocol === 'compound') return new CompoundPaybackAction(ethToWeth(debt.helpValue), from, amount);
  if (position.meta.protocol === 'aave') return new AaveV2PaybackAction('v2default', ethToWeth(debt.helpValue), from, amount, debt.interestMode);
};

export const getFlInfoFromRecipeActions = (recipeActions) => {
  const flActionInfo = [
    { action: DyDxFlashLoanAction, protocol: 'dydx', feePercent: '0' },
    { action: AaveV2FlashLoanAction, protocol: 'aave', feePercent: '0.09' },
    { action: MakerFlashLoanAction, protocol: 'maker', feePercent: '0' },
    { action: BalancerFlashLoanAction, protocol: 'balancer', feePercent: '0' },
  ];
  let flUsed;
  recipeActions.some((action) => {
    flActionInfo.forEach((flInfo) => {
      if (action instanceof flInfo.action) {
        flUsed = flInfo;
      }
    });
    return !!flUsed;
  });
  return flUsed;
};
export const handleLidoCurveActions = (_fromAsset, _toAsset, actions, fromSource, toSource, amount) => {
  const fromAsset = _fromAsset.toUpperCase();
  const toAsset = _toAsset.toUpperCase();
  if (fromAsset === 'WETH') {
    if (toAsset === 'STETH') {
      // TODO check isStethOnPeg
      return actions.push(new LidoStakeAction(fromSource, amount, toSource));
    }
    if (toAsset === 'WSTETH') {
      return actions.push(new LidoWrapAction(_fromAsset, fromSource, amount, toSource));
    }
    if (toAsset === 'STECRV') {
      return actions.push(new CurveStethPoolSupplyAction(fromSource, toSource, amount, '0'));
    }
  }
  if (fromAsset === 'STETH') {
    if (toAsset === 'WSTETH') {
      return actions.push(new LidoWrapAction(_fromAsset, fromSource, amount, toSource));
    }
    if (toAsset === 'STECRV') {
      return actions.push(new CurveStethPoolSupplyAction(fromSource, toSource, '0', amount));
    }
  }
  if (fromAsset === 'WSTETH') {
    if (toAsset === 'STETH') {
      return actions.push(new LidoUnwrapAction(fromSource, amount, toSource));
    }
    if (toAsset === 'STECRV') {
      actions.push(new LidoUnwrapAction(fromSource, amount, toSource));
      return actions.push(new CurveStethPoolSupplyAction(fromSource, toSource, '0', `$${actions[actions.length - 1].id}`));
    }
  }
  if (fromAsset === 'STECRV') {
    if (toAsset === 'WETH') {
      return actions.push(new CurveStethPoolWithdrawAction(fromSource, toSource, amount, 'WETH', 'WETH'));
    }
    if (toAsset === 'STETH') {
      return actions.push(new CurveStethPoolWithdrawAction(fromSource, toSource, amount, 'stETH', 'stETH'));
    }
    if (toAsset === 'WSTETH') {
      actions.push(new CurveStethPoolWithdrawAction(fromSource, toSource, amount, 'stETH', 'stETH'));
      return actions.push(new LidoWrapAction('stETH', fromSource, amount, toSource));// pool withdraw action returns amount of lp burned
    }
  }
  throw new Error(`No combination for assets ${fromAsset} and ${toAsset}`);
};

/**
 * @param amount {string | number}
 * @param [slippage] {number}
 * @returns {Promise<boolean>}
 */
export const isStethOnPeg = async (amount, slippage = 0.1) => {
  const { price } = await getBestExchangePriceCached(amount, 'WETH', 'stETH');
  return +setSlippagePercent(slippage, price) < 1;
};
