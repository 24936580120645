/* eslint-disable max-classes-per-file */
import Dec from 'decimal.js';
import {
  assetAmountInEth, assetAmountInWei, getAssetInfo, getAssetInfoByAddress,
} from '@defisaver/tokens';
import dfs from '@defisaver/sdk';
import t from 'translate';
import SavingsGetter from './SavingsGetter';
import {
  MStableImUsdTokenAddress,
  MStableImUsdTokenContract, MStableImUsdVaultAddress, MStableMUsdTokenAddress,
  MStableMUsdTokenContract,
} from '../../contractRegistryService';
import { multicall } from '../../multicallService';
import MStableProtocolIcon from '../../../components/Decorative/Protocols/MStableProtocolIcon';
import { getAssetBalanceAction } from '../../../actions/assetsActions';
import {
  supplyToVault, importPosition, moveVault, withdrawFromVault,
} from '../../../actions/savingsActions/savingsRecipeActions';
import { basicAmountValidation } from '../../vaultCommonService';
import { confirmViaModal } from '../../../actions/modalActions';
import { compareAddresses, formatNumber } from '../../utils';
import { MAXUINT } from '../../../constants/general';

export class MStableSaveGetter extends SavingsGetter {
  static supplyRecipeName = 'mStableDeposit';

  static withdrawRecipeName = 'mStableWithdraw';

  static moveRecipeName = 'mStableMove';

  constructor(slug, name) {
    super(slug, name, ['DAI', 'USDC', 'USDT', 'mUSD'], 'mUSD', MStableProtocolIcon);
    this.tokenContract = MStableImUsdTokenAddress;
    this.mUSDContract = MStableMUsdTokenContract;
    this.description = t('savings.description_mstable_save');
    this.isVault = false;
  }

  // overriden
  setMoveOptions(allGetters) {
    super.setMoveOptions(allGetters);

    this.moveOptions = this.moveOptions.map((option) => ({
      ...option,
      label: `${option.label} ${option.label.startsWith('mStable') ? '' : option.getter.getManageData().availableAssets[0]}`,
    }));
  }

  static _setMinoutSlippage(minOut) {
    return new Dec(minOut).sub(1e18).toString();
  }

  static _getAssetPair(asset, isVault) {
    const assetPairs = dfs.utils.mstableAssetPairs;
    if (isVault && asset.symbol === 'imUSD') return assetPairs.IMASSET_IMASSETVAULT;
    if (asset.symbol === 'mUSD') return isVault ? assetPairs.MASSET_IMASSETVAULT : assetPairs.MASSET_IMASSET;
    return isVault ? assetPairs.BASSET_IMASSETVAULT : assetPairs.BASSET_IMASSET;
  }

  static async _getMaxWithdraw(account, _asset) {
    const asset = getAssetInfo(_asset);
    const mUsdAmount = assetAmountInWei(await this._getSupplied(account), 'mUSD');
    if (asset.symbol === 'mUSD') {
      return assetAmountInEth(new Dec(mUsdAmount).sub(1).toString(), asset.symbol);
    }
    const assetAmount = await MStableMUsdTokenContract().methods.getRedeemOutput(asset.address, mUsdAmount).call().catch(async () => {
      const withdrawableAssetLiquidity = await this._getWithdrawableAssetLiquidity();
      const assetLiquidity = withdrawableAssetLiquidity.find((withdrawableAsset) => compareAddresses(withdrawableAsset.asset.address, asset.address));
      return assetLiquidity === undefined ? 0 : assetLiquidity.liquidity;
    });
    return assetAmountInEth(new Dec(assetAmount).sub(1).toString(), asset.symbol);
  }

  static async _getSuppliedImUsd(account) {
    const imUSD = MStableImUsdTokenContract();
    return imUSD.methods.balanceOf(account).call();
  }

  static async _getSupplied(account) {
    const imUSD = MStableImUsdTokenContract();
    const underlyingAmountWei = await imUSD.methods.balanceOfUnderlying(account).call();
    return assetAmountInEth(underlyingAmountWei, 'mUSD');
  }

  static async _assetToMUsd(assetAddress, amount) {
    if (new Dec(amount).eq(0)) return '0';
    return MStableMUsdTokenContract().methods.getMintOutput(assetAddress, amount).call();
  }

  static async _mUsdToAsset(assetAddress, mUsdAmount) {
    if (new Dec(mUsdAmount).eq(0)) return '0';
    return MStableMUsdTokenContract().methods.getRedeemOutput(assetAddress, mUsdAmount).call();
  }

  static async _mUsdToImUsd(amount) {
    if (new Dec(amount).eq(0)) return '0';
    return MStableImUsdTokenContract().methods.underlyingToCredits(amount).call();
  }

  static async _assetOutToMUsd(assetAddress, amount) {
    if (new Dec(amount).eq(0)) return '0';
    return MStableMUsdTokenContract().methods.getRedeemExactBassetsOutput([assetAddress], [amount]).call();
  }

  static async _imUsdToMUsd(amount) {
    if (new Dec(amount).eq(0)) return '0';
    return MStableImUsdTokenContract().methods.creditsToUnderlying(amount).call();
  }

  static async _getWithdrawableAssetLiquidity() {
    const mUSD = MStableMUsdTokenContract();
    const [bAssets, config] = await Promise.all([
      mUSD.methods.getBassets().call(),
      mUSD.methods.getConfig().call(),
    ]);
    const assetLiquidity = bAssets.personal.map((_, i) => {
      const asset = getAssetInfoByAddress(bAssets.personal[i].addr);
      return {
        asset,
        liquidity: assetAmountInEth(bAssets.data[i][1], asset.symbol),
      };
    });
    const totalLiquidity = assetLiquidity.reduce((acc, val) => acc.add(val.liquidity), new Dec(0));
    return assetLiquidity.map(({ asset }) => ({
      asset,
      liquidity: this._getAssetLiquidity(asset.symbol, assetLiquidity, totalLiquidity, assetAmountInEth(config.limits.min), assetAmountInEth(config.limits.max)),
    }));
  }

  static _getAssetLiquidity(asset, assetAmounts, totalLiquidity, lowerBound = '0.05', upperBound = '0.65') {
    const low = assetAmounts.find((a) => a.asset.symbol === asset);
    const up = assetAmounts.filter((a) => a.asset.symbol !== asset);

    let min = new Dec(low.liquidity).sub(new Dec(totalLiquidity).mul(lowerBound)).div(new Dec(1).sub(lowerBound));

    up.forEach(({ liquidity }) => {
      const curr = new Dec(totalLiquidity).mul(upperBound).sub(liquidity).div(upperBound);
      if (curr.lt(min)) {
        min = curr;
      }
    });
    return assetAmountInWei(min.toString(), asset) - 1;
  }

  static async _getMStablePoolData() {
    const calls = [
      {
        target: MStableImUsdTokenAddress,
        params: [],
        abiItem: { inputs: [], name: 'totalSupply', outputs: [{ name: 'totalSupply', type: 'uint256' }] },
      },
      {
        target: MStableImUsdTokenAddress,
        params: [],
        abiItem: { inputs: [], name: 'exchangeRate', outputs: [{ name: 'exchangeRate', type: 'uint256' }] },
      },
    ];
    const [{ totalSupply }, { exchangeRate }] = await multicall(calls);
    return {
      totalSupply,
      exchangeRate: assetAmountInEth(exchangeRate, 'ETH'),
      fundBalance: new Dec(assetAmountInEth(totalSupply, 'imUSD')).mul(assetAmountInEth(exchangeRate, 'ETH')).toString(),
    };
  }


  async getPoolSize() {
    const { fundBalance } = await this.constructor._getMStablePoolData();
    return fundBalance;
  }


  async getSupplied(account) {
    return this.constructor._getSupplied(account);
  }

  getMaxWithdraw(account) {
    return async ({ value }) => this.constructor._getMaxWithdraw(account, value);
  }

  getMaxWithdrawMove(account) {
    return async (val) => {
      const getter = val.getter;
      const asset = getAssetInfo(getter.getManageData().suppliedAsset);
      return this.getMaxWithdraw(account)({ value: asset.symbol });
    };
  }

  getMaxSupply(dispatch) {
    return async ({ value }) => dispatch(getAssetBalanceAction(value || this.availableAssets[0]));
  }

  async getPoolLiquidity() {
    return this.getPoolSize();
  }


  static async _checkMUsdExchangeRate(mUsdAmount, bAssetAmount, bAsset, dispatch) {
    if (new Dec(assetAmountInEth(mUsdAmount)).mul(0.99).gte(assetAmountInEth(bAssetAmount, bAsset))) {
      const confirmed = await dispatch(confirmViaModal(t('savings.mstable_unfavorable_exchange_rate', {
        '%asset%': bAsset,
        '%mUSDAmount%': formatNumber(assetAmountInEth(mUsdAmount)),
        '%bAssetAmount%': formatNumber(assetAmountInEth(bAssetAmount, bAsset)),
      })));
      if (!confirmed) throw new Error(t('errors.denied_transaction'));
    }
  }

  static _getMStableImportRecipe() {
    return async (firstAction, firstInput, account, proxyAddress, firstInputSelect, isMax) => {
      const amount = isMax ?
        MAXUINT :
        await this._mUsdToImUsd(firstInput);
      return new dfs.Recipe('mStableImport', [
        new dfs.actions.basic.PullTokenAction(firstAction.approve, account, amount),
      ]);
    };
  }


  static _getMStableSupplyRecipe(isVault) {
    return async (firstAction, firstInput, account, proxyAddress, asset, isMax) => {
      const amount = assetAmountInWei(firstInput, asset.symbol);
      const minOut = asset.symbol === 'mUSD' ? amount : await this._assetToMUsd(asset.address, amount);
      const assetPair = this._getAssetPair(asset, isVault);
      return new dfs.Recipe(this.supplyRecipeName, [
        new dfs.actions.mstable.MStableDepositAction(asset.address, MStableMUsdTokenAddress, MStableImUsdTokenAddress, MStableImUsdVaultAddress, account, proxyAddress, isMax ? MAXUINT : amount, this._setMinoutSlippage(minOut), assetPair),
      ]);
    };
  }

  static _getMStableWithdrawRecipe(dispatch, isVault) {
    return async (firstAction, firstInput, account, proxyAddress, asset, isMax) => {
      const minOut = assetAmountInWei(firstInput, asset.symbol);
      const mUsdAmount = asset.symbol === 'mUSD' ? minOut : await this._assetOutToMUsd(asset.address, minOut);
      await this._checkMUsdExchangeRate(mUsdAmount, minOut, asset.symbol, dispatch);
      const imUsdAmount = isMax ? MAXUINT : await this._mUsdToImUsd(mUsdAmount);
      const assetPair = this._getAssetPair(asset, isVault);
      return new dfs.Recipe(this.withdrawRecipeName, [
        new dfs.actions.mstable.MStableWithdrawAction(asset.address, MStableMUsdTokenAddress, MStableImUsdTokenAddress, MStableImUsdVaultAddress, proxyAddress, account, imUsdAmount, this._setMinoutSlippage(minOut), assetPair),
      ]);
    };
  }

  static _getMStableMoveWithdrawRecipe(dispatch, isVault) {
    return async (firstAction, firstInput, account, proxyAddress, firstInputSelect, isMax) => {
      const asset = getAssetInfo(firstInputSelect.getter.getManageData().availableAssets[0]);
      const minOut = assetAmountInWei(firstInput, asset.symbol);
      const mUsdAmount = asset.symbol === 'mUSD' ? minOut : await this._assetOutToMUsd(asset.address, minOut);
      await this._checkMUsdExchangeRate(mUsdAmount, minOut, asset.symbol, dispatch);
      const imUsdAmount = isMax ? MAXUINT : await this._mUsdToImUsd(mUsdAmount);
      const assetPair = this._getAssetPair(asset, isVault);
      return {
        recipe: new dfs.Recipe(this.moveRecipeName, [
          new dfs.actions.mstable.MStableWithdrawAction(asset.address, MStableMUsdTokenAddress, MStableImUsdTokenAddress, MStableImUsdVaultAddress, proxyAddress, proxyAddress, imUsdAmount, this._setMinoutSlippage(minOut), assetPair),
        ]),
        asset,
        amount: '$1',
        estimateAmount: minOut,
      };
    };
  }

  async getMoveSupplyActions(amount, asset, from, to, estimateAmount) {
    const minOut = asset.symbol === 'mUSD' ? amount : await this.constructor._assetToMUsd(asset.address, new Dec(estimateAmount).mul(0.99).floor().toString());
    const assetPair = this.constructor._getAssetPair(asset, this.isVault);
    return [new dfs.actions.mstable.MStableDepositAction(asset.address, MStableMUsdTokenAddress, MStableImUsdTokenAddress, MStableImUsdVaultAddress, from, to, amount, minOut, assetPair)];
  }

  getImportAction(account, proxyAddress, hasSmartWallet, dispatch) {
    let action = (contextAction) => dispatch(importPosition(contextAction, this.constructor._getMStableImportRecipe()));
    if (!hasSmartWallet) {
      action = this.getCreateSmartWalletAction(dispatch);
    }
    return {
      value: 'import',
      label: t('common.import'),
      executingLabel: t('common.importing'),
      description: t('savings.import_info'),
      symbol: 'mUSD',
      approve: this.tokenContract,
      getMaxValue: () => this.getSupplied(account),
      validate: basicAmountValidation(),
      action,
      otherProps: {
        allowOverMax: false,
        debounce: true,
        actionDesc: '',
      },
      additionalActions: [],
    };
  }

  getSupplyAction(account, proxyAddress, hasSmartWallet, dispatch) {
    let action = (contextAction) => dispatch(supplyToVault(contextAction, this.constructor._getMStableSupplyRecipe(this.isVault)));
    if (!hasSmartWallet) {
      action = this.getCreateSmartWalletAction(dispatch);
    }
    return {
      value: 'supply',
      label: t('common.supply'),
      executingLabel: t('common.supplying'),
      description: t('savings.supply_info'),
      getMaxValue: this.getMaxSupply(dispatch),
      validate: basicAmountValidation(),
      action,
      otherProps: {
        allowOverMax: false,
        debounce: true,
        actionDesc: '',
        approveSelect: true,
        selectOptions: this.availableAssets.map(asset => ({
          ...getAssetInfo(asset),
          value: asset,
          label: asset,
        })),
      },
      additionalActions: [],
    };
  }

  getWithdrawAction(account, proxyAddress, hasSmartWallet, dispatch) {
    return {
      value: 'withdraw',
      label: t('common.withdraw'),
      executingLabel: t('common.withdrawing'),
      description: t('savings.withdraw_info'),
      getMaxValue: this.getMaxWithdraw(proxyAddress),
      validate: basicAmountValidation(),
      action: (contextAction) => dispatch(withdrawFromVault(contextAction, this.constructor._getMStableWithdrawRecipe(dispatch, this.isVault))),
      otherProps: {
        allowOverMax: false,
        debounce: true,
        actionDesc: '',
        selectOptions: this.availableAssets.map(asset => ({
          ...getAssetInfo(asset),
          value: asset,
          label: asset,
        })),
      },
      additionalActions: [],
    };
  }


  getMoveAction(account, proxyAddress, hasSmartWallet, dispatch) {
    return {
      value: 'move',
      label: t('common.move'),
      executingLabel: t('common.moving'),
      description: t('savings.move_info'),
      getMaxValue: this.getMaxWithdrawMove(proxyAddress),
      validate: basicAmountValidation(),
      action: (contextAction) => dispatch(moveVault(contextAction, this.constructor._getMStableMoveWithdrawRecipe(dispatch, this.isVault))),
      otherProps: {
        allowOverMax: false,
        debounce: true,
        actionDesc: '',
        selectOptions: this.moveOptions,
      },
      additionalActions: [],
    };
  }
}
