import React from 'react';

export default function ExchangeIcon(props) {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="30" height="30" rx="3" fill="#61717E" />
      <path fillRule="evenodd" clipRule="evenodd" d="M16.439 7.22082C16.7174 6.93309 17.1764 6.92556 17.4642 7.20401L22.0816 11.6725C22.2939 11.878 22.3603 12.1917 22.2495 12.4655C22.1387 12.7393 21.8729 12.9185 21.5775 12.9185H7.725C7.32459 12.9185 7 12.5939 7 12.1935C7 11.7931 7.32459 11.4685 7.725 11.4685H19.7858L16.4558 8.24599C16.1681 7.96754 16.1605 7.50855 16.439 7.22082Z" fill="#626D75" />
      <path fillRule="evenodd" clipRule="evenodd" d="M13.3105 22.0817C13.0321 22.3694 12.5731 22.3769 12.2854 22.0985L7.66787 17.6299C7.4556 17.4245 7.38918 17.1108 7.49999 16.837C7.61079 16.5632 7.87665 16.384 8.17205 16.384L22.0245 16.384C22.4249 16.384 22.7495 16.7085 22.7495 17.109C22.7495 17.5094 22.4249 17.834 22.0245 17.834L9.96375 17.834L13.2937 21.0565C13.5815 21.335 13.589 21.7939 13.3105 22.0817Z" fill="#626D75" />
    </svg>
  );
}
