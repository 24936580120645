export const GET_SAVINGS_MANAGE_DATA_REQUEST = 'GET_SAVINGS_MANAGE_DATA_REQUEST';
export const GET_SAVINGS_MANAGE_DATA_SUCCESS = 'GET_SAVINGS_MANAGE_DATA_SUCCESS';
export const GET_SAVINGS_MANAGE_DATA_FAILURE = 'GET_SAVINGS_MANAGE_DATA_FAILURE';

export const GET_SAVINGS_MANAGE_PROJECTS_INFO_REQUEST = 'GET_SAVINGS_MANAGE_PROJECTS_INFO_REQUEST';
export const GET_SAVINGS_MANAGE_PROJECTS_INFO_SUCCESS = 'GET_SAVINGS_MANAGE_PROJECTS_INFO_SUCCESS';
export const GET_SAVINGS_MANAGE_PROJECTS_INFO_FAILURE = 'GET_SAVINGS_MANAGE_PROJECTS_INFO_FAILURE';

export const GET_SAVINGS_PROJECT_DATA_REQUEST = 'GET_SAVINGS_PROJECT_DATA_REQUEST';
export const GET_SAVINGS_PROJECT_DATA_SUCCESS = 'GET_SAVINGS_PROJECT_DATA_SUCCESS';
export const GET_SAVINGS_PROJECT_DATA_FAILURE = 'GET_SAVINGS_PROJECT_DATA_FAILURE';

export const GET_SAVINGS_ADDITIONAL_DATA_REQUEST = 'GET_SAVINGS_ADDITIONAL_DATA_REQUEST';
export const GET_SAVINGS_ADDITIONAL_DATA_SUCCESS = 'GET_SAVINGS_ADDITIONAL_DATA_SUCCESS';
export const GET_SAVINGS_ADDITIONAL_DATA_FAILURE = 'GET_SAVINGS_ADDITIONAL_DATA_FAILURE';

export const GET_SAVINGS_MANAGE_AFTER_VALUE_REQUEST = 'GET_SAVINGS_MANAGE_AFTER_VALUE_REQUEST';
export const GET_SAVINGS_MANAGE_AFTER_VALUE_SUCCESS = 'GET_SAVINGS_MANAGE_AFTER_VALUE_SUCCESS';
export const GET_SAVINGS_MANAGE_AFTER_VALUE_FAILURE = 'GET_SAVINGS_MANAGE_AFTER_VALUE_FAILURE';

export const SAVINGS_MANAGE_WITHDRAW_ASSET_TO_ACCOUNT_DIRECT_REQUEST = 'SAVINGS_MANAGE_WITHDRAW_ASSET_TO_ACCOUNT_DIRECT_REQUEST';
export const SAVINGS_MANAGE_WITHDRAW_ASSET_TO_ACCOUNT_DIRECT_SUCCESS = 'SAVINGS_MANAGE_WITHDRAW_ASSET_TO_ACCOUNT_DIRECT_SUCCESS';
export const SAVINGS_MANAGE_WITHDRAW_ASSET_TO_ACCOUNT_DIRECT_FAILURE = 'SAVINGS_MANAGE_WITHDRAW_ASSET_TO_ACCOUNT_DIRECT_FAILURE';

export const GET_SAVINGS_MANAGE_EXECUTING_REQUEST = 'GET_SAVINGS_MANAGE_EXECUTING_REQUEST';
export const GET_SAVINGS_MANAGE_EXECUTING_SUCCESS = 'GET_SAVINGS_MANAGE_EXECUTING_SUCCESS';
export const GET_SAVINGS_MANAGE_EXECUTING_FAILURE = 'GET_SAVINGS_MANAGE_EXECUTING_FAILURE';

export const GET_SAVINGS_MANAGE_MAX_VALUE_REQUEST = 'GET_SAVINGS_MANAGE_MAX_VALUE_REQUEST';
export const GET_SAVINGS_MANAGE_MAX_VALUE_SUCCESS = 'GET_SAVINGS_MANAGE_MAX_VALUE_SUCCESS';
export const GET_SAVINGS_MANAGE_MAX_VALUE_FAILURE = 'GET_SAVINGS_MANAGE_MAX_VALUE_FAILURE';
