import React from 'react';
import PropTypes from 'prop-types';
import warningIcon from './warning.svg';
import './WarningBox.scss';

const WarningBox = ({
  children, marginTop, marginBottom, withButton, extraDanger,
}) => (
  <div className={`warning-box-wrapper ${marginTop ? 'margin-top' : ''}  ${marginBottom ? 'margin-bottom' : ''} ${withButton ? 'with-button' : ''} ${extraDanger ? 'extra-danger' : ''}`}>
    <img src={warningIcon} alt="" />
    <span>
      {children}
    </span>
  </div>
);

WarningBox.defaultProps = {
  marginTop: false,
  marginBottom: false,
  withButton: false,
  extraDanger: false,
};

WarningBox.propTypes = {
  children: PropTypes.node.isRequired,
  marginTop: PropTypes.bool,
  marginBottom: PropTypes.bool,
  withButton: PropTypes.bool,
  extraDanger: PropTypes.bool,
};

export default WarningBox;
