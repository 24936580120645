import React, { Component } from 'react';
import { connect } from 'react-redux';
import t from 'translate';
import PropTypes from 'prop-types';
import {
  getCloseCdpData,
  clearCloseMcdCdpErrors,
} from 'actions/makerActions/makerManageActions/makerManageMcdActions';
import ModalBody from '../../ModalBody';
import Loader from '../../../Loader/Loader';
import ErrorBox from '../../../Decorative/ErrorBox/ErrorBox';
import CloseMcdCdpPicker from './CloseMcdCdpPicker/CloseMcdCdpPicker';
import ModalHeader from '../../ModalHeader';

import './CloseMcdCdpModal.scss';

class CloseMcdCdpModal extends Component {
  UNSAFE_componentWillMount() {
    this.props.getCloseCdpData();
  }

  componentWillUnmount() {
    this.props.clearCloseMcdCdpErrors();
  }

  render() {
    const {
      gettingCloseCdpData, gettingCloseCdpDataError, closeData,
      closingCdp, closeModal, closingCdpError,
    } = this.props;

    const loadedData = !gettingCloseCdpData && !gettingCloseCdpDataError && closeData;

    return (
      <div id="close-mcd-cdp-modal-wrapper">
        <ModalHeader closeModal={closeModal} />

        <ModalBody>
          { gettingCloseCdpData && (<Loader />) }


          {
            !gettingCloseCdpData && gettingCloseCdpDataError && (
              <ErrorBox>{ gettingCloseCdpDataError }</ErrorBox>
            )
          }

          {
            loadedData && (
              <CloseMcdCdpPicker
                closeModal={closeModal}
                closing={closingCdp}
                error={closingCdpError}
              />
            )
          }
        </ModalBody>

        {
          loadedData && (
            <div className="modal-controls">
              <button
                type="submit"
                className="button green"
                form="close-mcd-cdp-form"
                disabled={closingCdp}
              >
                { closingCdp ? t('common.closing') : t('common.close') } cdp
              </button>
            </div>
          )
        }
      </div>
    );
  }
}

CloseMcdCdpModal.defaultProps = {
  closeData: null,
};

CloseMcdCdpModal.propTypes = {
  getCloseCdpData: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  clearCloseMcdCdpErrors: PropTypes.func.isRequired,
  closingCdp: PropTypes.bool.isRequired,
  closingCdpError: PropTypes.string.isRequired,
  gettingCloseCdpData: PropTypes.bool.isRequired,
  gettingCloseCdpDataError: PropTypes.string.isRequired,
  closeData: PropTypes.object,
};

const mapStateToProps = ({
  makerManage, makerManageMcd,
}) => ({
  gettingCloseCdpData: makerManageMcd.gettingCloseCdpData,
  gettingCloseCdpDataError: makerManageMcd.gettingCloseCdpDataError,
  closeData: makerManageMcd.closeData,

  closingCdp: makerManage.closingCdp,
  closingCdpError: makerManage.closingCdpError,
});

const mapDispatchToProps = { getCloseCdpData, clearCloseMcdCdpErrors };

export default connect(mapStateToProps, mapDispatchToProps)(CloseMcdCdpModal);
