import t from 'translate';
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import ModalBody from '../ModalBody';

import './InfoModal.scss';

const InfoModal = ({ title, content, closeModal }) => {
  const dispatch = useDispatch();
  return (
    <div className="info-modal">
      <ModalBody>
        <div className="new-modal-top-wrapper">
          <h1>{title}</h1>
        </div>
        <div className="new-modal-content-wrapper">
          {typeof content === 'string' && (<p>{content}</p>)}
          {typeof content === 'function' && content()}
        </div>
      </ModalBody>

      <div className="modal-controls">
        <button type="button" className="button gray" onClick={() => dispatch(closeModal())}>{t('common.close')}</button>
      </div>
    </div>
  );
};

InfoModal.defaultProps = {};

InfoModal.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default InfoModal;
