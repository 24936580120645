import { getAssetInfo } from '@defisaver/tokens';
import { compoundCollateralAssetsOptions, aaveCollateralAssetsOptions } from 'constants/assets';
import Dec from 'decimal.js';

const formatOption = (option, usedAssetData, assetData, eModeCategory = 0) => ({
  ...option,
  amount: usedAssetData?.supplied || usedAssetData?.borrowed || '0',
  amountUsd: usedAssetData?.suppliedUsd || usedAssetData?.borrowedUsd || '0',
  isSupplied: usedAssetData?.isSupplied,
  isBorrowed: usedAssetData?.isBorrowed,
  canBeBorrowed: assetData.canBeBorrowed,
  canBeSupplied: assetData.canBeSupplied,
  borrowSortPower: (usedAssetData?.isBorrowed ? 1 : 0) + (usedAssetData?.isSupplied ? -1 : 0),
  supplySortPower: (usedAssetData?.isSupplied ? 1 : 0) + (usedAssetData?.isBorrowed ? -1 : 0),
  sortIndex: assetData.sortIndex,
  interestMode: usedAssetData?.interestMode || '',
  collateralFactor: (eModeCategory === 0 || new Dec(assetData.eModeCategoryData.collateralFactor).eq(0))
    ? assetData.collateralFactor
    : assetData.eModeCategoryData.collateralFactor,
});

const compoundAssets = state => compoundCollateralAssetsOptions.map((option) => {
  const usedAssetData = state.compoundManage.usedAssets[option.value];
  const assetData = state.compoundManage.assetsData[option.value];
  return formatOption(option, usedAssetData, assetData);
}).sort(({ sortIndex: aSortIndex }, { sortIndex: bSortIndex }) => aSortIndex - bSortIndex);

const aaveAssets = state => (state.aaveManage.selectedMarket.value === 'v1' ? aaveCollateralAssetsOptions.map((option) => {
  const usedAssetData = state.aaveManage[state.general.walletType.value][state.aaveManage.selectedMarket.value].usedAssets[option.value];
  const assetData = state.aaveManage[state.aaveManage.selectedMarket.value].assetsData[option.value];
  return formatOption(option, usedAssetData, assetData);
}).sort(({ sortIndex: aSortIndex }, { sortIndex: bSortIndex }) => aSortIndex - bSortIndex)
  : state.aaveManage.selectedMarket.assets.map(option => ({
    value: option,
    cAsset: `a${option}`,
    label: option,
    address: getAssetInfo(option).address,
  })).map((option) => {
    const usedAssetData = state.aaveManage[state.general.walletType.value][state.aaveManage.selectedMarket.value].usedAssets[option.value];
    const assetData = state.aaveManage[state.aaveManage.selectedMarket.value].assetsData[option.value];
    const { eModeCategory } = state.aaveManage[state.general.walletType.value][state.aaveManage.selectedMarket.value];
    return formatOption(option, usedAssetData, assetData, state.aaveManage.selectedMarket.value === 'v3default' ? eModeCategory : 0);
  }).sort(({ sortIndex: aSortIndex }, { sortIndex: bSortIndex }) => aSortIndex - bSortIndex));

// sorted by amount used (suppliedUsd or borrowedUsd)
const assetsSorted = (state, protocol = 'compound') => {
  let assetsF = compoundAssets;
  if (protocol === 'aave') assetsF = aaveAssets;
  return assetsF(state).sort((a, b) => b.amountUsd - a.amountUsd);
};

export const suppliedAssetsOptions = (state, protocol) => assetsSorted(state, protocol).filter(asset => asset.isSupplied);
export const borrowedAssetsOptions = (state, protocol) => assetsSorted(state, protocol).filter(asset => asset.isBorrowed);

export const notSuppliedAssetsOptions = (state, protocol) => assetsSorted(state, protocol).filter(asset => !asset.isSupplied);
export const notBorrowedAssetsOptions = (state, protocol) => assetsSorted(state, protocol).filter(asset => !asset.isBorrowed);

// Sorting: borrowed, not used, supplied
export const borrowableAssetsOptions = (state, protocol) => assetsSorted(state, protocol).filter(a => a.canBeBorrowed).sort((a, b) => b.borrowSortPower - a.borrowSortPower);

// Sorting: supplied, not used, borrowed
export const suppliableAssetsOptions = (state, protocol) => assetsSorted(state, protocol).filter(a => a.canBeSupplied).sort((a, b) => b.supplySortPower - a.supplySortPower);
