import * as rflxTypes from '../../actionTypes/reflexerActionTypes/reflexerActionTypes';
import { CONNECT_PROVIDER_SUCCESS } from '../../actionTypes/generalActionTypes';

const INITIAL_STATE = {
  safes: {},
  safesLoading: false,
  safesError: '',
  selectedSafeId: null,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case rflxTypes.RFL_GET_SAFES_REQUEST:
      return {
        ...state,
        safesLoading: true,
        safesError: '',
      };

    case rflxTypes.RFL_GET_SAFES_ERROR:
      return {
        ...state,
        safesLoading: false,
        safesError: payload.errorMessage,
      };

    case rflxTypes.RFL_GET_SAFES_SUCCESS:
      return {
        ...state,
        safes: {
          ...state.safes,
          ...payload.safes,
        },
        safesLoading: false,
      };

    case rflxTypes.RFL_SELECT_SAFE:
      return {
        ...state,
        selectedSafeId: payload.selectedSafeId,
      };

    case CONNECT_PROVIDER_SUCCESS:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};
