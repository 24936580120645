import React from 'react';
import PropTypes from 'prop-types';
import withErrorFallback from '../ErrorFallback/ErrorFallback';


const CrashModalWrapper = ({ children }) => (
  <div>
    { children }
  </div>
);

CrashModalWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withErrorFallback(CrashModalWrapper);
