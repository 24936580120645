/* eslint-disable */
import React from 'react';
import { v4 as uuid } from 'uuid';

export default function AvalancheIcon(props) {
  const id = `avalanche_icon${uuid()}`;
  const fill = `url(#${id})`;
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z" fill={fill}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M8.56805 21H11.3823C11.8817 21 12.1314 21 12.3564 20.9367C12.6012 20.8678 12.8252 20.7448 13.0099 20.578C13.1796 20.4248 13.3035 20.2198 13.5511 19.8098L17.2357 13.7111C17.4798 13.3071 17.602 13.1049 17.6555 12.8919C17.7137 12.6599 17.7137 12.4182 17.6552 12.1862C17.6014 11.9732 17.4791 11.7713 17.2347 11.3675V11.3674H17.2346L15.8136 9.01979C15.4774 8.46435 15.3092 8.18663 15.0957 8.08347C14.8651 7.972 14.5917 7.97219 14.3613 8.08398C14.1479 8.18744 13.9802 8.46539 13.6448 9.02129L7.48323 19.2345L7.48312 19.2348H7.48311C7.15263 19.7826 6.98738 20.0564 7.00075 20.2811C7.0152 20.5239 7.15141 20.7456 7.36735 20.8777C7.56716 21 7.90079 21 8.56805 21ZM18.1597 20.9998H21.4225C22.0968 20.9998 22.434 20.9998 22.6345 20.8763C22.8511 20.7429 22.9868 20.5193 22.9994 20.2751C23.0111 20.0493 22.841 19.7741 22.5008 19.2236L20.8667 16.5799L20.8666 16.5797C20.5313 16.0372 20.3636 15.7659 20.1516 15.6645C19.9226 15.5549 19.6519 15.555 19.423 15.6649C19.2112 15.7667 19.0439 16.0383 18.7093 16.5813L17.0806 19.2252C16.7417 19.7752 16.5723 20.0502 16.5842 20.2759C16.5969 20.5199 16.7328 20.7433 16.9494 20.8765C17.1497 20.9998 17.4865 20.9998 18.1597 20.9998Z" fill="white"/>
      <defs>
        <linearGradient id={id} x1="15" y1="0" x2="15" y2="30" gradientUnits="userSpaceOnUse">
          <stop stopColor="#E74141"/>
          <stop offset="1" stopColor="#A02F2F"/>
        </linearGradient>
      </defs>
    </svg>
  );
}
