import dfs from '@defisaver/sdk';
import { assetAmountInWei } from '@defisaver/tokens';
import Dec from 'decimal.js';
import RecipeAction from '../RecipeAction';
import { AssetAmount, Source } from '../../components/Recipes/RecipeCreator/inputTypes';
import { generateAfterValues } from '../../actions/liquityActions/liquityManageAfterValues';
import {
  findInsertPosition,
  getBorrowingFeePercentage,
  getTroveInfo,
} from '../../services/liquityServices/liquityService';
import BorrowIcon from '../recipeIcons/Borrow.svg';
import { ethToWei } from '../../services/ethService';
import { formatNumber } from '../../services/utils';

export default class LiquityBorrowAction extends RecipeAction {
  static prettyName = 'Borrow LUSD from Trove';

  static protocol = 'liquity';

  static protocolPrettyName = 'Liquity';

  static description = 'Borrows additional LUSD from an existing Trove.';

  constructor(debt = '', to = 'wallet') {
    super();
    this.inputs = [
      new AssetAmount('Debt', debt, 'LUSD'),
      new Source('To', to, true),
    ];
    this.output = new AssetAmount('output', 0, 'LUSD');
  }

  async getAfterValues(_balances = {}, returnValues = [], actions = [], _positions = {}, getState) {
    const positions = { ..._positions };
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const {
      liquity: { proxy },
      liquityStaking: { proxy: stakingProxy },
    } = getState();
    if (!positions.liquity) positions.liquity = { ...proxy, ...stakingProxy };
    const amount = args[0] || '0';
    const { afterPosition, balances, returnValue } = await generateAfterValues({
      amount,
      to: args[1],
    }, {
      vault: positions.liquity,
      assets: getState().assets,
      account: getState().general.account,
      proxyAddress: getState().maker.proxyAddress,
    },
    _balances);
    positions.liquity = afterPosition;
    this.output.value = returnValue;
    return { returnValue: this.output, balances, positions };
  }

  async toDfsAction(getState, actions, returnValues, positions, actionBeforeState, actionCurrentState) {
    const {
      general: { account },
      maker: { proxyAddress },
    } = getState();
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const to = this.inputs[1].value === 'wallet' ? account : proxyAddress;
    const trove = actionCurrentState?.positions?.liquity || await getTroveInfo(proxyAddress);
    const amount = assetAmountInWei(args[0], 'LUSD');
    const { lowerHint, upperHint } = await findInsertPosition(assetAmountInWei(trove.collateral, 'WETH'), assetAmountInWei(trove.debtInAsset, 'LUSD'), proxyAddress);
    const { amount: maxFeePercentage, showWarning } = await getBorrowingFeePercentage();
    return new dfs.actions.liquity.LiquityBorrowAction(ethToWei(maxFeePercentage), amount, to, upperHint, lowerHint);
  }

  _getPrettyName(actionCalls, actions) {
    const args = this.mapReturnValuesToArgs(actionCalls.map(a => a.returnValue), actions);
    return `Borrow ${formatNumber(args[0])} LUSD from Trove`;
  }

  static getIcon() {
    return BorrowIcon;
  }
}
