import { getSafeInfo, getUserSafes, getLsSafe } from 'services/reflexerServices/reflexerService';
import { captureException } from '../../sentry';
import { addToLsState } from '../../services/localStorageService';
import * as rflxTypes from '../../actionTypes/reflexerActionTypes/reflexerActionTypes';

export const getSafeInfoAction = (selectedSafe) => async (dispatch) => {
  dispatch({ type: rflxTypes.RFL_GET_SAFES_REQUEST });
  try {
    const safeData = await getSafeInfo(selectedSafe);
    dispatch({ type: rflxTypes.RFL_GET_SAFES_SUCCESS, payload: { safes: { [selectedSafe.id]: safeData } } });
  } catch (err) {
    captureException(err);
    dispatch({ type: rflxTypes.RFL_GET_SAFES_ERROR, payload: { errorMessage: err.message } });
  }
};

export const switchSafe = (selectedSafe, updateData = false) => async (dispatch, getState) => {
  try {
    if (updateData) dispatch(getSafeInfoAction(selectedSafe));
    dispatch({ type: rflxTypes.RFL_SELECT_SAFE, payload: { selectedSafeId: selectedSafe.id } });
    addToLsState({ account: getState().general.account, selectedSafe: selectedSafe.id });
  } catch (err) {
    captureException(err);
  }
};

export const updateSafeInfoAction = () => async (dispatch, getState) => {
  const {
    reflexer: { safes, selectedSafeId },
  } = getState();
  const safe = safes[selectedSafeId];
  return dispatch(getSafeInfoAction(safe));
};

export const getUserSafesAction = (userAddress = '', userProxy = '') => async (dispatch, getState) => {
  dispatch({ type: rflxTypes.RFL_GET_SAFES_REQUEST });
  try {
    const {
      general: { account },
      maker: { proxyAddress },
      reflexer: { selectedSafeId },
    } = getState();

    let fetchingAddress = account;
    let fetchingProxy = proxyAddress;

    if (userAddress) {
      fetchingAddress = userAddress;
      fetchingProxy = userProxy;
    }

    // console.time('getUserSafes');
    if (!fetchingProxy) {
      dispatch({ type: rflxTypes.RFL_GET_SAFES_SUCCESS, payload: { safes: {} } });
      return {};
    }
    const safes = await getUserSafes(fetchingProxy);
    // console.timeEnd('getUserSafes');
    // console.time('getUserSafesIfMultipleCollTypesExist');
    // await getUserSafesIfMultipleCollTypesExist(proxyAddress);
    // console.timeEnd('getUserSafesIfMultipleCollTypesExist');
    if (Object.keys(safes).length) {
      const safe = getLsSafe(fetchingAddress, safes);
      if (safe) {
        dispatch(switchSafe(safe, false));
      } else if (!selectedSafeId || !Object.values(safes).find((s) => s.id === selectedSafeId)) {
        const lastSafe = Object.values(safes)[Object.values(safes).length - 1];
        dispatch(switchSafe(lastSafe, false));
      }
    }
    dispatch({ type: rflxTypes.RFL_GET_SAFES_SUCCESS, payload: { safes } });
    return safes;
  } catch (err) {
    captureException(err);
    dispatch({ type: rflxTypes.RFL_GET_SAFES_ERROR, payload: { errorMessage: err.message } });
  }
};
