import React from 'react';
import { v4 as uuid } from 'uuid';

export default function SushiswapIcon(props) {
  const id = `sushiswap_icon${uuid()}`;
  const fill = `url(#${id})`;

  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z" fill={fill} />
      <path fillRule="evenodd" clipRule="evenodd" d="M22.4835 16.7288C22.4759 16.7394 20.9829 18.6024 19.9041 19.9484C19.296 20.7072 18.8195 21.3017 18.8151 21.3075C17.731 22.7115 14.4104 22.0987 11.3432 19.9147C8.28005 17.7336 6.61501 14.8011 7.57878 13.3155C7.58581 13.3046 8.77236 11.823 9.79432 10.5469L9.7952 10.5458C10.5663 9.58295 11.2435 8.73739 11.2491 8.72942C12.2935 7.26266 15.6551 7.86432 18.7574 10.0733C21.8596 12.2822 23.5279 15.262 22.4835 16.7288ZM21.9552 16.4514C20.9983 17.7953 17.9183 17.244 15.0759 15.2201C12.2336 13.1962 10.7051 10.4661 11.662 9.12219C12.6189 7.7783 15.6988 8.32956 18.5412 10.3535C21.3836 12.3774 22.9121 15.1075 21.9552 16.4514ZM15.9265 14.0489C17.4682 15.1466 19.1386 15.4456 19.6576 14.7167C20.1767 13.9878 19.3476 12.507 17.806 11.4093C16.2644 10.3116 14.5939 10.0126 14.0749 10.7415C13.5559 11.4704 14.3849 12.9512 15.9265 14.0489Z" fill="white" />
      <defs>
        <linearGradient id={id} x1="15" y1="0" x2="15" y2="30" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFC564" />
          <stop offset="1" stopColor="#FFA25B" />
        </linearGradient>
      </defs>
    </svg>
  );
}
