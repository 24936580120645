import Dec from 'decimal.js';
import dfs, { Recipe } from '@defisaver/sdk';
import { getAssetInfo, MAXUINT } from '@defisaver/tokens';
import callTx from '../txService';
import { LiquityBorrowerOperationsContract, LiquityTroveManagerContract } from '../contractRegistryService';
import {
  findInsertPosition,
  findInsertPositionFromContract,
  getApproxHint,
  getExpectedFee,
  getNumTrials,
  getRedemptionHints,
  getTroveInfo,
} from './liquityService';
import { ethToWei } from '../ethService';
import { callRecipeViaProxy } from '../contractCallService';
import { isWalletTypeProxy } from '../utils';
import { getExchangeOrder } from '../exchangeServiceV3';
import { LIQUITY_LIQ_RESERVE_LUSD } from '../../constants/liquity';
import { ZERO_ADDRESS } from '../../constants/general';

/**
 *
 * @param getState
 * @param sendTxFunc
 * @param collateralAmount
 * @param maxFeePercentage
 * @param debtAmount
 * @param priceForAmount
 * @param leveraged
 * @param slippage
 * @param useFlashloan
 * @param flProtocol
 * @return {Promise<unknown>}
 */
export const openTrove = async (getState, sendTxFunc, collateralAmount, maxFeePercentage, debtAmount, priceForAmount, leveraged, slippage, useFlashloan, flProtocol) => {
  try {
    const {
      general: { account, accountType },
      maker: { proxyAddress },
      liquityStrategies: { liquitySubscribedStrategies },
    } = getState();
    const collateralAmountWei = ethToWei(collateralAmount);
    const maxFeePercentageWei = ethToWei(maxFeePercentage);
    const debtAmountWei = ethToWei(debtAmount);
    const debtFee = await getExpectedFee(debtAmountWei);
    const expectedDebtWei = new Dec(debtAmountWei).add(debtFee).add(ethToWei(LIQUITY_LIQ_RESERVE_LUSD)).toString();
    const strategies = liquitySubscribedStrategies.filter(({ isEnabled }) => isEnabled);

    let actions;
    if (leveraged) {
      const { orderData, value } = await getExchangeOrder('LUSD', 'WETH', debtAmount, priceForAmount.toString(), slippage, account, false, false, true);

      const minPrice = new Dec(priceForAmount).mul(100 - slippage).div(100).toString();
      const collateralFromDebtWei = ethToWei(new Dec(debtAmount).times(minPrice).toString());
      const newCollateralAmountWei = new Dec(collateralAmountWei).plus(collateralFromDebtWei).toString();
      const { upperHint: supplyUpperHint, lowerHint: supplyLowerHint } = await findInsertPosition(newCollateralAmountWei, expectedDebtWei, proxyAddress);

      if (useFlashloan) {
        actions = [
          new dfs.actions.flashloan.BalancerFlashLoanAction([getAssetInfo('WETH').address], [collateralFromDebtWei], ZERO_ADDRESS, []),
          new dfs.actions.basic.WrapEthAction(collateralAmountWei),
          new dfs.actions.liquity.LiquityOpenAction(maxFeePercentageWei, newCollateralAmountWei, debtAmountWei, proxyAddress, proxyAddress, supplyUpperHint, supplyLowerHint),
          new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress),
          new dfs.actions.basic.SendTokenAction(getAssetInfo('WETH').address, dfs.actionAddresses().FLBalancer, collateralFromDebtWei),
          new dfs.actions.basic.UnwrapEthAction(MAXUINT, proxyAddress),
        ];
      } else {
        const { upperHint, lowerHint } = await findInsertPosition(collateralAmountWei, expectedDebtWei, proxyAddress);
        actions = [
          new dfs.actions.basic.WrapEthAction(collateralAmountWei),
          new dfs.actions.liquity.LiquityOpenAction(maxFeePercentageWei, '$1', debtAmountWei, proxyAddress, proxyAddress, upperHint, lowerHint),
          new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value),
          new dfs.actions.liquity.LiquitySupplyAction('$3', proxyAddress, supplyUpperHint, supplyLowerHint),
        ];
      }
    } else {
      const { upperHint, lowerHint } = await findInsertPosition(collateralAmountWei, expectedDebtWei, proxyAddress);
      actions = [
        new dfs.actions.basic.WrapEthAction(collateralAmountWei),
        new dfs.actions.liquity.LiquityOpenAction(maxFeePercentageWei, '$1', debtAmountWei, proxyAddress, account, upperHint, lowerHint),
      ];
    }
    // handling for cases where user closes liquity trove on recipe creator/shifter without deactivating strategy
    strategies.forEach(({ subId }) => actions.push(new dfs.actions.basic.ToggleSubAction(subId, false)));

    const recipe = new Recipe(`LiquityOpen${leveraged ? 'Leveraged' : ''}Recipe`, actions);
    console.log(recipe);
    return callRecipeViaProxy(accountType, sendTxFunc, proxyAddress, account, recipe);
  } catch (e) {
    console.log(e);
  }
};

/**
 *
 * @param getState
 * @param sendTxFunc
 * @param trove
 * @param addAmount
 * @return {Promise<unknown>}
 */
export const addCollateral = async (getState, sendTxFunc, trove, addAmount) => {
  const { general: { account, accountType, walletType }, maker: { proxyAddress } } = getState();
  const isProxy = isWalletTypeProxy(walletType);
  const borrowerOperationsContract = LiquityBorrowerOperationsContract();
  const collateralAmountWei = ethToWei(trove.collateral);
  const debtAmountWei = ethToWei(trove.debtInAsset);
  const addAmountWei = ethToWei(addAmount);

  const { upperHint, lowerHint } = await findInsertPosition(new Dec(collateralAmountWei).plus(addAmountWei).toString(), debtAmountWei, isProxy ? proxyAddress : account);

  if (isProxy) {
    const actions = [
      new dfs.actions.basic.WrapEthAction(addAmountWei),
      new dfs.actions.liquity.LiquitySupplyAction('$1', proxyAddress, upperHint, lowerHint),
    ];
    const recipe = new Recipe('LiquitySupplyAction', actions);
    return callRecipeViaProxy(accountType, sendTxFunc, proxyAddress, account, recipe);
  }
  const params = [upperHint, lowerHint];
  return callTx(accountType, 'not used', sendTxFunc, borrowerOperationsContract, 'addColl', params, { from: account, value: addAmountWei }, 'addColl');
};

/**
 *
 * @param getState
 * @param sendTxFunc
 * @param trove
 * @param withdrawAmount
 * @return {Promise<unknown>}
 */
export const withdraw = async (getState, sendTxFunc, trove, withdrawAmount) => {
  const { general: { account, accountType, walletType }, maker: { proxyAddress } } = getState();
  const isProxy = isWalletTypeProxy(walletType);
  const borrowerOperationsContract = LiquityBorrowerOperationsContract();
  const collateralAmountWei = ethToWei(trove.collateral);
  const withdrawAmountWei = ethToWei(withdrawAmount);
  const debtAmountWei = ethToWei(trove.debtInAsset);

  const { upperHint, lowerHint } = await findInsertPosition(new Dec(collateralAmountWei).minus(withdrawAmountWei).toString(), debtAmountWei, isProxy ? proxyAddress : account);

  if (isProxy) {
    const actions = [
      new dfs.actions.liquity.LiquityWithdrawAction(withdrawAmountWei, proxyAddress, upperHint, lowerHint),
      new dfs.actions.basic.UnwrapEthAction('$1', account),
    ];
    const recipe = new Recipe('LiquityWithdrawAction', actions);
    return callRecipeViaProxy(accountType, sendTxFunc, proxyAddress, account, recipe);
  }
  const params = [withdrawAmountWei, upperHint, lowerHint];
  return callTx(accountType, 'not used', sendTxFunc, borrowerOperationsContract, 'withdrawColl', params, { from: account }, 'withdrawColl');
};

/**
 *
 * @param getState
 * @param sendTxFunc
 * @param trove
 * @param maxFeePercentage
 * @param generateAmount
 * @return {Promise<unknown>}
 */
export const generate = async (getState, sendTxFunc, trove, maxFeePercentage, generateAmount) => {
  const { general: { account, accountType, walletType }, maker: { proxyAddress } } = getState();
  const isProxy = isWalletTypeProxy(walletType);
  const borrowerOperationsContract = LiquityBorrowerOperationsContract();
  const collateralAmountWei = ethToWei(trove.collateral);
  const maxFeePercentageWei = ethToWei(maxFeePercentage);
  const generateAmountWei = ethToWei(generateAmount);
  const debtAmountWei = ethToWei(trove.debtInAsset);

  const { upperHint, lowerHint } = await findInsertPosition(collateralAmountWei, new Dec(debtAmountWei).plus(generateAmountWei).toString(), isProxy ? proxyAddress : account);

  if (isProxy) {
    const actions = [
      new dfs.actions.liquity.LiquityBorrowAction(maxFeePercentageWei, generateAmountWei, account, upperHint, lowerHint),
    ];
    const recipe = new Recipe('LiquityBorrowAction', actions);
    return callRecipeViaProxy(accountType, sendTxFunc, proxyAddress, account, recipe);
  }
  const params = [maxFeePercentageWei, generateAmountWei, upperHint, lowerHint];
  return callTx(accountType, 'not used', sendTxFunc, borrowerOperationsContract, 'withdrawLUSD', params, { from: account }, 'withdrawLUSD');
};

/**
 *
 * @param getState
 * @param sendTxFunc
 * @param trove
 * @param paybackAmount
 * @return {Promise<unknown>}
 */
export const payback = async (getState, sendTxFunc, trove, paybackAmount) => {
  const { general: { account, accountType, walletType }, maker: { proxyAddress } } = getState();
  const isProxy = isWalletTypeProxy(walletType);
  const borrowerOperationsContract = LiquityBorrowerOperationsContract();
  const collateralAmountWei = ethToWei(trove.collateral);
  const paybackAmountWei = ethToWei(paybackAmount);
  const debtAmountWei = ethToWei(trove.debtInAsset);

  const { upperHint, lowerHint } = await findInsertPosition(collateralAmountWei, new Dec(debtAmountWei).minus(paybackAmountWei).toString(), isProxy ? proxyAddress : account);

  if (isProxy) {
    const actions = [
      new dfs.actions.liquity.LiquityPaybackAction(paybackAmountWei, account, upperHint, lowerHint),
    ];
    const recipe = new Recipe('LiquityPaybackAction', actions);
    return callRecipeViaProxy(accountType, sendTxFunc, proxyAddress, account, recipe);
  }
  const params = [paybackAmountWei, upperHint, lowerHint];
  return callTx(accountType, 'not used', sendTxFunc, borrowerOperationsContract, 'repayLUSD', params, { from: account }, 'repayLUSD');
};

/**
 *
 * @param getState
 * @param sendTxFunc
 * @return {Promise<Promise|*>}
 */
export const closeTrove = async (getState, sendTxFunc) => {
  const {
    general: { account, accountType, walletType },
    maker: { proxyAddress },
    liquityStrategies: { liquitySubscribedStrategies },
  } = getState();
  const isProxy = isWalletTypeProxy(walletType);
  const borrowerOperationsContract = LiquityBorrowerOperationsContract();
  const strategies = liquitySubscribedStrategies.filter(({ isEnabled }) => isEnabled);

  if (isProxy) {
    // TODO liquity - add close to asset/pay debt using coll option
    const actions = [
      new dfs.actions.liquity.LiquityCloseAction(account, proxyAddress),
      new dfs.actions.basic.UnwrapEthAction(MAXUINT, account),
    ];
    strategies.forEach(({ subId }) => actions.push(new dfs.actions.basic.ToggleSubAction(subId, false)));
    const recipe = new Recipe('LiquityCloseAction', actions);
    return callRecipeViaProxy(accountType, sendTxFunc, proxyAddress, account, recipe);
  }
  const params = [];
  return callTx(accountType, 'not used', sendTxFunc, borrowerOperationsContract, 'closeTrove', params, { from: account }, 'closeTrove');
};

/**
 *
 * @param getState
 * @param sendTxFunc
 * @return {Promise<Promise|*>}
 */
export const claimCollateral = async (getState, sendTxFunc) => {
  const { general: { account, accountType, walletType }, maker: { proxyAddress } } = getState();
  const isProxy = isWalletTypeProxy(walletType);
  const borrowerOperationsContract = LiquityBorrowerOperationsContract();
  if (isProxy) {
    const actions = [
      new dfs.actions.liquity.LiquityClaimAction(proxyAddress),
      new dfs.actions.basic.UnwrapEthAction(MAXUINT, account),
    ];
    const recipe = new Recipe('LiquityOpenAction', actions);
    return callRecipeViaProxy(accountType, sendTxFunc, proxyAddress, account, recipe);
  }
  return callTx(accountType, 'not used', sendTxFunc, borrowerOperationsContract, 'claimCollateral', [], { from: account }, 'claimCollateral');
};

/**
 *
 * @param getState {function}
 * @param sendTxFunc {function}
 * @param lusdAmount {string}
 * @param maxFeePercentage {string}
 * @return {Promise<Promise|*>}
 */
export const redeemCollateral = async (getState, sendTxFunc, lusdAmount, maxFeePercentage) => {
  try {
    const { general: { account, accountType } } = getState();
    const liquityTroveManagerContract = LiquityTroveManagerContract();
    const lusdAmountWei = ethToWei(lusdAmount);
    const maxFeeWei = ethToWei(maxFeePercentage);
    const { assetPrice } = await getTroveInfo(account);
    const ethPriceWei = ethToWei(assetPrice);

    const { 0: firstRedemptionHint, 1: partialRedemptionNewICR, 2: truncatedLUSDAmount } = await getRedemptionHints(lusdAmountWei, ethPriceWei, 50); // iterations ?
    const numTrials = await getNumTrials();
    const { hintAddress: approxPartialRedemptionHint } = await getApproxHint(partialRedemptionNewICR, numTrials, 42);
    const exactPartialRedemptionHint = await findInsertPositionFromContract(partialRedemptionNewICR, approxPartialRedemptionHint, approxPartialRedemptionHint);

    const params = [truncatedLUSDAmount, firstRedemptionHint, exactPartialRedemptionHint[0], exactPartialRedemptionHint[1], partialRedemptionNewICR, 0, maxFeeWei];
    return callTx(accountType, 'not used', sendTxFunc, liquityTroveManagerContract, 'redeemCollateral', params, { from: account }, 'redeemCollateral');
  } catch (err) {
    console.log(err);
  }
};
