import Dec from 'decimal.js';
import { change } from 'redux-form';
import t from 'translate';
import { captureException } from 'sentry';
import { assetAmountInWei, getAssetInfo } from '@defisaver/tokens';
import { aaveCollateralAssets } from 'constants/assets';
import {
  activateUnstakeCooldown, callSaverProxyContract, claimRewards, getAaveAccountData, getAaveMarketsData, unstakeAave,
  getStakeAaveBalanceAndCooldown, importLoan, migrateLendToAave, setAssetAsCollateral, stakeAave, swapInterestRateMode,
  aaveManageTrackEventWrapper,
} from 'services/aaveServices/aaveManageService';
import {
  ethToWeth, formatNumber, getAddressForWalletType, isWalletTypeProxy, requireOwner, wait, getEnsAddress, isAddress,
  formatAcc, resetFields,
} from 'services/utils';
import { addToLsState } from 'services/localStorageService';
import { calcBorrowValuesWithoutColl } from 'services/moneymarketCommonService';
import { getBestExchangePrice, getSlippageThreshold } from 'services/exchangeServiceV3';
import { flProtocolAndFeeFor, transferErc20 } from 'services/assetsService';

import { calculateTradeSizeImpact } from 'services/makerServices/makerManageServices/makerManageService';
import { callMultipleTxAction, sendTx } from '../txNotificationActions';
import { trackEvent } from '../../services/analyticsService';
import {
  addApproveTxIfNeeded, approveAddressOnAssetAction, approveNewAaveTokenAction, approveNewAaveTokenActionIfNeeded,
  approveTypeToLabelMap, getAssetsBalancesAction, getAssetsPricesAction,
} from '../assetsActions';
import { APPROVE_TYPE_AAVE, APPROVE_TYPE_DS_PROXY } from '../../actionTypes/assetsActionTypes';
import {
  closeModal, confirmViaModal, openAaveCollateralRequiredModal, openAaveProxyMigrationModal, toggleModal,
} from '../modalActions';
import {
  AaveWETHAdapterAddress, LendToAaveMigratorAddress, stkAAVEAddress, getConfigContractAddress,
} from '../../services/contractRegistryService';
import { createDSProxyAction } from '../makerActions/makerActions';
import { getAaveSaverSubscribedGraphData } from './aaveSaverActions';
import {
  addApproveWethAdapterIfNeeded, approveWethAdapter, borrowV2, claimStkAaveRewards, getAaveAccountDataV2,
  getAaveMarketsDataV2, getAaveV2Action, getAaveV2Recipe, getATokenAddress, paybackV2, setAssetAsCollateralV2, supplyV2,
  swapInterestRateModeV2, withdrawV2,
} from '../../services/aaveServices/aaveManageServiceV2';
import * as aaveManageTypes from '../../actionTypes/aaveActionTypes/aaveManageActionTypes';

import { AAVE_VERSIONS } from '../../constants/aaveMarkets';
import { changeWalletType } from '../generalActions';
import { WALLET_TYPES_OPTIONS } from '../../constants/general';
import { AAVE_BORROW_MODAL } from '../../components/Modals/modalTypes';

import { setAfterValue } from './aaveManageAfterValuesV2';
import {
  getMaxBoostAction, getMaxBorrowAction, getMaxPaybackAction, getMaxRepayAction, getMaxSupplyAction, getMaxWithdrawAction,
} from './aaveManageMaxGettersV2';
import * as aaveRecipes from '../../recipes/aaveRecipes';
import * as aaveRecipesV3 from '../../recipes/aaveRecipesV3';
import { callRecipeViaProxy } from '../../services/contractCallService';
import {
  addApproveWethAdapterIfNeededV3, approveWethAdapterV3, borrowV3, getAaveAccountDataV3, getAaveMarketsDataV3,
  getAaveV3Action, getAaveV3Recipe, paybackV3, setAssetAsCollateralV3, setEMode, supplyV3, swapInterestRateModeV3,
  withdrawV3,
} from '../../services/aaveServices/aaveManageServiceV3';
import { getDashboardInputs, addApproveForActionWithSelectIfNeeded } from '../dashboardActions';
import config from '../../config/config.json';

import { formName } from '../../components/DashboardActions/dashboardActionUtils';

export const setAaveBorrowInterestRateAction = (selectedOption) => (dispatch) => {
  dispatch({ type: aaveManageTypes.SET_AAVE_BORROW_INTEREST_RATE_SUCCESS, payload: selectedOption });
};

export const resetAaveBorrowInterestRateAction = () => (dispatch) => {
  dispatch({ type: aaveManageTypes.SET_AAVE_BORROW_INTEREST_RATE_SUCCESS, payload: {} });
};

/**
 *
 * @param {String | Number} borrowAmount
 * @param {String} borrowAsset
 * @param {Object} usedAssets
 * @param {String} market
 * @return {function(*): { value: Number, name: String } | boolean}
 */
export const chooseAaveBorrowInterestRateMode = (borrowAmount, borrowAsset, usedAssets, market = 'v2default') => async (dispatch, getState) => {
  let resolve;
  const modalPromise = new Promise((res) => { resolve = res; });
  const { assetsData } = getState().aaveManage[market];
  dispatch(
    toggleModal(
      AAVE_BORROW_MODAL,
      {
        width: 420, resolve, closeModal, borrowAmount, borrowAsset, usedAssets, assetsData,
      },
      true,
    ),
  );
  const result = await modalPromise;
  await dispatch(closeModal());
  await wait(500);
  return result;
};

// DATA GETTERS START

/**
 * Gets assets prices
 * @return {function(...[*]=)}
 */
export const fetchAaveAssetsData = () => async (dispatch, getState) => {
  const { selectedMarket } = getState().aaveManage;

  dispatch({ type: aaveManageTypes.FETCH_AAVE_ASSETS_DATA_REQUEST, selectedMarket: selectedMarket.value });

  try {
    await dispatch(getAssetsPricesAction(selectedMarket.value === 'v1' ? aaveCollateralAssets.map(a => a.underlyingAsset) : selectedMarket.assets, 'aave', selectedMarket));
    await dispatch(getAssetsBalancesAction(selectedMarket.value === 'v1' ? aaveCollateralAssets.map(a => a.underlyingAsset) : selectedMarket.assets));

    let marketsData = [];
    if (selectedMarket.value === 'v1') marketsData = await getAaveMarketsData(aaveCollateralAssets.map(a => getAssetInfo(a.underlyingAsset).address));
    if (selectedMarket.value === 'v2default') marketsData = await getAaveMarketsDataV2(selectedMarket);
    if (selectedMarket.value === 'v3default') marketsData = await getAaveMarketsDataV3(selectedMarket);

    const payload = {};
    // Sort by market size
    marketsData
      .sort((a, b) => {
        const { assets } = getState();

        const aMarket = new Dec(assets[a.symbol].aavePrice).times(a.totalSupply).toString();
        const bMarket = new Dec(assets[b.symbol].aavePrice).times(b.totalSupply).toString();

        return new Dec(bMarket).minus(aMarket).toNumber();
      })
      .forEach((market, i) => { payload[market.symbol] = { ...market, sortIndex: i }; });

    // if (payloadMarket !== selectedMarket) dispatch({ type: aaveManageTypes.SET_SELECTED_MARKET_ACTION, payload: payloadMarket });
    dispatch({ type: aaveManageTypes.FETCH_AAVE_ASSETS_DATA_SUCCESS, payload, selectedMarket: selectedMarket.value });
  } catch (err) {
    dispatch({ type: aaveManageTypes.FETCH_AAVE_ASSETS_DATA_FAILURE, payload: err.message, selectedMarket: selectedMarket.value });
    captureException(err);
  }
};

/**
 * Fetches Aave position data for selected wallet type
 * @return {function(...[*]=)}
 */
export const fetchAaveAccountData = (market = 'v1') => async (dispatch, getState) => {
  const walletType = getState().general.walletType.value;
  const { selectedMarket } = getState().aaveManage;

  const payloadMarket = selectedMarket.value || market;

  try {
    dispatch({ type: aaveManageTypes.FETCH_AAVE_ACCOUNT_DATA_REQUEST, walletType, selectedMarket: payloadMarket });
    const address = getAddressForWalletType(getState);
    const { assets, aaveManage: { selectedMarket } } = getState();
    const { assetsData } = getState().aaveManage[selectedMarket.value];
    let payload = [];
    if (payloadMarket === 'v1') payload = await getAaveAccountData(address, assetsData, assets);
    if (payloadMarket === 'v2default') payload = await getAaveAccountDataV2(address, assetsData, assets, selectedMarket);
    if (payloadMarket === 'v3default') payload = await getAaveAccountDataV3(address, assetsData, assets, selectedMarket);

    dispatch({
      type: aaveManageTypes.FETCH_AAVE_ACCOUNT_DATA_SUCCESS, payload, walletType, selectedMarket: payloadMarket,
    });
    if (payload?.isSubscribedToAutomation) dispatch(getAaveSaverSubscribedGraphData());
  } catch (err) {
    dispatch({
      type: aaveManageTypes.FETCH_AAVE_ACCOUNT_DATA_FAILURE, payload: err.message, walletType, selectedMarket: payloadMarket,
    });
    captureException(err);
  }
};

/**
 * Fetches all data needed for the AaveManage page
 *
 * @return {Function}
 */
export const fetchAaveManageData = () => async (dispatch) => {
  dispatch({ type: aaveManageTypes.FETCH_AAVE_MANAGE_DATA_REQUEST });

  try {
    await dispatch(fetchAaveAssetsData());
    await dispatch(fetchAaveAccountData());

    dispatch({ type: aaveManageTypes.FETCH_AAVE_MANAGE_DATA_SUCCESS });
  } catch (err) {
    dispatch({ type: aaveManageTypes.FETCH_AAVE_MANAGE_DATA_FAILURE, payload: err.message });
    captureException(err);
  }
};

// DATA GETTERS END

// ACTIONS EXECUTORS START

export const aaveBoostAction = (inputAmount, closeModal) => async (dispatch, getState) => {
  const { general: { walletType, account }, maker: { proxyAddress } } = getState();
  if (!isWalletTypeProxy(walletType)) return dispatch(openAaveProxyMigrationModal());

  trackEvent('aaveManage', 'boost');
  dispatch({ type: aaveManageTypes.AAVE_BOOST_REQUEST });

  const {
    aaveManage: {
      actionsSelectValues, selectedMarket, selectedBorrowInterestRate, boostAmount,
      boostExchangeRate, slippagePercent, useFl, flProtocol,
    },
    general: { accountType },
  } = getState();
  const borrowAsset = actionsSelectValues.boostBorrow.value;
  const supplyAsset = actionsSelectValues.boostSupply.value;

  const amount = assetAmountInWei(inputAmount, borrowAsset);

  const sendTxFunc = (promise) => {
    const amount = formatNumber(parseFloat(inputAmount), 2);
    const additionalInfo = {
      protocol: 'aave',
      firstToken: borrowAsset,
      firstAmount: inputAmount,
      secondToken: supplyAsset,
      secondAmount: boostAmount,
    };
    return sendTx(promise, `${t('common.boost')} ${amount} ${borrowAsset}`, 'aave', dispatch, getState, additionalInfo);
  };

  try {
    if (selectedMarket.value === 'v1') {
      await callSaverProxyContract(getState, amount, supplyAsset, borrowAsset, 'boost', sendTxFunc);
    } else if (selectedMarket.value === 'v2default') {
      const recipe = useFl
        ? await aaveRecipes.boostWithDebtLoan(supplyAsset, borrowAsset, inputAmount, selectedMarket.providerAddress, selectedBorrowInterestRate.value, boostExchangeRate, slippagePercent, proxyAddress, flProtocol)
        : await aaveRecipes.boost(supplyAsset, borrowAsset, inputAmount, selectedMarket.providerAddress, selectedBorrowInterestRate.value, boostExchangeRate, slippagePercent, proxyAddress);
      await callRecipeViaProxy(accountType, sendTxFunc, proxyAddress, account, recipe, 0, recipe.extraGas);
    } else if (selectedMarket.value === 'v3default') {
      const { assetsData } = getState().aaveManage[selectedMarket.value];
      const supplyId = assetsData[supplyAsset].assetId;
      const borrowId = assetsData[borrowAsset].assetId;
      const recipe = useFl
        ? await aaveRecipesV3.boostWithDebtLoanV3(supplyAsset, supplyId, borrowAsset, borrowId, inputAmount, selectedMarket, selectedBorrowInterestRate.value, boostExchangeRate, slippagePercent, proxyAddress, flProtocol)
        : await aaveRecipesV3.boostV3(supplyAsset, supplyId, borrowAsset, borrowId, inputAmount, selectedMarket, selectedBorrowInterestRate.value, boostExchangeRate, slippagePercent, proxyAddress);
      await callRecipeViaProxy(accountType, sendTxFunc, proxyAddress, account, recipe, 0, recipe.extraGas);
    }

    dispatch({ type: aaveManageTypes.AAVE_BOOST_SUCCESS });

    dispatch(setAfterValue(0, 'clear'));
    dispatch(change('aaveManageAdvancedForm', 'boostAmount', null));
    closeModal();
    await dispatch(fetchAaveAssetsData());
    await dispatch(fetchAaveAccountData());
    dispatch(getMaxBoostAction());
    trackEvent('aaveManage', 'boostSuccess');
  } catch (err) {
    dispatch({ type: aaveManageTypes.AAVE_BOOST_FAILURE, payload: err.message });
    captureException(err);
    trackEvent('aaveManage', 'boostError', err.message);
  }
};

export const aaveRepayAction = (inputAmount, closeModal) => async (dispatch, getState) => {
  const { general: { walletType, account, accountType }, maker: { proxyAddress } } = getState();
  if (!isWalletTypeProxy(walletType)) return dispatch(openAaveProxyMigrationModal());
  trackEvent('aaveManage', 'repay');
  dispatch({ type: aaveManageTypes.AAVE_REPAY_REQUEST });

  const {
    actionsSelectValues, selectedMarket, repayAmount, useFl, flProtocol, maxWithdraw,
    repayExchangeRate, slippagePercent, maxRepay,
  } = getState().aaveManage;
  const { usedAssets } = getState().aaveManage[walletType.value][selectedMarket.value];
  const withdrawAsset = actionsSelectValues.repayWithdraw.value;
  const paybackAsset = actionsSelectValues.repayPayback.value;

  const amount = assetAmountInWei(inputAmount, withdrawAsset);

  const sendTxFunc = (promise) => {
    const amount = formatNumber(parseFloat(inputAmount), 2);
    const additionalInfo = {
      protocol: 'aave',
      firstToken: withdrawAsset,
      firstAmount: inputAmount,
      secondToken: paybackAsset,
      secondAmount: repayAmount,
    };
    return sendTx(promise, `${t('common.repay')} ${amount} ${withdrawAsset}`, 'aave', dispatch, getState, additionalInfo);
  };

  const interestRate = actionsSelectValues.repayPayback.interestMode === 'both'
    ? (actionsSelectValues.repayPayback.additionalLabel === 'Stable' ? '1' : '2')
    : actionsSelectValues.repayPayback.interestMode;
  try {
    if (selectedMarket.value === 'v1') {
      await callSaverProxyContract(getState, amount, paybackAsset, withdrawAsset, 'repay', sendTxFunc);
    } else if (selectedMarket.value === 'v2default') {
      const borrowed = usedAssets[paybackAsset].borrowed;
      const supplied = usedAssets[withdrawAsset].supplied;
      const recipe = useFl
        ? await aaveRecipes.repayWithCollLoan(withdrawAsset, paybackAsset, inputAmount, selectedMarket.providerAddress, interestRate, repayExchangeRate, slippagePercent, proxyAddress, account, flProtocol, borrowed, maxWithdraw)
        : await aaveRecipes.repay(withdrawAsset, paybackAsset, inputAmount, selectedMarket.providerAddress, interestRate, repayExchangeRate, slippagePercent, proxyAddress, account, supplied, borrowed);
      await callRecipeViaProxy(accountType, sendTxFunc, proxyAddress, account, recipe, 0, recipe.extraGas);
    } else if (selectedMarket.value === 'v3default') {
      const borrowed = usedAssets[paybackAsset].borrowed;
      const supplied = usedAssets[withdrawAsset].supplied;
      const { assetsData } = getState().aaveManage[selectedMarket.value];
      const supplyId = assetsData[withdrawAsset].assetId;
      const borrowId = assetsData[paybackAsset].assetId;
      const recipe = useFl
        ? await aaveRecipesV3.repayV3WithCollLoan(withdrawAsset, supplyId, paybackAsset, borrowId, inputAmount, selectedMarket, interestRate, repayExchangeRate, slippagePercent, proxyAddress, account, flProtocol, borrowed, maxWithdraw)
        : await aaveRecipesV3.repayV3(withdrawAsset, supplyId, paybackAsset, borrowId, inputAmount, selectedMarket, interestRate, repayExchangeRate, slippagePercent, proxyAddress, account, supplied, borrowed);
      await callRecipeViaProxy(accountType, sendTxFunc, proxyAddress, account, recipe, 0, recipe.extraGas);
    }

    dispatch({ type: aaveManageTypes.AAVE_REPAY_SUCCESS });

    dispatch(setAfterValue(0, 'clear'));
    dispatch(change('aaveManageAdvancedForm', 'repayAmount', null));
    closeModal();
    await dispatch(fetchAaveAssetsData());
    await dispatch(fetchAaveAccountData());
    dispatch(getMaxRepayAction());
    trackEvent('aaveManage', 'repaySuccess');
  } catch (err) {
    dispatch({ type: aaveManageTypes.AAVE_REPAY_FAILURE, payload: err.message });
    captureException(err);
    trackEvent('aaveManage', 'repayError', err.message);
  }
};

export const approveWethAdapterAction = (stable, market) => async (dispatch, getState) => {
  const { general: { account, path, accountType } } = getState();
  const sendTxFunc = promise => sendTx(promise, `${t('common.approve')} Aave WETH Adapter`, 'Aave', dispatch, getState);
  if (market === 'v2default') await approveWethAdapter(accountType, path, sendTxFunc, account, stable);
  if (market === 'v3default') await approveWethAdapterV3(accountType, path, sendTxFunc, account, stable);
};
export const addApproveWethAdapterIfNeededAction = (stable, txToExecute, approveFunctionObject, market) => async (dispatch, getState) => {
  const { general: { account } } = getState();
  if (market === 'v2default') return addApproveWethAdapterIfNeeded(account, stable, txToExecute, approveFunctionObject);
  if (market === 'v3default') return addApproveWethAdapterIfNeededV3(account, stable, txToExecute, approveFunctionObject);
};

/**
 * Handles redux action for enabling an asset as collateral
 *
 * @param underlyingAsset {String}
 * @return {function(...[*]=)}
 */
export const enableAssetAsCollateralAction = underlyingAsset => async (dispatch, getState) => {
  const sendTxFunc = (promise, waitForSign) => sendTx(promise, t('compound.enable_collateral', { '%asset': underlyingAsset }), 'Aave', dispatch, getState, { protocol: 'aave' }, waitForSign);

  const {
    general: {
      walletType, account, accountType, path,
    },
    maker: { proxyAddress },
    aaveManage: { selectedMarket },
  } = getState();

  dispatch({ type: aaveManageTypes.ENABLE_AAVE_ASSET_AS_COLLATERAL_REQUEST, asset: underlyingAsset });

  let assetAddress = getAssetInfo(underlyingAsset).address;

  try {
    if (selectedMarket.value === 'v1') {
      await setAssetAsCollateral(accountType, path, sendTxFunc, account, proxyAddress, walletType, assetAddress, true);
    } else {
      if (underlyingAsset === 'ETH') assetAddress = getAssetInfo('WETH').address;
      if (selectedMarket.value === 'v2default') {
        await setAssetAsCollateralV2(accountType, path, sendTxFunc, account, proxyAddress, walletType, assetAddress, true, selectedMarket);
      } else if (selectedMarket.value === 'v3default') {
        const { assetsData } = getState().aaveManage[selectedMarket.value];

        const { usedAssets } = getState().aaveManage[walletType.value][selectedMarket.value];
        const usedAssetsData = Object.values(usedAssets);
        const isInIsolationMode = usedAssetsData.find(a => assetsData[a.symbol].isIsolated && a.collateral);
        const hasCollateral = usedAssetsData.find(a => a.collateral);

        if (!isInIsolationMode && !hasCollateral) {
          if (assetsData[underlyingAsset]?.isIsolated && !usedAssets[underlyingAsset]?.collateral) {
            const confirmed = await dispatch(confirmViaModal(t('aave.supplying_isolated_asset')));
            if (!confirmed) throw new Error(t('errors.denied_transaction'));
          }
        }

        await setAssetAsCollateralV3(accountType, path, sendTxFunc, account, proxyAddress, walletType, assetAddress, assetsData[underlyingAsset].assetId, true, selectedMarket);
      }
    }

    dispatch(fetchAaveManageData());

    dispatch({ type: aaveManageTypes.ENABLE_AAVE_ASSET_AS_COLLATERAL_SUCCESS, asset: underlyingAsset });
  } catch (err) {
    dispatch({ type: aaveManageTypes.ENABLE_AAVE_ASSET_AS_COLLATERAL_FAILURE, payload: err.message, asset: underlyingAsset });
    captureException(err);
  }
};

/**
 * Handles redux action for disabling an asset as collateral
 *
 * @param underlyingAsset {String}
 * @return {function(...[*]=)}
 */
export const disableAssetAsCollateralAction = underlyingAsset => async (dispatch, getState) => {
  const sendTxFunc = (promise, waitForSign) => sendTx(promise, t('compound.disable_collateral', { '%asset': underlyingAsset }), 'Aave', dispatch, getState, { protocol: 'aave' }, waitForSign);

  const {
    general: {
      walletType, account, accountType, path,
    },
    maker: { proxyAddress },
    aaveManage: { selectedMarket },
  } = getState();
  const { assetsData } = getState().aaveManage[selectedMarket.value];
  const {
    borrowedUsd, borrowLimitUsd, usedAssets, eModeCategory,
  } = getState().aaveManage[walletType.value][selectedMarket.value];

  dispatch({ type: aaveManageTypes.DISABLE_AAVE_ASSET_AS_COLLATERAL_REQUEST, asset: underlyingAsset });

  const borrowValuesWithoutColl = calcBorrowValuesWithoutColl(underlyingAsset, usedAssets, assetsData, borrowLimitUsd, borrowedUsd, eModeCategory);

  let assetAddress = getAssetInfo(underlyingAsset).address;

  try {
    // If the funds are going to be liquidated after the user disables the
    // passed down asset as collateral, make him confirm it
    if (borrowValuesWithoutColl.borrowPowerUsed > 100 || (borrowValuesWithoutColl.borrowPowerUsed > 0 && +borrowValuesWithoutColl.borrowLimitUsd <= 0)) {
      const result = await dispatch(openAaveCollateralRequiredModal(borrowValuesWithoutColl));

      if (!result) throw new Error('Rejected');
    }

    if (selectedMarket.value === 'v1') {
      await setAssetAsCollateral(accountType, path, sendTxFunc, account, proxyAddress, walletType, assetAddress, false);
    } else {
      if (underlyingAsset === 'ETH') assetAddress = getAssetInfo('WETH').address;
      if (selectedMarket.value === 'v2default') {
        await setAssetAsCollateralV2(accountType, path, sendTxFunc, account, proxyAddress, walletType, assetAddress, false, selectedMarket);
      } else if (selectedMarket.value === 'v3default') {
        const { assetsData } = getState().aaveManage[selectedMarket.value];
        await setAssetAsCollateralV3(accountType, path, sendTxFunc, account, proxyAddress, walletType, assetAddress, assetsData[underlyingAsset].assetId, false, selectedMarket);
      }
    }

    dispatch(fetchAaveManageData());

    dispatch({ type: aaveManageTypes.DISABLE_AAVE_ASSET_AS_COLLATERAL_SUCCESS, asset: underlyingAsset });
  } catch (err) {
    dispatch({ type: aaveManageTypes.DISABLE_AAVE_ASSET_AS_COLLATERAL_FAILURE, payload: err.message, asset: underlyingAsset });
    captureException(err);
  }
};


// ACTIONS EXECUTORS END

/**
 * Sets the Aave manage page active tab to the reducer
 *
 * @param payload
 * @return {Function}
 */
export const setAaveManageActiveTab = payload => (dispatch) => {
  dispatch({ type: aaveManageTypes.AAVE_MANAGE_SET_ACTIVE_TAB, payload });
};

export const onWalletTypeChange = () => (dispatch, getState) => {
  dispatch(fetchAaveAccountData());
};

export const switchToUserWallet = () => (dispatch) => {
  dispatch(changeWalletType(WALLET_TYPES_OPTIONS[1]));
  dispatch(onWalletTypeChange());
};

export const fetchAaveAssetsDataForMarket = (market) => async (dispatch, getState) => {
  dispatch({ type: aaveManageTypes.FETCH_AAVE_ASSETS_DATA_REQUEST, selectedMarket: market });

  const selectedMarket = AAVE_VERSIONS(getState().general.network).find(version => version.value === market);

  try {
    await dispatch(getAssetsPricesAction(selectedMarket.value === 'v1' ? aaveCollateralAssets.map(a => a.underlyingAsset) : selectedMarket.assets, 'aave', selectedMarket));
    await dispatch(getAssetsBalancesAction(selectedMarket.value === 'v1' ? aaveCollateralAssets.map(a => a.underlyingAsset) : selectedMarket.assets));

    let marketsData = [];
    if (market === 'v1') marketsData = await getAaveMarketsData(aaveCollateralAssets.map(a => getAssetInfo(a.underlyingAsset).address));
    if (market === 'v2default') marketsData = await getAaveMarketsDataV2(selectedMarket);
    if (market === 'v3default') marketsData = await getAaveMarketsDataV3(selectedMarket);

    const payload = {};
    // Sort by market size
    marketsData
      .sort((a, b) => {
        const { assets } = getState();

        const aMarket = new Dec(assets[a.symbol].aavePrice).times(a.totalSupply).toString();
        const bMarket = new Dec(assets[b.symbol].aavePrice).times(b.totalSupply).toString();

        return new Dec(bMarket).minus(aMarket).toNumber();
      })
      .forEach((market, i) => { payload[market.symbol] = { ...market, sortIndex: i }; });

    // if (payloadMarket !== selectedMarket) dispatch({ type: aaveManageTypes.SET_SELECTED_MARKET_ACTION, payload: payloadMarket });
    dispatch({ type: aaveManageTypes.FETCH_AAVE_ASSETS_DATA_SUCCESS, payload, selectedMarket: market });
  } catch (err) {
    dispatch({ type: aaveManageTypes.FETCH_AAVE_ASSETS_DATA_FAILURE, payload: err.message, selectedMarket: market });
    captureException(err);
  }
};

export const fetchAccountDataForWalletTypeAndMarket = (walletType, address, market) => async (dispatch, getState) => {
  const { assets } = getState();
  const { assetsData } = getState().aaveManage[market];
  const selectedMarket = AAVE_VERSIONS(getState().general.network).find(version => version.value === market);

  try {
    dispatch({ type: aaveManageTypes.FETCH_AAVE_ACCOUNT_DATA_REQUEST, walletType, selectedMarket: market });

    let payload = [];
    if (market === 'v1') payload = await getAaveAccountData(address, assetsData, assets);
    if (market === 'v2default') payload = await getAaveAccountDataV2(address, assetsData, assets, selectedMarket, walletType);
    if (market === 'v3default') payload = await getAaveAccountDataV3(address, assetsData, assets, selectedMarket, walletType);

    dispatch({
      type: aaveManageTypes.FETCH_AAVE_ACCOUNT_DATA_SUCCESS, payload, walletType, selectedMarket: market,
    });
    return payload;
  } catch (err) {
    dispatch({
      type: aaveManageTypes.FETCH_AAVE_ACCOUNT_DATA_FAILURE, payload: err.message, walletType, selectedMarket: market,
    });
    captureException(err);
  }
};

const importToProxyAction = (accountType, path, sendTxFunc, account, collAsset, borrowAsset, flashLoanSize, selectedMarket) => async (dispatch, getState) => {
  try {
    dispatch({ type: aaveManageTypes.MIGRATE_AAVE_V1_TO_PROXY_REQUEST });
    await importLoan(accountType, path, sendTxFunc, account, getState().maker.proxyAddress, collAsset, borrowAsset, flashLoanSize);
    dispatch({ type: aaveManageTypes.MIGRATE_AAVE_V1_TO_PROXY_SUCCESS });
  } catch (err) {
    dispatch({ type: aaveManageTypes.MIGRATE_AAVE_V1_TO_PROXY_FAILURE, payload: err.message });
    throw err;
  }
};

/**
 * Migrate 1 coll & 1 borrow asset from Aave to DSProxy Aave
 *
 * @param collAsset
 * @param borrowAsset
 * @param closeModal
 * @returns {function(...[*]=)}
 */
export const migrateAaveToProxy = (collAsset, borrowAsset, closeModal) => async (dispatch, getState) => {
  trackEvent('aaveManage', 'migrateToProxy');

  const { proxyAddress } = getState().maker;
  const { selectedMarket } = getState().aaveManage;
  const { assetsData } = getState().aaveManage[selectedMarket.value];
  const { usedAssets } = getState().aaveManage.account[selectedMarket.value];
  const { account, accountType, path } = getState().general;
  const ethUsdPrice = getState().assets.ETH.marketPrice;
  const txsToExecute = [];
  const createProxyFunctionObject = {
    func: () => dispatch(createDSProxyAction()),
    type: 'createCdp',
    notifMessage: t('account.create_ds_proxy'),
    checkReturnValue: true,
    returnValueFailureMessage: 'Error fetching Proxy address',
  };
  try {
    const approveFunctionObject = {
      type: 'approve',
      notifMessage: `${t('common.approve')} ${approveTypeToLabelMap[APPROVE_TYPE_DS_PROXY]} ${t('common.for')} ${collAsset.cAsset}`,
    };
    const importFunctionObject = {
      type: 'import',
      notifMessage: t('compound.migrating_to_proxy'),
    };

    if (!proxyAddress) txsToExecute.push(createProxyFunctionObject);
    const collAssetData = assetsData[collAsset.value];
    const collUsedAssetData = usedAssets[collAsset.value];
    const flashLoanSize = (collUsedAssetData.suppliedUsd / ethUsdPrice) * 1.2;

    const sendTxFunc = promise => sendTx(promise, t('compound.migrating_to_proxy'), 'Aave', dispatch, getState, { protocol: 'aave' });

    if (borrowAsset.value !== 'none') {
      if (selectedMarket.value === 'v1') {
        approveFunctionObject.func = () => dispatch(approveAddressOnAssetAction(collAsset.cAsset, getState().maker.proxyAddress, APPROVE_TYPE_DS_PROXY));
        // if user has proxy, check for approval
        if (proxyAddress) await dispatch(addApproveTxIfNeeded(collAsset.cAsset, getState().maker.proxyAddress, Dec(collUsedAssetData.supplied).toString(), APPROVE_TYPE_DS_PROXY, txsToExecute, approveFunctionObject));
        // if not, always approve aAsset
        else txsToExecute.push(approveFunctionObject);
      } else {
        const approveAsset = ethToWeth(collAsset.value);
        const approveAAsset = ethToWeth(collAsset.cAsset);
        const aTokenAddress = await getATokenAddress(getAssetInfo(approveAsset).address, selectedMarket);

        approveFunctionObject.func = () => dispatch(approveNewAaveTokenAction(approveAAsset, accountType, path, account, aTokenAddress, false, approveAsset));
        // if user has proxy, check for approval
        if (proxyAddress) await dispatch(approveNewAaveTokenActionIfNeeded(account, accountType, path, collAsset, selectedMarket, collUsedAssetData, approveAAsset, aTokenAddress, txsToExecute, approveFunctionObject));
        // if not, always approve aAsset
        else txsToExecute.push(approveFunctionObject);
      }
      importFunctionObject.func = () => dispatch(importToProxyAction(accountType, path, sendTxFunc, account, collAsset.value, borrowAsset.value, flashLoanSize, selectedMarket));
      txsToExecute.push(importFunctionObject);
    } else {
      let aTokenAddress = '';
      if (selectedMarket.value !== 'v1') {
        const approveAsset = ethToWeth(collAsset.value);
        aTokenAddress = await getATokenAddress(getAssetInfo(approveAsset).address, selectedMarket);
      }
      const transferFunctionObject = {
        type: 'transfer',
        notifMessage: t('compound.migrating_to_proxy'),
        func: async () => {
          await requireOwner(getState().maker.proxyAddress, account);
          return transferErc20(accountType, path, sendTxFunc, account, getState().maker.proxyAddress, selectedMarket.value === 'v1' ? getAssetInfo(collAsset.cAsset).address : aTokenAddress, getState().maker.proxyAddress, 0, true);
        },
      };
      txsToExecute.push(transferFunctionObject);
    }

    const returnValues = await dispatch(callMultipleTxAction(txsToExecute));

    dispatch({ type: aaveManageTypes.MIGRATE_AAVE_V1_TO_PROXY_SUCCESS });
    dispatch(switchToUserWallet());

    closeModal();
    trackEvent('aaveManage', 'migrateToProxySuccess');
  } catch (err) {
    trackEvent('aaveManage', 'migrateToProxyError');
    dispatch({ type: aaveManageTypes.NEW_ATOKEN_APPROVED_FAILURE, payload: err.message });
    dispatch({ type: aaveManageTypes.MIGRATE_AAVE_V1_TO_PROXY_FAILURE, payload: err.message });
    captureException(err);
  }
};

export const swapInterestRateModeAction = (underlyingAsset, interestMode) => async (dispatch, getState) => {
  const sendTxFunc = (promise, waitForSign) => sendTx(promise, t('aave.swap_interest_rate', { '%asset': underlyingAsset }), 'Aave', dispatch, getState, { protocol: 'aave' }, waitForSign);

  const {
    general: {
      walletType, account, accountType, path,
    },
    maker: { proxyAddress },
    aaveManage,
  } = getState();

  const { selectedMarket } = aaveManage;
  const { assetsData } = aaveManage[selectedMarket.value];

  try {
    if (selectedMarket.value === 'v1') {
      const assetAddress = getAssetInfo(underlyingAsset).address;
      dispatch({ type: aaveManageTypes.AAVE_SWAP_INTEREST_RATE_MODE_REQUEST, asset: underlyingAsset, interestRate: interestMode.value });
      await swapInterestRateMode(accountType, path, sendTxFunc, account, proxyAddress, walletType, assetAddress);
    } else {
      const assetAddress = getAssetInfo(ethToWeth(underlyingAsset)).address;
      const interestRate = interestMode.value === 'Stable' ? '1' : '2';
      dispatch({ type: aaveManageTypes.AAVE_SWAP_INTEREST_RATE_MODE_REQUEST, asset: underlyingAsset, interestRate: interestMode.value });
      if (selectedMarket.value === 'v2default') {
        await swapInterestRateModeV2(accountType, path, sendTxFunc, account, proxyAddress, walletType, assetAddress, interestRate, selectedMarket);
      } else if (selectedMarket.value === 'v3default') {
        const { assetId } = assetsData[underlyingAsset];
        await swapInterestRateModeV3(accountType, path, sendTxFunc, account, proxyAddress, walletType, assetId, interestRate, selectedMarket);
      }
    }

    dispatch(fetchAaveAccountData());
    dispatch(fetchAaveAssetsData());

    dispatch({ type: aaveManageTypes.AAVE_SWAP_INTEREST_RATE_MODE_SUCCESS, asset: underlyingAsset, interestRate: interestMode.value });
  } catch (err) {
    dispatch({
      type: aaveManageTypes.AAVE_SWAP_INTEREST_RATE_MODE_FAILURE, payload: err.message, asset: underlyingAsset, interestRate: interestMode.value,
    });
    captureException(err);
  }
};

export const changeAaveMarket = (selectedMarket) => async (dispatch, getState) => {
  dispatch({ type: aaveManageTypes.SET_SELECTED_MARKET_ACTION, payload: selectedMarket });
  addToLsState({ account: getState().general.account, aaveMarket: selectedMarket.value });
};

export const getBoostModalData = (inputAmount, borrowAsset, supplyAsset) => async (dispatch, getState) => {
  dispatch({ type: aaveManageTypes.GET_BOOST_MODAL_DATA_REQUEST });

  try {
    const { proxyAddress } = getState().maker;
    const { price: exchangeRate, source } = await getBestExchangePrice(inputAmount.toString(), borrowAsset, supplyAsset, proxyAddress);
    const boostAmount = supplyAsset === 'stETH' && borrowAsset === 'ETH' ? inputAmount : new Dec(exchangeRate).times(inputAmount).toString();

    const marketPrice = await getSlippageThreshold(borrowAsset, supplyAsset);
    const tradeSizeImpact = calculateTradeSizeImpact(marketPrice, exchangeRate);
    await dispatch(getMaxBorrowAction(true));
    const maxBorrow = getState().aaveManage.maxBorrow;
    const useFl = new Dec(inputAmount).gt(maxBorrow);
    const flData = useFl
      ? (await flProtocolAndFeeFor(inputAmount, borrowAsset, getState().general.network))
      : {
        protocol: 'none', feeMultiplier: '1', flFee: '0', useAltRecipe: true,
      };

    dispatch({
      type: aaveManageTypes.GET_BOOST_MODAL_DATA_SUCCESS,
      payload: {
        boostAmount,
        tradeSizeImpact,
        useFl,
        boostExchangeRate: exchangeRate,
        exchangeSource: source,
        useAltRecipe: flData.useAltRecipe,
        flProtocol: flData.protocol,
        flFee: flData.flFee,
      },
    });
  } catch (err) {
    dispatch({ type: aaveManageTypes.GET_BOOST_MODAL_DATA_FAILURE, payload: err.message });
    captureException(err);
  }
};

/**
 * Fetches the repay modal data
 *
 * @param inputAmount
 * @param withdrawAsset
 * @param paybackAsset
 * @return {function(...[*]=)}
 */
export const getRepayModalData = (inputAmount, withdrawAsset, paybackAsset) => async (dispatch, getState) => {
  dispatch({ type: aaveManageTypes.GET_REPAY_MODAL_DATA_REQUEST });

  try {
    const { proxyAddress } = getState().maker;
    const { price: exchangeRate, source } = await getBestExchangePrice(inputAmount.toString(), withdrawAsset, paybackAsset, proxyAddress);

    const repayAmount = new Dec(inputAmount).times(exchangeRate).toString();

    const marketPrice = await getSlippageThreshold(withdrawAsset, paybackAsset);
    const tradeSizeImpact = calculateTradeSizeImpact(marketPrice, exchangeRate);

    await dispatch(getMaxWithdrawAction(true));
    const maxWithdraw = getState().aaveManage.maxValues.withdraw; // handle v1
    const useFl = new Dec(inputAmount).gt(maxWithdraw);
    const flData = useFl
      ? (await flProtocolAndFeeFor(inputAmount, withdrawAsset, getState().general.network))
      : {
        protocol: 'none', feeMultiplier: '1', useAltRecipe: true, flFee: '0',
      };

    const payload = {
      repayAmount,
      tradeSizeImpact,
      useFl,
      repayExchangeRate: exchangeRate,
      exchangeSource: source,
      useAltRecipe: flData.useAltRecipe,
      flProtocol: flData.protocol,
      flFee: flData.flFee,
    };

    dispatch({ type: aaveManageTypes.GET_REPAY_MODAL_DATA_SUCCESS, payload });
  } catch (err) {
    dispatch({ type: aaveManageTypes.GET_REPAY_MODAL_DATA_FAILURE, payload: err.message });
    captureException(err);
  }
};

export const getStakeAaveBalanceAndCooldownAction = (userAddress, userProxy) => async (dispatch, getState) => {
  try {
    const { account } = getState().general;
    const { proxyAddress } = getState().maker;

    let fetchingAddress = account;
    let fetchingProxy = proxyAddress;

    if (userAddress) {
      fetchingAddress = userAddress;
      fetchingProxy = userProxy;
    }

    dispatch({ type: aaveManageTypes.GET_STAKE_AAVE_BALANCE_AND_COOLDOWN_REQUEST });
    const payload = await getStakeAaveBalanceAndCooldown(fetchingAddress, fetchingProxy);
    dispatch({ type: aaveManageTypes.GET_STAKE_AAVE_BALANCE_AND_COOLDOWN_SUCCESS, payload });
    return payload;
  } catch (err) {
    dispatch({ type: aaveManageTypes.GET_STAKE_AAVE_BALANCE_AND_COOLDOWN_FAILURE, payload: err.message });
    console.error(err);
  }
};

export const fetchStakeModalData = () => async (dispatch, getState) => {
  await dispatch(getStakeAaveBalanceAndCooldownAction());
  await dispatch(getAssetsBalancesAction(['AAVE', 'LEND', 'stkAAVE']));
  await dispatch(getAssetsPricesAction(['AAVE', 'LEND', 'stkAAVE']));
};

export const stakeAaveAction = _amount => async (dispatch, getState) => {
  const {
    general: {
      walletType, account, accountType, path,
    },
    maker: { proxyAddress },
  } = getState();
  const additionalInfo = {
    protocol: 'aave',
    firstToken: 'AAVE',
    firstAmount: _amount,
  };
  const sendTxFunc = promise => sendTx(promise, t('aave.stake_aave', { '%amount': formatNumber(_amount, 2) }), 'Aave', dispatch, getState, additionalInfo);
  const txToExecute = [];
  try {
    dispatch({ type: aaveManageTypes.AAVE_STAKE_REQUEST });
    // approve
    const approveFunctionObject = {
      func: () => dispatch(approveAddressOnAssetAction('AAVE', stkAAVEAddress, APPROVE_TYPE_AAVE)),
      type: 'approve',
      notifMessage: `${t('common.approve')} ${approveTypeToLabelMap[APPROVE_TYPE_AAVE]} ${t('common.for')} ${'AAVE'}`,
    };
    const stakeFunctionObject = {
      type: 'stake',
      notifMessage: t('aave.stake_aave', { '%amount': formatNumber(_amount, 2) }),
      func: () => stakeAave(accountType, path, sendTxFunc, account, proxyAddress, walletType, _amount),
    };

    await dispatch(addApproveTxIfNeeded('AAVE', stkAAVEAddress, _amount, APPROVE_TYPE_AAVE, txToExecute, approveFunctionObject));
    txToExecute.push(stakeFunctionObject);
    const returnValues = await dispatch(callMultipleTxAction(txToExecute));

    dispatch({ type: aaveManageTypes.AAVE_STAKE_SUCCESS });

    await dispatch(fetchStakeModalData());
  } catch (err) {
    dispatch({ type: aaveManageTypes.AAVE_STAKE_FAILURE, payload: err.message });
    console.error(err);
  }
};

export const activateUnstakeCooldownAction = amount => async (dispatch, getState) => {
  const {
    general: {
      walletType, account, accountType, path,
    },
    maker: { proxyAddress },
    aaveManage: { cooldownSeconds, unstakeWindow },
  } = getState();
  const sendTxFunc = promise => sendTx(promise, t('aave.activate_cooldown'), 'Aave', dispatch, getState, { protocol: 'aave' });


  try {
    dispatch({ type: aaveManageTypes.AAVE_COOLDOWN_REQUEST });
    await activateUnstakeCooldown(accountType, path, sendTxFunc, account, proxyAddress, walletType, cooldownSeconds, unstakeWindow);
    dispatch({ type: aaveManageTypes.AAVE_COOLDOWN_SUCCESS });

    await dispatch(fetchStakeModalData());
  } catch (err) {
    dispatch({ type: aaveManageTypes.AAVE_COOLDOWN_FAILURE, payload: err.message });
    console.error(err);
  }
};

export const unstakeAaveAction = amount => async (dispatch, getState) => {
  const {
    general: {
      walletType, account, accountType, path,
    },
    maker: { proxyAddress },
  } = getState();

  const additionalInfo = {
    protocol: 'aave',
    firstToken: 'stkAAVE',
    firstAmount: amount,
  };
  const sendTxFunc = promise => sendTx(promise, t('aave.unstake_aave', { '%amount': formatNumber(amount, 2) }), 'Aave', dispatch, getState, additionalInfo);

  try {
    dispatch({ type: aaveManageTypes.AAVE_UNSTAKE_REQUEST });
    await unstakeAave(accountType, path, sendTxFunc, account, proxyAddress, walletType, amount);
    dispatch({ type: aaveManageTypes.AAVE_UNSTAKE_SUCCESS });

    await dispatch(fetchStakeModalData());
  } catch (err) {
    dispatch({ type: aaveManageTypes.AAVE_UNSTAKE_FAILURE, payload: err.message });
    console.error(err);
  }
};

export const migrateLendToAaveAction = _amount => async (dispatch, getState) => {
  const {
    general: {
      walletType, account, accountType, path,
    },
    maker: { proxyAddress },
  } = getState();

  const additionalInfo = {
    protocol: 'aave',
    firstToken: 'LEND',
    firstAmount: _amount,
  };
  const sendTxFunc = promise => sendTx(promise, t('aave.migrate_lend_aave', { '%amount': formatNumber(_amount, 2) }), 'Aave', dispatch, getState, additionalInfo);

  const txToExecute = [];
  try {
    dispatch({ type: aaveManageTypes.MIGRATE_LEND_TO_AAVE_REQUEST });
    const approveFunctionObject = {
      func: () => dispatch(approveAddressOnAssetAction('LEND', LendToAaveMigratorAddress, APPROVE_TYPE_AAVE)),
      type: 'approve',
      notifMessage: `${t('common.approve')} ${approveTypeToLabelMap[APPROVE_TYPE_AAVE]} ${t('common.for')} AAVE`,
    };
    const migrateFunctionObject = {
      type: 'migrate',
      notifMessage: t('aave.migrate_lend_aave', { '%amount': formatNumber(_amount, 2) }),
      func: () => migrateLendToAave(accountType, path, sendTxFunc, account, proxyAddress, walletType, _amount),
    };

    await dispatch(addApproveTxIfNeeded('LEND', LendToAaveMigratorAddress, _amount, APPROVE_TYPE_AAVE, txToExecute, approveFunctionObject));
    txToExecute.push(migrateFunctionObject);
    const returnValues = await dispatch(callMultipleTxAction(txToExecute));

    dispatch({ type: aaveManageTypes.MIGRATE_LEND_TO_AAVE_SUCCESS });

    await dispatch(fetchStakeModalData());
  } catch (err) {
    dispatch({ type: aaveManageTypes.MIGRATE_LEND_TO_AAVE_FAILURE, payload: err.message });
    console.error(err);
  }
};

export const claimAaveRewardsAction = () => async (dispatch, getState) => {
  const {
    general: {
      walletType, account, accountType, path,
    },
    maker: { proxyAddress },
    aaveManage: { rewardBalance },
  } = getState();

  const additionalInfo = {
    protocol: 'aave',
    firstToken: 'AAVE',
    firstAmount: rewardBalance,
  };
  const sendTxFunc = promise => sendTx(promise, t('aave.claim_rewards', { '%amount': formatNumber(rewardBalance, 5) }), 'Aave', dispatch, getState, additionalInfo);

  try {
    dispatch({ type: aaveManageTypes.CLAIM_REWARDS_REQUEST });
    await claimRewards(accountType, path, sendTxFunc, account, proxyAddress, walletType);
    dispatch({ type: aaveManageTypes.CLAIM_REWARDS_SUCCESS });

    await dispatch(fetchStakeModalData());
  } catch (err) {
    console.error(err);
    dispatch({ type: aaveManageTypes.CLAIM_REWARDS_FAILURE, payload: err.message });
  }
};

export const claimStkAaveRewardsAction = (from) => async (dispatch, getState) => {
  const {
    general: {
      walletType, account, accountType, path,
    },
    maker: { proxyAddress },
    aaveManage: { stkAaveRewardBalanceAcc, stkAaveRewardBalanceProxy },
  } = getState();

  const amount = from === 'account' ? stkAaveRewardBalanceAcc : stkAaveRewardBalanceProxy;

  const additionalInfo = {
    protocol: 'aave',
    firstToken: 'stkAAVE',
    firstAmount: amount,
  };
  const sendTxFunc = promise => sendTx(promise, t('aave.claim_stkaave', { '%amount': formatNumber(amount, 5) }), 'Aave', dispatch, getState, additionalInfo);

  try {
    dispatch({ type: aaveManageTypes.CLAIM_REWARDS_REQUEST });
    await claimStkAaveRewards(accountType, path, sendTxFunc, account, proxyAddress, from);
    dispatch({ type: aaveManageTypes.CLAIM_REWARDS_SUCCESS });

    await dispatch(fetchStakeModalData());
  } catch (err) {
    console.error(err);
    dispatch({ type: aaveManageTypes.CLAIM_REWARDS_FAILURE, payload: err.message });
  }
};

export const changeDashboardAction = (payload, primary = true) => async (dispatch) => {
  await dispatch({
    type: primary ? aaveManageTypes.AAVE_SET_DASHBOARD_ACTION : aaveManageTypes.AAVE_SET_ADDITIONAL_DASHBOARD_ACTION,
    payload,
  });
  dispatch(setAfterValue(0, 'reset'));
};

const postAction = (contextAction, secondAction, firstAction) => async (dispatch) => {
  await dispatch(setAfterValue(0, 'clear'));
  await dispatch(resetFields(formName, { [`${contextAction.value}-${firstAction?.value}`]: '', [`${contextAction.value}-${secondAction?.value}`]: '' }));
  firstAction.getMax();
  secondAction?.getMax?.();

  await dispatch(fetchAaveAccountData());
  await dispatch(fetchAaveAssetsData());
  dispatch({ type: aaveManageTypes.AAVE_ACTION_EXEC_SUCCESS, action: contextAction.value });
};

/**
 *
 * @param value {string} action being executed
 * contextAction {Object} where the action is being called from
 * additionalAction {Object} contains value of action to be executed in recipe, eg. 'borrow' but also can be 'collateral' if contextAction is 'borrow'
 * @return {Function}
 */
export const basicAaveAction = (value) => (contextAction, additionalAction) => async (dispatch, getState) => {
  try {
    const {
      aaveManage,
      assets,
      general: { account, accountType, walletType },
      maker: { proxyAddress },
    } = getState();

    const {
      firstAction, firstInput, secondAction, secondInput, firstInputSelect: fromAsset, secondInputSelect: secondaryFromAsset,
    } = dispatch(getDashboardInputs(contextAction, additionalAction));

    const {
      ratioTooLow, ratioTooHigh, selectedMarket, afterValue,
    } = aaveManage;
    const { assetsData } = aaveManage[selectedMarket.value];
    const { usedAssets } = aaveManage[walletType.value][selectedMarket.value];

    let primaryRateMode = fromAsset?.interestMode === 'both'
      ? fromAsset.additionalLabel === 'Stable' ? '1' : '2'
      : fromAsset?.interestMode;
    let secondaryRateMode = secondaryFromAsset?.interestMode === 'both'
      ? secondaryFromAsset.additionalLabel === 'Stable' ? '1' : '2'
      : secondaryFromAsset?.interestMode;

    if (firstAction.value === 'borrow') {
      const interestRate = await dispatch(chooseAaveBorrowInterestRateMode(firstInput, fromAsset.value, usedAssets, selectedMarket.value));
      if (!interestRate) throw new Error('User canceled interest rate modal.');
      primaryRateMode = interestRate.value;
    }
    if (secondAction && secondAction?.value === 'borrow') {
      const interestRate = await dispatch(chooseAaveBorrowInterestRateMode(secondInput, secondaryFromAsset.value, { ...usedAssets, ...afterValue?.usedAssets }, selectedMarket.value));
      if (!interestRate) throw new Error('User canceled interest rate modal.');
      secondaryRateMode = interestRate.value;
    }

    if (selectedMarket.value !== 'v3default' && (ratioTooLow || ratioTooHigh)) {
      const confirmed = await dispatch(confirmViaModal(t('errors.automatic_trigger')));
      if (!confirmed) return false;
    }

    aaveManageTrackEventWrapper(getState, 'aaveManage', value);
    dispatch({ type: aaveManageTypes.AAVE_ACTION_EXEC_REQUEST, action: contextAction.value });

    let targetAccount = secondAction?.value === 'send' ? secondInput : account;
    if (!isAddress(targetAccount)) targetAccount = await getEnsAddress(targetAccount);

    const firstInputWei = assetAmountInWei(firstInput, fromAsset.value);
    const secondInputWei = secondAction?.value === 'send' ? '' : assetAmountInWei(secondInput, secondaryFromAsset?.value);

    let primaryEnableAsColl = true;
    let secondaryEnableAsColl = true;

    const usedAssetsData = Object.values(usedAssets);
    const isInIsolationMode = usedAssetsData.find(a => assetsData[a.symbol].isIsolated && a.collateral);
    const hasCollateral = usedAssetsData.find(a => a.collateral);

    if (selectedMarket.value === 'v3default') {
      if (firstAction.value === 'collateral' || secondAction?.value === 'collateral') {
        const firstActionIsColl = firstAction.value === 'collateral';

        const asset = firstActionIsColl ? fromAsset.value : secondaryFromAsset.value;
        const isCurrAssetIsolated = assetsData[asset].isIsolated && usedAssets[asset]?.collateral;

        if (isInIsolationMode && !isCurrAssetIsolated) {
          if (firstActionIsColl) primaryEnableAsColl = false;
          else secondaryEnableAsColl = false;

          const confirmed = await dispatch(confirmViaModal(t('aave.supplying_while_isolated', { '%asset': asset })));
          if (!confirmed) throw new Error(t('errors.denied_transaction'));
        }
        if (assetsData[asset].isIsolated && !isInIsolationMode && usedAssetsData.find(a => a.collateral)) {
          if (firstActionIsColl) primaryEnableAsColl = false;
          else secondaryEnableAsColl = false;

          const confirmed = await dispatch(confirmViaModal(t('aave.supplying_isolated_asset_disabled_coll', { '%asset': asset })));
          if (!confirmed) throw new Error(t('errors.denied_transaction'));
        }

        if (assetsData[asset].isSiloed && !usedAssets[asset]?.isSupplied && (firstActionIsColl ? primaryEnableAsColl : secondaryEnableAsColl)) {
          if (firstActionIsColl) primaryEnableAsColl = false;
          else secondaryEnableAsColl = false;

          const confirmed = await dispatch(confirmViaModal(t('aave.supplying_while_siloed', { '%asset': asset })));
          if (!confirmed) throw new Error(t('errors.denied_transaction'));
        }
      }
    }

    let recipe;
    if (selectedMarket.value === 'v2default') {
      recipe = await getAaveV2Recipe(
        firstAction.value, firstInputWei, secondAction?.value, secondInputWei, fromAsset.address, primaryRateMode, secondaryFromAsset?.address, secondaryRateMode,
        selectedMarket, targetAccount, proxyAddress, usedAssets,
      );
    }
    if (selectedMarket.value === 'v3default') {
      const fromId = assetsData[fromAsset.value].assetId;
      const secondaryFromId = assetsData[secondaryFromAsset?.value]?.assetId;
      recipe = await getAaveV3Recipe(
        firstAction.value, firstInputWei, secondAction?.value, secondInputWei, fromAsset.address, fromId, primaryRateMode, primaryEnableAsColl,
        secondaryFromAsset?.address, secondaryFromId, secondaryRateMode, secondaryEnableAsColl,
        selectedMarket, targetAccount, proxyAddress, usedAssets, assetsData, assets,
      );
    }

    const amount = formatNumber(parseFloat(firstInput), 2);
    const additionalAmount = formatNumber(parseFloat(secondInput), 2);

    let label = `${firstAction.label}: ${amount} ${fromAsset.value}`;
    if (secondAction && secondAction.value !== 'send') label += ` + ${secondAction.label}: ${additionalAmount} ${secondaryFromAsset.value}`;
    else if (secondAction) label += ` + ${secondAction.label} to ${formatAcc(targetAccount, 5, 3)}`;

    const proxySendHandler = (promise) => {
      const additionalInfo = {
        protocol: 'aave',
        firstToken: fromAsset.value,
        firstAmount: firstInput,
      };
      return sendTx(promise, label, 'aave', dispatch, getState, additionalInfo);
    };

    if (selectedMarket.value === 'v3default' && !isInIsolationMode && firstAction.value === 'collateral' && !hasCollateral) {
      if (assetsData[fromAsset.value]?.isIsolated && !usedAssets[fromAsset.value]?.collateral) {
        const confirmed = await dispatch(confirmViaModal(t('aave.supplying_isolated_asset')));
        if (!confirmed) throw new Error(t('errors.denied_transaction'));
      }
    }

    const txToExecute = [];
    await dispatch(addApproveForActionWithSelectIfNeeded(firstAction, firstInput, fromAsset, proxyAddress, txToExecute));
    if (secondAction) await dispatch(addApproveForActionWithSelectIfNeeded(secondAction, secondInput, secondaryFromAsset, proxyAddress, txToExecute));
    txToExecute.push({
      func: () => callRecipeViaProxy(accountType, proxySendHandler, proxyAddress, account, recipe),
      type: firstAction.value,
      notifMessage: label,
    });
    await dispatch(callMultipleTxAction(txToExecute));

    dispatch(postAction(contextAction, secondAction, firstAction));
    aaveManageTrackEventWrapper(getState, 'aaveManage', `${value}Success`);
  } catch (err) {
    dispatch({ type: aaveManageTypes.AAVE_ACTION_EXEC_FAILURE, action: contextAction.value, payload: err.message });
    aaveManageTrackEventWrapper(getState, 'aaveManage', `${value}Error`, err.message);
    captureException(err);
  }
};

export const borrowForProxyAction = basicAaveAction('borrow');
export const withdrawForProxyAction = basicAaveAction('withdraw');
export const addCollateralForProxyAction = basicAaveAction('collateral');
export const paybackForProxyAction = basicAaveAction('payback');

/**
 * Handles redux actions for the aave supply tab withdraw action
 *
 * @param contextAction {Object}
 * @param additionalAction {Object}
 *
 * @return {Function}
 */
export const aaveWithdrawForAccountAction = (contextAction, additionalAction) => async (dispatch, getState) => {
  const {
    general: {
      walletType, account, accountType, path, network,
    },
    aaveManage,
  } = getState();

  const { selectedMarket } = aaveManage;
  const { assetsData } = aaveManage[selectedMarket.value];
  const { usedAssets } = aaveManage[walletType.value][selectedMarket.value];

  const {
    firstInput: _amount, firstInputSelect: fromAsset,
  } = dispatch(getDashboardInputs(contextAction, additionalAction));

  const asset = fromAsset?.value;
  const supplied = usedAssets[asset].supplied;

  trackEvent('aaveManage', 'withdraw');
  dispatch({ type: aaveManageTypes.AAVE_WITHDRAW_REQUEST });

  const approveFunctionObject = {
    type: 'approve',
    notifMessage: `${t('common.approve')} Aave WETH Adapter`,
  };
  const withdrawFunctionObject = {
    type: 'withdraw',
    notifMessage: `${t('common.withdraw')}: ${_amount} ${asset}`,
  };

  const sendTxFunc = (promise, waitForSign) => {
    const amount = formatNumber(parseFloat(_amount), 2);
    const additionalInfo = {
      protocol: 'aave',
      firstToken: asset,
      firstAmount: _amount,
    };
    return sendTx(promise, `${t('common.withdraw')}: ${amount} ${asset}`, 'Aave', dispatch, getState, additionalInfo, waitForSign);
  };

  try {
    const txToExecute = [];

    if (selectedMarket.value === 'v2default') {
      withdrawFunctionObject.func = () => withdrawV2(accountType, path, sendTxFunc, account, walletType, _amount, asset, supplied, selectedMarket);
      if (asset === 'ETH') {
        approveFunctionObject.func = () => dispatch(approveAddressOnAssetAction('aWETH', AaveWETHAdapterAddress, APPROVE_TYPE_AAVE));
        await dispatch(addApproveTxIfNeeded('aWETH', AaveWETHAdapterAddress, _amount, APPROVE_TYPE_AAVE, txToExecute, approveFunctionObject));
      }
    }
    if (selectedMarket.value === 'v3default') {
      withdrawFunctionObject.func = () => withdrawV3(accountType, path, sendTxFunc, account, walletType, _amount, asset, assetsData[asset].assetId, supplied, selectedMarket);
      if (asset === 'ETH') {
        const aWethAdapterAddr = getConfigContractAddress('AaveV3WETHAdapterL2');
        approveFunctionObject.func = () => dispatch(approveAddressOnAssetAction('aWETH', aWethAdapterAddr, APPROVE_TYPE_AAVE, config.aWETH.networks[network].address));
        await dispatch(addApproveTxIfNeeded('aWETH', aWethAdapterAddr, _amount, APPROVE_TYPE_AAVE, txToExecute, approveFunctionObject, config.aWETH.networks[network].address));
      }
    }

    txToExecute.push(withdrawFunctionObject);

    await dispatch(callMultipleTxAction(txToExecute));

    dispatch({ type: aaveManageTypes.AAVE_WITHDRAW_SUCCESS });

    dispatch(setAfterValue(0, 'clear'));
    dispatch(change('AaveManageCollateralForm', 'withdrawAmount', null));
    await dispatch(fetchAaveAssetsData());
    await dispatch(fetchAaveAccountData());
    dispatch(getMaxWithdrawAction());
    trackEvent('aaveManage', 'withdrawSuccess');
  } catch (err) {
    dispatch({ type: aaveManageTypes.AAVE_WITHDRAW_FAILURE, payload: err.message });
    trackEvent('aaveManage', 'withdrawError', err.message);
    captureException(err);
  }
};

/**
 * Handles redux actions for the aave supply tab supply action
 *
 * @param contextAction {Object}
 * @param additionalAction {Object}
 *
 * @return {Function}
 */
export const aaveSupplyForAccountAction = (contextAction, additionalAction) => async (dispatch, getState) => {
  const {
    general: {
      walletType, account, accountType, path,
    },
    aaveManage,
  } = getState();

  const { selectedMarket } = aaveManage;
  const { assetsData } = aaveManage[selectedMarket.value];
  const { usedAssets } = aaveManage[walletType.value][selectedMarket.value];

  const {
    firstInput: _amount, firstInputSelect: fromAsset,
  } = dispatch(getDashboardInputs(contextAction, additionalAction));

  const asset = fromAsset?.value;

  trackEvent('aaveManage', 'supply');
  dispatch({ type: aaveManageTypes.AAVE_SUPPLY_REQUEST });


  const sendTxFunc = (promise, waitForSign) => {
    const amount = formatNumber(parseFloat(_amount), 2);
    const additionalInfo = {
      protocol: 'aave',
      firstToken: asset,
      firstAmount: _amount,
    };

    return sendTx(promise, `${t('common.supply')}: ${amount} ${asset}`, 'Aave', dispatch, getState, additionalInfo, waitForSign);
  };

  try {
    if (usedAssets[asset]?.isBorrowed && !usedAssets[asset]?.isSupplied) {
      const confirmed = await dispatch(confirmViaModal(t('compound.warning_supplying_borrowed')));
      if (!confirmed) throw new Error(t('errors.denied_transaction'));
    }

    const txToExecute = [];

    const approveFunctionObject = {
      func: () => dispatch(approveAddressOnAssetAction(asset, selectedMarket.lendingPoolAddress, APPROVE_TYPE_AAVE)),
      type: 'approve',
      notifMessage: `${t('common.approve')} ${approveTypeToLabelMap[APPROVE_TYPE_AAVE]} ${t('common.for')} ${asset}`,
    };

    let supply;
    if (selectedMarket.value === 'v2default') supply = () => supplyV2(accountType, path, sendTxFunc, account, walletType, _amount, asset, selectedMarket);
    if (selectedMarket.value === 'v3default') {
      const usedAssetsData = Object.values(usedAssets);
      const isInIsolationMode = usedAssetsData.find(a => assetsData[a.symbol].isIsolated && a.collateral);
      const hasCollateral = usedAssetsData.find(a => a.collateral);
      const isCurrAssetIsolated = assetsData[asset].isIsolated && usedAssets[asset]?.collateral;

      if (!isInIsolationMode && !hasCollateral) {
        if (assetsData[asset].isIsolated && !usedAssets[asset]?.collateral && !usedAssets[asset]?.isSupplied) {
          const confirmed = await dispatch(confirmViaModal(t('aave.supplying_isolated_asset')));
          if (!confirmed) throw new Error(t('errors.denied_transaction'));
        }
      }
      if (assetsData[asset].isIsolated && !isInIsolationMode && usedAssetsData.find(a => a.collateral)) {
        const confirmed = await dispatch(confirmViaModal(t('aave.supplying_isolated_asset_disabled_coll', { '%asset': asset })));
        if (!confirmed) throw new Error(t('errors.denied_transaction'));
      }
      if (isInIsolationMode && !isCurrAssetIsolated) {
        const confirmed = await dispatch(confirmViaModal(t('aave.supplying_while_isolated', { '%asset': asset })));
        if (!confirmed) throw new Error(t('errors.denied_transaction'));
      }
      if (assetsData[asset].isSiloed && !usedAssets[asset]?.isSupplied) {
        const confirmed = await dispatch(confirmViaModal(t('aave.supplying_while_siloed', { '%asset': asset })));
        if (!confirmed) throw new Error(t('errors.denied_transaction'));
      }

      supply = () => supplyV3(accountType, path, sendTxFunc, account, walletType, _amount, asset, assetsData[asset].assetId, selectedMarket);
    }

    const supplyFunctionObject = {
      func: () => supply(),
      type: 'supply',
      notifMessage: `${t('common.supply')}: ${formatNumber(parseFloat(_amount), 2)} ${asset}`,
    };

    await dispatch(addApproveTxIfNeeded(asset, selectedMarket.lendingPoolAddress, _amount, APPROVE_TYPE_AAVE, txToExecute, approveFunctionObject));
    txToExecute.push(supplyFunctionObject);
    await dispatch(callMultipleTxAction(txToExecute));

    dispatch({ type: aaveManageTypes.AAVE_SUPPLY_SUCCESS });

    dispatch(setAfterValue(0, 'clear'));
    dispatch(change('aaveManageCollateralForm', 'supplyAmount', null));
    await dispatch(fetchAaveAssetsData());
    await dispatch(fetchAaveAccountData());
    dispatch(getMaxSupplyAction());
    trackEvent('aaveManage', 'supplySuccess');
  } catch (err) {
    dispatch({ type: aaveManageTypes.AAVE_SUPPLY_FAILURE, payload: err.message });
    trackEvent('aaveManage', 'supplyError', err.message);
    captureException(err);
  }
};

/**
 * Handles redux actions for the aave borrow tab borrow action
 *
 * @param contextAction {Object}
 * @param additionalAction {Object}
 *
 * @return {Function}
 */
export const aaveBorrowForAccountAction = (contextAction, additionalAction) => async (dispatch, getState) => {
  const {
    general: {
      walletType, account, accountType, path,
    },
    maker: { proxyAddress },
    aaveManage,
  } = getState();

  const { selectedMarket } = aaveManage;
  const { usedAssets } = aaveManage[walletType.value][selectedMarket.value];

  const {
    firstInput: _amount, firstInputSelect: fromAsset,
  } = dispatch(getDashboardInputs(contextAction, additionalAction));

  const asset = fromAsset?.value;

  trackEvent('aaveManage', 'borrow');
  dispatch({ type: aaveManageTypes.AAVE_BORROW_REQUEST });

  const sendTxFunc = (promise, waitForSign) => {
    const amount = formatNumber(parseFloat(_amount), 2);
    const additionalInfo = {
      protocol: 'aave',
      firstToken: asset,
      firstAmount: _amount,
    };
    return sendTx(promise, `${t('common.borrow')}: ${amount} ${asset}`, 'Aave', dispatch, getState, additionalInfo, waitForSign);
  };

  try {
    if (usedAssets[asset]?.isSupplied && !usedAssets[asset]?.isBorrowed) {
      const confirmed = await dispatch(confirmViaModal(t('compound.warning_borrowing_supplied')));
      if (!confirmed) throw new Error(t('errors.denied_transaction'));
    }

    const interestRate = await dispatch(chooseAaveBorrowInterestRateMode(_amount, asset, usedAssets, selectedMarket.value));
    if (!interestRate) throw new Error('User canceled interest rate modal.');
    const { name, value } = interestRate;

    const txToExecute = [];
    const approveFunctionObject = {
      type: 'approve',
      notifMessage: `${t('common.approve')} Aave WETH Adapter`,
    };
    const borrowFunctionObject = {
      type: 'borrow',
      notifMessage: `${t('common.borrow')}: ${formatNumber(parseFloat(_amount), 2)} ${asset} with ${name}`,
    };

    if (selectedMarket.value === 'v2default') {
      approveFunctionObject.func = () => dispatch(approveWethAdapterAction(value === 1, selectedMarket.value));
      borrowFunctionObject.func = () => borrowV2(accountType, path, sendTxFunc, account, walletType, _amount, value, asset, selectedMarket);
      if (!isWalletTypeProxy(walletType) && asset === 'ETH') await dispatch(addApproveWethAdapterIfNeededAction(value === 1, txToExecute, approveFunctionObject, selectedMarket.value));
    } else if (selectedMarket.value === 'v3default') {
      approveFunctionObject.func = () => dispatch(approveWethAdapterAction(value === 1, selectedMarket.value));
      const { assetsData } = getState().aaveManage[selectedMarket.value];
      borrowFunctionObject.func = () => borrowV3(accountType, path, sendTxFunc, account, walletType, _amount, value, asset, assetsData[asset].assetId, selectedMarket);
      if (!isWalletTypeProxy(walletType) && asset === 'ETH') await dispatch(addApproveWethAdapterIfNeededAction(value === 1, txToExecute, approveFunctionObject, selectedMarket.value));
    }
    txToExecute.push(borrowFunctionObject);

    await dispatch(callMultipleTxAction(txToExecute));

    dispatch({ type: aaveManageTypes.AAVE_BORROW_SUCCESS });
    dispatch(setAfterValue(0, 'clear'));

    dispatch(change('aaveManageDebtForm', 'borrowAmount', null));
    await dispatch(fetchAaveAssetsData());
    await dispatch(fetchAaveAccountData());
    dispatch(getMaxBorrowAction());
    trackEvent('aaveManage', 'borrowSuccess');
  } catch (err) {
    dispatch({ type: aaveManageTypes.AAVE_BORROW_FAILURE, payload: err.message });
    trackEvent('aaveManage', 'borrowError', err.message);
    captureException(err);
  }
};

/**
 * Handles redux actions for the aave borrow tab payback action
 *
 * @param contextAction {Object}
 * @param additionalAction {Object}
 *
 * @return {Function}
 */
export const aavePaybackForAccountAction = (contextAction, additionalAction) => async (dispatch, getState) => {
  const {
    general: {
      walletType, account, accountType, path,
    },
    maker: { proxyAddress },
    aaveManage,
  } = getState();

  const { selectedMarket } = aaveManage;
  const { usedAssets } = aaveManage[walletType.value][selectedMarket.value];

  const {
    firstInput: _amount, firstInputSelect: fromAsset,
  } = dispatch(getDashboardInputs(contextAction, additionalAction));

  const asset = fromAsset.value;
  let _borrowed = usedAssets[asset].borrowed;

  trackEvent('aaveManage', 'payback');
  dispatch({ type: aaveManageTypes.AAVE_PAYBACK_REQUEST });

  const sendTxFunc = (promise, waitForSign) => {
    const amount = formatNumber(parseFloat(_amount), 2);
    const additionalInfo = {
      protocol: 'aave',
      firstToken: asset,
      firstAmount: _amount,
    };
    return sendTx(promise, `${t('common.payback')}: ${amount} ${asset}`, 'Aave', dispatch, getState, additionalInfo, waitForSign);
  };

  try {
    const txToExecute = [];

    const approveFunctionObject = {
      func: () => dispatch(approveAddressOnAssetAction(asset, selectedMarket.lendingPoolAddress, APPROVE_TYPE_AAVE)),
      type: 'approve',
      notifMessage: `${t('common.approve')} ${approveTypeToLabelMap[APPROVE_TYPE_AAVE]} ${t('common.for')} ${asset}`,
    };

    const paybackFunctionObject = {
      type: 'payback',
      notifMessage: `${t('common.payback')}: ${formatNumber(parseFloat(_amount), 2)} ${asset}`,
    };

    let intRate;
    if (fromAsset.interestMode === 'both') {
      const isStable = fromAsset.additionalLabel === 'Stable';
      intRate = isStable ? '1' : '2';
      _borrowed = isStable ? usedAssets[asset].borrowedStable : usedAssets[asset].borrowedVariable;
    } else {
      intRate = fromAsset.interestMode;
    }

    if (selectedMarket.value === 'v2default') {
      paybackFunctionObject.func = () => paybackV2(accountType, path, sendTxFunc, account, walletType, _amount, asset, _borrowed, intRate, selectedMarket);
    } else if (selectedMarket.value === 'v3default') {
      const { assetsData } = getState().aaveManage[selectedMarket.value];
      paybackFunctionObject.func = () => paybackV3(accountType, path, sendTxFunc, account, walletType, _amount, asset, assetsData[asset].assetId, _borrowed, intRate, selectedMarket);
    }

    await dispatch(addApproveTxIfNeeded(asset, selectedMarket.lendingPoolAddress, _amount, APPROVE_TYPE_AAVE, txToExecute, approveFunctionObject));
    txToExecute.push(paybackFunctionObject);
    await dispatch(callMultipleTxAction(txToExecute));

    dispatch({ type: aaveManageTypes.AAVE_PAYBACK_SUCCESS });

    dispatch(setAfterValue(0, 'clear'));
    dispatch(change('aaveManageDebtForm', 'paybackAmount', null));
    await dispatch(fetchAaveAssetsData());
    await dispatch(fetchAaveAccountData());
    dispatch(getMaxPaybackAction());
    trackEvent('aaveManage', 'paybackSuccess');
  } catch (err) {
    dispatch({ type: aaveManageTypes.AAVE_PAYBACK_FAILURE, payload: err.message });
    trackEvent('aaveManage', 'paybackError', err.message);
    captureException(err);
  }
};

export const aaveRepayActionV2 = (contextAction, additionalAction, closeModal) => async (dispatch, getState) => {
  const forAction = 'repay';
  dispatch({ type: aaveManageTypes.AAVE_ACTION_EXEC_REQUEST, action: contextAction.value });

  try {
    const {
      general: { walletType, account, accountType },
      maker: { proxyAddress },
      aaveManage,
      assets,
    } = getState();
    if (!isWalletTypeProxy(walletType)) return dispatch(openAaveProxyMigrationModal());
    const {
      slippagePercent, repayExchangeRate, useFl, flProtocol, ratioTooLow, ratioTooHigh, selectedMarket, maxValues,
      repayAmount, afterValue,
    } = aaveManage;

    const maxWithdraw = maxValues.withdraw;

    aaveManageTrackEventWrapper(getState, 'aaveManage', forAction);

    if (ratioTooLow || ratioTooHigh) {
      const confirmed = await dispatch(confirmViaModal(t('errors.automatic_trigger')));
      if (!confirmed) return false;
    }

    const {
      firstAction, firstInput: inputAmount, secondAction, secondInput, firstInputSelect, firstInputSecondSelect, secondInputSelect,
    } = dispatch(getDashboardInputs(contextAction, additionalAction));


    const withdrawAsset = firstInputSelect.value;
    const paybackAsset = firstInputSecondSelect.value;

    const amount = formatNumber(parseFloat(inputAmount), 2);
    const additionalAmount = formatNumber(parseFloat(secondInput), 2);

    let label = `${firstAction.label}: ${amount} ${firstInputSelect.value}`;
    if (secondAction && secondAction.value !== 'send') label += ` + ${secondAction.label}: ${additionalAmount} ${secondInputSelect.value}`;

    const sendTxFunc = (promise) => {
      const additionalInfo = {
        protocol: 'aave',
        firstToken: withdrawAsset,
        firstAmount: inputAmount,
        secondToken: paybackAsset,
        secondAmount: repayAmount,
      };

      return sendTx(promise, label, 'aave', dispatch, getState, additionalInfo);
    };

    const primarySecondInputInterestRate = firstInputSecondSelect.interestMode === 'both'
      ? (firstInputSecondSelect.additionalLabel === 'Stable' ? '1' : '2')
      : firstInputSecondSelect.interestMode;

    const { assetsData } = aaveManage[selectedMarket.value];
    const { usedAssets } = aaveManage[walletType.value][selectedMarket.value];

    let additionalActions = [];

    if (secondAction) {
      let secondaryInputInterestRate = '';

      if (secondAction.value === 'borrow') {
        const interestRate = await dispatch(chooseAaveBorrowInterestRateMode(secondInput, secondInputSelect.value, { ...usedAssets, ...afterValue?.usedAssets }, selectedMarket.value));
        if (!interestRate) throw new Error('User canceled interest rate modal.');
        secondaryInputInterestRate = interestRate.value;
      }

      if (selectedMarket.value === 'v2default') {
        additionalActions = await getAaveV2Action(secondAction.value, selectedMarket, assetAmountInWei(secondInput, secondInputSelect?.value), secondInputSelect.address, secondaryInputInterestRate, account, proxyAddress, usedAssets);
      }
      if (selectedMarket.value === 'v3default') {
        additionalActions = await getAaveV3Action(secondAction.value, selectedMarket, assetAmountInWei(secondInput, secondInputSelect?.value), secondInputSelect.address, assetsData[secondInputSelect?.value].assetId, secondaryInputInterestRate, true, account, proxyAddress, usedAssets, assetsData, assets);
      }
    }

    let recipe;

    if (selectedMarket.value === 'v2default') {
      const borrowed = usedAssets[paybackAsset].borrowed;
      const supplied = usedAssets[withdrawAsset].supplied;
      recipe = useFl
        ? await aaveRecipes.repayWithCollLoan(withdrawAsset, paybackAsset, inputAmount, selectedMarket, primarySecondInputInterestRate, repayExchangeRate, slippagePercent, proxyAddress, account, flProtocol, borrowed, maxWithdraw, additionalActions)
        : await aaveRecipes.repay(withdrawAsset, paybackAsset, inputAmount, selectedMarket, primarySecondInputInterestRate, repayExchangeRate, slippagePercent, proxyAddress, account, supplied, borrowed, additionalActions);
    } else if (selectedMarket.value === 'v3default') {
      const borrowed = usedAssets[paybackAsset].borrowed;
      const supplied = usedAssets[withdrawAsset].supplied;
      const supplyId = assetsData[withdrawAsset].assetId;
      const borrowId = assetsData[paybackAsset].assetId;

      recipe = useFl
        ? await aaveRecipesV3.repayV3WithCollLoan(withdrawAsset, supplyId, paybackAsset, borrowId, inputAmount, selectedMarket, primarySecondInputInterestRate, repayExchangeRate, slippagePercent, proxyAddress, account, flProtocol, borrowed, maxWithdraw, additionalActions)
        : await aaveRecipesV3.repayV3(withdrawAsset, supplyId, paybackAsset, borrowId, inputAmount, selectedMarket, primarySecondInputInterestRate, repayExchangeRate, slippagePercent, proxyAddress, account, supplied, borrowed, additionalActions);
    }

    const txToExecute = [];
    await dispatch(addApproveForActionWithSelectIfNeeded(firstAction, inputAmount, firstInputSelect, proxyAddress, txToExecute));
    if (secondAction) await dispatch(addApproveForActionWithSelectIfNeeded(secondAction, secondInput, secondInputSelect, proxyAddress, txToExecute));
    txToExecute.push({
      func: () => callRecipeViaProxy(accountType, sendTxFunc, proxyAddress, account, recipe, 0, recipe.extraGas),
      notifMessage: label,
    });
    await dispatch(callMultipleTxAction(txToExecute));

    dispatch(postAction(contextAction, secondAction, firstAction));
    closeModal();

    aaveManageTrackEventWrapper(getState, 'aaveManage', `${forAction}Success`);
  } catch (err) {
    dispatch({ type: aaveManageTypes.AAVE_ACTION_EXEC_FAILURE, action: contextAction.value, payload: err.message });
    aaveManageTrackEventWrapper(getState, 'aaveManage', `${forAction}Error`, err.message);
    captureException(err);
  }
};

export const aaveBoostActionV2 = (contextAction, additionalAction, closeModal) => async (dispatch, getState) => {
  const forAction = 'boost';
  dispatch({ type: aaveManageTypes.AAVE_ACTION_EXEC_REQUEST, action: contextAction.value });

  try {
    const {
      general: { walletType, account, accountType },
      maker: { proxyAddress },
      aaveManage,
      assets,
    } = getState();

    const {
      slippagePercent, boostExchangeRate, useFl, flProtocol, ratioTooLow, ratioTooHigh,
      selectedMarket, selectedBorrowInterestRate, boostAmount,
    } = aaveManage;

    if (!isWalletTypeProxy(walletType)) return dispatch(openAaveProxyMigrationModal());

    aaveManageTrackEventWrapper(getState, 'aaveManage', forAction);

    if (ratioTooLow || ratioTooHigh) {
      const confirmed = await dispatch(confirmViaModal(t('errors.automatic_trigger')));
      if (!confirmed) return false;
    }

    const {
      firstAction, firstInput: inputAmount, secondAction, secondInput, secondInputSelect, firstInputSelect,
      firstInputSecondSelect,
    } = dispatch(getDashboardInputs(contextAction, additionalAction));

    const borrowAsset = firstInputSelect?.value;
    const supplyAsset = firstInputSecondSelect?.value;

    const amount = formatNumber(parseFloat(inputAmount), 2);
    const additionalAmount = formatNumber(parseFloat(secondInput), 2);

    let label = `${firstAction.label}: ${amount} ${firstInputSelect.value}`;
    if (secondAction && secondAction.value !== 'send') label += ` + ${secondAction.label}: ${additionalAmount} ${secondInputSelect.value}`;

    const sendTxFunc = (promise) => {
      const additionalInfo = {
        protocol: 'aave',
        firstToken: borrowAsset,
        firstAmount: inputAmount,
        secondToken: supplyAsset,
        secondAmount: boostAmount,
      };
      return sendTx(promise, label, 'aave', dispatch, getState, additionalInfo);
    };

    const { usedAssets } = aaveManage[walletType.value][selectedMarket.value];
    const { assetsData } = aaveManage[selectedMarket.value];

    let additionalActions = [];
    if (secondAction) {
      const secondaryInputInterestRate = secondInputSelect.interestMode === 'both'
        ? (secondInputSelect.additionalLabel === 'Stable' ? '1' : '2')
        : secondInputSelect.interestMode;

      if (selectedMarket.value === 'v2default') {
        additionalActions = await getAaveV2Action(secondAction.value, selectedMarket, assetAmountInWei(secondInput, secondInputSelect?.value), secondInputSelect.address, secondaryInputInterestRate, account, proxyAddress, usedAssets);
      }
      if (selectedMarket.value === 'v3default') {
        additionalActions = await getAaveV3Action(secondAction.value, selectedMarket, assetAmountInWei(secondInput, secondInputSelect?.value), secondInputSelect.address, assetsData[secondInputSelect?.value].assetId, secondaryInputInterestRate, true, account, proxyAddress, usedAssets, assetsData, assets);
      }
    }

    let recipe;

    if (selectedMarket.value === 'v2default') {
      recipe = useFl
        ? await aaveRecipes.boostWithDebtLoan(supplyAsset, borrowAsset, inputAmount, selectedMarket, selectedBorrowInterestRate.value, boostExchangeRate, slippagePercent, proxyAddress, flProtocol, additionalActions)
        : await aaveRecipes.boost(supplyAsset, borrowAsset, inputAmount, selectedMarket, selectedBorrowInterestRate.value, boostExchangeRate, slippagePercent, proxyAddress, additionalActions);
    } else if (selectedMarket.value === 'v3default') {
      const supplyId = assetsData[supplyAsset].assetId;
      const borrowId = assetsData[borrowAsset].assetId;
      recipe = useFl
        ? await aaveRecipesV3.boostWithDebtLoanV3(supplyAsset, supplyId, borrowAsset, borrowId, inputAmount, selectedMarket, selectedBorrowInterestRate.value, boostExchangeRate, slippagePercent, proxyAddress, flProtocol, additionalActions)
        : await aaveRecipesV3.boostV3(supplyAsset, supplyId, borrowAsset, borrowId, inputAmount, selectedMarket, selectedBorrowInterestRate.value, boostExchangeRate, slippagePercent, proxyAddress, additionalActions);
    }

    const txToExecute = [];
    await dispatch(addApproveForActionWithSelectIfNeeded(firstAction, inputAmount, firstInputSelect, proxyAddress, txToExecute));
    if (secondAction) await dispatch(addApproveForActionWithSelectIfNeeded(secondAction, secondInput, secondInputSelect, proxyAddress, txToExecute));
    txToExecute.push({
      func: () => callRecipeViaProxy(accountType, sendTxFunc, proxyAddress, account, recipe, 0, recipe.extraGas),
      notifMessage: label,
    });
    await dispatch(callMultipleTxAction(txToExecute));

    dispatch(postAction(contextAction, secondAction, firstAction));
    closeModal();

    aaveManageTrackEventWrapper(getState, 'aaveManage', `${forAction}Success`);
  } catch (err) {
    dispatch({ type: aaveManageTypes.AAVE_ACTION_EXEC_FAILURE, action: contextAction.value, payload: err.message });
    aaveManageTrackEventWrapper(getState, 'aaveManage', `${forAction}Error`, err.message);
    captureException(err);
  }
};

/**
 * Sets aave v3 eMode to passed category
 *
 * @param categoryId
 * @returns {(function(*, *): Promise<void>)|*}
 */
export const setEModeAction = (categoryId) => async (dispatch, getState) => { // TODO handle multiple categories
  try {
    const { general: { account, accountType }, maker: { proxyAddress }, aaveManage } = getState();
    const { selectedMarket } = aaveManage;
    const { assetsData } = aaveManage[selectedMarket.value];

    const sendTxTitle = categoryId === 0
      ? 'Disable eMode'
      : `Set eMode to category '${Object.values(assetsData).find(i => i.eModeCategory === categoryId).eModeCategoryData.label}'`;

    const sendTxFunc = (promise, waitForSign) => {
      const additionalInfo = {
        protocol: 'aave',
      };
      return sendTx(promise, sendTxTitle, 'Aave', dispatch, getState, additionalInfo, waitForSign);
    };

    await setEMode(accountType, account, proxyAddress, sendTxFunc, categoryId, selectedMarket);
    dispatch(fetchAaveManageData());
    dispatch(closeModal());
  } catch (err) {
    console.error(err);
  }
};
