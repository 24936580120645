import dfs from '@defisaver/sdk';
import { getAssetInfo, getAssetInfoByAddress } from '@defisaver/tokens';
import { compoundCollateralAssets } from 'constants/assets';
import cloneDeep from 'lodash/cloneDeep';
import RecipeAction from '../RecipeAction';
import {
  Amount, Asset, AssetAmount, Source,
} from '../../components/Recipes/RecipeCreator/inputTypes';
import { supplyAfterValues } from '../../actions/compoundActions/compoundManageAfterValues';
import SupplyIcon from '../recipeIcons/Supply.svg';
import { formatNumber, removeDuplicatesFromArrayOfObjects } from '../../services/utils';
import { assetAmountInWeiIgnorePointer } from '../../services/recipeCreator/recipeActionUtils';
import { MAXUINT } from '../../constants/general';

const compAssets = removeDuplicatesFromArrayOfObjects(compoundCollateralAssets.map(asset => getAssetInfo(asset.underlyingAsset.replace(/^ETH/, 'WETH'))), 'address');

export default class CompoundSupplyAction extends RecipeAction {
  static prettyName = 'Supply collateral to Compound';

  static protocol = 'compound';

  static protocolPrettyName = 'Compound';

  static description = 'Deposits selected asset into Compound.';

  constructor(asset = 'DAI', from = 'wallet', amount = '') {
    super();
    this.inputs = [
      new Asset('Asset', asset, compAssets),
      new Source('Source', from),
      new Amount('Amount', amount),
    ];
    this.output = new AssetAmount('output', 0, asset);
  }

  async getAfterValues(_balances = {}, returnValues = [], actions = [], _positions = {}, getState) {
    const positions = { ..._positions };
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const source = args[1];
    const asset = getAssetInfoByAddress(args[0]);
    let amount = args[2];
    if (!positions.compound) {
      const { usedAssets, assetsData } = getState().compoundManage;
      positions.compound = { usedAssets, assetsData };
    }
    const currentBalances = cloneDeep(_balances);
    const isSourceWallet = args[1] === 'wallet';
    // if asset isn't used in earlier actions
    const balanceAtStart = isSourceWallet ? getState().assets[asset.symbol].balance : '0';

    if (amount === 'All available') amount = currentBalances?.[source]?.[asset.symbol] || balanceAtStart;
    const { afterPosition, balances, returnValue } = await supplyAfterValues(
      {
        amount,
        firstAsset: asset.symbol.replace('WETH', 'ETH'),
        from: args[1],
      },
      {
        usedAssets: positions.compound.usedAssets,
        assetsData: positions.compound.assetsData,
        assets: getState().assets,
        source,
        walletType: { value: 'proxy' },
        account: getState().general.account,
        proxyAddress: getState().maker.proxyAddress,
      },
      _balances,
    );
    positions.compound = afterPosition;
    this.output.value = returnValue;
    this.output.asset = asset.symbol;
    return { returnValue: this.output, balances, positions };
  }

  async toDfsAction(getState) {
    const {
      general: { account },
      maker: { proxyAddress },
    } = getState();
    const from = this.inputs[1].value === 'wallet' ? account : proxyAddress;
    const cAssetAddress = getAssetInfo(`c${this.inputs[0].asset.replace('WETH', 'ETH')}`).address;
    const amount = this.inputs[2].value === 'All available' ? MAXUINT : assetAmountInWeiIgnorePointer(this.inputs[2].value, this.inputs[0].asset);
    return new dfs.actions.compound.CompoundSupplyAction(cAssetAddress, amount, from, true);
  }

  static getIcon() {
    return SupplyIcon;
  }

  _getPrettyName(actionCalls, actions) {
    const args = this.mapReturnValuesToArgs(actionCalls.map(a => a.returnValue), actions);
    return `Supply ${args[2] === 'All available' ? 'all' : formatNumber(args[2])} ${this.inputs[0].asset} to Compound`;
  }

  getAsset() {
    return this.inputs[0].asset;
  }
}
