import React from 'react';
import t from 'translate';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import ModalBody from '../../ModalBody';
import ModalHeader from '../../ModalHeader';
import TransferCdpForm from './TransferCdpForm/TransferCdpForm';
import WarningBox from '../../../Decorative/WarningBox/WarningBox';
import ErrorBox from '../../../Decorative/ErrorBox/ErrorBox';
import { formatEtherScanLink } from '../../../../services/utils';

import './TransferCdpModal.scss';

let TransferCdpModal = ({
  closeModal, cdpId, pristine, invalid, submittingForm, submittingFormError, history,
  formValues, account, isSubscribedToAutomation,
}) => (
  <div id="transfer-cdp-modal-wrapper" className={submittingFormError ? 'form-error' : ''}>
    <ModalHeader closeModal={closeModal} />

    <ModalBody>
      <div className="new-modal-top-wrapper">
        <h1>{t('maker.transfer_cdp')}: #{cdpId}</h1>
      </div>

      <div className="new-modal-content-wrapper">
        <p>{t('maker.transfer_cdp_description')}</p>

        {
          isSubscribedToAutomation && (
            <WarningBox marginBottom>{t('maker.transfer_cdp_automation')}</WarningBox>
          )
        }

        <TransferCdpForm history={history} closeModal={closeModal} />

        {
          formValues.toAddress === account &&
          <ErrorBox marginTop>{t('errors.own_address')}</ErrorBox>
        }
        {
          formValues.toAddress &&
          !window._web3.utils.isAddress(formValues.toAddress) &&
          <ErrorBox marginTop>{t('errors.invalid_address')}</ErrorBox>
        }

        { !submittingForm && submittingFormError && (<ErrorBox marginTop>{submittingFormError}</ErrorBox>) }

        {
          formValues.toAddress && (
            <>
              <WarningBox marginTop>
                {t('maker.transfer_cdp_warning')}
                <br />
                CDP <a target="_blank" rel="noopener noreferrer" href={`https://defiexplore.com/cdp/${cdpId}`}>#{cdpId}</a>
                <br />
                {`${t('common.new_owner')}: `}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={formatEtherScanLink(formValues.toAddress, 'address')}
                >
                  {formValues.toAddress}
                </a>
              </WarningBox>
            </>
          )
        }
      </div>
    </ModalBody>

    <div className="modal-controls">
      <button
        type="submit"
        disabled={
          pristine || invalid || submittingForm || !formValues.toAddress ||
          (formValues.toAddress && !window._web3.utils.isAddress(formValues.toAddress)) ||
          (formValues.toAddress === account)
        }
        className="button green"
        form="transfer-cdp-form"
      >
        { submittingForm ? t('common.transferring') : t('common.transfer') }
      </button>
    </div>
  </div>
);

TransferCdpModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  cdpId: PropTypes.number.isRequired,
  isSubscribedToAutomation: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submittingForm: PropTypes.bool.isRequired,
  submittingFormError: PropTypes.string.isRequired,
  account: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
};

TransferCdpModal = reduxForm({
  form: 'transferCdpForm',
})(TransferCdpModal);

const selector = formValueSelector('transferCdpForm');

const mapStateToProps = state => ({
  formValues: {
    toAddress: selector(state, 'toAddress'),
  },
  cdpId: state.maker.cdp ? state.maker.cdp.id : 0,
  isSubscribedToAutomation: state.maker.cdp ? state.maker.cdp.isSubscribedToAutomation : false,
  account: state.general.account,
  submittingForm: state.makerManage.transferringCdp,
  submittingFormError: state.makerManage.transferringCdpError,
});

export default connect(mapStateToProps)(TransferCdpModal);
