import React, {
  Component, useState, useEffect, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  checkExploitableApprovalsAction,
  withdrawAllFromSafeguardAction,
  deapproveAddressOnAssetAction,
} from '../../actions/assetsActions';
import {
  CONTRACTS_WITH_EXPLOIT,
  ASSETS_WITH_EXPLOIT,
  balancesOnSafeguard,
} from '../../services/assetsService';
import { APPROVE_TYPE_CONTRACT } from '../../actionTypes/assetsActionTypes';
import Loader from '../Loader/Loader';
import PrivateRoute from '../PrivateRoute/PrivateRoute';

import ErrorBox from '../Decorative/ErrorBox/ErrorBox';
import SuccessBox from '../Decorative/SuccessBox/SuccessBox';
import { userDeapprovedApiCall } from '../../services/apiService';

const ExchangeDeapprove = ({
  account, exploitableApprovals, exploitableApprovalsLoading, exploitableApprovalsError,
  checkExploitableApprovalsAction, deapproveAddressOnAssetAction, withdrawAllFromSafeguardAction,
}) => {
  const [loading, setLoading] = useState(true);
  const [balances, setBalances] = useState([]);
  useEffect(() => {
    setLoading(true);
    balancesOnSafeguard(account).then((balances) => {
      console.log(balances);
      setLoading(false);
      setBalances(balances);
    });
  }, [account]);
  const removeApproval = (asset, contract) => {
    deapproveAddressOnAssetAction(asset, contract, APPROVE_TYPE_CONTRACT)
      .then(() => userDeapprovedApiCall(account, 'Exchange'));
  };

  const hasExploitableApprovalExchange = exploitableApprovals.some(a => a.some(b => b));
  const hasBalanceToWithdraw = balances.some(balance => balance !== '0');

  return (
    <div className="dashboard-page-wrapper">
      <div className="content-wrapper">
        <div className="width-container">
          <div className="deapprove-wrapper">
            <p>
              A vulnerability in the DeFi Saver Exchange has been found. Please remove all approvals listed below in order to secure your account.
              <br />
              <br />
              If your funds were moved from your account <b>they are currently on a smart contract from where they can be withdrawn only by your account.</b> You can withdraw your funds below after removing all listed approvals.
              <br />
              <br />
              No other part of DeFi Saver was affected by the found vulnerability.
              <br />
              <br />
              <a target="_blank" rel="noopener noreferrer" href="https://medium.com/defi-saver/disclosing-a-recently-discovered-exchange-vulnerability-fcd0b61edffe">More details</a>
            </p>

            <hr />

            { exploitableApprovalsLoading && <Loader /> }
            {
              !exploitableApprovalsLoading &&
              !hasExploitableApprovalExchange && (
                <SuccessBox>Your account is secure, no action is required.</SuccessBox>
              )
            }
            {
              !exploitableApprovalsLoading &&
              hasExploitableApprovalExchange && (
                <ErrorBox>Action required: your account is not secure.</ErrorBox>
              )
            }
            {
              !exploitableApprovalsLoading &&
              Object.keys(CONTRACTS_WITH_EXPLOIT.exchange).map((contract, i) => (
                exploitableApprovals[i] &&
                CONTRACTS_WITH_EXPLOIT.exchange[contract].map((asset, j) => (
                  exploitableApprovals[i][j]
                    ? (
                      <div key={`${contract}-${asset}`} className="MarginTop10">
                        <button className="button" type="button" onClick={() => removeApproval(asset, contract)}>Remove {asset} approval for {contract}</button>
                      </div>
                    ) : ''
                ))
              ))
            }

            <hr />

            { loading && <Loader /> }
            {
              !loading &&
              hasBalanceToWithdraw && (
                <div>
                  {
                    hasExploitableApprovalExchange && <p>Please remove all approvals to exploitable contracts before withdrawing funds. </p>
                  }
                  <button className="button green" type="button" disabled={hasExploitableApprovalExchange} onClick={withdrawAllFromSafeguardAction}>Withdraw funds from Safeguard</button>
                </div>
              )
            }

            {
              !loading &&
              !hasBalanceToWithdraw && (
                <div>
                  <p>If any of your funds are missing, do not hesitate to contact us via Twitter, Discord or the help widget on the bottom right.</p>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
};

ExchangeDeapprove.defaultProps = {
  exploitableApprovals: [],
};

ExchangeDeapprove.propTypes = {
  account: PropTypes.string.isRequired,
  checkExploitableApprovalsAction: PropTypes.func.isRequired,
  deapproveAddressOnAssetAction: PropTypes.func.isRequired,
  withdrawAllFromSafeguardAction: PropTypes.func.isRequired,
  exploitableApprovals: PropTypes.array,
  exploitableApprovalsLoading: PropTypes.bool.isRequired,
  exploitableApprovalsError: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  account: state.general.account,
  exploitableApprovals: state.general.exploitableApprovals.exchange,
  exploitableApprovalsLoading: state.general.exploitableApprovalsLoading,
  exploitableApprovalsError: state.general.exploitableApprovalsError,
});

const mapDispatchToProps = {
  checkExploitableApprovalsAction,
  deapproveAddressOnAssetAction,
  withdrawAllFromSafeguardAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExchangeDeapprove);
