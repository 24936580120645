/* eslint-disable */
import React from 'react';
import { v4 as uuid } from 'uuid';

export default function CbridgeIcon(props) {
  const id = `cbridge_icon${uuid()}`;
  const fill = `url(#${id})`;
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z" fill={fill}/>
      <path d="M10.7198 18.2132H7.86505C7.86505 18.2132 8.57875 9 15.0345 9C21.4902 9 22.0741 18.2132 22.0741 18.2132H19.3491C19.3491 18.2132 19.0247 12.2441 15.0345 12.2441C11.0442 12.2441 10.7198 18.2132 10.7198 18.2132Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M7.60547 18.4041H10.9793C10.9793 18.4041 10.9721 17.741 11.1102 16.8567C12.0142 17.2344 12.6014 17.8449 12.6014 18.5338C12.6014 19.6805 10.9747 20.6101 8.96802 20.6101C6.96137 20.6101 5.33466 19.6805 5.33466 18.5338C5.33466 17.593 6.42972 16.7984 7.93122 16.5434C7.67579 17.636 7.60547 18.4041 7.60547 18.4041Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M22.3985 18.4041H19.0246C19.0246 18.4041 19.0319 17.741 18.8937 16.8568C17.9898 17.2344 17.4027 17.845 17.4027 18.5339C17.4027 19.6805 19.0294 20.6101 21.036 20.6101C23.0427 20.6101 24.6694 19.6805 24.6694 18.5339C24.6694 17.593 23.5743 16.7984 22.0727 16.5434C22.3282 17.636 22.3985 18.4041 22.3985 18.4041Z" fill="white"/>
      <defs>
        <linearGradient id={id} x1="15" y1="0" x2="15" y2="30" gradientUnits="userSpaceOnUse">
          <stop stopColor="#4065F6"/>
          <stop offset="1" stopColor="#293E92"/>
        </linearGradient>
      </defs>
    </svg>
  );
}
