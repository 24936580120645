import {
  getMaxBorrow, getMaxPayback, getMaxRepay,
} from 'services/liquityServices/liquityMaxGettersService';
import * as vaultCommonService from 'services/vaultCommonService';
import { captureException } from '../../sentry';
import { getAssetBalanceAction } from '../assetsActions';
import {
  LQTY_MAX_ADD_COLLATERAL_REQUEST,
  LQTY_MAX_ADD_COLLATERAL_SUCCESS,
  LQTY_MAX_ADD_COLLATERAL_FAILURE,

  LQTY_MAX_WITHDRAW_COLLATERAL_REQUEST,
  LQTY_MAX_WITHDRAW_COLLATERAL_SUCCESS,
  LQTY_MAX_WITHDRAW_COLLATERAL_FAILURE,

  LQTY_MAX_GENERATE_REQUEST,
  LQTY_MAX_GENERATE_SUCCESS,
  LQTY_MAX_GENERATE_FAILURE,

  LQTY_MAX_PAYBACK_REQUEST,
  LQTY_MAX_PAYBACK_SUCCESS,
  LQTY_MAX_PAYBACK_FAILURE,

  LQTY_GET_MAX_BOOST_REQUEST,
  LQTY_GET_MAX_BOOST_SUCCESS,
  LQTY_GET_MAX_BOOST_FAILURE,

  LQTY_GET_MAX_REPAY_REQUEST,
  LQTY_GET_MAX_REPAY_SUCCESS,
  LQTY_GET_MAX_REPAY_FAILURE,
} from '../../actionTypes/liquityActionTypes/liquityManageActionTypes';

const getMaxAddCollateralAction = () => async (dispatch, getState) => {
  dispatch({ type: LQTY_MAX_ADD_COLLATERAL_REQUEST });
  try {
    const {
      liquity,
      general: { walletType },
    } = getState();
    const payload = await dispatch(getAssetBalanceAction(liquity[walletType.value].asset)); // todo memoize this
    dispatch({ type: LQTY_MAX_ADD_COLLATERAL_SUCCESS, payload });
  } catch (err) {
    dispatch({ type: LQTY_MAX_ADD_COLLATERAL_FAILURE, payload: err.message });
    captureException(err);
  }
};

const getMaxWithdrawAction = () => async (dispatch, getState) => {
  dispatch({ type: LQTY_MAX_WITHDRAW_COLLATERAL_REQUEST });
  try {
    const {
      liquity,
      general: { walletType },
    } = getState();
    const payload = vaultCommonService.getMaxWithdraw(liquity[walletType.value]);
    dispatch({ type: LQTY_MAX_WITHDRAW_COLLATERAL_SUCCESS, payload });
  } catch (err) {
    dispatch({ type: LQTY_MAX_WITHDRAW_COLLATERAL_FAILURE, payload: err.message });
    captureException(err);
  }
};

const getMaxGenerateAction = () => async (dispatch, getState) => {
  dispatch({ type: LQTY_MAX_GENERATE_REQUEST });

  try {
    const {
      liquity,
      general: { walletType },
    } = getState();
    const payload = await getMaxBorrow(liquity[walletType.value]);
    dispatch({ type: LQTY_MAX_GENERATE_SUCCESS, payload });
  } catch (err) {
    dispatch({ type: LQTY_MAX_GENERATE_FAILURE, payload: err.message });
    captureException(err);
  }
};

const getMaxPaybackAction = () => async (dispatch, getState) => {
  dispatch({ type: LQTY_MAX_PAYBACK_REQUEST });

  try {
    const {
      liquity,
      general: { walletType },
    } = getState();
    const trove = liquity[walletType.value];
    const assetBalance = await dispatch(getAssetBalanceAction(trove.debtAsset));
    const payload = getMaxPayback(trove, assetBalance);

    dispatch({ type: LQTY_MAX_PAYBACK_SUCCESS, payload });
  } catch (err) {
    dispatch({ type: LQTY_MAX_PAYBACK_FAILURE, payload: err.message });
    captureException(err);
  }
};

const getMaxBoostAction = () => async (dispatch, getState) => {
  dispatch({ type: LQTY_GET_MAX_BOOST_REQUEST });

  try {
    const { liquity, general: { account }, maker: { proxyAddress } } = getState();
    const trove = liquity.proxy;
    const buffer = 0.06; // 0.01 sell slippage + 0.05 liquity borrow slippage
    const payload = proxyAddress ? await vaultCommonService.getMaxBoost(trove, account, buffer) : '0';

    dispatch({ type: LQTY_GET_MAX_BOOST_SUCCESS, payload });
  } catch (err) {
    dispatch({ type: LQTY_GET_MAX_BOOST_FAILURE, payload: err.message });
    captureException(err);
  }
};

const getMaxRepayAction = () => async (dispatch, getState) => {
  dispatch({ type: LQTY_GET_MAX_REPAY_REQUEST });

  try {
    const { liquity, general: { account }, maker: { proxyAddress } } = getState();
    const trove = liquity.proxy;
    const payload = proxyAddress ? await getMaxRepay(trove, account) : '0';

    dispatch({ type: LQTY_GET_MAX_REPAY_SUCCESS, payload });
  } catch (err) {
    dispatch({ type: LQTY_GET_MAX_REPAY_FAILURE, payload: err.message });
    captureException(err);
  }
};

export const getDebtFormMaxValues = () => (dispatch) => {
  dispatch(getMaxGenerateAction());
  dispatch(getMaxPaybackAction());
};

export const getCollateralFormMaxValues = () => (dispatch) => {
  dispatch(getMaxAddCollateralAction());
  dispatch(getMaxWithdrawAction());
};

export const getAdvancedFormMaxValues = () => (dispatch) => {
  dispatch(getMaxBoostAction());
  dispatch(getMaxRepayAction());
};
