import dfs from '@defisaver/sdk';
import { assetAmountInWei } from '@defisaver/tokens';
import Dec from 'decimal.js';
import RecipeAction from '../RecipeAction';
import { Amount, AssetAmount, Source } from '../../components/Recipes/RecipeCreator/inputTypes';
import { openAfterValues } from '../../actions/liquityActions/liquityManageAfterValues';
import {
  findInsertPosition,
  getBorrowingFeePercentage,
  getExpectedFee,
  getTroveInfo,
} from '../../services/liquityServices/liquityService';
import { ethToWei } from '../../services/ethService';
import { LIQUITY_LIQ_RESERVE_LUSD } from '../../constants/liquity';
import CreateIcon from '../recipeIcons/Create.svg';
import { MAXUINT } from '../../constants/general';


export default class LiquityOpenAction extends RecipeAction {
  static prettyName = 'Open new Trove';

  static protocol = 'liquity';

  static protocolPrettyName = 'Liquity';

  static description = 'Creates a new Trove, deposits (W)ETH and borrows LUSD.';

  constructor(from = 'wallet', collateral = '', debt = '', to = 'wallet') {
    super();
    this.inputs = [
      new Source('From', from),
      new AssetAmount('Collateral', collateral, 'WETH'),
      new AssetAmount('Debt', debt, 'LUSD'),
      new Source('To', to, true),
    ];
    this.output = new Amount('output', 0);
  }

  async getAfterValues(_balances = {}, returnValues = [], actions = [], _positions = {}, getState) {
    const positions = { ..._positions };
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const {
      liquity: { proxy },
      liquityStaking: { proxy: stakingProxy },
    } = getState();
    if (!positions.liquity) positions.liquity = { ...proxy, ...stakingProxy };
    const source = args[0];
    const dest = args[3];
    let collAmount = args[1] || '0';
    const debtAmount = args[2] || '0';
    const balanceAtStart = source === 'wallet' ? getState().assets.WETH.balance : '0';
    if (collAmount === 'All available') collAmount = _balances?.[source]?.WETH || balanceAtStart;

    const { afterPosition, balances, returnValue } = await openAfterValues({
      collAmount,
      debtAmount,
      from: source,
      to: dest,
    }, {
      vault: positions.liquity,
      assets: getState().assets,
      account: getState().general.account,
      proxyAddress: getState().maker.proxyAddress,
    },
    _balances);
    positions.liquity = afterPosition;
    this.output.value = returnValue;
    return { returnValue: this.output, balances, positions };
  }

  async toDfsAction(getState, actions, returnValues, positions) {
    const {
      general: { account },
      maker: { proxyAddress },
    } = getState();
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const collAmount = args[1] === 'All available' ? MAXUINT : assetAmountInWei(args[1], 'WETH');
    const debtAmount = assetAmountInWei(args[2], 'LUSD');
    const fee = await getExpectedFee(debtAmount);
    const liquidationReserve = ethToWei(LIQUITY_LIQ_RESERVE_LUSD);
    const expectedDebt = new Dec(debtAmount).add(fee).add(liquidationReserve).toString();
    const { amount: maxFeePercentage, showWarning } = await getBorrowingFeePercentage();
    const { lowerHint, upperHint } = await findInsertPosition(collAmount, expectedDebt, proxyAddress);
    const fromAddress = args[0] === 'wallet' ? account : proxyAddress;
    const toAddress = args[3] === 'wallet' ? account : proxyAddress;
    return new dfs.actions.liquity.LiquityOpenAction(ethToWei(maxFeePercentage), collAmount, debtAmount, fromAddress, toAddress, upperHint, lowerHint);
  }

  setAsset(asset) {
    this.asset = asset;
  }

  getAsset() {
    return this.asset;
  }

  static getIcon() {
    return CreateIcon;
  }
}
