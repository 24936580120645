import clientConfig from 'config/clientConfig.json';
import {
  CONNECT_PROVIDER,
  CONNECT_PROVIDER_SUCCESS,
  CONNECT_PROVIDER_FAILURE,

  LOGIN_STARTED,
  LOGIN_FINISHED,

  SUBSCRIBE_COMING_SOON_REQUEST,
  SUBSCRIBE_COMING_SOON_SUCCESS,
  SUBSCRIBE_COMING_SOON_FAILURE,
  RESET_SUBSCRIBE_COMING_SOON,

  SUBMIT_CONTACT_US_REQUEST,
  SUBMIT_CONTACT_US_SUCCESS,
  SUBMIT_CONTACT_US_FAILURE,
  RESET_CONTACT_US,

  CHANGE_LEDGER_ACC_TYPE,

  LIST_LEDGER_ACCOUNTS_REQUEST,
  LIST_LEDGER_ACCOUNTS_SUCCESS,
  LIST_LEDGER_ACCOUNTS_FAILURE,

  SET_LEDGER_PATH,

  LEDGER_LIST_MORE_ACCOUNTS_REQUEST,
  LEDGER_LIST_MORE_ACCOUNTS_SUCCESS,
  LEDGER_LIST_MORE_ACCOUNTS_FAILURE,

  RESET_CONNECT_LEDGER,

  SET_ENS_NAME,

  CLEAR_ACCOUNT_TYPE,

  GET_GAS_PRICES_REQUEST,
  GET_GAS_PRICES_SUCCESS,
  GET_GAS_PRICES_FAILURE,
  GAS_PRICES_RESET,

  GET_GAS_PRICE_HISTORY_REQUEST,
  GET_GAS_PRICE_HISTORY_SUCCESS,
  GET_GAS_PRICE_HISTORY_FAILURE,

  CHANGE_WALLET_TYPE,

  CONNECT_VIEW_ONLY_ACCOUNT_REQUEST,
  CONNECT_VIEW_ONLY_ACCOUNT_SUCCESS,
  CONNECT_VIEW_ONLY_ACCOUNT_FAILURE,

  SET_HIDE_HINTS,
  UPDATE_RECENT_ACCOUNTS,
  SET_APP_BACKDROP,

  CHANGE_NETWORK,
} from '../actionTypes/generalActionTypes';
import {
  LS_ACCOUNT, LEDGER_ACC_TYPES, WALLET_TYPES_OPTIONS,
} from '../constants/general';
import {
  EXPLOITABLE_APPROVALS_FAILURE,
  EXPLOITABLE_APPROVALS_REQUEST,
  EXPLOITABLE_APPROVALS_SUCCESS,
} from '../actionTypes/assetsActionTypes';
import { GET_PROXY_ADDRESS_SUCCESS } from '../actionTypes/makerActionTypes/makerActionTypes';
import { getRecentAccountsWithState } from '../services/localStorageService';

const lsAccountType = localStorage.getItem(LS_ACCOUNT);

const INITIAL_STATE = {
  loggingIn: false,
  loggingInAccountType: '',

  connectingProvider: false,

  account: '',
  ensName: '',
  accountError: '',
  accountType: lsAccountType || '',
  forkAccountType: '',
  forkCreated: 0,
  forkId: '',
  network: clientConfig.network,
  path: '',
  walletType: WALLET_TYPES_OPTIONS[0],
  smartWallets: [],
  additionalAccountData: {},
  recentAccounts: getRecentAccountsWithState(),

  subscribingComingSoon: false,
  subscribingComingSoonSuccess: false,
  subscribingComingSoonError: '',

  sendingContactUs: false,
  sendingContactUsError: '',

  ledgerAccType: LEDGER_ACC_TYPES[0],
  ledgerAccounts: [],
  listingLedgerAccounts: false,
  listingLedgerAccountsError: '',

  listingMoreLedgerAccounts: false,
  listingMoreLedgerAccountsError: '',

  gettingGasPrices: false,
  gettingGasPricesError: '',
  gasPrices: null,

  exploitableApprovals: {},
  exploitableApprovalsLoading: false,
  exploitableApprovalsError: '',

  gettingGasPriceHistory: false,
  gettingGasPriceHistoryError: '',
  gasPriceHistory: [],

  connectingViewOnly: false,
  connectingViewOnlyError: '',

  hideHints: false,
  appBackdropOpen: false,
  appBackdropClass: '',
  appBackdropOnClick: () => {},
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case CONNECT_PROVIDER:
      return { ...state, connectingProvider: true };

    case CONNECT_PROVIDER_SUCCESS:
      return {
        ...state,
        connectingProvider: false,
        accountError: '',
        ensName: '',
        account: payload.account,
        accountType: payload.accountType,
        network: payload.network,
        path: payload.path || '',
        forkAccountType: payload.forkAccountType || '',
        forkCreated: payload.forkCreated || 0,
        forkId: payload.forkId || '',
        smartWallets: payload.smartWallets || [],
        additionalAccountData: payload.additionalAccountData || {},
        recentAccounts: getRecentAccountsWithState(),
      };

    case UPDATE_RECENT_ACCOUNTS:
      return {
        ...state,
        recentAccounts: payload,
      };

    case CONNECT_PROVIDER_FAILURE:
      return {
        ...state,
        connectingProvider: false,
        connectingAccountType: '',
        accountError: payload,
      };

    case GET_PROXY_ADDRESS_SUCCESS:
      return {
        ...state,
        smartWallets: payload,
      };

    case LOGIN_STARTED:
      return { ...state, loggingIn: true, loggingInAccountType: payload };

    case LOGIN_FINISHED:
      return { ...state, loggingIn: false, loggingInAccountType: '' };

    case SUBSCRIBE_COMING_SOON_REQUEST:
      return { ...state, subscribingComingSoon: true, subscribingComingSoonError: '' };

    case SUBSCRIBE_COMING_SOON_SUCCESS:
      return {
        ...state,
        subscribingComingSoon: false,
        subscribingComingSoonSuccess: true,
        subscribingComingSoonError: '',
      };

    case SUBSCRIBE_COMING_SOON_FAILURE:
      return {
        ...state,
        subscribingComingSoon: false,
        subscribingComingSoonSuccess: false,
        subscribingComingSoonError: payload,
      };

    case RESET_SUBSCRIBE_COMING_SOON:
      return {
        ...state,
        subscribingComingSoon: false,
        subscribingComingSoonSuccess: false,
        subscribingComingSoonError: '',
      };

    case SUBMIT_CONTACT_US_REQUEST:
      return { ...state, sendingContactUs: true, sendingContactUsError: '' };

    case SUBMIT_CONTACT_US_SUCCESS:
      return {
        ...state,
        sendingContactUs: false,
        sendingContactUsError: '',
      };

    case SUBMIT_CONTACT_US_FAILURE:
      return {
        ...state,
        sendingContactUs: false,
        sendingContactUsError: payload,
      };

    case RESET_CONTACT_US:
      return {
        ...state,
        sendingContactUs: false,
        sendingContactUsError: '',
      };

    case CHANGE_LEDGER_ACC_TYPE:
      return { ...state, ledgerAccType: payload };

    case LIST_LEDGER_ACCOUNTS_REQUEST:
      return { ...state, listingLedgerAccounts: true, listingLedgerAccountsError: '' };

    case LIST_LEDGER_ACCOUNTS_SUCCESS:
      return {
        ...state,
        listingLedgerAccounts: false,
        listingLedgerAccountsError: '',
        ledgerAccounts: payload,
      };

    case LIST_LEDGER_ACCOUNTS_FAILURE:
      return {
        ...state,
        listingLedgerAccounts: false,
        listingLedgerAccountsError: payload,
      };

    case SET_LEDGER_PATH:
      return { ...state, path: payload };

    case LEDGER_LIST_MORE_ACCOUNTS_REQUEST:
      return { ...state, listingMoreLedgerAccounts: true, listingMoreLedgerAccountsError: '' };

    case LEDGER_LIST_MORE_ACCOUNTS_SUCCESS:
      return {
        ...state,
        listingMoreLedgerAccounts: false,
        listingMoreLedgerAccountsError: '',
        ledgerAccounts: payload,
      };

    case LEDGER_LIST_MORE_ACCOUNTS_FAILURE:
      return {
        ...state,
        listingMoreLedgerAccounts: false,
        listingMoreLedgerAccountsError: payload,
      };

    case RESET_CONNECT_LEDGER:
      return {
        ...state,
        ledgerAccounts: [],
        listingLedgerAccounts: false,
        listingLedgerAccountsError: '',
        listingMoreLedgerAccounts: false,
        listingMoreLedgerAccountsError: '',
      };

    case SET_ENS_NAME:
      if (action.account !== state.account) return state;
      return {
        ...state,
        additionalAccountData: {
          ...state.additionalAccountData,
          ensName: payload,
        },
      };

    case CLEAR_ACCOUNT_TYPE:
      return {
        ...state, account: '', accountType: '', forkAccountType: '',
      };

    case GET_GAS_PRICES_REQUEST:
      return { ...state, gettingGasPrices: true, gettingGasPricesError: '' };

    case GET_GAS_PRICES_SUCCESS:
      return {
        ...state,
        gettingGasPrices: false,
        gettingGasPricesError: '',
        gasPrices: payload,
      };

    case GET_GAS_PRICES_FAILURE:
      return {
        ...state,
        gettingGasPrices: false,
        gettingGasPricesError: payload,
      };

    case GAS_PRICES_RESET:
      return {
        ...state,
        gettingGasPrices: false,
        gettingGasPricesError: '',
        gasPrices: null,
      };

    case CHANGE_WALLET_TYPE:
      return { ...state, walletType: payload };

    case EXPLOITABLE_APPROVALS_REQUEST:
      return { ...state, gettingExploitableApprovals: true };
    case EXPLOITABLE_APPROVALS_SUCCESS:
      return {
        ...state,
        exploitableApprovalsLoading: false,
        exploitableApprovals: payload,
      };
    case EXPLOITABLE_APPROVALS_FAILURE:
      return {
        ...state,
        exploitableApprovalsLoading: false,
        exploitableApprovalsError: payload,
      };

    case GET_GAS_PRICE_HISTORY_REQUEST:
      return {
        ...state,
        gettingGasPriceHistory: true,
        gettingGasPriceHistoryError: '',
      };

    case GET_GAS_PRICE_HISTORY_SUCCESS:
      return {
        ...state,
        gettingGasPriceHistory: false,
        gettingGasPriceHistoryError: '',
        gasPriceHistory: payload,
      };

    case GET_GAS_PRICE_HISTORY_FAILURE:
      return {
        ...state,
        gettingGasPriceHistory: false,
        gettingGasPriceHistoryError: payload,
      };

    case CONNECT_VIEW_ONLY_ACCOUNT_REQUEST:
      return {
        ...state,
        connectingViewOnly: true,
        connectingViewOnlyError: '',
      };
    case CONNECT_VIEW_ONLY_ACCOUNT_SUCCESS:
      return {
        ...state,
        connectingViewOnly: false,
        connectingViewOnlyError: '',
      };

    case CONNECT_VIEW_ONLY_ACCOUNT_FAILURE:
      return {
        ...state,
        connectingViewOnly: false,
        connectingViewOnlyError: payload,
      };

    case SET_HIDE_HINTS:
      return {
        ...state,
        hideHints: payload,
      };

    case SET_APP_BACKDROP: return {
      ...state, appBackdropOpen: payload.isOpen, appBackdropClass: payload._class, appBackdropOnClick: payload.onClick,
    };

    case CHANGE_NETWORK: return {
      ...state, network: payload,
    };

    default:
      return state;
  }
};
