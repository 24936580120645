import PropTypes from 'prop-types';
import t from 'translate';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalHeader from '../ModalHeader';
import ModalBody from '../ModalBody';
import headerBg from '../Compound/CompoundProxyMigrationModal/upgrade-modal-bg.svg';
import { ACCOUNT_TYPES } from '../../../constants/general';
import { openLoginModal } from '../../../actions/modalActions';
import { createDSProxyAction } from '../../../actions/makerActions/makerActions';
import '../Aave/AaveProxyMigrationModalV1/AaveProxyMigrationModalV1.scss';

const CreateSmartWalletModal = ({ closeModal }) => {
  const dispatch = useDispatch();
  const accountType = useSelector((state) => state.general.accountType);
  const creatingDSProxy = useSelector((state) => state.maker.creatingDSProxy);
  return (
    <div className="compound-proxy-migration-modal-wrapper">
      <ModalHeader closeModal={closeModal} />
      <ModalBody>
        <div className="new-modal-top-wrapper" style={{ backgroundImage: `url(${headerBg})` }}>
          <h1>{t('account.create_user_wallet')}</h1>
        </div>
        <div className="new-modal-content-wrapper">
          <div className="Flex">
            <div className="left-wrapper">
              <p>{t('account.smart_wallet_create_modal_info1')}</p>
              <p>{t('account.smart_wallet_create_modal_info2')}</p>
              <p><a target="_blank" rel="noopener noreferrer" href="https://help.defisaver.com/smart-savings/what-is-the-user-wallet">{t('account.wallet_info_learn_more')}</a></p>
            </div>
          </div>
        </div>
      </ModalBody>

      <div className="modal-controls">
        <button
          type="button"
          onClick={() => (
            accountType !== ACCOUNT_TYPES.viewOnly ?
              dispatch(createDSProxyAction()).then(closeModal) :
              openLoginModal())}
          disabled={creatingDSProxy}
          className="button green"
        >
          {creatingDSProxy ? t('common.creating') : t('common.create')}
        </button>
      </div>
    </div>
  );
};
CreateSmartWalletModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};
export default CreateSmartWalletModal;
