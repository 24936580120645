import React from 'react';

function NotAllowedIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#969B9F"
        fillRule="evenodd"
        d="M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10zm-4.906-3.68L16.32 5.094A8 8 0 015.094 16.32zM3.68 14.906A8 8 0 0114.906 3.68L3.68 14.906z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default NotAllowedIcon;
