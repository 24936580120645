/* eslint-disable */
import React from 'react';

export default function CompoundProtocolIcon(props) {
  return (
    <svg width="22" height="27" viewBox="0 0 22 27" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M1.12476 21.0428C0.426847 20.6315 0 19.8992 0 19.1127V14.7213C0 14.5532 0.0467352 14.3912 0.133974 14.2471C0.405038 13.7968 1.00636 13.6438 1.47371 13.9079L11.7617 19.686C12.363 20.0252 12.7338 20.6436 12.7338 21.3159V25.8664C12.7338 26.0735 12.6745 26.2806 12.5624 26.4577C12.2228 26.992 11.4999 27.1601 10.9453 26.8329L1.12476 21.0428ZM16.4601 12.7042C17.0614 13.0434 17.4322 13.6618 17.4322 14.3341V23.5671C17.4322 23.8403 17.2795 24.0924 17.0334 24.2245L14.7807 25.4462C14.7527 25.4612 14.7216 25.4732 14.6904 25.4822V20.3554C14.6904 19.692 14.329 19.0767 13.737 18.7345L4.70156 13.5267V7.73955C4.70156 7.57145 4.7483 7.40937 4.83553 7.26529C5.10659 6.81504 5.70792 6.66196 6.17527 6.9261L16.4601 12.7042ZM20.9623 5.88454C21.5667 6.22072 21.9375 6.84506 21.9375 7.51743V21.0038C21.9375 21.2799 21.7786 21.535 21.5262 21.6671L19.3919 22.7777V13.3886C19.3919 12.7253 19.0306 12.1129 18.4417 11.7707L9.20683 6.43384V0.943854C9.20683 0.775763 9.25356 0.613675 9.33769 0.469596C9.60875 0.0193516 10.2101 -0.133731 10.6774 0.127411L20.9623 5.88454Z" fill="white"/>
    </svg>
  );
}
