import Dec from 'decimal.js';
import { getAssetInfo } from '@defisaver/tokens';
import { isAddress } from './utils';

const addToSet = (addresses) => {
  const set = new Set();
  addresses.forEach((address) => set.add(address.toLowerCase()));
  return set;
};

const STABLE_PAIRS_MAINNET = addToSet([
  '0x4fabb145d64652a948d72533023f6e7a623c7c53', // BUSD
  '0x6b175474e89094c44da98b954eedeac495271d0f', // DAI
  '0xdb25f211ab05b1c97d595516f45794528a807ad8', // EURS
  '0x956f47f50a910163d8bf957cf5846d573e7f87ca', // FEI
  '0x853d955acef822db058eb8505911ed77f175b99e', // FRAX
  '0x056fd409e1d7a124bd7017459dfea2f387b6d5cd', // GUSD
  '0x5f98805a4e8be255a32880fdec7f6728c6568ba0', // LUSD
  '0x99d8a9c45b2eca8864373a26d1459e3dff1e17f3', // MIM
  '0x03ab458634910aad20ef5f1c8ee96f1d6ac54919', // RAI
  '0x57ab1ec28d129707052df4df418d58a2d46d5f51', // sUSD
  '0x0000000000085d4780b73119b644ae5ecd22b376', // TUSD
  '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48', // USDC
  '0x8e870d67f660d95d5be530380d0ec0bd388289e1', // USDP
  '0xdac17f958d2ee523a2206206994597c13d831ec7', // USDT
  '0xa693b19d2931d498c5b318df961919bb4aee87a5', // UST
  '0xe2f2a5c287993345a840db3b0845fbc70f5935a5', // mUSD
  // tokens below are unused atm
  '0xBC6DA0FE9aD5f3b0d58160288917AA56653660E9', // alUSD
  '0x674C6Ad92Fd080e4004b2312b45f796a192D27a0', // USDN
  '0xdf574c24545e5ffecb9a659c229253d4111d87e1', // HUSD
  '0x0c10bf8fcb7bf5412187a595ab97a3609160b5c6', // USDD
  '0x2A8e1E676Ec238d8A992307B495b45B3fEAa5e86', // OUSD
  '0xc581b735a1688071a1746c968e0798d642ede491', // EURT
  '0x1a7e4e63778b4f12a199c062f3efdd288afcbce8', // agEUR
  '0x865377367054516e17014ccded1e7d814edc9ce4', // DOLA
]);

const STABLE_PAIRS_OPTIMISM = addToSet([
  '0x4Fabb145d64652a948d72533023f6E7A623C7C53', // BUSD wrong address
  '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1', // DAI
  '0x2E3D870790dC77A83DD1d18184Acc7439A53f475', // FRAX
  '0x7FB688CCf682d58f86D7e38e03f9D22e7705448B', // RAI
  '0x8c6f28f2F1A3C87F0f938b96d27520d9751ec8d9', // sUSD
  '0x7F5c764cBc14f9669B88837ca1490cCa17c31607', // USDC
  '0x94b008aA00579c1307B0EF2c499aD98a8ce58e58', // USDT
  '0xFB21B70922B9f6e3C6274BcD6CB1aa8A0fe20B80', // UST
  '0xdFA46478F9e5EA86d57387849598dbFB2e964b02', // MAI
]);

const STABLE_PAIRS_ARBITRUM = addToSet([
  '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1', // DAI
  '0xD22a58f79e9481D1a88e00c343885A588b34b68B', // EURS
  '0x4A717522566C7A09FD2774cceDC5A8c43C5F9FD2', // FEI
  '0x17FC002b466eEc40DaE837Fc4bE5c67993ddBd6F', // FRAX
  '0xFEa7a6a0B346362BF88A9e4A88416B77a57D6c2A', // MIM
  '0xaeF5bbcbFa438519a5ea80B4c7181B4E78d419f2', // RAI
  '0xA970AF1a584579B618be4d69aD6F73459D112F95', // sUSD
  '0x4D15a3A2286D883AF0AA1B3f21367843FAc63E07', // TUSD
  '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8', // USDC
  '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9', // USDT
  '0x13780E6d5696DD91454F6d3BbC2616687fEa43d0', // UST
  '0x3F56e0c36d275367b8C502090EDF38289b3dEa0d', // MAI
  '0x64343594Ab9b56e99087BfA6F2335Db24c2d1F17', // VST
  '0xcd14C3A2ba27819B352aae73414A26e2b366dC50', // USX
  '0x969131D8ddC06C2Be11a13e6E7fACF22CF57d95e', // EUX
]);

const ETH_PAIRS_MAINNET = addToSet([
  '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee', // ETH
  '0xae7ab96520de3a18e5e111b5eaab095312d7fe84', // stETH
  '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2', // WETH
  '0x7f39c581f595b53c5cb19bd0b3f8da6c935e2ca0', // wstETH
  // tokens below are unused atm
  '0x5e74c9036fb86bd7ecdcb084a0673efc32ea31cb', // sETH
  '0xae78736cd615f374d3085123a210448e74fc6393', // rETH
  '0xFe2e637202056d30016725477c5da089Ab0A043A', // sETH2
]);

const ETH_PAIRS_OPTIMISM = addToSet([
  '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee', // ETH
  '0xae7ab96520DE3A18E5e111B5EaAb095312D7fE84', // stETH
  '0x4200000000000000000000000000000000000006', // WETH
  '0xE405de8F52ba7559f9df3C368500B6E6ae6Cee49', // sETH
]);

const ETH_PAIRS_ARBITRUM = addToSet([
  '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee', // ETH
  '0xae7ab96520DE3A18E5e111B5EaAb095312D7fE84', // stETH
  '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1', // WETH
  '0xEC70Dcb4A1EFa46b8F2D97C310C9c4790ba5ffA8', // rETH
  '0xcAB86F6Fb6d1C2cBeeB97854A0C023446A075Fe3', // deETH
]);

const BTC_PAIRS_MAINNET = addToSet([
  '0xEB4C2781e4ebA804CE9a9803C67d0893436bB27D', // renBTC
  '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599', // WBTC
  // tokens below are unused atm
  '0xfe18be6b3bd88a2d2a7f928d00292e7a9963cfc6', // sBTC
  '0x0316eb71485b0ab14103307bf65a021042c6d380', // HBTC
  '0x8daebade922df735c38c80c7ebd708af50815faa', // tBTC
  '0x5228a22e72ccc52d415ecfd199f99d0665e7733b', // pBTC
  '0xc4e15973e6ff2a35cc804c2cf9d2a1b817a8b40f', // IBBTC
]);

const BTC_PAIRS_OPTIMISM = addToSet([
  '0x68f180fcCe6836688e9084f035309E29Bf0A2095', // WBTC
  '0x298B9B95708152ff6968aafd889c6586e9169f1D', // sBTC
]);

const BTC_PAIRS_ARBITRUM = addToSet([
  '0xDBf31dF14B66535aF65AaC99C32e9eA844e14501', // renBTC
  '0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f', // WBTC
  '0x9Ab3FD50FcAe73A1AEDa959468FD0D662c881b42', // IBBTC
  '0x7cb16cb78ea464aD35c8a50ABF95dff3c9e09d5d', // 0xBTC
]);

const STABLE_PAIRS = {
  1: STABLE_PAIRS_MAINNET,
  10: STABLE_PAIRS_OPTIMISM,
  42161: STABLE_PAIRS_ARBITRUM,
};

const ETH_PAIRS = {
  1: ETH_PAIRS_MAINNET,
  10: ETH_PAIRS_OPTIMISM,
  42161: ETH_PAIRS_ARBITRUM,
};

const BTC_PAIRS = {
  1: BTC_PAIRS_MAINNET,
  10: BTC_PAIRS_OPTIMISM,
  42161: BTC_PAIRS_ARBITRUM,
};

const FEE_DIVIDER = '400';
const PAIR_FEE_DIVIDER = '1000';

export const getFeeDivider = (_addr1, _addr2, network) => {
  const addr1 = isAddress(_addr1) ? _addr1.toLowerCase() : getAssetInfo(_addr1).address.toLowerCase();
  const addr2 = isAddress(_addr2) ? _addr2.toLowerCase() : getAssetInfo(_addr2).address.toLowerCase();
  const isPair = [STABLE_PAIRS[network], ETH_PAIRS[network], BTC_PAIRS[network]]
    .some((pairs) => pairs.has(addr1) && pairs.has(addr2));
  if (isPair) return PAIR_FEE_DIVIDER;
  return FEE_DIVIDER;
};

export const getFeePercentage = (addr1, addr2, network) => {
  const feeDivider = getFeeDivider(addr1, addr2, network);
  return new Dec(100).div(feeDivider).toString();
};
export const getFeeDecimal = (addr1, addr2, network) => {
  const feeDivider = getFeeDivider(addr1, addr2, network);
  return new Dec(1).div(feeDivider).toString();
};
