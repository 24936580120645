/* eslint-disable */
import React from 'react';

export default function TrezorIcon(props) {
  return (
    <svg className="trezor-icon" width="13" height="19" viewBox="0 0 13 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.51046 0.400024C3.91935 0.400024 1.82241 2.42057 1.82241 4.91727V6.6103C0.91251 6.76902 0 6.98065 0 7.25526V16.0907C0 16.0907 0 16.3351 0.284996 16.451C1.31778 16.8541 5.38093 18.2398 6.31436 18.5572C6.43463 18.6 6.46862 18.6 6.5 18.6C6.54445 18.6 6.56537 18.6 6.68564 18.5572C7.61907 18.2398 11.6927 16.8541 12.7255 16.451C12.9895 16.3452 13 16.1008 13 16.1008V7.25526C13 6.98065 12.1006 6.75894 11.1881 6.6103V4.91727C11.2011 2.42057 9.09111 0.400024 6.51046 0.400024ZM6.51046 2.55913C8.03741 2.55913 8.96038 3.44847 8.96038 4.91979V6.39111C7.24779 6.27522 5.7862 6.27522 4.06315 6.39111V4.91979C4.06315 3.44595 4.98612 2.55913 6.51046 2.55913ZM6.5 8.55778C8.63093 8.55778 10.4193 8.7165 10.4193 9.00119V14.5136C10.4193 14.5992 10.4089 14.6093 10.3305 14.6396C10.2546 14.6723 6.6961 15.9093 6.6961 15.9093C6.6961 15.9093 6.55229 15.9522 6.51046 15.9522C6.46601 15.9522 6.32482 15.8993 6.32482 15.8993C6.32482 15.8993 2.76629 14.6622 2.69047 14.6295C2.61464 14.5967 2.60157 14.5867 2.60157 14.5035V8.99111C2.58065 8.70642 4.36907 8.55778 6.5 8.55778Z"
        fill="#01B757"/>
    </svg>
  );
}
