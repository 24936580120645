/* eslint-disable */
import React from 'react';

export default function BalancerProtocolIcon(props) {
  return (
    <svg width="27" height="21" viewBox="0 0 27 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M13.335 20.0033C5.96966 20.0033 0 17.8641 0 14.998C0 13.5025 1.62599 12.1551 4.22851 11.2059C6.25985 12.5162 9.66845 13.1876 13.5331 13.1876C17.3056 13.1876 20.6405 12.3681 22.6902 11.1133C25.1453 12.0532 26.67 13.3636 26.67 14.8082C26.67 17.6789 20.7003 20.0033 13.335 20.0033Z" fill="white"/>
      <path d="M13.4934 12.6998C7.97111 12.6998 3.49219 10.9268 3.49219 8.73841C3.49219 7.52525 4.87277 6.43807 7.03705 5.71484C8.58166 6.54073 10.8963 7.06798 13.4934 7.06798C16.0906 7.06798 18.4052 6.54073 19.9498 5.71484C22.1187 6.44274 23.4947 7.52525 23.4947 8.73841C23.4992 10.9268 19.0203 12.6998 13.4934 12.6998Z" fill="white"/>
      <path d="M13.4946 6.35C9.19953 6.35 5.71582 4.92579 5.71582 3.175C5.71582 1.42421 9.19953 0 13.4946 0C17.7896 0 21.2733 1.42421 21.2733 3.175C21.2733 4.92579 17.7896 6.35 13.4946 6.35Z" fill="white"/>
    </svg>
  );
}
