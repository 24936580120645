import MStableSaveSupplyAction from './MStableSaveSupplyAction';

export default class MStableVaultSupplyAction extends MStableSaveSupplyAction {
  // all logic in MStableSaveSupplyAction because of similarity
  isVault = true;

  static prettyName = 'Supply to mStable Vault';

  static description = 'Deposits selected asset into mStable Vault';

  constructor(asset = 'DAI', from = 'wallet', amount = '0', to = 'wallet', isVault = true) {
    super(asset, from, amount, to, isVault);
    this.inputs = this.inputs.slice(0, 3);
  }
}
