import * as aaveManageTypes from '../../actionTypes/aaveActionTypes/aaveManageActionTypes';
import { CONNECT_PROVIDER_SUCCESS } from '../../actionTypes/generalActionTypes';
import { RESET_BR_SLIPPAGE_VALUES, SET_BR_SLIPPAGE_PERCENT } from '../../actionTypes/modalActionTypes';

import { AAVE_VERSIONS } from '../../constants/aaveMarkets';

const INITIAL_MARKET = AAVE_VERSIONS()[1]; // v2

const INITIAL_ACCOUNT_STATE = {
  suppliedUsd: '0',
  borrowedUsd: '0',
  borrowLimitUsd: '0',
  liquidationLimitUsd: '0',
  leftToBorrowUsd: '0',
  netApy: '0',
  incentiveUsd: '0',
  totalInterestUsd: '0',
  ratio: '0',
  minRatio: '0',
  fetchingAccountData: false,
  fetchingAccountDataError: '',
  usedAssets: {},
  isSubscribedToAutomation: false,
  automationResubscribeRequired: false,
  leveragedType: '',
  leveragedAsset: '',
  liquidationPrice: '0',
  liqRatio: '',
  lastUpdated: Date.now(),
  eModeCategories: undefined,
  eModeCategory: 0,
};

const INITIAL_MARKET_STATE = {
  fetchingAssetsData: false,
  fetchingAssetsDataError: '',
  assetsData: {},
  marketTokens: {},
  gettingMarketTokens: false,
  gettingMarketTokensError: '',
};

const INITIAL_STATE = {
  fetchingManageData: false,
  fetchingManageDataError: '',

  account: {
    v1: {
      ...INITIAL_ACCOUNT_STATE,
    },
    v2default: {
      ...INITIAL_ACCOUNT_STATE,
    },
    v2uniswap: {
      ...INITIAL_ACCOUNT_STATE,
    },
    v3default: {
      ...INITIAL_ACCOUNT_STATE,
    },
  },
  proxy: {
    v1: {
      ...INITIAL_ACCOUNT_STATE,
    },
    v2default: {
      ...INITIAL_ACCOUNT_STATE,
    },
    v2uniswap: {
      ...INITIAL_ACCOUNT_STATE,
    },
    v3default: {
      ...INITIAL_ACCOUNT_STATE,
    },
  },

  actionsSelectValues: {},

  collateralState: {},

  afterValue: null,
  afterType: '',
  gettingAfterValue: false,
  gettingAfterValueError: '',

  supplying: false,
  supplyingError: '',
  maxSupply: '0',
  gettingMaxSupply: false,
  gettingMaxSupplyError: '',

  withdrawing: false,
  withdrawingError: '',
  maxWithdraw: '0',
  gettingMaxWithdraw: false,
  gettingMaxWithdrawError: '',

  borrowing: false,
  borrowingError: '',
  maxBorrow: '0',
  gettingMaxBorrow: false,
  gettingMaxBorrowError: '',

  payingBack: false,
  payingBackError: '',
  maxPayback: '0',
  gettingMaxPayback: false,
  gettingMaxPaybackError: '',

  boosting: false,
  boostingError: '',

  maxBoost: '0',
  gettingMaxBoost: false,
  gettingMaxBoostError: '',

  boostAmount: '0',
  boostExchangeRate: '0',
  gettingBoostModalData: false,
  gettingBoostModalDataError: '',
  tradeSizeImpact: '0',

  repaying: false,
  repayingError: '',
  maxRepay: '0',
  gettingMaxRepay: false,
  gettingMaxRepayError: '',

  repayAmount: '0',
  repayExchangeRate: '0',
  gettingRepayModalData: false,
  gettingRepayModalDataError: '',

  useFl: false,
  flProtocol: 'none',
  flFee: '0',
  useAltRecipe: false,
  exchangeSource: '',

  activeTab: '',

  slippagePercent: '0',
  slippageRate: '0',
  currentSlippage: '0',

  migratingToProxy: false,
  migratingToProxyError: '',

  selectedBorrowInterestRate: {},
  swapInterestRate: {},

  rewardBalance: '0',
  cooldownSeconds: '0',
  unstakeWindow: '0',
  lendAaveRatio: '0',
  activatedCooldown: '0',
  totalStkAAVESupply: '0',
  gettingStakeAaveBalance: false,
  gettingStakeAaveBalanceError: '',
  stkAaveRewardBalanceAcc: '0',
  stkAaveRewardBalanceProxy: '0',

  unstaking: false,
  unstakingError: '',

  activatingStakeCooldown: false,
  activatingStakeCooldownError: '',

  staking: false,
  stakingError: '',

  selectedMarket: INITIAL_MARKET,
  v1: {
    ...INITIAL_MARKET_STATE,
  },
  v2uniswap: {
    ...INITIAL_MARKET_STATE,
  },
  v2default: {
    ...INITIAL_MARKET_STATE,
  },
  v3default: {
    ...INITIAL_MARKET_STATE,
  },
  fetchingAvailableMarkets: false,
  fetchingAvailableMarketsError: '',

  availableMarkets: {},

  migratingLendToAave: false,
  migratingLendToAaveError: '',

  newATokenApproving: false,
  newATokenApprovingError: '',
  newATokenApproved: false,

  migratingFromV1ToV2: false,
  migratingFromV1ToV2Error: '',

  // NEW
  selectedAction: { label: 'None', value: '' },
  selectedAdditionalActions: {},

  // afterType: '',
  afterCdp: null,
  ratioTooLow: false,
  ratioTooHigh: false,

  maxValues: {},
  loadingMaxValues: {},
  maxValuesErrors: {},

  executing: {},
  executingErrors: {},
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload, asset } = action;

  switch (type) {
    case aaveManageTypes.FETCH_AAVE_MANAGE_DATA_REQUEST:
      return {
        ...state,
        fetchingManageData: true,
        fetchingManageDataError: '',
      };

    case aaveManageTypes.FETCH_AAVE_MANAGE_DATA_SUCCESS:
      return {
        ...state,
        fetchingManageData: false,
        fetchingManageDataError: '',
      };

    case aaveManageTypes.FETCH_AAVE_MANAGE_DATA_FAILURE:
      return {
        ...state,
        fetchingManageData: false,
        fetchingManageDataError: payload,
      };

    case aaveManageTypes.FETCH_AAVE_ASSETS_DATA_REQUEST:
      return {
        ...state,
        [action.selectedMarket]: {
          ...state[action.selectedMarket],
          fetchingAssetsData: true,
          fetchingAssetsDataError: '',
        },
      };

    case aaveManageTypes.FETCH_AAVE_ASSETS_DATA_SUCCESS:
      return {
        ...state,
        [action.selectedMarket]: {
          ...state[action.selectedMarket],
          assetsData: payload,
          fetchingAssetsData: false,
          fetchingAssetsDataError: '',
        },
      };

    case aaveManageTypes.FETCH_AAVE_ASSETS_DATA_FAILURE:
      return {
        ...state,
        [action.selectedMarket]: {
          ...state[action.selectedMarket],
          fetchingAssetsData: false,
          fetchingAssetsDataError: payload,
        },
      };

    case aaveManageTypes.FETCH_AAVE_ACCOUNT_DATA_REQUEST:
      return {
        ...state,
        [action.walletType]: {
          ...state[action.walletType],
          [action.selectedMarket]: {
            ...state[action.walletType][action.selectedMarket],
            fetchingAccountData: true,
            fetchingAccountDataError: '',
          },
        },
      };

    case aaveManageTypes.FETCH_AAVE_ACCOUNT_DATA_SUCCESS:
      return {
        ...state,
        [action.walletType]: {
          ...state[action.walletType],
          [action.selectedMarket]: {
            ...state[action.walletType][action.selectedMarket],
            ...payload,
            fetchingAccountData: false,
            fetchingAccountDataError: '',
          },
        },
      };

    case aaveManageTypes.FETCH_AAVE_ACCOUNT_DATA_FAILURE:
      return {
        ...state,
        [action.walletType]: {
          ...state[action.walletType],
          [action.selectedMarket]: {
            ...state[action.walletType][action.selectedMarket],
            fetchingAccountData: false,
            fetchingAccountDataError: payload,
          },
        },
      };

    case aaveManageTypes.AAVE_SUPPLY_REQUEST:
      return {
        ...state,
        supplying: true,
        supplyingError: '',
        executing: {
          ...state.executing,
          collateral: true,
        },
        executingErrors: {
          ...state.executingErrors,
          collateral: '',
        },
      };

    case aaveManageTypes.AAVE_SUPPLY_SUCCESS:
      return {
        ...state,
        supplying: false,
        supplyingError: '',
        executing: {
          ...state.executing,
          collateral: false,
        },
        executingErrors: {
          ...state.executingErrors,
          collateral: '',
        },
      };

    case aaveManageTypes.AAVE_SUPPLY_FAILURE:
      return {
        ...state,
        supplying: false,
        supplyingError: payload,
        executing: {
          ...state.executing,
          collateral: false,
        },
        executingErrors: {
          ...state.executingErrors,
          collateral: payload,
        },
      };

    case aaveManageTypes.GET_AAVE_AFTER_VALUE_REQUEST:
      return { ...state, gettingAfterValue: true, gettingAfterValueError: '' };

    case aaveManageTypes.GET_AAVE_AFTER_VALUE_SUCCESS:
      return {
        ...state,
        gettingAfterValue: false,
        gettingAfterValueError: '',
        ...payload,
      };

    case aaveManageTypes.GET_AAVE_AFTER_VALUE_FAILURE:
      return {
        ...state,
        gettingAfterValue: false,
        gettingAfterValueError: payload,
      };

    case aaveManageTypes.AAVE_WITHDRAW_REQUEST:
      return {
        ...state,
        withdrawing: true,
        withdrawingError: '',
        executing: {
          ...state.executing,
          withdraw: true,
        },
        executingErrors: {
          ...state.executingErrors,
          withdraw: '',
        },
      };

    case aaveManageTypes.AAVE_WITHDRAW_SUCCESS:
      return {
        ...state,
        withdrawing: false,
        withdrawingError: '',
        executing: {
          ...state.executing,
          withdraw: false,
        },
        executingErrors: {
          ...state.executingErrors,
          withdraw: '',
        },
      };

    case aaveManageTypes.AAVE_WITHDRAW_FAILURE:
      return {
        ...state,
        withdrawing: false,
        withdrawingError: payload,
        executing: {
          ...state.executing,
          withdraw: false,
        },
        executingErrors: {
          ...state.executingErrors,
          withdraw: payload,
        },
      };

    case aaveManageTypes.GET_MAX_AAVE_WITHDRAW_REQUEST:
      return { ...state, gettingMaxWithdraw: true, gettingMaxWithdrawError: '' };

    case aaveManageTypes.GET_MAX_AAVE_WITHDRAW_SUCCESS:
      return {
        ...state,
        gettingMaxWithdraw: false,
        gettingMaxWithdrawError: '',
        maxWithdraw: payload,
      };

    case aaveManageTypes.GET_MAX_AAVE_WITHDRAW_FAILURE:
      return {
        ...state,
        gettingMaxWithdraw: false,
        gettingMaxWithdrawError: payload,
      };

    case aaveManageTypes.GET_MAX_AAVE_SUPPLY_REQUEST:
      return { ...state, gettingMaxSupply: true, gettingMaxSupplyError: '' };

    case aaveManageTypes.GET_MAX_AAVE_SUPPLY_SUCCESS:
      return {
        ...state,
        gettingMaxSupply: false,
        gettingMaxSupplyError: '',
        maxSupply: payload,
      };

    case aaveManageTypes.GET_MAX_AAVE_SUPPLY_FAILURE:
      return {
        ...state,
        gettingMaxSupply: false,
        gettingMaxSupplyError: payload,
      };

    case aaveManageTypes.AAVE_BORROW_REQUEST:
      return {
        ...state,
        borrowing: true,
        borrowingError: '',
        executing: {
          ...state.executing,
          borrow: true,
        },
        executingErrors: {
          ...state.executingErrors,
          borrow: '',
        },
      };

    case aaveManageTypes.AAVE_BORROW_SUCCESS:
      return {
        ...state,
        borrowing: false,
        borrowingError: '',
        executing: {
          ...state.executing,
          borrow: false,
        },
        executingErrors: {
          ...state.executingErrors,
          borrow: '',
        },
      };

    case aaveManageTypes.AAVE_BORROW_FAILURE:
      return {
        ...state,
        borrowing: false,
        borrowingError: payload,
        executing: {
          ...state.executing,
          borrow: false,
        },
        executingErrors: {
          ...state.executingErrors,
          borrow: payload,
        },
      };

    case aaveManageTypes.GET_MAX_AAVE_BORROW_REQUEST:
      return { ...state, gettingMaxBorrow: true, gettingMaxBorrowError: '' };

    case aaveManageTypes.GET_MAX_AAVE_BORROW_SUCCESS:
      return {
        ...state,
        gettingMaxBorrow: false,
        gettingMaxBorrowError: '',
        maxBorrow: payload,
      };

    case aaveManageTypes.GET_MAX_AAVE_BORROW_FAILURE:
      return {
        ...state,
        gettingMaxBorrow: false,
        gettingMaxBorrowError: payload,
      };

    case aaveManageTypes.ENABLE_AAVE_ASSET_AS_COLLATERAL_REQUEST:
      return {
        ...state,
        collateralState: {
          ...state.collateralState,
          [action.asset]: { ...state.collateralState[action.asset], enabling: true, enablingError: '' },
        },
      };

    case aaveManageTypes.ENABLE_AAVE_ASSET_AS_COLLATERAL_SUCCESS:
      return {
        ...state,
        collateralState: {
          ...state.collateralState,
          [action.asset]: { ...state.collateralState[action.asset], enabling: false, enablingError: '' },
        },
      };

    case aaveManageTypes.ENABLE_AAVE_ASSET_AS_COLLATERAL_FAILURE:
      return {
        ...state,
        collateralState: {
          ...state.collateralState,
          [action.asset]: { ...state.collateralState[action.asset], enabling: false, enablingError: payload },
        },
      };

    case aaveManageTypes.DISABLE_AAVE_ASSET_AS_COLLATERAL_REQUEST:
      return {
        ...state,
        collateralState: {
          ...state.collateralState,
          [action.asset]: { ...state.collateralState[action.asset], disabling: true, disablingError: '' },
        },
      };

    case aaveManageTypes.DISABLE_AAVE_ASSET_AS_COLLATERAL_SUCCESS:
      return {
        ...state,
        collateralState: {
          ...state.collateralState,
          [action.asset]: { ...state.collateralState[action.asset], disabling: false, disablingError: '' },
        },
      };

    case aaveManageTypes.DISABLE_AAVE_ASSET_AS_COLLATERAL_FAILURE:
      return {
        ...state,
        collateralState: {
          ...state.collateralState,
          [action.asset]: { ...state.collateralState[action.asset], disabling: false, disablingError: payload },
        },
      };

    case aaveManageTypes.AAVE_PAYBACK_REQUEST:
      return {
        ...state,
        payingBack: true,
        payingBackError: '',
        executing: {
          ...state.executing,
          payback: true,
        },
        executingErrors: {
          ...state.executingErrors,
          payback: '',
        },
      };

    case aaveManageTypes.AAVE_PAYBACK_SUCCESS:
      return {
        ...state,
        payingBack: false,
        payingBackError: '',
        executing: {
          ...state.executing,
          payback: false,
        },
        executingErrors: {
          ...state.executingErrors,
          payback: '',
        },
      };

    case aaveManageTypes.AAVE_PAYBACK_FAILURE:
      return {
        ...state,
        payingBack: false,
        payingBackError: payload,
        executing: {
          ...state.executing,
          payback: false,
        },
        executingErrors: {
          ...state.executingErrors,
          payback: payload,
        },
      };

    case aaveManageTypes.GET_MAX_AAVE_PAYBACK_REQUEST:
      return { ...state, gettingMaxPayback: true, gettingMaxPaybackError: '' };

    case aaveManageTypes.GET_MAX_AAVE_PAYBACK_SUCCESS:
      return {
        ...state,
        gettingMaxPayback: false,
        gettingMaxPaybackError: '',
        maxPayback: payload,
      };

    case aaveManageTypes.GET_MAX_AAVE_PAYBACK_FAILURE:
      return {
        ...state,
        gettingMaxPayback: false,
        gettingMaxPaybackError: payload,
      };

    case aaveManageTypes.AAVE_BOOST_REQUEST:
      return { ...state, boosting: true, boostingError: '' };

    case aaveManageTypes.AAVE_BOOST_SUCCESS:
      return {
        ...state,
        boosting: false,
        boostingError: '',
      };

    case aaveManageTypes.AAVE_BOOST_FAILURE:
      return {
        ...state,
        boosting: false,
        boostingError: payload,
      };

    case aaveManageTypes.GET_MAX_AAVE_BOOST_REQUEST:
      return { ...state, gettingMaxBoost: true, gettingMaxBoostError: '' };

    case aaveManageTypes.GET_MAX_AAVE_BOOST_SUCCESS:
      return {
        ...state,
        gettingMaxBoost: false,
        gettingMaxBoostError: '',
        maxBoost: payload,
      };

    case aaveManageTypes.GET_MAX_AAVE_BOOST_FAILURE:
      return {
        ...state,
        gettingMaxBoost: false,
        gettingMaxBoostError: payload,
      };

    case aaveManageTypes.AAVE_REPAY_REQUEST:
      return { ...state, repaying: true, repayingError: '' };

    case aaveManageTypes.AAVE_REPAY_SUCCESS:
      return {
        ...state,
        repaying: false,
        repayingError: '',
      };

    case aaveManageTypes.AAVE_REPAY_FAILURE:
      return {
        ...state,
        repaying: false,
        repayingError: payload,
      };

    case aaveManageTypes.GET_MAX_AAVE_REPAY_REQUEST:
      return { ...state, gettingMaxRepay: true, gettingMaxRepayError: '' };

    case aaveManageTypes.GET_MAX_AAVE_REPAY_SUCCESS:
      return {
        ...state,
        gettingMaxRepay: false,
        gettingMaxRepayError: '',
        maxRepay: payload,
      };

    case aaveManageTypes.GET_MAX_AAVE_REPAY_FAILURE:
      return {
        ...state,
        gettingMaxRepay: false,
        gettingMaxRepayError: payload,
      };

    case aaveManageTypes.AAVE_MANAGE_SET_ACTIVE_TAB:
      return { ...state, activeTab: payload };

    case aaveManageTypes.AAVE_MANAGE_SET_ACTION_SELECT_VALUES:
      return {
        ...state,
        actionsSelectValues: {
          ...state.actionsSelectValues,
          [payload.key]: payload.selectValue,
        },
      };

    case aaveManageTypes.GET_BOOST_MODAL_DATA_REQUEST:
      return {
        ...state,
        gettingBoostModalData: true,
        gettingBoostModalDataError: '',
      };

    case aaveManageTypes.GET_BOOST_MODAL_DATA_SUCCESS:
      return {
        ...state,
        gettingBoostModalData: false,
        gettingBoostModalDataError: '',
        ...payload,
      };

    case aaveManageTypes.GET_BOOST_MODAL_DATA_FAILURE:
      return {
        ...state,
        gettingBoostModalData: false,
        gettingBoostModalDataError: payload,
      };

    case aaveManageTypes.RESET_BOOST_MODAL:
      return {
        ...state,
        boosting: false,
        boostingError: '',
        boostAmount: '0',
        boostExchangeRate: '0',
        gettingBoostModalData: false,
        gettingBoostModalDataError: '',
      };

    case aaveManageTypes.GET_REPAY_MODAL_DATA_REQUEST:
      return {
        ...state,
        gettingRepayModalData: true,
        gettingRepayModalDataError: '',
      };

    case aaveManageTypes.GET_REPAY_MODAL_DATA_SUCCESS:
      return {
        ...state,
        gettingRepayModalData: false,
        gettingRepayModalDataError: '',
        ...payload,
      };

    case aaveManageTypes.GET_REPAY_MODAL_DATA_FAILURE:
      return {
        ...state,
        gettingRepayModalData: false,
        gettingRepayModalDataError: payload,
      };

    case aaveManageTypes.RESET_REPAY_MODAL:
      return {
        ...state,
        repaying: false,
        repayingError: '',
        gettingRepayModalData: false,
        gettingRepayModalDataError: '',
      };

    case SET_BR_SLIPPAGE_PERCENT:
      return {
        ...state,
        slippagePercent: payload.slippagePercent,
        slippageRate: payload.slippageRate,
      };

    case RESET_BR_SLIPPAGE_VALUES:
      return {
        ...state,
        slippagePercent: '0',
        slippageRate: '0',
        tradeSizeImpact: '0',
      };

    case aaveManageTypes.MIGRATE_AAVE_V1_TO_PROXY_REQUEST:
      return { ...state, migratingToProxy: true, migratingToProxyError: '' };

    case aaveManageTypes.MIGRATE_AAVE_V1_TO_PROXY_SUCCESS:
      return {
        ...state,
        migratingToProxy: false,
        migratingToProxyError: '',
      };

    case aaveManageTypes.MIGRATE_AAVE_V1_TO_PROXY_FAILURE:
      return {
        ...state,
        migratingToProxy: false,
        migratingToProxyError: payload,
      };

    case CONNECT_PROVIDER_SUCCESS:
      return { ...INITIAL_STATE };

    case aaveManageTypes.SET_AAVE_BORROW_INTEREST_RATE_SUCCESS:
      return {
        ...state,
        selectedBorrowInterestRate: payload,
      };

    case aaveManageTypes.AAVE_SWAP_INTEREST_RATE_MODE_REQUEST:
      return {
        ...state,
        swapInterestRate: {
          ...state.swapInterestRate,
          [action.asset]: {
            ...state.swapInterestRate[action.asset], swapping: true, swappingError: '', interestRate: action.interestRate,
          },
        },
      };
    case aaveManageTypes.AAVE_SWAP_INTEREST_RATE_MODE_SUCCESS:
      return {
        ...state,
        swapInterestRate: {
          ...state.swapInterestRate,
          [action.asset]: {
            ...state.swapInterestRate[action.asset], swapping: false, swappingError: '', interestRate: action.interestRate,
          },
        },
      };

    case aaveManageTypes.AAVE_SWAP_INTEREST_RATE_MODE_FAILURE:
      return {
        ...state,
        swapInterestRate: {
          ...state.swapInterestRate,
          [action.asset]: {
            ...state.swapInterestRate[action.asset], swapping: false, swappingError: payload, interestRate: action.interestRate,
          },
        },
      };

    case aaveManageTypes.GET_STAKE_AAVE_BALANCE_AND_COOLDOWN_REQUEST:
      return {
        ...state,
        gettingStakeAaveBalance: true,
        gettingStakeAaveBalanceError: '',
      };

    case aaveManageTypes.GET_STAKE_AAVE_BALANCE_AND_COOLDOWN_SUCCESS:
      return {
        ...state,
        ...payload,
        gettingStakeAaveBalance: false,
        gettingStakeAaveBalanceError: '',
      };

    case aaveManageTypes.GET_STAKE_AAVE_BALANCE_AND_COOLDOWN_FAILURE:
      return {
        ...state,
        gettingStakeAaveBalance: false,
        gettingStakeAaveBalanceError: payload,
      };

    case aaveManageTypes.AAVE_STAKE_REQUEST:
      return {
        ...state,
        staking: true,
        stakingError: '',
      };

    case aaveManageTypes.AAVE_STAKE_SUCCESS:
      return {
        ...state,
        staking: false,
        stakingError: '',
      };

    case aaveManageTypes.AAVE_STAKE_FAILURE:
      return {
        ...state,
        staking: false,
        stakingError: payload,
      };


    case aaveManageTypes.AAVE_COOLDOWN_REQUEST:
      return {
        ...state,
        activatingStakeCooldown: true,
        activatingStakeCooldownError: '',
      };

    case aaveManageTypes.AAVE_COOLDOWN_SUCCESS:
      return {
        ...state,
        activatingStakeCooldown: false,
        activatingStakeCooldownError: '',
      };

    case aaveManageTypes.AAVE_COOLDOWN_FAILURE:
      return {
        ...state,
        activatingStakeCooldown: false,
        activatingStakeCooldownError: payload,
      };

    case aaveManageTypes.AAVE_UNSTAKE_REQUEST:
      return {
        ...state,
        unstaking: true,
        unstakingError: '',
      };

    case aaveManageTypes.AAVE_UNSTAKE_SUCCESS:
      return {
        ...state,
        unstaking: false,
        unstakingError: '',
      };

    case aaveManageTypes.AAVE_UNSTAKE_FAILURE:
      return {
        ...state,
        unstaking: false,
        unstakingError: payload,
      };

    case aaveManageTypes.SET_SELECTED_MARKET_ACTION:
      return {
        ...state,
        selectedMarket: payload,
      };

    case aaveManageTypes.GET_AAVE_MARKET_PROVIDERS_REQUEST:
      return {
        ...state,
        fetchingAvailableMarkets: true,
        fetchingAvailableMarketsError: '',
      };

    case aaveManageTypes.GET_AAVE_MARKET_PROVIDERS_SUCCESS:
      return {
        ...state,
        fetchingAvailableMarkets: false,
        fetchingAvailableMarketsError: '',
        availableMarkets: payload,
      };

    case aaveManageTypes.GET_AAVE_MARKET_PROVIDERS_FAILURE:
      return {
        ...state,
        fetchingAvailableMarkets: false,
        fetchingAvailableMarketsError: payload,
        availableMarkets: {},
      };

    case aaveManageTypes.MIGRATE_LEND_TO_AAVE_REQUEST:
      return {
        ...state,
        migratingLendToAave: true,
        migratingLendToAaveError: '',
      };
    case aaveManageTypes.MIGRATE_LEND_TO_AAVE_SUCCESS:
      return {
        ...state,
        migratingLendToAave: false,
        migratingLendToAaveError: '',
      };

    case aaveManageTypes.MIGRATE_LEND_TO_AAVE_FAILURE:
      return {
        ...state,
        migratingLendToAave: false,
        migratingLendToAaveError: payload,
      };

    case aaveManageTypes.GET_AAVE_MARKET_TOKENS_REQUEST:
      return {
        ...state,
        [action.selectedMarket]: {
          ...state[action.selectedMarket],
          gettingMarketTokens: true,
          gettingMarketTokensError: '',
        },
      };

    case aaveManageTypes.GET_AAVE_MARKET_TOKENS_SUCCESS:
      return {
        ...state,
        [action.selectedMarket]: {
          ...state[action.selectedMarket],
          marketTokens: payload,
          gettingMarketTokens: false,
          gettingMarketTokensError: '',
        },
      };

    case aaveManageTypes.GET_AAVE_MARKET_TOKENS_FAILURE:
      return {
        ...state,
        [action.selectedMarket]: {
          ...state[action.selectedMarket],
          gettingMarketTokens: false,
          gettingMarketTokensError: payload,
        },
      };

    case aaveManageTypes.NEW_ATOKEN_APPROVED_REQUEST:
      return {
        ...state,
        newATokenApproving: true,
        newATokenApprovingError: '',
        newATokenApproved: false,
      };

    case aaveManageTypes.NEW_ATOKEN_APPROVED_SUCCESS:
      return {
        ...state,
        newATokenApproving: false,
        newATokenApprovingError: '',
        newATokenApproved: true,
      };

    case aaveManageTypes.NEW_ATOKEN_APPROVED_FAILURE:
      return {
        ...state,
        newATokenApproving: false,
        newATokenApprovingError: '',
        newATokenApproved: false,
      };

    case aaveManageTypes.MIGRATE_AAVE_FROM_V1_TO_V2_REQUEST:
      return {
        ...state,
        migratingFromV1ToV2: true,
      };

    case aaveManageTypes.MIGRATE_AAVE_FROM_V1_TO_V2_SUCCESS:
      return {
        ...state,
        migratingFromV1ToV2: false,
        migratingFromV1ToV2Error: '',
      };

    case aaveManageTypes.MIGRATE_AAVE_FROM_V1_TO_V2_FAILURE:
      return {
        ...state,
        migratingFromV1ToV2: false,
        migratingFromV1ToV2Error: payload,
      };

      // NEW

    case aaveManageTypes.AAVE_SET_DASHBOARD_ACTION:
      return {
        ...state,
        selectedAction: payload,
        afterCdp: null,
      };

    case aaveManageTypes.AAVE_SET_ADDITIONAL_DASHBOARD_ACTION:
      return {
        ...state,
        selectedAdditionalActions: {
          ...state.selectedAdditionalActions,
          [state.selectedAction.value]: payload,
        },
        afterCdp: null,
      };

    case aaveManageTypes.AAVE_ACTION_EXEC_REQUEST:
      return {
        ...state,
        executing: {
          ...state.executing,
          [action.action]: true,
        },
        executingErrors: {
          ...state.executingErrors,
          [action.action]: '',
        },
      };

    case aaveManageTypes.AAVE_ACTION_EXEC_SUCCESS:
      return {
        ...state,
        executing: {
          ...state.executing,
          [action.action]: false,
        },
      };

    case aaveManageTypes.AAVE_ACTION_EXEC_FAILURE:
      return {
        ...state,
        executing: {
          ...state.executing,
          [action.action]: false,
        },
        executingErrors: {
          ...state.executingErrors,
          [action.action]: payload,
        },
      };

    case aaveManageTypes.AAVE_GET_MAX_VALUE_REQUEST:
      return {
        ...state,
        loadingMaxValues: {
          ...state.loadingMaxValues,
          [action.forAction]: true,
        },
        maxValuesErrors: {
          ...state.loadingMaxValues,
          [action.forAction]: '',
        },
      };

    case aaveManageTypes.AAVE_GET_MAX_VALUE_SUCCESS:
      return {
        ...state,
        loadingMaxValues: {
          ...state.loadingMaxValues,
          [action.forAction]: false,
        },
        maxValues: {
          ...state.maxValues,
          [action.forAction]: payload,
        },
      };

    case aaveManageTypes.AAVE_GET_MAX_VALUE_FAILURE:
      return {
        ...state,
        loadingMaxValues: {
          ...state.loadingMaxValues,
          [action.forAction]: false,
        },
        maxValuesErrors: {
          ...state.loadingMaxValues,
          [action.forAction]: payload,
        },
      };

    default:
      return state;
  }
};
