import Dec from 'decimal.js';
import dfs from '@defisaver/sdk';
import {
  assetAmountInEth,
  assetAmountInWei, getAssetInfo, getIlkInfo, MAXUINT,
} from '@defisaver/tokens';
import { BCdpManagerAddress, mcdCdpManagerAddress } from 'services/contractRegistryService';
import { getExchangeOrder } from 'services/exchangeServiceV3';
import { ethToWeth, getWeiAmountForDecimals } from '../services/utils';
import { getFLAction } from '../services/assetsService';
import { normalizeFunc, setSlippagePercent } from '../services/exchangeServiceCommon';
import {
  compareMarketPriceWithPoolPrice,
  getLPLiquidityValueEstimate,
  getUniswapPrice,
} from '../services/uniswapServices';
import { getCdpManagerForType } from '../services/makerServices/makerMcdService';
import { CONTINUOUS_FEE_MULTIPLIER } from '../constants/general';

const sendRemainingAmountsToAcc = (isSecondAssetWeth, account, otherAsset) => [
  new dfs.actions.basic.SendTokenAction(getAssetInfo('DAI').address, account, MAXUINT),
  isSecondAssetWeth ? new dfs.actions.basic.UnwrapEthAction(MAXUINT, account) : new dfs.actions.basic.SendTokenAction(getAssetInfo(otherAsset).address, account, MAXUINT),
];

/**
 * Standard Maker Boost
 */
export const boost = async (debtAmount, price, slippagePercent, cdp, proxyAddress, type = 'mcd', additionalActions) => {
  const collAsset = ethToWeth(cdp.asset);
  const { orderData, value, extraGas } = await getExchangeOrder('DAI', collAsset, debtAmount, price, slippagePercent, proxyAddress, false, false, true);
  const debtAmountWei = assetAmountInWei(debtAmount, 'DAI');

  const managerAddr = getCdpManagerForType(type);

  const recipe = new dfs.Recipe('recMakerBoost', [
    ...additionalActions,
    new dfs.actions.maker.MakerGenerateAction(cdp.id, debtAmountWei, proxyAddress, managerAddr),
    new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value),
  ]);
  const previousActionId = `$${recipe.actions.length}`;
  recipe.addAction(new dfs.actions.maker.MakerSupplyAction(cdp.id, previousActionId, getIlkInfo(cdp.ilkLabel).join, proxyAddress, managerAddr));
  recipe.extraGas = extraGas;
  return recipe;
};

/**
 * Maker FL Boost for simple assets (non-LP, tradeable on exchanges)
 */
export const boostWithDebtLoan = async (debtAmount, price, slippagePercent, cdp, proxyAddress, flProtocol, type = 'mcd', additionalActions) => {
  const collAsset = ethToWeth(cdp.asset);
  const { orderData, value, extraGas } = await getExchangeOrder('DAI', collAsset, debtAmount, price, slippagePercent, proxyAddress, false, false, true, flProtocol === 'balancer' ? ['Balancer_V2'] : []);
  const debtAmountWei = assetAmountInWei(debtAmount, 'DAI');

  const managerAddr = getCdpManagerForType(type);

  const { FLAction, paybackAddress } = getFLAction(flProtocol, debtAmountWei, 'DAI');

  const recipe = new dfs.Recipe('recMakerFLBoost', [
    FLAction,
    new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value),
    new dfs.actions.maker.MakerSupplyAction(cdp.id, '$2', getIlkInfo(cdp.ilkLabel).join, proxyAddress, managerAddr),
    ...additionalActions,
    new dfs.actions.maker.MakerGenerateAction(cdp.id, '$1', paybackAddress, managerAddr),
  ]);
  recipe.extraGas = extraGas;
  return recipe;
};


/**
 * Standard Maker Repay
 */
export const repay = async (collAmount, price, slippagePercent, cdp, proxyAddress, account, type = 'mcd', additionalActions) => {
  const collAsset = ethToWeth(cdp.asset);
  const { orderData, value, extraGas } = await getExchangeOrder(collAsset, 'DAI', collAmount, price, slippagePercent, proxyAddress, false, false, true);
  const collAmountWei = assetAmountInWei(collAmount, cdp.asset);
  const payingBackAllDebt = new Dec(collAmount * price).gte(cdp.debtInAsset);

  const managerAddr = getCdpManagerForType(type);

  const recipe = new dfs.Recipe('recMakerRepay', [
    new dfs.actions.maker.MakerWithdrawAction(cdp.id, collAmountWei, getIlkInfo(cdp.ilkLabel).join, proxyAddress, managerAddr),
    new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value),
    new dfs.actions.maker.MakerPaybackAction(cdp.id, '$2', proxyAddress, managerAddr),
  ]);
  // If we're repaying the whole debt, we'll be buying a bit more than the required debt amount
  // MakerPaybackAction will pay back an amount up to the total debt, and leave the rest on the proxy
  if (payingBackAllDebt) recipe.addAction(new dfs.actions.basic.SendTokenAction(getAssetInfo('DAI').address, account, MAXUINT));
  additionalActions.forEach((a) => recipe.addAction(a));
  recipe.extraGas = extraGas;
  return recipe;
};


/**
 * Maker FL Repay for assets where FL is available
 */
export const repayWithCollLoan = async (collAmount, maxWithdraw, price, slippagePercent, cdp, proxyAddress, account, flProtocol, type = 'mcd', additionalActions) => {
  const collAsset = ethToWeth(cdp.asset);
  const { orderData, value, extraGas } = await getExchangeOrder(collAsset, 'DAI', collAmount, price, slippagePercent, proxyAddress, false, false, true, flProtocol === 'balancer' ? ['Balancer_V2'] : []);
  const collAmountWei = assetAmountInWei(collAmount, cdp.asset);
  const maxWithdrawWei = assetAmountInWei(maxWithdraw, cdp.asset);
  const payingBackAllDebt = new Dec(collAmount * price).gte(cdp.debtInAsset);
  const managerAddr = getCdpManagerForType(type);

  const recipe = new dfs.Recipe('recMakerFRepayViaColl', []);

  // Aave flashloans have a fee, so we minimize the loan amount/fee, at the cost of gas
  // (except when cdp is under dust limit because withdraw is then impossible)
  const partialFl = flProtocol === 'aave' && !cdp.debtTooLow;

  let flPaybackAddr;
  if (partialFl) {
    const flAmount = new Dec(collAmountWei).sub(maxWithdrawWei).toString();
    const { FLAction, paybackAddress } = getFLAction(flProtocol, flAmount, ethToWeth(cdp.asset));
    flPaybackAddr = paybackAddress;
    recipe.addAction(FLAction);
    recipe.addAction(new dfs.actions.maker.MakerWithdrawAction(cdp.id, maxWithdrawWei, getIlkInfo(cdp.ilkLabel).join, proxyAddress, managerAddr));
  } else {
    const { FLAction, paybackAddress } = getFLAction(flProtocol, collAmountWei, ethToWeth(cdp.asset));
    flPaybackAddr = paybackAddress;
    recipe.addAction(FLAction);
  }
  recipe.addAction(new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value));
  const previousActionId = `$${recipe.actions.length}`;
  recipe.addAction(new dfs.actions.maker.MakerPaybackAction(cdp.id, previousActionId, proxyAddress, managerAddr));
  recipe.addAction(new dfs.actions.maker.MakerWithdrawAction(cdp.id, '$1', getIlkInfo(cdp.ilkLabel).join, flPaybackAddr, managerAddr));
  if (payingBackAllDebt) recipe.addAction(new dfs.actions.basic.SendTokenAction(getAssetInfo('DAI').address, account, MAXUINT));
  additionalActions.forEach((a) => recipe.addAction(a));
  recipe.extraGas = extraGas;
  return recipe;
};

/**
 * Maker FL Repay for assets without FL
 */
export const repayWithDebtLoan = async (collAmount, price, slippagePercent, cdp, proxyAddress, account, flProtocol, type = 'mcd', additionalActions) => {
  const collAsset = ethToWeth(cdp.asset);
  const { orderData, value, extraGas } = await getExchangeOrder(collAsset, 'DAI', collAmount, price, slippagePercent, proxyAddress, false, false, true, flProtocol === 'balancer' ? ['Balancer_V2'] : []);
  const collAmountWei = assetAmountInWei(collAmount, cdp.asset);
  const payingBackAllDebt = new Dec(collAmount * price).gte(cdp.debtInAsset);

  const managerAddr = getCdpManagerForType(type);

  const estDebtAmount = new Dec(collAmount).mul(price).mul((100 - slippagePercent) / 100).toString();
  const estDebtAmountWei = assetAmountInWei(estDebtAmount, 'DAI');
  const { FLAction, paybackAddress } = getFLAction(flProtocol, estDebtAmountWei, 'DAI');

  const recipe = new dfs.Recipe('recMakerFRepayViaDebt', [
    FLAction,
    new dfs.actions.maker.MakerPaybackAction(cdp.id, estDebtAmountWei, proxyAddress, managerAddr),
    new dfs.actions.maker.MakerWithdrawAction(cdp.id, collAmountWei, getIlkInfo(cdp.ilkLabel).join, proxyAddress, managerAddr),
    new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value),
    new dfs.actions.basic.SendTokenAction(getAssetInfo('DAI').address, paybackAddress, '$1'),
  ]);
  recipe.extraGas = extraGas;
  if (payingBackAllDebt) recipe.addAction(new dfs.actions.basic.SendTokenAction(getAssetInfo('DAI').address, account, MAXUINT));
  else {
    recipe.addAction(new dfs.actions.basic.SubInputsAction('$4', '$1'));
    recipe.addAction(new dfs.actions.maker.MakerPaybackAction(cdp.id, '$6', proxyAddress, managerAddr));
  }
  additionalActions.forEach((a) => recipe.addAction(a));
  return recipe;
};

export const boostLPWithDAI = async (debtAmount, assets, firstAmount, secondAmount, price, slippagePercent, cdp, proxyAddress, account, type = 'mcd', deadline = 20, additionalActions) => {
  const otherAsset = assets.isFirstDAI ? assets.secondAsset : assets.firstAsset;

  const actualSellAmount = assets.isFirstDAI ? secondAmount : firstAmount;
  const leftoverDAIAmount = assets.isFirstDAI ? firstAmount : secondAmount;

  const estSecondAmountAfterSell = new Dec(actualSellAmount).mul(price).toString();
  const estSecondAmount = setSlippagePercent(normalizeFunc(slippagePercent), estSecondAmountAfterSell);

  const { orderData, value, extraGas } = await getExchangeOrder('DAI', ethToWeth(otherAsset), actualSellAmount, price, slippagePercent, proxyAddress, false, false, true);

  const leftoverDAIAmountWei = assetAmountInWei(leftoverDAIAmount, 'DAI');

  const minDaiAmount = setSlippagePercent(normalizeFunc(slippagePercent), leftoverDAIAmount);
  const minDaiAmountWei = assetAmountInWei(minDaiAmount, 'DAI');
  const minOtherAmountWei = assetAmountInWei(estSecondAmount, otherAsset);

  const managerAddr = getCdpManagerForType(type);
  const isSecondAssetWeth = ethToWeth(otherAsset) === 'WETH';

  const additionalActionsLength = additionalActions.length;

  const recipe = new dfs.Recipe('recMakerBoostLPDAI', [
    ...additionalActions,
    new dfs.actions.maker.MakerGenerateAction(cdp.id, assetAmountInWei(debtAmount, 'DAI'), proxyAddress, managerAddr),
    new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value),
    new dfs.actions.uniswap.UniswapSupplyAction(
      getAssetInfo(ethToWeth(assets.firstAsset)).address,
      getAssetInfo(ethToWeth(assets.secondAsset)).address,
      proxyAddress,
      proxyAddress,
      assets.isFirstDAI ? leftoverDAIAmountWei : `$${2 + additionalActionsLength}`,
      assets.isFirstDAI ? `$${2 + additionalActionsLength}` : leftoverDAIAmountWei,
      assets.isFirstDAI ? minDaiAmountWei : minOtherAmountWei,
      assets.isFirstDAI ? minOtherAmountWei : minDaiAmountWei,
      Date.now() + (1000 * 60 * deadline),
    ),
    new dfs.actions.maker.MakerSupplyAction(cdp.id, `$${3 + additionalActionsLength}`, getIlkInfo(cdp.ilkLabel).join, proxyAddress, managerAddr),
    new dfs.actions.basic.SendTokenAction(getAssetInfo('DAI').address, account, MAXUINT),
    isSecondAssetWeth ? new dfs.actions.basic.UnwrapEthAction(MAXUINT, account) : new dfs.actions.basic.SendTokenAction(getAssetInfo(otherAsset).address, account, MAXUINT),
  ]);
  recipe.extraGas = extraGas;

  return recipe;
};

export const boostLPWithFLDAI = async (debtAmount, assets, firstAmount, secondAmount, price, slippagePercent, cdp, proxyAddress, account, type = 'mcd', flProtocol, deadline = 20, additionalActions) => {
  const debtAmountWei = assetAmountInWei(debtAmount, 'DAI');
  const otherAsset = assets.isFirstDAI ? assets.secondAsset : assets.firstAsset;

  const actualSellAmount = assets.isFirstDAI ? secondAmount : firstAmount;
  const leftoverDAIAmount = assets.isFirstDAI ? firstAmount : secondAmount;

  const estSecondAmountAfterSell = new Dec(actualSellAmount).mul(price).toString();
  const estSecondAmount = setSlippagePercent(normalizeFunc(slippagePercent), estSecondAmountAfterSell);

  const { orderData, value, extraGas } = await getExchangeOrder('DAI', ethToWeth(otherAsset), actualSellAmount, price, slippagePercent, proxyAddress, false, false, true);

  const leftoverDAIAmountWei = assetAmountInWei(leftoverDAIAmount, 'DAI');

  const minDaiAmount = setSlippagePercent(normalizeFunc(slippagePercent), leftoverDAIAmount);
  const minDaiAmountWei = assetAmountInWei(minDaiAmount, 'DAI');
  const minOtherAmountWei = assetAmountInWei(estSecondAmount, otherAsset);

  const { FLAction, paybackAddress } = getFLAction(flProtocol, debtAmountWei, 'DAI');

  const managerAddr = getCdpManagerForType(type);
  const isSecondAssetWeth = ethToWeth(otherAsset) === 'WETH';

  const recipe = new dfs.Recipe('recFLMakerBoostLPDAI', [
    FLAction,
    new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value),
    new dfs.actions.uniswap.UniswapSupplyAction(
      getAssetInfo(ethToWeth(assets.firstAsset)).address,
      getAssetInfo(ethToWeth(assets.secondAsset)).address,
      proxyAddress,
      proxyAddress,
      assets.isFirstDAI ? leftoverDAIAmountWei : '$2',
      assets.isFirstDAI ? '$2' : leftoverDAIAmountWei,
      assets.isFirstDAI ? minDaiAmountWei : minOtherAmountWei,
      assets.isFirstDAI ? minOtherAmountWei : minDaiAmountWei,
      Date.now() + (1000 * 60 * deadline),
    ),
    new dfs.actions.maker.MakerSupplyAction(cdp.id, '$3', getIlkInfo(cdp.ilkLabel).join, proxyAddress, managerAddr),
    ...additionalActions,
    new dfs.actions.maker.MakerGenerateAction(cdp.id, assetAmountInWei(debtAmount, 'DAI'), paybackAddress, managerAddr),
    new dfs.actions.basic.SendTokenAction(getAssetInfo('DAI').address, account, MAXUINT),
    isSecondAssetWeth ? new dfs.actions.basic.UnwrapEthAction(MAXUINT, account) : new dfs.actions.basic.SendTokenAction(getAssetInfo(otherAsset).address, account, MAXUINT),
  ]);
  recipe.extraGas = extraGas;

  return recipe;
};

export const boostLPWithoutDAI = async (debtAmount, assets, firstAmount, secondAmount, price, priceSecond, slippagePercent, cdp, proxyAddress, account, type = 'mcd', deadline = 20, additionalActions) => {
  const _sellAmount = new Dec(debtAmount).div(2).toString();

  const estFirstAmount = new Dec(firstAmount).mul(price).toString();
  const estSecondAmount = new Dec(secondAmount).mul(priceSecond).toString();

  const {
    orderData, value, extraGas, liquiditySources,
  } = await getExchangeOrder('DAI', ethToWeth(assets.firstAsset), firstAmount, price, slippagePercent, proxyAddress, false, false, true);

  const isFirstSwapGoingToBalancer = (liquiditySources && liquiditySources.some(source => source.name === 'Balancer_V2'));

  const { orderData: orderDataSecond, value: valueSecond, extraGas: extraGasSecond } = await getExchangeOrder('DAI', ethToWeth(assets.secondAsset), secondAmount, priceSecond, slippagePercent, proxyAddress, false, false, true, isFirstSwapGoingToBalancer ? ['Balancer_V2'] : []);

  const minFirstAmount = setSlippagePercent(normalizeFunc(slippagePercent), estFirstAmount);
  const minSecondAmount = setSlippagePercent(normalizeFunc(slippagePercent), estSecondAmount);
  const minFirstAmountWei = assetAmountInWei(minFirstAmount, ethToWeth(assets.firstAsset));
  const minSecondAmountWei = assetAmountInWei(minSecondAmount, ethToWeth(assets.secondAsset));

  const managerAddr = getCdpManagerForType(type);

  const isFirstWeth = ethToWeth(assets.firstAsset) === 'WETH';
  const isSecondWeth = ethToWeth(assets.secondAsset) === 'WETH';

  const additionalActionsLength = additionalActions.length;

  const recipe = new dfs.Recipe('recMakerBoostLPNoDAI', [
    ...additionalActions,
    new dfs.actions.maker.MakerGenerateAction(cdp.id, assetAmountInWei(debtAmount, 'DAI'), proxyAddress, managerAddr),
    new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value),
    new dfs.actions.basic.SellAction(orderDataSecond, proxyAddress, proxyAddress, valueSecond),
    new dfs.actions.uniswap.UniswapSupplyAction(
      getAssetInfo(ethToWeth(assets.firstAsset)).address,
      getAssetInfo(ethToWeth(assets.secondAsset)).address,
      proxyAddress,
      proxyAddress,
      `$${2 + additionalActionsLength}`,
      `$${3 + additionalActionsLength}`,
      minFirstAmountWei,
      minSecondAmountWei,
      Date.now() + (1000 * 60 * deadline),
    ),
    new dfs.actions.maker.MakerSupplyAction(cdp.id, `$${4 + additionalActionsLength}`, getIlkInfo(cdp.ilkLabel).join, proxyAddress, managerAddr),
  ]);
  if (isFirstWeth) {
    recipe.addAction(new dfs.actions.basic.UnwrapEthAction(MAXUINT, account));
    recipe.addAction(new dfs.actions.basic.SendTokenAction(getAssetInfo(ethToWeth(assets.secondAsset)).address, account, MAXUINT));
  } else if (isSecondWeth) {
    recipe.addAction(new dfs.actions.basic.UnwrapEthAction(MAXUINT, account));
    recipe.addAction(new dfs.actions.basic.SendTokenAction(getAssetInfo(ethToWeth(assets.firstAsset)).address, account, MAXUINT));
  } else {
    recipe.addAction(new dfs.actions.basic.SendTokenAction(getAssetInfo(ethToWeth(assets.secondAsset)).address, account, MAXUINT));
    recipe.addAction(new dfs.actions.basic.SendTokenAction(getAssetInfo(ethToWeth(assets.firstAsset)).address, account, MAXUINT));
  }
  recipe.extraGas = extraGas;

  return recipe;
};

export const boostLPWithoutDAIFL = async (debtAmount, assets, firstAmount, secondAmount, price, priceSecond, slippagePercent, cdp, proxyAddress, account, type = 'mcd', flProtocol, deadline = 20, additionalActions) => {
  const debtAmountWei = assetAmountInWei(debtAmount, 'DAI');

  const estFirstAmount = new Dec(firstAmount).mul(price).toString();
  const estSecondAmount = new Dec(secondAmount).mul(priceSecond).toString();

  const { FLAction, paybackAddress } = getFLAction(flProtocol, debtAmountWei, 'DAI');

  const {
    orderData, value, extraGas, liquiditySources,
  } = await getExchangeOrder('DAI', ethToWeth(assets.firstAsset), firstAmount, price, slippagePercent, proxyAddress, false, false, true, flProtocol === 'balancer' ? ['Balancer_V2'] : []);

  const isFLOrSwapGoingToBalancer = flProtocol === 'balancer' || (liquiditySources && liquiditySources.some(source => source.name === 'Balancer_V2'));

  const { orderData: orderDataSecond, value: valueSecond, extraGas: extraGasSecond } = await getExchangeOrder('DAI', ethToWeth(assets.secondAsset), secondAmount, priceSecond, slippagePercent, proxyAddress, false, false, true, isFLOrSwapGoingToBalancer ? ['Balancer_V2'] : []);

  const minFirstAmount = setSlippagePercent(normalizeFunc(slippagePercent), estFirstAmount);
  const minSecondAmount = setSlippagePercent(normalizeFunc(slippagePercent), estSecondAmount);

  const minFirstAmountWei = assetAmountInWei(minFirstAmount, ethToWeth(assets.firstAsset));
  const minSecondAmountWei = assetAmountInWei(minSecondAmount, ethToWeth(assets.secondAsset));

  const managerAddr = getCdpManagerForType(type);

  const isFirstWeth = ethToWeth(assets.firstAsset) === 'WETH';
  const isSecondWeth = ethToWeth(assets.secondAsset) === 'WETH';

  const recipe = new dfs.Recipe('recFLMakerBoostLPNoDAI', [
    FLAction,
    new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value),
    new dfs.actions.basic.SellAction(orderDataSecond, proxyAddress, proxyAddress, valueSecond),
    new dfs.actions.uniswap.UniswapSupplyAction(
      getAssetInfo(ethToWeth(assets.firstAsset)).address,
      getAssetInfo(ethToWeth(assets.secondAsset)).address,
      proxyAddress,
      proxyAddress,
      '$2',
      '$3',
      minFirstAmountWei,
      minSecondAmountWei,
      Date.now() + (1000 * 60 * deadline),
    ),
    new dfs.actions.maker.MakerSupplyAction(cdp.id, '$4', getIlkInfo(cdp.ilkLabel).join, proxyAddress, managerAddr),
    ...additionalActions,
    new dfs.actions.maker.MakerGenerateAction(cdp.id, assetAmountInWei(debtAmount, 'DAI'), paybackAddress, managerAddr),
  ]);
  if (isFirstWeth) {
    recipe.addAction(new dfs.actions.basic.UnwrapEthAction(MAXUINT, account));
    recipe.addAction(new dfs.actions.basic.SendTokenAction(getAssetInfo(ethToWeth(assets.secondAsset)).address, account, MAXUINT));
  } else if (isSecondWeth) {
    recipe.addAction(new dfs.actions.basic.UnwrapEthAction(MAXUINT, account));
    recipe.addAction(new dfs.actions.basic.SendTokenAction(getAssetInfo(ethToWeth(assets.firstAsset)).address, account, MAXUINT));
  } else {
    recipe.addAction(new dfs.actions.basic.SendTokenAction(getAssetInfo(ethToWeth(assets.secondAsset)).address, account, MAXUINT));
    recipe.addAction(new dfs.actions.basic.SendTokenAction(getAssetInfo(ethToWeth(assets.firstAsset)).address, account, MAXUINT));
  }
  recipe.extraGas = extraGas;

  return recipe;
};

export const repayLPWithDAI = async (collAmount, assets, price, slippagePercent, cdp, proxyAddress, account, type = 'mcd', deadline = 20, additionalActions) => {
  const [firstAmount, secondAmount, lpAddress] = await getLPLiquidityValueEstimate(ethToWeth(assets.firstAsset), ethToWeth(assets.secondAsset), collAmount);

  const minFirstAmount = setSlippagePercent(normalizeFunc(slippagePercent), firstAmount);
  const minSecondAmount = setSlippagePercent(normalizeFunc(slippagePercent), secondAmount);
  const minFirstAmountWei = assetAmountInWei(minFirstAmount, assets.firstAsset);
  const minSecondAmountWei = assetAmountInWei(minSecondAmount, assets.secondAsset);

  const [sellAsset, sellAmount] = assets.isFirstDAI ? [ethToWeth(assets.secondAsset), secondAmount] : [ethToWeth(assets.firstAsset), firstAmount];
  // include slippage in sellAmount because we can't know the exact amount we are getting when withdrawing from uni pool
  // const sellAmount = setSlippagePercent(normalizeFunc(0.5), _sellAmount);

  const collAmountWei = getWeiAmountForDecimals(collAmount, 18);
  const sellAmountWei = assetAmountInWei(sellAmount, sellAsset);

  const { orderData, value, extraGas } = await getExchangeOrder(sellAsset, 'DAI', sellAmount, price, slippagePercent, proxyAddress, false, false, true);
  orderData[2] = '$3';
  const managerAddr = getCdpManagerForType(type);
  const isSecondAssetWeth = ethToWeth(sellAsset) === 'WETH';

  const recipe = new dfs.Recipe('recMakerRepayLPDAI', [
    new dfs.actions.maker.MakerWithdrawAction(cdp.id, getWeiAmountForDecimals(collAmount, 18), getIlkInfo(cdp.ilkLabel).join, proxyAddress, managerAddr),
    new dfs.actions.uniswap.UniswapWithdrawAction(
      getAssetInfo(ethToWeth(assets.firstAsset)).address,
      getAssetInfo(ethToWeth(assets.secondAsset)).address,
      collAmountWei,
      proxyAddress,
      proxyAddress,
      minFirstAmountWei,
      minSecondAmountWei,
      Date.now() + (1000 * 60 * deadline),
    ),
    new dfs.actions.basic.TokenBalanceAction(getAssetInfo(sellAsset).address, proxyAddress),
    new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value),
    new dfs.actions.basic.TokenBalanceAction(getAssetInfo('DAI').address, proxyAddress),
    new dfs.actions.maker.MakerPaybackAction(cdp.id, '$5', proxyAddress, managerAddr),
    new dfs.actions.basic.SendTokenAction(getAssetInfo('DAI').address, account, MAXUINT),
    isSecondAssetWeth ? new dfs.actions.basic.UnwrapEthAction(MAXUINT, account) : new dfs.actions.basic.SendTokenAction(getAssetInfo(sellAsset).address, account, MAXUINT),
  ]);
  additionalActions.forEach((a) => recipe.addAction(a));

  recipe.extraGas = extraGas;

  return recipe;
};

export const repayLPWithFLDAI = async (collAmount, lpFullRepayAmount, assets, price, slippagePercent, cdp, proxyAddress, account, type = 'mcd', flProtocol, deadline = 20, additionalActions) => {
  const [firstAmount, secondAmount, lpAddress] = await getLPLiquidityValueEstimate(ethToWeth(assets.firstAsset), ethToWeth(assets.secondAsset), collAmount);

  const sellAmountNoSlpg = assets.isFirstDAI ? secondAmount : firstAmount;

  const minFirstAmount = setSlippagePercent(normalizeFunc(slippagePercent), firstAmount);
  const minSecondAmount = setSlippagePercent(normalizeFunc(slippagePercent), secondAmount);
  const minFirstAmountWei = assetAmountInWei(minFirstAmount, assets.firstAsset);
  const minSecondAmountWei = assetAmountInWei(minSecondAmount, assets.secondAsset);

  const [[sellAsset, sellAmount], [, daiAmount]] = assets.isFirstDAI ? [[assets.secondAsset, minSecondAmount], [assets.firstAsset, minFirstAmount]] : [[assets.firstAsset, minFirstAmount], [assets.secondAsset, minSecondAmount]];

  const lpFullRepayAmountWei = assetAmountInWei(new Dec(new Dec(price).mul(sellAmount)).plus(daiAmount).toString(), 'DAI');
  const { FLAction, paybackAddress } = getFLAction(flProtocol, lpFullRepayAmountWei, 'DAI');

  const collAmountWei = getWeiAmountForDecimals(collAmount, 18);
  // 0x takes amount from min(orderData[2], amount in api call for quote)
  const { orderData, value, extraGas } = await getExchangeOrder(ethToWeth(sellAsset), 'DAI', sellAmountNoSlpg, price, slippagePercent, proxyAddress, false, false, true);
  orderData[2] = '$5';
  const managerAddr = getCdpManagerForType(type);
  const isSecondAssetWeth = ethToWeth(sellAsset) === 'WETH';

  const recipe = new dfs.Recipe('recFLMakerRepayLPDAI', [
    FLAction,
    new dfs.actions.maker.MakerPaybackAction(cdp.id, lpFullRepayAmountWei, proxyAddress, managerAddr),
    new dfs.actions.maker.MakerWithdrawAction(cdp.id, getWeiAmountForDecimals(collAmount, 18), getIlkInfo(cdp.ilkLabel).join, proxyAddress, managerAddr),
    new dfs.actions.uniswap.UniswapWithdrawAction(
      getAssetInfo(ethToWeth(assets.firstAsset)).address,
      getAssetInfo(ethToWeth(assets.secondAsset)).address,
      collAmountWei,
      proxyAddress,
      proxyAddress,
      minFirstAmountWei,
      minSecondAmountWei,
      Date.now() + (1000 * 60 * deadline),
    ),
    new dfs.actions.basic.TokenBalanceAction(getAssetInfo(ethToWeth(sellAsset)).address, proxyAddress),
    new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value),
    new dfs.actions.basic.SendTokenAction(getAssetInfo('DAI').address, paybackAddress, lpFullRepayAmountWei),
    new dfs.actions.basic.SendTokenAction(getAssetInfo('DAI').address, account, MAXUINT),
    isSecondAssetWeth ? new dfs.actions.basic.UnwrapEthAction(MAXUINT, account) : new dfs.actions.basic.SendTokenAction(getAssetInfo(sellAsset).address, account, MAXUINT),
  ]);

  additionalActions.forEach((a) => recipe.addAction(a));

  recipe.extraGas = extraGas;

  return recipe;
};

export const repayLPWithoutDAI = async (collAmount, assets, price, priceSecond, slippagePercent, cdp, proxyAddress, account, type = 'mcd', deadline = 20, additionalActions) => {
  const [firstAmount, secondAmount, lpAddress] = await getLPLiquidityValueEstimate(ethToWeth(assets.firstAsset), ethToWeth(assets.secondAsset), collAmount);

  const minFirstAmount = setSlippagePercent(normalizeFunc(slippagePercent), firstAmount.toString());
  const minSecondAmount = setSlippagePercent(normalizeFunc(slippagePercent), secondAmount.toString());
  const minFirstAmountWei = assetAmountInWei(minFirstAmount, assets.firstAsset);
  const minSecondAmountWei = assetAmountInWei(minSecondAmount, assets.secondAsset);

  const collAmountWei = getWeiAmountForDecimals(collAmount, 18);

  const {
    orderData, value, extraGas, liquiditySources,
  } = await getExchangeOrder(ethToWeth(assets.firstAsset), 'DAI', firstAmount, price, slippagePercent, proxyAddress, false, false, true);
  orderData[2] = '$3';

  const isFirstSwapGoingToBalancer = (liquiditySources && liquiditySources.some(source => source.name === 'Balancer_V2'));

  const { orderData: orderDataSecond, value: valueSecond, extraGas: extraGasSecond } = await getExchangeOrder(ethToWeth(assets.secondAsset), 'DAI', secondAmount, priceSecond, slippagePercent, proxyAddress, false, false, true, isFirstSwapGoingToBalancer ? ['Balancer_V2'] : []);
  orderDataSecond[2] = '$4';

  const managerAddr = getCdpManagerForType(type);

  const isFirstWeth = ethToWeth(assets.firstAsset) === 'WETH';
  const isSecondWeth = ethToWeth(assets.secondAsset) === 'WETH';

  const recipe = new dfs.Recipe('recMakerLPRepayNoDAI', [
    new dfs.actions.maker.MakerWithdrawAction(cdp.id, getWeiAmountForDecimals(collAmount, 18), getIlkInfo(cdp.ilkLabel).join, proxyAddress, managerAddr),
    new dfs.actions.uniswap.UniswapWithdrawAction(
      getAssetInfo(ethToWeth(assets.firstAsset)).address,
      getAssetInfo(ethToWeth(assets.secondAsset)).address,
      collAmountWei,
      proxyAddress,
      proxyAddress,
      minFirstAmountWei,
      minSecondAmountWei,
      Date.now() + (1000 * 60 * deadline),
    ),
    new dfs.actions.basic.TokenBalanceAction(getAssetInfo(ethToWeth(assets.firstAsset)).address, proxyAddress),
    new dfs.actions.basic.TokenBalanceAction(getAssetInfo(ethToWeth(assets.secondAsset)).address, proxyAddress),
    new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value),
    new dfs.actions.basic.SellAction(orderDataSecond, proxyAddress, proxyAddress, valueSecond),
    new dfs.actions.basic.TokenBalanceAction(getAssetInfo('DAI').address, proxyAddress),
    new dfs.actions.maker.MakerPaybackAction(cdp.id, '$7', proxyAddress, managerAddr),
  ]);

  if (isFirstWeth) {
    recipe.addAction(new dfs.actions.basic.UnwrapEthAction(MAXUINT, account));
    recipe.addAction(new dfs.actions.basic.SendTokenAction(getAssetInfo(ethToWeth(assets.secondAsset)).address, account, MAXUINT));
  } else if (isSecondWeth) {
    recipe.addAction(new dfs.actions.basic.UnwrapEthAction(MAXUINT, account));
    recipe.addAction(new dfs.actions.basic.SendTokenAction(getAssetInfo(ethToWeth(assets.firstAsset)).address, account, MAXUINT));
  } else {
    recipe.addAction(new dfs.actions.basic.SendTokenAction(getAssetInfo(ethToWeth(assets.secondAsset)).address, account, MAXUINT));
    recipe.addAction(new dfs.actions.basic.SendTokenAction(getAssetInfo(ethToWeth(assets.firstAsset)).address, account, MAXUINT));
  }

  additionalActions.forEach((a) => recipe.addAction(a));

  recipe.extraGas = extraGas;

  return recipe;
};

export const repayLPWithoutDAIFL = async (collAmount, lpFullRepayAmount, assets, price, priceSecond, slippagePercent, cdp, proxyAddress, account, type = 'mcd', flProtocol, deadline = 20, additionalActions) => {
  const [firstAmount, secondAmount, lpAddress] = await getLPLiquidityValueEstimate(ethToWeth(assets.firstAsset), ethToWeth(assets.secondAsset), collAmount);

  const minFirstAmount = setSlippagePercent(normalizeFunc(slippagePercent), firstAmount);
  const minSecondAmount = setSlippagePercent(normalizeFunc(slippagePercent), secondAmount);
  const minFirstAmountWei = assetAmountInWei(minFirstAmount, assets.firstAsset);
  const minSecondAmountWei = assetAmountInWei(minSecondAmount, assets.secondAsset);


  const collAmountWei = getWeiAmountForDecimals(collAmount, 18);

  const lpFullRepayAmountWei = assetAmountInWei(new Dec(new Dec(price).mul(minFirstAmount)).plus(new Dec(priceSecond).mul(minSecondAmount)).toString(), 'DAI');

  const { FLAction, paybackAddress } = getFLAction(flProtocol, lpFullRepayAmountWei, 'DAI');

  const {
    orderData, value, extraGas, liquiditySources,
  } = await getExchangeOrder(ethToWeth(assets.firstAsset), 'DAI', firstAmount, price, slippagePercent, proxyAddress, false, false, true, flProtocol === 'balancer' ? ['Balancer_V2'] : []);
  orderData[2] = '$5';

  const isFLOrSwapGoingToBalancer = flProtocol === 'balancer' || (liquiditySources && liquiditySources.some(source => source.name === 'Balancer_V2'));

  const { orderData: orderDataSecond, value: valueSecond, extraGas: extraGasSecond } = await getExchangeOrder(ethToWeth(assets.secondAsset), 'DAI', secondAmount, priceSecond, slippagePercent, proxyAddress, false, false, true, isFLOrSwapGoingToBalancer ? ['Balancer_V2'] : []);
  orderDataSecond[2] = '$6';

  const managerAddr = getCdpManagerForType(type);

  const isFirstWeth = ethToWeth(assets.firstAsset) === 'WETH';
  const isSecondWeth = ethToWeth(assets.secondAsset) === 'WETH';

  const recipe = new dfs.Recipe('recFLMakerLPRepayNoDAI', [
    FLAction,
    new dfs.actions.maker.MakerPaybackAction(cdp.id, lpFullRepayAmountWei, proxyAddress, managerAddr),
    new dfs.actions.maker.MakerWithdrawAction(cdp.id, getWeiAmountForDecimals(collAmount, 18), getIlkInfo(cdp.ilkLabel).join, proxyAddress, managerAddr),
    new dfs.actions.uniswap.UniswapWithdrawAction(
      getAssetInfo(ethToWeth(assets.firstAsset)).address,
      getAssetInfo(ethToWeth(assets.secondAsset)).address,
      collAmountWei,
      proxyAddress,
      proxyAddress,
      minFirstAmountWei,
      minSecondAmountWei,
      Date.now() + (1000 * 60 * deadline),
    ),
    new dfs.actions.basic.TokenBalanceAction(getAssetInfo(ethToWeth(assets.firstAsset)).address, proxyAddress),
    new dfs.actions.basic.TokenBalanceAction(getAssetInfo(ethToWeth(assets.secondAsset)).address, proxyAddress),
    new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value),
    new dfs.actions.basic.SellAction(orderDataSecond, proxyAddress, proxyAddress, valueSecond),
    new dfs.actions.basic.SendTokenAction(getAssetInfo('DAI').address, paybackAddress, lpFullRepayAmountWei),
    new dfs.actions.basic.SendTokenAction(getAssetInfo('DAI').address, account, MAXUINT),
  ]);

  if (isFirstWeth) {
    recipe.addAction(new dfs.actions.basic.UnwrapEthAction(MAXUINT, account));
    recipe.addAction(new dfs.actions.basic.SendTokenAction(getAssetInfo(ethToWeth(assets.secondAsset)).address, account, MAXUINT));
  } else if (isSecondWeth) {
    recipe.addAction(new dfs.actions.basic.UnwrapEthAction(MAXUINT, account));
    recipe.addAction(new dfs.actions.basic.SendTokenAction(getAssetInfo(ethToWeth(assets.firstAsset)).address, account, MAXUINT));
  } else {
    recipe.addAction(new dfs.actions.basic.SendTokenAction(getAssetInfo(ethToWeth(assets.secondAsset)).address, account, MAXUINT));
    recipe.addAction(new dfs.actions.basic.SendTokenAction(getAssetInfo(ethToWeth(assets.firstAsset)).address, account, MAXUINT));
  }

  additionalActions.forEach((a) => recipe.addAction(a));

  recipe.extraGas = extraGas;

  return recipe;
};

export const createBasicCdp = async (collAmount, debtAmount, ilkLabel, proxyAddress, account) => {
  const ilkData = getIlkInfo(ilkLabel);
  const collAsset = ethToWeth(ilkData.asset);
  const debtAmountWei = assetAmountInWei(debtAmount, 'DAI');
  const collAmountWei = assetAmountInWei(collAmount, collAsset);
  const managerAddress = getCdpManagerForType(ilkData.isCrop ? 'crop' : 'mcd');

  // const shouldWrapStETH = false; // collAsset.toUpperCase() === 'WSTETH';

  const recipe = new dfs.Recipe('recBasicCdp', []);
  recipe.addAction(collAsset === 'WETH'
    ? new dfs.actions.basic.WrapEthAction(collAmountWei)
    : new dfs.actions.basic.PullTokenAction(ilkData.assetData.address, account, collAmountWei),
  );
  // if (shouldWrapStETH) {
  //   recipe.addAction(new dfs.actions.lido.LidoWrapAction(MAXUINT, proxyAddress, proxyAddress, true));
  // }
  recipe.addAction(new dfs.actions.maker.MakerOpenVaultAction(ilkData.join, managerAddress));
  const cdpId = `$${recipe.actions.length}`;
  recipe.addAction(new dfs.actions.maker.MakerSupplyAction(cdpId, '$1', ilkData.join, proxyAddress, managerAddress));
  recipe.addAction(new dfs.actions.maker.MakerGenerateAction(cdpId, debtAmountWei, account, managerAddress));

  return recipe;
};


export const createLeveragedCdp = async (collAmount, debtAmount, price, slippagePercent, ilkLabel, proxyAddress, account, flProtocol) => {
  const ilkData = getIlkInfo(ilkLabel);
  const collAsset = ethToWeth(ilkData.asset);
  const debtAmountWei = assetAmountInWei(debtAmount, 'DAI');
  const collAmountWei = assetAmountInWei(collAmount, collAsset);
  // We use Maker FL here, so no fee is calculated
  const { FLAction, paybackAddress } = getFLAction(flProtocol, debtAmountWei, 'DAI');

  const shouldWrapStETH = false; // collAsset.toUpperCase() === 'WSTETH';
  const buyAsset = shouldWrapStETH ? 'WETH' : collAsset;
  const { orderData, value, extraGas } = await getExchangeOrder('DAI', buyAsset, debtAmount, price, slippagePercent, proxyAddress, false, false, true);
  orderData[2] = '$1';

  const recipe = new dfs.Recipe('recLeveragedCdp', []);
  recipe.addAction(FLAction);
  recipe.addAction(new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress));
  recipe.addAction(collAsset === 'WETH'
    ? new dfs.actions.basic.WrapEthAction(collAmountWei)
    : new dfs.actions.basic.PullTokenAction(ilkData.assetData.address, account, collAmountWei),
  );
  if (shouldWrapStETH) {
    recipe.addAction(new dfs.actions.lido.LidoWrapAction(MAXUINT, proxyAddress, proxyAddress, true));
  }
  recipe.addAction(new dfs.actions.maker.MakerOpenVaultAction(ilkData.join, mcdCdpManagerAddress));
  const cdpId = `$${recipe.actions.length}`;
  recipe.addAction(new dfs.actions.maker.MakerSupplyAction(cdpId, MAXUINT, ilkData.join, proxyAddress, mcdCdpManagerAddress));
  recipe.addAction(new dfs.actions.maker.MakerGenerateAction(cdpId, '$1', paybackAddress, mcdCdpManagerAddress));
  recipe.extraGas = extraGas;

  return recipe;
};

export const createLeveragedDAILPCdp = async (collAmount, debtAmount, assets, price, slippagePercent, ilkLabel, proxyAddress, account, flProtocol, deadline = 20) => {
  const ilkData = getIlkInfo(ilkLabel);
  const collAsset = ethToWeth(ilkData.asset);
  const debtAmountWei = assetAmountInWei(debtAmount, 'DAI');
  // LP tokens have 18 decimals
  const collAmountWei = getWeiAmountForDecimals(collAmount, 18);
  const otherAsset = assets.isFirstDAI ? assets.secondAsset : assets.firstAsset;

  const rate = await compareMarketPriceWithPoolPrice(price, 'DAI', ethToWeth(otherAsset));

  const actualSellAmount = new Dec(debtAmount).div(rate).toString();
  const leftoverDAIAmount = new Dec(debtAmount).sub(actualSellAmount).toString();

  const estSecondAmountAfterSell = new Dec(actualSellAmount).mul(price).toString();
  const estSecondAmount = setSlippagePercent(normalizeFunc(slippagePercent), estSecondAmountAfterSell);

  const { orderData, value, extraGas } = await getExchangeOrder('DAI', ethToWeth(otherAsset), actualSellAmount, price, slippagePercent, proxyAddress, false, false, true);

  const leftoverDAIAmountWei = assetAmountInWei(leftoverDAIAmount, 'DAI');

  const minDaiAmount = setSlippagePercent(normalizeFunc(slippagePercent), leftoverDAIAmount);
  const minDaiAmountWei = assetAmountInWei(minDaiAmount, 'DAI');
  const minOtherAmountWei = assetAmountInWei(estSecondAmount, otherAsset);

  const { FLAction, paybackAddress } = getFLAction(flProtocol, debtAmountWei, 'DAI');

  const isSecondAssetWeth = ethToWeth(otherAsset) === 'WETH';

  const recipe = new dfs.Recipe('recCreateLPCdpDAI', [
    FLAction,
    new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value),
    new dfs.actions.uniswap.UniswapSupplyAction(
      getAssetInfo(ethToWeth(assets.firstAsset)).address,
      getAssetInfo(ethToWeth(assets.secondAsset)).address,
      proxyAddress,
      proxyAddress,
      assets.isFirstDAI ? leftoverDAIAmountWei : '$2',
      assets.isFirstDAI ? '$2' : leftoverDAIAmountWei,
      assets.isFirstDAI ? minDaiAmountWei : minOtherAmountWei,
      assets.isFirstDAI ? minOtherAmountWei : minDaiAmountWei,
      Date.now() + (1000 * 60 * deadline),
    ),
    new dfs.actions.maker.MakerOpenVaultAction(ilkData.join, mcdCdpManagerAddress),
    new dfs.actions.basic.PullTokenAction(getAssetInfo(collAsset).address, account, collAmountWei),
    new dfs.actions.maker.MakerSupplyAction('$4', MAXUINT, ilkData.join, proxyAddress, mcdCdpManagerAddress),
    new dfs.actions.maker.MakerGenerateAction('$4', debtAmountWei, paybackAddress, mcdCdpManagerAddress),
    new dfs.actions.basic.SendTokenAction(getAssetInfo('DAI').address, account, MAXUINT),
    isSecondAssetWeth ? new dfs.actions.basic.UnwrapEthAction(MAXUINT, account) : new dfs.actions.basic.SendTokenAction(getAssetInfo(otherAsset).address, account, MAXUINT),
  ]);
  recipe.extraGas = extraGas;

  return recipe;
};

export const createLeveragedNoDAILPCdp = async (collAmount, debtAmount, assets, price, priceSecond, slippagePercent, ilkLabel, proxyAddress, account, flProtocol, deadline = 20) => {
  const ilkData = getIlkInfo(ilkLabel);
  const collAsset = ethToWeth(ilkData.asset);
  const debtAmountWei = assetAmountInWei(debtAmount, 'DAI');
  // LP tokens have 18 decimals
  const collAmountWei = getWeiAmountForDecimals(collAmount, 18);

  const priceFromFirstToSecond = new Dec(priceSecond).div(price).toString();
  const rate = await compareMarketPriceWithPoolPrice(priceFromFirstToSecond, ethToWeth(assets.firstAsset), ethToWeth(assets.secondAsset));

  const sellAmountSecondAsset = new Dec(debtAmount).div(rate).toString();
  const sellAmountFirstAsset = new Dec(debtAmount).sub(sellAmountSecondAsset).toString();

  const estFirstAmount = new Dec(sellAmountFirstAsset).mul(price).toString();
  const estSecondAmount = new Dec(sellAmountSecondAsset).mul(priceSecond).toString();

  const { FLAction, paybackAddress } = getFLAction(flProtocol, debtAmountWei, 'DAI');

  const {
    orderData, value, extraGas, liquiditySources,
  } = await getExchangeOrder('DAI', ethToWeth(assets.firstAsset), sellAmountFirstAsset, price, slippagePercent, proxyAddress, false, false, true, flProtocol === 'balancer' ? ['Balancer_V2'] : []);

  const isFLOrSwapGoingToBalancer = flProtocol === 'balancer' || (liquiditySources && liquiditySources.some(source => source.name === 'Balancer_V2'));

  const { orderData: orderDataSecond, value: valueSecond, extraGas: extraGasSecond } = await getExchangeOrder('DAI', ethToWeth(assets.secondAsset), sellAmountSecondAsset, priceSecond, slippagePercent, proxyAddress, false, false, true, isFLOrSwapGoingToBalancer ? ['Balancer_V2'] : []);

  const minFirstAmount = setSlippagePercent(normalizeFunc(slippagePercent), estFirstAmount);
  const minSecondAmount = setSlippagePercent(normalizeFunc(slippagePercent), estSecondAmount);
  const minFirstAmountWei = assetAmountInWei(minFirstAmount, ethToWeth(assets.firstAsset));
  const minSecondAmountWei = assetAmountInWei(minSecondAmount, ethToWeth(assets.secondAsset));


  const isFirstWeth = ethToWeth(assets.firstAsset) === 'WETH';
  const isSecondWeth = ethToWeth(assets.secondAsset) === 'WETH';

  const recipe = new dfs.Recipe('recCreateLPCdpNoDAI', [
    FLAction,
    new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value),
    new dfs.actions.basic.SellAction(orderDataSecond, proxyAddress, proxyAddress, valueSecond),
    new dfs.actions.uniswap.UniswapSupplyAction(
      getAssetInfo(ethToWeth(assets.firstAsset)).address,
      getAssetInfo(ethToWeth(assets.secondAsset)).address,
      proxyAddress,
      proxyAddress,
      '$2',
      '$3',
      minFirstAmountWei,
      minSecondAmountWei,
      Date.now() + (1000 * 60 * deadline),
    ),
    new dfs.actions.maker.MakerOpenVaultAction(ilkData.join, mcdCdpManagerAddress),
    new dfs.actions.basic.PullTokenAction(getAssetInfo(collAsset).address, account, collAmountWei),
    new dfs.actions.maker.MakerSupplyAction('$5', MAXUINT, ilkData.join, proxyAddress, mcdCdpManagerAddress),
    new dfs.actions.maker.MakerGenerateAction('$5', debtAmountWei, paybackAddress, mcdCdpManagerAddress),
    new dfs.actions.basic.SendTokenAction(getAssetInfo('DAI').address, account, MAXUINT),
  ]);
  if (isFirstWeth) {
    recipe.addAction(new dfs.actions.basic.UnwrapEthAction(MAXUINT, account));
    recipe.addAction(new dfs.actions.basic.SendTokenAction(getAssetInfo(ethToWeth(assets.secondAsset)).address, account, MAXUINT));
  } else if (isSecondWeth) {
    recipe.addAction(new dfs.actions.basic.UnwrapEthAction(MAXUINT, account));
    recipe.addAction(new dfs.actions.basic.SendTokenAction(getAssetInfo(ethToWeth(assets.firstAsset)).address, account, MAXUINT));
  } else {
    recipe.addAction(new dfs.actions.basic.SendTokenAction(getAssetInfo(ethToWeth(assets.secondAsset)).address, account, MAXUINT));
    recipe.addAction(new dfs.actions.basic.SendTokenAction(getAssetInfo(ethToWeth(assets.firstAsset)).address, account, MAXUINT));
  }

  recipe.extraGas = extraGas;

  return recipe;
};

export const createLeveragedGUNICdp = async (collAmount, debtAmount, firstAmount, secondAmount, assets, price, slippagePercent, ilkLabel, proxyAddress, account, flProtocol) => {
  const ilkData = getIlkInfo(ilkLabel);
  const collAsset = ethToWeth(ilkData.asset);
  const debtAmountWei = assetAmountInWei(debtAmount, 'DAI');
  // LP tokens have 18 decimals
  const collAmountWei = getWeiAmountForDecimals(collAmount, 18);

  const otherAsset = assets.isFirstDAI ? assets.secondAsset : assets.firstAsset;

  const coefficientFirst = new Dec(new Dec(firstAmount).plus(secondAmount)).div(firstAmount).mul(slippagePercent).toString();
  const coefficientSecond = new Dec(new Dec(firstAmount).plus(secondAmount)).div(secondAmount).mul(slippagePercent).toString();

  // maybe use market price and uni v3 price ratio to alter amount needed so we don't have dai leftover
  const minFirstAmount = setSlippagePercent(coefficientFirst, firstAmount);
  const minSecondAmount = setSlippagePercent(coefficientSecond, secondAmount);
  const minFirstAmountWei = assetAmountInWei(minFirstAmount, assets.firstAsset);
  const minSecondAmountWei = assetAmountInWei(minSecondAmount, assets.secondAsset);

  const sellAmount = assets.isFirstDAI ? secondAmount : firstAmount;

  const { FLAction, paybackAddress } = getFLAction(flProtocol, debtAmountWei, 'DAI');

  const {
    orderData, value, extraGas, liquiditySources,
  } = await getExchangeOrder('DAI', ethToWeth(otherAsset), sellAmount, price, slippagePercent, proxyAddress, false, false, true, flProtocol === 'balancer' ? ['Balancer_V2'] : []);

  const isSecondAssetWeth = ethToWeth(otherAsset) === 'WETH';

  const recipe = new dfs.Recipe('recCreateLPCdpDAI', [
    FLAction,
    new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value),
    new dfs.actions.basic.TokenBalanceAction(getAssetInfo('DAI').address, proxyAddress),
    new dfs.actions.basic.TokenBalanceAction(getAssetInfo('USDC').address, proxyAddress),
    new dfs.actions.guni.GUniDeposit(
      ilkData.assetAddress,
      getAssetInfo(ethToWeth('DAI')).address,
      getAssetInfo(ethToWeth('USDC')).address,
      '$3',
      '$4',
      minFirstAmountWei,
      minSecondAmountWei,
      proxyAddress,
      proxyAddress,
    ),
    new dfs.actions.maker.MakerOpenVaultAction(ilkData.join, mcdCdpManagerAddress),
    new dfs.actions.basic.PullTokenAction(getAssetInfo(collAsset).address, account, collAmountWei),
    new dfs.actions.maker.MakerSupplyAction('$6', MAXUINT, ilkData.join, proxyAddress, mcdCdpManagerAddress),
    new dfs.actions.maker.MakerGenerateAction('$6', debtAmountWei, paybackAddress, mcdCdpManagerAddress),
    new dfs.actions.basic.SendTokenAction(getAssetInfo('DAI').address, account, MAXUINT),
    isSecondAssetWeth ? new dfs.actions.basic.UnwrapEthAction(MAXUINT, account) : new dfs.actions.basic.SendTokenAction(getAssetInfo(otherAsset).address, account, MAXUINT),
  ]);

  recipe.extraGas = extraGas;

  return recipe;
};

export const boostGUNIVault = async (debtAmount, firstAmount, secondAmount, assets, price, slippagePercent, cdp, proxyAddress, account, type = 'mcd', additionalActions) => {
  const otherAsset = assets.isFirstDAI ? assets.secondAsset : assets.firstAsset;

  // min first amount: ((first + second) / first * slippage) * firstAmount
  const coefficientFirst = new Dec(new Dec(firstAmount).plus(secondAmount)).div(firstAmount).mul(slippagePercent).toString();
  const coefficientSecond = new Dec(new Dec(firstAmount).plus(secondAmount)).div(secondAmount).mul(slippagePercent).toString();

  const minFirstAmount = setSlippagePercent(coefficientFirst, firstAmount);
  const minSecondAmount = setSlippagePercent(coefficientSecond, secondAmount);
  const minFirstAmountWei = assetAmountInWei(minFirstAmount, assets.firstAsset);
  const minSecondAmountWei = assetAmountInWei(minSecondAmount, assets.secondAsset);
  const sellAmount = assets.isFirstDAI ? secondAmount : firstAmount;

  const { orderData, value, extraGas } = await getExchangeOrder('DAI', ethToWeth(otherAsset), sellAmount, price, slippagePercent, proxyAddress, false, false, true);

  const managerAddr = getCdpManagerForType(type);
  const isSecondAssetWeth = ethToWeth(otherAsset) === 'WETH';

  const additionalActionsLength = additionalActions.length;

  const recipe = new dfs.Recipe('recGuniBoost', [
    ...additionalActions,
    new dfs.actions.maker.MakerGenerateAction(cdp.id, assetAmountInWei(debtAmount, 'DAI'), proxyAddress, managerAddr),
    new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value),
    new dfs.actions.basic.TokenBalanceAction(getAssetInfo(assets.firstAsset).address, proxyAddress),
    new dfs.actions.basic.TokenBalanceAction(getAssetInfo(assets.secondAsset).address, proxyAddress),
    new dfs.actions.guni.GUniDeposit(
      getIlkInfo(cdp.ilk).assetAddress,
      getAssetInfo(assets.firstAsset).address,
      getAssetInfo(assets.secondAsset).address,
      `$${3 + additionalActionsLength}`,
      `$${4 + additionalActionsLength}`,
      minFirstAmountWei,
      minSecondAmountWei,
      proxyAddress,
      proxyAddress,
    ),
    new dfs.actions.maker.MakerSupplyAction(cdp.id, `$${5 + additionalActionsLength}`, getIlkInfo(cdp.ilkLabel).join, proxyAddress, managerAddr),
    new dfs.actions.basic.SendTokenAction(getAssetInfo('DAI').address, account, MAXUINT),
    isSecondAssetWeth ? new dfs.actions.basic.UnwrapEthAction(MAXUINT, account) : new dfs.actions.basic.SendTokenAction(getAssetInfo(otherAsset).address, account, MAXUINT),
  ]);
  recipe.extraGas = extraGas;

  return recipe;
};

export const boostGUNIVaultFL = async (debtAmount, firstAmount, secondAmount, assets, price, slippagePercent, cdp, proxyAddress, account, type = 'mcd', flProtocol, additionalActions) => {
  const debtAmountWei = assetAmountInWei(debtAmount, 'DAI');

  const otherAsset = assets.isFirstDAI ? assets.secondAsset : assets.firstAsset;

  const coefficientFirst = new Dec(new Dec(firstAmount).plus(secondAmount)).div(firstAmount).mul(slippagePercent).toString();
  const coefficientSecond = new Dec(new Dec(firstAmount).plus(secondAmount)).div(secondAmount).mul(slippagePercent).toString();

  const minFirstAmount = setSlippagePercent(coefficientFirst, firstAmount);
  const minSecondAmount = setSlippagePercent(coefficientSecond, secondAmount);
  const minFirstAmountWei = assetAmountInWei(minFirstAmount, assets.firstAsset);
  const minSecondAmountWei = assetAmountInWei(minSecondAmount, assets.secondAsset);

  const sellAmount = assets.isFirstDAI ? secondAmount : firstAmount;

  const { FLAction, paybackAddress } = getFLAction(flProtocol, debtAmountWei, 'DAI');

  const { orderData, value, extraGas } = await getExchangeOrder('DAI', ethToWeth(otherAsset), sellAmount, price, slippagePercent, proxyAddress, false, false, true, flProtocol === 'balancer' ? ['Balancer_V2'] : []);

  const managerAddr = getCdpManagerForType(type);
  const isSecondAssetWeth = ethToWeth(otherAsset) === 'WETH';

  const recipe = new dfs.Recipe('recFLGuniBoost', [
    FLAction,
    new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value),
    new dfs.actions.basic.TokenBalanceAction(getAssetInfo('DAI').address, proxyAddress),
    new dfs.actions.basic.TokenBalanceAction(getAssetInfo('USDC').address, proxyAddress),
    new dfs.actions.guni.GUniDeposit(
      getIlkInfo(cdp.ilk).assetAddress,
      getAssetInfo('DAI').address,
      getAssetInfo('USDC').address,
      '$3',
      '$4',
      minFirstAmountWei,
      minSecondAmountWei,
      proxyAddress,
      proxyAddress,
    ),
    new dfs.actions.maker.MakerSupplyAction(cdp.id, '$5', getIlkInfo(cdp.ilkLabel).join, proxyAddress, managerAddr),
    ...additionalActions,
    new dfs.actions.maker.MakerGenerateAction(cdp.id, assetAmountInWei(debtAmount, 'DAI'), paybackAddress, managerAddr),
    new dfs.actions.basic.SendTokenAction(getAssetInfo('DAI').address, account, MAXUINT),
    isSecondAssetWeth ? new dfs.actions.basic.UnwrapEthAction(MAXUINT, account) : new dfs.actions.basic.SendTokenAction(getAssetInfo(otherAsset).address, account, MAXUINT),
  ]);
  recipe.extraGas = extraGas;

  return recipe;
};

export const repayGUNIVault = async (collAmount, firstAmount, secondAmount, assets, price, slippagePercent, cdp, proxyAddress, account, type = 'mcd', additionalActions) => {
  const minFirstAmount = setSlippagePercent(normalizeFunc(slippagePercent), firstAmount);
  const minSecondAmount = setSlippagePercent(normalizeFunc(slippagePercent), secondAmount);
  const minFirstAmountWei = assetAmountInWei(minFirstAmount, assets.firstAsset);
  const minSecondAmountWei = assetAmountInWei(minSecondAmount, assets.secondAsset);

  const [sellAsset, sellAmount] = assets.isFirstDAI ? [ethToWeth(assets.secondAsset), secondAmount] : [ethToWeth(assets.firstAsset), firstAmount];

  const collAmountWei = getWeiAmountForDecimals(collAmount, 18);

  const { orderData, value, extraGas } = await getExchangeOrder(sellAsset, 'DAI', sellAmount, price, slippagePercent, proxyAddress, false, false, true);
  orderData[2] = '$3';

  const managerAddr = getCdpManagerForType(type);
  const isSecondAssetWeth = ethToWeth(sellAsset) === 'WETH';

  const recipe = new dfs.Recipe('recGuniRepay', [
    new dfs.actions.maker.MakerWithdrawAction(cdp.id, getWeiAmountForDecimals(collAmount, 18), getIlkInfo(cdp.ilkLabel).join, proxyAddress, managerAddr),
    new dfs.actions.guni.GUniWithdraw(
      getIlkInfo(cdp.ilk).assetAddress,
      collAmountWei,
      minFirstAmountWei,
      minSecondAmountWei,
      proxyAddress,
      proxyAddress,
    ),
    new dfs.actions.basic.TokenBalanceAction(getAssetInfo(sellAsset).address, proxyAddress),
    new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value),
    new dfs.actions.basic.TokenBalanceAction(getAssetInfo('DAI').address, proxyAddress),
    new dfs.actions.maker.MakerPaybackAction(cdp.id, '$5', proxyAddress, managerAddr),
    new dfs.actions.basic.SendTokenAction(getAssetInfo('DAI').address, account, MAXUINT),
    isSecondAssetWeth ? new dfs.actions.basic.UnwrapEthAction(MAXUINT, account) : new dfs.actions.basic.SendTokenAction(getAssetInfo(sellAsset).address, account, MAXUINT),
  ]);

  additionalActions.forEach((a) => recipe.addAction(a));

  recipe.extraGas = extraGas;

  return recipe;
};

export const repayGUNIVaultFL = async (collAmount, firstAmount, secondAmount, assets, price, slippagePercent, cdp, proxyAddress, account, type = 'mcd', flProtocol, additionalActions) => {
  const minFirstAmount = setSlippagePercent(normalizeFunc(slippagePercent), firstAmount);
  const minSecondAmount = setSlippagePercent(normalizeFunc(slippagePercent), secondAmount);
  const minFirstAmountWei = assetAmountInWei(minFirstAmount, assets.firstAsset);
  const minSecondAmountWei = assetAmountInWei(minSecondAmount, assets.secondAsset);

  const sellAmountNoSlpg = assets.isFirstDAI ? secondAmount : firstAmount;
  const [sellAsset, sellAmount] = assets.isFirstDAI ? [ethToWeth(assets.secondAsset), minSecondAmount] : [ethToWeth(assets.firstAsset), minFirstAmount];

  const collAmountWei = getWeiAmountForDecimals(collAmount, 18);

  const lpFullRepayAmountWei = assetAmountInWei(new Dec(new Dec(price).mul(sellAmount)).plus(firstAmount).toString(), 'DAI');

  const { FLAction, paybackAddress } = getFLAction(flProtocol, lpFullRepayAmountWei, 'DAI');

  const { orderData, value, extraGas } = await getExchangeOrder(sellAsset, 'DAI', sellAmountNoSlpg, price, slippagePercent, proxyAddress, false, false, true, flProtocol === 'balancer' ? ['Balancer_V2'] : []);
  orderData[2] = '$5';

  const managerAddr = getCdpManagerForType(type);
  const isSecondAssetWeth = ethToWeth(sellAsset) === 'WETH';

  const recipe = new dfs.Recipe('recFLGuniRepay', [
    FLAction,
    new dfs.actions.maker.MakerPaybackAction(cdp.id, lpFullRepayAmountWei, proxyAddress, managerAddr),
    new dfs.actions.maker.MakerWithdrawAction(cdp.id, getWeiAmountForDecimals(collAmount, 18), getIlkInfo(cdp.ilkLabel).join, proxyAddress, managerAddr),
    new dfs.actions.guni.GUniWithdraw(
      getIlkInfo(cdp.ilk).assetAddress,
      collAmountWei,
      minFirstAmountWei,
      minSecondAmountWei,
      proxyAddress,
      proxyAddress,
    ),
    new dfs.actions.basic.TokenBalanceAction(getAssetInfo(sellAsset).address, proxyAddress),
    new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value),
    new dfs.actions.basic.SendTokenAction(getAssetInfo('DAI').address, paybackAddress, lpFullRepayAmountWei),
    new dfs.actions.basic.SendTokenAction(getAssetInfo('DAI').address, account, MAXUINT),
    isSecondAssetWeth ? new dfs.actions.basic.UnwrapEthAction(MAXUINT, account) : new dfs.actions.basic.SendTokenAction(getAssetInfo(sellAsset).address, account, MAXUINT),
  ]);

  additionalActions.forEach((a) => recipe.addAction(a));

  recipe.extraGas = extraGas;

  return recipe;
};

export const boostCurveEthStethVault = async (debtAmount, firstAmount, lpEstimate, assets, price, slippagePercent, cdp, proxyAddress, account, type = 'crop', additionalActions) => {
  const debtAmountWei = assetAmountInWei(debtAmount, 'DAI');

  const managerAddr = getCdpManagerForType(type);

  const { orderData, value, extraGas } = await getExchangeOrder('DAI', ethToWeth(assets.firstAsset), debtAmount, price, slippagePercent, proxyAddress, false, false, true);
  const additionalActionsLength = additionalActions.length;

  const minMintAmountWei = getWeiAmountForDecimals(setSlippagePercent(slippagePercent, lpEstimate), 18);

  const recipe = new dfs.Recipe('recBoostCurveLP', [
    ...additionalActions,
    new dfs.actions.maker.MakerGenerateAction(cdp.id, debtAmountWei, proxyAddress, managerAddr),
    new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value),
    new dfs.actions.curve.CurveStethPoolDepositAction(proxyAddress, proxyAddress, [`$${2 + additionalActionsLength}`, 0], minMintAmountWei),
    new dfs.actions.maker.MakerSupplyAction(cdp.id, `$${3 + additionalActionsLength}`, getIlkInfo(cdp.ilkLabel).join, proxyAddress, managerAddr),
    ...sendRemainingAmountsToAcc(true, account),
  ]);
  recipe.extraGas = extraGas;

  return recipe;
};

export const boostFLCurveEthStethVault = async (debtAmount, firstAmount, lpEstimate, assets, price, slippagePercent, cdp, proxyAddress, account, type = 'crop', flProtocol, additionalActions) => {
  const debtAmountWei = assetAmountInWei(debtAmount, 'DAI');

  const managerAddr = getCdpManagerForType(type);

  const { FLAction, paybackAddress } = getFLAction(flProtocol, debtAmountWei, 'DAI');

  const { orderData, value, extraGas } = await getExchangeOrder('DAI', ethToWeth(assets.firstAsset), debtAmount, price, slippagePercent, proxyAddress, false, false, true, flProtocol === 'balancer' ? ['Balancer_V2'] : []);

  const minMintAmountWei = getWeiAmountForDecimals(setSlippagePercent(slippagePercent, lpEstimate), 18);

  const recipe = new dfs.Recipe('recFLBoostCurveLP', [
    FLAction,
    new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value),
    new dfs.actions.curve.CurveStethPoolDepositAction(proxyAddress, proxyAddress, ['$2', '0'], minMintAmountWei),
    new dfs.actions.maker.MakerSupplyAction(cdp.id, '$3', getIlkInfo(cdp.ilkLabel).join, proxyAddress, managerAddr),
    ...additionalActions,
    new dfs.actions.maker.MakerGenerateAction(cdp.id, debtAmountWei, paybackAddress, managerAddr),
    ...sendRemainingAmountsToAcc(true, account),
  ]);

  recipe.extraGas = extraGas;

  return recipe;
};

export const repayCurveEthStethVault = async (collAmount, firstAmount, assets, price, slippagePercent, cdp, proxyAddress, account, type = 'crop', additionalActions) => {
  const collAmountWei = getWeiAmountForDecimals(collAmount, 18);
  const firstAmountWei = assetAmountInWei(firstAmount, 'ETH');

  const { orderData, value, extraGas } = await getExchangeOrder(ethToWeth(assets.firstAsset), 'DAI', firstAmount, price, slippagePercent, proxyAddress, false, false, true);
  // No need to pipe balance because we get exact amount from CurveStethPoolWithdrawAction
  const managerAddr = getCdpManagerForType(type);

  const recipe = new dfs.Recipe('recRepayCurveLP', [
    new dfs.actions.maker.MakerWithdrawAction(cdp.id, collAmountWei, getIlkInfo(cdp.ilkLabel).join, proxyAddress, managerAddr),
    new dfs.actions.curve.CurveStethPoolWithdrawAction(proxyAddress, proxyAddress, [firstAmountWei, '0'], collAmountWei, '0'),
    new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value),
    new dfs.actions.maker.MakerPaybackAction(cdp.id, '$3', proxyAddress, managerAddr),
    ...sendRemainingAmountsToAcc(true, account),
  ]);

  additionalActions.forEach((a) => recipe.addAction(a));

  recipe.extraGas = extraGas;

  return recipe;
};

export const repayFLCurveEthStethVault = async (collAmount, firstAmount, assets, price, slippagePercent, cdp, proxyAddress, account, type = 'crop', flProtocol, additionalActions) => {
  const collAmountWei = getWeiAmountForDecimals(collAmount, 18);
  const firstAmountWei = assetAmountInWei(firstAmount, 'ETH');

  const sellAsset = ethToWeth(assets.firstAsset);
  const lpFullRepayAmount = new Dec(firstAmount).mul(price).toString();
  const lpFullRepayAmountWei = assetAmountInWei(lpFullRepayAmount, 'DAI');
  const { FLAction, paybackAddress } = getFLAction(flProtocol, lpFullRepayAmountWei, 'DAI');

  const { orderData, value, extraGas } = await getExchangeOrder(sellAsset, 'DAI', firstAmount, price, slippagePercent, proxyAddress, false, false, true, flProtocol === 'balancer' ? ['Balancer_V2'] : []);

  const managerAddr = getCdpManagerForType(type);

  const recipe = new dfs.Recipe('recFLRepayCurveLP', [
    FLAction,
    new dfs.actions.maker.MakerPaybackAction(cdp.id, lpFullRepayAmountWei, proxyAddress, managerAddr),
    new dfs.actions.maker.MakerWithdrawAction(cdp.id, collAmountWei, getIlkInfo(cdp.ilkLabel).join, proxyAddress, managerAddr),
    new dfs.actions.curve.CurveStethPoolWithdrawAction(proxyAddress, proxyAddress, [firstAmountWei, '0'], collAmountWei, '0'),
    new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value),
    new dfs.actions.basic.SendTokenAction(getAssetInfo('DAI').address, paybackAddress, lpFullRepayAmountWei),
    ...sendRemainingAmountsToAcc(true, account),
  ]);

  additionalActions.forEach((a) => recipe.addAction(a));

  recipe.extraGas = extraGas;

  return recipe;
};

export const createLeveragedCRVCdp = async (collAmount, debtAmount, amount, lpEstimate, assets, price, slippagePercent, ilkLabel, proxyAddress, account, flProtocol, selectedColl) => {
  const ilkData = getIlkInfo(ilkLabel);
  const collAsset = ethToWeth(ilkData.asset);
  const debtAmountWei = assetAmountInWei(debtAmount, 'DAI');
  // LP tokens have 18 decimals
  const collAmountWei = getWeiAmountForDecimals(collAmount, 18);
  const minMintAmountWei = getWeiAmountForDecimals(setSlippagePercent(slippagePercent, lpEstimate), 18);
  const { FLAction, paybackAddress } = getFLAction(flProtocol, debtAmountWei, 'DAI');

  const {
    orderData, value, extraGas, liquiditySources,
  } = await getExchangeOrder('DAI', ethToWeth('WETH'), debtAmount, price, slippagePercent, proxyAddress, false, false, true, flProtocol === 'balancer' ? ['Balancer_V2'] : []);

  const managerAddr = getCdpManagerForType('crop');
  let recipe;
  if (selectedColl === 'steCRV') {
    recipe = new dfs.Recipe('recCreateLeveragedCRVLPCdpDAI', [
      FLAction,
      new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value),
      new dfs.actions.curve.CurveStethPoolDepositAction(proxyAddress, proxyAddress, ['$2', '0'], minMintAmountWei),
      new dfs.actions.maker.MakerOpenVaultAction(ilkData.join, managerAddr),
      new dfs.actions.basic.PullTokenAction(getAssetInfo(collAsset).address, account, collAmountWei),
      new dfs.actions.maker.MakerSupplyAction('$5', MAXUINT, ilkData.join, proxyAddress, managerAddr),
      new dfs.actions.maker.MakerGenerateAction('$5', debtAmountWei, paybackAddress, managerAddr),
      ...sendRemainingAmountsToAcc(true, account),
    ]);
  } else {
    recipe = new dfs.Recipe('recCreateLeveragedCRVLPCdpDAI', []);
    recipe.addAction(FLAction);
    recipe.addAction(new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value));
    if (selectedColl === 'stETH') recipe.addAction(new dfs.actions.lido.LidoStakeAction(MAXUINT, proxyAddress, proxyAddress));
    recipe.addAction(selectedColl === 'stETH'
      ? new dfs.actions.basic.PullTokenAction(getAssetInfo(ethToWeth(selectedColl)).address, account, collAmountWei)
      : new dfs.actions.basic.WrapEthAction(collAmountWei));
    recipe.addAction(new dfs.actions.basic.TokenBalanceAction(getAssetInfo(ethToWeth(selectedColl)).address, proxyAddress));

    const balance = recipe.actions.length;

    recipe.addAction(new dfs.actions.curve.CurveStethPoolDepositAction(proxyAddress, proxyAddress, selectedColl === 'stETH' ? ['0', `$${balance}`] : [`$${balance}`, '0'], minMintAmountWei));
    recipe.addAction(new dfs.actions.maker.MakerOpenVaultAction(ilkData.join, managerAddr));

    const cdpId = recipe.actions.length;

    recipe.addAction(new dfs.actions.maker.MakerSupplyAction(`$${cdpId}`, MAXUINT, ilkData.join, proxyAddress, managerAddr));
    recipe.addAction(new dfs.actions.maker.MakerGenerateAction(`$${cdpId}`, debtAmountWei, paybackAddress, managerAddr));

    sendRemainingAmountsToAcc(selectedColl === 'ETH', account, selectedColl).forEach(a => recipe.addAction(a));
  }


  recipe.extraGas = extraGas;

  return recipe;
};

export const createBasicCRVCdp = (collAmount, debtAmount, ilkLabel, proxyAddress, account, selectedColl, lpEstimate) => {
  const ilkData = getIlkInfo(ilkLabel);
  const collAsset = ethToWeth(ilkData.asset);
  const debtAmountWei = assetAmountInWei(debtAmount, 'DAI');
  const collAmountWei = assetAmountInWei(collAmount, collAsset);
  const managerAddress = getCdpManagerForType('crop');

  const recipe = new dfs.Recipe('recCreateCRVLPCdpDAI', []);
  if (selectedColl === 'steCRV') {
    recipe.addAction(new dfs.actions.basic.PullTokenAction(ilkData.assetData.address, account, collAmountWei));
    recipe.addAction(new dfs.actions.maker.MakerOpenVaultAction(ilkData.join, managerAddress));
    recipe.addAction(new dfs.actions.maker.MakerSupplyAction('$2', '$1', ilkData.join, proxyAddress, managerAddress));
    recipe.addAction(new dfs.actions.maker.MakerGenerateAction('$2', debtAmountWei, account, managerAddress));
  } else {
    const minMintAmount = setSlippagePercent(0.5, lpEstimate);

    recipe.addAction(
      selectedColl === 'stETH'
        ? new dfs.actions.basic.PullTokenAction(getAssetInfo(ethToWeth(selectedColl)).address, account, collAmountWei)
        : new dfs.actions.basic.WrapEthAction(collAmountWei));
    recipe.addAction(new dfs.actions.curve.CurveStethPoolDepositAction(proxyAddress, proxyAddress, selectedColl === 'stETH' ? ['0', collAmountWei] : [collAmountWei, '0'], getWeiAmountForDecimals(minMintAmount, 18)));
    recipe.addAction(new dfs.actions.maker.MakerOpenVaultAction(ilkData.join, managerAddress));
    recipe.addAction(new dfs.actions.maker.MakerSupplyAction('$3', '$2', ilkData.join, proxyAddress, managerAddress));
    recipe.addAction(new dfs.actions.maker.MakerGenerateAction('$3', debtAmountWei, account, managerAddress));
  }

  return recipe;
};

export const closeCdp = async (cdp, strategies, price, slippagePercent, flProtocol = 'maker', toDai, proxyAddress, account) => {
  const collAsset = ethToWeth(cdp.asset);
  const managerAddr = getCdpManagerForType(cdp.type);

  const estDebtAmount = new Dec(cdp.debtInAsset).mul(CONTINUOUS_FEE_MULTIPLIER).toString();
  const estDebtAmountWei = assetAmountInWei(estDebtAmount, 'DAI');
  const { FLAction, paybackAddress } = getFLAction(flProtocol, estDebtAmountWei, 'DAI');

  const debtInCollAsset = new Dec(cdp.debtInAsset).div(price).toString();
  const collAmount = toDai
    ? cdp.collateral
    : new Dec(debtInCollAsset).mul(CONTINUOUS_FEE_MULTIPLIER).mul((100 + +slippagePercent) / 100).toString();
  const { orderData, value, extraGas } = await getExchangeOrder(collAsset, 'DAI', collAmount, price, slippagePercent, proxyAddress, false, false, true, flProtocol === 'balancer' ? ['Balancer_V2'] : []);

  const recipe = new dfs.Recipe(`recMakerClose${toDai ? 'Dai' : 'Coll'}`, [
    FLAction,
    new dfs.actions.maker.MakerPaybackAction(cdp.id, MAXUINT, proxyAddress, managerAddr),
    new dfs.actions.maker.MakerWithdrawAction(cdp.id, MAXUINT, getIlkInfo(cdp.ilkLabel).join, proxyAddress, managerAddr),
    new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value),
    new dfs.actions.basic.SendTokenAction(getAssetInfo('DAI').address, paybackAddress, '$1'),
    new dfs.actions.basic.SendTokenAction(getAssetInfo('DAI').address, account, MAXUINT),
  ]);
  if (!toDai) {
    recipe.addAction(cdp.asset === 'ETH'
      ? new dfs.actions.basic.UnwrapEthAction(MAXUINT, account)
      : new dfs.actions.basic.SendTokenAction(getAssetInfo(cdp.asset).address, account, MAXUINT));
  }
  if (cdp.isSubscribedToAutomation) {
    recipe.addAction(new dfs.actions.basic.AutomationV2Unsub(0, cdp.id));
  }
  strategies.forEach(({ subId }) => {
    recipe.addAction(new dfs.actions.basic.ToggleSubAction(subId, false));
  });
  recipe.extraGas = extraGas;
  return recipe;
};

export const createLeveragedWstEthCdp = async (collAmount, debtAmount, price, slippagePercent, ilk, proxyAddress, account, type = 'mcd', flProtocol, selectedColl) => {
  const ilkData = getIlkInfo(ilk);

  const managerAddr = getCdpManagerForType(type);
  const debtAmountWei = assetAmountInWei(debtAmount, 'DAI');
  const collAmountWei = assetAmountInWei(collAmount, selectedColl);

  const { FLAction, paybackAddress } = getFLAction(flProtocol, debtAmountWei, 'DAI');

  const {
    orderData, value, extraGas,
  } = await getExchangeOrder('DAI', ethToWeth('WETH'), debtAmount, price, slippagePercent, proxyAddress, false, false, true, flProtocol === 'balancer' ? ['Balancer_V2'] : []);

  // wstETH or stETH address (has no effect if ETH is selected)
  const pullAssetAddress = selectedColl === 'wstETH' ? ilkData.assetData.address : getAssetInfo(selectedColl).address;

  const recipe = new dfs.Recipe('leveragedWstEthCDPCreate', []);

  recipe.addAction(FLAction);
  // wrap if ETH is selected, otherwise just pull
  recipe.addAction(selectedColl === 'ETH' ? new dfs.actions.basic.WrapEthAction(collAmountWei) : new dfs.actions.basic.PullTokenAction(pullAssetAddress, account, collAmountWei));
  recipe.addAction(new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value));
  // if stETH is selected, wrap only WETH that is bought
  if (selectedColl === 'stETH') recipe.addAction(new dfs.actions.lido.LidoWrapAction(MAXUINT, proxyAddress, proxyAddress, true)); // last flag means use (W)ETH
  // wrap only pulled coll if stETH is selected, only bought if wstETH is selected or bought + pulled (wrapped ETH) if ETH is selected
  recipe.addAction(new dfs.actions.lido.LidoWrapAction(MAXUINT, proxyAddress, proxyAddress, selectedColl !== 'stETH')); // false if stETH is selected
  recipe.addAction(new dfs.actions.maker.MakerOpenVaultAction(ilkData.join, managerAddr));
  const cdpId = `$${recipe.actions.length}`;
  recipe.addAction(new dfs.actions.maker.MakerSupplyAction(cdpId, MAXUINT, ilkData.join, proxyAddress, managerAddr));
  recipe.addAction(new dfs.actions.maker.MakerGenerateAction(cdpId, debtAmountWei, paybackAddress, managerAddr));

  sendRemainingAmountsToAcc(selectedColl === 'ETH', account, selectedColl).forEach(a => recipe.addAction(a));

  recipe.extraGas = extraGas;
  return recipe;
};

export const createBasicWstEthCdp = async (collAmount, debtAmount, slippagePercent, ilk, proxyAddress, account, type = 'mcd', selectedColl) => {
  const ilkData = getIlkInfo(ilk);
  const managerAddr = getCdpManagerForType(type);
  const debtAmountWei = assetAmountInWei(debtAmount, 'DAI');
  const collAmountWei = assetAmountInWei(collAmount, selectedColl);

  const recipe = new dfs.Recipe('basicWstEthCDPCreate', []);

  recipe.addAction(selectedColl === 'ETH' ? new dfs.actions.basic.WrapEthAction(collAmountWei) : new dfs.actions.basic.PullTokenAction(getAssetInfo(ethToWeth(selectedColl)).address, account, collAmountWei));
  if (selectedColl !== 'wstETH') recipe.addAction(new dfs.actions.lido.LidoWrapAction(MAXUINT, proxyAddress, proxyAddress, selectedColl === 'ETH'));
  recipe.addAction(new dfs.actions.maker.MakerOpenVaultAction(ilkData.join, managerAddr));
  const cdpId = `$${recipe.actions.length}`;
  recipe.addAction(new dfs.actions.maker.MakerSupplyAction(cdpId, selectedColl !== 'wstETH' ? '$2' : '$1', ilkData.join, proxyAddress, managerAddr));
  recipe.addAction(new dfs.actions.maker.MakerGenerateAction(cdpId, debtAmountWei, account, managerAddr));

  return recipe;
};
