import { Transaction } from '@ethereumjs/tx';
import t from 'translate';
import {
  ADD_NOTIFICATION,
  CLOSE_NOTIFICATION,
  CHANGE_NOTIFICATION,
  ADD_CONFIRMATION,
  CLOSE_CONFIRMATION,
  PENDING_TX,
  CLEAR_PENDING_TX,
  CURRENT_TX,
} from '../actionTypes/notificationsActionTypes';
import { ADD_SERVER_TX } from '../actionTypes/txActionTypes';
import { ACCOUNT_TYPES, WALLET_NAMES } from '../constants/general';

import store from '../store';

import { randomId, wait } from '../services/utils';
import { addTxHashForMonitoringApiCall, getTxData } from '../services/apiService';
import { confirmViaModal } from './modalActions';
import { ethToWei, isLayer2Network } from '../services/ethService';
import { parseSwapEventsFromReceipt } from '../services/eventUtils';
import { logTx } from '../services/analyticsService';
import { parseAllFeesFromReceipts, parseSwapsFromReceipts } from '../services/recipeCreator/recipeSuccessService';

/**
 * Adds a tx notification to the right corner of the app
 *
 * @return {Function}
 */
export const addNotification = (id, status, title, description, hash = '', additionalInfo = {}) => (dispatch) => {
  const payload = {
    id, status, title, description, hash, ...additionalInfo,
  };

  dispatch({ type: ADD_NOTIFICATION, payload });
};

/**
 * Closes a tx if the tx is still present
 *
 * @param id {String}
 *
 * @return {Function}
 */
export const closeNotification = id => (dispatch, getState) => {
  const notifications = [...getState().notifications.notifications];
  const index = notifications.findIndex(notif => notif.id === id);

  if (index < 0) return;

  notifications.splice(index, 1, { ...notifications[index], hidden: true });

  dispatch({ type: CLOSE_NOTIFICATION, payload: notifications });
};

/**
 * Checks from the state if selected accountType/walletName support type 2 transactions
 *
 * @param getState
 * @return {boolean}
 */
const useType2Transaction = (getState) => {
  const { accountType, additionalAccountData, network } = getState().general;
  if (network !== 1) return false;
  const walletName = additionalAccountData?.walletName || '';

  // Missing ACCOUNT_TYPES
  // fortmatic - Not supporting type 2 txs
  // gnosisSafe: - TODO needs testing
  // walletConnect
  //    - MM via wallet connect doesn't receive values
  //    - Rainbow still not supporting type 2
  // coinbase - Sends wrong priority fee, random value
  // trezor - only model T support - cannot test bc we don't have one
  // fork - Tenderly returns 1 gwei base fee, so it's better to stick to type 0
  const {
    ledger, browser, walletconnect, debug,
  } = ACCOUNT_TYPES;

  // Missing WALLET_NAMES
  // imToken, rabby, trust - not supporting type 2 tx yet
  // xdefi - is this wallet even working?
  // opera - Not accepting values for both tx types
  // coinbase - As mentioned above, sends wrong priority fee, random value
  // frame - supports only type 2 but doesn't accept price values passed
  const {
    status, imToken, metaMask,
  } = WALLET_NAMES;

  const type2TxAllowedAccTypes = [ledger, debug];
  const type2TxAllowedBrowserWalletNames = [status, imToken, metaMask];
  const type2TxAllowedWcWalletNames = [status, imToken, metaMask];

  if (type2TxAllowedAccTypes.includes(accountType)) return true;
  if (accountType === walletconnect && type2TxAllowedWcWalletNames.includes(walletName)) return true;
  return accountType === browser && type2TxAllowedBrowserWalletNames.includes(walletName);
};

export const confirmTx = tx => (dispatch, getState) => new Promise((resolve) => {
  const isType2 = useType2Transaction(getState);
  dispatch({
    type: ADD_CONFIRMATION,
    payload: {
      ...tx,
      resolve,
      isType2,
      id: randomId(),
    },
  });
});

export const closeConfirmationDialog = id => (dispatch) => {
  dispatch({ type: CLOSE_CONFIRMATION, payload: { id } });
};

/**
 * Replaces a notifications contents with new ones
 *
 * @param id {String}
 * @param changes {Object}
 *
 * @return {Function}
 */
export const changeNotification = (id, changes) => (dispatch, getState) => {
  const notifications = [...getState().notifications.notifications];
  const index = notifications.findIndex(notif => notif.id === id);

  if (index < 0) return;

  notifications.splice(index, 1, { ...notifications[index], ...changes });

  dispatch({ type: CHANGE_NOTIFICATION, payload: notifications });
};

export const registerTx = (txHash, title, category) => async (dispatch, getState) => {
  const {
    account: address, forkId, accountType, network,
  } = getState().general;
  if (accountType === ACCOUNT_TYPES.gnosisSafe) return;
  if (network === 1) {
    addTxHashForMonitoringApiCall(address, txHash, title, category, forkId);
  }
  const payload = {
    address,
    txHash,
    title,
    category,
    status: 'pending',
    added: (new Date()).toISOString(),
  };
  dispatch({ type: ADD_SERVER_TX, payload });
};

export const sendTxWeb3 = (
  tx, title, category, dispatch, getState, onTxHashCallback, additionalInfo, isRaw,
) => new Promise(async (resolve, reject) => {
  const id = `${getState().notifications.notifications.length}-${title}`;
  const accountType = getState().general.accountType;
  let confirmed = false;
  let txHash = '';

  logTx(tx, additionalInfo);

  const formatTx = hash => `${hash.slice(0, 8)}...`;
  const closeThisNotification = () => { setTimeout(() => { dispatch(closeNotification(id)); }, 5000); };
  const handleError = async (err, hash, forkTxId) => {
    let errorMessage = t('errors.error_occurred');

    if (err) {
      if (err.message) errorMessage = err.message;
      if (typeof err === 'string') errorMessage = err;
    }
    if (errorMessage.includes('User denied transaction signature')) {
      errorMessage = t('errors.denied_transaction');
    }
    // Workaround for https://github.com/MetaMask/metamask-extension/issues/7160
    if (err?.stack?.includes('User denied transaction signature')) {
      errorMessage = t('errors.denied_transaction');
    }
    if (errorMessage.includes('reverted')) {
      errorMessage = t('errors.reverted_transaction', { '%txhash': txHash });
    }

    const txTakingTooLong = err?.stack?.includes('not mined within 50 blocks');

    dispatch(changeNotification(id, {
      tx: '', status: 'failed', description: errorMessage, loadingDfeData: accountType !== ACCOUNT_TYPES.fork, txTakingTooLong, hidden: false, forkTxId,
    }));

    if (hash && !txTakingTooLong && accountType !== ACCOUNT_TYPES.fork) {
      const dfeData = await getTxData(hash);

      dispatch(changeNotification(id, {
        tx: '', status: 'failed', description: errorMessage, loadingDfeData: false, dfeData,
      }));
    }

    reject(new Error(errorMessage));
  };
  const protocol = additionalInfo.protocol;

  if (accountType !== ACCOUNT_TYPES.gnosisSafe) {
    const confirmation = await store.dispatch(confirmTx({ ...tx, title, protocol }));
    if (confirmation === false) return handleError(new Error(t('errors.user_canceled')));
    const {
      gasPrice, gasLimit, maxFee, priorityFee, isType2,
    } = confirmation;
    if (parseFloat(gasPrice) > 500) {
      await wait(500);
      const confirmed = await store.dispatch(confirmViaModal(t('errors.gas_price_very_high', { '%gasPrice': gasPrice })));
      if (!confirmed) return handleError(new Error(t('errors.user_canceled')));
    }

    if (isType2) {
      delete tx.gasPrice; // eslint-disable-line
      tx.type = '0x2'; // eslint-disable-line
      tx.maxFeePerGas = window._web3.utils.numberToHex(ethToWei(maxFee, 'gwei')); // eslint-disable-line
      tx.maxPriorityFeePerGas = window._web3.utils.numberToHex(ethToWei(priorityFee, 'gwei')); // eslint-disable-line
    } else {
      tx.gasPrice = window._web3.utils.numberToHex(ethToWei(gasPrice, 'gwei')); // eslint-disable-line
      // eslint-disable-next-line no-param-reassign
      delete tx.accessList;
    }
    tx.gas = gasLimit; // eslint-disable-line
    tx.gasLimit = gasLimit; // eslint-disable-line
  }
  dispatch(addNotification(id, 'pending-sign', title, t('misc.confirm_transaction'), '', additionalInfo));

  let errorThrown = false;

  try {
    const send = tx.send;
    // eslint-disable-next-line no-param-reassign
    delete tx.send; // Send deleted from precaution
    (isRaw ? window._web3.eth.sendTransaction : send)({ ...tx }) // Do not pass any undefined props to send, trezor provider will fail sending tx
      .on('transactionHash', (hash) => {
        const description = t('misc.tx_sent', { '%txHash': formatTx(hash) });

        dispatch(registerTx(hash, title, category));

        txHash = hash;
        dispatch(changeNotification(id, { status: 'pending', hash, description }));
        onTxHashCallback(hash);
      })
      .on('confirmation', async (confirmNum, receipt) => {
        if (!confirmed) {
          confirmed = true;
          const {
            transactionHash, events, status, gasUsed, txId,
          } = receipt;

          if (events && events.Failure) {
            errorThrown = true;
            return handleError(t('errors.compound_tx_fail'), transactionHash, txId);
          }

          if (!status) {
            errorThrown = true;
            return handleError(t('errors.reverted_transaction'), transactionHash, txId);
          }
          const [recipeSwaps, fees] = await Promise.all([
            parseSwapsFromReceipts([receipt]),
            parseAllFeesFromReceipts([receipt], isLayer2Network(getState().general.network)),
          ]);
          const eventData = {
            recipeSwaps,
            fees,
            swapRate: undefined,
          };
          if (eventData.recipeSwaps.length === 0 && additionalInfo.firstToken && additionalInfo.secondToken) {
            eventData.swapRate = parseSwapEventsFromReceipt(receipt, additionalInfo);
          }

          const description = t('misc.tx_confirmed', { '%txHash': formatTx(transactionHash) });

          dispatch(changeNotification(id, {
            status: 'confirmed', description, gasUsed, eventData, hidden: false, forkTxId: txId,
          }));
          // closeThisNotification();

          resolve(receipt);
        }
      })
      .on('error', async (error) => {
        console.error(error);
        if (!errorThrown) await handleError(error, txHash, error?.receipt?.txId);
      });
  } catch (err) {
    await handleError(err, txHash);
  }
});

/**
 * Handles a transactions status in the notification
 *
 * @param event {Object}
 * @param title {String}
 * @param category {String}
 * @param dispatch {Function}
 * @param getState {Function}
 * @param additionalInfo {Object}
 * @param onTxHashCallback {Function}
 * @param isRaw {Boolean}
 *
 * @return {Promise<any>}
 */
export const sendTx = (event, title, category, dispatch, getState, additionalInfo = {}, onTxHashCallback = () => {}, isRaw = false) => (
  // clientConfig.network === 42 ?
  sendTxWeb3(event, title, category, dispatch, getState, onTxHashCallback, additionalInfo, isRaw)
  // : sendTxBlocknative(event, title, dispatch, getState)
);


export const callMultipleTxAction = (txArray, returnValues = []) => async (dispatch, getState) => {
  const numOfNotifications = getState().notifications.notifications.length;
  if (!txArray.length) return;
  try {
    dispatch({
      type: PENDING_TX,
      payload: {
        pendingTxs: txArray.map(tx => tx.notifMessage),
        numOfNotifications,
      },
    });
    let i = 0;
    // eslint-disable-next-line no-restricted-syntax
    for (const tx of txArray) {
      // eslint-disable-next-line no-await-in-loop
      const receipt = await tx.func();
      if (tx.checkReturnValue) {
        if (!receipt) throw new Error(tx.returnValueFailureMessage);
      }
      returnValues.push(receipt);
      dispatch({ type: CURRENT_TX, payload: ++i });
    }

    setTimeout(() => { dispatch({ type: CLEAR_PENDING_TX }); }, 5000);
    return returnValues;
  } catch (err) {
    console.error(err, err.message);
    dispatch({ type: CLEAR_PENDING_TX });

    throw new Error(err.message);
  }
};
