import React, { useEffect, useMemo, useState } from 'react';
import Dec from 'decimal.js';
import t from 'translate';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { repayAction, getRepayModalDataForAsset, resetRepayModal } from '../../../../actions/makerActions/makerManageActions/makerManageActions';
import { getDashboardInputs } from '../../../../actions/dashboardActions';

import { MakerSlippageLimitForm as SlippageLimitForm } from '../../BoostRepayCommon/SlippageLimitForm/SlippageLimitForm';
import TradeSizeImpactWarning from '../../../TradeSizeImpactWarning/TradeSizeImpactWarning';
import ModalHeader from '../../ModalHeader';
import ModalBody from '../../ModalBody';
import Loader from '../../../Loader/Loader';
import TooltipWrapper from '../../../TooltipWrapper/TooltipWrapper';
import ErrorBox from '../../../Decorative/ErrorBox/ErrorBox';
import InfoIcon from '../../../Decorative/InfoIcon';
import ActionInfo from '../../BoostRepayCommon/ActionInfo';
import AdvancedOptions from '../../BoostRepayCommon/AdvancedOptions/AdvancedOptions';
import AdditionalInfo from '../../../AdditionalInfo/AdditionalInfo';
import { MakerDeadlineLimitForm as DeadlineLimitForm } from '../../BoostRepayCommon/DeadlineLimitForm/DeadlineLimitForm';
import { isCdpLP } from '../../../../services/utils';
import { ASSETS_FROM_MAKER_ASSET } from '../../../../constants/assets';

const MakerRepayModal = ({
  closeModal, repayAmount, repayAction, gettingRepayModalData, tradeSizeImpact, gettingRepayModalDataError,
  repaying, repayExchangeRate, afterCdp, cdpDaiSymbol, cdpAssetSymbol, repayingError, resetRepayModal,
  useFl, flProtocol, exchangeSource, flFee, additionalAction, contextAction, getDashboardInputs,
  lpFirstAmount, lpSecondAmount, lpFullDaiAmount, getRepayModalDataForAsset,
  lpSellAmount, lpSellAmountSecond, repayAmountSecond, tradeSizeImpactSecond, sourceSecond, exchangeRateSecond, lpOneAsset,
}) => {
  const {
    firstInput: inputAmount, secondAction, secondInput,
  } = useMemo(() => getDashboardInputs(contextAction, additionalAction), []);

  const [advanced, setAdvanced] = useState(false);
  const [LPCdp, setLPCdp] = useState(false);
  const [advancedLP, setAdvancedLP] = useState(false);

  useEffect(() => {
    const isCDPLP = isCdpLP(cdpAssetSymbol);
    setLPCdp(isCDPLP);
    // eslint-disable-next-line no-unused-expressions
    getRepayModalDataForAsset(inputAmount, cdpAssetSymbol);
    return resetRepayModal;
  }, []);

  const assets = useMemo(() => ASSETS_FROM_MAKER_ASSET(cdpAssetSymbol), [cdpAssetSymbol]);

  const debtDai = afterCdp === null ? false : afterCdp.debtDai;
  const assetSymbol = LPCdp
    ? (assets.isFirstDAI
      ? assets.secondAsset
      : assets.firstAsset)
    : cdpAssetSymbol;

  const shouldFlip = new Dec(repayExchangeRate).lt(new Dec(1).div(repayExchangeRate).toString());

  const exchangeLabel = shouldFlip
    ? `${assetSymbol}/${cdpDaiSymbol}`
    : `${cdpDaiSymbol}/${assetSymbol}`;
  const correctedRepayExchangeRate = shouldFlip
    ? new Dec(1).div(repayExchangeRate).toString()
    : repayExchangeRate;

  const shouldFlipSecond = useMemo(() => {
    if (!exchangeRateSecond) return false;
    return new Dec(exchangeRateSecond).lt(new Dec(1).div(exchangeRateSecond).toString());
  }, [exchangeRateSecond]);

  const exchangeLabelSecond = (shouldFlipSecond && LPCdp)
    ? `${assets?.secondAsset}/${cdpDaiSymbol}`
    : `${cdpDaiSymbol}/${assets?.secondAsset}`;

  const correctedRepayExchangeRateSecond = useMemo(() => {
    if (!exchangeRateSecond) return '';
    return (shouldFlipSecond ? new Dec(1).div(exchangeRateSecond).toString() : exchangeRateSecond);
  }, [exchangeRateSecond]);

  const lpAction = LPCdp
    ? {
      lpAsset: cdpAssetSymbol,
      firstAsset: assets.firstAsset,
      firstAmount: lpFirstAmount,
      secondAsset: assets.secondAsset,
      secondAmount: lpSecondAmount,
      isFirstDAI: assets.isFirstDAI,
      lpFullCollateralAmount: inputAmount,
      lpFullDaiAmount,
      repayAmount,
      lpSellAmount,
      lpSellAmountSecond,
      lpProtocol: assets.protocolShortName,
      lpSellAsset: assets.hasDAI
        ? (
          assets.isFirstDAI ? assets.secondAsset : assets.firstAsset
        )
        : assets.firstAsset,
      lpSellAssetSecond: assets.secondAsset,
      repayAmountSecond,
      exchangeRateSecond,
      lpOneAsset,
    }
    : {};

  const secondAmount = LPCdp ? lpFullDaiAmount : repayAmount;

  const sourceLabel = (sourceSecond && exchangeSource !== sourceSecond) ? `${exchangeSource},${sourceSecond}` : exchangeSource;
  return (
    <div className="boost-repay-modal">
      <ModalHeader closeModal={closeModal} />

      <ModalBody>
        <div className="new-modal-top-wrapper">
          <h1>
            <TooltipWrapper title={t('maker.repay_modal_description', { '%daiLabel': cdpDaiSymbol, '%asset': cdpAssetSymbol })}>
              <InfoIcon /> {t('common.repay')}
            </TooltipWrapper>
          </h1>
        </div>

        <div className="new-modal-content-wrapper">

          {gettingRepayModalData && (<div className="container"><div className="loading-wrapper"><Loader /></div></div>)}

          {!gettingRepayModalData && gettingRepayModalDataError && (<ErrorBox>{gettingRepayModalDataError}</ErrorBox>)}

          {debtDai === 0 && (<div className="all-debt-repaid">{t('maker.all_debt_repay')}</div>)}

          {!gettingRepayModalData && !gettingRepayModalDataError && (
            <>
              <ActionInfo
                isRepay
                firstAsset={cdpAssetSymbol}
                firstAmount={inputAmount}
                secondAsset={cdpDaiSymbol}
                secondAmount={secondAmount}
                exchangeRate={correctedRepayExchangeRate}
                exchangeLabel={exchangeLabel}
                additionalAction={secondAction}
                additionalInput={secondInput}
                isLP={LPCdp}
                lpAction={lpAction}
                advancedLP={advancedLP}
                setAdvancedLP={setAdvancedLP}
                exchangeRateSecond={correctedRepayExchangeRateSecond}
                exchangeLabelSecond={exchangeLabelSecond}
                additionalAsset={secondAction?.value === 'withdraw' ? cdpAssetSymbol : cdpDaiSymbol}
              />

              <div className="advanced__wrapper container">
                <TradeSizeImpactWarning tradeSizeImpact={tradeSizeImpact} afterLabel={LPCdp ? exchangeLabel : ''} />
                {tradeSizeImpactSecond && <TradeSizeImpactWarning tradeSizeImpact={tradeSizeImpactSecond} afterLabel={LPCdp ? exchangeLabelSecond : ''} />}
                <AdvancedOptions
                  isRepay
                  borrowAsset={cdpAssetSymbol}
                  supplyAsset={cdpDaiSymbol}
                  advanced={advanced}
                  setAdvanced={setAdvanced}
                  exchangeRate={repayExchangeRate}
                  SlippageLimitForm={SlippageLimitForm}
                  isLP={LPCdp}
                  hasDeadline={assets.protocolShortName === 'Uni V2'}
                  DeadlineLimitForm={DeadlineLimitForm}
                />
                <AdditionalInfo
                  flashloanSource={useFl ? flProtocol : ''}
                  flashloanFee={flFee}
                  exchangeSource={sourceLabel}
                  additionalAsset={secondAction?.value === 'withdraw' ? cdpAssetSymbol : cdpDaiSymbol}
                  asset1={cdpAssetSymbol}
                  asset2={cdpDaiSymbol}
                />
                {repayingError && <ErrorBox marginTop>{repayingError}</ErrorBox>}
              </div>
            </>
          )}

        </div>
      </ModalBody>

      <div className="modal-controls">
        <button
          form="maker-repay-modal-form"
          type="button"
          disabled={repaying || gettingRepayModalData}
          onClick={() => repayAction(contextAction, additionalAction, closeModal)}
          className="button green"
        >
          {repaying ? t('common.repaying') : t('common.repay')}
        </button>
      </div>
    </div>
  );
};

MakerRepayModal.defaultProps = {
  repaying: false,
  repayingError: '',
  lpFirstAmount: '',
  lpSecondAmount: '',
  lpFullDaiAmount: '',
  lpSellAmount: '',
  lpSellAmountSecond: '',
  repayAmountSecond: '',
  exchangeRateSecond: '',
  tradeSizeImpactSecond: '',
  sourceSecond: '',
  lpOneAsset: false,
};

MakerRepayModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  repayAmount: PropTypes.string.isRequired,
  repayExchangeRate: PropTypes.string.isRequired,
  tradeSizeImpact: PropTypes.string.isRequired,
  getRepayModalDataForAsset: PropTypes.func.isRequired,
  repayAction: PropTypes.func.isRequired,
  resetRepayModal: PropTypes.func.isRequired,
  gettingRepayModalData: PropTypes.bool.isRequired,
  gettingRepayModalDataError: PropTypes.string.isRequired,
  repaying: PropTypes.bool,
  repayingError: PropTypes.string,
  afterCdp: PropTypes.object.isRequired,
  cdpDaiSymbol: PropTypes.string.isRequired,
  cdpAssetSymbol: PropTypes.string.isRequired,
  flProtocol: PropTypes.string.isRequired,
  useFl: PropTypes.bool.isRequired,
  flFee: PropTypes.string.isRequired,
  exchangeSource: PropTypes.string.isRequired,
  getDashboardInputs: PropTypes.func.isRequired,
  additionalAction: PropTypes.object,
  contextAction: PropTypes.object,

  lpFirstAmount: PropTypes.string,
  lpSecondAmount: PropTypes.string,
  lpFullDaiAmount: PropTypes.string,
  lpSellAmount: PropTypes.string,
  lpSellAmountSecond: PropTypes.string,
  repayAmountSecond: PropTypes.string,
  exchangeRateSecond: PropTypes.string,
  tradeSizeImpactSecond: PropTypes.string,
  sourceSecond: PropTypes.string,
  lpOneAsset: PropTypes.bool,
};

const mapStateToProps = ({ makerManage, maker }) => ({
  cdp: maker.cdp,
  tradeSizeImpact: makerManage.tradeSizeImpact,
  gettingRepayModalData: makerManage.gettingRepayModalData,
  gettingRepayModalDataError: makerManage.gettingRepayModalDataError,
  repaying: makerManage.executing.repay || (makerManage.executing[makerManage.selectedAction?.value] && makerManage.selectedAdditionalActions[makerManage.selectedAction?.value]?.value === 'repay'),
  repayingError: makerManage.executingErrors.repay,
  repayAmount: makerManage.repayAmount,
  repayExchangeRate: makerManage.repayExchangeRate,
  afterCdp: makerManage.afterCdp,
  useFl: makerManage.useFl,
  flProtocol: makerManage.flProtocol,
  flFee: makerManage.flFee,
  exchangeSource: makerManage.exchangeSource,
  cdpDaiSymbol: maker.cdp.daiLabel,
  cdpAssetSymbol: maker.cdp.asset,
  isFirstDAI: makerManage.isFirstDAI,
  lpFirstAmount: makerManage.lpFirstAmount,
  lpSecondAmount: makerManage.lpSecondAmount,
  lpFullDaiAmount: makerManage.lpFullDaiAmount,
  lpSellAmount: makerManage.lpSellAmount,
  lpSellAmountSecond: makerManage.lpSellAmountSecond,
  repayAmountSecond: makerManage.repayAmountSecond,
  tradeSizeImpactSecond: makerManage.tradeSizeImpactSecond,
  exchangeRateSecond: makerManage.exchangeRateSecond,
  sourceSecond: makerManage.sourceSecond,
  lpOneAsset: makerManage.lpOneAsset,
});

const mapDispatchToProps = {
  repayAction, resetRepayModal, getDashboardInputs, getRepayModalDataForAsset,
};

export default connect(mapStateToProps, mapDispatchToProps)(MakerRepayModal);
