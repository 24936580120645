import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import { getAssetInfo } from '@defisaver/tokens';
import { formatCdpId } from '../../services/makerServices/makerMcdService';

const CdpSelectCustomIconOption = (props) => {
  const Icon = getAssetInfo(props.data.value.asset).icon;

  return (
    <components.Option {...props}>
      <div className="icon-wrapper"><Icon /></div>
      <div className="option-value">{formatCdpId(props.data.value)}</div>
    </components.Option>
  );
};

CdpSelectCustomIconOption.propTypes = {
  data: PropTypes.shape({
    value: PropTypes.shape({
      asset: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default CdpSelectCustomIconOption;
