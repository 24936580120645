import React from 'react';
import {
  isMobileDevice,
} from '../../../services/utils';
import ConnectWalletButtonCustomOption from './ConnectWalletButtonCustomOption';

import './ConnectWalletButtons.scss';
import { CONNECT_WALLET_OPTIONS, DEBUG_WALLET_OPTION, GNOSIS_SAFE_OPTION } from '../../../constants/wallet';


const ConnectWalletButtons = () => {
  let options = CONNECT_WALLET_OPTIONS();
  if (window.parent !== window) {
    options.splice(-2, 0, GNOSIS_SAFE_OPTION);
  }
  if (window.debugWithAccount || localStorage.getItem('debugWithAccount')) {
    options.push(DEBUG_WALLET_OPTION);
  }
  if (isMobileDevice()) options = options.filter(({ mobile }) => mobile === true);

  return (
    <div className="connect-wallet-buttons-wrapper">
      {
          options.map(option => (
            <ConnectWalletButtonCustomOption
              key={option.label}
              option={option}
              length={options.length}
            />
          ))
        }
    </div>
  );
};

export default ConnectWalletButtons;
