import {
  GETTING_NEXUS_DATA_REQUEST,
  GETTING_NEXUS_DATA_SUCCESS,
  GETTING_NEXUS_DATA_FAILURE,
} from '../actionTypes/nexusActionTypes';
import { CONNECT_PROVIDER_SUCCESS } from '../actionTypes/generalActionTypes';

const INITIAL_STATE = {
  gettingInsurances: false,
  gettingInsurancesError: '',
  insurances: [],
  makerInsured: false,
  compoundInsured: false,
  aaveInsured: false,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case GETTING_NEXUS_DATA_REQUEST:
      return {
        ...state,
        gettingInsurances: true,
        insurances: [],
      };

    case GETTING_NEXUS_DATA_FAILURE:
      return {
        ...state,
        gettingInsurances: true,
        gettingInsurancesError: payload,
      };

    case GETTING_NEXUS_DATA_SUCCESS:
      return {
        ...state,
        gettingInsurances: false,
        ...payload,
      };

    case CONNECT_PROVIDER_SUCCESS:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};
