import Dec from 'decimal.js';
import { getAssetInfo } from '@defisaver/tokens';
import { getBestExchangePrice } from '../exchangeServiceV3';

export const getMaxBorrow = (trove) => {
  const liqRatio = (trove.liqPercent / 100) + 0.001;
  // debtInAsset is increased by 5% bc that is maxFeePercentage
  let maxGen = new Dec(trove.collateralUsd).div(liqRatio).minus(trove.debtUsd).div(trove.debtAssetPrice)
    .div(1.05)
    .toDP(getAssetInfo(trove.debtAsset).decimals)
    .toString();

  if (new Dec(maxGen).lt(0)) maxGen = '0';

  return maxGen;
};

export const getMaxPayback = (trove, debtAssetBalance) => {
  const _debtLeft = new Dec(trove.debtInAsset).minus(trove.minDebt);
  const debtLeft = _debtLeft.lt(0) ? '0' : _debtLeft;
  return Dec.min(debtLeft, debtAssetBalance).toString();
};

export const getMaxRepay = async (trove, address) => {
  const flashLoanAvailable = true;

  const { price: estPrice } = await getBestExchangePrice(trove.collateral, 'WETH', trove.debtAsset, address, true, true, true);

  if (flashLoanAvailable) {
    return new Dec(trove.debtInAsset).div(estPrice).mul(1.02)
      .toDP(getAssetInfo(trove.asset).decimals)
      .toString();
  }
};
