import dfs from '@defisaver/sdk';
import { assetAmountInWei } from '@defisaver/tokens';
import Dec from 'decimal.js';
import RecipeAction from '../RecipeAction';
import { AssetAmount, Source } from '../../components/Recipes/RecipeCreator/inputTypes';
import { addCollateralAfterValues } from '../../actions/liquityActions/liquityManageAfterValues';
import { findInsertPosition, getTroveInfo } from '../../services/liquityServices/liquityService';
import SupplyIcon from '../recipeIcons/Supply.svg';
import { formatNumber } from '../../services/utils';
import { MAXUINT } from '../../constants/general';

export default class LiquitySupplyAction extends RecipeAction {
  static prettyName = 'Supply WETH to Trove';

  static protocol = 'liquity';

  static protocolPrettyName = 'Liquity';

  static description = 'Supplies additional WETH to an existing Trove.';

  constructor(from = 'wallet', collateral = '') {
    super();
    this.inputs = [
      new Source('From', from),
      new AssetAmount('Collateral', collateral, 'WETH'),
    ];
    this.output = new AssetAmount('output', 0, 'WETH');
  }

  async getAfterValues(_balances = {}, returnValues = [], actions = [], _positions = {}, getState) {
    const positions = { ..._positions };
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const {
      liquity: { proxy },
      liquityStaking: { proxy: stakingProxy },
    } = getState();
    if (!positions.liquity) positions.liquity = { ...proxy, ...stakingProxy };
    const source = args[0];
    let amount = args[1] || '0';
    const balanceAtStart = source === 'wallet' ? getState().assets.WETH.balance : '0';
    if (amount === 'All available') amount = _balances?.[source]?.WETH || balanceAtStart;
    const { afterPosition, balances, returnValue } = await addCollateralAfterValues({
      amount,
      from: source,
    }, {
      vault: positions.liquity,
      assets: getState().assets,
      account: getState().general.account,
      proxyAddress: getState().maker.proxyAddress,
    },
    _balances);
    positions.liquity = afterPosition;
    this.output.value = returnValue;
    return { returnValue: this.output, balances, positions };
  }

  async toDfsAction(getState, actions, returnValues, positions, actionBeforeState, actionCurrentState) {
    const {
      general: { account },
      maker: { proxyAddress },
    } = getState();
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const from = this.inputs[0].value === 'wallet' ? account : proxyAddress;
    const trove = actionCurrentState?.positions?.liquity || await getTroveInfo(proxyAddress);
    const amount = args[1] === 'All available' ? MAXUINT : assetAmountInWei(args[1], 'WETH');
    // using trove.collateral because trove is taken from positions (acceptable using estimations from positions because hints are an approximation)
    const { lowerHint, upperHint } = await findInsertPosition(assetAmountInWei(trove.collateral, 'WETH'), assetAmountInWei(trove.debtInAsset, 'LUSD'), proxyAddress);
    return new dfs.actions.liquity.LiquitySupplyAction(amount, from, upperHint, lowerHint);
  }

  setAsset(asset) {
    this.asset = asset;
  }

  getAsset() {
    return this.asset;
  }

  static getIcon() {
    return SupplyIcon;
  }

  _getPrettyName(actionCalls, actions) {
    const args = this.mapReturnValuesToArgs(actionCalls.map(a => a.returnValue), actions);
    return `Supply ${formatNumber(args[1])} WETH to Trove`;
  }
}
