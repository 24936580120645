import dfs from '@defisaver/sdk';
import cloneDeep from 'lodash/cloneDeep';
import Dec from 'decimal.js';
import { assetAmountInEth } from '@defisaver/tokens';

import { MAXUINT } from '../../constants/general';
import RecipeAction from '../RecipeAction';
import { assetAmountInWeiIgnorePointer, changeBalance } from '../../services/recipeCreator/recipeActionUtils';
import { getStETHByWstETH } from '../../services/lidoService';
import { formatNumber } from '../../services/utils';

import { AssetAmount, Amount, Source } from '../../components/Recipes/RecipeCreator/inputTypes';
import SellIcon from '../recipeIcons/Swap.svg';

export default class LidoUnwrapAction extends RecipeAction {
  static prettyName = 'Unwrap wstETH';

  static protocol = 'lido';

  static protocolPrettyName = 'Lido';

  static description = 'Unwraps wstETH to stETH.';

  constructor(from = 'wallet', amount = '', to = 'wallet') {
    super();
    this.inputs = [
      new Source('From', from),
      new AssetAmount('Amount', amount, 'wstETH'),
      new Source('To', to, true),
    ];
    this.output = new AssetAmount('output', 0, 'stETH');
  }

  async toDfsAction(getState, i) {
    const {
      general: { account },
      maker: { proxyAddress },
    } = getState();
    const from = this.inputs[0].value === 'wallet' ? account : proxyAddress;
    const to = this.inputs[2].value === 'wallet' ? account : proxyAddress;
    const amount = this.inputs[1].value === 'All available' ? MAXUINT : assetAmountInWeiIgnorePointer(this.inputs[1].value, 'wstETH');
    return new dfs.actions.lido.LidoUnwrapAction(amount, from, to);
  }

  async getAfterValues(_balances = {}, returnValues = [], actions = [], positions = {}, getState) {
    const {
      general: { account },
      maker: { proxyAddress },
    } = getState();
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    let amount = args[1] || '0';
    const balances = cloneDeep(_balances);

    const isSourceWallet = args[0] === 'wallet';
    const balanceAtStart = isSourceWallet ? getState().assets.wstETH?.balance || '0' : '0';
    if (amount === 'All available') amount = balances?.[args[0]]?.wstETH || balanceAtStart;

    // Ratio between stETH and wstETH is not 1:1
    const stETHAmount = amount === '0' ? '0' : assetAmountInEth(await getStETHByWstETH(assetAmountInWeiIgnorePointer(amount, 'wstETH')), 'stETH');

    await changeBalance(balances, args[0], 'wstETH', new Dec(amount).mul(-1).toString(), args[0] === 'wallet' ? account : proxyAddress);
    await changeBalance(balances, args[2], 'stETH', stETHAmount, args[2] === 'wallet' ? account : proxyAddress);
    this.output.value = stETHAmount;
    return {
      returnValue: this.output,
      balances,
      positions,
    };
  }

  _getPrettyName(actionCalls, actions) {
    const args = this.mapReturnValuesToArgs(actionCalls.map(a => a.returnValue), actions);
    const amount = args[1];
    return `Unwrap ${amount === 'All available' ? 'all' : formatNumber(amount)} wstETH to stETH`;
  }

  static getIcon() {
    return SellIcon;
  }
}
