import memoize from 'memoizee';
import Dec from 'decimal.js';
import { yearnCollateralAssets } from 'constants/assets';
import { YearnRegistryAddress, YearnRegistryContract } from '../contractRegistryService';
import { aggregate, weiToEth } from '../ethService';

export const getYTokens = async () => {
  const tokensMulticallObject = [];
  for (let i = 0; i < 53; i++) {
    tokensMulticallObject.push({
      target: YearnRegistryAddress,
      call: ['tokens(uint256)(address)', i],
      returns: [
        [`token${i}`, val => val.toString()],
      ],
    });
  }

  const multiRes = await aggregate(tokensMulticallObject);
  console.log(multiRes);
};

export const getVaultAddressForToken = async (token, useIndex = false, index = 0) => {
  const contract = YearnRegistryContract();
  return useIndex ? contract.methods.vaults(token, index).call() : contract.methods.latestVault(token).call();
};


export const getLatestVaultAddressForTokens = async (tokenAddresses) => {
  const tokensMulticallObject = tokenAddresses.map((tokenAddress, i) => ({
    target: YearnRegistryAddress,
    call: ['latestVault(address)(address)', tokenAddress],
    returns: [
      [`token${i}`, val => val.toString()],
    ],
  }));
  const multiRes = await aggregate(tokensMulticallObject);
  return Object.values(multiRes.results.transformed);
};

export const getVaultInfoForToken = async (token, addresses, useIndex = false, index = 0) => {
  const address = await getVaultAddressForToken(token, useIndex, index);
  const _addresses = addresses.filter(i => i);
  const multicallObject = _addresses.map((account, index) => (
    [
      {
        target: address,
        call: ['balanceOf(address)(uint256)', account],
        returns: [
          [`balance${index}`, val => weiToEth(val.toString())],
        ],
      },
    ]
  )).flat();

  const multiRes = await aggregate(multicallObject);

  const { transformed } = multiRes.results;

  return [addresses.map((address, i) => transformed[`balance${i}`]), address];
};


export const getVaultInfoForTokenCached = memoize(getVaultInfoForToken, { maxAge: 2 * 60 * 1000, promise: true });

export const getNumOfYearnVaultsForAssets = async () => {
  const tokensMulticallObject = [];
  for (let i = 0; i < yearnCollateralAssets.length; i++) {
    tokensMulticallObject.push({
      target: YearnRegistryAddress,
      call: ['numVaults(address)(uint256)', yearnCollateralAssets[i].address],
      returns: [
        [`numOfTokens${i}`, val => val.toString()],
      ],
    });
  }

  const multiRes = await aggregate(tokensMulticallObject);
  const { results: { transformed } } = multiRes;
  return Object.values(transformed).reduce((finalArray, current) => [...finalArray, current], []);
};

export const getYearnVaultAddresses = async () => {
  const numOfTokens = await getNumOfYearnVaultsForAssets();
  const tokensMulticallObject = [];
  for (let i = 0; i < yearnCollateralAssets.length; i++) {
    for (let j = 0; j < parseInt(numOfTokens[i], 10); j++) {
      tokensMulticallObject.push({
        target: YearnRegistryAddress,
        call: ['vaults(address,uint256)(address)', yearnCollateralAssets[i].address, j],
        returns: [
          [`address${yearnCollateralAssets[i].symbol}${j}`, val => val.toString()],
        ],
      });
    }
  }
  const multiRes = await aggregate(tokensMulticallObject);
  const { results: { transformed } } = multiRes;
  return yearnCollateralAssets.map(asset => ({
    ...asset,
    vaultAddresses: Object.entries(transformed).filter(entry => entry[0].includes(asset.symbol)).map(entry => entry[1]),
  }));
};


export const assetsWithAllVaults = (numOfTokens) => numOfTokens.map((num, i) => new Array(parseInt(num, 10)).fill(yearnCollateralAssets[i]).map((asset, i, array) => {
  const latest = i === (array.length - 1);
  const showIndex = !latest && Dec(array.length).gte(2);
  return {
    ...asset,
    label: `${asset.symbol}${!latest ? ` (deprecated${showIndex ? ` ${array.length - i - 1}` : ''})` : ''}`,
    index: i.toString(),
  };
})).flat();


export const getYearnTokensInfo = async (tokens) => {
  const multicallObject = tokens.map((token, i) => ([
    {
      target: token.address,
      call: ['token()(address)'],
      returns: [
        [`token${i}${token.address}`, val => val.toString()],
      ],
    },
    {
      target: token.address,
      call: ['name()(string)'],
      returns: [
        [`name${i}${token.address}`, val => val.toString()],
      ],
    },
  ])).flat();
  const res = await aggregate(multicallObject);
  const { results: { transformed } } = res;
  return tokens.map((token, i) => ({
    address: token.address,
    name: transformed[`name${i}${token.address}`],
    token: transformed[`token${i}${token.address}`],
  }));
};
