/* eslint-disable */
import React from 'react';

export default function RabbyIcon(props) {
  return (
    <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.8927 10.3945C21.7175 8.55164 17.6399 3.40283 13.7444 1.25774C11.289 -0.404032 8.73039 -0.175733 8.21226 0.553907C7.07499 2.15518 11.9778 3.51201 15.2568 5.09532C14.5519 5.40153 13.8877 5.95102 13.4971 6.65372C12.2747 5.31887 9.5917 4.16945 6.44345 5.09532C4.32189 5.7193 2.55872 7.19027 1.87727 9.41198C1.71169 9.33839 1.52836 9.29748 1.33548 9.29748C0.597912 9.29748 0 9.8955 0 10.6332C0 11.3709 0.597912 11.9689 1.33548 11.9689C1.47219 11.9689 1.89965 11.8772 1.89965 11.8772L8.73039 11.9268C5.99864 16.2612 3.83977 16.8948 3.83977 17.6457C3.83977 18.3966 5.90543 18.1931 6.68106 17.9132C10.394 16.5733 14.3818 12.3973 15.0661 11.1951C17.9398 11.5537 20.3549 11.5962 20.8927 10.3945Z" fill="url(#paint0_linear)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M15.2563 5.09553C15.2565 5.09567 15.2567 5.09574 15.2569 5.09581C15.4088 5.03592 15.3843 4.8114 15.3425 4.6351C15.2466 4.22987 13.5916 2.59531 12.0375 1.86318C9.91973 0.865559 8.36029 0.916991 8.12988 1.3768C8.56127 2.26108 10.5612 3.09131 12.6498 3.9584C13.5409 4.32834 14.4481 4.70499 15.2567 5.09539C15.2565 5.09546 15.2565 5.09553 15.2563 5.09553Z" fill="url(#paint1_linear)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12.5691 13.9955C12.1408 13.8318 11.657 13.6816 11.1071 13.5453C11.6935 12.4958 11.8166 10.942 11.2627 9.95961C10.4854 8.58092 9.50968 7.84714 7.24227 7.84714C5.99525 7.84714 2.63762 8.26731 2.57801 11.0706C2.57176 11.3648 2.57786 11.6343 2.59914 11.8822L8.73041 11.9267C7.90385 13.2382 7.1297 14.2109 6.45192 14.9506C7.26567 15.1591 7.93722 15.3342 8.55373 15.495C9.13867 15.6475 9.67411 15.7871 10.2345 15.9301C11.0799 15.3141 11.8746 14.6424 12.5691 13.9955Z" fill="url(#paint2_linear)" />
      <path d="M1.79533 11.5942C2.0458 13.7238 3.25588 14.5584 5.72854 14.8054C8.20119 15.0524 9.61961 14.8866 11.5079 15.0585C13.085 15.202 14.4932 16.0058 15.0156 15.7281C15.4858 15.4781 15.2227 14.5749 14.5936 13.9954C13.7782 13.2443 12.6495 12.722 10.6636 12.5367C11.0594 11.4529 10.9485 9.93331 10.3339 9.10655C9.44515 7.91112 7.8047 7.37063 5.72855 7.60678C3.55943 7.85352 1.48099 8.92163 1.79533 11.5942Z" fill="url(#paint3_linear)" />
      <defs>
        <linearGradient id="paint0_linear" x1="6.19641" y1="8.76252" x2="20.7154" y2="12.8791" gradientUnits="userSpaceOnUse">
          <stop stopColor="#8697FF" />
          <stop offset="1" stopColor="#ABB7FF" />
        </linearGradient>
        <linearGradient id="paint1_linear" x1="18.2701" y1="8.48643" x2="7.79238" y2="-2.01504" gradientUnits="userSpaceOnUse">
          <stop stopColor="#8697FF" />
          <stop offset="1" stopColor="#5156D8" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint2_linear" x1="12.8606" y1="14.3614" x2="2.79648" y2="8.57622" gradientUnits="userSpaceOnUse">
          <stop stopColor="#465EED" />
          <stop offset="1" stopColor="#8697FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint3_linear" x1="7.06309" y1="8.65419" x2="13.8666" y2="17.2971" gradientUnits="userSpaceOnUse">
          <stop stopColor="#8898FF" />
          <stop offset="0.983895" stopColor="#6277F1" />
        </linearGradient>
      </defs>
    </svg>
  );
}
