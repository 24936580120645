import t from 'translate';
import { captureException } from 'sentry';
import Dec from 'decimal.js';
import { assetAmountInWei, getAssetInfo } from '@defisaver/tokens';
import { change } from 'redux-form';
import { compoundCollateralAssets } from 'constants/assets';
import * as compManageTypes from '../../actionTypes/compoundActionTypes/compoundManageActionTypes';
import {
  APPROVE_TYPE_COMPOUND,
  APPROVE_TYPE_DS_PROXY,
} from '../../actionTypes/assetsActionTypes';
import { WALLET_TYPES_OPTIONS } from '../../constants/general';
import { formName } from '../../components/DashboardActions/dashboardActionUtils';

import { trackEvent } from '../../services/analyticsService';
import { getBestExchangePrice, getSlippageThreshold } from '../../services/exchangeServiceV3';
import { calculateTradeSizeImpact } from '../../services/makerServices/makerManageServices/makerManageService';
import { getAssetBalance, flProtocolAndFeeFor } from '../../services/assetsService';
import { calcBorrowValuesWithoutColl } from '../../services/moneymarketCommonService';
import {
  supply, withdraw, borrow, payback, enableAssetAsCollateral, findCompoundAssetByUnderlying, disableAssetAsCollateral,
  getCompoundAccountData, getMarketsData, getCompBalance, claimCompBalance, claimAndSellCompBalance,
  handleWbtcLegacy, compoundManageTrackEventWrapper, getCompoundRecipe, getAction,
} from '../../services/compoundServices/compoundManageService';
import {
  formatAcc,
  formatNumber, getAddressForWalletType, getEnsAddress, isAddress, isWalletTypeProxy, resetFields,
} from '../../services/utils';
import { callMultipleTxAction, sendTx } from '../txNotificationActions';
import {
  addApproveTxIfNeeded, approveAddressOnAssetAction, getAssetsBalancesAction, getAssetsPricesAction, approveTypeToLabelMap,
} from '../assetsActions';
import {
  confirmViaModal,
  openCompoundCollateralRequiredModal,
  openCompoundProxyMigrationModal,
} from '../modalActions';
import { changeWalletType } from '../generalActions';
import { getCompoundSaverSubscribedGraphData } from './compoundSaverActions';
import { setAfterValue } from './compoundManageAfterValues';
import {
  getMaxWithdrawAction, getMaxSupplyAction, getMaxBorrowAction, getMaxPaybackAction,
} from './compoundManageMaxGetters';
import { callRecipeViaProxy } from '../../services/contractCallService';
import { isLayer2Network } from '../../services/ethService';
import { addApproveForActionWithSelectIfNeeded, getDashboardInputs } from '../dashboardActions';
import * as compoundRecipes from '../../recipes/compoundRecipes';

// DATA GETTERS START
/**
 * Gets assets prices
 * @return {function(...[*]=)}
 */
export const fetchCompoundAssetsData = () => async (dispatch, getState) => {
  dispatch({ type: compManageTypes.FETCH_COMPOUND_ASSETS_DATA_REQUEST });

  try {
    await dispatch(getAssetsPricesAction(compoundCollateralAssets.map(a => a.underlyingAsset), 'compound'));
    await dispatch(getAssetsBalancesAction(compoundCollateralAssets.map(a => a.underlyingAsset)));

    const marketsData = await getMarketsData(compoundCollateralAssets.map(a => a.address));
    const payload = {};
    // Sort by market size
    marketsData
      .sort((a, b) => {
        const { assets } = getState();

        const aMarket = new Dec(assets[handleWbtcLegacy(a.symbol)].compoundPrice).times(a.totalSupply).toString();
        const bMarket = new Dec(assets[handleWbtcLegacy(b.symbol)].compoundPrice).times(b.totalSupply).toString();

        return new Dec(bMarket).minus(aMarket).toNumber();
      })
      .forEach((market, i) => { payload[market.symbol] = { ...market, sortIndex: i }; });

    dispatch({ type: compManageTypes.FETCH_COMPOUND_ASSETS_DATA_SUCCESS, payload });
  } catch (err) {
    dispatch({ type: compManageTypes.FETCH_COMPOUND_ASSETS_DATA_FAILURE, payload: err.message });
    captureException(err);
  }
};

/**
 * Fetches data from the Compound API
 * @return {function(...[*]=)}
 */
export const fetchCompoundAccountData = () => async (dispatch, getState) => {
  dispatch({ type: compManageTypes.FETCH_COMPOUND_ACCOUNT_DATA_REQUEST });
  try {
    const address = getAddressForWalletType(getState);
    const { assets, compoundManage: { assetsData }, general: { walletType } } = getState();
    const payload = await getCompoundAccountData(address, assetsData, assets, walletType.value);

    dispatch({ type: compManageTypes.FETCH_COMPOUND_ACCOUNT_DATA_SUCCESS, payload });

    if (payload?.isSubscribedToAutomation) dispatch(getCompoundSaverSubscribedGraphData());
    return payload;
  } catch (err) {
    dispatch({ type: compManageTypes.FETCH_COMPOUND_ACCOUNT_DATA_FAILURE, payload: err.message });
    captureException(err);
  }
};

/**
 * Fetches all data needed for the CompoundManage page
 *
 * @return {Function}
 */
export const fetchCompoundManageData = () => async (dispatch) => {
  dispatch({ type: compManageTypes.FETCH_COMPOUND_MANAGE_DATA_REQUEST });

  try {
    await dispatch(fetchCompoundAssetsData());
    await dispatch(fetchCompoundAccountData());

    dispatch({ type: compManageTypes.FETCH_COMPOUND_MANAGE_DATA_SUCCESS });
  } catch (err) {
    dispatch({ type: compManageTypes.FETCH_COMPOUND_MANAGE_DATA_FAILURE, payload: err.message });
    captureException(err);
  }
};

/**
 * Fetches the boost modal data
 *
 * @param inputAmount
 * @param borrowAsset
 * @param supplyAsset
 * @return {function(...[*]=)}
 */
export const getBoostModalData = (inputAmount, borrowAsset, supplyAsset) => async (dispatch, getState) => {
  dispatch({ type: compManageTypes.GET_BOOST_MODAL_DATA_REQUEST });

  try {
    const { proxyAddress } = getState().maker;
    const { price: exchangeRate, source } = await getBestExchangePrice(inputAmount.toString(), borrowAsset, supplyAsset, proxyAddress);

    const boostAmount = new Dec(exchangeRate).times(inputAmount).toString();

    const marketPrice = await getSlippageThreshold(borrowAsset, supplyAsset);
    const tradeSizeImpact = calculateTradeSizeImpact(marketPrice, exchangeRate);

    await dispatch(getMaxBorrowAction(true));
    const maxBorrow = getState().compoundManage.maxValues.borrow;
    const useFl = new Dec(inputAmount).gt(maxBorrow);
    let flProtocol = 'none';
    let flFee = '0';
    let useAltRecipe = false;
    if (useFl) {
      let flData = await flProtocolAndFeeFor(inputAmount, borrowAsset, getState().general.network);
      if (flData.protocol === 'none') {
        useAltRecipe = true;
        flData = { protocol: 'maker', flFee: '0' };
      }
      flProtocol = flData.protocol;
      flFee = flData.flFee;
    }
    dispatch({
      type: compManageTypes.GET_BOOST_MODAL_DATA_SUCCESS,
      payload: {
        boostAmount,
        tradeSizeImpact,
        useFl,
        useAltRecipe,
        flProtocol,
        flFee,
        exchangeSource: source,
        boostExchangeRate: exchangeRate,
      },
    });
  } catch (err) {
    dispatch({ type: compManageTypes.GET_BOOST_MODAL_DATA_FAILURE, payload: err.message });
    captureException(err);
  }
};

/**
 * Fetches the repay modal data
 *
 * @param inputAmount
 * @param withdrawAsset
 * @param paybackAsset
 * @return {function(...[*]=)}
 */
export const getRepayModalData = (inputAmount, withdrawAsset, paybackAsset) => async (dispatch, getState) => {
  dispatch({ type: compManageTypes.GET_REPAY_MODAL_DATA_REQUEST });

  try {
    const { proxyAddress } = getState().maker;
    const { price: exchangeRate, source } = await getBestExchangePrice(inputAmount.toString(), withdrawAsset, paybackAsset, proxyAddress);

    const repayAmount = new Dec(inputAmount).times(exchangeRate).toString();

    const marketPrice = await getSlippageThreshold(withdrawAsset, paybackAsset);
    const tradeSizeImpact = calculateTradeSizeImpact(marketPrice, exchangeRate);

    await dispatch(getMaxWithdrawAction(true));
    const maxWithdraw = getState().compoundManage.maxValues.withdraw;
    const useFl = new Dec(inputAmount).gt(maxWithdraw);
    let flProtocol = 'none';
    let flFee = '0';
    let useAltRecipe = false;
    if (useFl) {
      let flData = await flProtocolAndFeeFor(inputAmount, withdrawAsset, getState().general.network);
      if (flData.protocol === 'none') {
        useAltRecipe = true;
        flData = { protocol: 'maker', flFee: '0' };
      }
      flProtocol = flData.protocol;
      flFee = flData.flFee;
    }

    const payload = {
      repayAmount,
      tradeSizeImpact,
      useFl,
      useAltRecipe,
      flProtocol,
      flFee,
      repayExchangeRate: exchangeRate,
      exchangeSource: source,
    };

    dispatch({ type: compManageTypes.GET_REPAY_MODAL_DATA_SUCCESS, payload });
  } catch (err) {
    dispatch({ type: compManageTypes.GET_REPAY_MODAL_DATA_FAILURE, payload: err.message });
    captureException(err);
  }
};

// DATA GETTERS END

// ACTIONS EXECUTORS START

/**
 * Handles redux actions for the compound supply tab withdraw action
 *
 * @param contextAction {Object}
 * @param additionalAction {Object}
 *
 * @return {Function}
 */
export const compoundWithdrawForAccountAction = (contextAction, additionalAction) => async (dispatch, getState) => {
  const {
    general: {
      walletType, account, accountType, path,
    },
    maker: { proxyAddress },
    compoundManage: {
      usedAssets, ratioTooLow, ratioTooHigh,
    },
  } = getState();

  const {
    firstInput: _amount, firstInputSelect: fromAsset,
  } = dispatch(getDashboardInputs(contextAction, additionalAction));

  const asset = fromAsset?.value;
  const supplied = usedAssets[asset].supplied;

  if (ratioTooLow || ratioTooHigh) {
    const confirmed = await dispatch(confirmViaModal(t('errors.automatic_trigger')));
    if (!confirmed) return false;
  }

  trackEvent('compoundManage', 'withdraw');
  dispatch({ type: compManageTypes.COMPOUND_WITHDRAW_REQUEST });


  const sendTxFunc = (promise, waitForSign) => {
    const amount = formatNumber(parseFloat(_amount), 2);
    const additionalInfo = {
      protocol: 'compound',
      firstToken: asset,
      firstAmount: _amount,
    };
    return sendTx(promise, `${t('common.withdraw')}: ${amount} ${asset}`, 'Compound', dispatch, getState, additionalInfo, waitForSign);
  };

  try {
    await withdraw(accountType, path, sendTxFunc, account, proxyAddress, walletType, _amount, asset, supplied);

    dispatch({ type: compManageTypes.COMPOUND_WITHDRAW_SUCCESS });

    dispatch(setAfterValue(0, 'clear'));
    dispatch(change('compoundManageCollateralForm', 'withdrawAmount', null));
    await dispatch(fetchCompoundAssetsData());
    await dispatch(fetchCompoundAccountData());
    dispatch(getMaxWithdrawAction());
    trackEvent('compoundManage', 'withdrawSuccess');
  } catch (err) {
    dispatch({ type: compManageTypes.COMPOUND_WITHDRAW_FAILURE, payload: err.message });
    trackEvent('compoundManage', 'withdrawError', err.message);
    captureException(err);
  }
};

/**
 * Handles redux actions for the compound supply tab supply action
 *
 * @param contextAction {Object}
 * @param additionalAction {Object}
 *
 * @return {Function}
 */
export const compoundSupplyForAccountAction = (contextAction, additionalAction) => async (dispatch, getState) => {
  const {
    general: {
      walletType, account, accountType, path,
    },
    maker: { proxyAddress },
    compoundManage: {
      usedAssets, ratioTooLow, ratioTooHigh,
    },
  } = getState();

  const {
    firstInput: _amount, firstInputSelect: fromAsset,
  } = dispatch(getDashboardInputs(contextAction, additionalAction));

  const asset = fromAsset?.value;
  const cAsset = fromAsset?.symbol;

  if (ratioTooLow || ratioTooHigh) {
    const confirmed = await dispatch(confirmViaModal(t('errors.automatic_trigger')));
    if (!confirmed) return false;
  }

  trackEvent('compoundManage', 'supply');
  dispatch({ type: compManageTypes.COMPOUND_SUPPLY_REQUEST });


  const sendTxFunc = (promise, waitForSign) => {
    const amount = formatNumber(parseFloat(_amount), 2);
    const additionalInfo = {
      protocol: 'compound',
      firstToken: asset,
      firstAmount: _amount,
    };

    return sendTx(promise, `${t('common.supply')}: ${amount} ${asset}`, 'Compound', dispatch, getState, additionalInfo, waitForSign);
  };

  try {
    if (usedAssets[asset]?.isBorrowed && !usedAssets[asset]?.isSupplied) {
      const confirmed = await dispatch(confirmViaModal(t('compound.warning_supplying_borrowed')));
      if (!confirmed) throw new Error(t('errors.denied_transaction'));
    }

    const cAssetInfo = getAssetInfo(cAsset);
    const isProxy = isWalletTypeProxy(walletType);
    const alreadyInMarket = usedAssets[asset]?.collateral;
    const txToExecute = [];

    const approveFunctionObject = {
      func: () => dispatch(approveAddressOnAssetAction(asset, isProxy ? proxyAddress : cAssetInfo.address, isProxy ? APPROVE_TYPE_DS_PROXY : APPROVE_TYPE_COMPOUND)),
      type: 'approve',
      notifMessage: `${t('common.approve')} ${approveTypeToLabelMap[isProxy ? APPROVE_TYPE_DS_PROXY : APPROVE_TYPE_COMPOUND]} ${t('common.for')} ${asset}`,
    };

    const supplyFunctionObject = {
      func: () => supply(accountType, path, sendTxFunc, account, proxyAddress, walletType, _amount, asset, alreadyInMarket),
      type: 'supply',
      notifMessage: `${t('common.supply')}: ${formatNumber(parseFloat(_amount), 2)} ${asset}`,
    };

    await dispatch(addApproveTxIfNeeded(asset, isProxy ? proxyAddress : cAssetInfo.address, _amount, isProxy ? APPROVE_TYPE_DS_PROXY : APPROVE_TYPE_COMPOUND, txToExecute, approveFunctionObject));
    txToExecute.push(supplyFunctionObject);
    await dispatch(callMultipleTxAction(txToExecute));

    dispatch({ type: compManageTypes.COMPOUND_SUPPLY_SUCCESS });

    dispatch(setAfterValue(0, 'clear'));
    dispatch(change('compoundManageCollateralForm', 'supplyAmount', null));
    await dispatch(fetchCompoundAssetsData());
    await dispatch(fetchCompoundAccountData());
    dispatch(getMaxSupplyAction());
    trackEvent('compoundManage', 'supplySuccess');
  } catch (err) {
    dispatch({ type: compManageTypes.COMPOUND_SUPPLY_FAILURE, payload: err.message });
    trackEvent('compoundManage', 'supplyError', err.message);
    captureException(err);
  }
};

/**
 * Handles redux actions for the compound borrow tab borrow action
 *
 * @param contextAction {Object}
 * @param additionalAction {Object}
 *
 * @return {Function}
 */
export const compoundBorrowForAccountAction = (contextAction, additionalAction) => async (dispatch, getState) => {
  const {
    general: {
      walletType, account, accountType, path,
    },
    maker: { proxyAddress },
    compoundManage: {
      usedAssets, ratioTooLow, ratioTooHigh,
    },
  } = getState();

  const {
    firstInput: _amount, firstInputSelect: fromAsset,
  } = dispatch(getDashboardInputs(contextAction, additionalAction));

  const asset = fromAsset?.value;

  if (ratioTooLow || ratioTooHigh) {
    const confirmed = await dispatch(confirmViaModal(t('errors.automatic_trigger')));
    if (!confirmed) return false;
  }
  trackEvent('compoundManage', 'borrow');
  dispatch({ type: compManageTypes.COMPOUND_BORROW_REQUEST });

  const sendTxFunc = (promise, waitForSign) => {
    const amount = formatNumber(parseFloat(_amount), 2);
    const additionalInfo = {
      protocol: 'compound',
      firstToken: asset,
      firstAmount: _amount,
    };
    return sendTx(promise, `${t('common.borrow')}: ${amount} ${asset}`, 'Compound', dispatch, getState, additionalInfo, waitForSign);
  };

  try {
    if (usedAssets[asset]?.isSupplied && !usedAssets[asset]?.isBorrowed) {
      const confirmed = await dispatch(confirmViaModal(t('compound.warning_borrowing_supplied')));
      if (!confirmed) throw new Error(t('errors.denied_transaction'));
    }

    const alreadyInMarket = usedAssets[asset]?.collateral;

    await borrow(accountType, path, sendTxFunc, account, proxyAddress, walletType, _amount, asset, alreadyInMarket);

    dispatch({ type: compManageTypes.COMPOUND_BORROW_SUCCESS });
    dispatch(setAfterValue(0, 'clear'));

    dispatch(change('compoundManageDebtForm', 'borrowAmount', null));
    await dispatch(fetchCompoundAssetsData());
    await dispatch(fetchCompoundAccountData());
    dispatch(getMaxBorrowAction());
    trackEvent('compoundManage', 'borrowSuccess');
  } catch (err) {
    dispatch({ type: compManageTypes.COMPOUND_BORROW_FAILURE, payload: err.message });
    trackEvent('compoundManage', 'borrowError', err.message);
    captureException(err);
  }
};

/**
 * Handles redux actions for the compound borrow tab payback action
 *
 * @param contextAction {Object}
 * @param additionalAction {Object}
 *
 * @return {Function}
 */
export const compoundPaybackForAccountAction = (contextAction, additionalAction) => async (dispatch, getState) => {
  const {
    general: {
      walletType, account, accountType, path,
    },
    maker: { proxyAddress },
    compoundManage: {
      usedAssets, ratioTooLow, ratioTooHigh,
    },
  } = getState();

  const {
    firstInput: _amount, firstInputSelect: fromAsset,
  } = dispatch(getDashboardInputs(contextAction, additionalAction));

  const asset = fromAsset?.value;
  const { borrowed } = usedAssets[asset];
  const cAsset = fromAsset?.symbol;

  if (ratioTooLow || ratioTooHigh) {
    const confirmed = await dispatch(confirmViaModal(t('errors.automatic_trigger')));
    if (!confirmed) return false;
  }
  trackEvent('compoundManage', 'payback');
  dispatch({ type: compManageTypes.COMPOUND_PAYBACK_REQUEST });

  const sendTxFunc = (promise, waitForSign) => {
    const amount = formatNumber(parseFloat(_amount), 2);
    const additionalInfo = {
      protocol: 'compound',
      firstToken: asset,
      firstAmount: _amount,
    };
    return sendTx(promise, `${t('common.payback')}: ${amount} ${asset}`, 'Compound', dispatch, getState, additionalInfo, waitForSign);
  };

  try {
    const isProxy = isWalletTypeProxy(walletType);
    const cAssetInfo = getAssetInfo(cAsset);
    const txToExecute = [];

    const approveFunctionObject = {
      func: () => dispatch(approveAddressOnAssetAction(asset, isProxy ? proxyAddress : cAssetInfo.address, isProxy ? APPROVE_TYPE_DS_PROXY : APPROVE_TYPE_COMPOUND)),
      type: 'approve',
      notifMessage: `${t('common.approve')} ${approveTypeToLabelMap[isProxy ? APPROVE_TYPE_DS_PROXY : APPROVE_TYPE_COMPOUND]} ${t('common.for')} ${asset}`,
    };

    const paybackFunctionObject = {
      func: () => payback(accountType, path, sendTxFunc, account, proxyAddress, walletType, _amount, asset, borrowed),
      type: 'payback',
      notifMessage: `${t('common.payback')}: ${formatNumber(parseFloat(_amount), 2)} ${asset}`,
    };

    await dispatch(addApproveTxIfNeeded(asset, isProxy ? proxyAddress : cAssetInfo.address, _amount, isProxy ? APPROVE_TYPE_DS_PROXY : APPROVE_TYPE_COMPOUND, txToExecute, approveFunctionObject));
    txToExecute.push(paybackFunctionObject);
    await dispatch(callMultipleTxAction(txToExecute));

    dispatch({ type: compManageTypes.COMPOUND_PAYBACK_SUCCESS });

    dispatch(setAfterValue(0, 'clear'));
    dispatch(change('compoundManageDebtForm', 'paybackAmount', null));
    await dispatch(fetchCompoundAssetsData());
    await dispatch(fetchCompoundAccountData());
    dispatch(getMaxPaybackAction());
    trackEvent('compoundManage', 'paybackSuccess');
  } catch (err) {
    dispatch({ type: compManageTypes.COMPOUND_PAYBACK_FAILURE, payload: err.message });
    trackEvent('compoundManage', 'paybackError', err.message);
    captureException(err);
  }
};

// ACTIONS EXECUTORS END

/**
 * Handles redux action for enabling an asset as collateral
 *
 * @param underlyingAsset {String}
 * @return {function(...[*]=)}
 */
export const enableAssetAsCollateralAction = underlyingAsset => async (dispatch, getState) => {
  const sendTxFunc = (promise, waitForSign) => sendTx(promise, t('compound.enable_collateral', { '%asset': underlyingAsset }), 'Compound', dispatch, getState, { protocol: 'compound' }, waitForSign);

  const {
    general: {
      walletType, account, accountType, path,
    },
    maker: { proxyAddress },
  } = getState();

  dispatch({ type: compManageTypes.ENABLE_COMPOUND_ASSET_AS_COLLATERAL_REQUEST, asset: underlyingAsset });

  const assetAddress = findCompoundAssetByUnderlying(underlyingAsset, 'address');

  try {
    await enableAssetAsCollateral(accountType, path, sendTxFunc, account, proxyAddress, walletType, assetAddress);

    dispatch(fetchCompoundManageData());

    dispatch({ type: compManageTypes.ENABLE_COMPOUND_ASSET_AS_COLLATERAL_SUCCESS, asset: underlyingAsset });
  } catch (err) {
    dispatch({ type: compManageTypes.ENABLE_COMPOUND_ASSET_AS_COLLATERAL_FAILURE, payload: err.message, asset: underlyingAsset });
    captureException(err);
  }
};

/**
 * Handles redux action for disabling an asset as collateral
 *
 * @param underlyingAsset {String}
 * @return {function(...[*]=)}
 */
export const disableAssetAsCollateralAction = underlyingAsset => async (dispatch, getState) => {
  const sendTxFunc = (promise, waitForSign) => sendTx(promise, t('compound.disable_collateral', { '%asset': underlyingAsset }), 'Compound', dispatch, getState, { protocol: 'compound' }, waitForSign);

  const {
    general: {
      walletType, account, accountType, path,
    },
    maker: { proxyAddress },
    compoundManage: {
      usedAssets, assetsData, borrowLimitUsd, borrowedUsd,
    },
  } = getState();

  dispatch({ type: compManageTypes.DISABLE_COMPOUND_ASSET_AS_COLLATERAL_REQUEST, asset: underlyingAsset });

  const borrowValuesWithoutColl = calcBorrowValuesWithoutColl(underlyingAsset, usedAssets, assetsData, borrowLimitUsd, borrowedUsd);

  const assetAddress = findCompoundAssetByUnderlying(underlyingAsset, 'address');

  try {
    // If the funds are going to be liquidated after the user disables the
    // passed down asset as collateral, make him confirm it
    if (borrowValuesWithoutColl.borrowPowerUsed > 100 || (borrowValuesWithoutColl.borrowPowerUsed > 0 && +borrowValuesWithoutColl.borrowLimitUsd <= 0)) {
      const result = await dispatch(openCompoundCollateralRequiredModal(borrowValuesWithoutColl));

      if (!result) throw new Error('Rejected');
    }

    await disableAssetAsCollateral(accountType, path, sendTxFunc, account, proxyAddress, walletType, assetAddress);

    dispatch(fetchCompoundManageData());

    dispatch({ type: compManageTypes.DISABLE_COMPOUND_ASSET_AS_COLLATERAL_SUCCESS, asset: underlyingAsset });
  } catch (err) {
    dispatch({ type: compManageTypes.DISABLE_COMPOUND_ASSET_AS_COLLATERAL_FAILURE, payload: err.message, asset: underlyingAsset });
    captureException(err);
  }
};

/**
 * Sets the compound manage page active tab to the reducer
 *
 * @param payload
 * @return {Function}
 */
export const setCompoundManageActiveTab = payload => (dispatch) => {
  dispatch({ type: compManageTypes.COMPOUND_MANAGE_SET_ACTIVE_TAB, payload });
};

/**
 * Sets the values of the actions select inputs to the reducer
 *
 * @param selectValue {Object}
 * @param key {String}
 * @return {Function}
 */
export const setCompoundActionSelectValues = (selectValue, key) => (dispatch) => {
  dispatch({ type: compManageTypes.COMPOUND_MANAGE_SET_ACTION_SELECT_VALUES, payload: { selectValue, key } });
};

/**
 * If the criteria is fulfilled reloads the CompoundManage page of wallet type change
 *
 * @return {function(...[*]=)}
 */
export const onWalletTypeChange = () => (dispatch) => dispatch(fetchCompoundAccountData());

/**
 * Switch to DS_Proxy compound
 * @returns {function(...[*]=)}
 */
export const switchToUserWallet = () => (dispatch) => {
  dispatch(changeWalletType(WALLET_TYPES_OPTIONS[1]));
  return dispatch(onWalletTypeChange());
};

/**
 * Switch to DS_Proxy compound
 * @returns {function(...[*]=)}
 */
export const switchToAccount = () => (dispatch) => {
  dispatch(changeWalletType(WALLET_TYPES_OPTIONS[0]));
  dispatch(onWalletTypeChange());
};

export const getCompBalanceAction = () => async (dispatch, getState) => {
  dispatch({ type: compManageTypes.COMP_BALANCE_REQUEST });
  try {
    const { account } = getState().general;
    const { proxyAddress } = getState().maker;
    const balances = await Promise.all([
      getCompBalance(account),
      getCompBalance(proxyAddress),
      getAssetBalance('COMP', account),
    ]);

    const balanceThreshold = '0.000001';

    const accountBalance = new Dec(balances[0]).minus(balances[2]).lessThan(balanceThreshold)
      ? '0'
      : new Dec(balances[0]).minus(balances[2]).toString();
    const smartWalletBalance = new Dec(balances[1]).lessThan(balanceThreshold)
      ? '0'
      : balances[1].toString();
    const alreadyOnAccount = balances[2].toString();

    dispatch({ type: compManageTypes.COMP_BALANCE_SUCCESS, payload: { account: accountBalance, smartWallet: smartWalletBalance, alreadyOnAccount } });
    return { account: accountBalance, smartWallet: smartWalletBalance, alreadyOnAccount };
  } catch (err) {
    dispatch({ type: compManageTypes.COMP_BALANCE_FAILURE, payload: err.message });
    captureException(err);
  }
};

export const claimCompBalanceAction = (claimFromAccount, claimAndSellParams, callback) => async (dispatch, getState) => {
  try {
    const {
      general: { account, accountType, path },
      maker: { proxyAddress },
      compoundManage: { assetsData, compBalance },
      assets,
    } = getState();

    const additionalInfo = {
      protocol: 'compound',
      firstToken: 'COMP',
      firstAmount: claimFromAccount ? compBalance.account : compBalance.smartWallet,
    };

    if (!claimFromAccount && claimAndSellParams && Object.keys(claimAndSellParams).length) {
      const {
        supply, swap, toAsset, amount, price, slippage, assetAmount,
      } = claimAndSellParams;

      const { usedAssets } = await getCompoundAccountData(proxyAddress, assetsData, assets, 'proxy');
      additionalInfo.secondToken = toAsset;
      additionalInfo.secondAmount = assetAmount;
      const sendTxFunc = promise => sendTx(promise, `${t('common.withdrawing')} COMP`, 'Compound', dispatch, getState, additionalInfo);

      await claimAndSellCompBalance({
        supply, swap, proxyAddress, usedAssets, toAsset, slippage, price, amount, account, accountType, sendTxFunc,
      });
      dispatch(fetchCompoundManageData());
      let action = 'claim';
      if (supply) action = 'claim and supply';
      if (swap) action = 'claim and swap';
      if (swap && supply) action = 'claim, swap and supply';
      trackEvent('CompLeverage', action);
    } else {
      const { usedAssets } = await getCompoundAccountData(
        claimFromAccount ? account : proxyAddress, assetsData, assets, claimFromAccount ? 'account' : 'proxy',
      );
      const sendTxFunc = promise => sendTx(promise, `${t('common.withdrawing')} COMP`, 'Compound', dispatch, getState, additionalInfo);
      await claimCompBalance(accountType, path, sendTxFunc, account, proxyAddress, claimFromAccount, usedAssets);
    }
    dispatch(getCompBalanceAction());
  } catch (err) {
    captureException(err);
  } finally {
    callback();
  }
};

export const getCompoundProxyData = (proxyAddress, assetsData, assets, walletType) => async (dispatch) => {
  if (!proxyAddress) return;

  dispatch({ type: compManageTypes.FETCH_COMPOUND_PROXY_DATA_REQUEST });
  try {
    const payload = await getCompoundAccountData(proxyAddress, assetsData, assets, walletType.value);

    dispatch({ type: compManageTypes.FETCH_COMPOUND_PROXY_DATA_SUCCESS });
    return payload;
  } catch (err) {
    dispatch({ type: compManageTypes.FETCH_COMPOUND_PROXY_DATA_FAILURE, payload: err.message });
    captureException(err);
  }
};

const postAction = (contextAction, secondAction, firstAction) => async (dispatch) => {
  await dispatch(setAfterValue(0, 'clear'));
  await dispatch(resetFields(formName, { [`${contextAction.value}-${firstAction?.value}`]: '', [`${contextAction.value}-${secondAction?.value}`]: '' }));
  firstAction.getMax();
  secondAction?.getMax?.();

  await dispatch(fetchCompoundAccountData());
  await dispatch(fetchCompoundAssetsData());
  dispatch({ type: compManageTypes.COMP_ACTION_EXEC_SUCCESS, action: contextAction.value });
};

/**
 * Handles redux actions for the generate dai smart contract call
 *
 * @param value {string} action being executed
 * contextAction {Object} where the action is being called from
 * additionalAction {Object} contains value of action to be executed in recipe, eg. 'borrow' but also can be 'collateral' if contextAction is 'borrow'
 * @return {Function}
 */
export const basicCompoundAction = (value) => (contextAction, additionalAction) => async (dispatch, getState) => {
  try {
    const {
      general: { account, accountType },
      maker: { proxyAddress },
    } = getState();

    const {
      firstAction, firstInput, secondAction, secondInput, firstInputSelect: fromAsset, secondInputSelect: secondaryFromAsset,
    } = dispatch(getDashboardInputs(contextAction, additionalAction));

    const { ratioTooLow, ratioTooHigh, usedAssets } = getState().compoundManage;
    if (ratioTooLow || ratioTooHigh) {
      const confirmed = await dispatch(confirmViaModal(t('errors.automatic_trigger')));
      if (!confirmed) return false;
    }

    compoundManageTrackEventWrapper(getState, 'compoundManage', value);
    dispatch({ type: compManageTypes.COMP_ACTION_EXEC_REQUEST, action: contextAction.value });

    let targetAccount = secondAction?.value === 'send' ? secondInput : account;
    if (!isAddress(targetAccount)) targetAccount = await getEnsAddress(targetAccount);

    const firstInputWei = assetAmountInWei(firstInput, fromAsset.value);
    const secondInputWei = secondAction?.value === 'send' ? '' : assetAmountInWei(secondInput, secondaryFromAsset?.value);

    const recipe = await getCompoundRecipe(
      firstAction.value, firstInputWei, secondAction?.value, secondInputWei, fromAsset.address, secondaryFromAsset?.address,
      targetAccount, proxyAddress, usedAssets,
    );

    const amount = formatNumber(parseFloat(firstInput), 2);
    const additionalAmount = formatNumber(parseFloat(secondInput), 2);

    let label = `${firstAction.label}: ${amount} ${fromAsset.value}`;
    if (secondAction && secondAction.value !== 'send') label += ` + ${secondAction.label}: ${additionalAmount} ${secondaryFromAsset.value}`;
    else if (secondAction) label += ` + ${secondAction.label} to ${formatAcc(targetAccount, 5, 3)}`;

    const proxySendHandler = (promise) => {
      const additionalInfo = {
        protocol: 'compound',
        firstToken: fromAsset.value,
        firstAmount: firstInput,
      };
      return sendTx(promise, label, 'compound', dispatch, getState, additionalInfo);
    };

    const txToExecute = [];
    await dispatch(addApproveForActionWithSelectIfNeeded(firstAction, firstInput, fromAsset, proxyAddress, txToExecute));
    if (secondAction) await dispatch(addApproveForActionWithSelectIfNeeded(secondAction, secondInput, secondaryFromAsset, proxyAddress, txToExecute));
    txToExecute.push({
      func: () => callRecipeViaProxy(accountType, proxySendHandler, proxyAddress, account, recipe),
      type: firstAction.value,
      notifMessage: label,
    });
    await dispatch(callMultipleTxAction(txToExecute));

    dispatch(postAction(contextAction, secondAction, firstAction));
    compoundManageTrackEventWrapper(getState, 'compoundManage', `${value}Success`);
  } catch (err) {
    dispatch({ type: compManageTypes.COMP_ACTION_EXEC_FAILURE, action: contextAction.value, payload: err.message });
    compoundManageTrackEventWrapper(getState, 'compoundManage', `${value}Error`, err.message);
    captureException(err);
  }
};

export const borrowForProxyAction = basicCompoundAction('borrow');
export const withdrawForProxyAction = basicCompoundAction('withdraw');
export const addCollateralForProxyAction = basicCompoundAction('collateral');
export const paybackForProxyAction = basicCompoundAction('payback');

export const compoundBoostAction = (contextAction, additionalAction, closeModal) => async (dispatch, getState) => {
  const forAction = 'boost';
  dispatch({ type: compManageTypes.COMP_ACTION_EXEC_REQUEST, action: contextAction.value });

  try {
    const {
      general: { walletType, account, accountType },
      maker: { proxyAddress },
      compoundManage: {
        slippagePercent, boostExchangeRate, useFl, flProtocol, useAltRecipe, ratioTooLow, ratioTooHigh,
      },
    } = getState();

    if (!isWalletTypeProxy(walletType)) {
      dispatch(openCompoundProxyMigrationModal());
      return false;
    }

    compoundManageTrackEventWrapper(getState, 'compoundManage', forAction);

    if (ratioTooLow || ratioTooHigh) {
      const confirmed = await dispatch(confirmViaModal(t('errors.automatic_trigger')));
      if (!confirmed) return false;
    }

    const {
      firstAction, firstInput: inputAmount, secondAction, secondInput, secondInputSelect, firstInputSelect,
      firstInputSecondSelect,
    } = dispatch(getDashboardInputs(contextAction, additionalAction));

    const borrowAsset = firstInputSelect?.value;
    const supplyAsset = firstInputSecondSelect?.value;

    const {
      compoundManage: { boostAmount, usedAssets },
      assets,
    } = getState();

    const amount = formatNumber(parseFloat(inputAmount), 2);
    const additionalAmount = formatNumber(parseFloat(secondInput), 2);

    let label = `${firstAction.label}: ${amount} ${firstInputSelect.value}`;
    if (secondAction && secondAction.value !== 'send') label += ` + ${secondAction.label}: ${additionalAmount} ${secondInputSelect.value}`;

    const sendTxFunc = (promise) => {
      const additionalInfo = {
        protocol: 'compound',
        firstToken: borrowAsset,
        firstAmount: inputAmount,
        secondToken: supplyAsset,
        secondAmount: boostAmount,
      };
      return sendTx(promise, label, 'compound', dispatch, getState, additionalInfo);
    };
    const additionalActions = secondAction
      ? await getAction(secondAction.value, assetAmountInWei(secondInput, secondInputSelect?.value), secondInputSelect.address, account, proxyAddress, usedAssets)
      : [];

    const inputAmountInDai = new Dec(inputAmount).mul(assets[handleWbtcLegacy(borrowAsset)].compoundPrice).div(assets.DAI.compoundPrice).toString();

    let recipe;
    if (!useFl) recipe = await compoundRecipes.boost(supplyAsset, borrowAsset, inputAmount, boostExchangeRate, slippagePercent, proxyAddress, additionalActions);
    else {
      recipe = !useAltRecipe
        ? await compoundRecipes.boostWithDebtLoan(supplyAsset, borrowAsset, inputAmount, boostExchangeRate, slippagePercent, proxyAddress, flProtocol, additionalActions)
        : await compoundRecipes.boostWithCollLoan(supplyAsset, borrowAsset, inputAmount, boostExchangeRate, slippagePercent, proxyAddress, inputAmountInDai, additionalActions);
    }

    const txToExecute = [];
    await dispatch(addApproveForActionWithSelectIfNeeded(firstAction, inputAmount, firstInputSelect, proxyAddress, txToExecute));
    if (secondAction) await dispatch(addApproveForActionWithSelectIfNeeded(secondAction, secondInput, secondInputSelect, proxyAddress, txToExecute));
    txToExecute.push({
      func: () => callRecipeViaProxy(accountType, sendTxFunc, proxyAddress, account, recipe, 0, recipe.extraGas),
      notifMessage: label,
    });
    await dispatch(callMultipleTxAction(txToExecute));

    dispatch(postAction(contextAction, secondAction, firstAction));
    closeModal();

    compoundManageTrackEventWrapper(getState, 'compoundManage', `${forAction}Success`);
  } catch (err) {
    dispatch({ type: compManageTypes.COMP_ACTION_EXEC_FAILURE, action: contextAction.value, payload: err.message });
    compoundManageTrackEventWrapper(getState, 'compoundManage', `${forAction}Error`, err.message);
    captureException(err);
  }
};

export const compoundRepayAction = (contextAction, additionalAction, closeModal) => async (dispatch, getState) => {
  const forAction = 'repay';
  dispatch({ type: compManageTypes.COMP_ACTION_EXEC_REQUEST, action: contextAction.value });

  try {
    const {
      general: { walletType, account, accountType },
      maker: { proxyAddress },
      compoundManage: {
        slippagePercent, repayExchangeRate, useFl, flProtocol, maxWithdraw, useAltRecipe, ratioTooLow, ratioTooHigh,
      },
    } = getState();
    if (!isWalletTypeProxy(walletType)) {
      dispatch(openCompoundProxyMigrationModal());
      return false;
    }

    compoundManageTrackEventWrapper(getState, 'compoundManage', forAction);

    if (ratioTooLow || ratioTooHigh) {
      const confirmed = await dispatch(confirmViaModal(t('errors.automatic_trigger')));
      if (!confirmed) return false;
    }

    const {
      firstAction, firstInput: inputAmount, secondAction, secondInput, firstInputSelect, firstInputSecondSelect, secondInputSelect,
    } = dispatch(getDashboardInputs(contextAction, additionalAction));

    const {
      compoundManage: { repayAmount, usedAssets },
      assets,
    } = getState();

    const withdrawAsset = firstInputSelect.value;
    const paybackAsset = firstInputSecondSelect.value;

    const amount = formatNumber(parseFloat(inputAmount), 2);
    const additionalAmount = formatNumber(parseFloat(secondInput), 2);

    let label = `${firstAction.label}: ${amount} ${firstInputSelect.value}`;
    if (secondAction && secondAction.value !== 'send') label += ` + ${secondAction.label}: ${additionalAmount} ${secondInputSelect.value}`;

    const sendTxFunc = (promise) => {
      const additionalInfo = {
        protocol: 'compound',
        firstToken: withdrawAsset,
        firstAmount: inputAmount,
        secondToken: paybackAsset,
        secondAmount: repayAmount,
      };

      return sendTx(promise, label, 'compound', dispatch, getState, additionalInfo);
    };

    const additionalActions = secondAction
      ? await getAction(secondAction.value, assetAmountInWei(secondInput, secondInputSelect?.value), secondInputSelect.address, account, proxyAddress, usedAssets)
      : [];

    const inputAmountInDai = new Dec(inputAmount).mul(assets[handleWbtcLegacy(withdrawAsset)].compoundPrice).div(assets.DAI.compoundPrice).toString();
    const borrowed = usedAssets[paybackAsset].borrowed;
    const supplied = usedAssets[withdrawAsset].supplied;
    let recipe;
    if (!useFl) recipe = await compoundRecipes.repay(withdrawAsset, paybackAsset, inputAmount, repayExchangeRate, slippagePercent, proxyAddress, account, supplied, borrowed, additionalActions);
    else {
      const ethIsColl = usedAssets.ETH?.collateral;
      recipe = !useAltRecipe
        ? await compoundRecipes.repayWithCollLoan(withdrawAsset, paybackAsset, inputAmount, repayExchangeRate, slippagePercent, proxyAddress, account, flProtocol, borrowed, maxWithdraw, additionalActions)
        : await compoundRecipes.repayWithDebtLoan(withdrawAsset, paybackAsset, inputAmount, repayExchangeRate, slippagePercent, proxyAddress, account, inputAmountInDai, borrowed, ethIsColl, additionalActions);
    }

    const txToExecute = [];
    await dispatch(addApproveForActionWithSelectIfNeeded(firstAction, inputAmount, firstInputSelect, proxyAddress, txToExecute));
    if (secondAction) await dispatch(addApproveForActionWithSelectIfNeeded(secondAction, secondInput, secondInputSelect, proxyAddress, txToExecute));
    txToExecute.push({
      func: () => callRecipeViaProxy(accountType, sendTxFunc, proxyAddress, account, recipe, 0, recipe.extraGas),
      notifMessage: label,
    });
    await dispatch(callMultipleTxAction(txToExecute));

    dispatch(postAction(contextAction, secondAction, firstAction));
    closeModal();

    compoundManageTrackEventWrapper(getState, 'compoundManage', `${forAction}Success`);
  } catch (err) {
    dispatch({ type: compManageTypes.COMP_ACTION_EXEC_FAILURE, action: contextAction.value, payload: err.message });
    compoundManageTrackEventWrapper(getState, 'compoundManage', `${forAction}Error`, err.message);
    captureException(err);
  }
};

export const changeDashboardAction = (payload, primary = true) => async (dispatch) => {
  await dispatch({
    type: primary ? compManageTypes.COMP_SET_DASHBOARD_ACTION : compManageTypes.COMP_SET_ADDITIONAL_DASHBOARD_ACTION,
    payload,
  });
  dispatch(setAfterValue(0, 'reset'));
};
