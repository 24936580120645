import Dec from 'decimal.js';
import cloneDeep from 'lodash/cloneDeep';
import { getBestExchangePrice } from 'services/exchangeServiceV3';
import { resetFields } from 'services/utils';
// import { getAssetBalance } from 'services/assetsService';
import { captureException } from '../../sentry';
import { changeBalance } from '../../services/recipeCreator/recipeActionUtils';
import {
  LQTY_AFTER_VALUES_REQUEST,
  LQTY_AFTER_VALUES_SUCCESS,
  LQTY_AFTER_VALUES_FAILURE,
} from '../../actionTypes/liquityActionTypes/liquityManageActionTypes';
import { getExpectedFee } from '../../services/liquityServices/liquityService';
import { ethToWei, weiToEth } from '../../services/ethService';
import { LIQUITY_LIQ_RESERVE_LUSD } from '../../constants/liquity';
import { getBoostAmount, getRepayAmount } from '../../services/vaultCommonService';

export const getAggregatedPositionData = (vault) => {
  const {
    liqPercent, collateral, assetPrice, debtInAsset, debtAssetPrice, minDebt,
  } = vault;

  const debtUsd = new Dec(debtInAsset).mul(debtAssetPrice).toString();
  const collateralUsd = new Dec(collateral).mul(assetPrice).toString();

  const liquidationPrice = new Dec(debtUsd).times(liqPercent / 100).div(collateral).toNumber();

  const ratio = new Dec(collateralUsd)
    .div(debtUsd)
    .times(100)
    .toDecimalPlaces(2, Dec.ROUND_HALF_EVEN)
    .toNumber();

  const debtTooLow = vault.troveStatus === 'active' ? new Dec(debtInAsset).lt(minDebt) : false;

  return {
    ...vault,
    liquidationPrice,
    ratio,
    debtUsd,
    collateralUsd,
    debtTooLow,
    disabledBecauseDusty: debtTooLow, // TODO rename disabledBecauseDusty
  };
};

const fullPosition = (getAfterValues) => async (input, stateData, balances = {}) => {
  const result = await getAfterValues.apply(this, [input, stateData, balances]);
  return {
    balances: result.balances,
    returnValue: result.returnValue,
    afterPosition: getAggregatedPositionData(result.vault),
  };
};

export const generateAfterValues = fullPosition(async ({ amount, to = 'wallet' }, {
  vault: _vault, assets, account, proxyAddress,
}, _balances = {}) => {
  const vault = { ..._vault };
  const balances = cloneDeep(_balances);
  const _amount = amount || '0';
  vault.debtInAsset = new Dec(vault.debtInAsset).plus(_amount).toString();
  const expectedFee = weiToEth(await getExpectedFee(ethToWei(_amount)));
  vault.debtInAsset = new Dec(vault.debtInAsset).plus(expectedFee).toString();
  await changeBalance(balances, to, vault.debtAsset, _amount, to === 'wallet' ? account : proxyAddress);
  vault.disabledBecauseClosed = vault.troveStatus !== 'active';
  return { vault, balances, returnValue: _amount };
});

export const paybackAfterValues = fullPosition(async ({ amount, from = 'wallet' }, {
  vault: _vault, assets, account, proxyAddress,
}, _balances = {}) => {
  const vault = { ..._vault };
  const balances = cloneDeep(_balances);
  const _amount = amount || '0';
  vault.debtInAsset = Dec.max(0, new Dec(vault.debtInAsset).minus(_amount)).toString();
  await changeBalance(balances, from, vault.debtAsset, new Dec(_amount).times(-1), from === 'wallet' ? account : proxyAddress);
  vault.disabledBecauseClosed = vault.troveStatus !== 'active';
  return { vault, balances, returnValue: _amount };
});

export const addCollateralAfterValues = fullPosition(async ({ amount, from = 'wallet' }, {
  vault: _vault, assets, account, proxyAddress,
}, _balances = {}) => {
  const vault = { ..._vault };
  const balances = cloneDeep(_balances);
  const _amount = amount || '0';
  vault.collateral = new Dec(vault.collateral).plus(_amount).toString();
  vault.collateralUsd = new Dec(vault.collateral).mul(vault.assetPrice).toString();
  await changeBalance(balances, from, vault.asset.replace(/^ETH/, 'WETH'), new Dec(_amount).times(-1), from === 'wallet' ? account : proxyAddress);
  vault.disabledBecauseClosed = vault.troveStatus !== 'active';
  return { vault, balances, returnValue: _amount };
});

export const withdrawAfterValues = fullPosition(async ({ amount, to = 'wallet' }, {
  vault: _vault, assets, account, proxyAddress,
}, _balances = {}) => {
  const vault = { ..._vault };
  const balances = cloneDeep(_balances);
  const _amount = amount || '0';
  vault.collateral = new Dec(vault.collateral).minus(_amount).toString();
  await changeBalance(balances, to, vault.asset.replace(/^ETH/, 'WETH'), _amount, to === 'wallet' ? account : proxyAddress);
  vault.disabledBecauseClosed = vault.troveStatus !== 'active';
  return { vault, balances, returnValue: _amount };
});

export const closeAfterValues = fullPosition(async ({ to = 'wallet', from = 'wallet' }, {
  vault: _vault, assets, account, proxyAddress,
}, _balances = {}) => {
  const vault = { ..._vault };
  const balances = cloneDeep(_balances);
  const _amount = vault.collateral;
  await changeBalance(balances, to, 'WETH', vault.collateral, to === 'wallet' ? account : proxyAddress);
  await changeBalance(balances, from, 'LUSD', new Dec(vault.debtInAsset).mul('-1').toString(), from === 'wallet' ? account : proxyAddress);
  vault.collateral = '0';
  vault.debtInAsset = '0';
  vault.disabledBecauseClosed = vault.troveStatus !== 'active';
  vault.troveStatus = 'closedByOwner';
  return { vault, balances, returnValue: _amount };
});

export const openAfterValues = fullPosition(async ({
  collAmount, debtAmount, from = 'wallet', to = 'wallet',
}, {
  vault: _vault, assets, account, proxyAddress,
}, _balances = {}) => {
  const vault = { ..._vault };
  const balances = cloneDeep(_balances);
  await changeBalance(balances, from, 'WETH', new Dec(collAmount).mul(-1).toString(), from === 'wallet' ? account : proxyAddress);
  await changeBalance(balances, to, 'LUSD', debtAmount, to === 'wallet' ? account : proxyAddress);
  vault.collateral = collAmount;
  const _debtAmount = debtAmount || '0';
  const expectedFee = weiToEth(await getExpectedFee(ethToWei(_debtAmount)));
  vault.debtInAsset = new Dec(_debtAmount).plus(expectedFee).plus(LIQUITY_LIQ_RESERVE_LUSD).toString();
  vault.alreadyOpened = vault.troveStatus === 'active';
  vault.troveStatus = 'active';
  return { vault, balances, returnValue: '' };
});

export const boostAfterValues = fullPosition(async ({ amount }, { proxyAddress, vault: _vault }, _balances = {}) => {
  const vault = { ..._vault };
  const balances = cloneDeep(_balances);
  const { price: rate } = await getBestExchangePrice(amount.toString(), vault.debtAsset, vault.asset, proxyAddress, true, true, true);
  const _amount = amount || '0';
  vault.debtInAsset = new Dec(vault.debtInAsset).plus(_amount).toString();
  const expectedFee = weiToEth(await getExpectedFee(ethToWei(_amount)));
  vault.debtInAsset = new Dec(vault.debtInAsset).plus(expectedFee).toString();
  vault.collateral = new Dec(vault.collateral).plus(new Dec(amount).times(rate)).toString();
  return { vault, balances, returnValue: amount };
});

export const repayAfterValues = fullPosition(async ({ amount }, { proxyAddress, vault: _vault }, _balances = {}) => {
  const vault = { ..._vault };
  const balances = cloneDeep(_balances);
  const { price: rate } = await getBestExchangePrice(amount.toString(), vault.asset, vault.debtAsset, proxyAddress, true, true, true);
  vault.collateral = new Dec(vault.collateral).minus(amount).toString();
  vault.debtInAsset = new Dec(vault.debtInAsset).minus(new Dec(amount).times(rate)).toString();
  if (new Dec(vault.debtInAsset).lt(0)) vault.debtInAsset = '0';
  return { vault, balances, returnValue: amount };
});

/**
 * Calculates the changed vault value
 *
 * @param amount {String}
 * @param type {String}
 *
 * @return {Function}
 */
export const setAfterValue = (amount, type) => async (dispatch, getState) => {
  if (type === 'clear' || !amount || amount === '0') {
    dispatch(resetFields('liquityManageAdvancedForm', { boostAmount: '', repayAmount: '' }));
    dispatch(resetFields('liquityManageDebtForm', { generateAmount: '', paybackAmount: '' }));
    dispatch(resetFields('liquityManageCollateralForm', { addCollateralAmount: '', withdrawAmount: '' }));

    return dispatch({ type: LQTY_AFTER_VALUES_SUCCESS, payload: { afterTrove: null, afterType: '' } });
  }

  dispatch({ type: LQTY_AFTER_VALUES_REQUEST });

  try {
    const {
      maker: { proxyAddress },
      liquity,
      general: { account, walletType },
      assets,
    } = getState();
    const trove = liquity[walletType.value];

    const graphData = {}; // for when automation exists
    const minRatio = graphData?.minRatio || 0;
    const maxRatio = graphData?.maxRatio || Infinity;
    const boostEnabled = graphData?.boostEnabled || false;

    const payload = {
      afterType: type,
    };

    if (type === 'boost') {
      payload.afterTrove = (await boostAfterValues({ amount }, { vault: trove, proxyAddress })).afterPosition;
      dispatch(resetFields('liquityManageAdvancedForm', { repayAmount: '' }));
    }
    if (type === 'repay') {
      payload.afterTrove = (await repayAfterValues({ amount }, { vault: trove, proxyAddress })).afterPosition;
      dispatch(resetFields('liquityManageAdvancedForm', { boostAmount: '' }));
    }

    if (type === 'generate') {
      payload.afterTrove = (await generateAfterValues({ amount }, { vault: trove, account, assets })).afterPosition;
      dispatch(resetFields('liquityManageDebtForm', { paybackAmount: '' }));
    }
    if (type === 'payback') {
      payload.afterTrove = (await paybackAfterValues({ amount }, { vault: trove, account, assets })).afterPosition;
      dispatch(resetFields('liquityManageDebtForm', { generateAmount: '' }));
    }

    if (type === 'withdraw') {
      payload.afterTrove = (await withdrawAfterValues({ amount }, { vault: trove, account, assets })).afterPosition;
      dispatch(resetFields('liquityManageCollateralForm', { addCollateralAmount: '' }));
    }
    if (type === 'collateral') {
      payload.afterTrove = (await addCollateralAfterValues({ amount }, { vault: trove, account, assets })).afterPosition;
      dispatch(resetFields('liquityManageCollateralForm', { withdrawAmount: '' }));
    }

    if (payload.afterTrove?.ratio) {
      payload.ratioTooLow = parseFloat(payload.afterTrove.ratio) < minRatio;
      payload.ratioTooHigh = boostEnabled && parseFloat(payload.afterTrove.ratio) > maxRatio;
    }

    if (payload.afterTrove?.ratio && trove.isSubscribedToAutomation) {
      payload.afterTrove.disabledBecauseTooLowForRepay = parseFloat(payload.afterTrove.ratio) < ((trove.liqPercent) + 20);
    }

    dispatch({ type: LQTY_AFTER_VALUES_SUCCESS, payload });
  } catch (err) {
    dispatch({ type: LQTY_AFTER_VALUES_FAILURE, payload: err.message });
    captureException(err);
  }
};
