import t from 'translate';
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import ModalBody from '../ModalBody';
import ModalHeader from '../ModalHeader';
import './AutomationUnsubNextStepsModal.scss';

import close from './close.png';
import monitor from './monitor.png';
import shift from './shift.png';
import headerBg from './header.png';

const AutomationUnsubNextStepsModal = ({ strategyName, protocol, closeModal }) => {
  const dispatch = useDispatch();
  return (
    <div className="automation-unsub-next-steps-modal-wrapper">
      <ModalHeader closeModal={closeModal} />

      <ModalBody>
        <div className="new-modal-top-wrapper" style={{ backgroundImage: `url(${headerBg})`, backgroundSize: 'contain' }}>
          <h1>What&apos;s next?</h1>
        </div>
        <div className="new-modal-content-wrapper">
          {/* <p>&quot;{strategyName}&quot; is now being disabled.</p> */}

          <div className="next-step-wrapper">
            <div className="image-wrapper"><img src={monitor} alt="illustration" /></div>
            <div>
              <h2>Keep your position safe</h2>
              <p>You can always manually repay your position to keep it from liquidation. Additionally, other Automation strategies might work better for your position - you can always check them out in the <Link to={`/${protocol}/strategies`} onClick={() => closeModal()}>Automation tab</Link>.</p>
            </div>
          </div>

          {(protocol === 'makerdao' || protocol === 'liquity') && (
            <div className="next-step-wrapper">
              <div className="image-wrapper"><img src={close} alt="illustration" /></div>
              <div>
                <h2>Closing your position?</h2>
                <p>You can use the Close option (top right on the dashboard) to fully repay all debt and withdraw remaining collateral.</p>
              </div>
            </div>
          )}

          <div className="next-step-wrapper">
            <div className="image-wrapper"><img src={shift} alt="illustration" /></div>
            <div>
              <h2>Switching assets or protocols?</h2>
              <p>The <Link to="/shifter" onClick={() => closeModal()}>Loan Shifter</Link> lets you make collateral or debt swaps, as well as move your whole position to a different protocol without having to unwind.</p>
            </div>
          </div>
        </div>
      </ModalBody>

      <div className="modal-controls">
        <button type="button" className="button gray" onClick={() => dispatch(closeModal())}>{t('common.close')}</button>
      </div>
    </div>
  );
};

AutomationUnsubNextStepsModal.defaultProps = {};

AutomationUnsubNextStepsModal.propTypes = {
  strategyName: PropTypes.string.isRequired,
  protocol: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default AutomationUnsubNextStepsModal;
