import cloneDeep from 'lodash/cloneDeep';
import Dec from 'decimal.js';
import memoize from 'memoizee';
import { getAssetInfo } from '@defisaver/tokens';
import { changeBalance } from '../../services/recipeCreator/recipeActionUtils';
import {
  getLPLiquidityMintedEstimate,
  getLPLiquidityValueEstimate,
  getUniswapPrice,
} from '../../services/uniswapServices';
import { compareAddresses } from '../../services/utils';

const getLPLiquidityMintedEstimateCached = memoize(getLPLiquidityMintedEstimate, { promise: true, maxAge: 2 * 60 * 1000, max: 1 });
const getLPLiquidityValueEstimateCached = memoize(getLPLiquidityValueEstimate, { promise: true, maxAge: 2 * 60 * 1000, max: 1 });

export const supplyLPAfterValues = async ({
  _firstAmount = '0', _firstAsset = 'WETH', _secondAsset = 'DAI', _secondAmount = '0', source = 'wallet', to = 'wallet',
}, { proxyAddress, account }, _balances = {}) => {
  // sort by address
  const [first, second] = [[_firstAsset, _firstAmount], [_secondAsset, _secondAmount]].sort((a, b) => getAssetInfo(a[0]).address - getAssetInfo(b[0]).address);
  const balances = cloneDeep(_balances);
  const cached = getLPLiquidityMintedEstimateCached._has(first[0], first[1], second[0], second[1]);
  try {
    const [liquidity, lpAddress] = await getLPLiquidityMintedEstimateCached(first[0], first[1], second[0], second[1]);
    const lpToken = `UNIV2${first[0].replace('WETH', 'ETH')}${second[0].replace('WETH', 'ETH')}`;
    const toAddr = to === 'wallet' ? account : proxyAddress;
    await changeBalance(balances, source, first[0], new Dec(first[1] || '0').mul(-1), source === 'wallet' ? account : proxyAddress);
    await changeBalance(balances, source, second[0], new Dec(second[1] || '0').mul(-1), source === 'wallet' ? account : proxyAddress);
    await changeBalance(balances, to, lpToken, liquidity, toAddr);
    return {
      balances,
      returnValue: liquidity,
      cached,
    };
  } catch (e) {
    return {
      balances,
      returnValue: '0',
      cached,
    };
  }
};


export const withdrawLPAfterValues = async ({
  liquidity, _firstAsset, _secondAsset, source = 'wallet', to = 'wallet',
}, { proxyAddress, account }, _balances = {}) => {
  const balances = cloneDeep(_balances);
  const [firstAsset, secondAsset] = [_firstAsset, _secondAsset].sort((a, b) => getAssetInfo(a).address - getAssetInfo(b).address);
  const cached = getLPLiquidityValueEstimateCached._has(firstAsset, secondAsset, liquidity);
  try {
    const [firstAmount, secondAmount, lpAddress] = await getLPLiquidityValueEstimate(firstAsset, secondAsset, liquidity);
    const toAddr = to === 'wallet' ? account : proxyAddress;
    await changeBalance(balances, to, firstAsset, firstAmount, toAddr);
    await changeBalance(balances, to, secondAsset, secondAmount, toAddr);
    const lpToken = `UNIV2${firstAsset.replace('WETH', 'ETH')}${secondAsset.replace('WETH', 'ETH')}`;
    await changeBalance(balances, source, lpToken, new Dec(liquidity).mul(-1), source === 'wallet' ? account : proxyAddress, lpAddress);
    return {
      balances,
      returnValue: liquidity,
      cached,
    };
  } catch (e) {
    return {
      balances,
      returnValue: '0',
      cached,
    };
  }
};
