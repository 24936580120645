import dfs from '@defisaver/sdk';
import { callRecipeViaProxy } from './contractCallService';
import { requireAddress } from './utils';

/**
 *
 * @param accountType
 * @param sendTxFunc
 * @param proxyAddress
 * @param account
 * @param newOwner
 * @return {Promise<void>}
 */
export const changeProxyOwner = async (accountType, sendTxFunc, proxyAddress, account, newOwner) => {
  console.log(newOwner, typeof newOwner);
  requireAddress(newOwner);
  const actions = [
    new dfs.actions.basic.ChangeProxyOwnerAction(newOwner),
  ];
  const recipe = new dfs.Recipe('ChangeProxyOwner', actions);

  await callRecipeViaProxy(accountType, sendTxFunc, proxyAddress, account, recipe);
};
