export const GET_MAX_VALUE_REQUEST = 'GET_MAX_VALUE_REQUEST';
export const GET_MAX_VALUE_SUCCESS = 'GET_MAX_VALUE_SUCCESS';
export const GET_MAX_VALUE_FAILURE = 'GET_MAX_VALUE_FAILURE';

export const MAKER_SET_DASHBOARD_ACTION = 'MAKER_SET_DASHBOARD_ACTION';
export const MAKER_SET_ADDITIONAL_DASHBOARD_ACTION = 'MAKER_SET_ADDITIONAL_DASHBOARD_ACTION';

export const MAKER_ACTION_EXEC_REQUEST = 'MAKER_ACTION_EXEC_REQUEST';
export const MAKER_ACTION_EXEC_FAILURE = 'MAKER_ACTION_EXEC_FAILURE';
export const MAKER_ACTION_EXEC_SUCCESS = 'MAKER_ACTION_EXEC_SUCCESS';

export const GET_AFTER_CDP_REQUEST = 'GET_AFTER_CDP_REQUEST';
export const GET_AFTER_CDP_SUCCESS = 'GET_AFTER_CDP_SUCCESS';
export const GET_AFTER_CDP_FAILURE = 'GET_AFTER_CDP_FAILURE';

export const TRANSFER_CDP_REQUEST = 'TRANSFER_CDP_REQUEST';
export const TRANSFER_CDP_SUCCESS = 'TRANSFER_CDP_SUCCESS';
export const TRANSFER_CDP_FAILURE = 'TRANSFER_CDP_FAILURE';

export const GET_REPAY_MODAL_DATA_REQUEST = 'GET_REPAY_MODAL_DATA_REQUEST';
export const GET_REPAY_MODAL_DATA_SUCCESS = 'GET_REPAY_MODAL_DATA_SUCCESS';
export const GET_REPAY_MODAL_DATA_FAILURE = 'GET_REPAY_MODAL_DATA_FAILURE';
export const RESET_REPAY_MODAL = 'RESET_REPAY_MODAL';

export const GET_BOOST_MODAL_DATA_REQUEST = 'GET_BOOST_MODAL_DATA_REQUEST';
export const GET_BOOST_MODAL_DATA_SUCCESS = 'GET_BOOST_MODAL_DATA_SUCCESS';
export const GET_BOOST_MODAL_DATA_FAILURE = 'GET_BOOST_MODAL_DATA_FAILURE';
export const RESET_BOOST_MODAL = 'RESET_BOOST_MODAL';

export const CLOSE_CDP_REQUEST = 'CLOSE_CDP_REQUEST';
export const CLOSE_CDP_SUCCESS = 'CLOSE_CDP_SUCCESS';
export const CLOSE_CDP_FAILURE = 'CLOSE_CDP_FAILURE';
export const CLEAR_CLOSE_CDP_ERROR = 'CLEAR_CLOSE_CDP_ERROR';
