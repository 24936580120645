import React from 'react';
import PropTypes from 'prop-types';
import ModalHeader from '../../ModalHeader';

const UniswapV3ImageModal = ({ closeModal, imageString }) => (
  <div className="uniswap-v3-image">;
    <ModalHeader closeModal={closeModal} />
    <div>
      <img src={imageString} alt="" />
    </div>
  </div>
);

UniswapV3ImageModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  imageString: PropTypes.string.isRequired,
};

export default UniswapV3ImageModal;
