/* eslint-disable */
import React from 'react';

export default function ArbitrumIcon(props) {
  return (
    <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g>
        <path d="M5.44189 7.83584L7.11704 10.4651L8.66454 9.56825L6.46447 6.10059L5.44189 7.83584Z" fill="white"/>
        <path d="M10.0733 8.51358L10.0722 7.795L7.66822 4.05078L6.77637 5.56414L9.09703 9.31733L9.9361 8.83111C10.0184 8.76425 10.0682 8.66616 10.0734 8.56033L10.0733 8.51358Z" fill="white"/>
        <path d="M0 9.19101L1.18489 9.8738L5.12797 3.5502L4.45877 3.53254C3.88857 3.52448 3.27435 3.67271 2.99244 4.1329L0.753301 7.60349L0 8.76092V9.19101Z" fill="white"/>
        <path d="M7.40395 3.55029L5.64021 3.5567L1.6499 10.1415L3.04464 10.9445L3.42393 10.3012L7.40395 3.55029Z" fill="white"/>
        <path d="M10.8169 3.54161C10.8022 3.17275 10.6024 2.83508 10.2896 2.6385L5.90489 0.116947C5.59544 -0.0388657 5.20887 -0.0390658 4.89887 0.116847C4.86222 0.135333 0.634834 2.58704 0.634834 2.58704C0.576326 2.61511 0.519969 2.64853 0.466964 2.68647C0.187706 2.88661 0.0174849 3.19764 0 3.53933V8.76141L0.753301 7.60398L0.746722 3.57463C0.747598 3.55989 0.749449 3.54538 0.752075 3.53108C0.76911 3.43627 0.82204 3.3517 0.901735 3.29454C0.921621 3.28031 5.22123 0.791529 5.23488 0.7847C5.33594 0.733946 5.46439 0.733346 5.56557 0.783124L9.89357 3.27261C9.99595 3.33782 10.0601 3.44838 10.0658 3.56925V8.56062C10.0606 8.66645 10.0188 8.76453 9.93647 8.8314L9.09739 9.31762L8.66445 9.56854L7.11697 10.4653L5.54766 11.3748C5.46314 11.4053 5.36431 11.4036 5.28048 11.3695L3.42374 10.3016L3.04445 10.9449L4.71304 11.9056C4.76822 11.937 4.81737 11.9648 4.85772 11.9874C4.92018 12.0225 4.96276 12.0459 4.97779 12.0532C5.09638 12.1108 5.26703 12.1443 5.42079 12.1443C5.56177 12.1443 5.69925 12.1184 5.82932 12.0675L10.3875 9.42771C10.6491 9.22499 10.8031 8.9193 10.8169 8.58813V3.54161Z" fill="white"/>
      </g>
    </svg>

  );
}
