import t from 'translate';
import dfs from '@defisaver/sdk';
import { assetAmountInWei } from '@defisaver/tokens';
import {
  addApproveForActionIfNeeded,
  addApproveForActionWithSelectIfNeeded,
  getDashboardInputs,
} from '../dashboardActions';
import { callMultipleTxAction, sendTx } from '../txNotificationActions';
import { callRecipeViaProxy } from '../../services/contractCallService';
import { captureException } from '../../sentry';
import { getSubscribedStrategiesAction } from '../startegiesActions/strategiesActions';
import { confirmViaModal } from '../modalActions';
import callTx from '../../services/txService';
import {
  MStableImUsdTokenAddress, MStableImUsdTokenContract, MStableImUsdVaultAddress,
  MStableImUsdVaultContract,
} from '../../services/contractRegistryService';
import { MAXUINT } from '../../constants/general';
import { formatNumber, requireAddress } from '../../services/utils';
import {
  GET_SAVINGS_MANAGE_EXECUTING_FAILURE,
  GET_SAVINGS_MANAGE_EXECUTING_REQUEST,
  GET_SAVINGS_MANAGE_EXECUTING_SUCCESS,
} from '../../actionTypes/savingsActionTypes/savingsManageActionTypes';

export const getMaxValueForAction = (slug, type) => (dispatch, getState) => getState().savingsManage.maxValues?.[slug]?.[type] || 0;

export const executingActionRequest = (slug, action) => ({
  type: GET_SAVINGS_MANAGE_EXECUTING_REQUEST,
  payload: { slug, action },
});

export const executingActionSuccess = (slug, action) => ({
  type: GET_SAVINGS_MANAGE_EXECUTING_SUCCESS,
  payload: { slug, action },
});

export const executingActionFailure = (slug, action, error) => ({
  type: GET_SAVINGS_MANAGE_EXECUTING_FAILURE,
  payload: { slug, action, error },
});

const getMStableToMStableMoveRecipe = async (firstSlug, secondSlug, account, proxyAddress, amount, isMax) => {
  if (firstSlug === 'mstable_imusd' && secondSlug === 'mstable_mta') {
    return new dfs.Recipe('mStableMove', [
      new dfs.actions.mstable.MStableDepositAction(MStableImUsdTokenAddress, MStableImUsdTokenAddress, MStableImUsdTokenAddress, MStableImUsdVaultAddress, proxyAddress, proxyAddress, isMax ? MAXUINT : await MStableImUsdTokenContract().methods.underlyingToCredits(amount).call(), 0, dfs.utils.mstableAssetPairs.IMASSET_IMASSETVAULT),
    ]);
  }
  if (firstSlug === 'mstable_mta' && secondSlug === 'mstable_imusd') {
    return new dfs.Recipe('mStableVaultMove', [
      new dfs.actions.mstable.MStableWithdrawAction(MStableImUsdTokenAddress, MStableImUsdTokenAddress, MStableImUsdTokenAddress, MStableImUsdVaultAddress, proxyAddress, proxyAddress, isMax ? MAXUINT : await MStableImUsdTokenContract().methods.underlyingToCredits(amount).call(), 0, dfs.utils.mstableAssetPairs.IMASSET_IMASSETVAULT),
    ]);
  }
};
const formNameToPositionName = (formName) => {
  if (formName.startsWith('rari')) return 'Rari';
  if (formName.startsWith('yearn')) return 'Yearn';
  if (formName.startsWith('mstable_imusd')) return 'mStable Save';
  if (formName.startsWith('mstable_mta')) return 'mStable Vault';
  if (formName.startsWith('dydx')) return 'dYdX';
  return '';
};

export const importPosition = (contextAction, getRecipe) => async (dispatch, getState) => {
  const {
    general: { account, accountType },
    maker: { proxyAddress },
  } = getState();
  const { firstAction, firstInput, firstInputSelect } = dispatch(getDashboardInputs(contextAction));
  try {
    dispatch(executingActionRequest(contextAction.formName, firstAction.value));
    requireAddress(account);
    requireAddress(proxyAddress);
    const txLabel = `Import ${formatNumber(firstInput)} ${firstAction.symbol || firstInputSelect.value} to ${formNameToPositionName(contextAction.formName)}`;
    const maxValue = dispatch(getMaxValueForAction(contextAction.formName, firstAction.value));
    const proxySendHandler = (promise) => {
      const additionalInfo = {
        protocol: 'savings',
        firstToken: firstAction.symbol,
        firstAmount: firstInput,
      };
      return sendTx(promise, txLabel, 'savings', dispatch, getState, additionalInfo);
    };
    const txToExecute = [];
    await dispatch(addApproveForActionIfNeeded(firstAction, firstInput, proxyAddress, txToExecute));
    await dispatch(addApproveForActionWithSelectIfNeeded(firstAction, firstInput, firstInputSelect, proxyAddress, txToExecute));
    const recipe = await getRecipe(firstAction, firstInput, account, proxyAddress, firstInputSelect, firstInput === maxValue);
    txToExecute.push({
      func: () => callRecipeViaProxy(accountType, proxySendHandler, proxyAddress, account, recipe),
      notifMessage: txLabel,
    });
    await dispatch(callMultipleTxAction(txToExecute));
    dispatch(executingActionSuccess(contextAction.formName, firstAction.value));
  } catch (err) {
    dispatch(executingActionFailure(contextAction.formName, firstAction.value, err.message));
    captureException(err);
  }
};
export const importMStableVaultPosition = (contextAction, getRecipe) => async (dispatch, getState) => {
  const {
    general: { account, accountType, path },
    maker: { proxyAddress },
  } = getState();
  const { firstAction, firstInput, firstInputSelect } = dispatch(getDashboardInputs(contextAction));
  try {
    dispatch(executingActionRequest(contextAction.formName, firstAction.value));
    requireAddress(account);
    requireAddress(proxyAddress);
    const maxValue = dispatch(getMaxValueForAction(contextAction.formName, firstAction.value));
    const txLabel = `Import ${formatNumber(firstInput)} mUSD to ${formNameToPositionName(contextAction.formName)}`;
    const proxySendHandler = (promise) => {
      const additionalInfo = {
        protocol: 'savings',
        firstToken: firstAction.symbol,
        firstAmount: firstInput,
      };
      return sendTx(promise, txLabel, 'savings', dispatch, getState, additionalInfo);
    };
    const { recipe, amount } = await getRecipe(firstAction, firstInput, account, proxyAddress, firstInputSelect, maxValue === firstInput);
    const txToExecute = [];
    txToExecute.push({
      func: () => callTx(
        accountType,
        path,
        (promise) => sendTx(promise, `Unstake ${formatNumber(firstInput)} mUSD from mStable Vault`, 'savings', dispatch, getState),
        MStableImUsdVaultContract(),
        'withdraw',
        [amount],
        { from: account },
        'withdraw',
      ),
      notifMessage: `Unstake ${formatNumber(firstInput)} mUSD from mStable Vault`,
    });
    await dispatch(addApproveForActionIfNeeded(firstAction, firstInput, proxyAddress, txToExecute));
    await dispatch(addApproveForActionWithSelectIfNeeded(firstAction, firstInput, firstInputSelect, proxyAddress, txToExecute));
    txToExecute.push({
      func: () => callRecipeViaProxy(
        accountType,
        proxySendHandler,
        proxyAddress,
        account,
        recipe,
      ),
      notifMessage: txLabel,
    });
    await dispatch(callMultipleTxAction(txToExecute));
    dispatch(executingActionSuccess(contextAction.formName, firstAction.value));
  } catch (err) {
    dispatch(executingActionFailure(contextAction.formName, firstAction.value, err.message));
    captureException(err);
  }
};
export const supplyToVault = (contextAction, getRecipe) => async (dispatch, getState) => {
  const {
    general: { account, accountType },
    maker: { proxyAddress },
  } = getState();
  const { firstAction, firstInput, firstInputSelect } = dispatch(getDashboardInputs(contextAction));
  try {
    dispatch(executingActionRequest(contextAction.formName, firstAction.value));
    requireAddress(account);
    requireAddress(proxyAddress);
    const maxValue = dispatch(getMaxValueForAction(contextAction.formName, firstAction.value));
    const txLabel = `Deposit ${formatNumber(firstInput)} ${firstAction.symbol || firstInputSelect.value} to ${formNameToPositionName(contextAction.formName)}`;
    const proxySendHandler = (promise) => {
      const additionalInfo = {
        protocol: 'savings',
        firstToken: firstAction.symbol,
        firstAmount: firstInput,
      };
      return sendTx(promise, txLabel, 'savings', dispatch, getState, additionalInfo);
    };
    const txToExecute = [];
    await dispatch(addApproveForActionIfNeeded(firstAction, firstInput, proxyAddress, txToExecute));
    await dispatch(addApproveForActionWithSelectIfNeeded(firstAction, firstInput, firstInputSelect, proxyAddress, txToExecute));
    const recipe = await getRecipe(firstAction, firstInput, account, proxyAddress, firstInputSelect, maxValue === firstInput);
    txToExecute.push({
      func: () => callRecipeViaProxy(
        accountType,
        proxySendHandler,
        proxyAddress,
        account,
        recipe,
      ),
      notifMessage: txLabel,
    });
    await dispatch(callMultipleTxAction(txToExecute));
    dispatch(executingActionSuccess(contextAction.formName, firstAction.value));
  } catch (err) {
    dispatch(executingActionFailure(contextAction.formName, firstAction.value, err.message));
    captureException(err);
  }
};
export const withdrawFromVault = (contextAction, getRecipe) => async (dispatch, getState) => {
  const {
    general: { account, accountType },
    maker: { proxyAddress },
  } = getState();
  const { firstAction, firstInput, firstInputSelect } = dispatch(getDashboardInputs(contextAction));
  try {
    dispatch(executingActionRequest(contextAction.formName, firstAction.value));
    requireAddress(account);
    requireAddress(proxyAddress);
    const maxValue = dispatch(getMaxValueForAction(contextAction.formName, firstAction.value));
    const txLabel = `Withdraw ${formatNumber(firstInput)} ${firstAction.symbol || firstInputSelect.value} from ${formNameToPositionName(contextAction.formName)}`;
    const proxySendHandler = (promise) => {
      const additionalInfo = {
        protocol: 'savings',
        firstToken: firstAction.symbol,
        firstAmount: firstInput,
      };
      return sendTx(promise, txLabel, 'savings', dispatch, getState, additionalInfo);
    };
    const txToExecute = [];
    const recipe = await getRecipe(firstAction, firstInput, account, proxyAddress, firstInputSelect, maxValue === firstInput);
    txToExecute.push({
      func: () => callRecipeViaProxy(
        accountType,
        proxySendHandler,
        proxyAddress,
        account,
        recipe,
      ),
      notifMessage: txLabel,
    });
    await dispatch(callMultipleTxAction(txToExecute));
    dispatch(executingActionSuccess(contextAction.formName, firstAction.value));
  } catch (err) {
    dispatch(executingActionFailure(contextAction.formName, firstAction.value, err.message));
    captureException(err);
  }
};
export const moveVault = (contextAction, getRecipe) => async (dispatch, getState) => {
  await dispatch(getSubscribedStrategiesAction());
  const {
    general: { account, accountType },
    maker: { proxyAddress },
    strategies: { subscribedStrategies },
  } = getState();
  const { firstAction, firstInput, firstInputSelect } = dispatch(getDashboardInputs(contextAction));

  const protocol = contextAction.formName.split('_')[0];
  let isProtocolUsedInStrategies;
  subscribedStrategies.forEach(({ bundle, isEnabled }) => { if (isEnabled && protocol === bundle) isProtocolUsedInStrategies = true; });

  if (isProtocolUsedInStrategies) {
    const confirmed = await dispatch(confirmViaModal(t('savings.moving_affects_strategies', { '%protocol': formNameToPositionName(contextAction.formName) })));
    if (!confirmed) throw new Error(t('errors.denied_transaction'));
  }

  try {
    dispatch(executingActionRequest(contextAction.formName, firstAction.value));
    requireAddress(account);
    requireAddress(proxyAddress);
    const maxValue = dispatch(getMaxValueForAction(contextAction.formName, firstAction.value));
    const txLabel = `Move ${formatNumber(firstInput)} ${firstAction.symbol || firstInputSelect.getter.getManageData().suppliedAsset} from ${formNameToPositionName(contextAction.formName)} to ${formNameToPositionName(firstInputSelect.getter.getManageData().slug)}`;
    const proxySendHandler = (promise) => {
      const additionalInfo = {
        protocol: 'savings',
        firstToken: firstAction.symbol,
        firstAmount: firstInput,
      };
      return sendTx(promise, txLabel, 'savings', dispatch, getState, additionalInfo);
    };
    const txToExecute = [];
    let finalRecipe = await getMStableToMStableMoveRecipe(contextAction.formName, firstInputSelect.getter.getManageData().slug, account, proxyAddress, assetAmountInWei(firstInput, 'mUSD'), maxValue === firstInput);
    if (!finalRecipe) {
      const {
        recipe, asset, amount, estimateAmount,
      } = await getRecipe(firstAction, firstInput, account, proxyAddress, firstInputSelect, maxValue === firstInput);
      const supplyAction = await firstInputSelect.getter.getMoveSupplyActions(amount, asset, proxyAddress, proxyAddress, estimateAmount);
      supplyAction.forEach((action) => {
        recipe.addAction(action);
      });
      finalRecipe = recipe;
    }
    txToExecute.push({
      func: () => callRecipeViaProxy(
        accountType,
        proxySendHandler,
        proxyAddress,
        account,
        finalRecipe,
      ),
      notifMessage: txLabel,
    });
    await dispatch(callMultipleTxAction(txToExecute));
    dispatch(executingActionSuccess(contextAction.formName, firstAction.value));
  } catch (err) {
    dispatch(executingActionFailure(contextAction.formName, firstAction.value, err.message));
    captureException(err);
  }
};
export const claimMta = (contextAction, getRecipe, hasSupply, toAsset, exchangeRate) => async (dispatch, getState) => {
  const {
    general: { account, accountType },
    maker: { proxyAddress },
    savingsManage: { slippagePercent },
  } = getState();
  const { firstAction, firstInput, firstInputSelect } = dispatch(getDashboardInputs(contextAction));
  try {
    dispatch(executingActionRequest(contextAction.formName, firstAction.value));
    requireAddress(account);
    requireAddress(proxyAddress);
    const txLabel = `Claim ${formatNumber(firstInput)} MTA ${hasSupply ? 'and supply to' : 'from'} ${formNameToPositionName(contextAction.formName)}`;
    const proxySendHandler = (promise) => {
      const additionalInfo = {
        protocol: 'savings',
        firstToken: firstAction.symbol,
        firstAmount: firstInput,
      };
      return sendTx(promise, txLabel, 'savings', dispatch, getState, additionalInfo);
    };
    const recipe = await getRecipe(firstAction, firstAction, account, proxyAddress, firstInputSelect, true, slippagePercent, toAsset, exchangeRate);
    const txToExecute = [];
    txToExecute.push({
      func: () => callRecipeViaProxy(
        accountType,
        proxySendHandler,
        proxyAddress,
        account,
        recipe,
      ),
      notifMessage: txLabel,
    });
    await dispatch(callMultipleTxAction(txToExecute));
    dispatch(executingActionSuccess(contextAction.formName, firstAction.value));
  } catch (err) {
    dispatch(executingActionFailure(contextAction.formName, firstAction.value, err.message));
    captureException(err);
  }
};
