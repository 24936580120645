import Dec from 'decimal.js';

/**
 * Gets cdp daiLabel from getState
 *
 * @param getState {Function}
 * @return {string}
 */
export const getCdpDaiSymbol = getState => getState().maker.cdp.daiLabel;

/**
 * Gets cdp asset label from getState
 *
 * @param getState {Function}
 * @return {string}
 */
export const getCdpAssetSymbol = getState => getState().maker.cdp.asset;

/**
 * Calculates max possible debt given current collateral
 *
 * @param cdp {Object} cdp object from store
 * @return {string}
 */
export const calcCdpDebtLimit = (cdp) => {
  const liqRatio = (cdp.liqPercent / 100) + 0.001;
  return new Dec(cdp.collateralUsd).div(liqRatio).toString();
};
