import React from 'react';
import t from 'translate';
import PropTypes from 'prop-types';
import { bemifyClass } from '../../../../services/stylingUtils';

import TogglableSection from '../../../TogglableSection/TogglableSection';
import InfoBox from '../../../Decorative/InfoBox/InfoBox';

import './AdvancedOptions.scss';

const className = bemifyClass('slippage-advanced-options');

const AdvancedOptions = ({
  advanced, setAdvanced, exchangeRate, SlippageLimitForm, isRepay, borrowAsset, supplyAsset,
  hasDeadline, DeadlineLimitForm, isLP,
}) => (
  <TogglableSection
    advanced={advanced}
    toggleAdvanced={setAdvanced}
    title={t('common.advanced')}
    additionalClasses={className()}
  >
    <div className={className('content')}>
      <SlippageLimitForm
        borrowAsset={borrowAsset}
        supplyAsset={supplyAsset}
        estimatedPrice={exchangeRate}
        type={isRepay ? 'repay' : 'boost'}
      />
      <InfoBox message={t('common.slippage_tolerance_info')} />
      { hasDeadline && (
        <>
          <DeadlineLimitForm />
          <InfoBox message={t('common.deadline_info')} />
        </>
      )}
    </div>
  </TogglableSection>
);

AdvancedOptions.defaultProps = {
  isRepay: false,
  isLP: false,
  hasDeadline: false,
  DeadlineLimitForm: <></>,
};

AdvancedOptions.propTypes = {
  advanced: PropTypes.bool.isRequired,
  setAdvanced: PropTypes.func.isRequired,
  exchangeRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  SlippageLimitForm: PropTypes.object.isRequired,
  DeadlineLimitForm: PropTypes.object,
  isRepay: PropTypes.bool,
  isLP: PropTypes.bool,
  hasDeadline: PropTypes.bool,
  borrowAsset: PropTypes.string.isRequired,
  supplyAsset: PropTypes.string.isRequired,
};

export default AdvancedOptions;
