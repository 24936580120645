import t from 'translate';
import Dec from 'decimal.js';
import { captureException } from 'sentry';
import { formatNumber, isWalletTypeProxy, compareAddresses } from 'services/utils';
import { change } from 'redux-form';
import * as actionTypes from '../../actionTypes/liquityActionTypes/liquityStakingActionTypes';
import {
  stakeLQTY, unstakeLQTY, stakeLUSD, unstakeLUSD, getStakedBalancesAndRewards, withdrawETHGainToTrove,
} from '../../services/liquityServices/liquityStakingService';
import { callMultipleTxAction, sendTx } from '../txNotificationActions';
import { trackEvent } from '../../services/analyticsService';
import {
  addApproveTxIfNeeded,
  approveAddressOnAssetAction,
  approveTypeToLabelMap,
  getAssetsBalancesAction,
} from '../assetsActions';
import { createDSProxyAction } from '../makerActions/makerActions';
import { APPROVE_TYPE_DS_PROXY } from '../../actionTypes/assetsActionTypes';


const emptyStakeBalancesAndRewards = {
  stakedLQTYBalance: '0',
  stakedLUSDBalance: '0',
  rewardETH: '0',
  rewardLUSD: '0',
  stabilityRewardETH: '0',
  stabilityRewardLQTY: '0',
};

export const getStakedBalancesAndRewardsAction = (userAddress = '', userProxy = '') => async (dispatch, getState) => {
  dispatch({ type: actionTypes.LQTY_GET_STAKE_BALANCES_AND_REWARDS_REQUEST });

  try {
    const {
      general: { account, recentAccounts },
      maker: { proxyAddress },
    } = getState();

    let fetchingAddress = account;
    let fetchingProxy = proxyAddress;

    if (userAddress) {
      fetchingAddress = userAddress;
      fetchingProxy = userProxy;
    }

    const [accountInfo, proxyInfo] = await getStakedBalancesAndRewards([fetchingAddress, fetchingProxy]);

    const payload = {
      totalLUSDDeposited: accountInfo.totalLUSDDeposited,
      totalLQTYStaked: accountInfo.totalLQTYStaked,
      account: { ...accountInfo },
      proxy: { ...(proxyInfo || emptyStakeBalancesAndRewards) },
    };
    dispatch({
      type: actionTypes.LQTY_GET_STAKE_BALANCES_AND_REWARDS_SUCCESS,
      payload,
    });
    dispatch(getAssetsBalancesAction(['LUSD', 'LQTY']));
    return payload;
  } catch (error) {
    console.error(error);
    dispatch({ type: actionTypes.LQTY_GET_STAKE_BALANCES_AND_REWARDS_FAILURE, payload: error.message });
    captureException(error);
  }
};

const cleanupAfterAction = () => (dispatch) => {
  dispatch(getStakedBalancesAndRewardsAction());
  dispatch(change('liquityStakingForm', 'stakeAmount', ''));
  dispatch(change('liquityStakingForm', 'unstakeAmount', ''));
  dispatch(getAssetsBalancesAction(['LUSD', 'LQTY']));
};

const liquityTrackEventWrapper = (method, message = '') => {
  trackEvent('liquity', method, message);
};

export const stakeLQTYAction = (inputAmount, closeModal) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.LQTY_STAKE_REQUEST });

  liquityTrackEventWrapper('stakeLQTY');
  try {
    const txToExecute = [];
    const { maker: { proxyAddress }, general: { walletType } } = getState();
    const isProxy = isWalletTypeProxy(walletType);

    const createProxyFunctionObject = {
      func: () => dispatch(createDSProxyAction()),
      type: 'createTrove',
      notifMessage: t('account.create_ds_proxy'),
      checkReturnValue: true,
      returnValueFailureMessage: 'Error fetching Proxy address',
    };

    const approveFunctionObject = {
      type: 'approve',
      notifMessage: `${t('common.approve')} ${approveTypeToLabelMap[APPROVE_TYPE_DS_PROXY]} ${t('common.for')} LQTY`,
      func: () => dispatch(approveAddressOnAssetAction('LQTY', getState().maker.proxyAddress, APPROVE_TYPE_DS_PROXY)),
    };
    const proxySendHandler = (promise) => {
      const amount = formatNumber(parseFloat(inputAmount), 2);
      const additionalInfo = {
        protocol: 'liquity',
        firstToken: 'LQTY',
        firstAmount: inputAmount,
      };
      return sendTx(promise, `${t('common.stake')}: ${amount} LQTY`, 'stakeLQTY', dispatch, getState, additionalInfo);
    };
    const stakeFunctionObject = {
      type: 'stake',
      notifMessage: `${t('common.stake')}: ${formatNumber(parseFloat(inputAmount), 2)} LQTY`,
      func: () => stakeLQTY(getState, proxySendHandler, inputAmount),
    };

    if (isProxy) {
      if (proxyAddress) {
        await dispatch(addApproveTxIfNeeded('LQTY', proxyAddress, inputAmount, APPROVE_TYPE_DS_PROXY, txToExecute, approveFunctionObject));
      } else {
        txToExecute.push(createProxyFunctionObject);
        txToExecute.push(approveFunctionObject);
      }
    }

    txToExecute.push(stakeFunctionObject);
    const returnValues = await dispatch(callMultipleTxAction(txToExecute));

    dispatch({ type: actionTypes.LQTY_STAKE_SUCCESS });
    closeModal();
    dispatch(cleanupAfterAction());
    liquityTrackEventWrapper('stakeLQTYSuccess');
  } catch (error) {
    dispatch({ type: actionTypes.LQTY_STAKE_FAILURE, payload: error.message });
    captureException(error);
    liquityTrackEventWrapper('stakeLQTYError', error.message);
  }
};

export const unstakeLQTYAction = (inputAmount, closeModal) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.LQTY_UNSTAKE_REQUEST });

  liquityTrackEventWrapper('unstakeLQTY');
  try {
    const txToExecute = [];

    const proxySendHandler = (promise) => {
      const amount = formatNumber(parseFloat(inputAmount), 2);
      const additionalInfo = {
        protocol: 'liquity',
        firstToken: 'LQTY',
        firstAmount: inputAmount,
      };
      return sendTx(promise, `${t('common.unstake')}: ${amount} LQTY`, 'unstakeLQTY', dispatch, getState, additionalInfo);
    };
    const unstakeFunctionObject = {
      type: 'unstake',
      notifMessage: `${t('common.stake')}: ${formatNumber(parseFloat(inputAmount), 2)} LQTY`,
      func: () => unstakeLQTY(getState, proxySendHandler, inputAmount),
    };

    txToExecute.push(unstakeFunctionObject);
    const returnValues = await dispatch(callMultipleTxAction(txToExecute));

    dispatch({ type: actionTypes.LQTY_UNSTAKE_SUCCESS });
    closeModal();
    dispatch(cleanupAfterAction());
    liquityTrackEventWrapper('unstakeLQTYSuccess');
  } catch (error) {
    dispatch({ type: actionTypes.LQTY_UNSTAKE_FAILURE, payload: error.message });
    captureException(error);
    liquityTrackEventWrapper('unstakeLQTYError', error.message);
  }
};

export const claimLQTYRewardsAction = (closeModal) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.LQTY_UNSTAKE_REQUEST });
  liquityTrackEventWrapper('claimLQTYRewards');

  try {
    const txToExecute = [];

    const proxySendHandler = (promise) => {
      const additionalInfo = {
        protocol: 'liquity',
      };
      return sendTx(promise, `${t('common.claim')} LQTY Rewards`, 'claimLQTYRewards', dispatch, getState, additionalInfo);
    };
    const unstakeFunctionObject = {
      type: 'claim',
      notifMessage: `${t('common.claim')} LQTY Rewards`,
      func: () => unstakeLQTY(getState, proxySendHandler, '0'),
    };

    txToExecute.push(unstakeFunctionObject);
    const returnValues = await dispatch(callMultipleTxAction(txToExecute));

    dispatch({ type: actionTypes.LQTY_UNSTAKE_SUCCESS });
    closeModal();
    dispatch(cleanupAfterAction());
    liquityTrackEventWrapper('claimLQTYRewardsSuccess');
  } catch (error) {
    dispatch({ type: actionTypes.LQTY_UNSTAKE_FAILURE, payload: error.message });
    captureException(error);
    liquityTrackEventWrapper('claimLQTYRewardsError', error.message);
  }
};

export const stakeLUSDAction = (inputAmount, closeModal) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.LQTY_LUSD_STAKE_REQUEST });

  liquityTrackEventWrapper('stakeLUSD');
  try {
    const txToExecute = [];
    const { maker: { proxyAddress }, general: { walletType } } = getState();
    const isProxy = isWalletTypeProxy(walletType);

    const createProxyFunctionObject = {
      func: () => dispatch(createDSProxyAction()),
      type: 'createTrove',
      notifMessage: t('account.create_ds_proxy'),
      checkReturnValue: true,
      returnValueFailureMessage: 'Error fetching Proxy address',
    };

    const approveFunctionObject = {
      type: 'approve',
      notifMessage: `${t('common.approve')} ${approveTypeToLabelMap[APPROVE_TYPE_DS_PROXY]} ${t('common.for')} LUSD`,
      func: () => dispatch(approveAddressOnAssetAction('LUSD', getState().maker.proxyAddress, APPROVE_TYPE_DS_PROXY)),
    };
    const proxySendHandler = (promise) => {
      const amount = formatNumber(parseFloat(inputAmount), 2);
      const additionalInfo = {
        protocol: 'liquity',
        firstToken: 'LUSD',
        firstAmount: inputAmount,
      };
      return sendTx(promise, `${t('common.stake')}: ${amount} LUSD`, 'stakeLUSD', dispatch, getState, additionalInfo);
    };
    const stakeFunctionObject = {
      type: 'stake',
      notifMessage: `${t('common.stake')}: ${formatNumber(parseFloat(inputAmount), 2)} LUSD`,
      func: () => stakeLUSD(getState, proxySendHandler, inputAmount),
    };

    if (isProxy) {
      if (proxyAddress) {
        await dispatch(addApproveTxIfNeeded('LUSD', proxyAddress, inputAmount, APPROVE_TYPE_DS_PROXY, txToExecute, approveFunctionObject));
      } else {
        txToExecute.push(createProxyFunctionObject);
        txToExecute.push(approveFunctionObject);
      }
    }

    txToExecute.push(stakeFunctionObject);
    const returnValues = await dispatch(callMultipleTxAction(txToExecute));

    dispatch({ type: actionTypes.LQTY_LUSD_STAKE_SUCCESS });
    closeModal();
    dispatch(cleanupAfterAction());
    liquityTrackEventWrapper('stakeLUSDSuccess');
  } catch (error) {
    dispatch({ type: actionTypes.LQTY_LUSD_STAKE_FAILURE, payload: error.message });
    captureException(error);
    liquityTrackEventWrapper('stakeLUSDError', error.message);
  }
};

export const unstakeLUSDAction = (inputAmount, closeModal) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.LQTY_LUSD_UNSTAKE_REQUEST });

  liquityTrackEventWrapper('unstakeLUSD');
  try {
    const txToExecute = [];

    const proxySendHandler = (promise) => {
      const amount = formatNumber(parseFloat(inputAmount), 2);
      const additionalInfo = {
        protocol: 'liquity',
        firstToken: 'LUSD',
        firstAmount: inputAmount,
      };
      return sendTx(promise, `${t('common.unstake')}: ${amount} LUSD`, 'unstakeLUSD', dispatch, getState, additionalInfo);
    };
    const unstakeFunctionObject = {
      type: 'unstake',
      notifMessage: `${t('common.stake')}: ${formatNumber(parseFloat(inputAmount), 2)} LUSD`,
      func: () => unstakeLUSD(getState, proxySendHandler, inputAmount),
    };

    txToExecute.push(unstakeFunctionObject);
    const returnValues = await dispatch(callMultipleTxAction(txToExecute));

    dispatch({ type: actionTypes.LQTY_LUSD_UNSTAKE_SUCCESS });
    closeModal();
    dispatch(cleanupAfterAction());
    liquityTrackEventWrapper('unstakeLUSDSuccess');
  } catch (error) {
    dispatch({ type: actionTypes.LQTY_LUSD_UNSTAKE_FAILURE, payload: error.message });
    captureException(error);
    liquityTrackEventWrapper('unstakeLUSDError', error.message);
  }
};

export const claimSPRewardsAction = (closeModal) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.LQTY_LUSD_UNSTAKE_REQUEST });
  liquityTrackEventWrapper('claimSPRewards');

  try {
    const txToExecute = [];

    const proxySendHandler = (promise) => {
      const additionalInfo = {
        protocol: 'liquity',
      };
      return sendTx(promise, `${t('common.claim')} SP Rewards`, 'claimSPRewards', dispatch, getState, additionalInfo);
    };
    const unstakeFunctionObject = {
      type: 'claim',
      notifMessage: `${t('common.claim')} SP Rewards`,
      func: () => unstakeLUSD(getState, proxySendHandler, '0'),
    };

    txToExecute.push(unstakeFunctionObject);
    const returnValues = await dispatch(callMultipleTxAction(txToExecute));

    dispatch({ type: actionTypes.LQTY_LUSD_UNSTAKE_SUCCESS });
    closeModal();
    dispatch(cleanupAfterAction());
    liquityTrackEventWrapper('claimSPRewardsSuccess');
  } catch (error) {
    dispatch({ type: actionTypes.LQTY_LUSD_UNSTAKE_FAILURE, payload: error.message });
    captureException(error);
    liquityTrackEventWrapper('claimSPRewardsError', error.message);
  }
};

export const withdrawETHGainToTroveAction = (withdrawAmount, closeModal) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.LQTY_WITHDRAW_ETH_GAIN_TO_TROVE_REQUEST });

  liquityTrackEventWrapper('withdrawETHGainToTrove');
  try {
    const {
      general: { walletType },
      liquity,
    } = getState();
    const trove = liquity[walletType.value];
    const txToExecute = [];

    const proxySendHandler = (promise) => {
      const additionalInfo = {
        protocol: 'liquity',
      };
      return sendTx(promise, `${t('common.claim')}:`, 'withdrawETHGainToTrove', dispatch, getState, additionalInfo);
    };
    const unstakeFunctionObject = {
      type: 'claim',
      notifMessage: `${t('common.claim')}`,
      func: () => withdrawETHGainToTrove(getState, proxySendHandler, trove, withdrawAmount),
    };

    txToExecute.push(unstakeFunctionObject);
    const returnValues = await dispatch(callMultipleTxAction(txToExecute));

    dispatch({ type: actionTypes.LQTY_WITHDRAW_ETH_GAIN_TO_TROVE_SUCCESS });
    closeModal();
    dispatch(cleanupAfterAction());
    liquityTrackEventWrapper('withdrawETHGainToTroveSuccess');
  } catch (error) {
    dispatch({ type: actionTypes.LQTY_WITHDRAW_ETH_GAIN_TO_TROVE_FAILURE, payload: error.message });
    captureException(error);
    liquityTrackEventWrapper('withdrawETHGainToTroveError', error.message);
  }
};
