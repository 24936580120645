/* eslint-disable */
import React from 'react';

export default function LedgerIcon(props) {
  return (
    <svg className="ledger-icon" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.3124 0H6.46823V10.4931H16.9957V2.73008C17 1.25609 15.7912 0 14.3124 0ZM4.05925 0H2.74332C1.2645 0 0 1.20055 0 2.73436V4.04599H4.05925V0ZM0 6.50264H4.05925V10.5486H0V6.50264ZM12.9407 16.9957H14.2567C15.7355 16.9957 17 15.7952 17 14.2614V12.954H12.9407V16.9957ZM6.46823 12.954H10.5275V17H6.46823V12.954ZM0 12.954V14.2656C0 15.7396 1.20449 17 2.74332 17H4.05925V12.954H0Z"
        fill="#41CCB4"/>
    </svg>
  );
}
