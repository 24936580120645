import React from 'react';
import {
  Address,
  Amount,
  Asset,
  AssetAmount,
  CdpId,
  CdpIlk, Deadline, FeeTier, Helper,
  InterestMode,
  Market, NftId, Select, Slippage,
  Source, Tick,
} from '../../../../../inputTypes';
import IlkActionField from './IlkActionField';
import CdpIdActionField from './CdpIdActionField';
import AccountActionField from './AccountActionField';
import AssetActionField from './AssetActionField';
import MarketActionField from './MarketActionField';
import SourceActionField from './SourceActionField';
import InterestModeActionField from './InterestModeActionField';
import AmountActionField from './AmountActionField';
import SlippageActionField from './SlippageActionField';
import DeadlineActionField from './DeadlineActionField';
import FeeTierActionField from './FeeTierActionField';
import TickActionField from './TickActionField';
import NftIdActionField from './NftIdActionField';
import SelectActionField from './SelectActionField';

const getInput = (props) => {
  switch (props.type.constructor) {
    case CdpIlk:
      return (<IlkActionField {...props} />);
    case CdpId:
      return (<CdpIdActionField {...props} />);
    case Address:
      return (<AccountActionField {...props} />);
    case Asset:
      return (<AssetActionField {...props} />);
    case Market:
      return (<MarketActionField {...props} />);
    case Source:
      return (<SourceActionField {...props} />);
    case InterestMode:
      return (<InterestModeActionField {...props} />);
    case Amount:
    case AssetAmount:
      return (<AmountActionField {...props} />);
    case Slippage:
      return (<SlippageActionField {...props} />);
    case Deadline:
      return (<DeadlineActionField {...props} />);
    case FeeTier:
      return (<FeeTierActionField {...props} />);
    case Tick:
      return (<TickActionField {...props} />);
    case NftId:
      return (<NftIdActionField {...props} />);
    case Select:
      return (<SelectActionField {...props} />);
    case Helper:
    default:
      return (
        <>
          <input className="form-item" id={props.type.name} type="text" value={props.originalValue || ''} onChange={(e) => props.handleChange(e, props.i)} />
          {props.isPointer && <span className="third-label">{props.actualValue}</span>}
          <span className="label-replacement" />
        </>
      );
  }
};

export default getInput;
