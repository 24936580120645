import t from 'translate';
import Dec from 'decimal.js';
import dfs from '@defisaver/sdk';
import {
  assetAmountInEth, getAssetInfo, assetAmountInWei, getAssetInfoByAddress,
} from '@defisaver/tokens';

import cloneDeep from 'lodash/cloneDeep';
import config from '../../config/config.json';
import { MAXUINT } from '../../constants/general';
import { EMPTY_AAVE_DATA } from './aaveManageServiceV2';
import {
  AaveV3ViewL2Contract, AaveV3WETHAdapterL2Contract, AaveV3WETHStableDebtTokenL2Contract,
  AaveV3WETHVariableDebtTokenL2Contract, getConfigContractAddress,
} from '../contractRegistryService';

import callTx from '../txService';
import { callActionViaProxy, callRecipeViaProxy } from '../contractCallService';
import {
  addToArrayIf, ethToWeth, isWalletTypeProxy, numberWithCommas, requireAddress,
} from '../utils';
import { calculateBorrowingAssetLimit, calculateNetApy } from '../moneymarketCommonService';
import { getAggregatedPositionData } from './aaveManageService';
import { getAssetBalance } from '../assetsService';
import { multicall } from '../multicallService';

const AAVE_REFERRAL_CODE = 64; // TODO - not used currently

const orderAssetsByCategories = (assetsData, usedAssets, assets) => {
  const usedAssetsValues = Object.values(usedAssets);

  const categoriesMapping = {};
  Object.values(assetsData).forEach((a) => {
    const borrowingOnlyFromCategory = a.eModeCategory === 0
      ? true
      : !usedAssetsValues.filter(u => u.isBorrowed && u.eModeCategory !== a.eModeCategory).length;
    const afterEnteringCategory = getAggregatedPositionData(usedAssets, assetsData, assets, a.eModeCategory);
    const willStayOverCollateralized = new Dec(afterEnteringCategory.ratio).gt(afterEnteringCategory.liqPercent);

    const canEnterCategory = borrowingOnlyFromCategory && willStayOverCollateralized;

    categoriesMapping[a.eModeCategory] = {
      canEnterCategory,
      id: a.eModeCategory,
      data: a.eModeCategoryData,
      assets: a.eModeCategory === 0 ? [] : [...(categoriesMapping[a.eModeCategory]?.assets || []), a.symbol],
      enabledData: {
        ratio: afterEnteringCategory.ratio,
        liqRatio: afterEnteringCategory.liqRatio,
        liqPercent: afterEnteringCategory.liqPercent,
        collRatio: afterEnteringCategory.collRatio,
      },
    };
  });
  return categoriesMapping;
};

/**
 * Fetch all aave data for address in a market
 *
 * @param address {string}
 * @param assetsData {object} getState().aaveManage[getState().aaveManage.selectedMarket].assetsData
 * @param assets {object} getState().assets
 * @param market
 * @param walletType
 * @returns {Promise<{borrowedUsd: *, suppliedUsd: *, usedAssets}>}
 */
export const getAaveAccountDataV3 = async (address, assetsData, assets, market, walletType = 'proxy') => {
  if (!address) return null;

  let payload = {
    ...EMPTY_AAVE_DATA,
    lastUpdated: Date.now(),
  };

  const loanInfoContract = AaveV3ViewL2Contract();
  const marketAddress = market.providerAddress;
  const _addresses = market.assets.map(a => getAssetInfo(ethToWeth(a)).address);

  const multicallData = [
    {
      target: market.lendingPoolAddress,
      abiItem: config[market.lendingPool].abi.find(({ name }) => name === 'getUserEMode'),
      params: [address],
    },
    {
      target: loanInfoContract.options.address,
      abiItem: loanInfoContract.options.jsonInterface.find(({ name }) => name === 'getTokenBalances'),
      params: [marketAddress, address, _addresses],
    },
  ];

  const multicallRes = await multicall(multicallData);

  payload.eModeCategory = +multicallRes[0][0];
  const loanInfo = multicallRes[1][0];

  const usedAssets = {};
  loanInfo.forEach((tokenInfo, i) => {
    const asset = market.assets[i];
    const isSupplied = tokenInfo.balance.toString() !== '0';
    const isBorrowed = tokenInfo.borrowsStable.toString() !== '0' || tokenInfo.borrowsVariable.toString() !== '0';
    if (!isSupplied && !isBorrowed) return;

    const supplied = assetAmountInEth(tokenInfo.balance.toString(), asset);
    const borrowedStable = assetAmountInEth(tokenInfo.borrowsStable.toString(), asset);
    const borrowedVariable = assetAmountInEth(tokenInfo.borrowsVariable.toString(), asset);
    const enabledAsCollateral = assetsData[asset].usageAsCollateralEnabled ? tokenInfo.enabledAsCollateral : false;
    let interestMode;
    if (borrowedVariable === '0' && borrowedStable !== '0') {
      interestMode = '1';
    } else if (borrowedVariable !== '0' && borrowedStable === '0') {
      interestMode = '2';
    } else {
      interestMode = 'both';
    }
    if (!usedAssets[asset]) usedAssets[asset] = {};
    usedAssets[asset] = {
      ...usedAssets[asset],
      symbol: asset,
      supplied,
      suppliedUsd: new Dec(supplied).mul(assets[asset].aavePrice).toString(),
      isSupplied,
      earned: '0',
      earnedUsd: '0',
      collateral: enabledAsCollateral,
      stableBorrowRate: new Dec(tokenInfo.stableBorrowRate).div(1e25).toString(),
      borrowedStable,
      borrowedVariable,
      borrowedUsdStable: new Dec(borrowedStable).mul(assets[asset].aavePrice).toString(),
      borrowedUsdVariable: new Dec(borrowedVariable).mul(assets[asset].aavePrice).toString(),
      borrowed: new Dec(borrowedStable).add(borrowedVariable).toString(),
      borrowedUsd: new Dec(new Dec(borrowedVariable).add(borrowedStable)).mul(assets[asset].aavePrice).toString(),
      isBorrowed,
      debt: '0',
      debtUsd: '0',
      eModeCategory: assetsData[asset].eModeCategory,
      interestMode,
    };
  });

  payload = {
    ...payload,
    usedAssets,
    ...getAggregatedPositionData(usedAssets, assetsData, assets, payload.eModeCategory),
  };

  payload.eModeCategories = orderAssetsByCategories(assetsData, usedAssets, assets);

  payload.ratio = payload.borrowedUsd && payload.borrowedUsd !== '0'
    ? new Dec(payload.borrowLimitUsd).div(payload.borrowedUsd).mul(100).toString()
    : '0';
  payload.minRatio = '100';
  payload.collRatio = payload.borrowedUsd && payload.borrowedUsd !== '0'
    ? new Dec(payload.suppliedCollateralUsd).div(payload.borrowedUsd).mul(100).toString()
    : '0';

  // Calculate borrow limits per asset
  Object.values(payload.usedAssets).forEach((item) => {
    if (item.isBorrowed) {
      // eslint-disable-next-line no-param-reassign
      item.stableLimit = calculateBorrowingAssetLimit(item.borrowedUsdStable, payload.borrowLimitUsd);
      // eslint-disable-next-line no-param-reassign
      item.variableLimit = calculateBorrowingAssetLimit(item.borrowedUsdVariable, payload.borrowLimitUsd);
      // eslint-disable-next-line no-param-reassign
      item.limit = calculateBorrowingAssetLimit(item.borrowedUsd, payload.borrowLimitUsd);
    }
  });

  const { netApy, incentiveUsd, totalInterestUsd } = calculateNetApy(usedAssets, assetsData);
  payload.netApy = netApy;
  payload.incentiveUsd = incentiveUsd;
  payload.totalInterestUsd = totalInterestUsd;

  payload.isSubscribedToAutomation = false;
  payload.automationResubscribeRequired = false;
  // if (walletType === 'proxy') {
  //   const monitorData = await isSubscribedToMonitor(address, 'v3default');
  //   payload.isSubscribedToAutomation = monitorData.isSubscribedToAutomation;
  //   payload.automationResubscribeRequired = monitorData.automationResubscribeRequired;
  // }

  return payload;
};

/**
 * Get token data for a specific market
 *
 * @param selectedMarket
 * @returns {Promise<*>}
 */
export const getAaveMarketsDataV3 = async (selectedMarket) => {
  try {
    const _addresses = selectedMarket.assets.map(a => getAssetInfo(ethToWeth(a)).address);
    const loanInfoContract = AaveV3ViewL2Contract();
    const marketAddress = selectedMarket.providerAddress;
    let loanInfo = await loanInfoContract.methods.getFullTokensInfo(marketAddress, _addresses).call();

    const multicallData = [];

    loanInfo = loanInfo
      .map((market, i) => ({
        symbol: selectedMarket.assets[i],
        isIsolated: new Dec(market.debtCeilingForIsolationMode).gt(0),
        debtCeilingForIsolationModeUsd: new Dec(market.debtCeilingForIsolationMode).div(100).toString(),
        isSiloed: market.isSiloedForBorrowing,
        eModeCategory: +market.emodeCategory,
        isolationModeTotalDebtUsd: new Dec(market.isolationModeTotalDebt).div(100).toString(),
        assetId: Number(market.assetId),
        underlyingTokenAddress: market.underlyingTokenAddress,
        supplyRate: new Dec(market.supplyRate.toString()).div(1e25).toString(),
        borrowRate: new Dec(market.borrowRateVariable.toString()).div(1e25).toString(),
        borrowRateStable: new Dec(market.borrowRateStable.toString()).div(1e25).toString(),
        collateralFactor: new Dec(market.collateralFactor.toString()).div(10000).toString(),
        liquidationRatio: new Dec(market.liquidationRatio.toString()).div(10000).toString(),
        marketLiquidity: assetAmountInEth(new Dec(market.totalSupply.toString())
          .sub(market.totalBorrow.toString())
          .toString(), selectedMarket.assets[i]),
        utilization: new Dec(market.totalBorrow.toString())
          .div(new Dec(market.totalSupply.toString()))
          .times(100)
          .toString(),
        usageAsCollateralEnabled: market.usageAsCollateralEnabled,
        supplyCap: market.supplyCap,
        totalSupply: assetAmountInEth(market.totalSupply.toString(), selectedMarket.assets[i]),
        canBeBorrowed: market.borrowingEnabled,
        canBeSupplied: true,
        disabledStableBorrowing: !market.stableBorrowRateEnabled,
        totalBorrow: assetAmountInEth(market.totalBorrow.toString(), selectedMarket.assets[i]),
        totalBorrowVar: assetAmountInEth(market.totalBorrowVar.toString(), selectedMarket.assets[i]),
        priceInEth: new Dec(market.price.toString()).div(1e18).toString(),
        isolationModeBorrowingEnabled: market.isolationModeBorrowingEnabled,
        supplyAaveAprPerAsset: 0,
        borrowAaveAprPerAsset: 0,
      }));

    loanInfo.forEach(({ eModeCategory }) => {
      multicallData.push({
        target: selectedMarket.lendingPoolAddress,
        abiItem: config[selectedMarket.lendingPool].abi.find(({ name }) => name === 'getEModeCategoryData'),
        params: [eModeCategory],
      });
    });

    const eModeCategories = await multicall(multicallData); // TODO move this to aaveV3ViewL2 instead of doing another call

    loanInfo = loanInfo.map((loanData, i) => ({
      ...loanData,
      eModeCategoryData: {
        label: eModeCategories[i][0].label,
        liquidationBonus: new Dec(eModeCategories[i][0].liquidationBonus).div(10000).toString(),
        liquidationThreshold: new Dec(eModeCategories[i][0].liquidationThreshold).div(10000).toString(),
        collateralFactor: new Dec(eModeCategories[i][0].ltv).div(10000).toString(),
        priceSource: eModeCategories[i][0].priceSource,
      },
    }));

    return loanInfo;
  } catch (err) {
    console.error(err.message);
  }
};

/**
 * Calls the supply action on the compound contract
 *
 * @param accountType {String}
 * @param path {String}
 * @param sendTxFunc {Function}
 * @param account {String}
 * @param walletType {Object}
 * @param amount {String}
 * @param asset {String}
 * @param id {Number}
 * @param market
 * @return {Promise<any>}
 */
export const supplyV3 = async (
  accountType, path, sendTxFunc, account, walletType, amount, asset, id, market,
) => {
  const assetAmount = assetAmountInWei(amount, asset);
  const supplyingEth = asset === 'ETH';
  const lendingPool = new window._web3.eth.Contract(config[market.lendingPool].abi, market.lendingPoolAddress);

  const txParams = {
    from: account,
    value: supplyingEth ? assetAmount : 0,
  };

  if (supplyingEth) {
    const wethAdapter = AaveV3WETHAdapterL2Contract();
    const funcParams = [market.lendingPoolAddress, account, AAVE_REFERRAL_CODE];

    return callTx(accountType, path, sendTxFunc, wethAdapter, 'depositETH', funcParams, txParams, 'depositETH');
  }
  // TODO use supply and other methods with bytes as param!!!
  const funcParams = [getAssetInfo(asset).address, assetAmount, account, AAVE_REFERRAL_CODE];
  return callTx(accountType, path, sendTxFunc, lendingPool, 'supply(address,uint256,address,uint16)', funcParams, txParams, 'supply(address,uint256,address,uint16)');
};

/**
 * Calls the borrow action on the compound contract
 *
 * @param accountType {String}
 * @param path {String}
 * @param sendTxFunc {Function}
 * @param account {String}
 * @param walletType {Object}
 * @param amount {String}
 * @param interestRateValue {Number}
 * @param asset {String} underlying asset symbol
 * @param id {Number}
 * @param market
 * @return {Promise<any>}
 */
export const borrowV3 = async (
  accountType, path, sendTxFunc, account, walletType, amount, interestRateValue, asset, id, market,
) => {
  const marketAddress = market.providerAddress;
  const assetAmount = assetAmountInWei(amount, asset);
  const assetAddress = getAssetInfo(ethToWeth(asset)).address;
  const borrowingEth = asset === 'ETH';

  const loanInfoContract = AaveV3ViewL2Contract();
  const loanInfo = await loanInfoContract.methods.getFullTokensInfo(marketAddress, [assetAddress]).call();
  const availableLiq = assetAmountInEth(loanInfo[0].availableLiquidity.toString(), asset);
  const liqAfterBorrow = new Dec(availableLiq).minus(amount).toString();
  if (new Dec(liqAfterBorrow).lt('0')) {
    throw new Error(`Not enough liquidity. The maximum amount available for borrowing is ${numberWithCommas(Dec.floor(availableLiq).toString())} ${asset}`);
  }

  const lendingPool = new window._web3.eth.Contract(config[market.lendingPool].abi, market.lendingPoolAddress);
  const txParams = { from: account };

  if (borrowingEth) {
    const wethAdapter = AaveV3WETHAdapterL2Contract();
    const funcParams = [market.lendingPoolAddress, assetAmount, interestRateValue, AAVE_REFERRAL_CODE];

    return callTx(accountType, path, sendTxFunc, wethAdapter, 'borrowETH', funcParams, txParams, 'borrowETH');
  }

  const funcParams = [getAssetInfo(asset).address, assetAmount, interestRateValue, AAVE_REFERRAL_CODE, account];
  return callTx(accountType, path, sendTxFunc, lendingPool, 'borrow', funcParams, txParams, 'borrow');
};

/**
 * Calls the repay action on the aave v2
 *
 * @param accountType {String}
 * @param path {String}
 * @param sendTxFunc {Function}
 * @param account {String}
 * @param walletType {Object}
 * @param amount {String}
 * @param asset {String}
 * @param id {Number}
 * @param borrowed {String}
 * @param interestRateValue {Number}
 * @param market
 * @return {Promise<any>}
 */
export const paybackV3 = async (
  accountType, path, sendTxFunc, account, walletType, amount, asset, id, borrowed, interestRateValue, market,
) => {
  let assetAmount = assetAmountInWei(amount, asset);
  let value = assetAmountInWei(new Dec(amount).toString(), asset);

  const balance = await getAssetBalance(asset, account);
  const wholeDebt = new Dec(amount).equals(borrowed) && new Dec(balance).gt(borrowed);
  const paybackEth = asset === 'ETH';

  const lendingPool = new window._web3.eth.Contract(config[market.lendingPool].abi, market.lendingPoolAddress);

  if (wholeDebt) {
    value = assetAmountInWei(new Dec(amount).mul(1.0001).toString(), asset);
    // uint(-1)
    assetAmount = MAXUINT;
  }

  const txParams = {
    from: account,
    value: paybackEth ? value : 0,
  };

  if (paybackEth) {
    const wethAdapter = AaveV3WETHAdapterL2Contract();
    const funcParams = [market.lendingPoolAddress, assetAmount, interestRateValue, account];

    return callTx(accountType, path, sendTxFunc, wethAdapter, 'repayETH', funcParams, txParams, 'repayETH');
  }

  const funcParams = [getAssetInfo(asset).address, assetAmount, interestRateValue, account];

  return callTx(accountType, path, sendTxFunc, lendingPool, 'repay', funcParams, txParams, 'repay');
};

export const approveWethAdapterV3 = async (accountType, path, sendTxFunc, account, stable) => {
  const contract = stable ? AaveV3WETHStableDebtTokenL2Contract() : AaveV3WETHVariableDebtTokenL2Contract();
  const aWethAdapterAddr = getConfigContractAddress('AaveV3WETHAdapterL2');
  const allowance = await contract.methods.borrowAllowance(account, aWethAdapterAddr).call();
  if (allowance.toString() === '0') {
    const funcParams = [aWethAdapterAddr, MAXUINT];
    return callTx(accountType, path, sendTxFunc, contract, 'approveDelegation', funcParams, { from: account }, 'approveDelegation');
  }
};

export const addApproveWethAdapterIfNeededV3 = async (account, stable, txToExecute, approveFunctionObject) => {
  const contract = stable ? AaveV3WETHStableDebtTokenL2Contract() : AaveV3WETHVariableDebtTokenL2Contract();
  const aWethAdapterAddr = getConfigContractAddress('AaveV3WETHAdapterL2');
  const allowance = await contract.methods.borrowAllowance(account, aWethAdapterAddr).call();
  if (allowance.toString() === '0') {
    txToExecute.push(approveFunctionObject);
    return true;
  }
  return false;
};

/**
 * Calls the redeemUnderlying action on the compound contract
 *
 * @param accountType {String}
 * @param path {String}
 * @param sendTxFunc {Function}
 * @param account {String}
 * @param walletType {Object}
 * @param amount {String}
 * @param asset {String}
 * @param id {Number}
 * @param supplied {String}
 * @param market
 * @return {Promise<any>}
 */
export const withdrawV3 = async (
  accountType, path, sendTxFunc, account, walletType, amount, asset, id, supplied, market,
) => {
  let assetAmount = assetAmountInWei(amount, asset);
  const lendingPool = new window._web3.eth.Contract(config[market.lendingPool].abi, market.lendingPoolAddress);
  const withdrawingEth = asset === 'ETH';

  if (new Dec(amount.toString()).equals(supplied)) { assetAmount = MAXUINT; }

  const txParams = { from: account };

  if (withdrawingEth) {
    const wethAdapter = AaveV3WETHAdapterL2Contract();
    const funcParams = [market.lendingPoolAddress, assetAmount, account];

    return callTx(accountType, path, sendTxFunc, wethAdapter, 'withdrawETH', funcParams, txParams, 'withdrawETH');
  }

  const funcParams = [getAssetInfo(asset).address, assetAmount, account];
  return callTx(accountType, path, sendTxFunc, lendingPool, 'withdraw', funcParams, txParams, 'withdraw');
};

/**
 * Swap interest rate for borrowing asset
 *
 * @param accountType
 * @param path
 * @param sendTxFunc
 * @param account
 * @param proxyAddress
 * @param walletType
 * @param assetId
 * @param interestRateValue
 * @param market
 * @returns {Promise<*>}
 */
export const swapInterestRateModeV3 = async (
  accountType, path, sendTxFunc, account, proxyAddress, walletType, assetId, interestRateValue, market,
) => {
  const isProxy = isWalletTypeProxy(walletType);
  const marketAddress = market.providerAddress;

  if (isProxy) {
    const recipe = new dfs.Recipe('recAaveDashAction', [
      new dfs.actions.aaveV3.AaveV3SwapBorrowRateModeAction(
        interestRateValue,
        assetId,
        market.value === 'v3default',
        marketAddress,
      ),
    ]);

    return callRecipeViaProxy(accountType, sendTxFunc, proxyAddress, account, recipe, 0, recipe.extraGas);
  }
  const lendingPool = new window._web3.eth.Contract(config[market.lendingPool].abi, market.lendingPoolAddress);
  const txParams = { from: account };
  const params = [assetId, interestRateValue];

  return callTx(accountType, path, sendTxFunc, lendingPool, 'swapBorrowRateMode', params, txParams, 'swapBorrowRateMode');
};

/**
 * Enable or disable asset as collateral
 *
 * @param accountType
 * @param path
 * @param sendTxFunc
 * @param account
 * @param proxyAddress
 * @param walletType
 * @param assetAddress
 * @param id
 * @param enable
 * @param market
 * @returns {Promise<*>}
 */
export const setAssetAsCollateralV3 = async (accountType, path, sendTxFunc, account, proxyAddress, walletType, assetAddress, id, enable, market) => {
  const lendingPool = new window._web3.eth.Contract(config[market.lendingPool].abi, market.lendingPoolAddress);
  const marketAddress = market.providerAddress;
  const isProxy = isWalletTypeProxy(walletType);

  if (isProxy) {
    const recipe = new dfs.Recipe('recAaveDashAction', [
      new dfs.actions.aaveV3.AaveV3CollateralSwitchAction(
        market.value === 'v3default',
        marketAddress,
        1,
        [id],
        [enable],
      ),
    ]);

    return callRecipeViaProxy(accountType, sendTxFunc, proxyAddress, account, recipe, 0, recipe.extraGas);
  }
  const params = [assetAddress, enable];

  return callTx(accountType, path, sendTxFunc, lendingPool, 'setUserUseReserveAsCollateral', params, { from: account }, 'setUserUseReserveAsCollateral');
};

export const getAaveV3Action = async (action, market, inputAmount, tokenAddr, tokenId, rateMode, enableAsColl, account, proxyAddress, usedAssets, assetsData, assets) => {
  let instantiatedAction;
  const asset = getAssetInfoByAddress(tokenAddr).symbol;
  const useDefaultMarket = market.value === 'v3default';
  const marketAddress = market.providerAddress;

  const isAssetETH = asset === 'ETH';
  const _tokenAddr = isAssetETH ? getAssetInfo('WETH').address : tokenAddr;
  switch (action) {
    case 'collateral': {
      const supplyCap = assetAmountInWei(assetsData[asset].supplyCap, asset);
      if (new Dec(supplyCap).gt(0)) {
        const aaveV3View = (await AaveV3ViewL2Contract().methods.getFullTokensInfo(marketAddress, [_tokenAddr]).call())[0];
        const totalSupply = aaveV3View.totalSupply.toString();
        const supplyAfter = new Dec(inputAmount).add(totalSupply).toString();

        if (new Dec(supplyAfter).gt(supplyCap)) {
          // TODO move all supply/debt cap/ceiling logic to max getters?
          throw new Error(
            `Total supplied would be reached. You can supply ne more than ${numberWithCommas(Dec.floor(assetAmountInEth(new Dec(supplyCap).sub(totalSupply).toString(), asset)).toString())}`,
          );
        }
      }
      if (isAssetETH) {
        instantiatedAction = [
          new dfs.actions.basic.WrapEthAction(inputAmount),
          new dfs.actions.aaveV3.AaveV3SupplyAction(inputAmount, proxyAddress, _tokenAddr, tokenId, enableAsColl, useDefaultMarket, false, marketAddress),
        ];
      } else {
        instantiatedAction = [new dfs.actions.aaveV3.AaveV3SupplyAction(inputAmount, account, _tokenAddr, tokenId, enableAsColl, useDefaultMarket, false, marketAddress)];
      }
      break;
    }
    case 'borrow': {
      const aaveV3View = (await AaveV3ViewL2Contract().methods.getFullTokensInfo(marketAddress, [_tokenAddr]).call())[0];
      const liquidity = new Dec(aaveV3View.totalSupply.toString()).sub(aaveV3View.totalBorrow.toString()).toString();
      const liqAfterBorrow = new Dec(liquidity).sub(inputAmount);

      if (liqAfterBorrow.lt('0')) {
        throw new Error(
          t('compound.missing_liquidity',
            { '%assetAmount': `${numberWithCommas(Dec.floor(assetAmountInEth(liquidity, asset)).toString())} ${asset}` },
          ),
        );
      }

      const isInIsolationMode = Object.values(usedAssets).find(a => assetsData[a.symbol].isIsolated && a.collateral);

      if (isInIsolationMode) { // Check if debt ceiling will be reached after borrow
        const isolatedAsset = isInIsolationMode.symbol;
        const { debtCeilingForIsolationModeUsd, isolationModeTotalDebtUsd } = assetsData[isolatedAsset];
        const inputAmountUsd = new Dec(assetAmountInEth(inputAmount, asset)).mul(assets[asset].aavePrice).toString();

        const isolationDebtAfterBorrow = new Dec(inputAmountUsd).add(isolationModeTotalDebtUsd).toString();

        if (new Dec(isolationDebtAfterBorrow).gt(debtCeilingForIsolationModeUsd)) {
          const leftToBorrow = new Dec(debtCeilingForIsolationModeUsd).sub(isolationModeTotalDebtUsd).div(assets[asset].aavePrice).toString();
          throw new Error(
            t('compound.missing_liquidity',
              { '%assetAmount': `${numberWithCommas(leftToBorrow)} ${asset}` },
            ),
          );
        }
      }
      instantiatedAction = [new dfs.actions.aaveV3.AaveV3BorrowAction(useDefaultMarket, marketAddress, inputAmount, isAssetETH ? proxyAddress : account, rateMode, tokenId, false)];
      if (isAssetETH) instantiatedAction.push(new dfs.actions.basic.UnwrapEthAction(inputAmount, account));
      break;
    }
    case 'withdraw': {
      const amount = assetAmountInWei(usedAssets[asset].supplied, asset) === inputAmount
        ? MAXUINT
        : inputAmount;
      if (isAssetETH) {
        instantiatedAction = [
          new dfs.actions.aaveV3.AaveV3WithdrawAction(tokenId, useDefaultMarket, amount, proxyAddress, marketAddress),
          new dfs.actions.basic.UnwrapEthAction(amount, account),
        ];
      } else {
        instantiatedAction = [new dfs.actions.aaveV3.AaveV3WithdrawAction(tokenId, useDefaultMarket, amount, account, marketAddress)];
      }
      break;
    }
    case 'payback': {
      const amountBorrowed = rateMode === 1 ? usedAssets[asset].borrowedStable : usedAssets[asset].borrowedVariable;
      const wholeDebt = assetAmountInWei(amountBorrowed, asset) === inputAmount;
      const amount = wholeDebt ? MAXUINT : inputAmount;
      if (isAssetETH) {
        // 1.0001x should cover the tx pending for ~3h at a 3% borrow APY
        const wrapAmount = wholeDebt ? new Dec(inputAmount).mul(1.0001).floor().toString() : inputAmount;
        instantiatedAction = [
          new dfs.actions.basic.WrapEthAction(wrapAmount),
          new dfs.actions.aaveV3.AaveV3PaybackAction(useDefaultMarket, marketAddress, amount, proxyAddress, rateMode, _tokenAddr, tokenId, false),
          // Unwrap makes sense only for paybacks over 1.5mil at 100gw
          // and the tradeoff is potentially unexpected behavior, as it would have to unwrap uint(-1)
        ];
      } else {
        instantiatedAction = [new dfs.actions.aaveV3.AaveV3PaybackAction(useDefaultMarket, marketAddress, amount, account, rateMode, _tokenAddr, tokenId, false)];
      }
      break;
    }
    default:
      throw new Error('Unknown action');
  }
  return instantiatedAction;
};

export const getAaveV3Recipe = async (
  primaryAction, primaryInput, secondaryAction, secondaryInput, primaryToken, primaryTokenId, primaryRateMode, primaryEnableAsColl, secondaryToken, secondaryTokenId, secondaryRateMode, secondaryEnableAsColl, market, account, proxyAddress, usedAssets, assetsData, assets,
) => {
  requireAddress(account);
  requireAddress(proxyAddress);
  const recipeActions =
    await Promise.all(
      [{
        market, action: primaryAction, input: primaryInput, token: primaryToken, tokenId: primaryTokenId, rateMode: primaryRateMode, enableAsColl: primaryEnableAsColl,
      },
      {
        market, action: secondaryAction, input: secondaryInput, token: secondaryToken, tokenId: secondaryTokenId, rateMode: secondaryRateMode, enableAsColl: secondaryEnableAsColl,
      }]
        .filter(a => a.action && a.action !== 'send')
        .map(action => getAaveV3Action(action.action, action.market, action.input, action.token, action.tokenId, action.rateMode, action.enableAsColl, account, proxyAddress, usedAssets, assetsData, assets))
        .flat(),
    );

  const name = 'recAaveV3DashAction';
  return new dfs.Recipe(name, recipeActions.flat());
};

/**
 *
 * @param accountType
 * @param account
 * @param proxyAddress
 * @param sendTxFunc
 * @param categoryId
 * @param selectedMarket
 * @returns {Promise<*>}
 */
export const setEMode = (accountType, account, proxyAddress, sendTxFunc, categoryId, selectedMarket) => {
  const useDefaultMarket = selectedMarket.value === 'v3default';
  const market = selectedMarket.providerAddress;

  return callActionViaProxy(accountType, sendTxFunc, proxyAddress, account, new dfs.actions.aaveV3.AaveV3SetEModeAction(categoryId, useDefaultMarket, market));
};
