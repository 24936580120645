import { getAssetInfo, getAssetInfoByAddress } from '@defisaver/tokens';
import { Erc20 } from '../config/config.json';
import { multicall } from './multicallService';
import { momoize } from './memoization';

export const getERC20TokenData = momoize(async (tokenAddress) => {
  const token = getAssetInfoByAddress(tokenAddress);
  if (token.symbol !== '?') return token;
  if (tokenAddress.toLowerCase() === getAssetInfo('ETH').address.toLowerCase()) return getAssetInfo('ETH');
  const multicallArray = [
    {
      target: tokenAddress,
      abiItem: Erc20.abi.find(({ name }) => name === 'name'),
      params: [],
    },
    {
      target: tokenAddress,
      abiItem: Erc20.abi.find(({ name }) => name === 'symbol'),
      params: [],
    },
    {
      target: tokenAddress,
      abiItem: Erc20.abi.find(({ name }) => name === 'decimals'),
      params: [],
    },
  ];

  const multiRes = await multicall(multicallArray);

  return {
    ...(getAssetInfo(multiRes[0]?.symbol)),
    name: multiRes[0][0],
    symbol: multiRes[1][0],
    decimals: multiRes[2][0],
    address: tokenAddress,
  };
}, { promise: true });
