import React from 'react';
import PropTypes from 'prop-types';
import errorIcon from './error-warning.svg';
import './ErrorBox.scss';

const ErrorBox = ({ children, marginTop, marginBottom }) => (
  <div className={`error-box-wrapper ${marginTop ? 'margin-top' : ''} ${marginBottom ? 'margin-bottom' : ''}`}>
    <img src={errorIcon} alt="" height={21} />
    <span>
      {children}
    </span>
  </div>
);

ErrorBox.defaultProps = {
  marginTop: false,
  marginBottom: false,
};

ErrorBox.propTypes = {
  children: PropTypes.node.isRequired,
  marginTop: PropTypes.bool,
  marginBottom: PropTypes.bool,
};

export default ErrorBox;
