import Dec from 'decimal.js';
import dfs from '@defisaver/sdk';
import {
  assetAmountInEth, assetAmountInWei,
  getAssetInfo,
  getAssetInfoByAddress,
} from '@defisaver/tokens';
import { aaveCollateralAssets } from 'constants/assets';
import {
  aaveLendingPoolAddress,
  AaveLendingPoolContract,
  AaveLoanInfoContract,
  getAaveAssetContract,
  stkAAVEContract,
  LENDAddress,
  repAddress,
  AAVEAddress,
  stkAAVEAddress,
  LendToAaveMigratorAddress,
  aaveLendingPoolCoreAddress,
  LendToAaveMigratorContract,
  AaveIncentivesControllerAddress,
  AaveMigrationReceiverAddress,
} from 'services/contractRegistryService';
import { getAssetBalance } from 'services/assetsService';
import {
  calculateBorrowingAssetLimit,
  calculateNetApy,
  isLeveragedPos,
  calcLeverageLiqPrice,
  getAssetsTotal,
} from 'services/moneymarketCommonService';
import {
  compareAddresses, isWalletTypeProxy, numberWithCommas, requireAddress,
} from 'services/utils';
import { callViaProxy } from 'services/contractCallService';
import { getExchangeOrder } from 'services/exchangeServiceV3';

import callTx from 'services/txService';
import { aggregate } from '../ethService';
import { REWARDABLE_ASSETS } from '../../constants/aaveMarkets';
import { CONTINUOUS_FEE_MULTIPLIER } from '../../constants/general';

import { trackEvent } from '../analyticsService';

const AAVE_REFERRAL_CODE = 64;
const AAVE_DISABLED_BORROW_ASSETS = [LENDAddress, repAddress, AAVEAddress];
const AAVE_DISABLED_COLL_ASSETS = [repAddress];

export const aaveManageTrackEventWrapper = (getState, from, method, message = '') => {
  trackEvent(from, method, message);
};

/**
 * @param usedAssets
 * @param assetsData
 * @param assets
 * @param eModeCategory
 * @return {{liquidationLimitUsd: *, suppliedCollateralUsd: *, borrowedUsd: *, suppliedUsd: *, borrowLimitUsd: *, leftToBorrowUsd: *, ratio: *, collRatio: *, netApy: *}}
 */
export const getAggregatedPositionData = (usedAssets, assetsData, assets, eModeCategory = 0) => {
  const payload = {
    suppliedUsd: getAssetsTotal(usedAssets, ({ isSupplied }) => isSupplied, ({ suppliedUsd }) => suppliedUsd),
    suppliedCollateralUsd: getAssetsTotal(usedAssets, ({ symbol, isSupplied, collateral }) => isSupplied && collateral, ({ suppliedUsd }) => suppliedUsd),
    borrowedUsd: getAssetsTotal(usedAssets, ({ isBorrowed }) => isBorrowed, ({ borrowedUsd }) => borrowedUsd),
    borrowLimitUsd: getAssetsTotal(usedAssets, ({ isSupplied, collateral }) => isSupplied && collateral, ({ symbol, suppliedUsd }) => new Dec(suppliedUsd).mul((eModeCategory === 0 || new Dec(assetsData[symbol].eModeCategoryData.collateralFactor).eq(0)) ? assetsData[symbol].collateralFactor : assetsData[symbol].eModeCategoryData.collateralFactor)),
    liquidationLimitUsd: getAssetsTotal(usedAssets, ({ isSupplied, collateral }) => isSupplied && collateral, ({ symbol, suppliedUsd }) => new Dec(suppliedUsd).mul((eModeCategory === 0 || new Dec(assetsData[symbol].eModeCategoryData.liquidationThreshold).eq(0)) ? assetsData[symbol].liquidationRatio : assetsData[symbol].eModeCategoryData.liquidationThreshold)),
  };
  const leftToBorrowUsd = new Dec(payload.borrowLimitUsd).sub(payload.borrowedUsd);
  payload.leftToBorrowUsd = leftToBorrowUsd.lessThanOrEqualTo('0') ? '0' : leftToBorrowUsd.toString();
  payload.ratio = payload.suppliedUsd
    ? new Dec(payload.borrowLimitUsd).div(payload.borrowedUsd).mul(100).toString()
    : '0';
  payload.collRatio = payload.suppliedUsd
    ? new Dec(payload.suppliedCollateralUsd).div(payload.borrowedUsd).mul(100).toString()
    : '0';
  const { netApy, incentiveUsd, totalInterestUsd } = calculateNetApy(usedAssets, assetsData);
  payload.netApy = netApy;
  payload.incentiveUsd = incentiveUsd;
  payload.totalInterestUsd = totalInterestUsd;
  payload.liqRatio = new Dec(payload.borrowLimitUsd).div(payload.liquidationLimitUsd).toString();
  payload.liqPercent = new Dec(payload.borrowLimitUsd).div(payload.liquidationLimitUsd).mul(100).toString();
  const { leveragedType, leveragedAsset } = isLeveragedPos(usedAssets);
  payload.leveragedType = leveragedType;
  if (leveragedType !== '') {
    payload.leveragedAsset = leveragedAsset;
    let assetPrice = assets[leveragedAsset].aavePrice;
    if (leveragedType === 'steth-leverage') assetPrice /= assets.ETH.aavePrice; // Treat ETH like a stablecoin in a long stETH position
    payload.liquidationPrice = calcLeverageLiqPrice(leveragedType, assetPrice, payload.borrowedUsd, payload.liquidationLimitUsd);
  }
  return payload;
};

export const getMaxBorrow = async (account, asset, _assetPrice = '0', ethPrice = '0', bufferPercent = 0) => {
  const loanInfoContract = AaveLoanInfoContract();

  const liquidity = await loanInfoContract.methods.getMaxBorrow(getAssetInfo('ETH').address, account).call();

  const borrowingPowerInEth = liquidity.toString();

  const assetPrice = Dec(_assetPrice).mul(10 ** -(getAssetInfo(asset).decimals - 18));

  const borrowingPowerInToken = asset === 'ETH'
    ? borrowingPowerInEth
    : new Dec(borrowingPowerInEth).mul(ethPrice).div(assetPrice).toDP(getAssetInfo(asset).decimals)
      .toString();

  return assetAmountInEth(Dec(borrowingPowerInToken).mul((100 - bufferPercent) / 100).toString(), asset);
};


/**
 * Calculates the max amount of an asset that the user can withdaw from aave
 * Has 1% buffer if user is borrowing funds
 *
 * @param usedAssetData {Object}
 * @param assetPrice {String}
 * @param assetData {Object}
 * @param borrowedUsd {String}
 * @param liquidationLimitUsd {String}
 * @param ratio {Number}
 * @return {String}
 */
export const getMaxWithdraw = (usedAssetData, assetPrice, assetData, borrowedUsd, liquidationLimitUsd, ratio = 1.01) => {
  const { supplied, collateral } = usedAssetData;
  const { liquidationRatio } = assetData;

  if (supplied.toString() === '0') return '0';
  if (!collateral) return supplied;
  if (borrowedUsd === '0') return supplied;
  const _borrowedUsd = new Dec(borrowedUsd).mul(ratio);

  let leftToBorrowBeforeLiqUsd = new Dec(liquidationLimitUsd).sub(_borrowedUsd);
  if (leftToBorrowBeforeLiqUsd.lt(0)) leftToBorrowBeforeLiqUsd = new Dec(0);
  const withdrawableBeforeLiq = new Dec(leftToBorrowBeforeLiqUsd).div(liquidationRatio).div(assetPrice);

  if (withdrawableBeforeLiq.greaterThan(supplied)) return supplied;
  return withdrawableBeforeLiq.toDP(getAssetInfo(assetData.symbol).decimals).toString();
};

/**
 * Fetch all aave data for address
 *
 * @param address {string}
 * @param assetsData {object} getState().aaveManage[getState().aaveManage.selectedMarket.value].assetsData
 * @param assets {object} getState().assets
 * @returns {Promise<{borrowedUsd: *, suppliedUsd: *, usedAssets}>}
 */
export const getAaveAccountData = async (address, assetsData, assets, walletType = 'proxy') => {
  if (!address) return null;

  let payload = {
    usedAssets: {},
    suppliedUsd: '0',
    borrowedUsd: '0',
    borrowLimitUsd: '0',
    leftToBorrowUsd: '0',
    ratio: '0',
    minRatio: '0',
    netApy: '0',
    incentiveUsd: '0',
    totalInterestUsd: '0',
    isSubscribedToAutomation: false,
    automationResubscribeRequired: false,
    lastUpdated: Date.now(),
  };

  const loanInfoContract = AaveLoanInfoContract();
  const loanInfo = await loanInfoContract.methods.getTokenBalances(address, aaveCollateralAssets.map(a => getAssetInfo(a.underlyingAsset).address)).call();
  const usedAssets = {};

  loanInfo.forEach((tokenInfo, i) => {
    const isSupplied = tokenInfo.balance.toString() !== '0';
    const isBorrowed = tokenInfo.borrows.toString() !== '0';
    if (!isSupplied && !isBorrowed) return;
    const asset = aaveCollateralAssets[i].underlyingAsset;
    const supplied = assetAmountInEth(tokenInfo.balance.toString(), asset);
    const borrowed = assetAmountInEth(tokenInfo.borrows.toString(), asset);
    const enabledAsCollateral = tokenInfo.enabledAsCollateral;
    const interestMode = tokenInfo.borrowRateMode.toString();
    if (!usedAssets[asset]) usedAssets[asset] = {};
    usedAssets[asset] = {
      ...usedAssets[asset],
      symbol: asset,
      supplied,
      suppliedUsd: Dec(supplied).mul(assets[asset].aavePrice).toString(),
      isSupplied,
      earned: '0',
      earnedUsd: '0',
      collateral: enabledAsCollateral,
      borrowed,
      stableBorrowRate: Dec(tokenInfo.borrowRate).div(1e25),
      borrowedUsd: Dec(borrowed).mul(assets[asset].aavePrice).toString(),
      isBorrowed,
      debt: '0',
      debtUsd: '0',
      interestMode,
    };
  });

  payload = {
    ...payload,
    usedAssets,
    ...getAggregatedPositionData(usedAssets, assetsData, assets),
  };

  // payload.leftToBorrowUsd = new Dec(payload.borrowLimitUsd).sub(payload.borrowedUsd).toString();

  payload.ratio = payload.borrowedUsd && payload.borrowedUsd !== '0'
    ? Dec(payload.borrowLimitUsd).div(payload.borrowedUsd).mul(100).toString()
    : '0';
  payload.minRatio = '100';
  payload.collRatio = payload.borrowedUsd && payload.borrowedUsd !== '0'
    ? Dec(payload.suppliedCollateralUsd).div(payload.borrowedUsd).mul(100).toString()
    : '0';

  // Calculate borrow limits per asset
  Object.values(payload.usedAssets).forEach((item) => {
    if (item.isBorrowed) {
      // eslint-disable-next-line no-param-reassign
      item.limit = calculateBorrowingAssetLimit(item.borrowedUsd, payload.borrowLimitUsd);
    }
  });

  const { netApy, incentiveUsd, totalInterestUsd } = calculateNetApy(usedAssets, assetsData);
  payload.netApy = netApy;
  payload.incentiveUsd = incentiveUsd;
  payload.totalInterestUsd = totalInterestUsd;

  payload.isSubscribedToAutomation = false;
  payload.automationResubscribeRequired = false;

  const suppliedAssets = Object.values(usedAssets).filter(({ isSupplied }) => isSupplied);
  payload.suppliedOnlyDai = suppliedAssets.length === 1 && suppliedAssets[0].symbol === 'DAI' && +payload.borrowedUsd === 0;

  return payload;
};


export const getAaveMarketsData = async (addresses) => {
  const loanInfoContract = AaveLoanInfoContract();
  const loanInfo = await loanInfoContract.methods.getFullTokensInfo(addresses).call();
  return loanInfo
    .map((market, i) => ({
      symbol: getAssetInfoByAddress(addresses[i]).symbol,
      underlyingTokenAddress: market.underlyingTokenAddress,
      supplyRate: new Dec(market.supplyRate.toString()).div(1e25),
      borrowRate: new Dec(market.borrowRate.toString()).div(1e25),
      borrowRateStable: new Dec(market.borrowRateStable.toString()).div(1e25),
      collateralFactor: new Dec(market.collateralFactor.toString()).div(100).toString(),
      liquidationRatio: new Dec(market.liquidationRatio.toString()).div(100).toString(),
      marketLiquidity: assetAmountInEth(new Dec(market.totalSupply.toString()).sub(market.totalBorrow.toString()).toString(), getAssetInfoByAddress(addresses[i]).symbol),
      utilization: new Dec(market.totalBorrow.toString()).div(new Dec(market.totalSupply.toString())).times(100).toString(),
      usageAsCollateralEnabled: market.usageAsCollateralEnabled,
      totalSupply: assetAmountInEth(market.totalSupply.toString(), getAssetInfoByAddress(addresses[i]).symbol),
    }))
    .map(assetData => ({
      ...assetData,
      canBeBorrowed: !AAVE_DISABLED_BORROW_ASSETS.find(a => compareAddresses(a, assetData.underlyingTokenAddress)),
      canBeSupplied: !AAVE_DISABLED_COLL_ASSETS.find(a => compareAddresses(a, assetData.underlyingTokenAddress)),
    }));
};


/**
 * Calls the supply action on the compound contract
 *
 * @param accountType {String}
 * @param path {String}
 * @param sendTxFunc {Function}
 * @param account {String}
 * @param proxyAddress {String} user's DSProxy
 * @param walletType {Object}
 * @param _amount {String}
 * @param asset {String}
 * @return {Promise<any>}
 */
export const supply = async (
  accountType, path, sendTxFunc, account, proxyAddress, walletType, _amount, asset,
) => {
  const lendingPool = AaveLendingPoolContract();
  const assetAddress = getAssetInfo(asset).address;

  const value = assetAmountInWei(_amount, asset);

  const txParams = {
    from: account,
    value: asset === 'ETH' ? value : 0,
  };

  const funcParams = [assetAddress, value];

  if (!isWalletTypeProxy(walletType)) funcParams.push(AAVE_REFERRAL_CODE);

  return !isWalletTypeProxy(walletType)
    ? callTx(accountType, path, sendTxFunc, lendingPool, 'deposit', funcParams, txParams, 'deposit')
    : callViaProxy(accountType, sendTxFunc, proxyAddress, account, 'AaveBasicProxy', 'deposit', funcParams, txParams.value);
};


/**
 * Calls the redeemUnderlying action on the compound contract
 *
 * @param accountType {String}
 * @param path {String}
 * @param sendTxFunc {Function}
 * @param account {String}
 * @param proxyAddress {String} user's DSProxy
 * @param walletType {Object}
 * @param _amount {String}
 * @param asset {String}
 * @param supplied {String}
 * @return {Promise<any>}
 */
export const withdraw = async (
  accountType, path, sendTxFunc, account, proxyAddress, walletType, _amount, asset, supplied,
) => {
  const assetAddress = getAssetInfo(asset).address;
  const aAsset = getAssetInfo(`a${asset}`);
  const aAssetAddress = aAsset.address;
  const aAssetContract = getAaveAssetContract(aAsset);
  const walletAddr = isWalletTypeProxy(walletType) ? proxyAddress : account;
  const wholeSupply = Dec(_amount.toString()).equals(supplied);

  let value = assetAmountInWei(_amount, asset);
  if (wholeSupply) {
    value = assetAmountInWei(await getAssetBalance(`a${asset}`, walletAddr), `a${asset}`);
  }

  return !isWalletTypeProxy(walletType)
    ? callTx(accountType, path, sendTxFunc, aAssetContract, 'redeem', [value], { from: account }, 'redeem')
    : callViaProxy(accountType, sendTxFunc, proxyAddress, account, 'AaveBasicProxy', 'withdraw', [assetAddress, aAssetAddress, value, wholeSupply], 0);
};


/**
 * Calls the borrow action on the compound contract
 *
 * @param accountType {String}
 * @param path {String}
 * @param sendTxFunc {Function}
 * @param account {String}
 * @param proxyAddress {String} user's DSProxy
 * @param walletType {Object}
 * @param _amount {String}
 * @param interestRateValue {Number}
 * @param asset {String} underlying asset symbol
 * @return {Promise<any>}
 */
export const borrow = async (
  accountType, path, sendTxFunc, account, proxyAddress, walletType, _amount, interestRateValue, asset,
) => {
  const assetAddress = getAssetInfo(asset).address;
  const lendingPool = AaveLendingPoolContract();

  const loanInfoContract = AaveLoanInfoContract();
  const loanInfo = await loanInfoContract.methods.getFullTokensInfo([assetAddress]).call();
  const availableLiq = assetAmountInEth(Dec(loanInfo[0].totalSupply.toString()).sub(loanInfo[0].totalBorrow.toString()).toString(), asset);
  const liqAfterBorrow = Dec(availableLiq).minus(_amount).toString();
  if (Dec(liqAfterBorrow).lt('0')) {
    throw new Error(`Not enough liquidity. The maximum amount available for borrowing is ${numberWithCommas(Dec.floor(availableLiq).toString())} ${asset}`);
  }

  const value = assetAmountInWei(_amount, asset);


  const funcParams = [assetAddress, value, interestRateValue]; // 3rd param is 2 for variable rate and 1 for fixed rate
  if (!isWalletTypeProxy(walletType)) funcParams.push(AAVE_REFERRAL_CODE);

  return !isWalletTypeProxy(walletType)
    ? callTx(accountType, path, sendTxFunc, lendingPool, 'borrow', funcParams, { from: account }, 'borrow')
    : callViaProxy(accountType, sendTxFunc, proxyAddress, account, 'AaveBasicProxy', 'borrow', funcParams, 0);
};


/**
 * Calls the repay action on the compound contract
 *
 * @param accountType {String}
 * @param path {String}
 * @param sendTxFunc {Function}
 * @param account {String}
 * @param proxyAddress {String} user's DSProxy
 * @param walletType {Object}
 * @param _amount {String}
 * @param asset {String}
 * @param borrowed {String}
 * @return {Promise<any>}
 */
export const payback = async (
  accountType, path, sendTxFunc, account, proxyAddress, walletType, _amount, asset, borrowed,
) => {
  const lendingPool = AaveLendingPoolContract();
  const assetAddress = getAssetInfo(asset).address;
  const aAssetAddress = getAssetInfo(`a${asset}`).address;

  let txParams = {};

  const balance = await getAssetBalance(asset, account);
  let value = assetAmountInWei(_amount, asset);
  const wholeDebt = Dec(_amount).equals(borrowed) && Dec(balance).gt(borrowed);
  if (wholeDebt) {
    // it is ok to send larger amount when repaying all debt. However uint(-1) doesn't work for proxy
    value = assetAmountInWei(Dec.min(await getAssetBalance(asset, account), _amount * CONTINUOUS_FEE_MULTIPLIER).toString(), asset);
    const { originationFee } = await lendingPool.methods.getUserReserveData(assetAddress, isWalletTypeProxy(walletType) ? proxyAddress : account).call();
    value = Dec(value).sub(originationFee).toString();
  }

  if (asset !== 'ETH') {
    txParams = { from: account };
  } else {
    txParams = { from: account, value };
  }

  return !isWalletTypeProxy(walletType)
    ? callTx(accountType, path, sendTxFunc, lendingPool, 'repay', [assetAddress, value, account], txParams)
    : callViaProxy(accountType, sendTxFunc, proxyAddress, account, 'AaveBasicProxy', 'payback', [assetAddress, aAssetAddress, value, false], txParams.value);
};

/**
 * Enables/disables an asset to be used as borrowing collateral
 *
 * @param accountType {String}
 * @param path {String}
 * @param sendTxFunc {Function}
 * @param account {String}
 * @param proxyAddress {String} user's DSProxy
 * @param walletType {Object}
 * @param assetAddress {String}
 * @param enable {boolean}
 * @return {Promise<unknown>}
 */
export const setAssetAsCollateral = async (accountType, path, sendTxFunc, account, proxyAddress, walletType, assetAddress, enable) => {
  const lendingPool = AaveLendingPoolContract();
  const funcName = 'setUserUseReserveAsCollateral';
  const params = [assetAddress, enable];
  return (!isWalletTypeProxy(walletType)
    ? callTx(accountType, path, sendTxFunc, lendingPool, funcName, params, { from: account }, funcName)
    : callViaProxy(accountType, sendTxFunc, proxyAddress, account, 'AaveBasicProxy', funcName, params, 0));
};

/**
 * Calls Boost/Repay via proxy
 *
 * @param getState {function}
 * @param amountParam {String} in wei
 * @param buyToken {String} debt asset symbol
 * @param sellToken {String} collateral asset symbol
 * @param funcName {String}
 * @param sendTxFunc {Function}
 * @param useFlashLoan {Boolean}
 * @return {Promise}
 */
export const callSaverProxyContract = async (
  getState, amountParam, buyToken, sellToken, funcName, sendTxFunc,
) => {
  const { account, accountType } = getState().general;
  const { proxyAddress } = getState().maker;
  const { slippagePercent, boostExchangeRate, repayExchangeRate } = getState().aaveManage;
  const isBoost = funcName === 'boost' || funcName === 'boostWithLoan';
  const price = isBoost ? boostExchangeRate : repayExchangeRate;

  if (isBoost) {
    const _address = getAssetInfo(sellToken).address;
    const loanInfoContract = AaveLoanInfoContract();
    const loanInfo = await loanInfoContract.methods.getFullTokensInfo([_address]).call();
    const availableLiq = assetAmountInEth(Dec(loanInfo[0].totalSupply.toString()).sub(loanInfo[0].totalBorrow.toString()).toString(), sellToken);
    const liqAfterBorrow = Dec(availableLiq).minus(assetAmountInEth(amountParam, sellToken)).toString();
    if (Dec(liqAfterBorrow).lt('0')) {
      throw new Error(`Not enough liquidity. The maximum amount available for borrowing is ${numberWithCommas(Dec.floor(availableLiq).toString())} ${sellToken}`);
    }
  }

  const { orderData, value, extraGas } = await getExchangeOrder(
    sellToken,
    buyToken,
    assetAmountInEth(amountParam, sellToken),
    price,
    slippagePercent,
    proxyAddress,
    false,
  );

  const gasCost = 0;
  const params = [orderData, gasCost];

  return callViaProxy(accountType, sendTxFunc, proxyAddress, account, 'AaveSaverProxy', funcName, params, value, 0, extraGas);
};


export const importLoan = async (
  accountType, path, sendTxFunc, account, proxyAddress, collCAsset, borrowCAsset, flashLoanSize,
) => {
  const collAssetAddress = getAssetInfo(collCAsset).address;
  const borrowAssetAddress = getAssetInfo(borrowCAsset).address;
  const flashLoanSizeWei = assetAmountInWei(flashLoanSize, 'ETH');
  return callViaProxy(accountType, sendTxFunc, proxyAddress, account, 'AaveImportTaker', 'importLoan', [collAssetAddress, borrowAssetAddress, flashLoanSizeWei], 0);
};


/**
 * Swap interest rate mode for borrowed asset
 *
 * @param accountType {String}
 * @param path {String}
 * @param sendTxFunc {Function}
 * @param account {String}
 * @param proxyAddress {String} user's DSProxy
 * @param walletType {Object}
 * @param assetAddress {String}
 * @return {Promise<unknown>}
 */
export const swapInterestRateMode = async (accountType, path, sendTxFunc, account, proxyAddress, walletType, assetAddress) => {
  const lendingPool = AaveLendingPoolContract();
  const funcName = 'swapBorrowRateMode';
  const params = [assetAddress];
  return (!isWalletTypeProxy(walletType)
    ? callTx(accountType, path, sendTxFunc, lendingPool, funcName, params, { from: account }, funcName)
    : callViaProxy(accountType, sendTxFunc, proxyAddress, account, 'AaveBasicProxy', funcName, params, 0));
};


export const getStakeAaveBalanceAndCooldown = async (address, proxyAddress) => {
  const multicallCallsObject = [
    {
      target: stkAAVEAddress,
      call: ['getTotalRewardsBalance(address)(uint)', address],
      returns: [
        ['rewardBalance', val => assetAmountInEth(val, 'AAVE')],
      ],
    },
    {
      target: stkAAVEAddress,
      call: ['stakersCooldowns(address)(uint)', address],
      returns: [
        ['activatedCooldown', val => val.toString()],
      ],
    },
    {
      target: stkAAVEAddress,
      call: ['COOLDOWN_SECONDS()(uint)'],
      returns: [
        ['cooldownSeconds', val => val.toString()],
      ],
    },
    {
      target: stkAAVEAddress,
      call: ['UNSTAKE_WINDOW()(uint256)'],
      returns: [
        ['unstakeWindow', val => val.toString()],
      ],
    },
    {
      target: stkAAVEAddress,
      call: ['totalSupply()(uint256)'],
      returns: [
        ['totalStkAAVESupply', val => assetAmountInEth(val, 'AAVE')],
      ],
    },
    {
      target: LendToAaveMigratorAddress,
      call: ['LEND_AAVE_RATIO()(uint256)'],
      returns: [
        ['lendAaveRatio', val => val.toString()],
      ],
    },
    {
      target: AaveIncentivesControllerAddress,
      call: ['getRewardsBalance(address[],address)(uint256)', REWARDABLE_ASSETS, address],
      returns: [
        ['stkAaveRewardBalanceAcc', val => assetAmountInEth(val, 'stkAAVE')],
      ],
    },
  ];

  if (proxyAddress) {
    multicallCallsObject.push({
      target: AaveIncentivesControllerAddress,
      call: ['getRewardsBalance(address[],address)(uint256)', REWARDABLE_ASSETS, proxyAddress],
      returns: [
        ['stkAaveRewardBalanceProxy', val => assetAmountInEth(val, 'stkAAVE')],
      ],
    });
  }

  const multiRes = await aggregate(multicallCallsObject);


  const {
    rewardBalance, cooldownSeconds, unstakeWindow, lendAaveRatio, activatedCooldown, totalStkAAVESupply,
    stkAaveRewardBalanceAcc, stkAaveRewardBalanceProxy,
  } = multiRes.results.transformed;

  return {
    rewardBalance, cooldownSeconds, unstakeWindow, lendAaveRatio, activatedCooldown, totalStkAAVESupply, stkAaveRewardBalanceAcc, stkAaveRewardBalanceProxy,
  };
};

// Stake only with account and not proxy
export const stakeAave = async (accountType, path, sendTxFunc, account, proxyAddress, walletType, _amount) => {
  const contract = stkAAVEContract();
  const funcName = 'stake';

  const amount = assetAmountInWei(_amount, 'AAVE');
  const params = [account, amount];
  return callTx(accountType, path, sendTxFunc, contract, funcName, params, { from: account }, funcName);
};

export const claimRewards = async (accountType, path, sendTxFunc, account, proxyAddress, walletType) => {
  const contract = stkAAVEContract();
  const funcName = 'claimRewards';
  // The amount of AAVE to be claimed. Use uint(-1) to claim all outstanding rewards for the user.
  const amount = '115792089237316195423570985008687907853269984665640564039457584007913129639935';
  const params = [account, amount];
  return callTx(accountType, path, sendTxFunc, contract, funcName, params, { from: account }, funcName);
};

export const activateUnstakeCooldown = async (accountType, path, sendTxFunc, account, proxyAddress, walletType, cooldownWindow, unstakeWindow) => {
  const contract = stkAAVEContract();
  const _alreadyActivatedCooldown = await contract.methods.stakersCooldowns(account).call();
  const alreadyActivatedCooldown = _alreadyActivatedCooldown.toString();
  const cooldownPlus12Days = Dec(alreadyActivatedCooldown).add(864000).add(172800); // cooldown window(10 days) + unstake window(2 days)
  if (alreadyActivatedCooldown !== '0' && Dec(cooldownPlus12Days).gt(Dec(Date.now()).div(1000))) {
    throw new Error('Already have active cooldown timer');
  }
  const funcName = 'cooldown';
  const params = [];
  return callTx(accountType, path, sendTxFunc, contract, funcName, params, { from: account }, funcName);
};

export const unstakeAave = async (accountType, path, sendTxFunc, account, proxyAddress, walletType, _amount) => {
  const contract = stkAAVEContract();
  const funcName = 'redeem';
  const amount = assetAmountInWei(_amount, 'AAVE');

  const params = [account, amount];
  return callTx(accountType, path, sendTxFunc, contract, funcName, params, { from: account }, funcName);
};

export const migrateLendToAave = async (accountType, path, sendTxFunc, account, proxyAddress, walletType, _amount) => {
  const contract = LendToAaveMigratorContract();
  const funcName = 'migrateFromLEND';
  const amount = assetAmountInWei(_amount, 'LEND');

  const params = [amount];
  return callTx(accountType, path, sendTxFunc, contract, funcName, params, { from: account }, funcName);
};

export const getAvailableLiqForAsset = async (asset) => {
  const _address = getAssetInfo(asset).address;
  const loanInfoContract = AaveLoanInfoContract();
  const loanInfo = await loanInfoContract.methods.getFullTokensInfo([_address]).call();

  return assetAmountInEth(Dec(loanInfo[0].totalSupply.toString()).sub(loanInfo[0].totalBorrow.toString()).toString(), asset);
};


export const migrateFromV1ToV2 = async (accountType, sendTxFunc, account, proxyAddress, suppliedAssets, isColl, borrowedAssets, flModes, market) => {
  const funcName = 'migrateV1Position';

  const params = [market.providerAddress, suppliedAssets, isColl, borrowedAssets, flModes, AaveMigrationReceiverAddress];
  return callViaProxy(accountType, sendTxFunc, proxyAddress, account, 'AaveMigrationTaker', funcName, params, 0);
};
