import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withErrorBoundary } from 'react-error-boundary';
import * as Sentry from '@sentry/browser';

import './ErrorFallback.scss';

const ErrorFallback = ({ error, componentStack }) => {
  const [showDebug, toggleDebug] = useState(false);

  if (error?.message?.startsWith('Loading chunk')) {
    setTimeout(() => document.location.reload(), 3000);
    return (
      <div className="error-fallback-wrapper warning">
        <h2>Reloading the page due to a DeFi Saver update. </h2>
        <p>You should be back with your DeFi in a second. </p>
      </div>
    );
  }

  return (
    <div className="error-fallback-wrapper">
      <h2>An error occured while rendering the component.</h2>
      <a role="button" tabIndex={0} onClick={() => toggleDebug(!showDebug)}>Show debug information</a>
      {
        showDebug && (
          <pre>
              {error.message}
            {componentStack}
          </pre>
        )
      }
    </div>
  );
};

ErrorFallback.propTypes = {
  error: PropTypes.any.isRequired,
  componentStack: PropTypes.string.isRequired,
};

ErrorFallback.defaultProps = {};

const withErrorFallback = C => withErrorBoundary(C, {
  FallbackComponent: ErrorFallback,
  onError: (error, componentStack) => {
    Sentry.captureException(error, { contexts: { react: { componentStack } } });
  },
});

export default withErrorFallback;
