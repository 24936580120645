import React from 'react';

export default function BridgeIcon(props) {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="30" height="30" rx="3" fill="#3A4750" />
      <path d="M5.77783 11.8222V18.2H9.42227C9.42227 15.181 11.87 12.7333 14.8889 12.7333C17.9079 12.7333 20.3556 15.181 20.3556 18.2H24V11.8222C24 11.8222 18.5174 10 14.8889 10C11.2604 10 5.77783 11.8222 5.77783 11.8222Z" fill="#626D75" />
      <path d="M5 20.55L7.18394 19.8948C7.9337 19.6699 8.73297 19.6699 9.48272 19.8948L10.5173 20.2052C11.267 20.4301 12.0663 20.4301 12.8161 20.2052L13.8506 19.8948C14.6004 19.6699 15.3996 19.6699 16.1494 19.8948L17.1839 20.2052C17.9337 20.4301 18.733 20.4301 19.4827 20.2052L20.5173 19.8948C21.267 19.6699 22.0663 19.6699 22.8161 19.8948L25 20.55" stroke="#626D75" strokeLinecap="round" />
    </svg>
  );
}
