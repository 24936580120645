import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import t from 'translate';
import ModalHeader from '../../ModalHeader';
import ModalBody from '../../ModalBody';
import './MakerCreateCdpSuccessModal.scss';
import success from '../../../Exchange/exchange-success.svg';

class MakerCreateCdpSuccessModal extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { closeModal, cdp, history } = this.props;
    return (
      <div className="maker-create-cdp-success-modal-wrapper">

        <ModalHeader closeModal={closeModal} />

        <ModalBody>
          <div className="modal-content">
            <img src={success} alt="" />

            <div className="title">{t('maker.cdp_created_successfully', { cdpId: cdp.id })}</div>

            <div className="container">
              <div className="description WhiteSpacePre">
                {t('maker.cdp_created_successfully_desc')}
              </div>
            </div>
          </div>
        </ModalBody>
        <div className="modal-controls">
          <button
            type="button"
            className="button"
            form="gas-price-modal-form"
            onClick={closeModal}
          >
            { t('common.set_up_later') }
          </button>

          <button
            type="button"
            className="button green"
            onClick={() => {
              history.push('/makerdao/strategies/');
              closeModal();
            }}
          >
            { t('common.automate') }
          </button>
        </div>
      </div>
    );
  }
}

MakerCreateCdpSuccessModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  cdp: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

MakerCreateCdpSuccessModal.defaultProps = {};

const mapStateToProps = ({ maker }) => ({
  cdp: maker.cdp,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MakerCreateCdpSuccessModal));
