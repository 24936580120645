import React from 'react';
import t from 'translate';
import PropTypes from 'prop-types';
import ModalHeader from '../ModalHeader';
import ModalBody from '../ModalBody';
import AlertIcon from '../../Decorative/AlertIcon';

import './NonWeb3ProviderModal.scss';
import CoinbaseLogo from './coinbase-logo.png';
import OperaLogo from './opera-logo.png';
import StatusLogo from './status-logo.png';
import TrustLogo from './trust-logo.png';

const NonWeb3ProviderModal = ({ closeModal, os }) => {
  const LinkWrapper = ({ link, image, alt }) => { // eslint-disable-line
    if (!link) return (<img src={image} alt={alt} />);

    return (
      <a target="_blank" rel="noopener noreferrer" href={link}>
        <img src={image} alt={alt} />
      </a>
    );
  };

  const LINKS = {
    coinbase: {
      Android: 'https://play.google.com/store/apps/details?id=org.toshi&hl=en',
      iOS: 'https://itunes.apple.com/us/app/coinbase-wallet/id1278383455?mt=8',
    },
    trust: {
      Android: 'https://play.google.com/store/apps/details?id=com.wallet.crypto.trustapp&hl=en',
      iOS: 'https://itunes.apple.com/us/app/trust-crypto-bitcoin-wallet/id1288339409?mt=8',
    },
    opera: {
      Android: 'https://play.google.com/store/apps/details?id=com.opera.browser',
      iOS: 'https://apps.apple.com/us/app/opera-touch-web-browser/id1411869974',
    },
    status: {
      Android: 'https://play.google.com/store/apps/details?id=im.status.ethereum&hl=en',
      iOS: 'https://testflight.apple.com/join/J8EuJmey',
    },
  };

  const MOBILE_BROWSERS_ROW_1 = [
    {
      alt: 'Coinbase logo',
      link: LINKS.coinbase[os],
      image: CoinbaseLogo,
    },
    {
      alt: 'Trust logo',
      link: LINKS.trust[os],
      image: TrustLogo,
    },
  ];

  const MOBILE_BROWSERS_ROW_2 = [
    {
      alt: 'Opera logo',
      link: LINKS.opera[os],
      image: OperaLogo,
    },
    {
      alt: 'Status logo',
      link: LINKS.status[os],
      image: StatusLogo,
    },
  ];

  return (
    <div id="non-web3-provider-modal-wrapper">
      <ModalHeader closeModal={closeModal} />

      <ModalBody>
        <div className="modal-content">
          <h3 className="title">{t('misc.non_web3')}</h3>

          <AlertIcon />

          <div className="description">
            {t('misc.web3_browser_info')}
          </div>
        </div>
      </ModalBody>

      <div className="links-container">
        <div className="links-wrapper">
          <div className="row">
            { MOBILE_BROWSERS_ROW_1.map(props => <LinkWrapper {...props} />) }
          </div>

          <div className="row">
            { MOBILE_BROWSERS_ROW_2.map(props => <LinkWrapper {...props} />) }
          </div>
        </div>
      </div>

      <div className="modal-controls">
        <button type="button" className="button green" onClick={closeModal}>
          {t('common.close')}
        </button>
      </div>
    </div>
  );
};

NonWeb3ProviderModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  os: PropTypes.string.isRequired,
};

export default NonWeb3ProviderModal;
