import React from 'react';
import { v4 as uuid } from 'uuid';

export default function DodoIcon(props) {
  const id = `dodo_icon${uuid()}`;
  const fill = `url(#${id})`;

  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z" fill={fill} />
      <path fillRule="evenodd" clipRule="evenodd" d="M14.8709 6.1018C14.7294 6.1577 14.5112 6.57366 14.386 7.02661C14.2171 7.63733 14.0975 7.79062 13.9229 7.62066C13.2266 6.94245 12.5414 7.70198 12.9368 8.71441C13.0577 9.02424 13.1305 9.29829 13.0986 9.3237C13.0666 9.34891 12.5569 9.52355 11.9657 9.7116C8.60751 10.7797 6.77948 13.3826 6.13444 18.014L6 18.9797H7.23915H8.4783L8.71022 17.52C9.31476 13.717 11.9367 11.6005 15.6276 11.9361C18.9276 12.2364 21.0852 14.3387 21.4564 17.6157L21.5994 18.878L22.7997 18.939L24 19V18.0849C24 14.2834 21.7785 11.0139 18.3318 9.7425L17.3602 9.38429L17.3924 8.43874C17.4229 7.53446 17.4001 7.49481 16.8691 7.52897C16.4271 7.55743 16.2852 7.45375 16.1737 7.02194C15.9343 6.09245 15.5621 5.82958 14.8709 6.1018ZM12.4366 14.6254C12.273 15.0405 12.273 16.4132 12.4366 16.8283C12.5064 17.0052 12.7782 17.15 13.0406 17.15C13.5958 17.15 13.7712 16.8084 13.7712 15.7269C13.7712 14.6453 13.5958 14.3038 13.0406 14.3038C12.7782 14.3038 12.5064 14.4485 12.4366 14.6254ZM16.5766 14.5678C16.5131 14.7289 16.49 15.3528 16.5252 15.9544C16.5847 16.969 16.6271 17.0483 17.1112 17.0483C17.6143 17.0483 17.6331 17.0009 17.6331 15.7269C17.6331 14.5105 17.5957 14.4003 17.1626 14.3404C16.8949 14.3033 16.6421 14.4015 16.5766 14.5678Z" fill="white" />
      <defs>
        <linearGradient id={id} x1="15" y1="0" x2="15" y2="30" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FBE94E" />
          <stop offset="1" stopColor="#ACA03A" />
        </linearGradient>
      </defs>
    </svg>
  );
}
