import {
  CREATE_CDP_REQUEST,
  CREATE_CDP_SUCCESS,
  CREATE_CDP_FAILURE,

  SET_CREATE_CDP_CALC_VALUES,

  RESET_CREATE_CDP_FORM,
} from '../../actionTypes/makerActionTypes/makerCreateCdpActionTypes';

const INITIAL_STATE = {
  creatingCdp: false,
  creatingCdpError: '',

  newCdpRatio: 0,
  newCdpLiqRatio: 0,
  newCdpMinRatio: 0,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case CREATE_CDP_REQUEST:
      return { ...state, creatingCdp: true, creatingCdpError: '' };

    case CREATE_CDP_SUCCESS:
      return { ...state, creatingCdp: false, creatingCdpError: '' };

    case CREATE_CDP_FAILURE:
      return { ...state, creatingCdp: false, creatingCdpError: payload };

    case SET_CREATE_CDP_CALC_VALUES:
      return {
        ...state,
        newCdpRatio: payload.ratio,
        newCdpLiqRatio: payload.liqRatio,
        newCdpMinRatio: payload.minRatio,
      };

    case RESET_CREATE_CDP_FORM:
      return {
        ...state,
        creatingCdp: false,
        creatingCdpError: '',
        newCdpRatio: 0,
        newCdpLiqRatio: 0,
        newCdpMinRatio: 0,
      };

    default:
      return state;
  }
};
