import cloneDeep from 'lodash/cloneDeep';
import memoize from 'memoizee';
import dfs from '@defisaver/sdk';
import { Amount, CdpId, Source } from 'components/Recipes/RecipeCreator/inputTypes';
import { getSafeInfo as _getSafeInfo, getRaiIlkInfo } from '../../services/reflexerServices/reflexerService';
import { addCollateralAfterValues } from '../../actions/reflexerActions/reflexerManageAfterValues';
import { formatNumber } from '../../services/utils';
import { assetAmountInWeiIgnorePointer } from '../../services/recipeCreator/recipeActionUtils';
import { MAXUINT } from '../../constants/general';

import RecipeAction from '../RecipeAction';
import SupplyIcon from '../recipeIcons/Supply.svg';


const getSafeInfo = memoize(_getSafeInfo, { maxAge: 2 * 60 * 1000 });

export default class ReflexerSupplyAction extends RecipeAction {
  static prettyName = 'Supply collateral to Safe';

  static protocol = 'reflexer';

  static protocolPrettyName = 'Reflexer';

  static description = 'Deposits collateral into selected Safe.';

  constructor(safeId = 0, from = 'wallet', amount = '') {
    super();
    this.inputs = [
      new CdpId('Safe ID', safeId, 'reflexer'),
      new Source('Source', from),
      new Amount('Amount', amount),
    ];
    this.output = new Amount('output', 0);
    this.asset = '';
  }

  async getAfterValues(_balances = {}, returnValues = [], actions = [], _positions = {}, getState) {
    const positions = { ..._positions };
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const safeId = args[0];
    if (!positions[`reflexer_${safeId}`]) positions[`reflexer_${safeId}`] = getState().reflexer.safes[safeId];
    const asset = positions[`reflexer_${safeId}`].asset.replace(/^ETH/, 'WETH');
    let amount = args[2] || '0';
    const currentBalances = cloneDeep(_balances);
    const isSourceWallet = args[1] === 'wallet';
    // if asset isn't used in earlier actions
    const balanceAtStart = isSourceWallet ? getState().assets[asset]?.balance || '0' : '0';
    if (amount === 'All available') amount = currentBalances?.[args[1]]?.[asset] || balanceAtStart;
    const { afterPosition, balances, returnValue } = await addCollateralAfterValues(
      {
        amount,
        from: args[1],
      },
      {
        vault: positions[`reflexer_${safeId}`],
        assets: getState().assets,
        account: getState().general.account,
        proxyAddress: getState().maker.proxyAddress,
      },
      _balances,
    );
    positions[`reflexer_${safeId}`] = afterPosition;
    this.output.value = returnValue;
    return { returnValue: this.output, balances, positions };
  }

  async toDfsAction(getState, actions, returnValues, positions) {
    const {
      general: { account },
      maker: { proxyAddress },
      reflexer: { safes },
    } = getState();
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const from = this.inputs[1].value === 'wallet' ? account : proxyAddress;
    const safe = positions[`reflexer_${args[0]}`] || await getSafeInfo(safes[args[0]]);
    const amount = this.inputs[2].value === 'All available' ? MAXUINT : assetAmountInWeiIgnorePointer(this.inputs[2].value, safe.asset);
    return new dfs.actions.reflexer.ReflexerSupplyAction(this.inputs[0].value, amount, getRaiIlkInfo(safe.collType).join, from);
  }

  static getIcon() {
    return SupplyIcon;
  }

  _getPrettyName(actionCalls, actions) {
    const args = this.mapReturnValuesToArgs(actionCalls.map(a => a.returnValue), actions);
    const positions = actionCalls[actionCalls.length - 1]?.positions;
    const safe = positions?.[`reflexer_${args[0]}`];
    if (!safe) return this.constructor.prettyName;
    const amount = args[2];
    return `Supply ${amount === 'All available' ? 'all' : formatNumber(amount)} ${safe?.asset.replace(/^ETH/, 'WETH')} to Safe #${args[0]}`;
  }

  setAsset(asset) {
    this.asset = asset;
  }

  getAsset() {
    return this.asset;
  }
}
