import {
  GET_SERVER_NOTIFICATIONS_REQUEST,
  GET_SERVER_NOTIFICATIONS_SUCCESS,
  GET_SERVER_NOTIFICATIONS_FAILURE,

  MARK_AS_READ_REQUEST,
  MARK_AS_READ_SUCCESS,
  MARK_AS_READ_FAILURE,

  CHANGE_SERVER_NOTIFICATION,
} from '../actionTypes/serverNotificationsActionTypes';
import { ACCOUNT_TYPES } from '../constants/general';
import { getServerNotificationsApiCall, markServerNotificationsAsReadApiCall } from '../services/apiService';

/**
 * Replaces a notifications contents with new ones
 *
 * @param id {String}
 * @param changes {Object}
 * @return {Function}
 */
export const changeServerNotification = (id, changes) => (dispatch, getState) => {
  const notifications = [...getState().serverNotifications.notifications];
  const index = notifications.findIndex(notif => notif.id === id);

  if (index < 0) return;

  notifications.splice(index, 1, { ...notifications[index], ...changes });

  dispatch({ type: CHANGE_SERVER_NOTIFICATION, payload: notifications });
};

/**
 * Gets notifications from the server
 * @return {Function}
 */
export const getServerNotifications = () => async (dispatch, getState) => {
  if (!getState().general.account) return;
  if (getState().general.accountType === ACCOUNT_TYPES.fork) return;

  const { accountType } = getState().general;
  const isDebug = accountType === ACCOUNT_TYPES.viewOnly || accountType === ACCOUNT_TYPES.debug;

  dispatch({ type: GET_SERVER_NOTIFICATIONS_REQUEST });

  try {
    let payload = await getServerNotificationsApiCall(getState().general.account, isDebug);

    const notifications = isDebug ? payload.notifications.notifications : payload.notifications;
    payload = notifications.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

    if (!payload) payload = [];

    dispatch({ type: GET_SERVER_NOTIFICATIONS_SUCCESS, payload });
  } catch (err) {
    dispatch({ type: GET_SERVER_NOTIFICATIONS_FAILURE, payload: err.message });
  }
};

/**
 * Fetches server notifications on call and then again every one minute
 * @return {Function}
 */
export const updateServerNotificationsInterval = () => (dispatch) => {
  dispatch(getServerNotifications());
  return setTimeout(() => dispatch(updateServerNotificationsInterval()), 30000);
};

/**
 * Marks a single notification as read on the server
 *
 * @param id {String}
 * @return {Function}
 */
export const markServerNotificationsAsRead = id => async (dispatch, getState) => {
  dispatch({ type: MARK_AS_READ_REQUEST });

  try {
    await markServerNotificationsAsReadApiCall(getState().general.account, id);

    dispatch({ type: MARK_AS_READ_SUCCESS });
  } catch (err) {
    dispatch({ type: MARK_AS_READ_FAILURE, payload: err.message });
  }
};
