import React, { useEffect, useMemo, useState } from 'react';
import Dec from 'decimal.js';
import t from 'translate';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { resetBoostModal } from '../../../../actions/makerActions/makerManageActions/makerManageActions';
import { aaveBoostActionV2, setAaveBorrowInterestRateAction, getBoostModalData } from '../../../../actions/aaveActions/aaveManageActionsV2';
import { getDashboardInputs } from '../../../../actions/dashboardActions';

import { AaveBoostSlippageLimitFormV2 as SlippageLimitForm } from '../../BoostRepayCommon/SlippageLimitForm/SlippageLimitForm';
import ModalHeader from '../../ModalHeader';
import ModalBody from '../../ModalBody';
import Loader from '../../../Loader/Loader';
import ErrorBox from '../../../Decorative/ErrorBox/ErrorBox';
import TooltipWrapper from '../../../TooltipWrapper/TooltipWrapper';
import TradeSizeImpactWarning from '../../../TradeSizeImpactWarning/TradeSizeImpactWarning';
import InfoIcon from '../../../Decorative/InfoIcon';
import ActionInfo from '../../BoostRepayCommon/ActionInfo';
import AdvancedOptions from '../../BoostRepayCommon/AdvancedOptions/AdvancedOptions';
import AdditionalInfo from '../../../AdditionalInfo/AdditionalInfo';
import WarningBox from '../../../Decorative/WarningBox/WarningBox';


const interestRateOptions = [
  { value: 1, name: 'Stable' },
  { value: 2, name: 'Variable' },
];

const AaveBoostModalV2 = ({
  closeModal, gettingBoostModalData, gettingBoostModalDataError, boostAmount, boostExchangeRate, boosting, assetsData,
  tradeSizeImpact, boostingError, useFl, flProtocol, exchangeSource, flFee, additionalAction, contextAction, activeTab,
  usedAssets,
}) => {
  const [advanced, setAdvanced] = useState(false);
  const dispatch = useDispatch();
  const {
    firstInput: inputAmount, secondAction, secondInput, firstInputSelect, firstInputSecondSelect, secondInputSelect,
  } = useMemo(() => dispatch(getDashboardInputs(contextAction, additionalAction)), []);

  const borrowAsset = firstInputSelect?.value;
  const supplyAsset = firstInputSecondSelect?.value;

  useEffect(() => {
    dispatch(setAaveBorrowInterestRateAction(interestRateOptions[1]));
    dispatch(getBoostModalData(inputAmount, borrowAsset, supplyAsset));
    return () => dispatch(resetBoostModal());
  }, []);

  const shouldFlip = new Dec(boostExchangeRate).lt(new Dec(1).div(boostExchangeRate).toString());
  const exchangeLabel = shouldFlip
    ? `${borrowAsset}/${supplyAsset}`
    : `${supplyAsset}/${borrowAsset}`;
  const correctedBoostExchangeRate = shouldFlip
    ? new Dec(1).div(boostExchangeRate).toString()
    : boostExchangeRate;

  const isSameAsset = borrowAsset === supplyAsset;

  const alreadySuppliedAndEnabled =
    usedAssets[borrowAsset]?.isSupplied
    && usedAssets[borrowAsset]?.interestMode === '2'
    && usedAssets[borrowAsset]?.collateral
    && new Dec(usedAssets[borrowAsset].supplied).gt(inputAmount);
  const unsupportedStableBorrowAsset = assetsData[borrowAsset].borrowRateStable.toString() === '0' || assetsData[borrowAsset].disabledStableBorrowing;
  return (
    <div className="boost-repay-modal">
      <ModalHeader closeModal={closeModal} />

      <ModalBody>
        <div className="new-modal-top-wrapper">
          <h1>
            <TooltipWrapper title={t('compound.boost_modal_description', { '%asset1': borrowAsset, '%asset2': supplyAsset })}>
              <InfoIcon /> {t('common.boost')} {additionalAction?.value && `+ ${additionalAction?.value === 'boost' ? contextAction.label : additionalAction.label}`}
            </TooltipWrapper>
          </h1>
        </div>

        <div className="new-modal-content-wrapper">

          {gettingBoostModalData && (<div className="container"><div className="loading-wrapper"><Loader /></div></div>)}

          {!gettingBoostModalData && gettingBoostModalDataError && (<ErrorBox>{gettingBoostModalDataError}</ErrorBox>)}

          {!gettingBoostModalData && !gettingBoostModalDataError && (
            <>
              <ActionInfo
                firstAsset={borrowAsset}
                firstAmount={inputAmount}
                secondAsset={supplyAsset}
                secondAmount={boostAmount}
                isSameAsset={isSameAsset}
                exchangeRate={correctedBoostExchangeRate}
                exchangeLabel={exchangeLabel}
                additionalAction={secondAction}
                additionalInput={secondInput}
                additionalAsset={secondInputSelect?.value}
              />

              <div className="advanced__wrapper container">
                {!isSameAsset && (
                  <>
                    <TradeSizeImpactWarning tradeSizeImpact={tradeSizeImpact} />
                  </>
                )}
                {alreadySuppliedAndEnabled && (
                  <div>
                    <WarningBox>{t('aave.cant_stable_borrow')}</WarningBox>
                  </div>
                )}
                {unsupportedStableBorrowAsset && (
                  <div>
                    <WarningBox>{t('aave.unsupported_stable_borrow_asset')}</WarningBox>
                  </div>
                )}
                <div className={`Switch ${unsupportedStableBorrowAsset || alreadySuppliedAndEnabled ? 'disabled' : ''}`}>
                  {interestRateOptions.map(option => (
                    <div
                      key={option.value}
                      className={`tab ${activeTab.value === option.value ? 'active' : ''}`}
                      onClick={() => dispatch(setAaveBorrowInterestRateAction(option))}
                    >
                      {option.name}
                    </div>
                  ))}
                </div>
                {!isSameAsset && (
                  <AdvancedOptions
                    borrowAsset={borrowAsset}
                    supplyAsset={supplyAsset}
                    advanced={advanced}
                    setAdvanced={setAdvanced}
                    exchangeRate={boostExchangeRate}
                    SlippageLimitForm={SlippageLimitForm}
                  />
                )}
                <AdditionalInfo
                  isSameAsset={isSameAsset}
                  flashloanSource={useFl ? flProtocol : ''}
                  flashloanFee={flFee}
                  exchangeSource={exchangeSource}
                  additionalAction={secondAction}
                  additionalInput={secondInput}
                />
                {boostingError && (<ErrorBox marginTop>{boostingError}</ErrorBox>)}
              </div>
            </>
          )}
        </div>
      </ModalBody>

      <div className="modal-controls">
        <button
          form="aave-boost-modal-form"
          type="button"
          disabled={boosting || gettingBoostModalData}
          onClick={() => dispatch(aaveBoostActionV2(contextAction, additionalAction, closeModal))}
          className="button green"
        >
          {boosting ? t('common.boosting') : t('common.boost')}
        </button>
      </div>
    </div>
  );
};

AaveBoostModalV2.defaultProps = {
  activeTab: {},
  assetsData: {},
  usedAssets: {},
};

AaveBoostModalV2.propTypes = {
  closeModal: PropTypes.func.isRequired,
  tradeSizeImpact: PropTypes.string.isRequired,

  gettingBoostModalData: PropTypes.bool.isRequired,
  gettingBoostModalDataError: PropTypes.string.isRequired,

  boostAmount: PropTypes.string.isRequired,
  boostExchangeRate: PropTypes.string.isRequired,

  boosting: PropTypes.bool.isRequired,

  boostingError: PropTypes.string.isRequired,

  useFl: PropTypes.bool.isRequired,
  flProtocol: PropTypes.string.isRequired,
  flFee: PropTypes.string.isRequired,
  exchangeSource: PropTypes.string.isRequired,

  additionalAction: PropTypes.object,
  contextAction: PropTypes.object,
  activeTab: PropTypes.object,
  assetsData: PropTypes.object,
  usedAssets: PropTypes.object,
};

const mapStateToProps = ({ general, aaveManage }) => ({
  tradeSizeImpact: aaveManage.tradeSizeImpact,
  gettingBoostModalData: aaveManage.gettingBoostModalData,
  gettingBoostModalDataError: aaveManage.gettingBoostModalDataError,
  boostAmount: aaveManage.boostAmount,
  boostExchangeRate: aaveManage.boostExchangeRate,
  boosting: aaveManage.executing?.boost || (aaveManage.executing[aaveManage.selectedAction?.value] && aaveManage.selectedAdditionalActions[aaveManage.selectedAction?.value]?.value === 'boost'),
  boostingError: aaveManage.executingErrors?.boost,
  useFl: aaveManage.useFl,
  activeTab: aaveManage.selectedBorrowInterestRate,
  flProtocol: aaveManage.flProtocol,
  flFee: aaveManage.flFee,
  exchangeSource: aaveManage.exchangeSource,
  assetsData: aaveManage[aaveManage.selectedMarket.value].assetsData,
  usedAssets: aaveManage[general.walletType.value][aaveManage.selectedMarket.value].usedAssets,
});

export default connect(mapStateToProps, null)(AaveBoostModalV2);
