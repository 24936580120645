import dfs from '@defisaver/sdk';
import { getAssetInfo, getAssetInfoByAddress, assetAmountInWei } from '@defisaver/tokens';
import {
  Amount, Asset, AssetAmount, InterestMode, Source,
} from '../../components/Recipes/RecipeCreator/inputTypes';
import RecipeAction from '../RecipeAction';
import { aaveV2FlashBorrowAfterValues } from '../../actions/flashLoanAfterValues';
import FlashIcon from '../recipeIcons/Flash.svg';
import { formatNumber } from '../../services/utils';
import { ZERO_ADDRESS } from '../../constants/general';
import { assetAmountInWeiIgnorePointer } from '../../services/recipeCreator/recipeActionUtils';
import { getAaveMarketInfoFromValue } from '../../constants/aaveMarkets';

export default class AaveV2FlashLoanAction extends RecipeAction {
  static prettyName = 'Aave Flash Loan';

  static protocolPrettyName = 'Aave';

  static description = 'Flash loans selected asset from Aave.';

  constructor(asset = 'DAI', amount = '') {
    super();
    this.inputs = [
      new Asset('Asset', asset, getAaveMarketInfoFromValue('v2default').assets.map(asset => getAssetInfo(asset.replace(/^ETH/, 'WETH')))),
      new Amount('Amount', amount),
      // new InterestMode('Interest Mode', interestMode),
    ];
    this.output = new AssetAmount('output', 0, asset);
  }

  async getAfterValues(_balances = {}, returnValues = [], actions = [], positions = {}, getState) {
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const asset = getAssetInfoByAddress(args[0]);
    const { returnValue, balances } = await aaveV2FlashBorrowAfterValues(
      { amount: args[1], asset: asset.symbol },
      { account: getState().general.account },
      _balances,
    );
    this.output.value = returnValue;
    this.output.asset = asset.symbol;
    return { returnValue: this.output, balances, positions };
  }

  async toDfsAction(getState) {
    const {
      maker: { proxyAddress },
    } = getState();
    const to = proxyAddress;
    const amount = assetAmountInWeiIgnorePointer(this.inputs[1].value, this.inputs[0].asset);
    const interestMode = 0; // this.inputs[2].value
    return new dfs.actions.flashloan.AaveV2FlashLoanAction([amount], [this.inputs[0].value], [interestMode], to, ZERO_ADDRESS, []);
  }

  static getIcon() {
    return FlashIcon;
  }

  _getPrettyName(actionCalls, actions) {
    const args = this.mapReturnValuesToArgs(actionCalls.map(a => a.returnValue), actions);
    return `Flash borrow ${formatNumber(args[1])} ${this.inputs[0].asset} from Aave`;
  }

  getAsset() {
    return this.inputs[0].asset;
  }
}
