import Dec from 'decimal.js';
import t from 'translate';
import { captureException } from 'sentry';
import { sendTx } from './txNotificationActions';
import { getCdpInfo } from '../services/makerServices/makerService';
import { migrateCdpFromInstadapp } from '../services/makerServices/makerInstaService';
import { addToLsState } from '../services/localStorageService';

import {
  MIGRATE_CDP_FROM_INSTADAPP_REQUEST,
  MIGRATE_CDP_FROM_INSTADAPP_SUCCESS,
  MIGRATE_CDP_FROM_INSTADAPP_FAILURE,
  MIGRATE_CDP_FROM_INSTADAPP_SUCCESS_SWITCH_CDP,

  GET_CDP_REQUEST,
  SWITCH_CDP,
} from '../actionTypes/makerActionTypes/makerActionTypes';
import { createDSProxyAction, getCDPLiqudations } from './makerActions/makerActions';
import { trackEvent } from '../services/analyticsService';
import {
  RESET_BR_SLIPPAGE_VALUES, SET_BR_SLIPPAGE_PERCENT, SET_DEADLINE_VALUE, RESET_DEADLINE_VALUE,
} from '../actionTypes/modalActionTypes';

/**
 * Changes the current cdp with another one in the whole state
 *
 * @param {Object}
 *
 * @return {Function}
 */
export const changeSelectedCdp = ({ value }) => async (dispatch, getState) => {
  const { account } = getState().general;
  dispatch({ type: GET_CDP_REQUEST });

  const newCdp = await getCdpInfo(value);
  dispatch(getCDPLiqudations(newCdp.id));

  addToLsState({ account, cdp: { id: newCdp.id, type: newCdp.type } });

  dispatch({ type: SWITCH_CDP, payload: newCdp });
};

/**
 * Handles redux states for when the user wants to migrate his instadapp cdp to dsproxy
 *
 * @param cdp
 * @param closeModal
 * @return {Function}
 */
export const migrateCdpFromInstadappAction = (cdp, closeModal) => async (dispatch, getState) => {
  trackEvent('makerManage', 'migrateInstaDappCdp');

  let { proxyAddress } = getState().maker;

  if (!proxyAddress) {
    proxyAddress = await dispatch(createDSProxyAction());
    if (!proxyAddress) return;
  }

  dispatch({ type: MIGRATE_CDP_FROM_INSTADAPP_REQUEST });

  try {
    const sendTxFunc = promise => sendTx(promise, t('maker.migrating_cdp_from_instadapp'), `CDP #${cdp.id}`, dispatch, getState);

    const { account, accountType, path } = getState().general;
    await migrateCdpFromInstadapp(cdp, accountType, path, sendTxFunc, account, cdp.id, proxyAddress);

    dispatch({ type: MIGRATE_CDP_FROM_INSTADAPP_SUCCESS });
    dispatch({
      type: MIGRATE_CDP_FROM_INSTADAPP_SUCCESS_SWITCH_CDP,
      payload: { ...getState().maker.cdp, external: '', owner: proxyAddress },
    });

    closeModal();
    trackEvent('makerManage', 'migrateInstaDappCdpSuccess');
  } catch (err) {
    dispatch({ type: MIGRATE_CDP_FROM_INSTADAPP_FAILURE, payload: err.message });
    captureException(err);
    trackEvent('makerManage', 'migrateInstaDappCdpError');
  }
};


/**
 * Sets the boost and repay modals slippage percent
 *
 * @param slippagePercent
 * @param estimatedPrice
 * @param type
 * @return {function(...[*]=)}
 */
export const setSlippagePercent = (slippagePercent, estimatedPrice, type) => async (dispatch) => {
  const slippageRate = new Dec(estimatedPrice)
    .minus(new Dec(estimatedPrice).div(100).times(slippagePercent))
    .toString();

  const payload = {
    slippagePercent,
    slippageRate,
  };

  dispatch({ type: SET_BR_SLIPPAGE_PERCENT, payload });
};

/**
 * Resets the values used for slippage limit calculation
 *
 * @return {Function}
 */
export const resetSlippageValues = () => (dispatch) => { dispatch({ type: RESET_BR_SLIPPAGE_VALUES }); };

export const setDeadline = (deadline) => async (dispatch) => {
  dispatch({ type: SET_DEADLINE_VALUE, payload: deadline });
};

export const resetDeadline = () => (dispatch) => { dispatch({ type: RESET_DEADLINE_VALUE }); };
