import { requireAddress, requireOwner } from '../utils';
import { getInstaAccount, getInstaV2Account } from '../contractRegistryService';
import callTx from '../txService';


export const migrateCdpFromInstadappDsaV1 = async (cdp, accountType, path, sendTxFunc, account, cdpId, newOwner) => {
  requireAddress(newOwner);
  await requireOwner(newOwner, account);

  const instaAccount = getInstaAccount(cdp.externalMeta.account);

  const funcParams = [[], [], '0x0000000000000000000000000000000000000000'];

  // check if proxy already auth
  if (!(await instaAccount.methods.isAuth(newOwner).call())) {
    funcParams[0].push('0xd1aff9f2acf800c876c409100d6f39aea93fc3d9');
    const authCallData = window._web3.eth.abi.encodeFunctionCall(
      {
        inputs: [{ internalType: 'address', name: 'authority', type: 'address' }], name: 'add', outputs: [], stateMutability: 'payable', type: 'function',
      },
      [newOwner],
    );
    funcParams[1].push(authCallData);
  }

  funcParams[0].push('0xac02030d8a8F49eD04b2f52C394D3F901A10F8A9');
  const transferCallData = window._web3.eth.abi.encodeFunctionCall(
    {
      inputs: [{ internalType: 'uint256', name: 'vault', type: 'uint256' }, { internalType: 'address', name: 'nextOwner', type: 'address' }], name: 'transfer', outputs: [], stateMutability: 'payable', type: 'function',
    },
    [cdpId, newOwner],
  );
  funcParams[1].push(transferCallData);

  const txParams = { from: account };

  return callTx(accountType, path, sendTxFunc, instaAccount, 'cast', funcParams, txParams);
};

export const migrateCdpFromInstadappDsaV2 = async (cdp, accountType, path, sendTxFunc, account, cdpId, newOwner) => {
  requireAddress(newOwner);
  await requireOwner(newOwner, account);

  const instaAccount = getInstaV2Account(cdp.externalMeta.account);

  const funcParams = [[], [], '0x0000000000000000000000000000000000000000'];

  // check if proxy already auth
  if (!(await instaAccount.methods.isAuth(newOwner).call())) {
    funcParams[0].push('AUTHORITY-A');
    const authCallData = window._web3.eth.abi.encodeFunctionCall(
      {
        inputs: [{ internalType: 'address', name: 'authority', type: 'address' }], name: 'add', outputs: [{ internalType: 'string', name: '_eventName', type: 'string' }, { internalType: 'bytes', name: '_eventParam', type: 'bytes' }], stateMutability: 'payable', type: 'function',
      },
      [newOwner],
    );
    funcParams[1].push(authCallData);
  }

  funcParams[0].push('MAKERDAO-A');
  const transferCallData = window._web3.eth.abi.encodeFunctionCall(
    {
      inputs: [{ internalType: 'uint256', name: 'vault', type: 'uint256' }, { internalType: 'address', name: 'nextOwner', type: 'address' }], name: 'transfer', outputs: [{ internalType: 'string', name: '_eventName', type: 'string' }, { internalType: 'bytes', name: '_eventParam', type: 'bytes' }], stateMutability: 'payable', type: 'function',
    },
    [cdpId, newOwner],
  );
  funcParams[1].push(transferCallData);

  const txParams = { from: account };

  return callTx(accountType, path, sendTxFunc, instaAccount, 'cast', funcParams, txParams);
};

/**
 * Checks cdp type and calls the right migrateCdpFromInstadapp method for the type
 *
 * @param cdp {Object}
 * @param accountType {String}
 * @param path {String}
 * @param sendTxFunc {Function}
 * @param account {String}
 * @param cdpId {Number}
 * @param newOwner {String}
 * @return {Promise<*>}
 */
export async function migrateCdpFromInstadapp(cdp, accountType, path, sendTxFunc, account, cdpId, newOwner) {
  const instaAccount = getInstaV2Account(cdp.externalMeta.account);
  const version = await instaAccount.methods.version().call();
  if (version === '1') return migrateCdpFromInstadappDsaV1(cdp, accountType, path, sendTxFunc, account, cdpId, newOwner);
  return migrateCdpFromInstadappDsaV2(cdp, accountType, path, sendTxFunc, account, cdpId, newOwner);
}
