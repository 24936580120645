import memoize from 'memoizee';
import dfs from '@defisaver/sdk';
import { AssetAmount, CdpId, Source } from 'components/Recipes/RecipeCreator/inputTypes';
import { getSafeInfo as _getSafeInfo } from '../../services/reflexerServices/reflexerService';
import { generateAfterValues } from '../../actions/reflexerActions/reflexerManageAfterValues';

import { assetAmountInWeiIgnorePointer } from '../../services/recipeCreator/recipeActionUtils';
import { formatNumber } from '../../services/utils';

import RecipeAction from '../RecipeAction';
import BorrowIcon from '../recipeIcons/Borrow.svg';


export default class ReflexerGenerateAction extends RecipeAction {
  static prettyName = 'Generate RAI from Safe';

  static protocol = 'reflexer';

  static protocolPrettyName = 'Reflexer';

  static description = 'Generates DAI from the selected Safe.';

  constructor(safeId = 0, amount = '', to = 'wallet') {
    super();
    this.inputs = [
      new CdpId('Safe ID', safeId, 'reflexer'),
      new AssetAmount('Amount', amount, 'RAI'),
      new Source('To', to, true),
    ];
    this.output = new AssetAmount('output', 0, 'RAI');
  }

  async getAfterValues(_balances = {}, returnValues = [], actions = [], _positions = {}, getState) {
    const positions = { ..._positions };
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const safeId = args[0];
    if (!positions[`reflexer_${safeId}`]) positions[`reflexer_${safeId}`] = getState().reflexer.safes[safeId];
    const { afterPosition, balances, returnValue } = await generateAfterValues(
      {
        amount: args[1],
        to: args[2],
      },
      {
        vault: positions[`reflexer_${safeId}`],
        assets: getState().assets,
        account: getState().general.account,
        proxyAddress: getState().maker.proxyAddress,
      },
      _balances,
    );
    positions[`reflexer_${safeId}`] = afterPosition;
    this.output.value = returnValue;
    return { returnValue: this.output, balances, positions };
  }

  async toDfsAction(getState) {
    const {
      general: { account },
      maker: { proxyAddress },
    } = getState();
    const to = this.inputs[2].value === 'wallet' ? account : proxyAddress;
    const amount = assetAmountInWeiIgnorePointer(this.inputs[1].value, 'RAI');
    return new dfs.actions.reflexer.ReflexerGenerateAction(this.inputs[0].value, amount, to);
  }

  static getIcon() {
    return BorrowIcon;
  }

  _getPrettyName(actionCalls, actions) {
    const args = this.mapReturnValuesToArgs(actionCalls.map(a => a.returnValue), actions);
    return `Generate ${formatNumber(args[1])} RAI from Safe #${args[0]}`;
  }
}
