import React from 'react';
import t from 'translate';
import PropTypes from 'prop-types';
import capitalize from 'lodash/capitalize';
import { formatNumber, getColorForRatio } from 'services/utils';
import Dec from 'decimal.js';
import InfoTooltip from '../../../../Decorative/InfoTooltip';
import TooltipWrapper from '../../../../TooltipWrapper/TooltipWrapper';

import './PositionStates.scss';

const getColorForBalance = (balance) => (+balance < 0 ? '#b51a1a' : 'inherit');

const liquityStatus = (status) => {
  if (status === 'active') return 'Active';
  if (status === 'nonExistent') return 'Non existent';
  return 'Closed';
};

const PositionStates = ({
  positions, balances, balanceWarning, ignoreZeroTempBalances,
}) => (
  <div className="position-states-wrapper Flex FlexStart">
    {Object.keys(balances).length === 0 && Object.keys(positions).length === 0 && (
    <>Nothing to show currently</>
    )}
    {
        Object.keys(balances).length > 0 &&
        (!ignoreZeroTempBalances || !!balances.wallet) && (
          <div className={`balances ${balanceWarning ? 'warning' : ''}`} key={JSON.stringify(balances)}>
            {
              Object.keys(balances).map((source) => (
                Object.keys(balances[source]).length > 0 && (
                  (!ignoreZeroTempBalances || source === 'wallet' || (Object.values(balances[source]).some(b => b !== '0'))) && (
                    <React.Fragment key={source}>
                      <h3>
                        {balanceWarning && <InfoTooltip message="You can't have funds on your recipe. Use them in an action or send to your wallet" />}
                        {capitalize(source.replace('recipe', 'temporary'))} balance
                      </h3>
                      {
                        Object.keys(balances[source]).map((asset) => (
                          <h4 key={asset}>{asset}: <span style={{ color: getColorForBalance(balances[source][asset]) }}><TooltipWrapper useText title={balances[source][asset]}>{formatNumber(balances[source][asset])}</TooltipWrapper></span></h4>
                        ))
                      }
                    </React.Fragment>
                  )
                )
              ))
            }
          </div>
        )
      }
    {Object.keys(positions).map((key) => (
      !positions[key].ignore ? (
        <div key={JSON.stringify(positions[key])} className={`positions ${key}`}>
          {
              key.substr(0, 5) === 'maker' && (
                <>
                  <h3>CDP {key.substr(6)}</h3>
                  <h4>Collateral: {formatNumber(positions[key].collateral)} {positions[key].asset}</h4>
                  <h4>Debt: <span className={positions[key].debtTooLow ? 'error' : ''}>{formatNumber(positions[key].debtDai)} DAI</span></h4>
                  <h4>Ratio: <span style={{ color: getColorForRatio(positions[key].ratio, positions[key].liqRatio * 100) }}>{formatNumber(positions[key].ratio)}%</span></h4>
                </>
              )
            }
          {
              key.substr(0, 8) === 'reflexer' && (
                <>
                  <h3>Safe {key.substr(9)}</h3>
                  <h4>Collateral: {formatNumber(positions[key].collateral)} {positions[key].asset}</h4>
                  <h4>Debt: <span className={positions[key].debtTooLow ? 'error' : ''}>{formatNumber(positions[key].debtInAsset)} RAI</span></h4>
                  <h4>Ratio: <span style={{ color: getColorForRatio(positions[key].ratio, positions[key].liqPercent) }}>{formatNumber(positions[key].ratio)}%</span></h4>
                </>
              )
            }
          {
              key.substr(0, 8) === 'compound' && (
                <>
                  <h3>Compound</h3>
                  <h4>Collateral: ${formatNumber(positions[key].suppliedUsd)}</h4>
                  <h4>Debt: ${formatNumber(positions[key].borrowedUsd)}</h4>
                  <h4>Ratio: <span style={{ color: getColorForRatio(positions[key].ratio, positions[key].liqRatio * 100) }}>{formatNumber(positions[key].ratio)}%</span></h4>
                  <h4>Net APY: {formatNumber(positions[key].netApy)}%</h4>
                </>
              )
            }
          {
              key.substr(0, 7) === 'aaveV2_' && (
                <>
                  <h3>Aave</h3>
                  <h4>Collateral: ${formatNumber(positions[key].suppliedUsd)}</h4>
                  <h4>Debt: ${formatNumber(positions[key].borrowedUsd)}</h4>
                  <h4>Ratio: <span style={{ color: getColorForRatio(positions[key].ratio, positions[key].liqRatio * 100) }}>{formatNumber(positions[key].ratio)}%</span></h4>
                  <h4>Net APY: {formatNumber(positions[key].netApy)}%</h4>
                </>
              )
            }
          {
              key.substr(0, 7) === 'liquity' && (
                <>
                  <h3>Liquity {t('liquity.trove')}</h3>
                  <h4>Collateral: {formatNumber(positions[key].collateral)} {positions[key].asset}</h4>
                  <h4>Total debt: <span className={positions[key].debtTooLow ? 'error' : ''}>{formatNumber(positions[key].debtInAsset)} LUSD</span></h4>
                  <h4>Ratio: <span style={{ color: getColorForRatio(positions[key].ratio, positions[key].liqPercent) }}>{formatNumber(positions[key].ratio)}%</span></h4>
                  <h4>Status: {liquityStatus(positions[key].troveStatus)}</h4>
                  {
                    !!positions[key].showStakingBalances && (
                      <>
                        <h3>Staking: </h3>
                        <h4>LUSD Stability Staked: {formatNumber(positions[key].stakedLUSDBalance)}</h4>
                        <h4>LQTY Staked: {formatNumber(positions[key].stakedLQTYBalance)}</h4>
                        <h4>Stability Rewards: </h4>
                        <h4>{`${formatNumber(positions[key].stabilityRewardETH)} ETH`}</h4>
                        <h4>{`${formatNumber(positions[key].stabilityRewardLQTY)} LQTY`}</h4>
                        <h4>Staking Rewards: </h4>
                        <h4>{`${formatNumber(positions[key].rewardETH)} ETH`}</h4>
                        <h4>{`${formatNumber(positions[key].rewardLUSD)} LUSD`}</h4>
                      </>
                    )
                  }
                </>
              )
            }
          {
              key.substr(0, 9) === 'uniswapV3' && (
                <>
                  <h3>{`${key.substr(9) === 'New position' ? 'New Uniswap V3 NFT' : `NFT #${key.substr(9)}`}`}</h3>
                  <h4>{`${formatNumber(positions[key].tickLowerPriceFirstToken, new Dec(positions[key].tickLowerPriceFirstToken).lt(0.01) ? 6 : 2)} - ${formatNumber(positions[key].tickUpperPriceFirstToken, new Dec(positions[key].tickUpperPriceFirstToken).lt(0.01) ? 6 : 2)} ${positions[key].tokenFirstInfo.symbol}/${positions[key].tokenSecondInfo.symbol}`}</h4>
                  <h4>{`${formatNumber(positions[key].tickUpperPriceSecondToken, new Dec(positions[key].tickUpperPriceSecondToken).lt(0.01) ? 6 : 2)} - ${formatNumber(positions[key].tickLowerPriceSecondToken, new Dec(positions[key].tickLowerPriceSecondToken).lt(0.01) ? 6 : 2)} ${positions[key].tokenSecondInfo.symbol}/${positions[key].tokenFirstInfo.symbol}`}</h4>
                  <h4>{`${positions[key].tokenFirstInfo.symbol} supplied: ${formatNumber(positions[key].amountInFirstToken, new Dec(positions[key].amountInFirstToken).lt(0.01) ? 6 : 2)}`}</h4>
                  <h4>{`${positions[key].tokenSecondInfo.symbol} supplied: ${formatNumber(positions[key].amountInSecondToken, new Dec(positions[key].amountInSecondToken).lt(0.01) ? 6 : 2)}`}</h4>
                </>
              )
            }
          {
            key.substr(0, 12) === 'mstableVault' && (
              <>
                <h3>mStable Vault</h3>
                <h4>imUSD supplied: {formatNumber(positions[key], 2)}</h4>
              </>
            )
          }
        </div>
      ) : ''))}
  </div>
);

PositionStates.defaultProps = {
  positions: {},
  balances: {},
  balanceWarning: false,
  ignoreZeroTempBalances: false,
};

PositionStates.propTypes = {
  positions: PropTypes.object,
  balances: PropTypes.object,
  balanceWarning: PropTypes.bool,
  ignoreZeroTempBalances: PropTypes.bool,
};

export default PositionStates;
