import {
  SAVER_IS_SUBSCRIBED_REQUEST,
  SAVER_IS_SUBSCRIBED_SUCCESS,
  SAVER_IS_SUBSCRIBED_FAILURE,

  SAVER_SUBSCRIBE_REQUEST,
  SAVER_SUBSCRIBE_SUCCESS,
  SAVER_SUBSCRIBE_FAILURE,
  SAVER_SUBSCRIBE_RESET,

  SAVER_UNSUBSCRIBE_REQUEST,
  SAVER_UNSUBSCRIBE_SUCCESS,
  SAVER_UNSUBSCRIBE_FAILURE,

  SET_SAVER_UPDATING,

  GET_SAVER_SUBSCRIBE_HISTORY_REQUEST,
  GET_SAVER_SUBSCRIBE_HISTORY_SUCCESS,
  GET_SAVER_SUBSCRIBE_HISTORY_FAILURE,
  GET_SAVER_SUBSCRIBE_HISTORY_LOADMORE_SUCCESS,
} from '../../actionTypes/makerActionTypes/makerSaverActionTypes';
import {
  GET_CDP_SUCCESS,
  MIGRATE_CDP_FROM_INSTADAPP_SUCCESS_SWITCH_CDP,
  SWITCH_CDP,
} from '../../actionTypes/makerActionTypes/makerActionTypes';
import { CREATE_CDP_SUCCESS } from '../../actionTypes/makerActionTypes/makerCreateCdpActionTypes';
import { CONNECT_PROVIDER_SUCCESS } from '../../actionTypes/generalActionTypes';
import { MAKER_ACTION_EXEC_SUCCESS } from '../../actionTypes/makerActionTypes/makerManageActionTypes/makerManageActionTypes';

const INITIAL_STATE = {
  subscribed: false,
  gettingIsSubscribed: false,
  gettingIsSubscribedError: '',

  subscribing: false,
  subscribingError: '',

  unsubscribing: false,
  unsubscribingError: '',

  updating: false,

  history: [],
  historyPage: 1,
  gettingHistory: false,
  gettingHistoryError: '',

  sendingUnsubscribeFeedback: false,
  sendingUnsubscribeFeedbackError: '',
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case SAVER_IS_SUBSCRIBED_REQUEST:
      return { ...state, gettingIsSubscribed: true, gettingIsSubscribedError: '' };

    case SAVER_IS_SUBSCRIBED_SUCCESS:
      return {
        ...state,
        gettingIsSubscribed: false,
        gettingIsSubscribedError: '',
        subscribed: payload.isSubscribedToAutomation,
      };

    case SAVER_IS_SUBSCRIBED_FAILURE:
      return {
        ...state,
        gettingIsSubscribed: false,
        gettingIsSubscribedError: payload,
      };

    case SAVER_SUBSCRIBE_REQUEST:
      return { ...state, subscribing: true, subscribingError: '' };

    case SAVER_SUBSCRIBE_SUCCESS:
      return {
        ...state,
        subscribing: false,
        subscribingError: '',
        subscribed: true,
        updating: false,
      };

    case SAVER_SUBSCRIBE_FAILURE:
      return {
        ...state,
        subscribing: false,
        subscribingError: payload,
      };

    case SAVER_SUBSCRIBE_RESET:
      return {
        ...state,
        subscribing: false,
        subscribingError: '',
      };

    case SAVER_UNSUBSCRIBE_REQUEST:
      return { ...state, unsubscribing: true, unsubscribingError: '' };

    case SAVER_UNSUBSCRIBE_SUCCESS:
      return {
        ...state,
        unsubscribing: false,
        unsubscribingError: '',
        subscribed: false,
      };

    case SAVER_UNSUBSCRIBE_FAILURE:
      return {
        ...state,
        unsubscribing: false,
        unsubscribingError: payload,
      };

    case SET_SAVER_UPDATING:
      return { ...state, updating: payload };

    case GET_SAVER_SUBSCRIBE_HISTORY_REQUEST:
      return { ...state, gettingHistory: true, gettingHistoryError: '' };

    case GET_SAVER_SUBSCRIBE_HISTORY_SUCCESS:
      return {
        ...state,
        gettingHistory: false,
        gettingHistoryError: '',
        history: payload.items,
        historyPage: payload.page,
      };

    case GET_SAVER_SUBSCRIBE_HISTORY_LOADMORE_SUCCESS:
      return {
        ...state,
        gettingHistory: false,
        gettingHistoryError: '',
        history: [...state.history, ...payload.items],
        historyPage: payload.page,
      };

    case GET_SAVER_SUBSCRIBE_HISTORY_FAILURE:
      return {
        ...state,
        gettingHistory: false,
        gettingHistoryError: payload,
      };

    case MAKER_ACTION_EXEC_SUCCESS:
    case MIGRATE_CDP_FROM_INSTADAPP_SUCCESS_SWITCH_CDP:
    case CREATE_CDP_SUCCESS:
    case GET_CDP_SUCCESS:
    case SWITCH_CDP:
      return {
        ...INITIAL_STATE,
        subscribed: payload?.isSubscribedToAutomation,
      };

    case CONNECT_PROVIDER_SUCCESS:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};
