import React from 'react';
import PropTypes from 'prop-types';

export default function InfoIcon(props) {
  return (
    <svg height="14" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className={`icon-Info-circle ${props.className || ''}`} {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M9 16.6C13.1974 16.6 16.6 13.1974 16.6 9C16.6 4.80264 13.1974 1.4 9 1.4C4.80264 1.4 1.4 4.80264 1.4 9C1.4 13.1974 4.80264 16.6 9 16.6ZM9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM8.80288 8.11161C8.89556 7.73192 8.56808 7.38194 8.18308 7.44921L7.11122 7.63651C6.75969 7.69794 6.42492 7.46276 6.36349 7.11122C6.30206 6.75969 6.53724 6.42492 6.88878 6.36349L7.96063 6.17619C9.26367 5.9485 10.372 7.133 10.0583 8.41805L9.08197 12.418C8.98886 12.7994 9.31964 13.1502 9.70589 13.0796L10.8839 12.8644C11.2349 12.8002 11.5715 13.0328 11.6356 13.3839C11.6998 13.7349 11.4672 14.0715 11.1161 14.1356L9.93818 14.3509C8.63092 14.5897 7.5114 13.4025 7.82652 12.1115L8.80288 8.11161ZM9 5.5C9.55228 5.5 10 5.05228 10 4.5C10 3.94772 9.55228 3.5 9 3.5C8.44771 3.5 8 3.94772 8 4.5C8 5.05228 8.44771 5.5 9 5.5Z" fill="#939DA7" />
    </svg>
  );
}

InfoIcon.propTypes = {
  className: PropTypes.string,
};

