import {
  GET_AFTER_CDP_REQUEST,
  GET_AFTER_CDP_SUCCESS,
  GET_AFTER_CDP_FAILURE,

  TRANSFER_CDP_REQUEST,
  TRANSFER_CDP_SUCCESS,
  TRANSFER_CDP_FAILURE,

  GET_REPAY_MODAL_DATA_REQUEST,
  GET_REPAY_MODAL_DATA_SUCCESS,
  GET_REPAY_MODAL_DATA_FAILURE,
  RESET_REPAY_MODAL,

  GET_BOOST_MODAL_DATA_REQUEST,
  GET_BOOST_MODAL_DATA_SUCCESS,
  GET_BOOST_MODAL_DATA_FAILURE,
  RESET_BOOST_MODAL,

  CLOSE_CDP_REQUEST,
  CLOSE_CDP_SUCCESS,
  CLOSE_CDP_FAILURE,
  CLEAR_CLOSE_CDP_ERROR,

  GET_MAX_VALUE_REQUEST,
  GET_MAX_VALUE_SUCCESS,
  GET_MAX_VALUE_FAILURE,

  MAKER_SET_DASHBOARD_ACTION,
  MAKER_SET_ADDITIONAL_DASHBOARD_ACTION,

  MAKER_ACTION_EXEC_REQUEST,
  MAKER_ACTION_EXEC_FAILURE,
  MAKER_ACTION_EXEC_SUCCESS,
} from 'actionTypes/makerActionTypes/makerManageActionTypes/makerManageActionTypes';
import { CONNECT_PROVIDER_SUCCESS } from 'actionTypes/generalActionTypes';
import { SWITCH_CDP } from 'actionTypes/makerActionTypes/makerActionTypes';
import {
  RESET_BR_SLIPPAGE_VALUES, RESET_DEADLINE_VALUE,
  SET_BR_SLIPPAGE_PERCENT, SET_DEADLINE_VALUE,
} from '../../../actionTypes/modalActionTypes';

const INITIAL_STATE = {
  repaying: false,
  repayingError: '',

  maxRepay: '0',
  gettingMaxRepay: false,
  gettingMaxRepayError: '',

  repayAmount: '0',
  repayExchangeRate: '0',
  gettingRepayModalData: false,
  gettingRepayModalDataError: '',

  afterType: '',
  afterCdp: null,
  gettingAfterCdp: false,
  gettingAfterCdpFailure: '',
  ratioTooLow: false,
  ratioTooHigh: false,

  boosting: false,
  boostingError: '',

  maxBoost: '0',
  gettingMaxBoost: false,
  gettingMaxBoostError: '',

  boostAmount: '0',
  boostExchangeRate: '0',
  gettingBoostModalData: false,
  gettingBoostModalDataError: '',

  useFl: false,
  flProtocol: 'none',
  flFee: '0',
  useAltRecipe: false,
  exchangeSource: '',

  transferringCdp: false,
  transferringCdpError: '',

  slippagePercent: '0',
  slippageRate: '0',
  tradeSizeImpact: '0',

  deadline: '0',

  closingCdp: false,
  closingCdpError: '',
  closingOption: '',

  maxValues: {},
  loadingMaxValues: {},
  maxValuesErrors: {},

  selectedAction: { label: 'None', value: '' },
  selectedAdditionalActions: {},

  executing: {},
  executingErrors: {},
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_MAX_VALUE_REQUEST:
      return {
        ...state,
        loadingMaxValues: {
          ...state.loadingMaxValues,
          [action.forAction]: true,
        },
        maxValuesErrors: {
          ...state.loadingMaxValues,
          [action.forAction]: '',
        },
      };

    case GET_MAX_VALUE_SUCCESS:
      return {
        ...state,
        loadingMaxValues: {
          ...state.loadingMaxValues,
          [action.forAction]: false,
        },
        maxValues: {
          ...state.maxValues,
          [action.forAction]: payload,
        },
      };

    case GET_MAX_VALUE_FAILURE:
      return {
        ...state,
        loadingMaxValues: {
          ...state.loadingMaxValues,
          [action.forAction]: false,
        },
        maxValuesErrors: {
          ...state.loadingMaxValues,
          [action.forAction]: payload,
        },
      };

    case MAKER_SET_DASHBOARD_ACTION:
      return {
        ...state,
        selectedAction: payload,
        afterCdp: null,
      };

    case MAKER_SET_ADDITIONAL_DASHBOARD_ACTION:
      return {
        ...state,
        selectedAdditionalActions: {
          ...state.selectedAdditionalActions,
          [state.selectedAction.value]: payload,
        },
        afterCdp: null,
      };

    case MAKER_ACTION_EXEC_REQUEST:
      return {
        ...state,
        executing: {
          ...state.executing,
          [action.action]: true,
        },
        executingErrors: {
          ...state.executingErrors,
          [action.action]: '',
        },
      };
    case MAKER_ACTION_EXEC_SUCCESS:
      return {
        ...state,
        executing: {
          ...state.executing,
          [action.action]: false,
        },
      };
    case MAKER_ACTION_EXEC_FAILURE:
      return {
        ...state,
        executing: {
          ...state.executing,
          [action.action]: false,
        },
        executingErrors: {
          ...state.executingErrors,
          [action.action]: payload,
        },
      };

    case GET_AFTER_CDP_REQUEST:
      return { ...state, gettingAfterCdp: true, gettingAfterCdpError: '' };

    case GET_AFTER_CDP_SUCCESS:
      return {
        ...state,
        gettingAfterCdp: false,
        gettingAfterCdpError: '',
        ...payload,
      };

    case GET_AFTER_CDP_FAILURE:
      return {
        ...state,
        gettingAfterCdp: false,
        gettingAfterCdpError: payload,
      };

    case TRANSFER_CDP_REQUEST:
      return { ...state, transferringCdp: true, transferringCdpError: '' };

    case TRANSFER_CDP_SUCCESS:
      return {
        ...state,
        transferringCdp: false,
        transferringCdpError: '',
      };

    case TRANSFER_CDP_FAILURE:
      return {
        ...state,
        transferringCdp: false,
        transferringCdpError: payload,
      };

    case GET_REPAY_MODAL_DATA_REQUEST:
      return {
        ...state,
        gettingRepayModalData: true,
        gettingRepayModalDataError: '',
        executingErrors: {
          ...state.executingErrors,
          repay: '',
        },
      };

    case GET_REPAY_MODAL_DATA_SUCCESS:
      return {
        ...state,
        gettingRepayModalData: false,
        gettingRepayModalDataError: '',
        ...payload,
      };

    case GET_REPAY_MODAL_DATA_FAILURE:
      return {
        ...state,
        gettingRepayModalData: false,
        gettingRepayModalDataError: payload,
      };

    case RESET_REPAY_MODAL:
      return {
        ...state,
        repaying: false,
        repayingError: '',
        gettingRepayModalData: false,
        gettingRepayModalDataError: '',
      };

    case GET_BOOST_MODAL_DATA_REQUEST:
      return {
        ...state,
        gettingBoostModalData: true,
        gettingBoostModalDataError: '',
        executingErrors: {
          ...state.executingErrors,
          boost: '',
        },
      };

    case GET_BOOST_MODAL_DATA_SUCCESS:
      return {
        ...state,
        gettingBoostModalData: false,
        gettingBoostModalDataError: '',
        ...payload,
      };

    case GET_BOOST_MODAL_DATA_FAILURE:
      return {
        ...state,
        gettingBoostModalData: false,
        gettingBoostModalDataError: payload,
      };

    case RESET_BOOST_MODAL:
      return {
        ...state,
        boosting: false,
        boostingError: '',
        boostAmount: '0',
        boostExchangeRate: '0',
        gettingBoostModalData: false,
        gettingBoostModalDataError: '',
      };

    case SET_BR_SLIPPAGE_PERCENT:
      return {
        ...state,
        slippagePercent: payload.slippagePercent,
        slippageRate: payload.slippageRate,
      };

    case RESET_BR_SLIPPAGE_VALUES:
      return {
        ...state,
        slippagePercent: '0',
        slippageRate: '0',
        tradeSizeImpact: '0',
      };

    case SET_DEADLINE_VALUE:
      return {
        ...state,
        deadline: payload,
      };

    case RESET_DEADLINE_VALUE:
      return {
        ...state,
        deadline: '0',
      };

    case CLOSE_CDP_REQUEST:
      return {
        ...state,
        closingCdp: true,
        closingCdpError: '',
      };

    case CLOSE_CDP_SUCCESS:
      return {
        ...state,
        closingCdp: false,
        closingCdpError: '',
      };

    case CLOSE_CDP_FAILURE:
      return {
        ...state,
        closingCdp: false,
        closingCdpError: payload,
      };

    case CLEAR_CLOSE_CDP_ERROR:
      return { ...state, closingCdpError: '' };

    case SWITCH_CDP:
    case CONNECT_PROVIDER_SUCCESS:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};
