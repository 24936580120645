import React from 'react';
import { connect } from 'react-redux';
import t from 'translate';
import PropTypes from 'prop-types';
import ModalBody from '../ModalBody';
import ModalHeader from '../ModalHeader';
import ConnectWalletButtons from '../../Connect/ConnectWalletButtons/ConnectWalletButtons';
import ConnectWallet from '../../Connect/ConnectWallet/ConnectWallet';

const LoginModal = ({ closeModal }) => (
  <div>
    <ModalHeader closeModal={closeModal} />
    <ModalBody>
      <div className="new-modal-top-wrapper">
        <h1 className="title">{t('misc.welcome_to_dfs')}</h1>
      </div>
      <ConnectWallet showTrackOption />
    </ModalBody>
  </div>
);

LoginModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default (LoginModal);
