import Dec from 'decimal.js';
import cloneDeep from 'lodash/cloneDeep';
import { isWalletTypeProxy, resetFields } from '../../services/utils';
import { getAggregatedPositionData } from '../../services/aaveServices/aaveManageService';
import { calculateBorrowingAssetLimit } from '../../services/moneymarketCommonService';
import {
  GET_AAVE_AFTER_VALUE_FAILURE,
  GET_AAVE_AFTER_VALUE_REQUEST,
  GET_AAVE_AFTER_VALUE_SUCCESS,
} from '../../actionTypes/aaveActionTypes/aaveManageActionTypes';
import { getBestExchangePrice } from '../../services/exchangeServiceV3';
import { captureException } from '../../sentry';
import { changeBalance } from '../../services/recipeCreator/recipeActionUtils';

const fullPosition = (getAfterValues) => async (input, stateData, balances = {}) => {
  const result = await getAfterValues.apply(this, [input, stateData, balances]);
  const usedAssets = { ...stateData.usedAssets, ...result.afterAssets };
  const payload = {
    balances: result.balances,
    returnValue: result.returnValue,
    afterPosition: {
      assetsData: stateData.assetsData,
      usedAssets,
      afterAssets: result.afterAssets,
      ...getAggregatedPositionData(usedAssets, stateData.assetsData, stateData.assets),
    },
  };
  Object.keys(payload.afterPosition.afterAssets).forEach((asset) => {
    if (!payload.afterPosition.usedAssets[asset].borrowedUsd) return;
    payload.afterPosition.usedAssets[asset].limit = calculateBorrowingAssetLimit(payload.afterPosition.usedAssets[asset].borrowedUsd, payload.afterPosition.borrowLimitUsd);
  });
  return payload;
};

export const supplyAfterValues = fullPosition(
  async (
    {
      amount, firstAsset, secondAsset, from = 'wallet',
    },
    {
      usedAssets, assets, walletType, account, proxyAddress,
    },
    _balances = {},
  ) => {
    const { aavePrice } = assets[firstAsset];
    const balances = cloneDeep(_balances);
    const amountUsd = new Dec(amount || '0').times(aavePrice).toString();

    // If the asset is not currently supplied
    const assetToChange = usedAssets[firstAsset] || {
      supplied: '0', suppliedUsd: '0', symbol: firstAsset, isSupplied: true,
    };

    const afterAssets = {
      [firstAsset]: {
        ...assetToChange,
        isSupplied: true,
        supplied: new Dec(assetToChange.supplied || '0').plus(amount || '0').toString(),
        suppliedUsd: new Dec(assetToChange.suppliedUsd || '0').plus(amountUsd).toString(),
      },
    };

    await changeBalance(balances, from, firstAsset.replace(/^ETH/, 'WETH'), new Dec(amount || '0').times(-1), from === 'wallet' ? account : proxyAddress);

    // SmartWallet supply automatically enables supplied asset as collateral
    if (isWalletTypeProxy(walletType)) afterAssets[firstAsset].collateral = true;

    return {
      afterAssets,
      balances,
      returnValue: amount || '0',
    };
  });

export const withdrawAfterValues = fullPosition(
  async (
    {
      amount, firstAsset, secondAsset, to = 'wallet',
    },
    {
      usedAssets, assets, account, proxyAddress,
    },
    _balances = {},
  ) => {
    const { aavePrice } = assets[firstAsset];
    const balances = cloneDeep(_balances);
    const amountUsd = new Dec(amount || '0').times(aavePrice).toString();

    const assetToChange = usedAssets[firstAsset] || {
      supplied: '0', suppliedUsd: '0', symbol: firstAsset, isSupplied: true,
    };

    const afterAssets = {
      [firstAsset]: {
        ...assetToChange,
        supplied: Dec(assetToChange.supplied).minus(amount || '0').toString(),
        suppliedUsd: Dec(assetToChange.suppliedUsd).minus(amountUsd).toString(),
      },
    };

    await changeBalance(balances, to, firstAsset.replace(/^ETH/, 'WETH'), amount || '0', to === 'wallet' ? account : proxyAddress);

    return {
      afterAssets,
      balances,
      returnValue: amount || '0',
    };
  });

export const borrowAfterValues = fullPosition(
  async (
    {
      amount, firstAsset, secondAsset, to = 'wallet', interestMode,
    },
    {
      usedAssets, assets, account, proxyAddress,
    },
    _balances = {},
  ) => {
    const { aavePrice } = assets[firstAsset];
    const balances = cloneDeep(_balances);
    const amountUsd = new Dec(amount || '0').times(aavePrice).toString();

    const assetToChange = usedAssets[firstAsset] || {
      borrowed: '0', borrowedStable: '0', borrowedVariable: '0', borrowedUsd: '0', borrowedUsdStable: '0', borrowedUsdVariable: '0', limit: '0', symbol: firstAsset, isBorrowed: true,
    };
    const interestModeProp = interestMode === '1' ? 'borrowedStable' : 'borrowedVariable';
    const interestModePropUsd = interestMode === '1' ? 'borrowedUsdStable' : 'borrowedUsdVariable';
    const afterAssets = {
      [firstAsset]: {
        ...assetToChange,
        isBorrowed: true,
        borrowed: Dec(assetToChange.borrowed || '0').add(amount || '0').toString(),
        borrowedUsd: Dec(assetToChange.borrowedUsd || '0').add(amountUsd).toString(),
        [interestModeProp]: Dec(assetToChange[interestModeProp] || '0').add(amount || '0').toString(),
        [interestModePropUsd]: Dec(assetToChange[interestModePropUsd] || '0').add(amountUsd).toString(),
      },
    };

    await changeBalance(balances, to, firstAsset.replace(/^ETH/, 'WETH'), amount || '0', to === 'wallet' ? account : proxyAddress);

    return {
      afterAssets,
      balances,
      returnValue: amount || '0',
    };
  });

export const paybackAfterValues = fullPosition(
  async (
    {
      amount, firstAsset, secondAsset, from = 'wallet', interestMode,
    },
    {
      usedAssets, assets, account, proxyAddress,
    },
    _balances = {},
  ) => {
    const { aavePrice } = assets[firstAsset];
    const balances = cloneDeep(_balances);
    const amountUsd = new Dec(amount || '0').times(aavePrice).toString();

    const assetToChange = usedAssets[firstAsset] || {
      borrowed: '0', borrowedStable: '0', borrowedVariable: '0', borrowedUsd: '0', borrowedUsdStable: '0', borrowedUsdVariable: '0', limit: '0', symbol: firstAsset, isBorrowed: true,
    };
    const interestModeProp = interestMode === '1' ? 'borrowedStable' : 'borrowedVariable';
    const interestModePropUsd = interestMode === '1' ? 'borrowedUsdStable' : 'borrowedUsdVariable';
    const afterAssets = {
      [firstAsset]: {
        ...assetToChange,
        borrowed: Dec.max(0, new Dec(assetToChange.borrowed || '0').minus(amount || '0')).toString(),
        borrowedUsd: Dec.max(0, new Dec(assetToChange.borrowedUsd || '0').minus(amountUsd)).toString(),
        [interestModeProp]: Dec.max(0, new Dec(assetToChange[interestModeProp] || '0').minus(amount || '0')).toString(),
        [interestModePropUsd]: Dec.max(0, new Dec(assetToChange[interestModePropUsd] || '0').minus(amountUsd)).toString(),
      },
    };

    await changeBalance(balances, from, firstAsset.replace(/^ETH/, 'WETH'), new Dec(amount || '0').times(-1), from === 'wallet' ? account : proxyAddress);

    return {
      afterAssets,
      balances,
      returnValue: amount || '0',
    };
  });

export const repayAfterValues = fullPosition(
  async (
    { amount: collDecrease, firstAsset, secondAsset },
    {
      usedAssets, assets, selectedMarket, proxyAddress,
    },
    balances = {},
  ) => {
    const { price: rate } = await getBestExchangePrice(collDecrease, firstAsset, secondAsset, proxyAddress);
    const debtDecrease = new Dec(collDecrease).times(rate).toString();
    const debtDecreaseUsd = new Dec(debtDecrease).times(assets[secondAsset].aavePrice);
    const collDecreaseUsd = new Dec(collDecrease).times(assets[firstAsset].aavePrice);

    const assetToChange = usedAssets[firstAsset] || {
      supplied: '0', suppliedUsd: '0', symbol: firstAsset, isSupplied: true,
    };

    const afterAssets = {
      [firstAsset]: {
        ...assetToChange,
        supplied: new Dec(assetToChange.supplied).minus(collDecrease).toString(),
        suppliedUsd: new Dec(assetToChange.suppliedUsd).minus(collDecreaseUsd).toString(),
        // SmartWallet supply automatically enables supplied asset as collateral
        collateral: true,
      },
    };

    const secondAssetToChange = afterAssets[secondAsset] || usedAssets[secondAsset] || {
      borrowed: '0', borrowedUsd: '0', limit: '0', symbol: secondAsset, isBorrowed: true,
    };

    afterAssets[secondAsset] = {
      ...secondAssetToChange,
      borrowed: new Dec(secondAssetToChange.borrowed).minus(debtDecrease).gt(0) ? new Dec(secondAssetToChange.borrowed).minus(debtDecrease).toString() : '0',
      borrowedUsd: new Dec(secondAssetToChange.borrowedUsd).minus(debtDecreaseUsd).gt(0) ? new Dec(secondAssetToChange.borrowedUsd).minus(debtDecreaseUsd).toString() : '0',
    };

    return {
      afterAssets,
      balances,
      returnValue: debtDecrease,
    };
  });

export const boostAfterValues = fullPosition(
  async (
    { amount: debtIncrease, firstAsset, secondAsset },
    {
      usedAssets, assets, selectedMarket, proxyAddress,
    },
    balances = {},
  ) => {
    let { price: rate } = await getBestExchangePrice(debtIncrease, firstAsset, secondAsset, proxyAddress);
    if (secondAsset === 'stETH' && firstAsset === 'ETH' && +rate < 1) {
      // stake only if peg above 1:1
      rate = 1;
    }
    const collIncrease = new Dec(debtIncrease).times(rate).toString();
    const collIncreaseUsd = new Dec(collIncrease).times(assets[secondAsset].aavePrice);
    const debtIncreaseUsd = new Dec(debtIncrease).times(assets[firstAsset].aavePrice);

    const assetToChange = usedAssets[firstAsset] || {
      borrowed: '0', borrowedUsd: '0', symbol: firstAsset, isBorrowed: true,
    };

    const afterAssets = {
      [firstAsset]: {
        ...assetToChange,
        isBorrowed: true,
        borrowed: new Dec(assetToChange.borrowed || '0').add(debtIncrease).toString(),
        borrowedUsd: new Dec(assetToChange.borrowedUsd || '0').add(debtIncreaseUsd).toString(),
      },
    };

    // If the asset is not currently supplied
    const secondAssetToChange = afterAssets[secondAsset] || usedAssets[secondAsset] || {
      supplied: '0', suppliedUsd: '0', symbol: secondAsset, isSupplied: true,
    };

    afterAssets[secondAsset] = {
      ...secondAssetToChange,
      isSupplied: true,
      supplied: new Dec(secondAssetToChange.supplied || '0').add(collIncrease).toString(),
      suppliedUsd: new Dec(secondAssetToChange.suppliedUsd || '0').add(collIncreaseUsd).toString(),
      // SmartWallet supply automatically enables supplied asset as collateral
      collateral: true,
    };

    return {
      afterAssets,
      balances,
      returnValue: collIncrease,
    };
  });


const formatGetterData = (amount, firstAsset, secondAsset, proxyAddress, data) => [
  { amount, firstAsset, secondAsset },
  {
    usedAssets: data.usedAssets,
    assets: data.assets,
    assetsData: data.assetsData,
    walletType: data.walletType,
    selectedMarket: data.selectedMarket,
    proxyAddress,
  }, {}];
/**
 * Calculates the changed asset type values that are shown
 *
 * @param amount {String|Number}
 * @param type {String}
 * @param _firstAsset {Object}
 * @param _secondAsset {Object}
 *
 * @return {Function}
 */
export const setAfterValue = (amount, type, _firstAsset = {}, _secondAsset = {}) => async (dispatch, getState) => {
  if (!amount || amount === '0') {
    dispatch(resetFields('aaveManageCollateralForm', { withdrawAmount: '', supplyAmount: '' }));
    dispatch(resetFields('aaveManageDebtForm', { paybackAmount: '', borrowAmount: '' }));
    dispatch(resetFields('aaveManageAdvancedForm', { boostAmount: '', repayAmount: '' }));

    return dispatch({ type: GET_AAVE_AFTER_VALUE_SUCCESS, payload: { afterValue: null, afterType: '' } });
  }

  dispatch({ type: GET_AAVE_AFTER_VALUE_REQUEST });

  try {
    const { general: { walletType }, maker: { proxyAddress }, assets } = getState();
    const { selectedMarket } = getState().aaveManage;
    const { assetsData } = getState().aaveManage[selectedMarket.value];
    const { usedAssets, isSubscribedToAutomation } = getState().aaveManage[walletType.value][selectedMarket.value];
    const firstAsset = _firstAsset.value;
    const secondAsset = _secondAsset?.value;

    let minRatio = 0;
    let maxRatio = Infinity;
    let boostEnabled = false;
    const { graphData } = getState().aaveSaver;

    if (isSubscribedToAutomation && graphData) {
      minRatio = graphData.minRatio;
      maxRatio = graphData.maxRatio;
      boostEnabled = graphData.boostEnabled;
    }

    const payload = { afterType: type };

    // const getterParams = [amount, firstAsset, secondAsset, usedAssets, assetsData, assets, walletType, selectedMarket, proxyAddress, null];
    const getterParams = [
      { amount, firstAsset, secondAsset },
      {
        usedAssets,
        assetsData,
        assets,
        walletType,
        selectedMarket,
        proxyAddress,
      }, {}];

    if (type === 'supply') {
      payload.afterValue = (await supplyAfterValues(...getterParams)).afterPosition;
      dispatch(resetFields('aaveManageCollateralForm', { withdrawAmount: '' }));
    }

    if (type === 'withdraw') {
      payload.afterValue = (await withdrawAfterValues(...getterParams)).afterPosition;
      dispatch(resetFields('aaveManageCollateralForm', { supplyAmount: '' }));
    }

    if (type === 'borrow') {
      payload.afterValue = (await borrowAfterValues(...getterParams)).afterPosition;
      dispatch(resetFields('aaveManageDebtForm', { paybackAmount: '' }));
    }

    if (type === 'payback') {
      payload.afterValue = (await paybackAfterValues(...getterParams)).afterPosition;
      dispatch(resetFields('aaveManageDebtForm', { borrowAmount: '' }));
    }

    if (type === 'repay') {
      payload.afterValue = (await repayAfterValues(...getterParams)).afterPosition;
      dispatch(resetFields('aaveManageAdvancedForm', { boostAmount: '' }));
    }

    if (type === 'boost') {
      payload.afterValue = (await boostAfterValues(...getterParams)).afterPosition;
      dispatch(resetFields('aaveManageAdvancedForm', { repayAmount: '' }));
    }

    // TODO move data from makerManage to afterValue & move check to fullPosition
    if (payload.afterValue?.ratio) {
      payload.ratioTooLow = parseFloat(payload.afterValue.ratio) < minRatio;
      payload.ratioTooHigh = boostEnabled && parseFloat(payload.afterValue.ratio) > maxRatio;
    }

    dispatch({ type: GET_AAVE_AFTER_VALUE_SUCCESS, payload });
  } catch (err) {
    dispatch({ type: GET_AAVE_AFTER_VALUE_FAILURE, payload: err.message });
    captureException(err);
  }
};

