import React, { useEffect, useState } from 'react';
import t from 'translate';
import Dec from 'decimal.js';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from 'react-select';

import { getAssetPriceAction } from 'actions/assetsActions';
import { openLoginModal, openCompoundUserWalletModal } from 'actions/modalActions';
import { getAssetInfo } from '@defisaver/tokens';
import { exchangeAssets } from 'constants/assets';
import { ACCOUNT_TYPES, DEFAULT_SLIPPAGE_PERCENT } from '../../../../constants/general';
import {
  getCompBalanceAction,
  claimCompBalanceAction,
  fetchCompoundAssetsData,
} from '../../../../actions/compoundActions/compoundManageActions';
import { getBestExchangePrice, getSlippageThreshold } from '../../../../services/exchangeServiceV3';
import { formatNumber } from '../../../../services/utils';
import { calculateTradeSizeImpact } from '../../../../services/makerServices/makerManageServices/makerManageService';
import { comptrollerContract } from '../../../../services/contractRegistryService';
import { getAssetUsdPrice } from '../../../../services/assetsService';

import { SelectOption, SelectValueContainer } from '../../../Forms/SelectComponent/SelectComponent';
import ModalHeader from '../../ModalHeader';
import ModalBody from '../../ModalBody';
import Loader from '../../../Loader/Loader';
import DataItem from '../../../DataItem/DataItem';
import TradeSizeImpactWarning from '../../../TradeSizeImpactWarning/TradeSizeImpactWarning';

import compIcon from './comp-icon-circle.svg';
import './CompRedeemModal.scss';

const symbolToOption = (value) => ({
  label: value,
  value,
  meta: {
    icon: getAssetInfo(value).icon,
  },
});

const CLAIM_COMP_OPTIONS = [
  {
    title: 'Claim', swap: false, supply: false, desc: t('compound.claim_from_smart_wallet'), btnCta: t('common.claim'), hasInput: false,
  },
  {
    title: 'Supply', swap: false, supply: true, toAsset: 'COMP', desc: t('compound.claim_and_supply_from_smart_wallet'), btnCta: t('common.supply'), hasInput: false,
  },
  {
    title: 'Swap', swap: true, supply: false, desc: t('compound.claim_and_swap_from_smart_wallet'), btnCta: t('common.confirm'), hasInput: true,
  },
  {
    title: 'Swap + Supply', swap: true, supply: true, desc: t('compound.claim_swap_and_supply_from_smart_wallet'), btnCta: t('common.confirm'), hasInput: true,
  },
];


const ClaimOptionAction = ({
  isAccount, accountType, currentOption, claimAction, balance, assetsFromCompound, assetsFromExchange, proxyAddress, openLoginModal, openCompoundUserWalletModal, compPrice,
}) => {
  const {
    hasInput, swap, supply, toAsset,
  } = currentOption;
  let assets = [];
  if (swap && supply) assets = assetsFromCompound;
  else if (swap && !supply) assets = assetsFromExchange;

  const slippage = DEFAULT_SLIPPAGE_PERCENT;
  const [selectedAsset, selectAsset] = useState(assets[0]);
  const [fetchingData, setFetchingData] = useState(false);
  const [sendingTx, setSendingTx] = useState(false);
  const [tradeSizeImpact, setTradeSizeImpact] = useState(1);
  const [selectedAssetAmount, setSelectedAssetAmount] = useState('0');
  const [selectedAssetPrice, setSelectedAssetPrice] = useState('0');

  const fetchData = () => new Promise(async (res, rej) => {
    try {
      const { price } = await getBestExchangePrice(balance, 'COMP', selectedAsset.value, proxyAddress);
      const assetPrice = await getSlippageThreshold('COMP', selectedAsset.value);
      const tradeSizeImpact = await calculateTradeSizeImpact(assetPrice, price);
      res({ price, tradeSizeImpact });
    } catch (error) {
      console.log(error);
      rej(error);
    }
  });

  useEffect(() => {
    if (!isAccount && assets.length && (swap || supply)) selectAsset(assets[0]);
  }, [assets]);

  useEffect(() => {
    let canceledFetch = false;
    if (!isAccount && (swap || supply) && +balance !== 0) {
      setFetchingData(true);
      fetchData().then(({ price, tradeSizeImpact }) => {
        if (!canceledFetch) {
          setSelectedAssetAmount(new Dec(price).mul(balance).toString());
          setTradeSizeImpact(+tradeSizeImpact);
          setSelectedAssetPrice(price);
          setFetchingData(false);
        }
      });
    }
    return () => { canceledFetch = true; };
  }, [selectedAsset?.value, balance]);

  const loading = fetchingData || !selectedAsset;

  return (
    <>
      <div className={`comp-redeem-modal__form ${hasInput ? 'has-input' : ''}`}>
        <DataItem
          value={balance || '0'}
          label={t('common.claimable')}
          symbol="COMP"
          smallSymbol
          decimals={+balance > 1 ? 2 : 4}
          type="standard-smaller"
          additionalValue={(compPrice * balance)}
          additionalValueSymbol="$"
          additionalValueSymbolAfter={false}
        />
        {hasInput && (
        <div className="comp-redeem-modal__select">
          <Select
            className="select box"
            classNamePrefix="select"
            menuPlacement="top"
            maxMenuHeight={220}
            value={selectedAsset}
            onChange={(val) => selectAsset(val)}
            options={assets}
            onBlur={event => event.preventDefault()}
            isSearchable
            isDisabled={+balance === 0 || fetchingData}
            components={{
              Option: SelectOption,
              ValueContainer: SelectValueContainer,
            }}
          />
          <div className="select__balance">
            {loading ? 'Loading...' : (<span><strong>~ {formatNumber(selectedAssetAmount, 5)}</strong> {selectedAsset.label}</span>)}
          </div>
        </div>
        )}
        <div className="Flex FlexEnd comp-redeem-modal__submit">
          {swap && +balance !== 0 && <TradeSizeImpactWarning exactAmount tradeSizeImpact={tradeSizeImpact} />}
          <button
            type="button"
            className="button green"
            disabled={+balance === 0 || fetchingData || sendingTx}
            onClick={() => {
              if (accountType === ACCOUNT_TYPES.viewOnly) return openLoginModal();
              setSendingTx(true);
              claimAction(
                isAccount,
                (!isAccount && (swap || supply)) ? {
                  ...currentOption,
                  slippage,
                  price: selectedAssetPrice,
                  amount: balance,
                  toAsset: toAsset || selectedAsset.value,
                  assetAmount: selectedAssetAmount,
                } : null,
                () => setSendingTx(false),
              );
            }}
          >
            {currentOption.btnCta || t('common.claim')}{sendingTx ? 'ing' : ''}
          </button>
        </div>
      </div>
      {!proxyAddress && openCompoundUserWalletModal && (
        <button
          type="button"
          className="button green learn-more"
          onClick={() => openCompoundUserWalletModal()}
        >
          {t('common.learn_more')}
        </button>
      )}
    </>
  );
};

const calculateCompPerDay = async (usedAssets, assetsData) => {
  const contract = await comptrollerContract();
  let compPerDayTotal = '0';

  await Promise.all(Object.values(usedAssets).map(async (item) => {
    const cToken = getAssetInfo(`c${item.symbol}`).address;
    let compSupplySpeed = await contract.methods.compSupplySpeeds(cToken).call();
    let compBorrowSpeed = await contract.methods.compBorrowSpeeds(cToken).call();
    compSupplySpeed = new Dec(compSupplySpeed).div(1e18);
    compBorrowSpeed = new Dec(compBorrowSpeed).div(1e18);

    const blocksPerMinute = 4;
    const compSpeedSupplyPerDay = compSupplySpeed.mul(blocksPerMinute).mul(60).mul(24).toString();
    const compSpeedBorrowPerDay = compBorrowSpeed.mul(blocksPerMinute).mul(60).mul(24).toString();
    const assetPriceInUSD = await getAssetUsdPrice(item.symbol, 'compound');
    const getCompPerDay = (speed, assetAmount, priceUSD, total) => (assetAmount ? new Dec(speed).mul(assetAmount).mul(priceUSD).div(new Dec(total).times(priceUSD))
      .toString() : '0');

    const compPerDayForSuppliedAsset = getCompPerDay(compSpeedSupplyPerDay, item.supplied, assetPriceInUSD, assetsData[item.symbol].totalSupply);
    const compPerDayForBorrowedAsset = getCompPerDay(compSpeedBorrowPerDay, item.borrowed, assetPriceInUSD, assetsData[item.symbol].totalBorrow);

    compPerDayTotal = new Dec(compPerDayTotal).plus(compPerDayForSuppliedAsset).plus(compPerDayForBorrowedAsset).toString();
  }));

  return compPerDayTotal;
};

const CompRedeemModal = ({
  closeModal, proxyAddress, getCompBalanceAction, claimCompBalanceAction,
  compBalance, compBalanceLoading, accountType, openLoginModal,
  getAssetPriceAction, compPrice, fetchingAssetsData, assetsData, fetchCompoundAssetsData,
  usedAssets, walletType, openCompoundUserWalletModal,
}) => {
  const assetsFromCompound = Object.values(assetsData).map(item => symbolToOption(item.symbol))
    .filter(({ value }) => value !== 'COMP') // Swapping from comp to comp is redundant
    .filter(({ value }) => value !== 'REP') // Not allowed for supplying anymore
    .sort((a, b) => {
      if ((usedAssets[a.value]?.supplied || 0) > (usedAssets[b.value]?.supplied || 0)) return -1;
      if ((usedAssets[a.value]?.supplied || 0) < (usedAssets[b.value]?.supplied || 0)) return 1;
      if (a.value < b.value) return -1;
      if (a.value > b.value) return 1;
      return 0;
    });
  const assetsFromExchange = exchangeAssets().map(item => symbolToOption(item.symbol))
    .filter(({ value }) => value !== 'COMP')
    .sort((a, b) => {
      if (a.value < b.value) return -1;
      if (a.value > b.value) return 1;
      return 0;
    });

  const [claimOption, setClaimOption] = useState(CLAIM_COMP_OPTIONS[0]);
  const [estimateCompPerDay, setEstimateCompPerDay] = useState(
    [{ per: 'week', amount: '0' }, { per: 'month', amount: '0' }, { per: 'year', amount: '0' },
    ]);

  useEffect(() => {
    getCompBalanceAction();
    getAssetPriceAction('COMP');
    if (!Object.keys(assetsData).length) fetchCompoundAssetsData();
  }, []);

  const fetchData = async () => {
    const compPerDay = await calculateCompPerDay(usedAssets, assetsData);
    setEstimateCompPerDay([
      { per: 'week', amount: new Dec(compPerDay).times(7).toString() },
      { per: 'month', amount: new Dec(compPerDay).times(30).toString() },
      { per: 'year', amount: new Dec(compPerDay).times(365).toString() },
    ]);
  };

  useEffect(() => {
    if (Object.keys(usedAssets).length) fetchData();
  }, [usedAssets]);

  const isProxySelected = walletType.value === 'proxy';
  const compEstimateTitle = isProxySelected
    ? 'compound.comp_estimate_title_smart_wallet'
    : 'compound.comp_estimate_title_account';

  const loading = fetchingAssetsData || compBalanceLoading;
  return (
    <div
      id="comp-redeem-modal-wrapper"
      className={`coming-soon-modal ${proxyAddress ? 'has-proxy' : 'no-proxy'}`}
    >
      <ModalHeader closeModal={closeModal} />

      <ModalBody>
        <div className="new-modal-top-wrapper" style={{ backgroundImage: `url(${compIcon})` }}>
          <h1 className="title">{t('compound.comp_tokens')}</h1>
        </div>
        <div className="new-modal-content-wrapper">
          {loading && (<Loader />)}
          {!loading && (
            <>
              <div className="comp-redeem-modal__details">
                <p>{t('compound.comp_modal_desc')}</p>

                <div className="comp-redeem-modal__section">
                  <div className="Flex FlexStart FlexTop">
                    <DataItem
                      style={{ marginRight: '20px' }}
                      value={compBalance.alreadyOnAccount || '0'}
                      label={t('common.current_balance')}
                      symbol="COMP"
                      smallSymbol
                      decimals={+compBalance.alreadyOnAccount > 1 ? 2 : 4}
                      type="standard-smaller"
                      additionalValue={compPrice * compBalance.alreadyOnAccount}
                      additionalValueSymbol="$"
                      additionalValueSymbolAfter={false}
                    />
                    <DataItem
                      value={compPrice}
                      label={`COMP ${t('common.price')}`}
                      symbol="$"
                      symbolAfter={false}
                      type="standard-smaller"
                    />
                  </div>
                </div>

                <div className="comp-redeem-modal__section">
                  <p className="sub-title">{t(compEstimateTitle)}</p>
                  <div className="estimate-comp">
                    {estimateCompPerDay.map(({ per, amount }) => (
                      <DataItem
                        key={per}
                        value={amount}
                        label={`In a ${per}:`}
                        decimals={+amount > 1 ? 2 : 4}
                        type="standard-smaller"
                        additionalValueSymbol="$"
                        additionalValue={compPrice * amount}
                        additionalValueSymbolAfter={false}
                      />
                    ))}
                  </div>
                </div>

              </div>

              <div className="comp-redeem-modal__actions">
                <div className="comp-redeem-modal__section">
                  <p className="sub-title">{t('compound.comp_smart_wallet_title')}</p>
                  <div className="Switch">
                    {CLAIM_COMP_OPTIONS.map((option) => (
                      <div
                        key={option.title}
                        className={claimOption.title === option.title ? 'active' : ''}
                        onClick={() => setClaimOption(option)}
                      >
                        {option.title}
                      </div>
                    ))}
                  </div>
                  <p className="desc">{claimOption.desc}</p>

                  <ClaimOptionAction
                    openCompoundUserWalletModal={openCompoundUserWalletModal}
                    accountType={accountType}
                    assetsFromCompound={assetsFromCompound}
                    assetsFromExchange={assetsFromExchange}
                    proxyAddress={proxyAddress}
                    balance={compBalance.smartWallet}
                    currentOption={claimOption}
                    claimAction={claimCompBalanceAction}
                    openLoginModal={openLoginModal}
                    compPrice={compPrice}
                  />
                </div>

                {(!isProxySelected || compBalance.account !== '0') && (
                  <div className="comp-redeem-modal__section">
                    <p className="sub-title">{t('compound.comp_eoa_title')}</p>
                    <p className="desc">{t('compound.claim_from_account')}</p>

                    <ClaimOptionAction
                      isAccount
                      accountType={accountType}
                      balance={compBalance.account}
                      claimAction={claimCompBalanceAction}
                      openLoginModal={openLoginModal}
                      compPrice={compPrice}
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </ModalBody>
    </div>
  );
};

CompRedeemModal.defaultProps = {
  proxyAddress: '',
};

CompRedeemModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  proxyAddress: PropTypes.string,
  getCompBalanceAction: PropTypes.func.isRequired,
  compBalance: PropTypes.object.isRequired,
  compBalanceLoading: PropTypes.bool.isRequired,
  fetchingAssetsData: PropTypes.bool.isRequired,
  claimCompBalanceAction: PropTypes.func.isRequired,
  accountType: PropTypes.string.isRequired,
  openLoginModal: PropTypes.func.isRequired,
  getAssetPriceAction: PropTypes.func.isRequired,
  compPrice: PropTypes.string.isRequired,
  assetsData: PropTypes.object.isRequired,
  fetchCompoundAssetsData: PropTypes.func.isRequired,
  usedAssets: PropTypes.object.isRequired,
  walletType: PropTypes.object.isRequired,
  openCompoundUserWalletModal: PropTypes.func.isRequired,
};

ClaimOptionAction.defaultProps = {
  isAccount: false,
  currentOption: {},
  proxyAddress: '',
  assetsFromCompound: [],
  assetsFromExchange: [],
  openCompoundUserWalletModal: null,
};

ClaimOptionAction.propTypes = {
  accountType: PropTypes.string.isRequired,
  balance: PropTypes.string.isRequired,
  claimAction: PropTypes.func.isRequired,
  openLoginModal: PropTypes.func.isRequired,
  isAccount: PropTypes.bool,
  currentOption: PropTypes.object,
  proxyAddress: PropTypes.string,
  assetsFromCompound: PropTypes.array,
  assetsFromExchange: PropTypes.array,
  openCompoundUserWalletModal: PropTypes.func,
  compPrice: PropTypes.string.isRequired,
};

const mapStateToProps = ({
  maker, compoundManage, general, assets,
}) => ({
  proxyAddress: maker.proxyAddress,
  compBalance: compoundManage.compBalance,
  compBalanceLoading: compoundManage.compBalanceLoading,
  compBalanceError: compoundManage.compBalanceError,
  fetchingAssetsData: compoundManage.fetchingAssetsData,
  assetsData: compoundManage.assetsData,
  usedAssets: compoundManage.usedAssets,
  accountType: general.accountType,
  compPrice: assets.COMP?.marketPrice,
  walletType: general.walletType,
});

const mapDispatchToProps = {
  getCompBalanceAction,
  claimCompBalanceAction,
  openCompoundUserWalletModal,
  openLoginModal,
  getAssetPriceAction,
  fetchCompoundAssetsData,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompRedeemModal);
