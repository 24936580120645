import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import t from 'translate';
import PropTypes from 'prop-types';
import ModalBody from '../ModalBody';
import ModalHeader from '../ModalHeader';
import './AddressMonitorModal.scss';
import { connectViewOnlyAccount, getViewOnlyAccountsFromLs } from '../../../actions/accountActions';
import TooltipWrapper from '../../TooltipWrapper/TooltipWrapper';
import Loader from '../../Loader/Loader';

const AddressMonitorModal = ({
  closeModal, connectViewOnlyAccount, connectingViewOnly,
}) => {
  const [value, setValue] = useState('');
  const [valid, setValid] = useState(false);
  const [addressOptions, setAddressOptions] = useState([]);
  const input = useRef(null);
  useEffect(() => {
    setAddressOptions(getViewOnlyAccountsFromLs().reverse());
  }, []);
  useEffect(() => {
    if (input) setTimeout(() => input.current.focus(), 100);
  }, [input]);
  const handleSubmit = (e) => {
    e.preventDefault();
    connectViewOnlyAccount(value, closeModal);
  };
  const handleChange = (e) => {
    setValue(e.target.value);
    setValid(new RegExp(/^\s*0x[0-9a-fA-F]{40}\s*$|^\s*[0-9a-zA-Z.].*((\.eth)|(\.xyz)\s*$)/).test(e.target.value));
  };

  return (
    <div>
      {connectingViewOnly && <Loader />}
      {!connectingViewOnly && (
        <>
          <ModalHeader closeModal={closeModal} />
          <ModalBody>
            <div className="new-modal-top-wrapper">
              <h1 className="title">Track Address</h1>
            </div>
            <div className="new-modal-content-wrapper">
              <div className="form-wrapper address-monitor-modal">
                <div className="address-monitor-inputs">
                  <div className="enter-address-description">{t('account.monitor_account_modal')}</div>
                  <div className="form-item-wrapper">
                    <div className="input-values">
                      <form
                        id="get-address-for-monitoring-modal"
                        onSubmit={handleSubmit}
                      >
                        <label
                          className="address-label"
                          htmlFor="address-for-monitoring-modal"
                        >{t('account.address_or_ens')}
                        </label>
                        <input
                          className="form-item"
                          type="text"
                          spellCheck="false"
                          id="address-for-monitoring-modal"
                          list="address-options"
                          autoComplete="off"
                          placeholder="0x"
                          value={value}
                          onChange={handleChange}
                          required
                          ref={input}
                        />
                        <datalist id="address-options">
                          {addressOptions.map(option => (
                            <option value={option} key={option} />
                          ),
                          )}
                        </datalist>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <div className="modal-controls">
            <TooltipWrapper disabled={valid} useText={!valid} title="Invalid input. Must be ethereum address or ens name ending in '.eth' or '.xyz'">
              <button
                type="submit"
                className="button green"
                disabled={!valid || connectingViewOnly}
                form="get-address-for-monitoring-modal"
              >
                {connectingViewOnly ? 'Connecting' : 'Connect'}
              </button>
            </TooltipWrapper>
          </div>
        </>
      )}
    </div>
  );
};
AddressMonitorModal.propTypes = {
  connectViewOnlyAccount: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  connectingViewOnly: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ general }) => ({
  connectingViewOnly: general.connectingViewOnly,
});

const mapDispatchToProps = {
  connectViewOnlyAccount,
};
export default connect(mapStateToProps, mapDispatchToProps)(AddressMonitorModal);
