import React, { Component } from 'react';
import PropTypes from 'prop-types';
import t from 'translate';
import TooltipWrapper from '../TooltipWrapper/TooltipWrapper';
import WarningBox from '../Decorative/WarningBox/WarningBox';
import { formatNumber } from '../../services/utils';
import ErrorBox from '../Decorative/ErrorBox/ErrorBox';
import './TradeSizeImpactWarning.scss';
import InfoIcon from '../Decorative/InfoIcon';

const TradeSizeImpactWarning = ({
  tradeSizeImpact, showOnlyWarning, exactAmount, afterLabel,
}) => {
  if (showOnlyWarning && parseFloat(tradeSizeImpact) <= 1) return '';
  const largeTsiWarningMsg = exactAmount ? 'large_tsi_warning_exact_amount' : 'large_tsi_warning';
  if (parseFloat(tradeSizeImpact) <= 0) {
    return (
      <div className="tsi-warning">
        <div>
          <TooltipWrapper title={t('common.trade_size_impact_info')}><InfoIcon /></TooltipWrapper>
          {t('common.trade_size_impact')}: 0%{afterLabel && t('misc.tsi_for_assets', { '%pair': afterLabel })}
        </div>
      </div>
    );
  }
  if (parseFloat(tradeSizeImpact) <= 1) {
    return (
      <div className="tsi-warning">
        <div>
          <TooltipWrapper title={t('common.trade_size_impact_info')}><InfoIcon /></TooltipWrapper>
          {t('common.trade_size_impact')}: {formatNumber(tradeSizeImpact)}%{afterLabel && t('misc.tsi_for_assets', { '%pair': afterLabel })}
        </div>
      </div>
    );
  }
  if (parseFloat(tradeSizeImpact) <= 3) {
    return (
      <div className="tsi-warning">
        <WarningBox>{t(`common.${largeTsiWarningMsg}`, { '%tsi': formatNumber(tradeSizeImpact) })}{afterLabel && t('misc.tsi_for_assets', { '%pair': afterLabel })}</WarningBox>
      </div>
    );
  }
  if (parseFloat(tradeSizeImpact) <= 5) {
    return (
      <div className="tsi-warning">
        <WarningBox extraDanger>{t(`common.${largeTsiWarningMsg}`, { '%tsi': formatNumber(tradeSizeImpact) })}{afterLabel && t('misc.tsi_for_assets', { '%pair': afterLabel })}</WarningBox>
      </div>
    );
  }
  return (
    <div className="tsi-warning">
      <ErrorBox>{t(`common.${largeTsiWarningMsg}`, { '%tsi': formatNumber(tradeSizeImpact) })}{afterLabel && t('misc.tsi_for_assets', { '%pair': afterLabel })}</ErrorBox>
    </div>
  );
};

TradeSizeImpactWarning.propTypes = {
  tradeSizeImpact: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  showOnlyWarning: PropTypes.bool,
  exactAmount: PropTypes.bool,
  afterLabel: PropTypes.string,
};

TradeSizeImpactWarning.defaultProps = {
  showOnlyWarning: false,
  exactAmount: false,
  afterLabel: '',
};

export default TradeSizeImpactWarning;
