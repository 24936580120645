import Dec from 'decimal.js';
import t from 'translate';
import { formValueSelector } from 'redux-form';
import { captureException } from 'sentry';
import {
  CET_CLOSE_CDP_DATA_REQUEST,
  CET_CLOSE_CDP_DATA_SUCCESS,
  CET_CLOSE_CDP_DATA_FAILURE,
  RECLAIM_COLLATERAL_REQUEST,
  RECLAIM_COLLATERAL_SUCCESS,
  RECLAIM_COLLATERAL_FAILURE,
  GET_OPEN_CDP_OPTIONS_REQUEST,
  GET_OPEN_CDP_OPTIONS_SUCCESS,
  GET_OPEN_CDP_OPTIONS_FAILURE,
} from 'actionTypes/makerActionTypes/makerManageActionTypes/makerManageMcdActionTypes';
import {
  CLOSE_CDP_REQUEST,
  CLOSE_CDP_SUCCESS,
  CLOSE_CDP_FAILURE,
  CLEAR_CLOSE_CDP_ERROR,
} from 'actionTypes/makerActionTypes/makerManageActionTypes/makerManageActionTypes';
import { trackEvent } from 'services/analyticsService';
import { reclaimCollateral } from 'services/makerServices/makerManageServices/makerManageMcdService';
import { calculateTradeSizeImpact } from '../../../services/makerServices/makerManageServices/makerManageService';
import { getCdpInfo } from '../../../services/makerServices/makerService';
import { sendTx } from '../../txNotificationActions';
import { formatNumber } from '../../../services/utils';
import { getSlippageThreshold, getBestExchangePrice } from '../../../services/exchangeServiceV3';
import { mcdCreateCollateralAssets } from '../../../constants/assets';
import { findEnabledStrategiesForCdp, getCollateralInfo } from '../../../services/makerServices/makerMcdService';
import { closeCdp } from '../../../recipes/makerRecipes';
import { callRecipeViaProxy } from '../../../services/contractCallService';
import { getSellFee } from '../../../constants/general';
import { getSubscribedStrategiesAction } from '../../startegiesActions/strategiesActions';
import { getSaverSubscribedGraphData } from '../makerActions';


/**
 * Fetches all data required for the close mcd cdp modal to work
 *
 * @return {function(...[*]=)}
 */
export const getCloseCdpData = () => async (dispatch, getState) => {
  dispatch({ type: CET_CLOSE_CDP_DATA_REQUEST });

  const { cdp, proxyAddress } = getState().maker;

  try {
    const SLIPPAGE_AMOUNT = '1';
    const { debtInAsset, collateral, asset } = cdp;

    const assetPrice = await getSlippageThreshold(asset, 'DAI');
    const { price: exchangeRate } = await getBestExchangePrice(collateral, asset, 'DAI', proxyAddress, true);
    const tradeSizeImpact = calculateTradeSizeImpact(assetPrice, exchangeRate);

    const collInDai = Dec(collateral).mul(exchangeRate).toString();
    const feesDai = Dec(collInDai).mul(0.0025).toString();
    const collMinusDebtInDai = Dec(collInDai).minus(Dec(debtInAsset).add(feesDai)).toString();

    const debtInCollAsset = Dec(debtInAsset).div(exchangeRate).toString();
    const feesAsset = Dec(debtInCollAsset).mul(getSellFee(cdp.debtAsset, cdp.asset)).toString();
    const collMinusDebtInAsset = Dec(collateral).minus(Dec(debtInCollAsset).add(feesAsset)).toString();

    const payload = {
      debtInCollAsset,
      collMinusDebtInAsset,
      collMinusDebtInDai,
      collInDai,
      assetPrice: exchangeRate,
      daiPrice: 1 / exchangeRate,
      tradeSizeImpact,
    };

    dispatch({ type: CET_CLOSE_CDP_DATA_SUCCESS, payload });
  } catch (err) {
    dispatch({ type: CET_CLOSE_CDP_DATA_FAILURE, payload: err.message });
    captureException(err);
  }
};

/**
 * Handles redux state for closing a mcd cdp
 *
 * @param toDai {boolean}
 * @param closeModal {Function}
 * @return {function(...[*]=)}
 */
export const closeCdpAction = (toDai, closeModal) => async (dispatch, getState) => {
  try {
    const {
      general: { account, accountType },
      maker: { cdp, cdps, proxyAddress },
      makerManageMcd: { closeData },
      makerManage: { slippagePercent, slippageRate },
      makerStrategies: { makerSubscribedStrategies },
    } = getState();
    const strategies = findEnabledStrategiesForCdp(cdp.id, makerSubscribedStrategies);

    const additionalInfo = {
      protocol: 'maker',
      firstToken: toDai ? cdp.debtAsset : cdp.asset,
      firstAmount: toDai ? closeData.collMinusDebtInDai : closeData.collMinusDebtInAsset,
    };
    const proxySendHandler = promise => sendTx(promise, t('maker.close_cdp'), `CDP #${cdp.id}`, dispatch, getState, additionalInfo);
    dispatch({ type: CLOSE_CDP_REQUEST });
    trackEvent('makerManage', 'closeMcdCdp');

    const recipe = await closeCdp(cdp, strategies, closeData.assetPrice, slippagePercent, 'maker', toDai, proxyAddress, account);
    await callRecipeViaProxy(accountType, proxySendHandler, proxyAddress, account, recipe);

    dispatch({
      type: CLOSE_CDP_SUCCESS,
      payload: { cdps, cdp: await getCdpInfo(cdp) },
    });

    trackEvent('makerManage', 'closeMcdCdpSuccess');
    if (cdp.isSubscribedToAutomation) dispatch(getSaverSubscribedGraphData());
    if (strategies.length > 0) dispatch(getSubscribedStrategiesAction());
    closeModal();
  } catch (err) {
    dispatch({ type: CLOSE_CDP_FAILURE, payload: err.message });
    captureException(err);
    trackEvent('makerManage', 'closeMcdCdpError', err.message);
  }
};

/**
 * Clears all errors that are used for the close mcd cdp modal
 *
 * @return {function(...[*]=)}
 */
export const clearCloseMcdCdpErrors = () => (dispatch) => {
  dispatch({ type: CLEAR_CLOSE_CDP_ERROR });
};

export const reclaimCollateralAction = () => async (dispatch, getState) => {
  const { cdp } = getState().maker;

  try {
    dispatch({ type: RECLAIM_COLLATERAL_REQUEST });
    trackEvent('makerManage', 'reclaim');

    const proxySendHandler = promise => sendTx(promise, `${t('common.claim')} ${formatNumber(cdp.unclaimedCollateral)} ${cdp.asset.toUpperCase()}`, `CDP #${cdp.id}`, dispatch, getState, { protocol: 'maker' });

    await reclaimCollateral(getState, proxySendHandler);

    dispatch({ type: RECLAIM_COLLATERAL_SUCCESS });
    trackEvent('makerManage', 'reclaimSuccess');
  } catch (err) {
    dispatch({ type: RECLAIM_COLLATERAL_FAILURE, payload: err.message });
    captureException(err);
    trackEvent('makerManage', 'reclaimError', err.message);
  }
};


export const getOpenCdpOptions = () => async (dispatch) => {
  dispatch({ type: GET_OPEN_CDP_OPTIONS_REQUEST });
  try {
    const options = await Promise.all((mcdCreateCollateralAssets).map(async ilk => {
      const { stabilityFee } = await getCollateralInfo(ilk.ilk);
      return ({
        ...ilk,
        value: ilk.ilkLabel,
        label: ilk.ilkLabel,
        secondLabel: `Stability Fee: ${parseFloat(stabilityFee).toFixed(1)}%`,
        meta: {
          icon: ilk.icon,
        },
      });
    }),
    );
    dispatch({ type: GET_OPEN_CDP_OPTIONS_SUCCESS, payload: options });
  } catch (err) {
    console.error(err.message);
    dispatch({ type: GET_OPEN_CDP_OPTIONS_FAILURE, payload: err.message });
  }
};
