import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Bulb from './Bulb';
import XIcon from '../XIcon';
import { changeHideHints } from '../../../actions/accountActions';

import './HintBox.scss';

const HintBox = ({
  children, marginTop, marginBottom, changeHideHints, hideHints, onClose,
}) => (
  <div className={`hint-box-wrapper ${marginTop ? 'margin-top' : ''} ${marginBottom ? 'margin-bottom' : ''} ${hideHints ? 'hide-hints' : ''}`}>
    <div className="close-hints" onClick={onClose || changeHideHints}>
      <XIcon />
    </div>
    <div className="hint-icon-wrapper">
      <Bulb />
    </div>
    <div className="hint-section-description">
      {children}
    </div>
  </div>
);

HintBox.defaultProps = {
  marginTop: false,
  marginBottom: false,
};

HintBox.propTypes = {
  children: PropTypes.node.isRequired,
  changeHideHints: PropTypes.func.isRequired,
  hideHints: PropTypes.bool.isRequired,
  marginTop: PropTypes.bool,
  marginBottom: PropTypes.bool,
  onClose: PropTypes.func,
};

const mapStateToProps = ({ general }) => ({
  hideHints: general.hideHints,
});
const mapDispatchToProps = {
  changeHideHints,
};

export default connect(mapStateToProps, mapDispatchToProps)(HintBox);
