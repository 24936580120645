import t from 'translate';

export const FEEDBACK_CODES = {
  adjustment: 'outside_adjustment',
  unsecure: 'feeling_unsecure',
  ratioChange: 'unexpected_ratio_change',
  closing: 'closing_position',
  other: 'other',
  uncertain: 'uncertain_about_system',
  changingProtocol: 'changing_to_different_protocol',
};

const commonOptions = [
  { value: FEEDBACK_CODES.adjustment, text: t('maker.saver_unsubscribe_reason_1') },
  { value: FEEDBACK_CODES.closing, text: t('maker.saver_unsubscribe_reason_6') },
  { value: FEEDBACK_CODES.changingProtocol, text: t('maker.saver_unsubscribe_reason_7') },
  { value: FEEDBACK_CODES.uncertain, text: t('maker.saver_unsubscribe_reason_8') },
  { value: FEEDBACK_CODES.other, text: t('maker.saver_unsubscribe_reason_5') },
];

export const FIELDS_FOR_FEEDBACK_FORM = {
  compound: [...commonOptions],
  makerdao: [...commonOptions],
  aave: [...commonOptions],
  liquity: [...commonOptions],
};

