import React from 'react';
import t from 'translate';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ModalBody from '../../ModalBody';
import ModalHeader from '../../ModalHeader';
import checkIcon from './checkIcon.svg';
import PageIconCompound from '../../../Decorative/PageIcons/page-compound.svg';
import PageIconMaker from '../../../Decorative/PageIcons/page-maker.svg';
import PageIconAave from '../../../Decorative/PageIcons/page-aave.svg';
import PageIconReflexer from '../../../Decorative/PageIcons/page-reflexer.svg';
import PageIconLiquity from '../../../Decorative/PageIcons/page-liquity.svg';

import './ShifterSuccessModal.scss';
import { formatNumber } from '../../../../services/utils';

const ShifterSuccessModal = ({
  closeModal,
  fromPosition, fromSupply, fromBorrow, fromSupplyAsset, fromBorrowAsset,
  toPosition, toSupply, toBorrow, toSupplyAsset, toBorrowAsset, history, meta, fromProtocol, toProtocol,
}) => {
  let fromIconSrc;
  if (fromProtocol === 'compound') fromIconSrc = PageIconCompound;
  else if (fromProtocol === 'aave') fromIconSrc = PageIconAave;
  else if (fromProtocol === 'reflexer') fromIconSrc = PageIconReflexer;
  else if (fromProtocol === 'liquity') fromIconSrc = PageIconLiquity;
  else fromIconSrc = PageIconMaker;

  let toIconSrc;
  let protocol;
  let route;
  if (toProtocol === 'compound') {
    toIconSrc = PageIconCompound;
    protocol = 'COMPOUND';
    route = 'compound/manage/smart-wallet';
  } else if (toProtocol === 'aave') {
    toIconSrc = PageIconAave;
    protocol = 'AAVE';
    route = 'aave/manage/smart-wallet/v2default';
  } else if (toProtocol === 'reflexer') {
    toIconSrc = PageIconReflexer;
    protocol = 'REFLEXER';
    route = `reflexer/manage/${meta.id}`;
  } else if (toProtocol === 'liquity') {
    toIconSrc = PageIconLiquity;
    protocol = 'LIQUITY';
    route = 'liquity/manage/smart-wallet';
  } else {
    toIconSrc = PageIconMaker;
    protocol = 'CDP';
    route = `makerdao/manage/${meta.id}`;
  }
  return (
    <div>
      <ModalHeader closeModal={closeModal} />
      <ModalBody>
        <div className="new-modal-top-wrapper" style={{ backgroundImage: `url(${checkIcon})` }}>
          <h1 className="title">Shift successful</h1>
        </div>
        <div className="new-modal-content-wrapper">
          <div className="sections">
            <div className="from-section">
              <div className="upper-part">{t('common.from')}</div>
              <div className="lower-part from-data-section">
                <div className="position"><img src={fromIconSrc} alt="" width={30} height={30} />{fromPosition}</div>
                <div className="coll">{t('common.collateral')}:</div>
                <div className="amount">{`${formatNumber(fromSupply, 4)} ${fromSupplyAsset}`}</div>
                <div className="coll">{t('common.debt')}:</div>
                <div className="amount">{`${formatNumber(fromBorrow, 4)} ${fromBorrowAsset}`}</div>
              </div>
            </div>
            <div className="to-section">
              <div className="upper-part">{t('common.to')}</div>
              <div className="lower-part to-data-section">
                <div className="position"><img src={toIconSrc} alt="" width={30} height={30} />{toPosition}</div>
                <div className="coll">{t('common.collateral')}:</div>
                <div className="amount">{`${formatNumber(toSupply, 4)} ${toSupplyAsset}`}</div>
                <div className="coll">{t('common.debt')}:</div>
                <div className="amount">{`${formatNumber(toBorrow, 4)} ${toBorrowAsset}`}</div>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <div className="modal-controls">
        <button
          type="button"
          className="button green"
          onClick={() => {
            closeModal();
            history.push(`${route}`);
          }}
        >
          {t('common.manage')} {protocol}
        </button>
      </div>
    </div>
  );
};

ShifterSuccessModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  fromPosition: PropTypes.string.isRequired,
  fromSupply: PropTypes.string.isRequired,
  fromBorrow: PropTypes.string.isRequired,
  fromSupplyAsset: PropTypes.string.isRequired,
  fromBorrowAsset: PropTypes.string.isRequired,
  toPosition: PropTypes.string.isRequired,
  toSupply: PropTypes.string.isRequired,
  toBorrow: PropTypes.string.isRequired,
  toSupplyAsset: PropTypes.string.isRequired,
  toBorrowAsset: PropTypes.string.isRequired,
  fromProtocol: PropTypes.string.isRequired,
  toProtocol: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(ShifterSuccessModal);
