import PropTypes from 'prop-types';
import React from 'react';
import ModalHeader from '../ModalHeader';
import ConnectLedger from '../../Connect/ConnectLedger/ConnectLedger';
import './LedgerConnectModal.scss';

const LedgerConnectModal = ({
  closeModal,
}) => (
  <div className="ledger-connect-modal-wrapper">
    <ModalHeader closeModal={closeModal} />

    <ConnectLedger closeModal={closeModal} />
  </div>
);

LedgerConnectModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default LedgerConnectModal;
