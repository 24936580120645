import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';

import t from 'translate';
import ModalHeader from '../ModalHeader';
import ModalBody from '../ModalBody';
import RecipeBookItemModalForm from './RecipeBookItemModalForm';

import './RecipeBookItemModal.scss';
import { simulateActions } from '../../../actions/shifterActions';
import PositionStates from '../../Recipes/RecipeCreator/RecipeActionList/RecipeSingleAction/PositionStates';
import { useActionWithLoading, useLoad } from '../../../services/hooks';
import { preloadForRecipe } from '../../../actions/preloadActions';
import Loader from '../../Loader/Loader';
import ErrorBox from '../../Decorative/ErrorBox/ErrorBox';
import { checkActions } from '../../../actions/recipeCreatorActions/recipeValidation';
import { executePremadeRecipe } from '../../../actions/recipeCreatorActions/recipeBookActions';
import { openRecipeInCreator } from '../../../actions/recipeCreatorActions';
import { debouncePromise } from '../../../services/utils';
import { encodePremadeRecipeTitle } from '../../../services/recipeCreator/recipeCreatorShare';
import { openCreateSmartWalletModal } from '../../../actions/modalActions';
import SellAction from '../../../recipeActions/basic/SellAction';
import { getFeePercentage } from '../../../services/feeUtils';

const TABS = ['Details', 'Quick Access'];

const RecipeBookItemModal = ({ data, dispatch }) => {
  useEffect(() => {
    data.history.replace(`?recipe=${encodePremadeRecipeTitle(data.title)}`);
  }, []);
  const closeModal = useCallback(() => {
    data.history.replace(data.history.location.pathname);
    data.closeModal();
  }, []);
  const [activeTab, setActiveTab] = useState(TABS[0]);
  const { loading: preloading, error: preloadError } = useLoad(async () => {
    if (!data.quickAccess) return;
    await dispatch(preloadForRecipe(data));
  }, [data]);
  const {
    action: _executePremadeRecipe,
    loading: executing,
    error: executingError,
  } = useActionWithLoading((actions, afterValues) => dispatch(executePremadeRecipe(actions, afterValues)));
  const assets = useSelector(state => state.assets);
  const network = useSelector(state => state.general.network);
  const proxyAddress = useSelector(state => state.maker.proxyAddress);
  const [createdActions, setActions] = useState([]);
  const [errors, setErrors] = useState([]);
  const [formData, setFormData] = useState(data?.quickAccessInputs?.map(({ value }) => value) || []);
  const [afterValues, setAfterValues] = useState(null);
  const [loading, setLoading] = useState(false);
  const updateAfterValues = useCallback(debouncePromise(async (formData, assets) => {
    setErrors([]);
    setLoading(true);
    try {
      const actions = await (async () => dispatch(data.create(...formData)))();
      const afterValues = await dispatch(simulateActions(actions));
      const { actionCalls } = afterValues;
      const actionErrors = checkActions(actions, actionCalls, assets, true);
      const errors = [...new Set(actionErrors.flat())];
      return {
        actions, afterValues, errors,
      };
    } catch (err) {
      console.error(err);
      return {
        actions: [],
        errors: [err.message || err],
        afterValues: null,
      };
    }
  }, 400), []);
  useEffect(() => {
    if (preloading) return;
    // setLoading(true); // this will show loader right on input, instead of waiting for debounce
    if (formData.some(input => input === '')) return;
    updateAfterValues(formData, assets)
      .then(({ actions, afterValues, errors }) => {
        setErrors(errors);
        setAfterValues(afterValues);
        setActions(actions);
        setLoading(false);
      });
  }, [formData, preloading]);

  const showCreatedActions = !!afterValues && createdActions.length > 0;
  const actions = showCreatedActions ? createdActions : data.actions;

  const finalCall = (afterValues?.actionCalls?.length === actions?.length) &&
    afterValues?.actionCalls?.[afterValues?.actionCalls?.length - 1];
  const hasErrors = errors?.length > 0;
  const recipeHasSellFee = showCreatedActions && actions?.find(a => a instanceof SellAction);
  return (
    <div className="action-modal-wrapper recipe-book-item-modal-wrapper">
      <ModalHeader closeModal={closeModal} />

      <ModalBody>
        <div className="new-modal-top-wrapper">
          {/* <RecipeBookItemBookmark width={25} bookmarked={data.bookmarked} clicked={() => console.log('bookmark modal')} /> */}
          <h1>{data.title}</h1>
        </div>

        <div className="new-modal-content-wrapper">
          {!!data?.quickAccessInputs?.length && (
            <div className="Switch">
              {TABS.map((tab) => (
                <div
                  key={tab}
                  className={activeTab === tab ? 'active' : ''}
                  onClick={() => setActiveTab(tab)}
                >
                  {tab}
                </div>
              ))}
            </div>
          )}

          <section className="row details">
            <div className="column">
              <p>{data.description}</p>
              {!!data.useCases?.length && (
              <p>
                <span>Use Cases: </span>
                <ul>
                  {data.useCases.map((item) => (
                    <li key={item}>{item}</li>
                  ))}
                </ul>
              </p>
              )}
              <div className="actions">
                {actions
                  ?.map((action, index) => ({
                    protocol: showCreatedActions ? action.constructor.protocol : action.protocol,
                    getIcon: showCreatedActions ? action.constructor.getIcon : action.getIcon,
                    name: showCreatedActions ? action.getPrettyName(afterValues.actionCalls, actions) : action.prettyName,
                  }))
                  .map((action, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                    <div key={`${action.prettyName}-${index}`} className={`action-draggable-outer-wrapper ${action.protocol}`}>
                      <div className="action-draggable-wrapper">
                        <div className="protocol-icon" />
                        <div className="icon"><img src={action.getIcon()} alt="" /></div>
                        <div className="inner-content-wrapper" key={action.name}>
                          <div>{action.name}</div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              {recipeHasSellFee && <div className="fee-info">{t('recipes.recipe_fee', { '%fee': getFeePercentage(recipeHasSellFee.inputs[0].address, recipeHasSellFee.inputs[3].address, network) })}</div>}
            </div>
            <div className="column quick-access">
              {!preloading && !preloadError && data.quickAccess && (
                <>
                  <RecipeBookItemModalForm recipe={data} formData={formData} setFormData={setFormData} afterValues={afterValues} />
                  {!!(Object.values(finalCall?.balances || {}).length || Object.values(finalCall?.positions || {}).length)
                  && !loading
                  && (
                    <>
                      <h2>Final state after recipe:</h2>
                      <div className="recipe-final-state-position-states">
                        <PositionStates positions={finalCall?.positions} balances={finalCall?.balances} ignoreZeroTempBalances />
                      </div>
                    </>
                  )}
                </>
              )}

              {data.quickAccess && (loading || preloading) && <div className="Flex MarginBottom20"><Loader /></div>}

              {preloadError && <div className="Flex"><ErrorBox>{preloadError}</ErrorBox></div>}
              {data.quickAccess && errors?.map?.(err => (<ErrorBox key={err}>{err}</ErrorBox>))}
              {executingError && <ErrorBox>{executingError}</ErrorBox>}

              <div className="quick-access-buttons">
                {/* <TooltipWrapper title="Coming soon" useText> */}
                {/*  <button type="button" className="button green highlighted" disabled>Automate recipe</button> */}
                {/* </TooltipWrapper> */}
                {data.quickAccess && proxyAddress && (
                  <button
                    type="button"
                    className="button green"
                    disabled={loading || preloading || !finalCall || hasErrors || executing}
                    onClick={() => _executePremadeRecipe(actions, afterValues)}
                  >
                    {(loading || preloading)
                      ? 'Loading...'
                      : executing ? 'Executing' : 'Execute'}
                  </button>
                )}
                {data.linkTo && (
                  <button
                    type="button"
                    className="button green with-mini-label"
                    onClick={() => {
                      data.history.push(data.linkTo.url);
                      data.closeModal();
                    }}
                  >
                    <span className="mini-label">Included in</span>
                    {data.linkTo.label}
                  </button>
                )}
                {proxyAddress && (
                  <button
                    type="button"
                    className="button gray"
                    disabled={executing}
                    onClick={async () => {
                      dispatch(openRecipeInCreator(await dispatch(data.create(...formData)), data.closeModal, data.history));
                    }}
                  >
                    {(loading || preloading) ? 'Loading...' : 'Edit in creator'}
                  </button>
                )}
                {
                  !proxyAddress && (
                    <>
                      <p>
                        {t('recipes.create_sw_description')}{' '}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://help.defisaver.com/smart-savings/what-is-the-user-wallet"
                        >
                          {t('account.wallet_info_learn_more')}
                        </a>
                      </p>
                      <button
                        type="button"
                        className="button green"
                        disabled={executing}
                        onClick={() => dispatch(openCreateSmartWalletModal())}
                      >
                        Create wallet
                      </button>
                    </>
                  )
                }
              </div>
            </div>
          </section>
        </div>
      </ModalBody>
    </div>
  );
};

RecipeBookItemModal.defaultProps = {};

RecipeBookItemModal.propTypes = {
  data: PropTypes.shape({
    preFetch: PropTypes.func,
    title: PropTypes.string.isRequired,
    id: PropTypes.string,
    description: PropTypes.string.isRequired,
    protocols: PropTypes.arrayOf(PropTypes.string).isRequired,
    actions: PropTypes.array.isRequired,
    create: PropTypes.func.isRequired,
    bookmarked: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    useCases: PropTypes.arrayOf(PropTypes.string).isRequired,
    openRecipeInCreator: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    quickAccess: PropTypes.bool,
    quickAccessInputs: PropTypes.array,
    linkTo: PropTypes.shape({
      url: PropTypes.string,
      label: PropTypes.string,
    }),
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(RecipeBookItemModal);
