import React, { useState } from 'react';
import t from 'translate';
import PropTypes from 'prop-types';
import Dec from 'decimal.js';

import ModalHeader from '../../ModalHeader';
import ModalBody from '../../ModalBody';
import WarningBox from '../../../Decorative/WarningBox/WarningBox';

import './AaveBorrowModal.scss';

const AaveBorrowModal = ({
  closeModal, borrowAmount, borrowAsset, usedAssets, assetsData, resolve,
}) => {
  const interestRateOptions = [
    { value: 1, name: 'Stable' },
    { value: 2, name: 'Variable' },
  ];
  const [borrowInterestRate, setBorrowInterestRate] = useState(interestRateOptions[1]);

  const alreadySuppliedAndEnabled =
    usedAssets[borrowAsset]?.isSupplied
    && (usedAssets[borrowAsset]?.collateral && new Dec(usedAssets[borrowAsset].supplied).gt(borrowAmount));
  const unsupportedStableBorrowAsset = assetsData[borrowAsset].borrowRateStable.toString() === '0' || assetsData[borrowAsset].disabledStableBorrowing;
  return (
    <div className="action-modal-wrapper borrow-modal-wrapper">
      <ModalHeader closeModal={() => {
        closeModal();
        resolve(false);
      }}
      />
      <ModalBody>
        <div className="new-modal-top-wrapper">
          <h1>{t('aave.select_rate')}</h1>
        </div>
        <div className="new-modal-content-wrapper">
          <div className="description">
            <div><span className="bold">Stable</span>{t('aave.stable_desc')}</div>
            <br />
            <div><span className="bold">Variable</span>{t('aave.variable_desc')}</div>
            <br />
            <div>{t('aave.can_switch')}</div>
          </div>
          <br />
          {alreadySuppliedAndEnabled && (
            <div>
              <WarningBox>{t('aave.cant_stable_borrow')}</WarningBox>
            </div>
          )}
          {unsupportedStableBorrowAsset && (
            <div>
              <WarningBox>{t('aave.unsupported_stable_borrow_asset')}</WarningBox>
            </div>
          )}
          <div className={`Switch ${alreadySuppliedAndEnabled || unsupportedStableBorrowAsset ? 'disabled' : ''}`}>
            {interestRateOptions.map(option => (
              <div
                key={option.value}
                onClick={() => {
                  setBorrowInterestRate(option);
                }}
                className={`tab ${borrowInterestRate.value === option.value ? 'active' : ''}`}
              >
                {option.name}
              </div>
            ))}
          </div>
        </div>

      </ModalBody>
      <div className="modal-controls">
        <button
          type="button"
          className="button green"
          onClick={() => { resolve(borrowInterestRate); }}
          disabled={!Object.keys(borrowInterestRate).length}
        >
          {t('common.confirm')}
        </button>
      </div>
    </div>
  );
};

AaveBorrowModal.defaultProps = {};

AaveBorrowModal.propTypes = {
  closeModal: PropTypes.func.isRequired,

  borrowAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  borrowAsset: PropTypes.string.isRequired,

  usedAssets: PropTypes.object.isRequired,
  assetsData: PropTypes.object.isRequired,

  resolve: PropTypes.func.isRequired,
};

export default AaveBorrowModal;
