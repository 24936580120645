import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Dec from 'decimal.js';
import { formatNumber } from '../../../../../../../../services/utils';
import { normalizeFunc } from '../../../../../../../../services/exchangeServiceCommon';

const setSlippagePercent = (slippagePercent, estimatedPrice) => new Dec(estimatedPrice)
  .minus(new Dec(estimatedPrice).div(100).times(slippagePercent))
  .toString();

const SlippageActionField = ({
  type, i, handleChange, actualValue, amountPerToken, tokenA, tokenB, showEstimate,
}) => {
  const [rate, setRate] = useState('');
  const [percent, setPercent] = useState(actualValue);
  useEffect(() => {
    if (percent && amountPerToken) {
      const slippageRate = setSlippagePercent(normalizeFunc(percent), amountPerToken);
      setRate(slippageRate);
    }
  }, [amountPerToken, actualValue]);

  const formattedRate = formatNumber(+rate > (1 / rate) ? rate : (1 / rate));

  return (
    <div className="slippage-item">
      <div className="value">
        <input
          className="form-item"
          autoComplete="off"
          placeholder="0"
          id={type.name}
          type="text"
          value={actualValue}
          onChange={(e) => {
            handleChange(e, i);
            setPercent(e.target.value);
          }}
        />
        <span className="percentage">%</span>
      </div>
      {actualValue && tokenA && tokenB && showEstimate && (
        <span className="estimate">{formattedRate} {tokenA}/{tokenB}</span>
      )}
    </div>
  );
};

SlippageActionField.defaultProps = {
  showEstimate: true,
  tokenA: 'WETH',
  tokenB: 'DAI',
  amountPerToken: '0',
};

SlippageActionField.propTypes = {
  handleChange: PropTypes.func.isRequired,
  type: PropTypes.object.isRequired,
  i: PropTypes.number.isRequired,
  actualValue: PropTypes.string.isRequired,
  amountPerToken: PropTypes.string,
  tokenA: PropTypes.string,
  tokenB: PropTypes.string,
  showEstimate: PropTypes.bool,
};

export default SlippageActionField;
