import dfs from '@defisaver/sdk';
import cloneDeep from 'lodash/cloneDeep';
import RecipeAction from '../RecipeAction';
import { Amount, Source } from '../../components/Recipes/RecipeCreator/inputTypes';
import { changeBalance } from '../../services/recipeCreator/recipeActionUtils';
import ClaimIcon from '../recipeIcons/Claim.svg';

export default class LiquityClaimSPRewardsAction extends RecipeAction {
  static prettyName = 'Claim SP Staking Rewards';

  static protocol = 'liquity';

  static protocolPrettyName = 'Liquity';

  static description = 'Claims ETH and LQTY rewards from the Stability pool.';

  constructor(amount = '', to = 'wallet') {
    super();
    this.inputs = [
      new Source('To', to, true),
    ];
    this.output = new Amount('output', 0);
  }

  async toDfsAction(getState) {
    const {
      general: { account },
      maker: { proxyAddress },
      recipeCreator: { actionCalls, actions },
    } = getState();
    const args = this.mapReturnValuesToArgs(actionCalls.map(a => a.returnValue), actions);
    const to = args[0] === 'wallet' ? account : proxyAddress;
    return new dfs.actions.liquity.LiquityClaimSPRewardsAction(to, to);
  }

  async getAfterValues(_balances = {}, returnValues = [], actions = [], _positions = {}, getState) {
    const positions = { ..._positions };
    const {
      general: { account },
      maker: { proxyAddress },
      liquity: { proxy },
      liquityStaking: { proxy: stakingProxy },
    } = getState();
    if (!positions.liquity) positions.liquity = { ...proxy, ...stakingProxy };
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const amount = args[0] || '0';
    const balances = cloneDeep(_balances);
    console.log(stakingProxy);
    if (parseFloat(stakingProxy.stabilityRewardETH) <= 0 && parseFloat(stakingProxy.stabilityRewardLQTY) <= 0) {
      positions.liquity.nothingToClaimSP = true;
    }
    await changeBalance(balances, args[0], 'ETH', positions.liquity.stabilityRewardETH, args[0] === 'wallet' ? account : proxyAddress);
    await changeBalance(balances, args[0], 'LQTY', positions.liquity.stabilityRewardLQTY, args[0] === 'wallet' ? account : proxyAddress);
    positions.liquity.stabilityRewardETH = '0';
    positions.liquity.stabilityRewardLQTY = '0';
    positions.liquity.showStakingBalances = true;
    this.output.value = amount;
    return {
      returnValue: this.output,
      balances,
      positions,
    };
  }

  _getPrettyName(actionCalls, actions) {
    return 'Claim SP Staking Rewards';
  }

  static getIcon() {
    return ClaimIcon;
  }
}
