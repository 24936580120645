import dfs from '@defisaver/sdk';
import { assetAmountInWei } from '@defisaver/tokens';
import Dec from 'decimal.js';
import cloneDeep from 'lodash/cloneDeep';
import RecipeAction from '../RecipeAction';
import { Amount, Source } from '../../components/Recipes/RecipeCreator/inputTypes';
import { findInsertPosition, getTroveInfo } from '../../services/liquityServices/liquityService';
import SupplyIcon from '../recipeIcons/Supply.svg';
import { changeBalance } from '../../services/recipeCreator/recipeActionUtils';

// still untested
export default class LiquityEthGainToTroveAction extends RecipeAction {
  static prettyName = 'Supply ETH gain to Trove';

  static protocol = 'liquity';

  static protocolPrettyName = 'Liquity';

  static description = 'Supplies ETH earned from the Stability pool to your Trove.';

  constructor(lqtyTo = 'wallet') {
    super();
    this.inputs = [
      new Source('To', lqtyTo, true),
    ];
    this.output = new Amount('output', '0');
  }

  async getAfterValues(_balances = {}, returnValues = [], actions = [], _positions = {}, getState) {
    const positions = { ..._positions };
    const {
      general: { account },
      maker: { proxyAddress },
      liquity: { proxy },
      liquityStaking: { proxy: stakingProxy },
    } = getState();
    if (!positions.liquity) positions.liquity = { ...proxy, ...stakingProxy };
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const balances = cloneDeep(_balances);
    await changeBalance(balances, args[0], 'LQTY', positions.liquity.stabilityRewardLQTY, args[0] === 'wallet' ? account : proxyAddress);
    positions.liquity.collateral += positions.liquity.stabilityRewardETH;
    this.output.value = positions.liquity.stabilityRewardETH;
    positions.liquity.stabilityRewardLQTY = '0';
    positions.liquity.stabilityRewardETH = '';
    positions.liquity.showStakingBalances = true;
    return {
      returnValue: this.output,
      balances,
      positions,
    };
  }

  async toDfsAction(getState, i) {
    const {
      general: { account },
      maker: { proxyAddress },
      recipeCreator: { actionCalls },
      liquityStaking: { proxy: stakingProxy },
    } = getState();
    const positions = actionCalls[i]?.positions || {};
    const from = this.inputs[0].value === 'wallet' ? account : proxyAddress;
    const trove = positions.liquity || await getTroveInfo(proxyAddress);
    const { lowerHint, upperHint } = await findInsertPosition(assetAmountInWei(trove.collateral, 'WETH'), assetAmountInWei(trove.debtInAsset, 'LUSD'), proxyAddress);
    return new dfs.actions.liquity.LiquityEthGainToTroveAction(from, upperHint, lowerHint);
  }

  static getIcon() {
    return SupplyIcon;
  }
}
