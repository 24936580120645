import React, { Component, Fragment } from 'react';
import t from 'translate';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Select from 'react-select';
import TooltipWrapper from '../../TooltipWrapper/TooltipWrapper';
import Loader from '../../Loader/Loader';
import CustomLedgerPathForm from './CustomLedgerPathForm/CustomLedgerPathForm';
import { ACCOUNT_TYPES, LEDGER_ACC_TYPES } from '../../../constants/general';
import {
  changeLedgerAccType,
  listLedgerAccounts,
  setLedgerDefaultPath,
  ledgerListMoreAccounts,
  resetConnectLedger,
} from '../../../actions/generalActions';
import { normalLogin } from '../../../actions/accountActions';
import { compareAddresses, formatNumber } from '../../../services/utils';
import ModalBody from '../../Modals/ModalBody';
import ErrorBox from '../../Decorative/ErrorBox/ErrorBox';

import '../Connect.scss';
import './ConnectLedger.scss';

class ConnectLedger extends Component {
  UNSAFE_componentWillMount() {
    this.props.setLedgerDefaultPath();
    this.props.listLedgerAccounts();
  }

  componentWillUnmount() {
    this.props.resetConnectLedger();
  }

  render() {
    const {
      ledgerAccType, changeLedgerAccType, listingLedgerAccounts, listingLedgerAccountsError,
      ledgerListMoreAccounts, ledgerAccounts, listingMoreLedgerAccounts, connectingProvider, normalLogin,
      account, accountType,
    } = this.props;

    const noClick = listingLedgerAccounts || listingMoreLedgerAccounts || connectingProvider;

    return (
      <>
        <ModalBody>
          <div className="connect-login-wrapper ledger-wrapper">
            <h2>{t('account.connect_message', { '%item': 'Ledger' })}</h2>
            <div className="acc-select-wrapper">
              <div className="label">{t('common.account_type')}</div>

              <Select
                className="select box"
                classNamePrefix="select"
                value={ledgerAccType}
                onChange={changeLedgerAccType}
                isDisabled={noClick}
                options={LEDGER_ACC_TYPES}
                onBlur={event => event.preventDefault()}
              />
            </div>

            {ledgerAccType && ledgerAccType.value === 'custom' && (<CustomLedgerPathForm />)}

            <div className="list-wrapper">
              {
                listingLedgerAccounts && (
                  <div className="loading-wrapper">
                    <Loader message={t('common.loading')} />
                  </div>
                )
              }

              {
                !listingLedgerAccounts && listingLedgerAccountsError && (
                  <ErrorBox>{listingLedgerAccountsError}</ErrorBox>
                )
              }

              {
                !listingLedgerAccounts && !listingLedgerAccountsError && (
                  <div className="list">
                    <div className="accounts-wrapper">
                      {
                        ledgerAccounts.map((item) => {
                          const connectedAddress = compareAddresses(item.address, account);

                          if (connectedAddress && accountType === ACCOUNT_TYPES.ledger) {
                            return (
                              <TooltipWrapper title={t('account.account_connected')} key={item.path}>
                                <div className="single-acc no-click Flex SpaceBetween">
                                  <span>{item.address}</span>
                                  {
                                    item.balance !== '0'
                                      ? <span>{formatNumber(item.balance)} ETH</span>
                                      : <i>{t('common.empty')}</i>
                                  }
                                </div>
                              </TooltipWrapper>
                            );
                          }

                          return (
                            <div
                              className={`single-acc ${noClick ? 'no-click' : 'can-click'} Flex SpaceBetween`}
                              key={item.path}
                              onClick={() => {
                                if (noClick) return;
                                normalLogin(ACCOUNT_TYPES.ledger, item.path);
                              }}
                            >
                              <span>{item.address}</span>
                              {
                                item.balance !== '0'
                                  ? <span>{formatNumber(item.balance)} ETH</span>
                                  : <i>{t('common.empty')}</i>
                              }
                            </div>
                          );
                        })
                      }
                    </div>

                    {
                      ledgerAccType && ledgerAccType.value !== 'custom' && (
                        <button
                          type="button"
                          className="more"
                          disabled={listingMoreLedgerAccounts}
                          onClick={ledgerListMoreAccounts}
                        >
                          {t('account.load_accounts', {
                            '%status': listingMoreLedgerAccounts ? t('common.loading_no_dots') : t('common.load'),
                          })}
                        </button>
                      )
                    }
                  </div>
                )
              }
            </div>
          </div>
        </ModalBody>
      </>
    );
  }
}

ConnectLedger.propTypes = {
  account: PropTypes.string.isRequired,
  accountType: PropTypes.string.isRequired,
  connectingProvider: PropTypes.bool.isRequired,
  ledgerAccType: PropTypes.object.isRequired,
  changeLedgerAccType: PropTypes.func.isRequired,
  listLedgerAccounts: PropTypes.func.isRequired,
  ledgerListMoreAccounts: PropTypes.func.isRequired,
  setLedgerDefaultPath: PropTypes.func.isRequired,
  resetConnectLedger: PropTypes.func.isRequired,
  normalLogin: PropTypes.func.isRequired,
  listingLedgerAccounts: PropTypes.bool.isRequired,
  listingLedgerAccountsError: PropTypes.string.isRequired,
  ledgerAccounts: PropTypes.array.isRequired,
  listingMoreLedgerAccounts: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ general }) => ({
  account: general.account,
  accountType: general.accountType,
  connectingProvider: general.connectingProvider,
  ledgerAccType: general.ledgerAccType,

  listingLedgerAccounts: general.listingLedgerAccounts,
  listingLedgerAccountsError: general.listingLedgerAccountsError,
  ledgerAccounts: general.ledgerAccounts,

  listingMoreLedgerAccounts: general.listingMoreLedgerAccounts,
});

const mapDispatchToProps = {
  changeLedgerAccType,
  listLedgerAccounts,
  setLedgerDefaultPath,
  ledgerListMoreAccounts,
  resetConnectLedger,
  normalLogin,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectLedger);
