import t from 'translate';
import { captureException } from 'sentry';
import { callWithRetry } from 'services/utils';
import * as aaveAutomation from 'services/aaveServices/aaveSaverService';
import Dec from 'decimal.js';
import {
  SET_AAVE_SAVER_UPDATING,
  AAVE_SAVER_SUBSCRIBE_RESET,
  AAVE_SAVER_SUBSCRIBE_REQUEST,
  AAVE_SAVER_SUBSCRIBE_SUCCESS,
  AAVE_SAVER_SUBSCRIBE_FAILURE,
  GET_AAVE_SAVER_SUBSCRIBE_HISTORY_REQUEST,
  GET_AAVE_SAVER_SUBSCRIBE_HISTORY_SUCCESS,
  GET_AAVE_SAVER_SUBSCRIBE_HISTORY_LOADMORE_SUCCESS,
  GET_AAVE_SAVER_SUBSCRIBE_HISTORY_FAILURE,
  GET_AAVE_SAVER_SUBSCRIBE_GRAPH_DATA_REQUEST,
  GET_AAVE_SAVER_SUBSCRIBE_GRAPH_DATA_SUCCESS,
  GET_AAVE_SAVER_SUBSCRIBE_GRAPH_DATA_FAILURE,
  AAVE_SAVER_IS_SUBSCRIBED_REQUEST,
  AAVE_SAVER_IS_SUBSCRIBED_SUCCESS,
  AAVE_SAVER_IS_SUBSCRIBED_FAILURE,
  AAVE_SAVER_UNSUBSCRIBE_REQUEST,
  AAVE_SAVER_UNSUBSCRIBE_SUCCESS,
  AAVE_SAVER_UNSUBSCRIBE_FAILURE,
} from '../../actionTypes/aaveActionTypes/aaveSaverActionTypes';
import { trackEvent } from '../../services/analyticsService';
import { sendTx } from '../txNotificationActions';
import { closeModal } from '../modalActions';
import { calculateLimits } from '../../services/aaveServices/aaveSaverService';
import { minMoneymarketProtectionDebt } from '../../config/clientConfig.json';
import { sendAutomationUnsubscribeApiCall } from '../../services/apiService';
import * as modalActions from '../modalActions';

export const setAaveSaverUpdating = payload => (dispatch) => { dispatch({ type: SET_AAVE_SAVER_UPDATING, payload }); };

/**
 * Resets the state values for the Aave saver subscribe form
 *
 * @return {Function}
 */
export const resetAaveSaverSubscribe = () => (dispatch) => { dispatch({ type: AAVE_SAVER_SUBSCRIBE_RESET }); };

export const getAaveSaverSubscribedHistory = (page = 1) => async (dispatch, getState) => {
  dispatch({ type: GET_AAVE_SAVER_SUBSCRIBE_HISTORY_REQUEST });

  try {
    const { proxyAddress } = getState().maker;
    const { selectedMarket } = getState().aaveManage;
    const events = await aaveAutomation.getAaveSaverHistory(proxyAddress, selectedMarket.value);
    const payload = { page, items: events };

    dispatch({
      type: page === 1
        ? GET_AAVE_SAVER_SUBSCRIBE_HISTORY_SUCCESS
        : GET_AAVE_SAVER_SUBSCRIBE_HISTORY_LOADMORE_SUCCESS,
      payload,
    });
  } catch (err) {
    dispatch({ type: GET_AAVE_SAVER_SUBSCRIBE_HISTORY_FAILURE, payload: err.message });
    captureException(err);
  }
};

/**
 * Fetches data for the status graph on Aave automation section
 *
 * @return {Function}
 */
export const getAaveSaverSubscribedGraphData = () => async (dispatch, getState) => {
  dispatch({ type: GET_AAVE_SAVER_SUBSCRIBE_GRAPH_DATA_REQUEST });

  try {
    const { proxyAddress } = getState().maker;
    const { selectedMarket } = getState().aaveManage;
    const { ratio } = getState().aaveManage.proxy[selectedMarket.value];
    const data = await aaveAutomation.getAaveSaverSubscriptionInfo(proxyAddress, selectedMarket.value);

    const payload = {
      ...data,
      current: ratio,
    };

    dispatch({ type: GET_AAVE_SAVER_SUBSCRIBE_GRAPH_DATA_SUCCESS, payload });

    return payload;
  } catch (err) {
    dispatch({ type: GET_AAVE_SAVER_SUBSCRIBE_GRAPH_DATA_FAILURE, payload: err.message });
  }
};

export const getAaveSaverSubscribedData = () => async (dispatch) => {
  await dispatch(getAaveSaverSubscribedGraphData());
  await dispatch(getAaveSaverSubscribedHistory());
};

export const getAaveIsSubscribed = () => async (dispatch, getState) => {
  dispatch({ type: AAVE_SAVER_IS_SUBSCRIBED_REQUEST });
  const { proxyAddress } = getState().maker;
  const { selectedMarket } = getState().aaveManage;
  try {
    const payload = await aaveAutomation.isSubscribedToMonitor(proxyAddress, selectedMarket.value);

    dispatch({ type: AAVE_SAVER_IS_SUBSCRIBED_SUCCESS, payload });

    return payload;
  } catch (err) {
    dispatch({ type: AAVE_SAVER_IS_SUBSCRIBED_FAILURE, payload: err.message });
  }
};

/**
 * Subscribes the user to the saver service
 *
 * @param formData {Object}
 * @return {Function}
 */
export const aaveSaverSubscribe = formData => async (dispatch, getState) => {
  const {
    advanced,
    ratio,
    ratioBelow,
    ratioOver,
    updating,
    boostEnabled,
  } = formData;

  trackEvent('aaveAutomate', updating ? 'update' : 'subscribe');
  dispatch({ type: AAVE_SAVER_SUBSCRIBE_REQUEST });

  try {
    const {
      aaveManage: { selectedMarket },
      aaveSaver: { subscribed },
      maker: { proxyAddress },
      general: { account, accountType, path },
    } = getState();
    const { borrowedUsd } = getState().aaveManage.proxy[selectedMarket.value];

    if (!subscribed && new Dec(borrowedUsd).lt(minMoneymarketProtectionDebt)) throw new Error(t('errors.trying_sub_under_min_debt'));
    let boostTo = ratio;
    let repayTo = ratio;


    let { minRatio, maxRatio } = calculateLimits(ratio);
    if (advanced) {
      // eslint-disable-next-line prefer-destructuring
      boostTo = formData.boostTo;
      // eslint-disable-next-line prefer-destructuring
      repayTo = formData.repayTo;
      minRatio = ratioBelow;
      maxRatio = ratioOver;
    }

    const proxySendHandler = promise => sendTx(promise, t('aave.aave_saver_subscribe'), 'Aave', dispatch, getState, { protocol: 'aave' });

    const payload = await aaveAutomation.subscribeToMonitor(
      accountType,
      path,
      account,
      proxySendHandler,
      proxyAddress,
      minRatio,
      boostEnabled ? maxRatio : 0,
      repayTo,
      boostEnabled ? boostTo : 0,
      boostEnabled,
      updating,
      selectedMarket.value,
    );

    dispatch({ type: AAVE_SAVER_SUBSCRIBE_SUCCESS, payload });
    await callWithRetry(
      // eslint-disable-next-line arrow-body-style
      () => {
        return aaveAutomation.isSubscribedToMonitor(proxyAddress, selectedMarket.value);
      },
      10,
      data => data.isSubscribedToAutomation,
    );
    dispatch(getAaveSaverSubscribedData());
    trackEvent('aaveAutomate', updating ? 'updateSuccess' : 'subscribeSuccess');
  } catch (err) {
    dispatch({ type: AAVE_SAVER_SUBSCRIBE_FAILURE, payload: err.message });
    captureException(err);
    trackEvent('aaveAutomate', updating ? 'updateError' : 'subscribeError', err.message);
  }
};

/**
 * Unsubscribes the user to the saver service
 *
 * @return {Function}
 */
export const aaveSaverUnsubscribe = (formData) => async (dispatch, getState) => {
  dispatch({ type: AAVE_SAVER_UNSUBSCRIBE_REQUEST });
  trackEvent('aaveAutomate', 'unsubscribe');

  try {
    const { proxyAddress } = getState().maker;
    const { selectedMarket } = getState().aaveManage;
    if (formData) {
      const apiReqPromise = sendAutomationUnsubscribeApiCall('Aave', proxyAddress, 0, formData.field, formData.description);
      dispatch(closeModal());
    }
    const onTxHashCallback = () => { dispatch(modalActions.openAutomationUnsubNextStepsModal({ strategyName: t('strategies.legacy_title'), protocol: 'aave' })); };
    const proxySendHandler = promise => sendTx(promise, t('aave.aave_saver_unsubscribe'), 'Aave', dispatch, getState, { protocol: 'aave' }, onTxHashCallback);
    const { account, accountType, path } = getState().general;

    await aaveAutomation.unsubscribeFromMonitor(
      accountType,
      path,
      account,
      proxySendHandler,
      proxyAddress,
      selectedMarket.value,
    );

    dispatch({ type: AAVE_SAVER_UNSUBSCRIBE_SUCCESS });
    trackEvent('aaveAutomate', 'unsubscribeSuccess');
  } catch (err) {
    dispatch({ type: AAVE_SAVER_UNSUBSCRIBE_FAILURE, payload: err.message });
    captureException(err);
    trackEvent('aaveAutomate', 'unsubscribeError', err.message);
  }
};
