export const TRANSFER_CDP_MODAL = 'TRANSFER_CDP_MODAL';
export const MAKER_REPAY_MODAL = 'MAKER_REPAY_MODAL';
export const MAKER_BOOST_MODAL = 'MAKER_BOOST_MODAL';
export const SHORT_MODAL = 'SHORT_MODAL';
export const LEVERAGE_MODAL = 'LEVERAGE_MODAL';
export const NON_WEB3_PROVIDER_MODAL = 'NON_WEB3_PROVIDER_MODAL';
export const COMPOUND_USER_WALLET_MODAL = 'COMPOUND_USER_WALLET_MODAL';
export const AAVE_USER_WALLET_MODAL = 'AAVE_USER_WALLET_MODAL';
export const AAVE_EMODE_MODAL = 'AAVE_EMODE_MODAL';
export const LEDGER_CONNECT_MODAL = 'LEDGER_CONNECT_MODAL';
export const TREZOR_CONNECT_MODAL = 'TREZOR_CONNECT_MODAL';
export const MIGRATE_CDP_FROM_INSTADAPP_MODAL = 'MIGRATE_CDP_FROM_INSTADAPP_MODAL';
export const AUTOMATION_UNSUB_FEEDBACK_MODAL = 'AUTOMATION_UNSUB_FEEDBACK_MODAL';
export const AUTOMATION_UNSUB_NEXT_STEPS_MODAL = 'AUTOMATION_UNSUB_NEXT_STEPS_MODAL';
export const EMERGENCY_MESSAGE_MODAL = 'EMERGENCY_MESSAGE_MODAL';
export const CONFIRMATION_MODAL = 'CONFIRMATION_MODAL';
export const INFO_MODAL = 'INFO_MODAL';
export const SEND_CONFIRMATION_MODAL = 'SEND_CONFIRMATION_MODAL';
export const CLOSE_MCD_CDP_MODAL = 'CLOSE_MCD_CDP_MODAL';
export const CDP_CREATED_MODAL = 'CDP_CREATED_MODAL';
export const AUTOMATION_MIGRATION_MODAL = 'AUTOMATION_MIGRATION_MODAL';
export const COMPOUND_COLLATERAL_REQUIRED_MODAL = 'COMPOUND_COLLATERAL_REQUIRED_MODAL';
export const AAVE_COLLATERAL_REQUIRED_MODAL = 'AAVE_COLLATERAL_REQUIRED_MODAL';
export const COMPOUND_BOOST_MODAL = 'COMPOUND_BOOST_MODAL';
export const COMPOUND_REPAY_MODAL = 'COMPOUND_REPAY_MODAL';
export const COMPOUND_PROXY_MIGRATION_MODAL = 'COMPOUND_PROXY_MIGRATION_MODAL';
export const MIGRATE_COMPOUND_FROM_INSTADAPP_MODAL = 'MIGRATE_COMPOUND_FROM_INSTADAPP_MODAL';
export const COMP_REDEEM_MODAL = 'COMP_REDEEM_MODAL';
export const ADDRESS_MONITOR_MODAL = 'ADDRESS_MONITOR_MODAL';
export const LOGIN_MODAL = 'LOGIN_MODAL';
export const AAVE_PROXY_MIGRATION_MODAL = 'AAVE_PROXY_MIGRATION_MODAL';
export const AAVE_PROXY_V1_MIGRATION_MODAL = 'AAVE_PROXY_V1_MIGRATION_MODAL';
export const MIGRATE_AAVE_FROM_INSTADAPP_MODAL = 'MIGRATE_AAVE_FROM_INSTADAPP_MODAL';
export const AAVE_BOOST_MODAL = 'AAVE_BOOST_MODAL';
export const AAVE_REPAY_MODAL = 'AAVE_REPAY_MODAL';
export const AAVE_BOOST_MODAL_V2 = 'AAVE_BOOST_MODAL_V2';
export const AAVE_REPAY_MODAL_V2 = 'AAVE_REPAY_MODAL_V2';
export const SHIFTER_SUCCESS_MODAL = 'SHIFTER_SUCCESS_MODAL';
export const AAVE_BORROW_MODAL = 'AAVE_BORROW_MODAL';
export const AAVE_STAKE_MODAL = 'AAVE_STAKE_MODAL';
export const SMARTWALLET_PICKER_MODAL = 'SMARTWALLET_PICKER_MODAL';
export const RFL_REPAY_MODAL = 'RFL_REPAY_MODAL';
export const RFL_BOOST_MODAL = 'RFL_BOOST_MODAL';
export const RFL_SAFE_CREATED_MODAL = 'RFL_SAFE_CREATED_MODAL';
export const SIM_START_MODAL = 'SIM_START_MODAL';
export const SIM_END_MODAL = 'SIM_END_MODAL';
export const CHANGE_NETWORK_MODAL = 'CHANGE_NETWORK_MODAL';
export const RECIPE_BOOK_ITEM_MODAL = 'RECIPE_BOOK_ITEM_MODAL';
export const RECIPE_SUCCESS_MODAL = 'RECIPE_SUCCESS_MODAL';
export const LQTY_CLOSE_TROVE_MODAL = 'LQTY_CLOSE_TROVE_MODAL';
export const LQTY_BOOST_MODAL = 'LQTY_BOOST_MODAL';
export const LQTY_REPAY_MODAL = 'LQTY_REPAY_MODAL';
export const LQTY_STAKING_MODAL = 'LQTY_STAKING_MODAL';
export const LQTY_REDEEM_MODAL = 'LQTY_REDEEM_MODAL';
export const MM_CHANGE_MODAL = 'MM_CHANGE_MODAL';
export const MANAGE_WALLETS_MODAL = 'MANAGE_WALLETS_MODAL';
export const CREATE_SMART_WALLET_MODAL = 'CREATE_SMART_WALLET_MODAL';
export const SMART_SAVINGS_CLAIM_AND_SUPPLY = 'SMART_SAVINGS_CLAIM_AND_SUPPLY';
export const TROVE_CREATED_MODAL = 'TROVE_CREATED_MODAL';
export const UNISWAP_V3_IMAGE_MODAL = 'UNISWAP_V3_IMAGE_MODAL';
