export const LQTY_ADD_COLLATERAL_REQUEST = 'LQTY_ADD_COLLATERAL_REQUEST';
export const LQTY_ADD_COLLATERAL_SUCCESS = 'LQTY_ADD_COLLATERAL_SUCCESS';
export const LQTY_ADD_COLLATERAL_FAILURE = 'LQTY_ADD_COLLATERAL_FAILURE';

export const LQTY_MAX_ADD_COLLATERAL_REQUEST = 'LQTY_MAX_ADD_COLLATERAL_REQUEST';
export const LQTY_MAX_ADD_COLLATERAL_SUCCESS = 'LQTY_MAX_ADD_COLLATERAL_SUCCESS';
export const LQTY_MAX_ADD_COLLATERAL_FAILURE = 'LQTY_MAX_ADD_COLLATERAL_FAILURE';

export const LQTY_WITHDRAW_COLLATERAL_REQUEST = 'LQTY_WITHDRAW_COLLATERAL_REQUEST';
export const LQTY_WITHDRAW_COLLATERAL_SUCCESS = 'LQTY_WITHDRAW_COLLATERAL_SUCCESS';
export const LQTY_WITHDRAW_COLLATERAL_FAILURE = 'LQTY_WITHDRAW_COLLATERAL_FAILURE';

export const LQTY_MAX_WITHDRAW_COLLATERAL_REQUEST = 'LQTY_MAX_WITHDRAW_COLLATERAL_REQUEST';
export const LQTY_MAX_WITHDRAW_COLLATERAL_SUCCESS = 'LQTY_MAX_WITHDRAW_COLLATERAL_SUCCESS';
export const LQTY_MAX_WITHDRAW_COLLATERAL_FAILURE = 'LQTY_MAX_WITHDRAW_COLLATERAL_FAILURE';

export const LQTY_GENERATE_REQUEST = 'LQTY_GENERATE_REQUEST';
export const LQTY_GENERATE_SUCCESS = 'LQTY_GENERATE_SUCCESS';
export const LQTY_GENERATE_FAILURE = 'LQTY_GENERATE_FAILURE';

export const LQTY_MAX_GENERATE_REQUEST = 'LQTY_MAX_GENERATE_REQUEST';
export const LQTY_MAX_GENERATE_SUCCESS = 'LQTY_MAX_GENERATE_SUCCESS';
export const LQTY_MAX_GENERATE_FAILURE = 'LQTY_MAX_GENERATE_FAILURE';

export const LQTY_PAYBACK_REQUEST = 'LQTY_PAYBACK_REQUEST';
export const LQTY_PAYBACK_SUCCESS = 'LQTY_PAYBACK_SUCCESS';
export const LQTY_PAYBACK_FAILURE = 'LQTY_PAYBACK_FAILURE';

export const LQTY_MAX_PAYBACK_REQUEST = 'LQTY_MAX_PAYBACK_REQUEST';
export const LQTY_MAX_PAYBACK_SUCCESS = 'LQTY_MAX_PAYBACK_SUCCESS';
export const LQTY_MAX_PAYBACK_FAILURE = 'LQTY_MAX_PAYBACK_FAILURE';

export const LQTY_AFTER_VALUES_REQUEST = 'LQTY_AFTER_VALUES_REQUEST';
export const LQTY_AFTER_VALUES_SUCCESS = 'LQTY_AFTER_VALUES_SUCCESS';
export const LQTY_AFTER_VALUES_FAILURE = 'LQTY_AFTER_VALUES_FAILURE';

export const LQTY_CLOSE_TROVE_REQUEST = 'LQTY_CLOSE_TROVE_REQUEST';
export const LQTY_CLOSE_TROVE_SUCCESS = 'LQTY_CLOSE_TROVE_SUCCESS';
export const LQTY_CLOSE_TROVE_FAILURE = 'LQTY_CLOSE_TROVE_FAILURE';

export const LQTY_CLAIM_COLLATERAL_REQUEST = 'LQTY_CLAIM_COLLATERAL_REQUEST';
export const LQTY_CLAIM_COLLATERAL_SUCCESS = 'LQTY_CLAIM_COLLATERAL_SUCCESS';
export const LQTY_CLAIM_COLLATERAL_FAILURE = 'LQTY_CLAIM_COLLATERAL_FAILURE';

export const LQTY_BOOST_REQUEST = 'LQTY_BOOST_REQUEST';
export const LQTY_BOOST_SUCCESS = 'LQTY_BOOST_SUCCESS';
export const LQTY_BOOST_FAILURE = 'LQTY_BOOST_FAILURE';

export const LQTY_GET_BOOST_MODAL_DATA_REQUEST = 'LQTY_GET_BOOST_MODAL_DATA_REQUEST';
export const LQTY_GET_BOOST_MODAL_DATA_SUCCESS = 'LQTY_GET_BOOST_MODAL_DATA_SUCCESS';
export const LQTY_GET_BOOST_MODAL_DATA_FAILURE = 'LQTY_GET_BOOST_MODAL_DATA_FAILURE';

export const LQTY_RESET_BOOST_MODAL = 'LQTY_RESET_BOOST_MODAL';

export const LQTY_GET_MAX_BOOST_REQUEST = 'LQTY_GET_MAX_BOOST_REQUEST';
export const LQTY_GET_MAX_BOOST_SUCCESS = 'LQTY_GET_MAX_BOOST_SUCCESS';
export const LQTY_GET_MAX_BOOST_FAILURE = 'LQTY_GET_MAX_BOOST_FAILURE';

export const LQTY_REPAY_REQUEST = 'LQTY_REPAY_REQUEST';
export const LQTY_REPAY_SUCCESS = 'LQTY_REPAY_SUCCESS';
export const LQTY_REPAY_FAILURE = 'LQTY_REPAY_FAILURE';

export const LQTY_GET_REPAY_MODAL_DATA_REQUEST = 'LQTY_GET_REPAY_MODAL_DATA_REQUEST';
export const LQTY_GET_REPAY_MODAL_DATA_SUCCESS = 'LQTY_GET_REPAY_MODAL_DATA_SUCCESS';
export const LQTY_GET_REPAY_MODAL_DATA_FAILURE = 'LQTY_GET_REPAY_MODAL_DATA_FAILURE';

export const LQTY_RESET_REPAY_MODAL = 'LQTY_RESET_REPAY_MODAL';

export const LQTY_GET_MAX_REPAY_REQUEST = 'LQTY_GET_MAX_REPAY_REQUEST';
export const LQTY_GET_MAX_REPAY_SUCCESS = 'LQTY_GET_MAX_REPAY_SUCCESS';
export const LQTY_GET_MAX_REPAY_FAILURE = 'LQTY_GET_MAX_REPAY_FAILURE';

export const LQTY_REDEEM_REQUEST = 'LQTY_REDEEM_REQUEST';
export const LQTY_REDEEM_SUCCESS = 'LQTY_REDEEM_SUCCESS';
export const LQTY_REDEEM_FAILURE = 'LQTY_REDEEM_FAILURE';
