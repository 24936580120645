import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import './Notifications.scss';
import { hideNotification } from '../../actions/noitificationActions';

const Notifications = ({
  showNotif, notifType, notifMessage, hideNotification,
}) => (
  <div className={`notification-wrapper ${showNotif ? 'active' : ''}`}>
    <div className={`notification-inner-wrapper ${notifType}`} onClick={hideNotification}>
      {notifMessage}
    </div>
  </div>
);

Notifications.propTypes = {
  showNotif: PropTypes.bool.isRequired,
  notifMessage: PropTypes.string.isRequired,
  notifType: PropTypes.string.isRequired,
  hideNotification: PropTypes.func.isRequired,
};

const mapStateToProps = ({ notification }) => ({
  showNotif: notification.displayed,
  notifMessage: notification.message,
  notifType: notification.type,
});

const mapDispatchToProps = {
  hideNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
