import React from 'react';
import PropTypes from 'prop-types';
import { protocolIcons } from '../../../constants/general';

const RecipeProtocolCircleIcon = ({ protocol }) => {
  let _protocol = protocol;
  if (protocol.startsWith('aave')) _protocol = 'aave';
  if (protocol.startsWith('uniswap')) _protocol = 'uniswapV3';
  return (<img className="protocol-circle-icon" width={23} height={23} src={protocolIcons[_protocol]} alt="" />);
};

RecipeProtocolCircleIcon.propTypes = {
  protocol: PropTypes.string.isRequired,
};
export default RecipeProtocolCircleIcon;
