// Do not import other actions to this file

export const authHelpcrunch = (newData = {}) => (dispatch, getState) => {
  try {
    if (window.HelpCrunch) {
      const { account, accountType } = getState().general;
      const { proxyAddress } = getState().maker.proxyAddress;
      const data = {
        account,
        accountType,
        proxyAddress: proxyAddress || newData.proxyAddress,
      };
      window.HelpCrunch('updateUserData', data);
    }
  } catch (err) { console.error(err); }
};
