import t from 'translate';
import React from 'react';
import PropTypes from 'prop-types';
import { assetAmountInEth } from '@defisaver/tokens';
import { formatEtherScanLink, formatNumber, parseTimestamp } from '../../../services/utils';

import Loader from '../../Loader/Loader';
import TxErrorIcon from '../../Decorative/TxErrorIcon';
import TxSuccessIcon from '../../Decorative/TxSuccessIcon';
import TxReplacedIcon from '../../Decorative/TxReplacedIcon';
import TxCanceledIcon from '../../Decorative/TxCanceledIcon';

import './SingleServerTx.scss';
import TooltipWrapper from '../../TooltipWrapper/TooltipWrapper';

const formatTx = hash => `${hash.slice(0, 6)}...`;

const SingleServerTx = ({
  txHash, status, mined, title, category, errorCode, errorMessage, gasUsed, gasLimit, gasPrice, ethPrice, fork,
}) => {
  let description = `Status: ${status}`;
  if (status === 'pending') description = t('misc.tx_pending', { '%txHash': formatTx(txHash) });
  if (status === 'confirmed') description = t('misc.tx_confirmed', { '%txHash': formatTx(txHash) });
  if (status === 'failed') description = t('misc.tx_rejected', { '%txHash': formatTx(txHash) });
  if (status === 'speedup') description = t('misc.tx_replaced', { '%txHash': formatTx(txHash) });
  if (status === 'cancel') description = t('misc.tx_canceled', { '%txHash': formatTx(txHash) });

  return (
    <div className={`single-server-tx-wrapper ${status}`}>

      <div className="single-server-tx-header">
        <div className="title">{title}</div>
        <div className="date">
          {category && `${category} | `}{mined ? parseTimestamp(mined) : 'Pending...'}
        </div>
      </div>
      <div className="single-server-tx-description">
        <div className="description">
          {
            fork
              ? (<div className="description">{ description }</div>)
              : (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="description"
                  href={formatEtherScanLink(txHash)}
                >
                  { description }
                </a>
              )
          }
        </div>
        <div className="status-wrapper">
          { status === 'pending' && (<Loader />) }
          { status === 'confirmed' && (<TxSuccessIcon />) }
          { status === 'speedup' && (<TxReplacedIcon />) }
          { status === 'failed' && (<TxErrorIcon />) }
          { status === 'cancel' && (<TxCanceledIcon />) }
          { status === 'dropped' && (<TxErrorIcon />) }
        </div>
        {
          txHash && status === 'failed' && !fork && (
            <div className="defiexplore-error-data">
              <p className="error">
                Reason:{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://defiexplore.com/tx/${txHash}`}
                >
                  {errorCode ? t(errorCode) : errorMessage || 'Unknown error'}
                </a>
              </p>
            </div>
          )
        }
        {
          gasUsed && (
            <div className="additional-data">
              Gas fee: {formatNumber(assetAmountInEth(gasUsed * gasPrice * 1e9, 'ETH'))} ETH | ${formatNumber(assetAmountInEth(gasUsed * gasPrice * 1e9, 'ETH') * ethPrice)}
              <br />
              Gas used: {gasUsed} ({formatNumber(gasUsed / gasLimit * 100)}%)
            </div>
          )
        }
      </div>
    </div>
  );
};


SingleServerTx.propTypes = {
  txHash: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  mined: PropTypes.string,
  title: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  errorCode: PropTypes.string,
  errorMessage: PropTypes.string,
  gasUsed: PropTypes.number,
  gasLimit: PropTypes.number,
  gasPrice: PropTypes.number,
  ethPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fork: PropTypes.bool,
};

SingleServerTx.defaultProps = {
  mined: null,
  errorCode: null,
  errorMessage: null,
  gasUsed: null,
  gasLimit: null,
  gasPrice: null,
  ethPrice: null,
  fork: false,
};

export default SingleServerTx;
