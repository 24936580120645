import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { connect } from 'react-redux';
import {
  SelectOption,
  SelectValueContainer,
} from 'components/Forms/SelectComponent/SelectComponent';
import withErrorFallback from '../../../../../../../ErrorFallback/ErrorFallback';

const CdpIdActionField = ({
  i, originalValue, handleChange, cdpOptions, safeOptions, filtered, iForUsingFiltered, type,
}) => {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    if (type.protocol === 'maker') {
      const _options = type.allowCrop
        ? cdpOptions
        : cdpOptions.filter(cdp => cdp.type !== 'crop');
      setOptions(_options);
    }
    if (type.protocol === 'reflexer') setOptions(safeOptions);
  }, [cdpOptions, safeOptions]);
  return (
    <Select
      className="select box"
      classNamePrefix="select"
      value={options.find(i => i.value === originalValue) || null}
      components={{
        Option: SelectOption,
        ValueContainer: SelectValueContainer,
      }}
      onChange={(e) => handleChange({ target: e }, i)}
      options={i === iForUsingFiltered ? filtered : options}
      onBlur={event => event.preventDefault()}
    />
  );
};

CdpIdActionField.defaultProps = {
  cdpOptions: [],
  safeOptions: [],
};

CdpIdActionField.propTypes = {
  cdpOptions: PropTypes.array,
  safeOptions: PropTypes.array,
  handleChange: PropTypes.func.isRequired,
  i: PropTypes.number.isRequired,
  originalValue: PropTypes.string.isRequired,
  filtered: PropTypes.array,
  iForUsingFiltered: PropTypes.number,
  type: PropTypes.object.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default withErrorFallback(connect(mapStateToProps, mapDispatchToProps)(CdpIdActionField));
