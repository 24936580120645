import React from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';

import InfoTooltip from '../../Decorative/InfoTooltip';

import './SelectComponent.scss';
import ArrowCaret from '../../Decorative/ArrowCaret';

// TODO Style in css
export const SelectOption = props => (
  <components.Option {...props}>
    <div className="default-select-option-wrapper">
      {props.data?.meta?.icon && <div className="icon-wrapper"><props.data.meta.icon height={props.data?.height || 30} width={props.data?.width || 30} /></div>}
      <div className="option-value">
        { props.data.secondLabel && <span className="second-select-label">{props.data.secondLabel}</span>}
        <div className="select__single-value">
          { props.children }
        </div>
      </div>
    </div>
  </components.Option>
);

SelectOption.propTypes = {
  data: PropTypes.shape({
    value: PropTypes.string.isRequired,
    meta: PropTypes.shape({
      icon: PropTypes.func,
    }),
    label: PropTypes.string.isRequired,
    secondLabel: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
  }).isRequired,
  children: PropTypes.node.isRequired,
};

export const SelectValueContainer = (props) => {
  const option = props.getValue()[0];
  return (
    <components.ValueContainer {...props}>
      <div className="default-select-value-wrapper">
        {option?.meta?.icon && <div className="icon-wrapper"><option.meta.icon height={option?.height || 30} width={option?.width || 30} /></div>}
        <div className="option-value">
          { option?.secondLabel && <span className="second-select-label">{option?.secondLabel}</span>}
          { props.children }
        </div>
        <ArrowCaret to="down" />
      </div>
    </components.ValueContainer>
  );
};

SelectValueContainer.propTypes = {
  getValue: PropTypes.func.isRequired,
  selectOption: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  getStyles: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

const selectComponents = {
  Option: SelectOption,
  ValueContainer: SelectValueContainer,
};

const SelectComponent = ({
  input, placeholder, wrapperClassName, inputClassName, errorClassName, showErrorText, labelText,
  id, meta: { touched, error }, focus, additional, disabled, setFieldToBeFocused, disableTouchValidation,
  options, tooltipInfo,
}) => {
  const touchedValidation = touched || disableTouchValidation;

  return (
    <div className={`${wrapperClassName} ${touchedValidation && error ? 'wrapper-error' : ''} ${disabled ? 'disabled' : ''} ${labelText ? 'with-label' : ''}`}>
      {labelText && (
        <label>
          {tooltipInfo && (<InfoTooltip message={tooltipInfo} />)}
          {labelText}
        </label>
      )}
      <Select
        {...input}
        {...additional}
        placeholder={placeholder}
        id={id || ''}
        className={`select box ${inputClassName}`}
        classNamePrefix="select"
        ref={input => setFieldToBeFocused(input)}
        autoFocus={focus}
        isDisabled={disabled}
        options={options}
        onBlur={() => input.onBlur(input.value)}
        components={selectComponents}
      />
      {touchedValidation && ((error && showErrorText && <div className={errorClassName}>{error}</div>))}
    </div>
  );
};

SelectComponent.defaultProps = {
  id: '',
  placeholder: '',
  showErrorText: false,
  disableTouchValidation: false,
  focus: false,
  disabled: false,
  additional: {},
  labelText: '',
  tooltipInfo: '',
  wrapperClassName: 'form-item-wrapper simple-select',
  inputClassName: 'form-item',
  errorClassName: 'form-item-error',
  setFieldToBeFocused: () => {},
  // icon: <div />,
};

SelectComponent.propTypes = {
  input: PropTypes.any.isRequired,
  placeholder: PropTypes.string,
  wrapperClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  errorClassName: PropTypes.string,
  id: PropTypes.string,
  meta: PropTypes.object.isRequired,
  showErrorText: PropTypes.bool,
  focus: PropTypes.bool,
  disabled: PropTypes.bool,
  disableTouchValidation: PropTypes.bool,
  additional: PropTypes.object,
  setFieldToBeFocused: PropTypes.func,
  options: PropTypes.array.isRequired,
  labelText: PropTypes.string,
  tooltipInfo: PropTypes.string,
  // icon: PropTypes.node,
};

export default SelectComponent;
