export const GET_TX_HISTORY_FROM_API_REQUEST = 'GET_TX_HISTORY_FROM_API_REQUEST';
export const GET_TX_HISTORY_FROM_API_SUCCESS = 'GET_TX_HISTORY_FROM_API_SUCCESS';
export const GET_TX_HISTORY_FROM_API_FAILURE = 'GET_TX_HISTORY_FROM_API_FAILURE';

export const GET_TOKENS_INFO_PORTFOLIO_REQUEST = 'GET_TOKENS_INFO_PORTFOLIO_REQUEST';
export const GET_TOKENS_INFO_PORTFOLIO_SUCCESS = 'GET_TOKENS_INFO_PORTFOLIO_SUCCESS';
export const GET_TOKENS_INFO_PORTFOLIO_FAILURE = 'GET_TOKENS_INFO_PORTFOLIO_FAILURE';

export const GET_TOKENS_ON_PROXY_PORTFOLIO_REQUEST = 'GET_TOKENS_ON_PROXY_PORTFOLIO_REQUEST';
export const GET_TOKENS_ON_PROXY_PORTFOLIO_SUCCESS = 'GET_TOKENS_ON_PROXY_PORTFOLIO_SUCCESS';
export const GET_TOKENS_ON_PROXY_PORTFOLIO_FAILURE = 'GET_TOKENS_ON_PROXY_PORTFOLIO_FAILURE';

export const FETCH_ALL_PORTFOLIO_DATA_REQUEST = 'FETCH_ALL_PORTFOLIO_DATA_REQUEST';
export const FETCH_ALL_PORTFOLIO_DATA_SUCCESS = 'FETCH_ALL_PORTFOLIO_DATA_SUCCESS';
export const FETCH_ALL_PORTFOLIO_DATA_FAILURE = 'FETCH_ALL_PORTFOLIO_DATA_FAILURE';

export const PORTFOLIO_PARSE_DATA_REQUEST = 'PORTFOLIO_PARSE_DATA_REQUEST';
export const PORTFOLIO_PARSE_DATA_SUCCESS = 'PORTFOLIO_PARSE_DATA_SUCCESS';
export const PORTFOLIO_PARSE_DATA_FAILURE = 'PORTFOLIO_PARSE_DATA_FAILURE';

export const WITHDRAW_TOKENS_FROM_PROXY_TO_WALLET_REQUEST = 'WITHDRAW_TOKENS_FROM_PROXY_TO_WALLET_REQUEST';
export const WITHDRAW_TOKENS_FROM_PROXY_TO_WALLET_SUCCESS = 'WITHDRAW_TOKENS_FROM_PROXY_TO_WALLET_SUCCESS';
export const WITHDRAW_TOKENS_FROM_PROXY_TO_WALLET_FAILURE = 'WITHDRAW_TOKENS_FROM_PROXY_TO_WALLET_FAILURE';

export const GET_UNKNOWN_TOKENS_INFO_REQUEST = 'GET_UNKNOWN_TOKENS_INFO_REQUEST';
export const GET_UNKNOWN_TOKENS_INFO_SUCCESS = 'GET_UNKNOWN_TOKENS_INFO_SUCCESS';
export const GET_UNKNOWN_TOKENS_INFO_FAILURE = 'GET_UNKNOWN_TOKENS_INFO_FAILURE';

export const UPDATE_ACC_TIME_UPDATED = 'UPDATE_ACC_TIME_UPDATED';
