import React from 'react';
import { components } from 'react-select';
import Loader from '../Loader/Loader';

const CdpSelectLoadingIndicator = props => (
  <components.DropdownIndicator {...props}>
    <div className="loading-indicator"><Loader /></div>
  </components.DropdownIndicator>
);

CdpSelectLoadingIndicator.propTypes = {};

export default CdpSelectLoadingIndicator;
