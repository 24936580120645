import React from 'react';
import PropTypes from 'prop-types';
import TxSuccessIcon from '../TxSuccessIcon';
import TxErrorIcon from '../TxErrorIcon';
import LoaderIcon from '../../Loader/LoaderIcon';

import './StepStatus.scss';

const StepStatus = ({ status, title }) => (
  <div className={`process-step ${status}`}>
    <span className="process-step-status-icon">
      {status === 'done' && (<TxSuccessIcon height={20} width={20} />)}
      {status === 'error' && (<TxErrorIcon height={20} width={20} />)}
      {status === 'loading' && (<span className="loader-wrapper"><LoaderIcon color="white" size={12} stroke={7} /></span>)}
      {status === 'empty' && (<span className="empty" />)}
    </span>
    {title}
  </div>
);

StepStatus.propTypes = {
  status: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default StepStatus;
