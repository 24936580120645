import {
  GET_TX_HISTORY_FROM_API_REQUEST,
  GET_TX_HISTORY_FROM_API_SUCCESS,
  GET_TX_HISTORY_FROM_API_FAILURE,

  GET_TOKENS_INFO_PORTFOLIO_REQUEST,
  GET_TOKENS_INFO_PORTFOLIO_SUCCESS,
  GET_TOKENS_INFO_PORTFOLIO_FAILURE,

  PORTFOLIO_PARSE_DATA_REQUEST,
  PORTFOLIO_PARSE_DATA_SUCCESS,
  PORTFOLIO_PARSE_DATA_FAILURE,

  GET_TOKENS_ON_PROXY_PORTFOLIO_REQUEST,
  GET_TOKENS_ON_PROXY_PORTFOLIO_SUCCESS,
  GET_TOKENS_ON_PROXY_PORTFOLIO_FAILURE,

  FETCH_ALL_PORTFOLIO_DATA_REQUEST,
  FETCH_ALL_PORTFOLIO_DATA_SUCCESS,
  FETCH_ALL_PORTFOLIO_DATA_FAILURE,

  WITHDRAW_TOKENS_FROM_PROXY_TO_WALLET_REQUEST,
  WITHDRAW_TOKENS_FROM_PROXY_TO_WALLET_SUCCESS,
  WITHDRAW_TOKENS_FROM_PROXY_TO_WALLET_FAILURE,

  UPDATE_ACC_TIME_UPDATED,
} from '../actionTypes/portfolioActionTypes';
import { CONNECT_PROVIDER_SUCCESS } from '../actionTypes/generalActionTypes';


const INITIAL_STATE = {
  txHistory: [],
  gettingTxHistory: false,
  gettingTxHistoryError: '',
  txHistoryLastPage: false,

  wallets: {},
  gettingData: false,
  gettingDataError: '',
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case CONNECT_PROVIDER_SUCCESS:
      return { ...INITIAL_STATE };

    case GET_TX_HISTORY_FROM_API_REQUEST: return {
      ...state,
      gettingTxHistory: true,
      gettingTxHistoryError: '',
    };

    case GET_TX_HISTORY_FROM_API_SUCCESS: return {
      ...state,
      gettingTxHistory: false,
      gettingTxHistoryError: '',
      txHistory: payload.items,
      txHistoryLastPage: payload.txHistoryLastPage,
    };

    case GET_TX_HISTORY_FROM_API_FAILURE: return {
      ...state,
      gettingTxHistory: false,
      gettingTxHistoryError: payload,
    };

    case UPDATE_ACC_TIME_UPDATED:
      return {
        ...state,
        wallets: {
          ...state.wallets,
          [action.account]: {
            ...state.wallets?.[action.account] || {},
            lastUpdated: Date.now(),
          },
        },
      };

    case GET_TOKENS_INFO_PORTFOLIO_REQUEST:
      return {
        ...state,
        wallets: {
          ...state.wallets,
          [action.account]: {
            ...state.wallets?.[action.account] || {},
            tokensInfo: {
              ...state.wallets?.[action.account]?.tokensInfo || {},
              gettingTokensInfo: true,
              gettingTokensInfoError: '',
            },
          },
        },
      };

    case GET_TOKENS_INFO_PORTFOLIO_SUCCESS:
      return {
        ...state,
        wallets: {
          ...state.wallets,
          [action.account]: {
            ...state.wallets?.[action.account] || {},
            tokensInfo: {
              ...state.wallets?.[action.account]?.tokensInfo || {},
              gettingTokensInfo: false,
              data: payload,
            },
          },
        },
      };

    case GET_TOKENS_INFO_PORTFOLIO_FAILURE:
      return {
        ...state,
        wallets: {
          ...state.wallets,
          [action.account]: {
            ...state.wallets?.[action.account] || {},
            tokensInfo: {
              ...state.wallets?.[action.account]?.tokensInfo || {},
              gettingTokensInfo: false,
              gettingTokensInfoError: payload,
            },
          },
        },
      };

    case PORTFOLIO_PARSE_DATA_REQUEST:
      return {
        ...state,
        wallets: {
          ...state.wallets,
          [action.account]: {
            ...state.wallets?.[action.account] || {},
            [`${action.protocol}Data`]: {
              ...state.wallets?.[action.account]?.[`${action.protocol}Data`] || {},
              [`parsing${action.protocol}Data`]: true,
              [`parsing${action.protocol}DataError`]: '',
            },
          },
        },
      };

    case PORTFOLIO_PARSE_DATA_SUCCESS:
      return {
        ...state,
        wallets: {
          ...state.wallets,
          [action.account]: {
            ...state.wallets?.[action.account] || {},
            [`${action.protocol}Data`]: {
              ...state.wallets?.[action.account]?.[`${action.protocol}Data`] || {},
              [`parsing${action.protocol}Data`]: false,
              data: payload,
            },
          },
        },
      };

    case PORTFOLIO_PARSE_DATA_FAILURE:
      return {
        ...state,
        wallets: {
          ...state.wallets,
          [action.account]: {
            ...state.wallets?.[action.account] || {},
            [`${action.protocol}Data`]: {
              ...state.wallets?.[action.account]?.[`${action.protocol}Data`] || {},
              [`parsing${action.protocol}Data`]: false,
              [`parsing${action.protocol}DataError`]: payload,
            },
          },
        },
      };

    case GET_TOKENS_ON_PROXY_PORTFOLIO_REQUEST:
      return {
        ...state,
        gettingProxyBalances: true,
        gettingProxyBalancesError: '',
      };

    case GET_TOKENS_ON_PROXY_PORTFOLIO_SUCCESS:
      return {
        ...state,
        gettingProxyBalances: false,
        wallets: {
          ...state.wallets,
          [action.account]: {
            ...state.wallets?.[action.account] || {},
            tokensInfo: {
              ...state.wallets?.[action.account]?.tokensInfo || {},
              gettingTokensInfo: false,
              data: {
                ...state.wallets?.[action.account]?.tokensInfo?.data || {},
                assets: [
                  ...state.wallets?.[action.account]?.tokensInfo?.data?.assets || [],
                  ...payload,
                ],
              },
            },
          },
        },
      };

    case GET_TOKENS_ON_PROXY_PORTFOLIO_FAILURE:
      return {
        ...state,
        gettingProxyBalances: false,
        gettingProxyBalancesError: payload,
      };

    case FETCH_ALL_PORTFOLIO_DATA_REQUEST:
      return {
        ...state,
        gettingData: true,
        gettingDataError: '',
      };

    case FETCH_ALL_PORTFOLIO_DATA_SUCCESS:
      return {
        ...state,
        gettingData: false,
        lastUpdated: action.updateTime ? action.time : state.lastUpdated,
      };

    case FETCH_ALL_PORTFOLIO_DATA_FAILURE:
      return {
        ...state,
        gettingData: false,
        gettingDataError: payload,
      };

    case WITHDRAW_TOKENS_FROM_PROXY_TO_WALLET_REQUEST:
      return {
        ...state,
        withdrawingAssetFromProxy: true,
        withdrawingAssetFromProxyError: '',
        withdrawingAsset: payload,
      };

    case WITHDRAW_TOKENS_FROM_PROXY_TO_WALLET_SUCCESS:
      return {
        ...state,
        withdrawingAssetFromProxy: false,
        withdrawingAssetFromProxyError: '',
        withdrawingAsset: '',
      };
    case WITHDRAW_TOKENS_FROM_PROXY_TO_WALLET_FAILURE:
      return {
        ...state,
        withdrawingAssetFromProxy: false,
        withdrawingAssetFromProxyError: payload,
        withdrawingAsset: '',
      };

    default:
      return state;
  }
};
