import Dec from 'decimal.js';
import config from 'config/config.json';
import {
  assetAmountInEth, assetAmountInWei, getIlkInfo,
} from '@defisaver/tokens';
import {
  mcdCdpManagerAddress,
  mcdSaverProxyActionsAddress,
  proxyRegistryInterfaceAddress,
} from '../../contractRegistryService';
import callTx from '../../txService';
import { bytesToString, numStringToBytes32 } from '../../utils';
import { getCdpManagerForType } from '../makerMcdService';


export const callProxyContract = async (
  accountType, path, sendTxFunc, proxyAddress, account, funcName,
  params, value,
) => {
  const contract = config.McdSaverProxyActions;

  const proxyContract = new window._web3.eth.Contract(config.DSProxy.abi, proxyAddress);

  const contractFunction = contract.abi.find(abi => abi.name === funcName);
  const data = window._web3.eth.abi.encodeFunctionCall(contractFunction, params);

  const txParams = { from: account, value };
  const funcParams = [mcdSaverProxyActionsAddress, data];

  return callTx(accountType, path, sendTxFunc, proxyContract, 'execute(address,bytes)', funcParams, txParams);
};

/**
 * Transfers a mcd cdp from the owner to another address
 *
 * @param accountType {String}
 * @param path {String}
 * @param sendTxFunc {Function}
 * @param toAddress {String}
 * @param cdpIdBytes32 {String}
 * @param proxyAddress {String}
 * @param txParams {Object}
 * @param funcName {String}
 * @return {Promise<void>}
 */
export const transferCdp = async (
  accountType, path, sendTxFunc, toAddress, cdpIdBytes32, proxyAddress, txParams, funcName,
) => {
  const contract = config.McdSaverProxyActions;
  const contractFunction = contract.abi.find(abi => abi.name === funcName);

  const proxyContract = new window._web3.eth.Contract(config.DSProxy.abi, proxyAddress);

  const params = [proxyRegistryInterfaceAddress, mcdCdpManagerAddress, cdpIdBytes32, toAddress];
  const data = window._web3.eth.abi.encodeFunctionCall(contractFunction, params);
  const funcParams = [mcdSaverProxyActionsAddress, data];

  return callTx(accountType, path, sendTxFunc, proxyContract, 'execute(address,bytes)', funcParams, txParams);
};

export const reclaimCollateral = async (getState, sendTxFunc) => {
  const { cdp } = getState().maker;
  const { account, accountType, path } = getState().general;
  const { proxyAddress } = getState().maker;
  const amountParam = assetAmountInWei(cdp.unclaimedCollateral, cdp.asset);
  const cdpIdBytes32 = numStringToBytes32(cdp.id.toString());

  const contract = config.McdSaverProxyActions;
  const contractFunction = contract.abi.find(abi => abi.name === 'frob');

  const proxyContract = new window._web3.eth.Contract(config.DSProxy.abi, proxyAddress);

  const params = [getCdpManagerForType(cdp.type), cdpIdBytes32, amountParam, 0];
  const data = window._web3.eth.abi.encodeFunctionCall(contractFunction, params);
  const funcParams = [mcdSaverProxyActionsAddress, data];

  const txParams = { from: account };

  return callTx(accountType, path, sendTxFunc, proxyContract, 'execute(address,bytes)', funcParams, txParams);
};
