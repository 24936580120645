import React, { useEffect, useState } from 'react';
import t from 'translate';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logOut } from 'actions/accountActions';
import { updateAssetBalanceInterval, clearAssetBalanceTimeoutId } from 'actions/assetsActions';
import { ACCOUNT_TYPES } from '../../../constants/general';
import { ALL_WALLET_OPTIONS, DEBUG_WALLET_OPTION, GNOSIS_SAFE_OPTION } from '../../../constants/wallet';

import { setProxyViaModal } from '../../../actions/makerActions/makerActions';
import {
  formatAcc, formatEtherScanLink, formatNumber, isMobileDevice,
} from '../../../services/utils';
import { openChangeNetworkModal } from '../../../actions/modalActions';

import TooltipWrapper from '../../TooltipWrapper/TooltipWrapper';
import ServerNotifications from '../../ServerNotifications/ServerNotifications';
import ServerTxMonitor from '../../ServerTxMonitor/ServerTxMonitor';
import GasPriceStatusWidget from '../../GasPriceStatusWidget/GasPriceStatusWidget';
import ConnectWalletHover from './ConnectWalletHover';
import ConnectNetworkHover from './ConnectNetworkHover';
import ConnectWalletIcon from './ConnectWalletIcon';

import './ConnectWalletDropdown.scss';

const ConnectWalletDropdown = ({
  loggingIn, account, accountType, setProxyViaModal,
  smartWallets, balance, logOut, forkAccountType, additionalAccountData,
  updateAssetBalanceInterval, clearAssetBalanceTimeoutId, ethBalanceTimeoutId, network, openChangeNetworkModal,
}) => {
  useEffect(() => {
    if (account) updateAssetBalanceInterval('ETH');
    return clearAssetBalanceTimeoutId('ETH', ethBalanceTimeoutId);
  }, []);

  useEffect(() => {
    clearAssetBalanceTimeoutId('ETH', ethBalanceTimeoutId);
    if (account) updateAssetBalanceInterval('ETH');
  }, [account]);

  const [walletDropdownOpened, setOpenWalletDropdown] = useState(false);
  const [networkDropdownOpened, setOpenNetworkDropdown] = useState(false);

  let options = ALL_WALLET_OPTIONS();
  if (window.parent !== window) {
    options.splice(-2, 0, GNOSIS_SAFE_OPTION);
  }
  if (window.debugWithAccount || localStorage.getItem('debugWithAccount')) {
    options.push(DEBUG_WALLET_OPTION);
  }
  if (isMobileDevice()) options = options.filter(({ mobile }) => mobile === true);

  const hideAddress = accountType === ACCOUNT_TYPES.fork && !forkAccountType;
  let walletLabel = formatAcc(account);
  if (additionalAccountData.ensName) walletLabel = additionalAccountData.ensName;
  if (hideAddress) walletLabel = t('common.simulation');

  return (
    <div className="connect-wallet-select-inline">
      <div className="connect-wallet-select">
        <div className="label">
          {
             account && (
               <>
                 <span key="label">
                   <span className={`connect-wallet-status ${loggingIn ? 'connecting' : 'connected'}`}>
                     {
                        !loggingIn && account && (
                          <TooltipWrapper copy title={account} useText disabled={hideAddress}>
                            <span className="acc">{ walletLabel }</span>
                          </TooltipWrapper>
                        )
                      }
                     <TooltipWrapper title={loggingIn ? `${t('common.connecting')}` : t('common.connected')}>
                       <span className="status" />
                     </TooltipWrapper>
                   </span>
                 </span>
                 <span key="balance" className="balance">
                   { loggingIn && t('common.loading_no_dots') }
                   {
                      !loggingIn && (
                        <>
                          {
                            (accountType === ACCOUNT_TYPES.walletConnect || accountType === ACCOUNT_TYPES.coinbase) && (
                              <><a className="Pointer" onClick={logOut}>Disconnect</a> | </>
                            )
                          }
                          <TooltipWrapper title={balance}>
                            {
                              hideAddress
                                ? <>{formatNumber(balance, 2)} ETH</>
                                : (
                                  <a href={formatEtherScanLink(account, 'address')} target="_blank" rel="noopener noreferrer">
                                    {formatNumber(balance, 2)} ETH
                                  </a>
                                )
                            }
                          </TooltipWrapper>
                        </>
                      )
                    }
                 </span>
               </>
             )
           }
        </div>

        <div>
          { loggingIn && <div className="connect-cta">{t('common.connecting')}...</div>}

          {!account && !loggingIn && <div className="connect-cta" onClick={() => setOpenWalletDropdown(!walletDropdownOpened)}>{t('account.connect_wallet')}</div>}

          {
            account && !loggingIn && (
              <ConnectWalletIcon
                clickedWallet={() => setOpenWalletDropdown(!walletDropdownOpened)}
                clickedNetwork={() => setOpenNetworkDropdown(!networkDropdownOpened)}
                network={network}
                account={account}
                accountType={accountType}
                additionalAccountData={additionalAccountData}
                forkAccountType={forkAccountType}
              />
            )
          }
          {walletDropdownOpened && <ConnectWalletHover close={() => setOpenWalletDropdown(!walletDropdownOpened)} />}
          {networkDropdownOpened && <ConnectNetworkHover close={() => setOpenNetworkDropdown(!networkDropdownOpened)} />}
        </div>
      </div>

      {
        smartWallets.length > 1 && (
          <div
            className="icon-wrapper"
            onClick={setProxyViaModal}
          >
            <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19.7589 5.21507V2.99285C19.7589 1.7673 18.6825 0.77063 17.3589 0.77063H3.75894C1.77414 0.77063 0.158936 2.26619 0.158936 4.10396V17.4373C0.158936 19.8829 2.31174 20.7706 3.75894 20.7706H19.7589C21.0825 20.7706 22.1589 19.774 22.1589 18.5484V7.4373C22.1589 6.21174 21.0825 5.21507 19.7589 5.21507ZM17.3589 15.2151H14.9589V10.7706H17.3589V15.2151ZM3.75894 5.21507C3.44996 5.20228 3.15822 5.07962 2.94447 4.87265C2.73071 4.66568 2.61141 4.39034 2.61141 4.10396C2.61141 3.81759 2.73071 3.54225 2.94447 3.33528C3.15822 3.1283 3.44996 3.00565 3.75894 2.99285H17.3589V5.21507H3.75894Z" fill="#61717E" />
            </svg>
          </div>
        )
      }

      { account && (
        <div className="notification-dropdowns-wrapper">
          { accountType !== ACCOUNT_TYPES.fork && <ServerNotifications /> }
          <ServerTxMonitor />
          {network === 1 && (<GasPriceStatusWidget />)}
        </div>
      ) }
      {
        account && accountType === ACCOUNT_TYPES.fork && (
          <style dangerouslySetInnerHTML={{ __html: 'body::before { opacity: 1; }' }} />
        )
      }
    </div>
  );
};

ConnectWalletDropdown.defaultProps = {
  ethBalanceTimeoutId: -1,
};

ConnectWalletDropdown.propTypes = {
  logOut: PropTypes.func.isRequired,

  // Account info
  loggingIn: PropTypes.bool.isRequired,
  accountType: PropTypes.string.isRequired,
  forkAccountType: PropTypes.string.isRequired,
  account: PropTypes.string.isRequired,
  balance: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  ethBalanceTimeoutId: PropTypes.number,
  additionalAccountData: PropTypes.object.isRequired,
  network: PropTypes.number,

  // Actions
  updateAssetBalanceInterval: PropTypes.func.isRequired,
  clearAssetBalanceTimeoutId: PropTypes.func.isRequired,
  setProxyViaModal: PropTypes.func.isRequired,
  smartWallets: PropTypes.array.isRequired,
  openChangeNetworkModal: PropTypes.func.isRequired,
};

const mapStateToProps = ({ general, assets }) => ({
  loggingIn: general.loggingIn,
  accountType: general.accountType,
  forkAccountType: general.forkAccountType,
  account: general.account,
  balance: assets.ETH.balance,
  ethBalanceTimeoutId: assets.ETH.balanceTimeoutId,
  smartWallets: general.smartWallets,
  additionalAccountData: general.additionalAccountData,
  network: general.network,
});

const mapDispatchToProps = {
  updateAssetBalanceInterval,
  clearAssetBalanceTimeoutId,
  logOut,
  setProxyViaModal,
  openChangeNetworkModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectWalletDropdown);
