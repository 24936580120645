import dfs from '@defisaver/sdk';
import {
  assetAmountInWei, getAssetInfo, getAssetInfoByAddress,
} from '@defisaver/tokens';
import { exchangeAssets } from 'constants/assets';
import RecipeAction from '../RecipeAction';
import WithdrawIcon from '../recipeIcons/Withdraw.svg';
import {
  Amount, Asset, Deadline, Slippage, Source, Address,
} from '../../components/Recipes/RecipeCreator/inputTypes';
import { getLPLiquidityValueEstimate } from '../../services/uniswapServices';
import { assetAmountInWeiIgnorePointer } from '../../services/recipeCreator/recipeActionUtils';
import { withdrawLPAfterValues } from '../../actions/lpActions/lpActions';
import { normalizeFunc, setSlippagePercent } from '../../services/exchangeServiceCommon';

const _exchangeAssets = [...exchangeAssets()];
_exchangeAssets[0] = getAssetInfo('WETH');

export default class UniswapV2WithdrawAction extends RecipeAction {
  static prettyName = 'Withdraw from Uniswap V2';

  static protocol = 'uniswap';

  static protocolPrettyName = 'Uniswap';

  static description = 'Withdraws assets from the selected Uniswap v2 liquidity pool.';

  constructor(firstAsset = 'WETH', secondAsset = 'DAI', liquidity = '', source = 'wallet', to = 'wallet', slippage = '2', deadline = '20') {
    super();
    this.inputs = [
      new Asset('1st token', firstAsset, _exchangeAssets),
      new Asset('2nd token', secondAsset, _exchangeAssets),
      new Amount('Amount', liquidity),
      new Source('From', source),
      new Source('To', to, true),
      new Slippage('Slippage tolerance', slippage),
      new Deadline('Deadline', deadline),
    ];
    this.output = new Amount('output', 0);
    this.error = '';
  }

  static getIcon() {
    return WithdrawIcon;
  }

  setError(e) {
    this.error = e;
  }

  getError() {
    return this.error;
  }

  getExpires() {
    if (this.updatedAt) {
      return this.updatedAt + (2 * 60 * 1000);
    }
    return Infinity;
  }

  async getAfterValues(_balances = {}, returnValues = [], actions = [], _positions = {}, getState) {
    const positions = { ..._positions };
    const { proxyAddress } = getState().maker;
    const { account } = getState().general;
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const { balances, returnValue, cached } = await withdrawLPAfterValues(
      {
        liquidity: args[2],
        _firstAsset: this.inputs[0].asset,
        _secondAsset: this.inputs[1].asset,
        source: args[3],
        to: args[4],
      },
      { proxyAddress, account },
      _balances);
    if (!cached) this.updatedAt = Date.now();
    this.output.value = returnValue;
    return { returnValue: this.output, balances, positions };
  }

  async toDfsAction(getState) {
    const {
      general: { account },
      maker: { proxyAddress },
      recipeCreator: { positions, returnValues, actions },
    } = getState();
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    // LP tokens have 18 decimals like DAI
    const [firstAmount, secondAmount, lpAddress] = await getLPLiquidityValueEstimate(this.inputs[0].asset, this.inputs[1].asset, args[2]);
    const liquidity = assetAmountInWeiIgnorePointer(args[2], 'DAI');
    const from = this.inputs[3].value === 'wallet' ? account : proxyAddress;
    const minFirstAmountWei = assetAmountInWei(setSlippagePercent(normalizeFunc(args[5]), firstAmount), this.inputs[0].asset);
    const minSecondAmountWei = assetAmountInWei(setSlippagePercent(normalizeFunc(args[5]), secondAmount), this.inputs[1].asset);
    const to = this.inputs[4].value === 'wallet' ? account : proxyAddress;
    return new dfs.actions.uniswap.UniswapWithdrawAction(
      args[0],
      args[1],
      liquidity,
      to,
      from,
      minFirstAmountWei,
      minSecondAmountWei,
      Date.now() + (1000 * 60 * args[6]),
    );
  }

  _getPrettyName(actionCalls, actions) {
    const args = this.mapReturnValuesToArgs(actionCalls.map(a => a.returnValue), actions);
    return `Withdraw from ${getAssetInfoByAddress(args[0]).symbol}-${getAssetInfoByAddress(args[1]).symbol} pool`;
  }
}
