import Dec from 'decimal.js';
import PropTypes from 'prop-types';
import React from 'react';
import { formatNumber } from '../../../services/utils';
import Arrow from '../../Decorative/Arrow';
import withErrorFallback from '../../ErrorFallback/ErrorFallback';

const TxTokenFields = ({
  firstToken, secondToken, firstAmount, secondAmount,
}) => {
  const FirstIcon = firstToken ? firstToken.icon : '';
  const SecondIcon = secondToken ? secondToken.icon : '';
  return (
    <div className="action-info-wrapper">
      { firstToken && firstAmount && (
        <div className={`token-section first-token ${secondToken ? 'has-second-token' : ''}`}>
          <div className="amount">{formatNumber(firstAmount, Dec(firstAmount).gt(0) ? 2 : 5, !Dec(firstAmount).gt(0))}</div>
          <div className="Flex FlexStart">
            <div className="token-icon"><FirstIcon width={20} height={20} /></div>
            <div className="token-name">{firstToken.symbol}</div>
          </div>
        </div>
      )}
      { secondToken && secondAmount && (
        <div className="arrow-wrapper">
          <Arrow to="right" size={15} color="#939DA7" />
        </div>
      )}
      { secondToken && secondAmount && (
        <div className="token-section has-second-token">
          <div className="amount">{formatNumber(secondAmount, Dec(secondAmount).gt(0) ? 2 : 5, !Dec(secondAmount).gt(0))}</div>
          <div className="Flex FlexStart">
            <div className="token-icon"><SecondIcon width={20} height={20} /></div>
            <div className="token-name">{secondToken.symbol}</div>
          </div>
        </div>
      )}
    </div>
  );
};

TxTokenFields.propTypes = {
  firstToken: PropTypes.object.isRequired,
  secondToken: PropTypes.object,
  firstAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  secondAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default withErrorFallback(TxTokenFields);
