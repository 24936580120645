import t from 'translate';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import capitalize from 'lodash/capitalize';

import TooltipWrapper from '../TooltipWrapper/TooltipWrapper';
import InfoIcon from '../Decorative/InfoIcon';

import './AdditionalInfo.scss';
import { getFeePercentage } from '../../services/feeUtils';

const formatFlSource = (flashloanSource) => {
  if (flashloanSource === 'dydx') return 'dYdX';
  if (flashloanSource === 'aaveV3') return 'Aave V3';
  return capitalize(flashloanSource);
};

const AdditionalInfo = ({
  isSameAsset, exchangeSource, flashloanSource, flashloanFee, hasTitle, showServiceFee, serviceFee, asset1, asset2,
}) => {
  const network = useSelector(({ general }) => general.network);
  return (
    <div className="additional-info">
      {hasTitle && <p className="additional-info__title">{t('common.additional_info')}: </p>}
      <ul className="additional-info__list">
        {!isSameAsset && exchangeSource && (
          <li className="additional-info__list__item">
            <TooltipWrapper title={t('misc.exchange_option_info')}><InfoIcon /></TooltipWrapper>Swapping
            with: {exchangeSource}
          </li>
        )}

        {showServiceFee && (
          <li className="additional-info__list__item">
            <TooltipWrapper title={t('misc.dfs_service_fee_info')}><InfoIcon /></TooltipWrapper>Service
            fee: {serviceFee || getFeePercentage(asset1, asset2, network)}%
          </li>
        )}
        {flashloanSource && flashloanSource !== 'none' && (
          <li className="additional-info__list__item">
            <TooltipWrapper title={t('misc.fl_protocol_info')}><InfoIcon /></TooltipWrapper>Flash loan
            via: {formatFlSource(flashloanSource)}{flashloanFee ? ` (${flashloanFee}% fee)` : ''}
          </li>
        )}
      </ul>
    </div>
  );
};

AdditionalInfo.defaultProps = {
  exchangeSource: '',
  flashloanSource: '',
  flashloanFee: '',
  showServiceFee: true,
  isSameAsset: false,
  hasTitle: true,
  asset1: '',
  asset2: '',
};

AdditionalInfo.propTypes = {
  showServiceFee: PropTypes.bool,
  exchangeSource: PropTypes.string,
  flashloanSource: PropTypes.string,
  isSameAsset: PropTypes.bool,
  hasTitle: PropTypes.bool,
  flashloanFee: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  serviceFee: PropTypes.string,
  asset1: PropTypes.string,
  asset2: PropTypes.string,
};

export default AdditionalInfo;
