import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import t from 'translate';
import {
  change, formValueSelector, reduxForm,
} from 'redux-form';
import Dec from 'decimal.js';
import { ACCOUNT_TYPES } from '../../../../constants/general';
import {
  stakeLQTYAction, unstakeLQTYAction, stakeLUSDAction, unstakeLUSDAction, getStakedBalancesAndRewardsAction,
  withdrawETHGainToTroveAction, claimLQTYRewardsAction, claimSPRewardsAction,
} from '../../../../actions/liquityActions/liquityStakingActions';
import { openLoginModal } from '../../../../actions/modalActions';
import { isWalletTypeProxy } from '../../../../services/utils';

import ActionItem from '../../../ActionItem/ActionItem';
import ModalHeader from '../../ModalHeader';
import ModalBody from '../../ModalBody';
import ErrorBox from '../../../Decorative/ErrorBox/ErrorBox';
import Loader from '../../../Loader/Loader';
import DataItem from '../../../DataItem/DataItem';
import WarningBox from '../../../Decorative/WarningBox/WarningBox';

import './LiquityStakingModal.scss';

const LiquityStakingModal = (props) => {
  const {
    trove, LQTY, LUSD, dispatch, formValues, stakedLQTYBalance, stakedLUSDBalance, accountType, openLoginModal,
    stakingLQTY, stakingLQTYErr, unstakingLQTY, unstakingLQTYErr,
    stakingLUSD, stakingLUSDErr, unstakingLUSD, unstakingLUSDErr,
    stakeLQTYAction, unstakeLQTYAction, stakeLUSDAction, unstakeLUSDAction, closeModal,
    getStakedBalancesAndRewardsAction, gettingBalancesAndRewards, gettingBalancesAndRewardsErr,
    withdrawingETHGainToTrove, withdrawingETHGainToTroveErr, withdrawETHGainToTroveAction, isProxy,
    rewardETH, rewardLUSD, stabilityRewardETH, stabilityRewardLQTY, totalLUSDDeposited, totalLQTYStaked, proxyAddress,
    claimLQTYRewardsAction, claimSPRewardsAction,
  } = props;
  const { stakeAmount, unstakeAmount } = formValues;
  const LQTY_STAKE_OPTIONS = [
    {
      asset: 'LQTY',
      stake: stakeLQTYAction,
      unstake: unstakeLQTYAction,
      staking: stakingLQTY,
      stakingErr: stakingLQTYErr,
      unstaking: unstakingLQTY,
      unstakingErr: unstakingLQTYErr,
      balance: LQTY.balance,
      gettingBalance: LQTY.gettingBalance,
      gettingStaked: false,
      staked: stakedLQTYBalance,
      totalStaked: totalLQTYStaked,
      claim: claimLQTYRewardsAction,
      rewards: [
        { amount: rewardETH, asset: 'ETH' },
        { amount: rewardLUSD, asset: 'LUSD' },
      ],
    },
    {
      asset: 'LUSD',
      stake: stakeLUSDAction,
      unstake: unstakeLUSDAction,
      staking: stakingLUSD,
      stakingErr: stakingLUSDErr,
      unstaking: unstakingLUSD,
      unstakingErr: unstakingLUSDErr,
      balance: LUSD.balance,
      gettingBalance: LUSD.gettingBalance,
      gettingStaked: false,
      staked: stakedLUSDBalance,
      totalStaked: totalLUSDDeposited,
      claim: claimSPRewardsAction,
      rewards: [
        { amount: stabilityRewardETH, asset: 'ETH' },
        { amount: stabilityRewardLQTY, asset: 'LQTY' },
      ],
    },
  ];
  const [stakeOption, setStakeOption] = useState(LQTY_STAKE_OPTIONS[0]);

  useEffect(() => { getStakedBalancesAndRewardsAction(); }, []);

  useEffect(() => {
    setStakeOption((prev) => LQTY_STAKE_OPTIONS[LQTY_STAKE_OPTIONS.findIndex((i) => i.asset === prev.asset)]);
  }, [props]);

  console.log(stakeOption);

  useEffect(() => {
    dispatch(change('liquityStakingForm', 'stakeAmount', ''));
    dispatch(change('liquityStakingForm', 'unstakeAmount', ''));
  }, [stakeOption.asset]);

  const canStake = new Dec(stakeOption.balance).gt(0) && new Dec(stakeAmount || 0).gt(0);
  const canUnstake = new Dec(stakeOption.staked).gt(0) && new Dec(unstakeAmount || 0).gt(0);
  return (
    <div className="action-modal-wrapper lqty-stake-modal-wrapper">
      <ModalHeader closeModal={closeModal} />

      <ModalBody>
        <div className="new-modal-top-wrapper">
          <h1>{t('common.stake')}</h1>
        </div>

        <div className="new-modal-content-wrapper">

          {gettingBalancesAndRewards && (<Loader />)}
          {!gettingBalancesAndRewards && (
            <div className="container">
              <div className="Switch">
                {LQTY_STAKE_OPTIONS.map((option) => (
                  <div
                    key={option.asset}
                    className={stakeOption.asset === option.asset ? 'active' : ''}
                    onClick={() => setStakeOption(option)}
                  >
                    {option.asset}
                  </div>
                ))}
              </div>
              <p className="info">
                {stakeOption.asset === 'LQTY' && t('liquity.stake_lqty_desc')}
                {stakeOption.asset === 'LUSD' && t('liquity.stake_lusd_desc')}
              </p>
              <div className="gains">
                {stakeOption.rewards.map((reward) => new Dec(reward.amount).gt(0) && (
                  <DataItem
                    key={reward.amount}
                    label={`Earned ${reward.asset} balance`}
                    symbolAfter={false}
                    loading={gettingBalancesAndRewards}
                    value={reward.amount}
                    type="small"
                  />
                ))}
                {new Dec(stakeOption.staked).gt(0) && (
                  <>
                    <DataItem
                      label={`Staked ${stakeOption.asset} balance`}
                      symbolAfter={false}
                      loading={gettingBalancesAndRewards}
                      value={stakeOption.staked}
                      type="small"
                    />
                    <DataItem
                      label="Pool share"
                      symbol="%"
                      loading={gettingBalancesAndRewards}
                      value={new Dec(stakeOption.staked).div(stakeOption.totalStaked).times(100).toString()}
                      type="small"
                    />
                  </>
                )}
                {trove && trove.troveStatus === 'active' && stakeOption.asset === 'LUSD' && new Dec(stabilityRewardETH).gt(0) && (
                  <>
                    <button
                      type="button"
                      className="button"
                      onClick={() => (accountType !== ACCOUNT_TYPES.viewOnly ?
                        withdrawETHGainToTroveAction(stabilityRewardETH, closeModal) :
                        openLoginModal()
                      )}
                    >
                      {withdrawingETHGainToTrove ? `${t('common.claiming')} to Trove` : `${t('common.claim')} to Trove`}
                    </button>
                    {withdrawingETHGainToTroveErr && <ErrorBox marginTop>{withdrawingETHGainToTroveErr}</ErrorBox>}
                  </>
                )}
                {(new Dec(stakeOption.rewards[0].amount).gt(0) || new Dec(stakeOption.rewards[1].amount).gt(0)) && (
                  <button
                    type="button"
                    className="button"
                    onClick={() => (accountType !== ACCOUNT_TYPES.viewOnly ?
                      stakeOption.claim(closeModal) :
                      openLoginModal()
                    )}
                  >
                    {stakeOption.unstaking ? t('common.claiming') : t('common.claim')}
                  </button>
                )}
              </div>
              {isProxy && !proxyAddress && stakeOption.asset === 'LUSD' && (
                <WarningBox marginBottom>Depositing LUSD to Stability Pool will require creating Smart Wallet</WarningBox>
              )}
              {isProxy && !proxyAddress && stakeOption.asset === 'LQTY' && (
                <WarningBox marginBottom>Staking LQTY will require creating Smart Wallet</WarningBox>
              )}
              <form
                className="lqty-stake-form form-wrapper"
                noValidate
              >
                <ActionItem
                  type="lqty-stake-form__item"
                  disabled={!canStake || stakeOption.staking}
                  actionExecuting={stakeOption.staking}
                  setValToMax={() => {
                    dispatch(change('liquityStakingForm', 'stakeAmount', stakeOption.balance));
                  }}
                  selectValueOnChange={(val) => {
                    dispatch(change('liquityStakingForm', 'stakeAmount', val));
                  }}
                  maxVal={stakeOption.balance}
                  gettingMaxVal={stakeOption.gettingStaked}
                  toExecuteLabel={t('common.stake')}
                  executingLabel={t('common.staking')}
                  name="stakeAmount"
                  id={`stake-${stakeOption.asset}-input`}
                  symbol={stakeOption.asset}
                  errorText={!canStake ? t('common.insufficient_balance') : ''}
                  executeAction={() => { stakeOption.stake(stakeAmount, closeModal); }}
                />

                <ActionItem
                  type="lqty-stake-form__item"
                  disabled={!canUnstake || stakeOption.unstaking}
                  actionExecuting={stakeOption.unstaking}
                  setValToMax={() => {
                    dispatch(change('liquityStakingForm', 'unstakeAmount', stakeOption.staked));
                  }}
                  selectValueOnChange={(val) => {
                    dispatch(change('liquityStakingForm', 'unstakeAmount', val));
                  }}
                  maxVal={stakeOption.staked}
                  gettingMaxVal={stakeOption.gettingBalance}
                  toExecuteLabel={t('common.unstake')}
                  executingLabel={t('common.unstaking')}
                  name="unstakeAmount"
                  id={`unstake-${stakeOption.asset}-input`}
                  symbol={stakeOption.asset}
                  errorText={!canUnstake ? t('common.insufficient_balance') : ''}
                  executeAction={() => { stakeOption.unstake(unstakeAmount, closeModal); }}
                />
              </form>
            </div>
          )}

          {stakeOption.stakingErr && <ErrorBox marginTop>{stakeOption.stakingErr}</ErrorBox>}
          {stakeOption.unstakingErr && <ErrorBox marginTop>{stakeOption.unstakingErr}</ErrorBox>}
          {gettingBalancesAndRewardsErr && <ErrorBox marginTop>{gettingBalancesAndRewardsErr}</ErrorBox>}
        </div>
      </ModalBody>
    </div>
  );
};

const LiquityStakingForm = reduxForm({
  form: 'liquityStakingForm',
})(LiquityStakingModal);

LiquityStakingModal.propTypes = {
  trove: PropTypes.object.isRequired,
  accountType: PropTypes.string.isRequired,
  LQTY: PropTypes.object.isRequired,
  LUSD: PropTypes.object.isRequired,
  stakingLQTY: PropTypes.bool.isRequired,
  stakingLQTYErr: PropTypes.string.isRequired,
  unstakingLQTY: PropTypes.bool.isRequired,
  unstakingLQTYErr: PropTypes.string.isRequired,
  stakingLUSD: PropTypes.bool.isRequired,
  stakingLUSDErr: PropTypes.string.isRequired,
  unstakingLUSD: PropTypes.bool.isRequired,
  unstakingLUSDErr: PropTypes.string.isRequired,
  stakeLQTYAction: PropTypes.func.isRequired,
  unstakeLQTYAction: PropTypes.func.isRequired,
  stakeLUSDAction: PropTypes.func.isRequired,
  unstakeLUSDAction: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired,
  stakedLQTYBalance: PropTypes.string.isRequired,
  stakedLUSDBalance: PropTypes.string.isRequired,
  gettingBalancesAndRewards: PropTypes.bool.isRequired,
  gettingBalancesAndRewardsErr: PropTypes.string.isRequired,
  getStakedBalancesAndRewardsAction: PropTypes.func.isRequired,
  withdrawingETHGainToTrove: PropTypes.bool.isRequired,
  withdrawingETHGainToTroveErr: PropTypes.string.isRequired,
  withdrawETHGainToTroveAction: PropTypes.func.isRequired,
  rewardETH: PropTypes.string.isRequired,
  rewardLUSD: PropTypes.string.isRequired,
  stabilityRewardETH: PropTypes.string.isRequired,
  stabilityRewardLQTY: PropTypes.string.isRequired,
  openLoginModal: PropTypes.func.isRequired,
  totalLUSDDeposited: PropTypes.string.isRequired,
  totalLQTYStaked: PropTypes.string.isRequired,
  proxyAddress: PropTypes.string.isRequired,
  isProxy: PropTypes.bool.isRequired,
  claimLQTYRewardsAction: PropTypes.func.isRequired,
  claimSPRewardsAction: PropTypes.func.isRequired,
};

const selector = formValueSelector('liquityStakingForm');

const mapStateToProps = state => ({
  initialValues: {
    stakeAmount: '',
    unstakeAmount: '',
  },
  formValues: {
    stakeAmount: selector(state, 'stakeAmount'),
    unstakeAmount: selector(state, 'unstakeAmount'),
  },

  isProxy: isWalletTypeProxy(state.general.walletType),

  proxyAddress: state.maker.proxyAddress,

  trove: state.liquity[state.general.walletType.value],

  accountType: state.general.accountType,

  LQTY: state.assets.LQTY,
  LUSD: state.assets.LUSD,

  totalLUSDDeposited: state.liquityStaking.totalLUSDDeposited,
  totalLQTYStaked: state.liquityStaking.totalLQTYStaked,

  stakingLQTY: state.liquityStaking.stakingLQTY,
  stakingLQTYErr: state.liquityStaking.stakingLQTYErr,

  unstakingLQTY: state.liquityStaking.unstakingLQTY,
  unstakingLQTYErr: state.liquityStaking.unstakingLQTYErr,

  stakingLUSD: state.liquityStaking.stakingLUSD,
  stakingLUSDErr: state.liquityStaking.stakingLUSDErr,

  unstakingLUSD: state.liquityStaking.unstakingLUSD,
  unstakingLUSDErr: state.liquityStaking.unstakingLUSDErr,

  stakedLQTYBalance: state.liquityStaking[state.general.walletType.value].stakedLQTYBalance,
  stakedLUSDBalance: state.liquityStaking[state.general.walletType.value].stakedLUSDBalance,

  rewardETH: state.liquityStaking[state.general.walletType.value].rewardETH,
  rewardLUSD: state.liquityStaking[state.general.walletType.value].rewardLUSD,

  stabilityRewardETH: state.liquityStaking[state.general.walletType.value].stabilityRewardETH,
  stabilityRewardLQTY: state.liquityStaking[state.general.walletType.value].stabilityRewardLQTY,

  gettingBalancesAndRewards: state.liquityStaking.gettingBalancesAndRewards,
  gettingBalancesAndRewardsErr: state.liquityStaking.gettingBalancesAndRewardsErr,

  withdrawingETHGainToTrove: state.liquityStaking.withdrawingETHGainToTrove,
  withdrawingETHGainToTroveErr: state.liquityStaking.withdrawingETHGainToTroveErr,
});

const mapDispatchToProps = {
  stakeLQTYAction, unstakeLQTYAction, stakeLUSDAction, unstakeLUSDAction, getStakedBalancesAndRewardsAction, withdrawETHGainToTroveAction, openLoginModal, claimLQTYRewardsAction, claimSPRewardsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(LiquityStakingForm);
