import React, { Component } from 'react';
import PropTypes from 'prop-types';
import t from 'translate';
import ModalBody from '../ModalBody';

import './ConfirmationModal.scss';

class ConfirmationModal extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { content, resolve, closeModal } = this.props;

    return (
      <div className="confirmation-modal">
        <ModalBody>
          <div className="new-modal-top-wrapper warning">
            <h1>{t('common.warning')}</h1>
          </div>
          <div className="new-modal-content-wrapper">
            {typeof content === 'string' && (<p>{ content }</p>)}
            {typeof content === 'function' && content()}
          </div>
        </ModalBody>

        <div className="modal-controls">
          <button type="button" className="button gray" onClick={() => resolve(false)}>{t('common.cancel')}</button>
          <button type="button" className="button green" onClick={() => resolve(true)}>{t('common.confirm')}</button>
        </div>
      </div>
    );
  }
}


ConfirmationModal.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  resolve: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

ConfirmationModal.defaultProps = {};

export default ConfirmationModal;
