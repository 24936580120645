import React from 'react';
import tokenIcons from '@defisaver/token-icons';

/**
 * Used to connect @defisaver/token-icons and @defisaver/tokens
 *
 * @param symbol
 * @returns {function()}
 * @constructor
 */
export default ({ symbol }) => (props) => {
  if (!tokenIcons.icons[symbol]) {
    return (
      <svg width="30" height="30" viewBox="0 0 193 193" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        {symbol && (
          <style>
            {`
            .blank-token__symbol {
              font-size: 64px;
              fill: white;
              font-family: 'Montserrat', sans-serif;
            }
          `}
          </style>
        )}
        <g className="blank-token">
          <circle className="blank-token__icon" cx="96.5" cy="96.5" r="96.5" transform="rotate(-180 96.5 96.5)" fill="url(#token_icon_blank)" fillOpacity="0.9" />
          {symbol && (
            <text className="blank-token__symbol" x="50%" y="60%" textAnchor="middle">
              {symbol.length > 4
                ? `${symbol.substr?.(0, 3)}…`
                : symbol}
            </text>
          )}
        </g>
        <defs>
          <linearGradient id="token_icon_blank" x1="193" y1="2.46321" x2="193" y2="193" gradientUnits="userSpaceOnUse">
            <stop stopColor="#182025" />
            <stop offset="1" stopColor="#2A5648" />
          </linearGradient>
        </defs>
      </svg>
    );
  }
  return (
    <svg height={30} width={30} viewBox="0 0 30 30" {...props}>
      <image width="30" height="30" xlinkHref={tokenIcons.icons[symbol]} />
    </svg>
  );
};
