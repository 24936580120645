import Dec from 'decimal.js';
import {
  McdAutomationSubscriptionsContract, // legacy
  SubscriptionsV2Contract,
  AutomaticLoggerContract,
  SubscriptionsV2Address,
  SubscriptionsProxyV2Address,
  MCDMonitorProxyV2Contract,
} from '../contractRegistryService';
import { isEmptyBytes, parseTimestamp, requireAddress } from '../utils';
import callTx from '../txService';
import config from '../../config/config.json';
import { ethToWei, weiToEth } from '../ethService';
import { ALLOWED_PERCENT_OVER_LIQ_RATIO_MAKER } from '../../constants/general';

export const isMonitorAuthorized = async (proxyAddress) => {
  const proxy = new window._web3.eth.Contract(config.DSProxy.abi, proxyAddress);
  const authorityAddr = await proxy.methods.authority().call();
  if (!isEmptyBytes(authorityAddr)) {
    const authority = new window._web3.eth.Contract(config.DSGuard.abi, authorityAddr);
    return authority.methods.canCall('0x1816A86C4DA59395522a42b871bf11A4E96A1C7a', proxyAddress, '0x1cff79cd').call();
  }
  return false;
};

/**
 * @param cdpId
 * @param cdpOwner {string} cdp owner (dsproxy)
 * @param type
 * @returns {Promise<{isSubscribedToAutomation: (boolean), automationResubscribeRequired: boolean}>}
 */
export const isSubscribedToMonitor = async (cdpId, cdpOwner = '', type) => {
  try {
    if (type === 'b') throw new Error('B.Protocol cdp not compatible for automation');

    const contract = await SubscriptionsV2Contract();
    const data = await contract.methods.getSubscribedInfo(cdpId).call();
    const authorized = await isMonitorAuthorized(cdpOwner);
    const isSubscribedToAutomation = data && data[0] && (data[5] === cdpOwner) && authorized;
    return {
      isSubscribedToAutomation,
      automationResubscribeRequired: data && data[0] && (data[5] !== cdpOwner || !authorized),
    };
  } catch (err) {
    console.error(err);
    return {
      isSubscribedToAutomation: false,
      automationResubscribeRequired: false,
    };
  }
};

/**
 *
 * @param accountType
 * @param path
 * @param account
 * @param sendTxFunc
 * @param proxyAddress
 * @param cdpId {Number}
 * @param minRatio {Number}
 * @param maxRatio {Number}
 * @param repayTo {Number}
 * @param boostTo {Number}
 * @param boostEnabled {Boolean}
 * @param nextPriceEnabled {Boolean}
 * @param updating {Boolean}
 * @returns {Promise<*>}
 */
export const subscribeToMonitor = async (
  accountType,
  path,
  account,
  sendTxFunc,
  proxyAddress,
  cdpId,
  minRatio,
  maxRatio,
  repayTo,
  boostTo,
  boostEnabled,
  nextPriceEnabled,
  updating,
) => {
  const contract = config.SubscriptionsProxyV2;

  const txParams = { from: account };

  const params = [
    cdpId,
    ethToWei(minRatio / 100),
    ethToWei(maxRatio / 100),
    ethToWei(boostTo / 100),
    ethToWei(repayTo / 100),
    boostEnabled,
    nextPriceEnabled,
    SubscriptionsV2Address,
  ];

  console.log(params);
  requireAddress(proxyAddress);
  const proxyContract = new window._web3.eth.Contract(config.DSProxy.abi, proxyAddress);
  const functionName = updating ? 'update' : 'subscribe';
  const contractFunction = contract.abi.find(abi => abi.name === functionName);
  const data = window._web3.eth.abi.encodeFunctionCall(contractFunction, params);
  const funcParams = [SubscriptionsProxyV2Address, data];

  return callTx(accountType, path, sendTxFunc, proxyContract, 'execute(address,bytes)', funcParams, txParams);
};

export const unsubscribeFromMonitor = async (
  accountType,
  path,
  account,
  sendTxFunc,
  proxyAddress,
  cdpId,
) => {
  const contract = config.SubscriptionsProxyV2;

  const txParams = { from: account };
  const params = [cdpId, SubscriptionsV2Address];

  requireAddress(proxyAddress);
  const proxyContract = new window._web3.eth.Contract(config.DSProxy.abi, proxyAddress);

  const contractFunction = contract.abi.find(abi => abi.name === 'unsubscribe');
  const data = window._web3.eth.abi.encodeFunctionCall(contractFunction, params);
  const funcParams = [SubscriptionsProxyV2Address, data];

  await callTx(accountType, path, sendTxFunc, proxyContract, 'execute(address,bytes)', funcParams, txParams);
};

export const calculateLimits = (optimalRate, liqRatio) => {
  const minAvailableRatio = liqRatio + ALLOWED_PERCENT_OVER_LIQ_RATIO_MAKER;
  let minRatio = optimalRate - 20;
  const maxRatio = optimalRate + 20;
  if (minRatio < minAvailableRatio) minRatio = minAvailableRatio;
  return { minRatio, maxRatio };
};

/*
Fallback
export const getSaverHistory2 = async (cdp) => {
  const res = await fetch(`https://defiexplore.com/api/automation_history/mcd/${cdp.id}`);
  const events = await res.json();
  return events
    .map((event, i) => ({
      type: event.event === 'CdpRepay' ? 'repay' : 'boost',
      boughtEth: event.event === 'CdpRepay' ? assetAmountInEth(event.amount, cdp.type) : '0',
      boughtDai: event.event === 'CdpRepay' ? '0' : assetAmountInEth(event.amount, 'DAI'),
      ratioFrom: weiToEth(event.ratioBefore) * 100,
      ratioTo: weiToEth(event.ratioAfter) * 100,
      date: parseTimestamp(event.timestamp),
      hash: event.txHash,
    })).reverse();
};
*/

export const getSaverHistory = async (cdp) => {
  const res = await fetch(`https://defiexplore.com/api/automation_history/mcd/${cdp.id}`);
  const events = await res.json();
  return events
    .map((event, i) => ({
      actionType: event.action,
      collAsset: cdp.asset,
      collAmount: event.collAmount,
      borrowAsset: 'DAI',
      borrowAmount: event.debtAmount,
      ratioFrom: event.ratioBefore,
      ratioTo: event.ratioAfter,
      timestamp: new Date(event.timestamp).getTime(),
      txHash: event.txHash,
      liqRatio: cdp.liqRatio,
    })).reverse();
};

/**
 * Fetches ratio data for saver subscribed cdp-s
 *
 * @param cdpId {Number}
 * @return {Promise<{maxRatio: *, minRatio: *, minOptimalRatio: *, maxOptimalRatio: *}>}
 */
export const getSaverSubscriptionInfo = async (cdpId) => {
  const contract = await SubscriptionsV2Contract();
  const data = await contract.methods.getCdpHolder(cdpId).call();
  return {
    minRatio: Dec(weiToEth(data[1].minRatio)).mul(100).toNumber(),
    maxRatio: data[1].boostEnabled ? Dec(weiToEth(data[1].maxRatio)).mul(100).toNumber() : Infinity,
    minOptimalRatio: Dec(weiToEth(data[1].optimalRatioRepay)).mul(100).toNumber(),
    maxOptimalRatio: data[1].boostEnabled ? Dec(weiToEth(data[1].optimalRatioBoost)).mul(100).toNumber() : Infinity,
    boostEnabled: data[1].boostEnabled,
    nextPriceEnabled: data[1].nextPriceEnabled,
    version: 2,
  };
};

export const getLegacySaverSubscriptionInfo = async (cdpId) => {
  const contract = await McdAutomationSubscriptionsContract();
  const data = await contract.methods.getSubscribedInfo(cdpId).call();
  return {
    minRatio: Dec(weiToEth(data[1])).mul(100).toNumber(),
    maxRatio: Dec(weiToEth(data[2])).mul(100).toNumber(),
    minOptimalRatio: Dec(weiToEth(data[3])).mul(100).toNumber(),
    maxOptimalRatio: Dec(weiToEth(data[4])).mul(100).toNumber(),
    boostEnabled: true,
    version: 1,
  };
};

export const migrateToV2 = async (
  accountType,
  path,
  account,
  sendTxFunc,
  proxyAddress,
  cdpId,
) => {
  const contract = config.SubscriptionsProxyV2;

  const txParams = { from: account };

  const v1Contract = await McdAutomationSubscriptionsContract();
  const v1Data = await v1Contract.methods.getSubscribedInfo(cdpId).call();

  const params = [
    cdpId,
    v1Data[1],
    v1Data[2],
    v1Data[4],
    v1Data[3],
    true,
    true,
    SubscriptionsV2Address,
  ];

  requireAddress(proxyAddress);
  const proxyContract = new window._web3.eth.Contract(config.DSProxy.abi, proxyAddress);

  const contractFunction = contract.abi.find(abi => abi.name === 'migrate');
  const data = window._web3.eth.abi.encodeFunctionCall(contractFunction, params);
  const funcParams = [SubscriptionsProxyV2Address, data];

  return callTx(accountType, path, sendTxFunc, proxyContract, 'execute(address,bytes)', funcParams, txParams);
};

export const getMonitorUpgradeEvents = async () => {
  const contract = MCDMonitorProxyV2Contract();
  const events = await contract.getPastEvents('allEvents', { fromBlock: 9837081, toBlock: 'latest' });
  return Promise.all(events.reverse().map(async event => ({
    ...event,
    timestamp: (await window._web3.eth.getBlock(event.blockNumber)).timestamp * 1000,
  })));
};
