import t from 'translate';
import React, { useEffect, useMemo, useState } from 'react';
import Dec from 'decimal.js';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { aaveRepayActionV2, getRepayModalData } from '../../../../actions/aaveActions/aaveManageActionsV2';
import { resetRepayModal } from '../../../../actions/makerActions/makerManageActions/makerManageActions';
import { getDashboardInputs } from '../../../../actions/dashboardActions';

import { AaveRepaySlippageLimitFormV2 as SlippageLimitForm } from '../../BoostRepayCommon/SlippageLimitForm/SlippageLimitForm';
import ModalHeader from '../../ModalHeader';
import ModalBody from '../../ModalBody';
import Loader from '../../../Loader/Loader';
import ErrorBox from '../../../Decorative/ErrorBox/ErrorBox';
import TooltipWrapper from '../../../TooltipWrapper/TooltipWrapper';
import TradeSizeImpactWarning from '../../../TradeSizeImpactWarning/TradeSizeImpactWarning';
import InfoIcon from '../../../Decorative/InfoIcon';
import ActionInfo from '../../BoostRepayCommon/ActionInfo';
import AdvancedOptions from '../../BoostRepayCommon/AdvancedOptions/AdvancedOptions';
import AdditionalInfo from '../../../AdditionalInfo/AdditionalInfo';

const AaveRepayModalV2 = ({
  closeModal, repayAmount, gettingRepayModalData, tradeSizeImpact, gettingRepayModalDataError, repaying,
  repayExchangeRate, repayingError, flProtocol, useFl, exchangeSource, flFee,
  contextAction, additionalAction,
}) => {
  const dispatch = useDispatch();
  const {
    firstInput: inputAmount, secondAction, secondInput, firstInputSelect, firstInputSecondSelect, secondInputSelect,
  } = useMemo(() => dispatch(getDashboardInputs(contextAction, additionalAction)), []);

  const withdrawAsset = firstInputSelect?.value;
  const paybackAsset = firstInputSecondSelect?.value;

  const [advanced, setAdvanced] = useState(false);
  useEffect(() => {
    dispatch(getRepayModalData(inputAmount, withdrawAsset, paybackAsset));
    return () => dispatch(resetRepayModal());
  }, []);

  const shouldFlip = new Dec(repayExchangeRate).lt(new Dec(1).div(repayExchangeRate).toString());
  const exchangeLabel = shouldFlip
    ? `${withdrawAsset}/${paybackAsset}`
    : `${paybackAsset}/${withdrawAsset}`;
  const correctedRepayExchangeRate = shouldFlip
    ? new Dec(1).div(repayExchangeRate).toString()
    : repayExchangeRate;

  const isSameAsset = withdrawAsset === paybackAsset;

  return (
    <div className="boost-repay-modal">
      <ModalHeader closeModal={closeModal} />

      <ModalBody>
        <div className="new-modal-top-wrapper">
          <h1>
            <TooltipWrapper title={t('compound.repay_modal_description', { '%asset1': paybackAsset, '%asset2': withdrawAsset })}>
              <InfoIcon /> {t('common.repay')} {contextAction?.value && additionalAction?.value && `+ ${contextAction.value === 'repay' ? additionalAction.label : contextAction.label}`}
            </TooltipWrapper>
          </h1>
        </div>

        <div className="new-modal-content-wrapper">

          {gettingRepayModalData && (<div className="container"><div className="loading-wrapper"><Loader /></div></div>)}

          {!gettingRepayModalData && gettingRepayModalDataError && (<ErrorBox>{gettingRepayModalDataError}</ErrorBox>)}

          {!gettingRepayModalData && !gettingRepayModalDataError && (
            <>
              <ActionInfo
                isRepay
                firstAsset={withdrawAsset}
                firstAmount={inputAmount}
                secondAsset={paybackAsset}
                secondAmount={repayAmount}
                isSameAsset={isSameAsset}
                exchangeRate={correctedRepayExchangeRate}
                exchangeLabel={exchangeLabel}
                additionalAction={secondAction}
                additionalInput={secondInput}
                additionalAsset={secondInputSelect?.value}
              />

              <div className="advanced__wrapper container">
                {!isSameAsset && (
                  <>
                    <TradeSizeImpactWarning tradeSizeImpact={tradeSizeImpact} />
                    <AdvancedOptions
                      isRepay
                      borrowAsset={withdrawAsset}
                      supplyAsset={paybackAsset}
                      advanced={advanced}
                      setAdvanced={setAdvanced}
                      exchangeRate={repayExchangeRate}
                      SlippageLimitForm={SlippageLimitForm}
                    />
                  </>
                )}
                <AdditionalInfo
                  isSameAsset={isSameAsset}
                  flashloanSource={useFl ? flProtocol : ''}
                  flashloanFee={flFee}
                  exchangeSource={exchangeSource}
                  additionalAction={secondAction}
                  additionalInput={secondInput}
                />
                {repayingError && <ErrorBox marginTop>{repayingError}</ErrorBox>}
              </div>
            </>
          )}

        </div>
      </ModalBody>

      <div className="modal-controls">
        <button
          form="compound-repay-modal-form"
          type="button"
          disabled={repaying || gettingRepayModalData}
          onClick={() => dispatch(aaveRepayActionV2(contextAction, additionalAction, closeModal))}
          className="button green"
        >
          {repaying ? t('common.repaying') : t('common.repay')}
        </button>
      </div>
    </div>
  );
};

AaveRepayModalV2.propTypes = {
  closeModal: PropTypes.func.isRequired,
  repayAmount: PropTypes.string.isRequired,
  repayExchangeRate: PropTypes.string.isRequired,
  tradeSizeImpact: PropTypes.string.isRequired,
  gettingRepayModalData: PropTypes.bool.isRequired,
  gettingRepayModalDataError: PropTypes.string.isRequired,
  repaying: PropTypes.bool.isRequired,
  repayingError: PropTypes.string.isRequired,
  flProtocol: PropTypes.string.isRequired,
  useFl: PropTypes.bool.isRequired,
  flFee: PropTypes.string.isRequired,
  exchangeSource: PropTypes.string.isRequired,
  additionalAction: PropTypes.object,
  contextAction: PropTypes.object,
};

const mapStateToProps = ({ aaveManage }) => ({
  tradeSizeImpact: aaveManage.tradeSizeImpact,
  gettingRepayModalData: aaveManage.gettingRepayModalData,
  gettingRepayModalDataError: aaveManage.gettingRepayModalDataError,
  repayAmount: aaveManage.repayAmount,
  repayExchangeRate: aaveManage.repayExchangeRate,
  repaying: aaveManage.executing?.repay || (aaveManage.executing[aaveManage.selectedAction?.value] && aaveManage.selectedAdditionalActions[aaveManage.selectedAction?.value]?.value === 'repay'),
  repayingError: aaveManage.executingErrors?.repay,
  flProtocol: aaveManage.flProtocol,
  useFl: aaveManage.useFl,
  flFee: aaveManage.flFee,
  exchangeSource: aaveManage.exchangeSource,
});

export default connect(mapStateToProps, null)(AaveRepayModalV2);
