import React from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';
import ArrowCaret from '../Decorative/ArrowCaret';

import { bemifyClass } from '../../services/stylingUtils';

import './TogglableSection.scss';

const className = bemifyClass('togglable-section');

const TogglableSection = ({
  advanced, toggleAdvanced, title, children, additionalClasses, modify, bgColor,
}) => (
  <div className={`${className('', modify)} ${additionalClasses}`} style={{ background: bgColor }}>
    <div className={className('title-wrapper')} onClick={() => toggleAdvanced(prev => !prev)}>
      <div className={className('title')}>{title}</div>
      <ArrowCaret to={advanced ? 'up' : 'down'} />
    </div>
    <AnimateHeight duration={300} height={advanced ? 'auto' : 0}>
      <div className={className('content')}>{children}</div>
    </AnimateHeight>
  </div>
);

TogglableSection.defaultProps = {
  advanced: false,
  bgColor: '', // TODO Replace with color modifier classes
  additionalClasses: '',
  modify: '',
};

TogglableSection.propTypes = {
  advanced: PropTypes.bool,
  toggleAdvanced: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node.isRequired,
  bgColor: PropTypes.string,
  additionalClasses: PropTypes.string,
  modify: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default TogglableSection;
