import {
  getAssetInfoByAddress, getAssetInfo, assetAmountInWei,
} from '@defisaver/tokens';
import {
  Trade, Token, Pair, TokenAmount, currencyEquals, Percent,
} from '@uniswap/sdk';
import { request as graphqlRequest } from 'graphql-request';
import Dec from 'decimal.js';
import { captureException } from '../sentry';
import { multicall } from './multicallService';
import { getErc20Contract, UniswapV2ViewAddress } from './contractRegistryService';
import { aggregate } from './ethService';
import { getERC20TokenData } from './erc20Service';


const ETH = getAssetInfo('ETH');
const WETH = getAssetInfo('WETH');
const USDC = getAssetInfo('USDC');
const DAI = getAssetInfo('DAI');
const USDT = getAssetInfo('USDT');
const COMP = getAssetInfo('COMP');
const MKR = getAssetInfo('MKR');

const WETH_TOKEN = new Token(1, window._web3.utils.toChecksumAddress(WETH.address), WETH.decimals, WETH.symbol, WETH.name);
const USDC_TOKEN = new Token(1, window._web3.utils.toChecksumAddress(USDC.address), USDC.decimals, USDC.symbol, USDC.name);
const DAI_TOKEN = new Token(1, window._web3.utils.toChecksumAddress(DAI.address), DAI.decimals, DAI.symbol, DAI.name);
const USDT_TOKEN = new Token(1, window._web3.utils.toChecksumAddress(USDT.address), USDT.decimals, USDT.symbol, USDT.name);
const COMP_TOKEN = new Token(1, window._web3.utils.toChecksumAddress(COMP.address), COMP.decimals, COMP.symbol, COMP.name);
const MKR_TOKEN = new Token(1, window._web3.utils.toChecksumAddress(MKR.address), MKR.decimals, MKR.symbol, MKR.name);
const AMPL_TOKEN = new Token(1, window._web3.utils.toChecksumAddress('0xD46bA6D942050d489DBd938a2C909A5d5039A161'), 9, 'AMPL', 'Ampleforth');

const ALLOWED_TRADE_AGAINST = [WETH_TOKEN, USDC_TOKEN, DAI_TOKEN, USDT_TOKEN, COMP_TOKEN, MKR_TOKEN];

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
const CUSTOM_BASES = {
  [AMPL_TOKEN.address]: [DAI, WETH_TOKEN],
};

const isEth = (address) => address.toLowerCase() === ETH.address.toLowerCase();

const wrappedToken = (asset) => (isEth(asset.address) ? WETH_TOKEN : new Token(
  1,
  window._web3.utils.toChecksumAddress(asset.address),
  +asset.decimals,
  asset.symbol,
  asset.name,
));

const allPairCombinations = (tokenA, tokenB) => {
  const bases = ALLOWED_TRADE_AGAINST;

  const basePairs = () => bases.flatMap((base) => bases.map(otherBase => [base, otherBase])).filter(
    ([t0, t1]) => t0.address !== t1.address,
  );

  return [
    // the direct pair
    [tokenA, tokenB],
    // token A against all bases
    ...bases.map((base) => [tokenA, base]),
    // token B against all bases
    ...bases.map((base) => [tokenB, base]),
    // each base against all bases
    ...basePairs(),
  ]
    .filter((tokens) => Boolean(tokens[0] && tokens[1]))
    .filter(([t0, t1]) => t0.address !== t1.address)
    .filter(([tokenA, tokenB]) => {
      const customBases = CUSTOM_BASES;
      if (!customBases) return true;

      const customBasesA = customBases[tokenA.address];
      const customBasesB = customBases[tokenB.address];

      if (!customBasesA && !customBasesB) return true;

      if (customBasesA && !customBasesA.find(base => tokenB.equals(base))) return false;
      return !(customBasesB && !customBasesB.find(base => tokenA.equals(base)));
    });
};

const reservesFunctionAbiItem = {
  constant: true,
  inputs: [],
  name: 'getReserves',
  outputs: [
    { internalType: 'uint112', name: '_reserve0', type: 'uint112' },
    { internalType: 'uint112', name: '_reserve1', type: 'uint112' },
    { internalType: 'uint32', name: '_blockTimestampLast', type: 'uint32' },
  ],
  payable: false,
  stateMutability: 'view',
  type: 'function',
};

const multicallPairReserves = async (pairAddresses) => {
  let calls = [];

  pairAddresses.forEach((address) => {
    calls = [...calls, {
      abiItem: reservesFunctionAbiItem,
      target: address,
      params: [],
    }];
  });

  return multicall(calls);
};

const PairState = {
  LOADING: 0,
  NOT_EXISTS: 1,
  EXISTS: 2,
  INVALID: 3,
};

const getPairs = async (combinations) => {
  const pairAddresses = combinations.map(([tokenA, tokenB]) => (tokenA && tokenB && !tokenA.equals(tokenB) ? Pair.getAddress(tokenA, tokenB) : undefined));

  const results = await multicallPairReserves(pairAddresses);

  return results.map((result, i) => {
    const tokenA = combinations[i][0];
    const tokenB = combinations[i][1];

    if (!tokenA || !tokenB || tokenA.equals(tokenB)) return [PairState.INVALID, null];
    if (!result) return [PairState.NOT_EXISTS, null];
    const { _reserve0, _reserve1 } = result;
    const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA];
    return [
      PairState.EXISTS,
      new Pair(new TokenAmount(token0, _reserve0.toString()), new TokenAmount(token1, _reserve1.toString())),
    ];
  });
};

const getAllowedPairs = async (tokenA, tokenB) => {
  const combinations = allPairCombinations(tokenA, tokenB);

  const allPairs = await getPairs(combinations);

  return Object.values(
    allPairs
    // filter out invalid pairs
      .filter((result) => Boolean(result[0] === PairState.EXISTS && result[1]))
    // filter out duplicated pairs
      .reduce((memo, [, curr]) => {
        // eslint-disable-next-line no-param-reassign
        memo[curr.liquidityToken.address] =
          memo[curr.liquidityToken.address] === null || memo[curr.liquidityToken.address] === undefined
            ? curr
            : memo[curr.liquidityToken.address];
        return memo;
      }, {}),
  );
};

const isTradeBetter = (tradeA, tradeB, minimumDelta) => {
  if (tradeA && !tradeB) return false;
  if (tradeB && !tradeA) return true;
  if (!tradeA || !tradeB) return undefined;

  if (
    tradeA.tradeType !== tradeB.tradeType ||
    !currencyEquals(tradeA.inputAmount.currency, tradeB.inputAmount.currency) ||
    !currencyEquals(tradeB.outputAmount.currency, tradeB.outputAmount.currency)
  ) {
    throw new Error('Trades are not comparable');
  }

  if (minimumDelta.equalTo(new Percent('0'))) {
    return tradeA.executionPrice.lessThan(tradeB.executionPrice);
  }
  return tradeA.executionPrice.raw.multiply(minimumDelta.add(new Percent('1'))).lessThan(tradeB.executionPrice);
};

/**
 *
 * @param {string} amount
 * @param {string} fromAsset
 * @param {string} toAsset
 * @param {boolean} shouldSell
 * @param {number} maxHops
 * @return {Promise<string[]>}
 */
export const getUniswapRoute = async (amount, fromAsset, toAsset, shouldSell, maxHops = 3) => {
  try {
    if (!amount || !fromAsset || !toAsset) return [];
    const assetIn = await getERC20TokenData(window._web3.utils.toChecksumAddress(fromAsset));
    const assetOut = await getERC20TokenData(window._web3.utils.toChecksumAddress(toAsset));

    const tokenIn = wrappedToken(assetIn);
    const tokenOut = wrappedToken(assetOut);

    const allowedPairs = await getAllowedPairs(tokenIn, tokenOut);

    const amountIn = new TokenAmount(tokenIn, assetAmountInWei(amount, assetIn.symbol));
    const amountOut = new TokenAmount(tokenOut, assetAmountInWei(amount, assetOut.symbol));

    let bestTradeSoFar = null;

    for (let i = 1; i <= maxHops; i++) {
      let trade;
      if (shouldSell) {
        trade = Trade.bestTradeExactIn(
          allowedPairs,
          amountIn,
          tokenOut,
          { maxHops: i, maxNumResults: 3 },
        )[0];
      } else {
        trade = Trade.bestTradeExactOut(
          allowedPairs,
          tokenIn,
          amountOut,
          { maxHops: i, maxNumResults: 3 },
        )[0];
      }
      const currentTrade = trade === null || trade === undefined ? null : trade;
      // if current trade is best yet, save it
      if (isTradeBetter(bestTradeSoFar, currentTrade, new Percent('50', '10000'))) {
        bestTradeSoFar = currentTrade;
      }
    }

    if (bestTradeSoFar && bestTradeSoFar.route && bestTradeSoFar.route.path) {
      return bestTradeSoFar.route.path.map(item => item.address);
    }
    return [];
  } catch (error) {
    captureException(error);
    return [];
  }
};

export const getAllPairReserves = async (assets) => {
  const combinations = assets.flatMap((v, i) => assets.slice(i + 1).map(w => [
    new Token(1, window._web3.utils.toChecksumAddress(v.address), v.decimals, v.symbol, v.name),
    new Token(1, window._web3.utils.toChecksumAddress(w.address), w.decimals, w.symbol, w.name),
  ]));
  return getPairs(combinations);
};

const getTokensAndPair = async (tokenA, tokenB) => {
  const token1Info = getAssetInfo(tokenA);
  const token2Info = getAssetInfo(tokenB);
  const _token1 = new Token(1, window._web3.utils.toChecksumAddress(token1Info.address), token1Info.decimals, token1Info.symbol, token1Info.name);
  const _token2 = new Token(1, window._web3.utils.toChecksumAddress(token2Info.address), token2Info.decimals, token2Info.symbol, token2Info.name);
  const results = await multicallPairReserves([Pair.getAddress(_token1, _token2)]);
  const [token1, token2] = _token1.sortsBefore(_token2) ? [_token1, _token2] : [_token2, _token1];
  const pair = new Pair(new TokenAmount(token1, results[0]._reserve0.toString()), new TokenAmount(token2, results[0]._reserve1.toString()));
  const firstInputToken = token1.symbol === tokenA ? token1 : token2;
  const secondInputToken = token2.symbol === tokenA ? token1 : token2;
  return [firstInputToken, secondInputToken, pair];
};

export const getLPAddress = (assetA, assetB) => {
  const tokenAInfo = getAssetInfoByAddress(assetA);
  const tokenBInfo = getAssetInfoByAddress(assetB);

  const tokenA = new Token(1, window._web3.utils.toChecksumAddress(tokenAInfo.address), tokenAInfo.decimals, tokenAInfo.symbol, tokenAInfo.name);
  const tokenB = new Token(1, window._web3.utils.toChecksumAddress(tokenBInfo.address), tokenBInfo.decimals, tokenBInfo.symbol, tokenBInfo.name);

  return Pair.getAddress(tokenA, tokenB);
};

export const getLPLiquidityMintedEstimate = async (tokenA, tokenAAmount, tokenB, tokenBAmount) => {
  if (!(+tokenAAmount) || !(+tokenBAmount)) return ['0', '0x0'];
  const [token1, token2, pair] = await getTokensAndPair(tokenA, tokenB);

  const contract = await getErc20Contract(pair.liquidityToken.address);
  const data = await contract.methods.totalSupply().call();
  const supply = new TokenAmount(pair.liquidityToken, data);
  const firstTokenSupply = new TokenAmount(token1, assetAmountInWei(tokenAAmount, token1.symbol));
  const secondTokenSupply = new TokenAmount(token2, assetAmountInWei(tokenBAmount, token2.symbol));
  return [pair.getLiquidityMinted(supply, firstTokenSupply, secondTokenSupply).toExact(), pair.liquidityToken.address];
};

export const getLPLiquidityValueEstimate = async (tokenA, tokenB, _liquidity) => {
  if (!(+_liquidity)) return ['0', '0', '0x0'];
  const [token1, token2, pair] = await getTokensAndPair(tokenA, tokenB);
  const contract = await getErc20Contract(pair.liquidityToken.address);
  const data = await contract.methods.totalSupply().call();
  const supply = new TokenAmount(pair.liquidityToken, data);
  // LP tokens have 18 decimals just like DAI
  const liquidity = new TokenAmount(pair.liquidityToken, assetAmountInWei(_liquidity, 'DAI'));
  const valueForFirstToken = await pair.getLiquidityValue(token1, supply, liquidity);
  const valueForSecondToken = await pair.getLiquidityValue(token2, supply, liquidity);
  return [valueForFirstToken.toExact(), valueForSecondToken.toExact(), pair.liquidityToken.address];
};

export const getUniswapPrice = async (tokenA, tokenB) => {
  const [token1,, pair] = await getTokensAndPair(tokenA, tokenB);
  return pair.priceOf(token1).toSignificant();
};

export const getDataFromGraphApi = async (account) => {
  const url = 'https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v2';
  const query = `{
  user(id:"${account}"){
    liquidityPositions(skip: 0, where:{liquidityTokenBalance_gt: 0}) {
    id
    liquidityTokenBalance
      pair {
        id
        token0Price
        token1Price
        reserve0
        reserve1
        token0{
          id
          name
          symbol
          decimals
          derivedETH
        }
        token1{
          id
          symbol
          name
          decimals
          derivedETH
        }
        totalSupply
      }
    }
  }}`;
  try {
    const res = await graphqlRequest(url, query);
    return res?.user?.liquidityPositions || [];
  } catch (err) {
    console.log(err.message);
  }
};

export const getAmountsFromGraphApiData = (data) => {
  console.log(data);
  const token0 = new Token(1, window._web3.utils.toChecksumAddress(data.pair.token0.id), data.pair.token0.decimals, data.pair.token0.symbol, data.pair.token0.name);
  const token1 = new Token(1, window._web3.utils.toChecksumAddress(data.pair.token1.id), data.pair.token1.decimals, data.pair.token1.symbol, data.pair.token1.name);
  const lpToken = new Token(1, window._web3.utils.toChecksumAddress(data.pair.id), 18);
  const supply = new TokenAmount(lpToken, assetAmountInWei(data.pair.totalSupply, 'DAI'));
  // LP tokens have 18 decimals just like DAI
  const liquidity = new TokenAmount(lpToken, assetAmountInWei(data.liquidityTokenBalance, 'DAI'));
  const pair = new Pair(new TokenAmount(token0, new Dec(data.pair.reserve0).mul(10 ** data.pair.token0.decimals).floor().toString()), new TokenAmount(token1, new Dec(data.pair.reserve1).mul(10 ** data.pair.token1.decimals).floor().toString()));
  const valueForFirstToken = pair.getLiquidityValue(token0, supply, liquidity);
  const valueForSecondToken = pair.getLiquidityValue(token1, supply, liquidity);

  return [valueForFirstToken.toSignificant(), valueForSecondToken.toSignificant()];
};


export const getUniDataForPools = async (tokens) => {
  const multicallObject = tokens.map(token => ({
    target: UniswapV2ViewAddress,
    call: ['getPairInfo(address)(address,address,uint112,uint112)', token.address],
    returns: [
      [`token0Address${token.address}`, val => val.toString()],
      [`token1Address${token.address}`, val => val.toString()],
      [`reserve0${token.address}`, val => val.toString()],
      [`reserve1${token.address}`, val => val.toString()],
    ],
  }));

  const res = await aggregate(multicallObject);

  const {
    results: {
      transformed,
    },
  } = res;

  return tokens.map(token => ({
    address: token.address,
    tokens: [transformed[`token0Address${token.address}`], transformed[`token1Address${token.address}`]],
    reserves: [transformed[`reserve0${token.address}`], transformed[`reserve1${token.address}`]],
  }));
};


export const compareMarketPriceWithPoolPrice = async (marketPrice, tokenA, tokenB) => {
  const [token1, token2, pair] = await getTokensAndPair(tokenA, tokenB);
  const tokenToCompare = token1.symbol === tokenA ? token1 : token2;
  const price = pair.priceOf(tokenToCompare).toSignificant();
  console.log(price, marketPrice);
  return new Dec(marketPrice).div(price).add(1).toString();
};
