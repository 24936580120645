import React from 'react';
import { v4 as uuid } from 'uuid';

export default function ZeroXIcon(props) {
  const id = `0x_icon${uuid()}`;
  const fill = `url(#${id})`;

  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z" fill={fill} />
      <path d="M10.3608 17.112L11.6028 15.8272L10.0588 13.7436L8.09281 10.962C7.37512 12.1865 6.99783 13.5807 7.00001 15C7.00001 17.44 8.09281 19.6248 9.81601 21.092L12.3116 19.3288C11.4577 18.7977 10.7792 18.0268 10.3608 17.1124V17.112ZM12.888 10.3608L14.1728 11.6028L16.2564 10.0588L19.038 8.09281C17.8135 7.37512 16.4193 6.99783 15 7.00001C12.56 7.00001 10.3752 8.09281 8.90801 9.81601L10.6712 12.3116C11.2023 11.4577 11.9732 10.7792 12.8876 10.3608H12.888ZM18.3972 14.1728L19.9412 16.2564L21.9072 19.038C22.6249 17.8135 23.0022 16.4193 23 15C23 12.56 21.9072 10.3752 20.184 8.90801L17.688 10.6712C18.5419 11.2023 19.2204 11.9732 19.6388 12.8876L18.3968 14.1728H18.3972ZM21.092 20.184L19.3288 17.6884C18.7977 18.5423 18.0268 19.2208 17.1124 19.6392L15.8272 18.3972L13.7436 19.9412L10.962 21.9072C12.1865 22.6249 13.5807 23.0022 15 23C17.44 23 19.6248 21.9072 21.092 20.184Z" fill="white" />
      <defs>
        <linearGradient id={id} x1="15" y1="0" x2="15" y2="30" gradientUnits="userSpaceOnUse">
          <stop stopColor="#4DAB9A" />
          <stop offset="1" stopColor="#316A60" />
        </linearGradient>
      </defs>
    </svg>
  );
}
