import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import t from 'translate';

import Dec from 'decimal.js';
import { getAssetInfo } from '@defisaver/tokens';
import { closeNotification } from '../../actions/txNotificationActions';
import { formatEtherScanLink, formatNumber, formatTenderlyForkLink } from '../../services/utils';

import TxConfirm from './TxConfirm/TxConfirm';
import TxConfirmEIP1559 from './TxConfirmEIP1559/TxConfirmEIP1559';
import CloseIcon from '../Decorative/CloseIcon';
import TxSuccessIcon from '../Decorative/TxSuccessIcon';
import TxReplacedIcon from '../Decorative/TxReplacedIcon';
import TxCanceledIcon from '../Decorative/TxCanceledIcon';
import Loader from '../Loader/Loader';
import './TxNotifications.scss';
import TooltipWrapper from '../TooltipWrapper/TooltipWrapper';
import { protocolIcons } from '../../constants/general';
import TxTokenFields from './TxTokenFields/TxTokenFields';


const TxNotifications = ({
  notifications, closeNotification, confirmations, pendingTxs, currentTx, numOfNotifications, network,
}) => {
  const getSwapRate = (swapRate, firstToken, secondToken) => (new Dec(swapRate || 0).gt(new Dec(1).div(swapRate || 0)) ?
    `${formatNumber(new Dec(swapRate).toString(), 2)} ${firstToken}/${secondToken}` : `${formatNumber(new Dec(1).div(swapRate).toString(), 2)} ${secondToken}/${firstToken}`);
  return (
    <div className="tx-notifications-wrapper">
      {
        // numOfNotifications = number of notifications prior to executing action that requires multiple txs
        pendingTxs.length > 1 && !notifications?.[numOfNotifications + currentTx]?.hidden && (
          <div className="tx-steps" style={{ background: `#323C44 linear-gradient(90deg, rgba(55, 176, 111, 0.3) ${new Dec(currentTx).div(pendingTxs.length).mul(100).toFixed(0)}%, rgba(97, 113, 126, 0.3) ${new Dec(currentTx + 1).div(pendingTxs.length).mul(100).toFixed(0)}%)` }}>
            <div className="tx-steps-item horizontal-line-wrapper mini">
              <div className="horizontal-line finished" />
            </div>
            {
              pendingTxs.map((tx, i) => (
                <React.Fragment key={tx}>
                  <TooltipWrapper title={tx}>
                    <div className={`tx-steps-item tx-number ${i <= currentTx ? 'finished' : ''}`}>{i + 1}</div>
                  </TooltipWrapper>
                  {(i + 1 < pendingTxs.length) && (
                    <div className="tx-steps-item horizontal-line-wrapper">
                      <div className={`horizontal-line ${i < currentTx ? 'finished' : ''}`} />
                    </div>
                  )}
                </React.Fragment>
              ))
            }
            <div className="tx-steps-item horizontal-line-wrapper mini">
              <div className="horizontal-line" />
            </div>
          </div>
        )
      }
      {/* { */}
      {/*    pendingTxs.length > 1 && !confirmations.length && !notifications.filter(n => !n.hidden).length && ( */}
      {/*      <Loader /> */}
      {/*    ) */}
      {/*  } */}
      {
        confirmations.map(conf => (
          <div className="tx-confirm-outer-wrapper" key={conf.id}>
            {conf.isType2 ? <TxConfirmEIP1559 txData={conf} /> : <TxConfirm txData={conf} />}
          </div>
        ))
      }
      {
        notifications.filter(n => !n.hidden).map(({
          id, title, description, status, hash, loadingDfeData, dfeData, txTakingTooLong, protocol, firstToken, firstAmount, secondToken, secondAmount, eventData = { recipeSwaps: [], fees: [] }, forkTxId, multipleSwaps,
        }) => {
          const { swapRate, recipeSwaps, fees } = eventData;
          return (
            <div className={`single-notification ${!pendingTxs.length ? 'not-composed' : ''} ${status} ${hash ? 'has-tx' : ''}`} key={id}>
              <div className="notif-header-wrapper">
                {protocol && protocolIcons[protocol] && <img src={protocolIcons[protocol]} alt="" />}
                <div className="title">{ title }</div>
                <div className="close-icon-wrapper" onClick={() => { closeNotification(id); }}><CloseIcon size={12} /></div>
              </div>
              {recipeSwaps.length === 0 && protocol && firstToken && firstAmount && (
                <TxTokenFields
                  firstToken={{ ...getAssetInfo(firstToken), symbol: firstToken }}
                  secondToken={secondToken ? { ...getAssetInfo(secondToken), symbol: secondToken } : undefined}
                  firstAmount={firstAmount}
                  secondAmount={secondAmount}
                />
              )}
              { recipeSwaps.length === 0 && multipleSwaps?.length > 0 && (
                multipleSwaps.map(({
                  buyToken, sellToken, buyAmount, sellAmount, wrapper,
                }) => (
                  <TxTokenFields
                    key={wrapper}
                    firstToken={sellToken}
                    secondToken={buyToken}
                    firstAmount={sellAmount}
                    secondAmount={buyAmount}
                  />
                ))
              )}
              { recipeSwaps.length > 0 && (
                recipeSwaps.map(({
                  buyToken, sellToken, buyAmount, sellAmount,
                }) => (
                  <TxTokenFields
                    firstToken={sellToken}
                    secondToken={buyToken}
                    firstAmount={sellAmount}
                    secondAmount={buyAmount}
                  />
                ))
              )}
              <div className="info-wrapper">
                {
                  (!hash) ? (<div className="description">{ description }</div>)
                    : (
                      <div>
                        {recipeSwaps.length === 0 && +swapRate > 0 && (<div className="swap-rate">Swap rate: {getSwapRate(swapRate, firstToken, secondToken)}</div>)}
                        {recipeSwaps.length > 0 && recipeSwaps.map(({
                          swapRate, wrapper, buyToken, sellToken,
                        }) => <div className="swap-rate" key={wrapper}>Swap rate: {getSwapRate(swapRate, sellToken.symbol, buyToken.symbol)}</div>)}
                        {fees.map(({ feeAsset, feeAmount, type }) => {
                          if (feeAsset && 0 + feeAmount) {
                            if (type === 'service') {
                              return (
                                <div key={feeAsset} className="fee">
                                  Service fee: {`${formatNumber(feeAmount, 5, true)} ${feeAsset}`}
                                </div>
                              );
                            }
                            if (type === 'aaveFL') {
                              return (
                                <div key={feeAsset} className="fee">
                                  Flash loan fee: {`${formatNumber(feeAmount, 5, true)} ${feeAsset}`}
                                </div>
                              );
                            }
                          }
                          return '';
                        })}
                        <div>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            className="description"
                            href={forkTxId ? formatTenderlyForkLink(forkTxId, network) : formatEtherScanLink(hash, 'tx', network)}
                          >
                            { description }
                          </a>
                        </div>
                      </div>
                    )
                }
                <span className="status-wrapper">
                  { (status === 'pending' || status === 'pending-sign') && (<Loader />) }
                  { status === 'confirmed' && (<TxSuccessIcon />) }
                  { status === 'speedup' && (<TxReplacedIcon />) }
                  { status === 'failed' && (<TxCanceledIcon />) }
                  { status === 'cancel' && (<TxCanceledIcon />) }
                  { status === 'rejected' && (<TxCanceledIcon />) }
                  { status === 'dropped' && (<TxCanceledIcon />) }
                </span>
                {
                  hash && status === 'failed' && (
                    <div className="defiexplore-error-data">
                      {!txTakingTooLong && loadingDfeData && (
                        <p className="loading">Loading error details...</p>
                      )}
                      {!txTakingTooLong && !loadingDfeData && (
                        <p className="error">
                          Reason:{' '}
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`https://defiexplore.com/tx/${hash}`}
                          >
                            {dfeData?.errorCode ? t(dfeData?.errorCode) : dfeData?.errorMessage ? dfeData?.errorMessage : 'Unknown error'}
                          </a>
                        </p>
                      )}
                    </div>
                  )
                }
              </div>
            </div>
          );
        })
      }
    </div>
  );
};

TxNotifications.propTypes = {
  notifications: PropTypes.array.isRequired,
  confirmations: PropTypes.array.isRequired,
  pendingTxs: PropTypes.array.isRequired,
  currentTx: PropTypes.number.isRequired,
  numOfNotifications: PropTypes.number.isRequired,
  closeNotification: PropTypes.func.isRequired,
  network: PropTypes.node.isRequired,
};

const mapStateToProps = ({ notifications, general }) => ({
  notifications: notifications.notifications,
  confirmations: notifications.confirmations,
  pendingTxs: notifications.pendingTxs,
  currentTx: notifications.currentTx,
  network: general.network,
  numOfNotifications: notifications.numOfNotifications,
});

const mapDispatchToProps = {
  closeNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(TxNotifications);
