import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import t from 'translate';
import { getAssetInfo } from '@defisaver/tokens';
import { BLOCK_EXPLORER_URL } from '../../../constants/general';
import {
  formatAcc, formatNumber, getEnsAddress, isAddress, isEmptyBytes, requireAddress,
} from '../../../services/utils';
import { getERC20TokenData } from '../../../services/erc20Service';

import ModalBody from '../ModalBody';
import TooltipWrapper from '../../TooltipWrapper/TooltipWrapper';
import WarningBox from '../../Decorative/WarningBox/WarningBox';
import ErrorBox from '../../Decorative/ErrorBox/ErrorBox';
import Loader from '../../Loader/Loader';

import './SendConfirmationModal.scss';

const SendConfirmationModal = ({
  resolve, sendingTo, account, proxyAddress, token, amount, network,
}) => {
  const [ensName, setEnsName] = useState('');
  const [address, setAddress] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  const onInit = async () => {
    let _address = sendingTo;
    if (!isAddress(_address)) {
      _address = await getEnsAddress(_address).catch(() => {
        throw new Error(t('misc.ens_not_found'));
      });
      if (!_address || isEmptyBytes(_address)) throw new Error(t('misc.ens_not_found'));
      setAddress(_address.toLowerCase().trim());
      setEnsName(sendingTo);
    } else {
      requireAddress(_address);
    }
    await getERC20TokenData(_address)
      .catch(() => {})
      .then((assetData) => {
        if (assetData) {
          throw new Error(t('errors.sending_to_asset_contract', { '%asset': assetData.symbol }));
        }
      })
      .finally(() => setAddress(_address));

    if (_address.toLowerCase() === account.toLowerCase()) throw new Error(t('misc.sending_to_your_acc'));
    if (_address.toLowerCase() === proxyAddress?.toLowerCase()) throw new Error(t('misc.sending_to_your_proxy'));
  };

  useEffect(() => {
    onInit().catch((err) => setError(err.message)).finally(() => setLoading(false));
  }, []);

  return (
    <div className="send-confirmation-modal">
      <ModalBody>
        <div className="new-modal-top-wrapper warning">
          <h1>{t('common.sending_funds')}</h1>
        </div>
        <div className="new-modal-content-wrapper">
          {loading && <Loader />}
          {!loading && error && <ErrorBox>{error}</ErrorBox>}
          {!loading && !error && (
            <>
              {amount && token && (
                <TooltipWrapper className="send-token" title={`${amount} ${token.symbol}`} useText>
                  {token.icon()} <p>{formatNumber(amount)}<span>{token.symbol}</span></p>
                </TooltipWrapper>
              )}
              <p className="send-address">
                {t('common.sending_to')}:{' '}
                <a
                  href={`${BLOCK_EXPLORER_URL[network]}address/${address}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {formatAcc(address)} {ensName && <>({ensName})</>}
                </a>
              </p>
              <WarningBox marginTop>{t('misc.send_tokens_warning')}</WarningBox>
            </>
          )}
        </div>
      </ModalBody>

      <div className="modal-controls">
        <button type="button" className="button gray" onClick={() => resolve(false)}>{t('common.cancel')}</button>
        <button type="button" className="button green" disabled={loading || error} onClick={() => resolve(address)}>{t('common.confirm')}</button>
      </div>
    </div>
  );
};

SendConfirmationModal.defaultProps = {
  amount: '',
  token: getAssetInfo(''),
};

SendConfirmationModal.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  token: PropTypes.object,
  resolve: PropTypes.func.isRequired,
  proxyAddress: PropTypes.string.isRequired,
  sendingTo: PropTypes.string.isRequired,
  account: PropTypes.string.isRequired,
  network: PropTypes.number.isRequired,
};

export default SendConfirmationModal;
