import {
  GET_UNIV3_NFT_REQUEST,
  GET_UNIV3_NFT_SUCCESS,
  GET_UNIV3_NFT_FAILURE,
  APPROVE_NFT_ON_ADDRESS_REQUEST,
  APPROVE_NFT_ON_ADDRESS_SUCCESS,
  APPROVE_NFT_ON_ADDRESS_FAILURE,
  UNISWAP_V3_MOCK_POSITION_REQUEST,
  UNISWAP_V3_MOCK_POSITION_SUCCESS,
  UNISWAP_V3_MOCK_POSITION_FAILURE,
  UNISWAP_V3_POOL_REQUEST,
  UNISWAP_V3_POOL_SUCCESS,
  UNISWAP_V3_POOL_FAILURE,
  UNISWAP_V3_MOCK_POOL_REQUEST,
  UNISWAP_V3_MOCK_POOL_SUCCESS,
  UNISWAP_V3_MOCK_POOL_FAILURE,
} from 'actionTypes/uniswapV3ActionTypes';


import { getAssetInfoByAddress } from '@defisaver/tokens';
import { Position, tickToPrice } from '@uniswap/v3-sdk';
import Dec from 'decimal.js';
import memoize from 'memoizee';
import { captureException } from '../sentry';
import {
  approveUniNft,
  getCollectAmounts,
  getNftIds,
  getPercent,
  getPool,
  getPositions,
  getTokenAmountPercent,
  getTokens,
  mockPool,
  mockPosition,
} from '../services/uniswapV3Services';
import { UniV3NonfungiblePositionManagerContract } from '../services/contractRegistryService';
import {
  compareAddresses, getEthAmountForDecimals, requireAddress,
} from '../services/utils';
import { sendTx } from './txNotificationActions';
import { getUnknownTokensInfo } from '../services/portfolioService';

export const parseNftParams = async (nfts, unknownTokens, account) => {
  if (!nfts.length) return [];
  return Promise.all(nfts.map(async nft => {
    const _firstToken = getAssetInfoByAddress(nft.tokenFirst).symbol === '?' ? unknownTokens.find(token => compareAddresses(token.address, nft.tokenFirst)) : getAssetInfoByAddress(nft.tokenFirst);
    const _secondToken = getAssetInfoByAddress(nft.tokenSecond).symbol === '?' ? unknownTokens.find(token => compareAddresses(token.address, nft.tokenSecond)) : getAssetInfoByAddress(nft.tokenSecond);
    const [,, firstToken, secondToken] = getTokens(_firstToken, _secondToken);
    const percent = getPercent(100);
    const [pool, poolAddress] = await getPool(firstToken, secondToken, new Dec(nft.fee).div(10000).toString(), nft.tickLower, nft.tickUpper);
    const position = new Position({
      pool,
      liquidity: nft.liquidity.toString(),
      tickLower: nft.tickLower,
      tickUpper: nft.tickUpper,
    });
    const tokensOwedFirst = getEthAmountForDecimals(nft.tokensOwedFirst, _firstToken.decimals);
    const tokensOwedSecond = getEthAmountForDecimals(nft.tokensOwedSecond, _secondToken.decimals);

    const collectibleAmounts = await getCollectAmounts(nft.id, account);
    return {
      ...nft,
      tickLowerPriceFirstToken: tickToPrice(firstToken, secondToken, nft.tickLower).toSignificant(),
      tickLowerPriceSecondToken: tickToPrice(secondToken, firstToken, nft.tickLower).toSignificant(),
      tickUpperPriceFirstToken: tickToPrice(firstToken, secondToken, nft.tickUpper).toSignificant(),
      tickUpperPriceSecondToken: tickToPrice(secondToken, firstToken, nft.tickUpper).toSignificant(),
      amountInFirstToken: getTokenAmountPercent(position, firstToken, percent, true).toExact(),
      amountInSecondToken: getTokenAmountPercent(position, secondToken, percent, false).toExact(),
      tokenFirstInfo: _firstToken,
      tokenSecondInfo: _secondToken,
      tokensOwedFirst,
      tokensOwedSecond,
      rewardFirst: getEthAmountForDecimals(collectibleAmounts.amount0, _firstToken.decimals),
      rewardSecond: getEthAmountForDecimals(collectibleAmounts.amount1, _secondToken.decimals),
      tokenURI: nft.tokenURI,
    };
  }));
};


export const getNftIdsAction = (userAddress = '', userProxy = '') => async (dispatch, getState) => {
  const {
    general: { account, recentAccounts },
    maker: { proxyAddress },
  } = getState();
  let fetchingAddress = account;
  let fetchingProxy = proxyAddress;

  if (userAddress) {
    fetchingAddress = userAddress;
    fetchingProxy = userProxy;
  }

  // eslint-disable-next-line no-unused-expressions
  dispatch({ type: GET_UNIV3_NFT_REQUEST });

  try {
    const [accountIDs, proxyIDs] = await getNftIds([fetchingAddress, fetchingProxy]);
    const _accountPositions = await getPositions(accountIDs, false, fetchingAddress, fetchingProxy);
    const _proxyPositions = await getPositions(proxyIDs, true, fetchingAddress, fetchingProxy);
    const unknownTokens = [
      ...(_accountPositions.map(pos => ([pos.tokenFirst, pos.tokenSecond]))),
      ...(_proxyPositions.map(pos => ([pos.tokenFirst, pos.tokenSecond]))),
    ]
      .flat()
      .filter(asset => getAssetInfoByAddress(asset).symbol === '?');
    const _unknownTokensInfo = unknownTokens.length ? await getUnknownTokensInfo(fetchingAddress, unknownTokens) : [];
    const unknownTokensInfo = unknownTokens.map((token, i) => ({
      ..._unknownTokensInfo[i],
      address: token,
    }));
    const accountPositions = await parseNftParams(_accountPositions, unknownTokensInfo, account);
    const proxyPositions = await parseNftParams(_proxyPositions, unknownTokensInfo, proxyAddress);
    const payload = { account: accountPositions, proxy: proxyPositions };
    // eslint-disable-next-line no-unused-expressions
    dispatch({ type: GET_UNIV3_NFT_SUCCESS, payload });
    return payload;
  } catch (error) {
    console.log(error);
    // eslint-disable-next-line no-unused-expressions
    dispatch({ type: GET_UNIV3_NFT_FAILURE, payload: error.message });
    captureException(error);
  }
};

export const approveUniNftIfNeeded = (spender, nftId, txObject, txsToExecute) => async (dispatch, getState) => {
  const contract = UniV3NonfungiblePositionManagerContract();
  const approvedAddress = await contract.methods.getApproved(nftId).call();
  const approved = approvedAddress === spender;
  if (!approved) {
    txsToExecute.push(txObject);
    return true;
  }
  return false;
};

export const approveUniNftAction = (spender, nftId, notifMessage) => async (dispatch, getState) => {
  requireAddress(spender);
  const { account, accountType, path } = getState().general;
  dispatch({ type: APPROVE_NFT_ON_ADDRESS_REQUEST });
  const sendTxFunc = (promise, waitForSign) => sendTx(promise, notifMessage, '', dispatch, getState, {}, waitForSign);
  try {
    await approveUniNft(accountType, path, sendTxFunc, account, spender, nftId);
    dispatch({ type: APPROVE_NFT_ON_ADDRESS_SUCCESS });
  } catch (err) {
    dispatch({ type: APPROVE_NFT_ON_ADDRESS_FAILURE, payload: err.message });
    captureException(err);

    // do not remove - this is here to stop any methods from executing after this one
    throw new Error(err);
  }
};

export const getPoolAction = (firstToken, secondToken, _fee) => async (dispatch, getState) => {
  dispatch({ type: UNISWAP_V3_POOL_REQUEST });
  try {
    const [pool, poolAddress] = await getPool(firstToken, secondToken, _fee);
    dispatch({ type: UNISWAP_V3_POOL_SUCCESS, payload: { pool } });
    return [pool, poolAddress];
  } catch (err) {
    dispatch({ type: UNISWAP_V3_POOL_FAILURE });
  }
};

export const getPoolActionCached = memoize(getPoolAction, { maxAge: 2 * 60 * 1000, promise: true });

export const mockPositionAction = (baseAsset, quoteAsset, baseAssetInfo, quoteAssetInfo, tickLower, tickUpper, pool, amount, isFirst) => (dispatch, getState) => {
  dispatch({ type: UNISWAP_V3_MOCK_POSITION_REQUEST });
  try {
    const position = mockPosition(baseAsset, quoteAsset, baseAssetInfo, quoteAssetInfo, tickLower, tickUpper, pool, amount, isFirst);
    dispatch({ type: UNISWAP_V3_MOCK_POSITION_SUCCESS });
    return position;
  } catch (err) {
    dispatch({ type: UNISWAP_V3_MOCK_POSITION_FAILURE });
  }
};

export const mockPoolAction = (firstAsset, secondAsset, firstAssetInfo, secondAssetInfo, _fee, price, invert) => (dispatch, getState) => {
  dispatch({ type: UNISWAP_V3_MOCK_POOL_REQUEST });
  try {
    const pool = mockPool(firstAsset, secondAsset, firstAssetInfo, secondAssetInfo, _fee, price, invert);
    dispatch({ type: UNISWAP_V3_MOCK_POOL_SUCCESS });
    return pool;
  } catch (err) {
    dispatch({ type: UNISWAP_V3_MOCK_POOL_FAILURE });
  }
};
