import Dec from 'decimal.js';
import { get0xPrice } from './0xServiceV3';
import { getScpPrice } from './scpServiceV3';
import { ZERO_ADDRESS } from '../constants/general';
import { isAddress } from './utils';
import { getNetwork } from './ethService';

export const getOffchainEmptyData = () => ({
  wrapper: ZERO_ADDRESS,
  to: ZERO_ADDRESS,
  allowanceTarget: ZERO_ADDRESS,
  price: '0',
  protocolFee: '0',
  data: '0x00',
  value: '0',
  gas: 0,
});

/**
 * Get offchain prices from API
 *
 * @param sellToken {String}
 * @param buyToken {String}
 * @param amount {String}
 * @param convertAmountToWei {Boolean} should amount be converted to wei
 * @param infoOnly {Boolean} should be true if just for showing price
 * @param takerAddress {String} address that will take order from 0x
 * @param slippagePercentage {Number} percentage of slippage limit in format 0.03 (this is 3%, fixed to 2 decimal places)
 * @param shouldSell {Boolean} look for price to sell or to buy (if false sellToken becomes becomes buyToken and vice-versa)
 * @param _excludeSources {array} exclude liquidity sources from 0x
 * @return {{data: string, price: string, protocolFee: string, to: string, value: string, source: string, liquiditySources: array}}
 */
export const getOffchainPrice = async (sellToken, buyToken, amount, convertAmountToWei = true, infoOnly = false, takerAddress = '0x0000000000000000000000000000000000000001', slippagePercentage = 0.03, shouldSell = true, _excludeSources = []) => {
  let source = '';
  if (sellToken === buyToken) {
    return { source, ...getOffchainEmptyData() };
  }

  try {
    const network = await getNetwork();

    if (network === 42161) return { source, ...getOffchainEmptyData() };

    const promiseToResolve = [
      get0xPrice(sellToken, buyToken, amount, convertAmountToWei, true, takerAddress, slippagePercentage, shouldSell, _excludeSources, network),
    ];

    if (network === 1 && !isAddress(sellToken) && !isAddress(buyToken)) {
      promiseToResolve.push(getScpPrice(sellToken, buyToken, amount, convertAmountToWei, true, takerAddress, slippagePercentage, shouldSell));
    }

    const [zeroxData, scpData] = await Promise.all(promiseToResolve);

    if (new Dec(zeroxData.price).gte(scpData?.price || 0)) {
      const filterAndSortLiqSources = (ls) => ls?.filter(({ proportion }) => new Dec(proportion || 0).gt(0)).sort((a, b) => b.proportion - a.proportion) || [];
      source = '0x';
      if (!infoOnly) {
        return {
          source,
          liquiditySources: filterAndSortLiqSources(zeroxData.sources),
          ...(await get0xPrice(sellToken, buyToken, amount, convertAmountToWei, false, takerAddress, slippagePercentage, shouldSell, _excludeSources, network)),
        };
      }

      return {
        source,
        ...zeroxData,
        liquiditySources: filterAndSortLiqSources(zeroxData.sources),
      };
    }

    if (!scpData) return { source, ...(getOffchainEmptyData()) };

    source = 'SCP';
    if (network === 1 && !infoOnly) {
      return {
        source,
        ...(await getScpPrice(sellToken, buyToken, amount, convertAmountToWei, false, takerAddress, slippagePercentage, shouldSell)),
      };
    }

    return { source, ...scpData };
  } catch (e) {
    return { source, ...(getOffchainEmptyData()) };
  }
};
