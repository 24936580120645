import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formatNumber } from '../../../../services/utils';

const FeesMade = ({ fees, assetData }) => (
  <>
    <div className="recipe-success-modal-subtitle">Fees:</div>
    <ul className="fees-made-list">
      {fees.map(({ feeAsset, feeAmount, type }) => {
        if (type === 'service') {
          if (feeAsset === '' || feeAmount === 0) { return ''; }
          const dollarVal = feeAmount * assetData[feeAsset].marketPrice;
          return <li key={feeAsset}>Service fee: ${formatNumber(dollarVal)} ({formatNumber(feeAmount)} {feeAsset})</li>;
        }
        if (type === 'aaveFL') {
          if (feeAsset === '' || feeAmount === 0) { return ''; }
          const dollarVal = feeAmount * assetData[feeAsset].marketPrice;
          return <li key={feeAsset}>Flash loan fee: ${formatNumber(dollarVal)} ({formatNumber(feeAmount)} {feeAsset})</li>;
        }
        return <></>;
      })}
    </ul>
  </>
);

FeesMade.propTypes = {
  fees: PropTypes.array.isRequired,
  assetData: PropTypes.object.isRequired,
};
const mapStateToProps = ({ assets }) => ({ assetData: assets });
const mapDispatchToProps = {

};
export default connect(mapStateToProps, mapDispatchToProps)(FeesMade);
