import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getMonitorUpgradeEvents } from '../../services/makerServices/makerSaverMcdService';
import {
  formatEtherScanLink, formatNumber, getRatioForPrice,
  numberWithCommas,
  parseTimestamp,
  timestampToTime,
} from '../../services/utils';

import InitIcon from './InitIcon.svg';
import RevertIcon from './RevertIcon.svg';
import CancelIcon from './CancelIcon.svg';
import SuccessIcon from './SuccessIcon.svg';

import './AutomationStats.scss';
import { getAutomationStats } from '../../services/makerServices/makerStatsService';
import DataItem from '../DataItem/DataItem';
import t from '../../i18n/i18n';
import Arrow from '../Decorative/Arrow';

// eslint-disable-next-line camelcase
const tmp_EVENT_DESCRIPTIONS = {
  ['0xa01083b6aba78afe350717fe204eae700c5eade847db098bf3d25b185d3bbc14'.toLowerCase()]: 'WBTC Automation added.',
  ['0x35437af0c9c49a246c94a451093d7d180a335499b7a5d76d140e0862f88db162'.toLowerCase()]: 'Testing revert mechanism before launch (revert).',
  ['0xb141c5762d928c46c2d18f8b7863029ce591da7f27e724613a1347ecf62823a4'.toLowerCase()]: 'Testing revert mechanism before launch (deploy).',
  ['0x72ee78f1b7cd6c926ae88f73f61f5d97bd2dc3789cc0728354782cade9f709b7'.toLowerCase()]: 'Initial Automation v2 deploy',
};

// eslint-disable-next-line camelcase
const getEventDescription = event => tmp_EVENT_DESCRIPTIONS[event.transactionHash?.toLowerCase()];

/* eslint-disable react/prop-types */
const InitEvent = ({ event }) => (
  <tr>
    <td><img src={InitIcon} alt="" /> Initiated</td>
    <td>{parseTimestamp(event.timestamp)}</td>
    <td>
      New monitor: <a target="_blank" rel="noopener noreferrer" href={formatEtherScanLink(event.returnValues.newMonitor, 'address')}>{event.returnValues.newMonitor}</a>
      { getEventDescription(event) && (<p>{getEventDescription(event)}</p>) }
    </td>
  </tr>
);
const RevertEvent = ({ event }) => (
  <tr>
    <td><img src={RevertIcon} alt="" /> Reverted</td>
    <td>{parseTimestamp(event.timestamp)}</td>
    <td>
      Reverted to previous monitor.
      { getEventDescription(event) && (<p>{getEventDescription(event)}</p>) }
    </td>
  </tr>
);
const CancelEvent = ({ event }) => (
  <tr>
    <td><img src={CancelIcon} alt="" /> Cancelled</td>
    <td>{parseTimestamp(event.timestamp)}</td>
    <td>
      Change cancelled.
      { getEventDescription(event) && (<p>{getEventDescription(event)}</p>) }
    </td>
  </tr>
);
const FinishEvent = ({ event }) => (
  <tr>
    <td><img src={SuccessIcon} alt="" /> Successful</td>
    <td>{parseTimestamp(event.timestamp)}</td>
    <td>
      New monitor: <a target="_blank" rel="noopener noreferrer" href={formatEtherScanLink(event.returnValues.monitor, 'address')}>{event.returnValues.monitor}</a>
      { getEventDescription(event) && (<p>{getEventDescription(event)}</p>) }
    </td>
  </tr>
);

const Event = ({ event }) => {
  const componentMap = {
    MonitorChangeInitiated: InitEvent,
    MonitorChangeReverted: RevertEvent,
    MonitorChangeFinished: FinishEvent,
    MonitorChangeCanceled: CancelEvent,
  };
  const component = componentMap[event.event];
  return component ? component({ event }) : <div>{event.event}</div>;
};

const AutomationStats = () => {
  const [upgradeEvents, setEvents] = useState([]);
  const [changelog, setChangelog] = useState([]);
  const [stats, setStats] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => { getMonitorUpgradeEvents().then(setEvents); }, []);
  useEffect(() => {
    getAutomationStats().then(({ stats, changelog }) => {
      setChangelog(changelog);
      setStats(stats);
      setLoading(false);
    });
  }, []);

  const events = useMemo(() => upgradeEvents.map(e => ({ ...e, description: changelog[e.transactionHash.toLowerCase()] })), [upgradeEvents, changelog]);

  return (
    <div className="dashboard-page-wrapper automation-stats-wrapper">
      <div className="content-wrapper">
        <div className="width-container">
          <h1>{t('misc.automation_stats')}</h1>
          <div className="stats-wrapper">
            <div>
              <DataItem
                gettingValue={loading}
                label={t('misc.positions_subbed')}
                value={stats.totalSubs}
                symbol=""
                smallSymbol
                decimals={0}
              />
              <DataItem
                gettingValue={loading}
                label={`USD ${t('misc.total')}`}
                value={stats.totalUSD}
                symbol="$"
                symbolAfter={false}
              />
            </div>
            <div>
              <DataItem
                type="standard-smaller"
                label={`ETH ${t('misc.total')}`}
                value={stats?.assetsAmount?.ETH}
                valueLabel=" ETH"
              />
              <DataItem
                type="standard-smaller"
                label={`BAT ${t('misc.total')}`}
                value={stats?.assetsAmount?.BAT}
                valueLabel=" BAT"
              />
              <DataItem
                type="standard-smaller"
                label={`WBTC ${t('misc.total')}`}
                value={stats?.assetsAmount?.WBTC}
                valueLabel=" WBTC"
              />
            </div>
          </div>
          <div className="dfe-link">
            <a href="https://defiexplore.com/stats" target="_blank" rel="noopener noreferrer">
              {t('misc.explore_maker_stats')} <Arrow to="right" size={16} color="#939DA7" />
            </a>
          </div>

          <h1>{t('misc.automation_contract_upgrades')}</h1>
          <div className="table-wrapper">
            <table border={0} cellSpacing={0}>
              <tr>
                <th>Event</th>
                <th>Timestamp</th>
                <th>Description</th>
              </tr>
              { events.map(event => <Event event={event} />) }
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

AutomationStats.propTypes = {};

AutomationStats.defaultProps = {};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AutomationStats);
