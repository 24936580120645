import { change } from 'redux-form';
import t from 'translate';
import { captureException } from 'sentry';
import memoize from 'memoizee';
import { getBestExchangePrice as getBestExchangePriceV3, exchange } from 'services/exchangeServiceV3';
import Dec from 'decimal.js';
import cloneDeep from 'lodash/cloneDeep';
import { getAssetInfo } from '@defisaver/tokens';
import { callMultipleTxAction, sendTx } from './txNotificationActions';
import { getThresholdAmountForAsset, setSlippagePercent } from '../services/exchangeServiceCommon';
import {
  approveTypeToLabelMap,
  getAssetBalanceAction,
  addApproveTxIfNeeded,
  approveAddressOnAssetAction, getAssetPriceAction,
} from './assetsActions';
import { trackEvent } from '../services/analyticsService';
import { APPROVE_TYPE_DS_PROXY } from '../actionTypes/assetsActionTypes';
import { DEFAULT_SLIPPAGE_PERCENT } from '../constants/general';
import { formatAcc, formatNumber } from '../services/utils';
import { changeBalance } from '../services/recipeCreator/recipeActionUtils';
import { createDSProxyAction } from './makerActions/makerActions';
import { parseSwapsFromReceipts } from '../services/recipeCreator/recipeSuccessService';
import { sendConfirmViaModal } from './modalActions';

const getExchangePriceV3Cached = memoize(getBestExchangePriceV3, { promise: true, maxAge: 2 * 60 * 1000, max: 1 });

export const exchangeFunds = (amount, from, to, price, _slippage, sendReceivedToken, _sendTo) => async (dispatch, getState) => {
  trackEvent('exchange', 'exchange');
  dispatch(change('exchangeForm', 'success', false));
  dispatch(change('exchangeForm', 'exchanging', true));
  dispatch(change('exchangeForm', 'error', ''));

  const slippage = _slippage || DEFAULT_SLIPPAGE_PERCENT;

  try {
    let sendToAddress = '';

    const receivedAmount = price * amount;

    if (sendReceivedToken) {
      sendToAddress = await dispatch(sendConfirmViaModal({ sendingTo: _sendTo, token: to, amount: receivedAmount }));
      if (!sendToAddress) throw new Error(t('errors.denied_transaction'));
    }

    const { assets } = getState();
    const { account, accountType, path } = getState().general;
    const { proxyAddress } = getState().maker;

    const additionalInfo = {
      protocol: 'exchange',
      firstToken: from.symbol,
      firstAmount: amount,
      secondToken: to.symbol,
      secondAmount: receivedAmount,
    };
    const notifMessage = t('misc.exchange_for', { '%from': `${formatNumber(amount)} ${from.symbol}`, '%to': `~${formatNumber(receivedAmount)} ${to.symbol}${sendReceivedToken ? ` + Send to: ${formatAcc(sendToAddress, 5, 3)}` : ''}` });
    const sendTxFunc = (promise, waitForSign) => sendTx(promise, notifMessage, '', dispatch, getState, additionalInfo, waitForSign);

    const txToExecute = [];
    const returnValues = [];

    const createProxyFunctionObject = {
      func: () => dispatch(createDSProxyAction()),
      type: 'exchange',
      notifMessage: t('account.create_ds_proxy'),
      checkReturnValue: true,
      returnValueFailureMessage: 'Error fetching Proxy address',
    };

    const approveFunctionObject = {
      func: () => dispatch(approveAddressOnAssetAction(from.symbol, getState().maker.proxyAddress, APPROVE_TYPE_DS_PROXY, from.address)),
      type: 'approve',
      notifMessage: `${t('common.approve')} ${approveTypeToLabelMap[APPROVE_TYPE_DS_PROXY]} ${t('common.for')} ${from.symbol}`,
    };

    if (proxyAddress) {
      await dispatch(addApproveTxIfNeeded(from.symbol, getState().maker.proxyAddress, amount, APPROVE_TYPE_DS_PROXY, txToExecute, approveFunctionObject, from.address));
    } else {
      txToExecute.push(createProxyFunctionObject);
      if (from.symbol !== 'ETH') txToExecute.push(approveFunctionObject);
    }

    txToExecute.push({
      func: () => exchange(
        accountType,
        path,
        sendTxFunc,
        account,
        getState().maker.proxyAddress,
        amount,
        from.address,
        to.address,
        price,
        slippage,
        sendToAddress,
        0,
      ),
      expectReturn: true,
      type: 'exchange',
      notifMessage,
    });

    const preSwapBalances = {
      sendToAddress,
      wasSent: sendReceivedToken,
      sellTokenBeforeBalance: assets[from.symbol].balance,
      buyTokenBeforeBalance: assets[to.symbol].balance,
    };

    await dispatch(callMultipleTxAction(txToExecute, returnValues));

    const swapReceipt = (await parseSwapsFromReceipts([returnValues[returnValues.length - 1]]))[0];

    await dispatch(getAssetBalanceAction(from.symbol, from.address));
    await dispatch(getAssetPriceAction(from.symbol, 'market', from.address));
    await dispatch(getAssetBalanceAction(to.symbol, to.address));
    await dispatch(getAssetPriceAction(to.symbol, 'market', to.address));

    preSwapBalances.sellTokenAfterBalance = getState().assets[from.symbol].balance;
    preSwapBalances.buyTokenAfterBalance = getState().assets[to.symbol].balance;
    preSwapBalances.fromTokenPrice = getState().assets[from.symbol].marketPrice;
    preSwapBalances.toTokenPrice = getState().assets[to.symbol].marketPrice;
    const sell = getAssetInfo(from.symbol);
    const buy = getAssetInfo(to.symbol);
    swapReceipt.sellToken = sell.symbol === '?' ? swapReceipt.sellToken : sell;
    swapReceipt.buyToken = buy.symbol === '?' ? swapReceipt.buyToken : buy;

    dispatch(change('exchangeForm', 'exchangeReceipt', { ...swapReceipt, ...preSwapBalances }));
    dispatch(change('exchangeForm', 'exchanging', false));
    dispatch(change('exchangeForm', 'success', true));
    trackEvent('exchange', 'exchangeSuccess');
    dispatch(change('exchangeForm', 'inputAmount', ''));
  } catch (err) {
    dispatch(change('exchangeForm', 'exchanging', false));
    dispatch(change('exchangeForm', 'error', err.message));
    trackEvent('exchange', 'exchangeError', err.message);
    captureException(err);
  }
};

export const exchangeSellAfterValues = async ({
  amount, firstAsset, secondAsset, source = 'wallet', toSource = 'recipe', slippage,
}, { proxyAddress, account }, _balances = {}) => {
  const balances = cloneDeep(_balances);
  const cached = getExchangePriceV3Cached._has(amount, firstAsset, secondAsset, proxyAddress, true, true, true, true);
  const { price: rate } = await getExchangePriceV3Cached(amount, firstAsset, secondAsset, proxyAddress, true, true, true, true);
  await changeBalance(balances, source, firstAsset, new Dec(amount || '0').mul(-1), source === 'wallet' ? account : proxyAddress);
  const received = new Dec(amount || '0').mul(setSlippagePercent(slippage, rate)).toString();
  await changeBalance(balances, toSource, secondAsset, received, toSource === 'wallet' ? account : proxyAddress);
  return {
    balances,
    returnValue: received,
    cached,
  };
};

export const exchangeBuyAfterValues = async ({
  amount, firstAsset, secondAsset, source = 'wallet', toSource = 'recipe',
}, { proxyAddress, account }, _balances = {}) => {
  const balances = cloneDeep(_balances);
  const cached = getExchangePriceV3Cached._has(getThresholdAmountForAsset(secondAsset), firstAsset, secondAsset, proxyAddress, true, false, true, true);
  const { price: rate } = await getExchangePriceV3Cached(getThresholdAmountForAsset(secondAsset), firstAsset, secondAsset, proxyAddress, true, false, true, true);
  const sold = new Dec(amount || '0').div(rate).toString();
  await changeBalance(balances, source, firstAsset, new Dec(sold === 'NaN' ? '0' : sold || '0').mul(-1), source === 'wallet' ? account : proxyAddress);
  await changeBalance(balances, toSource, secondAsset, amount || '0', toSource === 'wallet' ? account : proxyAddress);
  return {
    balances,
    returnValue: amount,
    cached,
  };
};
