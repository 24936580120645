import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import ConnectWalletButtons from '../ConnectWalletButtons/ConnectWalletButtons';
import { openSimulationStartModal } from '../../../actions/modalActions';
import TrackAddress from '../TrackAddress/TrackAddress';

import './ConnectWallet.scss';

function ConnectWallet({ showTrackOption }) {
  const dispatch = useDispatch();
  return (
    <div className="connect-wallet-wrapper">
      <h2>Connect wallet</h2>
      <p>Manage your positions using advanced actions.</p>
      <ConnectWalletButtons />
      <hr />
      <h2>Start a simulation</h2>
      <p>Test DeFi features in a sandbox, with a starting balance of 100 ETH.</p>
      <button type="button" className="button green" onClick={() => dispatch(openSimulationStartModal())}>
        Start Simulation
      </button>
      <br />
      {
        showTrackOption && (
          <>
            <hr />
            <TrackAddress />
          </>
        )
      }
      <br />
    </div>
  );
}

ConnectWallet.defaultProps = {
  showTrackOption: false,
};

ConnectWallet.propTypes = {
  showTrackOption: PropTypes.bool,
};

export default ConnectWallet;
