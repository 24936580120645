import Dec from 'decimal.js';
import DyDxFlashLoanPaybackAction from '../../recipeActions/flashloan/DyDxFlashLoanPaybackAction';
import AaveV2FlashLoanPaybackAction from '../../recipeActions/flashloan/AaveV2FlashLoanPaybackAction';
import { addInstantiatedAction, reorderActions } from '../recipeCreatorActions';
import SendTokenAction from '../../recipeActions/basic/SendTokenAction';
import UnwrapEthAction from '../../recipeActions/basic/UnwrapEthAction';
import AaveV2FlashLoanAction from '../../recipeActions/flashloan/AaveV2FlashLoanAction';
import DyDxFlashLoanAction from '../../recipeActions/flashloan/DyDxFlashLoanAction';
import MakerFlashLoanAction from '../../recipeActions/flashloan/MakerFlashLoanAction';
import WrapEthAction from '../../recipeActions/basic/WrapEthAction';
import MakerFlashLoanPaybackAction from '../../recipeActions/flashloan/MakerFlashLoanPaybackAction';
import BalancerFlashLoanAction from '../../recipeActions/flashloan/BalancerFlashLoanAction';
import BalancerFlashLoanPaybackAction from '../../recipeActions/flashloan/BalancerFlashLoanPaybackAction';

import { checkActions, isFLAction } from './recipeValidation';

/**
 * Suggest improvements to recipe
 *
 * @param dispatch
 * @param getState
 * @returns {Array<Array<{ message: string, fix: function }>>}
 */
export const checkRecipe = (dispatch, getState) => {
  const {
    general: { account, accountType },
    maker: { proxyAddress },
    recipeCreator: { actions, actionCalls },
  } = getState();
  const recipeSuggestions = [];
  const finalCall = actionCalls[actionCalls.length - 1];

  // Suggest transfering to wallet
  for (const asset of Object.keys(finalCall?.balances?.recipe || {})) {
    if (parseFloat(finalCall.balances.recipe[asset]) > 0 && asset !== 'imUSD') {
      if (parseFloat(finalCall.balances.dydx?.[asset]) < 0 || parseFloat(finalCall.balances.aaveV2?.[asset]) < 0 || parseFloat(finalCall.balances.maker?.[asset]) < 0 || parseFloat(finalCall.balances.balancer?.[asset]) < 0) {
        // If there is a negative FL balance, don't suggest to send them to the account.
      } else if (asset === 'WETH') {
        recipeSuggestions.push({
          action: UnwrapEthAction,
          reason: 'balance',
          message: 'Unwrap remaining WETH from recipe balance to your wallet at the end of the Recipe?',
          fix: () => dispatch(
            addInstantiatedAction(new UnwrapEthAction('All available', account), actions.length),
          ),
        });
      } else if (!(asset === 'stETH' && finalCall.balances.recipe[asset] === '0.000000000000000001')) { // A lot of proxies already have 1 wei stETH
        recipeSuggestions.push({
          action: SendTokenAction,
          reason: 'balance',
          message: `Transfer remaining recipe ${asset} balance back to your wallet at the end of the Recipe?`,
          fix: () => dispatch(
            addInstantiatedAction(new SendTokenAction(asset, 'All available', account), actions.length),
          ),
        });
      }
    }
  }

  // Suggest wrapping ETH
  if (parseFloat(finalCall?.balances?.recipe?.WETH) < 0 || parseFloat(finalCall?.balances?.wallet?.WETH) < 0) {
    const amount = new Dec(finalCall.balances?.recipe?.WETH || finalCall.balances?.wallet?.WETH).abs().toString();
    const firstActionIsFL = isFLAction(actions[0]);
    recipeSuggestions.push({
      action: WrapEthAction,
      reason: 'weth',
      message: 'Wrap ETH from your wallet to use in recipe?',
      fix: () => dispatch(
        addInstantiatedAction(new WrapEthAction(amount), firstActionIsFL ? 1 : 0),
      ),
    });
  }

  // Suggest FL payback
  for (const asset of Object.keys(finalCall?.balances?.dydx || {})) {
    if (parseFloat(finalCall.balances.dydx[asset]) < 0) {
      const flAction = actions.find(a => a.constructor === DyDxFlashLoanAction);
      const amount = flAction
        ? `$${flAction?.id}`
        : new Dec(finalCall.balances.dydx[asset]).abs().toString();
      recipeSuggestions.push({
        action: DyDxFlashLoanPaybackAction,
        reason: 'flashloan',
        message: `Pay dYdX ${asset} flash loan back at the end of the Recipe?`,
        fix: () => dispatch(addInstantiatedAction(new DyDxFlashLoanPaybackAction(asset, amount), actions.length)),
      });
    }
  }
  for (const asset of Object.keys(finalCall?.balances?.aaveV2 || {})) {
    if (parseFloat(finalCall.balances.aaveV2[asset]) < 0) {
      const flAction = actions.find(a => a.constructor === AaveV2FlashLoanAction);
      const amount = flAction
        ? `$${flAction?.id}`
        : new Dec(finalCall.balances.aaveV2[asset]).abs().toString();
      recipeSuggestions.push({
        action: AaveV2FlashLoanPaybackAction,
        reason: 'flashloan',
        message: `Pay Aave V2 ${asset} flash loan back at the end of the Recipe?`,
        fix: () => dispatch(addInstantiatedAction(new AaveV2FlashLoanPaybackAction(asset, amount), actions.length)),
      });
    }
  }
  // Suggest Maker FL payback
  if (parseFloat(finalCall?.balances?.maker?.DAI) < 0) {
    const flAction = actions.find(a => a.constructor === MakerFlashLoanAction);
    const amount = flAction
      ? `$${flAction?.id}`
      : new Dec(finalCall.balances.maker.DAI).abs().toString();
    recipeSuggestions.push({
      action: MakerFlashLoanPaybackAction,
      reason: 'flashloan',
      message: 'Pay Maker DAI flash loan back at the end of the Recipe?',
      fix: () => dispatch(addInstantiatedAction(new MakerFlashLoanPaybackAction(amount), actions.length)),
    });
  }
  // Suggest Balancer FL payback
  for (const asset of Object.keys(finalCall?.balances?.balancer || {})) {
    if (parseFloat(finalCall.balances.balancer[asset]) < 0) {
      const flAction = actions.find(a => a.constructor === BalancerFlashLoanAction);
      const amount = flAction
        ? `$${flAction?.id}`
        : new Dec(finalCall.balances.balancer[asset]).abs().toString();
      recipeSuggestions.push({
        action: BalancerFlashLoanPaybackAction,
        reason: 'flashloan',
        message: `Pay Balancer ${asset} flash loan back at the end of the Recipe?`,
        fix: () => dispatch(addInstantiatedAction(new BalancerFlashLoanPaybackAction(asset, amount), actions.length)),
      });
    }
  }
  actions.forEach((action, i) => {
    // Move FL to top
    if (i !== 0 && isFLAction(action)) {
      recipeSuggestions.push({
        action: action.constructor,
        reason: 'order',
        message: 'Flashloan must be the first action in a Recipe. Move it to the top?',
        fix: () => dispatch(
          reorderActions({ source: { index: i, droppableId: 'recipe-actions' }, destination: { index: 0 } }),
        ),
      });
    }
  });

  return recipeSuggestions;
};

export const validateActions = () => async (dispatch, getState) => {
  const {
    recipeCreator: { actions, actionCalls },
    assets,
  } = getState();
  const recipeErrors = [];
  const actionsErrors = checkActions(actions, actionCalls, assets, false);
  const recipeSuggestions = checkRecipe(dispatch, getState);

  dispatch({
    type: 'RC_VALIDATION_SUCCESS',
    payload: { recipeErrors, actionsErrors, recipeSuggestions },
  });
};
