import Dec from 'decimal.js';
import dfs, { Recipe } from '@defisaver/sdk';
import { assetAmountInWei, getAssetInfo, MAXUINT } from '@defisaver/tokens';
import { getExchangeOrder } from 'services/exchangeServiceV3';
import { ZERO_ADDRESS } from '../constants/general';
import { addToArrayIf, ethToWeth, requireAddress } from '../services/utils';
import { getFLAction } from '../services/assetsService';
import { callRecipeViaProxy } from '../services/contractCallService';
import { getCollAssetsHelperArrays, getRecipeDebtAssetsWithInfoArray } from '../services/aaveServices/aaveMigrateService';
import config from '../config/config.json';
import { multicall } from '../services/multicallService';

/**
 * Standard Aave Boost
 */
export const boostV3 = async (_collAsset, collId, _debtAsset, debtId, debtAmount, selectedMarket, rate, price, slippagePercent, proxyAddress, additionalActions) => {
  const marketAddress = selectedMarket.providerAddress;
  const useDefaultMarket = selectedMarket.value === 'v3default';
  const collAsset = ethToWeth(_collAsset);
  const collAddress = getAssetInfo(collAsset).address;
  const debtAsset = ethToWeth(_debtAsset);
  const debtAmountWei = assetAmountInWei(debtAmount, debtAsset);

  const recipe = new dfs.Recipe('recAaveV3Boost', [
    new dfs.actions.aaveV3.AaveV3BorrowAction(useDefaultMarket, marketAddress, debtAmountWei, proxyAddress, rate, debtId, false),
  ]);

  additionalActions.forEach((action) => recipe.addAction(action));

  if (collAsset !== debtAsset) {
    const {
      orderData,
      value,
      extraGas,
    } = await getExchangeOrder(debtAsset, collAsset, debtAmount, price, slippagePercent, proxyAddress, false, false, true);
    recipe.addAction(new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value));
    const previousActionId = `$${recipe.actions.length}`;
    recipe.addAction(new dfs.actions.aaveV3.AaveV3SupplyAction(previousActionId, proxyAddress, collAddress, collId, true, useDefaultMarket, false, marketAddress));
    recipe.extraGas = extraGas;
  } else {
    const previousActionId = `$${recipe.actions.length}`;
    recipe.addAction(new dfs.actions.aaveV3.AaveV3SupplyAction(previousActionId, proxyAddress, collAddress, collId, true, useDefaultMarket, false, marketAddress));
  }
  return recipe;
};

/**
 * Aave traditional FL Boost
 * @returns {Promise<Recipe>}
 */
export const boostWithDebtLoanV3 = async (_collAsset, collId, _debtAsset, debtId, debtAmount, selectedMarket, rate, price, slippagePercent, proxyAddress, flProtocol, additionalActions) => {
  const marketAddress = selectedMarket.providerAddress;
  const useDefaultMarket = selectedMarket.value === 'v3default';

  const collAsset = ethToWeth(_collAsset);
  const collAddress = getAssetInfo(collAsset).address;
  const debtAsset = ethToWeth(_debtAsset);

  const debtAmountWei = assetAmountInWei(debtAmount, debtAsset);

  const { FLAction, paybackAddress } = getFLAction(flProtocol, debtAmountWei, debtAsset);

  const recipe = new dfs.Recipe('recAaveV3FLBoost', [FLAction]);

  additionalActions.forEach((action) => recipe.addAction(action));
  if (collAsset !== debtAsset) {
    const { orderData, value, extraGas } = await getExchangeOrder(debtAsset, collAsset, debtAmount, price, slippagePercent, proxyAddress, false, false, true, flProtocol === 'balancer' ? ['Balancer_V2'] : []);
    recipe.addAction(new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value));
    const previousActionId = `$${recipe.actions.length}`;
    recipe.addAction(new dfs.actions.aaveV3.AaveV3SupplyAction(previousActionId, proxyAddress, collAddress, collId, true, useDefaultMarket, false, marketAddress));

    recipe.extraGas = extraGas;
  } else {
    recipe.addAction(new dfs.actions.aaveV3.AaveV3SupplyAction(debtAmountWei, proxyAddress, collAddress, collId, true, useDefaultMarket, false, marketAddress));
  }
  recipe.addAction(new dfs.actions.aaveV3.AaveV3BorrowAction(useDefaultMarket, marketAddress, '$1', paybackAddress, rate, debtId, false));

  return recipe;
};

/**
 * Standard Aave Repay
 */
export const repayV3 = async (_collAsset, collId, _debtAsset, debtId, collAmount, selectedMarket, rate, price, slippagePercent, proxyAddress, account, supplied, borrowed, additionalActions) => {
  const marketAddress = selectedMarket.providerAddress;
  const useDefaultMarket = selectedMarket.value === 'v3default';
  const collAsset = ethToWeth(_collAsset);
  const debtAsset = ethToWeth(_debtAsset);
  const debtAddress = getAssetInfo(debtAsset).address;

  const payingBackAllDebt = new Dec(collAmount * price).gte(borrowed);
  const sellingAllSupplied = supplied === collAmount;
  const differentAssets = collAsset !== debtAsset;

  const collAmountWei = sellingAllSupplied ? MAXUINT : assetAmountInWei(collAmount, collAsset);

  const recipe = new dfs.Recipe('recAaveV3Repay', [
    new dfs.actions.aaveV3.AaveV3WithdrawAction(collId, useDefaultMarket, collAmountWei, proxyAddress, marketAddress),
  ]);

  if (differentAssets) {
    const { orderData, value, extraGas } = await getExchangeOrder(collAsset, debtAsset, collAmount, price, slippagePercent, proxyAddress, false, false, true);
    if (sellingAllSupplied) orderData[2] = '$1';
    recipe.addAction(new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value));
    recipe.addAction(new dfs.actions.aaveV3.AaveV3PaybackAction(useDefaultMarket, marketAddress, '$2', proxyAddress, rate, debtAddress, debtId, false));

    recipe.extraGas = extraGas;
  } else {
    recipe.addAction(new dfs.actions.aaveV3.AaveV3PaybackAction(useDefaultMarket, marketAddress, '$1', proxyAddress, rate, debtAddress, debtId, false));
  }

  // AavePaybackAction will pay back an amount up to the total debt, and leave the rest on the proxy
  if (payingBackAllDebt) {
    recipe.addAction(
      debtAsset === 'WETH'
        ? new dfs.actions.basic.UnwrapEthAction(MAXUINT, account)
        : new dfs.actions.basic.SendTokenAction(getAssetInfo(debtAsset).address, account, MAXUINT),
    );
  }
  additionalActions.forEach((a) => recipe.addAction(a));
  return recipe;
};

export const repayV3WithCollLoan = async (_collAsset, collId, _debtAsset, debtId, collAmount, selectedMarket, rate, price, slippagePercent, proxyAddress, account, flProtocol, borrowed, maxWithdraw, additionalActions) => {
  const marketAddress = selectedMarket.providerAddress;
  const useDefaultMarket = selectedMarket.value === 'v3default';
  const collAsset = ethToWeth(_collAsset);
  const debtAsset = ethToWeth(_debtAsset);
  const debtAddress = getAssetInfo(debtAsset).address;
  const collAmountWei = assetAmountInWei(collAmount, collAsset);

  const payingBackAllDebt = new Dec(collAmount * price).gte(borrowed);
  const differentAssets = collAsset !== debtAsset;

  const maxWithdrawWei = assetAmountInWei(maxWithdraw, collAsset);
  const useAave = flProtocol === 'aaveV3';
  const recipe = new dfs.Recipe('recAaveV3FLRepay', []);
  let flPaybackAddr;

  if (useAave && new Dec(maxWithdrawWei).gt(0)) {
    // Aave flashloans have a fee, so we minimize the loan amount/fee, at the cost of gas
    const flAmount = new Dec(collAmountWei).sub(maxWithdrawWei).toString();
    const { FLAction, paybackAddress } = getFLAction(flProtocol, flAmount, collAsset);
    flPaybackAddr = paybackAddress;
    recipe.addAction(FLAction);

    recipe.addAction(new dfs.actions.aaveV3.AaveV3WithdrawAction(collId, useDefaultMarket, maxWithdrawWei, proxyAddress, marketAddress));
  } else {
    // other flashloans are free, so we FL the full amount
    const { FLAction, paybackAddress } = getFLAction(flProtocol, collAmountWei, collAsset);
    flPaybackAddr = paybackAddress;
    recipe.addAction(FLAction);
  }

  if (differentAssets) {
    const { orderData, value, extraGas } = await getExchangeOrder(collAsset, debtAsset, collAmount, price, slippagePercent, proxyAddress, false, false, true, flProtocol === 'balancer' ? ['Balancer_V2'] : []);
    recipe.addAction(new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value));
    const previousActionId = `$${recipe.actions.length}`;
    recipe.addAction(new dfs.actions.aaveV3.AaveV3PaybackAction(useDefaultMarket, marketAddress, previousActionId, proxyAddress, rate, debtAddress, debtId, false));

    recipe.extraGas = extraGas;
  } else {
    recipe.addAction(new dfs.actions.aaveV3.AaveV3PaybackAction(useDefaultMarket, marketAddress, collAmountWei, proxyAddress, rate, debtAddress, debtId, false));
  }
  recipe.addAction(new dfs.actions.aaveV3.AaveV3WithdrawAction(collId, useDefaultMarket, '$1', flPaybackAddr, marketAddress));


  // AavePaybackAction will pay back an amount up to the total debt, and leave the rest on the proxy
  if (payingBackAllDebt) {
    recipe.addAction(
      debtAsset === 'WETH'
        ? new dfs.actions.basic.UnwrapEthAction(MAXUINT, account)
        : new dfs.actions.basic.SendTokenAction(getAssetInfo(debtAsset).address, account, MAXUINT),
    );
  }
  additionalActions.forEach((a) => recipe.addAction(a));
  return recipe;
};

/**
 *
 * @param accountType
 * @param sendTxFunc
 * @param account
 * @param proxyAddress
 * @param selectedMarket
 * @param collAsset
 * @param collId
 * @param debtAsset
 * @param debtId
 * @param collAmount
 * @param debtAmount
 * @param minPrice
 * @param borrowInterestRateMode
 * @param slippage
 * @param useFl
 * @param leveraged
 * @param flProtocol
 * @return {Promise<*>}
 */
export const openAaveV3Position = async (
  accountType, sendTxFunc, account, proxyAddress, selectedMarket, collAsset, collId, debtAsset, debtId, collAmount, debtAmount, borrowInterestRateMode, minPrice, slippage, useFl, leveraged, flProtocol,
) => {
  requireAddress(proxyAddress);
  const marketAddress = selectedMarket.providerAddress;
  const useDefaultMarket = selectedMarket.value === 'v3default';

  const isSameAssetPosition = collAsset === debtAsset;

  const collAmountWei = assetAmountInWei(collAmount.toString(), collAsset);
  const debtAmountWei = assetAmountInWei(debtAmount.toString(), debtAsset);

  const collAssetAddress = getAssetInfo(ethToWeth(collAsset)).address;

  const isEthColl = collAsset === 'ETH';
  const isEthDebt = debtAsset === 'ETH';

  const getTokenAction = isEthColl
    ? new dfs.actions.basic.WrapEthAction(collAmountWei)
    : new dfs.actions.basic.PullTokenAction(getAssetInfo(collAsset).address, account, collAmountWei);

  let actions;
  if (leveraged) {
    const { orderData, value } = !isSameAssetPosition
      ? await getExchangeOrder(ethToWeth(debtAsset), ethToWeth(collAsset), debtAmount.toString(), minPrice, slippage, account, false, false, true)
      : ({ orderData: [], value: '0' });

    if (useFl) {
      const flAmountWei = assetAmountInWei(debtAmount, ethToWeth(debtAsset));

      if (flProtocol === 'none') throw new Error('Cannot create Aave position, flashloan not available.');

      const { FLAction, paybackAddress } = getFLAction(flProtocol, flAmountWei, ethToWeth(debtAsset));

      actions = [
        FLAction,
        getTokenAction,
        ...addToArrayIf(!isSameAssetPosition,
          new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value),
        ),
        new dfs.actions.aaveV3.AaveV3SupplyAction(MAXUINT, proxyAddress, collAssetAddress, collId, true, useDefaultMarket, false, marketAddress),
        new dfs.actions.aaveV3.AaveV3BorrowAction(useDefaultMarket, marketAddress, '$1', paybackAddress, borrowInterestRateMode, debtId, false),
      ];
    } else {
      actions = [
        ...addToArrayIf(isEthColl, new dfs.actions.basic.WrapEthAction(collAmountWei)),
        new dfs.actions.aaveV3.AaveV3SupplyAction(collAmountWei, isEthColl ? proxyAddress : account, collAssetAddress, collId, true, useDefaultMarket, false, marketAddress),
        new dfs.actions.aaveV3.AaveV3BorrowAction(useDefaultMarket, marketAddress, debtAmountWei, proxyAddress, borrowInterestRateMode, debtId, false),
        ...addToArrayIf(!isSameAssetPosition, new dfs.actions.basic.SellAction(orderData, proxyAddress, proxyAddress, value)),
        new dfs.actions.aaveV3.AaveV3SupplyAction(MAXUINT, proxyAddress, collAssetAddress, collId, true, useDefaultMarket, false, marketAddress),
      ];
    }
  } else {
    actions = [
      ...addToArrayIf(isEthColl, new dfs.actions.basic.WrapEthAction(collAmountWei)),
      new dfs.actions.aaveV3.AaveV3SupplyAction(collAmountWei, isEthColl ? proxyAddress : account, collAssetAddress, collId, true, useDefaultMarket, false, marketAddress),
      new dfs.actions.aaveV3.AaveV3BorrowAction(useDefaultMarket, marketAddress, debtAmountWei, isEthDebt ? proxyAddress : account, borrowInterestRateMode, debtId, false),
      ...addToArrayIf(isEthDebt, new dfs.actions.basic.UnwrapEthAction(MAXUINT, account)),
    ];
  }
  const recipe = new Recipe(`AaveV3Open${leveraged ? 'Leveraged' : ''}Recipe`, actions);
  console.log(recipe);
  return callRecipeViaProxy(accountType, sendTxFunc, proxyAddress, account, recipe);
};

/**
 * Creates recipe for migrating instadapp or EOA position to DSProxy
 *
 * @param totalDebtInDai {number} - USD debt
 * @param debtAssets {Array.<{symbol:string,interestMode:string}>} - Array of debt assets in instadapp position
 * @param _collAssets {Array.<{symbol:string,collateral:boolean}>} - Array of collateral assets where symbol is asset's symbol, and collateral describes whether asset is enabled as collateral
 * @param market {object} - Aave market object
 * @param account {string} - User wallet address
 * @param proxyAccount {string} - User DSProxy address
 * @param onBehalfOf {string} - On behalf of DSA or EOA
 * @param isDSA {boolean}
 * @return {Recipe}
 */
export const migrateV3 = async (totalDebtInDai, debtAssets, _collAssets, market, account, proxyAccount, onBehalfOf, isDSA = false) => {
  // TODO => there is no Balancer on Optimism yet (or any other free FL option afaik) hence we can't do migration
  // const flAmountWei = assetAmountInWei(new Dec(totalDebtInDai).mul(2), 'DAI');
  // const flAssetAddress = getAssetInfo('DAI').address;
  // // we don't have sell here so it's fine to use Balancer
  // const flPaybackAddr = dfs.actionAddresses().FLBalancer;
  // const flActionNumber = 1;
  // const { aCollAssets, collEnabledAssets } = await getCollAssetsHelperArrays(_collAssets, market);
  //
  // const recipe = new dfs.Recipe(`recAaveMigrateFrom${isDSA ? 'Instadapp' : 'EOA'}`, []);
  // if (totalDebtInDai > 0) {
  //   const getDebtActionNumberOffset = 2;
  //   const debtAssetsWithInfo = (await getRecipeDebtAssetsWithInfoArray(debtAssets, market)).map((data, index) => ({
  //     ...data,
  //     getDebtActionNumber: getDebtActionNumberOffset + index,
  //   }));
  //
  //   recipe.addAction(new dfs.actions.flashloan.BalancerFlashLoanAction([flAssetAddress], [flAmountWei], ZERO_ADDRESS, []));
  //   debtAssetsWithInfo.forEach(({ debtAssetAddress }) => {
  //     recipe.addAction(new dfs.actions.basic.TokenBalanceAction(debtAssetAddress, onBehalfOf));
  //   });
  //   recipe.addAction(new dfs.actions.aave.AaveSupplyAction(market.providerAddress, flAssetAddress, flAmountWei, proxyAccount, proxyAccount, true));
  //
  //   debtAssetsWithInfo.forEach(({ rateMode, getDebtActionNumber, assetAddress }) => {
  //     recipe.addAction(new dfs.actions.aave.AaveBorrowAction(market.providerAddress, assetAddress, `$${getDebtActionNumber}`, rateMode, proxyAccount, proxyAccount));
  //     recipe.addAction(new dfs.actions.aave.AavePaybackAction(market.providerAddress, assetAddress, `$${getDebtActionNumber}`, rateMode, proxyAccount, onBehalfOf));
  //   });
  // }
  //
  // const assetAmountsToPullFromDsa = aCollAssets.map(() => MAXUINT);
  // if (isDSA) {
  //   recipe.addAction(new dfs.actions.insta.InstPullTokensAction(onBehalfOf, aCollAssets, assetAmountsToPullFromDsa, proxyAccount));
  // } else {
  //   aCollAssets.forEach((aToken) => {
  //     recipe.addAction(new dfs.actions.basic.PullTokenAction(aToken, onBehalfOf, MAXUINT));
  //   });
  // }
  // recipe.addAction(new dfs.actions.aave.AaveCollateralSwitchAction(market.providerAddress, collEnabledAssets, collEnabledAssets.map(() => true)));
  //
  // if (totalDebtInDai > 0) {
  //   recipe.addAction(new dfs.actions.aave.AaveWithdrawAction(market.providerAddress, flAssetAddress, `$${flActionNumber}`, flPaybackAddr));
  // }
  // return recipe;
};
