export const SUPPORTED_LANGUAGES = [
  { value: 'en', label: 'Eng' },
  { value: 'tc', label: '中文' },
  { value: 'es', label: 'Esp' },
];

export const SUPPORTED_LANGUAGES_FULL = [
  { value: 'en', label: 'English', abrLabel: 'En' },
  { value: 'tc', label: '中文', abrLabel: 'Tc' },
  { value: 'es', label: 'Español', abrLabel: 'Es' },
];

export const getLangOption = () => {
  const lang = localStorage.getItem('lang') || 'en';

  return SUPPORTED_LANGUAGES_FULL.find(({ value }) => value === lang);
};

export const getLangFull = key => ({
  en: 'English',
  es: 'Español',
  sc: '中文 (简化)',
  tc: '中文 (繁體)',
})[key];

export const setLang = (lang) => {
  localStorage.setItem('lang', lang);
  document.location.reload();
};
