export const GET_CDP_REQUEST = 'GET_CDP_REQUEST';
export const GET_CDP_SUCCESS = 'GET_CDP_SUCCESS';
export const GET_CDP_FAILURE = 'GET_CDP_FAILURE';

export const GET_CDPS_REQUEST = 'GET_CDPS_REQUEST';
export const GET_CDPS_SUCCESS = 'GET_CDPS_SUCCESS';
export const GET_CDPS_FAILURE = 'GET_CDPS_FAILURE';

export const SWITCH_CDP = 'SWITCH_CDP';

export const GET_PROXY_ADDRESS_REQUEST = 'GET_PROXY_ADDRESS_REQUEST';
export const GET_PROXY_ADDRESS_SUCCESS = 'GET_PROXY_ADDRESS_SUCCESS';
export const GET_PROXY_ADDRESS_FAILURE = 'GET_PROXY_ADDRESS_FAILURE';
export const ADD_PROXY_ADDRESS = 'ADD_PROXY_ADDRESS';

export const MIGRATE_CDP_FROM_ADDRESS_TO_PROXY_REQUEST = 'MIGRATE_CDP_FROM_ADDRESS_TO_PROXY_REQUEST';
export const MIGRATE_CDP_FROM_ADDRESS_TO_PROXY_SUCCESS = 'MIGRATE_CDP_FROM_ADDRESS_TO_PROXY_SUCCESS';
export const MIGRATE_CDP_FROM_ADDRESS_TO_PROXY_FAILURE = 'MIGRATE_CDP_FROM_ADDRESS_TO_PROXY_FAILURE';
export const MIGRATE_CDP_FROM_ADDRESS_TO_PROXY_RESET = 'MIGRATE_CDP_RESET';

export const CREATE_DS_PROXY_REQUEST = 'CREATE_DS_PROXY_REQUEST';
export const CREATE_DS_PROXY_SUCCESS = 'CREATE_DS_PROXY_SUCCESS';
export const CREATE_DS_PROXY_FAILURE = 'CREATE_DS_PROXY_FAILURE';

export const MIGRATE_CDP_FROM_INSTADAPP_REQUEST = 'MIGRATE_CDP_FROM_INSTADAPP_REQUEST';
export const MIGRATE_CDP_FROM_INSTADAPP_SUCCESS = 'MIGRATE_CDP_FROM_INSTADAPP_SUCCESS';
export const MIGRATE_CDP_FROM_INSTADAPP_FAILURE = 'MIGRATE_CDP_FROM_INSTADAPP_FAILURE';
export const MIGRATE_CDP_FROM_INSTADAPP_SUCCESS_SWITCH_CDP = 'MIGRATE_CDP_FROM_INSTADAPP_SUCCESS_SWITCH_CDP';

export const GET_SAVER_SUBSCRIBE_GRAPH_DATA_REQUEST = 'GET_SAVER_SUBSCRIBE_GRAPH_DATA_REQUEST';
export const GET_SAVER_SUBSCRIBE_GRAPH_DATA_SUCCESS = 'GET_SAVER_SUBSCRIBE_GRAPH_DATA_SUCCESS';
export const GET_SAVER_SUBSCRIBE_GRAPH_DATA_FAILURE = 'GET_SAVER_SUBSCRIBE_GRAPH_DATA_FAILURE';

export const GET_CDP_PROFITABILITY_REQUEST = 'GET_CDP_PROFITABILITY_REQUEST';
export const GET_CDP_PROFITABILITY_SUCCESS = 'GET_CDP_PROFITABILITY_SUCCESS';
export const GET_CDP_PROFITABILITY_FAILURE = 'GET_CDP_PROFITABILITY_FAILURE';

export const CDP_LIQUIDATIONS_SUCCESS = 'CDP_LIQUIDATIONS_SUCCESS';

export const CHANGE_PROXY_OWNER_REQUEST = 'CHANGE_PROXY_OWNER_REQUEST';
export const CHANGE_PROXY_OWNER_SUCCESS = 'CHANGE_PROXY_OWNER_SUCCESS';
export const CHANGE_PROXY_OWNER_FAILURE = 'CHANGE_PROXY_OWNER_FAILURE';
