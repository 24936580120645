import React, { useState } from 'react';
import Select from 'react-select';
import { getLangOption, setLang, SUPPORTED_LANGUAGES_FULL } from '../../services/translationService';
import LanguageSwitchCustomValueContainer from './LanguageSwitchCustomValueContainer';
import './LanguageSwitch.scss';

const LanguageSwitch = () => {
  const [lang, setSelectedLang] = useState(getLangOption());

  return (
    <div className={`language-switch-wrapper ${lang.abrLabel}`}>
      <Select
        className="select box"
        classNamePrefix="select"
        value={lang}
        onChange={(option) => {
          setSelectedLang(option);
          setLang(option.value);
        }}
        components={{
          ValueContainer: LanguageSwitchCustomValueContainer,
        }}
        options={SUPPORTED_LANGUAGES_FULL}
        isSearchable={false}
        onBlur={event => event.preventDefault()}
        menuPlacement="top"
      />
    </div>
  );
};

LanguageSwitch.propTypes = {};

export default LanguageSwitch;
