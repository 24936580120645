import memoize from 'memoizee';
import store from '../store';
import { defined } from './utils';

const getCriteria = (options) => {
  const {
    network, account, accountType, forkId,
  } = store.getState().general;
  let criteria = {};
  if (options.resetOnNetwork) criteria = { network, forkId };
  if (options.resetOnAccount) criteria = { ...criteria, account, accountType };
  return JSON.stringify(criteria);
};

const DEFAULT_OPTIONS = {
  resetOnNetwork: true,
  resetOnAccount: false,
};

/**
 * Memoize function call with additional criteria
 * eg. current network in redux, or whether a fork is active
 * Useful for memoizing methods that have a different output depending on external factors like the network
 *
 * @param func {function}
 * @param [options] {Object}
 * @returns {function(...[*]): *}
 */
export const momoize = (func, options = {}) => {
  const _memoized = memoize((_crit, ...params) => func(...params), {
    ...options,
    length: defined(options.length) ? (options.length + 1) : false, // without length:false, memoize ignores all params
  });

  const _options = Object.keys(DEFAULT_OPTIONS).reduce((current, key) => ({
    ...current,
    [key]: defined(options[key]) ? options[key] : DEFAULT_OPTIONS[key],
  }), options);

  return (...params) => {
    const _crit = getCriteria(_options);
    return _memoized(_crit, ...params);
  };
};
