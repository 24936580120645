import Dec from 'decimal.js';
import { getOffchainEmptyData, getOffchainPrice } from 'services/offchainExchangeServiceV3';
import { getAssetInfo } from '@defisaver/tokens';
import dfs from '@defisaver/sdk';
import { DFSPricesContract } from './contractRegistryService';
import {
  formatSlippageFor0x, compareAddresses, isAddress, getWeiAmountForDecimals,
} from './utils';
import {
  getThresholdAmountForAsset,
  formatPriceWithDecimalForContract, parsePriceWithDecimalsFromContract,
} from './exchangeServiceCommon';
import { getUniswapRoute } from './uniswapServices';
import { getUniswapV3EncodedPath } from './uniswapV3Services';
import { getSushiswapRoute } from './sushiswapService';
import { ZERO_ADDRESS } from '../constants/general';
import {
  KYBER_WRAPPER, UNISWAP_WRAPPER, SUSHISWAP_WRAPPER, UNISWAPV3_WRAPPER, standardExchangeWrappers, CURVE_WRAPPER,
} from '../config/exchangeWrappers';
import { callActionViaProxy } from './contractCallService';
import { getERC20TokenData } from './erc20Service';
import { momoize } from './memoization';
import { getFeeDecimal, getFeeDivider } from './feeUtils';
import { getRoutingFromApiCall } from './apiService';
import { getNetwork } from './ethService';

const assetsForOffchainOnly = ['GUSD', 'xSUSHI'];

const DEFAULT_FEE = 0.25 / 100;

const includeFeeInPrice = (price, from, to, fee, shouldSell = true) => {
  if (from === to) return price;
  if (shouldSell) return new Dec(price).mul(new Dec(1).sub(fee)).toString();
  return new Dec(price).mul(new Dec(1).add(fee)).toString();
};

const excludeFeeFromPrice = (price, from, to, fee, shouldSell = true) => {
  if (from === to) return price;
  if (shouldSell) return new Dec(price).mul(new Dec(1).add(fee)).toString();
  return new Dec(price).mul(new Dec(1).sub(fee)).toString();
};

/**
 * Skip to on-chain exchange if amount is relatively small
 * @param amount {String}
 * @param from {String} asset
 * @param to {String} asset
 * @param shouldSell {boolean}
 * @return {Promise<boolean>}
 */
const useOnchainDirectly = async (amount, from, to, shouldSell = true) => {
  if (window._web3.isFork) return true;
  const _from = isAddress(from) ? (await getERC20TokenData(from)).symbol : from;
  const _to = isAddress(to) ? (await getERC20TokenData(to)).symbol : to;
  if (assetsForOffchainOnly.includes(_from) || assetsForOffchainOnly.includes(_to)) return false;
  // return new Dec(amount).lt(getThresholdAmountForAsset(shouldSell ? _from : _to));
  return false;
};

const getRoutingFromApi = async (amount, fromAssetAddr, toAssetAddr, sources = ['curve']) => {
  const ethAddr = getAssetInfo('ETH').address;
  const wethAddr = getAssetInfo('WETH').address;
  const res = await getRoutingFromApiCall({
    amount,
    fromAsset: fromAssetAddr.replace(ethAddr, wethAddr),
    toAsset: toAssetAddr.replace(ethAddr, wethAddr),
    forkId: window._web3.forkId,
    sources,
  });
  return res.filter(data => !!data).map((data) => ({
    ...data,
    // wrapper: getWrapper(data.source), // TODO getWrapper
    wrapper: CURVE_WRAPPER,
  }));
};

/**
 * @param amount {String} Amount of asset being sold (not in wei)
 * @param from {String} Symbol for asset being sold
 * @param to {String} Symbol for asset being bought
 * @param shouldSell {Boolean} look for price to sell or to buy
 * @param useRecipeWrappers {boolean} Use special wrappers for recipes
 * @param excludedSources {array}
 * @return {Promise<{wrapper:String, price:String, wrapperData:String, source:String, route:String[]}>}
 */
const getOnchainPrice = async (amount, from, to, shouldSell, useRecipeWrappers = false, excludedSources = []) => {
  const contract = DFSPricesContract();

  const network = await getNetwork();
  const isMainnet = network === 1;

  const fromTokenData = isAddress(from) ? await getERC20TokenData(from) : getAssetInfo(from);
  const toTokenData = isAddress(to) ? await getERC20TokenData(to) : getAssetInfo(to);

  const routes = await Promise.all([
    (isMainnet ? await getUniswapRoute(amount, fromTokenData.address, toTokenData.address, shouldSell) : null),
    (isMainnet ? await getSushiswapRoute(amount, fromTokenData.address, toTokenData.address, shouldSell) : null),
    (useRecipeWrappers ? await getUniswapV3EncodedPath(amount, fromTokenData.address, toTokenData.address, shouldSell, network) : null),
    (isMainnet && useRecipeWrappers ? getRoutingFromApi(amount, fromTokenData.address, toTokenData.address) : null),
  ]);

  const uniPathEncoded = isMainnet && window._web3.eth.abi.encodeParameter('address[]', routes[0]);
  const sushiPathEncoded = isMainnet && window._web3.eth.abi.encodeParameter('address[]', routes[1]);

  const wrappers = isMainnet ? [UNISWAP_WRAPPER, SUSHISWAP_WRAPPER] : [];
  const wrappersData = isMainnet ? [uniPathEncoded, sushiPathEncoded] : [];

  const isBalancerExcluded = excludedSources.includes('Balancer_V2');

  if (isMainnet && shouldSell && !isBalancerExcluded) {
    // Kyber wrapper returns price with 3% offset for buy orders
    wrappers.push(KYBER_WRAPPER);
    wrappersData.push('0x00');
  }

  if (routes[2]) {
    // Uniswap v3 only available on recipes
    wrappers.push(UNISWAPV3_WRAPPER[network]);
    wrappersData.push(routes[2].encodedRoute);
  }
  if (routes[3]) {
    routes[3].forEach(({ wrapper, wrapperData }) => {
      wrappers.push(wrapper);
      wrappersData.push(wrapperData);
    });
  }

  const amountInWei = getWeiAmountForDecimals(amount, shouldSell ? fromTokenData.decimals : toTokenData.decimals);

  let data = await contract.methods.getBestPrice(
    amountInWei,
    fromTokenData.address,
    toTokenData.address,
    shouldSell ? 0 : 1,
    wrappers,
    wrappersData,
  ).call();

  let source = '';
  let route = [];

  if (compareAddresses(data[0], KYBER_WRAPPER)) {
    source = 'Kyber V3';
    const uniV2Data = await contract.methods.getBestPrice(
      amountInWei,
      fromTokenData.address,
      toTokenData.address,
      shouldSell ? 0 : 1,
      [UNISWAP_WRAPPER],
      [uniPathEncoded],
    ).call();
    const priceRatio = new Dec(data[1]).div(uniV2Data[1]);
    if (priceRatio.gt(0.99) && priceRatio.lt(1.01)) data = uniV2Data;
  }
  let wrapperData = '0x00';

  if (compareAddresses(data[0], UNISWAP_WRAPPER)) {
    route = await Promise.all(routes[0].map(async (i) => (await getERC20TokenData(i)).symbol));
    wrapperData = uniPathEncoded;
    source = 'Uniswap V2';
  }
  if (compareAddresses(data[0], SUSHISWAP_WRAPPER)) {
    route = await Promise.all(routes[1].map(async (i) => (await getERC20TokenData(i)).symbol));
    wrapperData = sushiPathEncoded;
    source = 'Sushiswap';
  }
  if (compareAddresses(data[0], UNISWAPV3_WRAPPER[network])) {
    route = routes[2].route;
    wrapperData = routes[2].encodedRoute;
    source = 'Uniswap V3';
  }
  if (compareAddresses(data[0], CURVE_WRAPPER)) {
    const apiRoute = routes[3].find(({ wrapper }) => compareAddresses(wrapper, CURVE_WRAPPER));
    route = apiRoute.route;
    wrapperData = apiRoute.wrapperData;
    source = 'Curve';
  }
  return {
    route,
    source,
    wrapperData,
    wrapper: data[0],
    price: parsePriceWithDecimalsFromContract(data[1], fromTokenData.decimals, toTokenData.decimals),
  };
};

/**
 * @param amount {String} Amount of asset being sold (or bought if shouldSell=false) (not in wei)
 * @param from {String} Symbol for asset being sold
 * @param to {String} Symbol for asset being bought
 * @param takerAddress {String} Account executing the trade
 * @param [includeOffchain] {boolean} Include 0x & ScpSwap
 * @param [shouldSell] {boolean} Look for price to sell or to buy
 * @param [useRecipeWrappers] {boolean}
 * @param noFee {boolean} if exchange order is made for exchange dashboard
 * @return {Promise<{ price:string, source:string, liquiditySources:array, route:string[] }>}
 */
export const getBestExchangePrice = async (amount, from, to, takerAddress, includeOffchain = true, shouldSell = true, useRecipeWrappers = true, noFee = false) => {
  // console.log('Getting price', amount, from, to, takerAddress, includeOffchain, shouldSell, useRecipeWrappers);
  // if (!useRecipeWrappers) console.trace();
  if (!amount || !parseFloat(amount)) {
    return {
      source: '', price: '0', route: [], liquiditySources: [],
    };
  }
  if (from === to) {
    return {
      source: '', price: '1', route: [], liquiditySources: [],
    };
  }
  const fromTokenData = isAddress(from) ? await getERC20TokenData(from) : getAssetInfo(from);
  const toTokenData = isAddress(to) ? await getERC20TokenData(to) : getAssetInfo(to);

  const feeDecimal = noFee ? '0' : getFeeDecimal(fromTokenData.address, toTokenData.address, await getNetwork());

  const isOffchain = !(await useOnchainDirectly(amount, from, to, shouldSell));

  if (includeOffchain && isOffchain) {
    const offchainData = await getOffchainPrice(from, to, amount, true, true, takerAddress, formatSlippageFor0x(0.03), shouldSell);
    if (offchainData.price > 0) {
      const _price = parsePriceWithDecimalsFromContract(offchainData.price, fromTokenData.decimals, toTokenData.decimals);
      const price = includeFeeInPrice(_price, from, to, feeDecimal, shouldSell);

      return {
        route: [],
        liquiditySources: offchainData.liquiditySources,
        source: offchainData.source,
        price,
      };
    }
  }

  const { price: _price, source, route } = await getOnchainPrice(amount, from, to, shouldSell, useRecipeWrappers);
  const price = includeFeeInPrice(_price, from, to, feeDecimal, shouldSell);
  return { price, source, route };
};

export const getBestExchangePriceCached = momoize(getBestExchangePrice, { promise: true, maxAge: 60 * 1000 });

/**
 * Fetches prices and creates order ready to be passed to transaction
 * This should only be called when before sending tx, not to be used for just querying the price.
 * For that purpose there is getBestPrice method.
 * @DEV To use output value from previous actions with orderData, do orderData[2]='$1' for example
 *
 * @param _from {string} Symbol for asset being sold ('ETH')
 * @param _to {string} Symbol for asset being bought ('DAI')
 * @param amount {string} Amount of asset being sold ('100.12312')
 * @param price {string} Price you got from getBestPrice (so minPrice can be calculated based on what user saw)
 * @param slippage {string|Number} Slippage percentage tolerated [0-100]
 * @param takerAddress {string} Address that will execute actual transaction
 * @param withValue {boolean} true ETH sold is being sent by user, false if it is extracted from contract (ie. Boost/Repay)
 * @param onlyOnchain {boolean}
 * @param useRecipeWrappers {boolean} Use special wrappers for recipes
 * @param _excludeSources {array} exclude liquidity sources from 0x
 * @param noFee {boolean} if exchange order is made for exchange dashboard
 * @return {Promise<{orderData: (string)[], value: (string), extraGas: (number)}>} Order data array & tx value that can be passed directly to contract call
 */
export const getExchangeOrder = async (
  _from,
  _to,
  amount,
  price,
  slippage,
  takerAddress,
  withValue = true,
  onlyOnchain = false,
  useRecipeWrappers = false,
  _excludeSources,
  noFee = false,
) => {
  // @DEV: This should throw but I was scared it might break something.
  //       If these errors are displayed, they should be fixed ASAP.
  if (!price || price.toString() === '0') console.error('Error: minPrice in exchange order should not be 0 - fix ASAP');
  if (slippage.toString() === '100') console.error('Error: slippage in exchange order should not be 100 - fix ASAP');

  const fromTokenData = isAddress(_from) ? await getERC20TokenData(_from) : getAssetInfo(_from);
  const toTokenData = isAddress(_to) ? await getERC20TokenData(_to) : getAssetInfo(_to);

  const network = await getNetwork();
  const feeDivider = noFee ? '0' : getFeeDivider(fromTokenData.address, toTokenData.address, network);
  const feeDecimal = noFee ? '0' : getFeeDecimal(fromTokenData.address, toTokenData.address, network);

  let useOnchainOnly = onlyOnchain ? true : await useOnchainDirectly(amount, fromTokenData.address, toTokenData.address);
  const minPrice = new Dec(excludeFeeFromPrice(price, fromTokenData.address, toTokenData.address, feeDecimal))
    .mul(100 - slippage)
    .div(100)
    .toString();

  const minPriceForContract = formatPriceWithDecimalForContract(minPrice, fromTokenData.decimals, toTokenData.decimals);
  const offchainData = useOnchainOnly
    ? getOffchainEmptyData()
    : await getOffchainPrice(fromTokenData.symbol, toTokenData.symbol, amount.toString(), true, false, takerAddress, formatSlippageFor0x(slippage), true, _excludeSources);

  // if offchain request fails
  if (!useOnchainOnly && offchainData.wrapper === ZERO_ADDRESS) useOnchainOnly = true;

  // eslint-disable-next-line prefer-const
  let { wrapper, wrapperData } = useOnchainOnly ?
    await getOnchainPrice(amount, fromTokenData.address, toTokenData.address, true, useRecipeWrappers, _excludeSources) :
    { wrapper: ZERO_ADDRESS, wrapperData: '0x00' };

  const extraGas = useOnchainOnly ? 0 : parseInt(offchainData.gas, 10);
  let value = offchainData.protocolFee;
  if (withValue && fromTokenData.symbol === 'ETH') {
    value = new Dec(value).add(window._web3.utils.toWei(amount.toString())).toFixed(0);
  }

  const offchainDataArray = [offchainData.wrapper, offchainData.to, offchainData.allowanceTarget, useOnchainOnly ? offchainData.price : minPriceForContract, offchainData.protocolFee, offchainData.data];
  if (!useRecipeWrappers) {
    wrapper = standardExchangeWrappers[wrapper];
    offchainDataArray[0] = standardExchangeWrappers[offchainDataArray[0]];
  }
  return {
    orderData: [
      fromTokenData.address,
      toTokenData.address,
      getWeiAmountForDecimals(amount, fromTokenData.decimals),
      '0',
      minPriceForContract,
      feeDivider,
      '0x0000000000000000000000000000000000000000', // set by contract
      wrapper,
      wrapperData,
      offchainDataArray,
    ],
    value,
    extraGas,
    liquiditySources: offchainData.liquiditySources,
  };
};

export const getEmptyExchangeOrder = () => {
  const offchainData = getOffchainEmptyData();
  return {
    orderData: [
      ZERO_ADDRESS,
      ZERO_ADDRESS,
      '0',
      '0',
      '0',
      '400',
      ZERO_ADDRESS,
      ZERO_ADDRESS,
      '0x00',
      [offchainData.wrapper, offchainData.to, offchainData.allowanceTarget, offchainData.price, offchainData.protocolFee, offchainData.data],
    ],
    value: 0,
    extraGas: 0,
  };
};


/**
 * @param accountType {string}
 * @param path {string}
 * @param sendTxFunc {string}
 * @param account {string}
 * @param proxyAddress {string}
 * @param amount {string}
 * @param from {string} Address for asset being sold
 * @param to {string} Address for asset being bought
 * @param price {string} Price you got from getBestPrice (so minPrice can be calculated based on what user saw)
 * @param slippage {string|Number} Slippage percentage tolerated [0-100]
 * @param [sendToAddress] {string} Send out coming token to address
 * @param [fee] {number}
 * @return {Promise<unknown>}
 */
export const exchange = async (
  accountType, path, sendTxFunc, account, proxyAddress, amount, from,
  to, price, slippage, sendToAddress, fee,
) => {
  const { orderData, value } = await getExchangeOrder(from, to, amount, price, slippage, proxyAddress, true, false, true, [], true);

  const toAddress = sendToAddress || account;

  return callActionViaProxy(accountType, sendTxFunc, proxyAddress, account, new dfs.actions.basic.SellAction(orderData, account, toAddress, value));
};

/**
 * Fetches price used as threshold for calculating slippage
 * Accounts for price differences (checks price for 0.01 WBTC, 1 ETH and 10 if other assets)
 *
 * @param from {string} asset ('ETH')
 * @param to {string} asset ('DAI')
 * @returns {Promise<string|*>}
 */
export const getSlippageThreshold = async (from, to) => (await getBestExchangePrice(getThresholdAmountForAsset(from), from, to, '0x0000000000000000000000000000000000000001', true, true, true)).price;
