export const CONNECT_PROVIDER = 'CONNECT_PROVIDER';
export const CONNECT_PROVIDER_SUCCESS = 'CONNECT_PROVIDER_SUCCESS';
export const CONNECT_PROVIDER_FAILURE = 'CONNECT_PROVIDER_FAILURE';

export const LOGIN_STARTED = 'LOGIN_STARTED';
export const LOGIN_FINISHED = 'LOGIN_FINISHED';

export const SUBSCRIBE_COMING_SOON_REQUEST = 'SUBSCRIBE_COMING_SOON_REQUEST';
export const SUBSCRIBE_COMING_SOON_SUCCESS = 'SUBSCRIBE_COMING_SOON_SUCCESS';
export const SUBSCRIBE_COMING_SOON_FAILURE = 'SUBSCRIBE_COMING_SOON_FAILURE';
export const RESET_SUBSCRIBE_COMING_SOON = 'RESET_SUBSCRIBE_COMING_SOON';

export const SUBMIT_CONTACT_US_REQUEST = 'SUBMIT_CONTACT_US_REQUEST';
export const SUBMIT_CONTACT_US_SUCCESS = 'SUBMIT_CONTACT_US_SUCCESS';
export const SUBMIT_CONTACT_US_FAILURE = 'SUBMIT_CONTACT_US_FAILURE';
export const RESET_CONTACT_US = 'RESET_CONTACT_US';

export const CHANGE_LEDGER_ACC_TYPE = 'CHANGE_LEDGER_ACC_TYPE';

export const LIST_LEDGER_ACCOUNTS_REQUEST = 'LIST_LEDGER_ACCOUNTS_REQUEST';
export const LIST_LEDGER_ACCOUNTS_SUCCESS = 'LIST_LEDGER_ACCOUNTS_SUCCESS';
export const LIST_LEDGER_ACCOUNTS_FAILURE = 'LIST_LEDGER_ACCOUNTS_FAILURE';

export const SET_LEDGER_PATH = 'SET_LEDGER_PATH';

export const LEDGER_LIST_MORE_ACCOUNTS_REQUEST = 'LEDGER_LIST_MORE_ACCOUNTS_REQUEST';
export const LEDGER_LIST_MORE_ACCOUNTS_SUCCESS = 'LEDGER_LIST_MORE_ACCOUNTS_SUCCESS';
export const LEDGER_LIST_MORE_ACCOUNTS_FAILURE = 'LEDGER_LIST_MORE_ACCOUNTS_FAILURE';

export const RESET_CONNECT_LEDGER = 'RESET_CONNECT_LEDGER';

export const SET_ENS_NAME = 'SET_ENS_NAME';

export const CLEAR_ACCOUNT_TYPE = 'CLEAR_ACCOUNT_TYPE';

export const GET_GAS_PRICES_REQUEST = 'GET_GAS_PRICES_REQUEST';
export const GET_GAS_PRICES_SUCCESS = 'GET_GAS_PRICES_SUCCESS';
export const GET_GAS_PRICES_FAILURE = 'GET_GAS_PRICES_FAILURE';
export const GAS_PRICES_RESET = 'GAS_PRICES_RESET';

export const CHANGE_WALLET_TYPE = 'CHANGE_WALLET_TYPE';

export const GET_GAS_PRICE_HISTORY_REQUEST = 'GET_GAS_PRICE_HISTORY_REQUEST';
export const GET_GAS_PRICE_HISTORY_SUCCESS = 'GET_GAS_PRICE_HISTORY_SUCCESS';
export const GET_GAS_PRICE_HISTORY_FAILURE = 'GET_GAS_PRICE_HISTORY_FAILURE';

export const CONNECT_VIEW_ONLY_ACCOUNT_REQUEST = 'CONNECT_VIEW_ONLY_ACCOUNT_REQUEST';
export const CONNECT_VIEW_ONLY_ACCOUNT_SUCCESS = 'CONNECT_VIEW_ONLY_ACCOUNT_SUCCESS';
export const CONNECT_VIEW_ONLY_ACCOUNT_FAILURE = 'CONNECT_VIEW_ONLY_ACCOUNT_FAILURE';

export const SET_HIDE_HINTS = 'SET_HIDE_HINTS';

export const UPDATE_RECENT_ACCOUNTS = 'UPDATE_RECENT_ACCOUNTS';

export const SET_APP_BACKDROP = 'SET_APP_BACKDROP';

export const CHANGE_NETWORK = 'CHANGE_NETWORK';
