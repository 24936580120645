export const GET_ASSET_PRICE_REQUEST = 'GET_ASSET_PRICE_REQUEST';
export const GET_ASSET_PRICE_SUCCESS = 'GET_ASSET_PRICE_SUCCESS';
export const GET_ASSET_PRICE_FAILURE = 'GET_ASSET_PRICE_FAILURE';
export const GET_ASSETS_PRICE_REQUEST = 'GET_ASSETS_PRICE_REQUEST';
export const GET_ASSETS_PRICE_SUCCESS = 'GET_ASSETS_PRICE_SUCCESS';
export const GET_ASSETS_PRICE_FAILURE = 'GET_ASSETS_PRICE_FAILURE';
export const UPDATE_ASSET_PRICE_TIMEOUT_ID = 'UPDATE_ASSET_PRICE_TIMEOUT_ID';

export const GET_ASSET_BALANCE_SUCCESS = 'GET_ASSET_BALANCE_SUCCESS';
export const GET_ASSET_BALANCE_REQUEST = 'GET_ASSET_BALANCE_REQUEST';
export const GET_ASSET_BALANCE_FAILURE = 'GET_ASSET_BALANCE_FAILURE';
export const GET_ASSETS_BALANCE_REQUEST = 'GET_ASSETS_BALANCE_REQUEST';
export const GET_ASSETS_BALANCE_SUCCESS = 'GET_ASSETS_BALANCE_SUCCESS';
export const GET_ASSETS_BALANCE_FAILURE = 'GET_ASSETS_BALANCE_FAILURE';
export const UPDATE_ASSET_BALANCE_TIMEOUT_ID = 'UPDATE_ASSET_BALANCE_TIMEOUT_ID';

export const GET_ASSET_PROXY_BALANCE_REQUEST = 'GET_ASSET_PROXY_BALANCE_REQUEST';
export const GET_ASSET_PROXY_BALANCE_SUCCESS = 'GET_ASSET_PROXY_BALANCE_SUCCESS';
export const GET_ASSET_PROXY_BALANCE_FAILURE = 'GET_ASSET_PROXY_BALANCE_FAILURE';

export const APPROVE_TYPE_DS_PROXY = 'DsProxy';
export const APPROVE_TYPE_EXCHANGE = 'Exchange';
export const APPROVE_TYPE_MIGRATION = 'Migration';
export const APPROVE_TYPE_COMPOUND = 'Compound';
export const APPROVE_TYPE_AAVE = 'Aave';
export const APPROVE_TYPE_CONTRACT = 'Contract';

export const APPROVE_ADDRESS_ON_ASSET_REQUEST = 'APPROVE_ADDRESS_ON_ASSET_REQUEST';
export const APPROVE_ADDRESS_ON_ASSET_SUCCESS = 'APPROVE_ADDRESS_ON_ASSET_SUCCESS';
export const APPROVE_ADDRESS_ON_ASSET_FAILURE = 'APPROVE_ADDRESS_ON_ASSET_FAILURE';
export const CLEAR_ASSET_APPROVE_ERROR = 'CLEAR_ASSET_APPROVE_ERROR';

export const GET_IS_ADDRESS_APPROVED_ON_ASSET_REQUEST = 'GET_IS_ADDRESS_APPROVED_ON_ASSET_REQUEST';
export const GET_IS_ADDRESS_APPROVED_ON_ASSET_SUCCESS = 'GET_IS_ADDRESS_APPROVED_ON_ASSET_SUCCESS';
export const GET_IS_ADDRESS_APPROVED_ON_ASSET_FAILURE = 'GET_IS_ADDRESS_APPROVED_ON_ASSET_FAILURE';

export const GET_FUTURE_PRICES_REQUEST = 'GET_FUTURE_PRICES_REQUEST';
export const GET_FUTURE_PRICES_SUCCESS = 'GET_FUTURE_PRICES_SUCCESS';
export const GET_FUTURE_PRICES_FAILURE = 'GET_FUTURE_PRICES_FAILURE';

export const EXPLOITABLE_APPROVALS_REQUEST = 'EXPLOITABLE_APPROVALS_REQUEST';
export const EXPLOITABLE_APPROVALS_SUCCESS = 'EXPLOITABLE_APPROVALS_SUCCESS';
export const EXPLOITABLE_APPROVALS_FAILURE = 'EXPLOITABLE_APPROVALS_FAILURE';
