/* eslint-disable */
import React from 'react';

export default function FortmaticIcon(props) {
  return (
    <svg className="fortmatic-icon" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.6579 0H7.10527H3.55264H0V3.75548V7.51095V7.75174V11.2445V11.2664V15H3.55264V11.2664V11.2445V7.75174V7.51095V3.75548H7.10527H10.6579H14.2105V0H10.6579Z"
        fill="#6852ff"/>
      <path
        d="M7.10522 11.437H10.6485V15H10.7732C11.685 14.9994 12.5593 14.6369 13.2039 13.9922C13.8484 13.3474 14.2105 12.4732 14.2105 11.5616V7.89474H7.10522V11.437Z"
        fill="#6852ff"/>
    </svg>

  );
}
