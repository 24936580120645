import React from 'react';


export default function TwitterIcon(props) {
  return (
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="13.5" cy="13.5" r="13.5" fill="#939DA7" />
      <path fillRule="evenodd" clipRule="evenodd" d="M21 9.42058C20.4856 9.66154 19.9317 9.82449 19.3508 9.89729C19.9441 9.52286 20.3993 8.92914 20.6138 8.22189C20.0583 8.56858 19.4444 8.8208 18.7887 8.95601C18.2661 8.3675 17.5191 8 16.6924 8C15.1073 8 13.8213 9.35645 13.8213 11.0293C13.8213 11.2667 13.8459 11.4973 13.8952 11.7192C11.5081 11.5926 9.39209 10.3879 7.97458 8.55298C7.72724 9.00195 7.5859 9.52286 7.5859 10.0776C7.5859 11.1281 8.09292 12.0555 8.86371 12.5989C8.39367 12.5842 7.94993 12.4464 7.56207 12.221V12.2583C7.56207 13.7265 8.55227 14.9512 9.86705 15.2286C9.62628 15.2997 9.37237 15.3352 9.11023 15.3352C8.92534 15.3352 8.74456 15.317 8.56953 15.2815C8.9352 16.4845 9.99525 17.3608 11.2525 17.3842C10.2697 18.1972 9.03052 18.6817 7.68533 18.6817C7.4536 18.6817 7.22434 18.6678 7 18.6401C8.27123 19.4982 9.78159 20 11.4029 20C16.6867 20 19.5751 15.3846 19.5751 11.3811C19.5751 11.2494 19.5726 11.1177 19.5677 10.9885C20.129 10.5612 20.6162 10.0282 21 9.42058Z" fill="white" />
    </svg>
  );
}
