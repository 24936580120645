import Dec from 'decimal.js';
import t from 'translate';
import * as multicall from '@makerdao/multicall';
import { getNetworkData } from '@defisaver/sdk';

import { WALLET_NAMES } from '../constants/general';
import { MulticallAddress } from './contractRegistryService';

import store from '../store';

export const getAccount = () => (
  new Promise(async (resolve, reject) => {
    try {
      const accounts = await window._web3.eth.getAccounts();
      if (!accounts.length) throw new Error(t('errors.no_accounts_locked'));
      resolve(accounts[0]);
    } catch (err) {
      reject(err);
    }
  })
);

export const weiToEth = (_weiVal, size = 'ether') => window._web3.utils.fromWei(
  Dec(_weiVal.toString()).toString(),
  size,
);

export const ethToWei = (_ethVal, size = 'ether') => {
  const parts = Dec(_ethVal.toString()).toString().split('.');
  let val = parts[0];

  if (parts[1]) {
    const decimals = parts[1].length > 18 ? parts[1].substring(0, 18) : parts[1];
    val += `.${decimals}`;
  }

  return window._web3.utils.toWei(`${val}`, size);
};

/**
 *
 * @returns {Promise<number>}
 */
export const getNetwork = () => window._web3.eth.net.getId();

/**
 * Returns name of Ethereum network for given ID
 *
 * @return {String}
 */
export const nameOfNetwork = (networkId) => {
  const networks = {
    1: 'Mainnet',
    3: 'Ropsten',
    4: 'Rinkeby',
    42: 'Kovan',
    42161: 'Arbitrum One',
    10: 'Optimism',
  };
  return networks[networkId] || t('errors.unknown_network');
};

export const isLayer2Network = (networkId) => [10, 42161].includes(networkId);

/**
 * Checks if the user has approved to use MM as the provider
 *
 * @return {Promise<*>}
 */
export const isMetaMaskApproved = async () => {
  try {
    // legacy window.web3.currentProvider or no provider
    if (!window.ethereum || !window.ethereum.enable) return true;

    // should return address if approved
    if (window.ethereum.selectedAddress) return true;

    // returns [] if not approved
    const { result: [account] } = await window.ethereum.send({ method: 'eth_accounts' });
    return !!account;
  } catch (err) {
    return false;
  }
};

/**
 * If MetaMask privacy is on, opens MetaMask modal to whitelist it
 *
 * @return {Promise<*>}
 */
export const metamaskApprove = async () => {
  if (window.ethereum) return window.ethereum.enable();
};


/**
 * Gets the name of the inpage provider
 *
 * @return {string}
 */
export const getBrowserProviderName = () => {
  const provider = window.ethereum || window.web3?.currentProvider;

  if (!provider) return WALLET_NAMES.browser;

  if (provider.isTally) return WALLET_NAMES.tally;
  if (provider.isStatus) return WALLET_NAMES.status;
  if (provider.isImToken) return WALLET_NAMES.imToken;
  if (provider.isTrust) return WALLET_NAMES.trust;
  if (provider.isToshi) return WALLET_NAMES.coinbase;
  if (provider.isTokenary) return WALLET_NAMES.tokenary;
  if (navigator.userAgent.match(/Opera|OPR/)) return WALLET_NAMES.opera;
  if (provider.isRabby) return WALLET_NAMES.rabby;
  if (provider.isFrame) return WALLET_NAMES.frame;
  if (provider.isXDEFI || typeof provider.isXDEFI === 'boolean') return WALLET_NAMES.xdefi;
  if (provider.isBrave) return WALLET_NAMES.brave; // Does not exist on desktop currently, might exist on mobile

  // leave at last place because some providers set this as true in addition to their own flag
  // ie. Rabby sets both isRabby and isMetamask as true
  if (provider.isMetaMask) return WALLET_NAMES.metaMask;

  return WALLET_NAMES.browser;
};


export const aggregate = async (calls) => {
  const config = {
    web3: window._web3,
    multicallAddress: MulticallAddress,
  };
  return multicall.aggregate(calls, config);
};
/**
 * Tries to switch to the given network
 *
 * @param networkId {number} Hexadecimal network identifier
 *
 * @return {Promise<boolean|error>}
 */
export const metamaskSwitchNetwork = async (networkId) => {
  try {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: `0x${networkId.toString(16)}` }],
    });
    return true;
  } catch (err) {
    console.error(err);
    if (err.code === 4902) {
      try {
        const networkData = getNetworkData(networkId);
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              ...networkData,
              chainId: `0x${networkId.toString(16)}`,
            },
          ],
        });
        return true;
      } catch (err) {
        console.error(err);
        return err;
      }
    } else { return err; }
  }
};

export const requireCorrectChain = async () => {
  const walletNetwork = await getNetwork();
  const appNetwork = store.getState().general.network;
  if (appNetwork !== walletNetwork) {
    const walletType = getBrowserProviderName();
    throw new Error(t('errors.wrong_network', { '%type': walletType, '%network': nameOfNetwork(appNetwork) }));
  }
};
