import t from 'translate';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { setEModeAction } from '../../../../actions/aaveActions/aaveManageActionsV2';

import { bemifyClass } from '../../../../services/stylingUtils';
import { formatNumber } from '../../../../services/utils';

import ModalHeader from '../../ModalHeader';
import ModalBody from '../../ModalBody';
import WarningBox from '../../../Decorative/WarningBox/WarningBox';

import emodeIcon from './emode-icon-circle.svg';

import './AaveEModeModal.scss';

const bem = bemifyClass('aave-emode-modal');

// TODO Handle multiple categories
const AaveEModeModal = ({ closeModal }) => {
  const dispatch = useDispatch();
  const { aaveManage, general } = useSelector(({ aaveManage, general }) => ({ aaveManage, general }));

  const { selectedMarket } = aaveManage;
  const {
    eModeCategory, ratio, liqPercent, eModeCategories,
  } = aaveManage[general.walletType.value][selectedMarket.value];

  const [isInEMode, setIsInEMode] = useState(false);

  useEffect(() => {
    if (eModeCategory !== undefined) setIsInEMode(eModeCategory !== 0);
  }, [eModeCategory]);

  const canChangeEMode = isInEMode
    ? eModeCategories?.[0]?.canEnterCategory
    : eModeCategories?.[1]?.canEnterCategory;

  return (
    <div className={`action-modal-wrapper borrow-modal-wrapper ${bem()}`}>
      <ModalHeader closeModal={closeModal} />
      <ModalBody>
        <div className="new-modal-top-wrapper" style={{ backgroundImage: `url(${emodeIcon})` }}>
          <h1>Efficiency mode (eMode)</h1>
        </div>
        <div className="new-modal-content-wrapper">
          <div className={bem('description')}>
            <div>{t('aave.e_mode_desc')}</div>
          </div>
          <br />

          {eModeCategories && (
            <div className={bem('categories')}>
              {Object.values(eModeCategories).filter(c => (isInEMode ? true : c.id !== 0)).map(({
                assets, data, enabledData, id,
              }, i) => (
                <div key={data.label} className={bem('category')}>
                  <div className={bem('category-data')}>
                    <span>{id === 0 ? 'Position if efficiency mode was deactivated:' : eModeCategory === id ? 'Active eMode category: ' : 'Category:'}</span>
                    {id !== 0 && <span>{data.label}</span>}
                  </div>
                  {id !== 0 && (
                    <div className={bem('category-data')}>
                      <span>Assets:</span>
                      <span>{assets?.join(', ')}</span>
                    </div>
                  )}
                  {eModeCategory !== id && (
                    <>
                      <div className={bem('category-data')}>
                        <span>Safety ratio:</span>
                        <span>{formatNumber(ratio)}% &#8594; {formatNumber(enabledData.ratio)}%</span>
                      </div>
                      <div className={bem('category-data')}>
                        <span>Liquidation ratio:</span>
                        <span>{formatNumber(liqPercent)}% &#8594; {formatNumber(enabledData.liqPercent)}%</span>
                      </div>
                    </>
                  )}
                  {(i !== Object.values(eModeCategories).filter(c => (isInEMode ? true : c.id !== 0)).length - 1) && <hr />}
                </div>
              ))}
            </div>
          )}
          {eModeCategories && !canChangeEMode && (
            <WarningBox>
              You can&lsquo;t enter eMode. To be able to enter it you must satisfy following:
              <br />
              <br />
              - All the borrowed assets of the user are in the chosen category.
              <br />
              - Changing eMode doesn’t leave user position under-collateralised
            </WarningBox>
          )}
        </div>
      </ModalBody>
      <div className="modal-controls">
        <button
          type="button"
          className="button green"
          disabled={!canChangeEMode}
          onClick={() => dispatch(setEModeAction(isInEMode ? 0 : 1))}
        >
          {isInEMode ? 'Disable eMode' : 'Enable eMode'}
        </button>
      </div>
    </div>
  );
};
AaveEModeModal.defaultProps = {};

AaveEModeModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default AaveEModeModal;
