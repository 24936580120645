import {
  TRANSFER_CDP_SUCCESS,
  CLOSE_CDP_SUCCESS,
  MAKER_ACTION_EXEC_SUCCESS,
} from '../../actionTypes/makerActionTypes/makerManageActionTypes/makerManageActionTypes';
import {
  ADD_CDP_TO_CDPS,
  CREATE_CDP_SUCCESS,
} from '../../actionTypes/makerActionTypes/makerCreateCdpActionTypes';
import {
  GET_CDP_REQUEST,
  GET_CDP_SUCCESS,
  GET_CDP_FAILURE,

  SWITCH_CDP,

  GET_PROXY_ADDRESS_REQUEST,
  GET_PROXY_ADDRESS_SUCCESS,
  GET_PROXY_ADDRESS_FAILURE,
  ADD_PROXY_ADDRESS,

  GET_CDPS_REQUEST,
  GET_CDPS_SUCCESS,
  GET_CDPS_FAILURE,

  MIGRATE_CDP_FROM_ADDRESS_TO_PROXY_FAILURE,
  MIGRATE_CDP_FROM_ADDRESS_TO_PROXY_REQUEST,
  MIGRATE_CDP_FROM_ADDRESS_TO_PROXY_SUCCESS,
  MIGRATE_CDP_FROM_ADDRESS_TO_PROXY_RESET,

  CREATE_DS_PROXY_REQUEST,
  CREATE_DS_PROXY_SUCCESS,
  CREATE_DS_PROXY_FAILURE,

  MIGRATE_CDP_FROM_INSTADAPP_REQUEST,
  MIGRATE_CDP_FROM_INSTADAPP_SUCCESS,
  MIGRATE_CDP_FROM_INSTADAPP_FAILURE,
  MIGRATE_CDP_FROM_INSTADAPP_SUCCESS_SWITCH_CDP,

  GET_SAVER_SUBSCRIBE_GRAPH_DATA_REQUEST,
  GET_SAVER_SUBSCRIBE_GRAPH_DATA_SUCCESS,
  GET_SAVER_SUBSCRIBE_GRAPH_DATA_FAILURE,

  GET_CDP_PROFITABILITY_REQUEST,
  GET_CDP_PROFITABILITY_SUCCESS,
  GET_CDP_PROFITABILITY_FAILURE,

  CDP_LIQUIDATIONS_SUCCESS,

  CHANGE_PROXY_OWNER_FAILURE,
  CHANGE_PROXY_OWNER_REQUEST,
  CHANGE_PROXY_OWNER_SUCCESS,
} from '../../actionTypes/makerActionTypes/makerActionTypes';
import {
  CONNECT_PROVIDER_SUCCESS,
} from '../../actionTypes/generalActionTypes';
import {
  AUTOMATION_PERMISSION_FIX_SUCCESS,
  SAVER_SUBSCRIBE_SUCCESS,
  SAVER_UNSUBSCRIBE_SUCCESS,
} from '../../actionTypes/makerActionTypes/makerSaverActionTypes';
import {
  GET_CDP_HISTORY_REQUEST,
  GET_CDP_HISTORY_SUCCESS,
  GET_CDP_HISTORY_GRAPH_DATA_REQUEST,
  GET_CDP_HISTORY_GRAPH_DATA_SUCCESS,
} from '../../actions/makerActions/makerHistoryActions';
import {
  RECLAIM_COLLATERAL_SUCCESS,
  GET_OPEN_CDP_OPTIONS_REQUEST,
  GET_OPEN_CDP_OPTIONS_SUCCESS,
  GET_OPEN_CDP_OPTIONS_FAILURE,
} from '../../actionTypes/makerActionTypes/makerManageActionTypes/makerManageMcdActionTypes';

const CDP_RELATED_STATE = {
  migratingCdpFromAddress: false,
  migratingCdpFromAddressError: '',

  gettingStableData: false,
  gettingStableDataError: '',

  creatingDSProxy: false,
  creatingDSProxyError: '',

  migratingCdpFromInstadapp: false,
  migratingCdpFromInstadappError: '',

  graphData: null,
  gettingGraphData: false,
  gettingGraphDataError: '',

  gettingCdpProfitability: false,
  gettingCdpProfitabilityError: '',
  cdpProfitability: {
    valueImported: 0,
    valueExported: 0,
  },

  historyTxs: [],
  historyTxsLoading: false,

  cdpLiquidations: [],

  cdpOptions: [],
  gettingCdpOptions: false,
  gettingCdpOptionsError: '',
};

const INITIAL_STATE = {
  cdp: null,
  cdps: [],
  gettingCdp: false,
  gettingCdpError: '',
  gettingCdps: false,
  gettingCdpsError: '',

  gettingProxyAddress: false,
  gettingProxyAddressSuccess: false,
  gettingProxyAddressError: '',
  proxyAddress: '',

  historyGraphData: {},
  historyGraphDataLoading: false,

  changingProxyOwner: false,
  changingProxyOwnerErr: '',

  ...CDP_RELATED_STATE,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_CDP_REQUEST:
      return { ...state, gettingCdp: true };

    case GET_CDP_SUCCESS:
      return {
        ...state,
        gettingCdp: false,
        gettingCdpError: '',
        cdp: payload,
      };

    case GET_CDP_FAILURE:
      return {
        ...state,
        gettingCdp: false,
        gettingCdpError: payload,
      };

    case MAKER_ACTION_EXEC_SUCCESS:
    case MIGRATE_CDP_FROM_INSTADAPP_SUCCESS_SWITCH_CDP:
    case CREATE_CDP_SUCCESS:
    case AUTOMATION_PERMISSION_FIX_SUCCESS:
      return {
        ...state,
        gettingCdp: false,
        cdp: { ...payload },
      };

    case SWITCH_CDP:
      return {
        ...state,
        ...CDP_RELATED_STATE,
        gettingCdp: false,
        cdp: { ...payload },
      };

    case GET_CDPS_REQUEST:
      return { ...state, gettingCdps: true };

    case GET_CDPS_FAILURE:
      return {
        ...state,
        gettingCdps: false,
        gettingCdpsError: payload,
      };

    case GET_CDPS_SUCCESS:
    case ADD_CDP_TO_CDPS:
      return {
        ...state,
        gettingCdps: false,
        gettingCdpsError: '',
        cdps: payload,
      };

    case CLOSE_CDP_SUCCESS:
    case TRANSFER_CDP_SUCCESS:
      return { ...state, cdps: payload.cdps, cdp: payload.cdp };

    case GET_PROXY_ADDRESS_REQUEST:
      return { ...state, gettingProxyAddress: true, gettingProxyAddressError: '' };

    case GET_PROXY_ADDRESS_SUCCESS:
      return {
        ...state,
        gettingProxyAddress: false,
        gettingProxyAddressSuccess: true,
        gettingProxyAddressError: '',
      };

    case GET_PROXY_ADDRESS_FAILURE:
      return {
        ...state,
        gettingProxyAddress: false,
        gettingProxyAddressSuccess: false,
        gettingProxyAddressError: payload,
      };

    case ADD_PROXY_ADDRESS:
      return { ...state, proxyAddress: payload };

    case MIGRATE_CDP_FROM_ADDRESS_TO_PROXY_REQUEST:
      return { ...state, migratingCdpFromAddress: true, migratingCdpFromAddressError: '' };

    case MIGRATE_CDP_FROM_ADDRESS_TO_PROXY_SUCCESS:
      return {
        ...state,
        migratingCdpFromAddress: false,
        migratingCdpFromAddressError: '',
        cdp: payload,
      };

    case MIGRATE_CDP_FROM_ADDRESS_TO_PROXY_FAILURE:
      return {
        ...state,
        migratingCdpFromAddress: false,
        migratingCdpFromAddressError: payload,
      };

    case MIGRATE_CDP_FROM_ADDRESS_TO_PROXY_RESET:
      return {
        ...state,
        migratingCdpFromAddress: false,
        migratingCdpFromAddressError: '',
      };

    case CREATE_DS_PROXY_REQUEST:
      return { ...state, creatingDSProxy: true, creatingDSProxyError: '' };

    case CREATE_DS_PROXY_SUCCESS:
      return {
        ...state,
        creatingDSProxy: false,
        creatingDSProxyError: '',
      };

    case CREATE_DS_PROXY_FAILURE:
      return {
        ...state,
        creatingDSProxy: false,
        creatingDSProxyError: payload,
      };

    case MIGRATE_CDP_FROM_INSTADAPP_REQUEST:
      return {
        ...state,
        migratingCdpFromInstadapp: true,
        migratingCdpFromInstadappError: '',
      };

    case MIGRATE_CDP_FROM_INSTADAPP_SUCCESS:
      return {
        ...state,
        migratingCdpFromInstadapp: false,
        migratingCdpFromInstadappError: '',
      };

    case MIGRATE_CDP_FROM_INSTADAPP_FAILURE:
      return {
        ...state,
        migratingCdpFromInstadapp: false,
        migratingCdpFromInstadappError: payload,
      };

    case GET_SAVER_SUBSCRIBE_GRAPH_DATA_REQUEST:
      return { ...state, gettingGraphData: true, gettingGraphDataError: '' };

    case GET_SAVER_SUBSCRIBE_GRAPH_DATA_SUCCESS:
      return {
        ...state,
        gettingGraphData: false,
        gettingGraphDataError: '',
        graphData: state.graphData === null ? payload : { ...state.graphData, ...payload },
      };

    case GET_SAVER_SUBSCRIBE_GRAPH_DATA_FAILURE:
      return {
        ...state,
        gettingGraphData: false,
        gettingGraphDataError: payload,
      };

    case GET_CDP_PROFITABILITY_REQUEST:
      return { ...state, gettingCdpProfitability: true, gettingCdpProfitabilityError: '' };

    case GET_CDP_PROFITABILITY_SUCCESS:
      return {
        ...state,
        gettingCdpProfitability: false,
        gettingCdpProfitabilityError: '',
        cdpProfitability: payload,
      };

    case GET_CDP_PROFITABILITY_FAILURE:
      return {
        ...state,
        gettingCdpProfitability: false,
        gettingCdpProfitabilityError: payload,
      };

    case SAVER_SUBSCRIBE_SUCCESS:
      return {
        ...state,
        cdp: {
          ...state.cdp,
          isSubscribedToAutomation: true,
        },
      };

    case SAVER_UNSUBSCRIBE_SUCCESS:
      return {
        ...state,
        cdp: {
          ...state.cdp,
          isSubscribedToAutomation: false,
        },
      };

    case GET_CDP_HISTORY_REQUEST:
      return {
        ...state,
        historyTxsLoading: true,
      };

    case GET_CDP_HISTORY_SUCCESS:
      return {
        ...state,
        historyTxs: payload,
        historyTxsLoading: false,
      };

    case GET_CDP_HISTORY_GRAPH_DATA_REQUEST:
      return {
        ...state,
        historyGraphDataLoading: true,
      };

    case GET_CDP_HISTORY_GRAPH_DATA_SUCCESS:
      return {
        ...state,
        historyGraphDataLoading: false,
        historyGraphData: {
          ...state.historyGraphData,
          [payload.ilk]: { ...payload.data },
        },
      };

    case RECLAIM_COLLATERAL_SUCCESS:
      return {
        ...state,
        cdp: {
          ...state.cdp,
          collateral: state.cdp.unclaimedCollateral,
          unclaimedCollateral: '0',
        },
      };

    case CDP_LIQUIDATIONS_SUCCESS:
      return {
        ...state,
        cdpLiquidations: payload,
      };

    case CONNECT_PROVIDER_SUCCESS:
      return {
        ...INITIAL_STATE,
        proxyAddress: payload.proxyAddress || '',
      };

    case GET_OPEN_CDP_OPTIONS_REQUEST:
      return {
        ...state,
        gettingCdpOptions: true,
        gettingCdpOptionsError: '',
      };

    case GET_OPEN_CDP_OPTIONS_SUCCESS:
      return {
        ...state,
        gettingCdpOptions: false,
        cdpOptions: payload,
      };
    case GET_OPEN_CDP_OPTIONS_FAILURE:
      return {
        ...state,
        gettingCdpOptions: false,
        gettingCdpOptionsError: payload,
      };

    case CHANGE_PROXY_OWNER_REQUEST: return {
      ...state,
      changingProxyOwner: true,
      changingProxyOwnerErr: '',
    };

    case CHANGE_PROXY_OWNER_SUCCESS: return {
      ...state,
      changingProxyOwner: false,
      changingProxyOwnerErr: '',
    };

    case CHANGE_PROXY_OWNER_FAILURE: return {
      ...state,
      changingProxyOwner: false,
      changingProxyOwnerErr: payload,
    };

    default:
      return state;
  }
};
