import React, { useEffect, useMemo, useState } from 'react';
import Dec from 'decimal.js';
import t from 'translate';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getBoostModalData, compoundBoostAction } from '../../../../actions/compoundActions/compoundManageActions';
import { resetBoostModal } from '../../../../actions/makerActions/makerManageActions/makerManageActions';
import { getDashboardInputs } from '../../../../actions/dashboardActions';

import { CompoundBoostSlippageLimitForm as SlippageLimitForm } from '../../BoostRepayCommon/SlippageLimitForm/SlippageLimitForm';
import TooltipWrapper from '../../../TooltipWrapper/TooltipWrapper';
import ModalHeader from '../../ModalHeader';
import ModalBody from '../../ModalBody';
import Loader from '../../../Loader/Loader';
import ErrorBox from '../../../Decorative/ErrorBox/ErrorBox';
import TradeSizeImpactWarning from '../../../TradeSizeImpactWarning/TradeSizeImpactWarning';
import InfoIcon from '../../../Decorative/InfoIcon';
import ActionInfo from '../../BoostRepayCommon/ActionInfo';
import AdvancedOptions from '../../BoostRepayCommon/AdvancedOptions/AdvancedOptions';
import AdditionalInfo from '../../../AdditionalInfo/AdditionalInfo';

const CompoundBoostModal = ({
  closeModal, gettingBoostModalData, gettingBoostModalDataError, boostAmount, boostExchangeRate, boosting,
  boostAction, tradeSizeImpact, boostingError, getBoostModalData, resetBoostModal,
  useFl, flProtocol, exchangeSource, flFee, getDashboardInputs, additionalAction, contextAction,
}) => {
  const {
    firstInput: inputAmount, secondAction, secondInput, firstInputSelect, firstInputSecondSelect, secondInputSelect,
  } = useMemo(() => getDashboardInputs(contextAction, additionalAction), []);

  const borrowAsset = firstInputSelect?.value;
  const supplyAsset = firstInputSecondSelect?.value;

  const [advanced, setAdvanced] = useState(false);
  useEffect(() => {
    getBoostModalData(inputAmount, borrowAsset, supplyAsset);
    return resetBoostModal;
  }, []);

  const shouldFlip = new Dec(boostExchangeRate).lt(new Dec(1).div(boostExchangeRate).toString());
  const exchangeLabel = shouldFlip
    ? `${borrowAsset}/${supplyAsset}`
    : `${supplyAsset}/${borrowAsset}`;
  const correctedBoostExchangeRate = shouldFlip
    ? new Dec(1).div(boostExchangeRate).toString()
    : boostExchangeRate;

  const isSameAsset = borrowAsset === supplyAsset;

  return (
    <div className="boost-repay-modal">
      <ModalHeader closeModal={closeModal} />

      <ModalBody>
        <div className="new-modal-top-wrapper">
          <h1>
            <TooltipWrapper title={t('compound.boost_modal_description', { '%asset1': borrowAsset, '%asset2': supplyAsset })}>
              <InfoIcon /> {t('common.boost')} {additionalAction?.value && `+ ${additionalAction?.value === 'boost' ? contextAction.label : additionalAction.label}`}
            </TooltipWrapper>
          </h1>
        </div>

        <div className="new-modal-content-wrapper">

          {gettingBoostModalData && (<div className="container"><div className="loading-wrapper"><Loader /></div></div>)}

          {!gettingBoostModalData && gettingBoostModalDataError && (<ErrorBox>{gettingBoostModalDataError}</ErrorBox>)}

          {!gettingBoostModalData && !gettingBoostModalDataError && (
            <>
              <ActionInfo
                firstAsset={borrowAsset}
                firstAmount={inputAmount}
                secondAsset={supplyAsset}
                secondAmount={boostAmount}
                isSameAsset={isSameAsset}
                exchangeRate={correctedBoostExchangeRate}
                exchangeLabel={exchangeLabel}
                additionalAction={secondAction}
                additionalInput={secondInput}
                additionalAsset={secondInputSelect?.value}
              />

              <div className="advanced__wrapper container">
                {!isSameAsset && (
                  <>
                    <TradeSizeImpactWarning tradeSizeImpact={tradeSizeImpact} />
                  </>
                )}
                {!isSameAsset && (
                  <AdvancedOptions
                    borrowAsset={borrowAsset}
                    supplyAsset={supplyAsset}
                    advanced={advanced}
                    setAdvanced={setAdvanced}
                    exchangeRate={boostExchangeRate}
                    SlippageLimitForm={SlippageLimitForm}
                  />
                )}
                <AdditionalInfo
                  isSameAsset={isSameAsset}
                  flashloanSource={useFl ? flProtocol : ''}
                  flashloanFee={flFee}
                  exchangeSource={exchangeSource}
                  additionalAction={secondAction}
                  additionalInput={secondInput}
                  asset1={supplyAsset}
                  asset2={borrowAsset}
                />
                {boostingError && (<ErrorBox marginTop>{boostingError}</ErrorBox>)}
              </div>
            </>
          )}
        </div>
      </ModalBody>

      <div className="modal-controls">
        <button
          form="compound-boost-modal-form"
          type="button"
          disabled={boosting || gettingBoostModalData}
          onClick={() => boostAction(contextAction, additionalAction, closeModal)}
          className="button green"
        >
          {boosting ? t('common.boosting') : t('common.boost')}
        </button>
      </div>
    </div>
  );
};

CompoundBoostModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  tradeSizeImpact: PropTypes.string.isRequired,

  getBoostModalData: PropTypes.func.isRequired,
  gettingBoostModalData: PropTypes.bool.isRequired,
  gettingBoostModalDataError: PropTypes.string.isRequired,

  boostAmount: PropTypes.string.isRequired,
  boostExchangeRate: PropTypes.string.isRequired,

  boostAction: PropTypes.func.isRequired,
  resetBoostModal: PropTypes.func.isRequired,
  boosting: PropTypes.bool.isRequired,

  boostingError: PropTypes.string.isRequired,

  useFl: PropTypes.bool.isRequired,
  flProtocol: PropTypes.string.isRequired,
  flFee: PropTypes.string.isRequired,
  exchangeSource: PropTypes.string.isRequired,

  getDashboardInputs: PropTypes.func.isRequired,
  additionalAction: PropTypes.object,
  contextAction: PropTypes.object,
};

const mapStateToProps = ({ compoundManage }) => ({
  tradeSizeImpact: compoundManage.tradeSizeImpact,
  gettingBoostModalData: compoundManage.gettingBoostModalData,
  gettingBoostModalDataError: compoundManage.gettingBoostModalDataError,
  boostAmount: compoundManage.boostAmount,
  boostExchangeRate: compoundManage.boostExchangeRate,
  boosting: compoundManage.executing?.boost || (compoundManage.executing[compoundManage.selectedAction?.value] && compoundManage.selectedAdditionalActions[compoundManage.selectedAction?.value]?.value === 'boost'),
  boostingError: compoundManage.executingErrors?.boost,
  useFl: compoundManage.useFl,
  flProtocol: compoundManage.flProtocol,
  flFee: compoundManage.flFee,
  exchangeSource: compoundManage.exchangeSource,
});

const mapDispatchToProps = {
  getBoostModalData,
  resetBoostModal,
  boostAction: compoundBoostAction,
  getDashboardInputs,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompoundBoostModal);
