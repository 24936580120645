export const LIQUITY_NORMAL_MODE_RATIO = 110; // MCR
export const LIQUITY_RECOVERY_MODE_RATIO = 150; // CCR

export const LIQUITY_LIQ_RESERVE_LUSD = 200; // LUSD_GAS_COMPENSATION
export const LIQUITY_MIN_NET_DEBT = 1800; // MIN_NET_DEBT
export const LIQUITY_MIN_DEBT_LUSD = LIQUITY_MIN_NET_DEBT + LIQUITY_LIQ_RESERVE_LUSD;

export const LIQUITY_KICKBACK_RATE = 100;

export const LIQUITY_TROVE_STATUS_ENUM = ['nonExistent', 'active', 'closedByOwner', 'closedByLiquidation', 'closedByRedemption'];

export const LIQUITY_STATUS_MAPPING = {
  nonExistent: 'Non existent',
  active: 'Active',
  closedByOwner: 'Closed',
  closedByLiquidation: 'Liquidated',
  closedByRedemption: 'Redeemed',
};
