import React from 'react';

const MStableProtocolIcon = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 19.6309H24.8135V23.5323H0V19.6309Z" fill="white" />
    <path d="M0.154778 3.36301C0.154778 2.34854 0.0928667 1.36457 0 0.348767H4.42019L4.5737 2.41219C5.0651 1.71333 6.26466 0 9.38215 0C12.9627 0 13.9803 2.25439 14.2834 3.01425C15.5733 0.444245 17.6783 0.0318266 19.2841 0.0318266C22.4312 0.0318266 23.6965 1.96795 24.0357 2.60183C24.869 3.99822 24.8096 5.93301 24.8096 7.3294V16.1056H20.214V6.95545C20.214 5.24211 19.9677 3.33781 17.5918 3.33781C14.8445 3.33781 14.7826 5.97149 14.7826 8.35183V16.1056H10.1844V6.85331C10.1844 5.61606 10.1535 3.33119 7.62281 3.33119C4.753 3.33119 4.753 5.87067 4.753 6.95545V16.1056H0.154778V3.36301Z" fill="white" />
  </svg>
);

export default MStableProtocolIcon;
