export const FETCH_AAVE_MANAGE_DATA_REQUEST = 'FETCH_AAVE_MANAGE_DATA_REQUEST';
export const FETCH_AAVE_MANAGE_DATA_SUCCESS = 'FETCH_AAVE_MANAGE_DATA_SUCCESS';
export const FETCH_AAVE_MANAGE_DATA_FAILURE = 'FETCH_AAVE_MANAGE_DATA_FAILURE';

export const FETCH_AAVE_ASSETS_DATA_REQUEST = 'FETCH_AAVE_ASSETS_DATA_REQUEST';
export const FETCH_AAVE_ASSETS_DATA_SUCCESS = 'FETCH_AAVE_ASSETS_DATA_SUCCESS';
export const FETCH_AAVE_ASSETS_DATA_FAILURE = 'FETCH_AAVE_ASSETS_DATA_FAILURE';

export const FETCH_AAVE_ACCOUNT_DATA_REQUEST = 'FETCH_AAVE_ACCOUNT_DATA_REQUEST';
export const FETCH_AAVE_ACCOUNT_DATA_SUCCESS = 'FETCH_AAVE_ACCOUNT_DATA_SUCCESS';
export const FETCH_AAVE_ACCOUNT_DATA_FAILURE = 'FETCH_AAVE_ACCOUNT_DATA_FAILURE';

export const GET_AAVE_AFTER_VALUE_REQUEST = 'GET_AAVE_AFTER_VALUE_REQUEST';
export const GET_AAVE_AFTER_VALUE_SUCCESS = 'GET_AAVE_AFTER_VALUE_SUCCESS';
export const GET_AAVE_AFTER_VALUE_FAILURE = 'GET_AAVE_AFTER_VALUE_FAILURE';

export const ENABLE_AAVE_ASSET_AS_COLLATERAL_REQUEST = 'ENABLE_AAVE_ASSET_AS_COLLATERAL_REQUEST';
export const ENABLE_AAVE_ASSET_AS_COLLATERAL_SUCCESS = 'ENABLE_AAVE_ASSET_AS_COLLATERAL_SUCCESS';
export const ENABLE_AAVE_ASSET_AS_COLLATERAL_FAILURE = 'ENABLE_AAVE_ASSET_AS_COLLATERAL_FAILURE';

export const DISABLE_AAVE_ASSET_AS_COLLATERAL_REQUEST = 'DISABLE_AAVE_ASSET_AS_COLLATERAL_REQUEST';
export const DISABLE_AAVE_ASSET_AS_COLLATERAL_SUCCESS = 'DISABLE_AAVE_ASSET_AS_COLLATERAL_SUCCESS';
export const DISABLE_AAVE_ASSET_AS_COLLATERAL_FAILURE = 'DISABLE_AAVE_ASSET_AS_COLLATERAL_FAILURE';

export const AAVE_SUPPLY_REQUEST = 'AAVE_SUPPLY_REQUEST';
export const AAVE_SUPPLY_SUCCESS = 'AAVE_SUPPLY_SUCCESS';
export const AAVE_SUPPLY_FAILURE = 'AAVE_SUPPLY_FAILURE';

export const AAVE_WITHDRAW_REQUEST = 'AAVE_WITHDRAW_REQUEST';
export const AAVE_WITHDRAW_SUCCESS = 'AAVE_WITHDRAW_SUCCESS';
export const AAVE_WITHDRAW_FAILURE = 'AAVE_WITHDRAW_FAILURE';

export const GET_MAX_AAVE_WITHDRAW_REQUEST = 'GET_MAX_AAVE_WITHDRAW_REQUEST';
export const GET_MAX_AAVE_WITHDRAW_SUCCESS = 'GET_MAX_AAVE_WITHDRAW_SUCCESS';
export const GET_MAX_AAVE_WITHDRAW_FAILURE = 'GET_MAX_AAVE_WITHDRAW_FAILURE';

export const AAVE_BORROW_REQUEST = 'AAVE_BORROW_REQUEST';
export const AAVE_BORROW_SUCCESS = 'AAVE_BORROW_SUCCESS';
export const AAVE_BORROW_FAILURE = 'AAVE_BORROW_FAILURE';

export const GET_MAX_AAVE_BORROW_REQUEST = 'GET_MAX_AAVE_BORROW_REQUEST';
export const GET_MAX_AAVE_BORROW_SUCCESS = 'GET_MAX_AAVE_BORROW_SUCCESS';
export const GET_MAX_AAVE_BORROW_FAILURE = 'GET_MAX_AAVE_BORROW_FAILURE';

export const ENABLE_AAVE_BORROW_REQUEST = 'ENABLE_AAVE_BORROW_REQUEST';
export const ENABLE_AAVE_BORROW_SUCCESS = 'ENABLE_AAVE_BORROW_SUCCESS';
export const ENABLE_AAVE_BORROW_FAILURE = 'ENABLE_AAVE_BORROW_FAILURE';

export const GET_MAX_AAVE_SUPPLY_REQUEST = 'GET_MAX_AAVE_SUPPLY_REQUEST';
export const GET_MAX_AAVE_SUPPLY_SUCCESS = 'GET_MAX_AAVE_SUPPLY_SUCCESS';
export const GET_MAX_AAVE_SUPPLY_FAILURE = 'GET_MAX_AAVE_SUPPLY_FAILURE';

export const AAVE_PAYBACK_REQUEST = 'AAVE_PAYBACK_REQUEST';
export const AAVE_PAYBACK_SUCCESS = 'AAVE_PAYBACK_SUCCESS';
export const AAVE_PAYBACK_FAILURE = 'AAVE_PAYBACK_FAILURE';

export const GET_MAX_AAVE_PAYBACK_REQUEST = 'GET_MAX_AAVE_PAYBACK_REQUEST';
export const GET_MAX_AAVE_PAYBACK_SUCCESS = 'GET_MAX_AAVE_PAYBACK_SUCCESS';
export const GET_MAX_AAVE_PAYBACK_FAILURE = 'GET_MAX_AAVE_PAYBACK_FAILURE';

export const GET_MAX_AAVE_BOOST_REQUEST = 'GET_MAX_AAVE_BOOST_REQUEST';
export const GET_MAX_AAVE_BOOST_SUCCESS = 'GET_MAX_AAVE_BOOST_SUCCESS';
export const GET_MAX_AAVE_BOOST_FAILURE = 'GET_MAX_AAVE_BOOST_FAILURE';

export const GET_BOOST_MODAL_DATA_REQUEST = 'GET_BOOST_MODAL_DATA_REQUEST';
export const GET_BOOST_MODAL_DATA_SUCCESS = 'GET_BOOST_MODAL_DATA_SUCCESS';
export const GET_BOOST_MODAL_DATA_FAILURE = 'GET_BOOST_MODAL_DATA_FAILURE';
export const RESET_BOOST_MODAL = 'RESET_BOOST_MODAL';

export const AAVE_BOOST_REQUEST = 'AAVE_BOOST_REQUEST';
export const AAVE_BOOST_SUCCESS = 'AAVE_BOOST_SUCCESS';
export const AAVE_BOOST_FAILURE = 'AAVE_BOOST_FAILURE';

export const GET_MAX_AAVE_REPAY_REQUEST = 'GET_MAX_AAVE_REPAY_REQUEST';
export const GET_MAX_AAVE_REPAY_SUCCESS = 'GET_MAX_AAVE_REPAY_SUCCESS';
export const GET_MAX_AAVE_REPAY_FAILURE = 'GET_MAX_AAVE_REPAY_FAILURE';

export const GET_REPAY_MODAL_DATA_FAILURE = 'GET_REPAY_MODAL_DATA_FAILURE';
export const GET_REPAY_MODAL_DATA_REQUEST = 'GET_REPAY_MODAL_DATA_REQUEST';
export const GET_REPAY_MODAL_DATA_SUCCESS = 'GET_REPAY_MODAL_DATA_SUCCESS';
export const RESET_REPAY_MODAL = 'RESET_REPAY_MODAL';

export const AAVE_REPAY_REQUEST = 'AAVE_REPAY_REQUEST';
export const AAVE_REPAY_SUCCESS = 'AAVE_REPAY_SUCCESS';
export const AAVE_REPAY_FAILURE = 'AAVE_REPAY_FAILURE';

export const AAVE_MANAGE_SET_ACTIVE_TAB = 'AAVE_MANAGE_SET_ACTIVE_TAB';

export const AAVE_MANAGE_SET_ACTION_SELECT_VALUES = 'AAVE_MANAGE_SET_ACTION_SELECT_VALUES';

export const MIGRATE_AAVE_V1_TO_PROXY_REQUEST = 'MIGRATE_AAVE_V1_TO_PROXY_REQUEST';
export const MIGRATE_AAVE_V1_TO_PROXY_SUCCESS = 'MIGRATE_AAVE_V1_TO_PROXY_SUCCESS';
export const MIGRATE_AAVE_V1_TO_PROXY_FAILURE = 'MIGRATE_AAVE_V1_TO_PROXY_FAILURE';

export const SET_AAVE_BORROW_INTEREST_RATE_SUCCESS = 'SET_AAVE_BORROW_INTEREST_RATE_SUCCESS';

export const AAVE_SWAP_INTEREST_RATE_MODE_REQUEST = 'AAVE_SWAP_INTEREST_RATE_MODE_REQUEST';
export const AAVE_SWAP_INTEREST_RATE_MODE_SUCCESS = 'AAVE_SWAP_INTEREST_RATE_MODE_SUCCESS';
export const AAVE_SWAP_INTEREST_RATE_MODE_FAILURE = 'AAVE_SWAP_INTEREST_RATE_MODE_FAILURE';

export const SET_SELECTED_MARKET_ACTION = 'SET_SELECTED_MARKET_ACTION';

export const GET_AAVE_MARKET_PROVIDERS_REQUEST = 'GET_AAVE_MARKET_PROVIDERS_REQUEST';
export const GET_AAVE_MARKET_PROVIDERS_SUCCESS = 'GET_AAVE_MARKET_PROVIDERS_SUCCESS';
export const GET_AAVE_MARKET_PROVIDERS_FAILURE = 'GET_AAVE_MARKET_PROVIDERS_FAILURE';


export const GET_STAKE_AAVE_BALANCE_AND_COOLDOWN_REQUEST = 'GET_STAKE_AAVE_BALANCE_AND_COOLDOWN_REQUEST';
export const GET_STAKE_AAVE_BALANCE_AND_COOLDOWN_SUCCESS = 'GET_STAKE_AAVE_BALANCE_AND_COOLDOWN_SUCCESS';
export const GET_STAKE_AAVE_BALANCE_AND_COOLDOWN_FAILURE = 'GET_STAKE_AAVE_BALANCE_AND_COOLDOWN_FAILURE';

export const AAVE_STAKE_REQUEST = 'AAVE_STAKE_REQUEST';
export const AAVE_STAKE_SUCCESS = 'AAVE_STAKE_SUCCESS';
export const AAVE_STAKE_FAILURE = 'AAVE_STAKE_FAILURE';

export const AAVE_COOLDOWN_REQUEST = 'AAVE_COOLDOWN_REQUEST';
export const AAVE_COOLDOWN_SUCCESS = 'AAVE_COOLDOWN_SUCCESS';
export const AAVE_COOLDOWN_FAILURE = 'AAVE_COOLDOWN_FAILURE';

export const AAVE_UNSTAKE_REQUEST = 'AAVE_UNSTAKE_REQUEST';
export const AAVE_UNSTAKE_SUCCESS = 'AAVE_UNSTAKE_SUCCESS';
export const AAVE_UNSTAKE_FAILURE = 'AAVE_UNSTAKE_FAILURE';

export const MIGRATE_LEND_TO_AAVE_REQUEST = 'MIGRATE_LEND_TO_AAVE_REQUEST';
export const MIGRATE_LEND_TO_AAVE_SUCCESS = 'MIGRATE_LEND_TO_AAVE_SUCCESS';
export const MIGRATE_LEND_TO_AAVE_FAILURE = 'MIGRATE_LEND_TO_AAVE_FAILURE';

export const CLAIM_REWARDS_REQUEST = 'CLAIM_REWARDS_REQUEST';
export const CLAIM_REWARDS_SUCCESS = 'CLAIM_REWARDS_SUCCESS';
export const CLAIM_REWARDS_FAILURE = 'CLAIM_REWARDS_FAILURE';

export const GET_AAVE_MARKET_TOKENS_REQUEST = 'GET_AAVE_MARKET_TOKENS_REQUEST';
export const GET_AAVE_MARKET_TOKENS_SUCCESS = 'GET_AAVE_MARKET_TOKENS_SUCCESS';
export const GET_AAVE_MARKET_TOKENS_FAILURE = 'GET_AAVE_MARKET_TOKENS_FAILURE';

export const NEW_ATOKEN_APPROVED_REQUEST = 'NEW_ATOKEN_APPROVED_REQUEST';
export const NEW_ATOKEN_APPROVED_SUCCESS = 'NEW_ATOKEN_APPROVED_SUCCESS';
export const NEW_ATOKEN_APPROVED_FAILURE = 'NEW_ATOKEN_APPROVED_FAILURE';

export const MIGRATE_AAVE_FROM_V1_TO_V2_REQUEST = 'MIGRATE_AAVE_FROM_V1_TO_V2_REQUEST';
export const MIGRATE_AAVE_FROM_V1_TO_V2_SUCCESS = 'MIGRATE_AAVE_FROM_V1_TO_V2_SUCCESS';
export const MIGRATE_AAVE_FROM_V1_TO_V2_FAILURE = 'MIGRATE_AAVE_FROM_V1_TO_V2_FAILURE';


// NEW
export const AAVE_SET_DASHBOARD_ACTION = 'AAVE_SET_DASHBOARD_ACTION';
export const AAVE_SET_ADDITIONAL_DASHBOARD_ACTION = 'AAVE_SET_ADDITIONAL_DASHBOARD_ACTION';

export const AAVE_ACTION_EXEC_REQUEST = 'AAVE_ACTION_EXEC_REQUEST';
export const AAVE_ACTION_EXEC_SUCCESS = 'AAVE_ACTION_EXEC_SUCCESS';
export const AAVE_ACTION_EXEC_FAILURE = 'AAVE_ACTION_EXEC_FAILURE';

export const AAVE_GET_MAX_VALUE_REQUEST = 'AAVE_GET_MAX_VALUE_REQUEST';
export const AAVE_GET_MAX_VALUE_SUCCESS = 'AAVE_GET_MAX_VALUE_SUCCESS';
export const AAVE_GET_MAX_VALUE_FAILURE = 'AAVE_GET_MAX_VALUE_FAILURE';
