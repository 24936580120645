import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import t from 'translate';
import PropTypes from 'prop-types';
import closeIllustration from './close-illustration.png';
import closeIcon from './closeIcon.svg';
import CloseMcdCdpForm from '../CloseMcdCdpForm/CloseMcdCdpForm';
import ErrorBox from '../../../../Decorative/ErrorBox/ErrorBox';
import WarningBox from '../../../../Decorative/WarningBox/WarningBox';

import './CloseMcdCdpPicker.scss';
import walletIcon from '../../../UserWalletModal/wallet-icon-circle.svg';

const CloseMcdCdpPicker = ({
  closeModal, closing, error, cdpId, isSubscribedToAutomation,
}) => (
  <div className="close-mcd-cdp-picker-wrapper">
    <div className="new-modal-top-wrapper" style={{ backgroundImage: `url(${closeIcon})` }}>
      <h1 className="title">
        { closing ? t('common.closing') : t('common.close') } CDP #{cdpId}
      </h1>
    </div>
    <div className="new-modal-content-wrapper">
      <>
        <div className="descriptions">
          <div className="description">{ t('maker.close_mcd_cdp_description') }</div>
        </div>

        { isSubscribedToAutomation && (<WarningBox marginBottom>{t('maker.close_cdp_automation')}</WarningBox>) }

        <CloseMcdCdpForm closeModal={closeModal} />

        { error && <ErrorBox>{ error }</ErrorBox> }
      </>
    </div>
  </div>
);

CloseMcdCdpPicker.propTypes = {
  closeModal: PropTypes.func.isRequired,
  closing: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  cdpId: PropTypes.number.isRequired,
  isSubscribedToAutomation: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ maker }) => ({
  cdpId: maker.cdp.id,
  isSubscribedToAutomation: maker.cdp.isSubscribedToAutomation,
});

export default connect(mapStateToProps)(CloseMcdCdpPicker);
