import React, { useEffect, useMemo, useState } from 'react';
import t from 'translate';
import Dec from 'decimal.js';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ModalHeader from '../ModalHeader';
import ModalBody from '../ModalBody';
import TooltipWrapper from '../../TooltipWrapper/TooltipWrapper';
import InfoIcon from '../../Decorative/InfoIcon';
import Loader from '../../Loader/Loader';
import ErrorBox from '../../Decorative/ErrorBox/ErrorBox';
import TradeSizeImpactWarning from '../../TradeSizeImpactWarning/TradeSizeImpactWarning';
import { SmartSavingsSlippageLimitForm as SlippageLimitForm } from '../BoostRepayCommon/SlippageLimitForm/SlippageLimitForm';
import AdditionalInfo from '../../AdditionalInfo/AdditionalInfo';
import { getDashboardInputs } from '../../../actions/dashboardActions';
import ActionInfo from './ActionInfo';
import AdvancedOptions from '../BoostRepayCommon/AdvancedOptions/AdvancedOptions';

const SmartSavingsClaimAndSupplyModal = ({
  closeModal, contextAction,
  actions: {
    getInfo,
    execute,
  },
}) => {
  const { firstAction, firstInput } = useMemo(() => getDashboardInputs(contextAction), []);
  const [info, setInfo] = useState(undefined);
  const [advanced, setAdvanced] = useState(false);
  const account = useSelector(({ general }) => general.account);
  const proxyAddress = useSelector(({ maker }) => maker.proxyAddress);
  const executing = useSelector(({ savingsManage }) => savingsManage.executing?.mstable_mta?.claim);
  const executingError = useSelector(({ savingsManage }) => savingsManage.executingErrors?.mstable_mta?.claim);
  useEffect(() => {
    getInfo(firstAction, firstInput, account, proxyAddress).then(setInfo).catch(setInfo);
  }, []);
  const loading = info === undefined;
  const err = info instanceof Error;
  return (
    <div className="boost-repay-modal">
      <ModalHeader closeModal={closeModal} />

      <ModalBody>
        <div className="new-modal-top-wrapper">
          <h1>
            <TooltipWrapper title={t('savings.claim_supply')}>
              <InfoIcon /> {`${t('common.claim')} + ${t('common.supply')}`}
            </TooltipWrapper>
          </h1>
        </div>

        <div className="new-modal-content-wrapper">

          {loading && (<div className="container"><div className="loading-wrapper"><Loader /></div></div>)}

          {err && (<ErrorBox>{info.message}</ErrorBox>)}

          {!loading && !err && (
            <>
              <ActionInfo
                firstAsset={info.fromAsset}
                firstAmount={info.fromAmount}
                secondAsset={info.toAsset}
                secondAmount={info.toAmount}
                exchangeRate={new Dec(1).div(info.exchangeRate).toString()}
                exchangeLabel={`${info.fromAsset}/${info.toAsset}`}
              />

              <div className="advanced__wrapper container">
                <TradeSizeImpactWarning tradeSizeImpact={info.tradeSizeImpact} />
                <AdvancedOptions
                  advanced={advanced}
                  setAdvanced={setAdvanced}
                  exchangeRate={info.exchangeRate}
                  borrowAsset={info.fromAsset}
                  supplyAsset={info.toAsset}
                  SlippageLimitForm={SlippageLimitForm}
                />
                <AdditionalInfo
                  flashloanSource=""
                  flashloanFee={0}
                  exchangeSource={info.exchangeSource}
                  asset1={info.fromAsset}
                  asset2={info.toAsset}
                />
                {executingError && (<ErrorBox marginTop>{executingError}</ErrorBox>)}
              </div>
            </>
          )}
        </div>
      </ModalBody>
      <div className="modal-controls">
        <button
          form="maker-boost-modal-form"
          type="button"
          disabled={loading || err || executing}
          onClick={() => execute(info.toAsset, info.exchangeRate)}
          className="button green"
        >
          {executing ? `${t('common.claiming')} + ${t('common.supplying')}` : `${t('common.claim')} + ${t('common.supply')}`}
        </button>
      </div>
    </div>
  );
};

SmartSavingsClaimAndSupplyModal.defaultProps = {};

SmartSavingsClaimAndSupplyModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  contextAction: PropTypes.object,
  actions: PropTypes.shape({
    getInfo: PropTypes.func.isRequired,
    execute: PropTypes.func.isRequired,
  }).isRequired,
};

export default SmartSavingsClaimAndSupplyModal;
