import { getCdpsAction } from './makerActions/makerActions';
import { getUserSafesAction } from './reflexerActions/reflexerActions';
import { fetchAaveAssetsDataForMarket, fetchAccountDataForWalletTypeAndMarket } from './aaveActions/aaveManageActionsV2';
import { fetchCompoundAssetsData, switchToUserWallet } from './compoundActions/compoundManageActions';
import { dispatchLiquityInitActions } from './liquityActions/liquityActions';
import { getStakedBalancesAndRewardsAction } from './liquityActions/liquityStakingActions';
import { getNftIdsAction } from './uniswapV3Actions';
import { getNumOfYearnVaultsForAssetsAction } from './yearnActions';

export const preloadForRecipe = ({ actions }) => (dispatch, getState) => {
  const preload = [...new Set([...actions.map(Action => Action.protocol).filter(i => !!i)])];
  const proxyAddress = getState().maker.proxyAddress;
  return Promise.all([
    // getAssetsBalancesFromApiAction(),
    preload.includes('maker') && dispatch(getCdpsAction()),
    preload.includes('reflexer') && dispatch(getUserSafesAction()),
    preload.includes('aavev2') && dispatch(fetchAaveAssetsDataForMarket('v2default')).then(() => dispatch(fetchAccountDataForWalletTypeAndMarket('proxy', proxyAddress, 'v2default'))),
    preload.includes('compound') && dispatch(fetchCompoundAssetsData()).then(() => dispatch(switchToUserWallet())),
    preload.includes('liquity') && dispatch(dispatchLiquityInitActions()),
    preload.includes('liquity') && dispatch(getStakedBalancesAndRewardsAction()),
    preload.includes('uniswap') && dispatch(getNftIdsAction()),
    preload.includes('yearn') && dispatch(getNumOfYearnVaultsForAssetsAction()),
  ]);
};
