/* eslint-disable */
import React from 'react';

export default function DefaultProtocolIcon(props) {
  return (
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M24.3623 5.50584C24.0173 5.04083 23.3433 5.01934 22.9338 5.42878L18.8106 9.55205C18.4645 9.89809 18.4289 10.4401 18.6524 10.8754C19.497 12.5203 19.497 14.4776 18.6524 16.1225C18.4289 16.5578 18.4645 17.0998 18.8106 17.4459L22.9338 21.5691C23.3433 21.9786 24.0173 21.9571 24.3623 21.4921C27.8794 16.7519 27.8794 10.2459 24.3623 5.50584Z" fill="white"/>
      <path d="M5.50657 2.63785C5.04157 2.98288 5.02007 3.65689 5.42951 4.06633L9.55278 8.1896C9.89882 8.53563 10.4408 8.57124 10.8762 8.34771C12.5211 7.50311 14.4783 7.50311 16.1232 8.34771C16.5585 8.57124 17.1005 8.53563 17.4466 8.1896L21.5698 4.06633C21.9793 3.65689 21.9578 2.98288 21.4928 2.63785C16.7526 -0.879282 10.2467 -0.879282 5.50657 2.63785Z" fill="white"/>
      <path d="M17.4466 18.8108C17.1005 18.4648 16.5585 18.4292 16.1232 18.6527C14.4783 19.4973 12.5211 19.4973 10.8762 18.6527C10.4408 18.4292 9.89882 18.4648 9.55278 18.8108L5.42951 22.9341C5.02007 23.3435 5.04157 24.0175 5.50657 24.3626C10.2467 27.8797 16.7526 27.8797 21.4928 24.3626C21.9578 24.0175 21.9793 23.3435 21.5698 22.9341L17.4466 18.8108Z" fill="white"/>
      <path d="M8.34771 10.8754C8.57124 10.4401 8.53564 9.89809 8.1896 9.55205L4.06633 5.42878C3.65689 5.01934 2.98288 5.04083 2.63785 5.50584C-0.879283 10.2459 -0.879282 16.7519 2.63785 21.4921C2.98288 21.9571 3.65689 21.9786 4.06633 21.5691L8.1896 17.4459C8.53564 17.0998 8.57124 16.5578 8.34771 16.1225C7.50311 14.4776 7.50311 12.5203 8.34771 10.8754Z" fill="white"/>
    </svg>
  );
}
