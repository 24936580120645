import Dec from 'decimal.js';
import { isAddress } from '../../utils';
import { openCreateSmartWalletModal } from '../../../actions/modalActions';

export default class SavingsGetter {
  slug = '';

  name = '';

  description = '';

  tokenContract = null;

  availableAssets = [];

  suppliedAsset = '';

  ProtocolIcon = null;

  moveOptions = [];

  isLegacy = false;

  constructor(slug, name, availableAssets, suppliedAsset, ProtocolIcon) {
    this.slug = slug;
    this.name = name;
    this.availableAssets = availableAssets;
    this.suppliedAsset = suppliedAsset;
    this.ProtocolIcon = ProtocolIcon;
  }

  getImportAction(account, proxyAddress, hasSmartWallet, dispatch) {
  }

  getWithdrawAction(account, proxyAddress, hasSmartWallet, dispatch) {
  }

  getSupplyAction(account, proxyAddress, hasSmartWallet, dispatch) {
  }

  getMoveAction(account, proxyAddress, hasSmartWallet, dispatch) {
  }

  getClaimAction(account, proxyAddress, hasSmartWallet, dispatch) {
  }

  // eslint-disable-next-line no-empty-function
  async getMoveSupplyActions(amount, asset, from, to, estimateAmount) {
  }

  getCreateSmartWalletAction(dispatch) {
    return () => dispatch(openCreateSmartWalletModal());
  }

  getActions(supplied, supppliedEoa, claimable, account, proxyAddress, dispatch) {
    const actions = [];
    const hasSmartWallet = isAddress(proxyAddress);
    if (new Dec(supppliedEoa).gt(1)) {
      actions.push(this.getImportAction(account, proxyAddress, hasSmartWallet, dispatch));
    }
    actions.push(this.getSupplyAction(account, proxyAddress, hasSmartWallet, dispatch));
    if (new Dec(supplied).gt(1)) {
      actions.push(this.getWithdrawAction(account, proxyAddress, hasSmartWallet, dispatch));
      actions.push(this.getMoveAction(account, proxyAddress, hasSmartWallet, dispatch));
    }
    if (new Dec(claimable).gt(0)) {
      actions.push(this.getClaimAction(account, proxyAddress, hasSmartWallet, dispatch));
    }
    return actions.filter((val) => val);
  }

  async getSupplied(account) {
    return '0';
  }

  async getClaimable(account) {
    return {
      asset: '',
      amount: 0,
    };
  }

  async getPoolSize() {
    return '0';
  }

  async getPoolLiquidity() {
    return '0';
  }

  setMoveOptions(allGetters) {
    const moveOptions = allGetters.reduce((acc, getter) => {
      const manageData = getter.getManageData();
      if (manageData.slug === this.slug || manageData.isLegacy) return acc;
      if (manageData.slug.includes('rari')) return acc;
      const hasAsset = this.availableAssets.some((asset) => manageData.availableAssets.includes(asset));
      if (hasAsset) {
        acc.push(getter);
      }
      return acc;
    }, []);
    this.moveOptions = moveOptions.map((getter) => {
      const manageData = getter.getManageData();
      return {
        label: manageData.name,
        value: manageData.slug,
        getter,
      };
    });
  }

  getManageData() {
    return {
      ProtocolIcon: this.ProtocolIcon,
      slug: this.slug,
      name: this.name,
      availableAssets: this.availableAssets,
      suppliedAsset: this.suppliedAsset,
      description: this.description,
      isLegacy: this.isLegacy,
    };
  }

  async getAsyncManageData(account, proxyAddress, dispatch) {
    const [poolSize, liquidity, supplied, supppliedEoa, claim] = await Promise.all([
      this.getPoolSize(),
      this.getPoolLiquidity(),
      isAddress(proxyAddress) ? this.getSupplied(proxyAddress).then((val) => (new Dec(val).gte(1) ? val : 0)) : 0,
      this.getSupplied(account).then((val) => (new Dec(val).gte(1) ? val : 0)),
      isAddress(proxyAddress) ? this.getClaimable(proxyAddress, dispatch) : { asset: '', amount: 0 },
    ]);
    const actions = this.getActions(supplied, supppliedEoa, claim.amount, account, proxyAddress, dispatch);
    return {
      supplied,
      poolSize,
      claim,
      liquidity,
      actions,
    };
  }
}
