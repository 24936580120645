import dfs from '@defisaver/sdk';
import { getAssetInfoByAddress } from '@defisaver/tokens';
import { Amount, Asset, AssetAmount } from '../../components/Recipes/RecipeCreator/inputTypes';
import { balancerFlashLoanAssets } from '../../constants/assets';
import RecipeAction from '../RecipeAction';
import { balancerFlashPaybackAfterValues } from '../../actions/flashLoanAfterValues';
import FlashIcon from '../recipeIcons/Flash.svg';
import { formatNumber } from '../../services/utils';
import { assetAmountInWeiIgnorePointer } from '../../services/recipeCreator/recipeActionUtils';

export default class BalancerFlashLoanPaybackAction extends RecipeAction {
  static prettyName = 'Balancer Loan Payback';

  static protocolPrettyName = 'Balancer';

  static description = 'Pays back flash loaned asset to Balancer.';

  constructor(asset = 'DAI', amount = '') {
    super();
    this.inputs = [
      new Asset('Asset', asset, balancerFlashLoanAssets),
      new Amount('Amount', amount),
    ];
    this.output = new AssetAmount('output', 0, asset);
  }

  async getAfterValues(_balances = {}, returnValues = [], actions = [], positions = {}, getState) {
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const asset = getAssetInfoByAddress(args[0]);
    const { returnValue, balances } = await balancerFlashPaybackAfterValues(
      { amount: args[1], asset: asset.symbol },
      { account: getState().general.account },
      _balances,
    );
    this.output.value = returnValue;
    this.output.asset = asset.symbol;
    return { returnValue: this.output, balances, positions };
  }

  async toDfsAction(getState) {
    const amount = assetAmountInWeiIgnorePointer(this.inputs[1].value, this.inputs[0].asset);
    return new dfs.actions.flashloan.BalancerFlashLoanPaybackAction(amount, this.inputs[0].value);
  }

  static getIcon() {
    return FlashIcon;
  }

  _getPrettyName(actionCalls, actions) {
    const args = this.mapReturnValuesToArgs(actionCalls.map(a => a.returnValue), actions);
    return `Pay flash borrowed ${formatNumber(args[1])} ${this.inputs[0].asset} back to Balancer`;
  }

  getAsset() {
    return this.inputs[0].asset;
  }
}
