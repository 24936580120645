import React from 'react';
import { v4 as uuid } from 'uuid';

export default function ViperswapIcon(props) {
  const id = `viperswap_icon${uuid()}`;
  const fill = `url(#${id})`;

  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z" fill={fill} />
      <path d="M13.606 19.6549C19.6436 18.0376 20.614 15.9892 20.614 15.9892C19.2665 17.6065 14.1311 18.8208 14.1311 18.8208L13.606 19.6549Z" fill="white" />
      <path d="M21.7592 12.3775C21.7592 12.3775 20.8369 8.78857 17.9181 8.37471C14.9992 7.96084 13.5568 8.7654 13.5568 8.7654L13.2942 9.37143C13.2652 9.47594 13.2052 9.56921 13.1222 9.63901C13.0392 9.70882 12.937 9.75189 12.829 9.76258L12.0908 9.69353C11.9887 9.70356 11.889 9.73074 11.796 9.77394C11.3871 9.96202 10.2059 10.5067 9.70619 10.7062C9.0997 10.9488 7.65095 11.3667 8.4596 12.1754C8.4596 12.1754 8.25743 11.5689 9.26825 11.2454C10.2791 10.922 12.6646 10.3559 13.3519 10.8007C14.0393 11.2454 13.0666 14.6009 12.7427 14.6009H11.7728C11.7728 14.6009 12.4601 15.2478 13.0666 15.0456C13.6731 14.8435 13.833 14.6009 13.833 14.6009C13.833 14.6009 14.3986 11.6479 14.6817 11.2454C14.9647 10.8429 20.3845 9.34508 21.7592 12.3775ZM14.3805 10.8007L14.2792 10.4963C18.4169 9.19653 20.2841 10.6389 20.2841 10.6389C17.4329 9.54725 14.3805 10.8007 14.3805 10.8007Z" fill="white" />
      <path d="M13.0935 11.299C13.0935 11.299 12.1231 11.1627 11.1795 12.3235C10.1751 13.5573 9.34599 15.6117 7.36344 15.6925C7.36344 15.6925 8.42877 16.0432 9.15655 15.6925C9.15655 15.6925 8.21297 16.6093 7 16.6361C7 16.6361 8.89715 16.8219 9.80302 15.5581C10.9369 13.9789 11.1795 11.8651 13.0935 11.299Z" fill="white" />
      <path d="M8.51048 12.8732C8.66539 12.9589 8.82817 13.0297 8.99658 13.0844C8.99658 13.0844 8.61906 12.6605 8.52456 12.4411L8.19974 12.3157L7.87628 11.7978C7.90291 11.9695 7.94705 12.138 8.00803 12.3007C7.8154 12.0227 7.68229 11.6979 7.84948 11.4471C8.21291 10.902 9.9924 10.4363 10.518 10.0724C10.866 9.83118 11.92 9.22833 12.511 8.79357C12.8713 8.52859 13.28 8.33675 13.714 8.22888C23.4969 5.79111 22.6082 14.6009 22.6082 14.6009C23.4714 8.55006 19.4141 7 16.7478 7C14.0815 7 12.1367 7.92995 12.0158 8.05124C11.895 8.17254 10.9646 9.26422 10.3177 9.66854C9.67076 10.0729 7.32567 10.6794 7.36474 11.5284C7.40199 12.2894 8.32058 12.7796 8.51048 12.8732Z" fill="white" />
      <path d="M8.9425 11.6756C9.13042 11.8849 9.36996 12.0412 9.63718 12.1288C9.9044 12.2165 10.1899 12.2326 10.4653 12.1754C9.95332 12.1213 9.62986 11.2945 9.62986 11.2945C8.98294 11.4108 8.9425 11.6756 8.9425 11.6756ZM10.0474 12.1077C10.0474 12.1077 9.62986 12.1481 9.10423 11.6079L9.25234 11.4866C9.25234 11.4866 9.54309 11.9564 10.0474 12.1077Z" fill="white" />
      <path d="M23.0666 11.2945C23.0666 11.2945 24.6299 16.2586 20.6134 19.2506L20.2841 20.6521C20.2841 20.6521 25.0878 17.3271 23.0666 11.2945Z" fill="white" />
      <path d="M12.9181 13.6981C12.9181 13.6981 13.4033 12.2812 13.2688 11.6756C13.2688 11.6756 12.9858 13.3342 12.3793 13.9135L11.5707 14.062C11.5707 14.062 12.2848 14.2238 12.5679 14.2238C12.8509 14.2238 12.9181 13.6981 12.9181 13.6981Z" fill="white" />
      <path d="M21.3412 17.0586C22.6255 14.4746 20.8024 10.9488 15.0456 12.4411C15.0456 12.4411 19.9665 11.4871 20.991 14.3719C22.4029 18.3479 14.6835 20.1415 12.7682 20.4636L11.5688 22C11.5688 22 19.9511 19.8571 21.3412 17.0586Z" fill="white" />
      <defs>
        <linearGradient id={id} x1="15" y1="0" x2="15" y2="30" gradientUnits="userSpaceOnUse">
          <stop stopColor="#215129" />
          <stop offset="1" stopColor="#132F18" />
        </linearGradient>
      </defs>
    </svg>
  );
}
