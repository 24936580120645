import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Dec from 'decimal.js';
import t from 'translate';

import { getBoostModalData, resetBoostModal, boostAction } from '../../../../actions/reflexerActions/reflexerManageActions';
import { getDashboardInputs } from '../../../../actions/dashboardActions';

import { ReflexerSlippageLimitForm as SlippageLimitForm } from '../../BoostRepayCommon/SlippageLimitForm/SlippageLimitForm';
import TooltipWrapper from '../../../TooltipWrapper/TooltipWrapper';
import TradeSizeImpactWarning from '../../../TradeSizeImpactWarning/TradeSizeImpactWarning';
import ModalHeader from '../../ModalHeader';
import ModalBody from '../../ModalBody';
import Loader from '../../../Loader/Loader';
import ErrorBox from '../../../Decorative/ErrorBox/ErrorBox';
import InfoIcon from '../../../Decorative/InfoIcon';
import ActionInfo from '../../BoostRepayCommon/ActionInfo';
import AdvancedOptions from '../../BoostRepayCommon/AdvancedOptions/AdvancedOptions';
import AdditionalInfo from '../../../AdditionalInfo/AdditionalInfo';

const ReflexerBoostModal = ({
  getBoostModalData, resetBoostModal, closeModal, gettingBoostModalData, gettingBoostModalDataError,
  boostAmount, boostExchangeRate, boosting, boostAction, tradeSizeImpact, supplyAsset, borrowAsset, boostingError,
  useFl, flProtocol, exchangeSource, flFee, additionalAction, contextAction, getDashboardInputs,
}) => {
  const {
    firstInput: inputAmount, secondAction, secondInput,
  } = useMemo(() => getDashboardInputs(contextAction, additionalAction), []);

  useEffect(() => {
    getBoostModalData(inputAmount);
    return resetBoostModal;
  }, []);
  const [advanced, setAdvanced] = useState(false);

  const shouldFlip = new Dec(boostExchangeRate).lt(new Dec(1).div(boostExchangeRate).toString());

  const exchangeLabel = shouldFlip
    ? `${borrowAsset}/${supplyAsset}`
    : `${supplyAsset}/${borrowAsset}`;
  const correctedBoostExchangeRate = shouldFlip
    ? new Dec(1).div(boostExchangeRate).toString()
    : boostExchangeRate;

  return (
    <div className="boost-repay-modal">
      <ModalHeader closeModal={closeModal} />

      <ModalBody>
        <div className="new-modal-top-wrapper">
          <h1>
            <TooltipWrapper title={t('maker.boost_modal_description', { '%daiLabel': borrowAsset, '%asset': supplyAsset, CDP: 'Safe' })}>
              <InfoIcon /> {t('common.boost')}
            </TooltipWrapper>
          </h1>
        </div>

        <div className="new-modal-content-wrapper">

          {gettingBoostModalData && (<div className="container"><div className="loading-wrapper"><Loader /></div></div>)}

          {!gettingBoostModalData && gettingBoostModalDataError && (<ErrorBox>{gettingBoostModalDataError}</ErrorBox>)}

          {!gettingBoostModalData && !gettingBoostModalDataError && (
            <>
              <ActionInfo
                firstAsset={borrowAsset}
                firstAmount={inputAmount}
                secondAsset={supplyAsset}
                secondAmount={boostAmount}
                exchangeRate={correctedBoostExchangeRate}
                exchangeLabel={exchangeLabel}
                additionalAction={secondAction}
                additionalInput={secondInput}
                additionalAsset={secondAction?.value === 'collateral' ? 'ETH' : 'RAI'}
              />

              <div className="advanced__wrapper container">
                <TradeSizeImpactWarning tradeSizeImpact={tradeSizeImpact} />
                <AdvancedOptions
                  borrowAsset={borrowAsset}
                  supplyAsset={supplyAsset}
                  advanced={advanced}
                  setAdvanced={setAdvanced}
                  exchangeRate={boostExchangeRate}
                  SlippageLimitForm={SlippageLimitForm}
                />
                <AdditionalInfo
                  flashloanSource={useFl ? flProtocol : ''}
                  flashloanFee={flFee}
                  exchangeSource={exchangeSource}
                  additionalAsset={secondAction?.value === 'collateral' ? 'ETH' : 'RAI'}
                  asset1={borrowAsset}
                  asset2={supplyAsset}
                />
                {boostingError && (<ErrorBox marginTop>{boostingError}</ErrorBox>)}
              </div>
            </>
          )}
        </div>
      </ModalBody>

      <div className="modal-controls">
        <button
          form="reflexer-boost-modal-form"
          type="button"
          disabled={boosting || gettingBoostModalData}
          onClick={() => boostAction(contextAction, additionalAction, closeModal)}
          className="button green"
        >
          {boosting ? t('common.boosting') : t('common.boost')}
        </button>
      </div>
    </div>
  );
};

ReflexerBoostModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  tradeSizeImpact: PropTypes.string.isRequired,

  getBoostModalData: PropTypes.func.isRequired,
  gettingBoostModalData: PropTypes.bool.isRequired,
  gettingBoostModalDataError: PropTypes.string.isRequired,

  boostAmount: PropTypes.string.isRequired,
  boostExchangeRate: PropTypes.string.isRequired,

  boostAction: PropTypes.func.isRequired,
  resetBoostModal: PropTypes.func.isRequired,
  boosting: PropTypes.bool.isRequired,

  borrowAsset: PropTypes.string.isRequired,
  supplyAsset: PropTypes.string.isRequired,

  boostingError: PropTypes.string.isRequired,

  useFl: PropTypes.bool.isRequired,
  flProtocol: PropTypes.string.isRequired,
  flFee: PropTypes.string.isRequired,
  exchangeSource: PropTypes.string.isRequired,

  getDashboardInputs: PropTypes.func.isRequired,
  additionalAction: PropTypes.object,
  contextAction: PropTypes.object,
};

const mapStateToProps = ({ reflexerManage, reflexer }) => ({
  tradeSizeImpact: reflexerManage.tradeSizeImpact,
  gettingBoostModalData: reflexerManage.gettingBoostModalData,
  gettingBoostModalDataError: reflexerManage.gettingBoostModalDataError,
  boostAmount: reflexerManage.boostAmount,
  boostExchangeRate: reflexerManage.boostExchangeRate,
  boosting: reflexerManage.executing.boost || (reflexerManage.executing[reflexerManage.selectedAction?.value] && reflexerManage.selectedAdditionalActions[reflexerManage.selectedAction?.value]?.value === 'boost'),
  boostingError: reflexerManage.executingErrors.boost,
  borrowAsset: reflexer.safes[reflexer.selectedSafeId].debtAsset,
  supplyAsset: reflexer.safes[reflexer.selectedSafeId].asset,
  useFl: reflexerManage.useFl,
  flProtocol: reflexerManage.flProtocol,
  flFee: reflexerManage.flFee,
  exchangeSource: reflexerManage.exchangeSource,
});

const mapDispatchToProps = {
  getBoostModalData, resetBoostModal, boostAction, getDashboardInputs,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReflexerBoostModal);
