import t from 'translate';
import PropTypes from 'prop-types';
import React from 'react';
import ActionInfoItem from '../BoostRepayCommon/AssetInfoItem';

const ActionInfo = ({
  firstAsset, secondAsset, firstAmount, secondAmount, exchangeRate, exchangeLabel,
}) => (
  <div className="action-info container">
    <div className="action-item-section">
      <ActionInfoItem
        amount={firstAmount}
        asset={firstAsset}
        actionName={t('common.claiming')}
      />

      <ActionInfoItem
        amount={secondAmount}
        asset={secondAsset}
        exchangeRate={exchangeRate}
        actionName={`${t('common.buying')}${exchangeRate ? ` ${t('common.with_swap_rate')}` : ''}: `}
        exchangeLabel={exchangeLabel}
      />
    </div>
  </div>
);


ActionInfo.propTypes = {
  exchangeRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  exchangeLabel: PropTypes.string.isRequired,
  firstAsset: PropTypes.string.isRequired,
  secondAsset: PropTypes.string.isRequired,
  firstAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  secondAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default ActionInfo;
