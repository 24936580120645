/* eslint import/first: 0 */
import React, { Component, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect, Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { updateAssetPriceInterval, updateAssetBalanceInterval } from '../../actions/assetsActions';
import { updateServerNotificationsInterval } from '../../actions/serverNotificationsActions';
import { updateServerTxInterval } from '../../actions/serverTxActions';
import { listenToAccChange, updateGasPriceInterval } from '../../actions/generalActions';

import './App.scss';
import 'common/buttons.scss';

import clientConfig from '../../config/clientConfig.json';

import withErrorFallback from '../ErrorFallback/ErrorFallback';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import Footer from '../Footer/Footer';
import Page404 from '../Page404/Page404';
import ModalRoot from '../Modals/ModalRoot';
import Notifications from '../Notifications/Notifications';
import TxNotifications from '../TxNotifications/TxNotifications';
import TermsOfService from '../TermsOfService/TermsOfService';
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy';
import AutomationStats from '../AutomationStats/AutomationStats';
import FundSafeguard from '../FundSafeguard/FundSafeguard';
import SafeguardRedirect from '../SafeguardRedirect/SafeguardRedirect';
import WhatsNew from '../WhatsNew/WhatsNew';
import BookmarkBanner from '../BookmarkBanner/BookmarkBanner';
import SelectPortal from './SelectPortal';
import Showcase from '../Showcase/Showcase';


const MakerRoutesLazy = lazy(() => import('../Maker/MakerRoutes'));
const LiquityRoutesLazy = lazy(() => import('../Liquity/LiquityRoutes'));
const CompoundRoutesLazy = lazy(() => import('../Compound/CompoundRoutes'));
const AaveRoutesLazy = lazy(() => import('../Aave/AaveRoutes'));
const SmartSavingsRoutesLazy = lazy(() => import('../SmartSavings/SmartSavingsRoutes'));
const LiquidationRoutesLazy = lazy(() => import('../Liquidation/LiquidationRoutes'));
const ExchangeRouteLazy = lazy(() => import('../Exchange/ExchangeRoute'));
const PortfolioLazy = lazy(() => import('../Portfolio/PortfolioRoute'));
const TxHistory = lazy(() => import('../Portfolio/TxHistory/TxHistoryRoute'));
const ShifterLazy = lazy(() => import('../Shifter/ShifterRoute'));
const ReflexerRoutesLazy = lazy(() => import('../Reflexer/ReflexerRoutes'));
const BridgeRoutesLazy = lazy(() => import('../Bridge/BridgeRoute'));

const RecipesLazy = lazy(() => import('../Recipes/RecipesRoutes'));
const ChangeProxyOwnerRoutes = lazy(() => import('../ChangeProxyOwner/ChangeProxyOwnerRoutes'));

import AppBackdrop from './AppBackdrop';

import './share-thumbnail-2020.png';
import featureBlocker from '../FeatureBlocker/FeatureBlocker';
import { NETWORKS } from '../../constants/general';

class App extends Component {
  componentDidMount() {
    this.props.updateAssetPriceInterval('ETH');
    this.props.updateAssetBalanceInterval('ETH');
    this.props.updateServerNotificationsInterval();
    this.props.updateServerTxInterval();
    this.props.updateGasPriceInterval();

    this.props.listenToAccChange();

    window.debugWithAccount = '';
    window.debugLogs = false;
  }

  render() {
    const { store, network } = this.props;
    const base = clientConfig.serveFrom || '/';
    const selectedNetwork = Object.values(NETWORKS).find(n => n.chainId === network);
    const isLayer2 = network !== 1 && selectedNetwork?.accent;
    return (
      <Provider store={store}>
        <BrowserRouter basename={base}>
          <BookmarkBanner />

          <div className={`app ${isLayer2 ? 'network-fade' : ''}`} style={{ '--networkAccent': selectedNetwork.accent }}>
            {/* {isLayer2 && (<div className="network-line" style={{ backgroundColor: `rgb(${selectedNetwork.accent})` }} />)} */}

            <Sidebar />
            <Notifications />

            <div className="main-content">
              <TxNotifications />
              <Header />
              <SafeguardRedirect />
              <Suspense fallback={<div className="loader-between-pages" />}>
                <Switch>
                  <Route exact path="/" component={PortfolioLazy} />
                  <Route exact path="/transaction-history" component={TxHistory} />
                  <Route path="/terms-of-service" component={TermsOfService} />
                  <Route path="/privacy-policy" component={() => <PrivacyPolicy />} />
                  <Route path="/whats-new" component={WhatsNew} />
                  <Route path="/stats" component={AutomationStats} />

                  <Route path="/makerdao/liquidation" component={LiquidationRoutesLazy} />
                  <Route path="/makerdao" component={featureBlocker(network, 'maker', MakerRoutesLazy)} />
                  <Route path="/compound" component={featureBlocker(network, 'compound', CompoundRoutesLazy)} />
                  <Route path="/aave" component={featureBlocker(network, 'aave', AaveRoutesLazy)} />
                  <Route path="/smart-savings" component={featureBlocker(network, 'savings', SmartSavingsRoutesLazy)} />
                  <Route path="/reflexer" component={featureBlocker(network, 'reflexer', ReflexerRoutesLazy)} />
                  <Route path="/liquity" component={featureBlocker(network, 'liquity', LiquityRoutesLazy)} />
                  <Route path="/exchange" component={featureBlocker(network, 'exchange', ExchangeRouteLazy)} />
                  <Route path="/shifter" component={featureBlocker(network, 'shifter', ShifterLazy)} />
                  <Route path="/recipes" component={featureBlocker(network, 'recipes', RecipesLazy)} />
                  <Route path="/bridge" component={BridgeRoutesLazy} />

                  <Route path="/safeguard" component={FundSafeguard} />
                  <Route path="/change-proxy-owner" component={ChangeProxyOwnerRoutes} />

                  <Route exact path="/showcase" component={Showcase} />

                  <Route path="*" component={Page404} />
                </Switch>
              </Suspense>

              <Footer />

              <AppBackdrop />

              <ModalRoot />
            </div>
          </div>
        </BrowserRouter>

        <SelectPortal />
      </Provider>
    );
  }
}

App.propTypes = {
  store: PropTypes.object.isRequired,
  updateAssetPriceInterval: PropTypes.func.isRequired,
  updateAssetBalanceInterval: PropTypes.func.isRequired,
  updateServerNotificationsInterval: PropTypes.func.isRequired,
  updateServerTxInterval: PropTypes.func.isRequired,
  listenToAccChange: PropTypes.func.isRequired,
  updateGasPriceInterval: PropTypes.func.isRequired,
  network: PropTypes.number.isRequired,
};

const mapDispatchToProps = {
  updateAssetPriceInterval,
  updateAssetBalanceInterval,
  updateServerNotificationsInterval,
  listenToAccChange,
  updateServerTxInterval,
  updateGasPriceInterval,
};

const mapStateToProps = ({ general }) => ({
  network: general.network,
});

export default withErrorFallback(connect(mapStateToProps, mapDispatchToProps)(App));
