import dfs from '@defisaver/sdk';
import { assetAmountInWei } from '@defisaver/tokens';
import cloneDeep from 'lodash/cloneDeep';
import Dec from 'decimal.js';
import RecipeAction from '../RecipeAction';
import { AssetAmount, Amount, Source } from '../../components/Recipes/RecipeCreator/inputTypes';
import { changeBalance } from '../../services/recipeCreator/recipeActionUtils';
import StakeIcon from '../recipeIcons/Stake.svg';
import { formatNumber } from '../../services/utils';

export default class LiquitySPDepositAction extends RecipeAction {
  static prettyName = 'Deposit LUSD to stability pool';

  static protocol = 'liquity';

  static protocolPrettyName = 'Liquity';

  static description = 'Supplies LUSD to the Liquity Stability pool.';

  constructor(from = 'wallet', amount = '', to = 'wallet') {
    super();
    this.inputs = [
      new Source('From', from),
      new AssetAmount('Amount', amount, 'LUSD'),
      new Source('To', to, true),
    ];
    this.output = new Amount('output', 0);
  }

  async toDfsAction(getState) {
    const {
      general: { account },
      maker: { proxyAddress },
      recipeCreator: { actionCalls, actions },
    } = getState();
    const args = this.mapReturnValuesToArgs(actionCalls.map(a => a.returnValue), actions);
    const from = args[0] === 'wallet' ? account : proxyAddress;
    const to = args[2] === 'wallet' ? account : proxyAddress;
    const amount = assetAmountInWei(args[1], 'LUSD');
    return new dfs.actions.liquity.LiquitySPDepositAction(amount, from, to, to);
  }

  async getAfterValues(_balances = {}, returnValues = [], actions = [], _positions = {}, getState) {
    const positions = { ..._positions };
    const {
      general: { account },
      maker: { proxyAddress },
      liquity: { proxy },
      liquityStaking: { proxy: stakingProxy },
    } = getState();
    if (!positions.liquity) positions.liquity = { ...proxy, ...stakingProxy };
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const amount = args[1] || '0';
    const balances = cloneDeep(_balances);
    await changeBalance(balances, args[0], 'LUSD', new Dec(amount || 0).mul(-1), args[0] === 'wallet' ? account : proxyAddress);
    await changeBalance(balances, args[2], 'ETH', positions.liquity.stabilityRewardETH, args[2] === 'wallet' ? account : proxyAddress);
    await changeBalance(balances, args[2], 'LQTY', positions.liquity.stabilityRewardLQTY, args[2] === 'wallet' ? account : proxyAddress);
    positions.liquity.stabilityRewardETH = '0';
    positions.liquity.stabilityRewardLQTY = '0';
    positions.liquity.stakedLUSDBalance += amount;
    positions.liquity.showStakingBalances = true;
    this.output.value = amount;
    return {
      returnValue: this.output,
      balances,
      positions,
    };
  }

  _getPrettyName(actionCalls, actions) {
    const args = this.mapReturnValuesToArgs(actionCalls.map(a => a.returnValue), actions);
    return `Deposit ${formatNumber(args[1])} LUSD to stability pool`;
  }

  static getIcon() {
    return StakeIcon;
  }
}
