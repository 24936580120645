import dfs from '@defisaver/sdk';
import { getAssetInfoByAddress, assetAmountInWei, getAssetInfo } from '@defisaver/tokens';
import RecipeAction from '../RecipeAction';
import {
  Amount, Asset, AssetAmount, Market, Source,
} from '../../components/Recipes/RecipeCreator/inputTypes';
import { getAaveMarketInfoFromValue } from '../../constants/aaveMarkets';
import { withdrawAfterValues } from '../../actions/aaveActions/aaveManageAfterValues';
import { formatNumber } from '../../services/utils';
import WithdrawIcon from '../recipeIcons/Withdraw.svg';
import { assetAmountInWeiIgnorePointer } from '../../services/recipeCreator/recipeActionUtils';
import { MAXUINT } from '../../constants/general';

export default class AaveV2WithdrawAction extends RecipeAction {
  static prettyName = 'Withdraw collateral from Aave';

  static protocol = 'aavev2';

  static protocolPrettyName = 'Aave';

  static description = 'Withdraws selected asset from Aave.';

  constructor(market = 'v2default', asset = 'DAI', amount = '', to = 'wallet') {
    super();
    this.inputs = [
      new Market('Market', market),
      new Asset('Asset', asset, getAaveMarketInfoFromValue(market).assets.map(asset => getAssetInfo(asset.replace(/^ETH/, 'WETH')))),
      new Amount('Amount', amount),
      new Source('To', to, true),
    ];
    this.output = new AssetAmount('output', 0, asset);
  }

  async getAfterValues(_balances = {}, returnValues = [], actions = [], _positions = {}, getState) {
    const positions = { ..._positions };
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const market = args[0];
    const proxy = 'proxy';
    const source = args[3];
    const asset = getAssetInfoByAddress(args[1]);
    if (!positions[`aaveV2_${market}`]) {
      const { assetsData } = getState().aaveManage[`${market}`];
      const { usedAssets } = getState().aaveManage[`${proxy}`][`${market}`];
      positions[`aaveV2_${market}`] = { usedAssets, assetsData };
    }
    const _asset = asset.symbol.replace('WETH', 'ETH');
    let amount = args[2];
    if (amount === 'All available') amount = positions[`aaveV2_${market}`].usedAssets[_asset]?.supplied || '0';
    const { afterPosition, balances, returnValue } = await withdrawAfterValues(
      {
        amount,
        firstAsset: _asset,
        to: args[3],
      },
      {
        usedAssets: positions[`aaveV2_${market}`].usedAssets,
        assetsData: positions[`aaveV2_${market}`].assetsData,
        assets: getState().assets,
        source,
        walletType: { value: 'proxy' },
        account: getState().general.account,
        proxyAddress: getState().maker.proxyAddress,
      },
      _balances,
    );
    positions[`aaveV2_${market}`] = afterPosition;
    this.output.value = returnValue;
    this.output.asset = asset.symbol;
    return { returnValue: this.output, balances, positions };
  }

  async toDfsAction(getState) {
    const {
      general: { account },
      maker: { proxyAddress },
    } = getState();
    const to = this.inputs[3].value === 'wallet' ? account : proxyAddress;
    const amount = this.inputs[2].value === 'All available' ? MAXUINT : assetAmountInWeiIgnorePointer(this.inputs[2].value, this.inputs[1].asset);
    return new dfs.actions.aave.AaveWithdrawAction(
      getAaveMarketInfoFromValue(this.inputs[0].value).providerAddress,
      this.inputs[1].value,
      amount,
      to,
    );
  }

  static getIcon() {
    return WithdrawIcon;
  }

  _getPrettyName(actionCalls, actions) {
    const args = this.mapReturnValuesToArgs(actionCalls.map(a => a.returnValue), actions);
    return `Withdraw ${args[2] === 'All available' ? 'all' : formatNumber(args[2])} ${this.inputs[1].asset} from Aave`;
  }

  getAsset() {
    return this.inputs[1].asset;
  }
}
