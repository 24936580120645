import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import general from './generalReducer';
import modal from './modalReducer';
import notification from './notificationReducer';
import notifications from './notificationsReducer';
import compoundManage from './compoundReducers/compoundManageReducer';
import compoundMigrate from './compoundReducers/compoundMigrateReducer';
import compoundSaver from './compoundReducers/compoundSaverReducer';
import compoundCreate from './compoundReducers/compoundCreateReducer';
import maker from './makerReducers/makerReducer';
import makerManage from './makerReducers/makerManageReducers/makerManageReducer';
import makerManageMcd from './makerReducers/makerManageReducers/makerManageMcdReducer';
import makerSaver from './makerReducers/makerSaverReducer';
import makerCreateCdp from './makerReducers/makerCreateCdpReducer';
import makerLiquidation from './makerLiquidationReducer';
import aaveManage from './aaveReducers/aaveManageReducer';
import aaveSaver from './aaveReducers/aaveSaverReducer';
import aaveMigrate from './aaveReducers/aaveMigrateReducer';
import aaveCreate from './aaveReducers/aaveCreateReducer';
import savingsManage from './savingsReducers/savingsManageReducer';
import nexus from './nexusReducer';
import serverNotifications from './serverNotificationsReducer';
import assets from './assetsReducer';
import tx from './txReducer';
import portfolio from './portfolioReducer';
import reflexer from './reflexerReducers/reflexerReducer';
import reflexerManage from './reflexerReducers/reflexerManageReducer';
import recipeCreator from './recipeCreatorReducer';
import liquity from './liquityReducers/liquityReducer';
import liquityCreate from './liquityReducers/liquityCreateReducer';
import liquityManage from './liquityReducers/liquityManageReducer';
import liquityStaking from './liquityReducers/liquityStakingReducer';
import uniswapV3 from './uniswapV3Reducer';
import yearn from './yearnReducer';
import strategies from './strategiesReducers/strategiesReducer';
import makerStrategies from './strategiesReducers/makerStrategiesReducer';
import liquityStrategies from './strategiesReducers/liquityStrategiesReducer';
import bridge from './bridgeReducer';

export default combineReducers({
  form,
  general,
  modal,
  notification,
  notifications,
  serverNotifications,
  assets,
  tx,
  portfolio,
  recipeCreator,

  // Maker
  maker,
  makerSaver,
  makerCreateCdp,
  // Maker manage
  makerManage,
  makerManageMcd,
  makerLiquidation,

  // Compound
  compoundManage,
  compoundSaver,
  compoundCreate,
  compoundMigrate,

  // Aave
  aaveManage,
  aaveSaver,
  aaveCreate,
  aaveMigrate,

  // SmartSavings,
  savingsManage,

  // Reflexer
  reflexer,
  reflexerManage,

  // Liquity
  liquity,
  liquityCreate,
  liquityManage,
  liquityStaking,
  liquityStrategies,

  // UniswapV3
  uniswapV3,

  // Yearn
  yearn,

  // Strategies
  strategies,
  makerStrategies,

  // Bridge
  bridge,

  // Other
  nexus,
});
