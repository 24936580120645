const API_URL = 'https://defiexplore.com/goapi/logger';

const getTokenKey = () => {
  const superSecretKey = 'thisisreallysuperimpossibletoguesskey';
  const ts = Math.round((new Date()).getTime() / 1000); // timestamp in seconds
  const key = `${superSecretKey}-${ts}`;

  return window._web3.utils.sha3(key).substring(2);
};

/**
 * Log anything in our database
 *
 * @param tag {String} tag should be identifier for log
 * @param data {String} data to log
 */
export const log = async (tag, data) => {
  try {
    const formData = new URLSearchParams();
    formData.append('tag', tag);
    formData.append('data', data);

    const res = await fetch(API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${getTokenKey(web3)}`,
      },
      body: formData.toString(),
    });

    return null;
  } catch (e) {
    return e;
  }
};
