import React, { Component } from 'react';
import t from 'translate';
import PropTypes from 'prop-types';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Dec from 'decimal.js';
import { setSlippagePercent, resetSlippageValues } from 'actions/actionsInModals';
import InputComponent from '../../../Forms/InputComponent';
import TooltipWrapper from '../../../TooltipWrapper/TooltipWrapper';
import { numberWithCommas, formatNumber } from '../../../../services/utils';
import { DEFAULT_SLIPPAGE_PERCENT } from '../../../../constants/general';

import './SlippageLimitForm.scss';

class SlippageLimitForm extends Component {
  constructor(props) {
    super(props);
    this.state = { id: 'slippage-amount' };
  }

  UNSAFE_componentWillMount() {
    const {
      setSlippagePercent, initialValues, estimatedPrice, type,
    } = this.props;
    setSlippagePercent(initialValues.slippagePercent, estimatedPrice, type);
    this.setState({ id: `slippage-amount-${Math.floor(Math.random() * 10000)}` });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      setSlippagePercent, estimatedPrice, type, formValues,
    } = this.props;

    setSlippagePercent(formValues.slippagePercent, estimatedPrice, type);
  }

  componentWillUnmount() {
    this.props.resetSlippageValues();
  }

  render() {
    const {
      setSlippagePercent, slippageRate, type, borrowAsset, supplyAsset, estimatedPrice, showThirdLabel,
    } = this.props;
    const { id } = this.state;

    const buyingAsset = type === 'boost' ? supplyAsset : borrowAsset;
    const sellingAsset = type === 'boost' ? borrowAsset : supplyAsset;
    const shouldFlip = Dec(slippageRate).lt(Dec(1).div(slippageRate));
    const exchangeLabel = shouldFlip
      ? `${sellingAsset}/${buyingAsset}`
      : `${buyingAsset}/${sellingAsset}`;
    const correctedSlippageRate = shouldFlip
      ? Dec(1).div(slippageRate).toString()
      : slippageRate;
    const priceTooltip = `${numberWithCommas(correctedSlippageRate, 4)} ${exchangeLabel} = ${numberWithCommas(Dec(1).div(correctedSlippageRate), 4)} ${exchangeLabel.split('/').reverse().join('/')}`;


    const thirdLabel = type === 'close' ? (
      <TooltipWrapper title={slippageRate} useText>
        ({ `min ${slippageRate ? formatNumber(slippageRate, 4) : '-'}`} {supplyAsset})
      </TooltipWrapper>
    ) : (
      <TooltipWrapper title={priceTooltip} useText>
        { correctedSlippageRate ? numberWithCommas(correctedSlippageRate, 4) : '-' } {exchangeLabel}
      </TooltipWrapper>
    );

    const normalizeFunc = (_val) => {
      const val = +_val;
      if (val < 0) return 0;
      if (val > 100) return 100;
      return val;
    };

    return (
      <form
        onSubmit={() => {}}
        className="slippage-limit-wrapper form-wrapper"
        noValidate
      >
        <div className="data-wrapper">
          <Field
            id={id}
            name="slippagePercent"
            placeholder="0"
            type="number"
            additional={{ min: 0 }}
            labelText={t('misc.exchange_slippage')}
            onChange={(e) => {
              setSlippagePercent(normalizeFunc(e.target.value), estimatedPrice, type);
            }}
            secondLabelText="%"
            thirdLabelText={showThirdLabel ? thirdLabel : ''}
            component={InputComponent}
            showErrorText
            normalize={normalizeFunc}
          />
        </div>
      </form>
    );
  }
}

SlippageLimitForm.defaultProps = {
  type: '',
  showThirdLabel: true,
};

SlippageLimitForm.propTypes = {
  setSlippagePercent: PropTypes.func.isRequired,
  slippageRate: PropTypes.string.isRequired,
  initialValues: PropTypes.object.isRequired,
  resetSlippageValues: PropTypes.func.isRequired,
  type: PropTypes.string,
  borrowAsset: PropTypes.string.isRequired,
  supplyAsset: PropTypes.string.isRequired,
  estimatedPrice: PropTypes.string.isRequired,
  formValues: PropTypes.object.isRequired,
  showThirdLabel: PropTypes.bool,
};

const SlippageLimitFormComp = reduxForm({
  form: 'slippageLimitForm',
})(SlippageLimitForm);

const selector = formValueSelector('slippageLimitForm');

const mapDispatchToProps = {
  setSlippagePercent,
  resetSlippageValues,
};

export const MakerCloseSlippageLimitForm = connect(state => ({
  initialValues: {
    slippagePercent: DEFAULT_SLIPPAGE_PERCENT,
  },
  formValues: {
    slippagePercent: selector(state, 'slippagePercent'),
  },
  slippageRate: state.makerManage.slippageRate,
}), mapDispatchToProps)(SlippageLimitFormComp);

export const MakerSlippageLimitForm = connect(state => ({
  initialValues: {
    slippagePercent: DEFAULT_SLIPPAGE_PERCENT,
  },
  formValues: {
    slippagePercent: selector(state, 'slippagePercent'),
  },
  slippageRate: state.makerManage.slippageRate,
  borrowAsset: state.maker.cdp.debtAsset,
  supplyAsset: state.maker.cdp.asset,
}), mapDispatchToProps)(SlippageLimitFormComp);

export const CompoundBoostSlippageLimitForm = connect((state) => ({
  initialValues: {
    slippagePercent: DEFAULT_SLIPPAGE_PERCENT,
  },
  formValues: {
    slippagePercent: selector(state, 'slippagePercent'),
  },
  slippageRate: state.compoundManage.slippageRate,
}), mapDispatchToProps)(SlippageLimitFormComp);

export const CompoundRepaySlippageLimitForm = connect(state => ({
  initialValues: {
    slippagePercent: DEFAULT_SLIPPAGE_PERCENT,
  },
  formValues: {
    slippagePercent: selector(state, 'slippagePercent'),
  },
  slippageRate: state.compoundManage.slippageRate,
}), mapDispatchToProps)(SlippageLimitFormComp);

export const AaveBoostSlippageLimitForm = connect(state => ({
  initialValues: {
    slippagePercent: DEFAULT_SLIPPAGE_PERCENT,
  },
  formValues: {
    slippagePercent: selector(state, 'slippagePercent'),
  },
  slippageRate: state.aaveManage.slippageRate,
  borrowAsset: state.aaveManage.actionsSelectValues.boostBorrow.label,
  supplyAsset: state.aaveManage.actionsSelectValues.boostSupply.label,
}), mapDispatchToProps)(SlippageLimitFormComp);

export const AaveRepaySlippageLimitForm = connect(state => ({
  initialValues: {
    slippagePercent: DEFAULT_SLIPPAGE_PERCENT,
  },
  formValues: {
    slippagePercent: selector(state, 'slippagePercent'),
  },
  slippageRate: state.aaveManage.slippageRate,
  borrowAsset: state.aaveManage.actionsSelectValues.repayPayback.label,
  supplyAsset: state.aaveManage.actionsSelectValues.repayWithdraw.label,
}), mapDispatchToProps)(SlippageLimitFormComp);

export const AaveBoostSlippageLimitFormV2 = connect(state => ({
  initialValues: {
    slippagePercent: DEFAULT_SLIPPAGE_PERCENT,
  },
  formValues: {
    slippagePercent: selector(state, 'slippagePercent'),
  },
  slippageRate: state.aaveManage.slippageRate,
}), mapDispatchToProps)(SlippageLimitFormComp);

export const AaveRepaySlippageLimitFormV2 = connect(state => ({
  initialValues: {
    slippagePercent: DEFAULT_SLIPPAGE_PERCENT,
  },
  formValues: {
    slippagePercent: selector(state, 'slippagePercent'),
  },
  slippageRate: state.aaveManage.slippageRate,
}), mapDispatchToProps)(SlippageLimitFormComp);

export const LiquitySlippageLimitForm = connect(state => ({
  initialValues: {
    slippagePercent: DEFAULT_SLIPPAGE_PERCENT,
  },
  formValues: {
    slippagePercent: selector(state, 'slippagePercent'),
  },
  slippageRate: state.liquityManage.slippageRate,
  borrowAsset: state.liquity.proxy.debtAsset,
  supplyAsset: state.liquity.proxy.asset,
}), mapDispatchToProps)(SlippageLimitFormComp);

export const ReflexerSlippageLimitForm = connect(state => ({
  initialValues: {
    slippagePercent: DEFAULT_SLIPPAGE_PERCENT,
  },
  formValues: {
    slippagePercent: selector(state, 'slippagePercent'),
  },
  slippageRate: state.reflexerManage.slippageRate,
  borrowAsset: state.reflexer.safes[state.reflexer.selectedSafeId].debtAsset,
  supplyAsset: state.reflexer.safes[state.reflexer.selectedSafeId].asset,
}), mapDispatchToProps)(SlippageLimitFormComp);

export const SmartSavingsSlippageLimitForm = connect((state, ownProps) => ({
  initialValues: {
    slippagePercent: DEFAULT_SLIPPAGE_PERCENT,
  },
  formValues: {
    slippagePercent: selector(state, 'slippagePercent'),
  },
  slippageRate: state.savingsManage.slippageRate,
  borrowAsset: ownProps.borrowAsset,
  supplyAsset: ownProps.supplyAsset,
}), mapDispatchToProps)(SlippageLimitFormComp);
