/* eslint-disable */
import React from 'react';

export default function OptimismIcon(props) {
  return (
    <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M3.57203 0.0661779C4.09414 0.195654 4.54183 0.520292 4.7163 0.895952C4.91307 1.31949 4.88009 1.83226 4.58239 2.97802C4.21503 4.39189 3.43778 4.99847 1.99148 5C0.74491 5.00134 0 4.44296 0 3.50721C0 3.17184 0.206094 2.18789 0.391835 1.63654C0.675694 0.793999 1.25235 0.256806 2.0776 0.0663163C2.4604 -0.0220363 3.21594 -0.0221284 3.57203 0.0661779ZM9.05408 0.174283C9.69646 0.376846 10.066 0.887579 9.99022 1.46816C9.887 2.25908 9.45286 2.82814 8.747 3.09787C8.51672 3.18586 8.36255 3.20404 7.72305 3.21861C7.30896 3.22804 6.92374 3.24516 6.86705 3.25663C6.73391 3.2836 6.68787 3.40507 6.53433 4.13447C6.36685 4.93019 6.35194 4.94398 5.65711 4.94398C4.9411 4.94398 4.95591 4.97863 5.20125 3.88005C5.59411 2.12072 5.68489 1.7124 5.82857 1.05832C5.99221 0.31338 6.04753 0.15231 6.16294 0.0853288C6.21103 0.057389 6.72264 0.0452693 7.48018 0.0541045C8.61673 0.0673343 8.74642 0.0772338 9.05408 0.174283ZM2.46011 1.05711C2.2528 1.11993 2.02077 1.2853 1.8954 1.45951C1.77353 1.62891 1.66055 1.98084 1.50765 2.66777C1.38869 3.20205 1.38277 3.46729 1.48521 3.67291C1.71544 4.13488 2.60578 4.04172 2.95603 3.51901C3.17544 3.19164 3.47702 1.95715 3.43627 1.55342C3.40319 1.22522 3.20774 1.04837 2.85699 1.02922C2.71487 1.02145 2.53632 1.03403 2.46011 1.05711ZM7.26802 1.11753C7.18263 1.17697 6.9982 1.83341 6.99572 2.08668C6.99412 2.24807 7.10161 2.27596 7.64091 2.25422C7.99646 2.23993 8.06854 2.22184 8.22577 2.10758C8.46543 1.93347 8.55602 1.79178 8.58516 1.54555C8.60678 1.36311 8.59303 1.32462 8.46145 1.19931C8.31641 1.06119 8.30363 1.05832 7.83321 1.05832C7.51972 1.05832 7.32354 1.07886 7.26802 1.11753Z" fill="white"/>
    </svg>

  );
}
