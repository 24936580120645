export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const CLOSE_NOTIFICATION = 'CLOSE_NOTIFICATION';
export const CHANGE_NOTIFICATION = 'CHANGE_NOTIFICATION';

export const ADD_CONFIRMATION = 'ADD_CONFIRMATION';
export const CLOSE_CONFIRMATION = 'CLOSE_CONFIRMATION';

export const PENDING_TX = 'PENDING_TX';
export const CLEAR_PENDING_TX = 'CLEAR_PENDING_TX';
export const CURRENT_TX = 'CURRENT_TX';
