import t from 'translate';
import { getInstadappAccountsWithAuthorized } from '../../services/instaServices';
import {
  filterCollateralAssetsFromUsedAssets,
  filterBorrowedAssetsFromUsedAssets,
} from '../../services/moneymarketCommonService';
import { getCompoundAccountData } from '../../services/compoundServices/compoundManageService';
import {
  ADD_COMPOUND_MIGRATE_INSTADAPP_DATA, ALLOW_COMPOUND_MIGRATE_INSTADAPP_DATA, COMPOUND_MIGRATE_FROM_INSTADAPP_ERROR,
  COMPOUND_MIGRATE_FROM_INSTADAPP_REQUEST,
  COMPOUND_MIGRATE_FROM_INSTADAPP_SUCCESS,
  COMPOUND_MIGRATE_PROXY_AUTH_ERROR,
  COMPOUND_MIGRATE_PROXY_AUTH_REQUEST,
  COMPOUND_MIGRATE_PROXY_AUTH_SUCCESS, DENY_COMPOUND_MIGRATE_INSTADAPP_DATA, RESET_COMPOUND_MIGRATE_INSTADAPP_DATA,
  COMPOUND_MIGRATE_APPROVALS_FAILURE,
  COMPOUND_MIGRATE_APPROVALS_REQUEST,
  COMPOUND_MIGRATE_APPROVALS_SUCCESS,
  MIGRATE_COMPOUND_TO_PROXY_FAILURE,
  MIGRATE_COMPOUND_TO_PROXY_REQUEST,
  MIGRATE_COMPOUND_TO_PROXY_SUCCESS,
} from '../../actionTypes/compoundActionTypes/compoundMigrateActionTypes';
import clientConfig from '../../config/clientConfig.json';
import { trackEvent } from '../../services/analyticsService';
import { createDSProxyAction } from '../makerActions/makerActions';
import { authorizeProxyForInstadappAccount } from '../instaActions';
import { callMultipleTxAction, sendTx } from '../txNotificationActions';
import * as compoundRecipes from '../../recipes/compoundRecipes';
import { callRecipeViaProxy } from '../../services/contractCallService';
import { fetchCompoundAssetsData, switchToUserWallet } from './compoundManageActions';
import { captureException } from '../../sentry';
import { approveAssets } from '../assetsActions';

export const fetchCompoundInstadappData = () => async (dispatch, getState) => {
  if (clientConfig.network !== 1) return;
  const { general: { account }, maker: { proxyAddress } } = getState();
  if (!account) {
    dispatch({ type: ADD_COMPOUND_MIGRATE_INSTADAPP_DATA, payload: [] });
    return false;
  }
  const instadappAccounts = await getInstadappAccountsWithAuthorized(account, proxyAddress);
  if (!instadappAccounts.length) {
    dispatch({ type: ADD_COMPOUND_MIGRATE_INSTADAPP_DATA, payload: [] });
    return false;
  }
  await dispatch(fetchCompoundAssetsData());
  const { assets, compoundManage: { assetsData } } = getState();
  const promises = instadappAccounts.map(async (dsa) => {
    const data = await getCompoundAccountData(dsa.account, assetsData, assets, 'dsa');
    return {
      dsa, data,
    };
  });
  return Promise.all(promises).then((positions) => {
    const filteredPositions = positions.filter(({ data: { suppliedUsd } }) => suppliedUsd > 100);
    dispatch({ type: ADD_COMPOUND_MIGRATE_INSTADAPP_DATA, payload: filteredPositions });
    return filteredPositions.length > 0;
  });
};
export const resetCompoundInstadappData = () => (dispatch) => dispatch({ type: RESET_COMPOUND_MIGRATE_INSTADAPP_DATA });

export const migrateCompoundFromEoa = (compoundData, closeModal) => async (dispatch, getState) => {
  trackEvent('compoundMigrate', 'migrateCompoundPositionFromEoa');
  let { proxyAddress } = getState().maker;
  if (!proxyAddress) {
    proxyAddress = await dispatch(createDSProxyAction());
    if (!proxyAddress) return;
  }
  try {
    const sendTxFunc = promise => sendTx(promise, t('compound.migrating_position_from_account'), 'Compound', dispatch, getState);
    const { general: { account, accountType } } = getState();
    const debtInDai = compoundData.borrowedUsd;
    const collAssets = filterCollateralAssetsFromUsedAssets(compoundData.usedAssets)
      .map(({ symbol, collateral }) => ({ symbol, collateral }));
    const debtAssets = filterBorrowedAssetsFromUsedAssets(compoundData.usedAssets)
      .map(({ symbol }) => symbol);
    dispatch({ type: COMPOUND_MIGRATE_APPROVALS_REQUEST });
    const recipe = await compoundRecipes.migrate(debtInDai, debtAssets, collAssets, account, proxyAddress, account, false);
    const approvals = await recipe.getAssetsToApprove();
    const approvalTxs = await dispatch(approveAssets(approvals));
    try {
      if (approvalTxs.length > 0) await dispatch(callMultipleTxAction(approvalTxs));
      dispatch({ type: COMPOUND_MIGRATE_APPROVALS_SUCCESS });
    } catch (err) {
      dispatch({ type: COMPOUND_MIGRATE_APPROVALS_FAILURE, payload: err.message });
      return;
    }
    dispatch({ type: MIGRATE_COMPOUND_TO_PROXY_REQUEST });
    await callRecipeViaProxy(accountType, sendTxFunc, proxyAddress, account, recipe, 0, recipe.extraGas);
    dispatch({ type: MIGRATE_COMPOUND_TO_PROXY_SUCCESS });
    dispatch(fetchCompoundAssetsData());
    dispatch(switchToUserWallet());
    closeModal();
    trackEvent('compoundMigrate', 'migrateCompoundPositionFromEoaSuccess');
  } catch (err) {
    dispatch({ type: MIGRATE_COMPOUND_TO_PROXY_FAILURE, payload: err.message });
    captureException(err);
    trackEvent('compoundMigrate', 'migrateCompoundPositionFromEoaError');
  }
};

export const migrateCompoundFromInstadapp = (dsaData, compoundData, closeModal) => async (dispatch, getState) => {
  trackEvent('compoundMigrate', 'migrateCompoundPositionFromInstadapp');

  let { proxyAddress } = getState().maker;
  if (!proxyAddress) {
    proxyAddress = await dispatch(createDSProxyAction());
    if (!proxyAddress) return;
  }
  if (!dsaData.isAuthorizedForProxy) {
    dispatch({ type: COMPOUND_MIGRATE_PROXY_AUTH_REQUEST, payload: dsaData.account });
    try {
      await dispatch(authorizeProxyForInstadappAccount(dsaData.account, dsaData.version));
      dispatch({ type: COMPOUND_MIGRATE_PROXY_AUTH_SUCCESS, payload: dsaData.account });
    } catch (err) {
      dispatch({ type: COMPOUND_MIGRATE_PROXY_AUTH_ERROR, payload: { account: dsaData.account, error: err.message } });
      return;
    }
  }
  try {
    dispatch({ type: COMPOUND_MIGRATE_FROM_INSTADAPP_REQUEST, payload: dsaData.account });
    const sendTxFunc = promise => sendTx(promise, t('compound.migrating_position_from_instadapp'), 'Compound', dispatch, getState);

    const { general: { account, accountType } } = getState();
    const debtInDai = compoundData.borrowedUsd;
    const collAssets = filterCollateralAssetsFromUsedAssets(compoundData.usedAssets)
      .map(({ symbol, collateral }) => ({ symbol, collateral }));
    const debtAssets = filterBorrowedAssetsFromUsedAssets(compoundData.usedAssets)
      .map(({ symbol }) => symbol);
    const recipe = compoundRecipes.migrate(debtInDai, debtAssets, collAssets, account, proxyAddress, dsaData.account, true);
    await callRecipeViaProxy(accountType, sendTxFunc, proxyAddress, account, recipe, 0, recipe.extraGas);
    dispatch({ type: COMPOUND_MIGRATE_FROM_INSTADAPP_SUCCESS, payload: dsaData.account });
    dispatch(fetchCompoundAssetsData());
    dispatch(switchToUserWallet());
    closeModal();
    trackEvent('compoundMigrate', 'migrateInstaDappCompoundSuccess');
  } catch (err) {
    dispatch({ type: COMPOUND_MIGRATE_FROM_INSTADAPP_ERROR, payload: { account: dsaData.account, error: err.message } });
    captureException(err);
    trackEvent('compoundMigrate', 'migrateInstaDappCompoundError');
  }
};
export const allowCompoundInstadappData = () => (dispatch) => dispatch({ type: ALLOW_COMPOUND_MIGRATE_INSTADAPP_DATA });
export const denyCompoundInstadappData = () => (dispatch) => dispatch({ type: DENY_COMPOUND_MIGRATE_INSTADAPP_DATA });
