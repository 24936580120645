import t from 'translate';
import { captureException } from 'sentry';
import {
  SUBSCRIBE_COMING_SOON_REQUEST,
  SUBSCRIBE_COMING_SOON_SUCCESS,
  SUBSCRIBE_COMING_SOON_FAILURE,
  RESET_SUBSCRIBE_COMING_SOON,

  CHANGE_LEDGER_ACC_TYPE,

  LIST_LEDGER_ACCOUNTS_REQUEST,
  LIST_LEDGER_ACCOUNTS_SUCCESS,
  LIST_LEDGER_ACCOUNTS_FAILURE,

  SET_LEDGER_PATH,

  LEDGER_LIST_MORE_ACCOUNTS_REQUEST,
  LEDGER_LIST_MORE_ACCOUNTS_SUCCESS,
  LEDGER_LIST_MORE_ACCOUNTS_FAILURE,

  RESET_CONNECT_LEDGER,

  GET_GAS_PRICES_REQUEST,
  GET_GAS_PRICES_SUCCESS,
  GET_GAS_PRICES_FAILURE,
  GAS_PRICES_RESET,

  GET_GAS_PRICE_HISTORY_REQUEST,
  GET_GAS_PRICE_HISTORY_SUCCESS,
  GET_GAS_PRICE_HISTORY_FAILURE,

  CHANGE_WALLET_TYPE,
  SET_APP_BACKDROP,
} from '../actionTypes/generalActionTypes';
import { ACCOUNT_TYPES, DEFAULT_LEDGER_PATH } from '../constants/general';
import { subscribeComingSoonApiCall, getGasPriceHistoryApi } from '../services/apiService';
import { notify } from './noitificationActions';
import { loginMetaMask, logOut, switchToAccount } from './accountActions';
import { isMetaMaskApproved } from '../services/ethService';
import { compareAddresses, wait } from '../services/utils';
import { addToLsState } from '../services/localStorageService';
import { closeModal } from './modalActions';
import clientConfig from '../config/clientConfig.json';
import { MM_CHANGE_MODAL } from '../components/Modals/modalTypes';

/**
 * Listens to account change and reloads the page if there is no account or
 * the account changes
 *
 * @return {Function}
 */
export const listenToAccChange = () => (dispatch, getState) => {
  if (window.ethereum) window.ethereum.autoRefreshOnNetworkChange = false;
  if (window.ethereum?.on) {
    window.ethereum.on('accountsChanged', async (accounts) => {
      const { account, connectingProvider, accountType } = getState().general;
      const { modalType, modalProps } = getState().modal;

      if (modalType === MM_CHANGE_MODAL) {
        if (compareAddresses(modalProps.targetAccount, accounts[0])) {
          dispatch(closeModal());
          dispatch(switchToAccount(ACCOUNT_TYPES.browser, modalProps.targetAccount));
          return;
        }
      }

      if (connectingProvider) return;

      if (accountType !== ACCOUNT_TYPES.browser) return;

      if (modalType !== '') {
        dispatch(closeModal());
        await wait(500);
      }

      if (account && !accounts[0]) dispatch(logOut());
      if (accounts[0] !== account && await isMetaMaskApproved()) dispatch(loginMetaMask(false));
    });

    window.ethereum.on('chainChanged', async (_chainId) => {
      const chainId = parseInt(_chainId, 16);
      if (getState().general.accountType === ACCOUNT_TYPES.browser && await isMetaMaskApproved()) {
        if (clientConfig.supportedNetworks.includes(chainId)) dispatch(loginMetaMask(false));
        else dispatch(logOut());
      }
    });
  } else {
    const interval = setInterval(async () => {
      const { account, connectingProvider, accountType } = getState().general;

      if (connectingProvider) return;
      if (accountType !== ACCOUNT_TYPES.browser) {
        return clearInterval(interval);
      }

      const accounts = await window._web3.eth.getAccounts();

      if (account && !accounts[0]) window.location.reload();
      if (accounts[0] !== account) loginMetaMask(false);
    }, 1000);
  }
};

/**
 * Sends the users email from the feature subscribe form to the mailchimp account
 *
 * @param email {String}
 * @return {*}
 */
export const subscribeComingSoonAction = ({ email }) => async (dispatch) => {
  dispatch({ type: SUBSCRIBE_COMING_SOON_REQUEST });

  try {
    const res = await subscribeComingSoonApiCall(email);

    if (!res.ok) {
      dispatch({ type: SUBSCRIBE_COMING_SOON_FAILURE, payload: t('errors.email_fail') });
    } else {
      dispatch({ type: SUBSCRIBE_COMING_SOON_SUCCESS });
    }
  } catch (e) {
    dispatch({ type: SUBSCRIBE_COMING_SOON_FAILURE, payload: e.message });
  }
};

/**
 * Resets the subscribeComingSoon state in the reducer
 */
export const resetSubscribeComingSoon = () => (dispatch) => {
  dispatch({ type: RESET_SUBSCRIBE_COMING_SOON });
};

/**
 * Lists all ledger accounts for a account type and path
 *
 * @return {Function}
 */
export const listLedgerAccounts = () => async (dispatch, getState) => {
  dispatch({ type: LIST_LEDGER_ACCOUNTS_REQUEST });

  const { ledgerAccType, path } = getState().general;

  try {
    const ledgerService = await import(/* webpackChunkName: "wallets" */ '../services/ledgerService');
    const payload = await ledgerService.listAccounts(ledgerAccType.value, 0, 5, path);

    dispatch({ type: LIST_LEDGER_ACCOUNTS_SUCCESS, payload });
  } catch (err) {
    dispatch({ type: LIST_LEDGER_ACCOUNTS_FAILURE, payload: err.message });
    captureException(err);
  }
};

/**
 * Changes the connect page ledger account type select input value
 *
 * @param payload {Object}
 */
export const changeLedgerAccType = payload => (dispatch) => {
  dispatch({ type: CHANGE_LEDGER_ACC_TYPE, payload });
  dispatch(listLedgerAccounts());
};

/**
 * Sets the general reducer path property to the input value and gets accounts for that path
 *
 * @return {Function}
 */
export const setLedgerPath = path => (dispatch) => {
  dispatch({ type: SET_LEDGER_PATH, payload: path });
  dispatch(listLedgerAccounts());
};

/**
 * Sets the general reducer path property to the ledger default path
 *
 * @return {Function}
 */
export const setLedgerDefaultPath = () => (dispatch) => {
  dispatch({ type: SET_LEDGER_PATH, payload: DEFAULT_LEDGER_PATH });
};

/**
 * Adds new accounts to the ledger connect list of accounts
 *
 * @return {Function}
 */
export const ledgerListMoreAccounts = () => async (dispatch, getState) => {
  dispatch({ type: LEDGER_LIST_MORE_ACCOUNTS_REQUEST });

  try {
    const { ledgerAccType, path, ledgerAccounts } = getState().general;

    const ledgerService = await import(/* webpackChunkName: "wallets" */ '../services/ledgerService');
    let payload = await ledgerService.listAccounts(ledgerAccType.value, ledgerAccounts.length, 5, path);

    payload = [...ledgerAccounts, ...payload];

    dispatch({ type: LEDGER_LIST_MORE_ACCOUNTS_SUCCESS, payload });
  } catch (err) {
    dispatch({ type: LEDGER_LIST_MORE_ACCOUNTS_FAILURE });
    notify(err.message, 'error')(dispatch);
    captureException(err);
  }
};

/**
 * Resets the state for the connect ledger wallet section
 *
 * @return {Function}
 */
export const resetConnectLedger = () => (dispatch) => { dispatch({ type: RESET_CONNECT_LEDGER }); };

export const getMedianGasPrices = async () => {
  const res = await fetch(`${clientConfig.apiUrl}/api/gas-price/current`);
  const data = await res.json();
  if (!data.fast) throw new Error('Invalid median prices');
  return {
    fast: data.fast,
    regular: data.regular,
    cheap: data.cheap,
  };
};

/**
 * Gets gas price history from API
 *
 * @return {function(*): Promise<void>}
 */
const getGasPriceHistory = () => async (dispatch) => {
  dispatch({ type: GET_GAS_PRICE_HISTORY_REQUEST });
  try {
    const payload = (await getGasPriceHistoryApi()).history;
    dispatch({ type: GET_GAS_PRICE_HISTORY_SUCCESS, payload });
  } catch (error) {
    dispatch({ type: GET_GAS_PRICE_HISTORY_FAILURE, payload: `Couldn't get gas price history: ${error.message}` });
    captureException(error);
  }
};

/**
 * Gets gas prices and waiting time for them
 *
 * @param fetchHistory
 * @return {function(*): Promise<void>}
 */
export const getGasPrices = (fetchHistory = false) => async (dispatch) => {
  dispatch({ type: GET_GAS_PRICES_REQUEST });
  try {
    const payload = await getMedianGasPrices();
    dispatch({ type: GET_GAS_PRICES_SUCCESS, payload });
    if (fetchHistory) dispatch(getGasPriceHistory());
    return payload;
  } catch (err) {
    dispatch({ type: GET_GAS_PRICES_FAILURE, payload: `Couldn't get current gas prices: ${err.message}` });
    captureException(err);
  }
};

/**
 * Updates gas price data
 *
 * @return {function(*=): number}
 */
export const updateGasPriceInterval = () => (dispatch) => window.requestAnimationFrame(() => setTimeout(() => dispatch(getGasPrices()), 1000 * 60 * 15));

/**
 * Resets the gas price modal props in the reducer
 * @return {Function}
 */
export const resetGasPriceModal = () => (dispatch) => { dispatch({ type: GAS_PRICES_RESET }); };

/**
 * Changes the selected used wallet type and saves it to the localStorage
 * @return {function(...[*]=)}
 */
export const changeWalletType = payload => (dispatch, getState) => {
  const { account } = getState().general;

  if (account) addToLsState({ account, walletType: payload });

  dispatch({ type: CHANGE_WALLET_TYPE, payload });
};

// with no class, standard tansition time applies
export const setAppBackdrop = (isOpen, _class = '', onClick = () => {}) => (dispatch) => dispatch({ type: SET_APP_BACKDROP, payload: { isOpen, _class, onClick } });
