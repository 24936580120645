import React, { useEffect } from 'react';
import t from 'translate';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import UserWalletModal from './UserWalletModal';

const CompoundUserWalletModal = ({
  closeModal, proxyAddress,
}) => (
  <UserWalletModal closeModal={closeModal}>
    <div className="content-section-wrapper info">
      <div className="description">
        {t('account.compound_user_wallet_info')}
        <br />
        <br />
        {t('account.compound_user_wallet_info_2')}
        <br />
        <br />
        <a target="_blank" rel="noopener noreferrer" href="https://help.defisaver.com/smart-savings/what-is-the-user-wallet">{t('account.wallet_info_learn_more')}</a>
      </div>
    </div>
  </UserWalletModal>
);

CompoundUserWalletModal.defaultProps = {
  proxyAddress: '',
};

CompoundUserWalletModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  proxyAddress: PropTypes.string,
};

const mapStateToProps = ({ maker, savingsManage }) => ({
  proxyAddress: maker.proxyAddress,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(CompoundUserWalletModal);
