import dfs from '@defisaver/sdk';
import cloneDeep from 'lodash/cloneDeep';
import RecipeAction from '../RecipeAction';
import { changeBalance } from '../../services/recipeCreator/recipeActionUtils';
import { Source, Amount, NftId } from '../../components/Recipes/RecipeCreator/inputTypes';
import SupplyIcon from '../recipeIcons/Supply.svg';
import { MAXUINT128 } from '../../constants/general';


export default class UniswapV3CollectAction extends RecipeAction {
  static prettyName = 'Collect fees from Uniswap V3';

  static protocol = 'uniswap';

  static protocolPrettyName = 'Uniswap';

  static description = 'Collects accrued fees from a Uniswap v3 position.';

  constructor(tokenID = '', to = 'wallet') {
    super();
    this.inputs = [
      new NftId('NFT ID', tokenID),
      new Source('To', to),
    ];
    this.output = new Amount();
  }

  static getIcon() {
    return SupplyIcon;
  }

  setError(e) {
    this.error = e;
  }

  getError() {
    return this.error;
  }

  async getAfterValues(_balances = {}, returnValues = [], actions = [], _positions = {}, getState) {
    const positions = cloneDeep(_positions);
    const {
      maker: { proxyAddress },
      general: { account },
      uniswapV3: { account: accountNfts, proxy: proxyNfts },
    } = getState();
    const balances = cloneDeep(_balances);
    const nftOptions = [...accountNfts, ...proxyNfts];
    const allUniNfts = [
      ...nftOptions
        .filter(nft => !Object.entries(positions)
          .find(pos => pos[0].substr(0, 9) === 'uniswapV3' && pos[1].id === nft.id.toString())),
      ...Object.keys(positions)
        .filter(id => id.substr(0, 9) === 'uniswapV3' && !nftOptions.find(nft => nft.id.toString() === id.substr(10)))
        .map(id => ({
          ...positions[id],
        })),
    ];
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const uniV3Position = allUniNfts.find(nft => nft.id === args[0]);
    if (!positions[`uniswapV3${args[0]}`]) positions[`uniswapV3${args[0]}`] = { ...uniV3Position, value: uniV3Position.id };
    await changeBalance(balances, args[1], uniV3Position.tokenFirstInfo.symbol, positions[`uniswapV3${args[0]}`].rewardFirst, args[1] === 'wallet' ? account : proxyAddress, uniV3Position.tokenFirstInfo.address);
    await changeBalance(balances, args[1], uniV3Position.tokenSecondInfo.symbol, positions[`uniswapV3${args[0]}`].rewardSecond, args[1] === 'wallet' ? account : proxyAddress, uniV3Position.tokenSecondInfo.address);
    positions[`uniswapV3${args[0]}`].tokensOwedFirst = '0';
    positions[`uniswapV3${args[0]}`].tokensOwedSecond = '0';
    positions[`uniswapV3${args[0]}`].rewardFirst = '0';
    positions[`uniswapV3${args[0]}`].rewardSecond = '0';
    return { returnValue: '0', balances, positions };
  }

  async toDfsAction(getState, recipeActions, returnValues, positions) {
    const {
      general: { account },
      maker: { proxyAddress },
      recipeCreator: { actions },
    } = getState();
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const uniV3Position = positions[`uniswapV3${args[0]}`];
    const to = args[1] === 'wallet' ? account : proxyAddress;
    return new dfs.actions.uniswapV3.UniswapV3CollectAction(
      this.inputs[0].value,
      to,
      MAXUINT128,
      MAXUINT128,
      uniV3Position.owner,
    );
  }
}
