import * as types from '../../actionTypes/liquityActionTypes/liquityManageActionTypes';
import { RESET_BR_SLIPPAGE_VALUES, SET_BR_SLIPPAGE_PERCENT } from '../../actionTypes/modalActionTypes';

const INITIAL_STATE = {
  generating: false,
  generatingError: '',

  maxGenerate: '0',
  gettingMaxGenerate: false,
  gettingMaxGenerateError: '',

  withdrawing: false,
  withdrawingError: '',

  maxWithdraw: '0',
  gettingMaxWithdraw: false,
  gettingMaxWithdrawError: '',

  addingCollateral: false,
  addingCollateralError: '',

  maxAddCollateral: '0',
  gettingMaxAddCollateral: false,
  gettingMaxAddCollateralError: '',

  payingBack: false,
  payingBackError: '',

  maxPayback: '0',
  gettingMaxPayback: false,
  gettingMaxPaybackError: '',

  closingTrove: false,
  closingTroveErr: '',

  claimingCollateral: false,
  claimingCollateralErr: '',

  boosting: false,
  boostingError: '',

  maxBoost: '0',
  gettingMaxBoost: false,
  gettingMaxBoostError: '',

  boostAmount: '0',
  boostExchangeRate: '0',
  gettingBoostModalData: false,
  gettingBoostModalDataError: '',

  repaying: false,
  repayingError: '',

  maxRepay: '0',
  gettingMaxRepay: false,
  gettingMaxRepayError: '',

  repayAmount: '0',
  repayExchangeRate: '0',
  gettingRepayModalData: false,
  gettingRepayModalDataError: '',

  redeeming: false,
  redeemingErr: '',

  slippagePercent: '0',
  slippageRate: '0',
  tradeSizeImpact: '0',

  useFl: false,
  flProtocol: 'none',
  flFee: '0',
  useAltRecipe: false,
  exchangeSource: '',

  afterType: '',
  afterTrove: null,
  gettingAfterTrove: false,
  gettingAfterTroveFailure: '',
  ratioTooLow: false,
  ratioTooHigh: false,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.LQTY_ADD_COLLATERAL_REQUEST: return {
      ...state,
      addingCollateral: true,
      addingCollateralError: '',
    };

    case types.LQTY_ADD_COLLATERAL_SUCCESS: return {
      ...state,
      addingCollateral: false,
      addingCollateralError: '',
    };

    case types.LQTY_ADD_COLLATERAL_FAILURE: return {
      ...state,
      addingCollateral: false,
      addingCollateralError: payload,
    };

    case types.LQTY_MAX_ADD_COLLATERAL_REQUEST: return {
      ...state,
      gettingMaxAddCollateral: true,
      gettingMaxAddCollateralError: '',

    };

    case types.LQTY_MAX_ADD_COLLATERAL_SUCCESS: return {
      ...state,
      maxAddCollateral: payload,
      gettingMaxAddCollateral: false,
      gettingMaxAddCollateralError: '',

    };

    case types.LQTY_MAX_ADD_COLLATERAL_FAILURE: return {
      ...state,
      gettingMaxAddCollateral: false,
      gettingMaxAddCollateralError: payload,

    };

    case types.LQTY_WITHDRAW_COLLATERAL_REQUEST: return {
      ...state,
      withdrawing: true,
      withdrawingError: '',
    };

    case types.LQTY_WITHDRAW_COLLATERAL_SUCCESS: return {
      ...state,
      withdrawing: false,
      withdrawingError: '',
    };

    case types.LQTY_WITHDRAW_COLLATERAL_FAILURE: return {
      ...state,
      withdrawing: false,
      withdrawingError: payload,
    };


    case types.LQTY_MAX_WITHDRAW_COLLATERAL_REQUEST: return {
      ...state,
      gettingMaxWithdraw: true,
      gettingMaxWithdrawError: '',
    };

    case types.LQTY_MAX_WITHDRAW_COLLATERAL_SUCCESS: return {
      ...state,
      maxWithdraw: payload,
      gettingMaxWithdraw: false,
      gettingMaxWithdrawError: '',
    };

    case types.LQTY_MAX_WITHDRAW_COLLATERAL_FAILURE: return {
      ...state,
      gettingMaxWithdraw: false,
      gettingMaxWithdrawError: payload,
    };

    case types.LQTY_GENERATE_REQUEST: return {
      ...state,
      generating: true,
      generatingError: '',
    };

    case types.LQTY_GENERATE_SUCCESS: return {
      ...state,
      generating: false,
      generatingError: '',
    };

    case types.LQTY_GENERATE_FAILURE: return {
      ...state,
      generating: false,
      generatingError: payload,
    };

    case types.LQTY_MAX_GENERATE_REQUEST: return {
      ...state,
      gettingMaxGenerate: true,
      gettingMaxGenerateError: '',
    };

    case types.LQTY_MAX_GENERATE_SUCCESS: return {
      ...state,
      maxGenerate: payload,
      gettingMaxGenerate: false,
      gettingMaxGenerateError: '',
    };

    case types.LQTY_MAX_GENERATE_FAILURE: return {
      ...state,
      gettingMaxGenerate: false,
      gettingMaxGenerateError: payload,
    };

    case types.LQTY_PAYBACK_REQUEST: return {
      ...state,
      payingBack: true,
      payingBackError: '',
    };

    case types.LQTY_PAYBACK_SUCCESS: return {
      ...state,
      payingBack: false,
      payingBackError: '',
    };

    case types.LQTY_PAYBACK_FAILURE: return {
      ...state,
      payingBack: false,
      payingBackError: payload,
    };

    case types.LQTY_MAX_PAYBACK_REQUEST: return {
      ...state,
      gettingMaxPayback: true,
      gettingMaxPaybackError: '',
    };

    case types.LQTY_MAX_PAYBACK_SUCCESS: return {
      ...state,
      maxPayback: payload,
      gettingMaxPayback: false,
      gettingMaxPaybackError: '',
    };

    case types.LQTY_MAX_PAYBACK_FAILURE: return {
      ...state,
      gettingMaxPayback: false,
      gettingMaxPaybackError: payload,
    };

    case types.LQTY_AFTER_VALUES_REQUEST:
      return {
        ...state,
        gettingAfterTrove: true,
        gettingAfterTroveError: '',
      };

    case types.LQTY_AFTER_VALUES_SUCCESS:
      return {
        ...state,
        gettingAfterTrove: false,
        gettingAfterTroveError: '',
        ...payload,
      };

    case types.LQTY_AFTER_VALUES_FAILURE:
      return {
        ...state,
        gettingAfterTrove: false,
        gettingAfterTroveError: payload,
      };

    case types.LQTY_CLOSE_TROVE_REQUEST: return {
      ...state,
      closingTrove: true,
      closingTroveErr: '',
    };

    case types.LQTY_CLOSE_TROVE_SUCCESS: return {
      ...state,
      closingTrove: false,
      closingTroveErr: '',
    };

    case types.LQTY_CLOSE_TROVE_FAILURE: return {
      ...state,
      closingTrove: false,
      closingTroveErr: payload,
    };

    case types.LQTY_CLAIM_COLLATERAL_REQUEST: return {
      ...state,
      claimingCollateral: true,
      claimingCollateralErr: '',
    };

    case types.LQTY_CLAIM_COLLATERAL_SUCCESS: return {
      ...state,
      claimingCollateral: false,
      claimingCollateralErr: '',
    };

    case types.LQTY_CLAIM_COLLATERAL_FAILURE: return {
      ...state,
      claimingCollateral: false,
      claimingCollateralErr: payload,
    };

    case types.LQTY_BOOST_REQUEST: return {
      ...state,
      boosting: true,
      boostingError: '',
    };

    case types.LQTY_BOOST_SUCCESS: return {
      ...state,
      boosting: false,
      boostingError: '',
    };

    case types.LQTY_BOOST_FAILURE: return {
      ...state,
      boosting: false,
      boostingError: payload,
    };

    case types.LQTY_GET_BOOST_MODAL_DATA_REQUEST:
      return {
        ...state,
        gettingBoostModalData: true,
        gettingBoostModalDataError: '',
      };

    case types.LQTY_GET_BOOST_MODAL_DATA_SUCCESS:
      return {
        ...state,
        gettingBoostModalData: false,
        gettingBoostModalDataError: '',
        ...payload,
      };

    case types.LQTY_GET_BOOST_MODAL_DATA_FAILURE:
      return {
        ...state,
        gettingBoostModalData: false,
        gettingBoostModalDataError: payload,
      };

    case types.LQTY_RESET_BOOST_MODAL: return {
      ...state,
      boosting: false,
      boostingError: '',
      boostAmount: '0',
      boostExchangeRate: '0',
      gettingBoostModalData: false,
      gettingBoostModalDataError: '',
    };

    case types.LQTY_GET_MAX_BOOST_REQUEST: return {
      ...state,
      gettingMaxBoost: true,
      gettingMaxBoostError: '',
    };

    case types.LQTY_GET_MAX_BOOST_SUCCESS: return {
      ...state,
      maxBoost: payload,
      gettingMaxBoost: false,
      gettingMaxBoostError: '',
    };

    case types.LQTY_GET_MAX_BOOST_FAILURE: return {
      ...state,
      gettingMaxBoost: false,
      gettingMaxBoostError: payload,
    };

    case types.LQTY_REPAY_REQUEST: return {
      ...state,
      repaying: true,
      repayingError: '',
    };

    case types.LQTY_REPAY_SUCCESS: return {
      ...state,
      repaying: false,
      repayingError: '',
    };

    case types.LQTY_REPAY_FAILURE: return {
      ...state,
      repaying: false,
      repayingError: payload,
    };

    case types.LQTY_GET_REPAY_MODAL_DATA_REQUEST:
      return {
        ...state,
        gettingRepayModalData: true,
        gettingRepayModalDataError: '',
      };

    case types.LQTY_GET_REPAY_MODAL_DATA_SUCCESS:
      return {
        ...state,
        gettingRepayModalData: false,
        gettingRepayModalDataError: '',
        ...payload,
      };

    case types.LQTY_GET_REPAY_MODAL_DATA_FAILURE:
      return {
        ...state,
        gettingRepayModalData: false,
        gettingRepayModalDataError: payload,
      };

    case types.LQTY_RESET_REPAY_MODAL: return {
      ...state,
      repaying: false,
      repayingError: '',
      repayAmount: '0',
      repayExchangeRate: '0',
      gettingRepayModalData: false,
      gettingRepayModalDataError: '',
    };

    case types.LQTY_GET_MAX_REPAY_REQUEST: return {
      ...state,
      gettingMaxRepay: true,
      gettingMaxRepayError: '',
    };

    case types.LQTY_GET_MAX_REPAY_SUCCESS: return {
      ...state,
      maxRepay: payload,
      gettingMaxRepay: false,
      gettingMaxRepayError: '',
    };

    case types.LQTY_GET_MAX_REPAY_FAILURE: return {
      ...state,
      gettingMaxRepay: false,
      gettingMaxRepayError: payload,
    };

    case types.LQTY_REDEEM_REQUEST: return {
      ...state,
      redeeming: true,
      redeemingErr: '',
    };

    case types.LQTY_REDEEM_SUCCESS: return {
      ...state,
      redeeming: false,
      redeemingErr: '',
    };

    case types.LQTY_REDEEM_FAILURE: return {
      ...state,
      redeeming: false,
      redeemingErr: payload,
    };

    case SET_BR_SLIPPAGE_PERCENT:
      return {
        ...state,
        slippagePercent: payload.slippagePercent,
        slippageRate: payload.slippageRate,
      };

    case RESET_BR_SLIPPAGE_VALUES:
      return {
        ...state,
        slippagePercent: '0',
        slippageRate: '0',
        tradeSizeImpact: '0',
      };

    default:
      return state;
  }
};

