import dfs from '@defisaver/sdk';
import { getAssetInfo } from '@defisaver/tokens';
import { exchangeAssets } from 'constants/assets';
import cloneDeep from 'lodash/cloneDeep';
import Dec from 'decimal.js';
import RecipeAction from '../RecipeAction';
import SupplyIcon from '../recipeIcons/Supply.svg';
import {
  Address, Amount, Asset, Deadline, Slippage, Source, NftId,
} from '../../components/Recipes/RecipeCreator/inputTypes';
import { assetAmountInWeiIgnorePointer, changeBalance } from '../../services/recipeCreator/recipeActionUtils';
import { supplyLPAfterValues } from '../../actions/lpActions/lpActions';
import { normalizeFunc, setSlippagePercent } from '../../services/exchangeServiceCommon';
import { getTokens } from '../../services/uniswapV3Services';
import { getEthAmountForDecimals, getWeiAmountForDecimals } from '../../services/utils';

const _exchangeAssets = [...exchangeAssets()];
_exchangeAssets[0] = getAssetInfo('WETH');

export default class UniswapV3SupplyAction extends RecipeAction {
  static prettyName = 'Supply to Uniswap V3 LP';

  static protocol = 'uniswap';

  static protocolPrettyName = 'Uniswap';

  static description = 'Supplies assets to an existing Uniswap v3 position.';

  constructor(nftId = '', firstAmount = '', secondAmount = '', source = 'wallet', slippage = '2', deadline = '20') {
    super();
    this.inputs = [
      new NftId('NFT ID', nftId),
      new Amount('Amount', firstAmount),
      new Amount('Amount', secondAmount),
      new Source('From', source),
      new Slippage('Slippage tolerance', slippage),
      new Deadline('Deadline', deadline),
    ];
    this.output = new Amount('output', 0);
    this.error = '';
  }

  static getIcon() {
    return SupplyIcon;
  }

  setError(e) {
    this.error = e;
  }

  getError() {
    return this.error;
  }

  getExpires() {
    if (this.updatedAt) {
      return this.updatedAt + (2 * 60 * 1000);
    }
    return Infinity;
  }

  // TODO: fetch pool and position like in withdraw
  async getAfterValues(_balances = {}, returnValues = [], actions = [], _positions = {}, getState) {
    const positions = cloneDeep(_positions);
    const {
      maker: { proxyAddress },
      general: { account },
      uniswapV3: { account: accountNfts, proxy: proxyNfts },
    } = getState();
    const balances = cloneDeep(_balances);
    const nftOptions = [...accountNfts, ...proxyNfts];
    const allUniNfts = [
      ...nftOptions
        .filter(nft => !Object.entries(positions)
          .find(pos => pos[0].substr(0, 9) === 'uniswapV3' && pos[1].id === nft.id.toString())),
      ...Object.keys(positions)
        .filter(id => id.substr(0, 9) === 'uniswapV3' && !nftOptions.find(nft => nft.id.toString() === id.substr(10)))
        .map(id => ({
          ...positions[id],
        })),
    ];
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    if (!args[1] || !args[2]) {
      this.setError('Invalid inputs');
      return { returnValue: '', balances, positions };
    }
    const uniV3Position = allUniNfts.find(nft => nft.id === args[0]);
    if (!positions[`uniswapV3${args[0]}`]) positions[`uniswapV3${args[0]}`] = { ...uniV3Position, value: uniV3Position.id };
    await changeBalance(balances, args[3], uniV3Position.tokenFirstInfo.symbol, new Dec(args[1] || 0).mul(-1).toString(), args[3] === 'wallet' ? account : proxyAddress, uniV3Position.tokenFirstInfo.address);
    await changeBalance(balances, args[3], uniV3Position.tokenSecondInfo.symbol, new Dec(args[2] || 0).mul(-1).toString(), args[3] === 'wallet' ? account : proxyAddress, uniV3Position.tokenSecondInfo.address);
    positions[`uniswapV3${args[0]}`].amountInFirstToken = new Dec(positions[`uniswapV3${args[0]}`].amountInFirstToken).plus(args[1] || 0).toString();
    positions[`uniswapV3${args[0]}`].amountInSecondToken = new Dec(positions[`uniswapV3${args[0]}`].amountInSecondToken).plus(args[2] || 0).toString();
    this.setError('');
    return { returnValue: '0', balances, positions };
  }

  async toDfsAction(getState, recipeActions, returnValues, positions) {
    const {
      general: { account },
      maker: { proxyAddress },
      recipeCreator: { actions, actionCalls },
      uniswapV3: { account: accountNfts, proxy: proxyNfts },
    } = getState();
    const nftOptions = [...accountNfts, ...proxyNfts];
    const allUniNfts = [
      ...nftOptions
        .filter(nft => !Object.entries(positions)
          .find(pos => pos[0].substr(0, 9) === 'uniswapV3' && pos[1].id === nft.id.toString())),
      ...Object.keys(positions)
        .filter(id => id.substr(0, 9) === 'uniswapV3' && !nftOptions.find(nft => nft.id.toString() === id.substr(10)))
        .map(id => ({
          ...positions[id],
        })),
    ];
    const args = this.mapReturnValuesToArgs(actionCalls.map(a => a.returnValue), actions);
    const uniV3Position = allUniNfts.find(nft => nft.id === args[0]);
    const from = args[3] === 'wallet' ? account : proxyAddress;
    const firstAmountWei = getWeiAmountForDecimals(args[1], uniV3Position.tokenFirstInfo.decimals);
    const secondAmountWei = getWeiAmountForDecimals(args[2], uniV3Position.tokenSecondInfo.decimals);
    const minFirstAmount = setSlippagePercent(args[4], args[1]);
    const minSecondAmount = setSlippagePercent(args[4], args[2]);
    const minFirstAmountWei = getWeiAmountForDecimals(minFirstAmount, uniV3Position.tokenFirstInfo.decimals);
    const minSecondAmountWei = getWeiAmountForDecimals(minSecondAmount, uniV3Position.tokenSecondInfo.decimals);
    return new dfs.actions.uniswapV3.UniswapV3SupplyAction(
      this.inputs[0].value,
      firstAmountWei,
      secondAmountWei,
      minFirstAmountWei,
      minSecondAmountWei,
      Date.now() + (1000 * 60 * args[5]),
      from,
      uniV3Position.tokenFirstInfo.address,
      uniV3Position.tokenSecondInfo.address,
    );
  }

  _getPrettyName(actionCalls, actions) {
    const args = this.mapReturnValuesToArgs(actionCalls.map(a => a.returnValue), actions);
    return 'Supply to pool';
  }
}
