import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

const AppBackdrop = ({ appBackdropOpen, appBackdropClass, appBackdropOnClick }) => (
  <div className={`app-backdrop ${appBackdropOpen ? 'app-backdrop--open' : ''} ${appBackdropClass}`} onClick={appBackdropOpen ? appBackdropOnClick : undefined} />
);

AppBackdrop.defaultProps = {
  appBackdropClass: '',
  appBackdropOnClick: () => {},
};

AppBackdrop.propTypes = {
  appBackdropOpen: PropTypes.bool.isRequired,
  appBackdropClass: PropTypes.string,
  appBackdropOnClick: PropTypes.func,
};

const mapStateToProps = ({ general }) => ({
  appBackdropOpen: general.appBackdropOpen,
  appBackdropClass: general.appBackdropClass,
  appBackdropOnClick: general.appBackdropOnClick,
});

export default connect(mapStateToProps, null)(AppBackdrop);
