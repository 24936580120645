export const LQTY_STAKE_REQUEST = 'LQTY_STAKE_REQUEST';
export const LQTY_STAKE_SUCCESS = 'LQTY_STAKE_SUCCESS';
export const LQTY_STAKE_FAILURE = 'LQTY_STAKE_FAILURE';

export const LQTY_UNSTAKE_REQUEST = 'LQTY_UNSTAKE_REQUEST';
export const LQTY_UNSTAKE_SUCCESS = 'LQTY_UNSTAKE_SUCCESS';
export const LQTY_UNSTAKE_FAILURE = 'LQTY_UNSTAKE_FAILURE';

export const LQTY_LUSD_STAKE_REQUEST = 'LQTY_LUSD_STAKE_REQUEST';
export const LQTY_LUSD_STAKE_SUCCESS = 'LQTY_LUSD_STAKE_SUCCESS';
export const LQTY_LUSD_STAKE_FAILURE = 'LQTY_LUSD_STAKE_FAILURE';

export const LQTY_LUSD_UNSTAKE_REQUEST = 'LQTY_LUSD_UNSTAKE_REQUEST';
export const LQTY_LUSD_UNSTAKE_SUCCESS = 'LQTY_LUSD_UNSTAKE_SUCCESS';
export const LQTY_LUSD_UNSTAKE_FAILURE = 'LQTY_LUSD_UNSTAKE_FAILURE';

export const LQTY_GET_STAKE_BALANCES_AND_REWARDS_REQUEST = 'LQTY_GET_STAKE_BALANCES_AND_REWARDS_REQUEST';
export const LQTY_GET_STAKE_BALANCES_AND_REWARDS_SUCCESS = 'LQTY_GET_STAKE_BALANCES_AND_REWARDS_SUCCESS';
export const LQTY_GET_STAKE_BALANCES_AND_REWARDS_FAILURE = 'LQTY_GET_STAKE_BALANCES_AND_REWARDS_FAILURE';

export const LQTY_WITHDRAW_ETH_GAIN_TO_TROVE_REQUEST = 'LQTY_WITHDRAW_ETH_GAIN_TO_TROVE_REQUEST';
export const LQTY_WITHDRAW_ETH_GAIN_TO_TROVE_SUCCESS = 'LQTY_WITHDRAW_ETH_GAIN_TO_TROVE_SUCCESS';
export const LQTY_WITHDRAW_ETH_GAIN_TO_TROVE_FAILURE = 'LQTY_WITHDRAW_ETH_GAIN_TO_TROVE_FAILURE';
