import React from 'react';
import PropTypes from 'prop-types';

import TooltipWrapper from '../../TooltipWrapper/TooltipWrapper';
import InfoIcon from '../InfoIcon';

import './InfoBox.scss';

const InfoBox = ({ message }) => (
  <TooltipWrapper title={message}>
    <div className="info-box-wrapper">
      <InfoIcon />
    </div>
  </TooltipWrapper>
);

InfoBox.defaultProps = {
  message: '',
};

InfoBox.propTypes = {
  message: PropTypes.string,
};

export default InfoBox;
