import { apiUrl } from 'config/clientConfig.json';

/**
 * Track matomo event
 *
 * @param category
 * @param action
 * @param name
 * @param value
 */
export const trackEvent = (category, action, name, value) => {
  try {
    if (window._paq && window._paq.push) {
      window._paq.push(['trackEvent', category, action, name, value]);
    }
  } catch (e) { console.error(e); }
};

export const logTx = (tx, additionalInfo) => {
  try {
    const data = {
      protocol: additionalInfo.protocol,
      failing: !!tx.failing,
      forkId: window._web3.forkId,
      additionalInfo: { additionalInfo, protocol: undefined },
    };

    // Only log failing txs and created recipes
    if (!data.failing && data.protocol !== 'recipe_creator') return;

    fetch(`${apiUrl}/api/pre-exec-tx/`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });
  } catch (err) {
    console.error(err);
  }
};
