import React, { useState } from 'react';
import t from 'translate';
import { Link, withRouter } from 'react-router-dom';

import { subscribeToNewsletter } from '../../services/apiService';

import GithubIcon from '../Decorative/GithubIcon';
import TwitterIcon from '../Decorative/TwitterIcon';
import DiscordIcon from '../Decorative/DiscordIcon';
import MediumIcon from '../Decorative/MediumIcon';
import ErrorBox from '../Decorative/ErrorBox/ErrorBox';
import SuccessBox from '../Decorative/SuccessBox/SuccessBox';

import './Footer.scss';


const Footer = () => {
  const [value, setValue] = useState('');
  const [clicked, setClicked] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState({ text: '', ok: false });

  const handleChange = (e) => { setValue(e.target.value); };

  return (
    <div className="footer-wrapper ">
      <div className="width-container">
        <div className="left-side">
          <div className="social-media-links-wrapper">
            <div className="label">{t('common.follow_us')}</div>

            <div className="icons-wrapper">
              <a className="medium-icon" target="_blank" rel="noopener noreferrer" href="https://medium.com/defi-saver">
                <MediumIcon />
              </a>

              <a className="discord-icon" target="_blank" rel="noopener noreferrer" href="https://discord.gg/XGDJHhZ">
                <DiscordIcon />
              </a>

              <a
                className="twitter-icon"
                target="_blank"
                rel="noopener noreferrer"
                href="https://twitter.com/defisaver"
              >
                <TwitterIcon />
              </a>

              <a
                className="github-icon"
                target="_blank"
                rel="noopener noreferrer"
                href="https://github.com/defisaver/defisaver-v3-contracts"
              >
                <GithubIcon />
              </a>
            </div>
          </div>
          <div className="company-links">
            <Link to="/terms-of-service" className="link">{t('pages.terms_of_service')}</Link>
            {' | '}
            <Link to="/privacy-policy" className="link">{t('pages.privacy_policy')}</Link>
          </div>
          <div className="company-info">
            Developed by Decenter.
          </div>
        </div>
        <div className="right-side">
          <div className="right-links-wrapper">
            <Link to="/stats">{t('common.stats')}</Link>
            <a href="https://help.defisaver.com" target="_blank" rel="noopener noreferrer">{t('pages.help')}</a>
            <a href="https://medium.com/defi-saver" target="_blank" rel="noopener noreferrer">{t('pages.blog')}</a>
          </div>

          <div id="mc_embed_signup">
            {!subscriptionStatus.text && (
              <form
                className="validate"
                target="_blank"
                noValidate
                onSubmit={async (e) => {
                  setClicked(true);
                  e.preventDefault();
                  try {
                    const res = await subscribeToNewsletter({ email: value });
                    if (res.ok) setSubscriptionStatus({ text: 'Subscribed successfully.', ok: true });
                    else throw new Error('Error while subscribing.');
                  } catch (err) {
                    setSubscriptionStatus({ text: err.message, ok: false });
                  } finally {
                    setClicked(false);
                  }
                }}
              >
                <div id="mc_embed_signup_scroll">
                  <h4>{t('misc.newsletter_sub')}:</h4>
                  <div className="mc-field-group">
                    <label htmlFor="mce-EMAIL">Email Address </label>
                    <input
                      type="email"
                      name="EMAIL"
                      className="required email"
                      id="mce-EMAIL"
                      placeholder="email"
                      autoComplete="off"
                      value={value}
                      onChange={handleChange}
                    />
                    <div className="clear">
                      <input
                        type="submit"
                        value="Subscribe"
                        name="subscribe"
                        id="mc-embedded-subscribe"
                        className={`button green ${value === '' && 'disabled'}`}
                        disabled={value === '' || clicked}
                      />
                    </div>
                  </div>
                  <div id="mce-responses" className="clear">
                    <div className="response" id="mce-error-response" style={{ display: 'none' }} />
                    <div className="response" id="mce-success-response" style={{ display: 'none' }} />
                  </div>
                </div>
              </form>
            )}
            {subscriptionStatus.text && (
              <div>
                {subscriptionStatus.ok && <SuccessBox>{subscriptionStatus.text}</SuccessBox>}
                {!subscriptionStatus.ok && <ErrorBox>{subscriptionStatus.text}</ErrorBox>}
              </div>
            )}
          </div>

        </div>
      </div>
    </div>
  );
};


export default withRouter(Footer);
