import React from 'react';

import '../../common/information-pages.scss';

const PrivacyPolicy = () => (
  <div className="privacy-policy-wrapper information-wrapper">
    <div className="first-section">
      <div className="jumbotron-heading">
        <p>Privacy Policy</p>
        <p>Last updated: September 18, 2019</p>
      </div>
    </div>

    <div className="points-wrapper">
      <div className="point">
        <div className="point-text">
          This Privacy Policy explains how DeFi Saver collects, uses, and discloses information about you through its websites, including web applications, and other online products and services (collectively, the “Services”) or when you otherwise interact with us.
        </div>
        <div className="point-text">
          We may change this Privacy Policy from time to time. If we make changes, we will notify you by revising the date at the top of the Privacy Policy and, in some cases, we may provide you with additional notice (such as adding a statement to our homepage or sending you a notification). We encourage you to review the Privacy Policy whenever you access the Services or otherwise interact with us to stay informed about our information practices and the choices available to you.
        </div>

        <div className="point-header">Collection of information</div>

        <div className="point-header-small">Information You Provide To Us</div>
        <div className="point-text">
          DeFi Saver is an application that runs within the MakerDAO Dai system. In order to fully understand the workings of the systems and the terms of service, we recommend reading the Dai Stablecoin System whitepaper, as well as the Dai Public Announcement and Dai Terms of Service available in the MakerDAO CDP Portal.
        </div>

        <div className="point-header-small">Automatically Collected Information</div>
        <div className="point-text">
          When you access or use our Services, we automatically collect information about you, including:
        </div>
        <div className="point-text">
          Log Information: We collect log information about your use of the Services, including the type of browser you use, access times, pages viewed, your IP address and the page you visited before navigating to our Services.
        </div>
        <div className="point-text">
          Device Information: We collect information about the computer or mobile device you use to access our Services, including the hardware model, operating system and version, unique device identifiers, and mobile network information.
        </div>
        <div className="point-text">
          Information Collected by Cookies and Other Tracking Technologies: We use various technologies to collect information, including cookies and web beacons. Cookies are small data files stored on your hard drive or in device memory that help us improve our Services and your experience, see which areas and features of our Services are popular and count visits. For more information about cookies and how to disable them, please see “Your Choices” below.
        </div>

        <div className="point-header-small">Information We Collect from Other Sources</div>
        <div className="point-text">
          We may obtain information from other sources, including third parties, and combine that with the information we collect through our Services.
        </div>

        <div className="point-header-small">Types of Information We Collect</div>
        <div className="point-text">
          We do not collect personal information. During your stay on DeFiSaver.com and your use of the DeFi Saver app we do not request or collect any information from you by which you may be personally identified, including your name, postal address, street number, city, state, zip code, country, email address, telephone number or any other identifier by which you may be contacted online or offline.
        </div>
        <div className="point-text">
          We only collect non-personal information. During your stay on DeFiSaver.com and your use of the DeFi Saver app we may gather information from your browsing session that does not individually identify you, such as your IP address, domain server, internet browser and version, device, and data related to usage, performance, site security and traffic patterns.
        </div>

        <div className="point-header">Use of information</div>
        <div className="point-text">
          We use the information we collect to provide, maintain, and improve our services. We may also use the information we collect to:
        </div>
        <div className="point-text">
          Send you technical notices, updates, security alerts and support and administrative messages and to respond to your comments, questions and customer service requests;
        </div>
        <div className="point-text">
          Monitor and analyze trends, usage and activities in connection with our Services;
        </div>
        <div className="point-text">
          Detect, investigate and prevent fraudulent transactions and other illegal activities and protect the rights and property of DeFi Saver and other DeFi Saver users.
        </div>

        <div className="point-header">Sharing of information</div>
        <div className="point-text">
          We may share information about you as follows or as otherwise described in this Privacy Policy:
          With vendors, consultants and other service providers who need access to such information to carry out work on our behalf;
        </div>
        <div className="point-text">
          In response to a request for information if we believe disclosure is in accordance with, or required by, any applicable law, regulation or legal process;
        </div>
        <div className="point-text">
          If we believe your actions are inconsistent with our user agreements or policies, or to protect the rights, property and safety of DeFi Saver or other DeFi Saver users;
        </div>
        <div className="point-text">
          In connection with, or during negotiations of, any merger, sale of company assets, financing or acquisition of all or a portion of our business by another company;
        </div>
        <div className="point-text">
          Between and among DeFi Saver and our current and future parents, affiliates, subsidiaries and other companies under common control and ownership.
        </div>

        <div className="point-header">Advertising and Analytics Services Provided by Third Parties</div>
        <div className="point-text">
          We may use analytics services that use cookies, web beacons, device identifiers and other technologies to provide us with information about your use of DeFi Saver services, including your IP address, web browser, mobile network information, pages viewed, time spent on pages or in app and links clicked. This information may be used by DeFi Saver to analyze and track data and determine the popularity of certain content.
        </div>
        <div className="point-text">
          We do not use any advertising tracking services that would enable us to show DeFi Saver content or advertisements to you outside of our website and app, nor do we share information about you with any such services.
        </div>

        <div className="point-header">Security</div>
        <div className="point-text">
          DeFi Saver takes reasonable measures to help protect information about you from loss, theft, misuse and unauthorized access, disclosure, alteration and destruction.
        </div>

        <div className="point-header">Data retention</div>
        <div className="point-text">
          We store the information we collect about you for as long as is necessary for the purpose(s) for which we originally collected it, or for other legitimate business purposes, including to meet our legal or other regulatory obligations.
        </div>

        <div className="point-header">Your Choices</div>

        <div className="point-header-small">Account Information</div>
        <div className="point-text">
          You may update, correct or delete information about you at any time by
          <a className="link next-to-comma" href="https://www.decenter.com/#contact" rel="noopener noreferrer" target="_blank">contacting us</a>. Please note that we may retain cached or archived copies of information about you for a certain period of time.
        </div>

        <div className="point-header-small">Cookies</div>
        <div className="point-text">
          Most web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove or reject browser cookies. Please note that if you choose to remove or reject cookies, this could affect the availability and functionality of our Services.
        </div>

        <div className="point-header-small">Contact Us</div>
        <div className="point-text">
          If you have any questions about this Privacy Policy, please
          <a className="link next-to-comma" href="https://www.decenter.com/#contact" rel="noopener noreferrer" target="_blank">contact us</a>.
        </div>
      </div>
    </div>
  </div>
);

export default PrivacyPolicy;
