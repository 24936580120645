/* eslint-disable */
import React from 'react';

export default function ForkIcon(props) {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M0.360474 0.922119C0.360474 0.507906 0.69626 0.172119 1.11047 0.172119H6.94381C7.35802 0.172119 7.69381 0.507906 7.69381 0.922119V6.75545C7.69381 7.16967 7.35802 7.50545 6.94381 7.50545H4.7771V9.33887H6.94381C7.35802 9.33887 7.69381 9.67465 7.69381 10.0889V12.6612C8.92026 12.5806 10.0804 12.0576 10.9549 11.1832C11.9082 10.2299 12.4437 8.93694 12.4437 7.58876C12.4437 7.5606 12.4453 7.53281 12.4483 7.50545H10.2772C9.86296 7.50545 9.52717 7.16967 9.52717 6.75545V0.922119C9.52717 0.507906 9.86296 0.172119 10.2772 0.172119H16.1105C16.5247 0.172119 16.8605 0.507906 16.8605 0.922119V6.75545C16.8605 7.16967 16.5247 7.50545 16.1105 7.50545H13.9392C13.9422 7.53281 13.9437 7.5606 13.9437 7.58876C13.9437 9.33477 13.2501 11.0093 12.0155 12.2439C10.8597 13.3997 9.31841 14.0813 7.69381 14.1637V15.9222C7.69381 16.3364 7.35802 16.6722 6.94381 16.6722H1.11047C0.69626 16.6722 0.360474 16.3364 0.360474 15.9222V10.0889C0.360474 9.67465 0.69626 9.33887 1.11047 9.33887H3.2771L3.2771 7.50545H1.11047C0.69626 7.50545 0.360474 7.16967 0.360474 6.75545V0.922119ZM11.0272 6.00545V1.67212H15.3605V6.00545H11.0272Z" fill="#61717E"/>
    </svg>
  );
}
