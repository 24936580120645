import t from 'translate';
import Dec from 'decimal.js';
import { captureException } from 'sentry';
import { getReflexerRecipe, getAction, checkAvailableDebt } from 'services/reflexerServices/reflexerManageService';
import {
  formatNumber, resetFields, getEnsAddress, isAddress, formatAcc,
} from 'services/utils';
import { getBestExchangePrice, getSlippageThreshold } from 'services/exchangeServiceV3';
import { useFlForRepay, useFlForBoost, getMaxWithdraw } from 'services/vaultCommonService';
import { assetAmountInWei, getAssetInfo } from '@defisaver/tokens';
import * as rflManageTypes from '../../actionTypes/reflexerActionTypes/reflexerManageActionTypes';
import * as reflexerRecipes from '../../recipes/reflexerRecipes';

import { callMultipleTxAction, sendTx } from '../txNotificationActions';
import { setAfterValue } from './reflexerManageAfterValues';
import { trackEvent } from '../../services/analyticsService';
import { getSafeInfoAction } from './reflexerActions';
import { calculateTradeSizeImpact } from '../../services/makerServices/makerManageServices/makerManageService';
import { flProtocolAndFeeFor } from '../../services/assetsService';
import { callRecipeViaProxy } from '../../services/contractCallService';
import { formName } from '../../components/DashboardActions/dashboardActionUtils';
import { confirmViaModal, sendConfirmViaModal } from '../modalActions';
import { getDashboardInputs, addApproveForActionIfNeeded } from '../dashboardActions';
import { getRaiIlkInfo } from '../../services/reflexerServices/reflexerService';
import { isLayer2Network } from '../../services/ethService';


const reflexerTrackEventWrapper = (getState, from, method, message = '') => {
  const { safes, selectedSafeId } = getState().reflexer;
  const safe = safes[selectedSafeId];
  const typeAndAssetLabel = `${safe.type} ${safe.collType}`;
  trackEvent(from, `${method} ${typeAndAssetLabel}`, message);
};


const confirmAutomationTrigger = () => async (dispatch, getState) => {
  const { ratioTooLow, ratioTooHigh } = getState().reflexerManage;
  // if (ratioTooLow || ratioTooHigh) {
  //   const confirmed = await dispatch(confirmViaModal(t('errors.automatic_trigger')));
  //   if (!confirmed) return false;
  // }
  return true;
};

const postAction = (contextAction, secondAction, firstAction) => async (dispatch, getState) => {
  await dispatch(setAfterValue(0, 'clear'));
  await dispatch(resetFields(formName, { [`${contextAction.value}-${firstAction?.value}`]: '', [`${contextAction.value}-${secondAction?.value}`]: '' }));
  firstAction.getMax();

  const { reflexer: { safes, selectedSafeId } } = getState();
  dispatch(getSafeInfoAction(safes[selectedSafeId]));
  dispatch({ type: rflManageTypes.RFL_ACTION_EXEC_SUCCESS, action: contextAction.value });
};

/**
 * Handles redux actions for the generate dai smart contract call
 *
 * @param value {string} action being executed
 * contextAction {Object} where the action is being called from
 * additionalAction {Object} contains value of action to be executed in recipe, eg. 'generate' but also can be 'collateral' if contextAction is 'generate'
 * @return {Function}
 */
export const basicReflexerAction = (value) => (contextAction, additionalAction) => async (dispatch, getState) => {
  try {
    const {
      general: { account, accountType },
      maker: { proxyAddress },
      reflexer: { safes, selectedSafeId },
    } = getState();

    const safe = safes[selectedSafeId];

    const {
      firstAction, firstInput, secondAction, secondInput,
    } = dispatch(getDashboardInputs(contextAction, additionalAction));

    const { ratioTooLow, ratioTooHigh } = getState().reflexerManage;
    if (ratioTooLow || ratioTooHigh) {
      const confirmed = await dispatch(confirmViaModal(t('errors.automatic_trigger')));
      if (!confirmed) return false;
    }

    if (firstAction.value === 'generate') await checkAvailableDebt(safe, firstInput);
    if (secondAction?.value === 'generate') await checkAvailableDebt(safe, secondInput);

    reflexerTrackEventWrapper(getState, 'reflexer', value);
    dispatch({ type: rflManageTypes.RFL_ACTION_EXEC_REQUEST, action: contextAction.value });


    let targetAccount = secondAction?.value === 'send' ? secondInput : account;
    if (!isAddress(targetAccount)) targetAccount = await getEnsAddress(targetAccount);
    const firstInputWei = assetAmountInWei(firstInput, firstAction.symbol);
    const secondInputWei = secondAction?.value === 'send' ? '' : assetAmountInWei(secondInput, secondAction?.symbol);
    const recipe = getReflexerRecipe(firstAction.value, firstInputWei, secondAction?.value, secondInputWei, safe, targetAccount, proxyAddress);
    const amount = formatNumber(parseFloat(firstInput), 2);
    const additionalAmount = formatNumber(parseFloat(secondInput), 2);
    let label = `${firstAction.label}: ${amount} ${firstAction.symbol}`;
    if (secondAction && secondAction.value !== 'send') label += ` + ${secondAction.label}: ${additionalAmount} ${secondAction.symbol}`;
    else if (secondAction) label += ` + ${secondAction.label} to ${formatAcc(targetAccount, 5, 3)}`;
    const proxySendHandler = (promise) => {
      const additionalInfo = {
        protocol: 'reflexer',
        firstToken: firstAction.symbol,
        firstAmount: firstInput,
      };
      return sendTx(promise, label, `Safe #${safe.id}`, dispatch, getState, additionalInfo);
    };

    const txToExecute = [];

    await dispatch(addApproveForActionIfNeeded(firstAction, firstInput, proxyAddress, txToExecute));
    if (secondAction) await dispatch(addApproveForActionIfNeeded(secondAction, secondInput, proxyAddress, txToExecute));
    txToExecute.push({
      func: () => callRecipeViaProxy(accountType, proxySendHandler, proxyAddress, account, recipe),
      type: firstAction.value,
      notifMessage: label,
    });
    await dispatch(callMultipleTxAction(txToExecute));

    dispatch(postAction(contextAction, secondAction, firstAction));
    reflexerTrackEventWrapper(getState, 'reflexer', `${value}Success`);
  } catch (err) {
    dispatch({ type: rflManageTypes.RFL_ACTION_EXEC_FAILURE, action: contextAction.value, payload: err.message });
    reflexerTrackEventWrapper(getState, 'reflexer', `${value}Error`, err.message);
    captureException(err);
  }
};

export const generateAction = basicReflexerAction('generate');
export const withdrawAction = basicReflexerAction('withdraw');
export const addCollateralAction = basicReflexerAction('collateral');
export const paybackAction = basicReflexerAction('payback');

export const getRepayModalData = inputAmount => async (dispatch, getState) => {
  dispatch({ type: rflManageTypes.GET_REPAY_MODAL_DATA_REQUEST });

  try {
    const {
      maker: { proxyAddress },
      reflexer: { safes, selectedSafeId },
    } = getState();
    const safe = safes[selectedSafeId];

    const { price: exchangeRate, source } = await getBestExchangePrice(inputAmount, safe.asset, safe.debtAsset, proxyAddress);

    const repayAmount = new Dec(inputAmount).times(exchangeRate).toString();

    const marketPrice = await getSlippageThreshold(safe.asset, safe.debtAsset);
    const tradeSizeImpact = calculateTradeSizeImpact(marketPrice, exchangeRate);

    const useFl = useFlForRepay(safe, inputAmount);
    const flData = await flProtocolAndFeeFor(inputAmount, safe.asset, getState().general.network);

    const payload = {
      repayAmount,
      tradeSizeImpact,
      useFl,
      repayExchangeRate: exchangeRate,
      exchangeSource: source,
      useAltRecipe: flData.useAltRecipe,
      flProtocol: flData.protocol,
      flFee: flData.flFee,
    };

    dispatch({ type: rflManageTypes.GET_REPAY_MODAL_DATA_SUCCESS, payload });
  } catch (err) {
    dispatch({ type: rflManageTypes.GET_REPAY_MODAL_DATA_FAILURE, payload: err.message });
    captureException(err);
  }
};

export const repayAction = (contextAction, additionalAction, closeModal) => async (dispatch, getState) => {
  dispatch({ type: rflManageTypes.RFL_ACTION_EXEC_REQUEST, action: contextAction.value });
  reflexerTrackEventWrapper(getState, 'reflexer', 'repay');

  try {
    const {
      general: { account, accountType },
      maker: { proxyAddress },
      reflexer: { safes, selectedSafeId },
      reflexerManage: {
        slippagePercent, repayExchangeRate, repayAmount, useFl, flProtocol,
      },
    } = getState();
    const safe = safes[selectedSafeId];

    const {
      firstAction, firstInput, secondAction, secondInput,
    } = dispatch(getDashboardInputs(contextAction, additionalAction));

    const amount = formatNumber(parseFloat(firstInput), 2);
    const additionalAmount = formatNumber(parseFloat(secondInput), 2);
    let label = `${firstAction.label}: ${amount} ${firstAction.symbol}`;
    if (secondAction) label += ` + ${secondAction.label}: ${additionalAmount} ${secondAction.symbol}`;

    const sendTxFunc = (promise) => {
      const additionalInfo = {
        protocol: 'reflexer',
        firstToken: safe.asset,
        firstAmount: firstInput,
        secondToken: 'RAI',
        secondAmount: repayAmount,
      };
      return sendTx(promise, label, `Safe #${safe.id}`, dispatch, getState, additionalInfo);
    };

    const additionalActions = secondAction
      ? getAction(secondAction.value, safe, assetAmountInWei(secondInput, secondAction.symbol), account, proxyAddress, getRaiIlkInfo(safe.collType).join, safe.asset)
      : [];

    const txToExecute = [];
    await dispatch(addApproveForActionIfNeeded(firstAction, firstInput, proxyAddress, txToExecute));
    if (secondAction) await dispatch(addApproveForActionIfNeeded(secondAction, secondInput, proxyAddress, txToExecute));

    const recipe = useFl
      ? await reflexerRecipes.repayWithCollLoan(firstInput, getMaxWithdraw(safe), repayExchangeRate, slippagePercent, safe, proxyAddress, account, flProtocol, additionalActions)
      : await reflexerRecipes.repay(firstInput, repayExchangeRate, slippagePercent, safe, proxyAddress, account, additionalActions);

    txToExecute.push({
      func: () => callRecipeViaProxy(accountType, sendTxFunc, proxyAddress, account, recipe, 0, recipe.extraGas),
      notifMessage: label,
    });
    await dispatch(callMultipleTxAction(txToExecute));

    dispatch(postAction(contextAction, secondAction, firstAction));
    closeModal();
    reflexerTrackEventWrapper(getState, 'reflexer', 'repaySuccess');
  } catch (err) {
    dispatch({ type: rflManageTypes.RFL_ACTION_EXEC_FAILURE, action: contextAction.value, payload: err.message });
    reflexerTrackEventWrapper(getState, 'reflexer', 'repayError', err.message);
    captureException(err);
  }
};

export const resetRepayModal = () => (dispatch) => { dispatch({ type: rflManageTypes.RFL_RESET_REPAY_MODAL }); };

export const getBoostModalData = inputAmount => async (dispatch, getState) => {
  dispatch({ type: rflManageTypes.RFL_GET_BOOST_MODAL_DATA_REQUEST });
  try {
    const {
      maker: { proxyAddress },
      reflexer: { safes, selectedSafeId },
    } = getState();
    const safe = safes[selectedSafeId];

    const { price: exchangeRate, source } = await getBestExchangePrice(inputAmount, safe.debtAsset, safe.asset, proxyAddress);

    const boostAmount = new Dec(exchangeRate).times(inputAmount).toString();

    const marketPrice = await getSlippageThreshold(safe.debtAsset, safe.asset);
    const tradeSizeImpact = calculateTradeSizeImpact(marketPrice, exchangeRate);

    const useFl = useFlForBoost(safe, inputAmount);
    const flData = useFl
      ? (await flProtocolAndFeeFor(inputAmount, 'RAI', getState().general.network))
      : {
        protocol: 'none', feeMultiplier: '1', flFee: '0', useAltRecipe: true,
      };

    dispatch({
      type: rflManageTypes.RFL_GET_BOOST_MODAL_DATA_SUCCESS,
      payload: {
        boostAmount,
        tradeSizeImpact,
        useFl,
        boostExchangeRate: exchangeRate,
        exchangeSource: source,
        useAltRecipe: flData.useAltRecipe,
        flProtocol: flData.protocol,
        flFee: flData.flFee,
      },
    });
  } catch (err) {
    dispatch({ type: rflManageTypes.RFL_GET_BOOST_MODAL_DATA_FAILURE, payload: err.message });
    captureException(err);
  }
};

export const boostAction = (contextAction, additionalAction, closeModal) => async (dispatch, getState) => {
  reflexerTrackEventWrapper(getState, 'reflexer', 'boost');
  dispatch({ type: rflManageTypes.RFL_ACTION_EXEC_REQUEST, action: contextAction.value });

  try {
    const {
      general: { account, accountType },
      maker: { proxyAddress },
      reflexer: { safes, selectedSafeId },
      reflexerManage: {
        slippagePercent, boostExchangeRate, boostAmount, useFl, flProtocol,
      },
    } = getState();
    const safe = safes[selectedSafeId];

    const {
      firstAction, firstInput, secondAction, secondInput,
    } = dispatch(getDashboardInputs(contextAction, additionalAction));

    await checkAvailableDebt(safe, firstInput);

    const amount = formatNumber(parseFloat(firstInput), 2);
    const additionalAmount = formatNumber(parseFloat(secondInput), 2);
    let label = `${firstAction.label}: ${amount} ${firstAction.symbol}`;
    if (secondAction) label += ` + ${secondAction.label}: ${additionalAmount} ${secondAction.symbol}`;

    const sendTxFunc = (promise) => {
      const additionalInfo = {
        protocol: 'reflexer',
        firstToken: 'RAI',
        firstAmount: firstInput,
        secondToken: safe.asset,
        secondAmount: boostAmount,
      };
      return sendTx(promise, label, `Safe #${safe.id}`, dispatch, getState, additionalInfo);
    };

    const additionalActions = secondAction
      ? getAction(secondAction.value, safe, assetAmountInWei(secondInput, secondAction.symbol), account, proxyAddress, getRaiIlkInfo(safe.collType).join, safe.asset)
      : [];

    const recipe = useFl
      ? await reflexerRecipes.boostWithDebtLoan(firstInput, boostExchangeRate, slippagePercent, safe, proxyAddress, flProtocol, additionalActions)
      : await reflexerRecipes.boost(firstInput, boostExchangeRate, slippagePercent, safe, proxyAddress, additionalActions);

    const txToExecute = [];
    await dispatch(addApproveForActionIfNeeded(firstAction, firstInput, proxyAddress, txToExecute));
    if (secondAction) await dispatch(addApproveForActionIfNeeded(secondAction, secondInput, proxyAddress, txToExecute));
    txToExecute.push({
      func: () => callRecipeViaProxy(accountType, sendTxFunc, proxyAddress, account, recipe, 0, recipe.extraGas),
      notifMessage: label,
    });
    await dispatch(callMultipleTxAction(txToExecute));

    dispatch(postAction(contextAction, secondAction, firstAction));
    closeModal();
    reflexerTrackEventWrapper(getState, 'reflexer', 'boostSuccess');
  } catch (err) {
    dispatch({ type: rflManageTypes.RFL_ACTION_EXEC_FAILURE, action: contextAction.value, payload: err.message });
    reflexerTrackEventWrapper(getState, 'reflexer', 'boostError', err.message);
    captureException(err);
  }
};

export const resetBoostModal = () => (dispatch) => { dispatch({ type: rflManageTypes.RFL_RESET_BOOST_MODAL }); };

export const changeDashboardAction = (payload, primary = true) => async (dispatch) => {
  await dispatch({
    type: primary ? rflManageTypes.RFL_SET_DASHBOARD_ACTION : rflManageTypes.RFL_SET_ADDITIONAL_DASHBOARD_ACTION,
    payload,
  });

  dispatch(setAfterValue(0, 'reset'));
};

export const openReflexerSendModal = (contextAction, additionalAction) => async (dispatch) => {
  if (additionalAction.value !== 'send') throw new Error('DEV: Send cannot be the primary action');

  const {
    firstAction, firstInput, secondInput: unresolvedReceiver,
  } = dispatch(getDashboardInputs(contextAction, additionalAction));

  const sendToAddress = await dispatch(sendConfirmViaModal({ sendingTo: unresolvedReceiver, token: getAssetInfo(firstAction.symbol), amount: firstInput }));
  if (!sendToAddress) throw new Error(t('errors.denied_transaction'));

  return dispatch(basicReflexerAction(contextAction.value)(contextAction, additionalAction));
};
