import dfs from '@defisaver/sdk';
import RecipeAction from '../RecipeAction';
import { Amount, AssetAmount } from '../../components/Recipes/RecipeCreator/inputTypes';
import { makerFlashBorrowAfterValues } from '../../actions/flashLoanAfterValues';
import { assetAmountInWeiIgnorePointer } from '../../services/recipeCreator/recipeActionUtils';
import { ZERO_ADDRESS } from '../../constants/general';
import FlashIcon from '../recipeIcons/Flash.svg';
import { formatNumber } from '../../services/utils';

export default class MakerFlashLoanAction extends RecipeAction {
  static prettyName = 'Maker Flash Loan';

  static protocol = 'maker';

  static protocolPrettyName = 'Maker';

  static description = 'Flash mints selected amount of DAI from Maker.';

  constructor(amount = '') {
    super();
    this.inputs = [
      new AssetAmount('Amount', amount, 'DAI'),
    ];
    this.output = new AssetAmount('output', 0, 'DAI');
  }

  async getAfterValues(_balances = {}, returnValues = [], actions = [], positions = {}, getState) {
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const { returnValue, balances } = await makerFlashBorrowAfterValues(
      { amount: args[0], asset: 'DAI' },
      { account: getState().general.account },
      _balances,
    );
    this.output.value = returnValue;
    this.output.asset = 'DAI';
    return { returnValue: this.output, balances, positions };
  }

  async toDfsAction(getState) {
    const amount = assetAmountInWeiIgnorePointer(this.inputs[0].value, 'DAI');
    return new dfs.actions.flashloan.MakerFlashLoanAction(amount, ZERO_ADDRESS, []);
  }

  static getIcon() {
    return FlashIcon;
  }

  _getPrettyName(actionCalls, actions) {
    const args = this.mapReturnValuesToArgs(actionCalls.map(a => a.returnValue), actions);
    return `Flash borrow ${formatNumber(args[0])} DAI from Maker`;
  }

  getAsset() {
    return 'DAI';
  }
}
