import React from 'react';
import PropTypes from 'prop-types';
import {
  defined, formatNumber, formatNumberWithInf, isMobileDevice,
} from 'services/utils';

import AfterVal from '../AfterVal/AfterVal';
import TooltipWrapper from '../TooltipWrapper/TooltipWrapper';
import RatioCircle from '../Decorative/RatioCircle/RatioCircle';
import Arrow from '../Decorative/Arrow';
import InfoIcon from '../Decorative/InfoIcon';

import './DataItem.scss';

const formatLabel = (label) => {
  if (typeof label !== 'string' || label.length === 0) return label;
  return `${label}${(label.substr(label.length - 1) === ':') ? '' : ':'}`;
};

const DataItem = ({
  label, value, symbol, loading, afterValueTooltip, valueColor, shouldFormatLabel,
  afterType, gettingAfterValue, afterValue, afterProp, symbolAfter, afterValueColor, showRatioCircle,
  highlighted, active, valueAddon, additionalValue, additionalValueLabel, additionalValueTooltip,
  additionalValueIcon, smallSymbol, tooltip, description, decimals, type, ratioCircleMin, ratioCircle,
  showInfinity, alwaysShowAfter, textValue, additionalValueSymbol, additionalValueSymbolAfter, additionalClasses, stopPropagation,
  removeSpacer, ...props
}) => {
  const hasAfterVal = afterValue && typeof afterValue[afterProp] !== 'undefined';
  const _additionalValueSymbol = additionalValueSymbol === null ? symbol : additionalValueSymbol;
  return (
    <>
      <div className={`data-item-wrapper ${type} ${highlighted ? 'highlighted' : ''} ${active ? 'active' : ''} ${additionalClasses}`} {...props}>
        <div className="label-value-wrapper">
          <div className="label-wrapper" onClick={(e) => { if (stopPropagation && isMobileDevice()) { e.preventDefault(); e.stopPropagation(); } }}>
            { description && (<TooltipWrapper title={description}><InfoIcon /></TooltipWrapper>) }
            { showRatioCircle && <RatioCircle ratio={ratioCircle || afterValue?.[afterProp] || value} min={ratioCircleMin} /> }
            <span className="label">
              {shouldFormatLabel ? formatLabel(label) : label}
            </span>
          </div>

          <div
            className="value-wrapper"
            style={{ color: valueColor }}
            onClick={stopPropagation ? (e) => { e.preventDefault(); e.stopPropagation(); } : undefined}
          >
            { loading && <div className="loading-placeholder" /> }
            {
              (!loading && defined(value)) && (
                <TooltipWrapper copy title={tooltip || value} useText={!!tooltip}>
                  {!textValue && parseFloat(value) < 0 && '-'}
                  {symbol && !symbolAfter && <span className={`symbol ${smallSymbol && 'small'}`}>{symbol}</span>}
                  {textValue ? value : (showInfinity ? formatNumberWithInf : formatNumber)(Math.abs(value), decimals)}
                  {symbol && symbolAfter && <span className={`symbol ${smallSymbol && 'small'}`}>{symbol}</span>}
                </TooltipWrapper>
              )
            }

            { valueAddon && !loading && (valueAddon) }
          </div>
        </div>

        {
          !!additionalValue &&
          !hasAfterVal && (
            <div className="after-value">
              <div className="amount-wrapper">
                {additionalValueLabel && (
                  <span className="after">{formatLabel(additionalValueLabel)}</span>
                )}
                <span className="amount">
                  <TooltipWrapper title={additionalValueTooltip || additionalValue} copy={!additionalValueTooltip}>
                    { _additionalValueSymbol && !additionalValueSymbolAfter && _additionalValueSymbol}
                    {formatNumber(additionalValue, decimals)}
                    { _additionalValueSymbol && additionalValueSymbolAfter && _additionalValueSymbol}
                    {additionalValueIcon && ' '}{additionalValueIcon}
                  </TooltipWrapper>
                </span>
              </div>
            </div>
          )
        }

        {
          (alwaysShowAfter || hasAfterVal) && (
            <>
              { type === 'arrow' && <Arrow to="right" color="white" /> }

              <AfterVal
                symbol={symbol}
                type={afterType}
                loading={gettingAfterValue}
                cdp={afterValue}
                cdpProp={afterProp}
                symbolAfter={symbolAfter}
                tooltip={afterValueTooltip}
                decimals={decimals}
                color={afterValueColor}
              />
            </>
          )
        }
      </div>
      { !removeSpacer && (type === 'standard' || type === 'standard-smaller') && <div className="spacer" />}
    </>
  );
};

DataItem.defaultProps = {
  value: 0,
  loading: false,
  decimals: 2,
  type: 'standard',
  afterValueTooltip: '',
  afterProp: '',
  afterValue: null,
  gettingAfterValue: false,
  afterType: '',
  symbolAfter: true,
  valueColor: '',
  afterValueColor: '',
  showRatioCircle: false,
  ratioCircle: 0,
  ratioCircleMin: '0',
  description: '',
  highlighted: false,
  active: false,
  valueAddon: '',
  additionalValue: '',
  additionalValueTooltip: '',
  additionalValueLabel: '',
  additionalValueIcon: '',
  smallSymbol: false,
  tooltip: '',
  showInfinity: false,
  alwaysShowAfter: false,
  label: '',
  symbol: '',
  textValue: false,
  additionalValueSymbol: null,
  additionalValueSymbolAfter: true,
  additionalClasses: '',
  shouldFormatLabel: true,
  stopPropagation: false,
  removeSpacer: false,
};

DataItem.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  symbol: PropTypes.string,
  decimals: PropTypes.number,
  loading: PropTypes.bool,
  description: PropTypes.string,
  additionalClasses: PropTypes.string,
  type: PropTypes.oneOf(['standard', 'standard-smaller', 'small', 'arrow']),

  afterType: PropTypes.string,
  gettingAfterValue: PropTypes.bool,
  afterValue: PropTypes.object,
  afterProp: PropTypes.string,
  afterValueTooltip: PropTypes.string,
  symbolAfter: PropTypes.bool,
  valueColor: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  afterValueColor: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),

  additionalValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  additionalValueTooltip: PropTypes.string,
  additionalValueLabel: PropTypes.string,
  additionalValueIcon: PropTypes.node,
  additionalValueSymbol: PropTypes.string,
  additionalValueSymbolAfter: PropTypes.bool,

  showRatioCircle: PropTypes.bool,
  ratioCircle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ratioCircleMin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  highlighted: PropTypes.bool,
  active: PropTypes.bool,
  valueAddon: PropTypes.node,
  smallSymbol: PropTypes.bool,
  tooltip: PropTypes.string,
  showInfinity: PropTypes.bool,
  alwaysShowAfter: PropTypes.bool,
  textValue: PropTypes.bool,
  shouldFormatLabel: PropTypes.bool,
  stopPropagation: PropTypes.bool,
  removeSpacer: PropTypes.bool,
};

export default DataItem;
