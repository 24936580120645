import t from 'translate';
import { captureException } from 'sentry';
import { getAssetInfo, getAssetInfoByAddress } from '@defisaver/tokens';
import capitalize from 'lodash/capitalize';
import Dec from 'decimal.js';
import { momoize } from 'services/memoization';
import {
  APPROVE_ADDRESS_ON_ASSET_FAILURE,
  APPROVE_ADDRESS_ON_ASSET_REQUEST,
  APPROVE_ADDRESS_ON_ASSET_SUCCESS,
  APPROVE_TYPE_AAVE,
  APPROVE_TYPE_COMPOUND,
  APPROVE_TYPE_CONTRACT,
  APPROVE_TYPE_DS_PROXY,
  APPROVE_TYPE_EXCHANGE,
  APPROVE_TYPE_MIGRATION,
  CLEAR_ASSET_APPROVE_ERROR,
  EXPLOITABLE_APPROVALS_FAILURE,
  EXPLOITABLE_APPROVALS_REQUEST,
  EXPLOITABLE_APPROVALS_SUCCESS,
  GET_ASSET_BALANCE_FAILURE,
  GET_ASSET_BALANCE_REQUEST,
  GET_ASSET_BALANCE_SUCCESS,
  GET_ASSET_PRICE_FAILURE,
  GET_ASSET_PRICE_REQUEST,
  GET_ASSET_PRICE_SUCCESS,
  GET_ASSET_PROXY_BALANCE_FAILURE,
  GET_ASSET_PROXY_BALANCE_REQUEST,
  GET_ASSET_PROXY_BALANCE_SUCCESS,
  GET_ASSETS_BALANCE_FAILURE,
  GET_ASSETS_BALANCE_REQUEST,
  GET_ASSETS_BALANCE_SUCCESS,
  GET_ASSETS_PRICE_FAILURE,
  GET_ASSETS_PRICE_REQUEST,
  GET_ASSETS_PRICE_SUCCESS,
  GET_FUTURE_PRICES_FAILURE,
  GET_FUTURE_PRICES_REQUEST,
  GET_FUTURE_PRICES_SUCCESS,
  GET_IS_ADDRESS_APPROVED_ON_ASSET_FAILURE,
  GET_IS_ADDRESS_APPROVED_ON_ASSET_REQUEST,
  GET_IS_ADDRESS_APPROVED_ON_ASSET_SUCCESS,
  UPDATE_ASSET_BALANCE_TIMEOUT_ID,
  UPDATE_ASSET_PRICE_TIMEOUT_ID,
} from '../actionTypes/assetsActionTypes';
import { sendTx } from './txNotificationActions';
import {
  approveAddressOnAsset,
  checkExploitableApprovals,
  deapproveAddressOnAsset,
  getApprovingReducerPropName,
  getAssetBalance,
  getAssetBalancesFromAddresses,
  getAssetsBalances,
  getAssetsUsdPrices,
  getAssetUsdPrice,
  getGettingIsApprovedReducerPropName,
  getIsApprovedReducerPropName,
  isAddressApprovedOnAsset,
  withdrawAllFromSafeguard,
} from '../services/assetsService';
import { compareAddresses, isAddress, requireAddress } from '../services/utils';
import { getTokensApiCall } from '../services/apiService';
import { handleWbtcLegacy } from '../services/compoundServices/compoundManageService';
import { aaveV2Tokens } from '../constants/aaveMarkets';
import { PORTFOLIO_BALANCE_ASSETS } from '../constants/assets';
import { getERC20TokenData } from '../services/erc20Service';
import { approveUniNftAction, approveUniNftIfNeeded } from './uniswapV3Actions';
import {
  NEW_ATOKEN_APPROVED_REQUEST,
  NEW_ATOKEN_APPROVED_SUCCESS,
} from '../actionTypes/aaveActionTypes/aaveManageActionTypes';

export const approveTypeToLabelMap = {
  [APPROVE_TYPE_DS_PROXY]: t('common.ds_proxy'),
  [APPROVE_TYPE_EXCHANGE]: t('common.exchange'),
  [APPROVE_TYPE_MIGRATION]: t('common.migration'),
  [APPROVE_TYPE_COMPOUND]: t('pages.compound'),
  [APPROVE_TYPE_CONTRACT]: t('common.contract'),
  [APPROVE_TYPE_AAVE]: t('pages.aave'),
};

/**
 * Handles redux action for checking if the address is approved on the asset
 *
 * @param asset {String}
 * @param spender {String}
 * @param amount {String}
 * @param approveType {String}
 * @param assetAddress {String}
 * @return {Function}
 */
export const isAddressApprovedOnAssetAction = (asset, spender, amount, approveType, assetAddress) => async (dispatch, getState) => {
  requireAddress(spender);
  const gettingPropName = getGettingIsApprovedReducerPropName(approveType);
  const isApprovedPropName = getIsApprovedReducerPropName(approveType);

  dispatch({ type: GET_IS_ADDRESS_APPROVED_ON_ASSET_REQUEST, asset, gettingPropName }); // eslint-disable-line object-curly-newline

  try {
    const payload = await isAddressApprovedOnAsset(asset, getState().general.account, spender, amount, assetAddress);

    dispatch({ type: GET_IS_ADDRESS_APPROVED_ON_ASSET_SUCCESS, asset, gettingPropName, isApprovedPropName, payload }); // eslint-disable-line object-curly-newline
  } catch (err) {
    dispatch({ type: GET_IS_ADDRESS_APPROVED_ON_ASSET_FAILURE, asset, gettingPropName, payload: err.message }); // eslint-disable-line object-curly-newline
    captureException(err);
  }
};

/**
 * Handles redux action for when an address is being approved on an asset
 *
 * @param asset {String}
 * @param spender {String}
 * @param approveType {String}
 * @param [assetAddress] {String}
 * @return {Function}
 */
export const approveAddressOnAssetAction = (asset, spender, approveType, assetAddress) => async (dispatch, getState) => {
  requireAddress(spender);
  const propName = getApprovingReducerPropName(approveType);
  const isApprovedPropName = getIsApprovedReducerPropName(approveType);
  const approveTypeLabel = approveTypeToLabelMap[approveType];
  const { account, accountType, path } = getState().general;

  dispatch({ type: APPROVE_ADDRESS_ON_ASSET_REQUEST, asset, propName });

  const notifMessage = `${t('common.approve')} ${approveTypeLabel} ${t('common.for')} ${asset}`;
  const sendTxFunc = (promise, waitForSign) => sendTx(promise, notifMessage, '', dispatch, getState, {}, waitForSign);

  try {
    const receipt = await approveAddressOnAsset(asset, accountType, path, account, spender, sendTxFunc, assetAddress);

    dispatch({ type: APPROVE_ADDRESS_ON_ASSET_SUCCESS, asset, propName, isApprovedPropName }); // eslint-disable-line object-curly-newline
    return receipt;
  } catch (err) {
    dispatch({ type: APPROVE_ADDRESS_ON_ASSET_FAILURE, asset, propName, payload: err.message }); // eslint-disable-line object-curly-newline
    captureException(err);

    // do not remove - this is here to stop any methods from executing after this one
    throw new Error(err);
  }
};

/**
 * Clears approving error for asset
 *
 * @param asset {String}
 * @param approveType {String}
 * @return {Function}
 */
export const clearAssetApproveError = (asset, approveType) => (dispatch) => {
  const propName = getApprovingReducerPropName(approveType);
  dispatch({ type: CLEAR_ASSET_APPROVE_ERROR, asset, propName });
};

/**
 * Checks if an address is approved and if not sends tx to approve it
 *
 * @param asset {String}
 * @param spender {String}
 * @param amount {String}
 * @param approveType {String}
 * @return {Function}
 */
export const approveAddressOnAssetIfNotApproved = (asset, spender, amount, approveType) => async (dispatch, getState) => {
  if (asset === 'ETH') return;

  requireAddress(spender);

  await dispatch(isAddressApprovedOnAssetAction(asset, spender, amount, approveType));
  const approved = getState().assets[handleWbtcLegacy(asset)][getIsApprovedReducerPropName(approveType)];

  if (!approved) return dispatch(approveAddressOnAssetAction(asset, spender, approveType));
};

/**
 * Sets the price timeout cycle timeoutId for an asset
 *
 * @param asset {String}
 * @param payload {Number}
 * @return {function(*): *}
 */
export const setAssetPriceTimeoutId = (asset, payload) => dispatch => dispatch({ type: UPDATE_ASSET_PRICE_TIMEOUT_ID, asset, payload });

/**
 * Clears an assets timeout cycle timeoutId and sets it to -1
 * to signal that it is not running
 *
 * @param asset {String}
 * @param payload
 * @return {Function}
 */
export const clearAssetPriceTimeoutId = (asset, payload) => (dispatch) => {
  clearTimeout(payload);
  dispatch(setAssetPriceTimeoutId(asset, -1));
};

/**
 * Handles redux states when an assets price is being fetched
 *
 * @param asset {String} symbol
 * @param fetchFrom {String}
 * @param assetAddress {String}
 * @return {Function}
 */
export const getAssetPriceAction = (asset, fetchFrom = 'market', assetAddress) => async (dispatch) => {
  dispatch({ type: GET_ASSET_PRICE_REQUEST, asset });

  try {
    const payload = await getAssetUsdPrice(asset, fetchFrom, assetAddress);

    dispatch({
      type: GET_ASSET_PRICE_SUCCESS,
      asset,
      payload,
      priceType: fetchFrom,
    });

    return payload;
  } catch (err) {
    dispatch({ type: GET_ASSET_PRICE_FAILURE, asset, payload: err.message });
    captureException(err);
  }
};
/**
 * Handles redux states when an assets price is being fetched
 *
 * @param assets {Array} array of asset symbols
 * @param fetchFrom {String}
 * @param selectedMarket {object} selected aave market
 * @return {Function}
 */
export const getAssetsPricesAction = (assets, fetchFrom = 'market', selectedMarket) => async (dispatch) => {
  try {
    // set network as 1 for the addresses on mainnet (price is the same on every network)
    const addresses = assets.map(asset => getAssetInfo(asset, 1).address);
    dispatch({
      type: GET_ASSETS_PRICE_REQUEST,
      payload: {
        assets,
        addresses,
      },
    });
    const prices = await getAssetsUsdPrices(assets, fetchFrom, selectedMarket);
    dispatch({
      type: GET_ASSETS_PRICE_SUCCESS,
      payload: {
        prices,
        assets,
        addresses,
        priceType: fetchFrom,
      },
    });
    const aaveIndex = assets.indexOf('AAVE');
    if (aaveIndex >= 0) {
      dispatch({
        type: GET_ASSET_PRICE_SUCCESS,
        asset: 'stkAAVE',
        payload: prices[aaveIndex],
        priceType: fetchFrom,
      });
    }

    return prices;
  } catch (err) {
    dispatch({
      type: GET_ASSETS_PRICE_FAILURE,
      payload: {
        assets,
        error: err.message,
      },
    });
    captureException(err);
  }
};

export const getAllAssetsPricesAction = () => async (dispatch, getState) => {
  const fetchFrom = 'market';
  const { assets } = getState();

  const filteredAssets = Object.fromEntries(Object.entries(assets)
    .filter(asset => !getAssetInfo(asset[0]).aaveCollateral
      && !getAssetInfo(asset[0]).compoundCollateral
      && !getAssetInfo(asset[0]).symbol.startsWith('crv')
      && !getAssetInfo(asset[0]).symbol.endsWith('CRV'),
    ));

  const keys = Object.keys(filteredAssets);
  const values = Object.values(filteredAssets);
  const getPriceFor = values.map((item, i) => {
    if (keys[i] === 'ETH') return getAssetInfo('WETH').address;
    if (keys[i] === 'REP') return getAssetInfo('REPv2').address;
    return item.address;
  });
  dispatch({
    type: GET_ASSETS_PRICE_REQUEST,
    payload: {
      assets: keys,
      addresses: getPriceFor,
    },
  });

  try {
    const prices = await getAssetsUsdPrices(getPriceFor, fetchFrom, 'v1', true);
    dispatch({
      type: GET_ASSETS_PRICE_SUCCESS,
      payload: {
        prices,
        assets: keys,
        addresses: getPriceFor,
        priceType: fetchFrom,
      },
    });
    const aaveIndex = keys.indexOf('AAVE');
    if (aaveIndex >= 0) {
      dispatch({
        type: GET_ASSET_PRICE_SUCCESS,
        asset: 'stkAAVE',
        payload: prices[aaveIndex],
        priceType: fetchFrom,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_ASSETS_PRICE_FAILURE,
      payload: {
        assets: keys,
        error: err.message,
      },
    });
    captureException(err);
  }
};


/**
 * Starts a timeout cycle that updates the assets price
 *
 * @param asset {String}
 * @return {Function}
 */
export const updateAssetPriceInterval = asset => dispatch => window.requestAnimationFrame(() => {
  dispatch(getAssetPriceAction(asset));
  const timeoutId = setTimeout(() => { dispatch(updateAssetPriceInterval(asset)); }, 60000); // change this to 60000
  dispatch(setAssetPriceTimeoutId(asset, timeoutId));
});

/**
 * Sets the balance timeout cycle timeoutId for an asset
 *
 * @param asset {String}
 * @param payload {Number}
 * @return {function(*, *): *}
 */
const setAssetsBalanceTimeoutId = (asset, payload) => dispatch => dispatch({ type: UPDATE_ASSET_BALANCE_TIMEOUT_ID, asset, payload });

/**
 * Clears an assets timeout cycle timeoutId and sets it to -1
 * to signal that it is not running
 *
 * @param asset {String}
 * @param payload
 * @return {Function}
 */
export const clearAssetBalanceTimeoutId = (asset, payload) => (dispatch) => {
  clearTimeout(payload);
  dispatch(setAssetsBalanceTimeoutId(asset, -1));
};

/**
 * Handles redux states when an assets balance is being fetched
 *
 * @param asset {String}
 * @return {Function}
 */
export const getAssetBalanceAction = (asset) => async (dispatch, getState) => {
  const isAssetAddress = isAddress(asset);
  const assetInfo = isAssetAddress ? await getERC20TokenData(asset) : getAssetInfo(asset);

  dispatch({ type: GET_ASSET_BALANCE_REQUEST, asset: assetInfo.symbol, address: assetInfo.address });

  try {
    const payload = await getAssetBalance(assetInfo.address, getState().general.account);

    dispatch({
      type: GET_ASSET_BALANCE_SUCCESS, payload, asset: assetInfo.symbol, address: assetInfo.address,
    });

    return payload;
  } catch (err) {
    dispatch({
      type: GET_ASSET_BALANCE_FAILURE, asset: assetInfo.symbol, address: assetInfo.address, payload: err.message,
    });
    captureException(err);
  }
};

export const getAssetProxyBalanceAction = (asset) => async (dispatch, getState) => {
  dispatch({ type: GET_ASSET_PROXY_BALANCE_REQUEST, asset, address: getAssetInfo(asset).address });

  try {
    const payload = await getAssetBalance(asset, getState().maker.proxyAddress);

    dispatch({
      type: GET_ASSET_PROXY_BALANCE_SUCCESS, asset, payload, address: getAssetInfo(asset).address,
    });

    return payload;
  } catch (err) {
    dispatch({
      type: GET_ASSET_PROXY_BALANCE_FAILURE, asset, address: getAssetInfo(asset).address, payload: err.message,
    });
    captureException(err);
  }
};

export const getAssetsBalancesAction = (assets, address = '') => async (dispatch, getState) => {
  try {
    const addresses = assets.map(asset => getAssetInfo(asset).address);
    dispatch({
      type: GET_ASSETS_BALANCE_REQUEST,
      payload: {
        assets,
        addresses,
      },
    });

    const balances = await getAssetsBalances(assets, address || getState().general.account);

    dispatch({
      type: GET_ASSETS_BALANCE_SUCCESS,
      payload: {
        balances,
        assets,
        addresses,
      },
    });
    return balances;
  } catch (err) {
    dispatch({
      type: GET_ASSETS_BALANCE_FAILURE,
      payload: {
        assets,
        error: err.message,
      },
    });
    captureException(err);
  }
};

export const getAssetsBalancesFromApiAction = () => async (dispatch, getState) => {
  try {
    const { account } = getState().general;

    const hardcodedAssets = PORTFOLIO_BALANCE_ASSETS.map(a => {
      const { address, decimals, symbol } = getAssetInfo(a);
      return { address, decimals, symbol };
    });

    const { payload } = await getTokensApiCall(account);
    const aV2Tokens = aaveV2Tokens.map(i => i.toLowerCase());
    let assets = [
      ...payload?.filter(i => {
        const asset = getAssetInfoByAddress(i.address);
        return !i.nft && asset.underlyingAsset === '' && aV2Tokens.indexOf(i.address) === -1;
      }),
      ...hardcodedAssets,
    ];
    assets = assets.filter(({ address }, index, self) => (
      index === self.findIndex((a) => (a.address.toLowerCase() === address.toLowerCase()))
    ));

    dispatch({
      type: GET_ASSETS_BALANCE_REQUEST,
      payload: {
        assets: assets.map(({ symbol }) => symbol),
        addresses: assets.map(({ address }) => address),
      },
    });

    const balances = await getAssetBalancesFromAddresses(account, assets);

    dispatch({
      type: GET_ASSETS_BALANCE_SUCCESS,
      payload: {
        balances,
        assets: assets.map(({ symbol }) => symbol),
        addresses: assets.map(({ address }) => address),
      },
    });
  } catch (error) {
    console.error(error);
    await dispatch(getAssetsBalancesAction(PORTFOLIO_BALANCE_ASSETS));
    captureException(error);
  }
};

/**
 * Starts a timeout cycle that updates the assets balance
 *
 * @param asset {String}
 * @return {Function}
 */
export const updateAssetBalanceInterval = asset => dispatch => window.requestAnimationFrame(() => {
  dispatch(getAssetBalanceAction(asset));
  const timeoutId = setTimeout(() => { dispatch(updateAssetBalanceInterval(asset)); }, 10000);
  dispatch(setAssetsBalanceTimeoutId(asset, timeoutId));
});

export const updateFuturePrices = () => async (dispatch) => {
  dispatch({ type: GET_FUTURE_PRICES_REQUEST });

  try {
    const res = await fetch('https://defiexplore.com/api/next-price');
    const regex = new RegExp(/(\w{3,14})FuturePrice/);
    const prices = await res.json();
    Object.keys(prices).forEach((key) => {
      if (regex.test(key)) {
        const asset = key.match(regex)[1].toUpperCase();
        dispatch({
          type: GET_FUTURE_PRICES_SUCCESS,
          asset,
          payload: {
            futurePrice: prices[`${asset.toLowerCase()}FuturePrice`],
            futurePriceUpdateTimestamp: prices[`${asset.toLowerCase()}UpdateTimestamp`],
          },
        });
      }
    });
  } catch (err) {
    dispatch({ type: GET_FUTURE_PRICES_FAILURE, payload: err.message });
    captureException(err);
  }
};

export const checkExploitableApprovalsAction = () => async (dispatch, getState) => {
  if (getState().general.network !== 1) return;
  dispatch({ type: EXPLOITABLE_APPROVALS_REQUEST });
  try {
    if (!getState().general.account) return;
    const approvals = await checkExploitableApprovals(getState().general.account);
    dispatch({ type: EXPLOITABLE_APPROVALS_SUCCESS, payload: approvals });
  } catch (err) {
    dispatch({ type: EXPLOITABLE_APPROVALS_FAILURE, payload: err.message.toString() });
    captureException(err);
  }
};

export const withdrawAllFromSafeguardAction = () => async (dispatch, getState) => {
  const {
    account, accountType, path, exploitableApprovals,
  } = getState().general;
  const hasExploitableApproval =
    Object.values(exploitableApprovals).flat(1).some(a => a.some(b => b));

  if (hasExploitableApproval) throw new Error('Exploitable contract still approved');
  const sendTxFunc = (promise, waitForSign) => sendTx(promise, t('misc.withdrawing_from_safeguard'), '', dispatch, getState, {}, waitForSign);
  await withdrawAllFromSafeguard(
    account,
    accountType,
    path,
    sendTxFunc,
  );
};

export const deapproveAddressOnAssetAction = (asset, spender, approveType) => async (dispatch, getState) => {
  const approveTypeLabel = approveTypeToLabelMap[approveType];
  const { account, accountType, path } = getState().general;

  const notifMessage = `${t('common.removing_approval_on')} ${approveTypeLabel} ${t('common.for')} ${asset}`;
  const sendTxFunc = (promise, waitForSign) => sendTx(promise, notifMessage, '', dispatch, getState, {}, waitForSign);

  await deapproveAddressOnAsset(asset, accountType, path, account, spender, sendTxFunc);
  dispatch(checkExploitableApprovalsAction());
};

export const addApproveTxIfNeeded = (asset, spender, amount, approveType, txsToExecute, txObject, assetAddress) => async (dispatch, getState) => {
  if (asset === 'ETH') return;

  requireAddress(spender);

  await dispatch(isAddressApprovedOnAssetAction(asset, spender, amount, approveType, assetAddress));
  const approved = getState().assets[asset][getIsApprovedReducerPropName(approveType)];
  if (!approved) {
    txsToExecute.push(txObject);
    return true;
  }
  return false;
};

export const getAssetBalanceActionCached = momoize(getAssetBalanceAction, { maxAge: 2 * 60 * 1000, promise: true, resetOnAccount: true });
export const getAssetProxyBalanceActionCached = momoize(getAssetProxyBalanceAction, { maxAge: 2 * 60 * 1000, promise: true, resetOnAccount: true });

export const checkBalanceOnRecipeChange = (_asset) => (dispatch, getState) => {
  const asset = _asset.replace(/^ETH/, 'WETH');
  if (!+getState().assets[asset]?.balance) {
    dispatch(getAssetBalanceActionCached(asset));
  }
};
export const approveNewAaveTokenAction = (approveAAsset, accountType, path, account, aTokenAddress, approved) => async (dispatch, getState) => {
  if (!approved) {
    const notifMessage = `${t('common.approve')} ${t('common.ds_proxy')} ${t('common.for')} ${approveAAsset}`;
    const sendTxFunc = (promise, waitForSign) => sendTx(promise, notifMessage, '', dispatch, getState, waitForSign);
    dispatch({ type: NEW_ATOKEN_APPROVED_REQUEST });
    await approveAddressOnAsset(approveAAsset, accountType, path, account, getState().maker.proxyAddress, sendTxFunc, aTokenAddress);
  }
  dispatch({ type: NEW_ATOKEN_APPROVED_SUCCESS });
};
export const approveNewAaveTokenActionIfNeeded = (account, accountType, path, asset, selectedMarket, collUsedAssetData, approveAAsset, aTokenAddress, txsToExecute, txObject) => async (dispatch, getState) => {
  const approved = await isAddressApprovedOnAsset(approveAAsset, account, getState().maker.proxyAddress, Dec(collUsedAssetData.supplied)
    .toString(), aTokenAddress);
  if (!approved) {
    // eslint-disable-next-line no-param-reassign
    txObject.func = () => dispatch(approveNewAaveTokenAction(approveAAsset, accountType, path, account, aTokenAddress, approved));
    txsToExecute.push(txObject);
    return true;
  }
  dispatch({ type: NEW_ATOKEN_APPROVED_SUCCESS });
  return false;
};
export const approveAssets = (approvals) => async (dispatch, getState) => {
  const {
    general: { account, accountType },
    maker: { proxyAddress },
  } = getState();
  const txToExecute = [];
  for (const approval of approvals) {
    if (compareAddresses(approval.owner, account)) {
      console.log(approval.owner, account);
      if (Object.keys(approval).includes('nft')) {
        const notifMessage = `${t('common.approve')} ${approveTypeToLabelMap[APPROVE_TYPE_DS_PROXY]} ${t('common.for')} NFT${approval.specialApproveLabel === 'uniswap v3' ? ` #${approval.tokenId}` : ''}`;
        const approveFunciontObject = {
          type: 'approve',
          notifMessage,
          func: () => dispatch(approveUniNftAction(proxyAddress, approval.tokenId, notifMessage)),
        };
        // eslint-disable-next-line no-await-in-loop
        await dispatch(approveUniNftIfNeeded(proxyAddress, approval.tokenId, approveFunciontObject, txToExecute));
        continue;
      }
      const asset = getAssetInfoByAddress(approval.asset);
      console.log(asset, approval);
      const approveFunctionObject = {
        type: 'approve',
        notifMessage: `${t('common.approve')} ${approveTypeToLabelMap[APPROVE_TYPE_DS_PROXY]} ${t('common.for')} ${asset.symbol}`,
        func: () => dispatch(approveAddressOnAssetAction(asset.symbol, proxyAddress, APPROVE_TYPE_DS_PROXY)),
      };
      if (asset.symbol === '?') {
        // eslint-disable-next-line no-await-in-loop
        const erc20Data = await getERC20TokenData(approval.asset);
        // currently only LP and yearn tokens are not in defisaver-tokens
        asset.symbol = capitalize(approval.specialApproveLabel) || erc20Data.symbol;
        asset.address = approval.asset;
        asset.decimals = 18;
        approveFunctionObject.notifMessage = `${t('common.approve')} ${approveTypeToLabelMap[APPROVE_TYPE_DS_PROXY]} ${t('common.for')} ${asset.symbol}`;
        // eslint-disable-next-line no-await-in-loop
        await dispatch(approveNewAaveTokenActionIfNeeded(account, accountType, '', asset.symbol, {}, { supplied: 1 }, asset.symbol, approval.asset, txToExecute, approveFunctionObject));
        continue;
      }
      console.log(asset);
      // eslint-disable-next-line no-await-in-loop
      await dispatch(addApproveTxIfNeeded(asset.symbol, proxyAddress, '1', APPROVE_TYPE_DS_PROXY, txToExecute, approveFunctionObject));
    }
  }
  return txToExecute;
};
