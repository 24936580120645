export const SAVER_IS_SUBSCRIBED_REQUEST = 'SAVER_IS_SUBSCRIBED_REQUEST';
export const SAVER_IS_SUBSCRIBED_SUCCESS = 'SAVER_IS_SUBSCRIBED_SUCCESS';
export const SAVER_IS_SUBSCRIBED_FAILURE = 'SAVER_IS_SUBSCRIBED_FAILURE';

export const SAVER_SUBSCRIBE_REQUEST = 'SAVER_SUBSCRIBE_REQUEST';
export const SAVER_SUBSCRIBE_SUCCESS = 'SAVER_SUBSCRIBE_SUCCESS';
export const SAVER_SUBSCRIBE_FAILURE = 'SAVER_SUBSCRIBE_FAILURE';
export const SAVER_SUBSCRIBE_RESET = 'SAVER_SUBSCRIBE_RESET';

export const SAVER_UNSUBSCRIBE_REQUEST = 'SAVER_UNSUBSCRIBE_REQUEST';
export const SAVER_UNSUBSCRIBE_SUCCESS = 'SAVER_UNSUBSCRIBE_SUCCESS';
export const SAVER_UNSUBSCRIBE_FAILURE = 'SAVER_UNSUBSCRIBE_FAILURE';

export const SET_SAVER_UPDATING = 'SET_SAVER_UPDATING';

export const GET_SAVER_SUBSCRIBE_HISTORY_REQUEST = 'GET_SAVER_SUBSCRIBE_HISTORY_REQUEST';
export const GET_SAVER_SUBSCRIBE_HISTORY_SUCCESS = 'GET_SAVER_SUBSCRIBE_HISTORY_SUCCESS';
export const GET_SAVER_SUBSCRIBE_HISTORY_LOADMORE_SUCCESS = 'GET_SAVER_SUBSCRIBE_HISTORY_LOADMORE_SUCCESS';
export const GET_SAVER_SUBSCRIBE_HISTORY_FAILURE = 'GET_SAVER_SUBSCRIBE_HISTORY_FAILURE';

export const AUTOMATION_PERMISSION_FIX_SUCCESS = 'AUTOMATION_PERMISSION_FIX_SUCCESS';
