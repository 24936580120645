import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { toChecksumAddress } from 'ethereumjs-util';
import { ACCOUNT_TYPES } from '../../../constants/general';
import { shortAddress } from '../../../services/utils';
import TrackAddressIcon from '../../Decorative/ConnectionWalletIcons/TrackAddressIcon';
import TooltipWrapper from '../../TooltipWrapper/TooltipWrapper';
import {
  removeAccountFromRecents,
  setAccountAsNotOwned,
  setAccountAsOwned,
  switchToAccount,
} from '../../../actions/accountActions';
import WalletIcon from '../ConnectWalletDropdown/WalletIcon';
import { openLoginModal } from '../../../actions/modalActions';

import './ManageWallets.scss';

const StarIcon = ({ fill }) => (
  <svg className="star-icon" width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.549 0.690983C8.84835 -0.230328 10.1518 -0.230328 10.4511 0.690983L11.8575 5.01925C11.9913 5.43128 12.3753 5.71024 12.8085 5.71024H17.3595C18.3282 5.71024 18.731 6.94985 17.9473 7.51925L14.2655 10.1943C13.915 10.4489 13.7683 10.9003 13.9022 11.3123L15.3085 15.6406C15.6079 16.5619 14.5534 17.328 13.7697 16.7586L10.0878 14.0836C9.73735 13.8289 9.26276 13.8289 8.91227 14.0836L5.23042 16.7586C4.44671 17.328 3.39223 16.5619 3.69158 15.6406L5.09792 11.3123C5.2318 10.9003 5.08514 10.4489 4.73465 10.1943L1.0528 7.51925C0.26909 6.94985 0.671865 5.71024 1.64059 5.71024H6.1916C6.62483 5.71024 7.00878 5.43128 7.14266 5.01925L8.549 0.690983Z"
      fill={fill}
    />
  </svg>
);
StarIcon.propTypes = { fill: PropTypes.string };
StarIcon.defaultProps = { fill: '#61717E' };

const StarredIcon = () => (<StarIcon fill="#55CE8D" />);

function CrossIcon() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14 4L4 14" stroke="#61717E" strokeWidth="2" />
      <path d="M14 14L4 4" stroke="#61717E" strokeWidth="2" />
    </svg>
  );
}


const ManageWallets = ({ showAddButton }) => {
  const dispatch = useDispatch();
  const recentAccounts = useSelector(state => state.general.recentAccounts);
  const ownedAccounts = recentAccounts.filter(a => a.owned);
  const trackedAccounts = recentAccounts.filter(a => !a.owned);
  return (
    <div className="manage-wallets-wrapper">
      <h2 className="Flex SpaceBetween">
        <span>My wallets</span>
        {showAddButton && (
          <button className="button mini green" type="button" onClick={() => dispatch(openLoginModal())}>Add Wallet</button>
        )}
      </h2>

      {
        ownedAccounts.length === 0 &&
        <p>No recently connected wallets.</p>
      }

      <div className="wallet-list">
        {ownedAccounts.map((accData) => (
          <div
            className={`wallet-option ${accData.accountType}`}
            onClick={() => dispatch(switchToAccount(accData.accountType, accData.account))}
            key={`${accData.account}-${accData.accountType}`}
          >
            <div className="Flex FlexStart">
              <div className="Flex wallet-icon"><WalletIcon accData={accData} /></div>
              <div>
                <h3>
                  {shortAddress(toChecksumAddress(accData.account))}
                  {accData.ensName && ` (${accData.ensName})`}
                </h3>
                <h4>{accData.walletName}</h4>
              </div>
            </div>
            <div className="Flex" onClick={e => e.stopPropagation()}>
              <button type="button" onClick={() => dispatch(switchToAccount(ACCOUNT_TYPES.viewOnly, accData.account))}>
                <TooltipWrapper title="Load as view-only"><TrackAddressIcon /></TooltipWrapper>
              </button>
              <button type="button" onClick={() => dispatch(setAccountAsNotOwned(accData.account, accData.accountType))}>
                <TooltipWrapper title="Remove from My Wallets"><StarredIcon /></TooltipWrapper>
              </button>
            </div>
          </div>
        ))}
      </div>

      {
        trackedAccounts.length > 0 && (
          <>
            <h2>Recent wallets</h2>
            <div className="wallet-list recent">
              {trackedAccounts.map((accData) => (
                <div
                  className={`wallet-option ${accData.accountType}`}
                  onClick={() => dispatch(switchToAccount(accData.accountType, accData.account))}
                  key={`${accData.account}-${accData.accountType}`}
                >
                  <div className="Flex FlexStart">
                    <div className="Flex wallet-icon"><WalletIcon accData={accData} /></div>
                    <div>
                      <h3>
                        {shortAddress(toChecksumAddress(accData.account))}
                        {accData.ensName && ` (${accData.ensName})`}
                      </h3>
                      <h4>{accData.walletName}</h4>
                    </div>
                  </div>
                  <div className="Flex" onClick={e => e.stopPropagation()}>
                    <button type="button" onClick={() => dispatch(removeAccountFromRecents(accData.account, accData.accountType))}>
                      <TooltipWrapper title="Clear from recents"><CrossIcon /></TooltipWrapper>
                    </button>
                    <button type="button" onClick={() => dispatch(setAccountAsOwned(accData.account, accData.accountType))}>
                      <TooltipWrapper title="Add to My Wallets"><StarIcon /></TooltipWrapper>
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </>
        )
      }
    </div>
  );
};

ManageWallets.defaultProps = {
  showAddButton: false,
};

ManageWallets.propTypes = {
  showAddButton: PropTypes.bool,
};

export default ManageWallets;
