import React from 'react';
import t from 'translate';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import unsubscribeFeedbackFormValidator from './UnsubscribeFeedbackFormValidator';
import { FEEDBACK_CODES, FIELDS_FOR_FEEDBACK_FORM } from '../feedbackFields';

import TextAreaComponent from '../../../Forms/TextAreaComponent';
import CheckboxComponent from '../../../Forms/CheckboxComponent/CheckboxComponent';
import ExternalLink from '../../../Decorative/ExternalLink/ExternalLink';

const FeedbackOption =
  ({ value, text, hasDescription, selectedOption, }) => ( // eslint-disable-line
    <div className="feedback-item-wrapper">
      <Field
        id={`radio-button-${value}`}
        type="radio"
        value={value}
        name="field"
        labelText={text}
        component={CheckboxComponent}
      />

      {selectedOption === value && value === FEEDBACK_CODES.adjustment && (
        <p className="feedback-item-wrapper-cta">
          Did you know you can add/remove funds to your position and rebalance to your current ratio in the same transaction? <ExternalLink href="https://help.defisaver.com">Find out how.</ExternalLink>
        </p>
      )}
      {selectedOption === value && value === FEEDBACK_CODES.uncertain && (
        <p className="feedback-item-wrapper-cta">
          We&apos;d love to help with any uncertainties you may have. Our <ExternalLink href="https://discord.gg/XGDJHhZ">Discord</ExternalLink> is very active, and our <ExternalLink href="https://twitter.com/DeFiSaver">Twitter</ExternalLink> DMs are open.
        </p>
      )}
    </div>
  );

const UnsubscribeFeedbackForm = ({
  handleSubmit, onSubmitAction, closeModal, protocol, selectedOption,
}) => (
  <form
    id="unsubscribe-feedback-form"
    onSubmit={handleSubmit(e => onSubmitAction(e, closeModal))}
    className="form-wrapper"
  >
    { FIELDS_FOR_FEEDBACK_FORM[protocol].map(params => (<FeedbackOption key={params.value} selectedOption={selectedOption} {...params} />)) }

    <div className="bottom-section">
      <p className="additional-info-label">Feel free to provide additional information: </p>
      <div className="textarea-container">
        <Field
          id="field-description"
          placeholder="(optional)"
          name="description"
          component={TextAreaComponent}
        />
      </div>

      <div className="info">{t('common.your_feedback_helps_us')}</div>

      {/* { !submittingForm && submittingFormError && (<div className="submit-error">{submittingFormError}</div>) } */}
    </div>
  </form>
);

const UnsubscribeFeedbackReduxForm = reduxForm({
  form: 'unsubscribeFeedbackForm',
  validate: unsubscribeFeedbackFormValidator,
})(UnsubscribeFeedbackForm);


FeedbackOption.defaultProps = {
  selectedOption: null,
  hasDescription: false,
};

FeedbackOption.propTypes = {
  value: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  hasDescription: PropTypes.bool,
  selectedOption: PropTypes.string,
};

UnsubscribeFeedbackForm.defaultProps = {
  selectedOption: null,
};

UnsubscribeFeedbackForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmitAction: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  selectedOption: PropTypes.string,
  protocol: PropTypes.string.isRequired,
  submitAction: PropTypes.func.isRequired,
};

const selector = formValueSelector('unsubscribeFeedbackForm');

const mapStateToProps = (state, { protocol }) => ({
  selectedOption: selector(state, 'field'),
  initialValues: {
    field: 'other',
  },
});

const mapDispatchToProps = (dispatch, { submitAction }) => ({
  onSubmitAction: (formData) => submitAction(formData),
});

export default connect(mapStateToProps, mapDispatchToProps)(UnsubscribeFeedbackReduxForm);
