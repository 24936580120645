import t from 'translate';
import { FIELDS_FOR_FEEDBACK_FORM } from '../feedbackFields';

const unsubscribeFeedbackFormValidator = ({ field, description }, { protocol }) => {
  const errors = {};

  if (!field) errors.field = t('errors.required');

  if (field) {
    const shouldHaveDescription = (FIELDS_FOR_FEEDBACK_FORM[protocol].find(({ value }) => field === value)).hasDescription;

    if (shouldHaveDescription && !description) errors.description = 'No description'; // do not translate - not visible
  }

  return errors;
};

export default unsubscribeFeedbackFormValidator;
