import capitalize from 'lodash/capitalize';
import { formatPositionId } from '../../services/recipeCreator/recipeActionUtils';
import DyDxFlashLoanAction from '../../recipeActions/flashloan/DyDxFlashLoanAction';
import AaveV2FlashLoanAction from '../../recipeActions/flashloan/AaveV2FlashLoanAction';
import MakerFlashLoanAction from '../../recipeActions/flashloan/MakerFlashLoanAction';
import BalancerFlashLoanAction from '../../recipeActions/flashloan/BalancerFlashLoanAction';
import UniswapV2SupplyAction from '../../recipeActions/uniswapV2/UniswapV2SupplyAction';
import UniswapV2WithdrawAction from '../../recipeActions/uniswapV2/UniswapV2WithdrawAction';
import UniswapV3MintAction from '../../recipeActions/uniswapV3/UniswapV3MintAction';
import UniswapV3SupplyAction from '../../recipeActions/uniswapV3/UniswapV3SupplyAction';
import UniswapV3WithdrawAction from '../../recipeActions/uniswapV3/UniswapV3WithdrawAction';
import UniswapV3CollectAction from '../../recipeActions/uniswapV3/UniswapV3CollectAction';

export const isFLAction = (action) => action.constructor === DyDxFlashLoanAction || action.constructor === AaveV2FlashLoanAction || action.constructor === MakerFlashLoanAction || action.constructor === BalancerFlashLoanAction;
export const isLPAction = (action) => action.constructor === UniswapV2SupplyAction || action.constructor === UniswapV2WithdrawAction ||
  action.constructor === UniswapV3MintAction || action.constructor === UniswapV3SupplyAction ||
  action.constructor === UniswapV3WithdrawAction || action.constructor === UniswapV3CollectAction;

/**
 * Check for errors after each action
 *
 * @param actions
 * @param actionCalls
 * @param assets
 * @param quickAccess {boolean} adjust validation for quick access modal (ie. won't show certain suggestions)
 * @returns {Array<Array<string>>}
 */
export const checkActions = (actions, actionCalls, assets, quickAccess = false) => {
  const actionsErrors = [];

  let numOfFlActions = 0;

  actionCalls.forEach((call, i) => {
    const actionErrors = [];

    const action = actions[i];

    if (isFLAction(action)) {
      numOfFlActions++;
      if (numOfFlActions > 1) actionErrors.push('Only one Flashloan action is possible in a recipe.');
      else if (i !== 0) actionErrors.push('Flashloan must be the first action in a Recipe.');
    }

    if (isLPAction(action)) {
      const error = action.getError();
      if (error) actionErrors.push(error);
    }

    // assert all balances > 0 after action
    for (const wallet of Object.keys(call.balances)) {
      if (['dydx', 'aave', 'aaveV2', 'maker', 'balancer'].includes(wallet)) continue; // skip FL providers
      for (const asset of Object.keys(call.balances[wallet])) {
        if (parseFloat(call.balances[wallet][asset]) < 0) {
          const otherWallet = wallet === 'wallet' ? 'recipe' : 'wallet';
          const _otherWallet = otherWallet.replace('recipe', 'temporary');
          const _wallet = wallet.replace('recipe', 'temporary');
          let otherBalance = call.balances?.[otherWallet]?.[asset];
          if (!otherBalance && otherWallet === 'wallet') otherBalance = assets[asset].balance;

          let errorText = `${capitalize(_wallet)} balance of ${asset} is negative. `;
          if (+otherBalance > 0 && !quickAccess) errorText += `Did you mean to use the ${_otherWallet} balance?`;
          else if (asset === 'WETH' && !quickAccess) errorText += 'You might want add a \'Wrap ETH\' action before this one.';
          actionErrors.push(errorText);
        }
      }
    }

    for (const positionId of Object.keys(call.positions)) {
      const position = call.positions[positionId];

      // assert ratio > liquidation ratio
      const hasBorrow = (parseFloat(position.borrowedUsd) || parseFloat(position.debtInAsset)) > 0;
      if (parseFloat(position.ratio) < position.liqPercent && hasBorrow) {
        actionErrors.push(`${formatPositionId(positionId)} is below liquidation threshold.`);
      }

      // assert collateral/debt > 0
      if (positionId.substring(0, 7) === 'liquity') {
        if (position.nothingToClaimStaking) actionErrors.push('There are no LQTY Staking Rewards to claim.');
        if (position.nothingToClaimSP) actionErrors.push('There are no SP Staking Rewards to claim.');
      }
      if (positionId.substr(0, 5) === 'maker' || positionId.substr(0, 8) === 'reflexer' || positionId.substring(0, 7) === 'liquity') {
        if (parseFloat(position.collateral) < 0) actionErrors.push(`${formatPositionId(positionId)} collateral is below zero.`);
        if (parseFloat(position.debt) < 0) actionErrors.push(`${formatPositionId(positionId)} debt is below zero.`);
        if (position?.ilk === '0x0000000000000000000000000000000000000000000000000000000000000000') actionErrors.push('Non-existent Vault selected.');
      } else if (positionId.substr(0, 9) === 'uniswapV3') { // eslint-disable-line no-empty

      } else if (positionId === 'mstableVault') {
        if (parseFloat(position) < 0) actionErrors.push('mStable Vault balance is below zero.');
      } else {
        Object.keys(position.afterAssets).forEach((asset, i) => {
          if (parseFloat(position.afterAssets[asset].supplied) < 0) actionErrors.push(`${formatPositionId(positionId)} ${asset} collateral is below zero.`);
          if (parseFloat(position.afterAssets[asset].borrowed) < 0) actionErrors.push(`${formatPositionId(positionId)} ${asset} debt is below zero.`);
        });
      }

      // assert debt = 0 or debt > minDebt (aka dust) for Maker/Reflexer
      if (position.debtTooLow) {
        actionErrors.push(`Debt is below minimum. You can either pay back all debt, or keep your debt above ${position.minDebt} ${position.debtAsset}.`);
      }
      // assert if trove is active for Liquity
      if (position.disabledBecauseClosed) {
        actionErrors.push('You don\'t currently have a Trove open.');
      }
      if (position.alreadyOpened) {
        actionErrors.push('You already have an active Trove.');
      }
    }
    actionsErrors.push(actionErrors);
  });

  return actionsErrors;
};
