import React, { Component } from 'react';
import t from 'translate';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import OutsideAlerter from '../OutsideAlerter/OutsideAlerter';
import Loader from '../Loader/Loader';
import SingleServerNotification from './SingleServerNotification/SingleServerNotification';
import ErrorBox from '../Decorative/ErrorBox/ErrorBox';

import './ServerNotifications.scss';

import emptyInbox from './empty-inbox.svg';
import BellIcon from '../Decorative/BellIcon';

class ServerNotifications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };

    this.setOpen = this.setOpen.bind(this);
  }

  setOpen(payload) { this.setState({ open: payload }); }

  render() {
    const {
      setOpen,
      state: { open },
      props: {
        gettingNotifications, gettingNotificationsError, notifications, loggingIn,
      },
    } = this;

    const bellFilter = toType => ({ type, read }) => type === toType && !read;

    const hasDanger = notifications.filter(bellFilter('danger')).length > 0;
    const hasWarning = notifications.filter(bellFilter('warning')).length > 0;
    const hasNotification = notifications.filter(bellFilter('notification')).length > 0;
    const unread = notifications.filter(({ read }) => !read).length;

    return (
      <OutsideAlerter className="dropdown-list-panel-wrapper" onClickOutside={() => { setOpen(false); }}>
        <div
          className={`
          icon-wrapper ${hasNotification && 'notification'} ${hasWarning && 'warning'} ${hasDanger && 'danger shake'}
          ${loggingIn && 'disabled'}
        `}
          onClick={() => { if (!loggingIn) setOpen(!open); }}
        >
          {
            unread > 0 && <span className="unread-count">{unread}</span>
          }
          <BellIcon />
        </div>

        <div
          className={`dropdown-wrapper ${open ? 'opened' : 'closed'}`}
        >
          <h3>{t('common.notifications')}</h3>
          <div className="dropdown-container">
            { gettingNotifications && notifications.length === 0 && (<Loader />) }

            {
              gettingNotificationsError && (
                <ErrorBox>{ gettingNotificationsError }</ErrorBox>
              )
            }

            {
              notifications.length === 0 && (
                <div className="empty-wrapper" key={1}>
                  <div className="title">{t('common.no_notifications_received')}</div>
                  <img src={emptyInbox} alt={t('common.no_notifications_received')} />
                </div>
              )
            }
            {
              notifications.length > 0 && (
                <div className="notifications-container" key={2}>
                  { notifications.map(n => <SingleServerNotification key={n.id} {...n} />) }
                </div>
              )
            }
          </div>
        </div>
      </OutsideAlerter>
    );
  }
}

ServerNotifications.propTypes = {
  notifications: PropTypes.array.isRequired,
  gettingNotifications: PropTypes.bool.isRequired,
  gettingNotificationsError: PropTypes.string.isRequired,
  loggingIn: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ serverNotifications, general }) => ({
  notifications: serverNotifications.notifications,
  gettingNotifications: serverNotifications.gettingNotifications,
  gettingNotificationsError: serverNotifications.gettingNotificationsError,
  loggingIn: general.loggingIn,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ServerNotifications);
