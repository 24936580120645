import {
  BRIDGE_GET_BALANCES_FOR_NETWORK_REQUEST,
  BRIDGE_GET_BALANCES_FOR_NETWORK_SUCCESS,
  BRIDGE_GET_BALANCES_FOR_NETWORK_FAILURE,
} from '../actionTypes/bridgeActionTypes';
import assets from '../constants/assets';

const INITIAL_ASSETS = assets.reduce((acc, t) => {
  acc[t.symbol] = {
    balance: '0',
  };
  return acc;
}, {});

const INITIAL_STATE = {
  fetchingForNetwork1: false,
  fetchingForNetwork10: false,
  fetchingForNetwork42161: false,
  fetchedForNetwork1: false,
  fetchedForNetwork10: false,
  fetchedForNetwork42161: false,
  fetchingForNetwork1Error: '',
  fetchingForNetwork10Error: '',
  fetchingForNetwork42161Error: '',
  timestamp1: Date.now(),
  timestamp10: Date.now(),
  timestamp42161: Date.now(),
  networks: {
    1: INITIAL_ASSETS,
    10: INITIAL_ASSETS,
    42161: INITIAL_ASSETS,
  },
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case BRIDGE_GET_BALANCES_FOR_NETWORK_REQUEST:
      return {
        ...state,
        [`fetchingForNetwork${payload.network}`]: true,
        [`fetchingForNetwork${payload.network}Error`]: '',
        [`fetchedForNetwork${payload.network}`]: false,
        networks: {
          ...state.networks,
          [payload.network]: {
            ...state.networks[payload.network],
            ...payload.assets.reduce((acc, symbol, i) => {
              acc[symbol] = {
                ...state.networks[payload.network][symbol],
                address: payload.addresses[i],
                gettingBalance: true,
                gettingBalanceError: '',
              };
              return acc;
            }, {}),
          },
        },
      };
    case BRIDGE_GET_BALANCES_FOR_NETWORK_SUCCESS:
      return {
        ...state,
        [`fetchingForNetwork${payload.network}`]: false,
        [`fetchedForNetwork${payload.network}`]: true,
        [`timestamp${payload.network}`]: Date.now(),
        networks: {
          ...state.networks,
          [payload.network]: {
            ...state.networks[payload.network],
            ...payload.assets.reduce((acc, symbol, i) => {
              acc[symbol] = {
                ...state.networks[payload.network][symbol],
                address: payload.addresses[i],
                gettingBalance: false,
                gettingBalanceError: '',
                balance: payload.balances[i],
              };
              return acc;
            }, {}),
          },
        },
      };
    case BRIDGE_GET_BALANCES_FOR_NETWORK_FAILURE:
      return {
        ...state,
        [`fetchingForNetwork${payload.network}`]: false,
        [`fetchingForNetwork${payload.network}Error`]: payload.error,
        networks: {
          ...state.networks,
          [payload.network]: {
            ...state.networks[payload.network],
            ...payload.assets.reduce((acc, symbol, i) => {
              acc[symbol] = {
                ...state.networks[payload.network][symbol],
                gettingBalance: false,
                gettingBalanceError: payload.error,
              };
              return acc;
            }, {}),
          },
        },
      };
    default:
      return state;
  }
};
