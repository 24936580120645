import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { toChecksumAddress } from 'ethereumjs-util';
import ModalHeader from '../ModalHeader';
import ModalBody from '../ModalBody';
import { connectViewOnlyAccount } from '../../../actions/accountActions';
import { getBrowserProviderName } from '../../../services/ethService';

import './MetamaskChangeAccModal.scss';

const MetamaskChangeAccModal = ({ closeModal, targetAccount, selectedAccount }) => {
  const dispatch = useDispatch();
  const currentAccount = useSelector(state => state.general.account);
  const walletName = getBrowserProviderName();
  return (
    <div className="metamask-change-acc-modal">
      <ModalHeader closeModal={closeModal} />
      <ModalBody>
        <div className="new-modal-top-wrapper">
          <h1 className="title">Switch your account</h1>
        </div>

        <div className="new-modal-content-wrapper">
          <p>Please switch the selected account in {walletName} wallet, or use view-only mode.</p>
          {selectedAccount && <p>Currently selected: <b>{(toChecksumAddress(selectedAccount))}</b></p>}
          <p>Switch to: <b>{(toChecksumAddress(targetAccount))}</b></p>
          <button className="button" type="button" onClick={() => dispatch(connectViewOnlyAccount(targetAccount, closeModal))}>
            Use view-only mode
          </button>
        </div>
      </ModalBody>
    </div>
  );
};

MetamaskChangeAccModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  targetAccount: PropTypes.string.isRequired,
  selectedAccount: PropTypes.string,
};

export default MetamaskChangeAccModal;
