import { CONNECT_PROVIDER_SUCCESS } from '../actionTypes/generalActionTypes';
import { RC_EXECUTE_RECIPE_REQUEST, RC_EXECUTE_RECIPE_SUCCESS, RC_EXECUTE_RECIPE_FAILURE } from '../actionTypes/recipeCreatorActionTypes';

const INITIAL_STATE = {
  actions: [],
  afterValuesLoading: false,
  // balances: {},
  returnValues: [], // deprecated
  actionCalls: [],
  recipeErrors: [],
  actionsErrors: [],
  recipeSuggestions: [],
  formOpen: null,
  updatingAction: false,
  expires: Infinity,
  executing: false,
  executingError: '',
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'RC_SET_ACTIONS': {
      return {
        ...state,
        actions: payload,
      };
    }

    case 'RC_UPDATE_ACTION_REQUEST': {
      return {
        ...state,
        updatingAction: true,
      };
    }

    case 'RC_UPDATE_ACTION': {
      const actions = [...state.actions];
      const i = actions.findIndex(({ id }) => id === payload.id);
      if (i === -1) return state;
      actions[i].args = [...payload.args];
      actions[i].inputs.map((input, i) => {
        input.value = payload.args[i]; // eslint-disable-line
        return input;
      });
      return {
        ...state,
        actions,
        updatingAction: false,
      };
    }

    case 'RC_UPDATE_AFTER_REQUEST': {
      return {
        ...state,
        afterValuesLoading: true,
        // balances: {},
        returnValues: [],
        actionCalls: [],
        recipeErrors: [],
        actionsErrors: [],
        recipeSuggestions: [],
      };
    }

    case 'RC_UPDATE_AFTER_STEP': {
      const {
        balances, returnValues, actionCalls,
      } = payload;
      return {
        ...state,
        // balances: { ...balances },
        returnValues: [...returnValues],
        actionCalls: [...actionCalls],
      };
    }

    case 'RC_UPDATE_AFTER_SUCCESS': {
      return {
        ...state,
        afterValuesLoading: false,
        expires: payload.expires,
      };
    }

    case 'RC_OPEN_FORM': {
      return {
        ...state,
        formOpen: payload,
      };
    }

    case 'RC_CLOSE_FORM': {
      return {
        ...state,
        formOpen: null,
      };
    }

    case 'RC_VALIDATION_SUCCESS': {
      const { recipeErrors, actionsErrors, recipeSuggestions } = payload;
      return {
        ...state,
        recipeErrors,
        actionsErrors,
        recipeSuggestions,
      };
    }
    case 'RC_EXECUTE_RECIPE_REQUEST': {
      return {
        ...state,
        executing: true,
        executingError: '',
      };
    }
    case 'RC_EXECUTE_RECIPE_SUCCESS': {
      return {
        ...state,
        executing: false,
        executingError: '',
      };
    }
    case 'RC_EXECUTE_RECIPE_FAILURE': {
      return {
        ...state,
        executing: false,
        executingError: payload,
      };
    }

    case CONNECT_PROVIDER_SUCCESS:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};
