/* eslint-disable */
import React from 'react';

export default function TxCanceledIcon(props) {
  return (
    <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M50.4196 25.0698C50.4196 38.8769 39.2267 50.0698 25.4196 50.0698C11.6124 50.0698 0.419556 38.8769 0.419556 25.0698C0.419556 11.2626 11.6124 0.0697632 25.4196 0.0697632C39.2267 0.0697632 50.4196 11.2626 50.4196 25.0698Z" fill="#EB5757"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M33.7149 37.1805L13.3088 16.7744L17.1242 12.959L37.5303 33.3651L33.7149 37.1805Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M17.1242 37.1805L37.5303 16.7744L33.7149 12.959L13.3088 33.3651L17.1242 37.1805Z" fill="white"/>
    </svg>
  );
}
