/* eslint-disable */
import React from 'react';

export default function ZkSyncIcon(props) {
  return (
    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M9.99977 2.82765L7.16316 0.000976562V2.0711L4.34668 4.14377L7.16316 4.14572V5.65432L9.99977 2.82765Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M0 2.82667L2.83659 5.65334V3.59987L5.6531 1.51053L2.83659 1.5086V0L0 2.82667Z" fill="white"/>
    </svg>
  );
}
