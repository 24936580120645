import Dec from 'decimal.js';
import { bytesToString } from '@defisaver/tokens/esm/utils';
import { getAssetInfo, getAssetInfoByAddress, MAXUINT } from '@defisaver/tokens';
import dfs from '@defisaver/sdk';
import {
  compareAddresses,
  getEthAmountForDecimals,
  formatNumber, numberWithCommas, addToArrayIf,
} from './utils';
import { LIQUITY_STATUS_MAPPING } from '../constants/liquity';
import { callActionViaProxy } from './contractCallService';
import { Erc20ViewContract, RENBTCAddress } from './contractRegistryService';
import { apyToApr, calculateInterestEarned } from './moneymarketCommonService';
import { NETWORKS } from '../constants/general';
import { isLayer2Network } from './ethService';

export const parseMakerData = (getState, account, proxyAddress, accountType, cdps) => {
  if (!cdps) return [];

  const activeStrategiesVaultIds =
    getState().makerStrategies.makerSubscribedStrategies.filter(({ isEnabled }) => isEnabled).map(({ subData }) => +subData.vaultId);

  const makerPositions = cdps.map(cdp => {
    const collateralUsd = cdp?.collateralUsd || new Dec(cdp?.collateral || 0).mul(cdp?.price || 0).toString();
    return ({
      network: 'mainnet',
      key: `maker${cdp.id}`,
      protocol: 'Maker',
      additionalClasses: 'AlignCenter SmallerGrid',
      account,
      accountType,
      protocolType: 'Lending',
      proxy: true,
      route: `/makerdao/manage/${cdp.id}`,
      isSubscribedToAutomation: cdp.isSubscribedToAutomation || activeStrategiesVaultIds.includes(cdp.id),
      usdValueCollateral: collateralUsd,
      usdValueDebt: cdp.debtUsd,
      usdValueTotal: new Dec(collateralUsd).minus(cdp?.debtUsd || 0).toString(),
      isEmpty: Dec(cdp?.collateralUsd || 0).lt(10),
      data: [
        {
          label: 'Maker Vault', value: (`#${cdp.id}`), afterSymbol: `(${bytesToString(cdp.ilk)})`, style: { gridArea: 'type' },
        },
        {
          label: 'Collateral', isNumber: true, value: cdp.collateral, icon: getAssetInfo(cdp.asset).icon, infoMessage: `$${numberWithCommas(collateralUsd, 2)}`, style: { gridArea: 'coll' },
        },
        {
          label: 'Debt', isNumber: true, value: cdp.debtDai, icon: getAssetInfo('DAI').icon, infoMessage: `$${numberWithCommas(cdp.debtUsd, 2)}`, style: { gridArea: 'debt' },
        },
        {
          label: 'Ratio', isNumber: true, value: cdp.ratio, afterSymbol: '%', style: { gridArea: 'ratio' },
        },
        {
          label: 'APY', isNumber: true, value: cdp.stabilityFee, afterSymbol: '%', style: { gridArea: 'other' },
        },
      ],
    });
  });
  return [...makerPositions];
};

export const parseCompoundData = (getState, account, proxyAddress, accountType, fetchedData) => {
  if (!fetchedData) return [];
  const {
    account: {
      suppliedUsd: suppliedAccComp,
      borrowedUsd: borrowedAccComp,
      ratio: ratioAccComp,
      netApy: apyAccComp,
      claimableComp: claimableAccComp,
      usedAssets: usedAssetsAccComp,
    },
    smartWallet: {
      suppliedUsd: suppliedProxyComp,
      borrowedUsd: borrowedProxyComp,
      ratio: ratioProxyComp,
      netApy: apyProxyComp,
      claimableComp: claimableProxyComp,
      usedAssets: usedAssetsProxyComp,
      isSubscribedToAutomation,
      suppliedOnlyDai,
    },
  } = fetchedData;
  const { assets } = getState();
  const compAccCollLabel = Object.values(usedAssetsAccComp).filter(assetInfo => assetInfo.isSupplied && +assetInfo.supplied).map(assetInfo => `${assetInfo.symbol}: ${numberWithCommas(assetInfo.supplied)}`).join('\n ');
  const compProxyCollLabel = Object.values(usedAssetsProxyComp).filter(assetInfo => assetInfo.isSupplied && +assetInfo.supplied).map(assetInfo => `${assetInfo.symbol}: ${numberWithCommas(assetInfo.supplied)}`).join('\n ');
  const compAccDebtLabel = Object.values(usedAssetsAccComp).filter(assetInfo => assetInfo.isBorrowed).map(assetInfo => `${assetInfo.symbol}: ${numberWithCommas(assetInfo.borrowed)}`).join('\n ');
  const compProxyDebtLabel = Object.values(usedAssetsProxyComp).filter(assetInfo => assetInfo.isBorrowed).map(assetInfo => `${assetInfo.symbol}: ${numberWithCommas(assetInfo.borrowed)}`).join('\n ');

  const usdTotalClaimableAcc = new Dec(claimableAccComp || 0).mul(assets.COMP.compoundPrice || 0).toString();
  const usdTotalClaimableProxy = new Dec(claimableProxyComp || 0).mul(assets.COMP.compoundPrice || 0).toString();

  const compoundAccObject = {
    network: 'mainnet',
    key: 'compoundaccount',
    protocol: 'Compound',
    account,
    accountType,
    proxy: false,
    protocolType: 'Lending',
    route: '/compound/manage/account',
    usdValueCollateral: suppliedAccComp,
    usdValueDebt: borrowedAccComp,
    usdValueTotal: new Dec(suppliedAccComp || 0).minus(borrowedAccComp || 0).toString(),
    usdTotalClaimable: usdTotalClaimableAcc,
    isEmpty: Dec(suppliedAccComp || 0).lt(10),
    data: [
      {
        label: 'Compound Position', value: 'Account', style: { gridArea: 'type' },
      },
      {
        label: 'Collateral', isNumber: true, value: suppliedAccComp, beforeSymbol: '$', infoMessage: compAccCollLabel, style: { gridArea: 'coll' },
      },
      {
        label: 'Debt', isNumber: true, value: borrowedAccComp, beforeSymbol: '$', infoMessage: compAccDebtLabel, style: { gridArea: 'debt' },
      },
      {
        label: 'Ratio', isNumber: true, value: ratioAccComp, afterSymbol: '%', style: { gridArea: 'ratio' },
      },
      {
        label: 'APY', isNumber: true, value: apyAccComp, afterSymbol: '%', style: { gridArea: 'other' },
      },
      {
        label: 'Claimable', isNumber: true, beforeSymbol: '$', value: usdTotalClaimableAcc, infoMessage: `${numberWithCommas(claimableAccComp)} COMP`, style: { gridArea: 'stake' },
      },
    ],
  };
  const compoundProxyObject = {
    network: 'mainnet',
    key: 'compoundproxy',
    protocol: 'Compound',
    protocolType: 'Lending',
    account,
    accountType,
    proxy: true,
    route: '/compound/manage/smart-wallet',
    usdValueCollateral: suppliedProxyComp,
    usdValueDebt: borrowedProxyComp,
    usdValueTotal: new Dec(suppliedProxyComp || 0).minus(borrowedProxyComp || 0).toString(),
    usdTotalClaimable: usdTotalClaimableProxy,
    isEmpty: Dec(suppliedProxyComp || 0).lt(10),
    isSubscribedToAutomation,
    suppliedOnlyDai,
    daiSupplied: usedAssetsProxyComp?.DAI?.supplied || '0',
    data: [
      {
        label: 'Compound Position', value: 'Smart Wallet', style: { gridArea: 'type' },
      },
      {
        label: 'Collateral', isNumber: true, value: suppliedProxyComp, beforeSymbol: '$', infoMessage: compProxyCollLabel, style: { gridArea: 'coll' },
      },
      {
        label: 'Debt', isNumber: true, value: borrowedProxyComp, beforeSymbol: '$', infoMessage: compProxyDebtLabel, style: { gridArea: 'debt' },
      },
      {
        label: 'Ratio', isNumber: true, value: ratioProxyComp, afterSymbol: '%', style: { gridArea: 'ratio' },
      },
      {
        label: 'APY', isNumber: true, value: apyProxyComp, afterSymbol: '%', style: { gridArea: 'other' },
      },
      {
        label: 'Claimable', isNumber: true, beforeSymbol: '$', value: usdTotalClaimableProxy, infoMessage: `${numberWithCommas(claimableProxyComp)} COMP`, style: { gridArea: 'stake' },
      },
    ],
  };

  return [compoundAccObject, compoundProxyObject];
};


export const parseAaveData = (getState, account, proxyAddress, accountType, fetchedData, network) => {
  if (!fetchedData) return [];
  const {
    account: {
      ratio: accRatioAave,
      suppliedUsd: accSuppliedUsdAave,
      borrowedUsd: accBorrowedUsdAave,
      netApy: accApyAave,
      usedAssets: usedAssetsAccAave,
    },
    proxy: {
      ratio: proxyRatioAave,
      suppliedUsd: proxySuppliedUsdAave,
      borrowedUsd: proxyBorrowedUsdAave,
      netApy: proxyApyAave,
      usedAssets: usedAssetsProxyAave,
      isSubscribedToAutomation,
      suppliedOnlyDai,
    },
    stkAaveRewardBalanceAcc,
    stkAaveRewardBalanceProxy,
    rewardBalance,
  } = fetchedData;
  const { assets } = getState();
  const aaveAccCollLabel = Object.values(usedAssetsAccAave).filter(assetInfo => assetInfo.isSupplied).map(assetInfo => `${assetInfo.symbol}: ${numberWithCommas(assetInfo.supplied)}`).join('\n ');
  const aaveProxyCollLabel = Object.values(usedAssetsProxyAave).filter(assetInfo => assetInfo.isSupplied).map(assetInfo => `${assetInfo.symbol}: ${numberWithCommas(assetInfo.supplied)}`).join('\n ');
  const aaveAccDebtLabel = Object.values(usedAssetsAccAave).filter(assetInfo => assetInfo.isBorrowed).map(assetInfo => `${assetInfo.symbol}: ${numberWithCommas(assetInfo.borrowed)}`).join('\n ');
  const aaveProxyDebtLabel = Object.values(usedAssetsProxyAave).filter(assetInfo => assetInfo.isBorrowed).map(assetInfo => `${assetInfo.symbol}: ${numberWithCommas(assetInfo.borrowed)}`).join('\n ');

  const usdTotalClaimableAcc = new Dec(new Dec(stkAaveRewardBalanceAcc || 0).plus(rewardBalance || 0)).mul(assets.AAVE.aavePrice || 0).toString();
  const usdTotalClaimableProxy = new Dec(stkAaveRewardBalanceProxy || 0).mul(assets.AAVE.aavePrice || 0).toString();

  const networkName = Object.values(NETWORKS).find(i => network === i.chainId).key || NETWORKS.mainnet.key;
  const isLayer2 = isLayer2Network(network);

  const aaveAccountObject = {
    network: networkName,
    key: 'aaveaccount',
    protocol: 'Aave',
    protocolType: 'Lending',
    account,
    accountType,
    proxy: false,
    route: '/aave/manage/account/v2default',
    usdValueCollateral: accSuppliedUsdAave,
    usdValueDebt: accBorrowedUsdAave,
    usdValueTotal: new Dec(accSuppliedUsdAave || 0).minus(accBorrowedUsdAave || 0).toString(),
    usdTotalClaimable: usdTotalClaimableAcc,
    isEmpty: new Dec(accSuppliedUsdAave || 0).lt(10) && new Dec(new Dec(rewardBalance || 0).mul(assets.AAVE.aavePrice || 0).toString()).lt(10),
    data: [
      {
        label: 'Aave Position', value: 'Account', style: { gridArea: 'type' },
      },
      {
        label: 'Collateral', isNumber: true, value: accSuppliedUsdAave, beforeSymbol: '$', infoMessage: aaveAccCollLabel, style: { gridArea: 'coll' },
      },
      {
        label: 'Debt', isNumber: true, value: accBorrowedUsdAave, beforeSymbol: '$', infoMessage: aaveAccDebtLabel, style: { gridArea: 'debt' },
      },
      {
        label: 'Ratio', isNumber: true, value: accRatioAave, afterSymbol: '%', style: { gridArea: 'ratio' },
      },
      {
        label: 'APY', isNumber: true, value: accApyAave, afterSymbol: '%', style: { gridArea: 'other' },
      },
      ...addToArrayIf(!isLayer2, {
        label: 'Staked', isNumber: true, value: new Dec(assets.stkAAVE.balance || 0).mul(assets.AAVE.aavePrice || 0).toString(), beforeSymbol: '$', infoMessage: `${numberWithCommas(assets.stkAAVE.balance)} AAVE`, style: { gridArea: 'stake' },
      }),
      ...addToArrayIf(!isLayer2, {
        label: 'Claimable', isNumber: true, value: usdTotalClaimableAcc, beforeSymbol: '$', infoMessage: `${numberWithCommas(new Dec(rewardBalance || 0).toString())} AAVE\n${numberWithCommas(new Dec(stkAaveRewardBalanceAcc || 0).toString())} stkAAVE`, style: { gridArea: 'claim' },
      }),
    ],
  };
  const aaveProxyObject = {
    network: networkName,
    key: 'aaveproxy',
    protocol: 'Aave',
    protocolType: 'Lending',
    account,
    accountType,
    proxy: true,
    suppliedOnlyDai,
    route: '/aave/manage/smart-wallet/v2default',
    usdValueCollateral: proxySuppliedUsdAave,
    usdValueDebt: proxyBorrowedUsdAave,
    usdValueTotal: new Dec(proxySuppliedUsdAave || 0).minus(proxyBorrowedUsdAave || 0).toString(),
    usdTotalClaimable: usdTotalClaimableProxy,
    isEmpty: new Dec(proxySuppliedUsdAave || 0).lt(10),
    daiSupplied: usedAssetsProxyAave?.DAI?.supplied || '0',
    isSubscribedToAutomation,
    data: [
      {
        label: 'Aave Position', value: 'Smart Wallet', style: { gridArea: 'type' },
      },
      {
        label: 'Collateral', isNumber: true, value: proxySuppliedUsdAave, beforeSymbol: '$', infoMessage: aaveProxyCollLabel, style: { gridArea: 'coll' },
      },
      {
        label: 'Debt', isNumber: true, value: proxyBorrowedUsdAave, beforeSymbol: '$', infoMessage: aaveProxyDebtLabel, style: { gridArea: 'debt' },
      },
      {
        label: 'Ratio', isNumber: true, value: proxyRatioAave, afterSymbol: '%', style: { gridArea: 'ratio' },
      },
      {
        label: 'APY', isNumber: true, value: proxyApyAave, afterSymbol: '%', style: { gridArea: 'other' },
      },
      ...addToArrayIf(!isLayer2, {
        label: 'Staked', isNumber: true, value: '0', beforeSymbol: '$', style: { gridArea: 'stake' },
      }),
      ...addToArrayIf(!isLayer2, {
        label: 'Claimable', isNumber: true, value: usdTotalClaimableProxy, beforeSymbol: '$', infoMessage: `${numberWithCommas(stkAaveRewardBalanceProxy)} stkAAVE`, style: { gridArea: 'claim' },
      }),
    ],
  };
  return [aaveAccountObject, aaveProxyObject];
};

export const parseReflexerData = (getState, account, proxyAddress, accountType, safes) => {
  if (!safes || !Object.values(safes).length) return [];
  const reflexerPositions = Object.values(safes).map(safe => ({
    network: 'mainnet',
    key: `reflexer${safe.id}`,
    protocol: 'Reflexer',
    protocolType: 'Lending',
    additionalClasses: 'AlignCenter SmallerGrid',
    account,
    accountType,
    proxy: true,
    route: `/reflexer/manage/${safe.id}`,
    usdValueCollateral: safe.collateralUsd,
    usdValueDebt: safe.debtUsd,
    usdValueTotal: new Dec(safe?.collateralUsd || 0).minus(safe?.debtInAsset || 0).toString(),
    isEmpty: Dec(safe?.collateralUsd || 0).lt(10),
    data: [
      {
        label: 'Reflexer Safe', value: `#${safe.id}`, afterSymbol: `(${safe.collType})`, style: { gridArea: 'type' },
      },
      {
        label: 'Collateral', isNumber: true, value: safe.collateral, icon: getAssetInfo(safe.asset).icon, infoMessage: `$${numberWithCommas(safe.collateralUsd, 2)}`, style: { gridArea: 'coll' },
      },
      {
        label: 'Debt', isNumber: true, value: safe.debtInAsset, icon: getAssetInfo('RAI').icon, infoMessage: `$${numberWithCommas(safe.debtUsd, 2)}`, style: { gridArea: 'debt' },
      },
      {
        label: 'Ratio', isNumber: true, value: safe.ratio, afterSymbol: '%', style: { gridArea: 'ratio' },
      },
      {
        label: 'APY', value: safe.stabilityFee, afterSymbol: '%', style: { gridArea: 'other' },
      },
    ],
  }));

  return [...reflexerPositions];
};

export const parseLiquityData = (getState, address, proxyAddress, accountType, fetchedData) => {
  if (!fetchedData) return [];

  const activeStrategies =
    getState().liquityStrategies.liquitySubscribedStrategies.filter(({ isEnabled }) => isEnabled);

  const {
    assets,
  } = getState();
  const {
    proxy,
    account,
  } = fetchedData;

  // Total Usd claimable value and label for proxy
  const proxyClaimLiqValue = new Dec(new Dec(proxy?.rewardETH || 0).plus(proxy?.stabilityRewardETH || 0).mul(proxy?.assetPrice || 0)).plus(new Dec(proxy?.stabilityRewardLQTY || 0).mul(assets?.LQTY?.marketPrice || 0)).plus(new Dec(proxy?.rewardLUSD || 0).mul(proxy?.debtAssetPrice || 0)).toString();
  const proxyClaimLiqLabel = `LUSD: ${numberWithCommas(proxy.rewardLUSD)}\nLQTY: ${numberWithCommas(proxy.stabilityRewardLQTY)}\nETH (LUSD): ${numberWithCommas(proxy.stabilityRewardETH)}\nETH (LQTY): ${numberWithCommas(proxy?.rewardETH)}`;

  // Total Usd claimable value and label for account
  const accClaimLiqValue = new Dec(new Dec(account?.rewardETH || 0).plus(account?.stabilityRewardETH || 0).mul(proxy?.assetPrice || 0)).plus(new Dec(account?.stabilityRewardLQTY || 0).mul(assets?.LQTY?.marketPrice || 0)).plus(new Dec(account?.rewardLUSD || 0).mul(proxy?.debtAssetPrice || 0)).toString();
  const accClaimLiqLabel = `LUSD: ${numberWithCommas(account?.rewardLUSD)}\nLQTY: ${numberWithCommas(account?.stabilityRewardLQTY)}\nETH (LUSD): ${numberWithCommas(account?.stabilityRewardETH)}\nETH (LQTY): ${numberWithCommas(account?.rewardETH)}`;

  // Total USD staked value and label for proxy
  const proxyStakeLiqValue = new Dec(new Dec(proxy?.stakedLQTYBalance || 0).mul(assets?.LQTY?.marketPrice || 0)).plus(new Dec(proxy?.stakedLUSDBalance || 0).mul(proxy?.debtAssetPrice || 0)).toString();
  const proxyStakeLiqLabel = `${+proxy?.stakedLUSDBalance ? `LUSD: ${numberWithCommas(proxy?.stakedLUSDBalance)}` : ''}${+proxy?.stakedLUSDBalance && +proxy?.stakedLQTYBalance ? '\n' : ''}${+proxy?.stakedLQTYBalance ? `LQTY ${numberWithCommas(proxy?.stakedLQTYBalance)}` : ''}`;

  // Total USD staked value and label for account
  const accStakeLiqValue = new Dec(new Dec(account?.stakedLQTYBalance || 0).mul(assets?.LQTY?.marketPrice || 0)).plus(new Dec(account.stakedLUSDBalance || 0).mul(proxy?.debtAssetPrice || 0)).toString();
  const accStakeLiqLabel = `${+account?.stakedLUSDBalance ? `LUSD: ${numberWithCommas(account?.stakedLUSDBalance)}` : ''}${+account?.stakedLUSDBalance && +account?.stakedLQTYBalance ? '\n' : ''}${+account?.stakedLQTYBalance ? `LQTY ${numberWithCommas(account?.stakedLQTYBalance)}` : ''}`;

  const liquityAccObject = {
    network: 'mainnet',
    key: 'liquityaccount',
    protocol: 'Liquity',
    protocolType: 'Lending',
    account: address,
    accountType,
    proxy: false,
    route: '/liquity/manage/account',
    usdValueCollateral: account?.collateralUsd || 0,
    usdValueDebt: account?.debtUsd || 0,
    usdValueTotal: new Dec(account?.collateralUsd || 0).minus(account?.debtUsd || 0).toString(),
    usdTotalClaimable: accClaimLiqValue,
    isEmpty: Dec(account?.collateralUsd || 0).lt(10) && Dec(accStakeLiqValue).lt(10) && Dec(accClaimLiqValue).lt(10),
    data: [
      {
        label: 'Liquity Trove', value: 'Account', style: { gridArea: 'type' },
      },
      {
        label: 'Collateral', isNumber: true, value: account?.collateral, icon: getAssetInfo('ETH').icon, infoMessage: `$${numberWithCommas(account?.collateralUsd, 2)}`, style: { gridArea: 'coll' },
      },
      {
        label: 'Debt', isNumber: true, value: account?.debtInAsset, icon: getAssetInfo('LUSD').icon, infoMessage: `$${numberWithCommas(account?.debtUsd, 2)}`, style: { gridArea: 'debt' },
      },
      {
        label: 'Ratio', isNumber: true, value: account?.ratio, afterSymbol: '%', style: { gridArea: 'ratio' },
      },
      { label: 'Status', value: account?.troveStatus === 'nonExistent' ? 'N/A' : LIQUITY_STATUS_MAPPING[account?.troveStatus], style: { gridArea: 'other' } },
      {
        label: 'Staked', isNumber: true, value: accStakeLiqValue, beforeSymbol: '$', infoMessage: accStakeLiqLabel, style: { gridArea: 'stake' },
      },
      {
        label: 'Claimable', isNumber: true, value: accClaimLiqValue, beforeSymbol: '$', infoMessage: accClaimLiqLabel, style: { gridArea: 'claim' },
      },
    ],
  };
  const liquityProxyObject = {
    network: 'mainnet',
    key: 'liquityproxy',
    protocol: 'Liquity',
    protocolType: 'Lending',
    account: address,
    accountType,
    proxy: true,
    route: '/liquity/manage/smart-wallet',
    usdValueCollateral: proxy?.collateralUsd || 0,
    usdValueDebt: proxy?.debtUsd || 0,
    usdValueTotal: new Dec(proxy?.collateralUsd || 0).minus(proxy?.debtUsd || 0).toString(),
    usdTotalClaimable: proxyClaimLiqValue,
    isSubscribedToAutomation: !!activeStrategies.length,
    isEmpty: Dec(proxy?.collateralUsd || 0).lt(10) && Dec(proxyStakeLiqValue).lt(10) && Dec(proxyClaimLiqValue).lt(10),
    data: [
      {
        label: 'Liquity Trove', value: 'Smart Wallet', style: { gridArea: 'type' },
      },
      {
        label: 'Collateral', isNumber: true, value: proxy?.collateral, icon: getAssetInfo('ETH').icon, infoMessage: `$${numberWithCommas(proxy?.collateralUsd, 2)}`, style: { gridArea: 'coll' },
      },
      {
        label: 'Debt', isNumber: true, value: proxy?.debtInAsset, icon: getAssetInfo('LUSD').icon, infoMessage: `$${numberWithCommas(proxy?.debtUsd, 2)}`, style: { gridArea: 'debt' },
      },
      {
        label: 'Ratio', isNumber: true, value: proxy?.ratio, afterSymbol: '%', style: { gridArea: 'ratio' },
      },
      { label: 'Status', value: proxy?.troveStatus === 'nonExistent' ? 'N/A' : LIQUITY_STATUS_MAPPING[proxy?.troveStatus], style: { gridArea: 'other' } },
      {
        label: 'Staked', isNumber: true, value: proxyStakeLiqValue, beforeSymbol: '$', infoMessage: proxyStakeLiqLabel, style: { gridArea: 'stake' },
      },
      {
        label: 'Claimable', isNumber: true, value: proxyClaimLiqValue, beforeSymbol: '$', infoMessage: proxyClaimLiqLabel, style: { gridArea: 'claim' },
      },
    ],
  };

  return [liquityAccObject, liquityProxyObject];
};

const parseTokenURIFromUniV3 = (tokenURI) => {
  const searchString = ('"image": "');
  const indexStart = tokenURI.indexOf(searchString);
  const indexEnd = tokenURI.indexOf('"', indexStart + searchString.length);
  return tokenURI.substring(indexStart + searchString.length, indexEnd);
};

export const parseUniV3Data = (getState, account, proxyAddress, accountType, fetchedData, unknownTokens) => {
  if (!fetchedData) return [];

  const {
    assets,
  } = getState();
  const {
    account: accNftsUni,
    proxy: proxyNftsUni,
  } = fetchedData;
  const UniV3AccountPositions = accNftsUni.map(nft => {
    const firstToken = getAssetInfoByAddress(nft.tokenFirst).symbol === '?' ? unknownTokens.find(token => compareAddresses(token.address, nft.tokenFirst)) : getAssetInfoByAddress(nft.tokenFirst);
    const secondToken = getAssetInfoByAddress(nft.tokenSecond).symbol === '?' ? unknownTokens.find(token => compareAddresses(token.address, nft.tokenSecond)) : getAssetInfoByAddress(nft.tokenSecond);
    const useFirstTokenAsBase = Dec(nft.tickLowerPriceFirstToken || 0).gt(nft.tickLowerPriceSecondToken || 0);
    const rangeValue = useFirstTokenAsBase
      ? `${formatNumber(nft.tickLowerPriceFirstToken)} - ${formatNumber(nft.tickUpperPriceFirstToken)}`
      : `${formatNumber(nft.tickUpperPriceSecondToken)} - ${formatNumber(nft.tickLowerPriceSecondToken)}`;
    const rangeAfterSymbol = useFirstTokenAsBase ? `${secondToken.symbol} / ${firstToken.symbol}` : `${firstToken.symbol} / ${secondToken.symbol}`;
    const rangeInfoMessage = useFirstTokenAsBase
      ? `${formatNumber(nft.tickUpperPriceSecondToken)} - ${formatNumber(nft.tickLowerPriceSecondToken)} ${firstToken.symbol} / ${secondToken.symbol}`
      : `${formatNumber(nft.tickLowerPriceFirstToken)} - ${formatNumber(nft.tickUpperPriceFirstToken)} ${secondToken.symbol} / ${firstToken.symbol}`;
    const claimableValue = new Dec(new Dec(nft.rewardFirst || 0).mul(assets?.[firstToken.symbol]?.marketPrice || firstToken.price)).plus(new Dec(nft.rewardSecond || 0).mul(assets?.[secondToken.symbol]?.marketPrice || secondToken.price)).toString();
    const claimableInfoMessage = `${firstToken.symbol}: ${numberWithCommas(nft.rewardFirst)}\n ${secondToken.symbol}: ${numberWithCommas(nft.rewardSecond)}`;
    const usdValue = new Dec(new Dec(nft.amountInFirstToken || 0).mul(assets?.[firstToken.symbol]?.marketPrice || firstToken.price)).plus(new Dec(nft?.amountInSecondToken || 0).mul(assets[secondToken.symbol]?.marketPrice || secondToken.price)).toString();
    const imageString = parseTokenURIFromUniV3(nft.tokenURI);
    return ({
      network: 'mainnet',
      key: `univ3${nft.id}`,
      protocol: 'Uniswap V3',
      protocolType: 'LP',
      positionType: 'Uniswap V3 Pool',
      account,
      accountType,
      proxy: false,
      assets: [firstToken.symbol, secondToken.symbol],
      amounts: [nft.amountInFirstToken, nft.amountInSecondToken],
      usdValue,
      id: nft.id,
      additionalClasses: 'V3',
      usdValueCollateral: usdValue,
      usdValueDebt: '0',
      usdValueTotal: usdValue,
      usdTotalClaimable: claimableValue,
      isEmpty: Dec(usdValue).lt(10),
      imageString,
      additionalInfo: [
        {
          label: 'Claimable', isNumber: true, value: claimableValue, beforeSymbol: '$', infoMessage: claimableInfoMessage, style: { gridArea: 'claim' },
        },
        {
          label: 'Range', value: rangeValue, afterSymbol: rangeAfterSymbol, infoMessage: rangeInfoMessage, style: { gridArea: 'range' },
        },
      ],
    });
  });
  const UniV3ProxyPositions = proxyNftsUni.map(nft => {
    const firstToken = getAssetInfoByAddress(nft.tokenFirst).symbol === '?' ? unknownTokens.find(token => compareAddresses(token.address, nft.tokenFirst)) : getAssetInfoByAddress(nft.tokenFirst);
    const secondToken = getAssetInfoByAddress(nft.tokenSecond).symbol === '?' ? unknownTokens.find(token => compareAddresses(token.address, nft.tokenSecond)) : getAssetInfoByAddress(nft.tokenSecond);
    const useFirstTokenAsBase = Dec(nft.tickLowerPriceFirstToken || 0).gt(nft.tickLowerPriceSecondToken || 0);
    const rangeValue = useFirstTokenAsBase
      ? `${formatNumber(nft.tickLowerPriceFirstToken)} - ${formatNumber(nft.tickUpperPriceFirstToken)}`
      : `${formatNumber(nft.tickUpperPriceSecondToken)} - ${formatNumber(nft.tickLowerPriceSecondToken)}`;
    const rangeAfterSymbol = useFirstTokenAsBase ? `${secondToken.symbol} / ${firstToken.symbol}` : `${firstToken.symbol} / ${secondToken.symbol}`;
    const rangeInfoMessage = useFirstTokenAsBase
      ? `${formatNumber(nft.tickUpperPriceSecondToken)} - ${formatNumber(nft.tickLowerPriceSecondToken)} ${firstToken.symbol} / ${secondToken.symbol}`
      : `${formatNumber(nft.tickLowerPriceFirstToken)} - ${formatNumber(nft.tickUpperPriceFirstToken)} ${secondToken.symbol} / ${firstToken.symbol}`;
    const claimableValue = new Dec(new Dec(nft.rewardFirst || 0).mul(assets?.[firstToken.symbol]?.marketPrice || firstToken.price)).plus(new Dec(nft.rewardSecond || 0).mul(assets?.[secondToken.symbol]?.marketPrice || secondToken.price)).toString();
    const claimableInfoMessage = `${firstToken.symbol}: ${numberWithCommas(nft.rewardFirst)}\n ${secondToken.symbol}: ${numberWithCommas(nft.rewardSecond)}`;
    const usdValue = new Dec(new Dec(nft.amountInFirstToken || 0).mul(assets[firstToken.symbol].marketPrice || 0)).plus(new Dec(nft.amountInSecondToken || 0).mul(assets[secondToken.symbol].marketPrice || 0)).toString();
    const imageString = parseTokenURIFromUniV3(nft.tokenURI);
    return ({
      protocol: 'Uniswap V3',
      protocolType: 'LP',
      positionType: 'Uniswap V3 Pool',
      account,
      accountType,
      proxy: true,
      assets: [firstToken.symbol, secondToken.symbol],
      amounts: [nft.amountInFirstToken, nft.amountInSecondToken],
      additionalClasses: 'V3',
      id: nft.id,
      usdValue,
      usdValueCollateral: usdValue,
      usdValueDebt: '0',
      usdValueTotal: usdValue,
      usdTotalClaimable: claimableValue,
      isEmpty: Dec(usdValue).lt(10),
      imageString,
      additionalInfo: [
        {
          label: 'Claimable', isNumber: true, value: claimableValue, beforeSymbol: '$', infoMessage: claimableInfoMessage, style: { gridArea: 'claim' },
        },
        {
          label: 'Range', value: rangeValue, afterSymbol: rangeAfterSymbol, infoMessage: rangeInfoMessage, style: { gridArea: 'range' },
        },
      ],
    });
  });

  return [...UniV3AccountPositions, ...UniV3ProxyPositions];
};


const getCrvUsdValueForYearn = (assets, yearnPool, poolTokenInfo, tokenInfo, unknownTokens) => {
  const percentageOfPool = new Dec(yearnPool.userBalance).div(poolTokenInfo.totalSupply).mul(100).toString();
  console.log(tokenInfo);
  const tokenData = (tokenInfo.underlyingTokens?.length ? tokenInfo.underlyingTokens : tokenInfo.tokens).map(token => (getAssetInfoByAddress(token).symbol === '?' ?
    unknownTokens.find(unknownToken => unknownToken.address === token) : getAssetInfoByAddress(token)));
  const tokenDecimals = tokenInfo.underlyingDecimals.length ? tokenInfo.underlyingDecimals : tokenInfo.decimals;
  // Curve registy contract normalizes tokens from non-meta pools to 18 decimals
  const isNormalized = tokenInfo.tokens.some((token, i) => !compareAddresses(token, tokenInfo.underlyingTokens[i]));

  const amounts = (tokenInfo.underlyingBalances.length ? tokenInfo.underlyingBalances : tokenInfo.balances).map((reserve, i) => getEthAmountForDecimals(new Dec(reserve).mul(percentageOfPool).div(100).toString(), isNormalized ? 18 : tokenDecimals[i]));
  return amounts.reduce((acc, curr, index, self) => {
    const currentToken = tokenData[index].symbol;
    return new Dec(acc).plus(new Dec(curr).mul(assets?.[currentToken]?.marketPrice || tokenData[index].price)).toString();
  }, '0');
};

export const parseYearnData = (getState, account, proxyAddress, accountType, fetchedData, unknownTokens) => {
  if (!fetchedData) return [];

  const { assets } = getState();
  let nonCurveCounter = 0;
  return fetchedData.vaults.map((vault, i) => {
    const tokenInfo = unknownTokens.find(token => token.address === vault.address);
    const underlyingToken = unknownTokens.find(token => token.address === vault.token);
    const balance = getEthAmountForDecimals(tokenInfo.userBalance, tokenInfo.decimals);
    const isCurveUnderlying = underlyingToken.name.includes('Curve.fi');

    if (!isCurveUnderlying) nonCurveCounter++;
    const usdValue = underlyingToken
      ? (isCurveUnderlying
        ? getCrvUsdValueForYearn(assets, tokenInfo, underlyingToken, fetchedData.crvPoolsInfo[i - nonCurveCounter], unknownTokens)
        : new Dec(balance).mul(underlyingToken.price).toString()
      )
      : new Dec(balance).mul(assets?.[getAssetInfoByAddress(vault.token)]?.marketPrice || 0).toString();

    return {
      network: 'mainnet',
      protocol: 'Yearn',
      protocolType: 'Other',
      positionType: 'Yearn Vault',
      key: `YearnVault${i}`,
      wallet: 'account',
      account,
      accountType,
      usdValue,
      usdValueCollateral: usdValue,
      usdValueDebt: '0',
      usdValueTotal: usdValue,
      additionalClasses: 'AlignCenter',
      isEmpty: Dec(usdValue).lt(10),
      data: [
        { label: 'Yearn Vault', value: underlyingToken.name.replace('Curve.fi', ''), style: { gridArea: 'type' } },
        {
          label: 'Deposited', isNumber: true, value: balance, afterSymbol: underlyingToken.symbol.replace('-', ' '), infoMessage: `$${formatNumber(usdValue)}`, style: { gridArea: 'deposit' },
        },
      ],
    };
  });
};

export const parseSavingsData = (getState, account, proxyAddress, accountType, fetchedData) => {
  if (!fetchedData) return [];

  const activeStrategiesBundles = getState().strategies.subscribedStrategies.filter(i => i.isEnabled).map(i => i.bundle);

  return Object.values(fetchedData)
    .filter(({ supplied, initialized, apy }) => initialized && supplied && supplied > 1)
    .map(({
      apys, name, supplied, suppliedAsset, slug,
    }) => {
      let earningEstimates;
      const apr = apyToApr(apys?.[apys.length - 1]?.apy || 0).toString();
      if (apr === '0') {
        earningEstimates = {
          week: 'N/A',
          month: 'N/A',
          year: 'N/A',
        };
      } else {
        earningEstimates = {
          week: formatNumber(calculateInterestEarned(supplied, apr, 'week')),
          month: formatNumber(calculateInterestEarned(supplied, apr, 'month')),
          year: formatNumber(calculateInterestEarned(supplied, apr, 'year')),
        };
      }
      return {
        network: 'mainnet',
        protocol: name,
        protocolType: 'Other',
        key: `Savings${account}${slug}`,
        additionalClasses: 'Savings AlignCenter',
        account,
        accountType,
        proxy: true,
        isSubscribedToAutomation: activeStrategiesBundles.includes(slug.split('_')?.[0]),
        usdValueCollateral: supplied,
        usdValueDebt: '0',
        usdValueTotal: supplied,
        isEmpty: Dec(supplied).lt(1),
        route: '/smart-savings/manage/',
        compoundDeposited: 1,
        aaveDeposited: 2,
        dydxDeposited: 3,
        data: [
          { label: 'Type', value: name, style: { gridArea: 'type' } },
          {
            label: 'Deposited', isNumber: true, value: formatNumber(supplied), icon: getAssetInfo(suppliedAsset).icon, style: { gridArea: 'deposit' },
          },
          {
            label: 'Estimate', value: earningEstimates.week, icon: getAssetInfo(suppliedAsset).icon, style: { gridArea: 'estimate' }, infoMessage: `Weekly estimate: ${earningEstimates.week}\nMonthly estimate: ${earningEstimates.month}\nYearly estimate: ${earningEstimates.year}`,
          },
          {
            label: 'Current APY',
            value: !apys?.[apys.length - 1]?.apy ? 'N/A' : formatNumber(apys[apys.length - 1].apy),
            afterSymbol: !apys?.[apys.length - 1]?.apy ? '' : '%',
            style: { gridArea: 'apr' },
          },
        ],
      };
    });
};

export const parseUniV2Data = (getState, account, proxyAddress, accountType, fetchedData, unknownTokens) => {
  if (!fetchedData) return [];

  const { assets } = getState();
  return fetchedData.map((tokenInfo, i) => {
    const poolTokenInfo = unknownTokens.find(token => token.address === tokenInfo.address);
    const percentageOfPool = new Dec(poolTokenInfo.userBalance).div(poolTokenInfo.totalSupply).mul(100).toString();

    const tokenData = tokenInfo.tokens.map(token => (getAssetInfoByAddress(token).symbol === '?' ?
      unknownTokens.find(unknownToken => unknownToken.address === token) : getAssetInfoByAddress(token)));
    const amounts = tokenInfo.reserves.map((reserve, i) => getEthAmountForDecimals(new Dec(reserve).mul(percentageOfPool).div(100).toString(), tokenData[i].decimals));

    const usdValue = amounts.reduce((acc, curr, index) => {
      const currentToken = tokenData[index].symbol;
      return new Dec(acc).plus(new Dec(curr).mul(assets?.[currentToken]?.marketPrice || tokenData[index].price)).toString();
    }, '0');
    return {
      network: 'mainnet',
      protocol: 'Uniswap V2',
      protocolType: 'LP',
      positionType: 'Uniswap V2 Pool',
      key: `UniV2${i}${account}`,
      proxy: false,
      account,
      accountType,
      assets: tokenData.map(token => token.symbol),
      amounts,
      usdValue,
      usdValueCollateral: usdValue,
      usdValueDebt: '0',
      usdValueTotal: usdValue,
      isEmpty: Dec(usdValue).lt(10),
    };
  });
};

export const parseSushiData = (getState, account, proxyAddress, accountType, fetchedData, unknownTokens) => {
  if (!fetchedData) return [];

  const { assets } = getState();
  return fetchedData.map((tokenInfo, i) => {
    const poolTokenInfo = unknownTokens.find(token => token.address === tokenInfo.address);
    const percentageOfPool = new Dec(poolTokenInfo.userBalance).div(poolTokenInfo.totalSupply).mul(100).toString();
    const tokenData = tokenInfo.tokens.map(token => (getAssetInfoByAddress(token).symbol === '?' ?
      unknownTokens.find(unknownToken => unknownToken.address === token) : getAssetInfoByAddress(token)));
    const amounts = tokenInfo.reserves.map((reserve, i) => getEthAmountForDecimals(new Dec(reserve).mul(percentageOfPool).div(100).toString(), tokenData[i].decimals));
    const usdValue = amounts.reduce((acc, curr, index, self) => {
      const currentToken = tokenData[index].symbol;
      return new Dec(acc).plus(new Dec(curr).mul(assets?.[currentToken]?.marketPrice || tokenData[index].price)).toString();
    }, '0');
    return {
      network: 'mainnet',
      protocol: 'SushiSwap',
      protocolType: 'LP',
      positionType: 'SushiSwap Pool',
      key: `Sushi${i}${account}`,
      proxy: false,
      account,
      accountType,
      assets: tokenData.map(token => token.symbol),
      amounts,
      usdValue,
      usdValueCollateral: usdValue,
      usdValueDebt: '0',
      usdValueTotal: usdValue,
      isEmpty: Dec(usdValue).lt(10),
    };
  });
};

export const parseBalancerData = (getState, account, proxyAddress, accountType, fetchedData, unknownTokens) => {
  if (!fetchedData) return [];

  const { assets } = getState();
  return fetchedData.map((tokenInfo, i) => {
    const poolTokenInfo = unknownTokens.find(token => token.address === tokenInfo.address);
    const percentageOfPool = new Dec(poolTokenInfo.userBalance).div(poolTokenInfo.totalSupply).mul(100).toString();

    const tokenData = tokenInfo.tokens.map(token => (getAssetInfoByAddress(token).symbol === '?' ?
      unknownTokens.find(unknownToken => unknownToken.address === token) : getAssetInfoByAddress(token)));
    const amounts = tokenInfo.balances.map((reserve, i) => getEthAmountForDecimals(new Dec(reserve).mul(percentageOfPool).div(100).toString(), tokenData[i].decimals));
    const usdValue = amounts.reduce((acc, curr, index, self) => {
      const currentToken = tokenData[index].symbol;
      return new Dec(acc).plus(new Dec(curr).mul(assets?.[currentToken]?.marketPrice || tokenData[index].price)).toString();
    }, '0');
    return {
      network: 'mainnet',
      protocol: 'Balancer',
      protocolType: 'LP',
      positionType: 'Balancer Pool',
      key: `Balancer${i}${account}`,
      account,
      accountType,
      proxy: false,
      assets: tokenData.map(token => token.symbol),
      amounts,
      usdValue,
      usdValueCollateral: usdValue,
      usdValueDebt: '0',
      usdValueTotal: usdValue,
      isEmpty: Dec(usdValue).lt(10),
    };
  });
};

// TODO: parse as 'other' type?,
// TODO: need to get total supply and balance for token and then unwind that position to underlying tokens
export const parseCurveData = (getState, account, proxyAddress, accountType, fetchedData, unknownTokens) => {
  if (!fetchedData) return [];

  const { assets } = getState();

  return fetchedData.map((tokenInfo, i) => {
    const poolTokenInfo = unknownTokens.find(token => token.address === tokenInfo.address);
    const percentageOfPool = new Dec(poolTokenInfo.userBalance).div(poolTokenInfo.totalSupply).mul(100).toString();

    const isRenBtcPool = compareAddresses(tokenInfo.tokens[0], RENBTCAddress);
    const tokenData = (tokenInfo.underlyingTokens?.length ? tokenInfo.underlyingTokens : tokenInfo.tokens).map(token => (getAssetInfoByAddress(token).symbol === '?' ?
      unknownTokens.find(unknownToken => unknownToken.address === token) : getAssetInfoByAddress(token)));


    const tokenDecimals = tokenInfo.underlyingDecimals.length ? tokenInfo.underlyingDecimals : tokenInfo.decimals;
    if (isRenBtcPool) {
      tokenData.unshift(getAssetInfoByAddress(RENBTCAddress));
      tokenDecimals.unshift('8');
    }
    // Curve registy contract normalizes tokens from non-meta pools () to 18 decimals

    const isNormalized = tokenInfo.tokens.some((token, i) => !compareAddresses(token, tokenInfo.underlyingTokens[i]));
    const amounts = (tokenInfo.underlyingBalances.length ? tokenInfo.underlyingBalances : tokenInfo.balances).map((reserve, i) => getEthAmountForDecimals(new Dec(reserve).mul(percentageOfPool).div(100).toString(), isNormalized && !isRenBtcPool ? 18 : tokenDecimals[i]));

    const usdValue = amounts.reduce((acc, curr, index, self) => {
      const currentToken = tokenData[index].symbol;
      return new Dec(acc).plus(new Dec(curr).mul(assets?.[currentToken]?.marketPrice || tokenData[index].price)).toString();
    }, '0');
    return {
      network: 'mainnet',
      protocol: 'Curve',
      protocolType: 'LP',
      positionType: 'Curve Pool',
      key: `Curve${i}${account}`,
      account,
      accountType,
      proxy: false,
      assets: tokenData.map(token => token.symbol),
      amounts,
      usdValue,
      usdValueCollateral: usdValue,
      usdValueDebt: '0',
      usdValueTotal: usdValue,
      typeName: poolTokenInfo.name.replace('Curve.fi', '').replaceAll('/', ' / '),
      isEmpty: Dec(usdValue).lt(10),
    };
  });
};


export const withdrawTokenFromProxy = (accountType, sendTxFunc, proxyAddress, account, asset) => {
  const action = asset === 'WETH'
    ? new dfs.actions.basic.UnwrapEthAction(MAXUINT, account)
    : new dfs.actions.basic.SendTokenAction(getAssetInfo(asset).address, account, MAXUINT);
  return callActionViaProxy(accountType, sendTxFunc, proxyAddress, account, action);
};

export const getUnknownTokensInfo = async (account, tokens) => {
  const contract = await Erc20ViewContract();
  return contract.methods.batchInfo(tokens, account).call();
};
