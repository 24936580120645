import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ConnectWalletDropdown from '../Connect/ConnectWalletDropdown/ConnectWalletDropdown';
import withErrorFallback from '../ErrorFallback/ErrorFallback';
import { routeMeta } from '../../constants/general';

import './Header.scss';

const Header = ({ location }) => {
  const { name, icon, label } = routeMeta(location.pathname);
  return (
    <div className="header-wrapper">
      <div className="header-inner-wrapper">
        <div className="page-title">
          { icon && <img src={icon} alt="" className="route-icon" />}

          { name }

          { label && <span>{label}</span>}
        </div>

        <div className="actions-wrapper">
          <ConnectWalletDropdown />
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withErrorFallback(withRouter(Header));
