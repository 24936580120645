import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { openSimulationStartModal, openSimulationEndModal } from 'actions/modalActions';
import { ACCOUNT_TYPES } from '../../constants/general';
import Toggle from '../Toggle/Toggle';

import './ForkState.scss';

const ForkState = ({
  accountType, forkCreated, openSimulationStartModal, openSimulationEndModal,
}) => {
  const active = accountType === ACCOUNT_TYPES.fork;
  const timeDelta = (Date.now() - forkCreated) / 1000;
  const minutes = Math.ceil(timeDelta / 60);
  return (
    <div className={`fork-state-wrapper ${active ? 'active' : ''}`}>
      <div className="fork-toggle-wrapper">
        Simulation <Toggle value={active} onClick={() => (active ? openSimulationEndModal() : openSimulationStartModal())} readOnly />
      </div>
      <div className="fork-details">
        <div className="label">Fork started: </div>
        <div className="value">{minutes}m ago</div>
      </div>
    </div>
  );
};

ForkState.propTypes = {
  accountType: PropTypes.string.isRequired,
  forkCreated: PropTypes.number.isRequired,
  openSimulationStartModal: PropTypes.func.isRequired,
  openSimulationEndModal: PropTypes.func.isRequired,
};

ForkState.defaultProps = {};

const mapStateToProps = ({ general }) => ({
  accountType: general.accountType,
  forkCreated: general.forkCreated,
});

const mapDispatchToProps = {
  openSimulationStartModal,
  openSimulationEndModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForkState);
