import { assetAmountInEth, assetAmountInWei, getIlkInfo } from '@defisaver/tokens';
import Dec from 'decimal.js';
import { getGuniPoolContract } from './contractRegistryService';
import { getWeiAmountForDecimals } from './utils';

export const getMintAmounts = async (address, firstAmount, secondAmount) => {
  const contract = await getGuniPoolContract(address);
  return contract.methods.getMintAmounts(assetAmountInWei(firstAmount, 'DAI'), assetAmountInWei(secondAmount, 'USDC')).call();
};

export const getBurnAmounts = async (address, receiver, liquidity, join) => {
  const contract = await getGuniPoolContract(address);
  return contract.methods.burn(getWeiAmountForDecimals(liquidity, 18), receiver).call({ from: join || receiver });
};

export const getActualMintAmounts = async (poolAddress, inputAmount) => {
  // getMintAmounts returns max amount needed to mint
  // (one token amount is  inputAmount, other amount is less and exactly how much is needed)
  const mockAmounts = await getMintAmounts(poolAddress, inputAmount, inputAmount);
  // TODO: need tokens if another GUNI ilk is added
  const firstAmount = assetAmountInEth(mockAmounts.amount0, 'DAI');
  const secondAmount = assetAmountInEth(mockAmounts.amount1, 'USDC');
  // we get ratio of the amounts so we can calculate the amounts of first and second token to be first + second = inputAmount
  const usdcAmount = new Dec(new Dec(secondAmount).mul(inputAmount)).div(new Dec(firstAmount).plus(secondAmount)).toString();

  const daiAmount = new Dec(inputAmount).minus(usdcAmount).toString();

  return getMintAmounts(poolAddress, daiAmount, usdcAmount);
};
