/* eslint-disable */
import React from 'react';

export default function MakerProtocolIcon(props) {
  return (
    <svg width="27" height="14" viewBox="0 0 27 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M2.10416 13.0645V2.81188L10.3453 8.66358V13.0645H12.4487V8.25042C12.4483 8.07154 12.404 7.89517 12.3192 7.73517C12.2344 7.57517 12.1114 7.43589 11.96 7.32827L1.96562 0.229113C1.78282 0.101016 1.56595 0.0232487 1.33911 0.00445463C1.11228 -0.0143395 0.884358 0.0265754 0.680688 0.122652C0.477018 0.218729 0.305574 0.366205 0.185408 0.54869C0.0652427 0.731174 0.00106243 0.941521 0 1.15635V13.0645H2.10416ZM24.8958 13.0645V2.81188L16.6547 8.66358V13.0645H14.5513V8.25042C14.5517 8.07154 14.596 7.89517 14.6808 7.73517C14.7656 7.57517 14.8886 7.43589 15.04 7.32827L25.0344 0.232018C25.217 0.104063 25.4336 0.0263205 25.6601 0.00741176C25.8867 -0.011497 26.1144 0.0291665 26.3179 0.124893C26.5215 0.22062 26.693 0.36767 26.8134 0.549736C26.9338 0.731801 26.9984 0.941768 27 1.15635V13.0645H24.8958Z" fill="white"/>
    </svg>
  );
}
