import {
  YEARN_GET_NUM_VAULTS_REQUEST,
  YEARN_GET_NUM_VAULTS_SUCCESS,
  YEARN_GET_NUM_VAULTS_FAILURE,
} from 'actionTypes/yearnActionTypes';
import { yearnCollateralAssets } from 'constants/assets';
import { getNumOfYearnVaultsForAssets } from '../services/yearnServices/yearnService';

export const getNumOfYearnVaultsForAssetsAction = () => async (dispatch, getState) => {
  dispatch({ type: YEARN_GET_NUM_VAULTS_REQUEST });
  try {
    const numOfVaults = await getNumOfYearnVaultsForAssets();
    const numOfVaultsForAsset = yearnCollateralAssets.reduce((finalObj, curr, i) => ({ ...finalObj, [curr.symbol]: numOfVaults[i] }), {});
    const payload = { numOfVaults, numOfVaultsForAsset };
    dispatch({ type: YEARN_GET_NUM_VAULTS_SUCCESS, payload });
  } catch (err) {
    dispatch({ type: YEARN_GET_NUM_VAULTS_FAILURE, payload: err.message });
  }
};
