/* eslint-disable */

export default {
  common: {
    loading: 'Cargando...',
    loading_no_dots: 'Cargando',
    load: 'Cargar',
    loading_asset_data: 'Cargando información del activo...',
    loading_positions: 'Loading positions...',
    create: 'Crear',
    creating: 'Creando',
    generate: 'Generar',
    generating: 'Generando',
    connect: 'Conectar',
    connecting: 'Conectando',
    connected: 'Conectado',
    buy: 'Comprar',
    buying: 'Comprando',
    enable: 'Habilitar',
    enabling: 'Habilitando',
    exchange: 'Cambiar',
    exchanging: 'Cambiando',
    supply: 'Suministrar',
    supplying: 'Suministrando',
    borrow: 'Tomar Préstamo',
    borrowing: 'Tomando Préstamo',
    borrow_desc: 'Borrowing lets you deposit and borrow funds in one transaction. All borrowed funds are sent to your account.',
    supplied: 'Suministrado',
    withdraw: 'Retirar',
    withdrawing: 'Retirando',
    transfer: 'Transferir',
    transferring: 'Transfiriendo',
    boost: 'Aumentar',
    boosting: 'Aumentando',
    repay: 'Pagar',
    repaying: 'Repagando',
    repay_from: 'Repay from:',
    payback: 'Devolver',
    pay_back: 'Pay back',
    keep_ratio: 'Keep ratio',
    sending: 'Enviando',
    send: 'Enviar',
    sent: 'Sent',
    subscribe: 'Subscribirse',
    subscribing: 'Subscribiendo',
    paying_back: 'Devolviendo',
    add_collateral: 'Agregar colateral',
    adding_collateral: 'Agregando colateral',
    migrate: 'Migrar',
    migrating: 'Migrando',
    move: 'Mover',
    moving: 'Moviendo',
    after: 'Después',
    before: 'Before',
    manage: 'Administrar',
    success: '¡Operación completada con éxito!',
    coming_soon: '%feature disponible próximamente...',
    short: 'Short',
    leverage: 'Apalancar',
    leverage_desc: 'Leveraging lets you create a leveraged position in one transaction. All borrowed funds are used to buy more of the used collateral asset.',
    in_a_week: 'En una semana',
    in_a_month: 'En un mes',
    in_a_year: 'En un año',
    farm: 'Farm',
    farm_desc: 'Farming lets you create a same asset leveraged position that maximizes rewards received from the protocol.',
    price: 'Precio',
    collateral_factor: 'Factor de Colateral',
    collateral_factor_short: 'Fac. de Col.',
    market_liquidity: 'Liquidez del mercado',
    utilization: 'Utilización',
    utilization_short: 'Util.',
    utilization_desc: 'Percentage of market supply utilized by borrowers',
    market_size: 'Tamaño del mercado',
    market_size_short: 'T. del m.',
    market_info: 'Información del mercado',
    insured: 'Asegurado:',
    supply_rate: 'Tasa de suministro:',
    borrow_rate: 'Tasa de préstamo:',
    choose_asset: 'Elegir activo:',
    stability_fee: 'Tasa de Estabilidad',
    liquidation_price: 'Precio de Liquidación',
    liquidation_price_short: 'Liq price:',
    trade_size_impact: 'Impacto en el precio del tamaño de la operación',
    trade_size_impact_info: 'La diferencia entre el precio de mercado actual y el precio estimado debido al tamaño de la operación.',
    slippage_tolerance_info: 'Su transacción se revertirá si el precio cambia desfavorablemente por más de este porcentaje.',
    from_source_info: 'Choose from which balance should asset come',
    to_source_info: 'Choose to which balance should asset be sent',
    buying_asset_at: 'Comprando %asset a',
    action: 'Acción',
    overview: 'Visión general',
    ratio: 'Ratio',
    pages: 'Páginas:',
    choose: 'Seleccionar',
    claimable: 'Reclamable',
    debt: 'Deuda',
    account_type: 'Tipo de cuenta:',
    path: 'Recorrido:',
    minimum_amount: 'Cantidad mínima:',
    from_token: 'De %token',
    to_token: 'A %token',
    to: 'A',
    from: 'De',
    amount: 'Cantidad',
    name: 'Nombre',
    balance: 'Balance',
    current_balance: 'Current balance',
    approve: 'Aprobar',
    approving: 'Aprobando',
    approved: 'Aprobado',
    remove_approval: 'Eliminar aprobación',
    removing_approval_on: 'Eliminando aprobación de',
    close: 'Cerrar',
    closing: 'Cerrando',
    logging_in: 'Iniciando sesión...',
    safe: 'Seguro',
    risky: 'Riesgoso',
    gamble: 'Arriesgar',
    amount_of_token: 'Cantidad de %token:',
    token_amount_in: 'Cantidad de %first_token en %second_token:',
    executing_action: 'Ejecutando acción',
    new_owner: 'Nuevo dueño',
    contact_us: 'Contacto',
    with: 'con',
    at_price: 'precio',
    automate: 'Automatizar',
    reminder: 'Recordatorio',
    info: 'Inforomación',
    legacy: 'Antiguo',
    live: 'En vivo',
    custom_type: 'tipo personalizado',
    max: 'max',
    min: 'min',
    max_val: 'max %value',
    min_val: 'min %value',
    email: 'Email',
    email_address: 'Dirección de Email',
    follow_us: 'Síganos:',
    protocols: 'Protocolos',
    wallets: 'Billeteras',
    repay_to: 'Pagar a',
    ratio_over: 'Ratio sobre',
    boost_to: 'Aumentar a',
    current_price: 'Precio actual',
    collateral: 'Colateral',
    collateral_in: 'Colateral en',
    set_up: 'Crear',
    how_does_it_work: 'Cómo funciona?',
    history: 'Historial',
    type: 'Tipo',
    date: 'Fecha',
    transaction: 'Transacción',
    load_more: 'Cargar más',
    protection: 'Protección',
    update: 'Actualizar',
    updating: 'Actualizando',
    disable: 'Desactivar',
    disabling: 'Desactivando',
    advanced: 'Avanzado',
    cancel: 'Cancelar',
    message: 'Mensaje',
    accept: 'Aceptar',
    import: 'Importar',
    importing: 'Importando',
    soon: 'próximamente',
    no_notifications_received: 'No hay notificaciones.',
    go_back_to_dashboard: 'Regresar al tablero',
    back_to_dashboard: 'Back to dashboard',
    your_feedback_helps_us: 'Sus comentarios nos ayudan a mejorar DeFi Saver.',
    create_dsproxy: 'Crear DSProxy',
    for: 'para',
    account: 'cuenta',
    ds_proxy: 'DSProxy',
    upgrade: 'Acutalizar',
    upgrading: 'Actualizando',
    migration: 'Migración',
    your_asset_balance: 'Su balance de %asset',
    ratio_after: 'Ratio después',
    convert: 'Convertir',
    converting: 'Convirtiendo',
    back: 'Anterior',
    next: 'Siguiente',
    borrowed: 'Tomado prestado',
    select: 'Seleccionar',
    selected: 'Seleccionado',
    subscribed: 'Suscrito',
    confirm: 'Confirmar',
    continue: 'Continuar',
    next_price: 'Próximo Precio',
    next_price_explanation: 'MakerDAO se actualizará con este precio después de %timestamp \nPróximo precio: $%price\nPróxima ratio: %ratio%',
    settings: 'Ajustes',
    required: 'Requerido',
    received: 'Recibido',
    exposure: 'Exposición',
    borrowed_asset: '%token tomado prestado',
    set_up_later: 'Configurar mas tarde',
    claim: 'Reclamar',
    claiming: 'Reclamando',
    bid: 'Ofertar',
    bidding: 'Ofertando',
    insufficient_balance: 'Saldo insuficiente',
    empty: 'Vacío',
    learn_more: 'Leer más',
    warning: 'Advertencia',
    token_swap: 'Intercambio de Tokens',
    asset: 'Activo',
    earned: 'Ganado',
    limit: 'Límite',
    total: 'Total',
    supply_apy: 'APY por suministrar',
    comp_supply_apy: 'COMP por suministrar',
    supply_apy_short: 'APY p. s.',
    borrow_apy:  'APY por pedir prestado',
    comp_borrow_apy:  'COMP APY por pedir prestado',
    borrow_apy_short:  'APY p. p.p.',
    stats: 'Estadísticas',
    address: 'Dirección',
    in_total: 'En total',
    contract: 'contrato',
    notifications: 'Notificaciones',
    safety_ratio: 'Ratio de seguridad',
    safety_ratio_desc: 'La ratio de su límite de préstamo frente a préstamos tomados. \nLiquidación ocurre por debajo de liqPercent%.',
    deposit: 'Deposit',
    automation: 'Automatización',
    wallet_type: 'Tipo de Billetera',
    account_capital: 'Cuenta',
    enabled: 'Habilitado',
    disabled: 'Deshabilitado',
    total_balance: 'Balance total',
    unavailable: 'No disponible',
    large_tsi_warning: 'Obtendrá un precio desfavorable debido al tamaño de la operación (%tsi% por debajo del precio de mercado). Considere un monto más pequeño.',
    large_tsi_warning_exact_amount: 'Obtendrá un precio desfavorable debido al tamaño de la operación (%tsi% por debajo del precio de mercado). ',
    accrued: 'Acumulado',
    protocol: 'Protocol',
    swap: 'Swap',
    swapping: 'Swapping',
    leveraging: 'Leveraging',
    liquidation: 'Liquidation',
    locked: 'Locked',
    unlocked: 'Unlocked',
    unlock: 'Unlock',
    liquidation_price_desc: 'Collateral price below which your position will face liquidation.',
    leveraged_long_position_desc: 'Your position is currently long %asset and will benefit from %asset going up in value.',
    leveraged_short_position_desc: 'Your position is currently short %asset and will benefit from %asset going down in value.',
    create_and_migrate: 'Create and migrate',
    received_asset: 'Received %asset',
    debt_available_from: '%debtAsset dispinible del %asset',
    debt_available_from_desc: 'Cantidad de %debtAsset disponible para ser generado a partir del tipo de colateral utilizado de acuerdo con el techo de deuda del protocolo global',
    add_liquidity: 'Add liquidity',
    cancel_transaction: 'Cancel',
    contract_creation: 'Create contract',
    contract_execution: 'Execute contract',
    send_eth: 'Sent',
    receive_eth: 'Received',
    remove_liquidity: 'Remove liquidity',
    mint: 'Mint',
    token_transfer: 'Token transfer',
    transfers: 'Transfers',
    receiver: 'Receiver',
    tx_hash: 'Tx hash',
    tx_fee: 'Tx fee',
    nonce: 'Nonce',
    block: 'Block',
    burn: 'Burn',
    emergency: 'Emergency',
    stake: 'Stake',
    staking: 'Staking',
    unstaking: 'Unstaking',
    swap_and_burn: 'Swap and burn',
    vote: 'Vote',
    add: 'Add',
    add_pool: 'Add pool',
    allow: 'Allow',
    collect: 'Collect',
    finalize: 'Finalize',
    join_pool: 'Join pool',
    delegate: 'Delegate',
    execute: 'Execute',
    execute_recipe: 'Execute recipe',
    propose: 'Propose',
    queue: 'Queue',
    trade: 'Trade',
    transfer_from: 'Transfer from',
    init: 'Initialize',
    lock: 'Lock',
    open: 'Open',
    wipe: 'Wipe',
    exit: 'Exit',
    deny: 'Deny',
    batch: 'Batch',
    sell: 'Sell',
    sold: 'Sold',
    claim_and_sell: 'Claim and sell',
    remove: 'Remove',
    redeem: 'Redeem',
    redeeming: 'Redeeming',
    shift: 'Shift',
    unsubscribe: 'Unsubscribe',
    stop: 'Stop',
    set_owner: 'Set owner',
    set_name: 'Set name',
    set_authority: 'Set authority',
    push: 'Push',
    start: 'Start',
    pull: 'Pull',
    set_cache: 'Set cache',
    enter: 'Enter',
    draw: 'Draw',
    give: 'Give',
    long_loading: 'This might take a while',
    error: 'Error',
    welcome: 'Welcome',
    defi_saver: 'DeFi Saver',
    share: 'Share',
    docs: 'Docs',
    simulation: 'Simulation',
    deadline_info: 'The transaction will revert if not confirmed within selected deadline',
    unstake: 'Unstake',
    staked: 'Staked',
    authorize_dsproxy:'Authorize DSProxy',
    migrate_position:'Migrate position',
    manage_wallets: 'Manage Wallets',
    additional_info: 'Additional info',
    with_swap_rate: 'at rate',
    from_account: 'from account',
    to_account: 'to account',
    depositing: 'Depositing',
    repay_details: 'Repay details',
    boost_details: 'Boost details',
    deadline: 'Deadline',
    and: 'and',
    you_pay: 'You pay',
    you_receive: 'You receive',
    import_token: 'Import token',
    no_token: 'No token found',
    sending_funds: 'Sending funds',
    sending_to: 'Sending to',
    summary:'Summary',
    positions:'Positions',
    token:'Token',
    tokens:'Tokens',
    complexity:'Complexity',
    day:'day',
    days:'days',
    low:'Low',
    medium:'Medium',
    high:'High',
    actions:'Actions',
    liquidation_protection: 'Protección de liquidación:',
    if_below: 'Si es inferior a',
    leverage_increase: 'Aumento de apalancamiento:',
    if_above: 'Si es superior a',
    position_info: 'Position Info',
    token_approvals:'Token approvals',
    min_debt:'Min. debt',
    debt_ceiling:'Debt ceiling',
    liquidation_fee:'Liquidation fee',
    zero_fee_notice: 'No fee is taken by DeFi Saver for this action.',
    bridge: 'Bridge',
    bridging: 'Bridging',
    you_send: 'You send',
    filters: 'Filters',
  },
  account: {
    create_position: 'All positions at DeFi Saver are created on a Smart Wallet in order to enable advanced features. %missing_sw',
    missing_sw: 'You currently don\'t have a Smart Wallet and the first, required step in the process will be to create one. ',
    connect_wallet: 'Conecte su billetera',
    connect_message: 'Conectar %item',
    secure_way: 'Esta es una manera segura de conectar su billetera',
    account_connected: 'Esta cuenta ya está conectada',
    load_accounts: '%status más cuentas',
    user_wallet: 'Smart Wallet',
    savings_user_wallet_info: 'Los fondos utilizados dentro de Smart Savings están controlados por su Smart Wallet personal, billetera MakerDAO DSProxy estándar utilizada para integración fácil con protocolos de préstamos.',
    savings_user_wallet_info_2: 'Debido al uso de Smart Wallet (DSProxy), sus fondos no serán visibles en otro tableros (por ejemplo, en Compound).',
    compound_user_wallet_info: 'El uso de Compound a través de su Smart Wallet le permite ejecutar operaciones avanzadas, como acciones basadas en Boost, Repay y Flash Loan. Smart Wallet es una billetera MakerDAO DSProxy estándar utilizada para integración fácil con protocolos de préstamos.',
    compound_user_wallet_info_2: 'Estos fondos no serán visibles en el tablero de Compound.',
    wallet_info_learn_more: 'Obtenga más información sobre Smart Wallet',
    user_wallet_address_info: 'No envíe fondos directamente a esta dirección.',
    create_user_wallet: 'Crear Smart Wallet',
    create_user_wallet_to_continue: 'Create Smart Wallet to continue',
    creating_user_wallet: 'Creando Smart wallet',
    user_wallet_information: 'Información sobre Smart Wallet',
    create_ds_proxy: 'Crear DSProxy',
    account_found: 'cuenta %type conectada a %account',
    monitor_account_modal:'Simplemente ingrese su dirección para para realizar seguimiento de sus activos y su cartera financiera descentralizada sin conectar su billetera.',
    address_or_ens: 'Dirección Ethereum o ENS',
    use_demo_address: 'Usar dirección de muestra',
    demo_address: 'test.defisaver.eth',
    enter_address_to_monitor: 'O ingrese su dirección para monitorizar su cartera',
    debugging_account: 'Cuenta de depuración cargada',
    monitoring_account: 'Cuenta cargada para monitoreo de cartera',
    your_smart_wallet: 'Su Smart Wallet',
    switch_to_smart_wallet: 'Cambiar a Smart Wallet',
    migration_unavailable: 'Migración no disponible',
    user_wallet_description_blocker: 'Para utilizar nuestras funciones avanzadas de %protocol, primero debe crear una Smart Wallet, su billetera personal de contrato inteligente que permitirá el uso de funciones avanzadas. Tenga en cuenta que al usar Smart Wallet pierde compatibilidad con otras aplicaciones (por ejemplo, la aplicación predeterminada de %protocol).',
    repay_remainder_returned: 'Remainder of collateral will be returned as %token',
    repay_remainder_returned_desc: 'If debt is fully paid off by Repay, the remainder of the Repay amount will be returned to your wallet as %token.',
    repay_action_item_desc: 'Withdraw %supply → Swap → Payback %debt',
    boost_action_item_desc: 'Borrow %debt → Swap → Supply %supply',
    repay_action_item_same_asset_desc: 'Withdraw %asset → Payback %asset',
    boost_action_item_same_asset_desc: 'Borrow %asset → Supply %asset',
    smart_wallet_create_modal_info1:'Some of our advanced features include interactions with multiple smart contracts in one transaction, which is not possible when using a standard Ethereum account.',
    smart_wallet_create_modal_info2:'Smart Wallet is your personal smart contract proxy wallet that holds your funds and makes these advanced features possible.',
  },
  maker: {
    create_cdp: 'Crear CDP',
    loading_cdp: 'Cargando CDP...',
    cdp_id: 'ID del CDP actual',
    cdp_lower_bound: 'Si por debajo de %lower_bound% pagar a %lowerbound_target%',
    cdp_upper_bound: 'Si por encima de %upper_bound% aumentar a %upperbound_target%',
    no_cdp_found: 'CDP no encontrado',
    close_cdp: 'Cerrar CDP',
    transfer_cdp: 'Transferir CDP',
    migrate_cdp: 'Migrar CDP',
    transfer_cdp_description: 'La transferencia de su CDP lo moverá de su dirección actual a la dirección seleccionada. La dirección actual no tendrá derechos de propiedad o administración una vez que se complete la transferencia.',
    transfer_cdp_automation: 'Tenga en cuenta que deberá volver a habilitar la Automatización desde la nueva dirección después de transferir.',
    close_cdp_automation: 'Esta acción deshabilitará su configuración de Automatización. Si decide volver a utilizar este CDP, deberá volver a habilitar la automatización.',
    create_cdp_description: 'Creating a CDP allows you to generate DAI by depositing one of supported collateral types',
    transfer_cdp_warning: 'Once completed this action cannot be undone. Please manually check the entered data:',
    minimum_ratio: 'La ratio de colateralización mínima aceptada es %min_ratio. \nRecomendamos una ratio superior a %suggested_ratio Para mantener el CDP seguro contra liquidación',
    ratio_warning: 'Su ratio de colateralización debe ser superior a minRatio',
    tsi_too_high: 'Impacto del tamaño de la operación demasiado alto',
    generate_description: 'Generar más %daiLabel desde su CDP',
    withdraw_description: 'Retirar %asset colateral de su CDP',
    boost_description: 'Genera %daiLabel para comprar %asset y agregarlo a su CDP en una transacción.',
    boost_modal_description: 'Boost genera %daiLabel desde su CDP para obtener más %asset. Esto aumenta su apalancamiento a costa de aumentar su precio de liquidación y disminuir su ratio de colateralización.',
    payback_description: 'Pagar la deuda en %daiLabel de su CDP',
    add_collateral_description: 'Agregar más %asset de colateral a su CDP',
    repay_description: 'Toma %asset para comprar %daiLabel y pagar la deuda en una transacción.',
    repay_modal_description: 'Repay usa %asset de su CDP para pagar la deuda en %daiLabel. Esto disminuye su precio de liquidación y aumenta su ratio de colateralización.',
    all_debt_repay: 'Toda la deuda será pagada y se le enviará el DAI adicional',
    migrate_description: 'La migración le permitirá interactuar con la plataforma DeFi Saver. Al migrar, usted utilizará la interfaz estándar de MakerDAO DSProxy y también podrá interactuar con otras plataformas de CDP.',
    saver_subscribe: 'Habilitando automatización de CDP',
    saver_unsubscribe: 'Deshabilitando automatización de CDP',
    no_cdp_blocker: 'There doesn\'t seem to be a MakerDAO CDP (Vault) associated with this account. You can create a MakerDAO CDP now to borrow DAi or leverage assets using our signature Boost and Repay features.',
    cdp_automation: 'Automatización de CDP',
    cdp_automation_description: 'Mantiene su CDP en una ratio de colateralización determinada para protegerlo de liquidación o aumenta el apalancamiento en función de los cambios del mercado.',
    no_actions_executed: 'Aún no se han ejecutado acciones.',
    cdp_automation_status: 'Estado de automatización del CDP',
    keep_ratio_at: 'Mantener la ratio a:',
    if_ratio_bellow: 'Si la ratio cae a menos de:',
    repay_it_to: 'Pagar hasta:',
    will_hit_ratio_at: 'Llegará a esta ratio en %value',
    if_ratio_goes_over: 'Si la ratio supera:',
    boost_it_to: 'Aumentarlo a:',
    automation_boost_disclaimer: 'El Boost automatizado solo se realizará si la tarifa de transacción es inferior al 10% del monto del Boost.',
    approx_sold_for: 'Se venderá aproximadamente %sold por %bought',
    cdp_automation_setup: 'Configuración de automatización de CDP',
    cdp_automation_setup_description: 'Una vez habilitado, DeFi Saver controlará la realción de colateralización de su CDP y activará automáticamente Repay si su CDP alcanza el límite inferior configurado, o Boost si alcanza el superior. Simplemente ingrese su ratio de colateralización deseada o configure manualmente a continuación.',
    loading_saver_data: 'Cargando datos de automatización...',
    automatic_boost_will_be_triggered: 'se activará el Boost automático al habilitar la automatización con los parámetros actuales',
    automatic_repay_will_be_triggered: 'se activará el Repay automático al habilitar la automatización con los parámetros actuales',
    automation_form_beta_warning: 'Esta característica está en fase beta. Úselo bajo su propio riesgo.',
    import_description: 'El uso de esta herramienta migrará su CDP de la billetera de InstaDApp al estándar Maker DSProxy. \n\n' +
      'Al migrar, obtiene acceso a nuestras funciones avanzadas como Automatización, Boost y Repay, así como otros paneles como al Oasis app de Maker y a Zerion.\n\n' +
      'Una vez que migre su CDP a un DSProxy, no podrá volver a usar InstaDApp a menos que vuelva a migrar a su billetera.',
    import_cdp: 'Importar CDP',
    migrating_cdp_from_instadapp: 'Migrando CDP desde InstaDApp',
    cdp_managed_by_instadapp: 'Este CDP está actualmente en una billetera de InstaDApp.',
    why_disabling_automation: '¿Puede decirnos por qué está deshabilitando la automatización?',
    saver_unsubscribe_reason_1: 'Quiero hacer un ajuste fuera de mi configuración actual de Automatización.',
    saver_unsubscribe_reason_5: 'Otro',
    saver_unsubscribe_reason_6: 'I\'m closing my position',
    saver_unsubscribe_reason_7: 'I\'m moving funds to a different protocol',
    saver_unsubscribe_reason_8: 'I have questions about the Automation system',
    liquidation_protection_if_bellow: 'Protección de liquidación: si es inferior a',
    leverage_increase_if_above: 'Aumento de apalancamiento: si es superior a',
    liquidation_ratio: 'Ratio de liquidación',
    min_ratio: 'Ratio mínima',
    ratio_not_safe: 'La ratio no sería segura después de esta acción',
    cdp_protected: 'Automatización habilitada y activa.',
    cdp_not_protected: 'Habilite la automatización para protección contra liquidación y apalancamiento automático',
    stability_fee_explanation: 'La tasa de préstamo anual para CDP, calculada en función de su deuda CDP pendiente y agregada continuamente a su deuda actual. La tasa es variable y votada por la gobernanza de Maker.',
    liquidation_ratio_explanation: 'La ratio de colateralización mínima en MakerDAO. Los CDPs que caen debajo de esta ratio se enfrentará a liquidación.',
    price_explanation: 'El precio actual en el protocolo MakerDAO.',
    buying_asset_at_explanation: 'se tomarán prestados DAI del CDP y se utilizarán para comprar más colateral a este precio',
    cdp_is_empty: 'el CDP está vacío',
    cdp_is_empty_desc: 'Este CDP está actualmente vacío y no tiene colateral ni deuda. Puede comenzar a usarlo nuevamente en cualquier momento agregando colateral.',
    close_mcd_cdp_description: 'El cierre de su posición de deuda colateralizada pagará la deuda actual utilizando el colateral depositado y retirará el colateral sobrante de su cuenta.',
    total_coll_minus_dai_debt: 'Colateral total - valor de la deuda en DAI:',
    dai_debt_value: 'vlor de la deuda en DAI:',
    minimum_accepted_asset_closing: 'Cantidad mínima aceptada de %asset después del cierre:',
    maximum_loss_closing: 'Pérdida máxima aceptada después del cierre:',
    close_fee: '(incluye tarifa de servicio de 0.25%)',
    close_fee_no_brackets: 'Includes 0.25% service fee',
    mcd_close_can_repay: 'Actualmente tiene suficiente colateral en su CDP para pagar la deuda en su totalidad utilizando nuestra función Repay. Repay es la opción óptima cuando corresponde, ya que incluye menos interacciones con diferentes protocolos y requiere menos tarifas, lo que genera mayores ganancias.',
    collateral_in_usd: 'Colateral en USD',
    coll_ratio: 'Ratio de Col.',
    received_dai: 'DAI recibido',
    leveraged: 'Apalancado',
    leveraged_desc: 'All generated DAI will be used to buy more ETH which will be added to your CDP for increased leverage.',
    value_invested: 'Valor invertido',
    value_invested_desc: 'Total de colateral añadido + DAI devuelto',
    value_withdrawn: 'Valor retirado',
    value_withdrawn_desc: 'Total DAI generateddo + colateral retirado',
    lifetime_profit: 'Ganancias Totales',
    balance_desc: 'Valor actual del colateral - deuda',
    lifetime_profit_desc: 'Balance del CDP + valor retirado - valor invertido',
    repay_remainder_returned: 'El colateral sobrante se devolverá en DAI',
    repay_remainder_returned_desc: 'Si cancela la deuda en su totalidad vía Repay, el sobrante se devolverá a su billetera como DAI.',
    cdp_created_successfully: 'CDP cdpId creado correctamente',
    cdp_created_successfully_desc: 'Sugerimos habilitar la protección contra liquidación para su posición utilizando nuestro sistema de automatización de CDP.\nTambién puede hacer esto en cualquier momento en el futuro.',
    unclaimed_collateral: '%amount No reclamado',
    unclaimed_collateral_desc: 'Tiene colateral no reclamado. Lo más probable es que esto haya sucedido debido a la liquidación de su CDP. Reclámelo para devolverlo a su CDP y así esté disponible para retiro.',
    close_auction_desc: 'Es el ganador de esta subasta. Cierre la subasta para reclamar sus ganancias.',
    close_auction: 'Cerrar Subasta',
    close_auction_dai: 'Cerrar y convertir a DAI',
    vat_dai_balance: 'Tiene %amount DAI No utilizado de ofertas anteriores.\nSe utilizará para futuras ofertas. Puede retirarlo en cualquier momento.',
    vat_coll_balance: 'Tiene un saldo de colateral en VAT. Esto puede suceder si otra persona cierra una oferta por usted. \nPuede retirar este saldo en cualquier momento.',
    bid_on_liq: 'Ofertar en subasta de liquidación',
    liq_auction_warning: 'Esta función está en versión beta y se proporciona sin garantía de ganancias. Úselo bajo su propio riesgo.',
    automation_boost_subheader: 'Aumentar cuando el precio suba',
    automation_repay_subheader: 'Pagar cuando el precio baje',
    automation_unsupported_asset: 'Automatización actualmente es compatible con %assets y CDPs de %last_asset.',
    strategy_unsupported_asset: 'This automated strategy is currently supported for %assets and %last_asset CDPs.',
    vaults_subbed: 'CDPs suscritas:',
    vault_id: 'ID de la CDP',
    close_to: 'Close to',
    leveraged_create_warning_1: 'The generated DAI will not be added to your wallet - they will be used to buy additional collateral for your CDP to increase leverage.',
    leveraged_create_warning_2: 'If you\'re not sure what the difference between a standard and leveraged CDP is, please see',
    leveraged_create_warning_3: 'this article',
    repay_withdraw_hint: 'Withdrawing %asset to pay back debt?\nDo this quicker using Repay!',
    repay_payback_hint: 'Not enough %asset to pay back debt?\nPay back debt with collateral using Repay.',
    boost_borrow_hint: 'Borrowing %asset to leverage more?\nDo this quicker using Boost!',
    cdp_history: 'CDP #%id History',
    dash_boost_collateral: 'Add %collAsset from your account to make the position safer, then generate %debtAsset to buy %collAsset and add it to the CDP.',
    dash_boost_payback: 'Pay back debt using %debtAsset from your account, then generate %debtAsset to buy %collAsset and add it to the CDP.',
    dash_repay_withdraw: 'Take out %collAsset to buy %debtAsset and pay back debt, while also withdrawing some of the remaining collateral to your account.',
    dash_repay_generate: 'Take out %collAsset to buy %debtAsset and pay back debt, while also generating more %debtAsset to your account.',
    dash_collateral_generate: 'Add more %collAsset to your CDP and generate %debtAsset to your account.',
    dash_collateral_boost: 'Add more %collAsset from your account and apply leverage boost to the same, previous ratio.',
    dash_withdraw_payback: 'Pay back part of your %debtAsset debt and withdraw %collAsset collateral to your account.',
    dash_withdraw_repay: 'Deleverage your position and withdraw %collAsset collateral to your account, while keeping the same, previous ratio.',
    dash_withdraw_send: 'Withdraw %collAsset collateral and send it to another account',
    dash_generate_collateral: 'Add more %collAsset collateral and generate more %debtAsset to your account.',
    dash_generate_repay: 'Deleverage your position and generate more %debtAsset to your account, while keeping the same, previous ratio.',
    dash_generate_send: 'Generate %debtAsset and send it to another account',
    dash_payback_withdraw: 'Pay back debt using %debtAsset from your account and withdraw %collAsset.',
    dash_payback_boost: 'Pay back debt using %debtAsset from your account and apply boost to keep the same, previous ratio.',
    crop_cdp_already_exists: 'A %ilk CDP (%cdpId) already exists. There can only be a single CDP of %ilk type per account',
    advanced_create: 'Your %asset will be deposited into the %pool pool to receive %lptoken.',
    advanced_create_wsteth_steth: 'Your %asset will be wrapped into %lptoken.',
    advanced_create_wsteth_other: 'Your %asset will be staked with Lido and wrapped into %lptoken.',
    min_debt_explanation:'Minimum amount of debt that this vault type has to maintain. This value (also known as "dust") is managed by MakerDAO governance and positions below the minimum debt will have limited management options.',
    debt_ceiling_explanation:'Maximum amount of DAI that can be generated from a specific vault type (and currently utilized percentage).',
    liquidation_fee_explanation:'A liquidation penalty applied to your debt amount in case of liquidation.',
  },
  maker_actions: {
    'add-collateral': 'Agrager Colateral',
    'create': 'Crear',
    'withdraw-collateral': 'Retirar Colateral',
    'generate-dai': 'Generar DAI',
    'payback-dai': 'Pagar DAI',
    'add-collateral-draw-dai': 'Agregar & Generar',
    'payback-dai-withdraw-collateral': 'Pagar & Retirar',
    'boost': 'Aumentar',
    'repay': 'Pagar',
    'liquidation': 'Liquidación',
    'close': 'Close',
    'borrow_supply': 'Borrow & Supply',
    'withdraw_payback': 'Withdraw & Pay Back',
    'transfer': 'Transfer',
    'payback': 'Devolver',
  },
  compound: {
    supply_assets_info: 'Suministre sus activos para comenzar a ganar intereses',
    supply_rate_description: 'Porcentaje de interés anual sobre los fondos suministrados. Cambios basados en la oferta y la demanda del mercado.',
    withdraw_info: 'Retirar activos desde su suinistro en Compound',
    borrow_info: 'Tomar préstamo en Compound. Necesita proporcionar activos como garantía antes de pedir prestado.',
    interest_estimate: 'Interés estimado:',
    interest_estimate_info: 'Esta es una estimación basada en las tasas de interés actuales.',
    borrow_limit: 'Límite de Préstamo',
    net_apy: 'APY neto',
    supply_balance: 'Balance de suministro',
    borrow_balance: 'Balance de préstamos tomados',
    left_to_borrow: 'Disponible para pedir prestado',
    boost_description: 'Increase leverage by borrowing, swapping to collateral asset and depositing to your position in a single transaction.',
    repay_description: 'Deleverage by withdrawing collateral, swapping to debt asset and paying back debt in a single transaction.',
    boost_modal_description: 'Boost toma prestado más %asset1 para obtener %asset2. Esto aumenta su apalancamiento a costa de aumentar su poder de préstamo utilizado.',
    repay_modal_description: 'Repay usa su %asset2 suministrado para pagar la deuda en %asset1. Esto disminuye su apalancamiento, pero también disminuye su poder de préstamo utilizado y hace que su posición sea más segura..',
    proxy_required_click_to_migrate: 'Se requiere una Smart Wallet para acciones avanzadas.',
    borrow_power_used: 'Poder de préstamo usado',
    migrating_to_proxy: 'Migrando a proxy',
    supply_balance_desc: 'La suma del colateral suministrado.',
    borrow_balance_desc: 'La suma de sus préstamos tomados.',
    borrow_limit_desc: 'El valor máximo de los fondos que puede pedir prestado.',
    payback_info: 'Pague el activo que tomó prestado.',
    collateral_required: 'Se requiere colateral',
    collateral_required_desc: 'Este activo es necesario para respaldar sus préstamos tomados. Deshabilitar este activo como colateral causará que su posición sea liquidada. Pague sus préstamos o proporcione un activo diferente como colateral antes de deshabilitar este.',
    enable_collateral: 'Activar %asset como colateral',
    disable_collateral: 'Desactivar %asset como colateral',
    comp_tokens: 'COMP',
    comp_tooltip: 'Retirar los tokens COMP ganados',
    comp_modal_desc: 'COMP tokens are automatically rewarded to all users of the Compound protocol.',
    comp_estimate_title_account: 'COMP rewards estimate for your account:',
    comp_estimate_title_smart_wallet: 'COMP rewards estimate for smart wallet:',
    warning_borrowing_supplied: 'Está tomando prestado un activo que ya suministró. ¿Está seguro de que esto es lo que quiere hacer?',
    warning_supplying_borrowed: 'Está suministrando un activo que ya pidió prestado. ¿Está seguro de que esto es lo que quiere hacer?',
    loading_compound_saver_data: 'Cargando los datos de automatización de Compound...',
    compound_automation: 'Automatización de Compound',
    compound_automation_description: 'Mantiene su Compound en una ratio de colateralización determinada para protegerlo de liquidación o aumenta el apalancamiento en función de los cambios del mercado.',
    compound_automation_setup: 'Configuración de automatización de Compound',
    compound_automation_setup_description: 'Una vez habilitado, DeFi Saver controlará su ratio de colateralización en Compound y activará automáticamente Repay si su Compound alcanza el límite inferior configurado, o Boost si alcanza el superior. Simplemente ingrese su ratio deseada o configure manualmente debajo.',
    compound_automation_status: 'Status de Automatización de Compound',
    compound_saver_subscribe: 'Activando Automatización de Compound',
    compound_saver_unsubscribe: 'Desactivando Automatización de Compound',
    migrate_to_proxy: 'Migrar a Smart Wallet',
    migrate_to_proxy_description: 'La Automatización sólo puede activarse cuando se usa Compound desde la Smart Wallet de DeFi Saver. \nCambie a su Smart Wallet o migre su posición de Compound si aún no lo ha hecho.',
    no_smart_wallet_automation: 'Debe tener una posición de Compound en una Smart Wallet para poder usar la automatización.',
    compound_unsubscribe_reason: 'Mi ratio de colateralización en Compound cambió de una manera que no esperaba que sucediera.',
    create_position: 'Open long or short position using the Compound protocol',
    open_position: 'Open position',
    safety_ratio_create_desc: 'The ratio of your borrow limit versus borrowed funds. Minimum ratio excepted is 100%. We recommend a ratio over 150% to keep your position from liquidation',
    current_and_after_supply: 'Your current supply of selected asset and supply after finishing the create process',
    current_and_after_borrow: 'Your current borrow of selected asset and borrow after finishing the create process',
    comp_eoa_title: 'Account position:',
    comp_smart_wallet_title: 'Smart Wallet position:',
    claim_from_account: 'Claim your allocated tokens to withdraw them to your main account.',
    claim_from_smart_wallet: 'Claim your COMP tokens.',
    claim_and_supply_from_smart_wallet: 'Claim and supply COMP to Compound in one transaction.',
    claim_and_swap_from_smart_wallet: 'Claim and swap COMP into a different asset in one transaction. Includes a 0.25% service fee.',
    claim_swap_and_supply_from_smart_wallet: 'Claim COMP, swap and supply the new asset to Compound in one transaction. Includes a 0.25% service fee.',
    compound_migrate_modal_info1: 'Algunas de nuestras funciones avanzadas incluyen interacciones con múltiples contratos inteligentes en una transacción, lo que no es posible cuando se usa una cuenta Ethereum estándar.',
    compound_migrate_modal_info2: 'Smart Wallet es su billetera proxy de contrato inteligente personal que contiene sus fondos de Compound y hace posibles estas funciones avanzadas.',
    compound_migrate_modal_info3: 'Puede migrar el colateral suministrado o migrar un par colateral-deuda a su Smart Wallet.',
    compound_migrate_not_supplied: 'La migración solo es posible para cuentas de Compound que suministran y piden prestado al menos un activo.',
    compound_migrate_withdraw: 'Para migrar los fondos proporcionados, retírelos y suminístrelos a su Smart Wallet manualmente.',
    compound_migrate_no_assets: 'No hay activos en su cuenta disponibles para la migrar.\nLa mayoría de las veces, esto se debe a que los fondos proporcionados en su cuenta de Compound has sido tomados en su Smart Wallet o viceversa.',
    buying_asset_at_explanation: '%borrow will be borrowed from the pool and used to buy more %supply at this price',
    create_smart_wallet_warning: 'You can only open a position when using Compound via the DeFi Saver Smart Wallet.',
    price_explanation: 'The current price in the Compound protocol.',
    compound_create: 'Compound Create',
    migrate_from_instadapp:'Compound DSA Migration',
    migrate_choose_position:'Select DSA',
    migrate_modal_description_1:'Using this tool will migrate your whole Compound position from DSA, the proprietary InstaDApp proxy wallet, to a standard DSProxy.',
    migrate_modal_description_2:'By migrating you gain access to our advanced features such as Automation, Boost and Repay, as well as our Recipe Creator.',
    migrate_modal_description_3:'Once you migrate your position to a DSProxy you will not be able to manage it at InstaDApp again unless you migrate back to their proxy wallet.',
    migrating_position_from_instadapp:'Migrating Compound from InstaDApp',
    dash_boost_collateral: 'Add collateral from your account to make the position safer, then generate debt to buy more collateral and add it to your position.',
    dash_boost_payback: 'Pay back debt from your account, then generate debt to buy more collateral and add it to your position.',
    dash_repay_withdraw: 'Withdraw selected asset and apply repay to keep your ratio unchanged.',
    dash_repay_generate: 'Borrow selected asset and deleverage your position to keep the same ratio.',
    add_collateral_description: 'Deposit the selected asset into Compound.',
    dash_collateral_generate: 'Add more collateral and borrow selected asset to your account.',
    dash_collateral_boost: 'Add more collateral from your position and apply boost to keep the ratio unchanged.',
    withdraw_description: 'Withdraw selected asset from Compound.',
    dash_withdraw_payback: 'Pay back part of your debt and withdraw collateral to your account.',
    dash_withdraw_repay: 'Deleverage your position and withdraw collateral to your account, while keeping the same, previous ratio.',
    generate_description: 'Borrow selected asset from Compound.',
    dash_generate_collateral: 'Add more collateral and generate more debt to your account.',
    dash_generate_repay: 'Deleverage your position and generate more debt to your account, while keeping the same, previous ratio.',
    payback_description: 'Pay back the %daiLabel borrowed asset.',
    dash_payback_withdraw: 'Pay back your debt and withdraw selected asset in one transaction.',
    dash_payback_boost: 'Pay back debt and apply boost to keep the ratio unchanged.',
    borrow_cap_exceeded: 'Borrow cap exceeded for %debtAsset market.',
    missing_liquidity: 'Not enough liquidity. The maximum amount available for borrowing is %assetAmount',
    migrating_position_from_account:'Migrating Compound from Account',
  },
  savings: {
    balance: 'Este es su saldo actual, incluidos los intereses devengados a partir de ahora en todos los protocolos integrados.',
    average_interest_rate: 'Esta es su tasa de interés promedio actual basada en la cantidad de DAI suministrada por protocolo.',
    curr_average_interest_rate: 'Tasa de interés actual:',
    user_wallet_description: 'Para utilizar nuestra función de ahorro, primero debe crear una Smart Wallet. Esta será su billetera de contrato personal que permitirá el uso de esta función avanzada y le permitirá retirar fondos en cualquier momento que desee. Tenga en cuenta que los fondos utilizados en nuestra función de Ahorros no serán visibles en otro panel (por ejemplo, el panel de Compound).',
    import_description: 'Importing lets you move your already supplied assets to your Smart Wallet so that you can use them in Smart Savings.',
    import_modal_description: 'Detectamos que ya tiene fondos suministrados a uno de los protocolos soportados. ¿Le gustaría importar estos fondos a Smart Savings?',
    monthly_avg_short: 'Promedio mensual',
    monthly_avg_description: 'Tasa de interés promedio del mes anterior',
    supply_asset_to_project: 'Suministrar %asset a %project',
    move_asset_from_to_project: 'Mover %asset de %project1 a %project2',
    withdraw_asset_from_project: 'Retirar %asset de %project',
    import_asset_to_project: 'Importar %asset a %project',
    withdraw_asset_to_account: 'Retirar %asset a la cuenta',
    emergency_withdraw: 'Retiro de emergencia de %token',
    accidentally_sent_withdraw: 'Ha enviado %token accidentalmente a su Smart Wallet, click debajo para retirar. Esta acción retirará todos los fondos del token especificado a su dirección.',
    liquidity: 'Liquidez',
    total_supply: 'Suministro Total',
    total_utilization: 'Utilización',
    deposited_amount: 'Cantidad depositada',
    deposited: 'Depositado',
    log_in_to_manage_your_savings: 'Inicie sesión para administrar sus Smart Savings',
    info_no_smart_wallet: 'Your assets in Smart Savings will be kept on your Smart Wallet. This will enable use of advanced features, but may not be compatible with other frontends for these protocols. You currently don\'t have a Smart Wallet and the first, required step will be to create one.',
    info_no_deposits: 'Your assets in Smart Savings will be kept on your Smart Wallet. This will enable use of advanced features, but may not be compatible with other frontends for these protocols.',
    supplied_info: 'Your current balance in this protocol, including interest earned so far.',
    supplied_info_summary: 'This is your current balance including interest earned so far, across all positions.',
    current_apy: 'Current APY',
    apy_info: 'A 7-day moving average APY, updated every 6 hours.',
    apy_info_summary: 'This is your current net APY for all deposits and protocols used.',
    pool_size: 'Pool size',
    import_info: 'Import your existing position to your Smart Wallet so you can use them in Smart Savings.',
    supply_info: 'Supply assets to the selected option to start earning yield.',
    withdraw_info: 'Withdraw assets back to your account.',
    description_yearn: 'You deposit and withdraw one and the same token. No additional actions or gas costs. Well handled security incidents. Lowest gas costs among available options.',
    description_rari: 'You deposit and withdraw one and the same token. No additional actions or gas costs. Some history of security incidents. Highest gas costs among available options.',
    description_mstable_save:'You can deposit and withdraw a variety of stablecoins, including DAI, USDC, USDT and mUSD. Depositing into mStable Save includes converting DAI, USDC and USDT to mUSD at 1:1 and depositing the mUSD into their Save contract to receive interest bearing imUSD. No history of security incidents. Medium gas costs compared to other options.',
    description_mstable_vault: 'You can deposit and withdraw a variety of stablecoins, including DAI, USDC, USDT and mUSD. Depositing into mStable Save includes converting DAI, USDC and USDT to mUSD at 1:1 and depositing the mUSD into their Save contract to receive interest bearing imUSD. The imUSD is then staked into their Vault contract to receive additional MTA rewards. No history of security incidents. Medium gas costs overall, with additional manual claiming of MTA rewards needed.',
    move_info:'Move currently deposited assets to the selected protocol.',
    mstable_unfavorable_exchange_rate:'Exchange rate between mUSD and %asset% seems to be different from 1:1 (%mUSDAmount% mUSD = %bAssetAmount% %asset%), resulting in potential loss. Withdrawing to a different asset might be more profitable. Are you sure you want to continue?',
    claim_info: 'Claim accrued protocol rewards.',
    claim_supply: 'Claim, swap and add the additional assets to your existing deposit.',
    claimable_info: 'Current amount of claimable reward tokens.',
    moving_affects_strategies: 'Moving funds to %protocol will require you to update your Smart Savings automation strategy settings as these are stored on-chain.',
    pool_size_info:'Total amount of assets deposited in the pool.',
    liquidity_info:'Currently available amount of assets that can be withdrawn from the pool.',
    liquidity_info_mstable:'Currently available amount of assets that can be withdrawn from the pool. Note: in mStable all assets can be withdrawn in mUSD at any point in time, while availability of specific stablecoins varies.',
    withrawable_info: 'The balances shown are withdrawable balances, based on the currently available liquidity in the used protocol and can differ from your total deposited amounts.',
  },
  misc: {
    logging_in: 'Iniciando sesión, espere...',
    connecting_provider: 'Conectando su cuenta, espere...',
    getting_cdp: 'Obteniendo su CDP, espere...',
    insurance_label: 'Asegurado por %sumAssured %currency hasta %validUntil',
    exchange_slippage: 'Tolerancia al disminución(slippage):',
    exchange_slippage_limit: 'Limitar disminución adicional en el precio:',
    exchange_for: 'Cambiar %from por %to',
    exchange_disclaimer: 'Mejor precio estimado obtenido de 0x, Kyber, Uniswap y Oasis. Incluye tarifa de servicio del 0.125%.',
    exchange_fee: 'Includes 0.125% service fee.',
    exchange_receipt: 'Check your swap receipt',
    boost_repay_exchange_disclaimer: 'Mejor precio estimado obtenido de 0x, Kyber, Uniswap y SushiSwap. Incluye tarifa de servicio del %fee%.',
    exchanging_funds: 'Cambiando sus fondos...',
    exchange_funds: 'Please enter amount and select the tokens you want to exchange.',
    exchanging_select: 'Selecione los tokens a cambiar.',
    exchanging_at_least: 'Seleccionando %value1 %token1 por al menos %value2 %token2',
    copied_to_clipboard: 'Copiado al portapapeles',
    short_description: 'La función Short tomará prestado el token elegido y lo cambiará por una moneda estable. Todo el proceso se realizará con un solo clic y en una sola transacción. ¡Próximamente!',
    leverage_description: 'La función de apalancamiento tomará prestado un token estable y lo cambiará por el token a apalancar. Todo el proceso se realizará con un solo clic de botón y en una sola transacción. ¡Próximamente!',
    non_web3: 'Navegador no compatible con web3',
    web3_browser_info: 'Para usar DeFi Saver e interactuar con la cadena de bloques, necesitará un navegador habilitado para Web3. Recomendamos usar Coinbase, Trust, Opera o Status.',
    feature_in_development: 'Esta función está en etapa de desarrollo.',
    subscribe_notifications: '¡Puede suscribirse a continuación para recibir una notificación tan pronto como esté disponible!',
    subscribe_success: '¡Gracias por suscribirse! Le notificaremos tan pronto como esta función se active.',
    confirm_transaction: 'Por favor confirme su transacción.',
    tx_sent: 'La transacción %txHash ha sido creada y está pendiente.',
    tx_pending: 'Transacción %txHash pendiente...',
    tx_confirmed: 'Transacción %txHash completada.',
    tx_rejected: 'Transacción %txHash rechazada.',
    tx_failed: 'Transacción rechazada.',
    tx_replaced: 'Transacción %txHash reemplazada.',
    tx_canceled: 'Transacción %txHash cancelada.',
    transaction_settings: 'Ajustes de Transacción',
    fast: 'Rápido',
    regular: 'Regular',
    cheap: 'Barato',
    gas_price: 'Precio de Gas',
    gas_limit: 'Límite de Gas',
    total_price: 'Precio total:',
    recent_txs: 'Transacciones recientes',
    no_recent_txs: 'No hay transacciones recientes',
    recommended_gas_prices: 'Recommended gas prices',
    customize_gas: 'Personalizar la configuración de Gas',
    real_gas_estimate: 'Gas estimado',
    real_gas_fee: 'Costo estimado',
    max_tx_fee: 'Tarifa máxima de transacción',
    total: 'Total',
    automation_stats: 'Estadísticas de automatización',
    automation_contract_upgrades: 'Actualizaciones del Contrato de Automatización',
    explore_maker_stats: 'Explorar Estadísticas de MakerDAO',
    gas_price_desc: 'Precio pagado por cada unidad de Gas usado por la transacción.',
    gas_limit_desc: 'Cantidad máxima de Gas permitido para la transacción.',
    real_gas_estimate_desc: 'Cantidad estimada de Gas que se espera que utilice la transacción',
    real_gas_fee_desc: 'Costo estimado de transacción con valores configurados actualmente.',
    base_fee: 'Base fee',
    base_fee_desc: 'The base fee is a required gas fee that gets burned upon transaction execution. It is automatically adjusted by the network, decreasing or increasing based on current network usage.',
    priority_fee: 'Priority fee',
    priority_fee_desc: 'The priority fee (often called the "miner tip") is an additional fee that is paid directly to the miners in order to incentivize inclusion of your transaction.',
    max_fee: 'Max fee',
    max_fee_desc: 'The max fee is the limit to how much you\'ll pay for both base fee and tip.',
    max_tx_fee_desc: 'Tarifa máxima pagada por la transacción. El Gas no utilizado por la transacción se reembolsa al usuario.',
    tx_failing_notification: 'Es muy probable que esta transacción falle. Comuníquese con nosotros para obtener ayuda si este error persiste.',
    welcome_to_dfs: 'Bienvenido a DeFi Saver',
    withdrawing_from_safeguard: 'retirando fondos de safeguard',
    low_gas_price_warning: 'Usar un precio de Gas más bajo que el Barato puede resultar en transacciones estancadas.',
    low_max_fee_warning: 'Using a max fee close to the current base fee may result in stuck transactions.',
    current_ratio: 'Ratio actual',
    log_in_to_manage_your_app: 'Inicie sesión para administrar su posición en %app',
    supply_to_enable_collateral: 'Suministrar para habilitar como colateral. El suministro a través de Smart Wallet habilitará el activo automáticamente.',
    automation_congestion_desc_1: 'Con el fin de mantener la automatización beneficiosa y rentable para todas las posiciones durante los aumentos repentinos del precio del gas, hemos implementado las siguientes reglas:',
    automation_congestion_rule_1: 'Automated Boosting will only be executed if the transaction cost is less than 5% of the Boost amount.',
    automation_congestion_rule_2: 'Automated Repay will be executed if the transaction cost is less than 5% of the Repay amount or as soon as you\'re less than 15% above liquidation ratio.',
    automation_congestion_desc_2: 'Automation always sends all transactions using current fast gas, tracks them and re-sends with faster gas if any get stuck. The maximum gas price that can be charged to the user is currently %gweiAmount Gwei.',
    automation_congestion_desc_3: 'We continue to make gas usage related optimizations to our smart contracts in order to reduce gas usage in as many instances as possible. (Last updated Mar 2nd 2022).',
    automation_congestion_desc_4: 'Si desea verificar algo relacionado con la automatización con nosotros, no dude en unirse a nuestro https://discord.gg/XGDJHhZ',
    footer_desc: 'DeFi Saver is a one-stop management solution for decentralized finance protocols.',
    newsletter_sub: 'Subscribe to the DeFi Saver newsletter',
    whats_new_title: 'What\'s new on DeFi Saver',
    low_ratio_warn: 'Safety ratio lower than currRatio. Please be mindful of liquidation risks that come with high leverage levels.',
    high_borrow_warn: 'Borrow power higher than currRatio. Please be mindful of liquidation risks that come with high leverage levels.',
    feature_unavailable_in_simulation: 'Feature unavailable in simulation',
    feature_unavailable_in_simulation_desc: 'Some features relying on external systems are disabled while you\'re inside the simulation. %feature is one of them. ',
    positions_subbed: 'Positions subscribed',
    vault_min_debt_warning: 'The minimum debt for a %protocol is currently %amount %asset. While below minimum debt, you can only pay off all debt or generate more debt.',
    exchange_option_info: 'The decentralized exchange used for your wanted swap.',
    dfs_service_fee_info: 'The DeFi Saver service fee for this transaction.',
    fl_protocol_info: 'The protocol used for the needed flash loan.',
    deadline: 'Deadline:',
    ens_or_addr_not_found: 'Couldn\'t find address or ENS',
    ens_not_found: 'There is no address with this ENS',
    exchange_advanced_subtitle: '(Slippage limit, liquidity sources)',
    exchange_best_rate_via: 'Best rate found via',
    received_token_sent: 'Received token sent to',
    send_to_different_addr: 'Send to a different address',
    token_addr_not_found: 'Token for the given address not found.',
    wrong_addr: 'You entered wrong address value',
    sending_to_your_acc: 'Can\'t send received tokens to your account',
    sending_to_your_proxy: 'Can\'t send received tokens to your smart wallet',
    send_tokens_warning: 'No funds will be sent to your account!',
    sending_tokens_details: 'Send tokens received from the swap to a different address.',
    tsi_for_assets: ' for %pair'
  },
  errors: {
    error_occurred: 'Ha ocurrido un error',
    required: 'Requerido',
    over_max: 'El valor es mayour que el valor máximo',
    under_zero: 'El valor no puede ser menor a 0',
    no_value: 'No se ha ingresado un valor',
    invalid_value: '%value no válido',
    positive_value: 'Debe ser un valor positivo',
    must_be_over: 'Debe ser mayour a %value',
    wallet_not_connected: 'Billetera no conectada',
    nothing_supplied: 'Nada suministrado',
    swap_same_project: 'Please select different protocols for moving assets.',
    no_local_storage_state: 'No hay estado de almacenamiento local',
    no_ledger_item: 'No hay último ítem ledger usado',
    wrong_network: 'Red incorrecta: configure %type en %network',
    provider_not_approved: 'Proveedor no aprobado previamente',
    denied_login: 'Ha denegado el inicio de sesión',
    denied_transaction: 'Ha denegado la transacción',
    email_fail: 'No se puede enviar el email',
    user_denied_tx: 'Tx denegada por el usuario',
    not_enough_funds: 'No hay fondos suficientes para copletar esta transacción.',
    tx_repeat: 'Se detectó una transacción duplicada.',
    tx_cancel: 'Transacción cancelada.',
    compound_tx_fail: 'Transacción de Compound fallida',
    no_accounts_locked: 'Sin cuentas (posiblemente bloqueadas)',
    unknown_network: 'Red desconocida',
    ledger_locked: 'Ledger está bloqueado',
    ledger_contract_data: 'Por favor habilite "Contract data" en su Ledger',
    user_canceled: 'El usuario canceló la transacción',
    automatic_trigger: 'Esta acción desencadenaría un ajuste automático basado en su configuración de Automatización.',
    no_wallet: 'Billetera no detectada',
    connect_access: 'Conecte su billetera para acceder a esta página',
    over_value: 'Debe ser mayor a %value',
    under_value: 'Debe ser menor a %value',
    value_less_than: 'El valor debe ser menor que %label',
    value_less_than_percent: 'Value must be %percent% under %label',
    target_limit_difference: 'La diferencia entre las proporciones objetivo y límite debe ser mayor que %value%',
    positive_number: 'Must be a positive numberDebe ser un número positivo',
    page_not_found: 'no se encuentra la página',
    debt_below_min: 'Your CDP debt is under the required minimum of %amount %asset. You can only increase your debt pay back the full amount.',
    debt_below_min_after: 'This action would cause debt to be under the required minimum of %amount %asset. ',
    reverted_transaction: 'La transacción se ha revertido debido a un error. Hash de transacción: %txhash',
    invalid_ds_proxy: 'Dirección de DSProxy no válida',
    ratio_too_low_for_repay: 'Para que la automatización funcione, la ratio debe mantenerse al menos un %percent% por encima de la ratio de liquidación.',
    gas_price_very_high: 'El precio de Gas seleccionado (%gasPrice Gwei) puede ser innecesariamente alto. Seguro que quiere continuar?',
    action_select_no_options: 'No hay colateral ni préstamos tomados disponibles para esta acción..',
    dai_debt_ceiling_hit: 'Se alcanzó el límite máximo de deuda global de MakerDAO para el colateral utilizado.',
    borrow_power_used_high: 'Borrow power used is too close or over 100%',
    minimum_dai_debt: 'Minimum debt for %ilk CDPs is %min DAI.',
    initial_dai_debt:'Selected CDP is under the minimum required debt (%min DAI).',
    minimum_rai_debt: 'Minimum debt for %ilk Safes is %min RAI.',
    initial_rai_debt:'Selected Safe is under the minimum required debt (%min RAI).',
    minimum_liquity_debt: 'Minimum debt for Liquity Troves is %min LUSD.',
    invalid_address: 'Invalid address',
    own_address: 'You have entered your own address',
    liquidity_warning: '%asset currently has low available liquidity on exchanges and you may see high slippage with any larger Boosts and Repays.',
    boost_no_collateral_enabled: 'Boost feature is unavailable until you enable your supplied asset as collateral in the main dashboard below.',
    v1_to_v2_lend_error: 'Migrating positions with LEND supplied is not supported.',
    trying_sub_under_min_debt: 'Your current debt is under the minimum debt required for enabling Automation.',
    exchange_proxy_warning: 'Using the Exchange requires a Smart Wallet created. Exchanged funds are regularly sent to your account.',
    over_debt_ceiling: 'Not enough DAI available for %ilk (%available DAI available).',
    additional_action_selected: 'Additional action selected',
    sending_to_asset_contract: 'You seem to have entered the %asset token contract address as the recipient. Please recheck the address you\'re sending to.',
    value_must_be_above: 'Value must be above "%label"',
    value_must_be_above_percent: 'Value must be %percent% above %label',
  },
  portfolio: {
    show_empty_positions: 'Show empty positions',
    hide_empty_positions: 'Hide empty positions',
    current_protocols: 'Protocolos actuales',
    estimated_earnings: 'Ganancia estimada',
    net_worth: 'Valor neto',
    assets: 'Activos',
    tokens: 'Tokens',
    no_owned_tokens: 'This account currently doesn\'t own any tokens',
    token_assets: 'Token Assets',
    portfolio_value: 'Portfolio Value',
    collateral: 'Collateral',
    debt: 'Debt',
    claimable: 'Claimable',
    supplied: 'Supplied',
    borrowed: 'Borrowed',
    on_smart_wallet: 'On Smart Wallet',
    failed_fetch: 'Failed to fetch %protocol Positions for address: %address',
  },
  aave: {
    aave_migrate_modal_info1: 'Algunas de nuestras funciones avanzadas incluyen interacciones con múltiples contratos inteligentes en una transacción, lo que no es posible cuando se usa una cuenta Ethereum estándar..',
    aave_migrate_modal_info2: 'Smart Wallet es su billetera proxy de contrato inteligente personal que contiene sus fondos de Aave y hace posibles estas funciones avanzadas.',
    aave_migrate_modal_info3: 'Puede migrar el colateral suministrado o migrar un par colateral-deuda a su Smart Wallet.',
    aave_proxy_migrate_modal_info1:'By migrating your %protocol position to a Smart Wallet you gain access to our advanced management options such as Boost, Repay, Automated strategies and Loan Shifting.',
    aave_proxy_migrate_modal_info2:'A Smart Wallet is your personal smart contract proxy wallet that is created, owned and manageable by you and you only.',
    aave_proxy_migrate_modal_info3:'Potential steps of the migration process can include creating a Smart Wallet, providing needed token approvals and the migration transaction itself.',
    aave_proxy_migrate_modal_info4:'Learn more about the migration process and the Smart Wallet here.',
    aave_migrate_not_supplied: 'La migración solo es posible para las cuentas de Aave que suministran y piden prestado al menos un activo.',
    aave_migrate_withdraw: 'Para migrar los fondos suministrados, retírelos y suminístrelos a su Smart Wallet manualmente.',
    aave_migrate_no_assets: 'No hay activos en su cuenta disponibles para migración.\nLa mayoría de las veces, esto se debe a que los fondos proporcionados en su Cuenta Aave se toman prestados en su Smart Wallet, o viceversa..',
    smart_wallet_info: 'El uso de Aave a través de su Smart Wallet le permite ejecutar operaciones avanzadas, como Boost y Repay. Smart Wallet es una billetera MakerDAO DSProxy estándar utilizada para integración fácil con protocolos de préstamos.',
    smart_wallet_info_2: 'Estos fondos no serán visibles en la aplicación Aave.',
    withdraw_info: 'Retirar activos de su suministro de Aave',
    supply_assets_info: 'Suministre sus activos para comenzar a ganar intereses',
    borrow_info: 'Tomar prestados activos de Aave. Necesita proporcionar activos como garantía antes de pedir prestado.',
    payback_info: 'Pagar el activo tomado en préstamo.',
    aave_automation: 'Aave Automation',
    aave_automation_description: 'Keeps your Aave position at a certain ratio to protect it from liquidation or increase leverage based on market changes.',
    aave_automation_setup: 'Aave Automation Setup',
    aave_automation_setup_description: 'Once Enabled, DeFi Saver will monitor your Aave ratio and automatically activate Repay if your Aave reaches the lower configured limit, or Boost if it reaches the upper one. Simply enter your target ratio, or configure manually below.',
    aave_automation_status: 'Aave Automation Status',
    aave_automation_active_account: 'Aave Automation is currently active for this Aave and the graph shows your current configuration.',
    aave_saver_subscribe: 'Enabling Aave Automation',
    aave_saver_unsubscribe: 'Disabling Aave Automation',
    aave_state: 'Current Aave ratio: %current%',
    no_smart_wallet_automation: 'You need to have a Aave position on a Smart Wallet in order to use Automation.',
    loading_aave_saver_data: 'Loading Aave Automation data...',
    migrate_to_proxy_description: 'Automation can only be enabled when using Aave via the DeFi Saver Smart Wallet. \nPlease switch to your Smart Wallet or migrate your Aave position if you haven\'t already.',
    aave_unsubscribe_reason: 'My Aave ratio was changed in a way I didn\'t expect would happen.',
    orig_fee: 'Origination Fee',
    migration_orig_fee_desc: 'Origination fee is a 0.00001% fee collected by Aave from borrowers. It has to be paid back directly by the borrower, so asset approval is required for migration. ',
    borrow_apy_var: 'Variable B. APY',
    borrow_apy_stable: 'Stable B. APY',
    interest_rate: 'Interest Rate',
    swap_interest_rate: 'Swap interest rate for %asset',
    change_rate: 'To %rate',
    select_rate: 'Select your interest rate',
    stable_desc: ' would be a good choice if you need to plan around a non-volatile rate over a longer period of time.',
    variable_desc: ' is based on the supply and demand for the selected asset on Aave and is re-calculated every second, meaning the rate will be lower when there is less demand for the asset.',
    can_switch: 'You can also switch between the two rates at any point in the future.',
    cant_stable_borrow: 'You can only use the stable borrow rate if you borrow more of the same asset than you have supplied.',
    unsupported_stable_borrow_asset: 'This asset is unsupported for stable rate borrow',
    change_interest_rate: 'Change interest rate',
    stake: 'Stake',
    unstake: 'unstake',
    stake_aave: 'Stake AAVE: %amount',
    unstake_aave: 'Unstake AAVE: %amount',
    activate_cooldown: 'Unstake AAVE: activate cooldown',
    migrate_lend_aave: 'Migrate %amount LEND to AAVE',
    migrate_lend_aave_desc: 'The LEND token is migrating to AAVE.',
    claim_rewards: 'Claim accrued AAVE: %amount',
    claim_stkaave: 'Claim accrued stkAAVE: %amount',
    currently_staked: 'Currently staked',
    stake_modal_title: 'AAVE Staking',
    stake_modal_desc: 'Staking AAVE in the Safety Module helps to secure the protocol in exchange for protocol incentives.',
    reward_estimates: 'Reward estimates',
    claimable_rewards: 'Claimable rewards',
    token_actions: 'Token Actions',
    stake_action_desc: 'Stake your AAVE to secure the Aave protocol and earn rewards.',
    unstake_action_desc: 'Unlocking staked funds activates a 10-day cooldown after which you can withdraw your AAVE during a specific unstaked time frame.',
    staking: 'Staking',
    stake_desc: 'Stake your AAVE',
    aave_token: 'AAVE',
    liquidation_ratio: 'Liquidation ratio',
    liquidation_ratio_desc: 'Safety ratio below which your position will face liquidation.',
    liquidation_limit: 'Liquidation limit',
    liquidation_limit_desc: 'Value of borrowed funds above which your position will face liquidation.',
    migrate_to_v2: 'Migrate from v1 to v2',
    migrate_v2_modal_title: 'Migrate to Aave v2',
    migrate_v2_modal_description: 'Migrating to Aave v2 will instantly move your whole portfolio (ie. all supplied and borrowed assets) from Aave v1 to Aave v2.',
    migrate_v2_modal_note: 'Note that the migration is only possible to your Smart Wallet on Aave v2 and there is no reverse migration back to v1 available.',
    migrate_v2_modal_automation_note: 'If you have Automation enabled on v1, it will be enabled and configured to the same settings on v2.',
    aave_v1_automation_deprication_title: 'Aave v1 Automation is being deprecated',
    aave_v1_automation_deprication_description: 'If you would like to use Automation for your Aave position, we strongly suggest migrating to Aave v2 which has lower transaction fees, as well as better liquidity and rates. You can find the migration button in the Aave dashboard.',
    migrate_from_instadapp:'Aave DSA Migration',
    migrate_modal_description_1:'Using this tool will migrate your whole Aave position from DSA, the proprietary InstaDApp proxy wallet, to a standard DSProxy.',
    migrating_position_from_instadapp:'Migrating Aave from InstaDApp',
    migrating_position_from_account:'Migrating Aave from Account',
    current_steth_eth_ratio_desc: 'Current stETH/ETH price ratio (should be pegged to 1)',
    liquidation_steth_eth_ratio_desc: 'stETH/ETH price ratio at which your position would liquidate',
    add_collateral_description: 'Deposit the selected asset into Aave.',
    withdraw_description: 'Withdraw selected asset from Aave.',
    generate_description: 'Borrow selected asset from Aave.',
    isolation_mode_description: 'Isolation mode allows to list new assets as Isolated which has a specific debt ceiling and can be only used to borrow stablecoins that have been permitted, by Aave Governance, to be borrowable in isolation mode.\nIsolated assets can only be used as collateral if there are no other supplied assets or none of the supplied assets is enabled as collateral.\nIf there are other assets supplied as collateral, the asset supported for isolation mode will not be counted as collateral.\nOnly certain assets can be borrowed against assets supported as collateral in isolation mode.',
    supplying_while_siloed: 'You\'re supplying a siloed asset which will not be counted towards your collateral value for borrowing against.',
    supplying_while_isolated: 'The asset you\'re supplying will not be counted as collateral because you are in isolation mode.',
    siloed_borrowing_desc: 'Siloed mode allows borrowing certain assets labelled as risky by Aave Governance, with the limitation being that you cannot borrow any other assets at the same time.',
    siloed_asset_desc: 'This is a siloed asset. Borrowing it will block you from borrowing any other asset at the same time.',
    isolation_asset_desc: 'This is an isolated asset. Using it as collateral will block you from using any other asset as collateral at the same time.\n',
    supplying_isolated_asset_disabled_coll: 'The asset you\'re supplying is an isolated asset and will not be counted towards your already supplied collateralized assets.',
    supplying_isolated_asset: 'You\'re entering isolated mode. In isolation mode, you can\'t supply other assets as collateral. A global debt ceiling limits the borrowing power of the isolated asset.',
    e_mode_desc: 'Efficiency mode allows supplying and borrowing assets correlated in price under preferential terms, which will usually include an increased LTV, higher liquidation threshold and a decreased liquidation bonus.\nGroups of assets that can be used in efficiency mode can be stablecoins or ETH and various liquid staking derivatives.',
    e_mode_active: 'Efficiency mode is activated',
    e_mode_activate: 'Activate efficiency mode',
    isolation_mode_activated: 'You are in isolation mode.',
    siloed_borrowing_activated: 'You are in siloed borrowing mode.',
    aave_create: 'Aave Create',
    open_position: 'Open position',
  },
  shifter: {
    shift: 'Shift',
    description: 'Shift your position between protocols or change your collateral and debt assets.',
    shift_protocol_desc: 'Shift your position between different protocols or Maker Vault types (e.g. ETH-A to ETH-B).',
    shift_coll_desc: 'Change your collateral asset to an asset with a lower Stability fee or a higher supply APY.',
    shift_debt_desc: 'Change your debt asset to a different one, for example one with a lower borrow APY.',
    error_shifting_asset_unavailable: 'Shifting %unavailable asset is not possible while shifting %shift_type',
    error_same_asset: 'Select a different %shift_type asset',
    no_position_selected: 'No position selected.',
    choose_shift_type: 'Choose what you want to shift:',
    smart_wallet_required: 'Loan Shifter can only work with positions that are on a Smart Wallet. Would you like to create a Smart Wallet and migrate your Compound position now? ',
    learn_more: 'Learn more about Loan Shifter here',
    position_on_main_acc_1: 'Have a %protocol position on your main account?',
    position_on_main_acc_2: 'Migrate it to your Smart Wallet to use the Loan Shifter',
    error_from_pos_no_coll: 'From position does not have collateral.',
    error_no_swap_coll_and_debt: 'Shifting both collateral and debt assets at the same time is not possible.',
    shift_type_not_supported: 'Shift type not supported.',
    error_shift_not_available_for_asset: 'Shift not available for %asset (no flash loan available).',
    error_shift_not_enough_liquidity: 'Not enough liquidity to execute exchange.',
  },
  reflexer: {
    safe: 'Safe',
    redemption_rate: 'Redemption Rate',
    rai_system_price_desc: 'RAI\'s moving peg or the current target price of RAI, also known as the redemption price.',
    rai_market_price_desc: 'Price at which RAI is currently trading.',
    redemption_rate_desc: 'Annual rate at which RAI is devalued or revalued, based on the difference between RAI redemption and market prices. A positive rate means the redemption price is increasing and vice-versa.',
    stability_fee_desc: 'The annual borrow rate for Reflexer Safes, calculated based on your outstanding debt and continuously added to your current debt. ',
    no_safe_found: 'No Safe Found',
    no_safe_blocker: 'There doesn\'t seem to be a Reflexer Safe associated with this account. You can create a Reflexer Safe now to borrow RAI or leverage ETH using our signature Boost and Repay features.',
    create_safe: 'Create a Safe',
    create_safe_description: 'Creating a Safe allows you to generate RAI by depositing ETH',
    safe_created_successfully: 'Safe #%safeId created successfully',
    safe_created_successfully_desc: '-',
    liquidation_ratio_explanation: 'The minimum collateralization ratio for %collType Safes. Any Safe that drops below this ratio faces liquidation.',
  },
  liquity: {
    recovery_mode_warning: 'Liquity is currently in Recovery mode, any trove with collateral ratio below 150% will get liquidated.',
    recovery_mode: 'Recovery mode',
    normal_mode: 'Normal mode',
    trove: 'Trove',
    create_trove: 'Create Trove',
    create_trove_warning: 'Your Trove will be created on your Smart Wallet. This enables the use of advanced features, but may not be compatible with other Liquity frontends. %missing_sw',
    close_trove: 'Close Trove',
    no_trove_found: 'No trove found',
    no_trove_blocker: 'There doesn\'t seem to be a Liquity Trove associated with this account.',
    no_trove_blocker_sw: 'There doesn\'t seem to be a Liquity Trove associated with this account. You can create a Liquity Trove now to borrow LUSD or leverage ETH using our signature Boost and Repay features.',
    create_trove_description: 'Creating a Trove allows you to generate LUSD by depositing ETH',
    minimum_ratio: 'Minimum accepted ratio in %mode is %min_ratio. \nWe recommend a ratio over %suggested_ratio to keep the Trove safe from liquidation',
    liquidation_ratio_explanation: 'The minimum collateralization ratio in %mode. Any Troves that fall below this ratio face liquidation.',
    liquidation_reserve_info: ' This fee covers the gas cost a liquidator would pay to liquidate your Trove. You are refunded this fee when you pay back your debt.',
    borrowing_fee_info: 'A one time fee charged as a percentage of the borrowed amount (in LUSD) and added to your Trove\'s debt. The fee varies between 0.5% and 5% depending on LUSD redemption volumes.',
    total_debt_info: 'The total amount of LUSD your Trove will hold.',
    liquidation_reserve: 'Liquidation reserve',
    borrowing_fee: 'Borrowing fee',
    total_debt: 'Total debt',
    tcr: 'Total collateralization ratio',
    tcr_short: 'TCR',
    tcr_info: 'The ratio of the Dollar value of the entire system collateral at the current ETH:USD price, to the entire system debt.',
    kickback_rate: 'Kickback rate',
    kickback_rate_info: 'A rate set by the frontend operator that determines the fraction of LQTY that will be paid out as a kickback to the Stability Providers using the frontend.',
    collateral_ratio_suggestion: 'Keeping your Collateral ratio above 150% can help avoid liquidation under Recovery mode.',
    proxy_required: 'Smart Wallet required for advanced actions.',
    lusd_market_price_info: 'Current LUSD market price in dollars',
    borrowing_fee_stats_info: 'Current fee percentage',
    debt_in_front_info: 'Amount of LUSD available for redemption in Troves with lower collateralization ratio than yours. The lower the amount, the more likely it becomes for your Trove to be redeemed against.',
    lusd_balance_info: 'LUSD balance on your wallet',
    lqty_balance_info: 'LQTY balance on your wallet',
    redemption_fee_info: 'The Redemption Fee is charged as a percentage of the redeemed Ether up to 1%. The Redemption Fee depends on LUSD redemption volumes and is 0.5% at minimum.',
    close_account: 'Closing your Trove will pay back the debt using LUSD from your account and withdraw the remaining ETH collateral to your account in the same transaction.',
    claimable_collateral_info_liq: 'Your trove has been liquidated during the Recovery mode.\nYou have %amount ETH to claim.',
    claimable_collateral_info_redeemed: 'Your trove has been fully redeemed.\nYou have %amount ETH to claim.',
    stake_action: 'Stake your LQTY',
    stake_action_desc: 'Stake your LQTY to earn a share of borrowing and redemption fee.',
    redeem_action_desc: 'Redeem ETH from LUSD.',
    redeem_lusd_desc: 'Redeem LUSD for ETH at $1 of ETH per 1 LUSD. An additional redemption fee is charged on the redeemed amount.',
    gains_info: 'Earned %asset balance',
    debt_below_min: 'Your Trove debt is under the required minimum of 2000 LUSD.',
    stake_lqty_desc: 'Stake LQTY and earn LUSD and ETH revenue from borrowing and redemption fees. Your LQTY stake will earn a share of the fees equal to your share of the total LQTY staked.',
    stake_lusd_desc: 'Deposit LUSD to the Stability Pool and earn ETH liquidation bonuses and LQTY rewards.',
    buying_asset_at_explanation: 'LUSD will be borrowed from the Trove and used to buy more collateral at this price',
    trove_created_successfully: 'Trove created successfully',
    trove_created_successfully_desc: 'We suggest enabling liquidation protection for your position using our Automated Strategies system.\nYou can also do this at any moment in the future.',
  },
  strategies: {
    short_description: 'Set up automated strategies to automatically adjust your positions, including automated liquidation protection, leverage management and position closing or opening.',
    legacy_title: 'Automated Leverage Management',
    legacy_description: 'Configure automated leverage management for your position, including auto-Repay for liquidation protection and (optional) auto-Boost for leverage increase.',
    smart_savings_payback_title: 'Savings Liquidation Protection',
    smart_savings_payback_description: 'Protect your %protocol from liquidation with automated debt payback using %asset deposited into Smart Savings.',
    stop_loss_title: 'Stop Loss',
    stop_loss_description: 'Fully close your position automatically once collateral asset reaches a configured minimum target price.',
    take_profit_title: 'Take Profit',
    take_profit_description: 'Fully close your position once collateral asset reaches a configured maximum target price.',
    enable_action_title: 'Enable automated strategy',
    disable_action_title: 'Disable automated strategy',
    update_action_title: 'Update automated strategy',
    no_smart_savings_selected: 'No Smart Savings protocol selected',
    warning_smart_savings_zero_balance: 'Your currently deposited balance in the selected Smart Savings protocol is 0 which would make this automated strategy impossible to run, unless you deposit funds afterwards. Are you sure you want to continue?',
    warning_smart_savings_not_enough_for_payback: 'Your currently deposited balance in the selected Smart Savings protocol is not sufficient to reach the target ratio in case your configured threshold is reached. Are you sure you want to continue?',
    strategy_unavailable_title: 'Additional strategy unavailable',
    strategy_unavailable_description: 'Enabling more than one automated strategy is currently not possible.',
    savings_strategies_fee_info: 'Fees for using Savings Liquidation Protection include transaction fees and a %feePercentage% service fee for any automated transactions that occur. There are no fees for simply keeping this automated strategy enabled and your position monitored.',
    close_strategies_fee_info: 'Fees for using Stop Loss and Take Profit strategies include a transaction fee and a %feePercentage% service fee in case the strategy gets executed and your position closed. There are no fees for simply keeping this automated strategy enabled and your position monitored.',
    leverage_strategies_fee_info: 'Fees for using Automated Leverage Management include a transaction fee and a %feePercentage% service fee on the repaid/leveraged amount. There are no fees for simply keeping this automated strategy enabled and your position monitored.',
    close_if_price_below: 'Close if price below',
    close_if_price_above: 'Close if price above',
    debt_at_least: 'Due to high transaction fees, especially during significant market movements, Automation is not recommended for smaller positions. A minimum of $%value in debt is required to enable %protocol Automation.',
  },
  recipes: {
    create_recipe: 'Create recipe',
    recipe_book: 'Recipe book',
    click_to_copy: 'Copy a link to your recipe',
    recipe_has_errors: 'Recipe contains errors',
    recipe_has_suggestions: 'Recipe contains suggestions you may want to check.',
    create_sw_description: 'A Smart Wallet is required to execute a recipe. It enables executing complex transactions and adds a layer of security protecting you from contract exploits.',
    refresh_prices_to_continue: 'Fetched prices have expired. Refresh prices to continue.',
    refresh_prices: 'Refresh prices',
    use_whole_balance_payback: 'Pay back whole debt',
    use_whole_balance_supply: 'Use whole balance',
    use_whole_balance_send: 'Use whole balance',
    use_whole_balance_withdraw: 'Use whole balance',
    whole_balance_payback: 'By checking this option, the recipe creator will use the exact amount needed to payback the debt',
    whole_balance_supply: 'By checking this option, the recipe creator will supply the whole balance from selected wallet',
    whole_balance_send: 'By checking this option, the recipe creator will send the whole balance from recipe wallet to specified address',
    whole_balance_withdraw: 'By checking this option, the recipe creator will withdraw all collateral specified address',
    nlp_header: 'Create a recipe using words - click here to try it out',
    nlp_input: 'Recipe sentence',
    nlp_parse: 'Prepare recipe',
    rc_header_desc: 'Execute complex transactions composed of multiple actions easily and safely',
    recipe_automation: 'Recipe Automation',
    quick_access: 'Quick Access',
    params_automatic: 'Recipe parameters are automatically calculated',
    recipe_fee: 'This recipe contains a sell action which includes a service fee of %fee%.'
  },
  faq: {
    what_is_automation: 'What is DFS Automation?',
    what_is_automation_answer: 'Automation is a trustless system for automatic liquidation protection or automated leverage management of collateralized debt positions that monitors users’ positions and automatically makes adjustments once the configured conditions are met.',
    what_are_requirements: 'What are the requirements for using Automation?',
    what_are_requirements_answer: 'The only two requirements for running Automation are that the user’s position is on a Smart Wallet (which is already the default behaviour for MakerDAO and Reflexer) and that a minimum debt requirement is met. Currently, the minimum required debt for \tenabling Automation is $40,000 of debt for MakerDAO and Liquity or $60,000 total debt balance in case of Compound and Aave.',
    how_does_automation_work: 'How does Automation work?',
    how_does_automation_work_answer: 'By enabling Automation, a user gives Automation smart contracts the rights to make adjustments on their position. Once Automation is enabled for any position, Automation bots will start continuously monitoring it and executing any needed adjustments as soon \tas the configured thresholds are reached.',
    when_are_tx_made: 'When are Automation transactions made?',
    when_are_tx_made_answer: 'Automated transactions are executed as soon as the position’s collateralization ratio reaches configured thresholds. This is something that will be affected by the used protocol’s price oracles, so a good way to look at it is that Automation for any given protocol runs on that protocol’s price oracles (not on any external price feed).',
    what_are_automation_fees: 'What are the fees for using Automation?',
    what_are_automation_fees_answer: 'There are two types of fees that users should know in regards to using Automation: the service fee of 0.3% (of the transaction size, \tper automated adjustment) and transaction fees (charged to the position, or, effectively, to the user\'s collateral).',
    what_are_automation_risks: 'What are the risks of using Automation?',
    what_are_automation_risks_answer: 'There are different types of risks that users should consider. Firstly, there is smart contract risk, although Automation has been audited with no issues found. Secondly, there is technical risk, where network conditions or other issues may prevent \tautomated-adjustments from being made in a timely manner. And, lastly, there is economic risk, which would ultimately be caused by the market moving against the user’s position, but should be considered in either case.',
  },
  pages: {
    terms_of_service: 'Términos del servicio',
    faq: 'FAQ',
    faq_long: 'Frequently Asked Questions',
    compound: 'Compound',
    smart_savings: 'Smart Savings',
    maker_dao: 'MakerDAO',
    liquity: 'Liquity',
    privacy_policy: 'Política de Privacidad',
    blog: 'Blog',
    help: 'Ayuda',
    exchange: 'Intercambiar',
    portfolio: 'Portafolio',
    transaction_history: 'History',
    funds_safeguard: 'Funds Safeguard',
    aave: 'Aave',
    whats_new: 'What\'s new',
    shifter: 'Loan Shifter',
    reflexer: 'Reflexer',
    recipe_creator: 'Recipe Creator',
    bridge: 'Bridge',
  },
  error: {
    slippage_hit: 'The transaction hit the slippage limit, hence execution reverted.',
    out_of_gas: 'Transaction ran out of gas.',
  },
  uniswap: {
    create_pool: 'You are the first liquidity provider for this Uniswap V3 pool. The transaction fee will be much higher as it includes gas costs for creating a new pool.',
    withdrawing_from_pool: 'Withdrawing %amount %asset from Uni V%version',
  },
  insta: {
    authorize_proxy_for_dsa:'Authorize DSProxy on DSA'
  },
  bridge: {
    outcome: 'Final Bridge outcome',
    auto_refresh: 'Routes will be auto refresh in ',
    none_available: 'No bridges are available at the moment with these parameters',
    transfered_funds: 'Your funds will be transfered in approximately ~%time minutes',
    searching: 'Searching for routes',
    current_status: 'Current status: %status',
    missing_params: 'Enter amount you want to transfer to another network'
  }
};
