import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { NETWORKS } from '../../../constants/general';
import clientConfig from '../../../config/clientConfig.json';

import { changeNetwork } from '../../../actions/accountActions';

import OutsideAlerter from '../../OutsideAlerter/OutsideAlerter';
import NetworkIcon from './NetworkIcon';

const ConnectNetworkHover = ({ close }) => {
  const dispatch = useDispatch();
  const { network } = useSelector(({ general }) => general);
  const networks = useMemo(
    () => Object.values(NETWORKS).filter(({ chainId }) => chainId !== network && clientConfig.supportedNetworks.includes(chainId)),
    [network],
  );

  return (
    <OutsideAlerter onClickOutside={close}>
      <div className="connect-wallet-hover-wrapper" onClick={close}>
        <div>
          {networks.map(({ chainId, chainName }) => (
            <div key={chainId} className="Flex FlexStart list-item action" onClick={() => dispatch(changeNetwork(chainId))}>
              <div className="item-icon smaller"><NetworkIcon chainId={chainId} /></div>
              <div><h3>{chainName}</h3></div>
            </div>
          ))}
        </div>
      </div>
    </OutsideAlerter>
  );
};

ConnectNetworkHover.propTypes = {
  close: PropTypes.func.isRequired,
};

export default ConnectNetworkHover;
