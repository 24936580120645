import {
  getAssetInfoByAddress, assetAmountInEth,
} from '@defisaver/tokens';
import Dec from 'decimal.js';
import { getAddr } from '@defisaver/sdk/src/addresses';
import Web3 from 'web3';
import { compareAddresses, getEthAmountForDecimals } from './utils';
import { getERC20TokenData } from './erc20Service';

const web3Utils = Web3.utils;
const transferEventSig = web3Utils.sha3('Transfer(address,address,uint256)'); // 0x exchange event
const swapEventSig = web3Utils.sha3('Swap(address,uint256,uint256,uint256,uint256,address)');// old exchange onchain event(univ2, kyber)
const transformEventSig = web3Utils.sha3('TransformedERC20(address,address,address,uint256,uint256)');
const DFS_WALLET = '0x322d58b9e75a6918f7e7849aee0ff09369977e08';
const DFS_FEE_TAKER = '0x6467e807dB1E71B9Ef04E0E3aFb962E4B0900B2B'; // Fee transferred to this address instead of directly to wallet starting March 2021
const DFS_SELL = web3Utils.sha3('DFSSell');
const DFS_AAVE_V2_FL = web3Utils.sha3('FLAaveV2');

const getTransfersInTxTo = (txReceipt, to) => txReceipt.logs.filter(log => compareAddresses(log.raw.topics[0], transferEventSig) &&
  compareAddresses(`0x${log.raw.topics[2].substr(26)}`, to));

const createReceiptLogsArrFromEvents = (txReceipt) => {
  const logs = [];
  Object.values(txReceipt?.events || {}).forEach((event) => {
    if (Array.isArray(event)) {
      event.forEach((innerEvent) => {
        logs.push(innerEvent);
      });
    } else {
      logs.push(event);
    }
  });
  return { ...txReceipt, logs };
};


export const parseFeeEventsFromReceipt = async (_txReceipt) => {
  const txReceipt = createReceiptLogsArrFromEvents(_txReceipt);
  const web3 = window._web3;
  try {
    let transfers = getTransfersInTxTo(txReceipt, DFS_WALLET);
    if (!transfers.length) transfers = getTransfersInTxTo(txReceipt, DFS_FEE_TAKER);
    if (transfers.length) {
      const res = await transfers.reduce(async (sum, transfer) => {
        const feeAssetInfo = await getERC20TokenData(transfer.address);
        let feeAsset = feeAssetInfo.symbol;
        const decodedValue = web3.eth.abi.decodeParameter('uint256', transfer.raw.data);
        const feeAmount = getEthAmountForDecimals(decodedValue.toString(), feeAssetInfo.decimals);
        feeAsset = feeAsset.replace('WETH', 'ETH');
        if (sum[feeAsset]) {
          return { ...sum, [feeAsset]: new Dec(sum[feeAsset]).add(feeAmount).toString() };
        }
        return { ...sum, [feeAsset]: feeAmount };
      }, {});
      return Object.keys(res).reduce((arr, asset) => {
        arr.push({ feeAsset: asset, feeAmount: res[asset] });
        return arr;
      }, []);
    }
  } catch (err) {
    console.error(err);
  }
  // console.error(`Error determining fee for ${txReceipt.transactionHash}`);
  return [{ feeAsset: '', feeAmount: 0 }];
};

export const parseSwapEventsFromReceipt = (_txReceipt, txInfo) => {
  const txReceipt = createReceiptLogsArrFromEvents(_txReceipt);
  const web3 = window._web3;
  // if swap went through 0x
  let parsedData = '';
  let rate = 0;
  const zeroXEvent = txReceipt.logs.find(log => compareAddresses(log.raw.topics[0], transformEventSig)
    && compareAddresses(log.address, '0xdef1c0ded9bec7f1a1670819833240f027b25eff'));
  const swapEvent = txReceipt.logs.find(log => compareAddresses(log.raw.topics[0], swapEventSig));
  if (zeroXEvent) {
    // console.log('zerox');
    parsedData = web3.eth.abi.decodeParameters(['address', 'address', 'uint256', 'uint256'], zeroXEvent.raw.data);
    const firstAsset = getAssetInfoByAddress(parsedData[0]).symbol;
    const secondAsset = getAssetInfoByAddress(parsedData[1]).symbol;
    const inAmount = assetAmountInEth(parsedData[2].toString(), firstAsset);
    const outAmount = assetAmountInEth(parsedData[3].toString(), secondAsset);
    rate = new Dec(inAmount).div(outAmount).toString();
  } else if (swapEvent) {
    // exchange went onchain
    const swapEvents = txReceipt.logs.filter(log => compareAddresses(log.raw.topics[0], swapEventSig));
    const parsedDataFirst = web3.eth.abi.decodeParameters(['uint256', 'uint256', 'uint256', 'uint256'], swapEvents[0].raw.data);
    const parsedDataSecond = web3.eth.abi.decodeParameters(['uint256', 'uint256', 'uint256', 'uint256'], swapEvents[swapEvents.length - 1].raw.data);
    parsedData = [parsedDataFirst[0], parsedDataFirst[1], parsedDataSecond[2], parsedDataSecond[3]];
    const inAmount = assetAmountInEth(new Dec(+parsedData[0] || +parsedData[1]).toString(), txInfo.firstToken);
    const outAmount = assetAmountInEth(new Dec(+parsedData[2] || +parsedData[3]).toString(), txInfo.secondToken);
    rate = new Dec(inAmount).div(outAmount).toString();
  }
  return rate;
};

export const parseSellEventsFromReceipt = (_txReceipt) => {
  const txReceipt = createReceiptLogsArrFromEvents(_txReceipt);
  const web3 = window._web3;
  // topics[1] for direct action
  // topics[2] for recipe action
  const sellEvents = txReceipt.logs.filter((event) => compareAddresses(event.raw.topics[1], DFS_SELL) || compareAddresses(event.raw.topics[2], DFS_SELL));
  return sellEvents.map((event) => {
    const decoded = web3.eth.abi.decodeParameters(['address', 'address', 'address', 'uint256', 'uint256', 'uint256'], `0x${event.raw.data.substr(130)}`);
    const [wrapper, sellToken, buyToken, sellAmount, buyAmount, fee] = Object.values(decoded);
    return {
      wrapper, sellToken, buyToken, sellAmount, buyAmount, fee,
    };
  });
};

export const parseAaveV2FlashLoanEventFromReceipt = (_txReceipt) => {
  const txReceipt = createReceiptLogsArrFromEvents(_txReceipt);
  const web3 = window._web3;
  const flashLoanEvents = txReceipt.logs.filter((event) => compareAddresses(event.raw.topics[1], DFS_AAVE_V2_FL) || compareAddresses(event.raw.topics[2], DFS_AAVE_V2_FL));
  return flashLoanEvents.map((event) => {
    const decoded = web3.eth.abi.decodeParameters(['address[]', 'uint256[]', 'uint256[]', 'address'], `0x${event.raw.data.substr(130)}`);
    const [tokens, amounts, modes, onBehalfOf] = Object.values(decoded);
    return {
      tokens, amounts, modes, onBehalfOf,
    };
  });
};
export const parseAaveV2FlashLoanTransfersFromReceipt = (_txReceipt) => {
  const txReceipt = createReceiptLogsArrFromEvents(_txReceipt);
  const web3 = window._web3;
  const transfers = getTransfersInTxTo(txReceipt, getAddr('FLAaveV2'));
  return transfers.map((transfer) => {
    const asset = getAssetInfoByAddress(transfer.address).symbol;
    const decodedValue = web3.eth.abi.decodeParameter('uint256', transfer.raw.data);
    const value = assetAmountInEth(decodedValue.toString(), asset);
    return { asset: asset.replace('WETH', 'ETH'), value };
  });
};

export const parseAaveV3FlashLoanTransfersFromReceipt = (_txReceipt) => {
  const txReceipt = createReceiptLogsArrFromEvents(_txReceipt);
  const web3 = window._web3;
  const transfers = getTransfersInTxTo(txReceipt, getAddr('FLAaveV3'));
  return transfers.map((transfer) => {
    const asset = getAssetInfoByAddress(transfer.address).symbol;
    const decodedValue = web3.eth.abi.decodeParameter('uint256', transfer.raw.data);
    const value = assetAmountInEth(decodedValue.toString(), asset);
    return { asset: asset.replace('WETH', 'ETH'), value };
  });
};
