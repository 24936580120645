import t from 'translate';
import { getAssetInfo, getAssetInfoByAddress } from '@defisaver/tokens';
import { backendApiUrl } from '../../config/clientConfig.json';
import { SubStorageContract } from '../contractRegistryService';

import * as encodingStrategiesService from './encodingStrategiesService';
import * as strategiesConstants from '../../constants/strategiesConstants';

import { requireAddress, wethToEth } from '../utils';
import { callViaProxy } from '../contractCallService';
import { multicall } from '../multicallService';
import { trackEvent } from '../analyticsService';
import { RATIO_STATE_OVER } from '../../constants/strategiesConstants';

/**
 *
 * @param {object} subStruct
 * @param {array} cdps
 * @return {*}
 */
const getStrategyOrBundleIdInfo = (subStruct, cdps) => {
  const id = +subStruct.strategyOrBundleId;
  const item = {
    ...(
      subStruct.isBundle
        ? strategiesConstants.BUNDLE_IDS[id]
        : strategiesConstants.STRATEGY_IDS[id]
    ),
  };

  if (!item) return null;

  if (item.protocol === 'makerdao') {
    if (item.strategyName === 'smart-savings-liquidation-protection') {
      item.triggerData = encodingStrategiesService.makerRepayFromSavingsTriggerData.decode(subStruct.triggerData);
      item.subData = encodingStrategiesService.makerRepayFromSavingsSubData.decode(subStruct.subData);

      const cdp = cdps.find(({ id }) => +item.subData.vaultId === +id);

      item.Icon = getAssetInfo('MKR').icon;
      item.subtitle = `MakerDAO Vault #${item.subData.vaultId} ${cdp?.ilkLabel ? `(${cdp.ilkLabel})` : ''} / ${item.bundleName}`;
      item.title = t('strategies.smart_savings_payback_title');
      item.graphData = {
        minRatio: +item.triggerData.ratio,
        minOptimalRatio: +item.subData.targetRatio,
        repayEnabled: true,
        boostEnabled: false,
      };
    }

    if (item.strategyName === 'close-on-price-to-debt' || item.strategyName === 'close-on-price-to-coll') {
      item.triggerData = encodingStrategiesService.closeOnPriceTriggerData.decode(subStruct.triggerData);
      item.subData = encodingStrategiesService.makerCloseOnPriceSubData.decode(subStruct.subData);
      const isTakeProfit = +item.triggerData.state === RATIO_STATE_OVER;
      item.strategyName = isTakeProfit ? 'take-profit' : 'stop-loss';
      const cdp = cdps.find(({ id }) => +item.subData.vaultId === +id);

      const closeSymbol = getAssetInfoByAddress(item.subData.closeToAssetAddr).symbol;
      item.Icon = getAssetInfo(closeSymbol).icon;
      item.subtitle = `MakerDAO Vault #${item.subData.vaultId} ${cdp?.ilkLabel ? `(${cdp.ilkLabel})` : ''}`;
      item.title = isTakeProfit ? t('strategies.take_profit_title') : t('strategies.stop_loss_title');
      item.title += ` (${wethToEth(closeSymbol)})`;
      item.graphData = {
        price: +item.triggerData.price,
        closeToAssetAddr: item.subData.closeToAssetAddr,
        repayEnabled: false,
        boostEnabled: false,
      };
    }
  }
  if (item.protocol === 'liquity') {
    if (item.strategyName === 'close-on-price-to-coll') {
      item.triggerData = encodingStrategiesService.closeOnPriceTriggerData.decode(subStruct.triggerData);
      item.subData = encodingStrategiesService.liquityCloseOnPriceSubData.decode(subStruct.subData);
      const isTakeProfit = +item.triggerData.state === RATIO_STATE_OVER;
      item.strategyName = isTakeProfit ? 'take-profit' : 'stop-loss';

      item.Icon = getAssetInfo('ETH').icon;
      item.subtitle = 'Liquity Trove';
      item.title = isTakeProfit ? t('strategies.take_profit_title') : t('strategies.stop_loss_title');
      item.title += ' (ETH)';
      item.graphData = {
        price: +item.triggerData.price,
        closeToAssetAddr: item.subData.closeToAssetAddr,
        repayEnabled: false,
        boostEnabled: false,
      };
    }
  }

  return item;
};

/**
 *
 * @param {string} accountType
 * @param {function} sendTxFunc
 * @param {string} proxyAddress
 * @param {string} account
 * @param {array} strategySub
 * @return {Promise<*>}
 */
export const subscribeToStrategy = async ({
  accountType, sendTxFunc, proxyAddress, account,
}, strategySub) => {
  requireAddress(proxyAddress);

  const funcParams = [strategySub];
  return callViaProxy(accountType, sendTxFunc, proxyAddress, account, 'SubProxy', 'subscribeToStrategy', funcParams);
};

/**
 *
 * @param {string} accountType
 * @param {function} sendTxFunc
 * @param {string} proxyAddress
 * @param {string} account
 * @param {string} subId
 * @return {Promise<*>}
 */
export const activateStrategySubscription = async ({
  accountType, sendTxFunc, proxyAddress, account,
}, subId) => {
  requireAddress(proxyAddress);

  const funcParams = [subId];
  return callViaProxy(accountType, sendTxFunc, proxyAddress, account, 'SubProxy', 'activateSub', funcParams);
};

/**
 *
 * @param {string} accountType
 * @param {function} sendTxFunc
 * @param {string} proxyAddress
 * @param {string} account
 * @param {string} subId
 * @return {Promise<*>}
 */
export const deactivateStrategySubscription = async ({
  accountType, sendTxFunc, proxyAddress, account,
}, subId) => {
  requireAddress(proxyAddress);

  const funcParams = [subId];
  return callViaProxy(accountType, sendTxFunc, proxyAddress, account, 'SubProxy', 'deactivateSub', funcParams);
};

/**
 *
 * @param {string} accountType
 * @param {function} sendTxFunc
 * @param {string} proxyAddress
 * @param {string} account
 * @param {string} subId
 * @param {array} strategySub
 * @return {Promise<*>}
 */
export const updateStrategySubscription = async ({
  accountType, sendTxFunc, proxyAddress, account,
}, subId, strategySub) => {
  requireAddress(proxyAddress);

  const funcParams = [subId, strategySub];
  return callViaProxy(accountType, sendTxFunc, proxyAddress, account, 'SubProxy', 'updateSubData', funcParams);
};

/**
 *
 * @param {string} accountType
 * @param {function} sendTxFunc
 * @param {string} proxyAddress
 * @param {string} account
 * @param {string} subId
 * @param {array} strategySub
 * @return {Promise<*>}
 */
export const updateAndActivateStrategySubscription = async ({
  accountType, sendTxFunc, proxyAddress, account,
}, subId, strategySub) => {
  requireAddress(proxyAddress);

  const funcParams = [subId, strategySub];
  return callViaProxy(accountType, sendTxFunc, proxyAddress, account, 'SubProxy', 'updateAndActivateSub', funcParams);
};

/**
 *
 * @param {string} proxy
 * @param {number|string} [fromBlock]
 * @param {number|string} [toBlock]
 * @return {*}
 */
export const getStrategiesSubscriptions = (proxy, fromBlock = 14242966, toBlock = 'latest') => {
  const contract = SubStorageContract();
  return contract.getPastEvents('Subscribe', {
    fromBlock,
    toBlock,
    filter: { proxy },
  });
};

/**
 *
 * @param {string} subId
 * @param {number|string} [fromBlock]
 * @param {number|string} [toBlock]
 * @return {*}
 */
export const getStrategiesUpdates = async (subId, fromBlock = 14242966, toBlock = 'latest') => {
  const contract = SubStorageContract();
  const events = await contract.getPastEvents('UpdateData', {
    fromBlock,
    toBlock,
    filter: { subId },
  });
  // fork and normal RPC sort events differently
  events.sort((a, b) => a.blockNumber - b.blockNumber);
  return events;
};

/**
 *
 * @param {array} subIds
 * @return {Promise<*[{}]>}
 */
export const getStrategiesSubs = async (subIds) => {
  const contract = SubStorageContract();

  const target = contract.options.address;
  const abiItem = contract.options.jsonInterface.find(abi => abi.name === 'strategiesSubs');

  return multicall(subIds.map((subId) => (
    { target, abiItem, params: [subId] }
  )));
};

/**
 *
 * @param {string} proxyAddress
 * @param {array} cdps
 * @return {Promise<*[{}]>}
 */
export const getSubscribedStrategies = async (proxyAddress, cdps) => {
  const subscriptions = (await getStrategiesSubscriptions(proxyAddress))?.map(({ returnValues }) => returnValues);

  let subsData = [];

  if (subscriptions) {
    subsData = await getStrategiesSubs(subscriptions.map(({ subId }) => subId));
    subsData = await Promise.all(subsData.map(async (sub, i) => {
      let latestUpdate = subscriptions[i];

      if (latestUpdate.subHash !== sub?.strategySubHash) {
        const updates = await getStrategiesUpdates(latestUpdate.subId);
        latestUpdate = updates?.[updates.length - 1]?.returnValues;
      }

      const strategyInfo = getStrategyOrBundleIdInfo(latestUpdate.subStruct, cdps);

      return {
        ...strategyInfo,
        subId: latestUpdate.subId,
        subHash: latestUpdate.subHash,
        subStruct: { ...latestUpdate.subStruct },
        isEnabled: sub.isEnabled,
      };
    }));
  }
  console.log('ovde');
  return subsData;
};

export const getAutomationApiTransactions = async (subId) => {
  const automationRes = await fetch(`${backendApiUrl}/automationapi/automation?id=${subId}`);
  const automationPayload = await automationRes.json();

  if (automationPayload?.message === 'success') {
    return Promise.all(automationPayload.result.transactions.reverse().filter((i) => i.mined).map(async ({ hash, id }) => {
      const transactionRes = await fetch(`${backendApiUrl}/automationapi/transaction?id=${id}`);
      const transactionPayload = await transactionRes.json();
      const date = transactionPayload.result.updated_at;

      return ({
        timestamp: +new Date(date),
        actionType: 'payback', // TODO handle this
        txHash: hash,
      });
    }));
  }
  if (automationPayload?.message.startsWith('no automation found for given id')) return [];
  throw new Error('Failed to fetch strategies subscriptions history.');
};

export const strategiesTrackEventWrapper = (action, protocol, strategy, status) => trackEvent('strategies', `${action} ${protocol} ${strategy}`, status);
