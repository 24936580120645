/* eslint-disable */
import React from 'react';

export default function GnosisSafeIcon(props) {
  return (
    <svg className="gnosissafe-icon" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M9.52295 18.5C14.4935 18.5 18.5229 14.4706 18.5229 9.5C18.5229 4.52944 14.4935 0.5 9.52295 0.5C4.55239 0.5 0.522949 4.52944 0.522949 9.5C0.522949 14.4706 4.55239 18.5 9.52295 18.5ZM9.52292 11.9811C10.7294 11.9811 11.7348 11.1199 11.9579 9.97874H15.9836C16.2349 9.97874 16.4387 9.77501 16.4387 9.52371C16.4387 9.2724 16.2349 9.06868 15.9836 9.06868H11.9667C11.7626 7.90398 10.7461 7.01885 9.52292 7.01885C8.15263 7.01885 7.0418 8.12968 7.0418 9.49997C7.0418 10.8703 8.15263 11.9811 9.52292 11.9811Z" fill="#008C73"/>
    </svg>
  );
}
