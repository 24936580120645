import React from 'react';
import PropTypes from 'prop-types';

const FeeTierActionField = ({
  type, i, handleChange, actualValue,
}) => {
  const feeOptions = [
    {
      value: '0.01',
      label: 'Very stable pairs',
      secondLabel: '0.01% fee',
    },
    {
      value: '0.05',
      label: 'Stable pairs',
      secondLabel: '0.05% fee',
    },
    {
      value: '0.3',
      label: 'Most pairs',
      secondLabel: '0.3% fee',
    },
    {
      value: '1',
      label: 'Exotic pairs',
      secondLabel: '1% fee',
    },
  ];
  return (
    <div className="Switch">
      {
        feeOptions.map((o) => (
          <div
            key={o.value}
            onClick={() => handleChange({ target: o }, i)}
            className={actualValue === o.value ? 'active' : ''}
          >
            <div>{o.label}</div>
            <div><b>{o.secondLabel}</b></div>
          </div>
        ))
      }
    </div>
  );
};

FeeTierActionField.propTypes = {
  handleChange: PropTypes.func.isRequired,
  type: PropTypes.object.isRequired,
  i: PropTypes.number.isRequired,
  actualValue: PropTypes.string.isRequired,
};

export default FeeTierActionField;
