import dfs from '@defisaver/sdk';
import { assetAmountInWei } from '@defisaver/tokens';
import Dec from 'decimal.js';
import RecipeAction from '../RecipeAction';
import { AssetAmount, Source } from '../../components/Recipes/RecipeCreator/inputTypes';
import { withdrawAfterValues } from '../../actions/liquityActions/liquityManageAfterValues';
import { findInsertPosition, getTroveInfo } from '../../services/liquityServices/liquityService';
import WithdrawIcon from '../recipeIcons/Withdraw.svg';
import { formatNumber } from '../../services/utils';
import { MAXUINT } from '../../constants/general';


export default class LiquityWithdrawAction extends RecipeAction {
  static prettyName = 'Withdraw WETH from Trove';

  static protocol = 'liquity';

  static protocolPrettyName = 'Liquity';

  static description = 'Withdraws part of (W)ETH collateral from a Trove.';

  constructor(collateral = '', to = 'wallet') {
    super();
    this.inputs = [
      new AssetAmount('Collateral', collateral, 'WETH'),
      new Source('To', to, true),
    ];
    this.output = new AssetAmount('output', 0, 'WETH');
  }

  async getAfterValues(_balances = {}, returnValues = [], actions = [], _positions = {}, getState) {
    const positions = { ..._positions };
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const {
      liquity: { proxy },
      liquityStaking: { proxy: stakingProxy },
    } = getState();
    if (!positions.liquity) positions.liquity = { ...proxy, ...stakingProxy };
    const source = args[1];
    let amount = args[0] || '0';
    if (amount === 'All available') amount = positions.liquity.collateral || '0';
    const { afterPosition, balances, returnValue } = await withdrawAfterValues(
      {
        amount,
        to: source,
      },
      {
        vault: positions.liquity,
        assets: getState().assets,
        account: getState().general.account,
        proxyAddress: getState().maker.proxyAddress,
      },
      _balances);
    positions.liquity = afterPosition;
    this.output.value = returnValue;
    return { returnValue: this.output, balances, positions };
  }

  async toDfsAction(getState, actions, returnValues, positions, actionBeforeState, actionCurrentState) {
    const {
      general: { account },
      maker: { proxyAddress },
    } = getState();
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const to = this.inputs[1].value === 'wallet' ? account : proxyAddress;
    console.log(actionCurrentState, actionBeforeState);
    const trove = actionCurrentState?.positions?.liquity || await getTroveInfo(proxyAddress);
    const amount = args[0] === 'All available' ? MAXUINT : assetAmountInWei(args[0], 'WETH');
    const { lowerHint, upperHint } = await findInsertPosition(assetAmountInWei(trove.collateral, 'WETH'), assetAmountInWei(trove.debtInAsset, 'LUSD'), proxyAddress);
    return new dfs.actions.liquity.LiquityWithdrawAction(amount, to, upperHint, lowerHint);
  }

  setAsset(asset) {
    this.asset = asset;
  }

  getAsset() {
    return this.asset;
  }

  static getIcon() {
    return WithdrawIcon;
  }

  _getPrettyName(actionCalls, actions) {
    const args = this.mapReturnValuesToArgs(actionCalls.map(a => a.returnValue), actions);
    return `Withdraw ${formatNumber(args[0])} WETH from Trove`;
  }
}
