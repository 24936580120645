import memoize from 'memoizee';
import dfs from '@defisaver/sdk';
import { AssetAmount, CdpId, Source } from 'components/Recipes/RecipeCreator/inputTypes';
import { generateAfterValues } from 'actions/makerActions/makerManageActions/makerManageAfterValues';
import { getFullCdpInfoFromId as _getFullCdpInfoFromId } from 'services/makerServices/makerManageServices/makerManageService';
import RecipeAction from '../RecipeAction';
import BorrowIcon from '../recipeIcons/Borrow.svg';
import { formatNumber } from '../../services/utils';
import { assetAmountInWeiIgnorePointer } from '../../services/recipeCreator/recipeActionUtils';
import { getCdpManagerForType } from '../../services/makerServices/makerMcdService';

const getFullCdpInfoFromId = memoize(_getFullCdpInfoFromId, { maxAge: 2 * 60 * 1000 });

export default class MakerGenerateAction extends RecipeAction {
  static prettyName = 'Generate DAI from Vault';

  static protocol = 'maker';

  static protocolPrettyName = 'Maker';

  static description = 'Generates DAI from the selected Vault.';

  constructor(cdpId = 0, amount = '', to = 'wallet') {
    super();
    this.inputs = [
      new CdpId('CDP ID', cdpId),
      new AssetAmount('Amount', amount, 'DAI'),
      new Source('To', to, true),
    ];
    this.output = new AssetAmount('output', 0, 'DAI');
  }

  async getAfterValues(_balances = {}, returnValues = [], actions = [], _positions = {}, getState) {
    const positions = { ..._positions };
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const cdpId = args[0];
    if (!positions[`maker_${cdpId}`]) positions[`maker_${cdpId}`] = await getFullCdpInfoFromId(cdpId);
    const { afterPosition, balances, returnValue } = await generateAfterValues(
      {
        amount: args[1],
        to: args[2],
      },
      {
        cdp: positions[`maker_${cdpId}`],
        assets: getState().assets,
        account: getState().general.account,
        proxyAddress: getState().maker.proxyAddress,
      },
      _balances,
    );
    positions[`maker_${cdpId}`] = afterPosition;
    this.output.value = returnValue;
    return { returnValue: this.output, balances, positions };
  }

  async toDfsAction(getState, recipeActions, returnValues, positions) {
    const {
      general: { account },
      maker: { proxyAddress },
    } = getState();
    const args = this.mapReturnValuesToArgs(returnValues, recipeActions);
    const managerAddress = getCdpManagerForType(positions[`maker_${args[0]}`].type);
    const to = this.inputs[2].value === 'wallet' ? account : proxyAddress;
    const amount = assetAmountInWeiIgnorePointer(this.inputs[1].value, 'DAI');
    return new dfs.actions.maker.MakerGenerateAction(this.inputs[0].value, amount, to, managerAddress);
  }

  static getIcon() {
    return BorrowIcon;
  }

  _getPrettyName(actionCalls, actions) {
    const args = this.mapReturnValuesToArgs(actionCalls.map(a => a.returnValue), actions);
    return `Generate ${formatNumber(args[1])} DAI from Vault #${args[0]}`;
  }
}
