import React from 'react';
import PropTypes from 'prop-types';

import './Toggle.scss';

const Toggle = ({ value, ...props }) => (
  <label className="toggle-wrapper">
    <input type="checkbox" checked={value} {...props} />
    <span className="toggle-movable" />
  </label>
);

Toggle.propTypes = {
  value: PropTypes.bool.isRequired,
};

Toggle.defaultProps = {};

export default Toggle;
