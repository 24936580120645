import t from 'translate';
import { captureException } from 'sentry';
import { callWithRetry } from 'services/utils';
// import { COMPOUND_ASSET_TYPES } from 'constants/general';
import * as compoundAutomation from 'services/compoundServices/compoundSaverService';
import Dec from 'decimal.js';
import {
  COMPOUND_SAVER_IS_SUBSCRIBED_REQUEST,
  COMPOUND_SAVER_IS_SUBSCRIBED_SUCCESS,
  COMPOUND_SAVER_IS_SUBSCRIBED_FAILURE,

  COMPOUND_SAVER_SUBSCRIBE_REQUEST,
  COMPOUND_SAVER_SUBSCRIBE_SUCCESS,
  COMPOUND_SAVER_SUBSCRIBE_FAILURE,
  COMPOUND_SAVER_SUBSCRIBE_RESET,

  COMPOUND_SAVER_UNSUBSCRIBE_REQUEST,
  COMPOUND_SAVER_UNSUBSCRIBE_SUCCESS,
  COMPOUND_SAVER_UNSUBSCRIBE_FAILURE,

  SET_COMPOUND_SAVER_UPDATING,

  GET_COMPOUND_SAVER_SUBSCRIBE_HISTORY_REQUEST,
  GET_COMPOUND_SAVER_SUBSCRIBE_HISTORY_SUCCESS,
  GET_COMPOUND_SAVER_SUBSCRIBE_HISTORY_LOADMORE_SUCCESS,
  GET_COMPOUND_SAVER_SUBSCRIBE_HISTORY_FAILURE,

  GET_COMPOUND_SAVER_SUBSCRIBE_GRAPH_DATA_REQUEST,
  GET_COMPOUND_SAVER_SUBSCRIBE_GRAPH_DATA_SUCCESS,
  GET_COMPOUND_SAVER_SUBSCRIBE_GRAPH_DATA_FAILURE,


} from '../../actionTypes/compoundActionTypes/compoundSaverActionTypes';
import { sendTx } from '../txNotificationActions';
import { trackEvent } from '../../services/analyticsService';
// import { getCompoundSaverSubscriptionInfo } from '../../services/compoundServices/compoundSaverService';
import { closeModal } from '../modalActions';
import { calculateLimits } from '../../services/compoundServices/compoundSaverService';
import { minMoneymarketProtectionDebt } from '../../config/clientConfig.json';
import { sendAutomationUnsubscribeApiCall } from '../../services/apiService';
import * as modalActions from '../modalActions';
/**
 * Resets the state values for the compound saver subscribe form
 *
 * @return {Function}
 */
export const resetCompoundSaverSubscribe = () => (dispatch) => { dispatch({ type: COMPOUND_SAVER_SUBSCRIBE_RESET }); };

export const getCompoundSaverSubscribedHistory = (page = 1) => async (dispatch, getState) => {
  dispatch({ type: GET_COMPOUND_SAVER_SUBSCRIBE_HISTORY_REQUEST });

  try {
    const { proxyAddress } = getState().maker;
    const events = await compoundAutomation.getCompoundSaverHistory(proxyAddress);
    const payload = { page, items: events };

    dispatch({
      type: page === 1
        ? GET_COMPOUND_SAVER_SUBSCRIBE_HISTORY_SUCCESS
        : GET_COMPOUND_SAVER_SUBSCRIBE_HISTORY_LOADMORE_SUCCESS,
      payload,
    });
  } catch (err) {
    dispatch({ type: GET_COMPOUND_SAVER_SUBSCRIBE_HISTORY_FAILURE, payload: err.message });
    captureException(err);
  }
};


/**
 * Fetches data for the status graph on Compound automation section
 *
 * @return {Function}
 */
export const getCompoundSaverSubscribedGraphData = () => async (dispatch, getState) => {
  dispatch({ type: GET_COMPOUND_SAVER_SUBSCRIBE_GRAPH_DATA_REQUEST });

  try {
    const { proxyAddress } = getState().maker;
    const { ratio } = getState().compoundManage;
    const data = await compoundAutomation.getCompoundSaverSubscriptionInfo(proxyAddress);

    const payload = {
      ...data,
      current: ratio,
    };

    dispatch({ type: GET_COMPOUND_SAVER_SUBSCRIBE_GRAPH_DATA_SUCCESS, payload });

    return payload;
  } catch (err) {
    dispatch({ type: GET_COMPOUND_SAVER_SUBSCRIBE_GRAPH_DATA_FAILURE, payload: err.message });
  }
};
export const getCompoundSaverSubscribedData = () => async (dispatch) => {
  await dispatch(getCompoundSaverSubscribedGraphData());
  await dispatch(getCompoundSaverSubscribedHistory());
};

/**
 * Checks if the user is subscribed to the CompoundService feature
 *
 * @return {Function}
 */
export const getCompoundIsSubscribed = () => async (dispatch, getState) => {
  dispatch({ type: COMPOUND_SAVER_IS_SUBSCRIBED_REQUEST });
  const { proxyAddress } = getState().maker;
  try {
    const payload = await compoundAutomation.isSubscribedToMonitor(proxyAddress);

    dispatch({ type: COMPOUND_SAVER_IS_SUBSCRIBED_SUCCESS, payload });

    return payload;
  } catch (err) {
    dispatch({ type: COMPOUND_SAVER_IS_SUBSCRIBED_FAILURE, payload: err.message });
  }
};

/**
  * Subscribes the user to the saver service
  *
  * @param formData {Object}
  * @return {Function}
  */
export const compoundSaverSubscribe = formData => async (dispatch, getState) => {
  const {
    advanced,
    ratio,
    ratioBelow,
    ratioOver,
    updating,
    boostEnabled,
  } = formData;

  trackEvent('compoundAutomate', updating ? 'update' : 'subscribe');
  dispatch({ type: COMPOUND_SAVER_SUBSCRIBE_REQUEST });

  try {
    const {
      maker: { proxyAddress },
      general: { account, accountType, path },
      compoundManage: { borrowedUsd },
      compoundSaver: { subscribed },
    } = getState();

    if (!subscribed && new Dec(borrowedUsd).lt(minMoneymarketProtectionDebt)) throw new Error(t('errors.trying_sub_under_min_debt'));
    let boostTo = ratio;
    let repayTo = ratio;
    let { minRatio, maxRatio } = calculateLimits(ratio);
    if (advanced) {
      // eslint-disable-next-line prefer-destructuring
      boostTo = formData.boostTo;
      // eslint-disable-next-line prefer-destructuring
      repayTo = formData.repayTo;
      minRatio = ratioBelow;
      maxRatio = ratioOver;
    }

    const proxySendHandler = promise => sendTx(promise, t('compound.compound_saver_subscribe'), 'Compound', dispatch, getState, { protocol: 'compound' });

    const payload = await compoundAutomation.subscribeToMonitor(
      accountType,
      path,
      account,
      proxySendHandler,
      proxyAddress,
      minRatio,
      boostEnabled ? maxRatio : 0,
      repayTo,
      boostEnabled ? boostTo : 0,
      boostEnabled,
      updating,
    );

    dispatch({ type: COMPOUND_SAVER_SUBSCRIBE_SUCCESS, payload });
    await callWithRetry(
      // eslint-disable-next-line arrow-body-style
      () => {
        return compoundAutomation.isSubscribedToMonitor(proxyAddress);
      },
      10,
      data => data.isSubscribedToAutomation,
    );
    dispatch(getCompoundSaverSubscribedData());
    trackEvent('compoundAutomate', updating ? 'updateSuccess' : 'subscribeSuccess');
  } catch (err) {
    dispatch({ type: COMPOUND_SAVER_SUBSCRIBE_FAILURE, payload: err.message });
    captureException(err);
    trackEvent('compoundAutomate', updating ? 'updateError' : 'subscribeError', err.message);
  }
};

/**
  * Unsubscribes the user to the saver service
  *
  * @return {Function}
  */
export const compoundSaverUnsubscribe = (formData) => async (dispatch, getState) => {
  dispatch({ type: COMPOUND_SAVER_UNSUBSCRIBE_REQUEST });
  trackEvent('compoundAutomate', 'unsubscribe');

  try {
    const { proxyAddress } = getState().maker;
    if (formData) {
      const apiReqPromise = sendAutomationUnsubscribeApiCall('Compound', proxyAddress, 0, formData.field, formData.description);
      dispatch(closeModal());
    }
    const onTxHashCallback = () => { dispatch(modalActions.openAutomationUnsubNextStepsModal({ strategyName: t('strategies.legacy_title'), protocol: 'compound' })); };
    const proxySendHandler = promise => sendTx(promise, t('compound.compound_saver_unsubscribe'), 'Compound', dispatch, getState, { protocol: 'compound' }, onTxHashCallback);
    const { account, accountType, path } = getState().general;

    await compoundAutomation.unsubscribeFromMonitor(
      accountType,
      path,
      account,
      proxySendHandler,
      proxyAddress,
    );

    dispatch({ type: COMPOUND_SAVER_UNSUBSCRIBE_SUCCESS });
    trackEvent('compoundAutomate', 'unsubscribeSuccess');
  } catch (err) {
    dispatch({ type: COMPOUND_SAVER_UNSUBSCRIBE_FAILURE, payload: err.message });
    captureException(err);
    trackEvent('compoundAutomate', 'unsubscribeError', err.message);
  }
};

export const setCompoundSaverUpdating = payload => (dispatch) => { dispatch({ type: SET_COMPOUND_SAVER_UPDATING, payload }); };
