import React from 'react';

export default function ShifterIcon(props) {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="30" height="30" rx="3" fill="#3A4750" />
      <path d="M7 8C7 7.44772 7.44772 7 8 7H13C13.5523 7 14 7.44772 14 8V15C14 15.5523 13.5523 16 13 16H8C7.44772 16 7 15.5523 7 15V8Z" fill="#626D75" />
      <path d="M16 16C16 15.4477 16.4477 15 17 15H22C22.5523 15 23 15.4477 23 16V23C23 23.5523 22.5523 24 22 24H17C16.4477 24 16 23.5523 16 23V16Z" fill="#626D75" />
      <path d="M16 9H19C19.5523 9 20 9.44772 20 10V13" stroke="#626D75" strokeLinecap="round" />
      <path d="M14 22L11 22C10.4477 22 10 21.5523 10 21L10 18" stroke="#626D75" strokeLinecap="round" />
    </svg>

  );
}
