import React from 'react';
import { v4 as uuid } from 'uuid';

export default function QuickswapIcon(props) {
  const id = `quickswap_icon${uuid()}`;
  const fill = `url(#${id})`;

  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z" fill={fill} />
      <path fillRule="evenodd" clipRule="evenodd" d="M17.8356 7.84337C17.5905 8.30655 17.4595 8.68539 17.5443 8.68539C17.8473 8.68539 17.1562 9.50228 16.4599 9.96732C15.1886 10.8163 14.7119 11.62 15.8127 11.0583C17.3019 10.2986 18.0495 9.30713 18.2693 7.79985C18.3336 7.35911 18.3624 6.99933 18.3336 7C18.3047 7.00084 18.0805 7.38036 17.8356 7.84337ZM14.1293 10.767C13.908 10.9841 13.5751 11.2474 13.3895 11.3521C13.0632 11.5365 13.0647 11.5427 13.4393 11.5478C13.8301 11.5529 14.6546 10.875 14.6546 10.5484C14.6546 10.294 14.5797 10.3252 14.1293 10.767ZM19.1245 12.4138C20.035 12.8808 21.7389 13.4555 21.7389 13.2954C21.7389 13.1446 20.1581 12.2464 20.0309 12.325C19.9537 12.3728 19.5446 12.2945 19.1219 12.1511C17.9913 11.7672 17.9918 11.8327 19.1245 12.4138ZM14.8223 13.2143C15.1463 13.4923 15.374 13.7542 15.3283 13.7966C15.2824 13.8389 14.7411 13.7998 14.1253 13.7094C13.343 13.5947 12.7265 13.6031 12.0789 13.7375C11.0465 13.9519 9.67027 14.5855 9.69422 14.8354C9.72054 15.109 10.491 15.5911 11.0895 15.7083C11.6088 15.8102 11.6475 15.7898 11.5453 15.4676C11.3887 14.9741 11.8721 14.7577 13.1308 14.7577C14.5278 14.7577 15.275 15.0925 15.5966 15.8625C16.4262 17.848 13.4808 19.7086 10.7601 18.9175C9.61798 18.5856 8.28562 17.4167 7.55628 16.1071L7.03979 15.1794L7.14404 16.0792C7.62931 20.2669 11.4265 23.3572 15.6073 22.9667C16.8494 22.8508 18.3683 22.2288 19.0978 21.5372C20.006 20.6765 20.3011 19.8818 20.3008 18.2998C20.3005 17.0562 20.2377 16.7463 19.8272 15.9627C18.872 14.1398 17.263 13.0605 15.1328 12.8132L14.2329 12.7088L14.8223 13.2143ZM10.0161 13.0709C9.9587 13.1637 10.0302 13.2396 10.1748 13.2396C10.3193 13.2396 10.4377 13.1637 10.4377 13.0709C10.4377 12.9782 10.3664 12.9023 10.279 12.9023C10.1916 12.9023 10.0734 12.9782 10.0161 13.0709Z" fill="white" />
      <path d="M20.3051 13.2396C21.7895 14.3191 22.2168 16.4444 22.2449 17.3721C22.8353 14.589 22.0594 7.50469 14.9076 7.50469C12.2931 7.47657 7.06421 9.27577 7.06421 15.1794C7.76702 15.3199 9.20637 15.4661 9.34131 14.9264C9.03208 14.3922 8.78469 13.0541 10.269 11.9746C10.269 12.1713 10.3534 12.5649 10.6907 12.5649C11.0281 12.5649 11.6746 12.1713 11.9558 11.9746H12.5461C12.3493 11.8902 11.9558 11.6035 11.9558 11.1312C11.9558 10.5408 15.0762 9.78179 15.2449 8.93842C15.3799 8.26372 15.526 9.10709 15.5823 9.61312C16.1445 9.20612 17.3196 8.34957 17.522 8.17939C17.775 7.96666 17.6907 9.61312 17.016 10.2878C16.3413 10.9625 20.8124 11.5529 21.5702 12.9866C21.0991 12.6493 20.5305 13.0147 20.3051 13.2396Z" fill="white" />
      <defs>
        <linearGradient id={id} x1="15" y1="0" x2="15" y2="30" gradientUnits="userSpaceOnUse">
          <stop stopColor="#85A6D4" />
          <stop offset="1" stopColor="#485B75" />
        </linearGradient>
      </defs>
    </svg>
  );
}
