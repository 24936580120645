import React from 'react';

export default function SimulationIcon(props) {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="30" height="30" rx="3" fill="#3A4750" />
      <path d="M9.91663 12.8334L9.91663 16.1667" stroke="#626D75" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M19.0833 13.6666C19.0833 15.2137 18.4688 16.6975 17.3748 17.7914C16.2808 18.8854 14.7971 19.5 13.25 19.5" stroke="#626D75" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.8333 7H7V12.8333H12.8333V7Z" fill="#626D75" stroke="#626D75" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22.0001 7H16.1667V12.8333H22.0001V7Z" stroke="#626D75" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.8333 16.1666H7V22H12.8333V16.1666Z" fill="#626D75" stroke="#626D75" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
