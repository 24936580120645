import React from 'react';
import PropTypes from 'prop-types';
import successIcon from './success-warning.svg';

import './SuccessBox.scss';

const SuccessBox = ({ children }) => (
  <div className="success-box-wrapper">
    <img src={successIcon} alt="" />
    <span>
      {children}
    </span>
  </div>
);

SuccessBox.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SuccessBox;
