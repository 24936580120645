import dfs from '@defisaver/sdk';
import cloneDeep from 'lodash/cloneDeep';
import Dec from 'decimal.js';
import { assetAmountInEth, getAssetInfo } from '@defisaver/tokens';

import { MAXUINT } from '../../constants/general';
import RecipeAction from '../RecipeAction';
import { assetAmountInWeiIgnorePointer, changeBalance } from '../../services/recipeCreator/recipeActionUtils';
import { getWstETHByStETH } from '../../services/lidoService';
import { formatNumber } from '../../services/utils';

import {
  AssetAmount, Amount, Source, Asset,
} from '../../components/Recipes/RecipeCreator/inputTypes';
import SellIcon from '../recipeIcons/Swap.svg';

export default class LidoWrapAction extends RecipeAction {
  static prettyName = 'Wrap stETH';

  static protocol = 'lido';

  static protocolPrettyName = 'Lido';

  static description = 'Wraps WETH or stETH into wstETH.';

  constructor(asset = 'stETH', from = 'wallet', amount = '', to = 'wallet') {
    super();
    this.inputs = [
      new Asset('Asset', asset, [getAssetInfo('stETH'), getAssetInfo('WETH')]),
      new Source('From', from),
      new Amount('Amount', amount),
      new Source('To', to, true),
    ];
    this.output = new AssetAmount('output', 0, 'wstETH');
  }

  async toDfsAction(getState, i) {
    const {
      general: { account },
      maker: { proxyAddress },
    } = getState();
    const from = this.inputs[1].value === 'wallet' ? account : proxyAddress;
    const to = this.inputs[3].value === 'wallet' ? account : proxyAddress;
    const assetToWrap = this.inputs[0]._asset;
    const amount = this.inputs[2].value === 'All available' ? MAXUINT : assetAmountInWeiIgnorePointer(this.inputs[2].value, assetToWrap);
    const isEth = assetToWrap === 'WETH';
    return new dfs.actions.lido.LidoWrapAction(amount, from, to, isEth);
  }

  async getAfterValues(_balances = {}, returnValues = [], actions = [], positions = {}, getState) {
    const {
      general: { account },
      maker: { proxyAddress },
    } = getState();
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const balances = cloneDeep(_balances);
    const assetToWrap = this.inputs[0]._asset;
    let amount = args[2] || '0';

    const isSourceWallet = args[1] === 'wallet';
    const balanceAtStart = isSourceWallet ? getState().assets[assetToWrap]?.balance || '0' : '0';
    if (amount === 'All available') amount = balances?.[args[1]]?.[assetToWrap] || balanceAtStart;

    // Ratio between stETH and wstETH is not 1:1
    const wstETHAmount = amount === '0' ? '0' : assetAmountInEth(await getWstETHByStETH(assetAmountInWeiIgnorePointer(amount, 'stETH')), 'wstETH');

    await changeBalance(balances, args[1], assetToWrap, new Dec(amount).mul(-1).toString(), isSourceWallet ? account : proxyAddress);
    await changeBalance(balances, args[3], 'wstETH', wstETHAmount, args[3] === 'wallet' ? account : proxyAddress);
    this.output.value = wstETHAmount;
    return {
      returnValue: this.output,
      balances,
      positions,
    };
  }

  _getPrettyName(actionCalls, actions) {
    const args = this.mapReturnValuesToArgs(actionCalls.map(a => a.returnValue), actions);
    const amount = args[2];
    return `Wrap ${amount === 'All available' ? 'all' : formatNumber(amount)} ${this.inputs[0]._asset} to wstETH`;
  }

  static getIcon() {
    return SellIcon;
  }
}
