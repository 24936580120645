import dfs from '@defisaver/sdk';
import { isId, randomId } from 'services/utils';
import sendIcon from './recipeIcons/Send.svg';

export default class RecipeAction {
  static prettyName = '';

  constructor() {
    this.id = randomId();
  }

  static async prepareAndInstantiate({ args }) {
    if (args) {
      return new this(...args);
    }
    return new this();
  }

  /**
   * @param balances
   * @param returnValues
   * @param actions
   * @param positions
   * @param getState
   * @returns {Promise<{returnValue: String | Array | Object, balances: Object, positions: Object}>}
   */
  async getAfterValues(balances = {}, returnValues = [], actions = [], positions = {}, getState) {
    return {
      balances,
      returnValue: '0',
      positions,
    };
  }

  /**
   * To be used from Action.getAfterValues when calculating after values for the whole recipe
   * @param returnValues {Array<*>} Array of values that previous Actions in Recipe have returned
   * @param actions {Array<*>} Array of ActionWrappers
   * @returns {Array<*>} Actual arguments that will be used when calling this Action
   */
  mapReturnValuesToArgs(returnValues, actions) {
    return this.inputs.map(({ value }) => {
      if (value && (typeof value !== 'boolean') && isId(value.substr?.(1))) {
        const index = actions.findIndex(({ id }) => id === value.substr(1));
        if (index !== -1) return returnValues[index].value;
      }
      return value;
    });
  }

  removeReference(id) {
    this.inputs.forEach((input) => {
      input.removeReference(id);
    });
  }

  async toDfsAction() {
    return new dfs.Action();
  }

  static getIcon() {
    return sendIcon;
  }

  _getPrettyName() {
    return this.constructor.prettyName;
  }

  getAsset() {
    return '';
  }

  getPrettyName(actionCalls, actions) {
    try {
      return this._getPrettyName(actionCalls, actions);
    } catch (err) {
      // console.error(err);
      return this.constructor.prettyName;
    }
  }

  getExpires() {
    return Infinity;
  }

  toJSON() {
    return {
      name: this.constructor.name,
      inputs: this.inputs.map(p => p.value),
    };
  }
}
