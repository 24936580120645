import { change } from 'redux-form';
import t from 'translate';
import Dec from 'decimal.js';
import { captureException } from 'sentry';
import { formatNumber, isWalletTypeProxy } from 'services/utils';
import * as actionTypes from '../../actionTypes/liquityActionTypes/liquityManageActionTypes';
import {
  addCollateral, withdraw, generate, payback, closeTrove, claimCollateral, redeemCollateral,
} from '../../services/liquityServices/liquityManageService';
import { callMultipleTxAction, sendTx } from '../txNotificationActions';
import { setAfterValue } from './liquityManageAfterValues';
import {
  getAdvancedFormMaxValues, getDebtFormMaxValues, getCollateralFormMaxValues,
} from './liquityManageMaxGetters';
import { trackEvent } from '../../services/analyticsService';
import { dispatchLiquityInitActions } from './liquityActions';
import { addApproveTxIfNeeded, approveAddressOnAssetAction, approveTypeToLabelMap } from '../assetsActions';
import { APPROVE_TYPE_DS_PROXY } from '../../actionTypes/assetsActionTypes';
import { calculateTradeSizeImpact } from '../../services/makerServices/makerManageServices/makerManageService';
import { getBestExchangePrice, getSlippageThreshold } from '../../services/exchangeServiceV3';
import { useFlForBoost, useFlForRepay } from '../../services/vaultCommonService';
import { getBorrowingFeePercentage, getRedemptionFeePercentage } from '../../services/liquityServices/liquityService';
import { flProtocolAndFeeFor } from '../../services/assetsService';
import * as liquityRecipes from '../../recipes/liquityRecipes';
import { callRecipeViaProxy } from '../../services/contractCallService';
import { isLayer2Network } from '../../services/ethService';
import { getSubscribedStrategiesAction } from '../startegiesActions/strategiesActions';

const confirmAutomationTrigger = () => async () => true;
// const { ratioTooLow, ratioTooHigh } = getState().liquityManage;
// if (ratioTooLow || ratioTooHigh) {
//   const confirmed = await dispatch(confirmViaModal(t('errors.automatic_trigger')));
//   if (!confirmed) return false;
// }
// return true;

const liquityTrackEventWrapper = (method, message = '') => {
  trackEvent('liquity', method, message);
};

export const addCollateralAction = inputAmount => async (dispatch, getState) => {
  dispatch({ type: actionTypes.LQTY_ADD_COLLATERAL_REQUEST });
  if (!(await dispatch(confirmAutomationTrigger()))) return;

  liquityTrackEventWrapper('addCollateral');
  try {
    const {
      liquity,
      general: { walletType },
    } = getState();
    const txToExecute = [];
    const trove = liquity[walletType.value];

    const proxySendHandler = (promise) => {
      const amount = formatNumber(parseFloat(inputAmount), 2);
      const additionalInfo = {
        protocol: 'liquity',
        firstToken: trove.asset,
        firstAmount: inputAmount,
      };
      return sendTx(promise, `${t('common.add_collateral')}: ${amount} ${trove.asset}`, 'Trove', dispatch, getState, additionalInfo);
    };
    const addCollateralFunctionObject = {
      type: 'supply',
      notifMessage: `${t('common.add_collateral')}: ${formatNumber(parseFloat(inputAmount), 2)} ${trove.asset}`,
      func: () => addCollateral(getState, proxySendHandler, trove, inputAmount),
    };

    txToExecute.push(addCollateralFunctionObject);
    await dispatch(callMultipleTxAction(txToExecute));

    dispatch({ type: actionTypes.LQTY_ADD_COLLATERAL_SUCCESS });
    dispatch(setAfterValue('0', 'clear'));
    dispatch(change('liquityManageCollateralForm', 'addCollateralAmount', null));
    await dispatch(dispatchLiquityInitActions());
    dispatch(getCollateralFormMaxValues());
    liquityTrackEventWrapper('addCollateralSuccess');
  } catch (error) {
    dispatch({ type: actionTypes.LQTY_ADD_COLLATERAL_FAILURE, payload: error.message });
    captureException(error);
    liquityTrackEventWrapper('addCollateralError', error.message);
  }
};

export const withdrawAction = inputAmount => async (dispatch, getState) => {
  if (!(await dispatch(confirmAutomationTrigger()))) return;

  liquityTrackEventWrapper('withdraw');
  dispatch({ type: actionTypes.LQTY_WITHDRAW_COLLATERAL_REQUEST });

  const {
    general: { walletType },
    liquity,
  } = getState();
  const trove = liquity[walletType.value];

  const proxySendHandler = (promise) => {
    const amount = formatNumber(parseFloat(inputAmount), 2);
    const additionalInfo = {
      protocol: 'liquity',
      firstToken: trove.asset,
      firstAmount: inputAmount,
    };
    return sendTx(promise, `${t('common.withdraw')} ${amount} ${trove.asset}`, 'Trove', dispatch, getState, additionalInfo);
  };

  try {
    await withdraw(getState, proxySendHandler, trove, inputAmount);

    dispatch({ type: actionTypes.LQTY_WITHDRAW_COLLATERAL_SUCCESS });
    dispatch(setAfterValue('0', 'clear'));
    dispatch(change('liquityManageCollateralForm', 'withdrawAmount', null));
    await dispatch(dispatchLiquityInitActions());
    dispatch(getCollateralFormMaxValues());
    liquityTrackEventWrapper('withdrawSuccess');
  } catch (error) {
    dispatch({ type: actionTypes.LQTY_WITHDRAW_COLLATERAL_FAILURE, payload: error.message });
    liquityTrackEventWrapper('withdrawError', error.message);
    captureException(error);
  }
};

export const generateAction = inputAmount => async (dispatch, getState) => {
  if (!(await dispatch(confirmAutomationTrigger()))) return;
  liquityTrackEventWrapper('generate');
  dispatch({ type: actionTypes.LQTY_GENERATE_REQUEST });

  const {
    general: { walletType },
    liquity,
  } = getState();
  const trove = liquity[walletType.value];

  const proxySendHandler = (promise) => {
    const amount = formatNumber(parseFloat(inputAmount), 2);
    const additionalInfo = {
      protocol: 'liquity',
      firstToken: 'LUSD',
      firstAmount: inputAmount,
    };
    return sendTx(promise, `${t('common.generate')} ${amount} ${trove.debtAsset}`, 'Trove', dispatch, getState, additionalInfo);
  };

  const maxFeePercentageObj = await getBorrowingFeePercentage(); // TODO liquity show warning if over 1
  try {
    await generate(getState, proxySendHandler, trove, maxFeePercentageObj.amount, inputAmount);

    dispatch({ type: actionTypes.LQTY_GENERATE_SUCCESS });
    dispatch(setAfterValue('0', 'clear'));
    dispatch(change('liquityManageDebtForm', 'generateAmount', null));
    await dispatch(dispatchLiquityInitActions());
    dispatch(getDebtFormMaxValues());
    liquityTrackEventWrapper('generateSuccess');
  } catch (err) {
    dispatch({ type: actionTypes.LQTY_GENERATE_FAILURE, payload: err.message });
    liquityTrackEventWrapper('generateError', err.message);
    captureException(err);
  }
};

export const paybackAction = inputAmount => async (dispatch, getState) => {
  if (!(await dispatch(confirmAutomationTrigger()))) return;

  const {
    maker: { proxyAddress },
    general: { walletType },
    liquity,
  } = getState();
  const isProxy = isWalletTypeProxy(walletType);
  const txToExecute = [];
  const trove = liquity[walletType.value];

  liquityTrackEventWrapper('payback');
  dispatch({ type: actionTypes.LQTY_PAYBACK_REQUEST });

  const proxySendHandler = (promise) => {
    const amount = formatNumber(parseFloat(inputAmount), 2);
    const additionalInfo = {
      protocol: 'liquity',
      firstToken: 'LUSD',
      firstAmount: inputAmount,
    };
    return sendTx(promise, `${t('common.payback')} ${amount} ${trove.debtAsset}`, 'Trove', dispatch, getState, additionalInfo);
  };

  const approveFunctionObject = {
    type: 'approve',
    notifMessage: `${t('common.approve')} ${approveTypeToLabelMap[APPROVE_TYPE_DS_PROXY]} ${t('common.for')} LUSD`,
    func: () => dispatch(approveAddressOnAssetAction('LUSD', proxyAddress, APPROVE_TYPE_DS_PROXY)),
  };
  const paybackFunctionObject = {
    type: 'payback',
    notifMessage: `${t('common.payback')}: ${formatNumber(parseFloat(inputAmount), 2)} LUSD`,
    func: () => payback(getState, proxySendHandler, trove, inputAmount),
  };

  try {
    if (isProxy) await dispatch(addApproveTxIfNeeded('LUSD', proxyAddress, inputAmount, APPROVE_TYPE_DS_PROXY, txToExecute, approveFunctionObject));
    txToExecute.push(paybackFunctionObject);
    await dispatch(callMultipleTxAction(txToExecute));

    dispatch({ type: actionTypes.LQTY_PAYBACK_SUCCESS });
    dispatch(setAfterValue('0', 'clear'));
    dispatch(change('liquityManageDebtForm', 'paybackAmount', null));
    await dispatch(dispatchLiquityInitActions());
    dispatch(getDebtFormMaxValues());
    liquityTrackEventWrapper('paybackSuccess');
  } catch (err) {
    dispatch({ type: actionTypes.LQTY_PAYBACK_FAILURE, payload: err.message });
    liquityTrackEventWrapper('paybackError', err.message);
    captureException(err);
  }
};

export const closeTroveAction = (closeModal) => async (dispatch, getState) => {
  if (!(await dispatch(confirmAutomationTrigger()))) return;

  const txToExecute = [];

  const {
    maker: { proxyAddress },
    general: { walletType },
    liquity,
  } = getState();
  const trove = liquity[walletType.value];
  const isProxy = isWalletTypeProxy(walletType);
  liquityTrackEventWrapper('close');
  dispatch({ type: actionTypes.LQTY_CLOSE_TROVE_REQUEST });

  const proxySendHandler = (promise) => {
    const additionalInfo = {
      protocol: 'liquity',
    };
    return sendTx(promise, `${t('common.close')}`, 'Trove', dispatch, getState, additionalInfo);
  };

  const approveFunctionObject = {
    type: 'approve',
    notifMessage: `${t('common.approve')} ${approveTypeToLabelMap[APPROVE_TYPE_DS_PROXY]} ${t('common.for')} LUSD`,
    func: () => dispatch(approveAddressOnAssetAction('LUSD', proxyAddress, APPROVE_TYPE_DS_PROXY)),
  };
  const closeFunctionObject = {
    type: 'close',
    notifMessage: `${t('common.close')}`,
    func: () => closeTrove(getState, proxySendHandler, trove),
  };

  try {
    if (isProxy) await dispatch(addApproveTxIfNeeded('LUSD', proxyAddress, trove.debtInAsset, APPROVE_TYPE_DS_PROXY, txToExecute, approveFunctionObject));
    txToExecute.push(closeFunctionObject);
    await dispatch(callMultipleTxAction(txToExecute));

    dispatch({ type: actionTypes.LQTY_CLOSE_TROVE_SUCCESS });
    await dispatch(dispatchLiquityInitActions());
    dispatch(getDebtFormMaxValues());
    dispatch(getSubscribedStrategiesAction());
    closeModal();
    liquityTrackEventWrapper('closeSuccess');
  } catch (err) {
    dispatch({ type: actionTypes.LQTY_CLOSE_TROVE_FAILURE, payload: err.message });
    liquityTrackEventWrapper('closeError', err.message);
    captureException(err);
  }
};

export const claimCollateralAction = () => async (dispatch, getState) => {
  liquityTrackEventWrapper('claimCollateral');
  dispatch({ type: actionTypes.LQTY_CLAIM_COLLATERAL_REQUEST });

  const proxySendHandler = (promise) => {
    const additionalInfo = {
      protocol: 'liquity',
    };
    return sendTx(promise, `${t('common.claim')}`, 'Trove', dispatch, getState, additionalInfo);
  };

  try {
    await claimCollateral(getState, proxySendHandler);

    dispatch({ type: actionTypes.LQTY_CLAIM_COLLATERAL_SUCCESS });
    await dispatch(dispatchLiquityInitActions());
    liquityTrackEventWrapper('claimCollateralSuccess');
  } catch (err) {
    dispatch({ type: actionTypes.LQTY_CLAIM_COLLATERAL_FAILURE, payload: err.message });
    liquityTrackEventWrapper('claimCollateralError', err.message);
    captureException(err);
  }
};

export const getBoostModalData = inputAmount => async (dispatch, getState) => {
  dispatch({ type: actionTypes.LQTY_GET_BOOST_MODAL_DATA_REQUEST });
  try {
    const { general: { account }, liquity } = getState();
    const trove = liquity.proxy;
    const { price: exchangeRate, source } = await getBestExchangePrice(inputAmount, trove.debtAsset, 'WETH', account, true, true, true);
    const boostAmount = new Dec(exchangeRate).times(inputAmount).toString();

    const marketPrice = await getSlippageThreshold(trove.debtAsset, 'WETH');
    const tradeSizeImpact = calculateTradeSizeImpact(marketPrice, exchangeRate);

    const useFl = useFlForBoost(trove, inputAmount);
    let flProtocol = 'none';
    let flFee = '0';
    let useAltRecipe = false;
    if (useFl) {
      let flData = await flProtocolAndFeeFor(inputAmount, trove.collAsset, getState().general.network);
      if (flData.protocol === 'none') {
        useAltRecipe = true;
        flData = await flProtocolAndFeeFor(boostAmount, trove.asset, getState().general.network);
      }
      flProtocol = flData.protocol;
      flFee = flData.flFee;
    }

    dispatch({
      type: actionTypes.LQTY_GET_BOOST_MODAL_DATA_SUCCESS,
      payload: {
        boostAmount,
        boostExchangeRate: exchangeRate,
        exchangeSource: source,
        flFee,
        tradeSizeImpact,
        useFl,
        flProtocol,
        useAltRecipe,
      },
    });
  } catch (err) {
    dispatch({ type: actionTypes.LQTY_GET_BOOST_MODAL_DATA_FAILURE, payload: err.message });
    captureException(err);
  }
};

export const boostAction = (inputAmount, closeModal) => async (dispatch, getState) => {
  liquityTrackEventWrapper('boost');
  dispatch({ type: actionTypes.LQTY_BOOST_REQUEST });

  const {
    general: { walletType, account, accountType },
    maker: { proxyAddress },
    liquityManage: {
      slippagePercent, boostExchangeRate, boostAmount, useFl, flProtocol,
    },
    liquity,
  } = getState();
  const trove = liquity[walletType.value];

  const sendTxFunc = (promise) => {
    const amount = formatNumber(parseFloat(inputAmount), 2);
    const additionalInfo = {
      protocol: 'liquity',
      firstToken: 'LUSD',
      firstAmount: inputAmount,
      secondToken: trove.asset,
      secondAmount: boostAmount,
    };
    return sendTx(promise, `${t('common.boost')} ${amount} ${trove.debtAsset}`, 'Trove', dispatch, getState, additionalInfo);
  };

  try {
    const maxFeePercentageObj = await getBorrowingFeePercentage(); // TODO liquity show warning if over 1
    const recipe = useFl
      ? await liquityRecipes.boostWithCollLoan(inputAmount, boostExchangeRate, slippagePercent, trove, maxFeePercentageObj.amount, proxyAddress, flProtocol)
      : await liquityRecipes.boost(inputAmount, boostExchangeRate, slippagePercent, trove, maxFeePercentageObj.amount, proxyAddress);
    await callRecipeViaProxy(accountType, sendTxFunc, proxyAddress, account, recipe, 0, recipe.extraGas);

    dispatch({ type: actionTypes.LQTY_BOOST_SUCCESS });
    dispatch(setAfterValue('0', 'clear'));
    dispatch(change('liquityManageAdvancedForm', 'boostAmount', null));
    closeModal();
    await dispatch(dispatchLiquityInitActions());
    dispatch(getAdvancedFormMaxValues());
    liquityTrackEventWrapper('boostSuccess');
  } catch (err) {
    dispatch({ type: actionTypes.LQTY_BOOST_FAILURE, payload: err.message });
    liquityTrackEventWrapper('boostError', err.message);
    captureException(err);
  }
};

export const resetBoostModal = () => (dispatch) => { dispatch({ type: actionTypes.LQTY_RESET_BOOST_MODAL }); };

export const getRepayModalData = inputAmount => async (dispatch, getState) => {
  dispatch({ type: actionTypes.LQTY_GET_REPAY_MODAL_DATA_REQUEST });

  try {
    const { general: { account }, liquity } = getState();
    const trove = liquity.proxy;
    const { price: exchangeRate, source } = await getBestExchangePrice(inputAmount, 'WETH', trove.debtAsset, account, true, true, true);

    const repayAmount = new Dec(inputAmount).times(exchangeRate).toString();

    const marketPrice = await getSlippageThreshold('WETH', trove.debtAsset);
    const tradeSizeImpact = calculateTradeSizeImpact(marketPrice, exchangeRate);

    const useFl = useFlForRepay(trove, inputAmount);
    const flData = await flProtocolAndFeeFor(inputAmount, trove.asset, getState().general.network);

    const payload = {
      repayAmount,
      tradeSizeImpact,
      useFl,
      useAltRecipe: flData.useAltRecipe,
      flProtocol: flData.protocol,
      flFee: flData.flFee,
      repayExchangeRate: exchangeRate,
      exchangeSource: source,
    };

    dispatch({ type: actionTypes.LQTY_GET_REPAY_MODAL_DATA_SUCCESS, payload });
  } catch (err) {
    dispatch({ type: actionTypes.LQTY_GET_REPAY_MODAL_DATA_FAILURE, payload: err.message });
    captureException(err);
  }
};

export const repayAction = (inputAmount, closeModal) => async (dispatch, getState) => {
  liquityTrackEventWrapper('repay');
  dispatch({ type: actionTypes.LQTY_REPAY_REQUEST });

  const {
    general: { walletType, account, accountType },
    maker: { proxyAddress },
    liquityManage: {
      slippagePercent, repayExchangeRate, repayAmount, useFl,
    },
    liquity,
  } = getState();
  const trove = liquity[walletType.value];

  const sendTxFunc = (promise) => {
    const amount = formatNumber(parseFloat(inputAmount), 2);
    const additionalInfo = {
      protocol: 'liquity',
      firstToken: trove.asset,
      firstAmount: inputAmount,
      secondToken: 'LUSD',
      secondAmount: repayAmount,
    };
    return sendTx(promise, `${t('common.repay')} ${amount} ${trove.asset}`, 'Trove', dispatch, getState, additionalInfo);
  };

  try {
    const recipe = useFl
      ? await liquityRecipes.repayWithCollLoan(inputAmount, repayExchangeRate, slippagePercent, trove, proxyAddress)
      : await liquityRecipes.repay(inputAmount, repayExchangeRate, slippagePercent, trove, proxyAddress);
    await callRecipeViaProxy(accountType, sendTxFunc, proxyAddress, account, recipe, 0, recipe.extraGas);
    dispatch({ type: actionTypes.LQTY_REPAY_SUCCESS });
    dispatch(setAfterValue('0', 'clear'));
    dispatch(change('liquityManageAdvancedForm', 'repayAmount', null));
    closeModal();
    await dispatch(dispatchLiquityInitActions());
    dispatch(getAdvancedFormMaxValues());
    liquityTrackEventWrapper('repaySuccess');
  } catch (err) {
    dispatch({ type: actionTypes.LQTY_REPAY_FAILURE, payload: err.message });
    liquityTrackEventWrapper('repayError', err.message);
    captureException(err);
  }
};

export const resetRepayModal = () => (dispatch) => { dispatch({ type: actionTypes.LQTY_RESET_REPAY_MODAL }); };


export const redeemCollateralAction = (inputAmount, outputAmount, maxFee, closeModal) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.LQTY_REDEEM_REQUEST });
  if (!(await dispatch(confirmAutomationTrigger()))) return;

  liquityTrackEventWrapper('redeemCollateral');
  try {
    const txToExecute = [];

    const proxySendHandler = (promise) => {
      const amount = formatNumber(parseFloat(inputAmount), 2);
      const additionalInfo = {
        protocol: 'liquity',
        firstToken: 'LUSD',
        firstAmount: inputAmount,
        secondToken: 'ETH',
        secondAmount: outputAmount,
      };
      return sendTx(promise, `${t('common.redeem')}: ${amount} LUSD`, 'Redeem', dispatch, getState, additionalInfo);
    };

    const maxFeePercentageObj = await getRedemptionFeePercentage();
    const redeemCollateralFunctionObject = {
      type: 'redeem',
      notifMessage: `${t('common.redeem')}: ${formatNumber(parseFloat(inputAmount), 2)} LUSD`,
      func: () => redeemCollateral(getState, proxySendHandler, inputAmount, maxFeePercentageObj.amount),
    };

    txToExecute.push(redeemCollateralFunctionObject);
    await dispatch(callMultipleTxAction(txToExecute));

    dispatch({ type: actionTypes.LQTY_REDEEM_SUCCESS });
    dispatch(change('liquityRedeemForm', 'redeemAmount', ''));
    closeModal();
    await dispatch(dispatchLiquityInitActions());
    liquityTrackEventWrapper('redeemCollateralSuccess');
  } catch (error) {
    dispatch({ type: actionTypes.LQTY_REDEEM_FAILURE, payload: error.message });
    captureException(error);
    liquityTrackEventWrapper('redeemCollateralError', error.message);
  }
};

