import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Linkify from 'react-linkify';
import { ACCOUNT_TYPES } from '../../../constants/general';
import {
  changeServerNotification, markServerNotificationsAsRead,
} from '../../../actions/serverNotificationsActions';

import twitterIcon from '../../../icons/twitter.svg';

import './SingleServerNotification.scss';

const SingleServerNotification = ({
  title, type, read, description, id, timestamp, share,
  changeServerNotification, markServerNotificationsAsRead, accountType,
}) => {
  const [open, setOpen] = useState(false);

  const UNREAD_MAX = 250;
  const greaterThanMax = description.length > UNREAD_MAX;
  const descriptionUnread = greaterThanMax ? `${description.substring(0, UNREAD_MAX)}...` : description;
  const descriptionModif = open ? description : descriptionUnread;

  const noCursor = read && !greaterThanMax;

  return (
    <div
      className={`
        single-server-notification-wrapper
        ${share && 'share'}
        ${noCursor && 'no-cursor'}
        ${read ? 'read' : 'not-read'}
        ${type}
      `}
      onClick={() => {
        setOpen(!open);

        if ((accountType !== ACCOUNT_TYPES.debug && accountType !== ACCOUNT_TYPES.viewOnly) && !read) {
          changeServerNotification(id, { read: true });
          markServerNotificationsAsRead(id);
        }
      }}
    >
      <div className="single-server-notification-header">
        {
        share &&
        (
          <a
            className="twitter-share-button"
            href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(share)}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={twitterIcon} alt="" />
            Share
          </a>
        )
      }
        <div className="title">{title}</div>
        <div className="date">{(new Date(timestamp)).toLocaleString()}</div>
      </div>
      <div className="description">
        {
          (open || !greaterThanMax)
            ? (
              <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                  <a target="blank" rel="noopener noreferrer" href={decoratedHref} key={key}>{decoratedText}</a>
                )}
              >
                {descriptionModif}
              </Linkify>
            )
            : descriptionModif
        }
      </div>
    </div>
  );
};

SingleServerNotification.propTypes = {
  title: PropTypes.string.isRequired,
  share: PropTypes.string,
  description: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  read: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  timestamp: PropTypes.string.isRequired,
  changeServerNotification: PropTypes.func.isRequired,
  markServerNotificationsAsRead: PropTypes.func.isRequired,
  accountType: PropTypes.string.isRequired,
};

SingleServerNotification.defaultProps = {
  share: null,
};

const mapStateToProps = ({ general }) => ({
  accountType: general.accountType,
});
const mapDispatchToProps = { changeServerNotification, markServerNotificationsAsRead };

export default connect(mapStateToProps, mapDispatchToProps)(SingleServerNotification);
