import dfs from '@defisaver/sdk';
import RecipeAction from '../RecipeAction';
import { Amount, Source } from '../../components/Recipes/RecipeCreator/inputTypes';
import { closeAfterValues } from '../../actions/liquityActions/liquityManageAfterValues';
import CloseIcon from '../recipeIcons/Close.svg';

export default class LiquityCloseAction extends RecipeAction {
  static prettyName = 'Close Trove';

  static protocol = 'liquity';

  static protocolPrettyName = 'Liquity';

  static description = 'Closes an existing Trove by paying back LUSD debt and withdrawing (W)ETH.';

  constructor(from = 'wallet', to = 'wallet') {
    super();
    this.inputs = [
      new Source('From', from),
      new Source('To', to, true),
    ];
    this.output = new Amount('output', 0);
  }

  async getAfterValues(_balances = {}, returnValues = [], actions = [], _positions = {}, getState) {
    const positions = { ..._positions };
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const {
      liquity: { proxy },
      liquityStaking: { proxy: stakingProxy },
    } = getState();
    if (!positions.liquity) positions.liquity = { ...proxy, ...stakingProxy };
    const { afterPosition, balances, returnValue } = await closeAfterValues({
      from: args[0],
      to: args[1],
    }, {
      vault: positions.liquity,
      assets: getState().assets,
      account: getState().general.account,
      proxyAddress: getState().maker.proxyAddress,
    },
    _balances);
    positions.liquity = afterPosition;
    this.output.value = returnValue;
    return { returnValue: this.output, balances, positions };
  }

  async toDfsAction(getState) {
    const {
      general: { account },
      maker: { proxyAddress },
    } = getState();
    const from = this.inputs[0].value === 'wallet' ? account : proxyAddress;
    const to = this.inputs[1].value === 'wallet' ? account : proxyAddress;
    return new dfs.actions.liquity.LiquityCloseAction(from, to);
  }

  setAsset(asset) {
    this.asset = asset;
  }

  getAsset() {
    return this.asset;
  }

  static getIcon() {
    return CloseIcon;
  }
}
