import TransferCdpModal from './Maker/TransferCdpModal/TransferCdpModal';
import MakerRepayModal from './Maker/MakerRepayModal/MakerRepayModal';
import MakerBoostModal from './Maker/MakerBoostModal/MakerBoostModal';
import ShortModal from './ShortModal/ShortModal';
import LeverageModal from './LeverageModal/LeverageModal';
import NonWeb3ProviderModal from './NonWeb3ProviderModal/NonWeb3ProviderModal';
import CompoundUserWalletModal from './UserWalletModal/CompoundUserWalletModal';
import AaveUserWalletModal from './UserWalletModal/AaveUserWalletModal';
import AaveEModeModal from './Aave/AaveEModeModal/AaveEModeModal';
import LedgerConnectModal from './LedgerConnectModal/LedgerConnectModal';
import TrezorConnectModal from './TrezorConnectModal/TrezorConnectModal';
import MigrateCdpFromInstadappModal from './Maker/CdpImportModal/MigrateCdpFromInstadappModal';
import UnsubscribeFeedbackModal from './UnsubscribeFeedbackModal/UnsubscribeFeedbackModal';
import ConfirmationModal from './ConfirmationModal/ConfirmationModal';
import InfoModal from './InfoModal/InfoModal';
import SendConfirmationModal from './SendConfirmationModal/SendConfirmationModal';
import CloseMcdCdpModal from './Maker/CloseMcdCdpModal/CloseMcdCdpModal';
import MakerCreateCdpSuccessModal from './Maker/MakerCreateCdpSuccessModal/MakerCreateCdpSuccessModal';
import { CompoundCollateralRequiredModal, AaveCollateralRequiredModal } from './CollateralRequiredModal/CollateralRequiredModal';
import CompoundBoostModal from './Compound/CompoundBoostModal/CompoundBoostModal';
import CompoundRepayModal from './Compound/CompoundRepayModal/CompoundRepayModal';
import CompoundProxyMigrationModal from './Compound/CompoundProxyMigrationModal/CompoundProxyMigrationModal';
import CompRedeemModal from './Compound/CompRedeemModal/CompRedeemModal';
import AddressMonitorModal from './AddressMonitorModal/AddressMonitorModal';
import LoginModal from './LoginModal/LoginModal';
import AaveBoostModal from './Aave/AaveBoostModal/AaveBoostModal';
import AaveRepayModal from './Aave/AaveRepayModal/AaveRepayModal';
import AaveBoostModalV2 from './Aave/AaveBoostModalV2/AaveBoostModalV2';
import AaveRepayModalV2 from './Aave/AaveRepayModalV2/AaveRepayModalV2';
import AaveProxyMigrationModal from './Aave/AaveProxyMigrationModal/AaveProxyMigrationModal';
import ShifterSuccessModal from './Shifter/ShifterSuccessModal/ShifterSuccessModal';
import AaveBorrowModal from './Aave/AaveBorrowModal/AaveBorrowModal';
import AaveStakeModal from './Aave/AaveStakeModal/AaveStakeModal';
import SmartWalletPickerModal from './SmartWalletPickerModal/SmartWalletPickerModal';
import ReflexerBoostModal from './Reflexer/ReflexerBoostModal/ReflexerBoostModal';
import ReflexerRepayModal from './Reflexer/ReflexerRepayModal/ReflexerRepayModal';
import ReflexerSafeCreatedModal from './Reflexer/ReflexerSafeCreatedModal/ReflexerSafeCreatedModal';
import SimulationStartModal from './SimulationStartModal/SimulationStartModal';
import SimulationEndModal from './SimulationEndModal/SimulationEndModal';
import RecipeBookItemModal from './RecipeBookItemModal/RecipeBookItemModal';

import CloseTroveModal from './Liquity/LiquityCloseTroveModal/LiquityCloseTroveModal';
import LiquityBoostModal from './Liquity/LiquityBoostModal/LiquityBoostModal';
import LiquityRepayModal from './Liquity/LiquityRepayModal/LiquityRepayModal';
import LiquityStakingModal from './Liquity/LiquityStakingModal/LiquityStakingModal';
import LiquityRedeemModal from './Liquity/LiquityRedeemModal/LiquityRedeemModal';
import RecipeSuccessModal from './RecipeSuccessModal/RecipeSuccessModal';
import MetamaskChangeAccModal from './MetamaskChangeAccModal/MetamaskChangeAccModal';
import ManageWalletsModal from './ManageWalletsModal/ManageWalletsModal';
// import EmergencyMessageModal from './EmergencyMessageModal/EmergencyMessageModal';

import {
  TRANSFER_CDP_MODAL,
  MAKER_REPAY_MODAL,
  MAKER_BOOST_MODAL,
  SHORT_MODAL,
  LEVERAGE_MODAL,
  NON_WEB3_PROVIDER_MODAL,
  COMPOUND_USER_WALLET_MODAL,
  AAVE_USER_WALLET_MODAL,
  AAVE_EMODE_MODAL,
  TREZOR_CONNECT_MODAL,
  LEDGER_CONNECT_MODAL,
  MIGRATE_CDP_FROM_INSTADAPP_MODAL,
  AUTOMATION_UNSUB_FEEDBACK_MODAL,
  CONFIRMATION_MODAL,
  SEND_CONFIRMATION_MODAL,
  CLOSE_MCD_CDP_MODAL,
  CDP_CREATED_MODAL,
  COMPOUND_COLLATERAL_REQUIRED_MODAL,
  COMPOUND_BOOST_MODAL,
  COMPOUND_REPAY_MODAL,
  COMPOUND_PROXY_MIGRATION_MODAL,
  COMP_REDEEM_MODAL,
  ADDRESS_MONITOR_MODAL,
  LOGIN_MODAL,
  AAVE_PROXY_MIGRATION_MODAL,
  AAVE_BOOST_MODAL,
  AAVE_REPAY_MODAL,
  AAVE_COLLATERAL_REQUIRED_MODAL,
  SHIFTER_SUCCESS_MODAL,
  AAVE_BORROW_MODAL,
  AAVE_STAKE_MODAL,
  SMARTWALLET_PICKER_MODAL,
  RFL_REPAY_MODAL,
  RFL_BOOST_MODAL,
  RFL_SAFE_CREATED_MODAL,
  CHANGE_NETWORK_MODAL,
  SIM_START_MODAL,
  SIM_END_MODAL,
  RECIPE_BOOK_ITEM_MODAL,
  LQTY_CLOSE_TROVE_MODAL,
  LQTY_BOOST_MODAL,
  LQTY_REPAY_MODAL,
  LQTY_STAKING_MODAL,
  LQTY_REDEEM_MODAL,
  RECIPE_SUCCESS_MODAL,
  MM_CHANGE_MODAL,
  MANAGE_WALLETS_MODAL,
  MIGRATE_COMPOUND_FROM_INSTADAPP_MODAL,
  MIGRATE_AAVE_FROM_INSTADAPP_MODAL,
  CREATE_SMART_WALLET_MODAL,
  SMART_SAVINGS_CLAIM_AND_SUPPLY,
  AAVE_BOOST_MODAL_V2,
  AAVE_REPAY_MODAL_V2,
  INFO_MODAL,
  AAVE_PROXY_V1_MIGRATION_MODAL,
  AUTOMATION_UNSUB_NEXT_STEPS_MODAL,
  TROVE_CREATED_MODAL,
  UNISWAP_V3_IMAGE_MODAL,
  // EMERGENCY_MESSAGE_MODAL,
} from './modalTypes';
import CompoundInstadappMigrationModal from './Compound/CompoundInstadappMigrationModal/CompoundInstadappMigrationModal';
import AaveInstadappMigrationModal from './Aave/AaveInstadappMigrationModal/AaveInstadappMigrationModal';
import CreateSmartWalletModal from './CreateSmartWalletModal/CreateSmartWalletModal';
import SmartSavingsClaimAndSupplyModal from './SmartSavingsClaimAndSupplyModal/SmartSavingsClaimAndSupplyModal';
import AaveProxyMigrationModalV1 from './Aave/AaveProxyMigrationModalV1/AaveProxyMigrationModalV1';
import AutomationUnsubNextStepsModal from './AutomationUnsubNextStepsModal/AutomationUnsubNextStepsModal';
import LiquityOpenTroveModal from './Liquity/LiquityOpenTroveModal/LiquityOpenTroveModal';
import UniswapV3ImageModal from './UniswapV3/UniswapV3ImageModal/UniswapV3ImageModal';
import ChangeNetworkModal from './ChangeNetworkModal/ChangeNetworkModal';

export default {
  [TRANSFER_CDP_MODAL]: TransferCdpModal,
  [MAKER_REPAY_MODAL]: MakerRepayModal,
  [MAKER_BOOST_MODAL]: MakerBoostModal,
  [SHORT_MODAL]: ShortModal,
  [LEVERAGE_MODAL]: LeverageModal,
  [NON_WEB3_PROVIDER_MODAL]: NonWeb3ProviderModal,
  [COMPOUND_USER_WALLET_MODAL]: CompoundUserWalletModal,
  [AAVE_USER_WALLET_MODAL]: AaveUserWalletModal,
  [AAVE_EMODE_MODAL]: AaveEModeModal,
  [LEDGER_CONNECT_MODAL]: LedgerConnectModal,
  [TREZOR_CONNECT_MODAL]: TrezorConnectModal,
  [MIGRATE_CDP_FROM_INSTADAPP_MODAL]: MigrateCdpFromInstadappModal,
  [AUTOMATION_UNSUB_FEEDBACK_MODAL]: UnsubscribeFeedbackModal,
  [CONFIRMATION_MODAL]: ConfirmationModal,
  [INFO_MODAL]: InfoModal,
  [SEND_CONFIRMATION_MODAL]: SendConfirmationModal,
  [CLOSE_MCD_CDP_MODAL]: CloseMcdCdpModal,
  [CDP_CREATED_MODAL]: MakerCreateCdpSuccessModal,
  [COMPOUND_COLLATERAL_REQUIRED_MODAL]: CompoundCollateralRequiredModal,
  [AAVE_COLLATERAL_REQUIRED_MODAL]: AaveCollateralRequiredModal,
  [COMPOUND_BOOST_MODAL]: CompoundBoostModal,
  [COMPOUND_REPAY_MODAL]: CompoundRepayModal,
  [COMPOUND_PROXY_MIGRATION_MODAL]: CompoundProxyMigrationModal,
  [MIGRATE_COMPOUND_FROM_INSTADAPP_MODAL]: CompoundInstadappMigrationModal,
  [COMP_REDEEM_MODAL]: CompRedeemModal,
  [ADDRESS_MONITOR_MODAL]: AddressMonitorModal,
  [LOGIN_MODAL]: LoginModal,
  [AAVE_BOOST_MODAL]: AaveBoostModal,
  [AAVE_REPAY_MODAL]: AaveRepayModal,
  [AAVE_BOOST_MODAL_V2]: AaveBoostModalV2,
  [AAVE_REPAY_MODAL_V2]: AaveRepayModalV2,
  [AAVE_PROXY_MIGRATION_MODAL]: AaveProxyMigrationModal,
  [AAVE_PROXY_V1_MIGRATION_MODAL]: AaveProxyMigrationModalV1,
  [MIGRATE_AAVE_FROM_INSTADAPP_MODAL]: AaveInstadappMigrationModal,
  [SHIFTER_SUCCESS_MODAL]: ShifterSuccessModal,
  [AAVE_BORROW_MODAL]: AaveBorrowModal,
  [AAVE_STAKE_MODAL]: AaveStakeModal,
  [SMARTWALLET_PICKER_MODAL]: SmartWalletPickerModal,
  [RFL_REPAY_MODAL]: ReflexerRepayModal,
  [RFL_BOOST_MODAL]: ReflexerBoostModal,
  [RFL_SAFE_CREATED_MODAL]: ReflexerSafeCreatedModal,
  [CHANGE_NETWORK_MODAL]: ChangeNetworkModal,
  [SIM_START_MODAL]: SimulationStartModal,
  [SIM_END_MODAL]: SimulationEndModal,
  [RECIPE_BOOK_ITEM_MODAL]: RecipeBookItemModal,
  [RECIPE_SUCCESS_MODAL]: RecipeSuccessModal,
  [LQTY_CLOSE_TROVE_MODAL]: CloseTroveModal,
  [LQTY_BOOST_MODAL]: LiquityBoostModal,
  [LQTY_REPAY_MODAL]: LiquityRepayModal,
  [LQTY_STAKING_MODAL]: LiquityStakingModal,
  [LQTY_REDEEM_MODAL]: LiquityRedeemModal,
  [MM_CHANGE_MODAL]: MetamaskChangeAccModal,
  [MANAGE_WALLETS_MODAL]: ManageWalletsModal,
  [CREATE_SMART_WALLET_MODAL]: CreateSmartWalletModal,
  [SMART_SAVINGS_CLAIM_AND_SUPPLY]: SmartSavingsClaimAndSupplyModal,
  [AUTOMATION_UNSUB_NEXT_STEPS_MODAL]: AutomationUnsubNextStepsModal,
  [TROVE_CREATED_MODAL]: LiquityOpenTroveModal,
  [UNISWAP_V3_IMAGE_MODAL]: UniswapV3ImageModal,
  // [EMERGENCY_MESSAGE_MODAL]: EmergencyMessageModal,
};
