import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import WalletIcon from './WalletIcon';
import { ACCOUNT_TYPES } from '../../../constants/general';
import { openChangeNetworkModal } from '../../../actions/modalActions';
import NetworkIcon from './NetworkIcon';

const ConnectWalletIcon = (props) => {
  const {
    additionalAccountData, forkAccountType, network, clickedWallet, clickedNetwork,
  } = props;

  if (forkAccountType) {
    return (
      <div className="connect-wallet-icon-wrapper" onClick={clickedWallet}>
        {forkAccountType && (
          <div className="secondary-icon">
            <WalletIcon accData={additionalAccountData} />
          </div>
        )}
        <div className="wallet-icon-wrapper fork">
          <WalletIcon accData={{ accountType: forkAccountType }} />
        </div>
      </div>
    );
  }

  return (
    <div className="connect-wallet-icon-wrapper">
      <div className="wallet-icon-wrapper" onClick={clickedWallet}>
        <WalletIcon accData={additionalAccountData} />
      </div>
      <div className="network-icon-wrapper" onClick={clickedNetwork}>
        <NetworkIcon chainId={network} />
      </div>
    </div>
  );
};

ConnectWalletIcon.propTypes = {
  additionalAccountData: PropTypes.object.isRequired,
  forkAccountType: PropTypes.string,
  network: PropTypes.number.isRequired,
  clickedWallet: PropTypes.func.isRequired,
  clickedNetwork: PropTypes.func.isRequired,
};

export default ConnectWalletIcon;
