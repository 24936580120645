import {
  ADD_NOTIFICATION,
  CHANGE_NOTIFICATION,
  CLOSE_NOTIFICATION,
  ADD_CONFIRMATION,
  CLOSE_CONFIRMATION,
  PENDING_TX,
  CLEAR_PENDING_TX,
  CURRENT_TX,
} from '../actionTypes/notificationsActionTypes';
import { CONNECT_PROVIDER_SUCCESS } from '../actionTypes/generalActionTypes';

const INITIAL_STATE = {
  notifications: [],
  confirmations: [],
  pendingTxs: [],
  currentTx: 0,
  numOfNotifications: 0,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_NOTIFICATION:
      return { ...state, notifications: [...state.notifications, payload] };

    case CLOSE_NOTIFICATION:
    case CHANGE_NOTIFICATION:
      return { ...state, notifications: payload };

    case ADD_CONFIRMATION:
      return { ...state, confirmations: [...state.confirmations, payload] };

    case CLOSE_CONFIRMATION:
      return { ...state, confirmations: state.confirmations.filter(conf => conf.id !== payload.id) };

    case CONNECT_PROVIDER_SUCCESS:
      if (payload.dontClearNotifications) return { ...state };
      return { ...INITIAL_STATE };

    case PENDING_TX:
      return { ...state, pendingTxs: payload.pendingTxs, numOfNotifications: payload.numOfNotifications };

    case CLEAR_PENDING_TX:
      return { ...state, pendingTxs: [], currentTx: 0 };
    case CURRENT_TX:
      return { ...state, currentTx: payload };
    default:
      return state;
  }
};
