import { CONNECT_PROVIDER_SUCCESS } from '../../actionTypes/generalActionTypes';
import { RESET_BR_SLIPPAGE_VALUES, SET_BR_SLIPPAGE_PERCENT } from '../../actionTypes/modalActionTypes';
import * as compManageTypes from '../../actionTypes/compoundActionTypes/compoundManageActionTypes';

const INITIAL_STATE = {
  fetchingManageData: false,
  fetchingManageDataError: '',

  fetchingAssetsData: false,
  fetchingAssetsDataError: '',
  assetsData: {},

  fetchingAccountData: false,
  fetchingAccountDataError: '',
  usedAssets: {},

  suppliedUsd: '0',
  borrowedUsd: '0',
  borrowLimitUsd: '0',
  leftToBorrowUsd: '0',
  netApy: '0',
  incentiveUsd: '0',
  totalInterestUsd: '0',
  ratio: '0',
  minRatio: '0',
  isSubscribedToAutomation: false,
  automationResubscribeRequired: false,
  leveragedType: '',
  leveragedAsset: '',
  liquidationPrice: '0',
  lastUpdated: 0,

  actionsSelectValues: {},

  collateralState: {},

  afterValue: null,
  afterType: '',
  gettingAfterValue: false,
  gettingAfterValueError: '',

  supplying: false,
  supplyingError: '',
  maxSupply: '0',
  gettingMaxSupply: false,
  gettingMaxSupplyError: '',

  withdrawing: false,
  withdrawingError: '',
  maxWithdraw: '0',
  gettingMaxWithdraw: false,
  gettingMaxWithdrawError: '',

  borrowing: false,
  borrowingError: '',
  maxBorrow: '0',
  gettingMaxBorrow: false,
  gettingMaxBorrowError: '',

  payingBack: false,
  payingBackError: '',
  maxPayback: '0',
  gettingMaxPayback: false,
  gettingMaxPaybackError: '',

  boosting: false,
  boostingError: '',

  maxBoost: '0',
  gettingMaxBoost: false,
  gettingMaxBoostError: '',

  boostAmount: '0',
  boostExchangeRate: '0',
  gettingBoostModalData: false,
  gettingBoostModalDataError: '',

  repaying: false,
  repayingError: '',
  maxRepay: '0',
  gettingMaxRepay: false,
  gettingMaxRepayError: '',

  repayAmount: '0',
  repayExchangeRate: '0',
  gettingRepayModalData: false,
  gettingRepayModalDataError: '',

  useFl: false,
  flProtocol: 'none',
  flFee: '0',
  useAltRecipe: false,
  exchangeSource: '',

  activeTab: '',

  slippagePercent: '0',
  slippageRate: '0',
  tradeSizeImpact: '0',

  migratingToProxy: false,
  migratingToProxyError: '',

  compBalance: {
    account: '0',
    smartWallet: '0',
  },
  compBalanceLoading: false,
  compBalanceError: '',

  fetchingProxyData: false,
  fetchingProxyDataError: '',

  // afterType: '',
  afterCdp: null,
  ratioTooLow: false,
  ratioTooHigh: false,

  maxValues: {},
  loadingMaxValues: {},
  maxValuesErrors: {},

  selectedAction: { label: 'None', value: '' },
  selectedAdditionalActions: {},

  executing: {},
  executingErrors: {},
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload, asset } = action;

  switch (type) {
    case compManageTypes.FETCH_COMPOUND_MANAGE_DATA_REQUEST:
      return {
        ...state,
        fetchingManageData: true,
        fetchingManageDataError: '',
      };

    case compManageTypes.FETCH_COMPOUND_MANAGE_DATA_SUCCESS:
      return {
        ...state,
        fetchingManageData: false,
        fetchingManageDataError: '',
      };

    case compManageTypes.FETCH_COMPOUND_MANAGE_DATA_FAILURE:
      return {
        ...state,
        fetchingManageData: false,
        fetchingManageDataError: payload,
      };

    case compManageTypes.FETCH_COMPOUND_ASSETS_DATA_REQUEST:
      return {
        ...state,
        fetchingAssetsData: true,
        fetchingAssetsDataError: '',
      };

    case compManageTypes.FETCH_COMPOUND_ASSETS_DATA_SUCCESS:
      return {
        ...state,
        fetchingAssetsData: false,
        fetchingAssetsDataError: '',
        assetsData: payload,
      };

    case compManageTypes.FETCH_COMPOUND_ASSETS_DATA_FAILURE:
      return {
        ...state,
        fetchingAssetsData: false,
        fetchingAssetsDataError: payload,
      };

    case compManageTypes.FETCH_COMPOUND_ACCOUNT_DATA_REQUEST:
      return {
        ...state,
        fetchingAccountData: true,
        fetchingAccountDataError: '',
      };

    case compManageTypes.FETCH_COMPOUND_ACCOUNT_DATA_SUCCESS:
      return {
        ...state,
        fetchingAccountData: false,
        fetchingAccountDataError: '',
        ...payload,
      };

    case compManageTypes.FETCH_COMPOUND_ACCOUNT_DATA_FAILURE:
      return {
        ...state,
        fetchingAccountData: false,
        fetchingAccountDataError: payload,
      };

    case compManageTypes.COMPOUND_SUPPLY_REQUEST:
      return {
        ...state,
        supplying: true,
        supplyingError: '',
        executing: {
          ...state.executing,
          supply: true,
        },
        executingErrors: {
          ...state.executingErrors,
          supply: '',
        },
      };

    case compManageTypes.COMPOUND_SUPPLY_SUCCESS:
      return {
        ...state,
        supplying: false,
        supplyingError: '',
        executing: {
          ...state.executing,
          supply: false,
        },
        executingErrors: {
          ...state.executingErrors,
          supply: '',
        },
      };

    case compManageTypes.COMPOUND_SUPPLY_FAILURE:
      return {
        ...state,
        supplying: false,
        supplyingError: payload,
        executing: {
          ...state.executing,
          supply: false,
        },
        executingErrors: {
          ...state.executingErrors,
          supply: payload,
        },
      };

    case compManageTypes.GET_COMPOUND_AFTER_VALUE_REQUEST:
      return { ...state, gettingAfterValue: true, gettingAfterValueError: '' };

    case compManageTypes.GET_COMPOUND_AFTER_VALUE_SUCCESS:
      return {
        ...state,
        gettingAfterValue: false,
        gettingAfterValueError: '',
        ...payload,
      };

    case compManageTypes.GET_COMPOUND_AFTER_VALUE_FAILURE:
      return {
        ...state,
        gettingAfterValue: false,
        gettingAfterValueError: payload,
      };

    case compManageTypes.COMPOUND_WITHDRAW_REQUEST:
      return {
        ...state,
        withdrawing: true,
        withdrawingError: '',
        executing: {
          ...state.executing,
          withdraw: true,
        },
        executingErrors: {
          ...state.executingErrors,
          withdraw: '',
        },
      };

    case compManageTypes.COMPOUND_WITHDRAW_SUCCESS:
      return {
        ...state,
        withdrawing: false,
        withdrawingError: '',
        executing: {
          ...state.executing,
          withdraw: false,
        },
        executingErrors: {
          ...state.executingErrors,
          withdraw: '',
        },
      };

    case compManageTypes.COMPOUND_WITHDRAW_FAILURE:
      return {
        ...state,
        withdrawing: false,
        withdrawingError: payload,
        executing: {
          ...state.executing,
          withdraw: false,
        },
        executingErrors: {
          ...state.executingErrors,
          withdraw: payload,
        },
      };

    case compManageTypes.COMPOUND_BORROW_REQUEST:
      return {
        ...state,
        borrowing: true,
        borrowingError: '',
        executing: {
          ...state.executing,
          borrow: true,
        },
        executingErrors: {
          ...state.executingErrors,
          borrow: '',
        },
      };

    case compManageTypes.COMPOUND_BORROW_SUCCESS:
      return {
        ...state,
        borrowing: false,
        borrowingError: '',
        executing: {
          ...state.executing,
          borrow: false,
        },
        executingErrors: {
          ...state.executingErrors,
          borrow: '',
        },
      };

    case compManageTypes.COMPOUND_BORROW_FAILURE:
      return {
        ...state,
        borrowing: false,
        borrowingError: payload,
        executing: {
          ...state.executing,
          borrow: false,
        },
        executingErrors: {
          ...state.executingErrors,
          borrow: payload,
        },
      };

    case compManageTypes.ENABLE_COMPOUND_ASSET_AS_COLLATERAL_REQUEST:
      return {
        ...state,
        collateralState: {
          ...state.collateralState,
          [action.asset]: { ...state.collateralState[action.asset], enabling: true, enablingError: '' },
        },
      };

    case compManageTypes.ENABLE_COMPOUND_ASSET_AS_COLLATERAL_SUCCESS:
      return {
        ...state,
        collateralState: {
          ...state.collateralState,
          [action.asset]: { ...state.collateralState[action.asset], enabling: false, enablingError: '' },
        },
      };

    case compManageTypes.ENABLE_COMPOUND_ASSET_AS_COLLATERAL_FAILURE:
      return {
        ...state,
        collateralState: {
          ...state.collateralState,
          [action.asset]: { ...state.collateralState[action.asset], enabling: false, enablingError: payload },
        },
      };

    case compManageTypes.DISABLE_COMPOUND_ASSET_AS_COLLATERAL_REQUEST:
      return {
        ...state,
        collateralState: {
          ...state.collateralState,
          [action.asset]: { ...state.collateralState[action.asset], disabling: true, disablingError: '' },
        },
      };

    case compManageTypes.DISABLE_COMPOUND_ASSET_AS_COLLATERAL_SUCCESS:
      return {
        ...state,
        collateralState: {
          ...state.collateralState,
          [action.asset]: { ...state.collateralState[action.asset], disabling: false, disablingError: '' },
        },
      };

    case compManageTypes.DISABLE_COMPOUND_ASSET_AS_COLLATERAL_FAILURE:
      return {
        ...state,
        collateralState: {
          ...state.collateralState,
          [action.asset]: { ...state.collateralState[action.asset], disabling: false, disablingError: payload },
        },
      };

    case compManageTypes.COMPOUND_PAYBACK_REQUEST:
      return {
        ...state,
        payingBack: true,
        payingBackError: '',
        executing: {
          ...state.executing,
          payback: true,
        },
        executingErrors: {
          ...state.executingErrors,
          payback: '',
        },
      };

    case compManageTypes.COMPOUND_PAYBACK_SUCCESS:
      return {
        ...state,
        payingBack: false,
        payingBackError: '',
        executing: {
          ...state.executing,
          payback: false,
        },
        executingErrors: {
          ...state.executingErrors,
          payback: '',
        },
      };

    case compManageTypes.COMPOUND_PAYBACK_FAILURE:
      return {
        ...state,
        payingBack: false,
        payingBackError: payload,
        executing: {
          ...state.executing,
          payback: false,
        },
        executingErrors: {
          ...state.executingErrors,
          payback: payload,
        },
      };

    case compManageTypes.COMPOUND_BOOST_REQUEST:
      return { ...state, boosting: true, boostingError: '' };

    case compManageTypes.COMPOUND_BOOST_SUCCESS:
      return {
        ...state,
        boosting: false,
        boostingError: '',
      };

    case compManageTypes.COMPOUND_BOOST_FAILURE:
      return {
        ...state,
        boosting: false,
        boostingError: payload,
      };

    case compManageTypes.COMPOUND_REPAY_REQUEST:
      return { ...state, repaying: true, repayingError: '' };

    case compManageTypes.COMPOUND_REPAY_SUCCESS:
      return {
        ...state,
        repaying: false,
        repayingError: '',
      };

    case compManageTypes.COMPOUND_REPAY_FAILURE:
      return {
        ...state,
        repaying: false,
        repayingError: payload,
      };

    case compManageTypes.COMPOUND_MANAGE_SET_ACTIVE_TAB:
      return { ...state, activeTab: payload };

    case compManageTypes.COMPOUND_MANAGE_SET_ACTION_SELECT_VALUES:
      return {
        ...state,
        actionsSelectValues: {
          ...state.actionsSelectValues,
          [payload.key]: payload.selectValue,
        },
      };

    case compManageTypes.GET_BOOST_MODAL_DATA_REQUEST:
      return {
        ...state,
        gettingBoostModalData: true,
        gettingBoostModalDataError: '',
        executing: {
          ...state.executing,
          boost: false,
        },
        executingErrors: {
          ...state.executingErrors,
          boost: payload,
        },
      };

    case compManageTypes.GET_BOOST_MODAL_DATA_SUCCESS:
      return {
        ...state,
        gettingBoostModalData: false,
        gettingBoostModalDataError: '',
        ...payload,
      };

    case compManageTypes.GET_BOOST_MODAL_DATA_FAILURE:
      return {
        ...state,
        gettingBoostModalData: false,
        gettingBoostModalDataError: payload,
      };

    case compManageTypes.RESET_BOOST_MODAL:
      return {
        ...state,
        boosting: false,
        boostingError: '',
        boostAmount: '0',
        boostExchangeRate: '0',
        gettingBoostModalData: false,
        gettingBoostModalDataError: '',
      };

    case compManageTypes.GET_REPAY_MODAL_DATA_REQUEST:
      return {
        ...state,
        gettingRepayModalData: true,
        gettingRepayModalDataError: '',
        executing: {
          ...state.executing,
          repay: false,
        },
        executingErrors: {
          ...state.executingErrors,
          repay: payload,
        },
      };

    case compManageTypes.GET_REPAY_MODAL_DATA_SUCCESS:
      return {
        ...state,
        gettingRepayModalData: false,
        gettingRepayModalDataError: '',
        ...payload,
      };

    case compManageTypes.GET_REPAY_MODAL_DATA_FAILURE:
      return {
        ...state,
        gettingRepayModalData: false,
        gettingRepayModalDataError: payload,
      };

    case compManageTypes.RESET_REPAY_MODAL:
      return {
        ...state,
        repaying: false,
        repayingError: '',
        gettingRepayModalData: false,
        gettingRepayModalDataError: '',
      };

    case SET_BR_SLIPPAGE_PERCENT:
      return {
        ...state,
        slippagePercent: payload.slippagePercent,
        slippageRate: payload.slippageRate,
      };

    case RESET_BR_SLIPPAGE_VALUES:
      return {
        ...state,
        slippagePercent: '0',
        slippageRate: '0',
        tradeSizeImpact: '0',
      };

    case compManageTypes.COMP_BALANCE_REQUEST:
      return { ...state, compBalanceLoading: true, compBalanceError: '' };

    case compManageTypes.COMP_BALANCE_SUCCESS:
      return {
        ...state,
        compBalanceLoading: false,
        compBalanceError: '',
        compBalance: payload,
      };

    case compManageTypes.COMP_BALANCE_FAILURE:
      return {
        ...state,
        compBalanceLoading: false,
        compBalanceError: payload,
      };

    case compManageTypes.FETCH_COMPOUND_PROXY_DATA_REQUEST:
      return {
        ...state,
        fetchingProxyData: true,
        fetchingProxyDataError: '',
      };
    case compManageTypes.FETCH_COMPOUND_PROXY_DATA_SUCCESS:
      return {
        ...state,
        fetchingProxyData: false,
        fetchingProxyDataError: '',
      };
    case compManageTypes.FETCH_COMPOUND_PROXY_DATA_FAILURE:
      return {
        ...state,
        fetchingProxyData: false,
        fetchingProxyDataError: payload,
      };

      // NEW


    case compManageTypes.COMP_SET_DASHBOARD_ACTION:
      return {
        ...state,
        selectedAction: payload,
        afterCdp: null,
      };

    case compManageTypes.COMP_SET_ADDITIONAL_DASHBOARD_ACTION:
      return {
        ...state,
        selectedAdditionalActions: {
          ...state.selectedAdditionalActions,
          [state.selectedAction.value]: payload,
        },
        afterCdp: null,
      };

    case compManageTypes.COMP_ACTION_EXEC_REQUEST:
      return {
        ...state,
        executing: {
          ...state.executing,
          [action.action]: true,
        },
        executingErrors: {
          ...state.executingErrors,
          [action.action]: '',
        },
      };

    case compManageTypes.COMP_ACTION_EXEC_SUCCESS:
      return {
        ...state,
        executing: {
          ...state.executing,
          [action.action]: false,
        },
      };

    case compManageTypes.COMP_ACTION_EXEC_FAILURE:
      return {
        ...state,
        executing: {
          ...state.executing,
          [action.action]: false,
        },
        executingErrors: {
          ...state.executingErrors,
          [action.action]: payload,
        },
      };

    case compManageTypes.COMP_GET_MAX_VALUE_REQUEST:
      return {
        ...state,
        loadingMaxValues: {
          ...state.loadingMaxValues,
          [action.forAction]: true,
        },
        maxValuesErrors: {
          ...state.loadingMaxValues,
          [action.forAction]: '',
        },
      };

    case compManageTypes.COMP_GET_MAX_VALUE_SUCCESS:
      return {
        ...state,
        loadingMaxValues: {
          ...state.loadingMaxValues,
          [action.forAction]: false,
        },
        maxValues: {
          ...state.maxValues,
          [action.forAction]: payload,
        },
      };

    case compManageTypes.COMP_GET_MAX_VALUE_FAILURE:
      return {
        ...state,
        loadingMaxValues: {
          ...state.loadingMaxValues,
          [action.forAction]: false,
        },
        maxValuesErrors: {
          ...state.loadingMaxValues,
          [action.forAction]: payload,
        },
      };

    case CONNECT_PROVIDER_SUCCESS:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};
