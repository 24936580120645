import React from 'react';
import t from 'translate';
import PropTypes from 'prop-types';
import { formatNumber } from '../../services/utils';
import TooltipWrapper from '../TooltipWrapper/TooltipWrapper';

import './AfterVal.scss';

const AfterVal = ({
  type, loading, cdp, cdpProp, symbol, color, tooltip, showSymbolLoading, decimals,
  symbolAfter,
}) => {
  let val = 0;
  let valLabel = 0;

  if (!loading && cdp) {
    val = cdp[cdpProp];
    valLabel = `${symbolAfter ? '' : symbol}${formatNumber(cdp[cdpProp], decimals)}${symbolAfter ? symbol : ''}`;

    if (val === 0 || val === Infinity) {
      val = '0';
      valLabel = `${symbolAfter ? '' : symbol}0${symbolAfter ? symbol : ''}`;
    }
  }

  const repayDaiDebt = type === 'repay' && cdpProp === 'debtDai';

  let colorClass = '';
  if (color !== '') colorClass = typeof color === 'boolean' ? (color ? 'green' : 'red') : '';

  return (
    <div className={`after-value ${type}`}>
      {
        loading && (
          <div className="loading-wrapper">
            { !symbolAfter && showSymbolLoading && (<div className="loading-symbol before">{symbol}</div>) }
            { loading && t('common.loading') }
            { symbolAfter && showSymbolLoading && (<div className="loading-symbol">{symbol}</div>) }
          </div>
        )
      }
      {
        !loading && cdp && (
          <div className="amount-wrapper">
            <span className="after">{t('common.after')}:</span>
            <span className={`amount ${colorClass}`} style={{ color }}>
              <TooltipWrapper copy title={tooltip || val}>
                {((type === 'repay' || type === 'boost') && !repayDaiDebt) ? '~' : ''}{valLabel}
              </TooltipWrapper>
            </span>
          </div>
        )
      }
    </div>
  );
};

AfterVal.defaultProps = {
  cdp: null,
  symbol: '',
  color: '',
  tooltip: '',
  decimals: 2,
  showSymbolLoading: false,
  symbolAfter: true,
};

AfterVal.propTypes = {
  type: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  cdp: PropTypes.object,
  cdpProp: PropTypes.string.isRequired,
  symbol: PropTypes.string,
  color: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  tooltip: PropTypes.string,
  showSymbolLoading: PropTypes.bool,
  decimals: PropTypes.number,
  symbolAfter: PropTypes.bool,
};

export default AfterVal;
