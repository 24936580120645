import { CurveViewContract, CurveViewAddress, CurveEthStethMinterContract } from 'services/contractRegistryService';
import { assetAmountInEth } from '@defisaver/tokens';
import { compareAddresses, getEthAmountForDecimals, getWeiAmountForDecimals } from '../utils';
import { ZERO_ADDRESS } from '../../constants/general';
import { aggregate } from '../ethService';


export const getCurveAddressesForUser = async (address) => {
  const contract = await CurveViewContract();

  const positions = [];
  let startIndex = 0;
  do {
    // eslint-disable-next-line no-await-in-loop
    const { lpBalances, nextIndex } = await contract.methods.getUserLP(address, startIndex, 10, 100).call();
    positions.push(...(lpBalances.filter(lpPosition => !compareAddresses(lpPosition.lpToken, ZERO_ADDRESS))));
    startIndex = nextIndex;
  } while (+startIndex);

  return positions;
};

export const getCurveData = async (tokens) => {
  if (!tokens.length) return [];
  const multicallObject = tokens.map(token => ({
    target: CurveViewAddress,
    call: ['getPoolDataFromLpToken(address)(uint256,address,string,address[8],uint256[8],uint256[8],address[8],uint256[8],uint256[8],address[10],int128[10])', token.lpToken],
    returns: [
      [`price${token.lpToken}`, val => assetAmountInEth(val.toString())],
      [`minter${token.lpToken}`, val => val.toString()],
      [`poolName${token.lpToken}`, val => val.toString()],
      [`tokens${token.lpToken}`, val => val.filter(token => !compareAddresses(token, ZERO_ADDRESS))],
      [`decimals${token.lpToken}`, val => val.map(decimal => decimal.toString()).filter(decimal => +decimal !== 0)],
      [`balances${token.lpToken}`, val => val.map(decimal => decimal.toString()).filter(decimal => +decimal !== 0)],
      [`underlyingTokens${token.lpToken}`, val => val.filter(token => !compareAddresses(token, ZERO_ADDRESS))],
      [`underlyingDecimals${token.lpToken}`, val => val.map(decimal => decimal.toString()).filter(decimal => +decimal !== 0)],
      [`underlyingBalances${token.lpToken}`, val => val.map(decimal => decimal.toString()).filter(decimal => +decimal !== 0)],
      [`gauges${token.lpToken}`, val => val.filter(token => !compareAddresses(token, ZERO_ADDRESS))],
      [`gaugeTypes${token.lpToken}`, val => val],
    ],
  }));
  const res = await aggregate(multicallObject);

  const {
    results: {
      transformed,
    },
  } = res;
  return tokens.map(token => ({
    address: token.lpToken,
    price: transformed[`price${token.lpToken}`],
    minter: transformed[`minter${token.lpToken}`],
    poolName: transformed[`poolName${token.lpToken}`],
    tokens: transformed[`tokens${token.lpToken}`],
    decimals: transformed[`decimals${token.lpToken}`],
    balances: transformed[`balances${token.lpToken}`],
    underlyingTokens: transformed[`underlyingTokens${token.lpToken}`],
    underlyingDecimals: transformed[`underlyingDecimals${token.lpToken}`],
    underlyingBalances: transformed[`underlyingBalances${token.lpToken}`],
    gauges: transformed[`gauges${token.lpToken}`],
  }));
};

export const getBurnAmountOneToken = async (amount, index) => {
  const contract = await CurveEthStethMinterContract();
  const amounts = await contract.methods.calc_withdraw_one_coin(getWeiAmountForDecimals(amount, 18), index).call();
  return assetAmountInEth(amounts, 'ETH');
};

export const getLpEstimate = async (ethAmountInWei = '0', stEthAmountInWei = '0', isDeposit = true) => {
  const contract = await CurveEthStethMinterContract();
  const lpEstimate = await contract.methods.calc_token_amount([ethAmountInWei, stEthAmountInWei], isDeposit).call();
  return getEthAmountForDecimals(lpEstimate, 18);
};
