import * as aaveCreateTypes from '../../actionTypes/aaveActionTypes/aaveCreateActionTypes';

const INITIAL_STATE = {
  creatingAave: false,
  creatingAaveError: '',

  calculating: false,

  newNetApy: '0',
  newSafetyRatio: '0',
  usedAssetsChanged: {},
  afterAssets: {},
  newSupplied: '0',
  newBorrowed: '0',
  changedStats: false,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case aaveCreateTypes.CREATE_AAVE_REQUEST: return {
      ...state, creatingAave: false, creatingAaveError: '',
    };

    case aaveCreateTypes.CREATE_AAVE_SUCCESS: return {
      ...state, creatingAave: false, creatingAaveError: '',
    };

    case aaveCreateTypes.CREATE_AAVE_FAILURE: return {
      ...state, creatingAave: false, creatingAaveError: payload,
    };

    case aaveCreateTypes.CREATE_AAVE_CALCULATING_VALUES: return {
      ...state, calculating: true,
    };

    case aaveCreateTypes.SET_CREATE_AAVE_CALC_VALUES: return {
      ...state,
      newNetApy: payload.netApy,
      newSafetyRatio: payload.ratio,
      usedAssetsChanged: payload.usedAssetsChanged,
      afterAssets: payload.afterAssets,
      newSupplied: payload.suppliedUsd,
      newBorrowed: payload.borrowedUsd,
      changedStats: true,
      calculating: false,
    };

    case aaveCreateTypes.RESET_CREATE_AAVE_FORM: return {
      ...state,
      creatingCompound: false,
      creatingCompoundError: '',
      newNetApy: '0',
      newSafetyRatio: '0',
      usedAssetsChanged: {},
      afterAssets: {},
      newSupplied: '0',
      newBorrowed: '0',
      changedStats: false,
      calculating: false,
    };

    default:
      return state;
  }
};
