import React from 'react';
import PropTypes from 'prop-types';
import StepStatus from '../../../../Decorative/StepStatus/StepStatus';

import './MigrateModalStatusSteps.scss';

const MigrateModalStatusSteps = ({ steps }) => (
  <div className="Flex SpaceBetween FlexWrap Width100 import-steps-wrapper">
    {steps.map(({ title, status }) => <StepStatus key={title} title={title} status={status} />)}
  </div>
);
MigrateModalStatusSteps.propTypes = {
  steps: PropTypes.array.isRequired,
};
export default MigrateModalStatusSteps;
