import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducers/index';
import { closeModalOnConnectMiddleware } from './middleware';

const reduxDevToolsEnchancer = process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true, traceLimit: 25 });
const createStoreWithMiddleware = applyMiddleware(thunk, closeModalOnConnectMiddleware)(createStore);
const store = createStoreWithMiddleware(reducers, reduxDevToolsEnchancer);
if (process.env.NODE_ENV !== 'production') window.store = store;

export default store;
