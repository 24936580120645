import dfs from '@defisaver/sdk';
import cloneDeep from 'lodash/cloneDeep';
import Dec from 'decimal.js';
import { assetAmountInWei } from '@defisaver/tokens';
import RecipeAction from '../RecipeAction';
import {
  Amount, AssetAmount,
} from '../../components/Recipes/RecipeCreator/inputTypes';
import SellIcon from '../recipeIcons/Swap.svg';
import { formatNumber } from '../../services/utils';
import {
  assetAmountInWeiIgnorePointer,
  changeBalance,
} from '../../services/recipeCreator/recipeActionUtils';

export default class WrapEthAction extends RecipeAction {
  static prettyName = 'Wrap ETH';

  static description = 'Wraps ETH into WETH, the ERC20 token.';

  constructor(amount = '') {
    super();
    this.inputs = [
      new AssetAmount('Amount', amount, 'ETH'),
    ];
    this.output = new AssetAmount('output', 0, 'WETH');
  }

  async getAfterValues(_balances = {}, returnValues = [], actions = [], positions = {}, getState) {
    const { proxyAddress } = getState().maker;
    const { account } = getState().general;
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const amount = args[0];
    const balances = cloneDeep(_balances);
    await changeBalance(balances, 'wallet', 'ETH', new Dec(amount || 0).mul(-1), account);
    await changeBalance(balances, 'recipe', 'WETH', amount || 0, proxyAddress);
    this.output.value = amount;
    return { returnValue: this.output, balances, positions };
  }

  async toDfsAction(getState) {
    const amount = assetAmountInWeiIgnorePointer(this.inputs[0].value, 'ETH');
    return new dfs.actions.basic.WrapEthAction(amount);
  }

  static getIcon() {
    return SellIcon;
  }

  _getPrettyName(actionCalls, actions) {
    const args = this.mapReturnValuesToArgs(actionCalls.map(a => a.returnValue), actions);
    return `Wrap ${formatNumber(args[0])} ETH to WETH`;
  }
}
