import React from 'react';


export default function LongIcon(props) {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="15" cy="15" r="15" fill="#9B51E0" />
      <path d="M10 20L20 10M20 10V14.7619M20 10H15.2381" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
