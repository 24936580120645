import Dec from 'decimal.js';
import { assetAmountInEth, ilkToAsset } from '@defisaver/tokens';
import { GebSafeManagerContract, RaiLoanInfoContract } from '../contractRegistryService';
import { bytesToString, stringToBytes } from '../utils';
import { getLsExistingItemAndState } from '../localStorageService';
import { momoize } from '../memoization';

/**
 * @typedef {Object} RaiSafeInfo
 * @property {number} id
 * @property {('standard'|'instadapp')} type
 * @property {string} owner
 * @property {'ETH'} asset
 * @property {string} collType
 * @property {string} collateral
 * @property {string} collateralUsd
 * @property {string} assetPrice
 * @property {string} debtAsset
 * @property {string} debtInAsset
 * @property {string} debtUsd
 * @property {string} debtAssetPrice
 * @property {string} debtAssetMarketPrice
 * @property {string} unnormalizedDebt - to be multiplied with RaiCollTypeInfo.rate
 * @property {string} liquidationPrice
 * @property {number} ratio
 * @property {number} liqPercent - Replacing liqRatio * 100
 * @property {string} unclaimedCollateral
 * @property {boolean} debtTooLow
 * @property {boolean} isSubscribedToAutomation
 * @property {string} redemptionRate
 * @property {number} minDebt
 * @property {string} creatableDebt
 * @property {string} futurePrice
 * @property {string} futurePriceTimestamp
 * @property {string} stabilityFee
 * @property {number} lastUpdated
 */

/**
 * @typedef {Object} RaiSafeBasicInfo
 * @property {number} id
 * @property {string} owner
 * @property {string} collateral
 * @property {string} unnormalizedDebt - to be multiplied with RaiCollTypeInfo.rate
 * @property {string} collType
 * @property {'ETH'} asset
 * @property {('standard'|'instadapp')} type
 */

/**
 * @typedef {Object} RaiCollTypeInfo
 * @property {string} currentRate
 * @property {string} globalDebtCurrent
 * @property {string} globalDebtCeiling
 * @property {string} assetPrice
 * @property {number} liqPercent - Replacing liqRatio * 100
 * @property {number} minDebt
 * @property {string} raiPrice
 * @property {string} raiTargetPrice
 * @property {string} redemptionRate
 * @property {string} futurePrice
 * @property {string} futurePriceTimestamp
 * @property {string} stabilityFee
 */


const parseCollTypeInfo = (collTypeInfo, futureData) => {
  const currentRate = new Dec(collTypeInfo.currRate).div(1e27).toString();
  const dust = new Dec(collTypeInfo.dust).div(1e27).div(1e18).toNumber();
  const globalDebtCurrent = new Dec(collTypeInfo.currDebtAmount).div(1e18).toString();
  const globalDebtCeiling = new Dec(collTypeInfo.debtCeiling).div(1e18).div(1e27).toString();
  const assetPrice = new Dec(collTypeInfo.assetPrice).div(1e27).toString();
  const liqPercent = new Dec(collTypeInfo.liqRatio).div(1e25).toNumber();
  const secondsPerYear = 60 * 60 * 24 * 365;
  const _stabilityFee = new Dec(collTypeInfo.stabilityFee.toString())
    .div(1e27)
    .pow(secondsPerYear)
    .minus(1)
    .mul(10000)
    .toNumber();
  const stabilityFee = (Math.round(_stabilityFee) / 100).toString();

  return {
    currentRate,
    globalDebtCurrent,
    globalDebtCeiling,
    assetPrice,
    liqPercent,
    minDebt: dust,
    futurePrice: futureData.futurePrice,
    futurePriceTimestamp: futureData.futurePriceTimestamp,
    stabilityFee,
  };
};

const parseGlobalRaiInfo = (raiInfo) => {
  const raiPrice = new Dec(raiInfo.currRaiPrice).div(1e18).toString();
  const raiTargetPrice = new Dec(raiInfo.redemptionPrice).div(1e27).toString();
  const redemptionRate = new Dec(raiInfo.redemptionRate).div(1e27).pow(365.25 * 24 * 3600).sub(1)
    .mul(100)
    .toString();
  return {
    raiPrice,
    raiTargetPrice,
    redemptionRate,
  };
};

/**
 * @param safe {RaiSafeBasicInfo}
 * @param collTypeData {RaiCollTypeInfo}
 * @returns {RaiSafeInfo}
 */
const parseSafeInfo = (safe, collTypeData) => {
  const debtRai = new Dec(safe.unnormalizedDebt).mul(collTypeData.currentRate).toString();
  const debtUsd = new Dec(debtRai).mul(collTypeData.raiTargetPrice).toString();
  const collateralUsd = new Dec(safe.collateral).mul(collTypeData.assetPrice).toString();
  const liquidationPrice = new Dec(debtUsd).times(collTypeData.liqPercent / 100).div(safe.collateral).toString();
  const ratio = collateralUsd / debtUsd * 100;
  const debtTooLow = new Dec(debtRai).gt(0) && new Dec(debtRai).lt(collTypeData.minDebt);
  const creatableDebt = new Dec(collTypeData.globalDebtCeiling).sub(collTypeData.globalDebtCurrent).toString();
  return {
    id: safe.id,
    type: safe.type,
    owner: safe.owner,
    asset: safe.asset,
    collType: safe.collType,
    collateral: safe.collateral,
    collateralUsd,
    assetPrice: collTypeData.assetPrice,
    debtAsset: 'RAI',
    debtInAsset: debtRai,
    debtUsd,
    debtAssetPrice: collTypeData.raiTargetPrice,
    debtAssetMarketPrice: collTypeData.raiPrice,
    unnormalizedDebt: safe.unnormalizedDebt,
    liquidationPrice,
    ratio,
    liqPercent: collTypeData.liqPercent,
    unclaimedCollateral: '0',
    debtTooLow,
    isSubscribedToAutomation: false,
    redemptionRate: collTypeData.redemptionRate,
    minDebt: collTypeData.minDebt,
    creatableDebt,
    futurePrice: collTypeData.futurePrice,
    futurePriceTimestamp: collTypeData.futurePriceTimestamp,
    stabilityFee: collTypeData.stabilityFee,
    lastUpdated: Date.now(),
  };
};

export const getRaiIlkInfo = (collType) => {
  const _collType = (collType.substr(0, 2) === '0x' ? bytesToString(collType) : collType).toUpperCase();
  const ilkData = {
    RAI: {
      join: '0x0A5653CCa4DB1B6E265F47CAf6969e64f1CFdC45',
    },
    'ETH-A': {
      join: '0x2D3cD7b81c93f188F3CB8aD87c8Acc73d6226e3A',
      pip: '0xD4A0E3EC2A937E7CCa4A192756a8439A8BF4bA91',
    },
  };
  if (!ilkData[_collType]) throw new Error('Unknown ilk');
  return ilkData[_collType];
};

const getCollTypeFuturePrice = async (collTypeBytes) => {
  const ilkInfo = getRaiIlkInfo(collTypeBytes);

  const timePosition = ilkInfo.isLP ? 5 : 2;
  const pricePosition = ilkInfo.isLP ? 7 : 4;
  const priceStorage = await window._web3.eth.getStorageAt(ilkInfo.pip, pricePosition);
  const timeStorage = await window._web3.eth.getStorageAt(ilkInfo.pip, timePosition);

  const lastUpdateTimestamp = parseInt(timeStorage.slice(14, 22), 16);
  const futurePriceTimestamp = lastUpdateTimestamp + 3600;
  const futurePrice = new Dec(`0x${priceStorage.slice(-32)}`).div(1e18).toString();
  return {
    futurePrice,
    futurePriceTimestamp,
  };
};

/**
 * @DEV Memoize this
 *
 * @param {string} collTypeBytes
 * @returns {Promise<RaiCollTypeInfo>}
 */
export const getCollateralInfo = async (collTypeBytes) => {
  const raiLoanInfoContract = RaiLoanInfoContract();
  const { collInfo, raiInfo } = await raiLoanInfoContract.methods.getCollAndRaiInfo(collTypeBytes).call();
  const futureData = await getCollTypeFuturePrice(collTypeBytes);
  return {
    ...parseCollTypeInfo(collInfo, futureData),
    ...parseGlobalRaiInfo(raiInfo),
  };
};

export const getCollateralInfoMemoized = momoize(getCollateralInfo, { promise: true, maxAge: 60 * 1000 });

/**
 * Not used currently
 * @async
 * @param safe {RaiSafeBasicInfo}
 * @returns {Promise<RaiSafeInfo>}
 */
export const getSafeInfo = async (safe) => {
  const raiLoanInfoContract = RaiLoanInfoContract();
  const data = await raiLoanInfoContract.methods.getSafeInfo(safe.id).call();
  const updatedSafeData = {
    ...safe,
    // id: parseInt(data.safeId, 10),
    // owner: '', // missing in getSafeInfo.data
    // type: 'standard',
    collateral: assetAmountInEth(data.coll, ilkToAsset(data.collType)),
    unnormalizedDebt: assetAmountInEth(data.debt, 'RAI'),
    collType: bytesToString(data.collType),
    asset: ilkToAsset(data.collType),
  };
  const collTypeData = await getCollateralInfo(data.collType);
  return parseSafeInfo(updatedSafeData, collTypeData);
};

/**
 * @param address {string}
 * @returns {Promise<Object<number, RaiSafeInfo>>}
 */
export const getUserSafesIfMultipleCollTypesExist = async (address) => {
  const raiLoanInfoContract = RaiLoanInfoContract();
  const safeData = await raiLoanInfoContract.methods.getUserSafesFullInfo(address).call();
  const basicSafesInfo = safeData.map((safe) => ({
    id: parseInt(safe.safeId, 10),
    owner: address,
    collateral: assetAmountInEth(safe.coll, ilkToAsset(safe.collType)),
    unnormalizedDebt: assetAmountInEth(safe.debt, 'RAI'),
    collType: bytesToString(safe.collType),
    asset: ilkToAsset(safe.collType),
    type: 'standard',
    // safeAddr: safe.safeAddr,
  }));
  const safes = await Promise.all(basicSafesInfo.map(getSafeInfo));
  return safes.reduce((obj, item) => ({ ...obj, [item.id]: item }), {});
};

/**
 * @param address {string}
 * @returns {Promise<Object<number, RaiSafeInfo>>}
 */
export const getUserSafes = async (address) => {
  const raiLoanInfoContract = RaiLoanInfoContract();
  const data = await raiLoanInfoContract.methods.getFullInfo(address, stringToBytes('ETH-A')).call();
  const futureData = await getCollTypeFuturePrice(stringToBytes('ETH-A'));
  const collTypeDataCollInfo = parseCollTypeInfo(data.collInfo, futureData);
  const collTypeDataRaiInfo = parseGlobalRaiInfo(data.raiInfo);
  const collTypeData = {
    ...collTypeDataCollInfo,
    ...collTypeDataRaiInfo,
  };
  const basicSafesInfo = data.safeInfos.map((safe) => ({
    id: parseInt(safe.safeId, 10),
    owner: address,
    collateral: assetAmountInEth(safe.coll, ilkToAsset(safe.collType)),
    unnormalizedDebt: assetAmountInEth(safe.debt, 'RAI'),
    collType: bytesToString(safe.collType),
    asset: ilkToAsset(safe.collType),
    type: 'standard',
    // safeAddr: safe.safeAddr,
  }));
  const safes = basicSafesInfo.map(safe => parseSafeInfo(safe, collTypeData));
  return safes.reduce((obj, item) => ({ ...obj, [item.id]: item }), {});
};

export const getLsSafe = (address, safes) => {
  const { existingItem } = getLsExistingItemAndState(address);

  if (!existingItem) return null;
  if (!existingItem.selectedSafe) return null;

  let safe = null;

  if (existingItem.selectedSafe) {
    safe = safes[existingItem.selectedSafe];
  }

  return safe || null;
};

/**
 *
 * @return {Promise<number>}
 */
export const latestSafeId = async () => {
  const contract = GebSafeManagerContract();
  const safei = await contract.methods.safei().call();
  return parseInt(safei, 10);
};
