export const chartOptions = {
  series: [{
    name: 'Average gas price',
    data: [],
    dataGrouping: {
      enabled: false,
    },
    color: '#37B06F',
    lineWidth: 1,
    fillColor: {
      linearGradient: {
        x1: 0,
        x2: 0,
        y1: 0,
        y2: 1,
      },
      stops: [
        [0, 'rgba(55, 176, 111, 0.7)'],
        [1, 'rgba(55, 176, 111, 0.1)'],
      ],
    },
    states: {
      hover: {
        enabled: false,
      },
    },
  }],
  chart: {
    height: 100,
    spacing: 0,
    type: 'area',
    backgroundColor: 'transparent',
    plotBorderColor: 'transparent',
  },
  time: {
    useUTC: false,
  },
  credits: { enabled: false },
  legend: { enabled: false },
  rangeSelector: {
    enabled: false,
  },
  navigator: {
    enabled: false,
  },
  scrollbar: {
    enabled: false,
  },
  xAxis: {
    gridLineColor: 'transparent',
    lineColor: 'transparent',
    minorGridLineColor: 'transparent',
    tickColor: 'transparent',
    labels: {
      enabled: false,
    },
  },
  yAxis: {
    tickAmount: 1,
    gridLineColor: 'transparent',
    lineColor: 'transparent',
    minorGridLineColor: 'transparent',
    tickColor: 'transparent',
    labels: {
      enabled: false,
    },
  },
  tooltip: {
    xDateFormat: '%b %d. %Y, %H:%M',
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
    style: {
      color: '#F0F0F0',
    },
  },
};
