import React from 'react';
import { v4 as uuid } from 'uuid';

export default function UniswapIcon(props) {
  const id = `uniswap_icon${uuid()}`;
  const fill = `url(#${id})`;

  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z" fill={fill} />
      <g style={{ mixBlendMode: 'darken' }}>
        <path d="M12.4298 8.5619C12.2329 8.53155 12.2246 8.52799 12.3173 8.51384C12.4948 8.4867 12.9141 8.52369 13.203 8.59196C13.8774 8.7513 14.4911 9.15948 15.1462 9.88443L15.3203 10.077L15.5692 10.0372C16.6181 9.86971 17.6851 10.0029 18.5776 10.4126C18.8231 10.5254 19.2102 10.7498 19.2586 10.8074C19.274 10.8258 19.3023 10.9441 19.3214 11.0703C19.3877 11.5071 19.3545 11.8418 19.2202 12.0919C19.147 12.2279 19.1429 12.2711 19.1921 12.3875C19.2314 12.4804 19.3408 12.5492 19.4491 12.5491C19.6709 12.5487 19.9096 12.1924 20.0202 11.6966L20.0642 11.4997L20.1512 11.5976C20.6286 12.1352 21.0036 12.8684 21.068 13.3903L21.0848 13.5263L21.0046 13.4026C20.8665 13.1898 20.7277 13.0449 20.55 12.928C20.2296 12.7174 19.891 12.6457 18.9941 12.5987C18.1839 12.5563 17.7254 12.4875 17.2708 12.3402C16.4973 12.0895 16.1074 11.7557 15.1886 10.5575C14.7805 10.0254 14.5282 9.73093 14.2773 9.49381C13.7072 8.95502 13.1469 8.67245 12.4298 8.5619Z" fill="white" />
      </g>
      <g style={{ mixBlendMode: 'darken' }}>
        <path d="M19.4415 9.75008C19.462 9.39348 19.5105 9.15827 19.6084 8.94346C19.6472 8.85844 19.6834 8.78885 19.689 8.78885C19.6946 8.78885 19.6777 8.8516 19.6516 8.9283C19.5805 9.13676 19.5687 9.4219 19.6177 9.75365C19.6799 10.1746 19.7153 10.2353 20.163 10.69C20.3729 10.9033 20.6171 11.1723 20.7056 11.2877L20.8666 11.4977L20.7056 11.3477C20.5088 11.1641 20.0561 10.8063 19.9561 10.7551C19.889 10.7208 19.8791 10.7214 19.8377 10.7623C19.7996 10.8 19.7915 10.8566 19.7863 11.1245C19.778 11.5419 19.7208 11.8098 19.5827 12.0777C19.5079 12.2226 19.4961 12.1917 19.5637 12.0281C19.6142 11.906 19.6193 11.8523 19.619 11.4482C19.6182 10.6363 19.5212 10.4412 18.9525 10.1068C18.8085 10.0221 18.571 9.89993 18.425 9.83531C18.2789 9.7707 18.1629 9.71441 18.1671 9.71021C18.1832 9.69428 18.7378 9.85517 18.9611 9.94053C19.2932 10.0675 19.348 10.0839 19.3883 10.0686C19.4154 10.0583 19.4285 9.9801 19.4415 9.75008Z" fill="white" />
      </g>
      <g style={{ mixBlendMode: 'darken' }}>
        <path d="M12.8122 11.1391C12.4126 10.5922 12.1653 9.75366 12.2188 9.12684L12.2354 8.93286L12.3263 8.94936C12.4972 8.98033 12.7918 9.0893 12.9297 9.17258C13.3082 9.40106 13.4721 9.70188 13.6388 10.4743C13.6876 10.7006 13.7517 10.9567 13.7812 11.0433C13.8286 11.1829 14.008 11.5088 14.1538 11.7204C14.2588 11.8729 14.1891 11.9451 13.9569 11.9243C13.6023 11.8925 13.1221 11.563 12.8122 11.1391Z" fill="white" />
      </g>
      <g style={{ mixBlendMode: 'darken' }}>
        <path d="M18.9565 15.2093C17.0887 14.4619 16.4309 13.8131 16.4309 12.7184C16.4309 12.5573 16.4365 12.4255 16.4433 12.4255C16.4501 12.4255 16.5224 12.4786 16.6039 12.5436C16.9826 12.8456 17.4068 12.9745 18.5809 13.1448C19.2719 13.245 19.6607 13.3259 20.0194 13.4441C21.1594 13.8199 21.8647 14.5825 22.0329 15.6212C22.0818 15.923 22.0531 16.489 21.9739 16.7873C21.9114 17.0229 21.7204 17.4476 21.6697 17.4639C21.6557 17.4684 21.642 17.4149 21.6383 17.3421C21.6192 16.9521 21.4209 16.5724 21.0879 16.288C20.7092 15.9646 20.2005 15.7071 18.9565 15.2093Z" fill="white" />
      </g>
      <g style={{ mixBlendMode: 'darken' }}>
        <path d="M17.6455 15.5198C17.6221 15.3814 17.5815 15.2047 17.5553 15.127L17.5076 14.9859L17.5961 15.0846C17.7187 15.2213 17.8155 15.3962 17.8975 15.6291C17.9601 15.8069 17.9672 15.8597 17.9667 16.1486C17.9662 16.4322 17.9584 16.4917 17.9006 16.6517C17.8094 16.904 17.6962 17.0829 17.5064 17.2749C17.1652 17.6201 16.7265 17.8112 16.0935 17.8904C15.9835 17.9041 15.6628 17.9274 15.3808 17.9419C14.6703 17.9787 14.2026 18.0546 13.7824 18.2012C13.722 18.2223 13.6681 18.2352 13.6626 18.2297C13.6456 18.2129 13.9317 18.0434 14.168 17.9302C14.5012 17.7706 14.8329 17.6836 15.5761 17.5606C15.9432 17.4998 16.3223 17.4261 16.4186 17.3968C17.3277 17.1197 17.7951 16.4048 17.6455 15.5198Z" fill="white" />
      </g>
      <g style={{ mixBlendMode: 'darken' }}>
        <path d="M18.5016 17.0311C18.2535 16.5009 18.1965 15.989 18.3324 15.5115C18.347 15.4605 18.3704 15.4188 18.3844 15.4188C18.3985 15.4188 18.4571 15.4502 18.5145 15.4887C18.6289 15.5652 18.8583 15.6941 19.4693 16.0252C20.2318 16.4384 20.6666 16.7583 20.9622 17.1238C21.2211 17.4439 21.3813 17.8086 21.4585 18.2532C21.5021 18.505 21.4765 19.111 21.4115 19.3645C21.2065 20.1642 20.73 20.7922 20.0504 21.1587C19.9509 21.2124 19.8615 21.2565 19.8518 21.2567C19.8421 21.2569 19.8784 21.1652 19.9325 21.053C20.161 20.578 20.1871 20.116 20.0142 19.6018C19.9084 19.2868 19.6926 18.9026 19.257 18.2532C18.7504 17.4983 18.6262 17.2974 18.5016 17.0311Z" fill="white" />
      </g>
      <g style={{ mixBlendMode: 'darken' }}>
        <path d="M11.486 19.892C12.1791 19.3103 13.0416 18.8971 13.8272 18.7702C14.1657 18.7156 14.7297 18.7373 15.0432 18.817C15.5458 18.9449 15.9953 19.2312 16.2291 19.5723C16.4576 19.9056 16.5556 20.1961 16.6577 20.8425C16.6979 21.0975 16.7417 21.3535 16.755 21.4115C16.8316 21.7465 16.9807 22.0142 17.1656 22.1488C17.4591 22.3623 17.9646 22.3755 18.4619 22.1828C18.5462 22.15 18.6195 22.1274 18.6246 22.1325C18.6427 22.1503 18.3923 22.3169 18.2157 22.4046C17.978 22.5226 17.789 22.5683 17.5378 22.5683C17.0824 22.5683 16.7043 22.338 16.3888 21.8685C16.3267 21.7761 16.1872 21.4994 16.0787 21.2534C15.7456 20.4983 15.5811 20.2683 15.1943 20.0165C14.8577 19.7975 14.4236 19.7582 14.0971 19.9173C13.6681 20.1264 13.5484 20.6712 13.8557 21.0165C13.9778 21.1538 14.2055 21.2721 14.3917 21.2951C14.74 21.3382 15.0394 21.0748 15.0394 20.7253C15.0394 20.4983 14.9516 20.3688 14.7307 20.2696C14.4289 20.1343 14.1045 20.2926 14.106 20.5743C14.1067 20.6945 14.1593 20.7699 14.2805 20.8244C14.3583 20.8594 14.3601 20.8621 14.2967 20.849C14.0198 20.7919 13.9549 20.4602 14.1776 20.2399C14.4449 19.9756 14.9976 20.0922 15.1874 20.4532C15.2671 20.6048 15.2764 20.9067 15.2068 21.089C15.0513 21.4971 14.5976 21.7116 14.1374 21.5948C13.824 21.5153 13.6964 21.4292 13.3187 21.0425C12.6622 20.3704 12.4074 20.2401 11.461 20.0933L11.2797 20.0652L11.486 19.892Z" fill="white" />
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M8.40574 7.86315C10.598 10.5077 12.1079 11.5987 12.2757 11.8292C12.4142 12.0196 12.3621 12.1907 12.1247 12.3248C11.9927 12.3994 11.7214 12.4749 11.5855 12.4749C11.4319 12.4749 11.3791 12.416 11.3791 12.416C11.29 12.332 11.2398 12.3467 10.7823 11.5395C10.1471 10.5598 9.61547 9.74718 9.60097 9.73357C9.56744 9.70211 9.56801 9.70317 10.7175 11.7474C10.9032 12.1735 10.7544 12.3299 10.7544 12.3905C10.7544 12.514 10.7206 12.5789 10.5674 12.7487C10.3119 13.0319 10.1978 13.3502 10.1153 14.0087C10.0229 14.747 9.76312 15.2685 9.04308 16.161C8.62159 16.6834 8.55263 16.7792 8.44627 16.9898C8.31231 17.2549 8.27548 17.4035 8.26055 17.7383C8.24477 18.0924 8.2755 18.3211 8.38433 18.6596C8.47961 18.956 8.57906 19.1516 8.8333 19.543C9.05271 19.8807 9.17904 20.1318 9.17904 20.23C9.17904 20.3081 9.19405 20.3082 9.53404 20.2319C10.3477 20.0493 11.0084 19.7281 11.3799 19.3344C11.6099 19.0908 11.6639 18.9563 11.6656 18.6225C11.6668 18.4041 11.659 18.3584 11.5997 18.2328C11.503 18.0283 11.327 17.8583 10.9391 17.5948C10.4308 17.2494 10.2137 16.9714 10.1538 16.5891C10.1046 16.2753 10.1616 16.054 10.4428 15.4683C10.7338 14.862 10.806 14.6037 10.8548 13.9926C10.8863 13.5978 10.9299 13.4421 11.044 13.3171C11.1631 13.1868 11.2702 13.1426 11.5648 13.1026C12.045 13.0374 12.3509 12.9139 12.6022 12.6837C12.8203 12.484 12.9115 12.2916 12.9255 12.0019L12.9362 11.7823L12.8143 11.6411C12.373 11.1297 8.11021 7.46115 8.08305 7.46115C8.07725 7.46115 8.22247 7.64206 8.40574 7.86315ZM9.42676 18.1389C9.52653 17.9633 9.47352 17.7376 9.30663 17.6272C9.14894 17.523 8.90397 17.5721 8.90397 17.7079C8.90397 17.7494 8.92702 17.7795 8.97899 17.8061C9.06649 17.8508 9.07284 17.9012 9.00399 18.004C8.93428 18.1082 8.9399 18.1998 9.01987 18.262C9.14876 18.3623 9.33121 18.3071 9.42676 18.1389Z" fill="white" />
      <g style={{ mixBlendMode: 'darken' }}>
        <path fillRule="evenodd" clipRule="evenodd" d="M13.2394 13.2178C13.0139 13.2866 12.7948 13.5241 12.7269 13.7731C12.6855 13.9251 12.709 14.1915 12.771 14.2738C12.8712 14.4068 12.968 14.4418 13.2303 14.44C13.7437 14.4364 14.19 14.2175 14.2419 13.9439C14.2845 13.7197 14.0884 13.4088 13.8184 13.2724C13.6791 13.202 13.3828 13.1741 13.2394 13.2178ZM13.8396 13.6843C13.9188 13.5725 13.8841 13.4516 13.7495 13.3699C13.493 13.2142 13.1052 13.343 13.1052 13.5838C13.1052 13.7037 13.3075 13.8344 13.4928 13.8344C13.6162 13.8344 13.7851 13.7613 13.8396 13.6843Z" fill="white" />
      </g>
      <defs>
        <linearGradient id={id} x1="15" y1="0" x2="15" y2="30" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FC157B" />
          <stop offset="1" stopColor="#CA0049" />
        </linearGradient>
      </defs>
    </svg>

  );
}
