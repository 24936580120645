import {
  LQTY_CREATE_TROVE_REQUEST,
  LQTY_CREATE_TROVE_SUCCESS,
  LQTY_CREATE_TROVE_FAILURE,
  LQTY_RESET_CREATE_TROVE,
} from '../../actionTypes/liquityActionTypes/liquityCreateActionTypes';

const INITIAL_STATE = {
  creatingTrove: false,
  creatingTroveErr: '',
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case LQTY_CREATE_TROVE_REQUEST: return {
      ...state,
      creatingTrove: true,
      creatingTroveErr: '',
    };

    case LQTY_CREATE_TROVE_SUCCESS: return {
      ...state,
      creatingTrove: false,
      creatingTroveErr: '',
    };

    case LQTY_CREATE_TROVE_FAILURE: return {
      ...state,
      creatingTrove: false,
      creatingTroveErr: payload,
    };

    case LQTY_RESET_CREATE_TROVE: return {
      creatingTrove: false,
      creatingTroveErr: '',
    };

    default:
      return state;
  }
};

