import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tippy';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { numberWithCommas } from '../../services/utils';
import { CopyIcon } from './TooltipIcons';

const TooltipWrapper = ({
  title, disabled, children, copy, useText, textToCopy, className,
}) => {
  const [copied, setIsCopied] = useState(false);
  const handleCopyClick = () => {
    setTimeout(() => setIsCopied(true), 250);
    setTimeout(() => setIsCopied(false), 1400);
  };
  const titleFormat = (
    <span className={`tooltip-title-format ${copied ? 'copied' : ''}`}>
      {copy && (<CopyIcon copied={copied} />)}
      {/* eslint-disable-next-line no-restricted-globals */}
      { useText || isNaN(title) ? title : numberWithCommas(title, 18, true) }
    </span>
  );

  return (
    <Tooltip className={className} hideOnClick={false} html={titleFormat} disabled={disabled} duration={100}>
      {
        copy
          ? (<CopyToClipboard text={textToCopy || title} onCopy={handleCopyClick}><span>{children}</span></CopyToClipboard>)
          : (<span>{children}</span>)
       }
    </Tooltip>
  );
};

TooltipWrapper.defaultProps = {
  children: null,
  disabled: false,
  useText: false,
  copy: false,
  textToCopy: '',
  className: '',
};

TooltipWrapper.propTypes = {
  children: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]).isRequired,
  disabled: PropTypes.bool,
  copy: PropTypes.bool,
  useText: PropTypes.bool,
  textToCopy: PropTypes.string,
  className: PropTypes.string,
};

export default TooltipWrapper;
