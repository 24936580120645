/* eslint-disable */
import React from 'react';
import { v4 as uuid } from 'uuid';

export default function PolygonIcon(props) {
  const id = `polygon_icon${uuid()}`;
  const fill = `url(#${id})`;
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z" fill={fill}/>
      <path d="M19.0738 12.277C18.7786 12.0994 18.3948 12.0994 18.0701 12.277L15.7675 13.6385L14.203 14.5264L11.9004 15.8879C11.6052 16.0655 11.2214 16.0655 10.8967 15.8879L9.06641 14.8224C8.77122 14.6448 8.56459 14.3192 8.56459 13.9641V11.8626C8.56459 11.5074 8.74171 11.1818 9.06641 11.0042L10.8671 9.96827C11.1624 9.79071 11.5461 9.79071 11.8709 9.96827L13.6716 11.0042C13.9668 11.1818 14.1734 11.5074 14.1734 11.8626V13.2241L15.738 12.3065V10.945C15.738 10.5899 15.5609 10.2643 15.2362 10.0867L11.9004 8.13319C11.6052 7.9556 11.2214 7.9556 10.8967 8.13319L7.50184 10.0867C7.17712 10.2643 7 10.5899 7 10.945V14.8816C7 15.2368 7.17712 15.5624 7.50184 15.74L10.8967 17.6935C11.1919 17.871 11.5756 17.871 11.9004 17.6935L14.203 16.3615L15.7675 15.444L18.0701 14.112C18.3653 13.9345 18.7491 13.9345 19.0738 14.112L20.8745 15.148C21.1697 15.3256 21.3764 15.6512 21.3764 16.0064V18.1078C21.3764 18.463 21.1993 18.7886 20.8745 18.9662L19.0738 20.0317C18.7786 20.2093 18.3948 20.2093 18.0701 20.0317L16.2694 18.9958C15.9742 18.8182 15.7675 18.4926 15.7675 18.1374V16.7759L14.203 17.6935V19.0549C14.203 19.4101 14.3801 19.7357 14.7048 19.9133L18.0996 21.8668C18.3948 22.0444 18.7786 22.0444 19.1033 21.8668L22.4981 19.9133C22.7934 19.7357 23 19.4101 23 19.0549V15.1184C23 14.7632 22.8229 14.4376 22.4981 14.26L19.0738 12.277Z" fill="white"/>
      <defs>
        <linearGradient id={id} x1="15" y1="0" x2="15" y2="30" gradientUnits="userSpaceOnUse">
          <stop stopColor="#8247E5"/>
          <stop offset="1" stopColor="#563292"/>
        </linearGradient>
      </defs>
    </svg>
  );
}
