import Dec from 'decimal.js';
import {
  CompoundSubscriptionsProxyAddress,
  CompoundSubscriptionsContract,
  CompoundMonitorProxyAddress, AuthCheckContract,

} from '../contractRegistryService';
import config from '../../config/config.json';
import { ethToWei, weiToEth } from '../ethService';
import { isEmptyBytes, parseTimestamp, requireAddress } from '../utils';
import callTx from '../txService';
import { AUTOMATION_MIN_COMP } from '../../constants/general';

export const isCompoundMonitorAuthorized = async (proxyAddress) => {
  const contract = AuthCheckContract();
  return contract.methods.canCall(CompoundMonitorProxyAddress, proxyAddress, '0x1cff79cd').call();
};

/**
 * @param proxy {string} user's proxy address
 * @returns {Promise<{isSubscribedToAutomation: (boolean), legacyIsSubscribedToAutomation: (boolean), automationResubscribeRequired: boolean}>}
 */
export const isSubscribedToMonitor = async (proxy) => {
  try {
    if (proxy === '') {
      return {
        isSubscribedToAutomation: false,
        automationResubscribeRequired: false,
      };
    }
    const contract = await CompoundSubscriptionsContract();
    const data = await contract.methods.getHolder(proxy).call();
    const authorized = await isCompoundMonitorAuthorized(proxy);
    const isSubscribedToAutomation = data && (data[0] === proxy) && authorized;
    return {
      isSubscribedToAutomation,
      automationResubscribeRequired: data && (data[0] !== proxy || !authorized),
    };
  } catch (err) {
    console.error(err);
    return {
      isSubscribedToAutomation: false,
      automationResubscribeRequired: false,
    };
  }
};

/**
 *
 * @param accountType
 * @param path
 * @param account
 * @param sendTxFunc
 * @param proxyAddress
 * @param minRatio {Number}
 * @param maxRatio {Number}
 * @param repayTo {Number}
 * @param boostTo {Number}
 * @param boostEnabled {Boolean}
 * @param updating {Boolean}
 * @returns {Promise<*>}
 */
export const subscribeToMonitor = async (
  accountType,
  path,
  account,
  sendTxFunc,
  proxyAddress,
  minRatio,
  maxRatio,
  repayTo,
  boostTo,
  boostEnabled,
  updating,
) => {
  const contract = config.CompoundSubscriptionsProxy;

  const txParams = { from: account };

  const params = [
    ethToWei(minRatio / 100),
    ethToWei(maxRatio / 100),
    ethToWei(boostTo / 100),
    ethToWei(repayTo / 100),
    boostEnabled,
  ];

  console.log(params);
  requireAddress(proxyAddress);
  const proxyContract = new window._web3.eth.Contract(config.DSProxy.abi, proxyAddress);
  const functionName = updating ? 'update' : 'subscribe';
  const contractFunction = contract.abi.find(abi => abi.name === functionName);
  const data = window._web3.eth.abi.encodeFunctionCall(contractFunction, params);
  const funcParams = [CompoundSubscriptionsProxyAddress, data];

  return callTx(accountType, path, sendTxFunc, proxyContract, 'execute(address,bytes)', funcParams, txParams);
};

export const unsubscribeFromMonitor = async (
  accountType,
  path,
  account,
  sendTxFunc,
  proxyAddress,
) => {
  const contract = config.CompoundSubscriptionsProxy;

  const txParams = { from: account };
  const params = [];

  requireAddress(proxyAddress);
  const proxyContract = new window._web3.eth.Contract(config.DSProxy.abi, proxyAddress);

  const contractFunction = contract.abi.find(abi => abi.name === 'unsubscribe');
  const data = window._web3.eth.abi.encodeFunctionCall(contractFunction, params);
  const funcParams = [CompoundSubscriptionsProxyAddress, data];

  await callTx(accountType, path, sendTxFunc, proxyContract, 'execute(address,bytes)', funcParams, txParams);
};

/**
 * Fetches ratio data for saver subscribed cdp-s
 *
 * @param proxy {string}
 * @return {Promise<{maxRatio: *, minRatio: *, minOptimalRatio: *, maxOptimalRatio: *}>}
 */
export const getCompoundSaverSubscriptionInfo = async (proxy) => {
  const contract = await CompoundSubscriptionsContract();
  try {
    const data = await contract.methods.getHolder(proxy).call();
    return {
      minRatio: Dec(weiToEth(data.minRatio)).mul(100).toNumber(),
      maxRatio: data.boostEnabled ? Dec(weiToEth(data.maxRatio)).mul(100).toNumber() : Infinity,
      minOptimalRatio: Dec(weiToEth(data.optimalRatioRepay)).mul(100).toNumber(),
      maxOptimalRatio: data.boostEnabled ? Dec(weiToEth(data.optimalRatioBoost)).mul(100).toNumber() : Infinity,
      boostEnabled: data.boostEnabled,
    };
  } catch (err) {
    return {
      minRatio: 0,
      maxRatio: Infinity,
      minOptimalRatio: 0,
      maxOptimalRatio: Infinity,
      boostEnabled: false,
    };
  }
};

export const getCompoundSaverHistory = async (proxy) => {
  const res = await fetch(`https://defiexplore.com/api/automation_history/comp/${proxy.toLowerCase()}`);
  const events = await res.json();
  return events
    .map((event, i) => ({
      actionType: event.action,
      collAsset: event.collAsset,
      collAmount: event.collAmount,
      borrowAsset: event.debtAsset,
      borrowAmount: event.debtAmount,
      ratioFrom: event.ratioBefore,
      ratioTo: event.ratioAfter,
      timestamp: new Date(event.timestamp).getTime(),
      txHash: event.txHash,
      liqRatio: '1',
    })).reverse();
};

export const calculateLimits = (optimalRate) => {
  let minRatio = optimalRate - 15;
  const maxRatio = optimalRate + 15;
  if (minRatio < AUTOMATION_MIN_COMP) minRatio = AUTOMATION_MIN_COMP;
  return { minRatio, maxRatio };
};
