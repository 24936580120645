/* eslint-disable import/first */
/* eslint-disable import/newline-after-import */
/* eslint-disable import/order */

import { set as dfsTokensSetConfig } from '@defisaver/tokens';
import TokenIcon from './components/TokenIcon/TokenIcon';
dfsTokensSetConfig('iconFunc', TokenIcon);

import './i18n/translations';

import { disableReactDevTools } from '@fvilers/disable-react-devtools';
if (process.env.NODE_ENV === 'production') disableReactDevTools();

import Decimal from 'decimal.js';
Decimal.set({
  rounding: Decimal.ROUND_DOWN,
  toExpPos: 9e15,
  toExpNeg: -9e15,
  precision: 50,
});

import './sentry';
