import React from 'react';
import { connect } from 'react-redux';
import t from 'translate';
import PropTypes from 'prop-types';
import Dec from 'decimal.js';
import { formatNumber, isWalletTypeProxy } from '../../../../../services/utils';
import { LIQUITY_LIQ_RESERVE_LUSD } from '../../../../../constants/liquity';

import ErrorBox from '../../../../Decorative/ErrorBox/ErrorBox';
import WarningBox from '../../../../Decorative/WarningBox/WarningBox';
import closeIcon from './closeIcon.svg';

import './LiquityCloseTrovePicker.scss';

const LiquityCloseTrovePicker = ({
  closeModal, closing, error, trove, hasEnoughLUSD, walletType,
}) => {
  const isProxy = isWalletTypeProxy(walletType);
  return (
    <div className="close-trove-picker-wrapper">
      <div className="new-modal-top-wrapper" style={{ backgroundImage: `url(${closeIcon})` }}>
        <h1 className="title">
          {closing ? t('common.closing') : t('common.close')} {t('liquity.trove')}
        </h1>
      </div>
      <div className="new-modal-content-wrapper">
        <>
          <div className="descriptions">
            <div className="description">
              {isProxy ? t('liquity.close_account') : t('liquity.close_account')}
            </div>
          </div>

          {!hasEnoughLUSD && (
            <WarningBox>
              You need at least {formatNumber(+trove.debtInAsset - LIQUITY_LIQ_RESERVE_LUSD)} LUSD balance to close your trove
            </WarningBox>
          )}

          {error && <ErrorBox>{error}</ErrorBox>}
        </>
      </div>
    </div>
  );
};

LiquityCloseTrovePicker.propTypes = {
  closeModal: PropTypes.func.isRequired,
  closing: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  trove: PropTypes.object.isRequired,
  hasEnoughLUSD: PropTypes.bool.isRequired,
  walletType: PropTypes.object.isRequired,
  // isSubscribedToAutomation: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ liquity, general, assets }) => ({
  trove: liquity[general.walletType.value],
  walletType: general.walletType,
  // isSubscribedToAutomation: liquity.liquity[general.walletType.value].isSubscribedToAutomation,
});

export default connect(mapStateToProps)(LiquityCloseTrovePicker);
