import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import clientConfig from '../../../config/clientConfig.json';
import { NETWORKS } from '../../../constants/general';

import { changeNetwork } from '../../../actions/accountActions';
import { closeModal } from '../../../actions/modalActions';
import { bemifyClass, flexBlock } from '../../../services/stylingUtils';

import ModalHeader from '../ModalHeader';
import ModalBody from '../ModalBody';

import './ChangeNetworkModal.scss';

const className = bemifyClass('change-network-modal');

const ChangeNetworkModal = () => {
  const dispatch = useDispatch();
  const { network } = useSelector(({ general }) => general);
  const networks = useMemo(
    () => Object.values(NETWORKS).filter(({ chainId }) => chainId !== network && clientConfig.supportedNetworks.includes(chainId)),
    [network],
  );

  return (
    <div className={className()}>
      <ModalHeader closeModal={() => dispatch(closeModal())} />

      <ModalBody>
        <div className="new-modal-top-wrapper">
          <h1>Select a network</h1>
        </div>

        <div className="new-modal-content-wrapper">
          <p>Select the network you want to switch to:</p>

          <ul className={`${flexBlock('', ['column'])} ${className('list')}`} style={{ '--flexGap': '2rem' }}>
            {networks.map(({
              Icon, chainName, color, chainId,
            }) => (
              <li key={chainName} className={className('item')} onClick={() => dispatch(changeNetwork(chainId))}>
                <div style={{ backgroundColor: color }}><Icon /></div>
                <div>{chainName}</div>
              </li>
            ))}
          </ul>

        </div>
      </ModalBody>
    </div>
  );
};

ChangeNetworkModal.defaultProps = {};

ChangeNetworkModal.propTypes = {};

export default ChangeNetworkModal;
