import dfs from '@defisaver/sdk';
import { assetAmountInWei } from '@defisaver/tokens';
import cloneDeep from 'lodash/cloneDeep';
import RecipeAction from '../RecipeAction';
import { AssetAmount, Amount, Source } from '../../components/Recipes/RecipeCreator/inputTypes';
import { changeBalance } from '../../services/recipeCreator/recipeActionUtils';
import UnstakeIcon from '../recipeIcons/Unstake.svg';
import { formatNumber } from '../../services/utils';

export default class LiquityUnstakeAction extends RecipeAction {
  static prettyName = 'Unstake LQTY';

  static protocol = 'liquity';

  static protocolPrettyName = 'Liquity';

  static description = 'Unstakes LQTY from the Liquity protocol.';

  constructor(amount = '', to = 'wallet') {
    super();
    this.inputs = [
      new AssetAmount('Amount', amount, 'LQTY'),
      new Source('To', to, true),
    ];
    this.output = new Amount('output', 0);
  }

  async toDfsAction(getState) {
    const {
      general: { account },
      maker: { proxyAddress },
      recipeCreator: { actionCalls, actions },
    } = getState();
    const args = this.mapReturnValuesToArgs(actionCalls.map(a => a.returnValue), actions);
    const to = args[1] === 'wallet' ? account : proxyAddress;
    const amount = assetAmountInWei(args[0], 'LQTY');
    return new dfs.actions.liquity.LiquityUnstakeAction(amount, to, to, to);
  }

  async getAfterValues(_balances = {}, returnValues = [], actions = [], _positions = {}, getState) {
    const positions = { ..._positions };
    const {
      general: { account },
      maker: { proxyAddress },
      liquity: { proxy },
      liquityStaking: { proxy: stakingProxy },
    } = getState();
    if (!positions.liquity) positions.liquity = { ...proxy, ...stakingProxy };
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const amount = args[0] || '0';
    const balances = cloneDeep(_balances);
    await changeBalance(balances, args[1], 'LQTY', amount, args[1] === 'wallet' ? account : proxyAddress);
    await changeBalance(balances, args[1], 'ETH', positions.liquity.rewardETH, args[1] === 'wallet' ? account : proxyAddress);
    await changeBalance(balances, args[1], 'LUSD', positions.liquity.rewardLUSD, args[1] === 'wallet' ? account : proxyAddress);
    positions.liquity.rewardETH = '0';
    positions.liquity.rewardLUSD = '0';
    positions.liquity.stakedLQTYBalance -= amount;
    positions.liquity.showStakingBalances = true;
    this.output.value = amount;
    return {
      returnValue: this.output,
      balances,
      positions,
    };
  }

  _getPrettyName(actionCalls, actions) {
    const args = this.mapReturnValuesToArgs(actionCalls.map(a => a.returnValue), actions);
    return `Unstake ${formatNumber(args[0])} LQTY`;
  }

  static getIcon() {
    return UnstakeIcon;
  }
}
