/* eslint-disable max-len */
import React from 'react';
import Footer from '../Footer/Footer';

import '../../common/information-pages.scss';
import './TermsOfService.scss';

const TermsOfService = () => (
  <div className="terms-of-service-wrapper information-wrapper">
    <div className="first-section">
      <div className="jumbotron-heading">
        <p>Terms of service</p>
        <p>Last updated: October 08, 2019</p>
      </div>
    </div>

    <div className="points-wrapper">
      <div className="point">
        <div className="point-header">General terms of service</div>
        <div className="point-text">
          DeFi Saver is a management application for decentralized finance protocols that run on Ethereum, including but not limited to Maker, Compound, dYdX, Fulcrum, Kyber and Uniswap.
        </div>

        <div className="point-text">
          DeFi Saver is in no way affiliated with companies or teams behind these protocols and we can make no guarantees regarding their stability or security. Additionally, we can provide no guarantees regarding the safety of funds that have been in any way used within DeFi Saver. We cannot compensate users for funds that have been lost during use within DeFi Saver or that have at any point in time been used within DeFi Saver.
        </div>

        <div className="point-text">
          By using DeFi Saver you agree to these terms and acknowledge that you are aware of the existing risk and knowingly accept it.
        </div>

        <div className="point-header">Additional information</div>
        <div className="point-text">
          In order to understand the existing risks of using decentralized finance, we strongly encourage users to get acquainted with the workings of the underlying protocols as much as possible. This includes materials such as
          <a className="link" target="_blank" rel="noopener noreferrer" href="https://cdp.makerdao.com/terms">MakerDAO DAI Terms of Service</a>
          and their
          <a className="link" target="_blank" rel="noopener noreferrer" href="https://github.com/makerdao/awesome-makerdao">Awesome MakerDAO</a>
          collection of resources,
          <a className="link next-to-comma" target="_blank" rel="noopener noreferrer" href="https://medium.com/compound-finance/faq-1a2636713b69">Compound FAQ</a>,
          <a className="link next-to-comma" target="_blank" rel="noopener noreferrer" href="https://dydx.exchange/terms-of-use/">dYdX Terms of Use</a>,
          as well as
          <a className="link" target="_blank" rel="noopener noreferrer" href="https://fulcrum.trade/tos/">Fulcrum Terms and Conditions</a>
          and their
          <a className="link next-to-comma" target="_blank" rel="noopener noreferrer" href="https://bzx.network/faq-fulcrum.html">FAQ</a>.
        </div>

        <div className="point-text">
          We would like to specifically highlight the following excerpt from Maker’s DAI Announcement Disclaimer that we believe can be considered true for all decentralized finance protocols:
          DAI System and Software is based on experimental blockchain and smart contract technology which carries significant and inherent operational, technological, financial and regulatory risks. It cannot be excluded that, as a result of defects, technical bugs, network forks, intentional attacks of third parties, acts of God, unscheduled maintenance, or other events, the DAI System and Software experiences disruption, suspension or termination, and/or the value of DAI over time may experience extreme volatility or depreciate in full, as well as ETH may be lost. Attacks by hackers on the DAI System and Software, smart contracts or other software used may have unforeseeable consequences, including loss of DAI and/or ETH. Also, market conditions may change and thus no market liquidity is guaranteed. All smart contracts are ultimately controlled by the network of miners. There are also other risks associated with the use of the DAI System and Software, including those that cannot be anticipated and [...] you declare and confirm that you understand the risks of using experimental public blockchain technology.
          The DAI System and Software and all of the matters set forth in the White Paper are new and untested. Use of the DAI System and Software requires deep knowledge of smart contract technology and related fields. Its use without proper skills and preparation may result in unintended consequences. [...] All users of the DAI System and Software expressly acknowledge, understand and agree that the user is using the DAI System and Software at the user’s sole risk and that the DAI System and Software are each provided, used and acquired on an “AS IS” and on an “AS AVAILABLE” basis without representations, warranties, promises or guarantees whatsoever of any kind by any entity and the user shall rely on its own examination and investigation thereof.
        </div>
      </div>
    </div>
  </div>
);

TermsOfService.propTypes = {};

export default TermsOfService;
