import React from 'react';
import { v4 as uuid } from 'uuid';

export default function SushiswapIcon(props) {
  return (
    <svg width="26" height="23" viewBox="0 0 26 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M20.5039 19.5612L20.5022 19.5633C19.5127 20.7979 18.7376 21.7651 18.7303 21.7746C16.9661 24.0594 11.5621 23.0621 6.57058 19.5079C1.58572 15.9585 -1.12397 11.1861 0.444474 8.76842C0.455906 8.75079 2.38797 6.33826 4.05141 4.26114C5.30632 2.69416 6.40835 1.31807 6.41758 1.30511C8.11724 -1.08189 13.5878 -0.102748 18.6364 3.49209C23.685 7.08693 26.3999 11.9362 24.7002 14.3232C24.688 14.3404 22.2593 17.3708 20.5039 19.5612ZM23.8405 13.8718C22.2832 16.0589 17.271 15.1618 12.6453 11.8681C8.01961 8.57437 5.53217 4.13137 7.08944 1.94433C8.64671 -0.242709 13.659 0.654412 18.2847 3.9481C22.9103 7.2418 25.3978 11.6848 23.8405 13.8718ZM14.0297 9.96198C16.5386 11.7484 19.2571 12.235 20.1017 11.0488C20.9464 9.86259 19.5972 7.45282 17.0884 5.6664C14.5795 3.87999 11.861 3.39342 11.0164 4.57961C10.1718 5.7658 11.5209 8.17557 14.0297 9.96198Z" fill="white" />
    </svg>
  );
}
