import Dec from 'decimal.js';
import { change } from 'redux-form';
import { assetAmountInWei } from '@defisaver/tokens';
import t from 'translate';
import { captureException } from 'sentry';
import {
  borrow, callSaverProxyContract, importLoan, payback, supply, withdraw,
} from 'services/aaveServices/aaveManageService';
import { formatNumber, isWalletTypeProxy } from 'services/utils';
import { getBestExchangePrice, getSlippageThreshold } from 'services/exchangeServiceV3';
import { flProtocolAndFeeFor } from 'services/assetsService';

import { calculateTradeSizeImpact } from 'services/makerServices/makerManageServices/makerManageService';
import { callMultipleTxAction, sendTx } from '../txNotificationActions';
import { trackEvent } from '../../services/analyticsService';
import { isLayer2Network } from '../../services/ethService';
import { addApproveTxIfNeeded, approveAddressOnAssetAction, approveTypeToLabelMap } from '../assetsActions';
import { APPROVE_TYPE_AAVE, APPROVE_TYPE_DS_PROXY } from '../../actionTypes/assetsActionTypes';
import { confirmViaModal, openAaveProxyMigrationModal } from '../modalActions';
import { aaveLendingPoolCoreAddress } from '../../services/contractRegistryService';
import * as aaveManageTypes from '../../actionTypes/aaveActionTypes/aaveManageActionTypes';

import { setAfterValue } from './aaveManageAfterValues';
import {
  getMaxBoostAction, getMaxBorrowAction, getMaxPaybackAction, getMaxRepayAction, getMaxSupplyAction, getMaxWithdrawAction,
} from './aaveManageMaxGetters';
import { chooseAaveBorrowInterestRateMode, fetchAaveAccountData, fetchAaveAssetsData } from './aaveManageActionsV2';

/**
 * Sets the values of the actions select inputs to the reducer
 *
 * @param selectValue {Object}
 * @param key {String}
 * @return {Function}
 */
export const setAaveActionSelectValues = (selectValue, key) => (dispatch) => {
  dispatch({ type: aaveManageTypes.AAVE_MANAGE_SET_ACTION_SELECT_VALUES, payload: { selectValue, key } });
};

// ACTIONS EXECUTORS START
export const aaveBoostAction = (inputAmount, closeModal) => async (dispatch, getState) => {
  const { general: { walletType } } = getState();
  if (!isWalletTypeProxy(walletType)) return dispatch(openAaveProxyMigrationModal());

  trackEvent('aaveManage', 'boost');
  dispatch({ type: aaveManageTypes.AAVE_BOOST_REQUEST });

  const {
    aaveManage: { actionsSelectValues, boostAmount },
  } = getState();
  const borrowAsset = actionsSelectValues.boostBorrow.value;
  const supplyAsset = actionsSelectValues.boostSupply.value;

  const amount = assetAmountInWei(inputAmount, borrowAsset);

  const sendTxFunc = (promise) => {
    const amount = formatNumber(parseFloat(inputAmount), 2);
    const additionalInfo = {
      protocol: 'aave',
      firstToken: borrowAsset,
      firstAmount: inputAmount,
      secondToken: supplyAsset,
      secondAmount: boostAmount,
    };
    return sendTx(promise, `${t('common.boost')} ${amount} ${borrowAsset}`, 'aave', dispatch, getState, additionalInfo);
  };

  try {
    await callSaverProxyContract(getState, amount, supplyAsset, borrowAsset, 'boost', sendTxFunc);

    dispatch({ type: aaveManageTypes.AAVE_BOOST_SUCCESS });

    dispatch(setAfterValue(0, 'clear'));
    dispatch(change('aaveManageAdvancedForm', 'boostAmount', null));
    closeModal();
    await dispatch(fetchAaveAssetsData());
    await dispatch(fetchAaveAccountData());
    dispatch(getMaxBoostAction());
    trackEvent('aaveManage', 'boostSuccess');
  } catch (err) {
    dispatch({ type: aaveManageTypes.AAVE_BOOST_FAILURE, payload: err.message });
    captureException(err);
    trackEvent('aaveManage', 'boostError', err.message);
  }
};

export const aaveRepayAction = (inputAmount, closeModal) => async (dispatch, getState) => {
  const { general: { walletType } } = getState();
  if (!isWalletTypeProxy(walletType)) return dispatch(openAaveProxyMigrationModal());

  trackEvent('aaveManage', 'repay');
  dispatch({ type: aaveManageTypes.AAVE_REPAY_REQUEST });

  const { actionsSelectValues, repayAmount } = getState().aaveManage;
  const withdrawAsset = actionsSelectValues.repayWithdraw.value;
  const paybackAsset = actionsSelectValues.repayPayback.value;

  const amount = assetAmountInWei(inputAmount, withdrawAsset);

  const sendTxFunc = (promise) => {
    const amount = formatNumber(parseFloat(inputAmount), 2);
    const additionalInfo = {
      protocol: 'aave',
      firstToken: withdrawAsset,
      firstAmount: inputAmount,
      secondToken: paybackAsset,
      secondAmount: repayAmount,
    };
    return sendTx(promise, `${t('common.repay')} ${amount} ${withdrawAsset}`, 'aave', dispatch, getState, additionalInfo);
  };

  try {
    await callSaverProxyContract(getState, amount, paybackAsset, withdrawAsset, 'repay', sendTxFunc);

    dispatch({ type: aaveManageTypes.AAVE_REPAY_SUCCESS });

    dispatch(setAfterValue(0, 'clear'));
    dispatch(change('aaveManageAdvancedForm', 'repayAmount', null));
    closeModal();
    await dispatch(fetchAaveAssetsData());
    await dispatch(fetchAaveAccountData());
    dispatch(getMaxRepayAction());
    trackEvent('aaveManage', 'repaySuccess');
  } catch (err) {
    dispatch({ type: aaveManageTypes.AAVE_REPAY_FAILURE, payload: err.message });
    captureException(err);
    trackEvent('aaveManage', 'repayError', err.message);
  }
};

/**
 * Handles redux actions for the aave supply tab withdraw action
 *
 * @param _amount {String}
 *
 * @return {Function}
 */
export const aaveWithdrawAction = _amount => async (dispatch, getState) => {
  const {
    general: {
      walletType, account, accountType, path,
    },
    maker: { proxyAddress },
    aaveManage,
  } = getState();
  const {
    actionsSelectValues, ratioTooLow, ratioTooHigh, selectedMarket,
  } = aaveManage;
  const { usedAssets } = aaveManage[walletType.value][selectedMarket.value];
  const asset = actionsSelectValues.withdraw.value;
  const supplied = usedAssets[asset].supplied;

  if (ratioTooLow || ratioTooHigh) {
    const confirmed = await dispatch(confirmViaModal(t('errors.automatic_trigger')));
    if (!confirmed) return false;
  }

  trackEvent('aaveManage', 'withdraw');
  dispatch({ type: aaveManageTypes.AAVE_WITHDRAW_REQUEST });

  const amount = formatNumber(parseFloat(_amount), 2);
  const sendTxFunc = (promise, waitForSign) => {
    const additionalInfo = {
      protocol: 'aave',
      firstToken: asset,
      firstAmount: _amount,
    };
    return sendTx(promise, `${t('common.withdraw')}: ${amount} ${asset}`, 'Aave', dispatch, getState, additionalInfo, waitForSign);
  };

  const txToExecute = [];
  const withdrawFunctionObject = {
    type: 'withdraw',
    notifMessage: `${t('common.withdraw')}: ${amount} ${asset}`,
  };

  try {
    withdrawFunctionObject.func = () => withdraw(accountType, path, sendTxFunc, account, proxyAddress, walletType, _amount, asset, supplied);

    txToExecute.push(withdrawFunctionObject);

    const returnValues = await dispatch(callMultipleTxAction(txToExecute));
    dispatch({ type: aaveManageTypes.AAVE_WITHDRAW_SUCCESS });

    dispatch(setAfterValue(0, 'clear'));
    dispatch(change('aaveManageCollateralForm', 'withdrawAmount', null));
    await dispatch(fetchAaveAssetsData());
    await dispatch(fetchAaveAccountData());
    dispatch(getMaxWithdrawAction());
    trackEvent('aaveManage', 'withdrawSuccess');
  } catch (err) {
    dispatch({ type: aaveManageTypes.AAVE_WITHDRAW_FAILURE, payload: err.message });
    captureException(err);
    trackEvent('aaveManage', 'withdrawError', err.message);
  }
};

/**
 * Handles redux actions for the aave supply tab supply action
 *
 * @param _amount {String}
 * @return {Function}
 */
export const aaveSupplyAction = _amount => async (dispatch, getState) => {
  const {
    general: {
      walletType, account, accountType, path,
    },
    maker: { proxyAddress },
  } = getState();
  const {
    actionsSelectValues, ratioTooLow, ratioTooHigh, selectedMarket,
  } = getState().aaveManage;
  const { usedAssets } = getState().aaveManage[walletType.value][selectedMarket.value];

  const asset = actionsSelectValues.supply.value;

  if (ratioTooLow || ratioTooHigh) {
    const confirmed = await dispatch(confirmViaModal(t('errors.automatic_trigger')));
    if (!confirmed) return false;
  }

  trackEvent('aaveManage', 'supply');
  dispatch({ type: aaveManageTypes.AAVE_SUPPLY_REQUEST });

  const sendTxFunc = (promise, waitForSign) => {
    const amount = formatNumber(parseFloat(_amount), 2);
    const additionalInfo = {
      protocol: 'aave',
      firstToken: asset,
      firstAmount: _amount,
    };
    return sendTx(promise, `${t('common.supply')}: ${amount} ${asset}`, 'Aave', dispatch, getState, additionalInfo, waitForSign);
  };

  try {
    if (usedAssets[asset]?.isBorrowed && !usedAssets[asset]?.isSupplied) {
      const confirmed = await dispatch(confirmViaModal(t('compound.warning_supplying_borrowed')));
      if (!confirmed) throw new Error(t('errors.denied_transaction'));
    }
    const isProxy = isWalletTypeProxy(walletType);
    const txToExecute = [];
    const approveFunctionObject = {
      func: () => dispatch(approveAddressOnAssetAction(asset, isProxy ? proxyAddress : aaveLendingPoolCoreAddress, isProxy ? APPROVE_TYPE_DS_PROXY : APPROVE_TYPE_AAVE)),
      type: 'approve',
      notifMessage: `${t('common.approve')} ${approveTypeToLabelMap[isProxy ? APPROVE_TYPE_DS_PROXY : APPROVE_TYPE_AAVE]} ${t('common.for')} ${asset}`,
    };
    const supplyFunctionObject = {
      type: 'supply',
      notifMessage: `${t('common.supply')}: ${formatNumber(parseFloat(_amount), 2)} ${asset}`,
    };
    supplyFunctionObject.func = () => supply(accountType, path, sendTxFunc, account, proxyAddress, walletType, _amount, asset);

    await dispatch(addApproveTxIfNeeded(asset, isProxy ? proxyAddress : aaveLendingPoolCoreAddress, _amount, isProxy ? APPROVE_TYPE_DS_PROXY : APPROVE_TYPE_AAVE, txToExecute, approveFunctionObject));
    txToExecute.push(supplyFunctionObject);
    const returnValues = await dispatch(callMultipleTxAction(txToExecute));

    const receipt = returnValues[returnValues.length - 1];
    // parseReceipt(receipt);
    dispatch({ type: aaveManageTypes.AAVE_SUPPLY_SUCCESS });

    dispatch(setAfterValue(0, 'clear'));
    dispatch(change('aaveManageCollateralForm', 'supplyAmount', null));
    await dispatch(fetchAaveAssetsData());
    await dispatch(fetchAaveAccountData());
    dispatch(getMaxSupplyAction());
    trackEvent('aaveManage', 'supplySuccess');
  } catch (err) {
    dispatch({ type: aaveManageTypes.AAVE_SUPPLY_FAILURE, payload: err.message });
    captureException(err);
    trackEvent('aaveManage', 'supplyError', err.message);
  }
};

/**
 * Handles redux actions for the aave borrow tab borrow action
 *
 * @param _amount {String}
 *
 * @return {Function}
 */
export const aaveBorrowAction = (_amount) => async (dispatch, getState) => {
  const {
    general: {
      walletType, account, accountType, path,
    },
    maker: { proxyAddress },
    aaveManage: {
      actionsSelectValues, ratioTooLow, ratioTooHigh, selectedMarket,
    },
  } = getState();
  const { usedAssets } = getState().aaveManage[walletType.value][selectedMarket.value];

  const asset = actionsSelectValues.borrow.value;

  const interestRate = await dispatch(chooseAaveBorrowInterestRateMode(_amount, asset, usedAssets, selectedMarket.value));
  if (!interestRate) throw new Error('User canceled interest rate modal.');
  const { name, value } = interestRate;

  if (ratioTooLow || ratioTooHigh) {
    const confirmed = await dispatch(confirmViaModal(t('errors.automatic_trigger')));
    if (!confirmed) return false;
  }

  trackEvent('aaveManage', 'borrow');
  dispatch({ type: aaveManageTypes.AAVE_BORROW_REQUEST });

  const sendTxFunc = (promise, waitForSign) => {
    const amount = formatNumber(parseFloat(_amount), 2);
    const additionalInfo = {
      protocol: 'aave',
      firstToken: asset,
      firstAmount: _amount,
    };
    return sendTx(promise, `${t('common.borrow')}: ${amount} ${asset} with ${name}`, 'Aave', dispatch, getState, additionalInfo, waitForSign);
  };

  try {
    if (usedAssets[asset]?.isSupplied && !usedAssets[asset]?.isBorrowed) {
      const confirmed = await dispatch(confirmViaModal(t('compound.warning_borrowing_supplied')));
      if (!confirmed) throw new Error(t('errors.denied_transaction'));
    }

    const txToExecute = [];
    const approveFunctionObject = {
      type: 'approve',
      notifMessage: `${t('common.approve')} Aave WETH Adapter`,
    };
    const borrowFunctionObject = {
      type: 'borrow',
      notifMessage: `${t('common.borrow')}: ${formatNumber(parseFloat(_amount), 2)} ${asset} with ${name}`,
    };

    borrowFunctionObject.func = () => borrow(accountType, path, sendTxFunc, account, proxyAddress, walletType, _amount, value, asset);
    txToExecute.push(borrowFunctionObject);

    const returnValue = await dispatch(callMultipleTxAction(txToExecute));
    dispatch({ type: aaveManageTypes.AAVE_BORROW_SUCCESS });
    dispatch(setAfterValue(0, 'clear'));

    dispatch(change('aaveManageDebtForm', 'borrowAmount', null));
    await dispatch(fetchAaveAssetsData());
    await dispatch(fetchAaveAccountData());
    dispatch(getMaxBorrowAction());
    trackEvent('aaveManage', 'borrowSuccess');
  } catch (err) {
    dispatch({ type: aaveManageTypes.AAVE_BORROW_FAILURE, payload: err.message });
    captureException(err);
    trackEvent('aaveManage', 'borrowError', err.message);
  }
};

/**
 * Handles redux actions for the aave borrow tab payback action
 *
 * @param _amount {String}
 *
 * @return {Function}
 */
export const aavePaybackAction = _amount => async (dispatch, getState) => {
  const {
    general: {
      walletType, account, accountType, path,
    },
    maker: { proxyAddress },
    aaveManage: {
      actionsSelectValues, ratioTooLow, ratioTooHigh, selectedMarket,
    },
  } = getState();
  const { usedAssets } = getState().aaveManage[walletType.value][selectedMarket.value];
  const asset = actionsSelectValues.payback.value;
  const _borrowed = usedAssets[asset].borrowed;

  if (ratioTooLow || ratioTooHigh) {
    const confirmed = await dispatch(confirmViaModal(t('errors.automatic_trigger')));
    if (!confirmed) return false;
  }

  trackEvent('aaveManage', 'payback');
  dispatch({ type: aaveManageTypes.AAVE_PAYBACK_REQUEST });

  const sendTxFunc = (promise, waitForSign) => {
    const amount = formatNumber(_amount, 2);
    const additionalInfo = {
      protocol: 'aave',
      firstToken: asset,
      firstAmount: _amount,
    };
    return sendTx(promise, `${t('common.payback')}: ${amount} ${asset}`, 'Aave', dispatch, getState, additionalInfo, waitForSign);
  };

  try {
    const isProxy = isWalletTypeProxy(walletType);
    const txToExecute = [];

    const approveFunctionObject = {
      func: () => dispatch(approveAddressOnAssetAction(asset, isProxy ? proxyAddress : aaveLendingPoolCoreAddress, isProxy ? APPROVE_TYPE_DS_PROXY : APPROVE_TYPE_AAVE)),
      type: 'approve',
      notifMessage: `${t('common.approve')} ${approveTypeToLabelMap[isProxy ? APPROVE_TYPE_DS_PROXY : APPROVE_TYPE_AAVE]} ${t('common.for')} ${asset}`,
    };
    const paybackFunctionObject = {
      type: 'payback',
      notifMessage: `${t('common.payback')}: ${formatNumber(parseFloat(_amount), 2)} ${asset}`,
    };

    paybackFunctionObject.func = () => payback(accountType, path, sendTxFunc, account, proxyAddress, walletType, _amount, asset, _borrowed);

    await dispatch(addApproveTxIfNeeded(asset, isProxy ? proxyAddress : aaveLendingPoolCoreAddress, _amount, isProxy ? APPROVE_TYPE_DS_PROXY : APPROVE_TYPE_AAVE, txToExecute, approveFunctionObject));
    txToExecute.push(paybackFunctionObject);
    const returnValues = await dispatch(callMultipleTxAction(txToExecute));

    dispatch({ type: aaveManageTypes.AAVE_PAYBACK_SUCCESS });

    dispatch(setAfterValue(0, 'clear'));
    dispatch(change('aaveManageDebtForm', 'paybackAmount', null));
    await dispatch(fetchAaveAssetsData());
    await dispatch(fetchAaveAccountData());
    dispatch(getMaxPaybackAction());
    trackEvent('aaveManage', 'paybackSuccess');
  } catch (err) {
    dispatch({ type: aaveManageTypes.AAVE_PAYBACK_FAILURE, payload: err.message });
    captureException(err);
    trackEvent('aaveManage', 'paybackError', err.message);
  }
};

// ACTIONS EXECUTORS END

/**
 * Sets the Aave manage page active tab to the reducer
 *
 * @param payload
 * @return {Function}
 */
export const setAaveManageActiveTab = payload => (dispatch) => {
  dispatch({ type: aaveManageTypes.AAVE_MANAGE_SET_ACTIVE_TAB, payload });
};

export const getBoostModalData = (inputAmount, borrowAsset, supplyAsset) => async (dispatch, getState) => {
  dispatch({ type: aaveManageTypes.GET_BOOST_MODAL_DATA_REQUEST });

  try {
    const { proxyAddress } = getState().maker;
    const { price: exchangeRate, source } = await getBestExchangePrice(inputAmount.toString(), borrowAsset, supplyAsset, proxyAddress);
    const stakeInsteadOfSell = borrowAsset === 'ETH' && supplyAsset === 'stETH' && +exchangeRate <= 1;
    const boostAmount = stakeInsteadOfSell ? inputAmount : new Dec(exchangeRate).times(inputAmount).toString();

    const marketPrice = await getSlippageThreshold(borrowAsset, supplyAsset);
    const tradeSizeImpact = calculateTradeSizeImpact(marketPrice, exchangeRate);
    await dispatch(getMaxBorrowAction(true));
    const maxBorrow = getState().aaveManage.maxBorrow;
    const useFl = new Dec(inputAmount).gt(maxBorrow);
    const flData = useFl
      ? (await flProtocolAndFeeFor(inputAmount, borrowAsset, getState().general.network))
      : {
        protocol: 'none', feeMultiplier: '1', flFee: '0', useAltRecipe: true,
      };

    dispatch({
      type: aaveManageTypes.GET_BOOST_MODAL_DATA_SUCCESS,
      payload: {
        boostAmount,
        tradeSizeImpact,
        useFl,
        boostExchangeRate: exchangeRate,
        exchangeSource: source,
        useAltRecipe: flData.useAltRecipe,
        flProtocol: flData.protocol,
        flFee: flData.flFee,
      },
    });
  } catch (err) {
    dispatch({ type: aaveManageTypes.GET_BOOST_MODAL_DATA_FAILURE, payload: err.message });
    captureException(err);
  }
};

/**
 * Fetches the repay modal data
 *
 * @param inputAmount
 * @param withdrawAsset
 * @param paybackAsset
 * @return {function(...[*]=)}
 */
export const getRepayModalData = (inputAmount, withdrawAsset, paybackAsset) => async (dispatch, getState) => {
  dispatch({ type: aaveManageTypes.GET_REPAY_MODAL_DATA_REQUEST });

  try {
    const { proxyAddress } = getState().maker;
    const { price: exchangeRate, source } = await getBestExchangePrice(inputAmount.toString(), withdrawAsset, paybackAsset, proxyAddress);

    const repayAmount = new Dec(inputAmount).times(exchangeRate).toString();

    const marketPrice = await getSlippageThreshold(withdrawAsset, paybackAsset);
    const tradeSizeImpact = calculateTradeSizeImpact(marketPrice, exchangeRate);

    await dispatch(getMaxWithdrawAction(true));
    const maxBorrow = getState().aaveManage.maxWithdraw;
    const useFl = new Dec(inputAmount).gt(maxBorrow);
    const flData = useFl
      ? (await flProtocolAndFeeFor(inputAmount, withdrawAsset, getState().general.network))
      : {
        protocol: 'none', feeMultiplier: '1', useAltRecipe: true, flFee: '0',
      };

    const payload = {
      repayAmount,
      tradeSizeImpact,
      useFl,
      repayExchangeRate: exchangeRate,
      exchangeSource: source,
      useAltRecipe: flData.useAltRecipe,
      flProtocol: flData.protocol,
      flFee: flData.flFee,
    };

    dispatch({ type: aaveManageTypes.GET_REPAY_MODAL_DATA_SUCCESS, payload });
  } catch (err) {
    dispatch({ type: aaveManageTypes.GET_REPAY_MODAL_DATA_FAILURE, payload: err.message });
    captureException(err);
  }
};
