import Dec from 'decimal.js';
import { getAssetInfo, getAssetInfoByAddress } from '@defisaver/tokens';
import clientConfig from '../config/clientConfig.json';
import { formatPriceWithDecimalForContract } from './exchangeServiceCommon';
import { log } from './defisaverLogger';
import { ZEROX_WRAPPER } from '../config/exchangeWrappers';
import { getWeiAmountForDecimals, isAddress } from './utils';
import { getERC20TokenData } from './erc20Service';

const API_URL = {
  1: 'https://api.0x.org/swap/v1/',
  10: 'https://optimism.api.0x.org/swap/v1/',
  42: 'https://kovan.api.0x.org/swap/v1/',
};

const defaultExcludedSources = ['LiquidityProvider'];

export const get0xEmptyData = () => ({
  to: '0x0000000000000000000000000000000000000000',
  data: '0x00',
  price: '0',
  value: '0',
  protocolFee: '0',
  allowanceTarget: '0x0000000000000000000000000000000000000000',
  wrapper: '0x0000000000000000000000000000000000000000',
  gas: 0,
});

/**
 * Get 0x price from API
 *
 * @param sellToken {String}
 * @param buyToken {String}
 * @param amount {String}
 * @param convertAmountToWei {Boolean} should amount be converted to wei
 * @param infoOnly {Boolean} should be true if just for showing price
 * @param takerAddress {String} address that will take order from 0x
 * @param slippagePercentage {Number} percentage of slippage limit in format 0.03 (this is 3%, fixed to 2 decimal places)
 * @param shouldSell {Boolean} look for price to sell or to buy (if false sellToken becomes becomes buyToken and vice-versa)
 * @param _excludeSources {array} exclude liquidity sources from 0x
 * @param network {number}
 * @return {{data: string, price: string, protocolFee: string, to: string, value: string}}
 */
export const get0xPrice = async (
  sellToken, buyToken, amount, convertAmountToWei = true, infoOnly = false, takerAddress = '0x0000000000000000000000000000000000000001', slippagePercentage = 0.03, shouldSell = true, _excludeSources = [], network,
) => {
  const apiUrl = API_URL[network];
  try {
    const isSellAddress = isAddress(sellToken);
    const isBuyAddress = isAddress(buyToken);

    const zeroxToken = 'ac54d378-2454-45ed-add8-f00710bd3e0c';
    // we are always dealing with weth
    let buyTokenConverted = isBuyAddress
      ? await getERC20TokenData(buyToken) : buyToken === 'ETH' ? getAssetInfo('WETH') : getAssetInfo(buyToken);
    if (isBuyAddress && buyTokenConverted.address.toLowerCase() === getAssetInfo('ETH').address.toLowerCase()) {
      buyTokenConverted = getAssetInfo('WETH');
    }

    let sellTokenConverted = isSellAddress
      ? await getERC20TokenData(sellToken) : sellToken === 'ETH' ? getAssetInfo('WETH') : getAssetInfo(sellToken);
    if (isSellAddress && sellTokenConverted.address.toLowerCase() === getAssetInfo('ETH').address.toLowerCase()) {
      sellTokenConverted = getAssetInfo('WETH');
    }

    const convertedAmount = convertAmountToWei ? getWeiAmountForDecimals(amount, shouldSell ? sellTokenConverted.decimals : buyTokenConverted.decimals) : amount;

    let url = '';
    const excludedSources = [...defaultExcludedSources, ..._excludeSources];
    if (shouldSell) {
      url = `${apiUrl}${infoOnly ? 'price' : 'quote'}?sellToken=${sellTokenConverted.address}&buyToken=${buyTokenConverted.address}&sellAmount=${convertedAmount}&affiliateAddress=0x322d58b9E75a6918f7e7849AEe0fF09369977e08&takerAddress=${takerAddress}&excludedSources=${excludedSources.join(',')}`;
    } else {
      url = `${apiUrl}${infoOnly ? 'price' : 'quote'}?sellToken=${sellTokenConverted.address}&buyToken=${buyTokenConverted.address}&buyAmount=${convertedAmount}&affiliateAddress=0x322d58b9E75a6918f7e7849AEe0fF09369977e08&takerAddress=${takerAddress}&excludedSources=${excludedSources.join(',')}`;
    }

    if (!infoOnly) {
      url += `&intentOnFilling=true&skipValidation=true&slippagePercentage=${0.1}&shouldSellEntireBalance=true`;
    }

    const res = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        '0x-API-KEY': zeroxToken,
      },
    });

    if (!res.ok) throw new Error(await res.text());

    const data = await res.json();

    if (!infoOnly) {
      log('0xorder', JSON.stringify({
        request: url,
        response: data,
      }));
    }
    // convert price to be able to compare with other prices onchain
    if (!shouldSell) {
      data.price = new Dec(1).div(data.price).toString();
      if (data.guaranteedPrice) data.guaranteedPrice = new Dec(1).div(data.guaranteedPrice).toString();
    }
    data.price = formatPriceWithDecimalForContract(data.price, sellTokenConverted.decimals, buyTokenConverted.decimals);
    if (new Dec(data.protocolFee).gt(0)) {
      data.protocolFee = new Dec(data.protocolFee).mul(150).div(100).toString();
    }
    data.value = sellTokenConverted.address === getAssetInfo('WETH').address
      ? new Dec(data.value).plus(convertedAmount).toString()
      : data.value;
    data.wrapper = ZEROX_WRAPPER[network];
    data.sources.forEach((source) => {
      // eslint-disable-next-line no-param-reassign
      if (source.hops) source.name = `${source.name} (${source.hops.join(` → ${getAssetInfoByAddress(source.intermediateToken).symbol} → `)})`;
    });

    return data;
  } catch (e) {
    return get0xEmptyData();
  }
};

