import React from 'react';
import t from 'translate';
import PropTypes from 'prop-types';
import ActionInfoItem from './AssetInfoItem';
import LPInfo from './LpInfo/LPInfo';


const ActionInfo = ({
  firstAsset, secondAsset, firstAmount, secondAmount, isSameAsset, exchangeRate, exchangeLabel, isRepay, additionalAsset,
  additionalAction, additionalInput, isLP, lpAction, advancedLP, setAdvancedLP, exchangeRateSecond, exchangeLabelSecond,
}) => (
  <div className={`action-info container ${additionalAction ? 'has-additional' : ''}`}>
    {
        additionalAction && additionalAction.goesFirst && (
          <div className="action-item-section">
            <ActionInfoItem
              amount={additionalInput}
              asset={additionalAsset}
              actionName={`${additionalAction.executingLabel} ${t('common.from_account')}`}
            />
          </div>
        )
      }
    <div className="action-item-section">
      <ActionInfoItem
        amount={firstAmount}
        asset={firstAsset}
        actionName={isRepay
          ? isSameAsset ? t('common.repaying') : t('common.withdrawing')
          : isSameAsset ? t('common.leveraging') : t('common.borrowing')}
      />

      {
          isLP && (
            <LPInfo
              advanced={advancedLP}
              setAdvanced={setAdvancedLP}
              exchangeRate={exchangeRate}
              exchangeLabel={exchangeLabel}
              isRepay={isRepay}
              lpAction={lpAction}
              exchangeRateSecond={exchangeRateSecond}
              exchangeLabelSecond={exchangeLabelSecond}
            />
          )
        }

      {!isSameAsset && !isLP && (
      <ActionInfoItem
        amount={secondAmount}
        asset={secondAsset}
        exchangeRate={exchangeRate}
        actionName={`${t('common.buying')}${exchangeRate ? ` ${t('common.with_swap_rate')}` : ''}: `}
        exchangeLabel={exchangeLabel}
      />
      )}
      {
          !isSameAsset && isLP && (
            <ActionInfoItem
              amount={secondAmount}
              asset={secondAsset}
              actionName={isRepay ? t('common.paying_back') : t('common.supplying')}
              estimate
            />
          )
        }
    </div>

    {additionalAction && !additionalAction.goesFirst && (
    <div className="action-item-section">
      <ActionInfoItem
        amount={additionalInput}
        asset={additionalAsset}
        actionName={`${additionalAction.executingLabel} ${t('common.to_account')}`}
      />
    </div>
    )}
  </div>
);
ActionInfo.defaultProps = {
  isRepay: false,
  isSameAsset: false,
  isLP: false,
  lpAction: {},
  exchangeRateSecond: '',
  exchangeLabelSecond: '',
  additionalAsset: '',
  advancedLP: false,
  setAdvancedLP: () => {},
};

ActionInfo.propTypes = {
  exchangeRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  exchangeLabel: PropTypes.string.isRequired,
  firstAsset: PropTypes.string.isRequired,
  secondAsset: PropTypes.string.isRequired,
  firstAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  secondAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isSameAsset: PropTypes.bool,
  isRepay: PropTypes.bool,
  additionalAction: PropTypes.object,
  additionalInput: PropTypes.string,
  additionalAsset: PropTypes.string,
  isLP: PropTypes.bool,
  lpAction: PropTypes.object,
  advancedLP: PropTypes.bool,
  setAdvancedLP: PropTypes.func,
  exchangeRateSecond: PropTypes.string,
  exchangeLabelSecond: PropTypes.string,
};

export default ActionInfo;
