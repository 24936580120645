import config from 'config/config.json';
import clientConfig from 'config/clientConfig.json';
import callTx from 'services/txService';


export const callViaProxy = async (
  accountType, sendTxFunc, proxyAddress, account, contractName, funcName, funcParams, value = '0', minGas, extraGas,
) => {
  const contract = config[contractName];

  const dsProxyContract = new window._web3.eth.Contract(config.DSProxy.abi, proxyAddress);

  const contractFunction = contract.abi.find(abi => abi.name === funcName);
  const data = window._web3.eth.abi.encodeFunctionCall(contractFunction, funcParams);

  const txParams = { from: account, value };
  const executeParams = [contract.networks[clientConfig.network].address, data];

  if (process.env.NODE_ENV !== 'production' || window.debugLogs) {
    console.log(`Calling ${contractName} at ${contract.networks[clientConfig.network].address}`);
    console.log(`    via Proxy at ${proxyAddress}`);
    console.log(`    Function "${funcName}" with params`, funcParams);
    console.log('    execute() params', executeParams);
    console.log('    Transaction params', txParams);
  }

  return callTx(accountType, 'not used', sendTxFunc, dsProxyContract, 'execute(address,bytes)', executeParams, txParams, funcName, minGas, extraGas);
};

export const callActionViaProxy = async (
  accountType, sendTxFunc, proxyAddress, account, Action, minGas, extraGas,
) => {
  const dsProxyContract = new window._web3.eth.Contract(config.DSProxy.abi, proxyAddress);

  const txParams = {
    from: account,
    value: await Action.getEthValue(),
  };
  const executeParams = Action.encodeForDsProxyCall();

  console.log(executeParams);

  if (process.env.NODE_ENV !== 'production' || window.debugLogs) {
    console.log(`Calling action "${Action.name}" at ${Action.contractAddress}`);
    console.log(`    via Proxy at ${proxyAddress}`);
    console.log('    with params', Action.args);
    console.log('    execute() params', executeParams);
    console.log('    Transaction params', txParams);
  }

  return callTx(accountType, 'not used', sendTxFunc, dsProxyContract, 'execute(address,bytes)', executeParams, txParams, Action.name, minGas, extraGas);
};

export const callRecipeViaProxy = async (
  accountType, sendTxFunc, proxyAddress, account, recipe, minGas, extraGas,
) => {
  if (recipe.actions.length === 1) {
    return callActionViaProxy(accountType, sendTxFunc, proxyAddress, account, recipe.actions[0], minGas, extraGas);
  }

  const dsProxyContract = new window._web3.eth.Contract(config.DSProxy.abi, proxyAddress);

  const txParams = {
    from: account,
    value: await recipe.getEthValue(),
    accessList: recipe.getAccessList(),
  };
  const executeParams = recipe.encodeForDsProxyCall();

  if (process.env.NODE_ENV !== 'production' || window.debugLogs) {
    console.log(`Calling recipe "${recipe.name}" at ${recipe.recipeExecutorAddress}`);
    console.log(`    via Proxy at ${proxyAddress}`);
    console.log('    with actions', recipe.actions);
    console.log('    execute() params', executeParams);
    console.log('    Transaction params', txParams);
  }

  return callTx(accountType, 'not used', sendTxFunc, dsProxyContract, 'execute(address,bytes)', executeParams, txParams, recipe.name, minGas, extraGas);
};
