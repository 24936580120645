import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ModalHeader from '../ModalHeader';
import ModalBody from '../ModalBody';
import { loginFork } from '../../../actions/accountActions';
import './SimulationStartModal.scss';

const SimulationStartModal = ({
  closeModal, account, accountType, loginFork,
}) => (
  <div className="simulation-start-modal-wrapper">
    <ModalHeader closeModal={closeModal} />

    <ModalBody>

      <div className="new-modal-top-wrapper">
        <h1>Create a simulation</h1>
      </div>

      <div className="new-modal-content-wrapper">
        <p>
          While in simulation mode, you&apos;ll be able to explore all DeFi Saver features with
          a starting balance of 100 ETH.
          <br />
          <br />
          The current block will be frozen, so rates, prices & other
          &quot;live data&quot; from the blockchain won&apos;t be updating until you exit the simulation.
          <br />
          <br />
          With that in mind, feel free to explore and play around for as long as you&apos;d like.
        </p>

        <div className="Flex">
          {
            account && (
              <button
                type="button"
                onClick={() => loginFork(false, account, accountType)}
                className="button MarginRight10"
              >
                Current account
              </button>
            )
          }
          <button
            type="button"
            onClick={() => loginFork()}
            className="button green highlighted"
          >
            New account
          </button>
        </div>
      </div>
    </ModalBody>

    {/* <div className="modal-controls"> */}
    {/*  <button */}
    {/*    type="button" */}
    {/*    onClick={() => loginFork(false, account, accountType)} */}
    {/*    className="button" */}
    {/*  > */}
    {/*    Simulate current account */}
    {/*  </button> */}
    {/*  <button */}
    {/*    type="button" */}
    {/*    onClick={() => loginFork()} */}
    {/*    className="button green" */}
    {/*  > */}
    {/*    Create new simulation account */}
    {/*  </button> */}
    {/* </div> */}
  </div>
);

SimulationStartModal.propTypes = {
  account: PropTypes.string.isRequired,
  accountType: PropTypes.string.isRequired,
  loginFork: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

const mapStateToProps = ({ general }) => ({
  account: general.account,
  accountType: general.accountType,
});

const mapDispatchToProps = {
  loginFork,
};

export default connect(mapStateToProps, mapDispatchToProps)(SimulationStartModal);
