export const ADD_COMPOUND_MIGRATE_INSTADAPP_DATA = 'ADD_COMPOUND_MIGRATE_INSTADAPP_DATA ';
export const RESET_COMPOUND_MIGRATE_INSTADAPP_DATA = 'RESET_COMPOUND_MIGRATE_INSTADAPP_DATA';
export const ALLOW_COMPOUND_MIGRATE_INSTADAPP_DATA = 'ALLOW_COMPOUND_MIGRATE_INSTADAPP_DATA';
export const DENY_COMPOUND_MIGRATE_INSTADAPP_DATA = 'DENY_COMPOUND_MIGRATE_INSTADAPP_DATA';

export const COMPOUND_MIGRATE_PROXY_AUTH_REQUEST = 'COMPOUND_MIGRATE_PROXY_AUTH_REQUEST';
export const COMPOUND_MIGRATE_PROXY_AUTH_SUCCESS = 'COMPOUND_MIGRATE_PROXY_AUTH_SUCCESS';
export const COMPOUND_MIGRATE_PROXY_AUTH_ERROR = 'COMPOUND_MIGRATE_PROXY_AUTH_ERROR';

export const COMPOUND_MIGRATE_FROM_INSTADAPP_REQUEST = 'COMPOUND_MIGRATE_FROM_INSTADAPP_REQUEST';
export const COMPOUND_MIGRATE_FROM_INSTADAPP_SUCCESS = 'COMPOUND_MIGRATE_FROM_INSTADAPP_SUCCESS';
export const COMPOUND_MIGRATE_FROM_INSTADAPP_ERROR = 'COMPOUND_MIGRATE_FROM_INSTADAPP_ERROR';

export const MIGRATE_COMPOUND_TO_PROXY_REQUEST = 'MIGRATE_COMPOUND_TO_PROXY_REQUEST';
export const MIGRATE_COMPOUND_TO_PROXY_SUCCESS = 'MIGRATE_COMPOUND_TO_PROXY_SUCCESS';
export const MIGRATE_COMPOUND_TO_PROXY_FAILURE = 'MIGRATE_COMPOUND_TO_PROXY_FAILURE';

export const COMPOUND_MIGRATE_APPROVALS_REQUEST = 'COMPOUND_MIGRATE_APPROVALS_REQUEST';
export const COMPOUND_MIGRATE_APPROVALS_SUCCESS = 'COMPOUND_MIGRATE_APPROVALS_SUCCESS';
export const COMPOUND_MIGRATE_APPROVALS_FAILURE = 'COMPOUND_MIGRATE_APPROVALS_FAILURE';

export const COMPOUND_MIGRATE_PROXY_RESET_STATE = 'COMPOUND_MIGRATE_PROXY_RESET_STATE';
