import dfs from '@defisaver/sdk';
import {
  assetAmountInWei, getAssetInfo, getAssetInfoByAddress,
} from '@defisaver/tokens';
import { exchangeAssets } from 'constants/assets';
import {
  FeeAmount, tickToPrice,
} from '@uniswap/v3-sdk';
import cloneDeep from 'lodash/cloneDeep';
import Dec from 'decimal.js';
import RecipeAction from '../RecipeAction';
import SupplyIcon from '../recipeIcons/Supply.svg';
import {
  Address,
  Amount, Asset, Deadline, FeeTier, NftId, Slippage, Source, Helper,
} from '../../components/Recipes/RecipeCreator/inputTypes';
import { changeBalance } from '../../services/recipeCreator/recipeActionUtils';
import { setSlippagePercent } from '../../services/exchangeServiceCommon';
import {
  getFeeLabel, getPool, getSqrtRatio, getTokens, mockPool, mockPosition, pricesToTicks,
} from '../../services/uniswapV3Services';

const _exchangeAssets = [...exchangeAssets()];
_exchangeAssets[0] = getAssetInfo('WETH');

export default class UniswapV3MintAction extends RecipeAction {
  static prettyName = 'Create LP NFT';

  static protocol = 'uniswap';

  static protocolPrettyName = 'Uniswap';

  static description = 'Creates a new Uniswap v3 position and NFT.';

  constructor(firstAsset = 'WETH', secondAsset = 'DAI', feeTier = '0.3', switchBase = false, tickLower = '', tickHigher = '', firstAmount = '', secondAmount = '', source = 'wallet', to = '0x0', slippage = '2', deadline = '20', startingPrice = '') {
    super();
    this.inputs = [
      new Asset('First token', firstAsset, _exchangeAssets),
      new Asset('Second token', secondAsset, _exchangeAssets),
      new FeeTier('Fee', feeTier),
      new Helper('Switch Base', switchBase),
      new Amount('First tick', tickLower),
      new Amount('Second tick', tickHigher),
      new Amount('Amount', firstAmount),
      new Amount('Amount', secondAmount),
      new Source('From', source),
      new Address('Recipient', to),
      new Slippage('Slippage tolerance', slippage),
      new Deadline('Deadline', deadline),
      new Amount('Starting price', startingPrice),
    ];
    this.output = new NftId('output', 0);
    this.error = '';
  }

  static getIcon() {
    return SupplyIcon;
  }

  setError(e) {
    this.error = e;
  }

  getError() {
    return this.error;
  }

  getExpires() {
    if (this.updatedAt) {
      return this.updatedAt + (2 * 60 * 1000);
    }
    return Infinity;
  }

  async getAfterValues(_balances = {}, returnValues = [], actions = [], _positions = {}, getState) {
    const positions = cloneDeep(_positions);
    const {
      maker: { proxyAddress },
      general: { account },
    } = getState();
    const balances = cloneDeep(_balances);
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const nftId = 'New position';
    try {
      if (!args[6] || !args[7]) throw new Error('One of amounts is empty');
      const invert = typeof args[3] === 'boolean' ? args[3] : (args[3] !== 'false');
      const [tokenFirstInfo, tokenSecondInfo, firstToken, secondToken] = getTokens(getAssetInfoByAddress(args[0]), getAssetInfoByAddress(args[1]));
      const [tickLower, tickUpper] = pricesToTicks(args[4], args[5], firstToken, secondToken, tokenFirstInfo, tokenSecondInfo, args[2], invert);
      let _pool;
      try {
        _pool = (await getPool(firstToken, secondToken, args[2]))[0];
      } catch (e) {
        _pool = mockPool(firstToken, secondToken, tokenFirstInfo, tokenSecondInfo, args[2], args[12], invert);
      }
      const position = mockPosition(firstToken, secondToken, tokenFirstInfo, tokenSecondInfo, tickLower, tickUpper, _pool, args[6], true);
      await changeBalance(balances, args[8], tokenFirstInfo.symbol, new Dec(args[6]).mul(-1).toString(), args[8] === 'wallet' ? account : proxyAddress);
      await changeBalance(balances, args[8], tokenSecondInfo.symbol, new Dec(args[7]).mul(-1).toString(), args[8] === 'wallet' ? account : proxyAddress);
      positions[`uniswapV3${nftId}`] = {
        id: nftId,
        tokenFirst: tokenFirstInfo.symbol,
        tokenSecond: tokenSecondInfo.symbol,
        tokenFirstInfo,
        tokenSecondInfo,
        tickLower,
        tickUpper,
        fee: new Dec(args[2]).mul(10000).toString(),
        value: `$${this.id}`,
        owner: args[9],
        tokensOwedFirst: '0',
        tokensOwedSecond: '0',
        tickLowerPriceFirstToken: tickToPrice(firstToken, secondToken, tickLower).toSignificant(),
        tickLowerPriceSecondToken: tickToPrice(secondToken, firstToken, tickLower).toSignificant(),
        tickUpperPriceFirstToken: tickToPrice(firstToken, secondToken, tickUpper).toSignificant(),
        tickUpperPriceSecondToken: tickToPrice(secondToken, firstToken, tickUpper).toSignificant(),
        amountInFirstToken: args[6],
        amountInSecondToken: args[7],
        liquidity: new Dec(position?.liquidity?.toString() || '0').plus(_pool?.liquidity?.toString() || '0').toString(),
        currentPrice: args[12],
        invert,
      };
      this.setError('');
      return { returnValue: new NftId('output', nftId), balances, positions };
    } catch (e) {
      console.error(e);
      this.setError('Invalid inputs');
      return { returnValue: '', balances, positions };
    }
  }

  async toDfsAction(getState) {
    const {
      general: { account },
      maker: { proxyAddress },
      recipeCreator: { returnValues, actions },
    } = getState();
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    // get token objects from uni sdk in sorted order
    const [firstTokenInfo, secondTokenInfo, firstToken, secondToken] = getTokens(getAssetInfoByAddress(args[0]), getAssetInfoByAddress(args[1]));
    const from = args[8] === 'wallet' ? account : proxyAddress;
    // calculate ticks based on sorted token order
    const [tickLower, tickUpper] = pricesToTicks(args[4], args[5], firstToken, secondToken, firstTokenInfo, secondTokenInfo, args[2], typeof args[3] === 'boolean' ? args[3] : (args[3] !== 'false'));
    // first amount is always the first sorted token (doesn't matter the order that user has entered)
    const firstAmountWei = assetAmountInWei(args[6], firstTokenInfo.symbol);
    const secondAmountWei = assetAmountInWei(args[7], secondTokenInfo.symbol);
    const minFirstAmount = setSlippagePercent(args[10], args[6]);
    const minSecondAmount = setSlippagePercent(args[10], args[7]);
    const minFirstAmountWei = assetAmountInWei(minFirstAmount, firstTokenInfo.symbol);
    const minSecondAmountWei = assetAmountInWei(minSecondAmount, secondTokenInfo.symbol);

    let hasPool;
    try {
      await getPool(firstToken, secondToken, args[2]);
      hasPool = true;
    } catch (e) {
      hasPool = false;
    }
    const sqrtRatio = hasPool ? '0' : getSqrtRatio(firstToken, secondToken, firstTokenInfo, secondTokenInfo, args[12], args[3]).toString();
    return hasPool ?
      new dfs.actions.uniswapV3.UniswapV3MintAction(
        firstTokenInfo.address,
        secondTokenInfo.address,
        FeeAmount[getFeeLabel(args[2])],
        tickLower,
        tickUpper,
        firstAmountWei,
        secondAmountWei,
        minFirstAmountWei,
        minSecondAmountWei,
        args[9],
        Date.now() + (1000 * 60 * args[11]),
        from)
      : new dfs.actions.uniswapV3.UniswapV3CreatePoolAction(
        firstTokenInfo.address,
        secondTokenInfo.address,
        FeeAmount[getFeeLabel(args[2])],
        tickLower,
        tickUpper,
        firstAmountWei,
        secondAmountWei,
        minFirstAmountWei,
        minSecondAmountWei,
        args[9],
        Date.now() + (1000 * 60 * args[11]),
        from,
        sqrtRatio,
      );
  }

  _getPrettyName(actionCalls, actions) {
    const args = this.mapReturnValuesToArgs(actionCalls.map(a => a.returnValue), actions);
    return `Create ${getAssetInfoByAddress(args[0]).symbol}-${getAssetInfoByAddress(args[1]).symbol} position`;
  }
}
