import PropTypes from 'prop-types';
import React from 'react';

import { NETWORKS } from '../../../constants/general';
import { WALLET_ICONS } from '../../../constants/wallet';

const NetworkIcon = ({ chainId }) => {
  const Icon = Object.values(NETWORKS).find(n => n.chainId === chainId).Icon || WALLET_ICONS['change-network'];
  return (<Icon />);
};

NetworkIcon.propTypes = { chainId: PropTypes.number.isRequired };

export default NetworkIcon;
