import * as liquityStrategiesActionTypes from '../../actionTypes/strategiesActionTypes/liquityStrategiesActionTypes';

const INITIAL_STATE = {
  liquitySubscribedStrategies: [],
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case liquityStrategiesActionTypes.GET_LIQUITY_SUBSCRIBED_STRATEGIES_SUCCESS:
      return { ...state, liquitySubscribedStrategies: payload };

    default:
      return state;
  }
};
