import { assetAmountInEth } from '@defisaver/tokens';
import Dec from 'decimal.js';
import { captureException } from '../../sentry';
import { getDebtInFront, getTroveInfoMulticall, getTrovePayload } from '../../services/liquityServices/liquityService';
import { LIQUITY_NORMAL_MODE_RATIO, LIQUITY_RECOVERY_MODE_RATIO } from '../../constants/liquity';
import * as actionTypes from '../../actionTypes/liquityActionTypes/liquityActionTypes';
import { getAssetPriceAction, getAssetsBalancesAction, getAssetsPricesAction } from '../assetsActions';
import { setAfterValue } from './liquityManageAfterValues';
import { isWalletTypeProxy } from '../../services/utils';
import { WALLET_TYPES_OPTIONS } from '../../constants/general';
import { changeWalletType } from '../generalActions';


export const getRedemptionRisk = () => async (dispatch, getState) => {
  dispatch({ type: actionTypes.LQTY_GET_REDEMPTION_RISK_REQUEST });
  try {
    const {
      general: { account, walletType },
      maker: { proxyAddress },
    } = getState();
    const isProxy = isWalletTypeProxy(walletType);
    const address = isProxy ? proxyAddress : account;
    const payload = address ? assetAmountInEth(await getDebtInFront(address), 'LUSD') : '';
    dispatch({ type: actionTypes.LQTY_GET_REDEMPTION_RISK_SUCCESS, payload });
  } catch (error) {
    dispatch({ type: actionTypes.LQTY_GET_REDEMPTION_RISK_FAILURE, payload: error.message });
    captureException(error);
  }
};

export const getTrove = (userAddress = '', userProxy = '') => async (dispatch, getState) => {
  dispatch({ type: actionTypes.LQTY_GET_TROVE_REQUEST });
  try {
    const {
      general: { account, recentAccounts },
      maker: { proxyAddress },
      assets: { ETH },
    } = getState();
    let fetchingAddress = account;
    let fetchingProxy = proxyAddress;

    if (userAddress) {
      fetchingAddress = userAddress;
      fetchingProxy = userProxy;
    }

    const [accountInfo, proxyInfo] = await getTroveInfoMulticall([fetchingAddress, fetchingProxy]);
    const minCollateralRatio = accountInfo.recoveryMode ? LIQUITY_RECOVERY_MODE_RATIO : LIQUITY_NORMAL_MODE_RATIO;

    const payload = {
      minCollateralRatio,
      ethPriceFeed: ETH.marketPrice,
      recoveryMode: accountInfo.recoveryMode,
      totalCollateralizationRatio: accountInfo.TCRatio,
      borrowingRateWithDecay: accountInfo.borrowingRateWithDecay,
      account: { ...getTrovePayload(accountInfo, minCollateralRatio) },
      proxy: { ...(proxyInfo && getTrovePayload(proxyInfo, minCollateralRatio)) },
    };

    // throw new Error('test');

    dispatch({ type: actionTypes.LQTY_GET_TROVE_SUCCESS, payload });
    return payload;
  } catch (error) {
    dispatch({ type: actionTypes.LQTY_GET_TROVE_FAILURE, payload: error.message });
    captureException(error);
  }
};

const switchToWalletWithTrove = () => (dispatch, getState) => {
  const {
    maker: { proxyAddress },
    liquity: { account: accTrove, proxy: proxyTrove },
  } = getState();

  // trove has loaded, isn't active and doesn't have claimable collateral
  const isAccTroveEmpty = !!accTrove.troveStatus && accTrove.troveStatus !== 'active' && new Dec(accTrove.claimableCollateral || 0).lte(0);
  const isProxyTroveEmpty = !proxyAddress || (!!proxyTrove.troveStatus && proxyTrove.troveStatus !== 'active' && new Dec(proxyTrove.claimableCollateral || 0).lte(0));

  if (isAccTroveEmpty) {
    dispatch(changeWalletType(WALLET_TYPES_OPTIONS[1]));
  } else if (isProxyTroveEmpty) {
    dispatch(changeWalletType(WALLET_TYPES_OPTIONS[0]));
  }
};

export const dispatchLiquityInitActions = (switchWalletType = false) => async (dispatch) => {
  dispatch({ type: actionTypes.LQTY_GET_INIT_DATA_REQUEST });
  dispatch(getAssetsPricesAction(['LUSD', 'ETH', 'LQTY']));
  dispatch(getTrove())
    .then(async () => {
      if (switchWalletType) await dispatch(switchToWalletWithTrove());
      dispatch(getRedemptionRisk());
    });
  dispatch(getAssetsBalancesAction(['LUSD', 'LQTY']));
  dispatch({ type: actionTypes.LQTY_GET_INIT_DATA_SUCCESS });
};

export const onWalletTypeChange = () => (dispatch) => {
  dispatch(setAfterValue('0', 'clear'));
};
