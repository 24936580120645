import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Dec from 'decimal.js';
import t from 'translate';

import {
  resetBoostModal,
  boostAction,
  getBoostModalDataForAsset,
} from '../../../../actions/makerActions/makerManageActions/makerManageActions';

import { MakerSlippageLimitForm as SlippageLimitForm } from '../../BoostRepayCommon/SlippageLimitForm/SlippageLimitForm';
import { MakerDeadlineLimitForm as DeadlineLimitForm } from '../../BoostRepayCommon/DeadlineLimitForm/DeadlineLimitForm';
import TradeSizeImpactWarning from '../../../TradeSizeImpactWarning/TradeSizeImpactWarning';
import TooltipWrapper from '../../../TooltipWrapper/TooltipWrapper';
import ModalHeader from '../../ModalHeader';
import ModalBody from '../../ModalBody';
import Loader from '../../../Loader/Loader';
import ErrorBox from '../../../Decorative/ErrorBox/ErrorBox';
import InfoIcon from '../../../Decorative/InfoIcon';
import ActionInfo from '../../BoostRepayCommon/ActionInfo';
import AdvancedOptions from '../../BoostRepayCommon/AdvancedOptions/AdvancedOptions';
import AdditionalInfo from '../../../AdditionalInfo/AdditionalInfo';
import { getDashboardInputs } from '../../../../actions/dashboardActions';
import { isCdpLP } from '../../../../services/utils';
import { ASSETS_FROM_MAKER_ASSET } from '../../../../constants/assets';

const MakerBoostModal = ({
  closeModal, gettingBoostModalData, gettingBoostModalDataError, boostAmount, boostExchangeRate, boosting,
  boostAction, tradeSizeImpact, cdpAssetSymbol, cdpDaiSymbol, boostingError, resetBoostModal,
  useFl, flProtocol, exchangeSource, flFee, additionalAction, contextAction, getDashboardInputs,
  getBoostModalDataForAsset, lpTokensAmount, lpFirstAmount, lpSecondAmount, tradeSizeImpactSecond,
  exchangeRateSecond, sourceSecond, lpSellAmount, boostAmountSecond, lpSellAmountSecond, lpOneAsset,
}) => {
  const {
    firstInput: inputAmount, secondAction, secondInput,
  } = useMemo(() => getDashboardInputs(contextAction, additionalAction), []);

  const [advanced, setAdvanced] = useState(false);
  const [advancedLP, setAdvancedLP] = useState(false);
  const [LPCdp, setLPCdp] = useState(false);

  useEffect(() => {
    const isCDPLP = isCdpLP(cdpAssetSymbol);
    setLPCdp(isCDPLP);
    // eslint-disable-next-line no-unused-expressions
    getBoostModalDataForAsset(inputAmount, cdpAssetSymbol);
    return resetBoostModal;
  }, []);

  const assets = useMemo(() => ASSETS_FROM_MAKER_ASSET(cdpAssetSymbol), [cdpAssetSymbol]);

  const shouldFlip = new Dec(boostExchangeRate).lt(new Dec(1).div(boostExchangeRate).toString());
  const assetSymbol = LPCdp
    ? (assets.isFirstDAI
      ? assets.secondAsset
      : assets.firstAsset)
    : cdpAssetSymbol;

  const exchangeLabel = shouldFlip
    ? `${cdpDaiSymbol}/${assetSymbol}`
    : `${assetSymbol}/${cdpDaiSymbol}`;
  const correctedBoostExchangeRate = shouldFlip
    ? new Dec(1).div(boostExchangeRate).toString()
    : boostExchangeRate;

  const shouldFlipSecond = useMemo(() => {
    if (!exchangeRateSecond) return false;
    return new Dec(exchangeRateSecond).lt(new Dec(1).div(exchangeRateSecond).toString());
  }, [exchangeRateSecond]);

  const exchangeLabelSecond = (shouldFlipSecond && LPCdp)
    ? `${cdpDaiSymbol}/${assets?.secondAsset}`
    : `${assets?.secondAsset}/${cdpDaiSymbol}`;

  const correctedBoostExchangeRateSecond = useMemo(() => {
    if (!exchangeRateSecond) return '';
    return (shouldFlipSecond ? new Dec(1).div(exchangeRateSecond).toString() : exchangeRateSecond);
  }, [exchangeRateSecond]);

  const lpAction = LPCdp
    ? {
      lpAsset: cdpAssetSymbol,
      borrowAsset: cdpDaiSymbol,
      firstAsset: assets.firstAsset,
      firstAmount: lpFirstAmount,
      secondAsset: assets.secondAsset,
      secondAmount: lpSecondAmount,
      isFirstDAI: assets.isFirstDAI,
      lpTokensAmount,
      lpFullDebtAmount: inputAmount,
      boostAmount,
      lpSellAmount,
      lpSellAmountSecond,
      lpProtocol: assets.protocolShortName,
      lpSellAsset: assets.hasDAI
        ? (
          assets.isFirstDAI ? assets.secondAsset : assets.firstAsset
        )
        : assets.firstAsset,
      lpSellAssetSecond: assets.secondAsset,
      boostAmountSecond,
      exchangeRateSecond,
      lpOneAsset,
    }
    : {};

  const secondAmount = LPCdp ? lpTokensAmount : boostAmount;

  const sourceLabel = (sourceSecond && exchangeSource !== sourceSecond) ? `${exchangeSource},${sourceSecond}` : exchangeSource;

  return (
    <div className="boost-repay-modal">
      <ModalHeader closeModal={closeModal} />

      <ModalBody>
        <div className="new-modal-top-wrapper">
          <h1>
            <TooltipWrapper title={t('maker.boost_modal_description', { '%daiLabel': cdpDaiSymbol, '%asset': cdpAssetSymbol })}>
              <InfoIcon /> {t('common.boost')}
            </TooltipWrapper>
          </h1>
        </div>

        <div className="new-modal-content-wrapper">

          {gettingBoostModalData && (<div className="container"><div className="loading-wrapper"><Loader /></div></div>)}

          {!gettingBoostModalData && gettingBoostModalDataError && (<ErrorBox>{gettingBoostModalDataError}</ErrorBox>)}

          {!gettingBoostModalData && !gettingBoostModalDataError && (
            <>
              <ActionInfo
                firstAsset={cdpDaiSymbol}
                firstAmount={inputAmount}
                secondAsset={cdpAssetSymbol}
                secondAmount={secondAmount}
                exchangeRate={correctedBoostExchangeRate}
                exchangeLabel={exchangeLabel}
                additionalAction={secondAction}
                additionalInput={secondInput}
                isLP={LPCdp}
                lpAction={lpAction}
                advancedLP={advancedLP}
                setAdvancedLP={setAdvancedLP}
                exchangeRateSecond={correctedBoostExchangeRateSecond}
                exchangeLabelSecond={exchangeLabelSecond}
                additionalAsset={secondAction?.value === 'collateral' ? cdpAssetSymbol : cdpDaiSymbol}
              />

              <div className="advanced__wrapper container">
                <TradeSizeImpactWarning tradeSizeImpact={tradeSizeImpact} afterLabel={LPCdp ? exchangeLabel : ''} />
                {tradeSizeImpactSecond && <TradeSizeImpactWarning tradeSizeImpact={tradeSizeImpactSecond} afterLabel={LPCdp ? exchangeLabelSecond : ''} />}
                <AdvancedOptions
                  borrowAsset={cdpDaiSymbol}
                  supplyAsset={cdpAssetSymbol}
                  advanced={advanced}
                  setAdvanced={setAdvanced}
                  exchangeRate={boostExchangeRate}
                  SlippageLimitForm={SlippageLimitForm}
                  isLP={LPCdp}
                  hasDeadline={assets.protocolShortName === 'Uni V2'}
                  DeadlineLimitForm={DeadlineLimitForm}
                />
                <AdditionalInfo
                  flashloanSource={useFl ? flProtocol : ''}
                  flashloanFee={flFee}
                  exchangeSource={sourceLabel}
                  additionalAsset={secondAction?.value === 'collateral' ? cdpAssetSymbol : cdpDaiSymbol}
                  asset1={cdpDaiSymbol}
                  asset2={cdpAssetSymbol}
                />
                {boostingError && (<ErrorBox marginTop>{boostingError}</ErrorBox>)}
              </div>
            </>
          )}
        </div>
      </ModalBody>

      <div className="modal-controls">
        <button
          form="maker-boost-modal-form"
          type="button"
          disabled={boosting || gettingBoostModalData}
          onClick={() => boostAction(contextAction, additionalAction, closeModal)}
          className="button green"
        >
          {boosting ? t('common.boosting') : t('common.boost')}
        </button>
      </div>
    </div>
  );
};

MakerBoostModal.defaultProps = {
  boosting: false,
  boostingError: '',
  lpTokensAmount: '',
  lpFirstAmount: '',
  lpSecondAmount: '',
  tradeSizeImpactSecond: '',
  exchangeRateSecond: '',
  sourceSecond: '',
  lpSellAmount: '',
  lpSellAmountSecond: '',
  boostAmountSecond: '',
  lpOneAsset: false,
};

MakerBoostModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  tradeSizeImpact: PropTypes.string.isRequired,

  getBoostModalDataForAsset: PropTypes.func.isRequired,
  gettingBoostModalData: PropTypes.bool.isRequired,
  gettingBoostModalDataError: PropTypes.string.isRequired,

  boostAmount: PropTypes.string.isRequired,
  boostExchangeRate: PropTypes.string.isRequired,

  boostAction: PropTypes.func.isRequired,
  resetBoostModal: PropTypes.func.isRequired,
  boosting: PropTypes.bool,
  boostingError: PropTypes.string,
  useFl: PropTypes.bool.isRequired,
  flProtocol: PropTypes.string.isRequired,
  flFee: PropTypes.string.isRequired,
  exchangeSource: PropTypes.string.isRequired,

  cdpDaiSymbol: PropTypes.string.isRequired,
  cdpAssetSymbol: PropTypes.string.isRequired,


  getDashboardInputs: PropTypes.func.isRequired,
  additionalAction: PropTypes.object,
  contextAction: PropTypes.object,

  lpTokensAmount: PropTypes.string,
  lpFirstAmount: PropTypes.string,
  lpSecondAmount: PropTypes.string,
  tradeSizeImpactSecond: PropTypes.string,
  exchangeRateSecond: PropTypes.string,
  sourceSecond: PropTypes.string,
  lpSellAmount: PropTypes.string,
  lpSellAmountSecond: PropTypes.string,
  boostAmountSecond: PropTypes.string,
  lpOneAsset: PropTypes.bool,
};

const mapStateToProps = ({ makerManage, maker }) => ({
  tradeSizeImpact: makerManage.tradeSizeImpact,
  gettingBoostModalData: makerManage.gettingBoostModalData,
  gettingBoostModalDataError: makerManage.gettingBoostModalDataError,
  boostAmount: makerManage.boostAmount,
  boostExchangeRate: makerManage.boostExchangeRate,
  boosting: makerManage.executing.boost || (makerManage.executing[makerManage.selectedAction?.value] && makerManage.selectedAdditionalActions[makerManage.selectedAction?.value]?.value === 'boost'),
  boostingError: makerManage.executingErrors.boost,
  useFl: makerManage.useFl,
  flProtocol: makerManage.flProtocol,
  flFee: makerManage.flFee,
  exchangeSource: makerManage.exchangeSource,
  cdpDaiSymbol: maker.cdp.daiLabel,
  cdpAssetSymbol: maker.cdp.asset,
  lpTokensAmount: makerManage.lpTokensAmount,
  lpFirstAmount: makerManage.lpFirstAmount,
  lpSecondAmount: makerManage.lpSecondAmount,
  tradeSizeImpactSecond: makerManage.tradeSizeImpactSecond,
  exchangeRateSecond: makerManage.exchangeRateSecond,
  sourceSecond: makerManage.sourceSecond,
  lpSellAmount: makerManage.lpSellAmount,
  lpSellAmountSecond: makerManage.lpSellAmountSecond,
  boostAmountSecond: makerManage.boostAmountSecond,
  lpOneAsset: makerManage.lpOneAsset,
});

const mapDispatchToProps = {
  resetBoostModal, boostAction, getDashboardInputs, getBoostModalDataForAsset,
};

export default connect(mapStateToProps, mapDispatchToProps)(MakerBoostModal);
