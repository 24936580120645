import { getCdpHistoryApiCall, getCdpHistoryGraphDataApiCall } from '../../services/apiService';

export const GET_CDP_HISTORY_REQUEST = 'GET_CDP_HISTORY_REQUEST';
export const GET_CDP_HISTORY_SUCCESS = 'GET_CDP_HISTORY_SUCCESS';
export const GET_CDP_HISTORY_GRAPH_DATA_REQUEST = 'GET_CDP_HISTORY_GRAPH_DATA_REQUEST';
export const GET_CDP_HISTORY_GRAPH_DATA_SUCCESS = 'GET_CDP_HISTORY_GRAPH_DATA_SUCCESS';

export const getCdpHistory = cdpId => async (dispatch) => {
  dispatch({ type: GET_CDP_HISTORY_REQUEST });
  const txs = await getCdpHistoryApiCall(cdpId);
  dispatch({
    type: GET_CDP_HISTORY_SUCCESS,
    payload: txs,
  });
};

export const getCdpHistoryGraphData = ilk => async (dispatch, getState) => {
  if (getState().maker.historyGraphData[ilk]) return;
  dispatch({ type: GET_CDP_HISTORY_GRAPH_DATA_REQUEST });
  const data = await getCdpHistoryGraphDataApiCall(ilk);
  dispatch({
    type: GET_CDP_HISTORY_GRAPH_DATA_SUCCESS,
    payload: { ilk, data },
  });
};
