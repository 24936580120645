import Dec from 'decimal.js';
import {
  parseAaveV2FlashLoanTransfersFromReceipt,
  parseAaveV3FlashLoanTransfersFromReceipt,
  parseFeeEventsFromReceipt,
  parseSellEventsFromReceipt,
} from '../eventUtils';
import { getEthAmountForDecimals } from '../utils';
import { getERC20TokenData } from '../erc20Service';

export const parseSwapsFromReceipts = async (receipts) => {
  const swapsMade = [];
  await Promise.all(receipts.map(async (receipt) => {
    if (receipt !== undefined) {
      await Promise.all(parseSellEventsFromReceipt(receipt).map(async (sellEvent) => {
        if (+sellEvent.buyAmount > 0 || +sellEvent.sellAmount > 0) {
          const buyToken = await getERC20TokenData(sellEvent.buyToken);
          const sellToken = await getERC20TokenData(sellEvent.sellToken);
          const buyAmount = getEthAmountForDecimals(sellEvent.buyAmount, buyToken.decimals);
          const sellAmount = getEthAmountForDecimals(sellEvent.sellAmount, sellToken.decimals);
          const swapRate = new Dec(sellAmount).div(buyAmount).toString();
          swapsMade.push({
            buyToken, sellToken, buyAmount, sellAmount, swapRate, wrapper: sellEvent.wrapper,
          });
        }
      }));
    }
  }));
  return swapsMade;
};
export const parseFeesFromReceipts = async (receipts) => {
  const feesMade = {};
  await Promise.all(receipts.map(async (receipt) => {
    if (receipt !== undefined) {
      const parsedFeeEvents = await parseFeeEventsFromReceipt(receipt);
      parsedFeeEvents.forEach(({ feeAsset, feeAmount }) => {
        if (feeAsset !== '') {
          if (feesMade[feeAsset] === undefined) {
            feesMade[feeAsset] = 0;
          }
          feesMade[feeAsset] += +feeAmount;
        }
      });
    }
  }));
  const finalFees = [];
  Object.keys(feesMade).forEach((feeAsset) => {
    const feeAmount = feesMade[feeAsset];
    if (feeAmount > 0) {
      finalFees.push({ feeAsset, feeAmount, type: 'service' });
    }
  });
  return finalFees;
};
export const parseAaveFLFeesFromReceipts = (receipts, isLayer2) => {
  const aaveFLFees = [];
  receipts.forEach((receipt) => {
    if (receipt !== undefined) {
      const aaveFlashLoans = {};
      const parseFunc = !isLayer2 ? parseAaveV2FlashLoanTransfersFromReceipt : parseAaveV3FlashLoanTransfersFromReceipt;
      parseFunc(receipt).forEach(({ asset, value }) => {
        if (asset === '') return;
        if (aaveFlashLoans[asset] === undefined) {
          aaveFlashLoans[asset] = +value;
        } else {
          aaveFlashLoans[asset] = Math.abs(aaveFlashLoans[asset] - (+value));
        }
      });
      Object.keys(aaveFlashLoans).forEach((key) => {
        aaveFLFees.push({ feeAsset: key, feeAmount: aaveFlashLoans[key], type: 'aaveFL' });
      });
    }
  });
  return aaveFLFees;
};
export const getPositionsChangedFromActionCalls = (actionCalls) => {
  const finalPositions = [];
  if (actionCalls.length) {
    const positions = actionCalls[actionCalls.length - 1].positions;
    const positionsHandled = [
      { from: 0, len: 5, str: 'maker' },
      { from: 0, len: 8, str: 'reflexer' },
      { from: 0, len: 8, str: 'compound' },
      { from: 0, len: 7, str: 'aaveV2_' },
      { from: 0, len: 6, str: 'aaveV1' },
      { from: 0, len: 7, str: 'liquity' },
      { from: 0, len: 9, str: 'uniswapV3' },
    ];
    Object.keys(positions).forEach((key) => {
      if (positions[key].ignore) return;
      if (positionsHandled.some(({ from, len, str }) => key.substr(from, len) === str)) {
        finalPositions.push({ ...positions[key], keyVal: key });
      }
    });
  }
  return finalPositions;
};

/**
 *
 * @param receipts
 * @param isLayer2
 * @returns {Promise<*[]>}
 */
export const parseAllFeesFromReceipts = async (receipts, isLayer2) => {
  const feesMade = await parseFeesFromReceipts(receipts);
  const aaveFLFees = parseAaveFLFeesFromReceipts(receipts, isLayer2);
  return [...feesMade, ...aaveFLFees];
};
export const getAllAssetsFromFees = (fees) => {
  const finAssets = new Set();
  fees.forEach(({ feeAsset }) => finAssets.add(feeAsset));
  finAssets.delete('');
  return Array.from(finAssets);
};
