import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { connect } from 'react-redux';
import {
  SelectOption,
  SelectValueContainer,
} from 'components/Forms/SelectComponent/SelectComponent';
import withErrorFallback from '../../../../../../../ErrorFallback/ErrorFallback';

const MarketActionField = ({
  i, actualValue, handleChange, isPointer,
}) => {
  const sourceOptions = [
    {
      value: 'v2default',
      label: 'Default market',
    },
  ];

  return (
    <Select
      className="select box"
      classNamePrefix="select"
      value={sourceOptions.find(i => i.value === actualValue) || null}
      components={{
        Option: SelectOption,
        ValueContainer: SelectValueContainer,
      }}
      onChange={(e) => handleChange({ target: e }, i)}
      options={sourceOptions}
      onBlur={event => event.preventDefault()}
    />
  );
};

MarketActionField.defaultProps = {
  actualValue: '',
  isPointer: false,
};

MarketActionField.propTypes = {
  handleChange: PropTypes.func.isRequired,
  i: PropTypes.number.isRequired,
  isPointer: PropTypes.bool,
  actualValue: PropTypes.string,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default withErrorFallback(connect(mapStateToProps, mapDispatchToProps)(MarketActionField));
