import { getAssetInfo } from '@defisaver/tokens';
import PropTypes from 'prop-types';
import React from 'react';
import Dec from 'decimal.js';
import { formatNumber, numberWithCommas } from '../../../services/utils';
import TooltipWrapper from '../../TooltipWrapper/TooltipWrapper';

const swapTooltip = (rate, label) => `${numberWithCommas(rate, 4)} ${label} = ${numberWithCommas(new Dec(1).div(rate).toString(), 4)} ${label.split('/').reverse().join('/')}`;

const ActionInfoItem = ({
  amount, asset, actionName, exchangeRate, exchangeLabel, actionNameAdditional, estimate,
}) => (
  <div className="action-item">
    <div className="action-item__name">
      {actionName}
      {actionNameAdditional ? (<><br />{actionNameAdditional}</>) : ''}
      {exchangeRate && (
        <span className="action-item__rate">
          <TooltipWrapper
            useText
            title={swapTooltip(exchangeRate, exchangeLabel)}
          >
            {numberWithCommas(exchangeRate, 2)} {exchangeLabel}
          </TooltipWrapper>
        </span>
      )}
    </div>
    <TooltipWrapper className="action-item__asset" copy title={amount}>
      {getAssetInfo(asset).icon()}
      <p className="action-item__asset__amount">{formatNumber(parseFloat(amount), 2)}
        <span className="action-item__asset__symbol">{`${asset}${estimate ? ' (Estimated)' : ''}`}</span>
      </p>
    </TooltipWrapper>
  </div>
);

ActionInfoItem.defaultProps = {
  exchangeRate: '',
  exchangeLabel: '',
  actionNameAdditional: '',
  estimate: false,
};

ActionInfoItem.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  asset: PropTypes.string.isRequired,
  actionName: PropTypes.string.isRequired,
  exchangeRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  exchangeLabel: PropTypes.string,
  actionNameAdditional: PropTypes.string,
  estimate: PropTypes.bool,
};
export default ActionInfoItem;
