import dfs from '@defisaver/sdk';
import { getAssetInfo } from '@defisaver/tokens';
import { Amount, AssetAmount } from '../../components/Recipes/RecipeCreator/inputTypes';
import RecipeAction from '../RecipeAction';
import { makerFlashPaybackAfterValues } from '../../actions/flashLoanAfterValues';
import FlashIcon from '../recipeIcons/Flash.svg';
import { formatNumber } from '../../services/utils';
import { assetAmountInWeiIgnorePointer } from '../../services/recipeCreator/recipeActionUtils';

export default class MakerFlashLoanPaybackAction extends RecipeAction {
  static prettyName = 'Maker Flash Loan Payback';

  static protocol = 'maker';

  static protocolPrettyName = 'Maker';

  static description = 'Pays back flash minted DAI to Maker.';

  constructor(amount = '') {
    super();
    this.inputs = [
      new AssetAmount('Amount', amount, 'DAI'),
    ];
    this.output = new AssetAmount('output', 0, 'DAI');
  }

  async getAfterValues(_balances = {}, returnValues = [], actions = [], positions = {}, getState) {
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const { returnValue, balances } = await makerFlashPaybackAfterValues(
      { amount: args[0], asset: 'DAI' },
      { account: getState().general.account },
      _balances,
    );
    this.output.value = returnValue;
    this.output.asset = 'DAI';
    return { returnValue: this.output, balances, positions };
  }

  async toDfsAction(getState) {
    const amount = assetAmountInWeiIgnorePointer(this.inputs[0].value, 'DAI');
    return new dfs.actions.flashloan.MakerFlashLoanPaybackAction(amount, getAssetInfo('DAI').address);
  }

  static getIcon() {
    return FlashIcon;
  }

  _getPrettyName(actionCalls, actions) {
    const args = this.mapReturnValuesToArgs(actionCalls.map(a => a.returnValue), actions);
    return `Pay flash borrowed ${formatNumber(args[0])} DAI back to Maker`;
  }

  getAsset() {
    return 'DAI';
  }
}
