import React from 'react';

const RariProtocolIcon = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="13.9997" cy="13.9991" r="12.9814" transform="rotate(-90 13.9997 13.9991)" stroke="url(#paint0_linear_17997_55969)" />
    <circle cx="13.9999" cy="13.999" r="9.48479" transform="rotate(127.501 13.9999 13.999)" stroke="url(#paint1_linear_17997_55969)" />
    <circle cx="13.9997" cy="13.9995" r="6.2407" transform="rotate(-90 13.9997 13.9995)" stroke="url(#paint2_linear_17997_55969)" />
    <defs>
      <linearGradient id="paint0_linear_17997_55969" x1="13.9997" y1="0.517656" x2="13.9997" y2="27.4805" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient id="paint1_linear_17997_55969" x1="13.9999" y1="4.01423" x2="13.9999" y2="23.9838" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient id="paint2_linear_17997_55969" x1="13.9997" y1="7.25883" x2="13.9997" y2="20.7402" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>

);

export default RariProtocolIcon;
