import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import t from 'translate';
import { getAssetsPricesAction } from '../../../actions/assetsActions';
import { useLoad } from '../../../services/hooks';
import {
  getAllAssetsFromFees, getPositionsChangedFromActionCalls, parseAllFeesFromReceipts, parseSwapsFromReceipts,
} from '../../../services/recipeCreator/recipeSuccessService';
import { formatEtherScanLink } from '../../../services/utils';
import { isLayer2Network } from '../../../services/ethService';

import ModalHeader from '../ModalHeader';
import ModalBody from '../ModalBody';
import RecipeActionBox from './Components/RecipeActionBox';
import PositionsChanged from './Components/PositionsChanged';
import SwapsMade from './Components/SwapsMade';
import FeesMade from './Components/FeesMade';
import ExternalArrowLinkIcon from '../../Decorative/ExternalArrowLinkIcon';
import Loader from '../../Loader/Loader';

import './RecipeSuccessModal.scss';

const RecipeSuccessModal = ({
  closeModal, data, getAssetsPricesAction, network,
}) => {
  const [swapsMade, setSwapsMade] = useState([]);
  const [fees, setFees] = useState([]);
  const finalPositions = getPositionsChangedFromActionCalls(data.actionCalls);
  let txHash;
  if (data.receipts.length) {
    txHash = data.receipts[data.receipts.length - 1].transactionHash;
  }
  const getAllFeeAssets = async () => {
    const finAssets = getAllAssetsFromFees(fees);
    return getAssetsPricesAction(finAssets);
  };
  const { loading } = useLoad(getAllFeeAssets, [fees]);

  useEffect(() => {
    parseSwapsFromReceipts(data.receipts).then((s) => setSwapsMade(s));
    parseAllFeesFromReceipts(data.receipts, isLayer2Network(network)).then((s) => setFees(s));
  }, []);
  return (
    <div className="action-modal-wrapper recipe-success-modal-wrapper">
      <ModalHeader closeModal={closeModal} />

      <ModalBody>
        { loading && <Loader margin={50} message={t('common.loading_positions')} /> }
        {!loading && (
        <>
          <div className="new-modal-top-wrapper">
            <h1>Recipe completed successfully</h1>
          </div>

          <div className="new-modal-content-wrapper">
            <div className="recipe-success-modal-actions-wrapper">
              {data.actions.map((item) => (
                <RecipeActionBox key={item.id} action={item} actionCalls={data.actionCalls} actions={data.actions} />
              ))}
            </div>
            <div className="recipe-success-modal-info-wrapper">
              {finalPositions && finalPositions.length !== 0 && (
                <div className="recipe-success-modal-positions-changed-wrapper">
                  <PositionsChanged positions={finalPositions} onPositionClick={closeModal} />
                </div>
              )}
              {swapsMade && swapsMade.length !== 0 && (
                <div className="recipe-success-modal-swaps-made-wrapper">
                  <SwapsMade swaps={swapsMade} />
                </div>
              )}
              {fees && fees.length !== 0 && (
                <div className="recipe-success-modal-fees-made-wrapper">
                  <FeesMade fees={fees} />
                </div>
              )}
            </div>
          </div>
        </>
        )}
      </ModalBody>
      <div className="modal-controls">
        <a type="button" className="button gray" target="_blank" rel="noopener noreferrer" href={formatEtherScanLink(txHash)}>
          <div className="modal-controls-etherscan-wrapper">
            VIEW ON ETHERSCAN
            <ExternalArrowLinkIcon />
          </div>
        </a>
        <button
          type="button"
          className="button green"
          onClick={closeModal}
        >
          CONTINUE
        </button>
      </div>
    </div>
  );
};
RecipeSuccessModal.defaultProps = {};

RecipeSuccessModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  getAssetsPricesAction: PropTypes.func.isRequired,
  network: PropTypes.number.isRequired,
  data: PropTypes.shape({
    receipts: PropTypes.array.isRequired,
    actions: PropTypes.array.isRequired,
    actionCalls: PropTypes.array.isRequired,
  }).isRequired,
};

const mapStateToProps = ({ general }) => ({
  network: general.network,
});

const mapDispatchToProps = {
  getAssetsPricesAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(RecipeSuccessModal);
