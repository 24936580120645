import SellAction from './basic/SellAction';
import SendTokenAction from './basic/SendTokenAction';
import PullTokenAction from './basic/PullTokenAction';
import WrapEthAction from './basic/WrapEthAction';
import UnwrapEthAction from './basic/UnwrapEthAction';

import MakerOpenVaultAction from './maker/MakerOpenVaultAction';
import MakerGenerateAction from './maker/MakerGenerateAction';
import MakerPaybackAction from './maker/MakerPaybackAction';
import MakerSupplyAction from './maker/MakerSupplyAction';
import MakerWithdrawAction from './maker/MakerWithdrawAction';
import MakerMergeAction from './maker/MakerMergeAction';
import MakerGiveAction from './maker/MakerGiveAction';

import ReflexerOpenSafeAction from './reflexer/ReflexerOpenSafeAction';
import ReflexerGenerateAction from './reflexer/ReflexerGenerateAction';
import ReflexerPaybackAction from './reflexer/ReflexerPaybackAction';
import ReflexerSupplyAction from './reflexer/ReflexerSupplyAction';
import ReflexerWithdrawAction from './reflexer/ReflexerWithdrawAction';

import CompoundBorrowAction from './compound/CompoundBorrowAction';
import CompoundPaybackAction from './compound/CompoundPaybackAction';
import CompoundSupplyAction from './compound/CompoundSupplyAction';
import CompoundWithdrawAction from './compound/CompoundWithdrawAction';
import CompoundClaimAction from './compound/CompoundClaimAction';

import AaveV2BorrowAction from './aaveV2/AaveV2BorrowAction';
import AaveV2PaybackAction from './aaveV2/AaveV2PaybackAction';
import AaveV2SupplyAction from './aaveV2/AaveV2SupplyAction';
import AaveV2WithdrawAction from './aaveV2/AaveV2WithdrawAction';

import AaveV2FlashLoanAction from './flashloan/AaveV2FlashLoanAction';
import AaveV2FlashLoanPaybackAction from './flashloan/AaveV2FlashLoanPaybackAction';
import DyDxFlashLoanAction from './flashloan/DyDxFlashLoanAction';
import DyDxFlashLoanPaybackAction from './flashloan/DyDxFlashLoanPaybackAction';
import MakerFlashLoanAction from './flashloan/MakerFlashLoanAction';

import UniswapV2SupplyAction from './uniswapV2/UniswapV2SupplyAction';
import UniswapV2WithdrawAction from './uniswapV2/UniswapV2WithdrawAction';

import UniswapV3MintAction from './uniswapV3/UniswapV3MintAction';
import UniswapV3SupplyAction from './uniswapV3/UniswapV3SupplyAction';
import UniswapV3WithdrawAction from './uniswapV3/UniswapV3WithdrawAction';
import UniswapV3CollectAction from './uniswapV3/UniswapV3CollectAction';

import LiquitySupplyAction from './liquity/LiquitySupplyAction';
import LiquityPaybackAction from './liquity/LiquityPaybackAction';
import LiquityBorrowAction from './liquity/LiquityBorrowAction';
import LiquityWithdrawAction from './liquity/LiquityWithdrawAction';
import LiquityCloseAction from './liquity/LiquityCloseAction';
import LiquityOpenAction from './liquity/LiquityOpenAction';
import LiquityClaimAction from './liquity/LiquityClaimAction';
import LiquityStakeAction from './liquity/LiquityStakeAction';
import LiquityUnstakeAction from './liquity/LiquityUnstakeAction';
import LiquitySPDepositAction from './liquity/LiquitySPDepositAction';
import LiquitySPWithdrawAction from './liquity/LiquitySPWithdrawAction';
import LiquityRedeemAction from './liquity/LiquityRedeemAction';
import LiquityEthGainToTroveAction from './liquity/LiquityEthGainToTroveAction';
import LiquityClaimStakingRewardsAction from './liquity/LiquityClaimStakingRewardsAction';
import LiquityClaimSPRewardsAction from './liquity/LiquityClaimSPRewardsAction';

import LidoStakeAction from './lido/LidoStakeAction';
import LidoWrapAction from './lido/LidoWrapAction';
import LidoUnwrapAction from './lido/LidoUnwrapAction';

import YearnSupplyAction from './yearn/YearnSupplyAction';
import YearnWithdrawAction from './yearn/YearnWithdrawAction';
import MakerFlashLoanPaybackAction from './flashloan/MakerFlashLoanPaybackAction';
import BalancerFlashLoanAction from './flashloan/BalancerFlashLoanAction';
import BalancerFlashLoanPaybackAction from './flashloan/BalancerFlashLoanPaybackAction';
import MStableSaveSupplyAction from './mstable/MStableSaveSupplyAction';
import MStableVaultSupplyAction from './mstable/MStableVaultSupplyAction';
import MStableSaveWithdrawAction from './mstable/MStableSaveWithdrawAction';
import MStableVaultWithdrawAction from './mstable/MStableVaultWithdrawAction';

const allActions = {
  utility: [
    WrapEthAction,
    UnwrapEthAction,
    SellAction,
    SendTokenAction,
    PullTokenAction,
  ],
  maker: [
    MakerOpenVaultAction,
    MakerSupplyAction,
    MakerWithdrawAction,
    MakerGenerateAction,
    MakerPaybackAction,
    MakerMergeAction,
    MakerGiveAction,
  ],
  reflexer: [
    ReflexerOpenSafeAction,
    ReflexerSupplyAction,
    ReflexerWithdrawAction,
    ReflexerGenerateAction,
    ReflexerPaybackAction,
  ],
  compound: [
    CompoundSupplyAction,
    CompoundWithdrawAction,
    CompoundBorrowAction,
    CompoundPaybackAction,
    CompoundClaimAction,
  ],
  'aave V2': [
    AaveV2SupplyAction,
    AaveV2WithdrawAction,
    AaveV2BorrowAction,
    AaveV2PaybackAction,
  ],
  flashloan: [
    BalancerFlashLoanAction,
    BalancerFlashLoanPaybackAction,
    AaveV2FlashLoanAction,
    AaveV2FlashLoanPaybackAction,
    MakerFlashLoanAction,
    MakerFlashLoanPaybackAction,
    DyDxFlashLoanAction,
    DyDxFlashLoanPaybackAction,
  ],
  'uniswap V2': [
    UniswapV2SupplyAction,
    UniswapV2WithdrawAction,
  ],
  'uniswap V3': [
    UniswapV3MintAction,
    UniswapV3SupplyAction,
    UniswapV3WithdrawAction,
    UniswapV3CollectAction,
  ],
  liquity: [
    LiquityOpenAction,
    LiquitySupplyAction,
    LiquityWithdrawAction,
    LiquityBorrowAction,
    LiquityPaybackAction,
    LiquityCloseAction,
    LiquitySPDepositAction,
    LiquitySPWithdrawAction,
    LiquityStakeAction,
    LiquityUnstakeAction,
    LiquityRedeemAction,
    LiquityClaimAction,
    LiquityEthGainToTroveAction,
    LiquityClaimStakingRewardsAction,
    LiquityClaimSPRewardsAction,
  ],
  lido: [
    LidoStakeAction,
    LidoWrapAction,
    LidoUnwrapAction,
  ],
  yearn: [
    YearnSupplyAction,
    YearnWithdrawAction,
  ],
  mstable: [
    MStableSaveSupplyAction,
    MStableVaultSupplyAction,
    MStableSaveWithdrawAction,
    MStableVaultWithdrawAction,
  ],
};

export const nameToClassMap = Object.values(allActions).flat().reduce((map, action) => ({ ...map, [action.name]: action }), {});

export default allActions;
