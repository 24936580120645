import t from 'translate';
import Dec from 'decimal.js';
import { captureException } from 'sentry';
import {
  GET_BOOST_MODAL_DATA_FAILURE,
  GET_BOOST_MODAL_DATA_REQUEST,
  GET_BOOST_MODAL_DATA_SUCCESS,
  GET_REPAY_MODAL_DATA_FAILURE,
  GET_REPAY_MODAL_DATA_REQUEST,
  GET_REPAY_MODAL_DATA_SUCCESS,
  MAKER_ACTION_EXEC_FAILURE,
  MAKER_ACTION_EXEC_REQUEST,
  MAKER_ACTION_EXEC_SUCCESS,
  MAKER_SET_ADDITIONAL_DASHBOARD_ACTION,
  MAKER_SET_DASHBOARD_ACTION,
  RESET_BOOST_MODAL,
  RESET_REPAY_MODAL,
  TRANSFER_CDP_FAILURE,
  TRANSFER_CDP_REQUEST,
  TRANSFER_CDP_SUCCESS,
} from 'actionTypes/makerActionTypes/makerManageActionTypes/makerManageActionTypes';
import {
  calculateTradeSizeImpact,
  checkAvailableDebt,
  getAction,
  getAssetDaiExchangeRate,
  getDaiAssetExchangeRate,
  transferCdp,
  makerManageTrackEventWrapper,
  getMakerRecipe,
} from 'services/makerServices/makerManageServices/makerManageService';
import {
  formatNumber, getEnsAddress, isAddress, isCdpExternal, resetFields, wait,
  formatAcc, ethToWeth, getEthAmountForDecimals,
} from 'services/utils';
import { addToLsState } from 'services/localStorageService';
import { getCdpAssetSymbol, getCdpDaiSymbol } from 'services/makerServices/makerServiceCommon';
import { getCdpInfo } from 'services/makerServices/makerService';
import { getSlippageThreshold, getBestExchangePrice } from 'services/exchangeServiceV3';
import { useFlForRepay, useFlForBoost, getMaxWithdraw } from 'services/vaultCommonService';
import {
  assetAmountInWei, getIlkInfo, getAssetInfo, assetAmountInEth,
} from '@defisaver/tokens';
import { confirmViaModal, openMigrateCdpFromInstadappModal, sendConfirmViaModal } from '../../modalActions';
import { callMultipleTxAction, sendTx } from '../../txNotificationActions';
import { setAfterValue } from './makerManageAfterValues';
import { callRecipeViaProxy } from '../../../services/contractCallService';
import * as makerRecipes from '../../../recipes/makerRecipes';
import { flProtocolAndFeeFor } from '../../../services/assetsService';
import { addApproveForActionIfNeeded, getDashboardInputs } from '../../dashboardActions';
import { formName } from '../../../components/DashboardActions/dashboardActionUtils';
import { ASSETS_FROM_MAKER_ASSET } from '../../../constants/assets';
import {
  compareMarketPriceWithPoolPrice,
  getLPLiquidityMintedEstimate,
  getLPLiquidityValueEstimate,
} from '../../../services/uniswapServices';
import { getActualMintAmounts, getBurnAmounts } from '../../../services/gelatoService';
import { normalizeFunc, setSlippagePercent } from '../../../services/exchangeServiceCommon';
import { getBurnAmountOneToken, getLpEstimate } from '../../../services/curveServices/curveService';
import { isLayer2Network } from '../../../services/ethService';

const postAction = (contextAction, secondAction, firstAction) => async (dispatch, getState) => {
  await dispatch(setAfterValue(0, 'clear'));
  await dispatch(resetFields(formName, { [`${contextAction.value}-${firstAction?.value}`]: '', [`${contextAction.value}-${secondAction?.value}`]: '' }));

  const payload = await getCdpInfo(getState().maker.cdp);
  dispatch({ type: MAKER_ACTION_EXEC_SUCCESS, action: contextAction.value, payload });
  firstAction.getMax();
};

/**
 * Handles redux actions for the generate dai smart contract call
 *
 * @param value {string} action being executed
 * contextAction {Object} where the action is being called from
 * additionalAction {Object} contains value of action to be executed in recipe, eg. 'generate' but also can be 'collateral' if contextAction is 'generate'
 * @return {Function}
 */
export const basicMakerAction = (value) => (contextAction, additionalAction) => async (dispatch, getState) => {
  try {
    const {
      general: { account, accountType },
      maker: { cdp, proxyAddress },
    } = getState();
    if (isCdpExternal(cdp)) return dispatch(openMigrateCdpFromInstadappModal());

    const {
      firstAction, firstInput, secondAction, secondInput,
    } = dispatch(getDashboardInputs(contextAction, additionalAction));

    const { ratioTooLow, ratioTooHigh } = getState().makerManage;
    if (ratioTooLow || ratioTooHigh) {
      const confirmed = await dispatch(confirmViaModal(t('errors.automatic_trigger')));
      if (!confirmed) return false;
    }

    if (firstAction.value === 'generate') await checkAvailableDebt(cdp, firstInput);
    if (secondAction?.value === 'generate') await checkAvailableDebt(cdp, secondInput);

    makerManageTrackEventWrapper(getState, 'makerManage', value);
    dispatch({ type: MAKER_ACTION_EXEC_REQUEST, action: contextAction.value });

    let targetAccount = secondAction?.value === 'send' ? secondInput : account;
    if (!isAddress(targetAccount)) targetAccount = await getEnsAddress(targetAccount);
    const firstInputWei = assetAmountInWei(firstInput, firstAction.symbol);
    const secondInputWei = secondAction?.value === 'send' ? '' : assetAmountInWei(secondInput, secondAction?.symbol);
    const recipe = getMakerRecipe(firstAction.value, firstInputWei, secondAction?.value, secondInputWei, cdp, targetAccount, proxyAddress);
    const amount = formatNumber(parseFloat(firstInput), 2);
    const additionalAmount = formatNumber(parseFloat(secondInput), 2);
    let label = `${firstAction.label}: ${amount} ${firstAction.symbol}`;
    if (secondAction && secondAction.value !== 'send') label += ` + ${secondAction.label}: ${additionalAmount} ${secondAction.symbol}`;
    else if (secondAction) label += ` + ${secondAction.label} to ${formatAcc(targetAccount, 5, 3)}`;
    const proxySendHandler = (promise) => {
      const additionalInfo = {
        protocol: 'maker',
        firstToken: firstAction.symbol,
        firstAmount: firstInput,
      };
      return sendTx(promise, label, `CDP #${cdp.id}`, dispatch, getState, additionalInfo);
    };

    const txToExecute = [];
    await dispatch(addApproveForActionIfNeeded(firstAction, firstInput, proxyAddress, txToExecute));
    if (secondAction) await dispatch(addApproveForActionIfNeeded(secondAction, secondInput, proxyAddress, txToExecute));
    txToExecute.push({
      func: () => callRecipeViaProxy(accountType, proxySendHandler, proxyAddress, account, recipe),
      type: firstAction.value,
      notifMessage: label,
    });
    await dispatch(callMultipleTxAction(txToExecute));

    dispatch(postAction(contextAction, secondAction, firstAction));
    makerManageTrackEventWrapper(getState, 'makerManage', `${value}Success`);
  } catch (err) {
    dispatch({ type: MAKER_ACTION_EXEC_FAILURE, action: contextAction.value, payload: err.message });
    makerManageTrackEventWrapper(getState, 'makerManage', `${value}Error`, err.message);
    captureException(err);
  }
};

export const generateAction = basicMakerAction('generate');
export const withdrawAction = basicMakerAction('withdraw');
export const addCollateralAction = basicMakerAction('collateral');
export const paybackAction = basicMakerAction('payback');

// CDP PROXY ACTIONS END //
// CDP SAVER PROXY ACTIONS START //

/**
 * Gets all the data that is displayed inside the repay modal
 *
 * @param inputAmount {String}
 *
 * @return {Function}
 */
export const getRepayModalData = inputAmount => async (dispatch, getState) => {
  dispatch({ type: GET_REPAY_MODAL_DATA_REQUEST });

  try {
    const { cdp, proxyAddress } = getState().maker;

    const { price: exchangeRate, source } = await getAssetDaiExchangeRate(getCdpAssetSymbol(getState), inputAmount, getCdpDaiSymbol(getState), proxyAddress);

    const repayAmount = new Dec(inputAmount).times(exchangeRate).toString();

    const marketPrice = await getSlippageThreshold(getCdpAssetSymbol(getState), getCdpDaiSymbol(getState));
    const tradeSizeImpact = calculateTradeSizeImpact(marketPrice, exchangeRate);

    const useFl = useFlForRepay(cdp, inputAmount);
    let flProtocol = 'none';
    let flFee = '0';
    let useAltRecipe = false;
    if (useFl) {
      let flData = await flProtocolAndFeeFor(inputAmount, cdp.asset, getState().general.network);
      if (flData.protocol === 'none') {
        useAltRecipe = true; // repayWithDebtLoan will be used
        flData = await flProtocolAndFeeFor(repayAmount, 'DAI', getState().general.network);
      }
      flProtocol = flData.protocol;
      flFee = flData.flFee;
    }

    const payload = {
      repayAmount,
      tradeSizeImpact,
      useFl,
      flFee,
      flProtocol,
      useAltRecipe,
      repayExchangeRate: exchangeRate,
      exchangeSource: source,
    };

    dispatch({ type: GET_REPAY_MODAL_DATA_SUCCESS, payload });
  } catch (err) {
    dispatch({ type: GET_REPAY_MODAL_DATA_FAILURE, payload: err.message });
    captureException(err);
  }
};

export const getInfoForRepayLPWithDAI = async (assets, inputAmount, proxyAddress) => {
  const [firstAmount, secondAmount, lpAddress] = await getLPLiquidityValueEstimate(ethToWeth(assets.firstAsset), ethToWeth(assets.secondAsset), inputAmount);

  const minFirstAmount = setSlippagePercent(normalizeFunc(0.5), firstAmount);
  const minSecondAmount = setSlippagePercent(normalizeFunc(0.5), secondAmount);

  // amount we want to sell
  const sellAmount = assets.isFirstDAI ? minSecondAmount : minFirstAmount;
  const sellAsset = assets.isFirstDAI ? assets.secondAsset : assets.firstAsset;
  // dai amount
  const daiLPAmount = assets.isFirstDAI ? minFirstAmount : minSecondAmount;
  const { price: exchangeRate, source } = await getBestExchangePrice(sellAmount, sellAsset, 'DAI', proxyAddress);

  // amount we are getting after selling
  const repayAmount = new Dec(exchangeRate).times(sellAmount).toString();
  const marketPrice = await getSlippageThreshold(sellAsset, 'DAI');
  const tradeSizeImpact = calculateTradeSizeImpact(marketPrice, exchangeRate);

  // amount of debt that is being paid (amount of dai + amount we get from selling)
  const lpFullRepayAmount = new Dec(repayAmount).plus(daiLPAmount).toString();

  return {
    firstAmount,
    secondAmount,
    sellAmount,
    sellAsset,
    daiLPAmount,
    exchangeRate,
    repayAmount,
    marketPrice,
    lpFullRepayAmount,
    tradeSizeImpact,
    source,
  };
};

export const getInfoForRepayLPWithoutDAI = async (assets, inputAmount, proxyAddress) => {
  // amounts we get from withrawing from Uniswap
  const [firstAmount, secondAmount, lpAddress] = await getLPLiquidityValueEstimate(ethToWeth(assets.firstAsset), ethToWeth(assets.secondAsset), inputAmount);

  const minFirstAmount = setSlippagePercent(normalizeFunc(0.5), firstAmount);
  const minSecondAmount = setSlippagePercent(normalizeFunc(0.5), secondAmount);

  // prices for both trades
  const { price: exchangeRate, source } = await getBestExchangePrice(minFirstAmount, ethToWeth(assets.firstAsset), 'DAI', proxyAddress);
  const { price: exchangeRateSecond, source: sourceSecond } = await getBestExchangePrice(minSecondAmount, ethToWeth(assets.secondAsset), 'DAI', proxyAddress);

  // amounts we estimate
  const repayAmount = new Dec(minFirstAmount).mul(exchangeRate).toString();
  const repayAmountSecond = new Dec(minSecondAmount).mul(exchangeRateSecond).toString();

  const marketPrice = await getSlippageThreshold(assets.firstAsset, 'DAI');
  const marketPriceSecond = await getSlippageThreshold(assets.secondAsset, 'DAI');

  const tradeSizeImpact = calculateTradeSizeImpact(marketPrice, exchangeRate);
  const tradeSizeImpactSecond = calculateTradeSizeImpact(marketPriceSecond, exchangeRateSecond);

  // amount of debt that is being paid
  const lpFullRepayAmount = new Dec(repayAmount).plus(repayAmountSecond).toString();

  return {
    repayAmount,
    repayAmountSecond,
    firstAmount,
    secondAmount,
    sellAmount: firstAmount,
    sellAmountSecond: secondAmount,
    exchangeRate,
    marketPrice,
    lpFullRepayAmount,
    exchangeRateSecond,
    marketPriceSecond,
    tradeSizeImpact,
    tradeSizeImpactSecond,
    lpSellAsset: assets.firstAsset,
    lpSellAssetSecond: assets.secondAsset,
    source,
    sourceSecond,
  };
};


export const getRepayModalDataLP = (inputAmount) => async (dispatch, getState) => {
  dispatch({ type: GET_REPAY_MODAL_DATA_REQUEST });

  try {
    const { cdp, proxyAddress } = getState().maker;

    const assets = ASSETS_FROM_MAKER_ASSET(cdp.asset);

    const {
      repayAmount,
      marketPrice,
      tradeSizeImpact,
      exchangeRate,
      firstAmount,
      secondAmount,
      sellAmount,
      sellAmountSecond,
      daiLPAmount,
      lpFullRepayAmount,
      source,
      exchangeRateSecond,
      sourceSecond,
      tradeSizeImpactSecond,
      repayAmountSecond,
      lpSellAsset,
      lpSellAssetSecond,
    } = assets.hasDAI ? await getInfoForRepayLPWithDAI(assets, inputAmount, proxyAddress) : await getInfoForRepayLPWithoutDAI(assets, inputAmount, proxyAddress);


    const useFl = useFlForRepay(cdp, inputAmount);

    let flProtocol = 'none';
    const flFee = '0';

    if (useFl) flProtocol = 'maker';

    const payload = {
      repayAmount,
      tradeSizeImpact,
      useFl,
      repayExchangeRate: exchangeRate,
      exchangeSource: source,
      lpFirstAmount: firstAmount,
      lpSecondAmount: secondAmount,
      lpFullDaiAmount: lpFullRepayAmount,
      lpSellAmount: sellAmount,
      lpSellAmountSecond: sellAmountSecond,
      isFirstDAI: assets.isFirstDAI,
      exchangeRateSecond,
      repayAmountSecond,
      lpSellAsset,
      lpSellAssetSecond,
      tradeSizeImpactSecond,
      sourceSecond,
      flFee,
      flProtocol,
    };

    dispatch({ type: GET_REPAY_MODAL_DATA_SUCCESS, payload });
  } catch (err) {
    dispatch({ type: GET_REPAY_MODAL_DATA_FAILURE, payload: err.message });
    captureException(err);
  }
};

export const getRepayModalDataGuni = (inputAmount) => async (dispatch, getState) => {
  dispatch({ type: GET_REPAY_MODAL_DATA_REQUEST });

  try {
    const {
      maker: { proxyAddress, cdp },
      general: { account },
    } = getState();
    const assets = ASSETS_FROM_MAKER_ASSET(cdp.asset);

    const mockAmounts = await getBurnAmounts(getIlkInfo(cdp.ilk).assetAddress, account, inputAmount, getIlkInfo(cdp.ilk).join);

    // hardcoded because we only have dai/usdc pair as of now
    const daiAmount = assetAmountInEth(mockAmounts.amount0, 'DAI');
    const otherAmount = assetAmountInEth(mockAmounts.amount1, 'USDC');
    const _otherAmount = setSlippagePercent(0.5, assetAmountInEth(mockAmounts.amount1, 'USDC'));

    const sellAsset = assets.isFirstDAI ? assets.secondAsset : assets.firstAsset;
    const { price: exchangeRate, source } = await getBestExchangePrice(_otherAmount, sellAsset, 'DAI', proxyAddress);
    const marketPrice = await getSlippageThreshold(sellAsset, 'DAI');
    const tradeSizeImpact = calculateTradeSizeImpact(marketPrice, exchangeRate);

    const useFl = useFlForRepay(cdp, inputAmount);
    const repayAmount = new Dec(_otherAmount).mul(exchangeRate).toString();

    const lpFullDaiAmount = new Dec(daiAmount).plus(repayAmount).toString();

    dispatch({
      type: GET_REPAY_MODAL_DATA_SUCCESS,
      payload: {
        repayAmount,
        tradeSizeImpact,
        useFl,
        flFee: '0',
        flProtocol: useFl ? 'maker' : 'none',
        repayExchangeRate: exchangeRate,
        exchangeSource: source,
        lpFirstAmount: daiAmount,
        lpSecondAmount: otherAmount,
        lpFullDaiAmount,
        lpTokensAmount: inputAmount,
        lpSellAmount: otherAmount,
        isFirstDAI: assets.isFirstDAI,
      },
    });
  } catch (err) {
    dispatch({ type: GET_REPAY_MODAL_DATA_FAILURE, payload: err.message });
    captureException(err);
  }
};

export const getRepayModalDataCrv = (inputAmount) => async (dispatch, getState) => {
  dispatch({ type: GET_REPAY_MODAL_DATA_REQUEST });
  try {
    const {
      maker: { proxyAddress, cdp },
      general: { account },
    } = getState();
    const assets = ASSETS_FROM_MAKER_ASSET(cdp.asset);
    const sellAsset = ethToWeth(assets.firstAsset);

    // currently we want only ETH, not both tokens
    const amountOfToken = await getBurnAmountOneToken(inputAmount, 0);
    const { price: exchangeRate, source } = await getBestExchangePrice(amountOfToken, sellAsset, 'DAI', proxyAddress);
    const marketPrice = await getSlippageThreshold(sellAsset, 'DAI');
    const tradeSizeImpact = calculateTradeSizeImpact(marketPrice, exchangeRate);

    const useFl = useFlForRepay(cdp, inputAmount);
    const repayAmount = new Dec(amountOfToken).mul(exchangeRate).toString();

    dispatch({
      type: GET_REPAY_MODAL_DATA_SUCCESS,
      payload: {
        repayAmount,
        tradeSizeImpact,
        useFl,
        flFee: '0',
        flProtocol: useFl ? 'maker' : 'none',
        repayExchangeRate: exchangeRate,
        exchangeSource: source,
        lpFirstAmount: amountOfToken,
        lpSecondAmount: '0',
        lpSellAmount: amountOfToken,
        lpFullDaiAmount: repayAmount,
        lpTokensAmount: inputAmount,
        isFirstDAI: assets.isFirstDAI,
        lpOneAsset: true,
      },
    });
  } catch (err) {
    dispatch({ type: GET_REPAY_MODAL_DATA_FAILURE, payload: err.message });
    captureException(err);
  }
};

export const getRepayModalDataForAsset = (inputAmount, asset) => dispatch => {
  if (asset.startsWith('UNIV2')) return dispatch(getRepayModalDataLP(inputAmount));
  if (asset.startsWith('GUNI')) return dispatch(getRepayModalDataGuni(inputAmount));
  if (asset.startsWith('steCRV')) return dispatch(getRepayModalDataCrv(inputAmount));
  return dispatch(getRepayModalData(inputAmount));
};

const getMakerCdpRepayRecipe = (useFl, cdpAsset, useAltRecipe, firstInput, assets, repayExchangeRate, slippagePercent, cdp, proxyAddress, account, additionalActions, lpFullRepayAmount, flProtocol, firstAmount, secondAmount, repayExchangeRateSecond) => {
  if (!useFl) {
    if (cdpAsset.startsWith('GUNI')) return makerRecipes.repayGUNIVault(firstInput, firstAmount, secondAmount, assets, repayExchangeRate, slippagePercent, cdp, proxyAddress, account, cdp.type, additionalActions);
    if (cdpAsset.startsWith('steCRV')) return makerRecipes.repayCurveEthStethVault(firstInput, firstAmount, assets, repayExchangeRate, slippagePercent, cdp, proxyAddress, account, cdp.type, additionalActions);
    if (cdpAsset.startsWith('UNIV2')) return assets.hasDAI ? makerRecipes.repayLPWithDAI(firstInput, assets, repayExchangeRate, slippagePercent, cdp, proxyAddress, account, cdp.type, 20, additionalActions) : makerRecipes.repayLPWithoutDAI(firstInput, assets, repayExchangeRate, repayExchangeRateSecond, slippagePercent, cdp, proxyAddress, account, cdp.type, 20, additionalActions);
    return makerRecipes.repay(firstInput, repayExchangeRate, slippagePercent, cdp, proxyAddress, account, cdp.type, additionalActions);
  }
  if (cdpAsset.startsWith('GUNI')) return makerRecipes.repayGUNIVaultFL(firstInput, firstAmount, secondAmount, assets, repayExchangeRate, slippagePercent, cdp, proxyAddress, account, cdp.type, 'maker', additionalActions);
  if (cdpAsset.startsWith('steCRV')) return makerRecipes.repayFLCurveEthStethVault(firstInput, firstAmount, assets, repayExchangeRate, slippagePercent, cdp, proxyAddress, account, cdp.type, 'maker', additionalActions);
  if (cdpAsset.startsWith('UNIV2')) return assets.hasDAI ? makerRecipes.repayLPWithFLDAI(firstInput, lpFullRepayAmount, assets, repayExchangeRate, slippagePercent, cdp, proxyAddress, account, cdp.type, 'maker', 20, additionalActions) : makerRecipes.repayLPWithoutDAIFL(firstInput, lpFullRepayAmount, assets, repayExchangeRate, repayExchangeRateSecond, slippagePercent, cdp, proxyAddress, account, cdp.type, 'maker', 20, additionalActions);
  if (useAltRecipe) return makerRecipes.repayWithDebtLoan(firstInput, repayExchangeRate, slippagePercent, cdp, proxyAddress, account, flProtocol, cdp.type, additionalActions);
  return makerRecipes.repayWithCollLoan(firstInput, getMaxWithdraw(cdp), repayExchangeRate, slippagePercent, cdp, proxyAddress, account, flProtocol, cdp.type, additionalActions);
};

/**
 * Handles redux actions for the repay from cdp smart contract call
 *
 * @param contextAction {Object}
 * @param additionalAction {Object}
 * @param closeModal {Function}
 *
 * @return {Function}
 */
export const repayAction = (contextAction, additionalAction, closeModal) => async (dispatch, getState) => {
  try {
    const { cdp } = getState().maker;
    if (isCdpExternal(getState().maker.cdp)) return dispatch(openMigrateCdpFromInstadappModal());

    makerManageTrackEventWrapper(getState, 'makerManage', 'repay');
    dispatch({ type: MAKER_ACTION_EXEC_REQUEST, action: contextAction.value });

    const {
      firstAction, firstInput, secondAction, secondInput,
    } = dispatch(getDashboardInputs(contextAction, additionalAction));

    const {
      repayAmount, repayExchangeRate, slippagePercent, maxRepay, useFl, lpSellAmount, lpSellAmountSecond,
      flProtocol, useAltRecipe, lpFullRepayAmount, lpFirstAmount, lpSecondAmount, exchangeRateSecond, repayAmountSecond,
    } = getState().makerManage;


    const amount = formatNumber(parseFloat(firstInput), 2);
    const additionalAmount = formatNumber(parseFloat(secondInput), 2);
    let label = `${firstAction.label}: ${amount} ${firstAction.symbol}`;
    if (secondAction) label += ` + ${secondAction.label}: ${additionalAmount} ${secondAction.symbol}`;


    const { account, accountType, path } = getState().general;
    const { proxyAddress } = getState().maker;
    const assets = ASSETS_FROM_MAKER_ASSET(cdp.asset);

    const sendTxFunc = (promise) => {
      if (assets.isLP && cdp.type !== 'crop' && !assets.hasDAI) {
        const multipleSwaps = [
          {
            buyToken: getAssetInfo('DAI'),
            sellToken: getAssetInfo(ethToWeth(assets.firstAsset)),
            buyAmount: repayAmount,
            sellAmount: lpSellAmount,
            wrapper: 1,
          },
          {
            buyToken: getAssetInfo('DAI'),
            sellToken: getAssetInfo(ethToWeth(assets.secondAsset)),
            buyAmount: repayAmountSecond,
            sellAmount: lpSellAmountSecond,
            wrapper: 2,
          },
        ];
        return sendTx(promise, label, `CDP #${cdp.id}`, dispatch, getState, { protocol: 'maker', multipleSwaps });
      }
      if (assets.isLP && (assets.hasDAI || cdp.type === 'crop')) {
        const additionalInfo = {
          protocol: 'maker',
          firstToken: assets.isFirstDAI ? assets.secondAsset : assets.firstAsset,
          firstAmount: lpSellAmount,
          secondToken: 'DAI',
          secondAmount: repayAmount,
        };
        return sendTx(promise, label, `CDP #${cdp.id}`, dispatch, getState, additionalInfo);
      }
      const additionalInfo = {
        protocol: 'maker',
        firstToken: cdp.asset,
        firstAmount: firstInput,
        secondToken: 'DAI',
        secondAmount: repayAmount,
      };
      return sendTx(promise, label, `CDP #${cdp.id}`, dispatch, getState, additionalInfo);
    };

    const additionalActions = secondAction
      ? getAction(secondAction.value, assetAmountInWei(secondInput, secondAction.symbol), cdp, account, proxyAddress)
      : [];
    const txToExecute = [];
    await dispatch(addApproveForActionIfNeeded(firstAction, firstInput, proxyAddress, txToExecute));
    if (secondAction) await dispatch(addApproveForActionIfNeeded(secondAction, secondInput, proxyAddress, txToExecute));
    const recipe = await getMakerCdpRepayRecipe(useFl, cdp.asset, useAltRecipe, firstInput, assets, repayExchangeRate, slippagePercent, cdp, proxyAddress, account, additionalActions, lpFullRepayAmount, flProtocol, lpFirstAmount, lpSecondAmount, exchangeRateSecond);
    txToExecute.push({
      func: () => callRecipeViaProxy(accountType, sendTxFunc, proxyAddress, account, recipe, 0, recipe.extraGas),
      type: 'payback',
      notifMessage: label,
    });
    await dispatch(callMultipleTxAction(txToExecute));

    dispatch(postAction(contextAction, secondAction, firstAction));
    closeModal();
    makerManageTrackEventWrapper(getState, 'makerManage', 'repaySuccess');
  } catch (err) {
    dispatch({ type: MAKER_ACTION_EXEC_FAILURE, action: contextAction.value, payload: err.message });
    makerManageTrackEventWrapper(getState, 'makerManage', 'repayError', err.message);
    captureException(err);
  }
};

/**
 * Resets the state for the repay modal
 *
 * @return {Function}
 */
export const resetRepayModal = () => (dispatch) => { dispatch({ type: RESET_REPAY_MODAL }); };

/**
 * Gets all the data that is displayed inside the boost modal
 *
 * @param inputAmount {Number}
 * @return {Function}
 */
export const getBoostModalData = inputAmount => async (dispatch, getState) => {
  dispatch({ type: GET_BOOST_MODAL_DATA_REQUEST });
  try {
    const { proxyAddress, cdp } = getState().maker;

    const { price: exchangeRate, source } = await getDaiAssetExchangeRate(
      getCdpDaiSymbol(getState), inputAmount.toString(), getCdpAssetSymbol(getState), proxyAddress,
    );

    const boostAmount = new Dec(exchangeRate).times(inputAmount).toString();

    const marketPrice = await getSlippageThreshold(getCdpDaiSymbol(getState), getCdpAssetSymbol(getState));
    const tradeSizeImpact = calculateTradeSizeImpact(marketPrice, exchangeRate);

    const useFl = useFlForBoost(cdp, inputAmount);
    const flData = useFl
      ? (await flProtocolAndFeeFor(inputAmount, 'DAI', getState().general.network))
      : {
        protocol: 'none', feeMultiplier: '1', flFee: '0', useAltRecipe: true,
      };

    dispatch({
      type: GET_BOOST_MODAL_DATA_SUCCESS,
      payload: {
        boostAmount,
        tradeSizeImpact,
        useFl,
        flProtocol: flData.protocol,
        useAltRecipe: flData.useAltRecipe,
        flFee: flData.flFee,
        boostExchangeRate: exchangeRate,
        exchangeSource: source,
      },
    });
  } catch (err) {
    dispatch({ type: GET_BOOST_MODAL_DATA_FAILURE, payload: err.message });
    captureException(err);
  }
};


export const getInfoForBoostLPWithDAI = async (inputAmount, assets, proxyAddress) => {
  const mockSellAmount = new Dec(inputAmount).div(2).toString();

  const { price: exchangeRate, source } = await getBestExchangePrice(mockSellAmount, 'DAI', assets.isFirstDAI ? assets.secondAsset : assets.firstAsset, proxyAddress);
  const rate = await compareMarketPriceWithPoolPrice(exchangeRate, 'DAI', ethToWeth(assets.isFirstDAI ? assets.secondAsset : assets.firstAsset));

  const actualSellAmount = new Dec(inputAmount).div(rate).toString();
  const leftoverDAIAmount = new Dec(inputAmount).sub(actualSellAmount).toString();

  const boostAmount = new Dec(exchangeRate).times(actualSellAmount).toString();
  const sellAsset = assets.isFirstDAI ? assets.secondAsset : assets.firstAsset;
  const marketPrice = await getSlippageThreshold('DAI', sellAsset);
  const tradeSizeImpact = calculateTradeSizeImpact(marketPrice, exchangeRate);
  const [lpEstimate] = await getLPLiquidityMintedEstimate(ethToWeth(assets.firstAsset), assets.isFirstDAI ? leftoverDAIAmount : boostAmount, ethToWeth(assets.secondAsset), assets.isFirstDAI ? boostAmount : leftoverDAIAmount);

  return {
    boostAmount,
    marketPrice,
    tradeSizeImpact,
    lpEstimate,
    exchangeRate,
    source,
    sellAsset,
    firstAmount: assets.isFirstDAI ? leftoverDAIAmount : actualSellAmount,
    secondAmount: assets.isFirstDAI ? actualSellAmount : leftoverDAIAmount,
  };
};

export const getInfoForBoostLPWithoutDAI = async (inputAmount, assets, proxyAddress) => {
  const mockSellAmount = new Dec(inputAmount).div(2).toString();

  const { price: exchangeRateFirst, source } = await getBestExchangePrice(mockSellAmount, 'DAI', assets.firstAsset, proxyAddress);
  const { price: exchangeRateSecond, source: sourceSecond } = await getBestExchangePrice(mockSellAmount, 'DAI', assets.secondAsset, proxyAddress);

  const priceFromFirstToSecond = new Dec(exchangeRateSecond).div(exchangeRateFirst).toString();
  const rate = await compareMarketPriceWithPoolPrice(priceFromFirstToSecond, ethToWeth(assets.firstAsset), ethToWeth(assets.secondAsset));

  const sellAmountSecondAsset = new Dec(inputAmount).div(rate).toString();
  const sellAmountFirstAsset = new Dec(inputAmount).sub(sellAmountSecondAsset).toString();

  const boostAmountFirst = new Dec(exchangeRateFirst).times(sellAmountFirstAsset).toString();
  const marketPriceFirst = await getSlippageThreshold('DAI', assets.firstAsset);
  const tradeSizeImpactFirst = calculateTradeSizeImpact(marketPriceFirst, exchangeRateFirst);

  const boostAmountSecond = new Dec(exchangeRateSecond).times(sellAmountSecondAsset).toString();
  const marketPriceSecond = await getSlippageThreshold('DAI', assets.secondAsset);
  const tradeSizeImpactSecond = calculateTradeSizeImpact(marketPriceSecond, exchangeRateSecond);

  const [lpEstimate] = await getLPLiquidityMintedEstimate(ethToWeth(assets.firstAsset), boostAmountFirst, ethToWeth(assets.secondAsset), boostAmountSecond);
  return {
    boostAmount: boostAmountFirst,
    marketPrice: marketPriceFirst,
    tradeSizeImpact: tradeSizeImpactFirst,
    exchangeRate: exchangeRateFirst,
    boostAmountSecond,
    marketPriceSecond,
    tradeSizeImpactSecond,
    exchangeRateSecond,
    lpEstimate,
    source,
    sourceSecond,
    sellAsset: assets.firstAsset,
    sellAssetSecond: assets.secondAsset,
    firstAmount: sellAmountFirstAsset,
    secondAmount: sellAmountSecondAsset,
  };
};

/**
 * Gets all the data that is displayed inside the boost modal for LP collateral assets
 *
 * @param inputAmount {Number}
 * @return {Function}
 */
export const getBoostModalDataLP = inputAmount => async (dispatch, getState) => {
  dispatch({ type: GET_BOOST_MODAL_DATA_REQUEST });
  try {
    const { proxyAddress, cdp } = getState().maker;
    const sellAmount = new Dec(inputAmount).div(2).toString();

    const assets = ASSETS_FROM_MAKER_ASSET(cdp.asset);

    const {
      boostAmount,
      marketPrice,
      tradeSizeImpact,
      lpEstimate,
      exchangeRate,
      boostAmountSecond,
      marketPriceSecond,
      tradeSizeImpactSecond,
      exchangeRateSecond,
      source,
      sourceSecond,
      firstAmount,
      secondAmount,
    } = assets.hasDAI ? await getInfoForBoostLPWithDAI(inputAmount, assets, proxyAddress) : await getInfoForBoostLPWithoutDAI(inputAmount, assets, proxyAddress);

    const useFl = useFlForBoost(cdp, inputAmount);
    // const flData = useFl ? (await flProtocolAndFeeFor(inputAmount, 'DAI')) : { protocol: 'none', feeMultiplier: 0 };

    const lpFirstAmount = assets.hasDAI
      ? (
        assets.isFirstDAI ? secondAmount : boostAmount
      )
      : boostAmount;
    // TODO: ?
    // lpSecondAmount is not relevant for hasDAI && isFirstDAI
    const lpSecondAmount = assets.hasDAI
      ? (
        assets.isFirstDAI ? boostAmount : secondAmount
      )
      : boostAmountSecond;

    dispatch({
      type: GET_BOOST_MODAL_DATA_SUCCESS,
      payload: {
        boostAmount,
        tradeSizeImpact,
        useFl,
        flProtocol: useFl ? 'maker' : 'none',
        flFee: '0',
        boostExchangeRate: exchangeRate,
        exchangeSource: source,
        lpFirstAmount,
        lpSecondAmount,
        lpTokensAmount: lpEstimate,
        isFirstDAI: assets.isFirstDAI,
        tradeSizeImpactSecond,
        exchangeRateSecond,
        sourceSecond,
        lpFullDaiAmount: inputAmount,
        lpSellAmount: firstAmount,
        lpSellAmountSecond: secondAmount,
        boostAmountSecond,
        firstDAIAmount: firstAmount,
        secondDAIAmount: secondAmount,
      },
    });
  } catch (err) {
    dispatch({ type: GET_BOOST_MODAL_DATA_FAILURE, payload: err.message });
    captureException(err);
  }
};

export const getBoostModalDataGuni = (inputAmount) => async (dispatch, getState) => {
  dispatch({ type: GET_BOOST_MODAL_DATA_REQUEST });

  try {
    const { proxyAddress, cdp } = getState().maker;
    const assets = ASSETS_FROM_MAKER_ASSET(cdp.asset);

    const actualAmounts = await getActualMintAmounts(getIlkInfo(cdp.ilk).assetAddress, inputAmount);

    const daiAmountLeft = assetAmountInEth(actualAmounts.amount0, assets.firstAsset);
    const usdcAmountNeeded = assetAmountInEth(actualAmounts.amount1, assets.secondAsset);

    const lpEstimate = getEthAmountForDecimals(actualAmounts.mintAmount, 18);

    const { price: exchangeRate, source } = await getBestExchangePrice(usdcAmountNeeded, 'DAI', assets.isFirstDAI ? assets.secondAsset : assets.firstAsset, proxyAddress);
    const boostAmount = new Dec(exchangeRate).mul(usdcAmountNeeded).toString();
    const marketPrice = await getSlippageThreshold('DAI', assets.isFirstDAI ? assets.secondAsset : assets.firstAsset);
    const tradeSizeImpact = calculateTradeSizeImpact(marketPrice, exchangeRate);

    const useFl = useFlForBoost(cdp, inputAmount);

    dispatch({
      type: GET_BOOST_MODAL_DATA_SUCCESS,
      payload: {
        boostAmount,
        tradeSizeImpact,
        useFl,
        flProtocol: useFl ? 'maker' : 'none',
        flFee: '0',
        boostExchangeRate: exchangeRate,
        exchangeSource: source,
        lpFirstAmount: daiAmountLeft,
        lpSecondAmount: boostAmount,
        lpTokensAmount: lpEstimate,
        lpSellAmount: usdcAmountNeeded,
        lpSellAsset: 'USDC',
        isFirstDAI: assets.isFirstDAI,
        firstDAIAmount: daiAmountLeft,
        secondDAIAmount: usdcAmountNeeded,
      },
    });
  } catch (err) {
    dispatch({ type: GET_BOOST_MODAL_DATA_FAILURE, payload: err.message });
    captureException(err);
  }
};

export const getBoostModalDataCrv = (inputAmount) => async (dispatch, getState) => {
  dispatch({ type: GET_BOOST_MODAL_DATA_REQUEST });
  try {
    const { proxyAddress, cdp } = getState().maker;
    const assets = ASSETS_FROM_MAKER_ASSET(cdp.asset);
    const neededAsset = ethToWeth(assets.firstAsset);
    const { price: exchangeRate, source } = await getBestExchangePrice(inputAmount, 'DAI', neededAsset, proxyAddress);
    const boostAmount = new Dec(exchangeRate).mul(inputAmount).toString();
    const lpEstimate = await getLpEstimate(assetAmountInWei(boostAmount, 'WETH'));

    const marketPrice = await getSlippageThreshold('DAI', neededAsset);
    const tradeSizeImpact = calculateTradeSizeImpact(marketPrice, exchangeRate);

    const useFl = useFlForBoost(cdp, inputAmount);

    dispatch({
      type: GET_BOOST_MODAL_DATA_SUCCESS,
      payload: {
        boostAmount,
        tradeSizeImpact,
        useFl,
        flProtocol: useFl ? 'maker' : 'none',
        flFee: '0',
        boostExchangeRate: exchangeRate,
        exchangeSource: source,
        lpFirstAmount: boostAmount,
        lpSecondAmount: '0',
        lpTokensAmount: lpEstimate,
        lpSellAmount: inputAmount,
        lpSellAsset: 'DAI',
        isFirstDAI: assets.isFirstDAI,
        lpOneAsset: true,
        // firstDAIAmount: daiAmountLeft,
        // secondDAIAmount: usdcAmountNeeded,
      },
    });
  } catch (err) {
    dispatch({ type: GET_BOOST_MODAL_DATA_FAILURE, payload: err.message });
    captureException(err);
  }
};

export const getBoostModalDataForAsset = (inputAmount, asset) => dispatch => {
  if (asset.startsWith('UNIV2')) return dispatch(getBoostModalDataLP(inputAmount));
  if (asset.startsWith('GUNI')) return dispatch(getBoostModalDataGuni(inputAmount));
  if (asset.startsWith('steCRV')) return dispatch(getBoostModalDataCrv(inputAmount));
  return dispatch(getBoostModalData(inputAmount));
};

const getMakerCdpBoostRecipe = (useFl, cdpAsset, firstInput, assets, boostExchangeRate, slippagePercent, cdp, proxyAddress, account, additionalActions, flProtocol, deadline, firstAmount, secondAmount, boostExchangeRateSecond, firstDAIAmount, secondDAIAmount, lpEstimate) => {
  if (!useFl) {
    if (cdpAsset.startsWith('GUNI')) return makerRecipes.boostGUNIVault(firstInput, firstAmount, secondAmount, assets, boostExchangeRate, slippagePercent, cdp, proxyAddress, account, cdp.type, additionalActions);
    if (cdpAsset.startsWith('steCRV')) return makerRecipes.boostCurveEthStethVault(firstInput, firstAmount, lpEstimate, assets, boostExchangeRate, slippagePercent, cdp, proxyAddress, account, cdp.type, additionalActions);
    if (cdpAsset.startsWith('UNIV2')) {
      return assets.hasDAI
        ? makerRecipes.boostLPWithDAI(firstInput, assets, firstDAIAmount, secondDAIAmount, boostExchangeRate, slippagePercent, cdp, proxyAddress, account, cdp.type, deadline, additionalActions)
        : makerRecipes.boostLPWithoutDAI(firstInput, assets, firstDAIAmount, secondDAIAmount, boostExchangeRate, boostExchangeRateSecond, slippagePercent, cdp, proxyAddress, account, cdp.type, deadline, additionalActions);
    }
    return makerRecipes.boost(firstInput, boostExchangeRate, slippagePercent, cdp, proxyAddress, cdp.type, additionalActions);
  }
  if (cdpAsset.startsWith('GUNI')) return makerRecipes.boostGUNIVaultFL(firstInput, firstAmount, secondAmount, assets, boostExchangeRate, slippagePercent, cdp, proxyAddress, account, cdp.type, 'maker', additionalActions);
  if (cdpAsset.startsWith('steCRV')) return makerRecipes.boostFLCurveEthStethVault(firstInput, firstAmount, lpEstimate, assets, boostExchangeRate, slippagePercent, cdp, proxyAddress, account, cdp.type, 'maker', additionalActions);
  if (cdpAsset.startsWith('UNIV2')) {
    return assets.hasDAI
      ? makerRecipes.boostLPWithFLDAI(firstInput, assets, firstDAIAmount, secondDAIAmount, boostExchangeRate, slippagePercent, cdp, proxyAddress, account, cdp.type, 'maker', deadline, additionalActions)
      : makerRecipes.boostLPWithoutDAIFL(firstInput, assets, firstDAIAmount, secondDAIAmount, boostExchangeRate, boostExchangeRateSecond, slippagePercent, cdp, proxyAddress, account, cdp.type, 'maker', deadline, additionalActions);
  }
  return makerRecipes.boostWithDebtLoan(firstInput, boostExchangeRate, slippagePercent, cdp, proxyAddress, flProtocol, cdp.type, additionalActions);
};

/**
 * Handles redux actions for the repay dai from cdp smart contract call
 *
 * @param contextAction {Object}
 * @param additionalAction {Object}
 * @param closeModal {Function}
 *
 * @return {Function}
 */
export const boostAction = (contextAction, additionalAction, closeModal) => async (dispatch, getState) => {
  try {
    const { cdp } = getState().maker;
    if (isCdpExternal(cdp)) return dispatch(openMigrateCdpFromInstadappModal());

    makerManageTrackEventWrapper(getState, 'makerManage', 'boost');
    dispatch({ type: MAKER_ACTION_EXEC_REQUEST, action: contextAction.value });

    const {
      firstAction, firstInput, secondAction, secondInput,
    } = dispatch(getDashboardInputs(contextAction, additionalAction));

    const {
      boostAmount, slippagePercent, boostExchangeRate, useFl, flProtocol, deadline, lpFirstAmount, lpSecondAmount,
      exchangeRateSecond, boostAmountSecond, lpSellAmountSecond, lpSellAmount, firstDAIAmount, secondDAIAmount, lpTokensAmount,
    } = getState().makerManage;

    await checkAvailableDebt(cdp, firstInput);

    const amount = formatNumber(parseFloat(firstInput), 2);
    const additionalAmount = formatNumber(parseFloat(secondInput), 2);
    let label = `${firstAction.label}: ${amount} ${firstAction.symbol}`;
    if (secondAction) label += ` + ${secondAction.label}: ${additionalAmount} ${secondAction.symbol}`;

    // const useFl = useFlForBoost(cdp, inputAmount);

    const { account, accountType } = getState().general;
    const { proxyAddress } = getState().maker;

    const additionalActions = secondAction
      ? getAction(secondAction.value, assetAmountInWei(secondInput, secondAction.symbol), cdp, account, proxyAddress)
      : [];
    const assets = ASSETS_FROM_MAKER_ASSET(cdp.asset);

    const sendTxFunc = (promise) => {
      if (assets.isLP && cdp.type !== 'crop' && !assets.hasDAI) {
        const multipleSwaps = [{
          buyToken: getAssetInfo(ethToWeth(assets.firstAsset)),
          sellToken: getAssetInfo('DAI'),
          buyAmount: boostAmount,
          sellAmount: lpSellAmount,
        },
        {
          buyToken: getAssetInfo(ethToWeth(assets.secondAsset)),
          sellToken: getAssetInfo('DAI'),
          buyAmount: boostAmountSecond,
          sellAmount: lpSellAmountSecond,
        }];
        return sendTx(promise, label, `CDP #${cdp.id}`, dispatch, getState, { protocol: 'maker', multipleSwaps });
      }
      if (assets.isLP && (assets.hasDAI || cdp.type === 'crop')) {
        const additionalInfo = {
          protocol: 'maker',
          firstToken: 'DAI',
          firstAmount: lpSellAmount,
          secondToken: assets.isFirstDAI ? assets.secondAsset : assets.firstAsset,
          secondAmount: boostAmount,
        };
        return sendTx(promise, label, `CDP #${cdp.id}`, dispatch, getState, additionalInfo);
      }

      const additionalInfo = {
        protocol: 'maker',
        firstToken: 'DAI',
        firstAmount: firstInput,
        secondToken: cdp.asset,
        secondAmount: boostAmount,
      };
      return sendTx(promise, label, `CDP #${cdp.id}`, dispatch, getState, additionalInfo);
    };

    const recipe = await getMakerCdpBoostRecipe(useFl, cdp.asset, firstInput, assets, boostExchangeRate, slippagePercent, cdp, proxyAddress, account, additionalActions, flProtocol, deadline, lpFirstAmount, lpSecondAmount, exchangeRateSecond, firstDAIAmount, secondDAIAmount, lpTokensAmount);

    const txToExecute = [];
    await dispatch(addApproveForActionIfNeeded(firstAction, firstInput, proxyAddress, txToExecute));
    if (secondAction) await dispatch(addApproveForActionIfNeeded(secondAction, secondInput, proxyAddress, txToExecute));
    txToExecute.push({
      func: () => callRecipeViaProxy(accountType, sendTxFunc, proxyAddress, account, recipe, 0, recipe.extraGas),
      type: 'payback',
      notifMessage: label,
    });
    await dispatch(callMultipleTxAction(txToExecute));

    dispatch(postAction(contextAction, secondAction, firstAction));
    closeModal();
    makerManageTrackEventWrapper(getState, 'makerManage', 'boostSuccess');
  } catch (err) {
    dispatch({ type: MAKER_ACTION_EXEC_FAILURE, action: contextAction.value, payload: err.message });
    makerManageTrackEventWrapper(getState, 'makerManage', 'boostError', err.message);
    captureException(err);
  }
};

/**
 * Resets the state for the repay modal
 *
 * @return {Function}
 */
export const resetBoostModal = () => (dispatch) => { dispatch({ type: RESET_BOOST_MODAL }); };

// CDP SAVER PROXY ACTIONS END //
// CDP OTHER ACTIONS START //

/**
 * Handles redux actions for when the user wants to transfer his cdp to another address
 *
 * @param formValues {Object}
 * @param history {Object}
 * @param closeModal {Function}
 *
 * @return {Function}
 */
export const transferCdpAction = ({ toAddress }, history, closeModal) => async (dispatch, getState) => {
  if (isCdpExternal(getState().maker.cdp)) return dispatch(openMigrateCdpFromInstadappModal());

  makerManageTrackEventWrapper(getState, 'makerManage', 'transfer');

  dispatch({ type: TRANSFER_CDP_REQUEST });

  const { cdp } = getState().maker;

  const proxySendHandler = promise => sendTx(promise, t('maker.transfer_cdp'), `CDP #${cdp.id}`, dispatch, getState, { protocol: 'maker' });

  try {
    const { account } = getState().general;
    const { cdp, cdps } = getState().maker;

    await transferCdp(getState, toAddress, proxySendHandler);
    await wait(5000);

    const newCdps = [...cdps];
    const closedCdpIndex = cdps.map(({ id }) => id).indexOf(cdp.id);
    newCdps.splice(closedCdpIndex, 1);

    const newCdp = newCdps.length > 0 ? await getCdpInfo(newCdps[0]) : null;

    addToLsState({
      account,
      cdp: {
        id: newCdp ? newCdp.id : -1,
        type: newCdp ? newCdp.type : '',
      },
    });

    closeModal();

    history.push(newCdp === null ? '/makerdao/create-cdp' : '/makerdao/manage');
    dispatch({ type: TRANSFER_CDP_SUCCESS, payload: { cdps: newCdps, cdp: newCdp } });
    makerManageTrackEventWrapper(getState, 'makerManage', 'transferSuccess');
  } catch (err) {
    dispatch({ type: TRANSFER_CDP_FAILURE, payload: err.message });
    makerManageTrackEventWrapper(getState, 'makerManage', 'transferError', err.message);
    captureException(err);
  }
};

// CDP OTHER ACTIONS END //

export const changeDashboardAction = (payload, primary = true) => async (dispatch) => {
  await dispatch({
    type: primary ? MAKER_SET_DASHBOARD_ACTION : MAKER_SET_ADDITIONAL_DASHBOARD_ACTION,
    payload,
  });
  dispatch(setAfterValue(0, 'reset'));
};

export const openMakerSendModal = (contextAction, additionalAction) => async (dispatch, getState) => {
  if (additionalAction.value !== 'send') throw new Error('DEV: Send cannot be the primary action');

  const {
    firstAction, firstInput, secondAction, secondInput: unresolvedReceiver,
  } = dispatch(getDashboardInputs(contextAction, additionalAction));

  const sendToAddress = await dispatch(sendConfirmViaModal({ sendingTo: unresolvedReceiver, token: getAssetInfo(firstAction.symbol), amount: firstInput }));
  if (!sendToAddress) throw new Error(t('errors.denied_transaction'));

  return dispatch(basicMakerAction(contextAction.value)(contextAction, additionalAction));
};

export const getBoostModalDataForCollAsset = (cdpAssetSymbol) => {
  if (cdpAssetSymbol.startsWith('UNIV2')) return getBoostModalDataLP;
  if (cdpAssetSymbol.startsWith('GUNI')) return getBoostModalDataGuni;
  return getBoostModalData;
};
