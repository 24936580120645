import { getAssetInfoByAddress } from '@defisaver/tokens';
import cloneDeep from 'lodash/cloneDeep';
import Dec from 'decimal.js';
import dfs from '@defisaver/sdk';
import { yearnCollateralAssets } from 'constants/assets';
import RecipeAction from '../RecipeAction';
import {
  Amount, Asset, Helper, Source,
} from '../../components/Recipes/RecipeCreator/inputTypes';
import WithdrawIcon from '../recipeIcons/Withdraw.svg';
import { formatNumber } from '../../services/utils';
import { assetAmountInWeiIgnorePointer, changeBalance } from '../../services/recipeCreator/recipeActionUtils';
import { getVaultAddressForToken, getVaultInfoForToken, assetsWithAllVaults } from '../../services/yearnServices/yearnService';
import { MAXUINT } from '../../constants/general';

export default class YearnWithdrawAction extends RecipeAction {
  static prettyName = 'Withdraw from Yearn Vault';

  static protocol = 'yearn';

  static protocolPrettyName = 'Yearn';

  static description = 'Withdraws selected asset from Yearn.';

  constructor(asset = 'WETH', from = 'wallet', amount = '', to = 'wallet', index = '0', availableAssets = []) {
    super();
    this.inputs = [
      new Asset('Asset', asset, availableAssets.length ? availableAssets : yearnCollateralAssets),
      new Source('From', from),
      new Amount('Amount', amount),
      new Source('To', to, true),
      new Helper('Index', index),
    ];
    this.output = new Amount('output', 0);
    this.asset = '';
    this.yAssetAddress = '';
  }

  static getIcon() {
    return WithdrawIcon;
  }

  setAsset(asset) {
    this.asset = asset;
  }

  getAsset() {
    return this.asset;
  }

  setYAssetAddress(address) {
    this.yAssetAddress = address;
  }

  getYAssetAddress() {
    return this.yAssetAddress;
  }

  _getPrettyName(actionCalls, actions) {
    const args = this.mapReturnValuesToArgs(actionCalls.map(a => a.returnValue), actions);
    const amount = args[2];
    const asset = getAssetInfoByAddress(args[0]).symbol;
    return `Withdraw ${amount === 'All available' ? 'all' : formatNumber(amount)} ${asset.replace(/^ETH/, 'WETH')} from Yearn Vault`;
  }

  async getAfterValues(_balances = {}, returnValues = [], actions = [], _positions = {}, getState) {
    const positions = { ..._positions };
    const {
      general: { account },
      maker: { proxyAddress },
      yearn: { numOfVaultsForAsset },
    } = getState();
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const asset = getAssetInfoByAddress(args[0]).symbol;
    let amount = args[2] || '0';
    const balances = cloneDeep(_balances);
    const [vaultBalances, vaultAddress] = await getVaultInfoForToken(args[0], [account, proxyAddress], true, args[4]);
    const isActive = Dec(new Dec(numOfVaultsForAsset[asset]).minus(1)).eq(args[4]);
    const additionalLabel = isActive ? '' : '(old)';
    if (amount === 'All available') {
      amount = (
        args[1] === 'wallet'
          ? (balances?.wallet?.[`YV-${asset}${additionalLabel}`] || vaultBalances[0])
          : (balances?.recipe?.[`YV-${asset}${additionalLabel}`] || vaultBalances[1] || 0))
        || '0';
    }
    await changeBalance(balances, args[1], `YV-${asset}${additionalLabel}`, new Dec(amount).mul(-1).toString(), args[1] === 'wallet' ? account : proxyAddress, vaultAddress);
    await changeBalance(balances, args[3], asset, amount, args[3] === 'wallet' ? account : proxyAddress);
    this.output.value = amount;
    return {
      returnValue: this.output,
      balances,
      positions,
    };
  }

  async toDfsAction(getState, i) {
    const {
      general: { account },
      maker: { proxyAddress },
    } = getState();
    const from = this.inputs[1].value === 'wallet' ? account : proxyAddress;
    const to = this.inputs[3].value === 'wallet' ? account : proxyAddress;
    const amount = this.inputs[2].value === 'All available' ? MAXUINT : assetAmountInWeiIgnorePointer(this.inputs[2].value, this.inputs[0]._asset);
    const vaultAddress = await getVaultAddressForToken(this.inputs[0].value, true, this.inputs[4].value);
    return new dfs.actions.yearn.YearnWithdrawAction(
      vaultAddress,
      amount,
      from,
      to,
    );
  }

  static async prepareAndInstantiate({ getState, args }) {
    const { numOfVaults } = getState().yearn;
    // wait for prepareAndInstantiate fix to pass args

    const availableAssets = await assetsWithAllVaults(numOfVaults);
    const defArgs = ['WETH', 'wallet', '', 'wallet', '0'];
    return new this(...(args || defArgs), availableAssets);
  }
}
