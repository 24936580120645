import config from '../config/config.json';
import clientConfig from '../config/clientConfig.json';
import { setupWeb3 } from './web3Service';

setupWeb3();

let selectedNetwork = clientConfig.network;

export const changeContractsNetwork = (networkId) => {
  selectedNetwork = networkId;
};

export const getConfigContractAddress = (name, network) => config[name].networks[network || selectedNetwork]?.address;

const createContractFromConfigFunc = (name, _address) => (networkId = selectedNetwork) => {
  const address = _address || getConfigContractAddress(name, networkId);
  return new window._web3Object[networkId].eth.Contract(config[name].abi, address);
};

// ASSETS addresses
export const ethAddress = getConfigContractAddress('ETH');
export const cEthAddress = getConfigContractAddress('cETH');
export const daiAddress = getConfigContractAddress('DAI');
export const saiAddress = getConfigContractAddress('SAI');
export const batAddress = getConfigContractAddress('BAT');
export const cBatAddress = getConfigContractAddress('cBAT');
export const repAddress = getConfigContractAddress('REP');
export const repV2Address = getConfigContractAddress('REPv2');
export const cRepAddress = getConfigContractAddress('cREP');
export const zrxAddress = getConfigContractAddress('ZRX');
export const kncAddress = getConfigContractAddress('KNC');
export const cZrxAddress = getConfigContractAddress('cZRX');
export const usdcAddress = getConfigContractAddress('USDC');
export const cUsdcAddress = getConfigContractAddress('cUSDC');
export const wbtcAddress = getConfigContractAddress('WBTC');
export const cWbtcAddress = getConfigContractAddress('cWBTC');
export const mkrAddress = getConfigContractAddress('MKR');
export const wethAddress = getConfigContractAddress('WETH');
export const usdtAddress = getConfigContractAddress('USDT');
export const cUsdtAddress = getConfigContractAddress('cUSDT');
export const compAddress = getConfigContractAddress('COMP');
export const aETHAddress = getConfigContractAddress('aETH');
export const aDAIAddress = getConfigContractAddress('aDAI');
export const av2DAIAddress = getConfigContractAddress('av2DAI');
export const aUSDCAddress = getConfigContractAddress('aUSDC');
export const aSUSDAddress = getConfigContractAddress('aSUSD');
export const SUSDAddress = getConfigContractAddress('SUSD');
export const aTUSDAddress = getConfigContractAddress('aTUSD');
export const TUSDAddress = getConfigContractAddress('TUSD');
export const aUSDTAddress = getConfigContractAddress('aUSDT');
export const aBUSDAddress = getConfigContractAddress('aBUSD');
export const BUSDAddress = getConfigContractAddress('BUSD');
export const aBATAddress = getConfigContractAddress('aBAT');
export const aKNCAddress = getConfigContractAddress('aKNC');
export const aLENDAddress = getConfigContractAddress('aLEND');
export const LENDAddress = getConfigContractAddress('LEND');
export const aLINKAddress = getConfigContractAddress('aLINK');
export const LINKAddress = getConfigContractAddress('LINK');
export const aMANAAddress = getConfigContractAddress('aMANA');
export const MANAAddress = getConfigContractAddress('MANA');
export const aMKRAddress = getConfigContractAddress('aMKR');
export const aREPAddress = getConfigContractAddress('aREP');
export const aSNXAddress = getConfigContractAddress('aSNX');
export const SNXAddress = getConfigContractAddress('SNX');
export const aWBTCAddress = getConfigContractAddress('aWBTC');
export const aZRXAddress = getConfigContractAddress('aZRX');
export const aENJAddress = getConfigContractAddress('aENJ');
export const ENJAddress = getConfigContractAddress('ENJ');
export const aRENAddress = getConfigContractAddress('aREN');
export const RENAddress = getConfigContractAddress('REN');
export const CRVAddress = getConfigContractAddress('CRV');
export const YFIAddress = getConfigContractAddress('YFI');
export const aYFIAddress = getConfigContractAddress('aYFI');
export const PAXUSDAddress = getConfigContractAddress('USDP');
export const DPIAddress = getConfigContractAddress('DPI');
export const UNIAddress = getConfigContractAddress('UNI');
export const LRCAddress = getConfigContractAddress('LRC');
export const cUNIAddress = getConfigContractAddress('cUNI');
export const cCOMPAddress = getConfigContractAddress('cCOMP');
export const aUNIAddress = getConfigContractAddress('aUNI');
export const AAVEAddress = getConfigContractAddress('AAVE');
export const aAAVEAddress = getConfigContractAddress('aAAVE');
export const stkAAVEAddress = getConfigContractAddress('stkAAVE');
export const BALAddress = getConfigContractAddress('BAL');
export const GUSDAddress = getConfigContractAddress('GUSD');
export const aWETHAddress = getConfigContractAddress('aWETH');
export const RENBTCAddress = getConfigContractAddress('RENBTC');

// Just addresses
export const BidProxyAddress = getConfigContractAddress('BidProxy');

export const mcdCreateTakerAddress = getConfigContractAddress('McdCreateTaker');

export const SubscriptionsProxyV2Address = getConfigContractAddress('SubscriptionsProxyV2');

export const withdrawTokenProxyAddress = getConfigContractAddress('WithdrawTokenProxy');

export const CompoundBasicProxyAddress = getConfigContractAddress('CompoundBasicProxy');

export const CompoundSubscriptionsProxyAddress = getConfigContractAddress('CompoundSubscriptionsProxy');

export const CompoundMonitorProxyAddress = getConfigContractAddress('CompoundMonitorProxy');

export const AaveMonitorProxyV2Address = getConfigContractAddress('AaveMonitorProxyV2');

export const GebSafeManagerAddress = getConfigContractAddress('GebSafeManager');
export const GebSafeManagerContract = createContractFromConfigFunc('GebSafeManager');

export const GebTaxCollectorAddress = getConfigContractAddress('GebTaxCollector');


export const FLDyDxAddress = getConfigContractAddress('FLDyDx');

export const FLAaveAddress = getConfigContractAddress('FLAave');

export const AaveV1FullPositionViewAddress = getConfigContractAddress('AaveV1FullPositionView');

export const BalancerVaultAddress = getConfigContractAddress('BalancerVault');

// Addresses with contracts
export const cDaiAddress = getConfigContractAddress('cDAI');
export const cDaiContract = createContractFromConfigFunc('cDAI');

export const proxyRegistryInterfaceAddress = getConfigContractAddress('ProxyRegistryInterface');
export const proxyRegistryInterfaceContract = createContractFromConfigFunc('ProxyRegistryInterface');

export const DFSProxyRegistryAddress = getConfigContractAddress('DFSProxyRegistry');
export const DFSProxyRegistryContract = createContractFromConfigFunc('DFSProxyRegistry');

export const mcdSaverProxyAddress = getConfigContractAddress('MCDSaverProxy');
export const MCDSaverProxyContract = createContractFromConfigFunc('MCDSaverProxy');

export const comptrollerAddress = getConfigContractAddress('Comptroller');
export const comptrollerContract = createContractFromConfigFunc('Comptroller');

export const nexusMutualAddress = getConfigContractAddress('NexusMutual');
export const nexusMutualContract = createContractFromConfigFunc('NexusMutual');

export const saverExchangeAddress = getConfigContractAddress('SaverExchange');
export const saverExchangeContract = createContractFromConfigFunc('SaverExchange');

export const saverExchangeAddressV2 = getConfigContractAddress('SaverExchangeV2');

export const dydxAddress = getConfigContractAddress('Dydx');
export const DydxContract = createContractFromConfigFunc('Dydx');

export const iDaiAddress = getConfigContractAddress('iDai');
export const iDaiContract = createContractFromConfigFunc('iDai');

export const mcdGetCdpsAddress = getConfigContractAddress('McdGetCdps');
export const McdGetCdpsContract = createContractFromConfigFunc('McdGetCdps');

export const McdViewAddress = getConfigContractAddress('McdView');
export const McdViewContract = createContractFromConfigFunc('McdView');

export const mcdSpotterAddress = getConfigContractAddress('McdSpotter');
export const McdSpotterContract = createContractFromConfigFunc('McdSpotter');

export const mcdVatAddress = getConfigContractAddress('McdVat');
export const McdVatContract = createContractFromConfigFunc('McdVat');

export const mcdJugAddress = getConfigContractAddress('McdJug');
export const McdJugContract = createContractFromConfigFunc('McdJug');

export const mcdDogAddress = getConfigContractAddress('McdDog');
export const McdDogContract = createContractFromConfigFunc('McdDog');

export const mcdCdpManagerAddress = getConfigContractAddress('McdCdpManager');
export const McdCdpManagerContract = createContractFromConfigFunc('McdCdpManager');

export const mcdSaverProxyActionsAddress = getConfigContractAddress('McdSaverProxyActions');
export const McdSaverProxyActionsContract = createContractFromConfigFunc('McdSaverProxyActions');

export const mcdAutomationSubscriptionsAddress = getConfigContractAddress('McdAutomationSubscriptions');
export const McdAutomationSubscriptionsContract = createContractFromConfigFunc('McdAutomationSubscriptions');

export const SubscriptionsV2Address = getConfigContractAddress('SubscriptionsV2');
export const SubscriptionsV2Contract = createContractFromConfigFunc('SubscriptionsV2');

export const automaticLoggerAddress = getConfigContractAddress('AutomaticLogger');
export const AutomaticLoggerContract = createContractFromConfigFunc('AutomaticLogger');

export const potAddress = getConfigContractAddress('Pot');
export const PotContract = createContractFromConfigFunc('Pot');

export const aaveLendingPoolAddress = getConfigContractAddress('AaveLendingPool');
export const AaveLendingPoolContract = createContractFromConfigFunc('AaveLendingPool');

export const aaveLendingPoolCoreAddress = getConfigContractAddress('AaveLendingPoolCore');

export const aaveRateAddress = getConfigContractAddress('AaveRate');
export const AaveRateContract = createContractFromConfigFunc('AaveRate');

export const aaveV2RateAddress = getConfigContractAddress('AaveV2Rate');
export const AaveV2RateContract = createContractFromConfigFunc('AaveV2Rate');

export const compoundLoanInfoAddress = getConfigContractAddress('CompoundLoanInfo');
export const CompoundLoanInfoContract = createContractFromConfigFunc('CompoundLoanInfo');

export const compoundImportTakerAddress = getConfigContractAddress('CompoundImportTaker');
export const CompoundImportTakerContract = createContractFromConfigFunc('CompoundImportTaker');

export const MCDMonitorProxyV2Address = getConfigContractAddress('MCDMonitorProxyV2');
export const MCDMonitorProxyV2Contract = createContractFromConfigFunc('MCDMonitorProxyV2');

export const BalanceScannerAddress = getConfigContractAddress('BalanceScanner');
export const BalanceScannerContract = createContractFromConfigFunc('BalanceScanner');

export const FundSafeguardAddress = getConfigContractAddress('FundSafeguard');
export const FundSafeguardContract = createContractFromConfigFunc('FundSafeguard');

export const CompBalanceAddress = getConfigContractAddress('CompBalance');
export const CompBalanceContract = createContractFromConfigFunc('CompBalance');

export const CompoundSubscriptionsAddress = getConfigContractAddress('CompoundSubscriptions');
export const CompoundSubscriptionsContract = createContractFromConfigFunc('CompoundSubscriptions');

export const DefisaverLoggerAddress = getConfigContractAddress('DefisaverLogger');
export const DefisaverLoggerContract = createContractFromConfigFunc('DefisaverLogger');

export const AllowanceProxyAddress = getConfigContractAddress('AllowanceProxy');
export const AllowanceProxyContract = createContractFromConfigFunc('AllowanceProxy');

export const AaveLoanInfoAddress = getConfigContractAddress('AaveLoanInfo');
export const AaveLoanInfoContract = createContractFromConfigFunc('AaveLoanInfo');

export const AaveImportTakerAddress = getConfigContractAddress('AaveImportTaker');

export const AaveImportAddress = getConfigContractAddress('AaveImport');

export const PricesAddress = getConfigContractAddress('Prices');
export const PricesContract = createContractFromConfigFunc('Prices');

export const DFSPricesAddress = getConfigContractAddress('DFSPrices');
export const DFSPricesContract = createContractFromConfigFunc('DFSPrices');

export const CompoundCreateReceiverAddress = getConfigContractAddress('CompoundCreateReceiver');
export const CompoundCreateReceiverContract = createContractFromConfigFunc('CompoundCreateReceiver');

export const CompoundCreateTakerAddress = getConfigContractAddress('CompoundCreateTaker');
export const CompoundCreateTakerContract = createContractFromConfigFunc('CompoundCreateTaker');

export const AaveSubscriptionsV2Address = getConfigContractAddress('AaveSubscriptionsV2');
export const AaveSubscriptionsV2Contract = createContractFromConfigFunc('AaveSubscriptionsV2');

export const AaveSubscriptionsV2ProxyAddress = getConfigContractAddress('AaveSubscriptionsV2Proxy');
export const AaveSubscriptionsV2ProxyContract = createContractFromConfigFunc('AaveSubscriptionsProxy');

export const AaveSaverTakerAddress = getConfigContractAddress('AaveSaverTaker');
export const AaveSaverTakerContract = createContractFromConfigFunc('AaveSaverTaker');

export const MulticallAddress = getConfigContractAddress('Multicall');
export const MulticallContract = createContractFromConfigFunc('Multicall');

export const UniMulticallAddress = getConfigContractAddress('UniMulticall');
export const UniMulticallContract = createContractFromConfigFunc('UniMulticall');

export const LoanShifterReceiverAddress = getConfigContractAddress('LoanShifterReceiver');
export const LoanShifterReceiverContract = createContractFromConfigFunc('LoanShifterReceiver');

export const McdCreateFlashLoanAddress = getConfigContractAddress('McdCreateFlashLoan');
export const McdCreateFlashLoanContract = createContractFromConfigFunc('McdCreateFlashLoan');

export const LendToAaveMigratorAddress = getConfigContractAddress('LendToAaveMigrator');
export const LendToAaveMigratorContract = createContractFromConfigFunc('LendToAaveMigrator');

export const AAVEContract = createContractFromConfigFunc('AAVE');
export const stkAAVEContract = createContractFromConfigFunc('stkAAVE');

export const LendingPoolAddressesProviderAddress = getConfigContractAddress('LendingPoolAddressesProvider');
export const LendingPoolAddressesProviderContract = createContractFromConfigFunc('LendingPoolAddressesProvider');

export const LendingPoolAddressesProviderRegistryAddress = getConfigContractAddress('LendingPoolAddressesProviderRegistry');
export const LendingPoolAddressesProviderRegistryContract = createContractFromConfigFunc('LendingPoolAddressesProviderRegistry');

export const AaveLoanInfoV2Address = getConfigContractAddress('AaveLoanInfoV2');
export const AaveLoanInfoV2Contract = createContractFromConfigFunc('AaveLoanInfoV2');

export const AaveBasicProxyV2Address = getConfigContractAddress('AaveBasicProxyV2');
export const AaveBasicProxyV2Contract = createContractFromConfigFunc('AaveBasicProxyV2');

export const AaveLendingPoolV2Address = getConfigContractAddress('AaveLendingPoolV2');
export const AaveLendingPoolV2Contract = createContractFromConfigFunc('AaveLendingPoolV2');

export const AaveImportV2Address = getConfigContractAddress('AaveImportV2');

export const AaveImportTakerV2Address = getConfigContractAddress('AaveImportTakerV2');

export const AaveWETHAdapterAddress = getConfigContractAddress('AaveWETHAdapter');
export const AaveWETHAdapterContract = createContractFromConfigFunc('AaveWETHAdapter');

export const WETHIStableDebtTokenAddress = getConfigContractAddress('WETHIStableDebtToken');
export const WETHIStableDebtTokenContract = createContractFromConfigFunc('WETHIStableDebtToken');

export const WETHIVariableDebtTokenAddress = getConfigContractAddress('WETHIVariableDebtToken');
export const WETHIVariableDebtTokenContract = createContractFromConfigFunc('WETHIVariableDebtToken');

export const AaveProtocolDataProviderAddress = getConfigContractAddress('AaveProtocolDataProvider');
export const AaveProtocolDataProviderContract = createContractFromConfigFunc('AaveProtocolDataProvider');

export const InstaDSAResolverAddress = getConfigContractAddress('InstaDSAResolver');
export const InstaDSAResolverContract = createContractFromConfigFunc('InstaDSAResolver');

export const DaiJoinAddress = getConfigContractAddress('DaiJoin');

export const BCdpManagerAddress = getConfigContractAddress('BCdpManager');
export const BCdpManagerContract = createContractFromConfigFunc('BCdpManager');

export const RaiLoanInfoAddress = getConfigContractAddress('RaiLoanInfo');
export const RaiLoanInfoContract = createContractFromConfigFunc('RaiLoanInfo');

export const VaultInfoAddress = getConfigContractAddress('VaultInfo');
export const VaultInfoContract = createContractFromConfigFunc('VaultInfo');

export const AaveIncentivesControllerAddress = getConfigContractAddress('AaveIncentivesController');
export const AaveIncentivesControllerContract = createContractFromConfigFunc('AaveIncentivesController');

export const AaveMigrationTakerAddress = getConfigContractAddress('AaveMigrationTaker');
export const AaveMigrationTakerContract = createContractFromConfigFunc('AaveMigrationTaker');

export const AaveMigrationReceiverAddress = getConfigContractAddress('AaveMigrationReceiver');
export const AaveMigrationReceiverContract = createContractFromConfigFunc('AaveMigrationReceiver');

export const AuthCheckAddress = getConfigContractAddress('AuthCheck');
export const AuthCheckContract = createContractFromConfigFunc('AuthCheck');

export const LiquityBorrowerOperationsAddress = getConfigContractAddress('BorrowerOperations');
export const LiquityBorrowerOperationsContract = createContractFromConfigFunc('BorrowerOperations');

export const LiquitySortedTrovesAddress = getConfigContractAddress('SortedTroves');
export const LiquitySortedTrovesContract = createContractFromConfigFunc('SortedTroves');

export const LiquityTroveManagerAddress = getConfigContractAddress('TroveManager');
export const LiquityTroveManagerContract = createContractFromConfigFunc('TroveManager');

export const LiquityHintHelpersAddress = getConfigContractAddress('HintHelpers');
export const LiquityHintHelpersContract = createContractFromConfigFunc('HintHelpers');

export const LiquityPriceFeedAddress = getConfigContractAddress('PriceFeed');
export const LiquityPriceFeedContract = createContractFromConfigFunc('PriceFeed');

export const LiquityViewAddress = getConfigContractAddress('LiquityView');
export const LiquityViewContract = createContractFromConfigFunc('LiquityView');

export const LiquityCollSurplusPoolAddress = getConfigContractAddress('CollSurplusPool');
export const LiquityCollSurplusPoolContract = createContractFromConfigFunc('CollSurplusPool');

export const LiquityLQTYStakingAddress = getConfigContractAddress('LQTYStaking');
export const LiquityLQTYStakingContract = createContractFromConfigFunc('LQTYStaking');

export const LiquityStabilityPoolAddress = getConfigContractAddress('StabilityPool');
export const LiquityStabilityPoolContract = createContractFromConfigFunc('StabilityPool');

export const UniV3NonfungiblePositionManagerAddress = getConfigContractAddress('UniV3NonfungiblePositionManager');
export const UniV3NonfungiblePositionManagerContract = createContractFromConfigFunc('UniV3NonfungiblePositionManager');

export const UniV3FactoryAddress = getConfigContractAddress('UniV3Factory');
export const UniV3FactoryContract = createContractFromConfigFunc('UniV3Factory');

export const YearnRegistryAddress = getConfigContractAddress('YearnRegistry');
export const YearnRegistryContract = createContractFromConfigFunc('YearnRegistry');

export const CurveViewAddress = getConfigContractAddress('CurveView');
export const CurveViewContract = createContractFromConfigFunc('CurveView');

export const BalancerViewAddress = getConfigContractAddress('BalancerView');
export const BalancerViewContract = createContractFromConfigFunc('BalancerView');

export const SushiViewAddress = getConfigContractAddress('SushiView');
export const SushiViewContract = createContractFromConfigFunc('SushiView');

export const UniswapV2ViewAddress = getConfigContractAddress('UniswapV2View');
export const UniswapV2ViewContract = createContractFromConfigFunc('UniswapV2View');

export const Erc20ViewAddress = getConfigContractAddress('Erc20View');
export const Erc20ViewContract = createContractFromConfigFunc('Erc20View');

export const MakerFlashLoanInfoAddress = getConfigContractAddress('MakerFlashLoanInfo');
export const MakerFlashLoanInfoContract = createContractFromConfigFunc('MakerFlashLoanInfo');

export const wstETHAddress = getConfigContractAddress('wstETH');
export const wstETHContract = createContractFromConfigFunc('wstETH');

export const RariFundManagerDaiAddress = getConfigContractAddress('RariFundManagerDai');
export const RariFundTokenDaiAddress = getConfigContractAddress('RariFundTokenDai');
export const RariFundProxyDaiAddress = getConfigContractAddress('RariFundProxyDai');
export const RariFundControllerDaiAddress = getConfigContractAddress('RariFundControllerDai');

export const RariFundManagerUsdcAddress = getConfigContractAddress('RariFundManagerUsdc');
export const RariFundTokenUsdcAddress = getConfigContractAddress('RariFundTokenUsdc');
export const RariFundProxyUsdcAddress = getConfigContractAddress('RariFundProxyUsdc');
export const RariFundControllerUsdcAddress = getConfigContractAddress('RariFundControllerUsdc');

export const MStableImUsdTokenAddress = getConfigContractAddress('MStableImUsdToken');
export const MStableImUsdTokenContract = createContractFromConfigFunc('MStableImUsdToken');

export const MStableImUsdVaultAddress = getConfigContractAddress('MStableImUsdVault');
export const MStableImUsdVaultContract = createContractFromConfigFunc('MStableImUsdVault');

export const MStableMUsdTokenAddress = getConfigContractAddress('MStableMUsdToken');
export const MStableMUsdTokenContract = createContractFromConfigFunc('MStableMUsdToken');

export const RariViewAddress = getConfigContractAddress('RariView');
export const RariViewContract = createContractFromConfigFunc('RariView');

export const YearnViewAddress = getConfigContractAddress('YearnView');
export const YearnViewContract = createContractFromConfigFunc('YearnView');

export const SubProxyAddress = getConfigContractAddress('SubProxy');
export const SubProxyContract = createContractFromConfigFunc('SubProxy');

export const SubStorageAddress = getConfigContractAddress('SubStorage');
export const SubStorageContract = createContractFromConfigFunc('SubStorage');

export const CdpRegistryAddress = getConfigContractAddress('CdpRegistry');
export const CdpRegistryContract = createContractFromConfigFunc('CdpRegistry');

export const CurveEthStethMinterAddress = getConfigContractAddress('CurveEthStethMinter');
export const CurveEthStethMinterContract = createContractFromConfigFunc('CurveEthStethMinter');

export const ChainlinkFeedRegistryAddress = getConfigContractAddress('ChainlinkFeedRegistry');
export const ChainlinkFeedRegistryContract = createContractFromConfigFunc('ChainlinkFeedRegistry');

export const LidoOracleAddress = getConfigContractAddress('LidoOracle');
export const LidoOracleContract = createContractFromConfigFunc('LidoOracle');

export const CropperAddress = getConfigContractAddress('Cropper');

export const AaveV3PoolAddressesProviderL2Address = getConfigContractAddress('AaveV3PoolAddressesProviderL2');
export const AaveV3PoolAddressesProviderL2Contract = createContractFromConfigFunc('AaveV3PoolAddressesProviderL2');

export const AaveV3LendingPoolL2Address = getConfigContractAddress('AaveV3LendingPoolL2');
export const AaveV3LendingPoolL2Contract = createContractFromConfigFunc('AaveV3LendingPoolL2');

export const AaveV3ProtocolDataProviderL2Address = getConfigContractAddress('AaveV3ProtocolDataProviderL2');
export const AaveV3ProtocolDataProviderL2Contract = createContractFromConfigFunc('AaveV3ProtocolDataProviderL2');

export const AaveV3ViewL2Address = getConfigContractAddress('AaveV3ViewL2');
export const AaveV3ViewL2Contract = createContractFromConfigFunc('AaveV3ViewL2');

export const AaveV3WETHAdapterL2Address = getConfigContractAddress('AaveV3WETHAdapterL2');
export const AaveV3WETHAdapterL2Contract = createContractFromConfigFunc('AaveV3WETHAdapterL2');

export const AaveV3WETHStableDebtTokenL2Address = getConfigContractAddress('AaveV3WETHStableDebtTokenL2');
export const AaveV3WETHStableDebtTokenL2Contract = createContractFromConfigFunc('AaveV3WETHStableDebtTokenL2');

export const AaveV3WETHVariableDebtTokenL2Address = getConfigContractAddress('AaveV3WETHVariableDebtTokenL2');
export const AaveV3WETHVariableDebtTokenL2Contract = createContractFromConfigFunc('AaveV3WETHVariableDebtTokenL2');

export const OVMGasPriceOracleAddress = getConfigContractAddress('OVMGasPriceOracle', 10);
export const OVMGasPriceOracleContract = createContractFromConfigFunc('OVMGasPriceOracle');


// Non standard contract getters
export const getErc20Contract = (address, networkId = selectedNetwork) => new window._web3Object[networkId].eth.Contract(config.Erc20.abi, address);

export const getInstaAccount = address => new window._web3.eth.Contract(config.InstaAccount.abi, address);

export const getInstaV2Account = address => new window._web3.eth.Contract(config.InstaAccountV2.abi, address);

export const getUniV3PoolContract = address => new window._web3.eth.Contract(config.UniV3Pool.abi, address);

export const getGuniPoolContract = address => new window._web3.eth.Contract(config.GuniPool.abi, address);

export const getCompoundAssetContract = (assetObject) => {
  let { abi } = config.CompoundAsset;

  if (assetObject.symbol === 'cETH') ({ abi } = config.cETH);
  else if (assetObject.symbol === 'cDAI') ({ abi } = config.cDAI);

  return new window._web3.eth.Contract(abi, assetObject.address);
};

export const getAaveAssetContract = (assetObject) => {
  const { abi } = config.AaveAsset;
  return new window._web3.eth.Contract(abi, assetObject.address);
};
