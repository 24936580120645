import React from 'react';
import PropTypes from 'prop-types';
import { getAssetInfo } from '@defisaver/tokens';
import DataItem from '../../../../DataItem/DataItem';
import './AssetContainer.scss';

const AssetContainer = ({ assetSymbol, assetAmount, additionalClasses }) => {
  const asset = getAssetInfo(assetSymbol);
  const Icon = asset.icon;

  return (
    <div className={`Flex FlexAlignCenter asset-container ${additionalClasses}`}>
      <Icon width={30} height={30} />
      <DataItem
        value={assetAmount}
        symbol={asset.symbol}
        smallSymbol
        type="standard-smaller"
        additionalClasses="asset-container__data-item"
      />
    </div>
  );
};

AssetContainer.propTypes = {
  assetSymbol: PropTypes.string.isRequired,
  assetAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  additionalClasses: PropTypes.string,
};
export default AssetContainer;
