export const LQTY_GET_TROVE_REQUEST = 'LQTY_GET_TROVE_REQUEST';
export const LQTY_GET_TROVE_SUCCESS = 'LQTY_GET_TROVE_SUCCESS';
export const LQTY_GET_TROVE_FAILURE = 'LQTY_GET_TROVE_FAILURE';

export const LQTY_GET_REDEMPTION_RISK_REQUEST = 'LQTY_GET_REDEMPTION_RISK_REQUEST';
export const LQTY_GET_REDEMPTION_RISK_SUCCESS = 'LQTY_GET_REDEMPTION_RISK_SUCCESS';
export const LQTY_GET_REDEMPTION_RISK_FAILURE = 'LQTY_GET_REDEMPTION_RISK_FAILURE';

export const LQTY_GET_INIT_DATA_REQUEST = 'LQTY_GET_INIT_DATA_REQUEST';
export const LQTY_GET_INIT_DATA_SUCCESS = 'LQTY_GET_INIT_DATA_SUCCESS';
