import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './modals.scss';
import './ActionModal.scss';

class Modal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { children: null, show: null };

    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.closeModal);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (!newProps.modalOpen && (this.state.children !== null)) {
      this.setState({ show: null });
      return setTimeout(() => { this.setState({ children: null }); }, 500);
    }

    if (newProps.modalOpen) { this.setState({ show: true }); }

    return this.setState({ children: newProps.children });
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.closeModal);
  }

  closeModal(event) {
    if (!this.props.closable) return;
    if (!event || event.key === 'Escape') {
      this.props.closeModal();
    }
  }

  render() {
    return (
      <div
        className={`modal-backdrop ${this.state.show ? 'open' : ''} ${this.props.className}`}
        role="button"
        tabIndex={0}
        onClick={() => this.closeModal()}
      >
        <div className="modal-scroll-container">
          <div className="modal-wrapper" style={{ maxWidth: this.props.width }}>
            <div
              className="modal"
              onClick={(e) => { e.stopPropagation(); }}
            >
              { (this.state.children !== null) && this.state.children }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Modal.defaultProps = {
  width: 'auto',
  className: '',
};

Modal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  closable: PropTypes.bool.isRequired,
};

export default Modal;
