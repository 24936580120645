/* eslint react/button-has-type: 0 */
import React, { useEffect, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import t from 'translate';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import debounceFunc from 'lodash/debounce';
import { ACCOUNT_TYPES } from '../../constants/general';
import TooltipWrapper from '../TooltipWrapper/TooltipWrapper';
import InputComponent from '../Forms/InputComponent';
import { formatNumber, notGreaterThan } from '../../services/utils';
import InfoBox from '../Decorative/InfoBox/InfoBox';
import ActionItemSingleSelectBlocker from './ActionItemSingleSelectBlocker';
import { openLoginModal } from '../../actions/modalActions';
import HintBox from '../Decorative/HintBox/HintBox';
import ErrorBox from '../Decorative/ErrorBox/ErrorBox';

/* eslint-disable react/prop-types */
const ValueContainer = props => {
  const value = props?.getValue()?.[0];
  const Icon = value?.meta?.icon;
  const secondLabel = props.secondLabel || props.label || value?.additionalLabel;
  return (
    <>
      {Icon && (<div className="icon-wrapper"><Icon height={30} width={30} /></div>)}
      <div className="labels-wrapper">
        {secondLabel && <span className="select-label">{secondLabel}</span>}
        <components.ValueContainer {...props} />
      </div>
    </>
  );
};
/* eslint-disable react/prop-types */

const OptionContainer = props => (
  <components.Option {...props} className="Flex">
    {props.data?.meta?.icon && <div className="icon-wrapper"><props.data.meta.icon height={30} width={30} /></div>}
    <div>
      <div className="option-value">{props.data.label}</div>
      {props.data.additionalLabel && <span className="select-label">{props.data.additionalLabel}</span>}
    </div>
  </components.Option>
);

OptionContainer.propTypes = {
  data: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    interestMode: PropTypes.string,
    meta: PropTypes.object,
    additionalLabel: PropTypes.string,
  }).isRequired,
};

const ActionItem = ({
  disabled,
  actionExecuting,
  setValToMax,
  maxVal,
  minVal,
  gettingMaxVal,
  afterType,
  type,
  setAfterValue,
  executingLabel,
  toExecuteLabel,
  showLabel,
  info,
  name,
  id,
  symbol,
  executeAction,
  errorText,
  noMax,
  mobileShow,
  buttonType,
  additionalClass,
  hasMigrateButton,
  showErrorText,
  setFieldToBeFocused,
  thirdLabelText,
  selectOptions,
  selectLabel,
  selectValue,
  selectValueOnChange,
  secondSelectOptions,
  secondSelectLabel,
  secondSelectValue,
  secondSelectValueOnChange,
  buttonTooltip,
  requireSelect,
  requireSecondSelect,
  buttonAfterText,
  allowOverMax,
  debounce,
  accountType,
  openLoginModal,
  secondSelectSecondLabel,
  selectSecondLabel,
  actionDesc,
  hintDescription,
  showErrorsBelow,
  reverseInputs,
  selectHasIcon,
  isSelectSearchable,
  readOnly,
  shouldPortalSelect,
}) => {
  const hasSelect = selectOptions.length > 0;
  const hasSecondSelect = secondSelectOptions.length > 0;
  const requireFirstAndSecondSelect = requireSelect && requireSecondSelect;
  useEffect(() => selectValueOnChange(selectOptions[0], true), [selectOptions.map(a => a.label).join()]);
  useEffect(() => secondSelectValueOnChange(secondSelectOptions[0], true), [secondSelectOptions.map(a => a.label).join()]);

  const additional = { min: minVal };
  let normalizeFunc = val => val;

  if (!noMax) additional.max = maxVal;
  if (!noMax && !allowOverMax) normalizeFunc = val => notGreaterThan(val, maxVal);

  const _id = useMemo(() => `${id}-${Math.floor(Math.random() * 10000)}`, []);

  const setAfterValueDebounced = useCallback(
    debounce ? debounceFunc(setAfterValue, 400) : setAfterValue,
    [debounce],
  );

  // DEV: https://github.com/JedWatson/react-select/issues/2810#issuecomment-569117980
  const SelectValueContainer = useMemo(() => props => <ValueContainer label={selectLabel} secondLabel={selectSecondLabel} {...props} />, [selectLabel, selectSecondLabel]);
  const SecondSelectValueContainer = useMemo(() => props => <ValueContainer label={secondSelectLabel} secondLabel={secondSelectSecondLabel} {...props} />, [secondSelectLabel, secondSelectSecondLabel]);

  return (
    <div className={`item ${type} ${mobileShow ? 'show' : ''} ${requireSelect || hasSelect ? 'select-item' : ''} ${actionDesc ? 'has-description' : ''} ${reverseInputs ? 'reverse' : ''} ${selectHasIcon ? 'withIcon' : ''} ${!symbol && !thirdLabelText ? 'no-label' : ''}`}>
      <div className="select-input-max-wrapper">
        <div className="action-items-above-wrapper">
          {
            actionDesc && (
              <div className="action-desc">{actionDesc}</div>
            )
          }
          {!noMax && (
            <div
              className={`max-wrapper ${actionExecuting ? 'loading' : ''}`}
              onClick={() => {
                if (!actionExecuting) setValToMax(selectValue, secondSelectValue);
              }}
            >
              <TooltipWrapper title={gettingMaxVal ? t('common.loading') : maxVal}>
                {
                  gettingMaxVal ?
                    t('common.loading') :
                    `(${t('common.max_val', { '%value': formatNumber(maxVal, 2) })}${symbol ? ` ${symbol}` : ''})`
                }
              </TooltipWrapper>
            </div>
          )}
        </div>

        <div className="select-input-wrapper">
          <Field
            id={_id}
            type="number"
            wrapperClassName={`form-item-wrapper ${type} ${afterType === type ? 'active' : ''} ${additionalClass}`}
            name={name}
            onChange={(e) => {
              const normVal = normalizeFunc(e.target.value);

              if (!normVal || parseFloat(normVal) <= 0) {
                setAfterValueDebounced(0, type);
                return;
              }

              if (noMax) setAfterValueDebounced(normVal, type, selectValue, secondSelectValue);
              if ((!noMax && (parseFloat(normVal) <= maxVal)) || allowOverMax) setAfterValueDebounced(normVal, type, selectValue, secondSelectValue);
            }}
            labelText={(showLabel && toExecuteLabel) ? `${toExecuteLabel}:` : ''}
            secondLabelText={symbol}
            thirdLabelText={thirdLabelText}
            placeholder="0"
            normalize={normalizeFunc}
            showErrorText={showErrorText}
            additional={additional}
            disabled={actionExecuting || readOnly}
            component={InputComponent}
            setFieldToBeFocused={setFieldToBeFocused}
          />

          { !requireSecondSelect && !hasSelect && requireSelect && (<ActionItemSingleSelectBlocker />) }

          {
            requireFirstAndSecondSelect && !hasSelect && requireSelect && (
              <ActionItemSingleSelectBlocker additionalClass="small" label={selectLabel} />
            )
          }

          {
            hasSelect && (
              <Select
                className={`select box ${selectOptions.length === 1 ? 'oneOption' : ''}`}
                classNamePrefix="select"
                value={selectValue}
                onChange={(val) => selectValueOnChange(val, false)}
                options={selectOptions}
                onBlur={event => event.preventDefault()}
                isSearchable={isSelectSearchable}
                components={{
                  ValueContainer: SelectValueContainer,
                  Option: OptionContainer,
                }}
                isDisabled={actionExecuting || selectOptions.length === 1}
                menuPortalTarget={shouldPortalSelect ? document.getElementById('select-portal') : null}
              />
            )
          }

          {
            hasSecondSelect && (
              <Select
                className={`select box ${secondSelectOptions.length === 1 ? 'oneOption' : ''}`}
                classNamePrefix="select"
                value={secondSelectValue}
                onChange={(val) => secondSelectValueOnChange(val, false)}
                options={secondSelectOptions}
                onBlur={event => event.preventDefault()}
                isSearchable={isSelectSearchable}
                components={{
                  ValueContainer: SecondSelectValueContainer,
                  Option: OptionContainer,
                }}
                isDisabled={actionExecuting || secondSelectOptions.length === 1}
                menuPortalTarget={shouldPortalSelect ? document.getElementById('select-portal') : null}
              />
            )
          }

          {
            requireFirstAndSecondSelect && !hasSecondSelect && requireSecondSelect && (
              <ActionItemSingleSelectBlocker additionalClass="small" label={secondSelectLabel} />
            )
          }
        </div>
      </div>

      <div className="item-button-wrapper">
        {info && <InfoBox message={info} />}

        <TooltipWrapper title={buttonTooltip || errorText} disabled={!disabled && !buttonTooltip}>
          <button
            type={buttonType}
            className={`button ${hasMigrateButton ? 'migrate' : ''}`}
            onClick={() => (
              accountType !== ACCOUNT_TYPES.viewOnly ?
                executeAction() :
                openLoginModal())}
            disabled={disabled}
            data-after-text={buttonAfterText}
          >
            <span>{ actionExecuting ? executingLabel : toExecuteLabel }</span>
          </button>
        </TooltipWrapper>
      </div>
      {showErrorsBelow && <ErrorBox>{showErrorsBelow}</ErrorBox>}
      {hintDescription && <HintBox>{hintDescription}</HintBox>}
    </div>
  );
};

ActionItem.defaultProps = {
  disabled: false,
  errorText: '',
  noMax: false,
  mobileShow: true,
  buttonType: 'button',
  additionalClass: '',
  symbol: '',
  showErrorText: false,
  setFieldToBeFocused: () => {},
  thirdLabelText: '',
  minVal: 0,
  selectOptions: [],
  secondSelectOptions: [],
  selectValue: null,
  secondSelectValue: null,
  selectLabel: '',
  secondSelectLabel: '',
  buttonTooltip: '',
  setAfterValue: () => {},
  selectValueOnChange: () => {},
  secondSelectValueOnChange: () => {},
  executeAction: () => {},
  requireSelect: false,
  requireSecondSelect: false,
  buttonAfterText: '',
  allowOverMax: false,
  debounce: false,
  secondSelectSecondLabel: '',
  selectSecondLabel: '',
  actionDesc: '',
  hintDescription: '',
  showErrorsBelow: '',
  reverseInputs: false,
  selectHasIcon: false,
  isSelectSearchable: true,
  info: '',
  type: '',
  afterType: '',
  executingLabel: '',
  toExecuteLabel: '',
  showLabel: true,
  readOnly: false,
  hasMigrateButton: false,
  shouldPortalSelect: false,
};

ActionItem.propTypes = {
  actionExecuting: PropTypes.bool.isRequired,
  setValToMax: PropTypes.func.isRequired,
  maxVal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  gettingMaxVal: PropTypes.bool.isRequired,
  afterType: PropTypes.string,
  type: PropTypes.string,
  setAfterValue: PropTypes.func,
  showLabel: PropTypes.bool,
  executingLabel: PropTypes.string,
  toExecuteLabel: PropTypes.string,
  info: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  symbol: PropTypes.string,
  executeAction: PropTypes.func,
  disabled: PropTypes.bool,
  noMax: PropTypes.bool,
  allowOverMax: PropTypes.bool,
  errorText: PropTypes.string,
  mobileShow: PropTypes.bool,
  buttonType: PropTypes.string,
  additionalClass: PropTypes.string,
  showErrorText: PropTypes.bool,
  setFieldToBeFocused: PropTypes.func,
  thirdLabelText: PropTypes.string,
  minVal: PropTypes.number,
  selectOptions: PropTypes.array,
  secondSelectOptions: PropTypes.array,
  selectValue: PropTypes.object,
  secondSelectValue: PropTypes.object,
  selectLabel: PropTypes.string,
  secondSelectLabel: PropTypes.string,
  selectValueOnChange: PropTypes.func,
  secondSelectValueOnChange: PropTypes.func,
  buttonTooltip: PropTypes.string,
  requireSelect: PropTypes.bool,
  requireSecondSelect: PropTypes.bool,
  buttonAfterText: PropTypes.string,
  debounce: PropTypes.bool,
  accountType: PropTypes.string.isRequired,
  openLoginModal: PropTypes.func.isRequired,
  selectSecondLabel: PropTypes.string,
  secondSelectSecondLabel: PropTypes.string,
  actionDesc: PropTypes.string,
  hintDescription: PropTypes.string,
  showErrorsBelow: PropTypes.string,
  reverseInputs: PropTypes.bool,
  selectHasIcon: PropTypes.bool,
  isSelectSearchable: PropTypes.bool,
  readOnly: PropTypes.bool,
  hasMigrateButton: PropTypes.bool,
  shouldPortalSelect: PropTypes.bool,
};

const mapStateToProps = ({ general }) => ({
  accountType: general.accountType,
});

const mapDispatchToProps = {
  openLoginModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionItem);
