/* eslint-disable */

export default {
  common: {
    loading: 'Loading...',
    loading_no_dots: 'Loading',
    load: 'Load',
    loading_asset_data: 'Loading asset data...',
    loading_positions: 'Loading positions...',
    create: 'Create',
    creating: 'Creating',
    generate: 'Generate',
    generating: 'Generating',
    connect: 'Connect',
    connecting: 'Connecting',
    connected: 'Connected',
    buy: 'Buy',
    buying: 'Buying',
    enable: 'Enable',
    enabling: 'Enabling',
    exchange: 'Exchange',
    exchanging: 'Exchanging',
    supply: 'Supply',
    supplying: 'Supplying',
    borrow: 'Borrow',
    borrowing: 'Borrowing',
    borrow_desc: 'Borrowing lets you deposit and borrow funds in one transaction. All borrowed funds are sent to your account.',
    supplied: 'Supplied',
    withdraw: 'Withdraw',
    withdrawing: 'Withdrawing',
    transfer: 'Transfer',
    transferring: 'Transferring',
    boost: 'Boost',
    boosting: 'Boosting',
    repay: 'Repay',
    repaying: 'Repaying',
    repay_from: 'Repay from:',
    payback: 'Payback',
    pay_back: 'Pay back',
    keep_ratio: 'Keep ratio',
    sending: 'Sending',
    send: 'Send',
    sent: 'Sent',
    subscribe: 'Subscribe',
    subscribing: 'Subscribing',
    paying_back: 'Paying back',
    add_collateral: 'Add collateral',
    adding_collateral: 'Adding collateral',
    migrate: 'Migrate',
    migrating: 'Migrating',
    move: 'Move',
    moving: 'Moving',
    after: 'After',
    before: 'Before',
    manage: 'Manage',
    success: 'Success!',
    coming_soon: '%feature coming soon...',
    short: 'Short',
    leverage: 'Leverage',
    leverage_desc: 'Leveraging lets you create a leveraged position in one transaction. All borrowed funds are used to buy more of the used collateral asset.',
    in_a_week: 'In a week',
    in_a_month: 'In a month',
    in_a_year: 'In a year',
    farm: 'Farm',
    farm_desc: 'Farming lets you create a same asset leveraged position that maximizes rewards received from the protocol.',
    price: 'Price',
    collateral_factor: 'Collateral factor',
    collateral_factor_short: 'Coll. fac.',
    market_liquidity: 'Market liquidity',
    utilization: 'Utilization',
    utilization_short: 'Util.',
    utilization_desc: 'Percentage of market supply utilized by borrowers',
    market_size: 'Market size',
    market_size_short: 'Mar. size',
    market_info: 'Market info',
    insured: 'Insured:',
    supply_rate: 'Supply rate:',
    borrow_rate: 'Borrow rate:',
    choose_asset: 'Choose asset:',
    stability_fee: 'Stability Fee',
    liquidation_price: 'Liquidation price',
    liquidation_price_short: 'Liq price:',
    trade_size_impact: 'Trade size price impact',
    trade_size_impact_info: 'The difference between current market price and estimated price due to trade size.',
    slippage_tolerance_info: 'Your transaction will revert if the price changes unfavourably by more than this percentage.',
    from_source_info: 'Choose from which balance should asset come',
    to_source_info: 'Choose to which balance should asset be sent',
    buying_asset_at: 'Buying %asset at',
    action: 'Action',
    overview: 'Overview',
    claimable: 'Claimable',
    ratio: 'Ratio',
    pages: 'Pages:',
    choose: 'Choose',
    debt: 'Debt',
    account_type: 'Account type:',
    path: 'Path:',
    minimum_amount: 'Minimum amount:',
    from_token: 'From %token',
    to_token: 'To %token',
    to: 'To',
    from: 'From',
    amount: 'Amount',
    name: 'Name',
    balance: 'Balance',
    current_balance: 'Current balance',
    approve: 'Approve',
    approving: 'Approving',
    approved: 'Approved',
    remove_approval: 'Remove approval',
    removing_approval_on: 'Removing approval on',
    close: 'Close',
    closing: 'Closing',
    logging_in: 'Logging in...',
    safe: 'Safe',
    risky: 'Risky',
    gamble: 'Gamble',
    amount_of_token: 'Amount of %token:',
    token_amount_in: '%first_token amount in %second_token:',
    executing_action: 'Executing action',
    new_owner: 'New owner',
    contact_us: 'Contact us',
    with: 'with',
    at_price: 'at price',
    automate: 'Automate',
    reminder: 'Reminder',
    info: 'Info',
    legacy: 'Legacy',
    live: 'Live',
    custom_type: 'Custom type',
    max: 'max',
    min: 'min',
    max_val: 'max %value',
    min_val: 'min %value',
    email: 'Email',
    email_address: 'Email address',
    follow_us: 'Follow us:',
    protocols: 'Protocols',
    wallets: 'Wallets',
    repay_to: 'Repay to',
    ratio_over: 'Ratio over',
    boost_to: 'Boost to',
    current_price: 'Current price',
    collateral: 'Collateral',
    collateral_in: 'Collateral in',
    set_up: 'Set up',
    how_does_it_work: 'How does it work?',
    history: 'History',
    type: 'Type',
    date: 'Date',
    transaction: 'Transaction',
    load_more: 'Load more',
    protection: 'Protection',
    update: 'Update',
    updating: 'Updating',
    disable: 'Disable',
    disabling: 'Disabling',
    advanced: 'Advanced',
    cancel: 'Cancel',
    message: 'Message',
    accept: 'Accept',
    import: 'Import',
    importing: 'Importing',
    soon: 'soon',
    no_notifications_received: 'No notifications received.',
    go_back_to_dashboard: 'Go back to dashboard',
    back_to_dashboard: 'Back to dashboard',
    your_feedback_helps_us: 'Your feedback is delivered directly to the team and helps us improve DeFi Saver.',
    create_dsproxy: 'Create DSProxy',
    for: 'for',
    account: 'account',
    ds_proxy: 'DSProxy',
    upgrade: 'Upgrade',
    upgrading: 'Upgrading',
    migration: 'Migration',
    your_asset_balance: 'Your %asset balance',
    ratio_after: 'Ratio after',
    convert: 'Convert',
    converting: 'Converting',
    back: 'Back',
    next: 'Next',
    borrowed: 'Borrowed',
    select: 'Select',
    selected: 'Selected',
    subscribed: 'Subscribed',
    confirm: 'Confirm',
    continue: 'Continue',
    next_price: 'Next Price',
    next_price_explanation: 'MakerDAO will update to the next price after %timestamp \nNext price: $%price\nNext ratio: %ratio%',
    settings: 'Settings',
    required: 'Required',
    received: 'Received',
    exposure: 'Exposure',
    borrowed_asset: 'Borrowed %token',
    set_up_later: 'Set up later',
    claim: 'Claim',
    claiming: 'Claiming',
    bid: 'Bid',
    bidding: 'Bidding',
    insufficient_balance: 'Insufficient balance',
    empty: 'Empty',
    learn_more: 'Learn more',
    warning: 'Warning',
    token_swap: 'Token Swaps',
    asset: 'Asset',
    earned: 'Earned',
    limit: 'Limit',
    total: 'Total',
    supply_apy: 'Supply APY',
    comp_supply_apy: 'COMP Supply APY',
    supply_apy_short: 'Sup. APY',
    borrow_apy:  'Borrow APY',
    comp_borrow_apy: 'COMP Borrow APY',
    borrow_apy_short:  'Bor. APY',
    stats: 'Stats',
    address: 'Address',
    in_total: 'In total',
    contract: 'contract',
    notifications: 'Notifications',
    safety_ratio: 'Safety ratio',
    safety_ratio_desc: 'The ratio of your borrow limit versus borrowed funds. \nLiquidation happens below liqPercent%.',
    deposit: 'Deposit',
    automation: 'Automation',
    wallet_type: 'Wallet Type',
    account_capital: 'Account',
    enabled: 'Enabled',
    disabled: 'Disabled',
    total_balance: 'Total balance',
    unavailable: 'Unavailable',
    large_tsi_warning: 'You will get an unfavorable price due to the trade size (%tsi% below market price). Consider using a smaller amount.',
    large_tsi_warning_exact_amount: 'You will get an unfavorable price due to the trade size (%tsi% below market price).',
    accrued: 'Accrued',
    protocol: 'Protocol',
    swap: 'Swap',
    swapping: 'Swapping',
    leveraging: 'Leveraging',
    liquidation: 'Liquidation',
    locked: 'Locked',
    unlocked: 'Unlocked',
    unlock: 'Unlock',
    liquidation_price_desc: 'Collateral price below which your position will face liquidation.',
    leveraged_long_position_desc: 'Your position is currently long %asset and will benefit from %asset going up in value.',
    leveraged_short_position_desc: 'Your position is currently short %asset and will benefit from %asset going down in value.',
    create_and_migrate: 'Create and migrate',
    received_asset: 'Received %asset',
    debt_available_from: '%debtAsset available from %asset',
    debt_available_from_desc: 'Amount of %debtAsset available to be generated from the used collateral type according to the global protocol debt ceiling',
    add_liquidity: 'Add liquidity',
    cancel_transaction: 'Cancel',
    contract_creation: 'Create contract',
    contract_execution: 'Execute contract',
    send_eth: 'Sent',
    receive_eth: 'Received',
    remove_liquidity: 'Remove liquidity',
    mint: 'Mint',
    token_transfer: 'Token transfer',
    transfers: 'Transfers',
    receiver: 'Receiver',
    tx_hash: 'Tx hash',
    tx_fee: 'Tx fee',
    nonce: 'Nonce',
    block: 'Block',
    burn: 'Burn',
    emergency: 'Emergency',
    stake: 'Stake',
    staking: 'Staking',
    unstaking: 'Unstaking',
    swap_and_burn: 'Swap and burn',
    vote: 'Vote',
    add: 'Add',
    add_pool: 'Add pool',
    allow: 'Allow',
    collect: 'Collect',
    finalize: 'Finalize',
    join_pool: 'Join pool',
    delegate: 'Delegate',
    execute: 'Execute',
    execute_recipe: 'Execute recipe',
    propose: 'Propose',
    queue: 'Queue',
    trade: 'Trade',
    transfer_from: 'Transfer from',
    init: 'Initialize',
    lock: 'Lock',
    open: 'Open',
    wipe: 'Wipe',
    exit: 'Exit',
    deny: 'Deny',
    batch: 'Batch',
    sell: 'Sell',
    sold: 'Sold',
    claim_and_sell: 'Claim and sell',
    remove: 'Remove',
    redeem: 'Redeem',
    redeeming: 'Redeeming',
    shift: 'Shift',
    unsubscribe: 'Unsubscribe',
    stop: 'Stop',
    set_owner: 'Set owner',
    set_name: 'Set name',
    set_authority: 'Set authority',
    push: 'Push',
    start: 'Start',
    pull: 'Pull',
    set_cache: 'Set cache',
    enter: 'Enter',
    draw: 'Draw',
    give: 'Give',
    long_loading: 'This might take a while',
    error: 'Error',
    welcome: 'Welcome',
    defi_saver: 'DeFi Saver',
    share: 'Share',
    docs: 'Docs',
    simulation: 'Simulation',
    deadline_info: 'The transaction will revert if not confirmed within selected deadline',
    unstake: 'Unstake',
    staked: 'Staked',
    authorize_dsproxy:'Authorize DSProxy',
    migrate_position:'Migrate position',
    manage_wallets: 'Manage Wallets',
    additional_info: 'Additional info',
    with_swap_rate: 'at rate',
    from_account: 'from account',
    to_account: 'to account',
    depositing: 'Depositing',
    repay_details: 'Repay details',
    boost_details: 'Boost details',
    deadline: 'Deadline',
    and: 'and',
    you_pay: 'You pay',
    you_receive: 'You receive',
    import_token: 'Import token',
    no_token: 'No token found',
    sending_funds: 'Sending funds',
    sending_to: 'Sending to',
    summary:'Summary',
    positions:'Positions',
    token:'Token',
    tokens:'Tokens',
    complexity:'Complexity',
    day:'day',
    days:'days',
    low:'Low',
    medium:'Medium',
    high:'High',
    actions:'Actions',
    liquidation_protection: 'Liquidation protection:',
    if_below: 'If below',
    leverage_increase: 'Leverage increase:',
    if_above: 'If above',
    position_info: 'Position Info',
    token_approvals:'Token approvals',
    min_debt:'Min. debt',
    debt_ceiling:'Debt ceiling',
    liquidation_fee:'Liquidation fee',
    zero_fee_notice: 'No fee is taken by DeFi Saver for this action.',
    bridge: 'Bridge',
    bridging: 'Bridging',
    you_send: 'You send',
    filters: 'Filters',
  },
  account: {
    create_position: 'All positions at DeFi Saver are created on a Smart Wallet in order to enable advanced features. %missing_sw',
    missing_sw: 'You currently don\'t have a Smart Wallet and the first, required step in the process will be to create one. ',
    connect_wallet: 'Connect your wallet',
    connect_message: 'Connect %item',
    secure_way: 'This is a secure way to access your wallet',
    account_connected: 'This account is currently connected',
    load_accounts: '%status more accounts',
    user_wallet: 'Smart Wallet',
    savings_user_wallet_info: 'Funds used within Smart Savings are controlled by your personal Smart Wallet, a standard MakerDAO DSProxy wallet used for easier integration with lending protocols.',
    savings_user_wallet_info_2: 'Because of Smart Wallet (DSProxy) usage, your funds will not be visible on other dashboards (e.g. the Compound dashboard).',
    compound_user_wallet_info: 'Using Compound via your Smart Wallet allows you to execute advanced operations, such as Boost, Repay and Flash Loan-based actions. Smart Wallet is a standard MakerDAO DSProxy wallet used for easier integration with lending protocols.',
    compound_user_wallet_info_2: 'These funds will not be visible on the Compound dashboard.',
    wallet_info_learn_more: 'Learn more about your Smart Wallet',
    user_wallet_address_info: 'Please do not send funds directly to this address.',
    create_user_wallet: 'Create Smart Wallet',
    create_user_wallet_to_continue: 'Create Smart Wallet to continue',
    creating_user_wallet: 'Creating Smart wallet',
    user_wallet_information: 'Smart Wallet information',
    create_ds_proxy: 'Create DSProxy',
    account_found: '%type account connected %account',
    monitor_account_modal:'Simply enter your address to track your assets and decentralized finance portfolio without connecting your wallet.',
    address_or_ens: 'Ethereum address or ENS',
    use_demo_address: 'Use demo address',
    demo_address: 'test.defisaver.eth',
    enter_address_to_monitor: 'Or enter your address to monitor your portfolio',
    debugging_account: 'Debugging account loaded',
    monitoring_account: 'Account loaded for portfolio monitoring',
    your_smart_wallet: 'Your Smart Wallet',
    switch_to_smart_wallet: 'Switch to Smart Wallet',
    migration_unavailable: 'Migration unavailable',
    user_wallet_description_blocker: 'In order to use our advanced %protocol features you first need to create a Smart wallet - your personal smart contract wallet that will enable usage of advanced features. Please note that by using the Smart wallet you lose compatibility with other apps (e.g. %protocol\'s default app).',
    repay_remainder_returned: 'Remainder of collateral will be returned as %token',
    repay_remainder_returned_desc: 'If debt is fully paid off by Repay, the remainder of the Repay amount will be returned to your wallet as %token.',
    repay_action_item_desc: 'Withdraw %supply → Swap → Payback %debt',
    boost_action_item_desc: 'Borrow %debt → Swap → Supply %supply',
    repay_action_item_same_asset_desc: 'Withdraw %asset → Payback %asset',
    boost_action_item_same_asset_desc: 'Borrow %asset → Supply %asset',
    smart_wallet_create_modal_info1:'Some of our advanced features include interactions with multiple smart contracts in one transaction, which is not possible when using a standard Ethereum account.',
    smart_wallet_create_modal_info2:'Smart Wallet is your personal smart contract proxy wallet that holds your funds and makes these advanced features possible.',
  },
  maker: {
    create_cdp: 'Create CDP',
    loading_cdp: 'Loading CDP...',
    cdp_id: 'CDP ID',
    cdp_lower_bound: 'If below %lower_bound% repay to %lowerbound_target%',
    cdp_upper_bound: 'If above %upper_bound% boost to %upperbound_target%',
    no_cdp_found: 'No CDP found',
    close_cdp: 'Close CDP',
    transfer_cdp: 'Transfer CDP',
    migrate_cdp: 'Migrate CDP',
    transfer_cdp_description: 'Transferring your CDP will move it from your current address to the selected address. The current address will no longer have ownership or management rights once the transfer is complete.',
    transfer_cdp_automation: 'Please note that you will need to re-enable Automation from the new address after transferring.',
    transfer_cdp_warning: 'Once completed this action cannot be undone. Please manually check the entered data:',
    close_cdp_automation: 'This action will disable your Automation configuration. If you decide to use this CDP again, you will need to re-enable Automation.',
    create_cdp_description: 'Creating a CDP allows you to generate DAI by depositing one of supported collateral types',
    minimum_ratio: 'Minimum accepted ratio is %min_ratio. \nWe recommend a ratio over %suggested_ratio to keep the CDP safe from liquidation',
    ratio_warning: 'Your ratio must be over minRatio',
    tsi_too_high: 'Trade size impact too high',
    generate_description: 'Generate more %daiLabel from your CDP',
    withdraw_description: 'Withdraw %asset collateral from your CDP',
    boost_description: 'Generates %daiLabel to buy %asset and add it to your CDP in a single transaction.',
    boost_modal_description: 'Boost generates %daiLabel from your CDP to obtain more %asset. This increases your leverage at the cost of increasing your liquidation price and decreasing your collateralization ratio.',
    payback_description: 'Pay back the %daiLabel debt of your CDP',
    add_collateral_description: 'Add more %asset collateral to your CDP',
    repay_description: 'Takes out %asset to buy %daiLabel and pay back debt in a single transaction.',
    repay_modal_description: 'Repay uses %asset from your CDP to pay back %daiLabel debt. This decreases your liquidation price and increases your collateralization ratio.',
    all_debt_repay: 'All the debt will be repayed, extra DAI will be sent back to you',
    migrate_description: 'Migrating will enable you to interact with the DeFi Saver platform. By migrating you will be using the standard MakerDAO DSProxy interface and will be able to interact with other CDP platforms as well.',
    saver_subscribe: 'Enabling CDP Automation',
    saver_unsubscribe: 'Disabling CDP Automation',
    no_cdp_blocker: 'There doesn\'t seem to be a MakerDAO CDP (Vault) associated with this account. You can create a MakerDAO CDP now to borrow DAi or leverage assets using our signature Boost and Repay features.',
    cdp_automation: 'CDP Automation',
    cdp_automation_description: 'Keeps your CDP at a certain ratio to protect it from liquidation or increase leverage based on market changes.',
    no_actions_executed: 'No actions have been executed yet.',
    cdp_automation_status: 'CDP Automation Status',
    keep_ratio_at: 'Keep the ratio at:',
    if_ratio_bellow: 'If ratio falls below:',
    repay_it_to: 'Repay it to:',
    will_hit_ratio_at: 'Will hit this ratio at %value',
    if_ratio_goes_over: 'If ratio goes over:',
    boost_it_to: 'Boost it to:',
    automation_boost_disclaimer: 'Automated Boost will only be performed if the transaction fee is less than 10% of the Boost amount.',
    approx_sold_for: 'Approximately %sold will be sold for %bought',
    cdp_automation_setup: 'CDP Automation Setup',
    cdp_automation_setup_description: 'Once Enabled, DeFi Saver will monitor your CDP ratio and automatically activate Repay if your CDP reaches the lower configured limit, or Boost if it reaches the upper one. Simply enter your target ratio, or configure manually below.',
    loading_saver_data: 'Loading Automation data...',
    automatic_boost_will_be_triggered: 'Automatic boost will be triggered upon enabling automation with current parameters',
    automatic_repay_will_be_triggered: 'Automatic repay will be triggered upon enabling automation with current parameters',
    automation_form_beta_warning: 'This feature is in beta. Use at your own risk.',
    import_description: 'Using this tool will migrate your CDP from the proprietary InstaDApp proxy wallet to the standard Maker DSProxy. \n\n' +
      'By migrating you gain access to our advanced features such as Automation, Boost and Repay, as well as other dashboards such as Maker\'s Oasis app and Zerion.\n\n' +
      'Once you migrate your CDP to a DSProxy you will not be able to use InstaDApp again unless you migrate back to their proxy wallet.',
    import_cdp: 'Import CDP',
    migrating_cdp_from_instadapp: 'Migrating CDP from InstaDApp',
    cdp_managed_by_instadapp: 'This CDP is currently on an InstaDApp proxy wallet.',
    why_disabling_automation: 'Want to let us know why you\'re disabling this strategy?',
    saver_unsubscribe_reason_1: 'I\'m adjusting my position',
    saver_unsubscribe_reason_5: 'Other',
    saver_unsubscribe_reason_6: 'I\'m closing my position',
    saver_unsubscribe_reason_7: 'I\'m moving funds to a different protocol',
    saver_unsubscribe_reason_8: 'I have questions about the Automation system',
    liquidation_protection_if_bellow: 'Liquidation protection: if below',
    leverage_increase_if_above: 'Leverage increase: if above',
    liquidation_ratio: 'Liquidation ratio',
    min_ratio: 'Min ratio',
    ratio_not_safe: 'Ratio would not be safe after this action',
    cdp_protected: 'Automation enabled and active.',
    cdp_not_protected: 'Enable Automation for liquidation protection and automatic leveraging',
    stability_fee_explanation: 'The annual borrow rate for CDPs, calculated based on your outstanding CDP debt and continuously added to your current debt. The rate is variable and voted on by Maker governance.',
    liquidation_ratio_explanation: 'The minimum collateralization ratio in MakerDAO. Any CDPs that drop below this ratio face liquidation.',
    price_explanation: 'The current price in the MakerDAO protocol.',
    buying_asset_at_explanation: 'DAI will be borrowed from the CDP and used to buy more collateral at this price',
    cdp_is_empty: 'CDP is empty',
    cdp_is_empty_desc: 'This CDP is currently empty and does not hold any collateral or debt. You can start using it again at any moment by adding new collateral.',
    close_mcd_cdp_description: 'Closing your collateralized debt position will pay back the current debt using currently locked collateral and withdraw remaining collateral to your account.',
    total_coll_minus_dai_debt: 'Total collateral - DAI debt value:',
    dai_debt_value: 'DAI debt value:',
    minimum_accepted_asset_closing: 'Minimum accepted %asset amount after closing:',
    maximum_loss_closing: 'Accepted maximum loss after closing:',
    close_fee: '(includes 0.25% service fee)',
    close_fee_no_brackets: 'Includes 0.25% service fee',
    mcd_close_can_repay: 'You currently have enough collateral in your CDP to pay back the debt fully using our standard Repay feature. Repay is the more optimal option when applicable, as it includes less interactions with different protocols and requires less fees, resulting in higher profits.',
    collateral_in_usd: 'Collateral in USD',
    coll_ratio: 'Coll. ratio',
    received_dai: 'Received DAI',
    leveraged: 'Leveraged',
    leveraged_desc: 'All generated DAI will be used to buy more ETH which will be added to your CDP for increased leverage.',
    value_invested: 'Value Invested',
    value_invested_desc: 'Total collateral added + DAI paid back',
    value_withdrawn: 'Value Withdrawn',
    value_withdrawn_desc: 'Total DAI generated + collateral withdrawn',
    lifetime_profit: 'Lifetime Profit',
    balance_desc: 'Current collateral value - debt',
    lifetime_profit_desc: 'CDP balance + value withdrawn - value invested',
    repay_remainder_returned: 'Remainder of collateral will be returned as DAI',
    repay_remainder_returned_desc: 'If debt is fully paid off by Repay, the remainder of the Repay amount will be returned to your wallet as DAI.',
    cdp_created_successfully: 'CDP cdpId created successfully',
    cdp_created_successfully_desc: 'We suggest enabling liquidation protection for your position using our CDP Automation system.\nYou can also do this at any moment in the future.',
    unclaimed_collateral: 'Unclaimed %amount',
    unclaimed_collateral_desc: 'You have unclaimed collateral. This has most likely happened due to your CDP being liquidated. Claim it to return it to your CDP, so it becomes available for withdrawal.',
    close_auction_desc: 'You are this auction\'s winner. Close the auction to claim your winnings.',
    close_auction: 'Close auction',
    close_auction_dai: 'Close and convert to DAI',
    vat_dai_balance: 'You have %amount DAI unused from previous bids.\nIt will be used for future bids. You can withdraw it any time.',
    vat_coll_balance: 'You have a collateral balance in VAT. This can happen if someone else closes a bid for you. \nYou can withdraw this balance any time.',
    bid_on_liq: 'Bid on liquidation auction',
    liq_auction_warning: 'This feature is in beta and is provided with no guarantee of profit. Use at your own risk.',
    automation_boost_subheader: 'Boost when price rises',
    automation_repay_subheader: 'Repay when price drops',
    automation_unsupported_asset: 'Automation is currently supported for %assets and %last_asset CDPs.',
    strategy_unsupported_asset: 'This automated strategy is currently supported for %assets and %last_asset CDPs.',
    vaults_subbed: 'Vaults subscribed:',
    vault_id: 'Vault ID',
    close_to: 'Close to',
    leveraged_create_warning_1: 'The generated DAI will not be added to your wallet - they will be used to buy additional collateral for your CDP to increase leverage.',
    leveraged_create_warning_2: 'If you\'re not sure what the difference between a standard and leveraged CDP is, please see',
    leveraged_create_warning_3: 'this article',
    repay_withdraw_hint: 'Withdrawing %asset to pay back debt?\nDo it in a single transaction using Repay!',
    repay_payback_hint: 'Not enough %asset to pay back debt?\nPay it back with collateral using Repay.',
    boost_borrow_hint: 'Borrowing %asset to supply more collateral?\nDo it in a single transaction using Boost!',
    cdp_history: 'CDP #%id History',
    dash_boost_collateral: 'Add %collAsset from your account to make the position safer, then generate %debtAsset to buy %collAsset and add it to the CDP.',
    dash_boost_payback: 'Pay back debt using %debtAsset from your account, then generate %debtAsset to buy %collAsset and add it to the CDP.',
    dash_repay_withdraw: 'Take out %collAsset to buy %debtAsset and pay back debt, while also withdrawing some of the remaining collateral to your account.',
    dash_repay_generate: 'Take out %collAsset to buy %debtAsset and pay back debt, while also generating more %debtAsset to your account.',
    dash_collateral_generate: 'Add more %collAsset to your CDP and generate %debtAsset to your account.',
    dash_collateral_boost: 'Add more %collAsset from your account and apply leverage boost to the same, previous ratio.',
    dash_withdraw_payback: 'Pay back part of your %debtAsset debt and withdraw %collAsset collateral to your account.',
    dash_withdraw_repay: 'Deleverage your position and withdraw %collAsset collateral to your account, while keeping the same, previous ratio.',
    dash_withdraw_send: 'Withdraw %collAsset collateral and send it to another account',
    dash_generate_collateral: 'Add more %collAsset collateral and generate more %debtAsset to your account.',
    dash_generate_repay: 'Deleverage your position and generate more %debtAsset to your account, while keeping the same, previous ratio.',
    dash_generate_send: 'Generate %debtAsset and send it to another account',
    dash_payback_withdraw: 'Pay back debt using %debtAsset from your account and withdraw %collAsset.',
    dash_payback_boost: 'Pay back debt using %debtAsset from your account and apply boost to keep the same, previous ratio.',
    crop_cdp_already_exists: 'A %ilk CDP (%cdpId) already exists. There can only be a single CDP of %ilk type per account',
    advanced_create: 'Your %asset will be deposited into the %pool pool to receive %lptoken.',
    advanced_create_wsteth_steth: 'Your %asset will be wrapped into %lptoken.',
    advanced_create_wsteth_other: 'Your %asset will be staked with Lido and wrapped into %lptoken.',
    min_debt_explanation:'Minimum amount of debt that this vault type has to maintain. This value (also known as "dust") is managed by MakerDAO governance and positions below the minimum debt will have limited management options.',
    debt_ceiling_explanation:'Maximum amount of DAI that can be generated from a specific vault type (and currently utilized percentage).',
    liquidation_fee_explanation:'A liquidation penalty applied to your debt amount in case of liquidation.',
  },
  maker_actions: {
    'add-collateral': 'Add Collateral',
    'create': 'Create',
    'withdraw-collateral': 'Withdraw Collateral',
    'generate-dai': 'Generate DAI',
    'payback-dai': 'Pay Back DAI',
    'add-collateral-draw-dai': 'Add & Generate',
    'payback-dai-withdraw-collateral': 'Pay Back & Withdraw',
    'boost': 'Boost',
    'repay': 'Repay',
    'liquidation': 'Liquidation',
    'close': 'Close',
    'borrow_supply': 'Borrow & Supply',
    'withdraw_payback': 'Withdraw & Pay Back',
    'transfer': 'Transfer',
    'payback': 'Payback',
  },
  compound: {
    supply_assets_info: 'Supply your assets to start earning interest',
    supply_rate_description: 'Yearly interest percentage on supplied funds. Changes based on market supply and demand.',
    withdraw_info: 'Withdraw assets from your Compound supply',
    borrow_info: 'Borrow assets from Compound. You need to supply assets as collateral before borrowing.',
    interest_estimate: 'Interest estimate:',
    interest_estimate_info: 'This is an estimate based on current interest rates.',
    borrow_limit: 'Borrow Limit',
    net_apy: 'Net APY',
    supply_balance: 'Supply Balance',
    borrow_balance: 'Borrow Balance',
    left_to_borrow: 'Left to Borrow',
    boost_description: 'Increase leverage by borrowing, swapping to collateral asset and depositing to your position in a single transaction.',
    repay_description: 'Deleverage by withdrawing collateral, swapping to debt asset and paying back debt in a single transaction.',
    boost_modal_description: 'Boost borrows more %asset1 to obtain more %asset2. This increases your leverage at the cost of increasing your borrow power used.',
    repay_modal_description: 'Repay uses supplied %asset2 to pay back %asset1 debt. This decreases your leverage, but also decreases your borrow power used and makes your position safer.',
    proxy_required_click_to_migrate: 'Smart Wallet required for advanced actions.',
    borrow_power_used: 'Borrow power used',
    migrating_to_proxy: 'Migrating to proxy',
    supply_balance_desc: 'The sum of your supplied collateral.',
    borrow_balance_desc: 'The sum of your borrowed funds.',
    borrow_limit_desc: 'The maximum value of funds you can borrow.',
    payback_info: 'Pay back the borrowed asset.',
    collateral_required: 'Collateral required',
    collateral_required_desc: 'This asset is required to support your borrowed funds. Disabling this asset as collateral will push your position into liquidation. Please repay borrowed funds or supply a different asset as collateral before disabling this one.',
    enable_collateral: 'Enable %asset as collateral',
    disable_collateral: 'Disable %asset as collateral',
    comp_tokens: 'COMP tokens',
    comp_tooltip: 'Withdraw earned COMP token',
    comp_modal_desc: 'COMP tokens are automatically rewarded to all users of the Compound protocol.',
    comp_estimate_title_account: 'COMP rewards estimate for your account:',
    comp_estimate_title_smart_wallet: 'COMP rewards estimate for smart wallet:',
    warning_borrowing_supplied: 'You are borrowing an asset you already supplied. Are you sure this is what you want to do?',
    warning_supplying_borrowed: 'You are supplying an asset you already borrowed. Are you sure this is what you want to do?',
    loading_compound_saver_data: 'Loading Compound Automation data...',
    compound_automation: 'Compound Automation',
    compound_automation_description: 'Keeps your Compound position at a certain ratio to protect it from liquidation or increase leverage based on market changes.',
    compound_automation_setup: 'Compound Automation Setup',
    compound_automation_setup_description: 'Once Enabled, DeFi Saver will monitor your Compound ratio and automatically activate Repay if your Compound reaches the lower configured limit, or Boost if it reaches the upper one. Simply enter your target ratio, or configure manually below.',
    compound_automation_status: 'Compound Automation Status',
    compound_saver_subscribe: 'Enabling Compound Automation',
    compound_saver_unsubscribe: 'Disabling Compound Automation',
    migrate_to_proxy: 'Migrate to Smart Wallet',
    migrate_to_proxy_description: 'Automation can only be enabled when using Compound via the DeFi Saver Smart Wallet. \nPlease switch to your Smart Wallet or migrate your Compound position if you haven\'t already.',
    no_smart_wallet_automation: 'You need to have a Compound position on a Smart Wallet in order to use Automation.',
    compound_unsubscribe_reason: 'My Compound ratio was changed in a way I didn\'t expect would happen.',
    create_position: 'Open long or short position using the Compound protocol',
    open_position: 'Open position',
    safety_ratio_create_desc: 'The ratio of your borrow limit versus borrowed funds. Minimum ratio excepted is 100%. We recommend a ratio over 150% to keep your position from liquidation',
    current_and_after_supply: 'Your current supply of selected asset and supply after finishing the create process',
    current_and_after_borrow: 'Your current borrow of selected asset and borrow after finishing the create process',
    comp_smart_wallet_title: 'Smart Wallet position:',
    comp_eoa_title: 'Account position:',
    claim_from_account: 'Claim your allocated tokens to withdraw them to your main account.',
    claim_from_smart_wallet: 'Claim your COMP tokens.',
    claim_and_supply_from_smart_wallet: 'Claim and supply COMP to Compound in one transaction.',
    claim_and_swap_from_smart_wallet: 'Claim and swap COMP into a different asset in one transaction. Includes a 0.25% service fee.',
    claim_swap_and_supply_from_smart_wallet: 'Claim COMP, swap and supply the new asset to Compound in one transaction. Includes a 0.25% service fee.',
    compound_migrate_modal_info1: 'Some of our advanced features include interactions with multiple smart contracts in one transaction, which is not possible when using a standard Ethereum account.',
    compound_migrate_modal_info2: 'Smart Wallet is your personal smart contract proxy wallet that holds your Compound funds and makes these advanced features possible.',
    compound_migrate_modal_info3: 'You can migrate your supplied collateral, or migrate a collateral-debt pair to your Smart Wallet.',
    compound_migrate_not_supplied: 'Migration is possible only for Compound accounts supplying and borrowing at least one asset.',
    compound_migrate_withdraw: 'To migrate your supplied funds, please withdraw them, and supply to your Smart Wallet manually.',
    compound_migrate_no_assets: 'No assets on your account are available for migration.\nMost often, this is because funds supplied on your Account Compound are borrowed on your Smart Wallet, or vice-versa.',
    buying_asset_at_explanation: '%borrow will be borrowed from the pool and used to buy more %supply at this price',
    create_smart_wallet_warning: 'You can only open a position when using Compound via the DeFi Saver Smart Wallet.',
    price_explanation: 'The current price in the Compound protocol.',
    compound_create: 'Compound Create',
    migrate_from_instadapp:'Compound DSA Migration',
    migrate_choose_position:'Select DSA',
    migrate_modal_description_1:'Using this tool will migrate your whole Compound position from DSA, the proprietary InstaDApp proxy wallet, to a standard DSProxy.',
    migrate_modal_description_2:'By migrating you gain access to our advanced features such as Automation, Boost and Repay, as well as our Recipe Creator.',
    migrate_modal_description_3:'Once you migrate your position to a DSProxy you will not be able to manage it at InstaDApp again unless you migrate back to their proxy wallet.',
    migrating_position_from_instadapp:'Migrating Compound from InstaDApp',
    dash_boost_collateral: 'Add collateral from your account to make the position safer, then generate debt to buy more collateral and add it to your position.',
    dash_boost_payback: 'Pay back debt from your account, then generate debt to buy more collateral and add it to your position.',
    dash_repay_withdraw: 'Withdraw selected asset and apply repay to keep your ratio unchanged.',
    dash_repay_generate: 'Borrow selected asset and deleverage your position to keep the same ratio.',
    add_collateral_description: 'Deposit the selected asset into Compound.',
    dash_collateral_generate: 'Add more collateral and borrow selected asset to your account.',
    dash_collateral_boost: 'Add more collateral from your position and apply boost to keep the ratio unchanged.',
    withdraw_description: 'Withdraw selected asset from Compound.',
    dash_withdraw_payback: 'Pay back part of your debt and withdraw collateral to your account.',
    dash_withdraw_repay: 'Deleverage your position and withdraw collateral to your account, while keeping the same, previous ratio.',
    generate_description: 'Borrow selected asset from Compound.',
    dash_generate_collateral: 'Add more collateral and generate more debt to your account.',
    dash_generate_repay: 'Deleverage your position and generate more debt to your account, while keeping the same, previous ratio.',
    payback_description: 'Pay back the %daiLabel borrowed asset.',
    dash_payback_withdraw: 'Pay back your debt and withdraw selected asset in one transaction.',
    dash_payback_boost: 'Pay back debt and apply boost to keep the ratio unchanged.',
    borrow_cap_exceeded: 'Borrow cap exceeded for %debtAsset market.',
    missing_liquidity: 'Not enough liquidity. The maximum amount available for borrowing is %assetAmount',
    migrating_position_from_account:'Migrating Compound from Account',
  },
  savings: {
    balance: 'This is your current balance, including interest earned as of now, across all integrated protocols.',
    average_interest_rate: 'This is your current average interest rate based on the amount of DAI supplied per protocol.',
    curr_average_interest_rate: 'Current interest rate:',
    user_wallet_description: 'In order to use our Savings feature you first need to create a Smart Wallet. This will be your personal contract wallet that will enable usage of this advanced feature while still allowing you to withdraw funds at any point you wish to do so. Please note that any funds used in our Savings feature will not be visible on other dashboard (e.g. Compound’s dashboard).',
    import_description: 'Importing lets you move your already supplied assets to your Smart Wallet so that you can use them in Smart Savings.',
    import_modal_description: 'We detected that you already have funds supplied to one of the supported protocols. Would you like to import these funds to Smart Savings?',
    monthly_avg_short: 'Monthly avg',
    monthly_avg_description: 'Average interest rate for previous month',
    supply_asset_to_project: 'Supply %asset to %project',
    move_asset_from_to_project: 'Move %asset from %project1 to %project2',
    withdraw_asset_from_project: 'Withdraw %asset from %project',
    import_asset_to_project: 'Import %asset to %project',
    withdraw_asset_to_account: 'Withdraw %asset to account',
    emergency_withdraw: 'Emergency %token Withdraw',
    accidentally_sent_withdraw: 'You\'ve accidentally sent %token to your Smart Wallet, click the button below to withdraw. This action will withdraw all funds of the specified token back to your address.',
    liquidity: 'Liquidity',
    total_supply: 'Total Supply',
    total_utilization: 'Utilization rate',
    deposited_amount: 'Deposited amount',
    deposited: 'Deposited',
    log_in_to_manage_your_savings: 'Log in to manage your Smart Savings',
    info_no_smart_wallet:'Your assets in Smart Savings will be kept on your Smart Wallet. This will enable use of advanced features, but may not be compatible with other frontends for these protocols. You currently don\'t have a Smart Wallet and the first, required step will be to create one.',
    info_no_deposits: 'Your assets in Smart Savings will be kept on your Smart Wallet. This will enable use of advanced features, but may not be compatible with other frontends for these protocols.',
    supplied_info: 'Your current balance in this protocol, including interest earned so far.',
    supplied_info_summary: 'This is your current balance including interest earned so far, across all positions.',
    current_apy: 'Current APY',
    apy_info: 'A 7-day moving average APY, updated every 6 hours.',
    apy_info_summary: 'This is your current net APY for all deposits and protocols used.',
    pool_size: 'Pool size',
    import_info: 'Import your existing position to your Smart Wallet so you can use them in Smart Savings.',
    supply_info: 'Supply assets to the selected option to start earning yield.',
    withdraw_info: 'Withdraw assets back to your account.',
    description_yearn: 'You deposit and withdraw one and the same token. No additional actions or gas costs. Well handled security incidents. Lowest gas costs among available options.',
    description_rari: 'You deposit and withdraw one and the same token. No additional actions or gas costs. Some history of security incidents. Highest gas costs among available options.',
    description_mstable_save:'You can deposit and withdraw a variety of stablecoins, including DAI, USDC, USDT and mUSD. Depositing into mStable Save includes converting DAI, USDC and USDT to mUSD at 1:1 and depositing the mUSD into their Save contract to receive interest bearing imUSD. No history of security incidents. Medium gas costs compared to other options.',
    description_mstable_vault: 'You can deposit and withdraw a variety of stablecoins, including DAI, USDC, USDT and mUSD. Depositing into mStable Save includes converting DAI, USDC and USDT to mUSD at 1:1 and depositing the mUSD into their Save contract to receive interest bearing imUSD. The imUSD is then staked into their Vault contract to receive additional MTA rewards. No history of security incidents. Medium gas costs overall, with additional manual claiming of MTA rewards needed.',
    move_info:'Move currently deposited assets to the selected protocol.',
    mstable_unfavorable_exchange_rate:'Exchange rate between mUSD and %asset% seems to be different from 1:1 (%mUSDAmount% mUSD = %bAssetAmount% %asset%), resulting in potential loss. Withdrawing to a different asset might be more profitable. Are you sure you want to continue?',
    claim_info: 'Claim accrued protocol rewards.',
    claim_supply: 'Claim, swap and add the additional assets to your existing deposit.',
    claimable_info: 'Current amount of claimable reward tokens.',
    moving_affects_strategies: 'Moving funds to %protocol will require you to update your Smart Savings automation strategy settings as these are stored on-chain.',
    pool_size_info:'Total amount of assets deposited in the pool.',
    liquidity_info:'Currently available amount of assets that can be withdrawn from the pool.',
    liquidity_info_mstable:'Currently available amount of assets that can be withdrawn from the pool. Note: in mStable all assets can be withdrawn in mUSD at any point in time, while availability of specific stablecoins varies.',
    withrawable_info: 'The balances shown are withdrawable balances, based on the currently available liquidity in the used protocol and can differ from your total deposited amounts.',
  },
  misc: {
    logging_in: 'Logging in, please wait...',
    connecting_provider: 'Connecting your account, please wait...',
    getting_cdp: 'Getting your CDP, please wait...',
    insurance_label: 'Insured for %sumAssured %currency until %validUntil',
    exchange_slippage: 'Slippage tolerance:',
    exchange_slippage_limit: 'Limit additional price slippage:',
    exchange_for: 'Exchange %from for %to',
    exchange_disclaimer: 'Estimated best price sourced from 0x, Kyber, Uniswap and Oasis. Includes 0.125% service fee.',
    exchange_fee: 'Includes 0.125% service fee.',
    exchange_receipt: 'Check your swap receipt',
    boost_repay_exchange_disclaimer: 'Estimated best price sourced from 0x, Kyber, Uniswap and SushiSwap. Includes %fee% service fee.',
    exchanging_funds: 'Exchanging your funds...',
    exchange_funds: 'Please enter amount and select the tokens you want to exchange.',
    exchanging_select: 'Please select the tokens you want to exchange.',
    exchanging_at_least: 'You are selling %value1 %token1 for at least %value2 %token2',
    copied_to_clipboard: 'Copied to clipboard',
    short_description: 'The Short feature will borrow the chosen token and exchange it for a stable coin. The whole process will be done with a single click and in a single transaction. Coming soon, stay tuned!',
    leverage_description: 'The Leverage feature will borrow a stable token and exchange it for the token you are leveraging. The whole process will be done with a single button click and in a single transaction. Coming soon, stay tuned!',
    non_web3: 'Non-web3 browser',
    web3_browser_info: 'In order to use DeFi Saver and interact with the blockchain you will need a Web3 enabled browser. We recommend using Coinbase, Trust, Opera or Status.',
    feature_in_development: 'This feature is currently in development.',
    subscribe_notifications: 'You can subscribe below to receive a notification as soon as it goes live!',
    subscribe_success: 'Thanks for subscribing! We will notify you as soon as this feature goes live.',
    confirm_transaction: 'Please confirm your transaction.',
    tx_sent: 'Transaction %txHash has been created and is currently pending.',
    tx_pending: 'Transaction %txHash pending...',
    tx_confirmed: 'Transaction %txHash completed.',
    tx_rejected: 'Transaction %txHash rejected.',
    tx_failed: 'Transaction rejected.',
    tx_replaced: 'Transaction %txHash replaced.',
    tx_canceled: 'Transaction %txHash canceled.',
    transaction_settings: 'Transaction settings',
    fast: 'Fast',
    regular: 'Regular',
    cheap: 'Cheap',
    gas_price: 'Gas price',
    gas_limit: 'Gas limit',
    total_price: 'Total price:',
    recent_txs: 'Recent transactions',
    no_recent_txs: 'No recent transactions',
    recommended_gas_prices: 'Recommended gas prices',
    customize_gas: 'Customize gas settings',
    real_gas_estimate: 'Estimated gas',
    real_gas_fee: 'Estimated fee',
    max_tx_fee: 'Max transaction fee',
    total: 'Total',
    automation_stats: 'Automation Stats',
    automation_contract_upgrades: 'Automation Contract Upgrades',
    explore_maker_stats: 'Explore MakerDAO Stats',
    gas_price_desc: 'Price paid per unit of gas used by the transaction.',
    gas_limit_desc: 'Maximum amount of gas allowed to be used by the transaction.',
    real_gas_estimate_desc: 'Estimated amount of gas expected to be used by the transaction',
    real_gas_fee_desc: 'Estimated transaction fee with currently configured values.',
    base_fee: 'Base fee',
    base_fee_desc: 'The base fee is a required gas fee that gets burned upon transaction execution. It is automatically adjusted by the network, decreasing or increasing based on current network usage.',
    priority_fee: 'Priority fee',
    priority_fee_desc: 'The priority fee (often called the "miner tip") is an additional fee that is paid directly to the miners in order to incentivize inclusion of your transaction.',
    max_fee: 'Max fee',
    max_fee_desc: 'The max fee is the limit to how much you\'ll pay for both base fee and tip.',
    max_tx_fee_desc: 'Maximum fee paid for the transaction. Any unused gas from the transaction is refunded to the user.',
    tx_failing_notification: 'An issue was detected that would cause this transaction to fail. Please contact us for assistance if the error persists.',
    welcome_to_dfs: 'Welcome to DeFi Saver',
    withdrawing_from_safeguard: 'Withdrawing funds from safeguard',
    low_gas_price_warning: 'Using lower than cheap gas price can result in stuck transactions.',
    low_max_fee_warning: 'Using a max fee close to the current base fee may result in stuck transactions.',
    current_ratio: 'Current ratio',
    log_in_to_manage_your_app: 'Log in to manage your %app position',
    supply_to_enable_collateral: 'Supply to enable as collateral. Supplying via Smart Wallet will enable the asset automatically.',
    automation_congestion_desc_1: 'In order to keep Automation beneficial and profitable for all positions during gas price surges, we have implemented the following rules:',
    automation_congestion_rule_1: 'Automated Boosting will only be executed if the transaction cost is less than 5% of the Boost amount.',
    automation_congestion_rule_2: 'Automated Repay will be executed if the transaction cost is less than 5% of the Repay amount or as soon as you\'re less than 15% above liquidation ratio.',
    automation_congestion_desc_2: 'Automation always sends all transactions using current fast gas, tracks them and re-sends with faster gas if any get stuck. The maximum gas price that can be charged to the user is currently %gweiAmount Gwei.',
    automation_congestion_desc_3: 'We continue to make gas usage related optimizations to our smart contracts in order to reduce gas usage in as many instances as possible. (Last updated Mar 2nd 2022).',
    automation_congestion_desc_4: 'If you would like to check anything regarding Automation with us, please feel free to join our https://discord.gg/XGDJHhZ',
    footer_desc: 'DeFi Saver is a one-stop management solution for decentralized finance protocols.',
    newsletter_sub: 'Subscribe to the DeFi Saver newsletter',
    whats_new_title: 'What\'s new on DeFi Saver',
    low_ratio_warn: 'Safety ratio lower than currRatio. Please be mindful of liquidation risks that come with high leverage levels.',
    high_borrow_warn: 'Borrow power higher than currRatio. Please be mindful of liquidation risks that come with high leverage levels.',
    feature_unavailable_in_simulation: 'Feature unavailable in simulation',
    feature_unavailable_in_simulation_desc: 'Some features relying on external systems are disabled while you\'re inside the simulation. %feature is one of them. ',
    positions_subbed: 'Positions subscribed',
    vault_min_debt_warning: 'The minimum debt for a %protocol is currently %amount %asset. While below minimum debt, you can only pay off all debt or generate more debt.',
    exchange_option_info: 'The decentralized exchange used for your wanted swap.',
    dfs_service_fee_info: 'The DeFi Saver service fee for this transaction.',
    fl_protocol_info: 'The protocol used for the needed flash loan.',
    deadline: 'Deadline:',
    ens_or_addr_not_found: 'Couldn\'t find address or ENS',
    ens_not_found: 'There is no address with this ENS',
    exchange_advanced_subtitle: '(Slippage limit, liquidity sources)',
    exchange_best_rate_via: 'Best rate found via',
    received_token_sent: 'Received token sent to',
    send_to_different_addr: 'Send to a different address',
    token_addr_not_found: 'Token for the given address not found.',
    wrong_addr: 'You entered wrong address value',
    sending_to_your_acc: 'Can\'t send received tokens to your account',
    sending_to_your_proxy: 'Can\'t send received tokens to your smart wallet',
    send_tokens_warning: 'No funds will be sent to your account!',
    sending_tokens_details: 'Send tokens received from the swap to a different address.',
    tsi_for_assets: ' for %pair'
  },
  errors: {
    error_occurred: 'Error occurred',
    required: 'Required',
    over_max: 'Value is larger than the max value',
    under_zero: 'Value can\'t be less than 0',
    no_value: 'No value entered',
    invalid_value: 'Invalid %value',
    positive_value: 'Must be a positive value',
    must_be_over: 'Must be over %value',
    wallet_not_connected: 'Wallet not connected',
    nothing_supplied: 'Nothing supplied',
    swap_same_project: 'Please select different protocols for moving assets.',
    no_local_storage_state: 'There is no local storage state',
    no_ledger_item: 'There is no last used ledger item',
    wrong_network: 'Wrong network - please set %type to %network',
    provider_not_approved: 'Provider not pre-approved',
    denied_login: 'You\'ve denied the login',
    denied_transaction: 'You\'ve denied the transaction',
    email_fail: 'Unable to send email',
    user_denied_tx: 'User denied tx',
    not_enough_funds: 'Not enough funds to complete transaction.',
    tx_repeat: 'Duplicate transaction detected.',
    tx_cancel: 'Transaction has been canceled.',
    compound_tx_fail: 'Compound transaction failed',
    no_accounts_locked: 'No accounts (Possibly locked)',
    unknown_network: 'Unknown network',
    ledger_locked: 'Ledger is locked',
    ledger_contract_data: 'Please enable "Contract data" on your Ledger',
    user_canceled: 'User canceled the transaction',
    automatic_trigger: 'Action would trigger an automatic adjustment based on your Automation settings.',
    no_wallet: 'Wallet not detected',
    connect_access: 'Connect your wallet to access this page',
    over_value: 'Must be over %value',
    under_value: 'Must be under %value',
    value_less_than: 'Value must be under %label',
    value_less_than_percent: 'Value must be %percent% under %label',
    target_limit_difference: 'Difference between target and limit ratios must be greater than %value%',
    positive_number: 'Must be a positive number',
    page_not_found: 'Page not found',
    debt_below_min: 'Your CDP debt is under the required minimum of %amount %asset. You can only increase your debt pay back the full amount.',
    debt_below_min_after: 'This action would cause debt to be under the required minimum of %amount %asset. ',
    reverted_transaction: 'Transaction has been reverted due to an error. Transaction hash: %txhash',
    invalid_ds_proxy: 'Invalid DSProxy address',
    ratio_too_low_for_repay: 'In order for automation to work, ratio must be kept at least %percent% above the liquidation ratio.',
    gas_price_very_high: 'Selected gas price (%gasPrice Gwei) may be unnecessarily high. Are you sure you want to continue?',
    action_select_no_options: 'No collateral or borrowed assets available for this action.',
    dai_debt_ceiling_hit: 'Global MakerDAO debt ceiling for used collateral has been reached.',
    borrow_power_used_high: 'Borrow power used is too close or over 100%',
    minimum_dai_debt: 'Minimum debt for %ilk CDPs is %min DAI.',
    initial_dai_debt:'Selected CDP is under the minimum required debt (%min DAI).',
    minimum_rai_debt: 'Minimum debt for %ilk Safes is %min RAI.',
    initial_rai_debt:'Selected Safe is under the minimum required debt (%min RAI).',
    minimum_liquity_debt: 'Minimum debt for Liquity Troves is %min LUSD.',
    invalid_address: 'Invalid address',
    own_address: 'You have entered your own address',
    liquidity_warning: '%asset currently has low available liquidity on exchanges and you may see high slippage with any larger Boosts and Repays.',
    boost_no_collateral_enabled: 'Boost feature is unavailable until you enable your supplied asset as collateral in the main dashboard below.',
    v1_to_v2_lend_error: 'Migrating positions with LEND supplied is not supported.',
    trying_sub_under_min_debt: 'Your current debt is under the minimum debt required for enabling Automation.',
    exchange_proxy_warning: 'Using the Exchange requires a Smart Wallet created. Exchanged funds are regularly sent to your account.',
    over_debt_ceiling: 'Not enough DAI available for %ilk (%available DAI available).',
    additional_action_selected: 'Additional action selected',
    sending_to_asset_contract: 'You seem to have entered the %asset token contract address as the recipient. Please recheck the address you\'re sending to.',
    value_must_be_above: 'Value must be above "%label"',
    value_must_be_above_percent: 'Value must be %percent% above %label',
  },
  portfolio: {
    show_empty_positions: 'Show empty positions',
    hide_empty_positions: 'Hide empty positions',
    current_protocols: 'Current protocols',
    estimated_earnings: 'Estimated earnings',
    net_worth: 'Net worth',
    assets: 'Assets',
    tokens: 'Tokens',
    no_owned_tokens: 'This account currently doesn\'t own any tokens',
    token_assets: 'Token Assets',
    portfolio_value: 'Portfolio Value',
    collateral: 'Collateral',
    debt: 'Debt',
    claimable: 'Claimable',
    supplied: 'Supplied',
    borrowed: 'Borrowed',
    on_smart_wallet: 'On Smart Wallet',
    failed_fetch: 'Failed to fetch %protocol Positions for address: %address',
  },
  aave: {
    aave_migrate_modal_info1: 'Some of our advanced features include interactions with multiple smart contracts in one transaction, which is not possible when using a standard Ethereum account.',
    aave_migrate_modal_info2: 'Smart Wallet is your personal smart contract proxy wallet that holds your Aave funds and makes these advanced features possible.',
    aave_migrate_modal_info3: 'You can migrate your supplied collateral, or migrate a collateral-debt pair to your Smart Wallet.',
    aave_proxy_migrate_modal_info1:'By migrating your %protocol position to a Smart Wallet you gain access to our advanced management options such as Boost, Repay, Automated strategies and Loan Shifting.',
    aave_proxy_migrate_modal_info2:'A Smart Wallet is your personal smart contract proxy wallet that is created, owned and manageable by you and you only.',
    aave_proxy_migrate_modal_info3:'Potential steps of the migration process can include creating a Smart Wallet, providing needed token approvals and the migration transaction itself.',
    aave_proxy_migrate_modal_info4:'Learn more about the migration process and the Smart Wallet here.',
    aave_migrate_not_supplied: 'Migration is possible only for Aave accounts supplying and borrowing at least one asset.',
    aave_migrate_withdraw: 'To migrate your supplied funds, please withdraw them, and supply to your Smart Wallet manually.',
    aave_migrate_no_assets: 'No assets on your account are available for migration.\nMost often, this is because funds supplied on your Account Aave are borrowed on your Smart Wallet, or vice-versa.',
    smart_wallet_info: 'Using Aave via your Smart Wallet allows you to execute advanced operations, such as Boost and Repay. Smart Wallet is a standard MakerDAO DSProxy wallet used for easier integration with lending protocols.',
    smart_wallet_info_2: 'These funds will not be visible on the Aave app.',
    withdraw_info: 'Withdraw assets from your Aave supply',
    supply_assets_info: 'Supply your assets to start earning interest',
    borrow_info: 'Borrow assets from Aave. You need to supply assets as collateral before borrowing.',
    payback_info: 'Pay back the borrowed asset.',
    aave_automation: 'Aave Automation',
    aave_automation_description: 'Keeps your Aave position at a certain ratio to protect it from liquidation or increase leverage based on market changes.',
    aave_automation_setup: 'Aave Automation Setup',
    aave_automation_setup_description: 'Once Enabled, DeFi Saver will monitor your Aave ratio and automatically activate Repay if your Aave reaches the lower configured limit, or Boost if it reaches the upper one. Simply enter your target ratio, or configure manually below.',
    aave_automation_status: 'Aave Automation Status',
    aave_automation_active_account: 'Aave Automation is currently active for this Aave and the graph shows your current configuration.',
    aave_saver_subscribe: 'Enabling Aave Automation',
    aave_saver_unsubscribe: 'Disabling Aave Automation',
    aave_state: 'Current Aave ratio: %current%',
    no_smart_wallet_automation: 'You need to have a Aave position on a Smart Wallet in order to use Automation.',
    loading_aave_saver_data: 'Loading Aave Automation data...',
    migrate_to_proxy_description: 'Automation can only be enabled when using Aave via the DeFi Saver Smart Wallet. \nPlease switch to your Smart Wallet or migrate your Aave position if you haven\'t already.',
    aave_unsubscribe_reason: 'My Aave ratio was changed in a way I didn\'t expect would happen.',
    orig_fee: 'Origination Fee',
    migration_orig_fee_desc: 'Origination fee is a 0.00001% fee collected by Aave from borrowers. It has to be paid back directly by the borrower, so asset approval is required for migration. ',
    borrow_apy_var: 'Variable B. APY',
    borrow_apy_stable: 'Stable B. APY',
    interest_rate: 'Interest Rate',
    swap_interest_rate: 'Swap interest rate for %asset',
    change_rate: 'To %rate',
    select_rate: 'Select your interest rate',
    stable_desc: ' would be a good choice if you need to plan around a non-volatile rate over a longer period of time.',
    variable_desc: ' is based on the supply and demand for the selected asset on Aave and is re-calculated every second, meaning the rate will be lower when there is less demand for the asset.',
    can_switch: 'You can also switch between the two rates at any point in the future.',
    cant_stable_borrow: 'You can only use the stable borrow rate if you borrow more of the same asset than you have supplied.',
    unsupported_stable_borrow_asset: 'This asset is unsupported for stable rate borrow',
    change_interest_rate: 'Change interest rate',
    stake: 'Stake',
    unstake: 'unstake',
    stake_aave: 'Stake AAVE: %amount',
    unstake_aave: 'Unstake AAVE: %amount',
    activate_cooldown: 'Unstake AAVE: activate cooldown',
    migrate_lend_aave: 'Migrate %amount LEND to AAVE',
    migrate_lend_aave_desc: 'The LEND token is migrating to AAVE.',
    claim_rewards: 'Claim accrued AAVE: %amount',
    claim_stkaave: 'Claim accrued stkAAVE: %amount',
    currently_staked: 'Currently staked',
    stake_modal_title: 'AAVE Staking',
    stake_modal_desc: 'Staking AAVE in the Safety Module helps to secure the protocol in exchange for protocol incentives.',
    reward_estimates: 'Reward estimates',
    claimable_rewards: 'Claimable rewards',
    token_actions: 'Token Actions',
    stake_action_desc: 'Stake your AAVE to secure the Aave protocol and earn rewards.',
    unstake_action_desc: 'Unlocking staked funds activates a 10-day cooldown after which you can withdraw your AAVE during a specific unstaked time frame.',
    staking: 'Staking',
    stake_desc: 'Stake your AAVE',
    aave_token: 'AAVE',
    liquidation_ratio: 'Liquidation ratio',
    liquidation_ratio_desc: 'Safety ratio below which your position will face liquidation.',
    liquidation_limit: 'Liquidation limit',
    liquidation_limit_desc: 'Value of borrowed funds above which your position will face liquidation.',
    migrate_to_v2: 'Migrate from v1 to v2',
    migrate_v2_modal_title: 'Migrate to Aave v2',
    migrate_v2_modal_description: 'Migrating to Aave v2 will instantly move your whole portfolio (ie. all supplied and borrowed assets) from Aave v1 to Aave v2.',
    migrate_v2_modal_note: 'Note that the migration is only possible to your Smart Wallet on Aave v2 and there is no reverse migration back to v1 available.',
    migrate_v2_modal_automation_note: 'If you have Automation enabled on v1, it will be enabled and configured to the same settings on v2.',
    aave_v1_automation_deprication_title: 'Aave v1 Automation is being deprecated',
    aave_v1_automation_deprication_description: 'If you would like to use Automation for your Aave position, we strongly suggest migrating to Aave v2 which has lower transaction fees, as well as better liquidity and rates. You can find the migration button in the Aave dashboard.',
    migrate_from_instadapp:'Aave DSA Migration',
    migrate_modal_description_1:'Using this tool will migrate your whole Aave position from DSA, the proprietary InstaDApp proxy wallet, to a standard DSProxy.',
    migrating_position_from_instadapp:'Migrating Aave from InstaDApp',
    migrating_position_from_account:'Migrating Aave from Account',
    current_steth_eth_ratio_desc: 'Current stETH/ETH price ratio (should be pegged to 1)',
    liquidation_steth_eth_ratio_desc: 'stETH/ETH price ratio at which your position would liquidate',
    add_collateral_description: 'Deposit the selected asset into Aave.',
    withdraw_description: 'Withdraw selected asset from Aave.',
    generate_description: 'Borrow selected asset from Aave.',
    isolation_mode_description: 'Isolation mode allows to list new assets as Isolated which has a specific debt ceiling and can be only used to borrow stablecoins that have been permitted, by Aave Governance, to be borrowable in isolation mode.\nIsolated assets can only be used as collateral if there are no other supplied assets or none of the supplied assets is enabled as collateral.\nIf there are other assets supplied as collateral, the asset supported for isolation mode will not be counted as collateral.\nOnly certain assets can be borrowed against assets supported as collateral in isolation mode.',
    supplying_while_siloed: 'You\'re supplying a siloed asset which will not be counted towards your collateral value for borrowing against.',
    supplying_while_isolated: 'The asset you\'re supplying will not be counted as collateral because you are in isolation mode.',
    siloed_borrowing_desc: 'Siloed mode allows borrowing certain assets labelled as risky by Aave Governance, with the limitation being that you cannot borrow any other assets at the same time.',
    siloed_asset_desc: 'This is a siloed asset. Borrowing it will block you from borrowing any other asset at the same time.',
    isolation_asset_desc: 'This is an isolated asset. Using it as collateral will block you from using any other asset as collateral at the same time.\n',
    supplying_isolated_asset_disabled_coll: 'The asset you\'re supplying is an isolated asset and will not be counted towards your already supplied collateralized assets.',
    supplying_isolated_asset: 'You\'re entering isolated mode. In isolation mode, you can\'t supply other assets as collateral. A global debt ceiling limits the borrowing power of the isolated asset.',
    e_mode_desc: 'Efficiency mode allows supplying and borrowing assets correlated in price under preferential terms, which will usually include an increased LTV, higher liquidation threshold and a decreased liquidation bonus.\nGroups of assets that can be used in efficiency mode can be stablecoins or ETH and various liquid staking derivatives.',
    e_mode_active: 'Efficiency mode is activated',
    e_mode_activate: 'Activate efficiency mode',
    isolation_mode_activated: 'You are in isolation mode.',
    siloed_borrowing_activated: 'You are in siloed borrowing mode.',
    aave_create: 'Aave Create',
    open_position: 'Open position',
  },
  shifter: {
    shift: 'Shift',
    description: 'Shift your position between protocols or change your collateral and debt assets.',
    shift_protocol_desc: 'Shift your position between different protocols or Maker Vault types (e.g. ETH-A to ETH-B).',
    shift_coll_desc: 'Change your collateral asset to an asset with a lower Stability fee or a higher supply APY.',
    shift_debt_desc: 'Change your debt asset to a different one, for example one with a lower borrow APY.',
    error_shifting_asset_unavailable: 'Shifting %unavailable asset is not possible while shifting %shift_type',
    error_same_asset: 'Select a different %shift_type asset',
    no_position_selected: 'No position selected.',
    choose_shift_type: 'Choose what you want to shift:',
    smart_wallet_required: 'Loan Shifter can only work with positions that are on a Smart Wallet. Would you like to create a Smart Wallet and migrate your Compound position now? ',
    learn_more: 'Learn more about Loan Shifter here',
    position_on_main_acc_1: 'Have a %protocol position on your main account?',
    position_on_main_acc_2: 'Migrate it to your Smart Wallet to use the Loan Shifter',
    error_from_pos_no_coll: 'From position does not have collateral.',
    error_no_swap_coll_and_debt: 'Shifting both collateral and debt assets at the same time is not possible.',
    shift_type_not_supported: 'Shift type not supported.',
    error_shift_not_available_for_asset: 'Shift not available for %asset (no flash loan available).',
    error_shift_not_enough_liquidity: 'Not enough liquidity to execute exchange.',
  },
  reflexer: {
    safe: 'Safe',
    redemption_rate: 'Redemption Rate',
    rai_system_price_desc: 'RAI\'s moving peg or the current target price of RAI, also known as the redemption price.',
    rai_market_price_desc: 'Price at which RAI is currently trading.',
    redemption_rate_desc: 'Annual rate at which RAI is devalued or revalued, based on the difference between RAI redemption and market prices. A positive rate means the redemption price is increasing and vice-versa.',
    stability_fee_desc: 'The annual borrow rate for Reflexer Safes, calculated based on your outstanding debt and continuously added to your current debt. ',
    no_safe_found: 'No Safe Found',
    no_safe_blocker: 'There doesn\'t seem to be a Reflexer Safe associated with this account. You can create a Reflexer Safe now to borrow RAI or leverage ETH using our signature Boost and Repay features.',
    create_safe: 'Create a Safe',
    create_safe_description: 'Creating a Safe allows you to generate RAI by depositing ETH',
    safe_created_successfully: 'Safe #%safeId created successfully',
    safe_created_successfully_desc: '-',
    liquidation_ratio_explanation: 'The minimum collateralization ratio for %collType Safes. Any Safe that drops below this ratio faces liquidation.',
  },
  liquity: {
    recovery_mode_warning: 'Liquity is currently in Recovery mode, any trove with collateral ratio below 150% will get liquidated.',
    recovery_mode: 'Recovery mode',
    normal_mode: 'Normal mode',
    trove: 'Trove',
    create_trove: 'Create Trove',
    create_trove_warning: 'Your Trove will be created on your Smart Wallet. This enables the use of advanced features, but may not be compatible with other Liquity frontends. %missing_sw',
    close_trove: 'Close Trove',
    no_trove_found: 'No trove found',
    no_trove_blocker: 'There doesn\'t seem to be a Liquity Trove associated with this account.',
    no_trove_blocker_sw: 'There doesn\'t seem to be a Liquity Trove associated with this account. You can create a Liquity Trove now to borrow LUSD or leverage ETH using our signature Boost and Repay features.',
    create_trove_description: 'Creating a Trove allows you to generate LUSD by depositing ETH',
    minimum_ratio: 'Minimum accepted ratio in %mode is %min_ratio. \nWe recommend a ratio over %suggested_ratio to keep the Trove safe from liquidation',
    liquidation_ratio_explanation: 'The minimum collateralization ratio in %mode. Any Troves that fall below this ratio face liquidation.',
    liquidation_reserve_info: ' This fee covers the gas cost a liquidator would pay to liquidate your Trove. You are refunded this fee when you pay back your debt.',
    borrowing_fee_info: 'A one time fee charged as a percentage of the borrowed amount (in LUSD) and added to your Trove\'s debt. The fee varies between 0.5% and 5% depending on LUSD redemption volumes.',
    total_debt_info: 'The total amount of LUSD your Trove will hold.',
    liquidation_reserve: 'Liquidation reserve',
    borrowing_fee: 'Borrowing fee',
    total_debt: 'Total debt',
    tcr: 'Total collateralization ratio',
    tcr_short: 'TCR',
    tcr_info: 'The ratio of the Dollar value of the entire system collateral at the current ETH:USD price, to the entire system debt.',
    kickback_rate: 'Kickback rate',
    kickback_rate_info: 'A rate set by the frontend operator that determines the fraction of LQTY that will be paid out as a kickback to the Stability Providers using the frontend.',
    collateral_ratio_suggestion: 'Keeping your Collateral ratio above 150% can help avoid liquidation under Recovery mode.',
    proxy_required: 'Smart Wallet required for advanced actions.',
    lusd_market_price_info: 'Current LUSD market price in dollars',
    borrowing_fee_stats_info: 'Current fee percentage',
    debt_in_front_info: 'Amount of LUSD available for redemption in Troves with lower collateralization ratio than yours. The lower the amount, the more likely it becomes for your Trove to be redeemed against.',
    lusd_balance_info: 'LUSD balance on your wallet',
    lqty_balance_info: 'LQTY balance on your wallet',
    redemption_fee_info: 'The Redemption Fee is charged as a percentage of the redeemed Ether up to 1%. The Redemption Fee depends on LUSD redemption volumes and is 0.5% at minimum.',
    close_account: 'Closing your Trove will pay back the debt using LUSD from your account and withdraw the remaining ETH collateral to your account in the same transaction.',
    claimable_collateral_info_liq: 'Your trove has been liquidated during the Recovery mode.\nYou have %amount ETH to claim.',
    claimable_collateral_info_redeemed: 'Your trove has been fully redeemed.\nYou have %amount ETH to claim.',
    stake_action: 'Stake your LQTY',
    stake_action_desc: 'Stake your LQTY or deposit your LUSD to stability pool.',
    stake_lqty_desc: 'Stake LQTY and earn LUSD and ETH revenue from borrowing and redemption fees. Your LQTY stake will earn a share of the fees equal to your share of the total LQTY staked.',
    stake_lusd_desc: 'Deposit LUSD to the Stability Pool and earn ETH liquidation bonuses and LQTY rewards.',
    redeem_lusd_desc: 'Redeem LUSD for ETH at $1 of ETH per 1 LUSD. An additional redemption fee is charged on the redeemed amount.',
    redeem_action_desc: 'Redeem ETH from LUSD.',
    gains_info: 'Earned %asset balance',
    debt_below_min: 'Your Trove debt is under the required minimum of 2000 LUSD.',
    buying_asset_at_explanation: 'LUSD will be borrowed from the Trove and used to buy more collateral at this price',
    trove_created_successfully: 'Trove created successfully',
    trove_created_successfully_desc: 'We suggest enabling liquidation protection for your position using our Automated Strategies system.\nYou can also do this at any moment in the future.',
  },
  strategies: {
    short_description: 'Set up automated strategies to automatically adjust your positions, including automated liquidation protection, leverage management and position closing or opening.',
    legacy_title: 'Automated Leverage Management',
    legacy_description: 'Configure automated leverage management for your position, including auto-Repay for liquidation protection and (optional) auto-Boost for leverage increase.',
    smart_savings_payback_title: 'Savings Liquidation Protection',
    smart_savings_payback_description: 'Protect your %protocol from liquidation with automated debt payback using %asset deposited into Smart Savings.',
    stop_loss_title: 'Stop Loss',
    stop_loss_description: 'Fully close your position automatically once collateral asset reaches a configured minimum target price.',
    take_profit_title: 'Take Profit',
    take_profit_description: 'Fully close your position once collateral asset reaches a configured maximum target price.',
    enable_action_title: 'Enable automated strategy',
    disable_action_title: 'Disable automated strategy',
    update_action_title: 'Update automated strategy',
    no_smart_savings_selected: 'No Smart Savings protocol selected',
    warning_smart_savings_zero_balance: 'Your currently deposited balance in the selected Smart Savings protocol is 0 which would make this automated strategy impossible to run, unless you deposit funds afterwards. Are you sure you want to continue?',
    warning_smart_savings_not_enough_for_payback: 'Your currently deposited balance in the selected Smart Savings protocol is not sufficient to reach the target ratio in case your configured threshold is reached. Are you sure you want to continue?',
    strategy_unavailable_title: 'Additional strategy unavailable',
    strategy_unavailable_description: 'Enabling more than one automated strategy is currently not possible.',
    savings_strategies_fee_info: 'Fees for using Savings Liquidation Protection include transaction fees and a %feePercentage% service fee for any automated transactions that occur. There are no fees for simply keeping this automated strategy enabled and your position monitored.',
    close_strategies_fee_info: 'Fees for using Stop Loss and Take Profit strategies include a transaction fee and a %feePercentage% service fee in case the strategy gets executed and your position closed. There are no fees for simply keeping this automated strategy enabled and your position monitored.',
    leverage_strategies_fee_info: 'Fees for using Automated Leverage Management include a transaction fee and a %feePercentage% service fee on the repaid/leveraged amount. There are no fees for simply keeping this automated strategy enabled and your position monitored.',
    close_if_price_below: 'Close if price below',
    close_if_price_above: 'Close if price above',
    debt_at_least: 'Due to high transaction fees, especially during significant market movements, Automation is not recommended for smaller positions. A minimum of $%value in debt is required to enable %protocol Automation.',
  },
  recipes: {
    create_recipe: 'Create recipe',
    recipe_book: 'Recipe book',
    click_to_copy: 'Copy a link to your recipe',
    recipe_has_errors: 'Recipe contains errors',
    recipe_has_suggestions: 'Recipe contains suggestions you may want to check.',
    create_sw_description: 'A Smart Wallet is required to execute a recipe. It enables executing complex transactions and adds a layer of security protecting you from contract exploits.',
    refresh_prices_to_continue: 'Fetched prices have expired. Refresh prices to continue.',
    refresh_prices: 'Refresh prices',
    use_whole_balance_payback: 'Pay back whole debt',
    use_whole_balance_supply: 'Use whole balance',
    use_whole_balance_send: 'Use whole balance',
    use_whole_balance_withdraw: 'Use whole balance',
    whole_balance_payback: 'By checking this option, the recipe creator will use the exact amount needed to payback the debt',
    whole_balance_supply: 'By checking this option, the recipe creator will supply the whole balance from selected wallet',
    whole_balance_send: 'By checking this option, the recipe creator will send the whole balance from recipe wallet to specified address',
    whole_balance_withdraw: 'By checking this option, the recipe creator will withdraw all collateral specified address',
    nlp_header: 'Create a recipe using words - click here to try it out',
    nlp_input: 'Recipe sentence',
    nlp_parse: 'Prepare recipe',
    rc_header_desc: 'Execute complex transactions composed of multiple actions easily and safely',
    recipe_automation: 'Recipe Automation',
    quick_access: 'Quick Access',
    params_automatic: 'Recipe parameters are automatically calculated',
    recipe_fee: 'This recipe contains a sell action which includes a service fee of %fee%.'
  },
  faq: {
    what_is_automation: 'What is DFS Automation?',
    what_is_automation_answer: 'Automation is a trustless system for automatic liquidation protection or automated leverage management of collateralized debt positions that monitors users’ positions and automatically makes adjustments once the configured conditions are met.',
    what_are_requirements: 'What are the requirements for using Automation?',
    what_are_requirements_answer: 'The only two requirements for running Automation are that the user’s position is on a Smart Wallet (which is already the default behaviour for MakerDAO and Reflexer) and that a minimum debt requirement is met. Currently, the minimum required debt for \tenabling Automation is $40,000 of debt for MakerDAO and Liquity or $60,000 total debt balance in case of Compound and Aave.',
    how_does_automation_work: 'How does Automation work?',
    how_does_automation_work_answer: 'By enabling Automation, a user gives Automation smart contracts the rights to make adjustments on their position. Once Automation is enabled for any position, Automation bots will start continuously monitoring it and executing any needed adjustments as soon \tas the configured thresholds are reached.',
    when_are_tx_made: 'When are Automation transactions made?',
    when_are_tx_made_answer: 'Automated transactions are executed as soon as the position’s collateralization ratio reaches configured thresholds. This is something that will be affected by the used protocol’s price oracles, so a good way to look at it is that Automation for any given protocol runs on that protocol’s price oracles (not on any external price feed).',
    what_are_automation_fees: 'What are the fees for using Automation?',
    what_are_automation_fees_answer: 'There are two types of fees that users should know in regards to using Automation: the service fee of 0.3% (of the transaction size, \tper automated adjustment) and transaction fees (charged to the position, or, effectively, to the user\'s collateral).',
    what_are_automation_risks: 'What are the risks of using Automation?',
    what_are_automation_risks_answer: 'There are different types of risks that users should consider. Firstly, there is smart contract risk, although Automation has been audited with no issues found. Secondly, there is technical risk, where network conditions or other issues may prevent \tautomated-adjustments from being made in a timely manner. And, lastly, there is economic risk, which would ultimately be caused by the market moving against the user’s position, but should be considered in either case.',
  },
  pages: {
    terms_of_service: 'Terms of service',
    faq: 'FAQ',
    faq_long: 'Frequently Asked Questions',
    compound: 'Compound',
    smart_savings: 'Smart Savings',
    maker_dao: 'MakerDAO',
    liquity: 'Liquity',
    privacy_policy: 'Privacy Policy',
    blog: 'Blog',
    help: 'Help',
    exchange: 'Exchange',
    portfolio: 'Portfolio',
    transaction_history: 'History',
    funds_safeguard: 'Funds Safeguard',
    aave: 'Aave',
    whats_new: 'What\'s new',
    shifter: 'Loan Shifter',
    reflexer: 'Reflexer',
    recipe_creator: 'Recipe Creator',
    bridge: 'Bridge',
  },
  error: {
    slippage_hit: 'The transaction hit the slippage limit, hence execution reverted.',
    out_of_gas: 'Transaction ran out of gas.',
  },
  uniswap: {
    create_pool: 'You are the first liquidity provider for this Uniswap V3 pool. The transaction fee will be much higher as it includes gas costs for creating a new pool.',
    withdrawing_from_pool: 'Withdrawing %amount %asset from Uni V%version'
  },
  insta: {
    authorize_proxy_for_dsa:'Authorize DSProxy on DSA'
  },
  bridge: {
    outcome: 'Final Bridge outcome',
    auto_refresh: 'Routes will be auto refresh in ',
    none_available: 'No bridges are available at the moment with these parameters',
    transfered_funds: 'Your funds will be transfered in approximately ~%time minutes',
    searching: 'Searching for routes',
    current_status: 'Current status: %status',
    missing_params: 'Enter amount you want to transfer to another network'
  }
};
