import Dec from 'decimal.js';
import memoize from 'memoizee';
import { assetAmountInWei, assetAmountInEth } from '@defisaver/tokens';
import config from '../config/config.json';
import { formatPriceForContract } from './exchangeServiceCommon';
import { log } from './defisaverLogger';
import { SCP_WRAPPER } from '../config/exchangeWrappers';

const API_URL = 'https://ip84.ip-51-77-29.eu/api/v0'; // 'https://swap.symbolic.markets/api/v0';
const API_KEY = 'XQWIU4F2iSSZlrDWGIvefdiXOdn78NCafRIy9MQFoU3jXsO3yoFGa1S6WcV2NSZ6Dzwm+V6RE5zAxcODhndM9PilM9bYUNo=';
const EXCHANGE_TAKER_ADDRESS = '0x235abFAd01eb1BDa28Ef94087FBAA63E18074926';

export const getEmptyData = () => ({
  to: '0x0000000000000000000000000000000000000000',
  data: '0x00',
  price: '0',
  value: '0',
  protocolFee: '0',
  allowanceTarget: '0x0000000000000000000000000000000000000000',
  wrapper: '0x0000000000000000000000000000000000000000',
  gas: 0,
});

const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${API_KEY}`,
};
const fetchMarkets = () => fetch(`${API_URL}/markets`, {
  method: 'GET',
  headers,
});

const memoizeMarkets = memoize(fetchMarkets, { length: 0 });

/**
 * Get ScpSwap price from API
 *
 * @param sellToken {String}
 * @param buyToken {String}
 * @param amount {String}
 * @param convertAmountToWei {Boolean} should amount be converted to wei
 * @param infoOnly {Boolean} should be true if just for showing price
 * @param takerAddress {String} address that will take order from Scp
 * @param slippagePercentage {Number} percentage of slippage limit in format 0.03 (this is 3%, fixed to 2 decimal places)
 * @param shouldSell {Boolean} look for price to sell or to buy (if false sellToken becomes becomes buyToken and vice-versa)
 * @return {{data: string, price: string, protocolFee: string, to: string, value: string}}
 */
export const getScpPrice = async (sellToken, buyToken, amount, convertAmountToWei = true, infoOnly = false, takerAddress = '0x0000000000000000000000000000000000000001', slippagePercentage = 0.03, shouldSell = true) => {
  try {
    // ScpSwap data
    const profile = EXCHANGE_TAKER_ADDRESS.toLowerCase() === takerAddress.toLowerCase() ? 'defisaver_exchange' : 'defisaver_repay';

    const convertedAmount = convertAmountToWei ? assetAmountInWei(amount, shouldSell ? sellToken : buyToken) : amount;
    // buy token can only be WETH, not ETH (we convert it on contract, so user gets ETH)
    const buyTokenConverted = buyToken === 'ETH' ? 'WETH' : buyToken;
    const sellTokenConverted = sellToken === 'ETH' ? 'WETH' : sellToken;

    const marketsRes = await memoizeMarkets();

    if (!marketsRes.ok) throw new Error(await marketsRes.text());

    const markets = await marketsRes.json();
    // check if market exists
    let reverseSide = false;
    let marketId = `${sellTokenConverted}-${buyTokenConverted}`;
    let marketIndex = markets.items.findIndex(m => m.id === marketId);

    if (marketIndex === -1) {
      marketId = `${buyTokenConverted}-${sellTokenConverted}`;
      marketIndex = markets.items.findIndex(m => m.id === marketId);
      reverseSide = true;
      if (marketIndex === -1) {
        return getEmptyData();
      }
    }

    const finalAmount = assetAmountInEth(convertedAmount, shouldSell ? sellTokenConverted : buyTokenConverted);

    const body = {
      market: marketId,
      model: infoOnly ? 'indicative' : 'firm',
      profile,
      meta: {
        taker: SCP_WRAPPER,
      },
    };

    // always specify amount for base asset or value for quote asset
    // natural side is 'sell' base asset for quote asset
    if (reverseSide) {
      body.side = 'sell';
      if (shouldSell) {
        body.value = finalAmount.toString();
      } else {
        body.amount = finalAmount.toString();
      }
    } else {
      // natural side
      body.side = 'buy';
      if (shouldSell) {
        // sell X base asset for quote asset
        body.amount = finalAmount.toString();
      } else {
        // sell base asset for Y quote asset
        body.value = finalAmount.toString();
      }
    }

    const priceRes = await fetch(`${API_URL}/quotes`, {
      method: 'POST',
      headers,
      body: JSON.stringify(body),
    });

    if (!priceRes.ok) throw new Error(await priceRes.text());

    const returnData = {};
    const data = await priceRes.json();
    const _price = reverseSide ? new Dec(1).div(data.price).toString() : data.price;
    // convert price to be able to compare with other prices onchain
    returnData.price = formatPriceForContract(_price, sellToken, buyToken);

    if (!infoOnly) {
      log('scpOrder', JSON.stringify({
        request: body,
        response: data,
      }));
    }

    if (!infoOnly) {
      const order = data.data['0xv2order'];

      const libOrder = [
        order.makerAddress,
        order.takerAddress,
        order.feeRecipientAddress,
        order.senderAddress,
        order.makerAssetAmount,
        order.takerAssetAmount,
        order.makerFee,
        order.takerFee,
        order.expirationTimeSeconds,
        order.salt,
        order.makerAssetData,
        order.takerAssetData,
      ];

      const takerAssetFillAmount = convertedAmount;
      const signature = order.signature;

      const params = [libOrder, takerAssetFillAmount, signature];

      const contractFunction = config.ZeroxExchange.abi.find(abi => abi.name === 'fillOrKillOrder');
      const txData = window._web3.eth.abi.encodeFunctionCall(contractFunction, params);

      returnData.to = order.exchangeAddress;
      returnData.value = sellTokenConverted === 'WETH' ? convertedAmount : '0';
      returnData.protocolFee = '0';
      returnData.data = txData;
      returnData.allowanceTarget = '0x95E6F48254609A6ee006F7D493c8e5fB97094ceF';
      returnData.wrapper = SCP_WRAPPER;
      returnData.gas = 0;
    }

    return returnData;
  } catch (e) {
    return getEmptyData();
  }
};
