export const ADD_AAVE_MIGRATE_INSTADAPP_DATA = 'ADD_AAVE_MIGRATE_INSTADAPP_DATA ';
export const RESET_AAVE_MIGRATE_INSTADAPP_DATA = 'RESET_AAVE_MIGRATE_INSTADAPP_DATA';
export const ALLOW_AAVE_MIGRATE_INSTADAPP_DATA = 'ALLOW_AAVE_MIGRATE_INSTADAPP_DATA';
export const DENY_AAVE_MIGRATE_INSTADAPP_DATA = 'DENY_AAVE_MIGRATE_INSTADAPP_DATA';

export const AAVE_MIGRATE_PROXY_AUTH_REQUEST = 'AAVE_MIGRATE_PROXY_AUTH_REQUEST';
export const AAVE_MIGRATE_PROXY_AUTH_SUCCESS = 'AAVE_MIGRATE_PROXY_AUTH_SUCCESS';
export const AAVE_MIGRATE_PROXY_AUTH_FAILURE = 'AAVE_MIGRATE_PROXY_AUTH_FAILURE';

export const AAVE_MIGRATE_FROM_INSTADAPP_REQUEST = 'AAVE_MIGRATE_FROM_INSTADAPP_REQUEST';
export const AAVE_MIGRATE_FROM_INSTADAPP_SUCCESS = 'AAVE_MIGRATE_FROM_INSTADAPP_SUCCESS';
export const AAVE_MIGRATE_FROM_INSTADAPP_FAILURE = 'AAVE_MIGRATE_FROM_INSTADAPP_FAILURE';

export const MIGRATE_AAVE_TO_PROXY_REQUEST = 'MIGRATE_AAVE_TO_PROXY_REQUEST';
export const MIGRATE_AAVE_TO_PROXY_SUCCESS = 'MIGRATE_AAVE_TO_PROXY_SUCCESS';
export const MIGRATE_AAVE_TO_PROXY_FAILURE = 'MIGRATE_AAVE_TO_PROXY_FAILURE';

export const AAVE_MIGRATE_APPROVALS_REQUEST = 'AAVE_MIGRATE_APPROVALS_REQUEST';
export const AAVE_MIGRATE_APPROVALS_SUCCESS = 'AAVE_MIGRATE_APPROVALS_SUCCESS';
export const AAVE_MIGRATE_APPROVALS_FAILURE = 'AAVE_MIGRATE_APPROVALS_FAILURE';

export const AAVE_MIGRATE_PROXY_RESET_STATE = 'AAVE_MIGRATE_PROXY_RESET_STATE';
