import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './RefreshButton.scss';
import TooltipWrapper from '../TooltipWrapper/TooltipWrapper';
import { parseTimeDelta } from '../../services/utils';

function RefreshButton({ refresh, lastUpdated }) {
  const [_lastUpdated, setLastUpdated] = useState(Date.now());
  useEffect(() => setLastUpdated(lastUpdated || Date.now()), [lastUpdated]);

  // Re-render every second to update tooltip display
  const [time, setTime] = useState(Date.now());
  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 1000);
    return () => clearInterval(interval);
  }, []);

  const _refresh = () => {
    if (time - _lastUpdated > 10 * 1000) {
      refresh().then(() => setLastUpdated(Date.now()));
    }
  };

  return (
    <TooltipWrapper className="refresh-button-wrapper" title={`Last updated: ${parseTimeDelta(_lastUpdated, false)}`}>
      <button
        onClick={_refresh}
        type="button"
        className="button mini dark with-icon no-text refresh"
      >
        <i />
      </button>
    </TooltipWrapper>
  );
}

RefreshButton.propTypes = {
  refresh: PropTypes.func.isRequired,
  lastUpdated: PropTypes.number,
};

RefreshButton.defaultProps = {
  lastUpdated: 0,
};

export default RefreshButton;
