import {
  AAVE_SAVER_IS_SUBSCRIBED_REQUEST,
  AAVE_SAVER_IS_SUBSCRIBED_SUCCESS,
  AAVE_SAVER_IS_SUBSCRIBED_FAILURE,

  AAVE_SAVER_SUBSCRIBE_REQUEST,
  AAVE_SAVER_SUBSCRIBE_SUCCESS,
  AAVE_SAVER_SUBSCRIBE_FAILURE,
  AAVE_SAVER_SUBSCRIBE_RESET,

  AAVE_SAVER_UNSUBSCRIBE_REQUEST,
  AAVE_SAVER_UNSUBSCRIBE_SUCCESS,
  AAVE_SAVER_UNSUBSCRIBE_FAILURE,

  SET_AAVE_SAVER_UPDATING,

  GET_AAVE_SAVER_SUBSCRIBE_HISTORY_REQUEST,
  GET_AAVE_SAVER_SUBSCRIBE_HISTORY_SUCCESS,
  GET_AAVE_SAVER_SUBSCRIBE_HISTORY_LOADMORE_SUCCESS,
  GET_AAVE_SAVER_SUBSCRIBE_HISTORY_FAILURE,

  GET_AAVE_SAVER_SUBSCRIBE_GRAPH_DATA_REQUEST,
  GET_AAVE_SAVER_SUBSCRIBE_GRAPH_DATA_SUCCESS,
  GET_AAVE_SAVER_SUBSCRIBE_GRAPH_DATA_FAILURE,
} from '../../actionTypes/aaveActionTypes/aaveSaverActionTypes';
import { CONNECT_PROVIDER_SUCCESS } from '../../actionTypes/generalActionTypes';


const INITIAL_STATE = {
  subscribed: false,
  gettingIsSubscribed: false,
  gettingIsSubscribedError: '',

  subscribing: false,
  subscribingError: '',

  unsubscribing: false,
  unsubscribingError: '',

  updating: false,
  history: [],
  historyPage: 1,
  gettingHistory: false,
  gettingHistoryError: '',

  gettingGraphData: false,
  gettingGraphDataError: '',
  graphData: null,

  sendingUnsubscribeFeedback: false,
  sendingUnsubscribeFeedbackError: '',
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case
      AAVE_SAVER_IS_SUBSCRIBED_REQUEST:
      return {
        ...state,
        gettingIsSubscribed: true,
        gettingIsSubscribedError: '',
      };
    case
      AAVE_SAVER_IS_SUBSCRIBED_SUCCESS:
      return {
        ...state,
        gettingIsSubscribed: false,
        gettingIsSubscribedError: '',
        subscribed: payload.isSubscribedToAutomation,

      };
    case
      AAVE_SAVER_IS_SUBSCRIBED_FAILURE:
      return {
        ...state,
        gettingIsSubscribed: false,
        gettingIsSubscribedError: payload,

      };
    case
      AAVE_SAVER_SUBSCRIBE_REQUEST:
      return {
        ...state,
        subscribing: true,
        subscribingError: '',
      };

    case
      AAVE_SAVER_SUBSCRIBE_SUCCESS:
      return {
        ...state,
        subscribing: false,
        subscribingError: '',
        subscribed: true,
        updating: false,
      };

    case
      AAVE_SAVER_SUBSCRIBE_FAILURE:
      return {
        ...state,
        subscribing: false,
        subscribingError: payload,
      };

    case
      AAVE_SAVER_SUBSCRIBE_RESET:
      return {
        ...state,
        subscribing: false,
        subscribingError: '',
      };

    case
      AAVE_SAVER_UNSUBSCRIBE_REQUEST:
      return {
        ...state,
        unsubscribing: true,
        unsubscribingError: '',
      };
    case
      AAVE_SAVER_UNSUBSCRIBE_SUCCESS:
      return {
        ...state,
        unsubscribing: false,
        unsubscribingError: '',
        subscribed: false,
      };
    case
      AAVE_SAVER_UNSUBSCRIBE_FAILURE:
      return {
        ...state,
        unsubscribing: false,
        unsubscribingError: payload,

      };
    case
      SET_AAVE_SAVER_UPDATING:
      return {
        ...state,
        updating: payload,
      };


    case
      GET_AAVE_SAVER_SUBSCRIBE_HISTORY_REQUEST:
      return {
        ...state,
        gettingHistory: true,
        gettingHistoryError: '',
      };

    case
      GET_AAVE_SAVER_SUBSCRIBE_HISTORY_SUCCESS:
      return {
        ...state,
        gettingHistory: false,
        gettingHistoryError: '',
        history: payload.items,
        historyPage: payload.page,
      };

    case
      GET_AAVE_SAVER_SUBSCRIBE_HISTORY_LOADMORE_SUCCESS:
      return {
        ...state,
        gettingHistory: false,
        gettingHistoryError: '',
        history: [...state.history, ...payload.items],
        historyPage: payload.page,
      };

    case
      GET_AAVE_SAVER_SUBSCRIBE_HISTORY_FAILURE:
      return {
        ...state,
        gettingHistory: false,
        gettingHistoryError: payload,
      };

    case
      GET_AAVE_SAVER_SUBSCRIBE_GRAPH_DATA_REQUEST:
      return {
        ...state,
        gettingGraphData: true,
        gettingGraphDataError: '',
      };

    case
      GET_AAVE_SAVER_SUBSCRIBE_GRAPH_DATA_SUCCESS:
      return {
        ...state,
        gettingGraphData: false,
        gettingGraphDataError: '',
        graphData: state.graphData === null ? payload : { ...state.graphData, ...payload },
      };

    case
      GET_AAVE_SAVER_SUBSCRIBE_GRAPH_DATA_FAILURE:
      return {
        ...state,
        gettingGraphData: false,
        gettingGraphDataError: payload,
      };

    case
      CONNECT_PROVIDER_SUCCESS:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};
