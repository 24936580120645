import * as Sentry from '@sentry/browser';
import { version } from '../package.json';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://28008e1a2e70451689b60c79a387bb6c@o291075.ingest.sentry.io/1531697',
    release: version,
    ignoreErrors: [
      /PollingBlockTracker/i, // web3-provider-engine
      /Cannot read property 'number' of null/i, // web3-provider-engine
      /toBufferBlock/i, // web3-provider-engine
      /Invalid JSON RPC response/i,
      /User denied account/i,
      /denied the transaction/i,
      'Provider not pre-approved',
      'User closed modal',
      /Network\s?Error/i,
      /Network request failed/i,
      /User canceled/i,
      /U2F DEVICE_INELIGIBLE/i,
      /header not found/i, // Metamask provider throws this
      /Wrong network/i,
      /Not enough liquidity/i,
      /Debt ceiling reached/i,
    ],
    denyUrls: [
      /extensions\//i,
      /^chrome:\/\//i,
    ],
    integrations: [
      new Sentry.Integrations.Breadcrumbs({
        console: false,
        beacon: false,
        dom: false,
        fetch: false,
        xhr: false,
      }),
    ],
  });
}

export const captureException = (err, context) => {
  console.error(err);
  if (err instanceof Error) Sentry.captureException(err, context);
  else Sentry.captureException(new Error(err.message));
};

export const configureSentryTags = (getState) => {
  Sentry.configureScope((scope) => {
    scope.setTag('accountType', getState().general.accountType);
  });
};
