/* eslint-disable */
import React from 'react';

export default function OptimismBridgeIcon(props) {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z" fill="#FF0420"/>
      <path d="M10.5589 19.2188C9.66009 19.2188 8.92372 19 8.3497 18.5625C7.78323 18.1172 7.5 17.4844 7.5 16.6641C7.5 16.4922 7.5189 16.2812 7.55663 16.0312C7.65487 15.4687 7.79459 14.7929 7.97584 14.0039C8.48942 11.8555 9.81496 10.7812 11.9524 10.7812C12.534 10.7812 13.0551 10.8828 13.5158 11.0859C13.9766 11.2812 14.3391 11.5781 14.6034 11.9766C14.8678 12.3671 15 12.8359 15 13.3828C15 13.5468 14.9811 13.7539 14.9433 14.0039C14.83 14.6992 14.6941 15.375 14.5354 16.0312C14.2711 17.1016 13.8142 17.9023 13.1646 18.4336C12.5151 18.957 11.6465 19.2188 10.5589 19.2188ZM10.7175 17.5312C11.1405 17.5312 11.4992 17.4023 11.7938 17.1445C12.0959 16.8867 12.3112 16.4922 12.4395 15.961C12.6132 15.2266 12.7455 14.5859 12.8361 14.039C12.8663 13.875 12.8814 13.707 12.8814 13.5351C12.8814 12.8242 12.5226 12.4687 11.8051 12.4687C11.3821 12.4687 11.0196 12.5976 10.7175 12.8554C10.423 13.1133 10.2115 13.5078 10.0831 14.039C9.94713 14.5625 9.81116 15.2031 9.67525 15.961C9.645 16.1171 9.6299 16.2812 9.6299 16.4531C9.6299 17.1719 9.99247 17.5312 10.7175 17.5312Z" fill="white"/>
      <path d="M15.6955 18.75C15.6147 18.75 15.5522 18.7234 15.5081 18.6703C15.4713 18.6096 15.4604 18.5413 15.475 18.4654L16.9958 11.0659C17.0105 10.9824 17.0509 10.9141 17.117 10.8609C17.1832 10.8078 17.2529 10.7812 17.3264 10.7812H20.2578C21.0733 10.7812 21.7271 10.9558 22.2193 11.3049C22.719 11.654 22.9688 12.1587 22.9688 12.819C22.9688 13.0087 22.9467 13.206 22.9026 13.4109C22.719 14.2837 22.348 14.9288 21.7896 15.3462C21.2386 15.7636 20.4819 15.9723 19.5194 15.9723H18.0317L17.5248 18.4654C17.5101 18.5489 17.4697 18.6171 17.4036 18.6703C17.3375 18.7234 17.2676 18.75 17.1942 18.75H15.6955ZM19.5966 14.4013C19.9051 14.4013 20.1733 14.314 20.401 14.1395C20.6361 13.9649 20.7904 13.7145 20.8639 13.3882C20.8859 13.2591 20.897 13.1453 20.897 13.0466C20.897 12.8265 20.8345 12.6596 20.7096 12.5458C20.5847 12.4243 20.3717 12.3636 20.0704 12.3636H18.748L18.3292 14.4013H19.5966Z" fill="white"/>
    </svg>
  );
}
