/* eslint-disable */
import React from 'react';

export default function LiquityProtocolIcon(props) {
  return (
    <svg width="60" height="52" viewBox="0 0 60 52" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M25.7235 51.9696L25.6555 51.9695H25.5839C0.0429104 51.9695 -10.0369 17.9707 12.5229 3.64268L12.518 3.69423C16.3766 1.34871 20.8945 0 25.7235 0C39.9062 0 51.4038 11.6338 51.4038 25.9848C51.4038 28.9618 50.9086 31.8217 49.9978 34.4853C59.9911 39.7126 60 52 60 52H26.2335C25.9674 52 25.7022 51.9963 25.4393 51.9889C25.6105 51.984 25.7813 51.9776 25.9514 51.9695H25.7916L25.7235 51.9696Z" fill="white"/>
    </svg>
  );
}
