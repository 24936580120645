/* eslint-disable */
import React from 'react';
import { v4 as uuid } from 'uuid';

export default function ArbitrumBridgeIcon(props) {
  const id = `arbitrum_bridge_icon${uuid()}`;
  const fill = `url(#${id})`;
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z" fill={fill}/>
      <path d="M14.9427 17.8161L17.1421 21.2682L19.1739 20.0907L16.2853 15.5377L14.9427 17.8161Z" fill="white"/>
      <path d="M21.0242 18.7059L21.0227 17.7625L17.8664 12.8464L16.6954 14.8334L19.7423 19.7613L20.844 19.1229C20.9521 19.0351 21.0175 18.9063 21.0243 18.7673L21.0242 18.7059Z" fill="white"/>
      <path d="M7.79773 19.5955L9.35347 20.492L14.5306 12.1893L13.652 12.1661C12.9033 12.1555 12.0969 12.3502 11.7267 12.9544L8.78679 17.5112L7.79773 19.0308V19.5955Z" fill="white"/>
      <path d="M17.5186 12.1892L15.2029 12.1976L9.96368 20.8433L11.7949 21.8976L12.2929 21.0531L17.5186 12.1892Z" fill="white"/>
      <path d="M22 12.1774C21.9806 11.6931 21.7184 11.2498 21.3077 10.9917L15.5506 7.68095C15.1443 7.47638 14.6368 7.47611 14.2298 7.68082C14.1816 7.70509 8.63119 10.9241 8.63119 10.9241C8.55437 10.961 8.48037 11.0049 8.41078 11.0547C8.04412 11.3175 7.82063 11.7258 7.79767 12.1745V19.0309L8.78673 17.5112L8.7781 12.2208C8.77924 12.2015 8.78168 12.1824 8.78512 12.1636C8.80749 12.0391 8.87699 11.9281 8.98162 11.8531C9.00773 11.8344 14.653 8.56666 14.6709 8.5577C14.8036 8.49106 14.9723 8.49027 15.1051 8.55563L20.7877 11.8243C20.9221 11.9099 21.0064 12.055 21.0139 12.2137V18.7673C21.007 18.9062 20.952 19.035 20.844 19.1228L19.7423 19.7612L19.1739 20.0907L17.1421 21.2681L15.0816 22.4622C14.9706 22.5023 14.8409 22.5 14.7308 22.4553L12.2929 21.0531L11.7949 21.8978L13.9858 23.1591C14.0582 23.2003 14.1228 23.2369 14.1757 23.2666C14.2577 23.3126 14.3136 23.3433 14.3334 23.3529C14.4891 23.4286 14.7131 23.4726 14.915 23.4726C15.1001 23.4726 15.2806 23.4386 15.4514 23.3717L21.4362 19.9057C21.7797 19.6396 21.9818 19.2382 22 18.8034V12.1774Z" fill="white"/>
      <defs>
        <linearGradient id={id} x1="15" y1="0" x2="15" y2="30" gradientUnits="userSpaceOnUse">
          <stop stopColor="#509EEA"/>
          <stop offset="1" stopColor="#3B6A97"/>
        </linearGradient>
      </defs>
    </svg>
  );
}
