export const GET_UNIV3_NFT_REQUEST = 'GET_UNIV3_NFT_REQUEST';
export const GET_UNIV3_NFT_SUCCESS = 'GET_UNIV3_NFT_SUCCESS';
export const GET_UNIV3_NFT_FAILURE = 'GET_UNIV3_NFT_FAILURE';

export const APPROVE_NFT_ON_ADDRESS_REQUEST = 'APPROVE_NFT_ON_ADDRESS_REQUEST';
export const APPROVE_NFT_ON_ADDRESS_SUCCESS = 'APPROVE_NFT_ON_ADDRESS_SUCCESS';
export const APPROVE_NFT_ON_ADDRESS_FAILURE = 'APPROVE_NFT_ON_ADDRESS_FAILURE';

export const UNISWAP_V3_MOCK_POSITION_REQUEST = 'UNISWAP_V3_MOCK_POSITION_REQUEST';
export const UNISWAP_V3_MOCK_POSITION_SUCCESS = 'UNISWAP_V3_MOCK_POSITION_SUCCESS';
export const UNISWAP_V3_MOCK_POSITION_FAILURE = 'UNISWAP_V3_MOCK_POSITION_FAILURE';

export const UNISWAP_V3_POOL_REQUEST = 'UNISWAP_V3_POOL_REQUEST';
export const UNISWAP_V3_POOL_SUCCESS = 'UNISWAP_V3_POOL_SUCCESS';
export const UNISWAP_V3_POOL_FAILURE = 'UNISWAP_V3_POOL_FAILURE';

export const UNISWAP_V3_MOCK_POOL_REQUEST = 'UNISWAP_V3_MOCK_POOL_REQUEST';
export const UNISWAP_V3_MOCK_POOL_SUCCESS = 'UNISWAP_V3_MOCK_POOL_SUCCESS';
export const UNISWAP_V3_MOCK_POOL_FAILURE = 'UNISWAP_V3_MOCK_POOL_FAILURE';
