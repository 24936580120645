import { CONNECT_PROVIDER_SUCCESS } from './actionTypes/generalActionTypes';
import { closeModal } from './actions/modalActions';

/**
 * Middleware that listens for CONNECT_PROVIDER_SUCCESS actions
 * and closes any active modals. This is to close the
 * connect ledger/trezor modals after successful connection.
 *
 * @param store
 * @returns {function(*): function(*=): *}
 */
export const closeModalOnConnectMiddleware = store => next => (action) => {
  if (action && action.type === CONNECT_PROVIDER_SUCCESS) {
    store.dispatch(closeModal());
  }
  return next(action);
};
