import { captureException, configureSentryTags } from 'sentry';
import { configure } from '@defisaver/sdk';
import capitalize from 'lodash/capitalize';
import t from 'translate';
import { set as dfsTokensSetConfig } from '@defisaver/tokens';
import {
  CONNECT_PROVIDER,
  CONNECT_PROVIDER_SUCCESS,
  CONNECT_PROVIDER_FAILURE,

  LOGIN_STARTED,
  LOGIN_FINISHED,

  SET_ENS_NAME,

  CLEAR_ACCOUNT_TYPE,

  CHANGE_WALLET_TYPE,

  CONNECT_VIEW_ONLY_ACCOUNT_REQUEST,
  CONNECT_VIEW_ONLY_ACCOUNT_SUCCESS,
  CONNECT_VIEW_ONLY_ACCOUNT_FAILURE,

  SET_HIDE_HINTS, UPDATE_RECENT_ACCOUNTS, CHANGE_NETWORK,
} from '../actionTypes/generalActionTypes';
import {
  ACCOUNT_TYPES, LS_ACCOUNT, LS_ADDRESS, LS_CDP_SAVER_STATE, WALLET_NAMES,
} from '../constants/general';
import { AAVE_VERSIONS } from '../constants/aaveMarkets';
import clientConfig from '../config/clientConfig.json';
import {
  isMetaMaskApproved,
  getAccount,
  nameOfNetwork,
  getNetwork,
  metamaskApprove,
  metamaskSwitchNetwork,
  getBrowserProviderName,
} from '../services/ethService';
import {
  setWeb3toMetamask,
  setupWeb3,
  setupFormatic,
  setupWalletConnect,
  setupWalletLink,
  setupLocalWeb3,
  setupGnosisSafe,
  getMetamaskWeb3,
  setWeb3Object,
} from '../services/web3Service';
import { notify } from './noitificationActions';
import {
  getMobileOperatingSystem, isEmptyBytes,
  isMobileDevice,
  namehash,
  getEnsAddress, compareAddresses,
} from '../services/utils';
import {
  addToLsState, addToRecentAccounts,
  getLsExistingItemAndState,
  getRecentAccounts, getRecentAccountsWithState,
  setRecentAccounts,
} from '../services/localStorageService';
import { pickProxy } from './makerActions/makerActions';
import { getNexusInsurances } from './nexusActions';
import { trackEvent } from '../services/analyticsService';
import { getServerNotifications } from './serverNotificationsActions';
import { getServerTx } from './serverTxActions';
import {
  closeModal,
  confirmViaModal,
  openAddressMonitorModal, openChangeMetamaskAccountModal,
  openLedgerConnectModal,
  openSimulationStartModal,
  openTrezorConnectModal,
} from './modalActions';
import { authHelpcrunch } from './utilActions';
import { SET_SELECTED_MARKET_ACTION } from '../actionTypes/aaveActionTypes/aaveManageActionTypes';
import { changeContractsNetwork } from '../services/contractRegistryService';


/**
 * Opens the non web3 provider modal when the user is accessing the site through a mobile phone
 * @return {Function}
 */
export const openNonWeb3ProviderModal = () => (dispatch) => {
  const os = getMobileOperatingSystem();

  dispatch({
    type: 'toggle_modal',
    payload: { modalType: 'NON_WEB3_PROVIDER_MODAL', modalProps: { os, width: 481 }, action: true },
  });
};

/**
 * Checks if the connected address has a corresponding ENS reverse record
 * @return {Function}
 */
const setEnsReverseName = () => async (dispatch, getState) => {
  try {
    if (getState().general.network !== 1) return;
    const account = getState().general.account;

    const reverseDomain = `${account.substr(2)}.addr.reverse`.toLowerCase();

    window._web3.eth.ens.registry.address = '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e';
    const res = await window._web3.eth.ens.getResolver(reverseDomain);
    const payload = await res.methods.name(namehash(reverseDomain)).call();

    if (payload) {
      dispatch({ type: SET_ENS_NAME, payload, account });
      addToLsState({ account, ensName: payload });
    }
  } catch (err) {
    // console.log('The connected account has no corresponding ENS address');
  }
};

export const postLogin = () => (dispatch, getState) => {
  const { network } = getState().general;
  dfsTokensSetConfig('network', network);
  configure({ chainId: network });
  dispatch(setEnsReverseName());
  dispatch(getNexusInsurances());
  dispatch(getServerNotifications());
  dispatch(getServerTx());
  dispatch(authHelpcrunch());
  configureSentryTags(getState);
};

const successfulLogin = (account, accountType, silent, _additionalAccountData = {}, _network = null) => async (dispatch, getState) => {
  const network = _network || getState().general.network;
  setWeb3Object(network);
  changeContractsNetwork(network);

  const { proxyAddress, smartWallets } = await dispatch(pickProxy(account, accountType, network));
  const additionalAccountData = { ..._additionalAccountData, accountType };
  if (!additionalAccountData.walletName) additionalAccountData.walletName = capitalize(accountType);

  const recentAccData = {
    account,
    accountType,
    walletName: additionalAccountData.walletName,
    lastConnected: Date.now(),
  };

  if (![ACCOUNT_TYPES.debug, ACCOUNT_TYPES.viewOnly, ACCOUNT_TYPES.fork].includes(accountType)) {
    additionalAccountData.owned = true;
    recentAccData.owned = true;
  }

  addToLsState({
    account,
    ..._additionalAccountData,
  });

  addToRecentAccounts(recentAccData);

  const isSameAccount = compareAddresses(getState().general.account, account);

  dispatch({
    type: CONNECT_PROVIDER_SUCCESS,
    payload: {
      account, accountType, network, proxyAddress, smartWallets, additionalAccountData, dontClearNotifications: isSameAccount,
    },
  });

  dispatch(postLogin());

  localStorage.setItem(LS_ACCOUNT, accountType);

  trackEvent('account', 'loginSuccess', accountType, silent ? 1 : 0);
};


// TODO probably remove this and use lastConnected
/**
 * Sets that all ledger accounts in local storage lastUsed property is false
 */
const resetLsLedgerAccountsLastUsed = () => {
  let lsState = localStorage.getItem(LS_CDP_SAVER_STATE);

  if (!lsState) return;

  lsState = JSON.parse(lsState);

  lsState = lsState.map((_item) => {
    const item = { ..._item };
    if (item.accountType === ACCOUNT_TYPES.ledger) item.lastUsed = false;
    return item;
  });

  localStorage.setItem(LS_CDP_SAVER_STATE, JSON.stringify(lsState));
};

/**
 * Fetches the last saved lastUsed ledger account from ls
 */
const getLedgerLastUsedPath = () => {
  let lsState = localStorage.getItem(LS_CDP_SAVER_STATE);
  if (!lsState) throw new Error(t('errors.no_local_storage_state'));

  lsState = JSON.parse(lsState);

  const itemIndex = lsState.findIndex(item => item.lastUsed && item.accountType === ACCOUNT_TYPES.ledger);
  if (itemIndex === -1) throw new Error(t('errors.no_ledger_item'));

  return lsState[itemIndex].path;
};

/**
 * Tries to connect to the connected ledger hardwallet
 *
 * @param _path {String}
 * @param silent {Boolean}
 *
 * @return {Function}
 */
export const loginLedger = (_path, silent = false) => async (dispatch) => {
  const accountType = ACCOUNT_TYPES.ledger;
  dispatch({ type: CONNECT_PROVIDER });

  let path = _path;

  try {
    // If the path is missing that means it is a silent login
    if (silent) path = getLedgerLastUsedPath();

    const network = clientConfig.network;
    const ledgerService = await import(/* webpackChunkName: "wallets" */ '../services/ledgerService');
    const account = await ledgerService.login(path, dispatch);

    resetLsLedgerAccountsLastUsed();
    if (!silent) notify(t('account.account_found', { '%type': 'Ledger', '%account': account }), 'success')(dispatch);
    await dispatch(successfulLogin(account, accountType, silent, { path, lastUsed: true }));
  } catch (err) {
    dispatch({ type: CLEAR_ACCOUNT_TYPE });
    dispatch({ type: CONNECT_PROVIDER_FAILURE, payload: err.message });
    setupWeb3(clientConfig.supportedNetworks[0]);
    if (!silent) {
      notify(err.message, 'error')(dispatch);
      captureException(err);
    }
    trackEvent('account', `loginErrorLedger${silent ? 'Silent' : ''}`, err.message);

    throw new Error('login failed');
  }
};

/**
 * Tries to connect to the connected trezor hardwallet
 *
 * @param path
 * @return {Function}
 */
export const loginTrezor = path => async (dispatch) => {
  const accountType = ACCOUNT_TYPES.trezor;
  dispatch({ type: CONNECT_PROVIDER });

  try {
    const network = clientConfig.network;
    const trezorService = await import(/* webpackChunkName: "wallets" */ '../services/trezorService');
    const account = await trezorService.login(path, dispatch);

    notify(t('account.account_found', { '%type': 'Trezor', '%account': account }), 'success')(dispatch);
    await dispatch(successfulLogin(account, accountType, false, { path }));
  } catch (err) {
    dispatch({ type: CLEAR_ACCOUNT_TYPE });
    dispatch({ type: CONNECT_PROVIDER_FAILURE, payload: err.message });
    setupWeb3(clientConfig.supportedNetworks[0]);
    trackEvent('account', 'loginErrorTrezor', err.message);
    captureException(err);
    throw new Error('login failed');
  }
};

/**
 * Tries to connect to the MetaMask web3 provider, also checks if the app is pre-approved
 *
 * @param silent {Boolean}
 *
 * @return {Function}
 */
export const loginMetaMask = silent => async (dispatch) => {
  const accountType = ACCOUNT_TYPES.browser;
  dispatch({ type: CONNECT_PROVIDER });
  const walletType = getBrowserProviderName();

  try {
    const metaMaskApproved = await isMetaMaskApproved();

    if (silent && !metaMaskApproved) {
      throw new Error(t('errors.provider_not_approved'));
    }

    await metamaskApprove();
    setWeb3toMetamask();

    let network = await getNetwork();

    if (!clientConfig.supportedNetworks.includes(network)) {
      const switchTo = clientConfig.supportedNetworks[0];
      const switchSuccessful = await metamaskSwitchNetwork(clientConfig.supportedNetworks[0]);
      if (switchSuccessful !== true) {
        throw new Error(t('errors.wrong_network', { '%type': walletType, '%network': nameOfNetwork(switchTo) }));
      } else {
        network = switchTo;
      }
    }

    const account = await getAccount();
    const walletName = getBrowserProviderName();

    if (!silent) notify(t('account.account_found', { '%type': walletType, '%account': account }), 'success')(dispatch);
    await dispatch(successfulLogin(account, accountType, silent, { walletName }, network));
  } catch (err) {
    dispatch({ type: CLEAR_ACCOUNT_TYPE });
    dispatch({ type: CONNECT_PROVIDER_FAILURE, payload: err.message });

    setupWeb3(clientConfig.supportedNetworks[0]);

    if (!silent) {
      let errorMessage = err.message || err;
      if (errorMessage.includes('User denied account authorization')) {
        errorMessage = t('errors.denied_login');
      }
      if (errorMessage.includes('wallet address undefined')) {
        errorMessage = t('errors.no_accounts_locked');
      }
      notify(errorMessage, 'error')(dispatch);
      if (getBrowserProviderName() === WALLET_NAMES.browser && isMobileDevice()) dispatch(openNonWeb3ProviderModal());
      captureException(err);
    }
    trackEvent('account', `loginErrorMetamask${silent ? 'Silent' : ''}`, err.message);

    throw new Error(err);
  }
};

export const changeNetwork = (chainId) => async (dispatch, getState) => {
  try {
    const success = await metamaskSwitchNetwork(chainId);
    if (success === true) {
      changeContractsNetwork(chainId);
      dfsTokensSetConfig('network', chainId);
      configure({ chainId });
      dispatch(closeModal());
      if (getState().general.accountType === ACCOUNT_TYPES.fork) {
        // eslint-disable-next-line no-use-before-define
        dispatch(logOutFork()); // TODO Temporary solution
      }
      dispatch({ type: CHANGE_NETWORK, payload: chainId });
    } else { throw new Error(success.message); }
  } catch (err) {
    console.error(err);
    notify(err.message, 'error')(dispatch);
    throw new Error(err.message);
  }
};

/**
 * Mocks wallet with address from window.debugWithAccount
 *
 * @return {Function}
 */
export const loginDebug = () => async (dispatch, getState) => {
  const accountType = ACCOUNT_TYPES.debug;
  dispatch({ type: CONNECT_PROVIDER });

  try {
    setupWeb3(getState().general.network);
    const account = window.debugWithAccount || localStorage.getItem('debugWithAccount');
    await new Promise(res => setTimeout(res, 500));
    if (!account) throw new Error('No account set');
    notify(t('account.debugging_account'), 'success')(dispatch);
    await dispatch(successfulLogin(account, accountType, false));
    localStorage.setItem('debugWithAccount', account);
  } catch (err) {
    dispatch({ type: CLEAR_ACCOUNT_TYPE });
    dispatch({ type: CONNECT_PROVIDER_FAILURE, payload: err.message });

    notify(err.message || err, 'error')(dispatch);
    if (getBrowserProviderName() === WALLET_NAMES.browser && isMobileDevice()) dispatch(openNonWeb3ProviderModal());

    throw new Error(err);
  }
};

/**
 * Mocks wallet with address from local storage to monitor data
 *
 * @return {Function}
 */
export const loginViewOnly = (silent = false, account) => async (dispatch, getState) => {
  const accountType = ACCOUNT_TYPES.viewOnly;
  dispatch({ type: CONNECT_PROVIDER });

  try {
    setupWeb3(getState().general.network);
    const network = clientConfig.network;
    const account = (localStorage.getItem('viewOnlyAccount') || '').toLowerCase().trim();
    await new Promise(res => setTimeout(res, 500));
    if (!account) throw new Error('No account set');
    notify(t('account.monitoring_account'), 'success')(dispatch);
    await dispatch(successfulLogin(account, accountType, false));
    localStorage.setItem('viewOnlyAccount', account);
    dispatch({ type: CONNECT_VIEW_ONLY_ACCOUNT_SUCCESS });
  } catch (err) {
    dispatch({ type: CLEAR_ACCOUNT_TYPE });
    dispatch({ type: CONNECT_PROVIDER_FAILURE, payload: err.message });

    notify(err.message || err, 'error')(dispatch);
    if (getBrowserProviderName() === WALLET_NAMES.browser && isMobileDevice()) dispatch(openNonWeb3ProviderModal());

    throw new Error(err);
  }
};

/**
 * Tries to connect to Fortmatic
 *
 * @param silent {Boolean}
 * @return {Function}
 */
export const loginFortmatic = silent => async (dispatch) => {
  const accountType = ACCOUNT_TYPES.fortmatic;
  dispatch({ type: CONNECT_PROVIDER });

  try {
    const fm = await setupFormatic();
    const accounts = await fm.user.login();

    if (accounts.length > 0) {
      const account = await getAccount();
      if (!silent) notify(t('account.account_found', { '%type': 'Fortmatic', '%account': account }), 'success')(dispatch);
      await dispatch(successfulLogin(account, accountType, silent));
    }
  } catch (err) {
    dispatch({ type: CLEAR_ACCOUNT_TYPE });
    dispatch({ type: CONNECT_PROVIDER_FAILURE, payload: err.message });

    setupWeb3(clientConfig.supportedNetworks[0]);

    if (!silent) {
      notify(err.message, 'error')(dispatch);
      captureException(err);
    }
    trackEvent('account', `loginErrorFortmatic${silent ? 'Silent' : ''}`, err.message);

    throw new Error('login failed');
  }
};

export const loginWalletConnect = silent => async (dispatch) => {
  const accountType = ACCOUNT_TYPES.walletConnect;
  dispatch({ type: CONNECT_PROVIDER });

  try {
    await setupWalletConnect();
    const accounts = await window._web3.eth.getAccounts();

    if (accounts.length > 0) {
      const account = await getAccount();
      let walletName = '';
      try {
        const wc = await window._web3.currentProvider._providers[2].getWalletConnector();
        // const wcIcon = wc.peerMeta.icons[0];
        walletName = (wc?.peerMeta?.name || '')
          .replace(/[^0-9a-zA-Z_ ]/g, '')
          .replace(/ Wallet$/g, '')
          .trim();
      } catch (e) {
        console.error(e);
      }
      if (!silent) notify(t('account.account_found', { '%type': 'WalletConnect', '%account': account }), 'success')(dispatch);
      await dispatch(successfulLogin(account, accountType, silent, { walletName }));
    }
  } catch (err) {
    dispatch({ type: CLEAR_ACCOUNT_TYPE });
    dispatch({ type: CONNECT_PROVIDER_FAILURE, payload: err.message });

    setupWeb3(clientConfig.supportedNetworks[0]);

    if (!silent) {
      notify(err.message, 'error')(dispatch);
      captureException(err);
    }

    trackEvent('account', `loginErrorWalletConnect${silent ? 'Silent' : ''}`, err.message);

    throw new Error('login failed');
  }
};

export const loginWalletLink = silent => async (dispatch) => {
  const accountType = ACCOUNT_TYPES.coinbase;
  dispatch({ type: CONNECT_PROVIDER });

  try {
    const account = await setupWalletLink();
    if (!silent) notify(t('account.account_found', { '%type': 'Coinbase', '%account': account }), 'success')(dispatch);
    await dispatch(successfulLogin(account, accountType, silent));
  } catch (err) {
    dispatch({ type: CLEAR_ACCOUNT_TYPE });
    dispatch({ type: CONNECT_PROVIDER_FAILURE, payload: err.message });

    setupWeb3(clientConfig.supportedNetworks[0]);

    if (!silent) {
      notify(err.message, 'error')(dispatch);
      captureException(err);
    }

    trackEvent('account', `loginErrorWalletLink${silent ? 'Silent' : ''}`, err.message);

    throw new Error('login failed');
  }
};

export const loginFork = (silent, _account = '', _forkAccountType = '') => async (dispatch, getState) => {
  const accountType = ACCOUNT_TYPES.fork;
  dispatch({ type: CONNECT_PROVIDER });
  dispatch(closeModal());
  try {
    // when not connected, network or getNetwork will return 1 even tho the MM is connected to another chain
    let network = +window?.ethereum?.networkVersion || getState().general.network;
    // When logged out and trying to simulate pickProxy will use wrong contracts unless we change network here
    changeContractsNetwork(network);
    let forkId;
    let account = _account;
    let forkAccountType = _forkAccountType;
    let forkCreated;
    if (silent) {
      const { existingItem } = getLsExistingItemAndState(account);
      forkId = existingItem.forkId;
      account = existingItem.account;
      network = existingItem.network;
      forkAccountType = existingItem.forkAccountType;
      forkCreated = existingItem.forkCreated;
    } else {
      const res = await fetch(`${clientConfig.apiUrl}/api/fork`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ account: _account, networkId: network }),
      });
      let blockNumber;
      ({ forkId, account, blockNumber } = await res.json());
      forkCreated = Date.now();

      addToLsState({
        account, forkId, forkAccountType, network, forkCreated: Date.now(),
      });
      addToRecentAccounts({
        account,
        accountType,
        network,
        lastConnected: Date.now(),
        owned: false,
        walletName: 'Fork',
      });
    }

    await setupLocalWeb3(forkId);
    setWeb3Object(network);

    const { proxyAddress, smartWallets } = await dispatch(pickProxy(account, accountType, network));
    dispatch({
      type: CONNECT_PROVIDER_SUCCESS,
      payload: {
        account, accountType, network, forkAccountType, forkCreated, forkId, proxyAddress, smartWallets, additionalAccountData: { accountType, walletName: 'Simulation' },
      },
    });
    dispatch(postLogin());
    localStorage.setItem(LS_ACCOUNT, accountType);
    localStorage.setItem(LS_ADDRESS, account);
    if (!silent) notify(t('account.account_found', { '%type': 'Simulation', '%account': account }), 'success')(dispatch);
  } catch (err) {
    dispatch({ type: CLEAR_ACCOUNT_TYPE });
    dispatch({ type: CONNECT_PROVIDER_FAILURE, payload: err.message });
    setupWeb3(clientConfig.supportedNetworks[0]);
    if (!silent) {
      notify(err.message, 'error')(dispatch);
      captureException(err);
    }
    console.error(err);

    trackEvent('account', `loginErrorFork${silent ? 'Silent' : ''}`, err.message);

    throw new Error('login failed');
  }
};

export const loginGnosisSafe = (silent) => async (dispatch) => {
  const accountType = ACCOUNT_TYPES.gnosisSafe;
  const walletName = WALLET_NAMES.gnosisSafe;
  dispatch({ type: CONNECT_PROVIDER });
  try {
    const account = await setupGnosisSafe();
    if (!silent) notify(t('account.account_found', { '%type': walletName, '%account': account }), 'success')(dispatch);
    await dispatch(successfulLogin(account, accountType, silent, { walletName }));
  } catch (err) {
    dispatch({ type: CLEAR_ACCOUNT_TYPE });
    dispatch({ type: CONNECT_PROVIDER_FAILURE, payload: err.message });

    setupWeb3(1);

    if (!silent) {
      notify(err.message, 'error')(dispatch);
      captureException(err);
    }

    trackEvent('account', 'loginErrorGnosis', err.message);

    throw new Error('login failed');
  }
};

/**
 * Gets LS state values for account and initializes them in the reducers
 *
 * @return {Function}
 */
export const setLsValuesToReducer = () => (dispatch, getState) => {
  const { account } = getState().general;
  if (!account) return;

  const { existingItem } = getLsExistingItemAndState(account);
  const hints = localStorage.getItem('hideHints');
  if (!existingItem) return;

  // Set values from ls to reducer here if needed
  if (existingItem.walletType) dispatch({ type: CHANGE_WALLET_TYPE, payload: existingItem.walletType });
  if (existingItem.aaveMarket) {
    const aaveMarket = AAVE_VERSIONS(getState().general.network).find(version => version.value === existingItem.aaveMarket);

    if (aaveMarket) dispatch({ type: SET_SELECTED_MARKET_ACTION, payload: aaveMarket });
  }
  if (hints) dispatch({ type: SET_HIDE_HINTS, payload: hints });
};

/**
 * If the user has already once successfully added an account this will
 * try a silent login for that account type.
 *
 * @return {Function}
 */
export const silentLogin = () => async (dispatch, getState) => {
  const { accountType, account } = getState().general;
  if (account) return;

  dispatch({ type: LOGIN_STARTED, payload: accountType });

  try {
    if (window.parent !== window) await dispatch(loginGnosisSafe());
    else {
      switch (accountType) {
        case ACCOUNT_TYPES.browser: {
          await dispatch(loginMetaMask(true));
          break;
        }

        case ACCOUNT_TYPES.ledger: {
          await dispatch(loginLedger('', true));
          break;
        }

        case ACCOUNT_TYPES.fortmatic: {
          await dispatch(loginFortmatic(true));
          break;
        }

        case ACCOUNT_TYPES.walletConnect: {
          await dispatch(loginWalletConnect(true));
          break;
        }

        case ACCOUNT_TYPES.coinbase: {
          await dispatch(loginWalletLink(true));
          break;
        }

        case ACCOUNT_TYPES.debug: {
          await dispatch(loginDebug());
          break;
        }

        case ACCOUNT_TYPES.viewOnly: {
          await dispatch(loginViewOnly(true, localStorage.getItem('viewOnlyAccount')));
          break;
        }

        case ACCOUNT_TYPES.fork: {
          await dispatch(loginFork(true, localStorage.getItem(LS_ADDRESS)));
          break;
        }

        case ACCOUNT_TYPES.gnosisSafe: {
          await dispatch(loginGnosisSafe(true));
          break;
        }

        default:
          console.error('Unknown wallet type', accountType);
      }
    }
  } catch (err) {
    console.error(err);
  }

  dispatch(setLsValuesToReducer());

  dispatch({ type: LOGIN_FINISHED });
};

/**
 * Tries not silent login for the selected account type
 *
 * @param accountType {String}
 * @param path {String}
 *
 * @return {Function}
 */
export const normalLogin = (accountType, path = '') => async (dispatch) => {
  dispatch({ type: LOGIN_STARTED, payload: accountType });

  try {
    switch (accountType) {
      case ACCOUNT_TYPES.trezor: {
        await dispatch(loginTrezor(path));
        break;
      }

      case ACCOUNT_TYPES.ledger: {
        await dispatch(loginLedger(path));
        break;
      }

      case ACCOUNT_TYPES.fortmatic: {
        await dispatch(loginFortmatic(false));
        break;
      }

      case ACCOUNT_TYPES.walletConnect: {
        await dispatch(loginWalletConnect(false));
        break;
      }

      case ACCOUNT_TYPES.coinbase: {
        await dispatch(loginWalletLink(false));
        break;
      }

      case ACCOUNT_TYPES.debug: {
        await dispatch(loginDebug(false));
        break;
      }

      case ACCOUNT_TYPES.viewOnly: {
        await dispatch(loginViewOnly());
        break;
      }

      case ACCOUNT_TYPES.fork: {
        await dispatch(loginFork());
        break;
      }

      case ACCOUNT_TYPES.gnosisSafe: {
        await dispatch(loginGnosisSafe());
        break;
      }

      default:
        await dispatch(loginMetaMask(false));
    }

    // if (getState().general.account) await dispatch(getProxyAddress());
  } catch (err) {
    console.error('LOGIN ERROR', err);
  }
  dispatch(setLsValuesToReducer());
  dispatch({ type: LOGIN_FINISHED });
};

export const connectTo = provider => async (dispatch) => {
  switch (provider.value) {
    case ACCOUNT_TYPES.ledger:
      await dispatch(openLedgerConnectModal());
      break;
    case ACCOUNT_TYPES.trezor:
      await dispatch(openTrezorConnectModal());
      break;
    case ACCOUNT_TYPES.viewOnly:
      await dispatch(openAddressMonitorModal());
      break;
    case ACCOUNT_TYPES.fork:
      await dispatch(openSimulationStartModal());
      break;
    default:
      // MetaMask and others
      await dispatch(normalLogin(provider.value));
      break;
  }
};

export const connectViewOnlyAccount = (value, closeModal = () => {}) => async (dispatch) => {
  dispatch({ type: CONNECT_VIEW_ONLY_ACCOUNT_REQUEST });
  let address = value.toLowerCase().trim();
  let saveEns = false;
  try {
    if (!(new RegExp(/0x[0-9a-fA-F]{40}/).test(value))) {
      address = await getEnsAddress(value);
      if (!address || isEmptyBytes(address)) {
        throw new Error('There is no address with this ENS');
      }
      saveEns = true;
      address = address.toLowerCase().trim();
    }

    if (!localStorage.getItem('viewOnlyHistory')) localStorage.setItem('viewOnlyHistory', '[]');
    let addresses = JSON.parse(localStorage.getItem('viewOnlyHistory'));

    if (!addresses.includes(address) && !addresses.includes(value)) {
      if (addresses.length === 5) {
        addresses = addresses.slice(1);
      }
      if (saveEns) {
        addresses.push(value);
      } else {
        addresses.push(address);
      }
    }

    localStorage.setItem('viewOnlyHistory', JSON.stringify(addresses));
    localStorage.setItem('viewOnlyAccount', address);
    closeModal();
    dispatch(loginViewOnly());
    // dispatch({ type: CONNECT_VIEW_ONLY_ACCOUNT_SUCCESS });
  } catch (err) {
    dispatch({ type: CONNECT_VIEW_ONLY_ACCOUNT_FAILURE });
    console.error(err);
    notify(err.message, 'error')(dispatch);
  }
};

export const getViewOnlyAccountsFromLs = () => {
  if (!localStorage.getItem('viewOnlyHistory')) localStorage.setItem('viewOnlyHistory', '[]');
  return JSON.parse(localStorage.getItem('viewOnlyHistory'));
};

export const changeHideHints = () => async (dispatch, getState) => {
  const { hideHints } = getState().general;
  const confirmed = await dispatch(confirmViaModal('Are you sure you want to hide all action hints?'));
  if (!confirmed) return false;
  dispatch({ type: SET_HIDE_HINTS, payload: !hideHints });

  localStorage.setItem('hideHints', JSON.stringify(!hideHints));
};

export const setAccountAsOwned = (account, accountType) => (dispatch) => {
  addToRecentAccounts({ account, accountType, owned: true });
  dispatch({ type: UPDATE_RECENT_ACCOUNTS, payload: getRecentAccountsWithState() });
};

export const setAccountAsNotOwned = (account, accountType) => (dispatch) => {
  addToRecentAccounts({
    account,
    accountType,
    owned: false,
  });
  dispatch({ type: UPDATE_RECENT_ACCOUNTS, payload: getRecentAccountsWithState() });
};

export const removeAccountFromRecents = (account, accountType) => (dispatch) => {
  const newAccounts = getRecentAccounts().filter(item => !compareAddresses(item.account, account) || item.accountType !== accountType);
  setRecentAccounts(newAccounts);
  dispatch({ type: UPDATE_RECENT_ACCOUNTS, payload: getRecentAccountsWithState() });
};

export const logOut = () => (dispatch, getState) => {
  const { account, accountType } = getState().general;
  if (accountType === ACCOUNT_TYPES.walletConnect) {
    // eslint-disable-next-line no-unused-expressions
    window?._web3?.currentProvider?.connection?.close();
    localStorage.removeItem(ACCOUNT_TYPES.walletConnect);
    dispatch(removeAccountFromRecents(account, accountType));
  }
  if (accountType === ACCOUNT_TYPES.coinbase) {
    // eslint-disable-next-line no-unused-expressions
    window?._web3?.currentProvider?.close();
    localStorage.removeItem(LS_ACCOUNT);
    dispatch(removeAccountFromRecents(account, accountType));
  }
  dispatch({ type: CLEAR_ACCOUNT_TYPE });
  trackEvent('account', 'disconnect');
};

export const logOutFork = () => (dispatch, getState) => {
  dispatch(closeModal());
  const { forkAccountType } = getState().general;
  localStorage.removeItem(LS_ACCOUNT);
  if (forkAccountType) {
    dispatch(normalLogin(forkAccountType));
  } else {
    dispatch({ type: CLEAR_ACCOUNT_TYPE });
  }
};

export const switchToAccount = (accountType, account) => async (dispatch) => {
  const accountData = getRecentAccounts().find(item => compareAddresses(item.account, account) && item.accountType === accountType);
  switch (accountType) {
    case ACCOUNT_TYPES.browser:
      await metamaskApprove();
      // eslint-disable-next-line no-case-declarations
      const web3 = getMetamaskWeb3();
      // eslint-disable-next-line no-case-declarations
      const [selectedAccount] = await web3.eth.getAccounts();
      if (!compareAddresses(selectedAccount, account)) {
        return dispatch(openChangeMetamaskAccountModal({ selectedAccount, targetAccount: account }));
      }
      return dispatch(normalLogin(accountType));
    case ACCOUNT_TYPES.viewOnly:
      return dispatch(connectViewOnlyAccount(account));
    default:
      return dispatch(normalLogin(accountType, accountData.path));
  }
};
