import * as strategiesActionTypes from '../../actionTypes/strategiesActionTypes/strategiesActionTypes';

const actionsStates = {
  subscribing: {},
  subscribingErr: {},

  updating: {},
  updatingErr: {},

  updatingAndActivating: {},
  updatingAndActivatingErr: {},

  activating: {},
  activatingErr: {},

  deactivating: {},
  deactivatingErr: {},
};

const INITIAL_STATE = {
  subscribedStrategies: [],
  gettingSubscribedStrategies: false,
  gettingSubscribedStrategiesErr: '',

  statusGraphCurrentValues: {},

  strategiesTxHistory: [],
  gettingStrategiesTxHistory: false,
  gettingStrategiesTxHistoryErr: '',

  ...actionsStates,
};

export default (state = INITIAL_STATE, action) => {
  const {
    type, payload, protocol, strategyName,
  } = action;

  switch (type) {
    case strategiesActionTypes.GET_SUBSCRIBED_STRATEGIES_REQUEST:
      return {
        ...state,
        gettingSubscribedStrategies: true,
        gettingSubscribedStrategiesErr: '',
      };

    case strategiesActionTypes.GET_SUBSCRIBED_STRATEGIES_SUCCESS:
      return {
        ...state,
        subscribedStrategies: payload,
        gettingSubscribedStrategies: false,
        gettingSubscribedStrategiesErr: '',
      };

    case strategiesActionTypes.GET_SUBSCRIBED_STRATEGIES_FAILURE:
      return {
        ...state,
        gettingSubscribedStrategies: false,
        gettingSubscribedStrategiesErr: payload,
      };

    case strategiesActionTypes.RESET_SUBSCRIPTION_ACTIONS:
      return {
        ...state,
        ...actionsStates,
      };

    case strategiesActionTypes.SET_STATUS_GRAPH_FROM_FORM:
      return {
        ...state,
        statusGraphCurrentValues: payload,
      };

    case strategiesActionTypes.SUBSCRIBE_TO_STRATEGY_REQUEST:
      return {
        ...state,
        subscribing: {
          [`${protocol}-${strategyName}`]: true,
        },
        subscribingErr: {
          [`${protocol}-${strategyName}`]: '',
        },
      };

    case strategiesActionTypes.SUBSCRIBE_TO_STRATEGY_SUCCESS:
      return {
        ...state,
        subscribing: {
          [`${protocol}-${strategyName}`]: false,
        },
        subscribingErr: {
          [`${protocol}-${strategyName}`]: '',
        },
      };

    case strategiesActionTypes.SUBSCRIBE_TO_STRATEGY_FAILURE:
      return {
        ...state,
        subscribing: {
          [`${protocol}-${strategyName}`]: false,
        },
        subscribingErr: {
          [`${protocol}-${strategyName}`]: payload,
        },
      };

    case strategiesActionTypes.UPDATE_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        updating: {
          [`${protocol}-${strategyName}`]: true,
        },
        updatingErr: {
          [`${protocol}-${strategyName}`]: '',
        },
      };

    case strategiesActionTypes.UPDATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        updating: {
          [`${protocol}-${strategyName}`]: false,
        },
        updatingErr: {
          [`${protocol}-${strategyName}`]: '',
        },
      };

    case strategiesActionTypes.UPDATE_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        updating: {
          [`${protocol}-${strategyName}`]: false,
        },
        updatingErr: {
          [`${protocol}-${strategyName}`]: payload,
        },
      };

    case strategiesActionTypes.UPDATE_AND_ACTIVATE_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        updatingAndActivating: {
          [`${protocol}-${strategyName}`]: true,
        },
        updatingAndActivatingErr: {
          [`${protocol}-${strategyName}`]: '',
        },
      };

    case strategiesActionTypes.UPDATE_AND_ACTIVATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        updatingAndActivating: {
          [`${protocol}-${strategyName}`]: false,
        },
        updatingAndActivatingErr: {
          [`${protocol}-${strategyName}`]: '',
        },
      };

    case strategiesActionTypes.UPDATE_AND_ACTIVATE_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        updatingAndActivating: {
          [`${protocol}-${strategyName}`]: false,
        },
        updatingAndActivatingErr: {
          [`${protocol}-${strategyName}`]: payload,
        },
      };

    case strategiesActionTypes.ACTIVATE_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        activating: {
          [`${protocol}-${strategyName}`]: true,
        },
        activatingErr: {
          [`${protocol}-${strategyName}`]: '',
        },
      };

    case strategiesActionTypes.ACTIVATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        activating: {
          [`${protocol}-${strategyName}`]: false,
        },
        activatingErr: {
          [`${protocol}-${strategyName}`]: '',
        },
      };

    case strategiesActionTypes.ACTIVATE_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        activating: {
          [`${protocol}-${strategyName}`]: false,
        },
        activatingErr: {
          [`${protocol}-${strategyName}`]: payload,
        },
      };

    case strategiesActionTypes.DEACTIVATE_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        deactivating: {
          [`${protocol}-${strategyName}`]: true,
        },
        deactivatingErr: {
          [`${protocol}-${strategyName}`]: '',
        },
      };

    case strategiesActionTypes.DEACTIVATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        deactivating: {
          [`${protocol}-${strategyName}`]: false,
        },
        deactivatingErr: {
          [`${protocol}-${strategyName}`]: '',
        },
      };

    case strategiesActionTypes.DEACTIVATE_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        deactivating: {
          [`${protocol}-${strategyName}`]: false,
        },
        deactivatingErr: {
          [`${protocol}-${strategyName}`]: payload,
        },
      };

    case strategiesActionTypes.GET_STRATEGIES_TX_HISTORY_REQUEST:
      return {
        ...state,
        gettingStrategiesTxHistory: true,
        gettingStrategiesTxHistoryErr: '',
      };

    case strategiesActionTypes.GET_STRATEGIES_TX_HISTORY_SUCCESS:
      return {
        ...state,
        strategiesTxHistory: payload,
        gettingStrategiesTxHistory: false,
        gettingStrategiesTxHistoryErr: '',
      };

    case strategiesActionTypes.GET_STRATEGIES_TX_HISTORY_FAILURE:
      return {
        ...state,
        gettingStrategiesTxHistory: false,
        gettingStrategiesTxHistoryErr: payload,
      };

    default:
      return state;
  }
};
