import { getInstaAccount, getInstaV2Account, InstaDSAResolverContract } from './contractRegistryService';
import { requireAddress } from './utils';

export const isProxyAuthorizedForInstaAccount = async (dsaAccount, version, proxyAddress) => {
  try {
    requireAddress(proxyAddress);
    requireAddress(dsaAccount);
  } catch (err) {
    return false;
  }
  let instaAccount;
  if (version === 1) {
    instaAccount = getInstaAccount(dsaAccount);
  } else {
    instaAccount = getInstaV2Account(dsaAccount);
  }
  return instaAccount.methods.isAuth(proxyAddress).call();
};
export const getInstadappAccounts = async (address) => {
  const registry = await InstaDSAResolverContract();
  const { IDs, accounts, versions } = await registry.methods.getAuthorityDetails(address).call();
  return IDs.map((id, i) => ({
    id,
    account: accounts[i],
    version: versions[i],
  }));
};
export const getInstadappAccountsWithAuthorized = async (address, proxyAddress) => {
  const instadappAccounts = await getInstadappAccounts(address);
  const promises = instadappAccounts.map(async ({ id, account, version }) => {
    const isAuthorized = await isProxyAuthorizedForInstaAccount(account, version, proxyAddress);
    return {
      id,
      account,
      version,
      isAuthorizedForProxy: isAuthorized,
    };
  });
  return Promise.all(promises);
};
