import { assets, getAssetInfo, ilkToAsset } from '@defisaver/tokens';
import { isEmptyBytes, stringToBytes } from '../services/utils';
import { getAaveMarketInfoFromValue } from './aaveMarkets';

export const supportedIlks = [
  'ETH-A', 'ETH-B', 'ETH-C', 'WSTETH-A', 'WSTETH-B',
  'CRVV1ETHSTETH-A',
  'WBTC-A', 'WBTC-B', 'WBTC-C',
  'LINK-A', 'YFI-A', 'UNI-A', 'RENBTC-A', 'MATIC-A', 'MANA-A', 'GUSD-A',
  'GUNIV3DAIUSDC1-A', 'GUNIV3DAIUSDC2-A',
  'UNIV2DAIUSDC-A', 'UNIV2USDCETH-A', 'UNIV2DAIETH-A', 'UNIV2WBTCDAI-A',
  'UNIV2WBTCETH-A', 'UNIV2UNIETH-A',
];

export const compoundCollateralAssets = [
  'cETH', 'cDAI', 'cBAT', 'cZRX', 'cREP', 'cUSDC', 'cWBTC Legacy', 'cWBTC', 'cUSDT',
  'cTUSD', 'cLINK', 'cUSDP', 'cUNI', 'cCOMP', 'cMKR', 'cSUSHI', 'cAAVE', 'cYFI', 'cFEI',
].map((symbol) => getAssetInfo(symbol));

export const aaveCollateralAssets = [
  'aETH', 'aDAI', 'aUSDC', 'aSUSD', 'aTUSD', 'aUSDT', 'aBUSD', 'aBAT', 'aKNCL', 'aLEND',
  'aLINK', 'aMANA', 'aMKR', 'aREP', 'aSNX', 'aWBTC', 'aZRX', 'aENJ', 'aREN', 'aYFI', 'aUNI', 'aAAVE',
].map((symbol) => getAssetInfo(symbol));

export const exchangeAssets = (networkId) => [
  'ETH', 'DAI', 'MKR', 'BAT', 'ZRX', 'KNCL', 'KNC', 'REP', 'USDC', 'WBTC', 'USDT', 'COMP', 'SUSD', 'TUSD',
  'BUSD', 'LINK', 'MANA', 'SNX', 'ENJ', 'REN', 'CRV', 'YFI', 'USDP', 'DPI', 'UNI', 'LRC', 'AAVE', 'BAL',
  'GUSD', 'RENBTC', 'RAI', '1INCH', 'BADGER', 'DIGG', 'MIR', 'SUSHI', 'CREAM', 'COVER', 'UMA', 'SNT', 'RPL',
  'TORN', 'BNT', 'ETH2x-FLI', 'LQTY', 'LUSD', 'FLX', 'ALCX', 'EURS', 'GRT', 'POOL', 'LDO', 'stETH', 'AXS',
  'BTC2x-FLI', 'FARM', 'FEI', 'GTC', 'INST', 'MTA', 'RUNE', 'TRIBE', 'RENFIL', 'MATIC', 'DYDX', 'PERP',
  'TCAP', 'CTX', 'RGT', 'DATA', 'AMP', 'FRAX', 'wstETH', 'YFII', 'TEND', 'OMG', 'PICKLE', 'ROOK', 'IDLE',
  'TRB', 'YAM', 'SPANK', 'CVX', 'SHIB', 'MIM', 'SPELL', 'ROBOT', 'GEL', 'GNO', 'TRAC', 'FTM', 'AUDIO', 'THALES',
  'RARI', 'FXS', 'BED', 'FOX', 'OHM', 'ALUSD', 'ENS', 'mUSD', 'UST',
].map(token => getAssetInfo(token, networkId)).filter(({ address }) => address && !isEmptyBytes(address));

export const yearnCollateralAssets = [
  'WETH', 'DAI', 'USDC', 'WBTC', 'USDT', 'SUSD', 'LINK', 'SNX', 'YFI', 'UNI', 'RAI', '1INCH', 'crvSTETH',
  'crvSETH', 'HEGIC', 'crvIB', 'crvHBTC', 'yveCRV', 'crvSBTC', 'crvRENBTC', 'crvSAAVE', 'crvOBTC', 'crvPBTC',
  'crvLUSD', 'crvBBTC', 'crvTBTC', 'crvFRAX', 'crvYBUSD', 'crvCOMP', 'crvGUSD', 'yCRV', '3Crv', 'crvTUSD',
  'crvBUSD', 'crvDUSD', 'crvUST', 'crvMUSD', 'crvSUSD', 'crvLINK', 'crvUSDN', 'crvUSDP', 'crvALUSD', 'crvRETH',
  'crvAETHc', 'crvAAVE', 'crvHUSD', 'crvEURS', 'crvTricrypto', 'crvPAX', 'crvUSDT', 'crvUSDK', 'crvRSV',
  'crv3crypto', 'crvEURT', 'crvMIM',
].map(symbol => getAssetInfo(symbol));

export const mcdCollateralAssets = supportedIlks.map((ilkLabel) => {
  const ilk = stringToBytes(ilkLabel);
  const asset = getAssetInfo(ilkToAsset(ilk));
  return {
    ...asset,
    ilk,
    ilkBytes: ilk,
    ilkLabel,
  };
});

export const mcdCreateCollateralAssets = supportedIlks.map((ilkLabel) => {
  const ilk = stringToBytes(ilkLabel);
  const asset = getAssetInfo(ilkToAsset(ilk));
  return {
    ...asset,
    ilk,
    ilkBytes: ilk,
    ilkLabel,
  };
});

export const getAaveV2Assets = () => getAaveMarketInfoFromValue('v2default').assets.map(symbol => getAssetInfo(symbol));

export const getAaveV3Assets = (network) => getAaveMarketInfoFromValue('v3default', network).assets.map(symbol => getAssetInfo(symbol));

export const compoundCollateralCreateOptions = compoundCollateralAssets
  .filter(a => aaveCollateralAssets.find(token => token.underlyingAsset === a.underlyingAsset))
  .filter(_asset => _asset.symbol !== 'cWBTC Legacy')
  .map(_asset => ({
    ..._asset,
    label: _asset.underlyingAsset,
    value: _asset.underlyingAsset,
    meta: { icon: getAssetInfo(_asset.underlyingAsset).icon },
  }));

export const compoundCollateralAssetsOptions = compoundCollateralAssets.map(_asset => ({
  ..._asset,
  label: _asset.symbol === 'cWBTC Legacy' ? `${_asset.underlyingAsset} Legacy` : _asset.underlyingAsset,
  cAsset: _asset.symbol,
  value: _asset.symbol === 'cWBTC Legacy' ? `${_asset.underlyingAsset} Legacy` : _asset.underlyingAsset,
}));

export const aaveCollateralAssetsOptions = aaveCollateralAssets.map(_asset => ({
  label: _asset.underlyingAsset,
  cAsset: _asset.symbol,
  value: _asset.underlyingAsset,
  meta: { icon: getAssetInfo(_asset.underlyingAsset).icon },
}));

export const aaveV2CollateralAssetsOptions = () => getAaveV2Assets().map(({ symbol }) => ({
  label: symbol,
  cAsset: `a${symbol}`,
  value: symbol,
  meta: { icon: getAssetInfo(symbol).icon },
}));

export const aaveV3CollateralAssetsOptions = (network) => getAaveV3Assets(network).map(({ symbol }) => ({
  label: symbol,
  cAsset: `a${symbol}`,
  value: symbol,
  meta: { icon: getAssetInfo(symbol).icon },
}));

export const savingsProjectToAssetMap = {
  0: getAssetInfo('cDAI'),
  2: getAssetInfo('iDAI'),
  4: getAssetInfo('aDAI'),
};

export const getCompoundAssetInfoFromUnderlying = (_underlyingAsset) => compoundCollateralAssets.find(({ underlyingAsset }) => underlyingAsset === _underlyingAsset);

export const getCloseCdpAssets = (asset) => [getAssetInfo(asset), getAssetInfo('DAI')];

export const assetExists = (asset) => !!assets.filter(({ symbol }) => symbol === asset);

export const dydxFlashLoanAssets = ['DAI', 'WETH', 'USDC'].map(symbol => getAssetInfo(symbol));

export const balancerFlashLoanAssets = ['DAI', 'WETH', 'USDC', 'wstETH', 'WBTC', 'BAL', 'GNO', 'USDT', 'MKR', 'RENBTC', 'LINK', 'SNX', 'YFI', 'MTA', 'LDO', 'COMP', 'REN', 'UNI', 'MATIC', 'ENJ', 'DPI', 'BAT', 'GTC', 'CRV', 'ZRX', 'AAVE', 'SUSHI', 'FEI', 'TRIBE', 'GRT', 'LUSD', 'FEI', 'ENS'].map(symbol => getAssetInfo(symbol));

export const CDP_AUTOMATION_SUPPORTED_ILKS = [
  'ETH-A', 'ETH-B', 'ETH-C', 'BAT-A', 'WBTC-A', 'WBTC-B', 'WBTC-C', 'ZRX-A', 'KNC-A',
  'MANA-A', 'LINK-A', 'YFI-A', 'UNI-A', 'AAVE-A', 'RENBTC-A', 'COMP-A', 'MATIC-A', 'WSTETH-A',
];

export const PORTFOLIO_BALANCE_ASSETS = ['ETH', 'DAI', 'USDC', 'WBTC', 'BAT', 'KNCL', 'KNC', 'ZRX', 'REPv2', 'USDT', 'COMP', 'MANA', 'LINK', 'LEND', 'SNX', 'REN', 'BUSD', 'SUSD', 'TUSD', 'CRV', 'YFI', 'DPI', 'UNI', 'USDP', 'AAVE', 'stkAAVE', 'RENBTC', 'WETH', 'LUSD', 'LQTY', 'stETH', 'DYDX', 'AMP', 'wstETH'];

export const STABLE_ASSETS = ['DAI', 'USDC', 'USDT', 'TUSD', 'USDP', 'GUSD', 'BUSD', 'SUSD', 'FRAX'];

export const ASSETS_FROM_MAKER_ASSET = (asset) => {
  switch (asset) {
    case 'UNIV2DAIETH': {
      return {
        firstAsset: 'DAI', secondAsset: 'ETH', isFirstDAI: true, hasDAI: true, protocolName: 'Uniswap V2', protocolShortName: 'Uni V2', isLP: true,
      };
    }
    case 'UNIV2WBTCETH': {
      return {
        firstAsset: 'WBTC', secondAsset: 'ETH', isFirstDAI: false, hasDAI: false, protocolName: 'Uniswap V2', protocolShortName: 'Uni V2', isLP: true,
      };
    }
    case 'UNIV2USDCETH': {
      return {
        firstAsset: 'USDC', secondAsset: 'ETH', isFirstDAI: false, hasDAI: false, protocolName: 'Uniswap V2', protocolShortName: 'Uni V2', isLP: true,
      };
    }
    case 'UNIV2DAIUSDC': {
      return {
        firstAsset: 'DAI', secondAsset: 'USDC', isFirstDAI: true, hasDAI: true, protocolName: 'Uniswap V2', protocolShortName: 'Uni V2', isLP: true,
      };
    }
    case 'UNIV2ETHUSDT': {
      return {
        firstAsset: 'ETH', secondAsset: 'USDT', isFirstDAI: false, hasDAI: false, protocolName: 'Uniswap V2', protocolShortName: 'Uni V2', isLP: true,
      };
    }
    case 'UNIV2LINKETH': {
      return {
        firstAsset: 'LINK', secondAsset: 'ETH', isFirstDAI: false, hasDAI: false, protocolName: 'Uniswap V2', protocolShortName: 'Uni V2', isLP: true,
      };
    }
    case 'UNIV2UNIETH': {
      return {
        firstAsset: 'UNI', secondAsset: 'ETH', isFirstDAI: false, hasDAI: false, protocolName: 'Uniswap V2', protocolShortName: 'Uni V2', isLP: true,
      };
    }
    case 'UNIV2WBTCDAI': {
      return {
        firstAsset: 'WBTC', secondAsset: 'DAI', isFirstDAI: false, hasDAI: true, protocolName: 'Uniswap V2', protocolShortName: 'Uni V2', isLP: true,
      };
    }
    case 'UNIV2AAVEETH': {
      return {
        firstAsset: 'AAVE', secondAsset: 'ETH', isFirstDAI: false, hasDAI: false, protocolName: 'Uniswap V2', protocolShortName: 'Uni V2', isLP: true,
      };
    }
    case 'UNIV2DAIUSDT': {
      return {
        firstAsset: 'DAI', secondAsset: 'USDT', isFirstDAI: true, hasDAI: true, protocolName: 'Uniswap V2', protocolShortName: 'Uni V2', isLP: true,
      };
    }
    case 'GUNIV3DAIUSDC1': {
      return {
        firstAsset: 'DAI', secondAsset: 'USDC', isFirstDAI: true, hasDAI: true, protocolName: 'Uniswap V3', protocolShortName: 'Guni', isLP: true,
      };
    }
    case 'GUNIV3DAIUSDC2': {
      return {
        firstAsset: 'DAI', secondAsset: 'USDC', isFirstDAI: true, hasDAI: true, protocolName: 'Uniswap V3', protocolShortName: 'Guni', isLP: true,
      };
    }
    case 'steCRV': {
      return {
        firstAsset: 'ETH', secondAsset: 'stETH', isFirstDAI: false, hasDAI: false, protocolName: 'Curve', protocolShortName: 'Curve', isLP: true,
      };
    }
    case 'wstETH': {
      return {
        firstAsset: 'ETH', secondAsset: 'stETH', isFirstDAI: false, hasDAI: false, protocolName: 'Lido', protocolShortName: 'Lido', isLP: true,
      };
    }
    default: {
      return {
        firstAsset: '', secondAsset: '', isFirstDAI: false, hasDAI: false, isLP: false,
      };
    }
  }
};

export const ENS_REGEX = /^\S+(.eth$)/;

export default assets;
