import GnosisSafeIcon from '../components/Decorative/Wallets/GnosisSafeIcon';
import BrowserIcon from '../components/Decorative/Wallets/BrowserIcon';
import MetaMaskIcon from '../components/Decorative/Wallets/MetaMaskIcon';
import TrezorIcon from '../components/Decorative/Wallets/TrezorIcon';
import LedgerIcon from '../components/Decorative/Wallets/LedgerIcon';
import FortmaticIcon from '../components/Decorative/Wallets/FortmaticIcon';
import WalletConnectIcon from '../components/Decorative/WalletConnectIcon';
import CoinBaseIcon from '../components/Decorative/Wallets/CoinBaseIcon';
import TrackAddressIcon from '../components/Decorative/ConnectionWalletIcons/TrackAddressIcon';
import ForkIcon from '../components/Decorative/Wallets/ForkIcon';
import ChangeNetworkIcon from '../components/Decorative/Wallets/ChangeNetworkIcon';
import ArgentIcon from '../components/Decorative/Wallets/ArgentIcon';
import RainbowIcon from '../components/Decorative/Wallets/RainbowIcon';
import FrameIcon from '../components/Decorative/Wallets/FrameIcon';
import XdefiIcon from '../components/Decorative/Wallets/XdefiIcon';
import RabbyIcon from '../components/Decorative/Wallets/RabbyIcon';
import TallyIcon from '../components/Decorative/Wallets/TallyIcon';
import CoinBaseIconLarge from '../components/Decorative/Wallets/CoinBaseIconLarge';
import BraveIcon from '../components/Decorative/Wallets/BraveIcon';
import AmbireIcon from '../components/Decorative/Wallets/AmbireIcon';
import { getBrowserProviderName } from '../services/ethService';

export const WALLET_ICONS = {
  browser: BrowserIcon,
  metamask: MetaMaskIcon,
  trezor: TrezorIcon,
  ledger: LedgerIcon,
  fortmatic: FortmaticIcon,
  walletconnect: WalletConnectIcon,
  coinbase: CoinBaseIcon,
  viewonly: TrackAddressIcon,
  'view-only': TrackAddressIcon,
  fork: ForkIcon,
  'change-network': ChangeNetworkIcon,
  simulation: ForkIcon,
  gnosis: GnosisSafeIcon,
  gnosissafe: GnosisSafeIcon,
  argent: ArgentIcon,
  rainbow: RainbowIcon,
  frame: FrameIcon,
  xdefi: XdefiIcon,
  rabby: RabbyIcon,
  tally: TallyIcon,
  brave: BraveIcon,
  ambire: AmbireIcon,
};


export const CONNECT_WALLET_OPTIONS = () => {
  const inPageProviderName = getBrowserProviderName();
  return [
    {
      label: inPageProviderName,
      value: inPageProviderName,
      Icon: WALLET_ICONS[inPageProviderName.toLowerCase()] || BrowserIcon,
      mobile: true,
    },
    {
      label: 'WalletConnect',
      value: 'walletconnect',
      Icon: WalletConnectIcon,
      mobile: true,
    },
    {
      label: 'Ledger',
      value: 'ledger',
      Icon: LedgerIcon,
      mobile: false,
    },
    {
      label: 'Fortmatic',
      value: 'fortmatic',
      Icon: FortmaticIcon,
      mobile: true,
    },
    {
      label: 'Coinbase',
      value: 'coinbase',
      Icon: CoinBaseIcon,
      LargeIcon: CoinBaseIconLarge,
      mobile: true,
    },
    {
      label: 'Trezor',
      value: 'trezor',
      Icon: TrezorIcon,
      mobile: false,
    },
  ];
};

export const ALL_WALLET_OPTIONS = () => [
  ...CONNECT_WALLET_OPTIONS(),
  {
    label: 'Track address',
    value: 'view-only',
    Icon: TrackAddressIcon,
    mobile: true,
  },
  {
    label: 'Simulation',
    value: 'fork',
    Icon: ForkIcon,
    mobile: true,
  },
];

export const GNOSIS_SAFE_OPTION = {
  label: 'Gnosis Safe',
  value: 'gnosissafe',
  Icon: GnosisSafeIcon,
  mobile: true,
};

export const DEBUG_WALLET_OPTION = {
  label: 'Debug address',
  value: 'debug',
  Icon: BrowserIcon,
  mobile: true,
};
