import t from 'translate';
import * as strategiesActionTypes from '../../actionTypes/strategiesActionTypes/strategiesActionTypes';
import * as makerStrategiesActionTypes from '../../actionTypes/strategiesActionTypes/makerStrategiesActionTypes';
import * as liquityStrategiesActionTypes from '../../actionTypes/strategiesActionTypes/liquityStrategiesActionTypes';

import * as strategiesService from '../../services/strategiesServices/strategiesService';
import * as modalActions from '../modalActions';
import { captureException } from '../../sentry';
import { sendTx } from '../txNotificationActions';
import { isCdpExternal } from '../../services/utils';
import { closeModal, openMigrateCdpFromInstadappModal } from '../modalActions';
import { sendAutomationUnsubscribeApiCall } from '../../services/apiService';
import { isLayer2Network } from '../../services/ethService';

/**
 * Fetching all strategy subscriptions and delegating to specific protocol reducer
 *
 * @return {(function(*, *): Promise<void>)|*}
 */
export const getSubscribedStrategiesAction = () => async (dispatch, getState) => {
  const { network } = getState().general;
  if (isLayer2Network(network)) return;
  dispatch({ type: strategiesActionTypes.GET_SUBSCRIBED_STRATEGIES_REQUEST });
  try {
    const { maker: { proxyAddress, cdps } } = getState();
    const payload = await strategiesService.getSubscribedStrategies(proxyAddress, cdps);

    const makerStrategies = payload.filter(({ protocol }) => protocol === 'makerdao');
    dispatch({ type: makerStrategiesActionTypes.GET_MAKER_SUBSCRIBED_STRATEGIES_SUCCESS, payload: makerStrategies });

    const liquityStrategies = payload.filter(({ protocol }) => protocol === 'liquity');
    dispatch({ type: liquityStrategiesActionTypes.GET_LIQUITY_SUBSCRIBED_STRATEGIES_SUCCESS, payload: liquityStrategies });

    dispatch({ type: strategiesActionTypes.GET_SUBSCRIBED_STRATEGIES_SUCCESS, payload });
  } catch (err) {
    console.error(err);
    dispatch({ type: strategiesActionTypes.GET_SUBSCRIBED_STRATEGIES_FAILURE, payload: err.message });
    captureException(err);
  }
};

export const resetSubscriptionActions = () => (dispatch) => dispatch({ type: strategiesActionTypes.RESET_SUBSCRIPTION_ACTIONS });

export const setStatusGraphFromForm = (payload) => (dispatch) => dispatch({ type: strategiesActionTypes.SET_STATUS_GRAPH_FROM_FORM, payload });

/**
 * Activate strategy subscription
 *
 * @param subId
 * @param protocol
 * @param strategyName
 * @return {(function(*=, *=): Promise<void>)|*}
 */
export const activateStrategySubscriptionAction = (subId, protocol, strategyName) => async (dispatch, getState) => {
  dispatch(resetSubscriptionActions());
  dispatch({ type: strategiesActionTypes.ACTIVATE_SUBSCRIPTION_REQUEST, protocol, strategyName });
  try {
    strategiesService.strategiesTrackEventWrapper('activate', protocol, strategyName, 'request');
    const {
      maker: { proxyAddress, cdp },
      general: { account, accountType },
    } = getState();

    if (protocol === 'makerdao' && isCdpExternal(cdp)) return dispatch(openMigrateCdpFromInstadappModal());

    const sendTxFunc = promise => sendTx(promise, t('strategies.enable_action_title'), '', dispatch, getState, { protocol: 'maker' });

    await strategiesService.activateStrategySubscription({
      accountType, sendTxFunc, proxyAddress, account,
    }, subId);

    dispatch({ type: strategiesActionTypes.ACTIVATE_SUBSCRIPTION_SUCCESS, protocol, strategyName });
    dispatch(getSubscribedStrategiesAction());
    strategiesService.strategiesTrackEventWrapper('activate', protocol, strategyName, 'success');
  } catch (err) {
    console.error(err);
    dispatch({
      type: strategiesActionTypes.ACTIVATE_SUBSCRIPTION_FAILURE, protocol, strategyName, payload: err.message,
    });
    captureException(err);
    strategiesService.strategiesTrackEventWrapper('activate', protocol, strategyName, `failed: ${err.message}`);
  }
};

/**
 * Deactivate strategy subscription
 *
 * @param selectedStrategy {Object}
 * @param protocol {string}
 * @param strategyName {string}
 * @param formData {Object}
 * @return {(function(*=, *=): Promise<void>)|*}
 */
export const deactivateStrategySubscriptionAction = (selectedStrategy, protocol, strategyName, formData) => async (dispatch, getState) => {
  const { subId } = selectedStrategy;
  dispatch(resetSubscriptionActions());
  dispatch({ type: strategiesActionTypes.DEACTIVATE_SUBSCRIPTION_REQUEST, protocol, strategyName });
  try {
    strategiesService.strategiesTrackEventWrapper('deactivate', protocol, strategyName, 'request');
    const {
      maker: { proxyAddress, cdp },
      general: { account, accountType },
    } = getState();

    if (protocol === 'makerdao' && isCdpExternal(cdp)) return dispatch(openMigrateCdpFromInstadappModal());

    if (formData) {
      const apiReqPromise = sendAutomationUnsubscribeApiCall(protocol, proxyAddress, cdp.id, formData.field, formData.description, strategyName);
      dispatch(closeModal());
    }


    const onTxHashCallback = () => dispatch(modalActions.openAutomationUnsubNextStepsModal({ strategyName: selectedStrategy.title, protocol }));

    const sendTxFunc = promise => sendTx(promise, t('strategies.disable_action_title'), '', dispatch, getState, { protocol: 'maker' }, onTxHashCallback);

    await strategiesService.deactivateStrategySubscription({
      accountType, sendTxFunc, proxyAddress, account,
    }, subId);

    dispatch({ type: strategiesActionTypes.DEACTIVATE_SUBSCRIPTION_SUCCESS, protocol, strategyName });
    dispatch(getSubscribedStrategiesAction());
    strategiesService.strategiesTrackEventWrapper('deactivate', protocol, strategyName, 'success');
  } catch (err) {
    console.error(err);
    dispatch({
      type: strategiesActionTypes.DEACTIVATE_SUBSCRIPTION_FAILURE, protocol, strategyName, payload: err.message,
    });
    captureException(err);
    strategiesService.strategiesTrackEventWrapper('deactivate', protocol, strategyName, `failed: ${err.message}`);
  }
};

export const getStrategiesTransactionsHistoryAction = (subId) => async (dispatch) => {
  dispatch({ type: strategiesActionTypes.GET_STRATEGIES_TX_HISTORY_REQUEST });
  try {
    const payload = await strategiesService.getAutomationApiTransactions(subId);
    console.log(payload);
    dispatch({ type: strategiesActionTypes.GET_STRATEGIES_TX_HISTORY_SUCCESS, payload });
  } catch (err) {
    console.log(err);
    dispatch({
      type: strategiesActionTypes.GET_STRATEGIES_TX_HISTORY_FAILURE, payload: err.message,
    });
    captureException(err);
  }
};

/**
 * Helper to get redux state for all strategies actions
 *
 *
 * @param strategies
 * @param name
 * @return {{updating: (*|boolean), subscribingErr: (*|string), updatingAndActivatingErr: (*|string), updatingAndActivating: (*|boolean), activatingErr: (*|string), deactivating: (*|boolean), subscribing: (*|boolean), activating: (*|boolean), updatingErr: (*|string), deactivatingErr: (*|string)}}
 */
export const getCommonStrategySubscriptionProps = (strategies, name) => ({
  subscribing: strategies.subscribing?.[name] || false,
  subscribingErr: strategies.subscribingErr?.[name] || '',

  updating: strategies.updating?.[name] || false,
  updatingErr: strategies.updatingErr?.[name] || '',

  updatingAndActivating: strategies.updatingAndActivating?.[name] || false,
  updatingAndActivatingErr: strategies.updatingAndActivatingErr?.[name] || '',

  activating: strategies.activating?.[name] || false,
  activatingErr: strategies.activatingErr?.[name] || '',

  deactivating: strategies.deactivating?.[name] || false,
  deactivatingErr: strategies.deactivatingErr?.[name] || '',
});
