import { assetAmountInEth, getAssetInfo } from '@defisaver/tokens';
import { getAaveMarketInfoFromValue } from '../../constants/aaveMarkets';
import { ethToWeth } from '../utils';
import { AaveLoanInfoV2Contract, AaveV3ViewL2Contract } from '../contractRegistryService';

export const getAvailableLiqForAsset = async (asset, market = getAaveMarketInfoFromValue('v2default')) => {
  const _address = getAssetInfo(ethToWeth(asset)).address;
  const loanInfoContract = AaveLoanInfoV2Contract();
  const marketAddress = market.providerAddress;
  const loanInfo = await loanInfoContract.methods.getFullTokensInfo(marketAddress, [_address])
    .call();

  return assetAmountInEth(loanInfo[0].availableLiquidity.toString(), asset);
};

export const getAvailableLiqForAssetV3 = async (asset, network) => {
  const market = getAaveMarketInfoFromValue('v3default', network);
  const _address = getAssetInfo(ethToWeth(asset)).address;
  const loanInfoContract = AaveV3ViewL2Contract();
  const marketAddress = market.providerAddress;
  const loanInfo = await loanInfoContract.methods.getFullTokensInfo(marketAddress, [_address]).call();

  return assetAmountInEth(loanInfo[0].availableLiquidity.toString(), asset);
};
