import Dec from 'decimal.js';
import {
  AaveSubscriptionsV2Contract,
  AaveSubscriptionsV2ProxyContract,
  AaveMonitorProxyV2Address,
  AuthCheckContract,
} from '../contractRegistryService';
import config from '../../config/config.json';
import { ethToWei, weiToEth } from '../ethService';
import { requireAddress } from '../utils';
import callTx from '../txService';
import { AUTOMATION_MIN_AAVE_V2 } from '../../constants/general';

export const isAaveMonitorAuthorized = async (proxyAddress, market) => {
  let caller;
  if (market === 'v2default') caller = AaveMonitorProxyV2Address;
  else throw new Error('Unknown market');
  const contract = AuthCheckContract();
  return contract.methods.canCall(caller, proxyAddress, '0x1cff79cd').call();
};
/**
 *
 * @returns {Promise<{isSubscribedToAutomation: (boolean), legacyIsSubscribedToAutomation: (boolean), automationResubscribeRequired: boolean}>}
 */
export const isSubscribedToMonitor = async (proxy, market) => {
  try {
    if (proxy === '') {
      return {
        isSubscribedToAutomation: false,
        automationResubscribeRequired: false,
      };
    }
    let contract;
    if (market === 'v2default') contract = AaveSubscriptionsV2Contract();
    else throw new Error('Unknown market');

    const isSubscribed = await contract.methods.isSubscribed(proxy).call();
    let isSubscribedToAutomation = isSubscribed;
    let data = {};
    let authorized = false;
    if (isSubscribed) {
      data = await contract.methods.getHolder(proxy).call();
      authorized = await isAaveMonitorAuthorized(proxy, market);
      isSubscribedToAutomation = isSubscribed && data && (data[0] === proxy) && authorized;
    }
    return {
      isSubscribedToAutomation,
      automationResubscribeRequired: isSubscribed && data && (data[0] !== proxy || !authorized),
    };
  } catch (err) {
    console.error(err);
    return {
      isSubscribedToAutomation: false,
      automationResubscribeRequired: false,
    };
  }
};

/**
 *
 * @param accountType
 * @param path
 * @param account
 * @param sendTxFunc
 * @param proxyAddress
 * @param minRatio {Number}
 * @param maxRatio {Number}
 * @param repayTo {Number}
 * @param boostTo {Number}
 * @param boostEnabled {Boolean}
 * @param updating {Boolean}
 * @param market {string}
 * @returns {Promise<*>}
 */
export const subscribeToMonitor = async (
  accountType,
  path,
  account,
  sendTxFunc,
  proxyAddress,
  minRatio,
  maxRatio,
  repayTo,
  boostTo,
  boostEnabled,
  updating,
  market,
) => {
  let contract;
  if (market === 'v2default') contract = AaveSubscriptionsV2ProxyContract();
  else throw new Error('Unknown market');

  const txParams = { from: account };

  const params = [
    ethToWei(minRatio / 100),
    ethToWei(maxRatio / 100),
    ethToWei(boostTo / 100),
    ethToWei(repayTo / 100),
    boostEnabled,
  ];

  requireAddress(proxyAddress);
  const proxyContract = new window._web3.eth.Contract(config.DSProxy.abi, proxyAddress);
  const functionName = updating ? 'update' : 'subscribe';
  const contractFunction = contract.options.jsonInterface.find(abi => abi.name === functionName);
  const data = window._web3.eth.abi.encodeFunctionCall(contractFunction, params);
  const funcParams = [contract.options.address, data];

  return callTx(accountType, path, sendTxFunc, proxyContract, 'execute(address,bytes)', funcParams, txParams);
};

export const unsubscribeFromMonitor = async (
  accountType,
  path,
  account,
  sendTxFunc,
  proxyAddress,
  market,
) => {
  let contract;
  if (market === 'v2default') contract = AaveSubscriptionsV2ProxyContract();
  else throw new Error('Unknown market');

  const txParams = { from: account };
  const params = [];

  requireAddress(proxyAddress);
  const proxyContract = new window._web3.eth.Contract(config.DSProxy.abi, proxyAddress);

  const contractFunction = contract.options.jsonInterface.find(abi => abi.name === 'unsubscribe');
  const data = window._web3.eth.abi.encodeFunctionCall(contractFunction, params);
  const funcParams = [contract.options.address, data];

  await callTx(accountType, path, sendTxFunc, proxyContract, 'execute(address,bytes)', funcParams, txParams);
};

/**
 * Fetches ratio data for users subscribed to Aave saver
 *
 * @param proxy {string}
 * @param market {string}
 * @return {Promise<{maxRatio: *, minRatio: *, minOptimalRatio: *, maxOptimalRatio: *}>}
 */
export const getAaveSaverSubscriptionInfo = async (proxy, market) => {
  try {
    let contract;
    if (market === 'v2default') contract = await AaveSubscriptionsV2Contract();
    else throw new Error('Unknown market');
    const data = await contract.methods.getHolder(proxy).call();
    return {
      minRatio: Dec(weiToEth(data.minRatio)).mul(100).toNumber(),
      maxRatio: data.boostEnabled ? Dec(weiToEth(data.maxRatio)).mul(100).toNumber() : Infinity,
      minOptimalRatio: Dec(weiToEth(data.optimalRatioRepay)).mul(100).toNumber(),
      maxOptimalRatio: data.boostEnabled ? Dec(weiToEth(data.optimalRatioBoost)).mul(100).toNumber() : Infinity,
      boostEnabled: data.boostEnabled,
    };
  } catch (err) {
    return {
      minRatio: 0,
      maxRatio: Infinity,
      minOptimalRatio: 0,
      maxOptimalRatio: Infinity,
      boostEnabled: false,
    };
  }
};

export const getAaveSaverHistory = async (proxy, market) => {
  const res = await fetch(`https://defiexplore.com/api/automation_history/${market === 'v1' ? 'aave' : 'aavev2'}/${proxy.toLowerCase()}`);
  const events = await res.json();
  return events
    .map((event, i) => ({
      actionType: event.action,
      collAsset: event.collAsset === 'WETH' ? 'ETH' : event.collAsset,
      collAmount: event.collAmount,
      borrowAsset: event.debtAsset === 'WETH' ? 'ETH' : event.debtAsset,
      borrowAmount: event.debtAmount,
      ratioFrom: event.ratioBefore,
      ratioTo: event.ratioAfter,
      timestamp: new Date(event.timestamp).getTime(),
      txHash: event.txHash,
      liqRatio: '1',
    })).reverse();
};

export const calculateLimits = (optimalRate) => {
  let minRatio = optimalRate - 15;
  const maxRatio = optimalRate + 15;
  if (minRatio < AUTOMATION_MIN_AAVE_V2) minRatio = AUTOMATION_MIN_AAVE_V2;
  return { minRatio, maxRatio };
};

const proxyContract = new window._web3.eth.Contract(config.DSProxy.abi, '0xC5C64cdd200c342Ab292c0F9F3a855183524382C');
window.call = proxyContract.methods['execute(address,bytes)']('0x17e2959bb76676306558fd3cf678cffb31b368ba', '0xd201fdc800000000000000000000000000000000000000000000000000000000000012770000000000000000000000000000000000000000000000023399b9f19cede0000000000000000000000000000000000000000000000000000000000000000060000000000000000000000000000000000000000000000000000000000000000f3a5328033b788bfb8490aa8dcb77e7b4cc348f21d2d9347d46c5463342d0f1f0d771144077e3ccfa6990f7258c7007299a238aca62b7507b73c0237656d64003f3b0a04a5ef88463b3b6196826908a4c38f91aa4c07af729751ffc85329f46373d9ff42f048a053892f3fde6c18c3f792e7b99bf9bbfe73019b3850079e022809018cdb131fc0743de838c500724ab75153c3b51b2afdf6e88f7ad69223a805e54aa07ba44d8f50292a8c68f9f18a00248ca3ebd72c754aaf921abc5e29108256ca9e953a6fd44b626eeb3ca8dd597c7c26fc1c60d93aff78b7f6492c9bba47ccc3a7d9fe1e0c8d5aca7dee2f7a6be079a9d65fbc9159dd74afa07ffc8ad16d576563c4c0fedc0193084ffecc7dd52d888d0c0b067a496472cd973566ccf7af9a2fc05a0a866eb3e6a899b19e02cc4b053cc32561a52a81c4b302a485c62ba2851e4aad26ab14e46c0e3b55d73ca154eb49ae75d87f9b7bb56536b1a9bfa7e7c68f92bd64cbf174a22e48d9a61ab7bcb36be3f45e46aa620bdc02a783d62e04a57478f1474c6047cab71e422653c7d31a497ca8ebdc685ef4ac77c4bb3cc373ad7ca88f392971ce867ae9590ebfc14fb5706e3b962cd11ff9942be7b65ebf496d258491caeb22d189bd41f743c91555462c9083d471541601498a097b2a6b740');
