import React from 'react';
import t from 'translate';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createDSProxyAction } from 'actions/makerActions/makerActions';
import { ACCOUNT_TYPES } from '../../../constants/general';
import { openLoginModal } from '../../../actions/modalActions';

import ModalHeader from '../ModalHeader';
import ModalBody from '../ModalBody';
import walletIcon from './wallet-icon-circle.svg';

import './UserWalletModal.scss';

const UserWalletModal = ({
  closeModal, proxyAddress, children,
  createDSProxyAction, creatingDSProxy, openLoginModal, accountType,
}) => (
  <div
    id="user-wallet-modal-wrapper"
    className={`coming-soon-modal ${proxyAddress ? 'has-proxy' : 'no-proxy'}`}
  >
    <ModalHeader closeModal={closeModal} />

    <ModalBody>
      <div className="new-modal-top-wrapper" style={{ backgroundImage: `url(${walletIcon})` }}>
        <h1 className="title">{t('account.user_wallet')}</h1>
      </div>

      {
        proxyAddress && (
          <div className="sub-header">
            <div className="disclaimer">{t('account.user_wallet_address_info')}</div>
            <div className="sub-title">{ proxyAddress }</div>
          </div>
        )
      }

      <div className="new-modal-content-wrapper">
        {children}
      </div>
    </ModalBody>

    {
      !proxyAddress && (
        <div className="modal-controls">
          <button
            type="button"
            className="button green"
            onClick={() => (accountType !== ACCOUNT_TYPES.viewOnly ?
              createDSProxyAction() :
              openLoginModal())}
            disabled={creatingDSProxy}
          >
            { creatingDSProxy ? t('account.creating_user_wallet') : t('account.create_user_wallet') }
          </button>
        </div>
      )
    }
  </div>
);


UserWalletModal.defaultProps = {
  proxyAddress: '',
};

UserWalletModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  proxyAddress: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  createDSProxyAction: PropTypes.func.isRequired,
  creatingDSProxy: PropTypes.bool.isRequired,
  accountType: PropTypes.string.isRequired,
  openLoginModal: PropTypes.func.isRequired,
};

const mapStateToProps = ({ general, maker }) => ({
  accountType: general.accountType,
  proxyAddress: maker.proxyAddress,
  creatingDSProxy: maker.creatingDSProxy,
});

const mapDispatchToProps = { createDSProxyAction, openLoginModal };

export default connect(mapStateToProps, mapDispatchToProps)(UserWalletModal);
