import React from 'react';
import t from 'translate';
import { Link } from 'react-router-dom';

import './Page404.scss';
import '../BlockerMessage/BlockerMessage.scss';

import svg from './404.svg';
import sectionBg from './sectionBg.png';

const Page404 = () => (
  <div className="dashboard-page-wrapper page-404-wrapper">
    <div className="content-wrapper">
      <div className="width-container">
        <div className="cdp-blocker-wrapper main-section-wrapper">
          <img src={sectionBg} className="bg-image" alt="background" />
          <div className="blocker-content">
            <div>
              <h1 className="large-title">404</h1>
              <h2 className="smaller-title">{ t('errors.page_not_found') }</h2>
              <Link to="/">{ t('common.go_back_to_dashboard') }</Link>
            </div>
            <div>
              <img src={svg} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

Page404.propTypes = {};

export default Page404;
