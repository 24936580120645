import React from 'react';
import t from 'translate';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { isLayer2Network } from '../../../../services/ethService';

import TooltipWrapper from '../../../TooltipWrapper/TooltipWrapper';
import InputComponent from '../../../Forms/InputComponent';
import InfoIcon from '../../../Decorative/InfoIcon';

const GasPriceModalForm = ({
  handleSubmit, onSubmit, network,
}) => (
  <form
    id="gas-price-modal-form"
    onSubmit={handleSubmit(onSubmit)}
    className="form-wrapper"
  >
    <Field
      id="gas-price"
      name="gasPrice"
      type="number"
      placeholder="0"
      labelText={(
        <span>
          <TooltipWrapper title={t('misc.gas_price_desc')}>
            <InfoIcon />
          </TooltipWrapper>
          {t('misc.gas_price')}
        </span>
      )}
      secondLabelText="Gwei"
      component={InputComponent}
      showErrorText
      additional={{
        min: isLayer2Network(network) ? 0.0000001 : 1,
        step: 'any',
      }}
    />
    <Field
      id="gas-limit"
      name="gasLimit"
      type="number"
      placeholder="0"
      labelText={(
        <span>
          <TooltipWrapper title={t('misc.gas_limit_desc')}>
            <InfoIcon />
          </TooltipWrapper>
          {t('misc.gas_limit')}
        </span>
      )}
      secondLabelText=""
      component={InputComponent}
      showErrorText
      additional={{
        min: 0,
        // max: 10e6,
      }}
    />
  </form>
);

const GasPriceModalFormComp = reduxForm({
  form: 'gasPriceModalForm',
  enableReinitialize: true,
})(GasPriceModalForm);

GasPriceModalForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  network: PropTypes.number.isRequired,
};

const mapStateToProps = (state, { gasPrice, gasLimit }) => ({
  initialValues: { gasPrice, gasLimit },
  network: state.general.network,
});

export default connect(mapStateToProps)(GasPriceModalFormComp);
