import React, { useEffect, useState, useMemo } from 'react';
import Dec from 'decimal.js';
import t from 'translate';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ModalHeader from 'components/Modals/ModalHeader';
import ModalBody from 'components/Modals/ModalBody';
import Loader from 'components/Loader/Loader';

import { formatNumber } from 'services/utils';
import ErrorBox from 'components/Decorative/ErrorBox/ErrorBox';
import { getAssetInfo } from '@defisaver/tokens';
import { ACCOUNT_TYPES } from '../../../../constants/general';
import TooltipWrapper from '../../../TooltipWrapper/TooltipWrapper';
import AaveModalIcon from './AaveModalIcon.svg';
import {
  stakeAaveAction, unstakeAaveAction, migrateLendToAaveAction, claimAaveRewardsAction, activateUnstakeCooldownAction,
  fetchStakeModalData, claimStkAaveRewardsAction,
} from '../../../../actions/aaveActions/aaveManageActionsV2';
import { openLoginModal } from '../../../../actions/modalActions';
import WarningBox from '../../../Decorative/WarningBox/WarningBox';
import DataItem from '../../../DataItem/DataItem';

import './AaveStakeModal.scss';

const AaveIcon = getAssetInfo('AAVE').icon;

const AAVE_STAKE_OPTIONS = [
  {
    title: 'Stake',
  },
  {
    title: 'Unstake',
  },
];


const AaveStakeModal = ({
  closeModal, activatedCooldown,
  openLoginModal, aaveData, stkAaveData, lendData, walletType, proxyAddress, accountType,
  staking, unstaking, activatingStakeCooldown, stakingError, unstakingError, activatingStakeCooldownError,
  rewardBalance, cooldownSeconds, gettingStakeAaveBalance, gettingStakeAaveBalanceError, lendAaveRatio, unstakeWindow,
  totalStkAAVESupply, migrateLendToAaveAction, stakeAaveAction, activateUnstakeCooldownAction, claimAaveRewardsAction, unstakeAaveAction,
  fetchStakeModalData, stkAaveRewardBalanceAcc, stkAaveRewardBalanceProxy, claimStkAaveRewardsAction,
}) => {
  const [activeOption, setActiveOption] = useState(AAVE_STAKE_OPTIONS[0]);
  const [stakeAmount, setStakeAmount] = useState('');
  const [unstakeAmount, setUnstakeAmount] = useState('');
  const [dateUnlocked, setDateUnlocked] = useState('0');
  const [dateLockedAgain, setDateLockedAgain] = useState('0');
  const [cooldowns, setCooldowns] = useState(
    {
      activeAndLocked: false,
      activeAndUnlocked: false,
      mustReactivate: false,
    },
  );
  const [unlockTimer, setUnlockTimer] = useState('0');
  const [unstakeTimer, setUnstakeTimer] = useState('0');
  const [hasTimer, setHasTimer] = useState(false);
  const [estimateRewardPerDay, setEstimateRewardPerDay] = useState(
    [{ per: 'week', amount: '0' }, { per: 'month', amount: '0' }, { per: 'year', amount: '0' },
    ]);

  const parseUsdValue = (amount) => {
    const usdValue = formatNumber(Dec(amount || '0').mul(aaveData.aavePrice || '0').toString(), 2);
    return `~$${usdValue}`;
  };

  const parseTimer = (timer) => {
    const days = Dec(timer).div(Dec(60).mul(60).mul(24)).floor().toString();
    const hours = Dec(timer).div(Dec(60).mul(60)).mod(24).floor()
      .toString();
    const minutes = Dec(timer).div(60).mod(60).floor()
      .toString();
    return `${days}d:${hours}h:${minutes}m`;
  };

  const calculateCooldowns = () => {
    if (+activatedCooldown) {
      const dateUnlocked = Dec(activatedCooldown)
        .add(cooldownSeconds)
        .toString();
      const dateLockedAgain = Dec(dateUnlocked)
        .add(unstakeWindow)
        .toString();
      const cooldownActivatedAndLocked = Dec(dateUnlocked)
        .gt(Dec(Date.now()).div(1000));
      const cooldownActivatedAndUnlocked = !cooldownActivatedAndLocked && Dec(dateLockedAgain)
        .gt(Dec(Date.now()).div(1000));
      const mustReactivateCooldown = !cooldownActivatedAndUnlocked && !cooldownActivatedAndLocked;

      setDateUnlocked(dateUnlocked);
      setDateLockedAgain(dateLockedAgain);
      setCooldowns({
        activeAndLocked: cooldownActivatedAndLocked,
        activeAndUnlocked: cooldownActivatedAndUnlocked,
        mustReactivate: mustReactivateCooldown,
      });
      if (cooldownActivatedAndLocked || cooldownActivatedAndUnlocked ||
        (!unlockTimer.localeCompare('0') && !unstakeTimer.localeCompare('0'))) {
        setHasTimer(true);
      }
    }
  };

  const calculateRemainingTime = () => {
    if (cooldowns.activeAndLocked) {
      const remainingTime = Dec(dateUnlocked).minus(Dec(Date.now()).div(1000));
      if (remainingTime.lte('0')) {
        calculateCooldowns();
        return;
      }
      setUnlockTimer(Dec(remainingTime).toString());
    } else if (cooldowns.activeAndUnlocked) {
      const remainingTime = Dec(dateLockedAgain).minus(Dec(Date.now()).div(1000));
      if (remainingTime.lte('0')) {
        calculateCooldowns();
        return;
      }
      setUnstakeTimer(Dec(remainingTime).toString());
      if (!hasTimer) setHasTimer(true);
    }
  };

  const dailyReward = useMemo(
    () => new Dec(550).mul(stkAaveData.balance).div(totalStkAAVESupply).toString(),
    [stkAaveData.balance, totalStkAAVESupply],
  );


  const disabledUnstake = !+activatedCooldown || cooldowns.activeAndLocked || cooldowns.mustReactivate;

  const loading =
    aaveData?.gettingPrice || aaveData?.gettingBalance ||
    stkAaveData?.gettingBalance || stkAaveData.gettingPrice ||
    lendData?.gettingPrice || lendData?.gettingBalance ||
    gettingStakeAaveBalance;

  const aaveFromLend = (+lendData.balance && +lendAaveRatio) ? formatNumber(Dec(lendData.balance).div(lendAaveRatio).toString()) : '0';
  const aaveFromLendLabel = `You will receive ${aaveFromLend} AAVE (1 AAVE = ${lendAaveRatio} LEND)`;
  useEffect(() => {
    fetchStakeModalData();
  }, []);

  useEffect(() => {
    calculateCooldowns();
    calculateRemainingTime();
  }, [activatedCooldown]);

  useEffect(
    () => setEstimateRewardPerDay([
      { per: 'week', amount: new Dec(dailyReward).times(7).toString() },
      { per: 'month', amount: new Dec(dailyReward).times(30).toString() },
      { per: 'year', amount: new Dec(dailyReward).times(365).toString() },
    ]),
    [dailyReward]);

  useEffect(() => {
    let interval = null;
    if (hasTimer && (cooldowns.activeAndLocked || cooldowns.activeAndUnlocked)) {
      // Fire first then wait 1min
      calculateRemainingTime();
      interval = setInterval(() => {
        calculateRemainingTime();
      }, 60000);
    } else if (!hasTimer && !cooldowns.activeAndLocked && !cooldowns.activeAndUnlocked) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [hasTimer, cooldowns]);


  return (
    <div
      id="aave-stake-modal-wrapper"
      className="action-modal-wrapper stake-modal-wrapper"
    >
      <ModalHeader closeModal={closeModal} />
      <ModalBody>
        <div className="new-modal-top-wrapper" style={{ backgroundImage: `url(${AaveModalIcon})` }}>
          <h1>{t('aave.stake_modal_title')}</h1>
        </div>
        { loading && <Loader />}
        { !loading && (
        <div className="new-modal-content-wrapper">
          <div className="stake-modal-wrapper__balance-section">
            <div className="stake-modal-description">
              {t('aave.stake_modal_desc')}
            </div>
            <div className="balance">
              <div className="Flex FlexStart FlexTop">
                <DataItem
                  value={stkAaveData.balance || '0'}
                  decimals={+stkAaveData.balance > 1 ? 2 : 4}
                  type="standard-smaller"
                  label={t('aave.currently_staked')}
                  symbol="AAVE"
                  smallSymbol
                  additionalValueSymbol="$"
                  additionalValue={aaveData.marketPrice * stkAaveData.balance}
                  additionalValueSymbolAfter={false}
                />
                <DataItem
                  value={aaveData.marketPrice}
                  type="standard-smaller"
                  label={`AAVE ${t('common.price')}`}
                  symbol="$"
                  symbolAfter={false}
                />
              </div>
            </div>
            <div className="estimates-section">
              <p className="estimates-sub-title">{t('aave.reward_estimates')}: </p>
              <div className="estimates-data">
                { estimateRewardPerDay.map(({ per, amount }) => (
                  <DataItem
                    key={per}
                    value={amount}
                    label={`In a ${per}:`}
                    decimals={+amount > 1 ? 2 : 4}
                    type="standard-smaller"
                    additionalValueSymbol="$"
                    additionalValue={aaveData.marketPrice * amount}
                    additionalValueSymbolAfter={false}
                  />
                ))}
              </div>
            </div>
            {
            Dec(rewardBalance).gt(0) && (
            <div className="claim-rewards-wrapper">
              <div className="claim-rewards-data">
                <DataItem
                  value={rewardBalance}
                  decimals={+rewardBalance > 1 ? 2 : 4}
                  symbol="AAVE"
                  type="standard-smaller"
                  label={t('aave.claimable_rewards')}
                  smallSymbol
                  additionalValueSymbol="$"
                  additionalValue={aaveData.marketPrice * rewardBalance}
                  additionalValueSymbolAfter={false}
                />
              </div>
              <button
                type="button"
                className="green button"
                onClick={() => (
                  accountType !== ACCOUNT_TYPES.viewOnly ? claimAaveRewardsAction() : openLoginModal()
                )}
              >
                {t('common.claim')}
              </button>
            </div>
            )
          }
            {
              Dec(stkAaveRewardBalanceAcc).gt(0) && (
              <div className="claim-rewards-wrapper">
                <div className="claim-rewards-data">
                  <DataItem
                    value={stkAaveRewardBalanceAcc}
                    decimals={+stkAaveRewardBalanceAcc > 1 ? 2 : 4}
                    symbol="stkAAVE"
                    type="standard-smaller"
                    label={t('aave.claimable_rewards')}
                    smallSymbol
                    additionalValueSymbol="$"
                    additionalValue={aaveData.marketPrice * stkAaveRewardBalanceAcc}
                    additionalValueSymbolAfter={false}
                  />
                </div>
                <button
                  type="button"
                  className="green button"
                  onClick={() => (
                    accountType !== ACCOUNT_TYPES.viewOnly ? claimStkAaveRewardsAction('account') : openLoginModal()
                  )}
                >
                  {t('common.claim')}
                </button>
              </div>
              )
            }
            {
              proxyAddress && Dec(stkAaveRewardBalanceProxy).gt(0) && (
              <div className="claim-rewards-wrapper">
                <DataItem
                  value={stkAaveRewardBalanceProxy}
                  decimals={+stkAaveRewardBalanceProxy > 1 ? 2 : 4}
                  symbol="stkAAVE"
                  type="standard-smaller"
                  smallSymbol
                  label={t('aave.claimable_rewards')}
                  additionalValueSymbol="$"
                  additionalValue={aaveData.marketPrice * stkAaveRewardBalanceProxy}
                  additionalValueSymbolAfter={false}
                />
                <button
                  type="button"
                  className="green button"
                  onClick={() => (
                    accountType !== ACCOUNT_TYPES.viewOnly ? claimStkAaveRewardsAction('proxy') : openLoginModal()
                  )}
                >
                  {t('common.claim')}
                </button>
              </div>
              )
            }
            {Dec(lendData.balance).gt(0) && (
            <div className="migrate-lend-aave">
              <WarningBox withButton className="migrate-lend-aave">
                <span>{t('aave.migrate_lend_aave_desc')}</span>
                <button
                  type="button"
                  className="green button"
                  onClick={() => (
                    accountType !== ACCOUNT_TYPES.viewOnly ? migrateLendToAaveAction(Dec(lendData.balance).toString()) : openLoginModal()
                  )}
                >
                  {t('common.migrate')}
                </button>
              </WarningBox>
              <div className="lend-aave-label">
                {aaveFromLendLabel}
              </div>
            </div>
            )}
          </div>
          <div className="stake-modal-wrapper__input-section">

            <div className="token-actions">
              {t('aave.token_actions')}
            </div>
            <div className="Switch">
              {
              AAVE_STAKE_OPTIONS.map(option => (
                <div
                  key={option.title}
                  className={activeOption.title === option.title ? 'active' : ''}
                  onClick={() => setActiveOption(option)}
                >
                  {option.title}
                </div>
              ))
            }
            </div>


            <div className="form-wrapper">
              { activeOption.title === 'Stake' && (
              <div className="lower-part">
                <div className="form-wrapper-description">
                  {t('aave.stake_action_desc')}
                </div>
                <div
                  className="max-wrapper"
                  onClick={() => {
                    setStakeAmount(Dec(aaveData.balance).toString());
                  }}
                >
                  <TooltipWrapper title={aaveData.gettingBalance ? t('common.loading') : Dec(aaveData.balance).toString()}>
                    {
                      aaveData.gettingBalance ?
                        t('common.loading') :
                        `(${t('common.max_val', { '%value': formatNumber(Dec(aaveData.balance).toString(), 2) })})`
                    }
                  </TooltipWrapper>
                </div>
                <div className="form-item-wrapper stake-input-section has-third-label">
                  <div className="input-label">
                    <AaveIcon width={30} height={30} />
                    {t('aave.aave_token')}
                  </div>
                  <div>
                    <input
                      type="number"
                      className="form-item"
                      onChange={(e) => setStakeAmount(e.target.value)}
                      value={stakeAmount}
                      placeholder="0"
                    />
                    <div className="third-label">
                      {parseUsdValue(stakeAmount || '0')}
                    </div>
                  </div>
                </div>
                <div className="stake-button-section">
                  <button
                    type="button"
                    className="button green"
                    onClick={() => (
                      accountType !== ACCOUNT_TYPES.viewOnly ? stakeAaveAction(stakeAmount) : openLoginModal()
                    )}
                    disabled={!stakeAmount || Dec(stakeAmount).gt(aaveData.balance) || unstaking || staking || activatingStakeCooldown}
                  >
                    {t('aave.stake')}
                  </button>
                  <div className="error-section">
                    { stakingError && (
                      <ErrorBox>{stakingError}</ErrorBox>
                    )}
                    { unstakingError && (
                      <ErrorBox>{unstakingError}</ErrorBox>
                    )}
                    { activatingStakeCooldownError && (
                      <ErrorBox>{activatingStakeCooldownError}</ErrorBox>
                    )}
                    {gettingStakeAaveBalanceError && (
                      <ErrorBox>{gettingStakeAaveBalanceError}</ErrorBox>
                    )}
                  </div>
                </div>
              </div>
              )}
              {
            activeOption.title === 'Unstake' && (
              <div className="lower-part">
                <div className="form-wrapper-description">
                  {t('aave.unstake_action_desc')}
                </div>
                <div className="unlock-section claim-rewards-wrapper">
                  <div className="balance unlock-balance">
                    <DataItem
                      value={stkAaveData.balance || '0'}
                      decimals={+stkAaveData.balance > 1 ? 2 : 4}
                      type="standard-smaller"
                      symbol="AAVE"
                      label={t('aave.currently_staked')}
                      smallSymbol
                    />
                  </div>
                  {(!(+activatedCooldown) || cooldowns.mustReactivate) &&
                    (
                      <button
                        type="button"
                        className="green button"
                        disabled={!(+stkAaveData.balance) || unstaking || staking || activatingStakeCooldown}
                        onClick={() => (
                          accountType !== ACCOUNT_TYPES.viewOnly ? activateUnstakeCooldownAction() : openLoginModal()
                        )}
                      >
                        {t('common.unlock')}
                      </button>
                    )}
                  {
                    cooldowns.activeAndLocked && (
                      <div className="cooldown-indicator">
                        { parseTimer(unlockTimer) }
                        <div className="cooldown-indicator-label">
                          {t('common.locked')}
                        </div>
                      </div>
                    )
                    }
                  {
                    cooldowns.activeAndUnlocked && (
                    <div>
                      { parseTimer(unstakeTimer) }
                      <div className="cooldown-indicator">
                        {t('common.unlocked')}
                      </div>
                    </div>
                    )
                  }
                </div>

                <div className={`unstake-input-amount-section ${disabledUnstake ? 'disabled' : ''}`}>
                  <div
                    className="max-wrapper"
                    onClick={() => (
                      disabledUnstake ? null : setUnstakeAmount(Dec(stkAaveData.balance).toString())
                    )}
                  >
                    <TooltipWrapper title={stkAaveData.gettingBalance ? t('common.loading') : Dec(stkAaveData.balance).toString()}>
                      {
                      stkAaveData.gettingBalance ?
                        t('common.loading') :
                        `(${t('common.max_val', { '%value': formatNumber(Dec(stkAaveData.balance).toString(), 2) })})`
                    }
                    </TooltipWrapper>
                  </div>
                  <div className="form-item-wrapper stake-input-section has-third-label">
                    <div className="input-label">
                      <AaveIcon width={30} height={30} />
                      {t('aave.aave_token')}
                    </div>
                    <div>
                      <input
                        type="number"
                        className="form-item"
                        onChange={e => setUnstakeAmount(e.target.value)}
                        value={unstakeAmount}
                        placeholder="0"
                        disabled={disabledUnstake || unstaking || staking || activatingStakeCooldown}
                      />
                      <div className="third-label">
                        {parseUsdValue(unstakeAmount || '0')}
                      </div>
                    </div>
                  </div>
                  <div className="stake-button-section">
                    <button
                      type="button"
                      className="button green"
                      disabled={disabledUnstake || unstaking || staking || activatingStakeCooldown}
                      onClick={() => (
                        accountType !== ACCOUNT_TYPES.viewOnly ? unstakeAaveAction(unstakeAmount) : openLoginModal()
                      )}
                    >
                      {t('common.withdraw')}
                    </button>
                    <div className="error-section">
                      { stakingError && (
                        <ErrorBox>{stakingError}</ErrorBox>
                      )}
                      { unstakingError && (
                        <ErrorBox>{unstakingError}</ErrorBox>
                      )}
                      { activatingStakeCooldownError && (
                        <ErrorBox>{activatingStakeCooldownError}</ErrorBox>
                      )}
                      {gettingStakeAaveBalanceError && (
                        <ErrorBox>{gettingStakeAaveBalanceError}</ErrorBox>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )
          }
            </div>
          </div>
        </div>
        )}
      </ModalBody>
    </div>
  );
};


AaveStakeModal.defaultProps = {
  proxyAddress: '',
  aaveData: {},
  stkAaveData: {},
  lendData: {},
};

AaveStakeModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  openLoginModal: PropTypes.func.isRequired,
  accountType: PropTypes.string.isRequired,
  walletType: PropTypes.object.isRequired,
  proxyAddress: PropTypes.string,

  staking: PropTypes.bool.isRequired,
  unstaking: PropTypes.bool.isRequired,
  activatingStakeCooldown: PropTypes.bool.isRequired,

  stakingError: PropTypes.string.isRequired,
  unstakingError: PropTypes.string.isRequired,
  activatingStakeCooldownError: PropTypes.string.isRequired,

  rewardBalance: PropTypes.string.isRequired,
  cooldownSeconds: PropTypes.string.isRequired,
  unstakeWindow: PropTypes.string.isRequired,
  lendAaveRatio: PropTypes.string.isRequired,
  totalStkAAVESupply: PropTypes.string.isRequired,
  stkAaveRewardBalanceAcc: PropTypes.string.isRequired,
  stkAaveRewardBalanceProxy: PropTypes.string.isRequired,
  gettingStakeAaveBalance: PropTypes.bool.isRequired,
  gettingStakeAaveBalanceError: PropTypes.string.isRequired,
  activatedCooldown: PropTypes.string.isRequired,

  aaveData: PropTypes.object,
  stkAaveData: PropTypes.object,
  lendData: PropTypes.object,

  migrateLendToAaveAction: PropTypes.func.isRequired,
  stakeAaveAction: PropTypes.func.isRequired,
  unstakeAaveAction: PropTypes.func.isRequired,
  claimAaveRewardsAction: PropTypes.func.isRequired,
  activateUnstakeCooldownAction: PropTypes.func.isRequired,
  fetchStakeModalData: PropTypes.func.isRequired,
  claimStkAaveRewardsAction: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  general, assets, maker, aaveManage,
}) => ({
  aaveData: assets.AAVE,
  stkAaveData: assets.stkAAVE,
  lendData: assets.LEND,

  proxyAddress: maker.proxyAddress,
  walletType: general.walletType,
  accountType: general.accountType,

  staking: aaveManage.staking,
  unstaking: aaveManage.unstaking,
  activatingStakeCooldown: aaveManage.activatingStakeCooldown,

  stakingError: aaveManage.stakingError,
  unstakingError: aaveManage.unstakingError,
  activatingStakeCooldownError: aaveManage.activatingStakeCooldownError,

  rewardBalance: aaveManage.rewardBalance,
  cooldownSeconds: aaveManage.cooldownSeconds,
  unstakeWindow: aaveManage.unstakeWindow,
  lendAaveRatio: aaveManage.lendAaveRatio,
  activatedCooldown: aaveManage.activatedCooldown,
  totalStkAAVESupply: aaveManage.totalStkAAVESupply,
  stkAaveRewardBalanceAcc: aaveManage.stkAaveRewardBalanceAcc,
  stkAaveRewardBalanceProxy: aaveManage.stkAaveRewardBalanceProxy,
  gettingStakeAaveBalance: aaveManage.gettingStakeAaveBalance,
  gettingStakeAaveBalanceError: aaveManage.gettingStakeAaveBalanceError,
});

const mapDispatchToProps = {
  migrateLendToAaveAction,
  openLoginModal,
  stakeAaveAction,
  unstakeAaveAction,
  claimAaveRewardsAction,
  activateUnstakeCooldownAction,
  fetchStakeModalData,
  claimStkAaveRewardsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AaveStakeModal);
