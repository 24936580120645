import Dec from 'decimal.js';
import { ACCOUNT_TYPES } from '../constants/general';
import { requireAddress, requireContract } from './utils';
import { requireCorrectChain } from './ethService';
import store from '../store';

const BASE_TX_MULTIPLIER = 1.1;
const BASIC_TX_MULTIPLIER = 1.2;

const logTenderlyDebugLink = (contract, contractFunc, funcParams, txParams, rawInput = true) => {
  if (process.env.NODE_ENV !== 'development' && !window.debugLogs) return;
  const _contractFunc = contractFunc.includes('(')
    ? contractFunc
    : contract.options.jsonInterface.find(({ name }) => name === contractFunc);
  const tenderlyLink = `https://dashboard.tenderly.co/defisaver-v2/dev-simulations/simulator/new?${
    [
      `network=${store.getState().general.network}`,
      'gas=4000000',
      `from=${txParams.from}`,
      `value=${new Dec(txParams.value || 0).toString()}`,
      `contractAddress=${contract.options.address.toLowerCase()}`,
      rawInput ? '' : `contractFunction=${window._web3.eth.abi.encodeFunctionSignature(_contractFunc)}`,
      ...(rawInput
        ? [`rawFunctionInput=${contract.methods[contractFunc](...funcParams).encodeABI()}`]
        : funcParams.map(i => `functionInputs=${i}`)),
    ].join('&')}`;
  console.log(`Transaction ${txParams.realGas === 4000000 ? 'failing ❌' : 'working ✅'}`);
  console.log(tenderlyLink);
};

const logTenderlyDebugLinkRaw = (txParams) => {
  if (process.env.NODE_ENV !== 'development' && !window.debugLogs) return;
  const tenderlyLink = `https://dashboard.tenderly.co/defisaver-v2/dev-simulations/simulator/new?${
    [
      `network=${txParams.chainId}`,
      'gas=4000000',
      `from=${txParams.from}`,
      `value=${new Dec(txParams.value || 0).toString()}`,
      `contractAddress=${txParams.to}`,
      `rawFunctionInput=${txParams.data}`,
    ].join('&')}`;
  console.log(`Transaction ${txParams.realGas === 4000000 ? 'failing ❌' : 'working ✅'}`);
  console.log(tenderlyLink);
};
/**
 * Passes down parameters and opens the GasPriceModal
 *
 * @param txParams {Object}
 * @param cb {Function}
 * @param dispatch {Function}
 * @return {Promise<void>}
 */
// TODO Remove
export const approveTransactionHardware = async (txParams, cb, dispatch) => {
  cb(null, txParams); // temporarily moved to callContractManualGas
  // try {
  //   const gasLimit = window._web3.utils.hexToNumberString(txParams.gas);
  //   const gasPrice = await dispatch(openGasPriceModal(gasLimit));
  //
  //   if (gasPrice === false) throw new Error(t('errors.user_canceled'));
  //   if (parseFloat(gasPrice) > 50) {
  //     await wait(500);
  //     const confirmed = await dispatch(confirmViaModal(t('errors.gas_price_very_high', { '%gasPrice': gasPrice })));
  //     if (!confirmed) throw new Error(MM_DENIED_TX_ERROR);
  //   }
  //
  //   txParams.gasPrice = window._web3.utils.numberToHex(ethToWei(gasPrice, 'gwei')); // eslint-disable-line
  //
  //   cb(null, txParams);
  // } catch (e) {
  //   cb(e);
  // }
};

/**
 * Calls the contract method via the web3 contract api
 * with custom estimatedGas
 * Takes in account exceptions because the estimateGas method
 * does not return the correct amount sometimes
 *
 * @param accountType {String}
 * @param path {String} TODO remove
 * @param sendTx {Function}
 * @param contract {Contract}
 * @param contractFunc {String}
 * @param funcParams {Array}
 * @param _txParams {Object}
 * @param funcName {String} TODO remove
 * @param minGas {number}
 * @param extraGas {number} Added to estimated gas - mainly for exchange, in case it falls back to on-chain
 *
 * @return {Promise}
 */
const callTx = (
  accountType, path, sendTx, contract, contractFunc, funcParams, _txParams, funcName = '', minGas = 0, extraGas = 0,
) => new Promise(async (resolve, reject) => {
  try {
    if (accountType === ACCOUNT_TYPES.viewOnly) {
      // TODO add analytics to track tx source here
      throw new Error('You are connected in view-only mode using the "Track wallet" option. Connect your wallet to send transactions. ');
    }
    requireAddress(contract.options.address);
    await requireContract(contract.options.address);
    await requireCorrectChain(); // In case wallet doesn't emit a `chainChanged` event

    const method = contract.methods[contractFunc](...funcParams);

    const txParams = { ..._txParams };

    try {
      txParams.realGas = await method.estimateGas(txParams);
    } catch (err) {
      txParams.realGas = 4000000;
      txParams.gas = 4000000;
      logTenderlyDebugLink(contract, contractFunc, funcParams, txParams);
      if (accountType === ACCOUNT_TYPES.debug) {
        console.log(txParams);
        throw new Error('Can\'t send tx with debug account');
      }
      const failingRes = await sendTx({
        ...txParams, contractFunc, funcParams, send: method.send, failing: true,
      });
      return resolve(failingRes);
    }

    let multiplier = BASE_TX_MULTIPLIER;
    if (parseFloat(txParams.realGas) < 500000) multiplier = BASIC_TX_MULTIPLIER;
    txParams.gas = Math.floor(multiplier * (txParams.realGas + extraGas));
    txParams.gas = Math.max(txParams.gas, minGas);

    logTenderlyDebugLink(contract, contractFunc, funcParams, txParams);
    if (accountType === ACCOUNT_TYPES.debug) {
      console.log(txParams);
      throw new Error('Can\'t send tx with debug account');
    }

    const res = await sendTx({
      ...txParams, contractFunc, funcParams, send: method.send,
    });

    resolve(res);
  } catch (err) {
    reject(err);
  }
});

export const callRawTx = (dataObject, sendRawTx, accountType, title, minGas = 0, extraGas = 0) => new Promise(async (resolve, reject) => {
  try {
    const {
      data, to, from, value,
    } = dataObject;

    const txParams = { ...dataObject };

    if (accountType === ACCOUNT_TYPES.viewOnly) {
      // TODO add analytics to track tx source here
      throw new Error('You are connected in view-only mode using the "Track wallet" option. Connect your wallet to send transactions. ');
    }

    requireAddress(to);
    await requireCorrectChain(); // In case wallet doesn't emit a `chainChanged` event

    try {
      txParams.realGas = await window._web3.eth.estimateGas({
        data, to, from, value,
      });
      console.log(txParams.realGas);
    } catch (err) {
      txParams.realGas = 4000000;
      txParams.gas = 4000000;
      logTenderlyDebugLinkRaw(txParams);
      if (accountType === ACCOUNT_TYPES.debug) {
        console.log(txParams);
        throw new Error('Can\'t send tx with debug account');
      }
      const failingRes = await sendRawTx({
        ...txParams, failing: true,
      });
      return resolve(failingRes);
    }

    console.log(txParams);
    let multiplier = BASE_TX_MULTIPLIER;
    if (parseFloat(txParams.realGas) < 500000) multiplier = BASIC_TX_MULTIPLIER;
    txParams.gas = Math.floor(multiplier * (txParams.realGas + extraGas));
    txParams.gas = Math.max(txParams.gas, minGas);

    logTenderlyDebugLinkRaw(txParams);
    const res = await sendRawTx({
      ...txParams, title,
    });

    resolve(res);
  } catch (err) {
    reject(err);
  }
});

export default callTx;
