import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from '../PrivateRoute/PrivateRoute';

import './FundSafeguard.scss';
import CompoundImportDeapprove from './CompoundImportDeapprove';
import ExchangeDeapprove from './ExchangeDeapprove';

const SafeguardRoutes = ({ match }) => (
  <div className="dashboard-wrapper">
    <Switch>
      <PrivateRoute
        exact
        requireLogin
        path={`${match.path}/exchange`}
        component={ExchangeDeapprove}
      />
      <PrivateRoute
        exact
        requireLogin
        path={`${match.path}/compound-import`}
        component={CompoundImportDeapprove}
      />
    </Switch>
  </div>
);

SafeguardRoutes.propTypes = {
  match: PropTypes.object.isRequired,
};

export default SafeguardRoutes;
