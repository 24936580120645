import dfs from '@defisaver/sdk';
import Dec from 'decimal.js';
import {
  assetAmountInWei, getIlkInfo, getAssetInfo, ilkToAsset, assetAmountInEth, MAXUINT,
} from '@defisaver/tokens';
import { getExchangeOrder, getBestExchangePrice, getSlippageThreshold } from 'services/exchangeServiceV3';
import { getAssetBalance } from '../../assetsService';
import {
  DaiJoinAddress,
  mcdCdpManagerAddress,
  mcdJugAddress,
  MCDSaverProxyContract, McdViewContract,
} from '../../contractRegistryService';
import {
  bytesToString, numberWithCommas, numStringToBytes32, requireAddress,
} from '../../utils';
import { getCdpInfo } from '../makerService';
import * as makerManageMcdService from './makerManageMcdService';
import * as vaultCommonService from '../../vaultCommonService';
import { trackEvent } from '../../analyticsService';
import { getCdpManagerForType, getCollateralInfo } from '../makerMcdService';

/**
 * Wrapper method for the trackEvent method that
 * ads cdp type and asset to the method name
 *
 * @param getState {Function}
 * @param from {String}
 * @param method {String}
 * @param message {String}
 */
export const makerManageTrackEventWrapper = (getState, from, method, message = '') => {
  const { cdp } = getState().maker;
  const typeAndAssetLabel = `${cdp.type} ${cdp.asset}`;
  trackEvent(from, `${method} ${typeAndAssetLabel}`, message);
};

/**
 * Checks cdp type and calls the right callProxyContract method for the type
 *
 * @param getState {Function}
 * @param cdp {Object}
 * @param sendTxFunc {Function}
 * @param funcName {String}
 * @param inputAmount {String}
 * @param additionalParams {Array}
 * @return {Promise<*>}
 */
export const callProxyContract = async (getState, cdp, sendTxFunc, funcName, inputAmount, additionalParams) => {
  const { account, accountType, path } = getState().general;
  const { proxyAddress } = getState().maker;

  const funcParams = [getCdpManagerForType(cdp.type), ...additionalParams[0]];
  const params = [funcParams, additionalParams[1]];

  await makerManageMcdService.callProxyContract(accountType, path, sendTxFunc, proxyAddress, account, funcName, ...params);

  if (funcName === 'shut') return true;
  return getCdpInfo(cdp);
};

/**
 * Checks cdp type and calls the callProxy contract method for the type with the right generate params
 *
 * @param getState {Function}
 * @param inputAmount {String}
 * @param sendTxFunc {Function}
 * @return {*}
 */
export const generate = async (getState, inputAmount, sendTxFunc) => {
  const { cdp } = getState().maker;
  const { globalDebtCurrent, globalDebtCeiling } = await getCollateralInfo(cdp.ilk);
  const leftToGenerate = Dec(globalDebtCeiling).minus(globalDebtCurrent).toString();

  if (Dec(leftToGenerate).lte(inputAmount)) {
    throw new Error(`Debt ceiling reached. The maximum amount available for generating is ${numberWithCommas(Dec.floor(leftToGenerate).toString())} DAI`);
  }

  const funcParams = [mcdJugAddress, DaiJoinAddress, cdp.id, assetAmountInWei(inputAmount, 'DAI')];
  const additionalParams = [funcParams, '0'];

  return callProxyContract(getState, cdp, sendTxFunc, 'draw', inputAmount, additionalParams);
};

/**
 * Checks cdp type and calls the callProxy contract method for the type with the right withdraw params
 *
 * @param getState {Function}
 * @param inputAmount {String}
 * @param sendTxFunc {Function}
 * @return {*}
 */
export const withdraw = async (getState, inputAmount, sendTxFunc) => {
  const { cdp } = getState().maker;

  const funcName = (cdp.asset === 'ETH') ? 'freeETH' : 'freeGem';
  const funcParams = [getIlkInfo(bytesToString(cdp.ilk)).join, cdp.id, assetAmountInWei(inputAmount, cdp.asset)];
  const additionalParams = [funcParams, '0'];

  return callProxyContract(getState, cdp, sendTxFunc, funcName, inputAmount, additionalParams);
};

/**
 * Checks cdp type and calls the callProxy contract method for the type with the right add collateral params
 *
 * @param getState {Function}
 * @param _inputAmount {String}
 * @param sendTxFunc {Function}
 * @return {*}
 */
export const addCollateral = async (getState, inputAmount, sendTxFunc) => {
  const { cdp } = getState().maker;
  let funcName = '';

  const collateralAmount = assetAmountInWei(inputAmount, cdp.asset);
  let value = '0';
  let funcParams = [];

  if (cdp.asset === 'ETH') {
    funcName = 'lockETH';
    funcParams = [getIlkInfo(bytesToString(cdp.ilk)).join, cdp.id];
    value = collateralAmount;
  } else {
    funcName = 'lockGem';
    funcParams = [getIlkInfo(bytesToString(cdp.ilk)).join, cdp.id, collateralAmount, true];
  }

  const additionalParams = [funcParams, value];

  return callProxyContract(getState, cdp, sendTxFunc, funcName, inputAmount, additionalParams);
};

/**
 * Checks cdp type and calls the callProxy contract method for the type with the right payback params
 *
 * @param getState {Function}
 * @param _inputAmount {String}
 * @param cdp {Object}
 * @param proxyAddress {String}
 * @param sendTxFunc {Function}
 * @return {*}
 */
export const payback = async (getState, inputAmount, cdp, proxyAddress, sendTxFunc) => {
  let additionalParams = [];
  let funcName = 'wipe';

  if (inputAmount === cdp.debtDai) { // MCD wipe all
    funcName = 'wipeAll';
    const funcParams = [DaiJoinAddress, cdp.id];
    additionalParams = [funcParams, '0'];
  } else { // MCD wipe
    const funcParams = [DaiJoinAddress, cdp.id, assetAmountInWei(inputAmount, 'DAI')];
    additionalParams = [funcParams, '0'];
  }

  return callProxyContract(getState, cdp, sendTxFunc, funcName, inputAmount, additionalParams);
};

/**
 * Checks cdp type and calls the right transferCdp method for the type
 *
 * @param getState {Function}
 * @param toAddress {String}
 * @param sendTxFunc {Function}
 * @return {*}
 */
export const transferCdp = (getState, toAddress, sendTxFunc) => {
  const { account, accountType, path } = getState().general; // eslint-disable-line
  const { cdp, proxyAddress } = getState().maker;

  const cdpIdBytes32 = numStringToBytes32(cdp.id.toString());
  const txParams = { from: account };
  const funcName = 'giveToProxy';

  return makerManageMcdService.transferCdp(accountType, path, sendTxFunc, toAddress, cdpIdBytes32, proxyAddress, txParams, funcName);
};

/**
 * Fetches rate for exchanging from cdp asset to dai
 *
 * @param asset {String}
 * @param assetAmount {String}
 * @param daiLabel {String}
 * @param proxyAddress {String}
 * @return {Promise<*>}
 */
export const getAssetDaiExchangeRate = async (asset, assetAmount, daiLabel, proxyAddress) => getBestExchangePrice(assetAmount, asset, daiLabel, proxyAddress);

/**
 * Fetches rate for exchanging from dai to eth
 *
 * @param daiSymbol {String}
 * @param daiAmount {String}
 * @param asset {String}
 * @param proxyAddress {String}
 * @return {Promise<*>}
 */
export const getDaiAssetExchangeRate = async (daiSymbol, daiAmount, asset, proxyAddress) => getBestExchangePrice(daiAmount, daiSymbol, asset, proxyAddress);

/**
 * Calculates current slippage based on the price of eth and the exchange rate
 * TODO move
 *
 * @param assetPrice {String}
 * @param exchangeRate
 * @return {String}
 */
export const calculateTradeSizeImpact = (assetPrice, exchangeRate) => {
  let tradeSizeImpact = Dec(assetPrice)
    .minus(exchangeRate)
    .div(assetPrice)
    .times(100)
    .toString();

  if (Dec(tradeSizeImpact).lessThan(0)) tradeSizeImpact = '0';
  if (Dec(tradeSizeImpact).greaterThan(100)) tradeSizeImpact = '100';

  return tradeSizeImpact;
};

/**
 * Gets the max collateral token amount that the user is able to send in the repay action
 *
 * @param cdp {Object}
 * @return {Promise<String>}
 */
export const getMaxRepayBasedOnColl = async (cdp) => {
  const contract = await MCDSaverProxyContract();

  const joinAddr = getIlkInfo(bytesToString(cdp.ilk)).join;
  const data = await contract.methods.getMaxCollateral(cdp.id, cdp.ilk, joinAddr).call();

  // USDC returns 6, and it should return 18 for this to work, so just using 18 decimals always
  let maxRepay = assetAmountInEth(data, 'ETH');
  // const rate = await getAssetDaiExchangeRate(asset, maxRepay, cdp.daiLabel, proxyAddress);

  // const daiDebtAfter = Dec(cdp.debtDai).minus(Dec(rate).times(maxRepay));

  // if (daiDebtAfter.lessThanOrEqualTo(MIN_DAI_DUST + 0.001)) {
  //   maxRepay = Dec(maxRepay).minus(Dec(MIN_DAI_DUST + 0.001).minus(daiDebtAfter).div(rate)).toString();
  // }

  if (Dec(maxRepay).lt(0)) maxRepay = Dec(0).toString();

  return maxRepay;
};

/**
 * Gets the max collateral token amount that the user is able to send in the repay action
 * [Result could slightly overestimate due to slippage (daiDebtAfter can be -1 DAI)]
 * (will need to be changed if flash loans include a fee)
 *
 * @param cdp {Object}
 * @param proxyAddress {String}
 * @return {Promise<String>}
 */
export const getMaxRepay = async (cdp, proxyAddress) => {
  const flashLoanAvailable = true;
  const { price: estPrice } = await getBestExchangePrice(cdp.collateral, cdp.asset, cdp.debtAsset, proxyAddress);
  if (flashLoanAvailable) {
    return new Dec(cdp.debtInAsset).div(estPrice).mul(1.02)
      .toDP(getAssetInfo(cdp.asset).decimals)
      .toString();
  }
  const maxWithdraw = vaultCommonService.getMaxWithdraw(cdp);
  const debtChange = new Dec(maxWithdraw).mul(estPrice);
  // if remaining debt = 0
  if (new Dec(cdp.debtInAsset).minus(debtChange.mul(0.98)).lt(0)) return maxWithdraw;
  // if remaining debt > minDebt
  if (new Dec(cdp.debtInAsset).minus(debtChange.mul(1.02)).gt(cdp.minDebt)) return maxWithdraw;
  // repay up to minDebt
  return new Dec(cdp.debtInAsset).minus(cdp.minDebt).mul(0.98).div(estPrice)
    .toDP(getAssetInfo(cdp.asset).decimals)
    .toString();
};

/**
 * Gets the max Dai amount user is able to pay back
 *
 * @param cdp {Object}
 * @param account {String}
 * @return {Promise}
 */
export const getMaxPayback = async (cdp, account) => {
  const assetBalance = await getAssetBalance(cdp.debtAsset, account);
  return vaultCommonService.getMaxPayback(cdp, assetBalance, false);
};

export const getWithdrawToMinDaiDebt = async (cdp, proxyAddress) => {
  const debt = Dec(cdp.debtDai).minus(cdp.minDebt);
  if (debt.lt(0)) return Dec(Infinity).toString();
  const { price: estPrice } = await getBestExchangePrice(cdp.collateral, cdp.asset, 'DAI', proxyAddress);
  // underestimate amount because if we get better price in the actual exchange, tx will revert due to dai dust ex. 495 dai
  let maxValue = Dec(debt).div(estPrice).mul(0.95).toString();
  if (Dec(maxValue).lt(0)) maxValue = Dec(Infinity).toString();

  return maxValue;
};

export const getFullCdpInfoFromId = async (cdpId) => {
  const contract = McdViewContract();
  const cdp = await contract.methods.getCdpInfo(cdpId).call();
  const ilkInfo = getIlkInfo(cdp.ilk);
  cdp.asset = ilkInfo.asset;
  cdp.id = cdpId;
  cdp.type = ilkInfo.isCrop ? 'crop' : 'mcd';

  return getCdpInfo(cdp);
};

export const checkAvailableDebt = async (cdp, inputAmount) => {
  const { globalDebtCurrent, globalDebtCeiling } = await getCollateralInfo(cdp.ilk);
  const leftToGenerate = new Dec(globalDebtCeiling).minus(globalDebtCurrent);

  if (leftToGenerate.lte(inputAmount)) {
    throw new Error(`Debt ceiling reached. The maximum amount available for generating is ${numberWithCommas(Dec.floor(leftToGenerate).toString())} DAI`);
  }
};

export const getAction = (action, input, cdp, account, proxyAddress) => {
  let instantiatedAction;
  const joinAddress = getIlkInfo(bytesToString(cdp.ilk)).join;
  const managerAddress = getCdpManagerForType(cdp.type);
  switch (action) {
    case 'collateral': {
      if (cdp.asset === 'ETH') {
        instantiatedAction = [
          new dfs.actions.basic.WrapEthAction(input),
          new dfs.actions.maker.MakerSupplyAction(cdp.id, input, joinAddress, proxyAddress, managerAddress),
        ];
      } else {
        instantiatedAction = [new dfs.actions.maker.MakerSupplyAction(cdp.id, input, joinAddress, account, managerAddress)];
      }
      if (cdp.type === 'crop') {
        const rewardToken = 'LDO'; // TODO add rewardToken to ilk data
        instantiatedAction.push(new dfs.actions.basic.SendTokenAction(getAssetInfo(rewardToken).address, account, MAXUINT));
      }
      break;
    }
    case 'generate': {
      instantiatedAction = [new dfs.actions.maker.MakerGenerateAction(cdp.id, input, account, managerAddress)];
      break;
    }
    case 'withdraw': {
      if (cdp.asset === 'ETH') {
        instantiatedAction = [
          new dfs.actions.maker.MakerWithdrawAction(cdp.id, input, joinAddress, proxyAddress, managerAddress),
          new dfs.actions.basic.UnwrapEthAction(input, account),
        ];
      } else {
        instantiatedAction = [new dfs.actions.maker.MakerWithdrawAction(cdp.id, input, joinAddress, account, managerAddress)];
      }
      if (cdp.type === 'crop') {
        const rewardToken = 'LDO'; // TODO add rewardToken to ilk data
        instantiatedAction.push(new dfs.actions.basic.SendTokenAction(getAssetInfo(rewardToken).address, account, MAXUINT));
      }
      break;
    }
    case 'payback': {
      const amount = assetAmountInWei(cdp.debtInAsset, cdp.debtAsset) === input
        ? MAXUINT
        : input;
      instantiatedAction = [new dfs.actions.maker.MakerPaybackAction(cdp.id, amount, account, managerAddress)];
      break;
    }
    default:
      throw new Error('Unknown action');
  }
  return instantiatedAction;
};

export const getMakerRecipe = (primaryAction, primaryInput, secondaryAction, secondaryInput, cdp, account, proxyAddress) => {
  requireAddress(account);
  requireAddress(proxyAddress);
  const recipeActions =
    [{ action: primaryAction, input: primaryInput }, { action: secondaryAction, input: secondaryInput }]
      .filter(a => a.action && a.action !== 'send')
      .map(action => getAction(action.action, action.input, cdp, account, proxyAddress))
      .flat();
  const name = 'recMakerDashAction';
  return new dfs.Recipe(name, recipeActions);
};

export const calculateDebtCeilingPercent = (globalDebtCeiling, creatableDebt) => {
  if (new Dec(globalDebtCeiling).eq(0)) {
    return '100';
  }
  return new Dec(1).sub(new Dec(creatableDebt).div(globalDebtCeiling)).mul(100).toFixed(2);
};
