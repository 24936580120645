import React from 'react';
import { v4 as uuid } from 'uuid';

export default function PancakeswapIcon(props) {
  const id = `pancakeswap_icon${uuid()}`;
  const fill = `url(#${id})`;

  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z" fill={fill} />
      <path fillRule="evenodd" clipRule="evenodd" d="M9.99323 21.0192C11.2801 22.0046 13.1216 22.5533 15.408 22.555H15.4196C17.706 22.5533 19.5477 22.0046 20.8344 21.0192C22.1367 20.0218 22.8276 18.6067 22.8276 17.0053C22.8276 15.4624 22.1381 14.3498 21.3581 13.5977C20.747 13.0083 20.0725 12.6309 19.6028 12.4136C19.732 12.0189 19.8512 11.621 19.9601 11.2202C20.1187 10.6304 20.2744 9.93845 20.2744 9.43158C20.2744 8.83121 20.1434 8.22839 19.7905 7.75994C19.4178 7.26531 18.8566 7 18.1815 7C17.6539 7 17.206 7.1958 16.855 7.53355C16.5199 7.85613 16.297 8.28493 16.1428 8.7316C15.8721 9.51675 15.7666 10.5031 15.7372 11.4875H15.0904C15.061 10.5031 14.9555 9.51675 14.6848 8.7316C14.5306 8.28493 14.3077 7.85613 13.9726 7.53355C13.6216 7.19604 13.1737 7.00024 12.6461 7.00024C11.971 7.00024 11.4098 7.26531 11.0371 7.75994C10.6842 8.22839 10.5532 8.83121 10.5532 9.43133C10.5532 9.93845 10.7089 10.6304 10.8675 11.2202C10.9764 11.621 11.0956 12.0189 11.2248 12.4136C10.7551 12.6309 10.0806 13.0083 9.46947 13.5977C8.68946 14.3498 8 15.4624 8 17.0053C8 18.6067 8.69092 20.0218 9.99323 21.0192ZM12.8343 17.3913C13.2736 17.3913 13.6297 16.9948 13.6297 16.2336C13.6297 15.4725 13.2736 15.076 12.8345 15.076C12.3952 15.076 12.0391 15.4725 12.0391 16.2336C12.0391 16.9948 12.3952 17.3913 12.8343 17.3913ZM18.0034 17.3913C18.4427 17.3913 18.7988 16.9948 18.7988 16.2336H18.799C18.799 15.4725 18.4427 15.076 18.0036 15.076C17.5643 15.076 17.2084 15.4725 17.2084 16.2336C17.2084 16.9948 17.5643 17.3913 18.0034 17.3913Z" fill="white" />
      <defs>
        <linearGradient id={id} x1="15" y1="0" x2="15" y2="30" gradientUnits="userSpaceOnUse">
          <stop stopColor="#5EC4D2" />
          <stop offset="1" stopColor="#47939D" />
        </linearGradient>
      </defs>
    </svg>
  );
}
