export const LIQUIDATION_REQUEST = 'LIQUIDATION_REQUEST';
export const LIQUIDATION_SUCCESS = 'LIQUIDATION_SUCCESS';
export const LIQUIDATION_ERROR = 'LIQUIDATION_ERROR';

export const VAT_BALANCES_REQUEST = 'VAT_BALANCES_REQUEST';
export const VAT_BALANCES_SUCCESS = 'VAT_BALANCES_SUCCESS';
export const VAT_BALANCES_ERROR = 'VAT_BALANCES_ERROR';

export const COLL_BID_REQUEST = 'COLL_BID_REQUEST';
export const COLL_BID_SUCCESS = 'COLL_BID_SUCCESS';
export const COLL_BID_ERROR = 'COLL_BID_ERROR';

export const DAI_BID_REQUEST = 'DAI_BID_REQUEST';
export const DAI_BID_SUCCESS = 'DAI_BID_SUCCESS';
export const DAI_BID_ERROR = 'DAI_BID_ERROR';

export const BID_DATA_REQUEST = 'BID_DATA_REQUEST';
export const BID_DATA_SUCCESS = 'BID_DATA_SUCCESS';
export const BID_DATA_ERROR = 'BID_DATA_ERROR';

export const VAT_EXIT_REQUEST = 'VAT_EXIT_REQUEST';
export const VAT_EXIT_SUCCESS = 'VAT_EXIT_SUCCESS';
export const VAT_EXIT_ERROR = 'VAT_EXIT_ERROR';

export const CLOSE_AUCTION_REQUEST = 'CLOSE_AUCTION_REQUEST';
export const CLOSE_AUCTION_SUCCESS = 'CLOSE_AUCTION_SUCCESS';
export const CLOSE_AUCTION_ERROR = 'CLOSE_AUCTION_ERROR';
