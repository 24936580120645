/* eslint-disable max-classes-per-file */
import { getAssetInfo, getAssetInfoByAddress } from '@defisaver/tokens';
import { exchangeAssets } from 'constants/assets';

const getAsset = (asset) => ((new RegExp(/^0x[0-9a-fA-F]{40}$/).test(asset)) ? getAssetInfoByAddress(asset) : getAssetInfo(asset));

class Type {
  removeReference(id) {
    if (`$${id}` === this.value) this.value = '';
  }
}

export class Amount extends Type {
  constructor(name, amount) {
    super();
    this.name = name;
    this.amount = amount;
    this.value = amount;
  }
}

export class Asset extends Type {
  constructor(name, asset, availableAssets = [...exchangeAssets(), getAssetInfo('WETH')]) {
    const assetInfo = getAsset(asset);
    super();
    this.name = name;
    this._asset = assetInfo.symbol;
    this.address = assetInfo.address;
    this.availableAssets = availableAssets;
  }

  set value(value) {
    const assetInfo = getAsset(value);
    this.address = assetInfo.address;
    this.asset = assetInfo.symbol;
  }

  get value() { return this.address; }

  set asset(symbol) {
    const assetInfo = getAsset(symbol);
    this._asset = assetInfo.symbol;
    this.address = assetInfo.address;
  }

  get asset() { return this._asset; }
}

export class AssetAmount extends Type {
  constructor(name, amount, asset) {
    const assetInfo = getAsset(asset);
    super();
    this.name = name;
    this._asset = assetInfo.symbol;
    this.address = assetInfo.address;
    this.amount = amount;
    this.value = amount;
  }

  set asset(symbol) {
    const assetInfo = getAsset(symbol);
    this._asset = assetInfo.symbol;
    this.address = assetInfo.address;
  }

  get asset() { return this._asset; }
}

export class Slippage extends Type {
  constructor(name, amount) {
    super();
    this.name = name;
    this.amount = amount;
    this.value = amount;
  }
}

export class Deadline extends Type {
  constructor(name, amount) {
    super();
    this.name = name;
    this.amount = amount;
    this.value = amount;
  }
}

export class Address extends Type {
  constructor(name, address) {
    super();
    this.name = name;
    this.address = address;
    this.value = address;
  }
}

export class CdpId extends Type {
  constructor(name, cdpId, protocol = 'maker', allowCrop = true) {
    super();
    this.name = name;
    this.value = cdpId.toString();
    this.protocol = protocol;
    this.allowCrop = allowCrop;
  }
}

export class CdpIlk extends Type {
  constructor(name, ilkLabel) {
    super();
    this.name = name;
    this.ilkLabel = ilkLabel;
    this.value = ilkLabel;
  }
}

export class Source extends Type {
  constructor(name, value = 'wallet', isTo = false) {
    super();
    this.name = name;
    // can be 'wallet' or 'recipe' which is basically the current recipe balance
    this.value = value;
    this.isTo = isTo;
  }
}

export class Market extends Type {
  constructor(name, value) {
    super();
    this.name = name;
    this.value = value;
  }
}

export class InterestMode extends Type {
  constructor(name, value) {
    super();
    this.name = name;
    this.value = value;
    this.label = value.toString() === '1' ? 'Stable' : 'Variable';
  }
}

export class FeeTier extends Type {
  constructor(name, value) {
    super();
    this.name = name;
    this.value = value;
  }
}

export class Tick extends Type {
  constructor(name, value) {
    super();
    this.name = name;
    this.value = value;
  }
}

export class NftId extends Type {
  constructor(name, value) {
    super();
    this.name = name;
    this.value = value;
  }
}

export class Helper extends Type {
  constructor(name, value) {
    super();
    this.name = name;
    this.value = value;
  }
}

export class Select extends Type {
  constructor(name, value, options) {
    super();
    this.name = name;
    this.value = value;
    this.options = options;
  }
}
