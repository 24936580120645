import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import t from 'translate';
import { change, formValueSelector, reduxForm } from 'redux-form';
import Select from 'react-select';
import Dec from 'decimal.js';
import capitalize from 'lodash/capitalize';
import { getAssetInfo } from '@defisaver/tokens';
import { ACCOUNT_TYPES } from '../../../../constants/general';

import { openLoginModal } from '../../../../actions/modalActions';
import { borrowedAssetsOptions, suppliedAssetsOptions } from '../../../../services/moneymarketStateSelectors';
import { fetchAccountDataForWalletTypeAndMarket, migrateAaveToProxy } from '../../../../actions/aaveActions/aaveManageActionsV2';
import { getColorForRatio } from '../../../../services/utils';
import { switchToUserWallet } from '../../../../actions/compoundActions/compoundManageActions';
import { AaveLendingPoolContract } from '../../../../services/contractRegistryService';

import ModalHeader from '../../ModalHeader';
import ModalBody from '../../ModalBody';
import ErrorBox from '../../../Decorative/ErrorBox/ErrorBox';
import headerBg from '../../Compound/CompoundProxyMigrationModal/upgrade-modal-bg.svg';
import DataItem from '../../../DataItem/DataItem';
import StepStatus from '../../../Decorative/StepStatus/StepStatus';
import withErrorFallback from '../../../ErrorFallback/ErrorFallback';
import WarningBox from '../../../Decorative/WarningBox/WarningBox';

import './AaveProxyMigrationModalV1.scss';

const AaveProxyMigrationModalV1 = ({
  creatingDSProxy, creatingDSProxyError, openLoginModal, assets, usedAssetsProxy, assetsData,
  closeModal, proxyAddress, accountType, borrowLimitUsdProxy, borrowedUsdProxy, usedAssetsAcc,
  borrowLimitUsdAcc, borrowedUsdAcc, migratingToProxy, migratingToProxyError,
  approvingDsProxy, approvedDsProxy, approvingDsProxyError, suppliedAssets, borrowedAssets, formValues,
  switchToUserWallet, migrateAaveToProxy, change, fetchingProxyData, fetchingAccountData,
  account, approvingDsProxyOrigFee, approvedDsProxyOrigFee, approvingDsProxyOrigFeeError, selectedMarket,
  newATokenApproving, newATokenApprovingError, newATokenApproved, fetchAccountDataForWalletTypeAndMarket,
}) => {
  useEffect(() => {
    if (selectedMarket.value === 'v1') {
      fetchAccountDataForWalletTypeAndMarket('proxy', proxyAddress, 'v1');
    } else {
      fetchAccountDataForWalletTypeAndMarket('proxy', proxyAddress, 'v2');
    }
  }, [proxyAddress, selectedMarket]);

  let step1Status = 'empty';
  if (proxyAddress) step1Status = 'done';
  if (creatingDSProxy) step1Status = 'loading';
  if (creatingDSProxyError) step1Status = 'error';

  let step2Status = 'empty';
  if (approvedDsProxy || newATokenApproved) step2Status = 'done';
  if (approvingDsProxy || newATokenApproving) step2Status = 'loading';
  if (approvingDsProxyError || newATokenApprovingError) step2Status = 'error';

  let step3Status = 'empty';
  if (migratingToProxy) step3Status = 'loading';
  if (migratingToProxyError) step3Status = 'error';

  const [collOptions, setCollOptions] = useState([]);
  const [_borrowOptions, setBorrowOptions] = useState([]);
  const borrowOptions = [..._borrowOptions, { value: 'none', label: 'None' }];

  const [origFee, setOrigFee] = useState('0');
  const approveOrigFeeAsset = origFee !== '0';

  useEffect(() => {
    setCollOptions(suppliedAssets.filter(a => !usedAssetsProxy[a.label]?.isBorrowed));
    setBorrowOptions(borrowedAssets.filter(a => !usedAssetsProxy[a.label]?.isSupplied));
  }, [suppliedAssets[0]?.label, borrowedAssets[0]?.label, usedAssetsProxy]);

  useEffect(() => { change('collAsset', collOptions[0]); }, [collOptions[0]?.label]);
  useEffect(() => { change('borrowAsset', borrowOptions[0]); }, [borrowOptions[0]?.label]);

  const [borrowPowerUsedAcc, setBorrowPowerUsedAcc] = useState(0);
  const [borrowPowerUsedProxy, setBorrowPowerUsedProxy] = useState(0);
  useEffect(() => {
    if (formValues.collAsset && formValues.borrowAsset) {
      const collAssetDataAcc = assetsData[formValues.collAsset.label];
      const collUsedAssetDataAcc = usedAssetsAcc[formValues.collAsset.label];
      const borrowAssetDataAcc = assetsData[formValues.borrowAsset.label];
      const borrowUsedAssetDataAcc = usedAssetsAcc[formValues.borrowAsset.label];

      const afterBorrowLimitUsdAcc = (collUsedAssetDataAcc?.collateral)
        ? Dec(borrowLimitUsdAcc).minus(Dec(collUsedAssetDataAcc?.suppliedUsd || '0').mul(collAssetDataAcc.collateralFactor)).toDecimalPlaces(10).toString()
        : borrowLimitUsdAcc;
      const afterBorrowedUsdAcc = Dec(borrowedUsdAcc).minus(borrowUsedAssetDataAcc?.borrowedUsd || '0').toString();
      if (afterBorrowLimitUsdAcc === '0' && afterBorrowedUsdAcc !== '0') setBorrowPowerUsedAcc(Infinity);
      else if (afterBorrowedUsdAcc === '0') setBorrowPowerUsedAcc(0);
      else setBorrowPowerUsedAcc(Dec(afterBorrowedUsdAcc).div(afterBorrowLimitUsdAcc).mul(100).toNumber());

      const collAssetDataProxy = assetsData[formValues.collAsset.label];
      const collUsedAssetDataProxy = usedAssetsProxy[formValues.collAsset.label];
      const borrowAssetDataProxy = assetsData[formValues.borrowAsset.label];
      const borrowUsedAssetDataProxy = usedAssetsProxy[formValues.borrowAsset.label];

      let afterBorrowLimitUsdProxy = new Dec(borrowLimitUsdProxy || '0').plus(new Dec(collUsedAssetDataAcc?.suppliedUsd || '0').mul(collAssetDataAcc.collateralFactor)).toString();
      if (+collUsedAssetDataProxy?.suppliedUsd && !collUsedAssetDataProxy?.collateral) {
        // if collAsset supplied to proxy position and not already enabled as collateral
        afterBorrowLimitUsdProxy = new Dec(afterBorrowLimitUsdProxy).plus(new Dec(collUsedAssetDataProxy.suppliedUsd || '0').mul(collAssetDataAcc.collateralFactor)).toString();
      }

      const afterBorrowedUsdProxy = new Dec(borrowedUsdProxy || '0').plus(borrowUsedAssetDataAcc?.borrowedUsd || '0').toString();
      setBorrowPowerUsedProxy(Dec(afterBorrowedUsdProxy).div(afterBorrowLimitUsdProxy).mul(100).toNumber());
    }
  });

  useEffect(() => {
    (async () => {
      if (!formValues.borrowAsset?.value || formValues.borrowAsset.value === 'none') return;
      const lendingPool = AaveLendingPoolContract();
      const assetAddress = getAssetInfo(formValues.borrowAsset.value).address;
      const { originationFee } = await lendingPool.methods.getUserReserveData(assetAddress, account).call();
      setOrigFee(originationFee);
    })();
  }, [formValues.borrowAsset?.value]);

  const hasAssetsOnProxy = usedAssetsProxy && !!Object.keys(usedAssetsProxy).length;
  const [borrowPowerLimitProxy, setBorrowPowerLimitProxy] = useState(90);
  const [warnRatio, setWarnRatio] = useState(110);
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    if (hasAssetsOnProxy && formValues.collAsset && formValues.borrowAsset.value !== 'none') {
      const collAssetForMigration = formValues.collAsset.value;
      const borrAssetForMigration = formValues.borrowAsset.value;
      const proxyAssetsValues = Object.values(usedAssetsProxy);
      const oneAssetUsed = proxyAssetsValues.length === 1;
      const twoAssetsUsed = proxyAssetsValues.length === 2;
      const proxyAssetOne = proxyAssetsValues[0];
      const proxyAssetTwo = proxyAssetsValues[1];

      if (
        oneAssetUsed
        && collAssetForMigration === proxyAssetOne.symbol
        && borrAssetForMigration === proxyAssetOne.symbol
      ) {
        setBorrowPowerLimitProxy(99.9);
        setWarnRatio(99);
        setShowWarning(true);
      } else if (
        twoAssetsUsed
        && (collAssetForMigration === proxyAssetOne.symbol || collAssetForMigration === proxyAssetTwo.symbol)
        && (borrAssetForMigration === proxyAssetOne.symbol || borrAssetForMigration === proxyAssetTwo.symbol)
        && (getAssetInfo(proxyAssetOne.symbol).isStable && getAssetInfo(proxyAssetTwo.symbol).isStable)
        && (
          (proxyAssetOne.collateral && !proxyAssetTwo.isSupplied && proxyAssetTwo.isBorrowed)
          || (proxyAssetTwo.collateral && !proxyAssetOne.isSupplied && proxyAssetOne.isBorrowed)
        )
      ) {
        setBorrowPowerLimitProxy(99);
        setWarnRatio(90);
        setShowWarning(true);
      } else {
        setBorrowPowerLimitProxy(90);
        setWarnRatio(110);
        setShowWarning(false);
      }
    }
  });

  const loading = creatingDSProxy || approvingDsProxy || approvingDsProxyOrigFee || migratingToProxy || newATokenApproving;
  const error = creatingDSProxyError || approvingDsProxyError || approvingDsProxyOrigFeeError || migratingToProxyError || newATokenApprovingError;

  const borrowPowerLimitAcc = 90;
  const assetsNotSupplied = !suppliedAssets.length;
  const assetsNotSupported = !collOptions.length || !borrowOptions.length;
  const unavailable = assetsNotSupplied || assetsNotSupported || borrowPowerUsedProxy > borrowPowerLimitProxy || borrowPowerUsedAcc > borrowPowerLimitAcc;

  return (
    <div className="aave-proxy-migration-modal-wrapper compound-proxy-migration-modal-wrapper">
      <ModalHeader closeModal={closeModal} />
      <ModalBody>
        <div className="new-modal-top-wrapper" style={{ backgroundImage: `url(${headerBg})` }}>
          <h1>Aave Migration</h1>
        </div>
        <div className="new-modal-content-wrapper">
          <div className="Flex">
            <div className="left-wrapper">
              <p>{t('aave.aave_migrate_modal_info1')}</p>
              <p>{t('aave.aave_migrate_modal_info2')}</p>
              <p><a target="_blank" rel="noopener noreferrer" href="https://help.defisaver.com/smart-savings/what-is-the-user-wallet">{t('account.wallet_info_learn_more')}</a></p>
              <p>{t('aave.aave_migrate_modal_info3')}</p>
            </div>
            <div className="separator" />
            {
              !loading &&
              !assetsNotSupplied &&
              !assetsNotSupported && (
                <div className="right-wrapper form-wrapper">
                  <div className="form-item-wrapper">
                    <div className="input-values">
                      <label htmlFor="coll-asset">{t('common.collateral')} {t('common.asset').toLowerCase()}:</label>
                      <Select
                        id="coll-asset"
                        className="select box transparent"
                        classNamePrefix="select"
                        value={formValues.collAsset}
                        onChange={e => change('collAsset', e)}
                        options={collOptions}
                        onBlur={event => event.preventDefault()}
                      />
                    </div>
                  </div>

                  <div className="form-item-wrapper">
                    <div className="input-values">
                      <label htmlFor="borrow-asset">{t('common.debt')} {t('common.asset').toLowerCase()}:</label>
                      <Select
                        id="borrow-asset"
                        className="select box transparent"
                        classNamePrefix="select"
                        value={formValues.borrowAsset}
                        onChange={e => change('borrowAsset', e)}
                        options={borrowOptions}
                        onBlur={event => event.preventDefault()}
                      />
                    </div>
                  </div>

                  {!unavailable && showWarning && borrowPowerLimitProxy > warnRatio && (
                    <WarningBox>{t('misc.high_borrow_warn', { currRatio: `${warnRatio}%` })}</WarningBox>
                  )}

                  <DataItem
                    label={`${t('compound.borrow_power_used')} (${capitalize(t('common.account'))})`}
                    symbol="%"
                    symbolAfter
                    value={borrowPowerUsedAcc}
                    type="small"
                    style={{ color: getColorForRatio(10000 / borrowPowerUsedAcc, 100, false) }}
                    showInfinity
                    decimals={1}
                  />
                  <DataItem
                    label={`${t('compound.borrow_power_used')} (${t('account.user_wallet')})`}
                    symbol="%"
                    symbolAfter
                    value={borrowPowerUsedProxy}
                    type="small"
                    style={{ color: getColorForRatio(10000 / borrowPowerUsedProxy, 100, false) }}
                    decimals={1}
                  />

                  {error && <ErrorBox>{error}</ErrorBox>}
                </div>
              )
            }
            {
              loading && (
                <div className="right-wrapper">
                  <div className="migrate-steps-wrapper">
                    <StepStatus title={t('common.create_dsproxy')} status={step1Status} />
                    {
                      formValues.borrowAsset.value !== 'none' && (
                        <StepStatus title={`${t('common.approve')} ${formValues.collAsset.cAsset}`} status={step2Status} />
                      )
                    }
                    <StepStatus title={t('common.migrate')} status={step3Status} />
                  </div>
                </div>
              )
            }
            {
              assetsNotSupplied && (
                <div className="right-wrapper">
                  <WarningBox>{t('account.migration_unavailable')}</WarningBox>
                  <p>{t('aave.aave_migrate_not_supplied')}</p>
                  { suppliedAssets.length > 0 && (<p>{t('aave.aave_migrate_withdraw')}</p>) }
                  <p>You can use Aave, including the advanced features, by <a className="Pointer" onClick={() => { closeModal(); switchToUserWallet(); }}>switching to your Smart Wallet</a>.</p>
                </div>
              )
            }
            {
              !assetsNotSupplied &&
              assetsNotSupported && (
                <div className="right-wrapper">
                  <WarningBox>{t('account.migration_unavailable')}</WarningBox>
                  <p>{t('aave.aave_migrate_no_assets')}</p>
                </div>
              )
            }
          </div>
        </div>
      </ModalBody>
      <div className="modal-controls">
        <button
          form="sell-cdp-form"
          type="button"
          disabled={loading || unavailable || fetchingProxyData || fetchingAccountData}
          onClick={
            () => (
              accountType !== ACCOUNT_TYPES.viewOnly ?
                migrateAaveToProxy(formValues.collAsset, formValues.borrowAsset, closeModal) :
                openLoginModal())
          }
          className="button green"
        >
          {loading ? t('common.migrating') : t('common.migrate')}
        </button>
      </div>
    </div>
  );
};

const AaveProxyMigrationModalComp = reduxForm({
  form: 'aaveProxyMigrationForm',
  destroyOnUnmount: false,
})(AaveProxyMigrationModalV1);
const selector = formValueSelector('aaveProxyMigrationForm');

AaveProxyMigrationModalV1.propTypes = {
  closeModal: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  assets: PropTypes.object.isRequired,
  assetsData: PropTypes.object.isRequired,
  usedAssetsAcc: PropTypes.object.isRequired,

  account: PropTypes.string.isRequired,
  proxyAddress: PropTypes.string,
  creatingDSProxy: PropTypes.bool.isRequired,
  creatingDSProxyError: PropTypes.string.isRequired,

  openLoginModal: PropTypes.func.isRequired,
  accountType: PropTypes.string.isRequired,

  borrowLimitUsdProxy: PropTypes.string.isRequired,
  borrowedUsdProxy: PropTypes.string.isRequired,

  borrowLimitUsdAcc: PropTypes.string.isRequired,
  borrowedUsdAcc: PropTypes.string.isRequired,

  approvingDsProxy: PropTypes.bool.isRequired,
  approvedDsProxy: PropTypes.bool.isRequired,
  approvingDsProxyError: PropTypes.string,

  approvingDsProxyOrigFee: PropTypes.bool.isRequired,
  approvedDsProxyOrigFee: PropTypes.bool.isRequired,
  approvingDsProxyOrigFeeError: PropTypes.string,

  migratingToProxy: PropTypes.bool.isRequired,
  migratingToProxyError: PropTypes.string,

  suppliedAssets: PropTypes.array.isRequired,
  borrowedAssets: PropTypes.array.isRequired,
  usedAssetsProxy: PropTypes.object.isRequired,

  formValues: PropTypes.object.isRequired,

  switchToUserWallet: PropTypes.func.isRequired,
  migrateAaveToProxy: PropTypes.func.isRequired,

  fetchAccountDataForWalletTypeAndMarket: PropTypes.func.isRequired,
  fetchingProxyData: PropTypes.bool.isRequired,
  fetchingAccountData: PropTypes.bool.isRequired,
  selectedMarket: PropTypes.object.isRequired,

  newATokenApproving: PropTypes.bool.isRequired,
  newATokenApprovingError: PropTypes.string.isRequired,
  newATokenApproved: PropTypes.bool.isRequired,

};

AaveProxyMigrationModalV1.defaultProps = {
  proxyAddress: '',
  approvingDsProxyError: '',
  approvingDsProxyOrigFeeError: '',
  migratingToProxyError: '',
};

const mapStateToProps = state => ({
  suppliedAssets: suppliedAssetsOptions(state, 'aave'),
  borrowedAssets: borrowedAssetsOptions(state, 'aave'),
  formValues: {
    collAsset: selector(state, 'collAsset'),
    borrowAsset: selector(state, 'borrowAsset'),
  },

  proxyAddress: state.maker.proxyAddress,
  creatingDSProxy: state.maker.creatingDSProxy,
  creatingDSProxyError: state.maker.creatingDSProxyError,

  fetchingAccountData: state.aaveManage.account[state.aaveManage.selectedMarket.value].fetchingAccountData,
  fetchingProxyData: state.aaveManage.proxy[state.aaveManage.selectedMarket.value].fetchingAccountData,

  account: state.general.account,
  accountType: state.general.accountType,
  assets: state.assets,
  assetsData: state.aaveManage[state.aaveManage.selectedMarket.value].assetsData,
  usedAssetsAcc: state.aaveManage.account[state.aaveManage.selectedMarket.value].usedAssets,
  usedAssetsProxy: state.aaveManage.proxy[state.aaveManage.selectedMarket.value].usedAssets,
  borrowLimitUsdProxy: state.aaveManage.proxy[state.aaveManage.selectedMarket.value].borrowLimitUsd,
  borrowedUsdProxy: state.aaveManage.proxy[state.aaveManage.selectedMarket.value].borrowedUsd,
  borrowLimitUsdAcc: state.aaveManage.account[state.aaveManage.selectedMarket.value].borrowLimitUsd,
  borrowedUsdAcc: state.aaveManage.account[state.aaveManage.selectedMarket.value].borrowedUsd,
  migratingToProxy: state.aaveManage.migratingToProxy,
  migratingToProxyError: state.aaveManage.migratingToProxyError,
  approvingDsProxy: !!state.assets[selector(state, 'collAsset')?.cAsset]?.approvingDsProxy,
  approvingDsProxyError: state.assets[selector(state, 'collAsset')?.cAsset]?.approvingDsProxyError,
  approvedDsProxy: !!state.assets[selector(state, 'collAsset')?.cAsset]?.approvedDsProxy,
  approvingDsProxyOrigFee: !!state.assets[selector(state, 'borrowAsset')?.value]?.approvingDsProxy,
  approvingDsProxyOrigFeeError: state.assets[selector(state, 'borrowAsset')?.value]?.approvingDsProxyError,
  approvedDsProxyOrigFee: !!state.assets[selector(state, 'borrowAsset')?.value]?.approvedDsProxy,

  selectedMarket: state.aaveManage.selectedMarket,

  newATokenApproving: state.aaveManage.newATokenApproving,
  newATokenApprovingError: state.aaveManage.newATokenApprovingError,
  newATokenApproved: state.aaveManage.newATokenApproved,
});

const mapDispatchToProps = {
  migrateAaveToProxy,
  openLoginModal,
  fetchAccountDataForWalletTypeAndMarket,
  change,
  switchToUserWallet,
};

export default withErrorFallback(connect(mapStateToProps, mapDispatchToProps)(AaveProxyMigrationModalComp));
