import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { connect } from 'react-redux';
import {
  SelectOption,
  SelectValueContainer,
} from 'components/Forms/SelectComponent/SelectComponent';
import withErrorFallback from 'components/ErrorFallback/ErrorFallback';
import { checkBalanceOnRecipeChange } from 'actions/assetsActions';
import Dec from 'decimal.js';

const AssetActionField = ({
  type, i, actualValue, handleChange, checkBalanceOnRecipeChange, hasIndex, index,
}) => {
  const assetOptions = type.availableAssets.map(asset => ({
    ...asset,
    value: asset.address,
    label: asset.label ? asset.label : asset.symbol,
    meta: {
      icon: asset.icon,
    },
  }));
  const value = assetOptions.find(i => {
    if (hasIndex) {
      return i.value === actualValue && Dec(i.index).eq(index);
    }
    return i.value === actualValue;
  }) || null;

  useEffect(() => {
    // fetch balance for initially selected asset
    if (value) checkBalanceOnRecipeChange(value.symbol);
  }, []);

  return (
    <Select
      className="select box"
      classNamePrefix="select"
      value={value}
      components={{
        Option: SelectOption,
        ValueContainer: SelectValueContainer,
      }}
      onChange={(e) => {
        checkBalanceOnRecipeChange(e.symbol);
        handleChange({ target: e }, i);
      }}
      options={assetOptions}
      onBlur={event => event.preventDefault()}
      // yearn has vaults with same value (base asset address)
      getOptionValue={option => (option.index ? `${option.value}${option.index}` : option.value)}
    />
  );
};

AssetActionField.defaultProps = {
  actualValue: '',
  hasIndex: false,
  index: 0,
};

AssetActionField.propTypes = {
  handleChange: PropTypes.func.isRequired,
  type: PropTypes.object.isRequired,
  i: PropTypes.number.isRequired,
  actualValue: PropTypes.string,
  checkBalanceOnRecipeChange: PropTypes.func.isRequired,
  hasIndex: PropTypes.bool,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  checkBalanceOnRecipeChange,
};

export default withErrorFallback(connect(mapStateToProps, mapDispatchToProps)(AssetActionField));
