import memoize from 'memoizee';
import dfs from '@defisaver/sdk';
import {
  CdpId, CdpIlk, AssetAmount, Source,
} from 'components/Recipes/RecipeCreator/inputTypes';
import RecipeAction from 'recipeActions/RecipeAction';
import { paybackAfterValues } from 'actions/makerActions/makerManageActions/makerManageAfterValues';
import { getFullCdpInfoFromId as _getFullCdpInfoFromId } from 'services/makerServices/makerManageServices/makerManageService';
import PaybackIcon from '../recipeIcons/Payback.svg';
import { formatNumber } from '../../services/utils';
import { assetAmountInWeiIgnorePointer } from '../../services/recipeCreator/recipeActionUtils';
import { MAXUINT } from '../../constants/general';
import { getCdpManagerForType } from '../../services/makerServices/makerMcdService';

const getFullCdpInfoFromId = memoize(_getFullCdpInfoFromId, { maxAge: 2 * 60 * 1000 });

export default class MakerPaybackAction extends RecipeAction {
  static prettyName = 'Pay DAI back to Vault';

  static protocol = 'maker';

  static protocolPrettyName = 'Maker';

  static description = 'Pays back DAI debt to the selected Vault.';

  constructor(cdpID = 0, from = 'wallet', amount = '') {
    super();
    this.inputs = [
      new CdpId('CDP ID', cdpID),
      new Source('From', from),
      new AssetAmount('Amount', amount, 'DAI'),
    ];
    this.output = new AssetAmount('output', 0, 'DAI');
  }

  async getAfterValues(_balances = {}, returnValues = [], actions = [], _positions = {}, getState) {
    const positions = { ..._positions };
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const cdpId = args[0];
    if (!positions[`maker_${cdpId}`]) positions[`maker_${cdpId}`] = await getFullCdpInfoFromId(cdpId);
    const asset = 'DAI';
    let amount = args[2];
    if (amount === 'All available') amount = positions[`maker_${cdpId}`]?.debtInAsset || '0';
    const { afterPosition, balances, returnValue } = await paybackAfterValues(
      {
        amount,
        from: args[1],
      },
      {
        cdp: positions[`maker_${cdpId}`],
        assets: getState().assets,
        account: getState().general.account,
        proxyAddress: getState().maker.proxyAddress,
      },
      _balances,
    );
    positions[`maker_${cdpId}`] = afterPosition;
    this.output.value = returnValue;
    return { returnValue: this.output, balances, positions };
  }


  async toDfsAction(getState, recipeActions, returnValues, positions) {
    const {
      general: { account },
      maker: { proxyAddress },
    } = getState();
    const args = this.mapReturnValuesToArgs(returnValues, recipeActions);
    const managerAddress = getCdpManagerForType(positions[`maker_${args[0]}`].type);
    const from = this.inputs[1].value === 'wallet' ? account : proxyAddress;
    const amount = this.inputs[2].value === 'All available' ? MAXUINT : assetAmountInWeiIgnorePointer(this.inputs[2].value, 'DAI');
    return new dfs.actions.maker.MakerPaybackAction(this.inputs[0].value, amount, from, managerAddress);
  }

  static getIcon() {
    return PaybackIcon;
  }

  _getPrettyName(actionCalls, actions) {
    const args = this.mapReturnValuesToArgs(actionCalls.map(a => a.returnValue), actions);
    return `Pay ${args[2] === 'All available' ? 'all' : formatNumber(args[2])} DAI back to Vault #${args[0]}`;
  }
}
