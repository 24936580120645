import Dec from 'decimal.js';
import t from 'translate';
import { assetAmountInWei, getAssetInfo, getIlkInfo } from '@defisaver/tokens';
import { getBestExchangePrice } from './exchangeServiceV3';
import { ALLOWED_PERCENT_OVER_LIQ_RATIO_MAKER } from '../constants/general';
import { ENS_REGEX, ASSETS_FROM_MAKER_ASSET } from '../constants/assets';
import { isAddress, ethToWeth, getEthAmountForDecimals } from './utils';
import {
  getLPLiquidityMintedEstimate,
} from './uniswapServices';
import { getActualMintAmounts } from './gelatoService';
import { getLpEstimate } from './curveServices/curveService';

/**
 * @param vault {RaiSafeInfo|MakerVaultInfo}
 * @param debtAssetBalance {string}
 * @param accountForMinDebt {boolean} Ignore minimum debt because it will be handled in the UI
 * @returns {string}
 */
export const getMaxPayback = (vault, debtAssetBalance, accountForMinDebt = true) => {
  const maxPayback = Dec.min(debtAssetBalance, vault.debtInAsset).toString();

  if (!accountForMinDebt) return maxPayback;

  // if remaining debt = 0
  if (new Dec(vault.debtInAsset).minus(maxPayback).lte(0)) return maxPayback;

  // if remaining debt > minDebt
  if (new Dec(vault.debtInAsset).minus(maxPayback).gt(vault.minDebt)) return maxPayback;

  // repay up to minDebt
  const debtMinusMinDebt = new Dec(vault.debtInAsset).minus(vault.minDebt).toString();
  return new Dec(debtMinusMinDebt).lt(0) ? '0' : debtMinusMinDebt;
};

/**
 * @param vault {RaiSafeInfo|MakerVaultInfo}
 * @returns {string}
 */
export const getMaxBorrow = (vault) => {
  const liqRatio = (vault.liqPercent / 100) + 0.001;
  let maxGen = new Dec(vault.collateralUsd).div(liqRatio).minus(vault.debtUsd).div(vault.debtAssetPrice)
    .toString();
  if (new Dec(maxGen).lt(0)) maxGen = '0';

  // handle global debt ceiling
  if (vault.creatableDebt && new Dec(maxGen).gt(vault.creatableDebt)) maxGen = vault.creatableDebt;

  return new Dec(maxGen).toDP(getAssetInfo(vault.debtAsset).decimals).toString();
};

/**
 * @param vault {RaiSafeInfo|MakerVaultInfo|LiquityTroveInfo}
 * @returns {string}
 */
export const getMaxWithdraw = (vault) => {
  const liqRatio = (vault.liqPercent / 100) + 0.001;
  let maxEth = new Dec(vault.collateral).minus(new Dec(liqRatio).times(vault.debtUsd).div(vault.assetPrice)).toString();

  if (new Dec(maxEth).lt(0)) maxEth = '0';

  return new Dec(maxEth).toDP(getAssetInfo(vault.asset).decimals).toString();
};

/**
 * Calculates liquidationPrice & ratio based on price - is used because the one in getCdpInfo is not correct
 *
 * @param cdp {{liqPercent: number, assetPrice: string, debtAssetPrice: string}} cdp object from store
 * @param collateral {(string|number)} Collateral in the cdp in eth
 * @param debtInAsset {(string|number)} Amount of debt that is in the cdp
 * @return {{liquidationPrice: number, ratio: number}} Returns the liquidation price and ration based on the input params
 */
export const calcLiqPriceAndRatio = (cdp, collateral = '0', debtInAsset = '0') => {
  const liqRatio = cdp.liqPercent / 100;

  const liquidationPrice = new Dec(debtInAsset).times(cdp.debtAssetPrice || 1).times(liqRatio).div(collateral)
    .toNumber();
  if (liquidationPrice === 0) return { liquidationPrice: 0, ratio: 0 };

  let ratio = new Dec(collateral)
    .times(cdp.assetPrice)
    .div(debtInAsset)
    .div(cdp.debtAssetPrice || 1)
    .times(100)
    .toDP(10, Dec.ROUND_HALF_EVEN)
    .toNumber();

  if (ratio > 1e10) ratio = 0;

  return { liquidationPrice, ratio };
};

/**
 * Gets the max dai that the user is able to send in the boost action
 * [Result could slightly underestimate due to slippage (ie. ratio could be higher so it's safe)]
 * (will need to be changed if flash loans include a fee)
 *
 * @param cdp {RaiSafeInfo|MakerVaultInfo|LiquityTroveInfo}
 * @param proxyAddress {String}
 * @param buffer {number}
 * @return {Promise<String>}
 */
export const getMaxBoost = async (cdp, proxyAddress, buffer = 0.005) => {
  let maxBoost = 0;

  // FL Boost supplies collateral first, which fails if CDP is under debt limit
  // In that case, allow only the standard Boost
  if (cdp.debtTooLow) maxBoost = getMaxBorrow(cdp);
  else {
    const targetRatio = (cdp.liqPercent / 100) + 0.001;
    // try to roughly overestimate the price by using the amount of debt equal to the collateral
    const biggestDebtAmount = new Dec(cdp.collateralUsd).div(cdp.debtAssetPrice).toString();
    const { price: estMarketPrice } = await getBestExchangePrice(biggestDebtAmount, cdp.debtAsset, cdp.asset, proxyAddress);
    maxBoost = new Dec(cdp.collateralUsd).div(cdp.debtAssetPrice).sub(new Dec(targetRatio).mul(cdp.debtInAsset))
      .div(new Dec(targetRatio).sub(new Dec(cdp.assetPrice).div(cdp.debtAssetPrice).mul(estMarketPrice)))
      .mul(1 - buffer)
      .toString();
  }

  if (cdp.creatableDebt) maxBoost = Dec.min(cdp.creatableDebt, maxBoost).toString();

  return new Dec(maxBoost).toDP(getAssetInfo(cdp.debtAsset).decimals).toString();

  // TODO check if maxBoost > liquidity pool size
};

/**
 * @param {MakerVaultInfo|RaiSafeInfo|LiquityTroveInfo} cdp
 * @param {string} inputAmount
 * @returns {boolean}
 */
export const useFlForRepay = (cdp, inputAmount) => {
  if (cdp.debtTooLow) return true;
  const { ratio } = calcLiqPriceAndRatio(cdp, new Dec(cdp.collateral).minus(inputAmount).toString(), cdp.debtInAsset);
  return new Dec(ratio).lt(cdp.liqPercent);
};

/**
 * @param {MakerVaultInfo|RaiSafeInfo|LiquityTroveInfo} cdp
 * @param {string} inputAmount
 * @returns {boolean}
 */
export const useFlForBoost = (cdp, inputAmount) => {
  const { ratio } = calcLiqPriceAndRatio(cdp, cdp.collateral.toString(), new Dec(cdp.debtInAsset).plus(inputAmount).toString());
  return new Dec(ratio).lt(cdp.liqPercent);
};

/**
 * Calculates boost amount to get to target ratio
 * @param {MakerVaultInfo|RaiSafeInfo|LiquityTroveInfo} cdp
 * @param proxyAddress
 * @param targetRatio
 * @returns {Promise<string>}
 */

export const getBoostAmount = async (cdp, proxyAddress, targetRatio) => {
  let boostAmount = 0;

  // FL Boost supplies collateral first, which fails if CDP is under debt limit
  // In that case, allow only the standard Boost
  if (cdp.debtTooLow) boostAmount = getMaxBorrow(cdp);
  else {
    const biggestDebtAmount = new Dec(cdp.collateralUsd).div(cdp.debtAssetPrice).toString();
    const estMarketPrice = await getBestExchangePrice(biggestDebtAmount, cdp.debtAsset, cdp.asset, proxyAddress);
    boostAmount = new Dec(cdp.collateralUsd).div(cdp.debtAssetPrice).sub(new Dec(targetRatio).mul(cdp.debtInAsset))
      .div(new Dec(targetRatio).sub(new Dec(cdp.assetPrice).div(cdp.debtAssetPrice).mul(estMarketPrice.price)))
      .toString();
  }

  return boostAmount;
};

/**
 *  Calculates repay amount for target ratio
 * @param {MakerVaultInfo|RaiSafeInfo|LiquityTroveInfo} cdp
 * @param proxyAddress
 * @param targetRatio
 * @returns {Promise<string>}
 */

export const getRepayAmount = async (cdp, proxyAddress, targetRatio) => {
  // equation: amountInUsd = ((ratio * debt) - coll) / (ratio - 1)
  // amount = amountInUsd / estPrice
  const estPrice = await getBestExchangePrice(cdp.collateral, cdp.asset, cdp.debtAsset, proxyAddress);
  const numerator = new Dec(new Dec(targetRatio).mul(cdp.debtUsd)).sub(cdp.collateralUsd);
  const denominator = new Dec(targetRatio).minus(1);

  const usdAmount = numerator.div(denominator)
    .div(estPrice.price)
    .toString();

  return new Dec(usdAmount).div(cdp.debtAssetPrice).toString();
};

export const getCDPLPMaxBoost = (cdp, proxyAddress = '', buffer = 0.005) => {
  let maxBoost = 0;

  if (cdp.debtTooLow) maxBoost = getMaxBorrow(cdp);
  else {
    const targetRatio = (cdp.liqPercent / 100) + 0.001;
    const numerator = new Dec(new Dec(targetRatio).mul(cdp.debtUsd)).sub(cdp.collateralUsd);

    const denominator = new Dec(1).sub(targetRatio);
    maxBoost = new Dec(numerator).div(denominator)
      .mul(1 - buffer)
      .toString();
  }

  if (cdp.creatableDebt) maxBoost = Dec.min(cdp.creatableDebt, maxBoost).toString();

  if (Dec(maxBoost).lt(0)) maxBoost = 0;

  return maxBoost;
};

// TODO:
//  y = a(1-b)^x => a = original amount, b = decay factor, x = time
const getMultiplierForDebt = (debtUsd) => {
  const calculatedMultiplier = new Dec(1.02).mul(new Dec(1).minus(0.000000001).pow(debtUsd)).toString();
  // if calculatedMultiplier < 1 than the amount is very high, so we increase it just a little (.5% not the entire 2% as was default before)
  return new Dec(calculatedMultiplier).lt(1.01) ? 1.01 : calculatedMultiplier;
};

// TODO: 1.03 is empirical conclusion
// it's hard to calculate max repay because you can't get accurate price
// so we reverse calculate how much is debt equal in coll
export const getCDPLPMaxRepay = async (cdp, proxyAddress) => {
  const assets = ASSETS_FROM_MAKER_ASSET(cdp.asset);
  const multiplier = getMultiplierForDebt(cdp.debtUsd);

  const halfDebt = new Dec(cdp.debtInAsset).div(2).toString();
  if (assets.hasDAI) {
    const otherAsset = assets.isFirstDAI ? assets.secondAsset : assets.firstAsset;
    const { price } = await getBestExchangePrice(new Dec(cdp.debtInAsset).div(2).toString(), 'DAI', otherAsset, proxyAddress);
    const estimateSecondAmount = new Dec(price).mul(halfDebt).toString();
    const [lpEstimate] = await getLPLiquidityMintedEstimate(ethToWeth(assets.firstAsset), assets.isFirstDAI ? halfDebt : estimateSecondAmount, ethToWeth(assets.secondAsset), assets.isFirstDAI ? estimateSecondAmount : halfDebt);
    return new Dec(lpEstimate).mul(1.03).toString();
    // return new Dec(lpEstimate).mul(multiplier).toString();
  }
  const { price: priceFirst } = await getBestExchangePrice(halfDebt, 'DAI', ethToWeth(assets.firstAsset), proxyAddress);
  const { price: priceSecond } = await getBestExchangePrice(halfDebt, 'DAI', ethToWeth(assets.secondAsset), proxyAddress);
  const estimateFirstAmount = new Dec(halfDebt).mul(priceFirst).toString();
  const estimateSecondAmount = new Dec(halfDebt).mul(priceSecond).toString();

  const [lpEstimate] = await getLPLiquidityMintedEstimate(ethToWeth(assets.firstAsset), estimateFirstAmount, ethToWeth(assets.secondAsset), estimateSecondAmount);
  return new Dec(lpEstimate).mul(1.03).toString();
  // return new Dec(lpEstimate).mul(multiplier).toString();
};


export const getCDPGUNIMaxRepay = async (cdp, proxyAddress) => {
  const actualAmounts = await getActualMintAmounts(getIlkInfo(cdp.ilk).assetAddress, cdp.debtInAsset);

  const lpEstimate = getEthAmountForDecimals(actualAmounts.mintAmount, 18);
  return new Dec(lpEstimate).mul(1.02).toString();
};

export const basicValidation = (cdp) => (amount, max, afterValues, executing, loadingAfter) => {
  if (executing) return t('common.executing_action');
  if (loadingAfter) return t('common.loading');
  if (!amount) return t('errors.no_value');
  if (new Dec(amount || 0).lte(0)) return t('errors.under_zero');
  if (new Dec(amount || 0).gt(max || 0)) return t('errors.over_max');
};

export const basicAmountValidation = (cdp) => (amount, max, afterValues, executing, loadingAfter) => {
  if (executing) return t('common.executing_action');
  if (loadingAfter) return t('common.loading');
  if (!amount) return t('errors.no_value');
  if (new Dec(amount || 0).lte(0)) return t('errors.under_zero');
  if (new Dec(amount || 0).gt(max || 0)) return t('errors.over_max');
};

export const basicAddressValidation = (cdp) => (value, _, afterValues, executing, loadingAfter) => {
  if (executing) return t('common.executing_action');
  if (loadingAfter) return t('common.loading');
  if (!value) return t('errors.no_value');
  if (ENS_REGEX.test(value)) return;
  if (!isAddress(value)) return t('errors.invalid_address');
};

export const debtNonChangingValidation = (cdp) => (amount, max, afterValues, executing, loadingAfter) => {
  if (cdp?.debtTooLow) return t('errors.debt_below_min', { '%amount': cdp.minDebt, '%asset': cdp.debtAsset });
};

export const ratioLoweringValidation = (cdp) => (amount, max, afterValues, executing, loadingAfter) => {
  if (afterValues?.disabledBecauseTooLowForRepay) return t('errors.ratio_too_low_for_repay', { '%percent': ALLOWED_PERCENT_OVER_LIQ_RATIO_MAKER });
};

export const debtCreatingValidation = (cdp) => (amount, max, afterValues, executing, loadingAfter) => {
  if (new Dec(cdp.creatableDebt || 0).sub(amount || 0).lt(0)) return t('errors.dai_debt_ceiling_hit');
};
// TODO: disabledBecauseDustyAfter doesn't exist, check what is going on
export const debtChangingValidation = (cdp) => (amount, max, afterValues, executing, loadingAfter) => {
  if (afterValues?.debtTooLow) return t('errors.debt_below_min_after', { '%amount': cdp.minDebt, '%asset': cdp.debtAsset });
};

export const validate = (cdp, validators) => (amount, max, afterValues, executing, loadingAfter) => {
  for (let i = 0; i < validators.length; i++) {
    const error = validators[i](cdp)(amount, max, afterValues, executing, loadingAfter);
    if (error) return error;
  }
  return '';
};

export const getCurveCDPMaxRepay = async (cdp, proxyAddress) => {
  const { price } = await getBestExchangePrice(cdp.debtUsd, 'DAI', 'WETH', proxyAddress);

  const debtInETH = new Dec(cdp.debtUsd).mul(price).toString();
  // calc whole debt in eth, then check lpEstimate from ETH
  const lpEstimate = await getLpEstimate(assetAmountInWei(debtInETH, 'WETH'));

  // TODO: maybe 1.02 is a bit too much for bigger CDPs
  return new Dec(lpEstimate).mul(1.02).toString();
};
