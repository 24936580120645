import React from 'react';
import t from 'translate';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import { getAssetInfo } from '@defisaver/tokens';
import { formatCdpId } from '../../services/makerServices/makerMcdService';

const CdpSelectCustomValueContainer = (props) => {
  const { value } = props.getValue()[0];
  let option = props.options.find(data => data.value.id === value.id);
  option = option || { value: { id: t('common.loading_no_dots'), asset: '', type: '' } };
  const Icon = getAssetInfo(option.value.asset).icon;

  return (
    <components.ValueContainer {...props}>
      <div className="value-container">
        <div className="icon-wrapper"><Icon /></div>
        <span className="option-value">
          { formatCdpId(option.value) }
        </span>
      </div>

      { props.children }
    </components.ValueContainer>
  );
};

CdpSelectCustomValueContainer.defaultProps = {
  children: (<div />),
};

CdpSelectCustomValueContainer.propTypes = {
  getValue: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  children: PropTypes.node,
};

export default CdpSelectCustomValueContainer;
