import React, { Component } from 'react';
import Dec from 'decimal.js';
import { connect } from 'react-redux';
import t from 'translate';
import PropTypes from 'prop-types';
import { closeTroveAction } from '../../../../actions/liquityActions/liquityManageActions';
import { LIQUITY_LIQ_RESERVE_LUSD } from '../../../../constants/liquity';

import ModalBody from '../../ModalBody';
import ModalHeader from '../../ModalHeader';
import CloseTrovePicker from './LiquityCloseTrovePicker/LiquityCloseTrovePicker';

import './LiquityCloseTroveModal.scss';

const LiquityCloseTroveModal = (props) => {
  const {
    closingTrove, closingTroveErr, closeModal, trove, lusdBalance, closeTroveAction,
  } = props;

  const hasEnoughLUSD = new Dec(+trove.debtInAsset - LIQUITY_LIQ_RESERVE_LUSD).lt(lusdBalance);

  return (
    <div id="close-trove-modal-wrapper">
      <ModalHeader closeModal={closeModal} />

      <ModalBody>
        {/* { gettingCloseCdpData && (<Loader />) } */}

        <CloseTrovePicker
          closeModal={closeModal}
          hasEnoughLUSD={hasEnoughLUSD}
          error={closingTroveErr}
        />
      </ModalBody>

      <div className="modal-controls">
        <button
          type="submit"
          className="button green"
          form="close-mcd-cdp-form"
          disabled={closingTrove || !hasEnoughLUSD}
          onClick={() => closeTroveAction(closeModal)}
        >
          { closingTrove ? t('common.closing') : t('liquity.close_trove') }
        </button>
      </div>
    </div>
  );
};

LiquityCloseTroveModal.defaultProps = {};

LiquityCloseTroveModal.propTypes = {
  trove: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  closingTrove: PropTypes.bool.isRequired,
  closingTroveErr: PropTypes.string.isRequired,
  lusdBalance: PropTypes.string.isRequired,
  closeTroveAction: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  liquity, liquityManage, assets, general,
}) => ({
  trove: liquity[general.walletType.value],
  closingTrove: liquityManage.closingTrove,
  closingTroveErr: liquityManage.closingTroveErr,
  lusdBalance: assets.LUSD.balance,
});

const mapDispatchToProps = {
  closeTroveAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(LiquityCloseTroveModal);
