import React from 'react';
import t from 'translate';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  openCloseCdpModal, openTransferCdpModal, openLoginModal,
} from 'actions/modalActions';
import { parseInsuranceToLabel } from 'actions/nexusActions';
import { getIlkInfo } from '@defisaver/tokens';
import { ACCOUNT_TYPES } from '../../../constants/general';

import TooltipWrapper from '../../TooltipWrapper/TooltipWrapper';
import CdpSelect from '../../CdpSelect/CdpSelect';
import NexusShield from '../../Decorative/NexusShield/NexusShield';
import DataItem from '../../DataItem/DataItem';
import MakerRefreshButton from './MakerRefreshButton';

import './MakerCdpSubHeader.scss';
import InfoDropdown from '../../InfoDropdown/InfoDropdown';
import { formatNumber } from '../../../services/utils';
import { calculateDebtCeilingPercent } from '../../../services/makerServices/makerManageServices/makerManageService';

const MakerCdpSubHeader = ({
  hasCdp, account, connectingProvider, gettingCdp, loggingIn, requireCdp,
  history, openCloseCdpModal, openTransferCdpModal, gettingStableData,
  isInsured, showActions, showRatio, cdp, openLoginModal, accountType,
}) => {
  let content;
  if (loggingIn || connectingProvider) {
    content = t('common.logging_in');
  } else if (gettingCdp) {
    content = t('maker.loading_cdp');
  } else if (!account) {
    if (requireCdp) return null;
    content = t('errors.wallet_not_connected');
  } else {
    content = hasCdp ?
      (
        <>
          <div className="left-wrapper">
            <CdpSelect />
            <InfoDropdown
              label={<div className="Flex">{cdp.ilkLabel}<div className="mini-divider" />{formatNumber(cdp.stabilityFee)}%</div>}
              modifier="maker-header"
              title={`Current ${cdp.ilkLabel} Parameters:`}
              items={[
                {
                  label: t('common.stability_fee'), description: t('maker.stability_fee_explanation'), value: cdp.stabilityFee, symbol: '%', decimals: 2,
                },
                {
                  label: t('common.min_debt'), description: t('maker.min_debt_explanation'), value: cdp.minDebt, symbol: cdp.debtAsset, additionalClasses: 'move-symbol',
                },
                {
                  label: t('common.debt_ceiling'), description: t('maker.debt_ceiling_explanation'), value: `${formatNumber(cdp.globalDebtCeiling)} ${cdp.debtAsset} (${calculateDebtCeilingPercent(cdp.globalDebtCeiling, cdp.creatableDebt)}%)`, textValue: true, tooltip: formatNumber(cdp.creatableDebt),
                },
                {
                  label: t('common.liquidation_fee'), description: t('maker.liquidation_fee_explanation'), value: cdp.liquidationFee, symbol: '%', decimals: 2,
                },
              ]}
            />
          </div>
          {
            hasCdp && showRatio && (
              <div className="right-wrapper">
                <div className="right-item">
                  <DataItem
                    value={cdp.ratio}
                    label={t('misc.current_ratio')}
                    symbol="%"
                    type="arrow"
                  />
                </div>
              </div>
            )
          }

          {hasCdp && showActions && (
            <div className="right-wrapper">
              {
                isInsured && (
                  <>
                    <div className="right-item insurance" key="insurance-label">
                      <div className="label">{t('common.insured')}</div>
                      <TooltipWrapper title={parseInsuranceToLabel(isInsured)}>
                        <NexusShield />
                      </TooltipWrapper>
                    </div>
                    <div className="divider" key="insurance-icon" />
                  </>
                )
              }

              <div className="cdp-actions-wrapper">
                {
                  (cdp.debtDai !== '0' && cdp.collateral !== '0') &&
                  (!getIlkInfo(cdp.ilk)?.isLP) &&
                  (
                    <TooltipWrapper title={t('maker.close_cdp')}>
                      <button
                        onClick={() => (
                          accountType !== ACCOUNT_TYPES.viewOnly ?
                            openCloseCdpModal(history) :
                            openLoginModal())}
                        type="button"
                        className="button mini dark with-icon close"
                      >
                        <i /> {t('common.close')}
                      </button>
                    </TooltipWrapper>
                  )
                }

                {
                  cdp.type !== 'crop' && (
                    <TooltipWrapper title={t('maker.transfer_cdp')}>
                      <button
                        onClick={() => (
                          accountType !== ACCOUNT_TYPES.viewOnly ?
                            openTransferCdpModal(history) :
                            openLoginModal())}
                        type="button"
                        disabled={cdp?.type === 'b'}
                        className="button mini dark with-icon transfer"
                      >
                        <i /> {t('common.transfer')}
                      </button>
                    </TooltipWrapper>
                  )
                }

                <MakerRefreshButton />
              </div>
            </div>
          )}
        </>
      )
      : t('maker.no_cdp_found');
  }
  return (
    <div className="cdp-select-header">
      <div className="width-container dashboard-header-wrapper">
        {content}
      </div>
    </div>
  );
};

MakerCdpSubHeader.propTypes = {
  hasCdp: PropTypes.bool.isRequired,
  account: PropTypes.string.isRequired,
  loggingIn: PropTypes.bool.isRequired,
  gettingCdp: PropTypes.bool.isRequired,
  connectingProvider: PropTypes.bool.isRequired,
  requireCdp: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  openCloseCdpModal: PropTypes.func.isRequired,
  openTransferCdpModal: PropTypes.func.isRequired,
  gettingStableData: PropTypes.bool.isRequired,
  isInsured: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  showActions: PropTypes.bool.isRequired,
  showRatio: PropTypes.bool.isRequired,
  cdp: PropTypes.object,
  accountType: PropTypes.string.isRequired,
  openLoginModal: PropTypes.func.isRequired,
};

MakerCdpSubHeader.defaultProps = {
  isInsured: false,
  cdp: null,
};

const mapStateToProps = ({ general, maker, nexus }) => ({
  hasCdp: !!maker.cdp,
  cdp: maker.cdp,
  account: general.account,
  loggingIn: general.loggingIn,
  gettingCdp: maker.gettingCdp,
  connectingProvider: general.connectingProvider,
  gettingStableData: maker.gettingStableData,
  isInsured: nexus.makerInsured,
  accountType: general.accountType,
});

const mapDispatchToProps = {
  openCloseCdpModal,
  openTransferCdpModal,
  openLoginModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MakerCdpSubHeader));
