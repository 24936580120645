/* eslint-disable import/first */
/* eslint-disable import/newline-after-import */
/* eslint-disable import/order */

// DEV Import first for global setup
import './setup';

import { version } from '../package.json';
console.log(`DeFi Saver v${version}`);

import React from 'react';
import ReactDOM from 'react-dom';

import App from './components/App/App';
import store from './store';

ReactDOM.render(<App store={store} />, document.getElementById('root'));
