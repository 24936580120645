import React from 'react';
import { v4 as uuid } from 'uuid';

export default function ParaswapIcon(props) {
  const id = `paraswap_icon${uuid()}`;
  const fill = `url(#${id})`;

  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z" fill={fill} />
      <path d="M23 21L16.0627 9L9.125 21H23ZM11.1875 14.625L14.375 9H8L11.1875 14.625ZM20.1668 19.4116H11.9584L16.0627 12.3122L20.1668 19.4116Z" fill="white" />
      <defs>
        <linearGradient id={id} x1="15" y1="0" x2="15" y2="30" gradientUnits="userSpaceOnUse">
          <stop stopColor="#3968EC" />
          <stop offset="1" stopColor="#2A4592" />
        </linearGradient>
      </defs>
    </svg>
  );
}
