import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { SelectOption, SelectValueContainer } from '../../../../../../../Forms/SelectComponent/SelectComponent';

const SelectActionField = ({
  handleChange, i, type,
}) => (
  <Select
    className="select box"
    classNamePrefix="select"
    value={type.options.find(i => i.value === type.value) || null}
    components={{
      Option: SelectOption,
      ValueContainer: SelectValueContainer,
    }}
    onChange={(e) => handleChange({ target: e }, i)}
    options={type.options}
    onBlur={event => event.preventDefault()}
  />
);

SelectActionField.propTypes = {
  handleChange: PropTypes.func.isRequired,
  i: PropTypes.number.isRequired,
  type: PropTypes.object.isRequired,
};
export default SelectActionField;
