import React, { Component } from 'react';
import t from 'translate';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isCdpExternal } from 'services/utils';
import { ACCOUNT_TYPES } from '../../../../constants/general';
import { migrateCdpFromInstadappAction } from '../../../../actions/actionsInModals';
import { openLoginModal } from '../../../../actions/modalActions';

import ModalHeader from '../../ModalHeader';
import ModalBody from '../../ModalBody';
import StepStatus from '../../../Decorative/StepStatus/StepStatus';
import headerBg from '../../Compound/CompoundProxyMigrationModal/upgrade-modal-bg.svg';

import './MigrateCdpFromInstadappModal.scss';

const MigrateCdpFromInstadappModal = ({
  closeModal, migratingCdpFromInstadapp, migratingCdpFromInstadappError, migrateCdpFromInstadappAction,
  creatingDSProxy, creatingDSProxyError, proxyAddress, cdp, accountType, openLoginModal,
}) => {
  const migrating = creatingDSProxy || migratingCdpFromInstadapp;

  let step1Status = 'empty';
  if (proxyAddress) step1Status = 'done';
  if (creatingDSProxy) step1Status = 'loading';
  if (creatingDSProxyError) step1Status = 'error';

  let step2Status = 'empty';
  if (migratingCdpFromInstadapp) step2Status = 'loading';
  if (migratingCdpFromInstadappError) step2Status = 'error';
  if (!isCdpExternal(cdp)) step2Status = 'done';

  return (
    <div id="migrate-cdp-from-instadapp-modal-wrapper">
      <ModalHeader closeModal={closeModal} actionHeader actionText={t('common.close')} />

      <ModalBody>
        <div className="new-modal-top-wrapper" style={{ backgroundImage: `url(${headerBg})` }}>
          <h1>{t('maker.migrate_cdp')}</h1>
        </div>

        <div className="new-modal-content-wrapper">
          <p className="whitespaced">{t('maker.import_description')}</p>

          <div className="import-steps-wrapper">
            <StepStatus title={t('common.create_dsproxy')} status={step1Status} />
            <StepStatus title={t('maker.migrate_cdp')} status={step2Status} />
          </div>
        </div>
      </ModalBody>

      <div className="modal-controls">
        <button
          type="button"
          className="button green"
          disabled={migrating || creatingDSProxy}
          onClick={() => (accountType !== ACCOUNT_TYPES.viewOnly ?
            migrateCdpFromInstadappAction(cdp, closeModal) :
            openLoginModal())}
        >
          {migrating ? t('common.migrating') : t('common.migrate')}
        </button>
      </div>
    </div>
  );
};

MigrateCdpFromInstadappModal.defaultProps = {
  proxyAddress: '',
};

MigrateCdpFromInstadappModal.propTypes = {
  cdp: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  migratingCdpFromInstadapp: PropTypes.bool.isRequired,
  creatingDSProxy: PropTypes.bool.isRequired,
  migratingCdpFromInstadappError: PropTypes.string.isRequired,
  creatingDSProxyError: PropTypes.string.isRequired,
  migrateCdpFromInstadappAction: PropTypes.func.isRequired,
  proxyAddress: PropTypes.string,
  accountType: PropTypes.string.isRequired,
  openLoginModal: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  migrateCdpFromInstadappAction,
  openLoginModal,
};

const mapStateToProps = ({ maker, general }) => ({
  cdp: maker.cdp,
  migratingCdpFromInstadapp: maker.migratingCdpFromInstadapp,
  migratingCdpFromInstadappError: maker.migratingCdpFromInstadappError,

  proxyAddress: maker.proxyAddress,
  creatingDSProxy: maker.creatingDSProxy,
  creatingDSProxyError: maker.creatingDSProxyError,
  accountType: general.accountType,
});

export default connect(mapStateToProps, mapDispatchToProps)(MigrateCdpFromInstadappModal);
