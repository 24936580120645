import Dec from 'decimal.js';

import MakerSupplyAction from '../recipeActions/maker/MakerSupplyAction';
import MakerGenerateAction from '../recipeActions/maker/MakerGenerateAction';
import MakerPaybackAction from '../recipeActions/maker/MakerPaybackAction';
import MakerWithdrawAction from '../recipeActions/maker/MakerWithdrawAction';
import MakerOpenVaultAction from '../recipeActions/maker/MakerOpenVaultAction';
import MakerMergeAction from '../recipeActions/maker/MakerMergeAction';

import ReflexerOpenSafeAction from '../recipeActions/reflexer/ReflexerOpenSafeAction';
import ReflexerPaybackAction from '../recipeActions/reflexer/ReflexerPaybackAction';
import ReflexerSupplyAction from '../recipeActions/reflexer/ReflexerSupplyAction';
import ReflexerWithdrawAction from '../recipeActions/reflexer/ReflexerWithdrawAction';
import ReflexerGenerateAction from '../recipeActions/reflexer/ReflexerGenerateAction';

import CompoundClaimAction from '../recipeActions/compound/CompoundClaimAction';
import CompoundSupplyAction from '../recipeActions/compound/CompoundSupplyAction';
import CompoundBorrowAction from '../recipeActions/compound/CompoundBorrowAction';
import CompoundPaybackAction from '../recipeActions/compound/CompoundPaybackAction';
import CompoundWithdrawAction from '../recipeActions/compound/CompoundWithdrawAction';

import AaveV2SupplyAction from '../recipeActions/aaveV2/AaveV2SupplyAction';
import AaveV2BorrowAction from '../recipeActions/aaveV2/AaveV2BorrowAction';
import AaveV2PaybackAction from '../recipeActions/aaveV2/AaveV2PaybackAction';
import AaveV2WithdrawAction from '../recipeActions/aaveV2/AaveV2WithdrawAction';
import AaveV2FlashLoanAction from '../recipeActions/flashloan/AaveV2FlashLoanAction';
import AaveV2FlashLoanPaybackAction from '../recipeActions/flashloan/AaveV2FlashLoanPaybackAction';

import DyDxFlashLoanAction from '../recipeActions/flashloan/DyDxFlashLoanAction';
import DyDxFlashLoanPaybackAction from '../recipeActions/flashloan/DyDxFlashLoanPaybackAction';

import SellAction from '../recipeActions/basic/SellAction';
import SendTokenAction from '../recipeActions/basic/SendTokenAction';
import WrapEthAction from '../recipeActions/basic/WrapEthAction';
import UnwrapEthAction from '../recipeActions/basic/UnwrapEthAction';

import { getMaxPayback as getMaxPaybackCommon, getMaxWithdraw as getMaxWithdrawVault } from './vaultCommonService';
import { getFullCdpInfoFromId } from './makerServices/makerManageServices/makerManageService';
import { getMaxWithdraw as getMaxWithdrawAave } from './aaveServices/aaveManageService';
import { getMaxWithdraw } from './compoundServices/compoundManageService';
import { captureException } from '../sentry';
import { getSafeInfo } from './reflexerServices/reflexerService';
import MakerFlashLoanAction from '../recipeActions/flashloan/MakerFlashLoanAction';
import MakerFlashLoanPaybackAction from '../recipeActions/flashloan/MakerFlashLoanPaybackAction';

export const INTENT_TYPES = {
  BORROW: 'Borrow',
  BUY: 'Buy',
  CLAIM: 'Claim',
  FLASH_LOAN: 'FlashLoan',
  FLASH_LOAN_PAYBACK: 'FlashLoanPayback',
  NONE: 'None',
  PAYBACK: 'Payback',
  SELL: 'Sell',
  SEND: 'Send',
  SUPPLY: 'Supply',
  VAULT_CREATE: 'VaultCreate',
  VAULT_MERGE: 'VaultMerge',
  WITHDRAW: 'Withdraw',
  WRAP: 'Wrap',
  UNWRAP: 'Unwrap',
};

const numberRegex = /[^0-9.]/g;
const abcRegex = /[^a-zA-Z]/g;
const assetAmountConcatenatedRegex = /([0-9.]+)([a-zA-Z]{3,})/gmi;
// const localeFormattedNumberVar1 = /((?<!\.|\d)[1-9]\d{0,2})(\.(\d{2,3})(?!\d))+(,\d+)?/gm; // safari doesn't support lookbehind - (?<!\.|\d)
// const localeFormattedNumberVar2 = /((?<!,|\d)[1-9]\d{0,2})(,(\d{2,3})(?!\d))+(\.\d+)?/gm;
// const localeFormattedNumberVar3 = /(?<!\.|\d|,)(\d+,\d+)(?!,|\.|\d)/gm;
const localeFormattedNumberVar1 = /([1-9]\d{0,2})(\.(\d{2,3})(?!\d))+(,\d+)?/gm;
const localeFormattedNumberVar2 = /([1-9]\d{0,2})(,(\d{2,3})(?!\d))+(\.\d+)?/gm;
const localeFormattedNumberVar3 = /(\d+,\d+)(?!,|\.|\d)/gm;

const parseLocaleFormattedNumber = (_string) => {
  let string = _string;
  const matchesVar1 = [...string.matchAll(localeFormattedNumberVar1)];
  if (matchesVar1.length) {
    matchesVar1.forEach((match) => {
      string = string.replaceAll(match[0], `${match[0].replaceAll('.', '').replaceAll(',', '.')}`);
    });
  }
  const matchesVar2 = [...string.matchAll(localeFormattedNumberVar2)];
  if (matchesVar2.length) {
    matchesVar2.forEach((match) => {
      string = string.replaceAll(match[0], `${match[0].replaceAll(',', '')}`);
    });
  }
  const matchesVar3 = [...string.matchAll(localeFormattedNumberVar3)];
  if (matchesVar3.length) {
    matchesVar3.forEach((match) => {
      string = string.replaceAll(match[0], `${match[0].replaceAll(',', '.')}`);
    });
  }
  return string;
};

const parseConcatenatedAssetAmount = (_string) => {
  let string = _string;
  const matches = [...string.matchAll(assetAmountConcatenatedRegex)];
  if (matches.length) {
    matches.forEach((match) => {
      string = string.replaceAll(match[0], `${match[1]} ${match[2]}`);
    });
  }
  return string;
};

export const parseUtterance = (_string) => {
  let string = parseConcatenatedAssetAmount(_string);
  string = parseLocaleFormattedNumber(string);
  return string;
};

const mapActionsToIntents = {
  maker: {
    Supply: MakerSupplyAction,
    Borrow: MakerGenerateAction,
    Payback: MakerPaybackAction,
    Withdraw: MakerWithdrawAction,
    FlashLoan: MakerFlashLoanAction,
    FlashLoanPayback: MakerFlashLoanPaybackAction,
  },
  reflexer: {
    Supply: ReflexerSupplyAction,
    Borrow: ReflexerGenerateAction,
    Payback: ReflexerPaybackAction,
    Withdraw: ReflexerWithdrawAction,
  },
  compound: {
    Supply: CompoundSupplyAction,
    Borrow: CompoundBorrowAction,
    Payback: CompoundPaybackAction,
    Withdraw: CompoundWithdrawAction,
  },
  aavev2: {
    Supply: AaveV2SupplyAction,
    Borrow: AaveV2BorrowAction,
    Payback: AaveV2PaybackAction,
    Withdraw: AaveV2WithdrawAction,
    FlashLoan: AaveV2FlashLoanAction,
    FlashLoanPayback: AaveV2FlashLoanPaybackAction,
  },
  dydx: {
    FlashLoan: DyDxFlashLoanAction,
    FlashLoanPayback: DyDxFlashLoanPaybackAction,
  },
};

export const quantityEntities = {
  half: 2,
  quarter: 4,
  third: 3,
  whole: 1,
};

const getAssetAmountData = (entities, index = 0) => ({
  amount: entities.assetAmount[index].amount[0],
  asset: entities.assetAmount[index].asset[0].toUpperCase(),
});

const filterAsset = (entities, skipAsset) => entities.asset.filter((i) => i[0] !== skipAsset)[0][0];

const getAddress = (entities, index = 0) => entities.address[index];

const getProtocol = (entities, index = 0) => entities.protocol[index][0].toLowerCase();

const getCdpId = (entities, index = 0) => entities.cdpId[index].replace(numberRegex, '');

const getMakerIlk = (entities, index = 0) => entities.makerIlk[index][0];

const getQuantity = (entities, index = 0) => entities.quantity[index][0];

const getAsset = (entities, index = 0) => entities.asset[index][0].toUpperCase();

const getSource = (entities, index = 0) => entities.source[index][0].toLowerCase();

const handleETH = (asset) => (asset === 'ETH' ? 'WETH' : asset);

/**
 *
 * @param {{}} prediction
 * @param {[{}]} _previousPredictions
 * @param {[{}]} _previousActions
 * @param {{}} state
 * @return {boolean|SendTokenAction|SellAction|MakerMergeAction|{}|MakerOpenVaultAction|CompoundClaimAction}
 */
export const getActionFromPrediction = async (prediction, _previousPredictions, _previousActions, state) => {
  try {
    const intent = prediction.topIntent;
    const entities = prediction.entities;
    const intentScore = prediction.intents[prediction.topIntent].score;
    const previousActions = [..._previousActions].reverse();
    const previousAction = [..._previousActions][previousActions.length - 1];
    const previousPredictions = [..._previousPredictions].reverse();
    const previousPrediction = [..._previousPredictions][previousPredictions.length - 1];

    console.log(prediction);

    if (intent === INTENT_TYPES.NONE || intentScore < 0.3) return false;

    if (intent === INTENT_TYPES.WRAP) {
      let amount = '';

      if (entities.assetAmount) amount = getAssetAmountData(entities).amount;

      return new WrapEthAction(amount);
    }

    if (intent === INTENT_TYPES.UNWRAP) {
      let amount = '';
      let source = 'wallet';

      if (entities.assetAmount) amount = getAssetAmountData(entities).amount;
      if (entities.source) source = getSource(entities);
      if (!amount) {
        previousActions.forEach((item) => {
          if (!amount && item?.output?._asset === 'WETH') amount = item?.output.value;
        });
      }

      return new UnwrapEthAction(amount, source);
    }

    if (intent === INTENT_TYPES.SELL) {
      let amount = '';
      let sellAsset = '';
      let buyAsset = '';
      let fromSource = 'wallet';
      let toSource = 'recipe';
      const slippage = '1';

      if (entities.assetAmount) {
        sellAsset = getAssetAmountData(entities).asset;
        amount = getAssetAmountData(entities).amount;
      } else if (previousPrediction?.entities.assetAmount) {
        sellAsset = getAssetAmountData(previousPrediction.entities).asset;
      }
      if (!amount) {
        if (!sellAsset && entities.asset && entities.asset.length === 2) sellAsset = getAsset(entities, 0);
        if (!sellAsset && previousAction?.output?._asset && entities.asset.length === 1) {
          sellAsset = previousAction?.output?._asset;
          buyAsset = entities.asset[0][0];
        }
        if (previousAction?.output?._asset === sellAsset) {
          amount = `$${previousAction.id}`;
          if (previousPrediction.entities.source) fromSource = getSource(previousPrediction.entities);
        } else if (previousPrediction.entities.assetAmount) amount = getAssetAmountData(previousPrediction.entities).amount;
      }
      if (!buyAsset && entities.asset) {
        buyAsset = filterAsset(entities, sellAsset);
      } else if (!buyAsset && previousPrediction?.entities.asset) {
        buyAsset = filterAsset(previousPrediction.entities, sellAsset);
      }

      if (entities.source) fromSource = getSource(entities);
      if (entities.source?.length > 1) toSource = getSource(entities, 1);
      return new SellAction(handleETH(sellAsset), fromSource, amount, handleETH(buyAsset), toSource, slippage);
    }

    if (intent === INTENT_TYPES.SEND) {
      let amount = '';
      let sendAsset = '';
      let to = state?.general?.account || '';

      if (entities.assetAmount) {
        sendAsset = getAssetAmountData(entities).asset;
        amount = getAssetAmountData(entities).amount;
      }
      if (entities.address) to = getAddress(entities);

      return new SendTokenAction(handleETH(sendAsset), amount, to);
    }

    if (intent === INTENT_TYPES.CLAIM) {
      const from = state.general?.smartWallets?.[0] || '';
      const to = (entities.source && getSource(entities)) || 'wallet';

      return new CompoundClaimAction(from, to);
    }

    if (
      intent === INTENT_TYPES.SUPPLY
      || intent === INTENT_TYPES.BORROW
      || intent === INTENT_TYPES.PAYBACK
      || intent === INTENT_TYPES.WITHDRAW
    ) {
      let protocol = '';
      let args = [];
      const market = 'v2default';
      let cdpId = '';
      let amount = '';
      let asset = '';
      let isReferencingCdpId = false;
      let source = 'wallet';
      const emptySafe = {
        collateral: 0,
        collateralUsd: 0,
        debtInAsset: 0,
        debtUsd: 0,
        liqRatio: 1.5,
        assetPrice: 600,
        debtAssetPrice: 1,
        type: 'standard',
        debtAsset: 'RAI',
      };

      if (entities.source) source = getSource(entities);

      if (entities.assetAmount) {
        amount = getAssetAmountData(entities).amount;
        asset = getAssetAmountData(entities).asset;
      } else if (entities.asset && !asset) {
        asset = getAsset(entities);
        if (previousAction?.output?._asset === asset) amount = `$${previousAction.id}`;
      } else if (previousPrediction) {
        previousPredictions.forEach((prediction) => {
          if (prediction.entities.assetAmount) {
            if (!entities.quantity && !amount) amount = getAssetAmountData(prediction.entities).amount;
            if (!asset) asset = getAssetAmountData(prediction.entities).asset;
          }
        });
      }

      if (!entities.protocol && previousPrediction) {
        previousPredictions.forEach((prediction) => {
          if (protocol) return;
          if (prediction.entities.protocol) {
            protocol = getProtocol(prediction.entities);
          } else if (prediction.entities.cdpId) {
            cdpId = getCdpId(prediction.entities);
            protocol = 'maker';
            // todo check reflexer
          } else if (prediction.topIntent === INTENT_TYPES.VAULT_CREATE) {
            protocol = 'maker';
            // todo check reflexer
          }
        });
      } else if (entities.cdpId) {
        protocol = 'maker';
      } else {
        protocol = getProtocol(entities);
      }

      if (protocol === 'maker' || protocol === 'reflexer' || entities.cdpId) {
        if (entities.cdpId) {
          cdpId = getCdpId(entities);
        } else if (previousPrediction) {
          previousPredictions.forEach((prediction) => {
            if (prediction.entities.cdpId) {
              cdpId = getCdpId(prediction.entities);
            }
          });
        }
        if (!cdpId) {
          previousActions.forEach((action) => {
            if (action.constructor === MakerOpenVaultAction) {
              cdpId = `$${action.id}`;
              isReferencingCdpId = true;
              protocol = 'maker';
            }
            if (!cdpId && action.constructor === ReflexerOpenSafeAction) {
              cdpId = `$${action.id}`;
              isReferencingCdpId = true;
              protocol = 'reflexer';
            }
          });
        }
        if (!protocol) protocol = 'maker';
      } else if (protocol === 'aavev1' && !mapActionsToIntents[protocol][intent]) {
        protocol = 'aavev2';
      }

      if (entities.quantity || !amount) {
        const quantity = entities.quantity ? quantityEntities[getQuantity(entities, 0)] : 1;
        console.log('quantity', quantity);
        if (quantity) {
          console.log('quantity in', quantity, intent);
          if (intent === INTENT_TYPES.PAYBACK) {
            if (protocol === 'maker') {
              if (!isReferencingCdpId) {
                const cdp = cdpId && await getFullCdpInfoFromId(cdpId);
                let paybackAmount = cdpId && cdp && cdp.ilkLabel ? getMaxPaybackCommon(cdp) : '0';

                previousActions.forEach((action) => {
                  if (action.constructor === MakerGenerateAction) {
                    paybackAmount = new Dec(paybackAmount).plus(action.inputs[1].amount).toString();
                  }
                });

                amount = new Dec(paybackAmount).dividedBy(quantity).toString();
              } else {
                previousActions.forEach((action) => {
                  if (action.constructor === MakerGenerateAction) {
                    amount = new Dec(action.inputs[1].amount).dividedBy(quantity).toString();
                  }
                });
              }
              console.log('maker payback', amount, quantity);
            }
            if (protocol === 'reflexer') {
              if (!isReferencingCdpId) {
                const safe = state?.reflexer?.safes?.[cdpId] && cdpId && await getSafeInfo({ ...emptySafe, id: cdpId });
                const assetBalance = state.assets[state?.rexlexer?.safes?.[cdpId]?.asset]?.balance || '0'; // todo search previous actions if not existing safe
                let paybackAmount = cdpId && safe && safe.ilkLabel ? getMaxPaybackCommon(safe, assetBalance) : '0';

                previousActions.forEach((action) => {
                  if (action.constructor === ReflexerGenerateAction) {
                    paybackAmount = new Dec(paybackAmount).plus(action.inputs[1].amount).toString();
                  }
                });

                amount = new Dec(paybackAmount).dividedBy(quantity).toString();
              } else {
                previousActions.forEach((action) => {
                  if (action.constructor === ReflexerGenerateAction) {
                    amount = new Dec(action.inputs[1].amount).dividedBy(quantity).toString();
                  }
                });
              }
              console.log('reflexer payback', amount, quantity);
            }
            if (protocol === 'aavev1') {
              const borrowedFromState = state.aaveManage?.account?.v1?.usedAssets?.[asset]?.borrowed;
              amount = borrowedFromState ? new Dec(borrowedFromState).dividedBy(quantity) : '';
              console.log('aave payback', amount, quantity);
            }
            if (protocol === 'aavev2') {
              // TODO: interest mode
              const borrowedFromState = state.aaveManage?.account?.[market]?.usedAssets?.[asset]?.borrowed;
              let paybackAmount = borrowedFromState || '0';

              console.log(paybackAmount);

              previousActions.forEach((action) => {
                if (action.constructor === AaveV2BorrowAction) {
                  paybackAmount = new Dec(paybackAmount).plus(action.inputs[2].amount).toString();
                }
              });

              console.log(paybackAmount);

              amount = new Dec(paybackAmount).dividedBy(quantity);
              console.log('aavev2 payback', amount, quantity);
            }
            if (protocol === 'compound') {
              console.log(asset, state.compoundManage?.usedAssets);
              const borrowedFromState = state.compoundManage?.usedAssets?.[asset]?.borrowed;
              let paybackAmount = borrowedFromState || '0';

              previousActions.forEach((action) => {
                if (action.constructor === CompoundBorrowAction) {
                  console.log(action.inputs[1].amount);
                  paybackAmount = new Dec(paybackAmount).plus(action.inputs[1].amount).toString();
                }
              });

              amount = new Dec(paybackAmount).dividedBy(quantity);
              console.log('compound payback', amount, quantity);
            }
          }
          if (intent === INTENT_TYPES.WITHDRAW) {
            if (protocol === 'maker') {
              const cdp = cdpId && await getFullCdpInfoFromId(cdpId);
              amount = cdpId && cdp ? new Dec(getMaxWithdrawVault(cdp)).dividedBy(quantity).toString() : '';
              console.log('maker withdraw', cdp, amount, quantity);
            }
            if (protocol === 'reflexer') {
              const safe = state?.reflexer?.safes?.[cdpId] && cdpId && await getSafeInfo({ ...emptySafe, id: cdpId });
              amount = cdpId && safe ? new Dec(getMaxWithdrawVault(safe)).dividedBy(quantity).toString() : '';
              console.log('reflexer withdraw', safe, amount, quantity);
            }
            if (protocol === 'aavev1') {
              const data = state.aaveManage?.account?.v1;
              const assetData = data && data.assetsData[asset];
              const assetPrice = state.assets?.[asset]?.aavePrice;
              const usedAssetData = data && data.usedAssets?.[asset];
              const borrowedUsd = data && data.borrowedUsd;
              const liquidationLimitUsd = data && data.liquidationLimitUsd;
              const ratio = 1.01;

              const max = data && assetPrice && Object.values(usedAssetData).length &&
                getMaxWithdrawAave(usedAssetData, assetPrice, assetData, borrowedUsd, liquidationLimitUsd, ratio);

              amount = max ? new Dec(max).dividedBy(quantity).toString() : '';
              console.log('aave withdraw', amount, quantity);
            }
            if (protocol === 'aavev2') {
              const data = state.aaveManage?.account?.[market];
              const assetData = data && data.assetsData[asset];
              const assetPrice = state.assets?.[asset]?.aavePrice;
              const usedAssetData = data && data.usedAssets?.[asset];
              const borrowedUsd = data && data.borrowedUsd;
              const liquidationLimitUsd = data && data.liquidationLimitUsd;
              const ratio = 1.01;

              const max = data && assetPrice && Object.values(usedAssetData).length &&
                getMaxWithdrawAave(usedAssetData, assetPrice, assetData, borrowedUsd, liquidationLimitUsd, ratio);

              amount = max ? new Dec(max).dividedBy(quantity).toString() : '';
              console.log('aavev2 withdraw', amount, quantity);
            }
            if (protocol === 'compound') {
              const data = state.compoundManage;
              const assetData = data && data.assetsData[asset];
              const assetPrice = state.assets?.[asset]?.compoundPrice;
              const usedAssetData = data && data.usedAssets?.[asset];
              const borrowedUsd = data && data.borrowedUsd;
              const borrowLimitUsd = data && data.borrowLimitUsd;
              const ratio = 1.01;

              const max = usedAssetData?.isSupplied && assetPrice &&
                getMaxWithdraw(usedAssetData, assetPrice, assetData, borrowedUsd, borrowLimitUsd, ratio);

              amount = max ? new Dec(max).dividedBy(quantity).toString() : '';
              console.log('compound withdraw', amount, quantity);
            }
          }
        }
      }

      if (protocol === 'maker' || protocol === 'reflexer') {
        if (intent === INTENT_TYPES.PAYBACK || intent === INTENT_TYPES.SUPPLY) {
          args = [cdpId, source, amount];
        } else {
          args = [cdpId, amount, source];
        }
      } else if (protocol === 'aavev2') {
        if (intent === INTENT_TYPES.PAYBACK || intent === INTENT_TYPES.SUPPLY) {
          args = [market, handleETH(asset), source, amount];
        } else {
          args = [market, handleETH(asset), amount, source];
        }
      } else if (intent === INTENT_TYPES.PAYBACK || intent === INTENT_TYPES.SUPPLY) {
        args = [handleETH(asset), source, amount];
      } else {
        args = [handleETH(asset), amount, source];
      }

      console.log(args);

      const Action = mapActionsToIntents[protocol][intent];
      return new Action(...args);
    }

    if (intent === INTENT_TYPES.FLASH_LOAN || intent === INTENT_TYPES.FLASH_LOAN_PAYBACK) {
      let protocol = entities.protocol ? getProtocol(entities) : 'dydx';
      let amount = 0;
      let asset = '';
      let source = 'wallet';

      if (entities.source) source = getSource(entities);

      if (entities.assetAmount) {
        amount = getAssetAmountData(entities).amount;
        asset = getAssetAmountData(entities).asset;
      }

      if (intent === INTENT_TYPES.FLASH_LOAN_PAYBACK) {
        if (!amount && previousPredictions) {
          previousPredictions.forEach((prediction) => {
            if (prediction.entities.assetAmount && prediction.topIntent === 'FlashLoan') {
              amount = getAssetAmountData(prediction.entities).amount;
            }
          });
          previousPredictions.forEach((prediction) => {
            if (!amount && prediction.entities.assetAmount) {
              amount = getAssetAmountData(prediction.entities).amount;
            }
          });
        }

        if (!asset && previousPredictions) {
          previousPredictions.forEach((prediction) => {
            if (prediction.entities.assetAmount && prediction.topIntent === 'FlashLoan') {
              asset = getAssetAmountData(prediction.entities).asset;
            }
          });
          previousPredictions.forEach((prediction) => {
            if (!asset && prediction.entities.assetAmount) {
              asset = getAssetAmountData(prediction.entities).asset;
            }
          });
        }
      }

      let args = [];

      if (intent === INTENT_TYPES.FLASH_LOAN) args = [handleETH(asset), amount, source];
      else if (intent === INTENT_TYPES.FLASH_LOAN_PAYBACK) args = [handleETH(asset), amount];

      if (protocol === 'aavev1' && !mapActionsToIntents[protocol][intent]) protocol = 'aavev2';

      const Action = mapActionsToIntents[protocol][intent];
      return new Action(...args);
    }

    if (intent === INTENT_TYPES.VAULT_CREATE) {
      let ilk = '';
      let protocol = 'maker';
      let Action;
      if (entities.makerIlk) ilk = getMakerIlk(entities);
      if (entities.protocol) protocol = getProtocol(entities);
      else if (!ilk && entities.asset) ilk = `${entities.asset[0][0]}-A`;

      if (protocol === 'maker') Action = MakerOpenVaultAction;
      if (protocol === 'reflexer') Action = ReflexerOpenSafeAction;

      return new Action(ilk);
    }

    if (intent === INTENT_TYPES.VAULT_MERGE) {
      let cdpIdOne = '';
      let cdpIdTwo = '';
      if (entities.cdpId) {
        cdpIdOne = getCdpId(entities);
        cdpIdTwo = getCdpId(entities, 1);
      }

      return new MakerMergeAction(cdpIdOne, cdpIdTwo);
    }

    return false;
  } catch (error) {
    console.error(error);
    captureException(error);
    return false;
  }
};
