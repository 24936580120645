import {
  proxyRegistryInterfaceContract,
} from '../contractRegistryService';
import { addToLsState, getLsExistingItemAndState } from '../localStorageService';
import callTx from '../txService';
import * as makerMcdService from './makerMcdService';
import * as makerMcdAutomationService from './makerSaverMcdService';

/**
 * Checks cdp type and calls the right getCdpInfo method for the type
 *
 * @param cdp {Object}
 * @return {*}
 */
export const getCdpInfo = cdp => makerMcdService.getCdpInfo(cdp);

/**
 * Transfers the cdp from the users address to the proxyAddress
 *
 * @param cdp {Object}
 * @param rest {...Object}
 * @return {Promise<any>}
 */
export const migrateCdpFromAddressToProxy = (cdp, ...rest) => makerMcdService.migrateCdpFromAddressToProxy(...rest);

/**
 * Checks cdp type and calls the right getSaverSubscriptionInfo method for the type
 *
 * @param cdp {Object}
 * @return {Promise<*>}>}
 */
export const getSaverSubscriptionInfo = cdp => makerMcdAutomationService.getSaverSubscriptionInfo(cdp.id);

/* /////////////// Not based on if scd or mcd /////////////// */

/**
 * Checks if the connected user has a cdp for their account or proxyAddress
 *
 * @param address {String}
 * @param proxyAddress {String}
 * @return {Promise<Array>}
 */
export const getCdps = async (address, proxyAddress) => {
  const mcdCdps = await makerMcdService.getCdps(address, proxyAddress);
  const instaDappMcdCdps = await makerMcdService.getInstadappCdps(address);
  const BProtocolCdps = await makerMcdService.getBProtocolCdps(address, proxyAddress);

  const idSort = (a, b) => a.id - b.id;

  return [
    ...mcdCdps.sort(idSort),
    ...instaDappMcdCdps.sort(idSort),
    ...BProtocolCdps.sort(idSort),
  ];
};

/**
 * Replaces the value of the cdp in ls from cdpId to cdp: { id, type }
 *
 * @param _existingItem {Object}
 * @param cdp {Object}
 */
const changeCdpInLs = (_existingItem, cdp) => {
  const existingItem = { ..._existingItem, cdp: { id: cdp.id, type: cdp.type } };
  delete existingItem.cdpId;
  addToLsState(existingItem, true);
};

/**
 * Finds cdp in fetched cdps based on LS saved cdpId
 *
 * @param address {String}
 * @param cdps {Array}
 * @return {*}
 */
const getLsCdp = (address, cdps) => {
  const { existingItem } = getLsExistingItemAndState(address);

  if (!existingItem) return null;
  if (!existingItem.cdp) return null;

  let cdp = null;

  if (existingItem.cdp) {
    cdp = cdps.find(_cdp => _cdp.id === existingItem.cdp.id && _cdp.type === existingItem.cdp.type);
  }

  return cdp || null;
};

/**
 * Finds cdp in fetched cdps based on route ID
 *
 * @param cdps {Array}
 * @return {*}
 */
const getRouteCdp = (cdps) => {
  let cdp = null;

  const match = document.location.pathname.match(/\/manage\/(\d+)[/?]*/);

  if (match) {
    const routeId = parseInt(match[1], 10);
    cdp = cdps.find(_cdp => _cdp.id === routeId);
  }

  return cdp || null;
};

/**
 * Contains the logic that determines which cdp is selected from the loaded cdps
 *
 * @param address {String}
 * @param cdps {Array}
 * @param switchToLast {Boolean}
 * @return {Promise<null>}
 */
export const getCdpToSelectWithInfo = async (address, cdps, switchToLast = false) => {
  const routeCdp = getRouteCdp(cdps);
  const lsCdp = getLsCdp(address, cdps);
  const infoCdp = switchToLast ? cdps[cdps.length - 1] : (routeCdp || lsCdp || cdps[0]);

  return infoCdp ? getCdpInfo(infoCdp) : null;
};

/**
 * Creates a DSProxy contract for a user that does not have one
 *
 * @param accountType {String}
 * @param path {String}
 * @param sendTxFunc {Function}
 * @param account {String}
 * @return {Promise<{String}>}
 */
export const createDSProxy = async (accountType, path, sendTxFunc, account) => {
  const contract = await proxyRegistryInterfaceContract();

  return callTx(accountType, path, sendTxFunc, contract, 'build', [account], { from: account });
};
