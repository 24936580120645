import t from 'translate';
import { captureException } from 'sentry';
import {
  ADD_PROXY_ADDRESS,
  CDP_LIQUIDATIONS_SUCCESS,
  CHANGE_PROXY_OWNER_FAILURE,
  CHANGE_PROXY_OWNER_REQUEST,
  CHANGE_PROXY_OWNER_SUCCESS,
  CREATE_DS_PROXY_FAILURE,
  CREATE_DS_PROXY_REQUEST,
  CREATE_DS_PROXY_SUCCESS,
  GET_CDP_FAILURE,
  GET_CDP_PROFITABILITY_FAILURE,
  GET_CDP_PROFITABILITY_REQUEST,
  GET_CDP_PROFITABILITY_SUCCESS,
  GET_CDP_REQUEST,
  GET_CDP_SUCCESS,
  GET_CDPS_FAILURE,
  GET_CDPS_REQUEST,
  GET_CDPS_SUCCESS,
  GET_PROXY_ADDRESS_FAILURE,
  GET_PROXY_ADDRESS_REQUEST,
  GET_PROXY_ADDRESS_SUCCESS,
  GET_SAVER_SUBSCRIBE_GRAPH_DATA_FAILURE,
  GET_SAVER_SUBSCRIBE_GRAPH_DATA_REQUEST,
  GET_SAVER_SUBSCRIBE_GRAPH_DATA_SUCCESS,
  MIGRATE_CDP_FROM_ADDRESS_TO_PROXY_FAILURE,
  MIGRATE_CDP_FROM_ADDRESS_TO_PROXY_REQUEST,
  MIGRATE_CDP_FROM_ADDRESS_TO_PROXY_RESET,
  MIGRATE_CDP_FROM_ADDRESS_TO_PROXY_SUCCESS,
} from 'actionTypes/makerActionTypes/makerActionTypes';
import {
  createDSProxy,
  getCdps,
  getCdpToSelectWithInfo,
  getSaverSubscriptionInfo,
  migrateCdpFromAddressToProxy,
} from 'services/makerServices/makerService';
import { DFSProxyRegistryContract, proxyRegistryInterfaceContract } from 'services/contractRegistryService';
import {
  callWithRetry, compareAddresses, isEmptyBytes, requireAddress,
} from 'services/utils';
import { addToLsState, getLsExistingItemAndState } from 'services/localStorageService';
import { trackEvent } from 'services/analyticsService';
import { ACCOUNT_TYPES } from '../../constants/general';

import { callMultipleTxAction, sendTx } from '../txNotificationActions';
import { getCDPLiqudationsApiCall } from '../../services/apiService';
import { authHelpcrunch } from '../utilActions';
import { pickSmartWalletViaModal } from '../modalActions';
import { changeProxyOwner } from '../../services/dsproxyService';
import { momoize } from '../../services/memoization';
import { isLayer2Network } from '../../services/ethService';
import { getSubscribedStrategiesAction } from '../startegiesActions/strategiesActions';

export const pickProxy = momoize((account, accountType, network) => async (dispatch, getState) => {
  const isMainnet = !isLayer2Network(network || getState().general.network);

  const contractCall = isMainnet ? DFSProxyRegistryContract().methods.getAllProxies : proxyRegistryInterfaceContract().methods.proxies;

  const data = await contractCall(account).call();
  const _mainWallet = isMainnet ? data[0] : data;
  const otherWallets = isMainnet ? data[1] : [];
  const mainWallet = isEmptyBytes(_mainWallet) ? '' : _mainWallet;
  const smartWallets = [mainWallet, ...otherWallets];
  const { existingItem } = getLsExistingItemAndState(account);

  let proxyAddress = '';

  if (existingItem && existingItem.proxy && smartWallets.find(p => compareAddresses(p, existingItem.proxy))) {
    proxyAddress = existingItem.proxy;
  } else {
    proxyAddress = smartWallets.length > 1 ? await dispatch(pickSmartWalletViaModal(smartWallets)) : smartWallets[0];
  }

  dispatch(authHelpcrunch({ proxyAddress }));

  if (accountType !== ACCOUNT_TYPES.fork) {
    addToLsState({
      account,
      proxy: proxyAddress,
    });
  }

  return { smartWallets, proxyAddress };
}, { maxAge: 2000, promise: true });

export const getProxyAddress = (userAccount = '', userAccountType = '') => async (dispatch, getState) => {
  const { account, accountType } = getState().general;
  dispatch({ type: GET_PROXY_ADDRESS_REQUEST });

  try {
    const { smartWallets, proxyAddress } = await dispatch(pickProxy(userAccount || account, userAccountType || accountType));

    dispatch({ type: GET_PROXY_ADDRESS_SUCCESS, payload: smartWallets });

    dispatch({ type: ADD_PROXY_ADDRESS, payload: proxyAddress });

    return proxyAddress;
  } catch (err) {
    dispatch({ type: GET_PROXY_ADDRESS_FAILURE, payload: err.message });
    captureException(err);
  }
};

export const setProxyViaModal = () => async (dispatch, getState) => {
  const { smartWallets, accountType } = getState().general;
  dispatch({ type: GET_PROXY_ADDRESS_REQUEST });

  try {
    const proxyAddress = smartWallets.length > 1
      ? await dispatch(pickSmartWalletViaModal(smartWallets))
      : smartWallets[0];

    dispatch({ type: GET_PROXY_ADDRESS_SUCCESS, payload: smartWallets });

    dispatch({ type: ADD_PROXY_ADDRESS, payload: proxyAddress });

    if (accountType !== ACCOUNT_TYPES.fork) {
      addToLsState({
        account: getState().general.account,
        proxy: proxyAddress,
      });
    }

    return proxyAddress;
  } catch (err) {
    dispatch({ type: GET_PROXY_ADDRESS_FAILURE, payload: err.message });
    captureException(err);
  }
};

/**
 * Fetches data for the CdpStatusGraph on the MakerSaverSubscribedStatus section
 *
 * @return {Function}
 */
export const getSaverSubscribedGraphData = () => async (dispatch, getState) => {
  dispatch({ type: GET_SAVER_SUBSCRIBE_GRAPH_DATA_REQUEST });

  try {
    const { cdp } = getState().maker;
    if (cdp.type === 'b') return;
    const data = await getSaverSubscriptionInfo(cdp);

    const payload = {
      ...data,
      current: cdp.ratio,
    };

    dispatch({ type: GET_SAVER_SUBSCRIBE_GRAPH_DATA_SUCCESS, payload });

    return payload;
  } catch (err) {
    dispatch({ type: GET_SAVER_SUBSCRIBE_GRAPH_DATA_FAILURE, payload: err.message });
    captureException(err);
  }
};

export const getCDPLiqudations = cdpId => async (dispatch) => {
  try {
    const payload = await getCDPLiqudationsApiCall(cdpId);
    dispatch({ type: CDP_LIQUIDATIONS_SUCCESS, payload });
  } catch (err) {
    captureException(err);
  }
};

export const getCdpsAction = () => async (dispatch, getState) => {
  dispatch({ type: GET_CDPS_REQUEST });
  try {
    const { account } = getState().general;
    if (!getState().maker.proxyAddress) await dispatch(getProxyAddress());

    const cdps = await getCdps(account, getState().maker.proxyAddress);
    dispatch({ type: GET_CDPS_SUCCESS, payload: cdps });
    return cdps;
  } catch (err) {
    dispatch({ type: GET_CDPS_FAILURE, payload: err.message });
    captureException(err);
  }
};

/**
 * Handles when a cdp if fetched from the chain
 *
 * @return {Function}
 */
export const getCdp = () => async (dispatch, getState) => {
  if (getState().maker.gettingCdp) return;

  dispatch({ type: GET_CDP_REQUEST });

  try {
    const { account } = getState().general;
    const cdps = await dispatch(getCdpsAction());
    if (cdps === undefined) {
      throw new Error('Error fetching CDPs');
    }
    const cdp = await getCdpToSelectWithInfo(account, cdps);

    dispatch({ type: GET_CDP_SUCCESS, payload: cdp });
    dispatch({ type: GET_CDPS_SUCCESS, payload: cdps });
    if (cdp) {
      dispatch(getSaverSubscribedGraphData());
      dispatch(getCDPLiqudations(cdp.id));
      dispatch(getSubscribedStrategiesAction());
    }
  } catch (err) {
    dispatch({ type: GET_CDP_FAILURE, payload: err.message });
    captureException(err);
  }
};

/**
 * Resets the state on the migrate cdp page
 *
 * @return {Function}
 */
export const resetMigrateCdpFromAddressToProxyAction = () => (dispatch) => { dispatch({ type: MIGRATE_CDP_FROM_ADDRESS_TO_PROXY_RESET }); };

/**
 * Calls DS contract method to create a DSProxy address if the user does not have one
 *
 * @return {Function}
 */
export const createDSProxyAction = () => async (dispatch, getState) => {
  trackEvent('general', 'createWallet');
  const notificationFunc = (promise, waitForSign) => sendTx(promise, t('account.create_ds_proxy'), '', dispatch, getState, {}, waitForSign); // eslint-disable-line

  const { account, accountType, path } = getState().general;

  dispatch({ type: CREATE_DS_PROXY_REQUEST });

  try {
    await createDSProxy(accountType, path, notificationFunc, account);
    const payload = await callWithRetry(() => dispatch(getProxyAddress()));
    requireAddress(payload);

    dispatch({ type: CREATE_DS_PROXY_SUCCESS });
    dispatch({ type: ADD_PROXY_ADDRESS, payload });
    trackEvent('general', 'createWalletSuccess');
    return payload;
  } catch (err) {
    trackEvent('general', 'createWalletError', err.message);
    dispatch({ type: CREATE_DS_PROXY_FAILURE, payload: err.message });
    captureException(err);
  }
};

/**
 * Checks if the account has a proxyAddress if it doesn't then it is first created then
 * the cdp is transferred to that proxyAddress. If it does exist it is immediately transferred to the
 * existing proxy address
 *
 * @param cdp {Object}
 * @return {Function}
 */
export const migrateCdpFromAddressToProxyAction = cdp => async (dispatch, getState) => {
  const notificationFunc = (promise, waitForSign) => sendTx(promise, `${t('maker.migrate_cdp')}`, `CDP #${cdp.id}`, dispatch, getState, { protocol: 'maker' } ,waitForSign);  // eslint-disable-line

  const { account, accountType, path } = getState().general;
  let { proxyAddress } = getState().maker;

  dispatch({ type: MIGRATE_CDP_FROM_ADDRESS_TO_PROXY_REQUEST });

  try {
    if (!proxyAddress) {
      proxyAddress = await dispatch(createDSProxyAction());
    }

    const payload = { ...cdp, owner: proxyAddress };

    requireAddress(proxyAddress);

    await migrateCdpFromAddressToProxy(cdp, accountType, path, notificationFunc, cdp.id, proxyAddress, account);

    dispatch({ type: MIGRATE_CDP_FROM_ADDRESS_TO_PROXY_SUCCESS, payload });
  } catch (err) {
    dispatch({ type: MIGRATE_CDP_FROM_ADDRESS_TO_PROXY_FAILURE, payload: err.message });
    captureException(err);
  }
};

/**
 * Fetches historical Vault profitability data
 *
 * @param cdpId
 * @returns {Function}
 */
export const getCdpProfitability = cdpId => async (dispatch) => {
  dispatch({ type: GET_CDP_PROFITABILITY_REQUEST });

  try {
    const res = await fetch(`https://defiexplore.com/api/cdps/${cdpId}/get-profit`);
    const data = await res.json();
    dispatch({
      type: GET_CDP_PROFITABILITY_SUCCESS,
      payload: {
        valueImported: data.dollarsIn,
        valueExported: data.dollarsOut,
      },
    });
  } catch (err) {
    dispatch({ type: GET_CDP_PROFITABILITY_FAILURE, payload: err.message });
    captureException(err);
  }
};

export const changeProxyOwnerAction = (newOwner) => async (dispatch, getState) => {
  dispatch({ type: CHANGE_PROXY_OWNER_REQUEST });
  try {
    const { maker: { proxyAddress }, general: { accountType, account } } = getState();
    const txToExecute = [];

    const sendTxFunc = (promise, waitForSign) => sendTx(promise, 'Change proxy owner', 'account', dispatch, getState, {}, waitForSign);

    const openTroveFunctionObject = {
      type: 'changeProxyOwner',
      notifMessage: 'Change proxy owner',
      func: () => changeProxyOwner(accountType, sendTxFunc, proxyAddress, account, newOwner),
    };

    txToExecute.push(openTroveFunctionObject);

    const returnValues = await dispatch(callMultipleTxAction(txToExecute));

    dispatch({ type: CHANGE_PROXY_OWNER_SUCCESS });
    // dispatch(getProxyAddress());
  } catch (error) {
    console.error(error);
    dispatch({ type: CHANGE_PROXY_OWNER_FAILURE, payload: error.message });
    captureException(error);
  }
};

export const getProxyForAccount = (account) => async (dispatch, getState) => {
  const isMainnet = !isLayer2Network(getState().general.network);
  const contractCall = isMainnet ? DFSProxyRegistryContract().methods.getAllProxies : proxyRegistryInterfaceContract().methods.proxies;
  const data = await contractCall(account).call();
  const _mainWallet = isMainnet ? data[0] : data;
  return isEmptyBytes(_mainWallet) ? '' : _mainWallet;
};
