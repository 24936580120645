import I18nEN from './I18-en';
import I18nTC from './I18-tc';
import I18nES from './I18-es';
import { getLangOption } from '../services/translationService';

const lang = getLangOption().value;

switch (lang) {
  case 'en': {
    window.I18n = I18nEN;
    break;
  }
  case 'tc': {
    window.I18n = I18nTC;
    break;
  }
  case 'es': {
    window.I18n = I18nES;
    break;
  }
  default: {
    window.I18n = I18nEN;
  }
}
