import React, {
  Component, useState, useEffect, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  checkExploitableApprovalsAction,
  withdrawAllFromSafeguardAction,
  deapproveAddressOnAssetAction,
} from '../../actions/assetsActions';
import {
  CONTRACTS_WITH_EXPLOIT,
  ASSETS_WITH_EXPLOIT,
  balancesOnSafeguard,
} from '../../services/assetsService';
import { APPROVE_TYPE_CONTRACT } from '../../actionTypes/assetsActionTypes';
import Loader from '../Loader/Loader';
import PrivateRoute from '../PrivateRoute/PrivateRoute';

import ErrorBox from '../Decorative/ErrorBox/ErrorBox';
import SuccessBox from '../Decorative/SuccessBox/SuccessBox';
import { userDeapprovedApiCall } from '../../services/apiService';

const CompoundImportDeapprove = ({
  account, exploitableApprovals, exploitableApprovalsLoading, exploitableApprovalsError,
  checkExploitableApprovalsAction, deapproveAddressOnAssetAction, withdrawAllFromSafeguardAction,
}) => {
  const [loading, setLoading] = useState(true);
  const removeApproval = (asset, contract) => {
    deapproveAddressOnAssetAction(asset, contract, APPROVE_TYPE_CONTRACT)
      .then(() => userDeapprovedApiCall(account, 'CompoundImport'));
  };

  const hasExploitableApprovalCompound = exploitableApprovals.some(a => a.some(b => b));

  return (
    <div className="dashboard-page-wrapper">
      <div className="content-wrapper">
        <div className="width-container">
          <div className="deapprove-wrapper">
            <p>
              A vulnerability in the DeFi Saver Compound Import has been found. Please remove all approvals listed below in order to secure your account.
              <br />
              <br />
              If your Compound position has been moved, <b>it is currently on a Smart Wallet that is owned and manageable by your account only.</b> You can manage this position and withdraw any funds in our Compound dashboard after removing all listed approvals.
              <br />
              <br />
              No other part of DeFi Saver was affected by the found vulnerability.
              <br />
              <br />
              <a target="_blank" rel="noopener noreferrer" href="https://medium.com/defi-saver/disclosing-a-recently-discovered-vulnerability-d88e3b5cb67">More details</a>
            </p>

            <hr />

            { exploitableApprovalsLoading && <Loader /> }

            {
              !exploitableApprovalsLoading &&
              !hasExploitableApprovalCompound && (
                <SuccessBox>Your account is secure, no action is required.</SuccessBox>
              )
            }
            {
              !exploitableApprovalsLoading &&
              hasExploitableApprovalCompound && (
                <ErrorBox>Action required: your account is not secure.</ErrorBox>
              )
            }
            {
              !exploitableApprovalsLoading &&
              Object.keys(CONTRACTS_WITH_EXPLOIT.compoundImport).map((contract, i) => (
                exploitableApprovals[i] &&
                CONTRACTS_WITH_EXPLOIT.compoundImport[contract].map((asset, j) => (
                  exploitableApprovals[i][j]
                    ? (
                      <div key={`${contract}-${asset}`} className="MarginTop10">
                        <button className="button" type="button" onClick={() => removeApproval(asset, contract)}>Remove {asset} approval for {contract}</button>
                      </div>
                    ) : ''
                ))
              ))
            }

            <hr />
            <div>
              <p>Do not hesitate to contact us via Twitter, Discord or the help widget on the bottom right.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CompoundImportDeapprove.defaultProps = {
  exploitableApprovals: [],
};

CompoundImportDeapprove.propTypes = {
  account: PropTypes.string.isRequired,
  checkExploitableApprovalsAction: PropTypes.func.isRequired,
  deapproveAddressOnAssetAction: PropTypes.func.isRequired,
  withdrawAllFromSafeguardAction: PropTypes.func.isRequired,
  exploitableApprovals: PropTypes.array,
  exploitableApprovalsLoading: PropTypes.bool.isRequired,
  exploitableApprovalsError: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  account: state.general.account,
  exploitableApprovals: state.general.exploitableApprovals.compoundImport,
  exploitableApprovalsLoading: state.general.exploitableApprovalsLoading,
  exploitableApprovalsError: state.general.exploitableApprovalsError,
});

const mapDispatchToProps = {
  checkExploitableApprovalsAction,
  deapproveAddressOnAssetAction,
  withdrawAllFromSafeguardAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompoundImportDeapprove);
