import t from 'translate';
import {
  GETTING_NEXUS_DATA_REQUEST,
  GETTING_NEXUS_DATA_SUCCESS,
  GETTING_NEXUS_DATA_FAILURE,
} from '../actionTypes/nexusActionTypes';
import { getAddressNexusInsurances } from '../services/nexusService';

export const getNexusInsurances = () => async (dispatch, getState) => {
  dispatch({ type: GETTING_NEXUS_DATA_REQUEST });

  try {
    const { account } = getState().general;
    const insurances = await getAddressNexusInsurances(account);

    let makerInsured = insurances.filter(i => i.insuring === 'maker');
    let compoundInsured = insurances.filter(i => i.insuring === 'compound');
    let aaveInsured = insurances.filter(i => i.insuring === 'aave');

    if (makerInsured.length === 0) makerInsured = false;
    if (compoundInsured.length === 0) compoundInsured = false;
    if (aaveInsured.length === 0) aaveInsured = false;

    dispatch({
      type: GETTING_NEXUS_DATA_SUCCESS,
      payload: {
        insurances, makerInsured, compoundInsured, aaveInsured,
      },
    });
  } catch (e) {
    dispatch({ type: GETTING_NEXUS_DATA_FAILURE, payload: e.message });
  }
};

export const parseInsuranceToLabel = iArr => iArr.reduce((_sum, i, index) => {
  let sum = _sum;
  sum += t('misc.insurance_label', {
    '%sumAssured': i.sumAssured,
    '%currency': i.currency,
    '%validUntil': (new Date(i.validUntil * 1000)).toDateString(),
  });

  if (index !== iArr.length - 1) sum += '<br>';

  return sum;
}, '');
