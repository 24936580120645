import React from 'react';
import PropTypes from 'prop-types';

import './BlockerMessage.scss';

const BlockerMessage = ({ children, additionalClass }) => (
  <div className="dashboard-page-wrapper">
    <div className="content-wrapper">
      <div className="width-container">
        <div className={`cdp-blocker-wrapper main-section-wrapper ${additionalClass}`}>
          { children }
        </div>
      </div>
    </div>
  </div>
);

BlockerMessage.defaultProps = {
  additionalClass: '',
};

BlockerMessage.propTypes = {
  children: PropTypes.node.isRequired,
  additionalClass: PropTypes.string,
};

export default BlockerMessage;
