export const CET_CLOSE_CDP_DATA_REQUEST = 'CET_CLOSE_CDP_DATA_REQUEST';
export const CET_CLOSE_CDP_DATA_SUCCESS = 'CET_CLOSE_CDP_DATA_SUCCESS';
export const CET_CLOSE_CDP_DATA_FAILURE = 'CET_CLOSE_CDP_DATA_FAILURE';

export const RECLAIM_COLLATERAL_REQUEST = 'RECLAIM_COLLATERAL_REQUEST';
export const RECLAIM_COLLATERAL_SUCCESS = 'RECLAIM_COLLATERAL_SUCCESS';
export const RECLAIM_COLLATERAL_FAILURE = 'RECLAIM_COLLATERAL_FAILURE';

export const GET_OPEN_CDP_OPTIONS_REQUEST = 'GET_OPEN_CDP_OPTIONS_REQUEST';
export const GET_OPEN_CDP_OPTIONS_SUCCESS = 'GET_OPEN_CDP_OPTIONS_SUCCESS';
export const GET_OPEN_CDP_OPTIONS_FAILURE = 'GET_OPEN_CDP_OPTIONS_FAILURE';
