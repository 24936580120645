import React from 'react';
import t from 'translate';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ModalHeader from '../ModalHeader';
import ModalBody from '../ModalBody';

const LeverageModal = ({ closeModal }) => (
  <div className="leverage-modal-wrapper coming-soon-modal">
    <ModalHeader closeModal={closeModal} />

    <ModalBody>
      <div className="modal-content">
        <div className="title">{t('common.coming_soon', { '%feature': t('common.leverage') })}</div>

        <div className="container">
          <div className="description">
            {t('misc.leverage_description')}
          </div>
        </div>
      </div>
    </ModalBody>
  </div>
);

LeverageModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(LeverageModal);
