import React from 'react';
import t from 'translate';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isInvalid, isPristine } from 'redux-form';
import UnsubscribeFeedbackForm from './UnsubscribeFeedbackForm/UnsubscribeFeedbackForm';

import ModalHeader from '../ModalHeader';
import ModalBody from '../ModalBody';

import './UnsubscribeFeedbackModal.scss';

import feedbackIcon from './feedback.svg';

const UnsubscribeFeedbackModal = ({
  closeModal, pristine, invalid, protocol, action,
}) => (
  <div id="unsubscribe-feedback-modal-wrapper">
    <ModalHeader closeModal={closeModal} />

    <ModalBody>
      <div className="new-modal-top-wrapper" style={{ backgroundImage: `url(${feedbackIcon})` }}>
        <h1>Disabling Automated Strategy</h1>
      </div>

      <div className="new-modal-content-wrapper">
        <p>{t('maker.why_disabling_automation')}</p>
        <UnsubscribeFeedbackForm closeModal={closeModal} protocol={protocol} submitAction={action} />
      </div>
    </ModalBody>

    <div className="modal-controls">
      <button
        type="button"
        disabled={invalid}
        className="button"
        form="unsubscribe-feedback-form"
        onClick={() => closeModal()}
      >
        { t('common.cancel') }
      </button>
      <button
        type="submit"
        disabled={invalid}
        className="button green"
        form="unsubscribe-feedback-form"
      >
        { t('common.disable') }
      </button>
    </div>
  </div>
);

UnsubscribeFeedbackModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  protocol: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { protocol }) => ({
  pristine: isPristine('unsubscribeFeedbackForm')(state),
  invalid: isInvalid('unsubscribeFeedbackForm')(state),
});

export default connect(mapStateToProps)(UnsubscribeFeedbackModal);
