import Dec from 'decimal.js';
import { flatten } from 'lodash';
import { assetAmountInEth, ilkToAsset, ilks } from '@defisaver/tokens';
import clientConfig from 'config/clientConfig.json';
import {
  McdGetCdpsContract,
  McdViewContract,
  mcdSpotterAddress,
  mcdVatAddress,
  mcdCdpManagerAddress,
  McdCdpManagerContract,
  BCdpManagerAddress, mcdJugAddress,
  SubscriptionsV2Address,
  AuthCheckAddress,
  CdpRegistryContract,
  CropperAddress, mcdDogAddress,
} from '../contractRegistryService';
import callTx from '../txService';
import { bytesToString, requireAddress } from '../utils';
import { aggregate } from '../ethService';
import { getInstadappAccounts } from '../instaServices';

/**
 * @typedef {Object} MakerVaultInfo
 * @property {number} id
 * @property {string} urn
 * @property {('mcd'|'b'|'crop')} type
 * @property {('InstaDApp'|'B-Protocol')} external
 * @property {string} owner
 * @property {string} asset
 * @property {string} ilk
 * @property {string} ilkLabel
 * @property {string} collateral
 * @property {string} collateralUsd
 * @property {string} assetPrice
 * @property {string} debtAsset
 * @property {string} debtInAsset
 * @property {string} debtUsd
 * @property {string} debtAssetPrice
 * @property {string} debtAssetMarketPrice
 * @property {string} liquidationPrice
 * @property {number} ratio
 * @property {number} liqPercent - Replacing liqRatio * 100
 * @property {string} unclaimedCollateral
 * @property {boolean} debtTooLow
 * @property {boolean} isSubscribedToAutomation
 * @property {number} minDebt
 * @property {string} creatableDebt
 * @property {boolean} automationResubscribeRequired
 * @property {string} stabilityFee
 * @property {number} lastUpdated
 * @property {string} daiLabel - deprecated by debtAsset
 * @property {string} debtDai - deprecated by debtInAsset
 * @property {string} liqRatio - deprecated by liqPercent
 * @property {string} globalDebtCeiling
 * @property {string} liquidationFee - percent value
 */

export const getCollateralInfo = async (ilk) => {
  const multiRes = await aggregate(
    [
      {
        target: mcdSpotterAddress,
        call: ['par()(uint256)'],
        returns: [
          ['par', val => Dec(val.toString()).div(1e27).toString()],
        ],
      },
      {
        target: mcdSpotterAddress,
        call: ['ilks(bytes32)(address,uint256)', ilk],
        returns: [
          ['pip', val => Dec(val.toString()).toString()],
          ['mat', val => Dec(val.toString()).div(1e27).toString()],
        ],
      },
      {
        target: mcdVatAddress,
        call: ['ilks(bytes32)(uint256,uint256,uint256,uint256,uint256)', ilk],
        returns: [
          ['art', val => Dec(val.toString()).toString()],
          ['rate', val => Dec(val.toString()).toString()],
          ['spot', val => Dec(val.toString()).div(1e27).toString()],
          ['line', val => Dec(val.toString()).div(1e45).toString()],
          ['dust', val => Dec(val.toString()).div(1e45).toString()],
        ],
      },
      {
        target: mcdJugAddress,
        call: ['ilks(bytes32)(uint256,uint256)', ilk],
        returns: [
          ['duty', val => Dec(val.toString()).toString()],
          ['rho', val => Dec(val.toString()).toString()],
        ],
      },
      {
        target: mcdJugAddress,
        call: ['drip(bytes32)(uint256)', ilk],
        returns: [
          // Rate after a transaction updates internal rate
          ['futureRate', val => Dec(val.toString()).toString()],
        ],
      },
      {
        target: mcdDogAddress,
        call: ['chop(bytes32)(uint256)', ilk],
        returns: [
          ['chop', val => Dec(val.toString()).div(1e18).toString()],
        ],
      },
    ],
  );


  const {
    results: {
      transformed: {
        par, mat, art, spot, line, dust, rate, duty, rho, chop, futureRate,
      },
    },
  } = multiRes;
  const secondsPerYear = 60 * 60 * 24 * 365;
  const stabilityFee = new Dec(duty.toString())
    .div(1e27)
    .pow(secondsPerYear)
    .minus(1)
    .mul(100)
    .toNumber();
  const liquidationFee = new Dec(chop).mul(100).sub(100).toString();
  return {
    ilkLabel: bytesToString(ilk),
    currentRate: rate.toString(),
    futureRate: futureRate.toString(),
    minDebt: dust.toString(),
    globalDebtCurrent: Dec(art.toString()).div(1e18).mul(Dec(futureRate.toString()).div(1e27)).toString(),
    globalDebtCeiling: line.toString(),
    assetPrice: Dec(spot).times(par).times(mat).toString(),
    liqRatio: mat,
    liqPercent: mat * 100,
    stabilityFee,
    liquidationFee: new Dec(liquidationFee).lt(0) ? '0' : liquidationFee,
  };
};

/**
 * @param cdp
 * @returns {Promise<MakerVaultInfo>}
 */
export const getCdpInfo = async (cdp) => {
  const ilkInfo = await getCollateralInfo(cdp.ilk);

  const automationAvailable = cdp.type === 'mcd' && !cdp.external;
  const multiRes = await aggregate(
    [
      {
        target: mcdVatAddress,
        call: ['urns(bytes32,address)(uint256,uint256)', cdp.ilk, cdp.urn],
        returns: [
          ['ink', val => Dec(val.toString())],
          ['art', val => Dec(val.toString())],
        ],
      },
      {
        target: mcdVatAddress,
        call: ['gem(bytes32,address)(uint256)', cdp.ilk, cdp.urn],
        returns: [
          ['coll', val => assetAmountInEth(val.toString(), cdp.asset)],
        ],
      },
      {
        target: SubscriptionsV2Address,
        call: ['getSubscribedInfo(uint256)(bool,uint128,uint128,uint128,uint128,address,uint256,uint256)', cdp.id],
        returns: [
          ['_subbed', data => automationAvailable && data],
          ['_'],
          ['_'],
          ['_'],
          ['_'],
          ['_owner'],
          ['_'],
          ['_'],
        ],
      },
      {
        target: AuthCheckAddress,
        call: ['canCall(address,address,bytes4)(bool)', '0x1816A86C4DA59395522a42b871bf11A4E96A1C7a', cdp.owner, '0x1cff79cd'],
        returns: [
          ['authorized', data => data],
        ],
      },
    ],
  );
  const {
    results: {
      transformed: {
        ink, art, coll, _subbed, _owner, authorized,
      },
    },
  } = multiRes;

  const collateralUsd = ink.mul(ilkInfo.assetPrice).floor();
  const debt = art.times(ilkInfo.currentRate).div(1e27).floor();
  const futureDebt = art.times(ilkInfo.futureRate).div(1e27).floor(); // after drip
  const debtDripDelta = assetAmountInEth(new Dec(futureDebt).sub(debt).toString(), 'DAI');
  const liquidationPrice = debt.times(ilkInfo.liqRatio).div(ink);

  let ratio = ink.times(ilkInfo.assetPrice).div(debt).times(100);
  if (debt.eq(0)) ratio = 0;

  const isSubscribedToAutomation = _subbed && (_owner === cdp.owner) && authorized;
  const automationResubscribeRequired = _subbed && (_owner !== cdp.owner || !authorized);

  const debtTooLow = debt.gt(0) && new Dec(assetAmountInEth(debt, 'DAI')).lt(ilkInfo.minDebt);
  const creatableDebt = new Dec(ilkInfo.globalDebtCeiling).sub(ilkInfo.globalDebtCurrent).toString();

  // TODO add par (DAI price) to getCollateralInfo & MakerVaultInfo
  const par = '1';
  return {
    ...cdp,
    id: cdp.id,
    urn: cdp.urn,
    type: cdp.type,
    ilk: cdp.ilk,
    ilkLabel: bytesToString(cdp.ilk),
    owner: cdp.owner,
    asset: cdp.asset,
    collateral: assetAmountInEth(ink, `MCD-${cdp.asset}`),
    collateralUsd: assetAmountInEth(collateralUsd, `MCD-${cdp.asset}`),
    debtDai: assetAmountInEth(debt, 'DAI'),
    debtUsd: assetAmountInEth(debt, 'DAI'),
    debtInAsset: assetAmountInEth(debt, 'DAI'),
    debtAssetPrice: par,
    debtAssetMarketPrice: par,
    liquidationPrice: liquidationPrice.toString(),
    ratio: parseFloat(ratio.toString()),
    liqRatio: ilkInfo.liqRatio.toString(),
    liqPercent: parseFloat(ilkInfo.liqPercent.toString()),
    assetPrice: ilkInfo.assetPrice,
    daiLabel: 'DAI',
    debtAsset: 'DAI',
    isSubscribedToAutomation,
    automationResubscribeRequired,
    unclaimedCollateral: coll,
    debtTooLow,
    minDebt: ilkInfo.minDebt,
    stabilityFee: ilkInfo.stabilityFee,
    creatableDebt,
    globalDebtCeiling: ilkInfo.globalDebtCeiling,
    liquidationFee: ilkInfo.liquidationFee,
    lastUpdated: Date.now(),
  };
};
/**
 * Gets cdps for a passed down address
 *
 * @param address {String}
 * @return {Promise<{id: *, ilk: string, urn: string, asset: string, type: string, owner: *}[]>}
 */
export const getStandardCdps = async (address) => {
  if (!address) return [];
  const contract = McdGetCdpsContract();
  let cdps = await contract.methods.getCdpsAsc(mcdCdpManagerAddress, address).call();
  cdps = cdps || { ids: [], ilks: [], urns: [] };

  return cdps.ids.map((id, i) => ({
    id: parseInt(id, 10),
    ilk: cdps.ilks[i].toLowerCase(), // collateral type
    ilkLabel: bytesToString(cdps.ilks[i].toLowerCase()),
    urn: cdps.urns[i].toLowerCase(), // contract of cdp
    asset: ilkToAsset(cdps.ilks[i]),
    type: 'mcd',
    owner: address,
  }));
};

/**
 * Gets CDPs from CdpRegistry (used for crop ilks, eg. collateral with LIDO or CRV rewards)
 *
 * @param address {String}
 * @return {Promise<{id: *, ilk: string, urn: string, asset: string, type: string, owner: *}[]>}
 */
export const getCropJoinCdps = async (address) => {
  if (!address) return [];
  const contract = McdViewContract();
  const cropIlkBytes = ilks.filter(i => i.isCrop).map(i => i.ilkBytes);
  let cdps = await contract.methods.getCropJoinCdps(cropIlkBytes, address).call();
  cdps = cdps || { ids: [], ilks: [], urns: [] };

  return cdps.ids.filter(id => id.toString() !== '0').map((id, i) => ({
    id: parseInt(id, 10),
    ilk: cdps.ilks[i].toLowerCase(), // collateral type
    ilkLabel: bytesToString(cdps.ilks[i].toLowerCase()),
    urn: cdps.urns[i].toLowerCase(), // contract of cdp
    asset: ilkToAsset(cdps.ilks[i]),
    type: 'crop',
    owner: address,
  }));
};

export const getBCdps = async (address) => {
  if (!address) return [];
  const contract = McdGetCdpsContract();
  let cdps = await contract.methods.getCdpsAsc(BCdpManagerAddress, address).call();
  cdps = cdps || { ids: [], ilks: [], urns: [] };

  return cdps.ids.map((id, i) => ({
    id: parseInt(id, 10),
    ilk: cdps.ilks[i].toLowerCase(), // collateral type
    ilkLabel: bytesToString(cdps.ilks[i].toLowerCase()),
    urn: cdps.urns[i].toLowerCase(), // contract of cdp
    asset: ilkToAsset(cdps.ilks[i]),
    type: 'b',
    owner: address,
    external: 'B-Protocol',
  }));
};

/**
 * Gets cdps for both the address and proxyAddress
 *
 * @param account {String}
 * @param proxyAddress {String}
 * @return {Promise<Array>}
 */
export const getCdps = async (account, proxyAddress) => {
  const promises = [
    getStandardCdps(proxyAddress),
    getStandardCdps(account),
    getCropJoinCdps(proxyAddress),
    getCropJoinCdps(account),
  ];

  return flatten(await Promise.all(promises));
};

/**
 * Gets all CDPs that were created or migrated on the Instadapp platform
 *
 * @param address {String}
 * @return {Promise<*>}
 */
export const getInstadappCdps = async (address) => {
  if (clientConfig.network !== 1) return [];
  const instadappAccounts = await getInstadappAccounts(address);
  if (!instadappAccounts.length) return [];
  const promises = instadappAccounts.map(async ({ id, account, version }) => {
    const cdps = await getStandardCdps(account);
    // TODO Get crop join CDPs?
    return cdps.map(cdp => ({
      ...cdp,
      external: 'instaDapp',
      externalMeta: {
        id,
        account,
        version,
      },
    }));
  });
  return (await Promise.all(promises)).flat();
};

/**
 * Get all CDPs from B.Protocol
 *
 * @param account
 * @param proxyAddress
 * @returns {Promise<*>}
 */
export const getBProtocolCdps = async (account, proxyAddress) => {
  const promises = [
    getBCdps(account),
    getBCdps(proxyAddress),
  ];
  return flatten(await Promise.all(promises));
};

/**
 * Transfers the mcd cdp from the users address to the proxyAddress
 *
 * @param accountType {String}
 * @param path {String}
 * @param sendTxFunc {Function}
 * @param cdpId {Number}
 * @param proxyAddress {String}
 * @param account {String}
 * @return {Promise<any>}
 */
export const migrateCdpFromAddressToProxy = async (
  accountType, path, sendTxFunc, cdpId, proxyAddress, account,
) => {
  const contract = await McdCdpManagerContract();
  requireAddress(proxyAddress);
  return callTx(accountType, path, sendTxFunc, contract, 'give', [cdpId, proxyAddress], { from: account });
};

export const formatCdpId = (cdp) => {
  if (cdp.external === 'B-Protocol') return `B-${cdp.id}`;
  // if (cdp.external === 'instaDapp') return `I-${cdp.id}`;
  return `${cdp.id}`;
};

export const latestCdpId = async () => {
  const contract = McdCdpManagerContract();
  const cdpi = await contract.methods.cdpi().call();
  return parseInt(cdpi, 10);
};

/**
 * Returns CDP ID for given DsProyx & crop ilk if it exists, returns 0 otherwise
 * @param owner
 * @param ilkBytes
 * @returns {Promise<String>}
 */
export const getCropCdpId = async (owner, ilkBytes) => {
  const cdpId = await CdpRegistryContract().methods.cdps(ilkBytes, owner).call();
  return cdpId.toString();
};

export const getCdpManagerForType = (type) => ({
  mcd: mcdCdpManagerAddress,
  crop: CropperAddress,
  b: BCdpManagerAddress,
})[type];
export const findEnabledStrategiesForCdp = (cdpId, makerStrategies) => makerStrategies.filter(({ subData, isEnabled }) => isEnabled && new Dec(subData.vaultId).eq(cdpId));
