import dfs from '@defisaver/sdk';
import cloneDeep from 'lodash/cloneDeep';
import RecipeAction from '../RecipeAction';
import { Amount, Source } from '../../components/Recipes/RecipeCreator/inputTypes';
import { changeBalance } from '../../services/recipeCreator/recipeActionUtils';
import ClaimIcon from '../recipeIcons/Claim.svg';

export default class LiquityClaimAction extends RecipeAction {
  static prettyName = 'Claim collateral';

  static protocol = 'liquity';

  static protocolPrettyName = 'Liquity';

  static description = 'Claims remaining ETH from a Trove that was liquidated or redeemed against.';

  constructor(to = 'wallet') {
    super();
    this.inputs = [
      new Source('To', to, true),
    ];
    this.output = new Amount('output', 0);
  }

  async toDfsAction(getState) {
    const {
      general: { account },
      maker: { proxyAddress },
    } = getState();
    const to = this.inputs[0].value === 'wallet' ? account : proxyAddress;
    return new dfs.actions.liquity.LiquityClaimAction(to);
  }

  async getAfterValues(_balances = {}, returnValues = [], actions = [], positions = {}, getState) {
    const {
      general: { account },
      maker: { proxyAddress },
      liquity: { proxy: { claimableCollateral } },
    } = getState();
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const balances = cloneDeep(_balances);
    await changeBalance(balances, args[0], 'WETH', claimableCollateral || 0, args[0] === 'wallet' ? account : proxyAddress);
    this.output.value = claimableCollateral || 0;
    return {
      returnValue: this.output,
      balances,
      positions,
    };
  }

  static getIcon() {
    return ClaimIcon;
  }
}
