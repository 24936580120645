import React from 'react';
import PropTypes from 'prop-types';
import { bemifyClass } from '../../services/stylingUtils';
import DataItem from '../DataItem/DataItem';
import './InfoDropdown.scss';
import ArrowCaretThick from '../Decorative/ArrowCaretThick';

const bem = bemifyClass('info-dropdown');

const InfoDropdown = ({
  title, label, items, modifier,
}) => (
  <div className={bem('', modifier)}>
    <div className={bem('value')}>
      <span className={bem('value-label')}>{label}</span>
      <ArrowCaretThick to="down" size={12} color="white" />
    </div>
    <div className={bem('list-wrapper')}>
      <div className={bem('list')}>
        {title && <div className={bem('title')}>{title}</div>}
        {items.map(({ additionalClasses = '', ...rest }) => (
          <DataItem
            key={rest.label}
            additionalClasses={`${bem('list-item')} ${additionalClasses}`}
            type="arrow"
            {...rest}
          />
        ))}
      </div>
    </div>
  </div>
);
InfoDropdown.defaultProps = {
  modifier: '',
};
InfoDropdown.propTypes = {
  title: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  items: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  modifier: PropTypes.string,
};
export default InfoDropdown;
