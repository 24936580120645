import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { checkExploitableApprovalsAction } from '../../actions/assetsActions';

const SafeguardRedirect = ({
  exploitableApprovals, location, account, checkExploitableApprovalsAction,
}) => {
  useEffect(() => {
    if (account) checkExploitableApprovalsAction();
  }, [account]);
  const hasExploitableApprovalExchange = exploitableApprovals.exchange?.some(a => a.some(b => b));
  const hasExploitableApprovalCompoundImport = exploitableApprovals.compoundImport?.some(a => a.some(b => b));
  if (hasExploitableApprovalExchange && !location.pathname.includes('safeguard')) return (<Redirect to="/safeguard/exchange" />);
  if (hasExploitableApprovalCompoundImport && !location.pathname.includes('safeguard')) return (<Redirect to="/safeguard/compound-import" />);
  return <></>;
};

SafeguardRedirect.propTypes = {
  exploitableApprovals: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  account: PropTypes.string.isRequired,
  checkExploitableApprovalsAction: PropTypes.func.isRequired,
};

SafeguardRedirect.defaultProps = {};

const mapStateToProps = state => ({
  account: state.general.account,
  exploitableApprovals: state.general.exploitableApprovals,
});

const mapDispatchToProps = {
  checkExploitableApprovalsAction,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SafeguardRedirect));
