import Dec from 'decimal.js';
import { getAssetInfo } from '@defisaver/tokens';
import { getAddr } from '@defisaver/sdk/src/addresses';
import * as strategiesConstants from '../../constants/strategiesConstants';
import {
  LIQUITY_CLOSE_ON_PRICE_TO_COLL_STRATEGY,
  MAKER_CLOSE_ON_PRICE_TO_COLL_STRATEGY,
  MAKER_CLOSE_ON_PRICE_TO_DAI_STRATEGY,
} from '../../constants/strategiesConstants';
import { compareAddresses, requireAddress } from '../utils';

export const makerRepayFromSavingsTriggerData = {
  /**
   * @param vaultId {number|string}
   * @param ratio {string}
   * @param ratioState {number}
   * @returns {[string]}
   */
  encode: (vaultId, ratio, ratioState) => {
    const web3 = window._web3;
    const _ratio = web3.utils.toWei(new Dec(ratio).div(100).toString());
    return [web3.eth.abi.encodeParameters(['uint256', 'uint256', 'uint8'], [vaultId, _ratio, ratioState])];
  },
  /**
   * @param triggerData {[string]}
   * @returns {{vaultId: number, ratioState: number, ratio: string}}
   */
  decode: (triggerData) => {
    const web3 = window._web3;
    const decodedData = web3.eth.abi.decodeParameters(['uint256', 'uint256', 'uint8'], triggerData[0]);
    return { vaultId: +decodedData[0], ratio: new Dec(web3.utils.fromWei(decodedData[1])).mul(100).toString(), ratioState: +decodedData[2] };
  },
};

export const makerRepayFromSavingsSubData = {
  /**
   * @param vaultId {number|string}
   * @param targetRatio {string}
   * @param [daiAddr] {string}
   * @param [mcdCdpManagerAddr] {string}
   * @returns {[string,string,string,string]}
   */
  encode: (vaultId, targetRatio, daiAddr = getAssetInfo('DAI').address, mcdCdpManagerAddr = getAddr('McdCdpManager')) => {
    const web3 = window._web3;

    const vaultIdEncoded = web3.eth.abi.encodeParameter('uint256', vaultId.toString());
    const _targetRatio = web3.utils.toWei(new Dec(targetRatio).div(100).toString());
    const targetRatioEncoded = web3.eth.abi.encodeParameter('uint256', _targetRatio);
    const daiAddrEncoded = web3.eth.abi.encodeParameter('address', daiAddr);
    const mcdManagerAddrEncoded = web3.eth.abi.encodeParameter('address', mcdCdpManagerAddr);

    return [vaultIdEncoded, targetRatioEncoded, daiAddrEncoded, mcdManagerAddrEncoded];
  },
  /**
   * @param subData {[string]}
   * @returns {{vaultId: number, daiAddr: string, mcdManagerAddr: string, targetRatio: string}}
   */
  decode: (subData) => {
    const web3 = window._web3;

    const vaultId = +web3.eth.abi.decodeParameter('uint256', subData[0]).toString();
    const targetRatio = new Dec(web3.utils.fromWei(web3.eth.abi.decodeParameter('uint256', subData[1]))).mul(100).toString();
    const daiAddr = web3.eth.abi.decodeParameter('address', subData[2]).toString();
    const mcdManagerAddr = web3.eth.abi.decodeParameter('address', subData[3]).toString();

    return {
      vaultId, targetRatio, daiAddr, mcdManagerAddr,
    };
  },
};

/**
 * Encode data for Maker repay from Smart Savings
 *
 * @param {string} bundleId
 * @param {number} vaultId
 * @param {string} ratioUnder
 * @param {string} targetRatio
 * @param {boolean} isBundle
 * @return {[string, boolean, [string], [string, string, string, string]]}
 */
export const encodeMakerRepayFromSavingsStrategySub = (bundleId, vaultId, ratioUnder, targetRatio, isBundle = true) => {
  const subData = makerRepayFromSavingsSubData.encode(vaultId, targetRatio);
  const triggerData = makerRepayFromSavingsTriggerData.encode(vaultId, ratioUnder, strategiesConstants.RATIO_STATE_UNDER);

  return [bundleId, isBundle, triggerData, subData];
};


export const closeOnPriceTriggerData = {
  /**
   * @param tokenAddr {string}
   * @param _price {string}
   * @param state {number}
   * @returns {[string]}
   */
  encode: (tokenAddr, _price, state) => {
    const web3 = window._web3;
    const price = new Dec(_price).mul(1e8).floor().toString();
    return [web3.eth.abi.encodeParameters(['address', 'uint256', 'uint8'], [tokenAddr, price, state])];
  },
  /**
   * @param triggerData {[string]}
   * @returns {{price: string, state: number, tokenAddr: string}}
   */
  decode: (triggerData) => {
    const web3 = window._web3;
    const decodedData = web3.eth.abi.decodeParameters(['address', 'uint256', 'uint8'], triggerData[0]);
    return { tokenAddr: decodedData[0], price: new Dec(decodedData[1]).div(1e8).toString(), state: +decodedData[2] };
  },
};

export const makerCloseOnPriceSubData = {
  /**
   * @param vaultId {string|number}
   * @param closeToAssetAddr {string}
   * @param [daiAddr] {string}
   * @param [mcdCdpManagerAddr] {string}
   * @returns {string[]}
   */
  encode: (vaultId, closeToAssetAddr, daiAddr = getAssetInfo('DAI').address, mcdCdpManagerAddr = getAddr('McdCdpManager')) => {
    const web3 = window._web3;

    const vaultIdEncoded = web3.eth.abi.encodeParameter('uint256', vaultId.toString());
    const daiAddrEncoded = web3.eth.abi.encodeParameter('address', daiAddr);
    const mcdManagerAddrEncoded = web3.eth.abi.encodeParameter('address', mcdCdpManagerAddr);
    if (compareAddresses(closeToAssetAddr, daiAddr)) {
      // close to DAI strategy
      return [vaultIdEncoded, daiAddrEncoded, mcdManagerAddrEncoded];
    }
    // close to collateral strategy
    const collAddrEncoded = web3.eth.abi.encodeParameter('address', closeToAssetAddr);
    return [vaultIdEncoded, collAddrEncoded, daiAddrEncoded, mcdManagerAddrEncoded];
  },
  /**
   * @param subData
   * @returns {{vaultId: number, closeToAssetAddr: string}}
   */
  decode: (subData) => {
    const web3 = window._web3;

    const vaultId = +web3.eth.abi.decodeParameter('uint256', subData[0]);
    // if closing to collateral, asset addr will be 2nd param out of 4
    // if closing to DAI, will return 2nd param out of 3, which will be DAI addr
    const closeToAssetAddr = web3.eth.abi.decodeParameter('address', subData[1]).toString().toLowerCase();

    return {
      vaultId, closeToAssetAddr,
    };
  },
};

export const liquityCloseOnPriceSubData = {
  /**
   * @param closeToAssetAddr {string}
   * @param [collAddr] {string}
   * @param [debtAddr] {string}
   * @returns {string[]}
   */
  encode: (closeToAssetAddr, collAddr = getAssetInfo('WETH').address, debtAddr = getAssetInfo('LUSD').address) => {
    const collAddrEncoded = window._web3.eth.abi.encodeParameter('address', collAddr);
    const debtAddrEncoded = window._web3.eth.abi.encodeParameter('address', debtAddr);
    // if (compareAddresses(closeToAssetAddr, daiAddr)) {
    //   // close to LUSD strategy
    //   return [daiAddrEncoded, mcdManagerAddrEncoded];
    // }
    // close to collateral strategy
    return [collAddrEncoded, debtAddrEncoded];
  },
  /**
   * @param subData
   * @returns {{collAddr: number, debtAddr: string}}
   */
  decode: (subData) => {
    const collAddr = window._web3.eth.abi.decodeParameter('address', subData[0]);
    const debtAddr = window._web3.eth.abi.decodeParameter('address', subData[1]);
    return { collAddr, debtAddr };
  },
};

/**
 * Encode data for Maker repay from Smart Savings
 *
 * @param {number} vaultId
 * @param {number} priceOverOrUnder
 * @param {string} price
 * @param {string} closeToAssetAddr
 * @param {string} chainlinkCollAddress
 * @return {[number, boolean, [string], [string, string, string]]}
 */
export const encodeMakerCloseOnPriceStrategySub = (vaultId, priceOverOrUnder, price, closeToAssetAddr, chainlinkCollAddress) => {
  requireAddress(closeToAssetAddr);
  requireAddress(chainlinkCollAddress);
  const subData = makerCloseOnPriceSubData.encode(vaultId, closeToAssetAddr);
  const triggerData = closeOnPriceTriggerData.encode(chainlinkCollAddress, price, priceOverOrUnder);

  const strategyOrBundleId = compareAddresses(closeToAssetAddr, getAssetInfo('DAI').address)
    ? MAKER_CLOSE_ON_PRICE_TO_DAI_STRATEGY
    : MAKER_CLOSE_ON_PRICE_TO_COLL_STRATEGY;
  const isBundle = false;

  return [strategyOrBundleId, isBundle, triggerData, subData];
};

/**
 * Encode data for Maker repay from Smart Savings
 *
 * @param {number} priceOverOrUnder
 * @param {string} price
 * @param {string} closeToAssetAddr
 * @param {string} chainlinkCollAddress
 * @return {[number, boolean, [string], [string, string, string]]}
 */
export const encodeLiquityCloseOnPriceStrategySub = (priceOverOrUnder, price, closeToAssetAddr, chainlinkCollAddress) => {
  requireAddress(closeToAssetAddr);
  requireAddress(chainlinkCollAddress);
  const subData = liquityCloseOnPriceSubData.encode(closeToAssetAddr);
  const triggerData = closeOnPriceTriggerData.encode(chainlinkCollAddress, price, priceOverOrUnder);

  // const strategyOrBundleId = compareAddresses(closeToAssetAddr, getAssetInfo('LUSD').address)
  //   ? LIQUITY_CLOSE_ON_PRICE_TO_DAI_STRATEGY
  //   : LIQUITY_CLOSE_ON_PRICE_TO_COLL_STRATEGY;
  const strategyOrBundleId = LIQUITY_CLOSE_ON_PRICE_TO_COLL_STRATEGY;
  const isBundle = false;

  return [strategyOrBundleId, isBundle, triggerData, subData];
};

/**
 * Compare subHash string with encoded subStruct
 *
 * @param currentSubHash
 * @param newSubStructEncoded
 * @return {boolean}
 */
export const compareSubHashes = (currentSubHash, newSubStructEncoded) => {
  const web3 = window._web3;

  return currentSubHash === web3.utils.keccak256(web3.eth.abi.encodeParameter('(uint64,bool,bytes[],bytes32[])', newSubStructEncoded));
};
