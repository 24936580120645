import PropTypes from 'prop-types';
import React from 'react';
import ModalHeader from '../ModalHeader';
import ConnectTrezor from '../../Connect/ConnectTrezor/ConnectTrezor';
import './TrezorConnectModal.scss';

const TrezorConnectModal = ({ closeModal }) => (
  <div className="trezor-connect-modal-wrapper">
    <ModalHeader closeModal={closeModal} />

    <ConnectTrezor closeModal={closeModal} />
  </div>
);

TrezorConnectModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default TrezorConnectModal;
