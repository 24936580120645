import Dec from 'decimal.js';
import { getAssetInfo } from '@defisaver/tokens';

import * as aaveManageTypes from '../../actionTypes/aaveActionTypes/aaveManageActionTypes';

import { captureException } from '../../sentry';
import { getMaxBoostUsd } from '../../services/moneymarketCommonService';
import { getMaxWithdraw } from '../../services/aaveServices/aaveManageService';
import { getAssetBalance } from '../../services/assetsService';
import { getAddressForWalletType } from '../../services/utils';
import { getDashboardInputs } from '../dashboardActions';
import { getMaxBorrowV2 } from '../../services/aaveServices/aaveManageServiceV2';

/**
 * Handles redux actions for when the number of max amount of selected asset that can be borrowed is calculating
 *
 * @return {Function}
 */
export const getMaxBoostAction = () => async (dispatch, getState) => {
  const forAction = 'boost';
  dispatch({ type: aaveManageTypes.AAVE_GET_MAX_VALUE_REQUEST, forAction });

  try {
    const { aaveManage, general: { walletType }, assets } = getState();
    const { selectedMarket, selectedAction, selectedAdditionalActions } = aaveManage;
    const { assetsData } = aaveManage[selectedMarket.value];
    const {
      eModeCategory, usedAssets, borrowedUsd, borrowLimitUsd,
    } = aaveManage[walletType.value][selectedMarket.value];

    const {
      firstAction,
      firstInputSelect,
      firstInputSecondSelect,
      secondInputSelect,
      secondInputSecondSelect,
    } = dispatch(getDashboardInputs(selectedAction, selectedAdditionalActions[selectedAction.value]));

    const borrowAsset = firstAction.value === forAction ? firstInputSelect?.value : secondInputSelect?.value;
    const supplyAsset = firstAction.value === forAction ? firstInputSecondSelect?.value : secondInputSecondSelect?.value;

    if (!borrowAsset || !supplyAsset) return dispatch({ type: aaveManageTypes.AAVE_GET_MAX_VALUE_SUCCESS, forAction, payload: '0' });

    const supplyAssetData = assetsData[supplyAsset];
    const supplyUsedAssetData = usedAssets[supplyAsset] || {};

    const collateralFactor = (eModeCategory === 0 || new Dec(supplyAssetData?.eModeCategoryData?.collateralFactor || 0).eq(0))
      ? supplyAssetData.collateralFactor
      : supplyAssetData.eModeCategoryData.collateralFactor;

    // borrow limit after enabling supplyAsset as collateral
    const newBorrowLimitUsd = (supplyUsedAssetData.collateral)
      ? borrowLimitUsd
      : new Dec(borrowLimitUsd).plus(new Dec(supplyUsedAssetData?.suppliedUsd || '0').mul(collateralFactor || '0')).toString();

    const debtAssetPrice = assets[borrowAsset].aavePrice;

    let maxBoostUsd = getMaxBoostUsd(collateralFactor || '0', newBorrowLimitUsd, borrowedUsd, 1.01);

    const assetPrice = getState().assets[borrowAsset].aavePrice;

    const borrowAssetData = assetsData[borrowAsset];
    let leftToBorrow;

    if (Number(borrowAssetData.borrowCap)) {
      leftToBorrow = new Dec(borrowAssetData.borrowCap).minus(borrowAssetData.totalBorrow).toString();
      if (leftToBorrow) {
        if (new Dec(leftToBorrow).lessThanOrEqualTo('0') || new Dec(maxBoostUsd).lessThanOrEqualTo('0')) maxBoostUsd = '0';
        else maxBoostUsd = Dec.min(new Dec(leftToBorrow).times(debtAssetPrice).toString(), maxBoostUsd).toString();
      }
    }

    let payload = new Dec(maxBoostUsd).div(assetPrice).toDP(getAssetInfo(borrowAsset).decimals).toString();

    let canSupply;
    let canBorrow;

    if (selectedMarket.value === 'v3default') {
      const usedAssetsValues = Object.values(usedAssets);
      const hasIsolatedAsset = usedAssetsValues.find(a => assetsData[a.symbol]?.isIsolated && a.collateral);

      if (hasIsolatedAsset?.symbol === supplyAsset) {
        canSupply = true;
      } else {
        canSupply = assetsData[supplyAsset]?.isIsolated
          ? !usedAssetsValues.find(a => a.collateral)
          : !hasIsolatedAsset;
      }

      if (hasIsolatedAsset) {
        canBorrow = assetsData[borrowAsset].isolationModeBorrowingEnabled;
      } else {
        canBorrow = !assetsData[supplyAsset].isIsolated;
      }

      if (canBorrow) {
        const isInSiloedBorrowing = usedAssetsValues.find((a) => assetsData[a.symbol].isSiloed && a.isBorrowed);
        if (isInSiloedBorrowing) {
          canBorrow = isInSiloedBorrowing.symbol === borrowAsset;
        } else if (assetsData[borrowAsset].isSiloed) {
          canBorrow = !usedAssetsValues.find((a) => a.isBorrowed);
        }
      }
      if (canBorrow && eModeCategory !== 0 && assetsData[borrowAsset].eModeCategory !== eModeCategory) canBorrow = false;
    } else {
      canSupply = true;
      canBorrow = true;
    }

    if (new Dec(payload).lt(0)) payload = '0';
    if (!canSupply || !canBorrow) payload = '0';

    dispatch({ type: aaveManageTypes.AAVE_GET_MAX_VALUE_SUCCESS, forAction, payload });
  } catch (err) {
    dispatch({ type: aaveManageTypes.AAVE_GET_MAX_VALUE_FAILURE, forAction, payload: err.message });
    captureException(err);
  }
};

/**
 * Handles redux actions for when the number of max amount of selected asset that can be repaid is calculating
 *
 * @return {Function}
 */
export const getMaxRepayAction = () => async (dispatch, getState) => {
  const forAction = 'repay';
  dispatch({ type: aaveManageTypes.AAVE_GET_MAX_VALUE_REQUEST, forAction });

  try {
    const { aaveManage, general: { walletType } } = getState();
    const { selectedMarket, selectedAction, selectedAdditionalActions } = aaveManage;
    const { usedAssets } = aaveManage[walletType.value][selectedMarket.value];

    const {
      firstAction,
      firstInputSelect,
      firstInputSecondSelect,
      secondInputSelect,
      secondInputSecondSelect,
    } = dispatch(getDashboardInputs(selectedAction, selectedAdditionalActions[selectedAction.value]));

    const collAsset = firstAction.value === forAction ? firstInputSelect?.value : secondInputSelect?.value;
    const debtAsset = firstAction.value === forAction ? firstInputSecondSelect?.value : secondInputSecondSelect?.value;

    let payload = '0';

    if (collAsset && debtAsset) {
      const assetPrice = getState().assets[collAsset].aavePrice;

      const totalDebtUsd = new Dec(usedAssets[debtAsset].borrowedUsd).mul(1.02).toString();
      const totalDebt = new Dec(totalDebtUsd).div(assetPrice).toDP(getAssetInfo(collAsset).decimals).toString();

      payload = Dec.min(usedAssets[collAsset].supplied, totalDebt).toString();
    }

    dispatch({ type: aaveManageTypes.AAVE_GET_MAX_VALUE_SUCCESS, forAction, payload });
  } catch (err) {
    dispatch({ type: aaveManageTypes.AAVE_GET_MAX_VALUE_FAILURE, forAction, payload: err.message });
    captureException(err);
  }
};

/**
 * Handles redux actions for when the number of max amount of selected asset that can be withdrawn is calculating
 *
 * @return {Function}
 */
export const getMaxWithdrawAction = (forRepayCheck = false) => async (dispatch, getState) => {
  const forAction = 'withdraw';
  dispatch({ type: aaveManageTypes.AAVE_GET_MAX_VALUE_REQUEST, forAction });

  const { aaveManage, general: { walletType } } = getState();

  const {
    selectedMarket, selectedAction, selectedAdditionalActions,
  } = aaveManage;

  const { usedAssets, borrowedUsd, borrowLimitUsd } = aaveManage[walletType.value][selectedMarket.value];
  const { assetsData } = aaveManage[selectedMarket.value];

  const {
    firstAction,
    firstInputSelect,
    secondInputSelect,
  } = dispatch(getDashboardInputs(selectedAction, selectedAdditionalActions[selectedAction.value]));

  let fromAsset = firstAction.value === forAction ? firstInputSelect?.value : secondInputSelect?.value;
  if (forRepayCheck && typeof forRepayCheck !== 'object') fromAsset = firstInputSelect?.value;

  try {
    let payload = '0';

    if (fromAsset) {
      const usedAssetData = usedAssets[fromAsset];
      const { aavePrice } = getState().assets[fromAsset];
      const assetData = assetsData[fromAsset];
      const { isBorrowed, isSupplied } = usedAssetData;

      let ratio;
      const onlyOneAssetUsed = Object.keys(usedAssets).length === 1;
      if (isBorrowed && isSupplied && onlyOneAssetUsed) ratio = 1.00011;
      else ratio = 1.01;

      payload = getMaxWithdraw(usedAssetData, aavePrice, assetData, borrowedUsd, borrowLimitUsd, ratio);
    }

    dispatch({ type: aaveManageTypes.AAVE_GET_MAX_VALUE_SUCCESS, forAction, payload });
  } catch (err) {
    dispatch({ type: aaveManageTypes.AAVE_GET_MAX_VALUE_FAILURE, forAction, payload: err.message });
    captureException(err);
  }
};

/**
 * Handles redux actions for when the number of max amount of selected asset that can be supplied is calculating
 *
 * @return {Function}
 */
export const getMaxSupplyAction = () => async (dispatch, getState) => {
  const forAction = 'collateral';
  dispatch({ type: aaveManageTypes.AAVE_GET_MAX_VALUE_REQUEST, forAction });

  try {
    const state = getState();
    const address = state.general.account;

    const { aaveManage } = state;
    const { selectedAction, selectedAdditionalActions, selectedMarket } = aaveManage;
    const {
      firstAction, firstInputSelect, secondInputSelect,
    } = dispatch(getDashboardInputs(selectedAction, selectedAdditionalActions[selectedAction.value]));

    let payload = '0';

    const asset = firstAction.value === forAction ? firstInputSelect?.value : secondInputSelect?.value;

    if (asset) {
      payload = await getAssetBalance(asset, address);
    }

    dispatch({ type: aaveManageTypes.AAVE_GET_MAX_VALUE_SUCCESS, forAction, payload });
  } catch (err) {
    console.error(err);
    dispatch({ type: aaveManageTypes.AAVE_GET_MAX_VALUE_FAILURE, forAction, payload: err.message });
    captureException(err);
  }
  return 'sss';
};

/**
 * Handles redux actions for when the number of max amount of selected asset that can be borrowed is calculating
 *
 * @return {Function}
 */
export const getMaxBorrowAction = (forBoostCheck = false) => async (dispatch, getState) => {
  const forAction = 'borrow';
  dispatch({ type: aaveManageTypes.AAVE_GET_MAX_VALUE_REQUEST, forAction });

  try {
    const address = getAddressForWalletType(getState);
    const { aaveManage, general: { walletType } } = getState();
    const {
      selectedAction, selectedAdditionalActions, selectedMarket,
    } = aaveManage;
    const {
      borrowedUsd, borrowLimitUsd, usedAssets, eModeCategory,
    } = aaveManage[walletType.value][selectedMarket.value];

    const {
      firstAction,
      firstInputSelect,
      secondInputSelect,
    } = dispatch(getDashboardInputs(selectedAction, selectedAdditionalActions[selectedAction.value]));

    let asset = firstAction.value === forAction ? firstInputSelect?.value : secondInputSelect?.value;
    if (forBoostCheck && typeof forBoostCheck !== 'object') asset = firstInputSelect?.value;

    let payload = '0';

    if (asset) {
      const { aavePrice } = getState().assets[asset];
      const { assetsData } = aaveManage[selectedMarket.value];
      const leftToBorrowUsd = new Dec(borrowLimitUsd).minus(borrowedUsd).toString();

      const fromAssetData = assetsData[asset];
      let leftToBorrowGlobally;
      if (Number(fromAssetData.borrowCap)) {
        leftToBorrowGlobally = new Dec(fromAssetData.borrowCap).minus(fromAssetData.totalBorrow).toString();
      }

      let canBorrow;
      if (selectedMarket.value === 'v3default') {
        const usedAssetsValues = Object.values(usedAssets);
        const hasIsolatedAsset = usedAssetsValues.find(a => assetsData[a.symbol]?.isIsolated && a.collateral);
        if (hasIsolatedAsset) { canBorrow = assetsData[asset].isolationModeBorrowingEnabled; } else { canBorrow = true; }

        if (canBorrow) {
          const isInSiloedBorrowing = usedAssetsValues.find((a) => assetsData[a.symbol].isSiloed && a.isBorrowed);
          if (isInSiloedBorrowing) {
            canBorrow = isInSiloedBorrowing.symbol === asset;
          } else if (assetsData[asset].isSiloed) {
            canBorrow = !usedAssetsValues.find((a) => a.isBorrowed);
          }
        }
        if (canBorrow && eModeCategory !== 0 && assetsData[asset].eModeCategory !== eModeCategory) canBorrow = false;
      } else {
        canBorrow = true;
      }

      payload = canBorrow ? getMaxBorrowV2(address, asset, aavePrice, 1, leftToBorrowUsd, leftToBorrowGlobally) : '0';
    }

    dispatch({ type: aaveManageTypes.AAVE_GET_MAX_VALUE_SUCCESS, forAction, payload });
  } catch (err) {
    dispatch({ type: aaveManageTypes.AAVE_GET_MAX_VALUE_FAILURE, forAction, payload: err.message });
    captureException(err);
  }
};

/**
 * Handles redux actions for when the number of max amount of selected asset that can be repaid is calculating
 *
 * @return {Function}
 */
export const getMaxPaybackAction = () => async (dispatch, getState) => {
  const forAction = 'payback';
  dispatch({ type: aaveManageTypes.AAVE_GET_MAX_VALUE_REQUEST, forAction });

  try {
    const { aaveManage, general: { walletType } } = getState();
    const { selectedMarket, selectedAction, selectedAdditionalActions } = aaveManage;
    const { usedAssets } = aaveManage[walletType.value][selectedMarket.value];

    const {
      firstAction,
      firstInputSelect,
      secondInputSelect,
    } = dispatch(getDashboardInputs(selectedAction, selectedAdditionalActions[selectedAction.value]));

    const fromAsset = firstAction.value === forAction ? firstInputSelect : secondInputSelect;

    let payload = '0';

    if (fromAsset?.value) {
      const {
        borrowed, borrowedVariable, borrowedStable, interestMode,
      } = usedAssets[fromAsset.value];
      const assetBalance = await getAssetBalance(fromAsset.value, getState().general.account);

      if (interestMode === 'both') {
        payload = fromAsset.additionalLabel === 'Stable'
          ? (new Dec(borrowedStable).gt(assetBalance) ? assetBalance : borrowedStable)
          : (new Dec(borrowedVariable).gt(assetBalance) ? assetBalance : borrowedVariable);
      } else {
        payload = new Dec(borrowed).gt(assetBalance) ? assetBalance : borrowed;
      }
    }

    dispatch({ type: aaveManageTypes.AAVE_GET_MAX_VALUE_SUCCESS, forAction, payload });
  } catch (err) {
    dispatch({ type: aaveManageTypes.AAVE_GET_MAX_VALUE_FAILURE, forAction, payload: err.message });
    captureException(err);
  }
};
