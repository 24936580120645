import React, { useEffect, useMemo, useState } from 'react';
import Dec from 'decimal.js';
import t from 'translate';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { repayAction, getRepayModalData, resetRepayModal } from '../../../../actions/reflexerActions/reflexerManageActions';
import { getDashboardInputs } from '../../../../actions/dashboardActions';

import { ReflexerSlippageLimitForm as SlippageLimitForm } from '../../BoostRepayCommon/SlippageLimitForm/SlippageLimitForm';
import TradeSizeImpactWarning from '../../../TradeSizeImpactWarning/TradeSizeImpactWarning';
import TooltipWrapper from '../../../TooltipWrapper/TooltipWrapper';
import ModalHeader from '../../ModalHeader';
import ModalBody from '../../ModalBody';
import Loader from '../../../Loader/Loader';
import ErrorBox from '../../../Decorative/ErrorBox/ErrorBox';
import InfoIcon from '../../../Decorative/InfoIcon';
import ActionInfo from '../../BoostRepayCommon/ActionInfo';
import AdvancedOptions from '../../BoostRepayCommon/AdvancedOptions/AdvancedOptions';
import AdditionalInfo from '../../../AdditionalInfo/AdditionalInfo';

const ReflexerRepayModal = ({
  getRepayModalData, resetRepayModal, closeModal, repayAmount, repayAction, gettingRepayModalData,
  tradeSizeImpact, gettingRepayModalDataError, repaying, repayExchangeRate, afterCdp, paybackAsset, withdrawAsset,
  repayingError, useFl, flProtocol, exchangeSource, flFee, additionalAction, contextAction, getDashboardInputs,
}) => {
  const {
    firstInput: inputAmount, secondAction, secondInput,
  } = useMemo(() => getDashboardInputs(contextAction, additionalAction), []);

  useEffect(() => {
    getRepayModalData(inputAmount);
    return resetRepayModal;
  }, []);
  const [advanced, setAdvanced] = useState(false);

  const debtDai = afterCdp === null ? false : afterCdp.debtDai;

  const shouldFlip = new Dec(repayExchangeRate).lt(new Dec(1).div(repayExchangeRate).toString());
  const exchangeLabel = shouldFlip
    ? `${withdrawAsset}/${paybackAsset}`
    : `${paybackAsset}/${withdrawAsset}`;
  const correctedRepayExchangeRate = shouldFlip
    ? new Dec(1).div(repayExchangeRate).toString()
    : repayExchangeRate;

  return (
    <div className="boost-repay-modal">
      <ModalHeader closeModal={closeModal} />

      <ModalBody>
        <div className="new-modal-top-wrapper">
          <h1>
            <TooltipWrapper title={t('maker.repay_modal_description', { '%daiLabel': paybackAsset, '%asset': withdrawAsset, CDP: 'Safe' })}>
              <InfoIcon /> {t('common.repay')}
            </TooltipWrapper>
          </h1>
        </div>

        <div className="new-modal-content-wrapper">

          {gettingRepayModalData && (<div className="container"><div className="loading-wrapper"><Loader /></div></div>)}

          {!gettingRepayModalData && gettingRepayModalDataError && (<ErrorBox>{gettingRepayModalDataError}</ErrorBox>)}

          {debtDai === 0 && (<div className="all-debt-repaid">{t('maker.all_debt_repay')}</div>)}

          {!gettingRepayModalData && !gettingRepayModalDataError && (
            <>
              <ActionInfo
                isRepay
                firstAsset={withdrawAsset}
                firstAmount={inputAmount}
                secondAsset={paybackAsset}
                secondAmount={repayAmount}
                exchangeRate={correctedRepayExchangeRate}
                exchangeLabel={exchangeLabel}
                additionalAction={secondAction}
                additionalInput={secondInput}
                additionalAsset={secondAction?.value === 'withdraw' ? 'ETH' : 'RAI'}
              />

              <div className="advanced__wrapper container">
                <TradeSizeImpactWarning tradeSizeImpact={tradeSizeImpact} />
                <AdvancedOptions
                  isRepay
                  borrowAsset={withdrawAsset}
                  supplyAsset={paybackAsset}
                  advanced={advanced}
                  setAdvanced={setAdvanced}
                  exchangeRate={repayExchangeRate}
                  SlippageLimitForm={SlippageLimitForm}
                />
                <AdditionalInfo
                  flashloanSource={useFl ? flProtocol : ''}
                  flashloanFee={flFee}
                  exchangeSource={exchangeSource}
                  additionalAsset={secondAction?.value === 'withdraw' ? 'ETH' : 'RAI'}
                  asset1={withdrawAsset}
                  asset2={paybackAsset}
                />
                {repayingError && <ErrorBox marginTop>{repayingError}</ErrorBox>}
              </div>
            </>
          )}

        </div>
      </ModalBody>

      <div className="modal-controls">
        <button
          form="reflexer-repay-modal-form"
          type="button"
          disabled={repaying || gettingRepayModalData}
          onClick={() => repayAction(contextAction, additionalAction, closeModal)}
          className="button green"
        >
          {repaying ? t('common.repaying') : t('common.repay')}
        </button>
      </div>
    </div>
  );
};

ReflexerRepayModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  repayAmount: PropTypes.string.isRequired,
  repayExchangeRate: PropTypes.string.isRequired,
  tradeSizeImpact: PropTypes.string.isRequired,
  getRepayModalData: PropTypes.func.isRequired,
  repayAction: PropTypes.func.isRequired,
  resetRepayModal: PropTypes.func.isRequired,
  gettingRepayModalData: PropTypes.bool.isRequired,
  gettingRepayModalDataError: PropTypes.string.isRequired,
  repaying: PropTypes.bool.isRequired,
  repayingError: PropTypes.string.isRequired,
  afterCdp: PropTypes.object.isRequired,
  withdrawAsset: PropTypes.string.isRequired,
  paybackAsset: PropTypes.string.isRequired,
  useFl: PropTypes.bool.isRequired,
  flProtocol: PropTypes.string.isRequired,
  flFee: PropTypes.string.isRequired,
  exchangeSource: PropTypes.string.isRequired,
  getDashboardInputs: PropTypes.func.isRequired,
  additionalAction: PropTypes.object,
  contextAction: PropTypes.object,
};

const mapStateToProps = ({ reflexerManage, reflexer }) => ({
  cdp: reflexer.cdp,
  tradeSizeImpact: reflexerManage.tradeSizeImpact,
  gettingRepayModalData: reflexerManage.gettingRepayModalData,
  gettingRepayModalDataError: reflexerManage.gettingRepayModalDataError,
  repaying: reflexerManage.executing.repay || (reflexerManage.executing[reflexerManage.selectedAction?.value] && reflexerManage.selectedAdditionalActions[reflexerManage.selectedAction?.value]?.value === 'repay'),
  repayingError: reflexerManage.executingErrors.repay,
  repayAmount: reflexerManage.repayAmount,
  repayExchangeRate: reflexerManage.repayExchangeRate,
  afterCdp: reflexerManage.afterCdp,
  paybackAsset: reflexer.safes[reflexer.selectedSafeId].debtAsset,
  withdrawAsset: reflexer.safes[reflexer.selectedSafeId].asset,
  useFl: reflexerManage.useFl,
  flProtocol: reflexerManage.flProtocol,
  flFee: reflexerManage.flFee,
  exchangeSource: reflexerManage.exchangeSource,
});

const mapDispatchToProps = {
  getRepayModalData, repayAction, resetRepayModal, getDashboardInputs,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReflexerRepayModal);
