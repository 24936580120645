import {
  nexusMutualContract,
  nexusMutualAddress,
  comptrollerAddress,
  mcdVatAddress,
} from './contractRegistryService';
import { aggregate } from './ethService';

export const getAddressNexusInsurances = async (address) => {
  if (!nexusMutualAddress) return [];
  const contract = nexusMutualContract();
  const ctInfo = {
    [comptrollerAddress.toLowerCase()]: 'compound',
    [mcdVatAddress.toLowerCase()]: 'maker',
    ['0xc1D2819CE78f3E15Ee69c6738eB1B400A26e632A'.toLowerCase()]: 'aave', // coudn't find this address in docs but it's what nexus uses
  };
  const currInfo = {
    '0x44414900': 'DAI',
    '0x45544800': 'ETH',
  };
  const insuranceIDs = await contract.methods.getAllCoversOfUser(address).call();

  const calls = insuranceIDs.map(id => ([
    {
      target: nexusMutualAddress,
      call: ['getCoverDetailsByCoverID1(uint256)(uint256,address,address,bytes4,uint256,uint256)', id],
      returns: [
        [`${id}cid`, val => parseInt(val, 10)],
        [`${id}memberAddress`, val => val],
        [`${id}scAddress`, val => val],
        [`${id}currencyCode`, val => val],
        [`${id}sumAssured`, val => val],
        [`${id}premiumNXM`, val => val],
      ],
    },
    {
      target: nexusMutualAddress,
      call: ['getCoverDetailsByCoverID2(uint256)(uint256,uint8,uint256,uint16,uint256)', id],
      returns: [
        [`${id}cid2`, val => parseInt(val, 10)],
        [`${id}status`, val => val],
        [`${id}sumAssured2`, val => val],
        [`${id}coverPeriod`, val => val],
        [`${id}validUntil`, val => val],
      ],
    },
  ]
  ),
  ).flat();

  const multiRes = await aggregate(calls);

  const { results: { transformed } } = multiRes;

  const res = insuranceIDs.map(id => {
    if (!ctInfo[transformed[`${id}scAddress`].toLowerCase()]) return {};
    return {
      insuring: ctInfo[transformed[`${id}scAddress`].toLowerCase()],
      contract: transformed[`${id}scAddress`],
      currency: currInfo[transformed[`${id}currencyCode`]],
      sumAssured: transformed[`${id}sumAssured`].toString(),
      coverPeriod: transformed[`${id}coverPeriod`].toString(),
      validUntil: transformed[`${id}validUntil`].toString(),
    };
  });

  return res.filter(insurance => (insurance.validUntil * 1000) > Date.now());
};
