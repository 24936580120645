import { assetAmountInWei, ilkToAsset, MAXUINT } from '@defisaver/tokens';
import Dec from 'decimal.js';
import dfs from '@defisaver/sdk';
import {
  numberWithCommas, requireAddress, stringToBytes,
} from '../utils';
import { callViaProxy } from '../contractCallService';
import { GebSafeManagerAddress, GebTaxCollectorAddress } from '../contractRegistryService';
import { getCollateralInfo, getRaiIlkInfo } from './reflexerService';

export const addCollateral = (getState, inputAmount, sendTxFunc) => {
  const {
    general: { account, accountType },
    maker: { proxyAddress },
    reflexer: { safes, selectedSafeId },
  } = getState();
  const safe = safes[selectedSafeId];

  const collateralAmount = assetAmountInWei(inputAmount, safe.asset);
  let funcName = 'lockETH';
  let funcParams = [GebSafeManagerAddress, getRaiIlkInfo(safe.collType).join, safe.id];
  let value = collateralAmount;
  if (safe.asset !== 'ETH') {
    funcName = 'lockTokenCollateral';
    funcParams = [GebSafeManagerAddress, getRaiIlkInfo(safe.collType).join, safe.id, collateralAmount, true];
    value = '0';
  }

  return callViaProxy(accountType, sendTxFunc, proxyAddress, account, 'GebProxyActions', funcName, funcParams, value);
};

export const withdraw = (getState, inputAmount, sendTxFunc) => {
  const {
    general: { account, accountType },
    maker: { proxyAddress },
    reflexer: { safes, selectedSafeId },
  } = getState();
  const safe = safes[selectedSafeId];

  const funcName = (safe.asset === 'ETH') ? 'freeETH' : 'freeTokenCollateral';
  const funcParams = [GebSafeManagerAddress, getRaiIlkInfo(safe.collType).join, safe.id, assetAmountInWei(inputAmount, safe.asset)];

  return callViaProxy(accountType, sendTxFunc, proxyAddress, account, 'GebProxyActions', funcName, funcParams, '0');
};

export const payback = (getState, inputAmount, sendTxFunc) => {
  const {
    general: { account, accountType },
    maker: { proxyAddress },
    reflexer: { safes, selectedSafeId },
  } = getState();
  const safe = safes[selectedSafeId];

  let funcName = 'repayDebt';
  let funcParams = [GebSafeManagerAddress, getRaiIlkInfo('RAI').join, safe.id, assetAmountInWei(inputAmount, 'RAI')];
  if (inputAmount === safe.debtInAsset) {
    funcName = 'repayAllDebt';
    funcParams = [GebSafeManagerAddress, getRaiIlkInfo('RAI').join, safe.id];
  }

  return callViaProxy(accountType, sendTxFunc, proxyAddress, account, 'GebProxyActions', funcName, funcParams, '0');
};

export const generate = async (getState, inputAmount, sendTxFunc) => {
  const {
    general: { account, accountType },
    maker: { proxyAddress },
    reflexer: { safes, selectedSafeId },
  } = getState();
  const safe = safes[selectedSafeId];

  const { globalDebtCurrent, globalDebtCeiling } = await getCollateralInfo(stringToBytes(safe.collType));
  const leftToGenerate = new Dec(globalDebtCeiling).minus(globalDebtCurrent);

  if (leftToGenerate.lte(inputAmount)) {
    throw new Error(`Debt ceiling reached. The maximum amount available for generating is ${numberWithCommas(leftToGenerate.floor().toString())} RAI`);
  }

  const funcName = 'generateDebt';
  const funcParams = [GebSafeManagerAddress, GebTaxCollectorAddress, getRaiIlkInfo('RAI').join, safe.id, assetAmountInWei(inputAmount, 'RAI')];

  return callViaProxy(accountType, sendTxFunc, proxyAddress, account, 'GebProxyActions', funcName, funcParams, '0');
};

export const createSafe = async (account, accountType, proxyAddress, sendTxFunc, ilk, _collAmount, _debtAmount) => {
  const { globalDebtCurrent, globalDebtCeiling } = await getCollateralInfo(ilk);
  const leftToGenerate = Dec(globalDebtCeiling).minus(globalDebtCurrent).toString();

  if (new Dec(leftToGenerate).lte(_debtAmount)) {
    throw new Error(`Global ceiling hit - the maximum RAI you can generate is ${leftToGenerate}`);
  }

  const asset = ilkToAsset(ilk);
  const raiAmount = assetAmountInWei(_debtAmount.toString(), 'RAI');
  const collAmount = assetAmountInWei(_collAmount.toString(), asset);

  requireAddress(proxyAddress);

  let value = '0';
  // const extraGas = 0;
  let funcParams = [];
  let funcName = '';

  if (asset === 'ETH') {
    funcName = 'openLockETHAndGenerateDebt';
    funcParams = [GebSafeManagerAddress, GebTaxCollectorAddress, getRaiIlkInfo(ilk).join, getRaiIlkInfo('RAI').join, ilk, raiAmount];
    value = collAmount;
  } else {
    funcName = 'openLockTokenCollateralAndGenerateDebt';
    funcParams = [GebSafeManagerAddress, GebTaxCollectorAddress, getRaiIlkInfo(ilk).join, getRaiIlkInfo('RAI').join, ilk, collAmount, raiAmount, true];
  }

  return callViaProxy(accountType, sendTxFunc, proxyAddress, account, 'GebProxyActions', funcName, funcParams, value);
};

export const checkAvailableDebt = async (safe, inputAmount) => {
  const { globalDebtCurrent, globalDebtCeiling } = await getCollateralInfo(stringToBytes(safe.collType));
  const leftToGenerate = new Dec(globalDebtCeiling).minus(globalDebtCurrent);

  if (leftToGenerate.lte(inputAmount)) {
    throw new Error(`Debt ceiling reached. The maximum amount available for generating is ${numberWithCommas(Dec.floor(leftToGenerate).toString())} RAI`);
  }
};

export const getAction = (action, safe, inputAmount, account, proxyAddress, joinAddress, cdpAsset) => {
  let instantiatedAction;
  switch (action) {
    case 'collateral': {
      if (cdpAsset === 'ETH') {
        instantiatedAction = [
          new dfs.actions.basic.WrapEthAction(inputAmount),
          new dfs.actions.reflexer.ReflexerSupplyAction(safe.id, inputAmount, joinAddress, proxyAddress),
        ];
      } else {
        instantiatedAction = [new dfs.actions.reflexer.ReflexerSupplyAction(safe.id, inputAmount, joinAddress, account)];
      }
      break;
    }
    case 'generate': {
      instantiatedAction = [new dfs.actions.reflexer.ReflexerGenerateAction(safe.id, inputAmount, account)];
      break;
    }
    case 'withdraw': {
      if (cdpAsset === 'ETH') {
        instantiatedAction = [
          new dfs.actions.reflexer.ReflexerWithdrawAction(safe.id, inputAmount, joinAddress, proxyAddress),
          new dfs.actions.basic.UnwrapEthAction(inputAmount, account),
        ];
      } else {
        instantiatedAction = [new dfs.actions.reflexer.ReflexerWithdrawAction(safe.id, inputAmount, joinAddress, account)];
      }
      break;
    }
    case 'payback': {
      const amount = assetAmountInWei(safe.debtInAsset, safe.debtAsset) === inputAmount
        ? MAXUINT
        : inputAmount;
      instantiatedAction = [new dfs.actions.reflexer.ReflexerPaybackAction(safe.id, amount, account)];
      break;
    }
    default:
      throw new Error('Unknown action');
  }
  return instantiatedAction;
};

export const getReflexerRecipe = (primaryAction, primaryInput, secondaryAction, secondaryInput, safe, account, proxyAddress) => {
  const recipeActions =
    [{ action: primaryAction, input: primaryInput }, { action: secondaryAction, input: secondaryInput }]
      .filter(a => a.action && a.action !== 'send')
      .map(action => getAction(action.action, safe, action.input, account, proxyAddress, getRaiIlkInfo(safe.collType).join, safe.asset))
      .flat();
  const name = 'recReflexerDashAction';
  return new dfs.Recipe(name, recipeActions);
};
