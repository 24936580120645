import {
  GET_SAVINGS_PROJECT_DATA_REQUEST,
  GET_SAVINGS_PROJECT_DATA_SUCCESS,
  GET_SAVINGS_PROJECT_DATA_FAILURE,

  GET_SAVINGS_ADDITIONAL_DATA_REQUEST,
  GET_SAVINGS_ADDITIONAL_DATA_SUCCESS,
  GET_SAVINGS_ADDITIONAL_DATA_FAILURE,
  GET_SAVINGS_MANAGE_AFTER_VALUE_REQUEST,
  GET_SAVINGS_MANAGE_AFTER_VALUE_SUCCESS,
  GET_SAVINGS_MANAGE_AFTER_VALUE_FAILURE,
  GET_SAVINGS_MANAGE_EXECUTING_REQUEST,
  GET_SAVINGS_MANAGE_EXECUTING_SUCCESS,
  GET_SAVINGS_MANAGE_EXECUTING_FAILURE,
  GET_SAVINGS_MANAGE_PROJECTS_INFO_REQUEST,
  GET_SAVINGS_MANAGE_PROJECTS_INFO_SUCCESS,
  GET_SAVINGS_MANAGE_PROJECTS_INFO_FAILURE,
  GET_SAVINGS_MANAGE_MAX_VALUE_REQUEST, GET_SAVINGS_MANAGE_MAX_VALUE_SUCCESS, GET_SAVINGS_MANAGE_MAX_VALUE_FAILURE,
} from '../../actionTypes/savingsActionTypes/savingsManageActionTypes';
import { RESET_BR_SLIPPAGE_VALUES, SET_BR_SLIPPAGE_PERCENT } from '../../actionTypes/modalActionTypes';

const INITIAL_STATE = {
  projectsData: {},
  gettingProjectsData: false,
  additionalDataLoading: false,
  additionalDataError: '',

  afterValue: null,
  afterType: '',
  gettingAfterValue: false,
  gettingAfterValueError: '',

  executing: {},
  executingErrors: {},
  fetchDataAfterExecution: false,

  maxValues: {},
  loadingMaxValues: {},
  maxValuesErrors: {},

  slippagePercent: '0',
  slippageRate: '0',
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_SAVINGS_MANAGE_PROJECTS_INFO_REQUEST:
      return {
        ...state,
        gettingProjectsInfo: true,
        gettingProjectsInfoError: '',
      };

    case GET_SAVINGS_MANAGE_PROJECTS_INFO_SUCCESS:
      return {
        ...state,
        gettingProjectsInfo: false,
        gettingProjectsInfoError: '',
      };

    case GET_SAVINGS_MANAGE_PROJECTS_INFO_FAILURE:
      return {
        ...state,
        gettingProjectsInfo: false,
        gettingProjectsInfoError: payload,
      };
    case GET_SAVINGS_PROJECT_DATA_REQUEST:
      return {
        ...state,
        projectsData: {
          ...state.projectsData,
          [action.project]: {
            ...state.projectsData[action.project],
            ...payload,
            initialized: true,
            loading: true,
            error: '',
          },
        },
      };
    case GET_SAVINGS_PROJECT_DATA_FAILURE:
      return {
        ...state,
        projectsData: {
          ...state.projectsData,
          [action.project]: {
            loading: false,
            error: payload,
          },
        },
      };
    case GET_SAVINGS_PROJECT_DATA_SUCCESS:
      return {
        ...state,
        projectsData: {
          ...state.projectsData,
          [action.project]: {
            ...state.projectsData[action.project],
            loading: false,
            ...payload,
          },
        },
      };


    case GET_SAVINGS_ADDITIONAL_DATA_REQUEST:
      return {
        ...state,
        additionalDataLoading: true,
        additionalDataError: false,
      };
    case GET_SAVINGS_ADDITIONAL_DATA_FAILURE:
      return {
        ...state,
        additionalDataLoading: false,
      };
    case GET_SAVINGS_ADDITIONAL_DATA_SUCCESS:
      return {
        ...state,
        additionalDataLoading: false,
        additionalDataError: payload,
        projectsData: {
          ...Object.keys(payload)
            .reduce((all, slug) => ({
              ...all,
              [slug]: {
                ...state.projectsData[slug],
                apys: payload[slug],
              },
            }), {}),
        },
      };


    case GET_SAVINGS_MANAGE_AFTER_VALUE_REQUEST:
      return { ...state, gettingAfterValue: true };

    case GET_SAVINGS_MANAGE_AFTER_VALUE_SUCCESS:
      return {
        ...state,
        gettingAfterValue: false,
        gettingAfterValueError: '',
        ...payload,
      };

    case GET_SAVINGS_MANAGE_AFTER_VALUE_FAILURE:
      return {
        ...state,
        gettingAfterValue: false,
        gettingAfterValueError: payload,
      };
    case GET_SAVINGS_MANAGE_EXECUTING_REQUEST:
      return {
        ...state,
        fetchDataAfterExecution: false,
        executing: {
          ...state.executing,
          [payload.slug]: {
            ...state.executing[payload.slug],
            [payload.action]: true,
          },
        },
        executingErrors: {
          ...state.executingErrors,
          [payload.slug]: {
            ...state.executingErrors[payload.slug],
            [payload.action]: '',
          },
        },
      };
    case GET_SAVINGS_MANAGE_EXECUTING_SUCCESS:
      return {
        ...state,
        fetchDataAfterExecution: true,
        executing: {
          ...state.executing,
          [payload.slug]: {
            ...state.executing[payload.slug],
            [payload.action]: false,
          },
        },
      };
    case GET_SAVINGS_MANAGE_EXECUTING_FAILURE:
      return {
        ...state,
        fetchDataAfterExecution: true,
        executing: {
          ...state.executing,
          [payload.slug]: {
            ...state.executing[payload.slug],
            [payload.action]: false,
          },
        },
        executingErrors: {
          ...state.executingErrors,
          [payload.slug]: {
            ...state.executingErrors[payload.slug],
            [payload.action]: payload.error,
          },
        },
      };
    case GET_SAVINGS_MANAGE_MAX_VALUE_REQUEST:
      return {
        ...state,
        loadingMaxValues: {
          ...state.loadingMaxValues,
          [payload.slug]: {
            ...state.loadingMaxValues[payload.slug],
            [payload.type]: true,
          },
        },
      };
    case GET_SAVINGS_MANAGE_MAX_VALUE_SUCCESS:
      return {
        ...state,
        loadingMaxValues: {
          ...state.loadingMaxValues,
          [payload.slug]: {
            ...state.loadingMaxValues[payload.slug],
            [payload.type]: false,
          },
        },
        maxValues: {
          ...state.maxValues,
          [payload.slug]: {
            ...state.maxValues[payload.slug],
            [payload.type]: payload.value,
          },
        },
      };
    case GET_SAVINGS_MANAGE_MAX_VALUE_FAILURE:
      return {
        ...state,
        loadingMaxValues: {
          ...state.loadingMaxValues,
          [payload.slug]: {
            ...state.loadingMaxValues[payload.slug],
            [payload.type]: false,
          },
        },
        maxValues: {
          ...state.maxValues,
          [payload.slug]: {
            ...state.maxValues[payload.slug],
            [payload.type]: 0,
          },
        },
        maxValuesErrors: {
          ...state.maxValuesErrors,
          [payload.slug]: {
            ...state.maxValuesErrors[payload.slug],
            [payload.type]: payload.err,
          },
        },
      };
    case SET_BR_SLIPPAGE_PERCENT:
      return {
        ...state,
        slippagePercent: payload.slippagePercent,
        slippageRate: payload.slippageRate,
      };
    case RESET_BR_SLIPPAGE_VALUES:
      return {
        ...state,
        slippagePercent: '0',
        slippageRate: '0',
      };
    default:
      return state;
  }
};
