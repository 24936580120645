import Dec from 'decimal.js';
import cloneDeep from 'lodash/cloneDeep';
import { change } from 'redux-form';
import { formName } from '../../components/DashboardActions/dashboardActionUtils';
import * as aaveManageTypes from '../../actionTypes/aaveActionTypes/aaveManageActionTypes';

import { getAggregatedPositionData, getMaxWithdraw } from '../../services/aaveServices/aaveManageService';
import { getMaxBorrowV2 } from '../../services/aaveServices/aaveManageServiceV2';
import { calculateBorrowingAssetLimit, getMaxBoostUsd } from '../../services/moneymarketCommonService';
import { isWalletTypeProxy } from '../../services/utils';
import { getBestExchangePrice } from '../../services/exchangeServiceV3';
import { captureException } from '../../sentry';
import { changeBalance } from '../../services/recipeCreator/recipeActionUtils';
import { getAssetBalance } from '../../services/assetsService';
import { getDashboardSelectAssets } from '../dashboardActions';

const fullPosition = (getAfterValues) => async (input, stateData, balances = {}) => {
  const result = await getAfterValues.apply(this, [input, stateData, balances]);
  const usedAssets = { ...stateData.usedAssets, ...result.afterAssets };
  const payload = {
    balances: result.balances,
    returnValue: result.returnValue,
    afterPosition: {
      usedAssets,
      assetsData: stateData.assetsData,
      afterAssets: result.afterAssets,
      ...getAggregatedPositionData(usedAssets, stateData.assetsData, stateData.assets, stateData.eModeCategory),
    },
  };
  Object.keys(payload.afterPosition.afterAssets).forEach((asset) => {
    payload.afterPosition.usedAssets[asset].limit = calculateBorrowingAssetLimit(payload.afterPosition.usedAssets[asset].borrowedUsd || 0, payload.afterPosition.borrowLimitUsd);
  });
  return payload;
};

export const supplyAfterValues = fullPosition(
  async (
    { amount, firstAsset, from = 'wallet' },
    {
      usedAssets, assets, walletType, account, proxyAddress, assetsAfter,
      hasCollateral, isInIsolationMode, assetsData, selectedMarket,
    },
    _balances = {},
  ) => {
    const balances = cloneDeep(_balances);
    const { aavePrice } = assets[firstAsset];
    const amountUsd = new Dec(amount || '0').times(aavePrice).toString();

    // If the asset is not currently supplied
    const assetToChange = assetsAfter?.[firstAsset] || usedAssets[firstAsset] || {
      supplied: '0', suppliedUsd: '0', symbol: firstAsset, isSupplied: true,
    };

    const afterAssets = {
      ...assetsAfter,
      [firstAsset]: {
        ...assetToChange,
        isSupplied: true,
        supplied: new Dec(assetToChange.supplied || '0').plus(amount || '0').toString(),
        suppliedUsd: new Dec(assetToChange.suppliedUsd || '0').plus(amountUsd).toString(),
      },
    };

    await changeBalance(balances, from, firstAsset.replace(/^ETH/, 'WETH'), new Dec(amount || '0').times(-1), from === 'wallet' ? account : proxyAddress);

    // SmartWallet supply automatically enables supplied asset as collateral
    if (isWalletTypeProxy(walletType)) afterAssets[firstAsset].collateral = true;

    if (selectedMarket.value === 'v3default') {
      if (isInIsolationMode && !assetsData[firstAsset].isIsolated) {
        afterAssets[firstAsset].collateral = false;
      }
      if (isInIsolationMode && assetsData[firstAsset].isIsolated && !usedAssets[firstAsset].collateral) {
        afterAssets[firstAsset].collateral = false;
      }
      if (hasCollateral && !isInIsolationMode && assetsData[firstAsset].isIsolated) {
        afterAssets[firstAsset].collateral = false;
      }

      if (assetsData[firstAsset].isSiloed && !usedAssets[firstAsset]?.isSupplied && afterAssets[firstAsset].collateral) {
        afterAssets[firstAsset].collateral = false;
      }
    }

    return {
      afterAssets,
      balances,
      returnValue: amount || '0',
    };
  });

export const withdrawAfterValues = fullPosition(
  async (
    {
      amount, firstAsset, secondAsset, to = 'wallet',
    },
    {
      usedAssets, assets, account, proxyAddress, assetsAfter,
    },
    _balances = {},
  ) => {
    const { aavePrice } = assets[firstAsset];
    const balances = cloneDeep(_balances);
    const amountUsd = new Dec(amount || '0').times(aavePrice).toString();

    const assetToChange = usedAssets?.[firstAsset]?.isSupplied ? assetsAfter?.[firstAsset] || usedAssets[firstAsset] : {
      supplied: '0', suppliedUsd: '0', symbol: firstAsset, isSupplied: true,
    };

    const afterAssets = {
      ...assetsAfter,
      [firstAsset]: {
        ...assetToChange,
        supplied: new Dec(assetToChange.supplied).minus(amount || '0').toString(),
        suppliedUsd: new Dec(assetToChange.suppliedUsd).minus(amountUsd).toString(),
      },
    };

    await changeBalance(balances, to, firstAsset.replace(/^ETH/, 'WETH'), amount || '0', to === 'wallet' ? account : proxyAddress);

    return {
      afterAssets,
      balances,
      returnValue: amount || '0',
    };
  });

export const borrowAfterValues = fullPosition(
  async (
    {
      amount, firstAsset, secondAsset, to = 'wallet',
    },
    {
      usedAssets, assets, account, proxyAddress, assetsAfter,
    },
    _balances = {},
  ) => {
    const { aavePrice } = assets[firstAsset];
    const balances = cloneDeep(_balances);
    const amountUsd = new Dec(amount || '0').times(aavePrice).toString();

    const assetToChange = assetsAfter?.[firstAsset] || usedAssets[firstAsset] || {
      borrowed: '0', borrowedUsd: '0', limit: '0', symbol: firstAsset, isBorrowed: true,
    };

    const afterAssets = {
      ...assetsAfter,
      [firstAsset]: {
        ...assetToChange,
        isBorrowed: true,
        borrowed: new Dec(assetToChange.borrowed || '0').add(amount || '0').toString(),
        borrowedUsd: new Dec(assetToChange.borrowedUsd || '0').add(amountUsd).toString(),
      },
    };

    await changeBalance(balances, to, firstAsset.replace(/^ETH/, 'WETH'), amount, to === 'wallet' ? account : proxyAddress);

    return {
      afterAssets,
      balances,
      returnValue: amount || '0',
    };
  });

export const paybackAfterValues = fullPosition(
  async (
    {
      amount, firstAsset, secondAsset, from = 'wallet',
    },
    {
      usedAssets, assets, account, proxyAddress, assetsAfter,
    },
    _balances = {},
  ) => {
    const { aavePrice } = assets[firstAsset];
    const balances = cloneDeep(_balances);
    const amountUsd = new Dec(amount || '0').times(aavePrice).toString();

    const assetToChange = usedAssets?.[firstAsset]?.isBorrowed ? assetsAfter?.[firstAsset] || usedAssets[firstAsset] : {
      borrowed: '0', borrowedUsd: '0', limit: '0', symbol: firstAsset, isBorrowed: true,
    };

    const afterAssets = {
      ...assetsAfter,
      [firstAsset]: {
        ...assetToChange,
        borrowed: Dec.max(0, new Dec(assetToChange.borrowed).minus(amount || '0')).toString(),
        borrowedUsd: Dec.max(0, new Dec(assetToChange.borrowedUsd).minus(amountUsd)).toString(),
      },
    };

    await changeBalance(balances, from, firstAsset.replace(/^ETH/, 'WETH'), new Dec(amount || '0').times(-1), from === 'wallet' ? account : proxyAddress);

    return {
      afterAssets,
      balances,
      returnValue: amount || '0',
    };
  });

export const repayAfterValues = fullPosition(
  async (
    { amount: collDecrease, firstAsset, secondAsset },
    {
      usedAssets, assets, proxyAddress, account, assetsAfter,
    },
    balances = {},
  ) => {
    const { price: rate } = await getBestExchangePrice(collDecrease, firstAsset, secondAsset, proxyAddress);
    const debtDecrease = new Dec(collDecrease).times(rate).toString();
    const debtDecreaseUsd = new Dec(debtDecrease).times(assets[secondAsset].aavePrice);
    const collDecreaseUsd = new Dec(collDecrease).times(assets[firstAsset].aavePrice);

    const assetToChange = assetsAfter?.[firstAsset] || usedAssets[firstAsset] || {
      supplied: '0', suppliedUsd: '0', symbol: firstAsset, isSupplied: true,
    };

    const afterAssets = {
      ...assetsAfter,
      [firstAsset]: {
        ...assetToChange,
        supplied: new Dec(assetToChange.supplied).minus(collDecrease).toString(),
        suppliedUsd: new Dec(assetToChange.suppliedUsd).minus(collDecreaseUsd).toString(),
        // SmartWallet supply automatically enables supplied asset as collateral
        collateral: true,
      },
    };

    const secondAssetToChange = afterAssets[secondAsset] || usedAssets[secondAsset] || {
      borrowed: '0', borrowedUsd: '0', limit: '0', symbol: secondAsset, isBorrowed: true,
    };

    afterAssets[secondAsset] = {
      ...secondAssetToChange,
      borrowed: new Dec(secondAssetToChange.borrowed).minus(debtDecrease).gt(0) ? new Dec(secondAssetToChange.borrowed).minus(debtDecrease).toString() : '0',
      borrowedUsd: new Dec(secondAssetToChange.borrowedUsd).minus(debtDecreaseUsd).gt(0) ? new Dec(secondAssetToChange.borrowedUsd).minus(debtDecreaseUsd).toString() : '0',
    };

    return {
      afterAssets,
      balances,
      returnValue: debtDecrease,
    };
  });

export const boostAfterValues = fullPosition(
  async (
    { amount: debtIncrease, firstAsset, secondAsset },
    {
      usedAssets, assets, proxyAddress, assetsAfter, assetsData,
    },
    balances = {},
  ) => {
    const { price: rate } = await getBestExchangePrice(debtIncrease, firstAsset, secondAsset, proxyAddress);
    const collIncrease = new Dec(debtIncrease).times(rate).toString();
    const collIncreaseUsd = new Dec(collIncrease).times(assets[secondAsset].aavePrice);
    const debtIncreaseUsd = new Dec(debtIncrease).times(assets[firstAsset].aavePrice);

    const assetToChange = assetsAfter?.[firstAsset] || usedAssets[firstAsset] || {
      borrowed: '0', borrowedUsd: '0', symbol: firstAsset, isBorrowed: true,
    };

    const afterAssets = {
      ...assetsAfter,
      [firstAsset]: {
        ...assetToChange,
        isBorrowed: true,
        borrowed: new Dec(assetToChange.borrowed || '0').add(debtIncrease).toString(),
        borrowedUsd: new Dec(assetToChange.borrowedUsd || '0').add(debtIncreaseUsd).toString(),
      },
    };

    // If the asset is not currently supplied
    const secondAssetToChange = afterAssets[secondAsset] || usedAssets[secondAsset] || {
      supplied: '0', suppliedUsd: '0', symbol: secondAsset, isSupplied: true,
    };

    afterAssets[secondAsset] = {
      ...secondAssetToChange,
      isSupplied: true,
      supplied: new Dec(secondAssetToChange.supplied || '0').add(collIncrease).toString(),
      suppliedUsd: new Dec(secondAssetToChange.suppliedUsd || '0').add(collIncreaseUsd).toString(),
      // SmartWallet supply automatically enables supplied asset as collateral
      collateral: true,
    };

    return {
      afterAssets,
      balances,
      returnValue: collIncrease,
    };
  });

const formatGetterData = (_getterParams, data) => [
  {
    amount: _getterParams[0].amount,
    firstAsset: _getterParams[0].firstAsset,
    secondAsset: _getterParams[0].secondAsset,
    secondActionFirstAsset: _getterParams[0].secondActionFirstAsset,
    secondActionSecondAsset: _getterParams[0].secondActionSecondAsset,
  },
  {
    account: _getterParams[1].account,
    proxyAddress: _getterParams[1].proxyAddress,
    usedAssets: data.usedAssets,
    assetsAfter: data.afterAssets,
    assets: data.assets,
    assetsData: data.assetsData,
    targetRatio: _getterParams[1].targetRatio,
    walletType: data.walletType,
    selectedMarket: _getterParams[1].selectedMarket,
    hasCollateral: _getterParams[1].hasCollateral,
    isInIsolationMode: _getterParams[1].isInIsolationMode,
    eModeCategory: _getterParams[1].eModeCategory,
  }];

const getAfterValue = async (action, name, inputs, data, _getterParams, dispatch, isSecondary = false) => {
  let afterValues = {};
  const getterParams = cloneDeep(formatGetterData(_getterParams, data));
  const [
    {
      firstAsset, secondAsset, secondActionFirstAsset, secondActionSecondAsset,
    },
    {
      assetsData, usedAssets, assets, account, isInIsolationMode, hasCollateral, eModeCategory,
      selectedMarket,
    },
  ] = getterParams;

  const isV3 = selectedMarket.value === 'v3default';

  switch (action) {
    case 'collateral': {
      afterValues = (await supplyAfterValues(...getterParams)).afterPosition;

      if (!isSecondary || !afterValues.max) afterValues.max = { ...data.max };
      const { borrowedUsd, borrowLimitUsd } = afterValues;
      const { borrowCap, totalBorrow, collateralFactor } = assetsData[secondActionSecondAsset] || { borrowCap: 0, totalBorrow: 0, collateralFactor: 0 };

      const collFactor = isV3
        ? (eModeCategory === 0 || new Dec(assetsData[secondActionSecondAsset]?.eModeCategoryData?.collateralFactor || 0).eq(0))
          ? assetsData[secondActionSecondAsset]?.collateralFactor
          : assetsData[secondActionSecondAsset]?.eModeCategoryData?.collateralFactor
        : collateralFactor;

      const isIsolated =
        isInIsolationMode || Object.values(afterValues.afterAssets).find(a => assetsData[a.symbol].isIsolated && a.isSupplied);

      const _hasCollateral = hasCollateral || Object.values(afterValues.afterAssets).find(a => a.collateral);

      let canBorrow = true;
      if (isV3 && secondActionFirstAsset && isIsolated && !assetsData[secondActionFirstAsset].isolationModeBorrowingEnabled) canBorrow = false;
      if (isV3 && eModeCategory !== 0 && assetsData[secondActionFirstAsset].eModeCategory !== eModeCategory) canBorrow = false;

      let canSupply = true;
      if (isV3 && secondActionSecondAsset && isIsolated && secondActionSecondAsset !== isIsolated.symbol) canSupply = false;
      if (isV3 && secondActionSecondAsset && !isIsolated && _hasCollateral && assetsData[secondActionSecondAsset].isIsolated) canSupply = false;

      afterValues.max.boost = canBorrow && canSupply && secondActionFirstAsset && secondActionSecondAsset
        ? new Dec(getMaxBoostUsd(collFactor || 0, borrowLimitUsd, borrowedUsd))
          .div(assets[secondActionFirstAsset]?.aavePrice || 0).toString()
        : '0';

      const leftToBorrow = new Dec(borrowLimitUsd).minus(borrowedUsd).toString();
      const leftToBorrowGlobally = !+borrowCap ? null : new Dec(borrowCap).minus(totalBorrow).toString();
      const borrowAssetPrice = assets[secondActionFirstAsset]?.aavePrice || 0;

      afterValues.max.borrow = canBorrow
        ? getMaxBorrowV2('', secondActionFirstAsset, borrowAssetPrice, 1, leftToBorrow, leftToBorrowGlobally)
        : '0';
      break;
    }
    case 'payback': {
      afterValues = (await paybackAfterValues(...getterParams)).afterPosition;
      if (!isSecondary || !afterValues.max) afterValues.max = { ...data.max };

      const { borrowedUsd, borrowLimitUsd } = afterValues;
      const withdrawAsset = !isSecondary ? secondActionFirstAsset : firstAsset;
      const usedAssetData = afterValues.afterAssets[withdrawAsset] || usedAssets[withdrawAsset];

      afterValues.max.withdraw = usedAssetData
        ? getMaxWithdraw(usedAssetData, assets[withdrawAsset].aavePrice, assetsData[withdrawAsset], borrowedUsd, borrowLimitUsd, 1)
        : '0';
      break;
    }
    case 'repay': {
      if (!(firstAsset && secondAsset)) break;
      afterValues = (await repayAfterValues({ amount: inputs[name], firstAsset, secondAsset }, getterParams[1])).afterPosition;
      if (!isSecondary || !afterValues.max) afterValues.max = { ...data.max };
      if (name.split('-')[0] !== 'repay') break; // Don't calculate max withdraw and borrow when repay is additional action

      if (!isSecondary || !afterValues.max) afterValues.max = {};

      const { borrowedUsd, borrowLimitUsd } = afterValues;

      const asset = !isSecondary ? secondActionFirstAsset : firstAsset;
      const { borrowCap, totalBorrow } = assetsData[asset] || { borrowCap: 0, totalBorrow: 0 };

      const leftToBorrow = new Dec(borrowLimitUsd).minus(borrowedUsd).toString();
      const leftToBorrowGlobally = !+borrowCap ? null : new Dec(borrowCap).minus(totalBorrow).toString();
      const borrowAssetPrice = assets[asset]?.aavePrice || 0;

      const usedAssetData = afterValues.afterAssets[asset] || usedAssets[asset];

      afterValues.max.withdraw = usedAssetData
        ? getMaxWithdraw(usedAssetData, assets[asset]?.aavePrice, assetsData[asset], borrowedUsd, borrowLimitUsd, 1)
        : '0';

      const isIsolated = isInIsolationMode || Object.values(afterValues.afterAssets).find(a => assetsData[a.symbol].isIsolated && a.isSupplied);

      let canBorrow = true;
      if (isV3 && secondActionFirstAsset && isIsolated && !assetsData[secondActionFirstAsset].isolationModeBorrowingEnabled) canBorrow = false;
      if (isV3 && secondActionFirstAsset && eModeCategory !== 0 && assetsData[secondActionFirstAsset].eModeCategory !== eModeCategory) canBorrow = false;

      afterValues.max.borrow = canBorrow ? getMaxBorrowV2('', asset, borrowAssetPrice, 1, leftToBorrow, leftToBorrowGlobally) : '0';
      break;
    }
    case 'withdraw': {
      afterValues = (await withdrawAfterValues(...getterParams)).afterPosition;

      if (!isSecondary || !afterValues.max) afterValues.max = { ...data.max };
      afterValues.max.send = inputs[name];
      afterValues.max.sell = inputs[name];

      let payback = '0';
      const paybackAsset = isSecondary ? secondActionFirstAsset : firstAsset;
      if (paybackAsset) {
        const { borrowed } = afterValues.afterAssets[paybackAsset] || usedAssets[paybackAsset];
        const assetBalance = await getAssetBalance(paybackAsset, account);
        payback = new Dec(borrowed || 0).gt(assetBalance) ? assetBalance : borrowed;
      }
      afterValues.max.payback = payback;
      break;
    }
    case 'borrow': {
      afterValues = (await borrowAfterValues(...getterParams)).afterPosition;

      if (!isSecondary || !afterValues.max) afterValues.max = { ...data.max };

      const _asset = isSecondary ? secondActionFirstAsset : firstAsset;

      const asset = assets[_asset];

      if (asset) {
        afterValues.max.collateral = asset.balance;
      }
      afterValues.max.send = inputs[name];
      afterValues.max.sell = inputs[name];
      break;
    }
    case 'boost': {
      if (!(firstAsset && secondAsset)) break;
      afterValues = (await boostAfterValues({
        amount: inputs[name], firstAsset, secondAsset,
      }, getterParams[1])).afterPosition;
      if (!isSecondary || !afterValues.max) afterValues.max = { ...data.max };

      const asset = !isSecondary ? secondActionFirstAsset : firstAsset;

      afterValues.max.collateral = assets[asset].balance;

      let payback = '0';
      if (asset && usedAssets[asset]) {
        const { borrowed } = afterValues.afterAssets[asset] || usedAssets[asset];
        const assetBalance = await getAssetBalance(asset, account);
        payback = new Dec(borrowed || 0).gt(assetBalance) ? assetBalance : borrowed;
      }
      afterValues.max.payback = payback;
      break;
    }
    default:
      afterValues = data;
      break;
  }

  return cloneDeep(afterValues);
};

/**
 * Calculates the changed asset type values that are shown
 *
 * @param amount {String|Number}
 * @param type {String}
 *
 * @return {Function}
 */
export const setAfterValue = (amount, type) => async (dispatch, getState) => {
  dispatch({ type: aaveManageTypes.GET_AAVE_AFTER_VALUE_REQUEST });

  try {
    const state = getState();
    const {
      general: { walletType, account }, maker: { proxyAddress }, assets, aaveManage, aaveSaver,
    } = state;
    const {
      borrowedUsd, borrowLimitUsd, isSubscribedToAutomation, selectedAction, selectedAdditionalActions, selectedMarket,
    } = aaveManage;

    const { assetsData } = aaveManage[selectedMarket.value];
    const { usedAssets, ratio, eModeCategory } = aaveManage[walletType.value][selectedMarket.value];

    let minRatio = 0;
    let maxRatio = Infinity;
    let boostEnabled = false;
    const { graphData } = aaveSaver;

    if (isSubscribedToAutomation && graphData) {
      minRatio = graphData.minRatio;
      maxRatio = graphData.maxRatio;
      boostEnabled = graphData.boostEnabled;
    }

    const contextAction = selectedAction?.value;
    const additionalAction = selectedAdditionalActions[contextAction]?.value;
    const flipActions = !selectedAction?.goesFirst;
    const firstAction = flipActions ? additionalAction : contextAction;
    const secondAction = flipActions ? contextAction : additionalAction;

    const inputs = {
      ...getState().form[formName]?.values,
      // form.dashboardActions.values[type] propagation hasn't reached the reducer state yet,
      // so we replace it with the value passed via onChange
      [`${contextAction}-${type}`]: amount || amount === 0 ? amount : getState().form[formName]?.values[`${contextAction}-${type}`], // This is needed for the case when changing select assets
    };
    const firstLabel = `${contextAction}-${firstAction}`;
    const secondLabel = `${contextAction}-${secondAction}`;

    const [_firstActionFirstAsset, _firstActionSecondAsset, _secondActionFirstAsset, _secondActionSecondAsset] = getDashboardSelectAssets(getState(), 'aave');

    const firstActionFirstAsset = flipActions ? _secondActionFirstAsset?.value : _firstActionFirstAsset?.value;
    const firstActionSecondAsset = flipActions ? _secondActionSecondAsset?.value : _firstActionSecondAsset?.value;
    const secondActionFirstAsset = flipActions ? _firstActionFirstAsset?.value : _secondActionFirstAsset?.value;
    const secondActionSecondAsset = flipActions ? _firstActionSecondAsset?.value : _secondActionSecondAsset?.value;

    if (type === 'clear' || (!+inputs[firstLabel] && !+inputs[secondLabel])) {
      return dispatch({ type: aaveManageTypes.GET_AAVE_AFTER_VALUE_SUCCESS, payload: { afterValue: null, afterType: '' } });
    }

    const targetRatio = ratio / 100;

    const hasCollateral = selectedMarket.value === 'v3default' && Object.values(usedAssets).find(a => a.collateral);
    const isInIsolationMode = selectedMarket.value === 'v3default' && Object.values(usedAssets).find(a => assetsData[a.symbol].isIsolated && a.collateral);

    const getterParams = [
      { amount },
      {
        usedAssets,
        assetsData,
        assets,
        walletType,
        proxyAddress,
        borrowedUsd,
        borrowLimitUsd,
        account,
        hasCollateral,
        isInIsolationMode,
        selectedMarket,
        eModeCategory,
      },
    ];

    const payload = {
      afterType: selectedAction.value,
      afterValue: { ...getterParams[1], afterType: `${selectedAction.value}-${selectedAdditionalActions[selectedAction.value]?.value}` },
    };

    if (additionalAction === 'repay' && secondAction && inputs[secondLabel]) {
      const label = `${selectedAction.value}-${additionalAction}`;
      getterParams[0].amount = inputs[secondLabel];
      getterParams[0].firstAsset = secondActionFirstAsset;
      getterParams[0].secondAsset = secondActionSecondAsset;
      getterParams[0].secondActionFirstAsset = firstActionFirstAsset;
      getterParams[0].secondActionSecondAsset = firstActionSecondAsset;

      const tmpAfter = { ...payload.afterValue, ...(await getAfterValue(secondAction, secondLabel, inputs, payload.afterValue, getterParams, dispatch, true)) };

      const collFactor = (eModeCategory === 0 || new Dec(assetsData[firstActionFirstAsset]?.eModeCategoryData?.collateralFactor || 0).eq(0))
        ? assetsData[firstActionFirstAsset]?.collateralFactor
        : assetsData[firstActionFirstAsset]?.eModeCategoryData?.collateralFactor;

      const targetAmount = getMaxBoostUsd(collFactor || 0, tmpAfter.borrowLimitUsd, tmpAfter.borrowedUsd, targetRatio, 1);

      inputs[label] = inputs[secondLabel]
        ? new Dec(targetAmount).abs().div(assets[firstActionFirstAsset]?.aavePrice).toString()
        : '0';

      dispatch(change(formName, label, inputs[label]));
    }
    if (firstAction && inputs[firstLabel]) {
      getterParams[0].amount = inputs[firstLabel];
      getterParams[0].firstAsset = firstActionFirstAsset;
      getterParams[0].secondAsset = firstActionSecondAsset;
      getterParams[0].secondActionFirstAsset = secondActionFirstAsset;
      getterParams[0].secondActionSecondAsset = secondActionSecondAsset;
      payload.afterValue = {
        ...payload.afterValue,
        ...(await getAfterValue(firstAction, firstLabel, inputs, payload.afterValue, getterParams, dispatch)),
      };
    }
    if (additionalAction === 'boost') {
      const label = `${selectedAction.value}-${additionalAction}`;
      getterParams[0].amount = inputs[firstLabel];
      getterParams[0].firstAsset = firstActionFirstAsset;
      getterParams[0].secondAsset = firstActionSecondAsset;
      getterParams[0].secondActionFirstAsset = secondActionFirstAsset;
      getterParams[0].secondActionSecondAsset = secondActionSecondAsset;

      const collFactor = (eModeCategory === 0 || new Dec(assetsData[secondActionSecondAsset]?.eModeCategoryData?.collateralFactor || 0).eq(0))
        ? assetsData[secondActionSecondAsset]?.collateralFactor
        : assetsData[secondActionSecondAsset]?.eModeCategoryData?.collateralFactor;

      inputs[label] = inputs[firstLabel]
        ? new Dec(
          getMaxBoostUsd(collFactor || 0, payload.afterValue.borrowLimitUsd, payload.afterValue.borrowedUsd, targetRatio, 1),
        ).div(assets[secondActionFirstAsset]?.aavePrice || 0).toString()
        : '0';

      dispatch(change(formName, label, inputs[label]));
    }
    if (secondAction && inputs[secondLabel]) {
      getterParams[0].amount = inputs[secondLabel];
      getterParams[0].firstAsset = secondActionFirstAsset;
      getterParams[0].secondAsset = secondActionSecondAsset;
      getterParams[0].secondActionFirstAsset = firstActionFirstAsset;
      getterParams[0].secondActionSecondAsset = firstActionSecondAsset;
      payload.afterValue = {
        ...payload.afterValue,
        ...(await getAfterValue(secondAction, secondLabel, inputs, payload.afterValue, getterParams, dispatch, true)),
      };
    }

    if (payload.afterValue && payload.afterValue.ratio && isSubscribedToAutomation) {
      payload.afterValue.ratioTooLow = parseFloat(payload.afterValue.ratio) < minRatio;
      payload.afterValue.ratioTooHigh = boostEnabled && parseFloat(payload.afterValue.ratio) > maxRatio;
    }

    dispatch({ type: aaveManageTypes.GET_AAVE_AFTER_VALUE_SUCCESS, payload });
  } catch (err) {
    dispatch({ type: aaveManageTypes.GET_AAVE_AFTER_VALUE_FAILURE, payload: err.message });
    captureException(err);
  }
};
