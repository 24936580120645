import {
  YEARN_GET_NUM_VAULTS_REQUEST,
  YEARN_GET_NUM_VAULTS_SUCCESS,
  YEARN_GET_NUM_VAULTS_FAILURE,
} from 'actionTypes/yearnActionTypes';

const INITIAL_STATE = {
  numOfVaults: [],
  numOfVaultsForAsset: {},
  gettingNumOfTokens: false,
  gettingNumOfTokensError: '',
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case YEARN_GET_NUM_VAULTS_REQUEST:
      return {
        ...state,
        gettingNumOfTokens: true,
        gettingNumOfTokensError: '',
      };
    case YEARN_GET_NUM_VAULTS_SUCCESS:
      return {
        ...state,
        gettingNumOfTokens: false,
        numOfVaults: [...payload.numOfVaults],
        numOfVaultsForAsset: { ...payload.numOfVaultsForAsset },
      };
    case YEARN_GET_NUM_VAULTS_FAILURE:
      return {
        ...state,
        gettingNumOfTokens: false,
        gettingNumOfTokensError: payload,
      };
    default:
      return state;
  }
};
