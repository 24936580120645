/* eslint-disable */
import React from 'react';

export default function MainnetIcon(props) {
  return (
    <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M3.34754 0L3.27441 0.248549V7.46085L3.34754 7.53385L6.69541 5.55493L3.34754 0Z" fill="#E1E1E1"/>
      <path d="M3.34885 0L0.000976562 5.55493L3.34885 7.53387V4.03324V0Z" fill="white"/>
      <path d="M3.34785 8.16835L3.30664 8.21859V10.7878L3.34785 10.9081L6.69773 6.19043L3.34785 8.16835Z" fill="#B9B9B9"/>
      <path d="M3.34787 10.9081V8.16835L0 6.19043L3.34787 10.9081Z" fill="#E1E1E1"/>
      <path d="M3.34766 7.53379L6.69547 5.5549L3.34766 4.0332V7.53379Z" fill="#B9B9B9"/>
      <path d="M0 5.5549L3.34782 7.53381V4.0332L0 5.5549Z" fill="#D7D7D7"/>
    </svg>

  );
}
