import dfs from '@defisaver/sdk';
import { getAssetInfo } from '@defisaver/tokens';
import { exchangeAssets } from 'constants/assets';
import cloneDeep from 'lodash/cloneDeep';
import Dec from 'decimal.js';
import { Position } from '@uniswap/v3-sdk';
import RecipeAction from '../RecipeAction';
import WithdrawIcon from '../recipeIcons/Withdraw.svg';
import {
  Amount, Deadline, Slippage, Source, Address, NftId,
} from '../../components/Recipes/RecipeCreator/inputTypes';
import { assetAmountInWeiIgnorePointer, changeBalance } from '../../services/recipeCreator/recipeActionUtils';
import { normalizeFunc, setSlippagePercent } from '../../services/exchangeServiceCommon';
import {
  getPercent, getPool, getPoolLiq, getTokenAmountPercent, getTokens, mockPool, mockPosition,
} from '../../services/uniswapV3Services';
import { MAXUINT128 } from '../../constants/general';
import { getWeiAmountForDecimals } from '../../services/utils';

const _exchangeAssets = [...exchangeAssets()];
_exchangeAssets[0] = getAssetInfo('WETH');

export default class UniswapV3WithdrawAction extends RecipeAction {
  static prettyName = 'Withdraw from Uniswap V3';

  static protocol = 'uniswap';

  static protocolPrettyName = 'Uniswap';

  static description = 'Withdraws assets from a Uniswap v3 position.';

  constructor(nftId = '', liquidity = '', source = 'wallet', slippage = '2', deadline = '20') {
    super();
    this.inputs = [
      new NftId('NFT ID', nftId),
      new Amount('Remove liquidity', liquidity),
      new Source('To', source),
      new Slippage('Slippage tolerance', slippage),
      new Deadline('Deadline', deadline),
    ];
    this.output = new Amount('output', 0);
    this.error = '';
  }

  static getIcon() {
    return WithdrawIcon;
  }

  getAsset() {
    return '%';
  }

  setError(e) {
    this.error = e;
  }

  getError() {
    return this.error;
  }

  getExpires() {
    if (this.updatedAt) {
      return this.updatedAt + (2 * 60 * 1000);
    }
    return Infinity;
  }

  async getAfterValues(_balances = {}, returnValues = [], actions = [], _positions = {}, getState) {
    const positions = cloneDeep(_positions);
    const {
      maker: { proxyAddress },
      general: { account },
      uniswapV3: { account: accountNfts, proxy: proxyNfts },
    } = getState();
    const balances = cloneDeep(_balances);
    const nftOptions = [...accountNfts, ...proxyNfts];
    const allUniNfts = [
      ...nftOptions
        .filter(nft => !Object.entries(positions)
          .find(pos => pos[0].substr(0, 9) === 'uniswapV3' && pos[1].id === nft.id.toString())),
      ...Object.keys(positions)
        .filter(id => id.substr(0, 9) === 'uniswapV3' && !nftOptions.find(nft => nft.id.toString() === id.substr(10)))
        .map(id => ({
          ...positions[id],
        })),
    ];
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const uniV3Position = allUniNfts.find(nft => nft.id === args[0]);
    if (!positions[`uniswapV3${args[0]}`]) positions[`uniswapV3${args[0]}`] = { ...uniV3Position, value: uniV3Position.id };
    const percent = getPercent(args[1] || '0');
    const [firstTokenInfo, secondTokenInfo, firstToken, secondToken] = getTokens(positions[`uniswapV3${args[0]}`].tokenFirstInfo, positions[`uniswapV3${args[0]}`].tokenSecondInfo);
    const pool = (await getPoolLiq(firstToken, secondToken, new Dec(positions[`uniswapV3${args[0]}`].fee).div(10000).toString(), positions[`uniswapV3${args[0]}`].liquidity))[0];
    const position = mockPosition(firstToken, secondToken, firstTokenInfo, secondTokenInfo, positions[`uniswapV3${args[0]}`].tickLower, positions[`uniswapV3${args[0]}`].tickUpper, pool, positions[`uniswapV3${args[0]}`].amountInFirstToken, true);

    const firstAmount = args[1] === '100' ? positions[`uniswapV3${args[0]}`].amountInFirstToken :
      getTokenAmountPercent(position, firstToken, percent, true).toExact() || '0';
    const secondAmount = args[1] === '100' ? positions[`uniswapV3${args[0]}`].amountInSecondToken :
      getTokenAmountPercent(position, secondToken, percent, false).toExact() || '0';
    await changeBalance(balances, args[2], positions[`uniswapV3${args[0]}`].tokenFirstInfo.symbol, firstAmount, args[2] === 'wallet' ? account : proxyAddress, positions[`uniswapV3${args[0]}`].tokenFirstInfo.address);
    await changeBalance(balances, args[2], positions[`uniswapV3${args[0]}`].tokenSecondInfo.symbol, secondAmount, args[2] === 'wallet' ? account : proxyAddress, positions[`uniswapV3${args[0]}`].tokenSecondInfo.address);

    positions[`uniswapV3${args[0]}`].amountInFirstToken = new Dec(positions[`uniswapV3${args[0]}`].amountInFirstToken).minus(firstAmount).toString();
    positions[`uniswapV3${args[0]}`].amountInSecondToken = new Dec(positions[`uniswapV3${args[0]}`].amountInSecondToken).minus(secondAmount).toString();


    // TODO: liq pool - liq position
    // positions[`uniswapV3${args[0]}`].liquidity =
    return { returnValue: '0', balances, positions };
  }

  async toDfsAction(getState, recipeActions, returnValues, positions) {
    const {
      general: { account },
      maker: { proxyAddress },
      recipeCreator: { actions },
    } = getState();
    const args = this.mapReturnValuesToArgs(returnValues, actions);

    const uniV3Position = positions[`uniswapV3${args[0]}`];
    const to = args[2] === 'wallet' ? account : proxyAddress;
    const percent = getPercent(args[1] || '0');
    const [firstTokenInfo, secondTokenInfo, firstToken, secondToken] = getTokens(uniV3Position.tokenFirstInfo, uniV3Position.tokenSecondInfo);
    let pool;
    try {
      pool = (await getPool(firstToken, secondToken, new Dec(uniV3Position.fee).div(10000).toString()))[0];
    } catch (e) {
      pool = mockPool(firstToken, secondToken, firstTokenInfo, secondTokenInfo, new Dec(uniV3Position.fee).div(10000).toString(), uniV3Position.currentPrice, uniV3Position.invert, uniV3Position.liquidity);
    }
    const position = new Position({
      pool,
      liquidity: uniV3Position.liquidity.toString(),
      tickLower: uniV3Position.tickLower,
      tickUpper: uniV3Position.tickUpper,
    });
    const firstAmount = getTokenAmountPercent(position, firstToken, percent, true).toSignificant() || '0';
    const secondAmount = getTokenAmountPercent(position, secondToken, percent, false).toSignificant() || '0';
    const minFirstAmount = setSlippagePercent(args[3], firstAmount);
    const minSecondAmount = setSlippagePercent(args[3], secondAmount);
    const minFirstAmountWei = getWeiAmountForDecimals(minFirstAmount, uniV3Position.tokenFirstInfo.decimals);
    const minSecondAmountWei = getWeiAmountForDecimals(minSecondAmount, uniV3Position.tokenSecondInfo.decimals);
    const liquidity = percent.multiply(position.liquidity.toString()).quotient.toString();
    return new dfs.actions.uniswapV3.UniswapV3WithdrawAction(
      this.inputs[0].value,
      liquidity,
      minFirstAmountWei,
      minSecondAmountWei,
      Date.now() + (1000 * 60 * args[4]),
      to,
      MAXUINT128,
      MAXUINT128,
      uniV3Position.owner,
    );
  }

  _getPrettyName(actionCalls, actions) {
    const args = this.mapReturnValuesToArgs(actionCalls.map(a => a.returnValue), actions);
    return 'Withdraw from pool';
  }
}
