import React, { useEffect, useState } from 'react';
import Dec from 'decimal.js';
import t from 'translate';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { repayAction, getRepayModalData, resetRepayModal } from '../../../../actions/liquityActions/liquityManageActions';

import { LiquitySlippageLimitForm as SlippageLimitForm } from '../../BoostRepayCommon/SlippageLimitForm/SlippageLimitForm';
import TooltipWrapper from '../../../TooltipWrapper/TooltipWrapper';
import ModalHeader from '../../ModalHeader';
import ModalBody from '../../ModalBody';
import Loader from '../../../Loader/Loader';
import ErrorBox from '../../../Decorative/ErrorBox/ErrorBox';
import TradeSizeImpactWarning from '../../../TradeSizeImpactWarning/TradeSizeImpactWarning';
import InfoIcon from '../../../Decorative/InfoIcon';
import ActionInfo from '../../BoostRepayCommon/ActionInfo';
import AdvancedOptions from '../../BoostRepayCommon/AdvancedOptions/AdvancedOptions';
import AdditionalInfo from '../../../AdditionalInfo/AdditionalInfo';

const LiquityRepayModal = ({
  getRepayModalData, resetRepayModal, closeModal, repayAmount, repayAction, gettingRepayModalData, inputAmount,
  tradeSizeImpact, gettingRepayModalDataError, repaying, repayExchangeRate, paybackAsset, withdrawAsset, repayingError,
  flProtocol, useFl, exchangeSource, flFee,
}) => {
  useEffect(() => {
    getRepayModalData(inputAmount);
    return resetRepayModal;
  }, []);
  const [advanced, setAdvanced] = useState(false);

  const shouldFlip = new Dec(repayExchangeRate).lt(new Dec(1).div(repayExchangeRate).toString());
  const exchangeLabel = shouldFlip
    ? `${withdrawAsset}/${paybackAsset}`
    : `${paybackAsset}/${withdrawAsset}`;
  const correctedRepayExchangeRate = shouldFlip
    ? new Dec(1).div(repayExchangeRate).toString()
    : repayExchangeRate;

  return (
    <div className="boost-repay-modal">
      <ModalHeader closeModal={closeModal} />

      <ModalBody>
        <div className="new-modal-top-wrapper">
          <h1>
            <TooltipWrapper title={t('maker.repay_modal_description', { '%daiLabel': paybackAsset, '%asset': withdrawAsset, CDP: 'Trove' })}>
              <InfoIcon /> {t('common.repay')}
            </TooltipWrapper>
          </h1>
        </div>

        <div className="new-modal-content-wrapper">

          {gettingRepayModalData && (<div className="container"><div className="loading-wrapper"><Loader /></div></div>)}

          {!gettingRepayModalData && gettingRepayModalDataError && (<ErrorBox>{gettingRepayModalDataError}</ErrorBox>)}

          {!gettingRepayModalData && !gettingRepayModalDataError && (
            <>
              <ActionInfo
                isRepay
                firstAsset={withdrawAsset}
                firstAmount={inputAmount}
                secondAsset={paybackAsset}
                secondAmount={repayAmount}
                exchangeRate={correctedRepayExchangeRate}
                exchangeLabel={exchangeLabel}
              />

              <div className="advanced__wrapper container">
                <TradeSizeImpactWarning tradeSizeImpact={tradeSizeImpact} />
                <AdvancedOptions
                  isRepay
                  borrowAsset={withdrawAsset}
                  supplyAsset={paybackAsset}
                  advanced={advanced}
                  setAdvanced={setAdvanced}
                  exchangeRate={repayExchangeRate}
                  SlippageLimitForm={SlippageLimitForm}
                />
                <AdditionalInfo
                  flashloanSource={useFl ? flProtocol : ''}
                  flashloanFee={flFee}
                  exchangeSource={exchangeSource}
                  asset1={withdrawAsset}
                  asset2={paybackAsset}
                />
                {repayingError && <ErrorBox marginTop>{repayingError}</ErrorBox>}
              </div>
            </>
          )}

        </div>
      </ModalBody>

      <div className="modal-controls">
        <button
          form="liquity-repay-modal-form"
          type="button"
          disabled={repaying || gettingRepayModalData}
          onClick={() => repayAction(inputAmount, closeModal)}
          className="button green"
        >
          {repaying ? t('common.repaying') : t('common.repay')}
        </button>
      </div>
    </div>
  );
};

LiquityRepayModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  repayAmount: PropTypes.string.isRequired,
  inputAmount: PropTypes.string.isRequired,
  repayExchangeRate: PropTypes.string.isRequired,
  tradeSizeImpact: PropTypes.string.isRequired,
  getRepayModalData: PropTypes.func.isRequired,
  repayAction: PropTypes.func.isRequired,
  resetRepayModal: PropTypes.func.isRequired,
  gettingRepayModalData: PropTypes.bool.isRequired,
  gettingRepayModalDataError: PropTypes.string.isRequired,
  repaying: PropTypes.bool.isRequired,
  repayingError: PropTypes.string.isRequired,
  withdrawAsset: PropTypes.string.isRequired,
  paybackAsset: PropTypes.string.isRequired,
  flProtocol: PropTypes.string.isRequired,
  useFl: PropTypes.bool.isRequired,
  exchangeSource: PropTypes.string.isRequired,
  flFee: PropTypes.string.isRequired,
};

const mapStateToProps = ({ liquityManage, liquity }) => ({
  tradeSizeImpact: liquityManage.tradeSizeImpact,
  gettingRepayModalData: liquityManage.gettingRepayModalData,
  gettingRepayModalDataError: liquityManage.gettingRepayModalDataError,
  repaying: liquityManage.repaying,
  repayingError: liquityManage.repayingError,
  repayAmount: liquityManage.repayAmount,
  repayExchangeRate: liquityManage.repayExchangeRate,
  paybackAsset: liquity.proxy.debtAsset,
  withdrawAsset: liquity.proxy.asset,
  flProtocol: liquityManage.flProtocol,
  useFl: liquityManage.useFl,
  flFee: liquityManage.flFee,
  exchangeSource: liquityManage.exchangeSource,
});

const mapDispatchToProps = {
  getRepayModalData, repayAction, resetRepayModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(LiquityRepayModal);
