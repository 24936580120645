import React, { useEffect, useState } from 'react';
import t from 'translate';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formValueSelector, change } from 'redux-form';
import Dec from 'decimal.js';
import { assetAmountInEth } from '@defisaver/tokens';
import AnimateHeight from 'react-animate-height';
import { getGasPrices, resetGasPriceModal } from '../../../actions/generalActions';
import { closeConfirmationDialog } from '../../../actions/txNotificationActions';

import { ACCOUNT_TYPES, protocolIcons } from '../../../constants/general';
import { isLayer2Network } from '../../../services/ethService';
import { formatNumber } from '../../../services/utils';

import TooltipWrapper from '../../TooltipWrapper/TooltipWrapper';
import WarningBox from '../../Decorative/WarningBox/WarningBox';
import Loader from '../../Loader/Loader';
import ErrorBox from '../../Decorative/ErrorBox/ErrorBox';
import InfoIcon from '../../Decorative/InfoIcon';
import ArrowCaret from '../../Decorative/ArrowCaret';
import GasPriceModalForm from './GasPriceModalForm/GasPriceModalForm';

import './TxConfirm.scss';
import { OVMGasPriceOracleContract } from '../../../services/contractRegistryService';
import { getOptimismLayerOneGas } from '../../../services/layer2Service';

const GAS_PRICE_TABS = [
  { label: t('misc.cheap'), value: 'cheap', wait: '<10m' },
  { label: t('misc.regular'), value: 'regular', wait: '<3m' },
  { label: t('misc.fast'), value: 'fast', wait: '<1m' },
];

const TxConfirm = ({
  closeConfirmationDialog, gasPrices, txData, gettingGasPrices, gasPriceModal, gasLimitModal, gettingGasPricesError,
  ethBalance, ethPrice, getGasPrices, resetGasPriceModal, change, network, accountType,
}) => {
  const [activeTab, setActiveTab] = useState(GAS_PRICE_TABS[2]);
  const [advanced, setAdvanced] = useState(false);
  const [layerOneGas, setLayerOneGas] = useState('0');

  const {
    realGas, title, resolve, id, value, protocol,
  } = txData;

  const isLayer2 = isLayer2Network(network);

  const initData = async () => {
    if (!isLayer2) {
      const prices = await getGasPrices();
      change('gasPriceModalForm', 'gasPrice', prices[activeTab.value]);
    } else {
      const price = await window._web3.eth.getGasPrice();
      change('gasPriceModalForm', 'gasPrice', accountType === ACCOUNT_TYPES.fork ? 0.001 : price / 1e9);
    }
  };

  useEffect(() => {
    (async () => {
      if (network === 10) {
        const layerOneGas = await getOptimismLayerOneGas(txData.data, gasLimitModal, gasPriceModal);
        setLayerOneGas(layerOneGas);
      }
    })();
  }, [txData?.data, gasLimitModal, gasPriceModal]);

  useEffect(() => {
    initData();
    return () => resetGasPriceModal();
  }, []);

  useEffect(() => {
    if (gasPrices && !isLayer2) {
      change('gasPriceModalForm', 'gasPrice', gasPrices[activeTab.value]);
    }
  }, [activeTab]);

  useEffect(() => {
    if (!isLayer2 && gettingGasPricesError) setAdvanced(true);
  }, [gettingGasPricesError]);

  const ethValue = assetAmountInEth(value, 'ETH');
  const txPrice = new Dec(gasPriceModal || 0)
    .mul(gasLimitModal || 0).mul(10 ** -9)
    .add(layerOneGas)
    .toString();
  const txPriceUsd = new Dec(txPrice).mul(ethPrice).toString();
  const realPrice = new Dec(gasPriceModal || 0)
    .mul(realGas).mul(10 ** -9)
    .add(layerOneGas)
    .toString();
  const realPriceUsd = new Dec(realPrice).mul(ethPrice).toString();
  const totalPrice = new Dec(txPrice).add(ethValue).toString();
  const insufficientBalance = new Dec(totalPrice).gt(ethBalance);
  const lowGasPrice = new Dec(gasPriceModal || 0).lt(gasPrices?.cheap || 0);

  const showAdvanced = advanced || gettingGasPricesError;

  const isSubmitDisabled = gettingGasPrices || !gasPriceModal || !gasLimitModal || insufficientBalance;

  return (
    <div className="tx-confirm-wrapper">
      <div className="content-wrapper">
        <div className="header">
          {protocol && protocolIcons[protocol] && <img src={protocolIcons[protocol]} alt="" />}
          <div className="title">{title}</div>
        </div>

        <div className="tx-content">
          {gettingGasPrices && (
            <div className="loader-wrapper">
              <Loader />
            </div>
          )}

          {!gettingGasPrices && gettingGasPricesError && (
            <ErrorBox>
              { gettingGasPricesError }
              <br />
              Please check <a href="https://ethgasstation.info/" target="_blank" rel="noopener noreferrer">ETH Gas Station</a> for recommended gas prices.
            </ErrorBox>
          )}

          {gasPrices &&
          !gettingGasPrices &&
          !gettingGasPricesError &&
          (
            <div className="Switch">
              {
                  GAS_PRICE_TABS.map(tab => (
                    <div
                      key={tab.value}
                      onClick={() => { setActiveTab(tab); }}
                      className={activeTab.value === tab.value ? 'active' : ''}
                    >
                      {tab.label} <span className="small">{tab.wait}</span>
                    </div>
                  ))
                }
            </div>
          )}

          {!gettingGasPrices && (
            <>
              <div className="advanced-wrapper">
                <div className="title-wrapper" onClick={() => setAdvanced(ps => !ps)}>
                  <div className="title-advanced">{ t('misc.customize_gas') }</div>
                  <ArrowCaret to={showAdvanced ? 'up' : 'down'} />
                </div>

                <AnimateHeight duration={300} height={showAdvanced ? 'auto' : 0}>
                  <div className="advanced-content">
                    <GasPriceModalForm
                      onSubmit={(e) => {
                        resolve({ gasPrice: e.gasPrice, gasLimit: e.gasLimit });
                        closeConfirmationDialog(id);
                      }}
                      gasPrice={isLayer2 ? 0.001 : 20}
                      gasLimit={txData.gas}
                    />

                    <div className="section-wrapper">
                      <div className="label">
                        <TooltipWrapper title={t('misc.real_gas_estimate_desc')}>
                          <InfoIcon />
                        </TooltipWrapper>
                        { t('misc.real_gas_estimate') }:
                      </div>
                      <div className="value"><TooltipWrapper copy title={realGas}>{formatNumber(realGas)}</TooltipWrapper></div>
                    </div>
                  </div>
                </AnimateHeight>
              </div>

              <div className="under-tabs-wrapper">
                <div className="section-wrapper">
                  <div className="label">
                    <TooltipWrapper title={t('misc.real_gas_fee_desc')}>
                      <InfoIcon />
                    </TooltipWrapper>
                    { t('misc.real_gas_fee') }:
                  </div>
                  <div className="value with-sub">
                    {
                      gettingGasPrices
                        ? t('common.loading_no_dots')
                        : <TooltipWrapper copy title={realPrice}>{formatNumber(realPrice)} ETH</TooltipWrapper>
                    }
                    <span className="subvalue">${formatNumber(realPriceUsd)}</span>
                  </div>
                </div>

                <div className="section-wrapper">
                  <div className="label">
                    <TooltipWrapper title={t('misc.max_tx_fee_desc')}>
                      <InfoIcon />
                    </TooltipWrapper>
                    { t('misc.max_tx_fee') }:
                  </div>
                  <div className="value with-sub">
                    {
                      gettingGasPrices
                        ? t('common.loading_no_dots')
                        : <TooltipWrapper copy title={txPrice}>{formatNumber(txPrice)} ETH</TooltipWrapper>
                    }
                    <span className="subvalue">${formatNumber(txPriceUsd)}</span>
                  </div>
                </div>
                {txData.failing && (
                  <ErrorBox>{ t('misc.tx_failing_notification') }</ErrorBox>
                )}
                {!isLayer2 && lowGasPrice && (
                  <WarningBox>{ t('misc.low_gas_price_warning') }</WarningBox>
                )}
              </div>
            </>
          )}
        </div>
      </div>

      <div className="bottom-controls">
        <button
          onClick={() => {
            resolve(false);
            closeConfirmationDialog(id);
          }}
          type="button"
          className="button"
        >
          { t('common.cancel') }
        </button>
        <TooltipWrapper copy title={t('common.insufficient_balance')} disabled={!insufficientBalance}>
          <button
            type="submit"
            disabled={isSubmitDisabled}
            className="button green"
            form="gas-price-modal-form"
          >
            { t('common.accept') }
          </button>
        </TooltipWrapper>
      </div>
    </div>
  );
};

TxConfirm.defaultProps = {
  gasPrices: null,
  gasPriceModal: 0,
  gasLimitModal: 0,
};

TxConfirm.propTypes = {
  getGasPrices: PropTypes.func.isRequired,
  resetGasPriceModal: PropTypes.func.isRequired,
  txData: PropTypes.object.isRequired,
  gettingGasPrices: PropTypes.bool.isRequired,
  gettingGasPricesError: PropTypes.string.isRequired,
  gasPriceModal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  gasLimitModal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  gasPrices: PropTypes.object,
  ethBalance: PropTypes.string.isRequired,
  ethPrice: PropTypes.string.isRequired,
  network: PropTypes.number.isRequired,
  change: PropTypes.func.isRequired,
  closeConfirmationDialog: PropTypes.func.isRequired,
  accountType: PropTypes.string.isRequired,
};

const selector = formValueSelector('gasPriceModalForm');

const mapStateToProps = state => ({
  gettingGasPrices: state.general.gettingGasPrices,
  gettingGasPricesError: state.general.gettingGasPricesError,
  gasPrices: state.general.gasPrices,
  gasPriceModal: selector(state, 'gasPrice'),
  gasLimitModal: selector(state, 'gasLimit'),
  ethBalance: state.assets.ETH.balance,
  ethPrice: state.assets.ETH.marketPrice,
  network: state.general.network,
  accountType: state.general.accountType,
});

const mapDispatchToProps = {
  getGasPrices,
  resetGasPriceModal,
  change,
  closeConfirmationDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(TxConfirm);
