import dfs from '@defisaver/sdk';
import RecipeAction from '../RecipeAction';
import { Address, CdpId } from '../../components/Recipes/RecipeCreator/inputTypes';
import { mcdCdpManagerAddress } from '../../services/contractRegistryService';
import { getFullCdpInfoFromId } from '../../services/makerServices/makerManageServices/makerManageService';
import { compareAddresses } from '../../services/utils';

export default class MakerGiveAction extends RecipeAction {
  static prettyName = 'Transfer Vault';

  static protocol = 'maker';

  static protocolPrettyName = 'Maker';

  static description = 'Transfers the selected Maker Vault to a different account.';

  constructor(cdpId = 0, newOwner = '') {
    super();
    this.inputs = [
      new CdpId('CDP ID', cdpId, 'maker', false),
      new Address('New owner', newOwner),
    ];
    this.output = new CdpId('output', 0);
  }

  async getAfterValues(_balances = {}, returnValues = [], actions = [], _positions = {}, getState) {
    const positions = { ..._positions };
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    const cdpId = args[0];
    const cdp = await getFullCdpInfoFromId(cdpId);
    const sentToSelf = compareAddresses(args[1], getState().maker.proxyAddress) || compareAddresses(args[1], getState().general.account);
    positions[`maker_${cdpId}`] = !positions[`maker_${cdpId}`] ? { ...cdp, ignore: !sentToSelf } : { ...positions[`maker_${cdpId}`], ignore: !sentToSelf };
    return {
      returnValue: args[1],
      balances: _balances,
      positions,
    };
  }

  async toDfsAction() {
    return new dfs.actions.maker.MakerGiveAction(this.inputs[0].value, this.inputs[1].value, true, mcdCdpManagerAddress);
  }

  _getPrettyName(actionCalls, actions) {
    const args = this.mapReturnValuesToArgs(actionCalls.map(a => a.returnValue), actions);
    return `Transfer Vault #${args[0]} to address ${args[1]}`;
  }
}
