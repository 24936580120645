import {
  GET_SERVER_NOTIFICATIONS_REQUEST,
  GET_SERVER_NOTIFICATIONS_SUCCESS,
  GET_SERVER_NOTIFICATIONS_FAILURE,

  CHANGE_SERVER_NOTIFICATION,

  MARK_AS_READ_REQUEST,
  MARK_AS_READ_SUCCESS,
  MARK_AS_READ_FAILURE,
} from '../actionTypes/serverNotificationsActionTypes';
import { CONNECT_PROVIDER_SUCCESS } from '../actionTypes/generalActionTypes';

const INITIAL_STATE = {
  gettingNotifications: false,
  gettingNotificationsError: '',
  notifications: [],
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_SERVER_NOTIFICATIONS_REQUEST:
      return { ...state, gettingNotifications: true, gettingNotificationsError: '' };

    case GET_SERVER_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        gettingNotifications: false,
        gettingNotificationsError: '',
        notifications: payload,
      };

    case GET_SERVER_NOTIFICATIONS_FAILURE:
      return {
        gettingNotifications: false,
        gettingNotificationsError: payload,
        notifications: [],
      };

    case CHANGE_SERVER_NOTIFICATION:
      return { ...state, notifications: payload };

    case MARK_AS_READ_REQUEST:
      return { ...state, markingAsRead: true, markingAsReadError: '' };

    case MARK_AS_READ_SUCCESS:
      return { ...state, markingAsRead: false, markingAsReadError: '' };

    case MARK_AS_READ_FAILURE:
      return { ...state, markingAsRead: false, markingAsReadError: payload };

    case CONNECT_PROVIDER_SUCCESS:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};
