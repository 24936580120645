export const GET_SUBSCRIBED_STRATEGIES_REQUEST = 'GET_SUBSCRIBED_STRATEGIES_REQUEST';
export const GET_SUBSCRIBED_STRATEGIES_SUCCESS = 'GET_SUBSCRIBED_STRATEGIES_SUCCESS';
export const GET_SUBSCRIBED_STRATEGIES_FAILURE = 'GET_SUBSCRIBED_STRATEGIES_FAILURE';

export const ACTIVATE_SUBSCRIPTION_REQUEST = 'ACTIVATE_SUBSCRIPTION_REQUEST';
export const ACTIVATE_SUBSCRIPTION_SUCCESS = 'ACTIVATE_SUBSCRIPTION_SUCCESS';
export const ACTIVATE_SUBSCRIPTION_FAILURE = 'ACTIVATE_SUBSCRIPTION_FAILURE';

export const DEACTIVATE_SUBSCRIPTION_REQUEST = 'DEACTIVATE_SUBSCRIPTION_REQUEST';
export const DEACTIVATE_SUBSCRIPTION_SUCCESS = 'DEACTIVATE_SUBSCRIPTION_SUCCESS';
export const DEACTIVATE_SUBSCRIPTION_FAILURE = 'DEACTIVATE_SUBSCRIPTION_FAILURE';

export const SUBSCRIBE_TO_STRATEGY_REQUEST = 'SUBSCRIBE_TO_STRATEGY_REQUEST';
export const SUBSCRIBE_TO_STRATEGY_SUCCESS = 'SUBSCRIBE_TO_STRATEGY_SUCCESS';
export const SUBSCRIBE_TO_STRATEGY_FAILURE = 'SUBSCRIBE_TO_STRATEGY_FAILURE';

export const UPDATE_SUBSCRIPTION_REQUEST = 'UPDATE_SUBSCRIPTION_REQUEST';
export const UPDATE_SUBSCRIPTION_SUCCESS = 'UPDATE_SUBSCRIPTION_SUCCESS';
export const UPDATE_SUBSCRIPTION_FAILURE = 'UPDATE_SUBSCRIPTION_FAILURE';

export const UPDATE_AND_ACTIVATE_SUBSCRIPTION_REQUEST = 'UPDATE_AND_ACTIVATE_SUBSCRIPTION_REQUEST';
export const UPDATE_AND_ACTIVATE_SUBSCRIPTION_SUCCESS = 'UPDATE_AND_ACTIVATE_SUBSCRIPTION_SUCCESS';
export const UPDATE_AND_ACTIVATE_SUBSCRIPTION_FAILURE = 'UPDATE_AND_ACTIVATE_SUBSCRIPTION_FAILURE';

export const GET_STRATEGIES_TX_HISTORY_REQUEST = 'GET_STRATEGIES_TX_HISTORY_REQUEST';
export const GET_STRATEGIES_TX_HISTORY_SUCCESS = 'GET_STRATEGIES_TX_HISTORY_SUCCESS';
export const GET_STRATEGIES_TX_HISTORY_FAILURE = 'GET_STRATEGIES_TX_HISTORY_FAILURE';

export const RESET_SUBSCRIPTION_ACTIONS = 'RESET_SUBSCRIPTION_ACTIONS';

export const SET_STATUS_GRAPH_FROM_FORM = 'SET_STATUS_GRAPH_FROM_FORM';
