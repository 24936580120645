import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Dec from 'decimal.js';
import t from 'translate';
import { formatNumber } from 'services/utils';
import TooltipWrapper from '../../TooltipWrapper/TooltipWrapper';
import ModalHeader from '../ModalHeader';
import ModalBody from '../ModalBody';

import './CollateralRequiredModal.scss';
import Arrow from '../../Decorative/Arrow';
import ArrowCaret from '../../Decorative/ArrowCaret';

const CollateralRequiredModal = ({
  closeModal, resolve, borrowValuesWithoutColl, borrowLimitUsd, borrowedUsd,
}) => {
  const borrowPowerUsed = Dec(borrowedUsd).div(borrowLimitUsd).times(100).toNumber();

  return (
    <div id="compound-collateral-required-modal-wrapper">
      <ModalHeader closeModal={closeModal} />

      <ModalBody>
        <div className="new-modal-top-wrapper warning">
          <h1>{ t('compound.collateral_required') }</h1>
        </div>

        <div className="new-modal-content-wrapper">
          <p>
            { t('compound.collateral_required_desc') }
          </p>

          <div className="data-wrapper">
            <div className="item-wrapper">
              <div className="label">{ t('compound.borrow_limit') }:</div>

              <div className="value-wrapper">
                <div className="val1">
                  <TooltipWrapper title={borrowLimitUsd}>
                    ${ formatNumber(borrowLimitUsd, 2) }
                  </TooltipWrapper>
                </div>

                <ArrowCaret to="right" color="#939DA7" size={14} />

                <div className="val2">
                  <TooltipWrapper title={borrowValuesWithoutColl.borrowLimitUsd}>
                    ${ formatNumber(borrowValuesWithoutColl.borrowLimitUsd, 2) }
                  </TooltipWrapper>
                </div>
              </div>
            </div>

            <div className="item-wrapper">
              <div className="label">{ t('compound.borrow_power_used') }:</div>

              <div className="value-wrapper">
                <div className="val1">{ formatNumber(borrowPowerUsed, 2) }%</div>

                <ArrowCaret to="right" color="#939DA7" size={14} />

                <div className="val2">{ t('maker_actions.liquidation') }</div>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>

      <div className="modal-controls">
        <button type="button" className="button gray" onClick={() => resolve(false)}>{t('common.cancel')}</button>
        <button type="button" className="button green" onClick={() => resolve(true)}>{t('common.confirm')}</button>
      </div>
    </div>
  );
};

CollateralRequiredModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  borrowValuesWithoutColl: PropTypes.object.isRequired,
  resolve: PropTypes.func.isRequired,
  borrowedUsd: PropTypes.string.isRequired,
  borrowLimitUsd: PropTypes.string.isRequired,
};

export const CompoundCollateralRequiredModal = connect(({ compoundManage }) => ({
  borrowedUsd: compoundManage.borrowedUsd,
  borrowLimitUsd: compoundManage.borrowLimitUsd,
}))(CollateralRequiredModal);

export const AaveCollateralRequiredModal = connect(({ aaveManage, general }) => ({
  borrowedUsd: aaveManage[general.walletType.value][aaveManage.selectedMarket.value].borrowedUsd,
  borrowLimitUsd: aaveManage[general.walletType.value][aaveManage.selectedMarket.value].borrowLimitUsd,
}))(CollateralRequiredModal);
