/* eslint-disable */
import React from 'react';

export default function ArrowCaret ({ size = 18, color = '#61717E', to = 'down', className = '' }) {
  return (
    <svg
      className={`arrow-caret-icon ${className}`}
      style={{ transform: `rotate(${{ down: 0, left: 90, up: 180, right: 270 }[to]}deg)` }}
      width={size} height={size / 2} viewBox="0 0 17 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.8927 0.495117L8.3927 7.99512L0.8927 0.495117" stroke={color}/>
    </svg>
  );
}
