import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withErrorFallback from 'components/ErrorFallback/ErrorFallback';
import { formatNumber } from 'services/utils';

const SourceActionField = ({
  type, i, actualValue, isPointer, originalValue, handleChange, balanceRecipe, balanceWallet, balanceRecipeTo, balanceWalletTo, multipleAssets,
  asset, showBalance, balanceWalletSecondAsset, balanceRecipeSecondAsset, balanceWalletSecondAssetTo, balanceRecipeSecondAssetTo, secondAsset,
  assetTo, secondAssetTo,
}) => {
  const sourceOptions = useMemo(() => {
    const sourceAsset = type.isTo ? assetTo : asset;
    const sourceSecondAsset = type.isTo ? secondAssetTo : secondAsset;
    const sourceBalanceWallet = type.isTo ? balanceWalletTo : balanceWallet;
    const sourceBalanceRecipe = type.isTo ? balanceRecipeTo : balanceRecipe;
    const sourceBalanceWalletSecondAsset = type.isTo ? balanceWalletSecondAssetTo : balanceWalletSecondAsset;
    const sourceBalanceRecipeSecondAsset = type.isTo ? balanceRecipeSecondAssetTo : balanceRecipeSecondAsset;
    return [
      {
        value: 'wallet',
        label: 'Wallet balance',
        secondLabel: `${sourceAsset ? '' : 'Balance: '}${formatNumber(sourceBalanceWallet, 2)} ${sourceAsset}`,
        secondAssetSecondLabel: `${sourceSecondAsset ? '' : 'Balance: '}${formatNumber(sourceBalanceWalletSecondAsset, 2)} ${sourceSecondAsset}`,
      },
      {
        value: 'recipe',
        label: 'Temp. balance',
        secondLabel: `${sourceAsset ? '' : 'Balance: '}${formatNumber(sourceBalanceRecipe, 2)} ${sourceAsset}`,
        secondAssetSecondLabel: `${sourceSecondAsset ? '' : 'Balance: '}${formatNumber(sourceBalanceRecipeSecondAsset, 2)} ${sourceSecondAsset}`,
      },
    ];
  }, [balanceRecipe, balanceWallet, balanceRecipeTo, balanceWalletTo, balanceWalletSecondAsset, balanceWalletSecondAssetTo, balanceRecipeSecondAsset, balanceRecipeSecondAssetTo, asset, secondAsset, assetTo, secondAssetTo]);

  return (
    <div className={`Switch ${!showBalance && 'no-balance'}`}>
      {
        sourceOptions.map((o) => (
          <div
            key={o.value}
            onClick={() => handleChange({ target: o }, i)}
            className={actualValue === o.value ? 'active' : ''}
          >
            <div>{o.label}</div>
            {showBalance && (<div><b>{o.secondLabel}</b></div>)}
            {showBalance && multipleAssets && (<div><b>{o.secondAssetSecondLabel}</b></div>)}
          </div>
        ))
      }
    </div>
  );
};

SourceActionField.defaultProps = {
  actualValue: '',
  isPointer: false,
  balanceWallet: '',
  balanceRecipe: '',
  balanceWalletTo: '',
  balanceRecipeTo: '',
  asset: '',
  showBalance: true,
  balanceWalletSecondAsset: '',
  balanceRecipeSecondAsset: '',
  balanceWalletSecondAssetTo: '',
  balanceRecipeSecondAssetTo: '',
  secondAsset: '',
  multipleAssets: false,
  assetTo: '',
  secondAssetTo: '',
};

SourceActionField.propTypes = {
  handleChange: PropTypes.func.isRequired,
  type: PropTypes.object.isRequired,
  i: PropTypes.number.isRequired,
  originalValue: PropTypes.string.isRequired,
  isPointer: PropTypes.bool,
  actualValue: PropTypes.string,
  balanceWallet: PropTypes.string,
  balanceRecipe: PropTypes.string,
  balanceRecipeTo: PropTypes.string,
  balanceWalletTo: PropTypes.string,
  asset: PropTypes.string,
  showBalance: PropTypes.bool,
  balanceWalletSecondAsset: PropTypes.string,
  balanceRecipeSecondAsset: PropTypes.string,
  balanceWalletSecondAssetTo: PropTypes.string,
  balanceRecipeSecondAssetTo: PropTypes.string,
  secondAsset: PropTypes.string,
  multipleAssets: PropTypes.bool,
  assetTo: PropTypes.string,
  secondAssetTo: PropTypes.string,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default withErrorFallback(connect(mapStateToProps, mapDispatchToProps)(SourceActionField));
