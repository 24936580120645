import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { connect } from 'react-redux';
import { SelectOption, SelectValueContainer } from 'components/Forms/SelectComponent/SelectComponent';
import withErrorFallback from '../../../../../../../ErrorFallback/ErrorFallback';


const NftIdActionField = ({
  i, originalValue, handleChange, nftOptions,
}) => (
  <Select
    className="select box"
    classNamePrefix="select"
    value={nftOptions.find(i => i.value === originalValue) || null}
    components={{
      Option: SelectOption,
      ValueContainer: SelectValueContainer,
    }}
    onChange={(e) => handleChange({ target: e }, i)}
    options={nftOptions}
    onBlur={event => event.preventDefault()}
  />
);

NftIdActionField.propTypes = {
  handleChange: PropTypes.func.isRequired,
  i: PropTypes.number.isRequired,
  originalValue: PropTypes.string,
  nftOptions: PropTypes.array.isRequired,
};

const mapStateToProps = () => ({
});

const mapDispatchToProps = {};

export default withErrorFallback(connect(mapStateToProps, mapDispatchToProps)(NftIdActionField));
