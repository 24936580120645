import dfs from '@defisaver/sdk';
import Dec from 'decimal.js';
import cloneDeep from 'lodash/cloneDeep';
import { getAssetInfoByAddress, getAssetInfo } from '@defisaver/tokens';
import { exchangeAssets } from 'constants/assets';
import RecipeAction from '../RecipeAction';
import {
  Amount, Asset, Source, AssetAmount, Address,
} from '../../components/Recipes/RecipeCreator/inputTypes';
import { compareAddresses, formatAcc, formatNumber } from '../../services/utils';
import {
  assetAmountInWeiIgnorePointer,
  changeBalance,
} from '../../services/recipeCreator/recipeActionUtils';
import SendIcon from '../recipeIcons/Send.svg';
import { MAXUINT } from '../../constants/general';

const _exchangeAssets = [...exchangeAssets()];
_exchangeAssets[0] = getAssetInfo('WETH');

export default class SendTokenAction extends RecipeAction {
  static prettyName = 'Send Token';

  static description = 'Sends tokens to the entered address.';

  constructor(asset = 'DAI', amount = '', to = '') {
    super();
    this.inputs = [
      new Asset('Asset', asset, _exchangeAssets),
      new Amount('Amount', amount),
      new Address('To', to),
      // new Source('To', to, true),
    ];
    this.output = new AssetAmount('output', amount, asset);
    this._toLabel = '';
  }

  async getAfterValues(_balances = {}, returnValues = [], actions = [], positions = {}, getState) {
    const {
      general: { account },
      maker: { proxyAddress },
      assets,
    } = getState();
    const args = this.mapReturnValuesToArgs(returnValues, actions);
    let amount = args[1];
    const asset = getAssetInfoByAddress(args[0]).symbol;
    const balances = cloneDeep(_balances);
    if (amount === 'All available') amount = balances?.recipe?.[asset] || assets[asset].proxyBalance || 0;
    await changeBalance(balances, 'recipe', asset, new Dec(amount || 0).mul(-1), proxyAddress);
    if (compareAddresses(args[2], account)) {
      await changeBalance(balances, 'wallet', asset, amount || 0, account);
    }
    this.output.value = amount;
    this._toLabel = compareAddresses(args[2], account) ? 'Wallet' : '';
    return { returnValue: this.output, balances, positions };
  }


  async toDfsAction(getState) {
    const {
      general: { account },
      maker: { proxyAddress },
    } = getState();
    const to = this.inputs[2].value === '' ? account : this.inputs[2].value;
    const tokenAddr = this.inputs[0].value;
    const amount = this.inputs[1].value === 'All available' ? MAXUINT : assetAmountInWeiIgnorePointer(this.inputs[1].value, this.inputs[0].asset);
    return new dfs.actions.basic.SendTokenAction(tokenAddr, to, amount);
  }

  static getIcon() {
    return SendIcon;
  }

  _getPrettyName(actionCalls, actions) {
    const args = this.mapReturnValuesToArgs(actionCalls.map(a => a.returnValue), actions);
    const asset = this.inputs[0].asset;
    let amount = formatNumber(args[1]);
    if (args[1] === 'Infinity' || args[1] === 'All available') amount = 'all';
    return `Send ${amount} ${asset} to ${this._toLabel || formatAcc(args[2])}`;
  }

  getAsset() {
    return this.inputs[0].asset;
  }
}
