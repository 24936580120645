import React, { useEffect, useMemo, useState } from 'react';
import Dec from 'decimal.js';
import t from 'translate';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { compoundRepayAction, getRepayModalData } from '../../../../actions/compoundActions/compoundManageActions';
import { resetRepayModal } from '../../../../actions/makerActions/makerManageActions/makerManageActions';
import { getDashboardInputs } from '../../../../actions/dashboardActions';

import { CompoundRepaySlippageLimitForm as SlippageLimitForm } from '../../BoostRepayCommon/SlippageLimitForm/SlippageLimitForm';
import TooltipWrapper from '../../../TooltipWrapper/TooltipWrapper';
import ModalHeader from '../../ModalHeader';
import ModalBody from '../../ModalBody';
import Loader from '../../../Loader/Loader';
import ErrorBox from '../../../Decorative/ErrorBox/ErrorBox';
import TradeSizeImpactWarning from '../../../TradeSizeImpactWarning/TradeSizeImpactWarning';
import InfoIcon from '../../../Decorative/InfoIcon';
import ActionInfo from '../../BoostRepayCommon/ActionInfo';
import AdvancedOptions from '../../BoostRepayCommon/AdvancedOptions/AdvancedOptions';
import AdditionalInfo from '../../../AdditionalInfo/AdditionalInfo';

const CompoundRepayModal = ({
  closeModal, repayAmount, compoundRepayAction, gettingRepayModalData, tradeSizeImpact,
  gettingRepayModalDataError, repaying, repayExchangeRate, repayingError, getRepayModalData,
  resetRepayModal, flProtocol, useFl, exchangeSource, flFee, getDashboardInputs, contextAction, additionalAction,
}) => {
  const {
    firstInput: inputAmount, secondAction, secondInput, firstInputSelect, firstInputSecondSelect, secondInputSelect,
  } = useMemo(() => getDashboardInputs(contextAction, additionalAction), []);

  const withdrawAsset = firstInputSelect?.value;
  const paybackAsset = firstInputSecondSelect?.value;

  const [advanced, setAdvanced] = useState(false);
  useEffect(() => {
    getRepayModalData(inputAmount, withdrawAsset, paybackAsset);
    return resetRepayModal;
  }, []);

  const shouldFlip = new Dec(repayExchangeRate).lt(new Dec(1).div(repayExchangeRate).toString());
  const exchangeLabel = shouldFlip
    ? `${withdrawAsset}/${paybackAsset}`
    : `${paybackAsset}/${withdrawAsset}`;
  const correctedRepayExchangeRate = shouldFlip
    ? new Dec(1).div(repayExchangeRate).toString()
    : repayExchangeRate;

  const isSameAsset = withdrawAsset === paybackAsset;

  return (
    <div className="boost-repay-modal">
      <ModalHeader closeModal={closeModal} />

      <ModalBody>
        <div className="new-modal-top-wrapper">
          <h1>
            <TooltipWrapper title={t('compound.repay_modal_description', { '%asset1': paybackAsset, '%asset2': withdrawAsset })}>
              <InfoIcon /> {t('common.repay')} {contextAction?.value && additionalAction?.value && `+ ${contextAction.value === 'repay' ? additionalAction.label : contextAction.label}`}
            </TooltipWrapper>
          </h1>
        </div>

        <div className="new-modal-content-wrapper">

          {gettingRepayModalData && (<div className="container"><div className="loading-wrapper"><Loader /></div></div>)}

          {!gettingRepayModalData && gettingRepayModalDataError && (<ErrorBox>{gettingRepayModalDataError}</ErrorBox>)}

          {!gettingRepayModalData && !gettingRepayModalDataError && (
            <>
              <ActionInfo
                isRepay
                firstAsset={withdrawAsset}
                firstAmount={inputAmount}
                secondAsset={paybackAsset}
                secondAmount={repayAmount}
                isSameAsset={isSameAsset}
                exchangeRate={correctedRepayExchangeRate}
                exchangeLabel={exchangeLabel}
                additionalAction={secondAction}
                additionalInput={secondInput}
                additionalAsset={secondInputSelect?.value}
              />

              <div className="advanced__wrapper container">
                {!isSameAsset && (
                  <>
                    <TradeSizeImpactWarning tradeSizeImpact={tradeSizeImpact} />
                    <AdvancedOptions
                      isRepay
                      borrowAsset={withdrawAsset}
                      supplyAsset={paybackAsset}
                      advanced={advanced}
                      setAdvanced={setAdvanced}
                      exchangeRate={repayExchangeRate}
                      SlippageLimitForm={SlippageLimitForm}
                    />
                  </>
                )}
                <AdditionalInfo
                  isSameAsset={isSameAsset}
                  flashloanSource={useFl ? flProtocol : ''}
                  flashloanFee={flFee}
                  exchangeSource={exchangeSource}
                  additionalAction={secondAction}
                  additionalInput={secondInput}
                  asset1={withdrawAsset}
                  asset2={paybackAsset}
                />
                {repayingError && <ErrorBox marginTop>{repayingError}</ErrorBox>}
              </div>
            </>
          )}

        </div>
      </ModalBody>

      <div className="modal-controls">
        <button
          form="compound-repay-modal-form"
          type="button"
          disabled={repaying || gettingRepayModalData}
          onClick={() => compoundRepayAction(contextAction, additionalAction, closeModal)}
          className="button green"
        >
          {repaying ? t('common.repaying') : t('common.repay')}
        </button>
      </div>
    </div>
  );
};

CompoundRepayModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  repayAmount: PropTypes.string.isRequired,
  repayExchangeRate: PropTypes.string.isRequired,
  tradeSizeImpact: PropTypes.string.isRequired,
  getRepayModalData: PropTypes.func.isRequired,
  compoundRepayAction: PropTypes.func.isRequired,
  resetRepayModal: PropTypes.func.isRequired,
  gettingRepayModalData: PropTypes.bool.isRequired,
  gettingRepayModalDataError: PropTypes.string.isRequired,
  repaying: PropTypes.bool.isRequired,
  repayingError: PropTypes.string.isRequired,
  flProtocol: PropTypes.string.isRequired,
  useFl: PropTypes.bool.isRequired,
  flFee: PropTypes.string.isRequired,
  exchangeSource: PropTypes.string.isRequired,
  getDashboardInputs: PropTypes.func.isRequired,
  additionalAction: PropTypes.object,
  contextAction: PropTypes.object,
};

const mapStateToProps = ({ compoundManage }) => ({
  tradeSizeImpact: compoundManage.tradeSizeImpact,
  gettingRepayModalData: compoundManage.gettingRepayModalData,
  gettingRepayModalDataError: compoundManage.gettingRepayModalDataError,
  repayAmount: compoundManage.repayAmount,
  repayExchangeRate: compoundManage.repayExchangeRate,
  repaying: compoundManage.executing?.repay || (compoundManage.executing[compoundManage.selectedAction?.value] && compoundManage.selectedAdditionalActions[compoundManage.selectedAction?.value]?.value === 'repay'),
  repayingError: compoundManage.executingErrors?.repay,
  flProtocol: compoundManage.flProtocol,
  useFl: compoundManage.useFl,
  flFee: compoundManage.flFee,
  exchangeSource: compoundManage.exchangeSource,
});

const mapDispatchToProps = {
  getRepayModalData, compoundRepayAction, resetRepayModal, getDashboardInputs,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompoundRepayModal);
