import {
  LIQUIDATION_REQUEST,
  LIQUIDATION_SUCCESS,
  LIQUIDATION_ERROR,
  VAT_BALANCES_REQUEST,
  VAT_BALANCES_SUCCESS,
  VAT_BALANCES_ERROR,
  COLL_BID_REQUEST,
  COLL_BID_SUCCESS,
  COLL_BID_ERROR,
  DAI_BID_REQUEST,
  DAI_BID_SUCCESS,
  DAI_BID_ERROR,
  CLOSE_AUCTION_SUCCESS,
} from '../actionTypes/makerActionTypes/makerLiquidationActionTypes';

const INITIAL_STATE = {
  liquidationData: null,
  liquidationDataLoading: false,
  liquidationDataError: '',

  vatBalances: {},
  vatBalancesLoading: false,
  vatBalancesError: '',

  collBidLoading: false,
  collBidError: '',
  daiBidLoading: false,
  daiBidError: '',
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case LIQUIDATION_REQUEST:
      return {
        ...state,
        // liquidationData: null,
        liquidationDataLoading: true,
        liquidationDataError: '',
      };

    case LIQUIDATION_SUCCESS:
      return {
        ...state,
        liquidationData: payload,
        liquidationDataLoading: false,
      };

    case LIQUIDATION_ERROR:
      return {
        ...state,
        liquidationData: null,
        liquidationDataLoading: false,
        liquidationDataError: payload,
      };

    case VAT_BALANCES_REQUEST:
      return {
        ...state,
        vatBalances: {},
        vatBalancesLoading: true,
        vatBalancesError: '',
      };

    case VAT_BALANCES_SUCCESS:
      return {
        ...state,
        vatBalances: {
          ...state.vatBalances,
          ...payload,
        },
        vatBalancesLoading: false,
      };

    case VAT_BALANCES_ERROR:
      return {
        ...state,
        vatBalances: {},
        vatBalancesLoading: false,
        vatBalancesError: payload,
      };

    case COLL_BID_REQUEST:
      return {
        ...state,
        collBidLoading: true,
        collBidError: '',
      };

    case COLL_BID_SUCCESS:
      return {
        ...state,
        collBidLoading: false,
        liquidationData: {
          ...state.liquidationData,
          dent: [
            ...state.liquidationData.dent,
            payload,
          ],
          flipperData: {
            ...state.liquidationData.flipperData,
            lot: payload.collAmount,
          },
        },
      };

    case COLL_BID_ERROR:
      return {
        ...state,
        collBidLoading: false,
        collBidError: payload,
      };

    case DAI_BID_REQUEST:
      return {
        ...state,
        daiBidLoading: true,
        daiBidError: '',
      };

    case DAI_BID_SUCCESS:
      return {
        ...state,
        daiBidLoading: false,
        liquidationData: {
          ...state.liquidationData,
          tend: [
            ...state.liquidationData.tend,
            payload,
          ],
          flipperData: {
            ...state.liquidationData.flipperData,
            bid: payload.collAmount,
          },
        },
      };

    case DAI_BID_ERROR:
      return {
        ...state,
        daiBidLoading: false,
        daiBidError: payload,
      };

    case CLOSE_AUCTION_SUCCESS:
      return {
        ...state,
        liquidationData: {
          ...state.liquidationData,
          finished: true,
          flipperData: {
            ...state.liquidationData.flipperData,
            finished: true,
          },
        },
      };

    default:
      return state;
  }
};
