import React, { Component, Fragment } from 'react';
import t from 'translate';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';
import Loader from '../Loader/Loader';
import MakerCdpSubHeader from '../Maker/MakerCdpSubHeader/MakerCdpSubHeader';
import BlockerMessage from '../BlockerMessage/BlockerMessage';
import NotConnectedBlocker from '../BlockerMessage/NotConnectedBlocker/NotConnectedBlocker';
import { silentLogin } from '../../actions/accountActions';
import { updateAssetPriceInterval, clearAssetPriceTimeoutId } from '../../actions/assetsActions';

const DashboardSubHeaderWrapper = ({
  SubheaderComponent, children, canSelectCdp, requireCdp, showActions, showRatio, loadedRoute, loadingRoute,
}) => (
  <>
    {
      SubheaderComponent ?
        <SubheaderComponent loadingRoute={loadingRoute} loadedRoute={loadedRoute} />
        :
        <div className="dashboard-spacer" />
    }
    { canSelectCdp ? <MakerCdpSubHeader requireCdp={requireCdp} showActions={showActions} showRatio={showRatio} /> : null }
    { children }
  </>
);

DashboardSubHeaderWrapper.defaultProps = {
  SubheaderComponent: null,
  loadingRoute: false,
  loadedRoute: false,
  canSelectCdp: false,
  requireCdp: false,
  showActions: false,
  showRatio: false,
};

DashboardSubHeaderWrapper.propTypes = {
  SubheaderComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  children: PropTypes.object.isRequired,
  canSelectCdp: PropTypes.bool,
  loadingRoute: PropTypes.bool,
  loadedRoute: PropTypes.bool,
  requireCdp: PropTypes.bool,
  showActions: PropTypes.bool,
  showRatio: PropTypes.bool,
};

class PrivateRoute extends Component {
  componentDidMount() {
    this.login();
  }

  componentDidUpdate(prevProps) {
    const {
      requireLogin, requireProxyAddress, account,
      requireCdp, accountType,
    } = this.props;
    const requiredLoginChanged = requireLogin !== prevProps.requireLogin;
    const requiredCdpChanged = requireCdp !== prevProps.requireCdp;
    const requiredProxyChanged = requireProxyAddress !== prevProps.requireProxyAddress;
    const accountChanged = account && prevProps.account && account !== prevProps.account;
    const accountTypeChanged = accountType && accountType !== prevProps.accountType;

    if (accountTypeChanged || requiredLoginChanged || requiredCdpChanged || requiredProxyChanged || accountChanged) this.login();
  }

  async login() {
    if (!this.props.account) this.props.silentLogin();
  }

  render() {
    const {
      component: Component, account, connectingProvider, cdp, gettingCdp, loggingIn, canSelectCdp,
      requireLogin, requireProxyAddress, gettingProxyAddress, showActions, showRatio,
      requireCdp, forceMigrate,
      subheaderComponent: SubheaderComponent, gettingCdpError, requireOpenCdp,
      ...rest
    } = this.props;

    const showLoader = (requireLogin && (connectingProvider || loggingIn));

    if (showLoader) {
      let message = '';

      if (loggingIn) message = t('misc.logging_in');
      if (connectingProvider || gettingProxyAddress) message = t('misc.connecting_provider');
      if (gettingCdp) message = t('misc.getting_cdp');

      return (
        <DashboardSubHeaderWrapper
          loadingRoute
          SubheaderComponent={SubheaderComponent}
        >
          <div className="loader-page-wrapper private">
            <Loader message={message} />
          </div>
        </DashboardSubHeaderWrapper>
      );
    }

    let content;
    let loadedRoute = false;

    if (requireLogin && !account) content = (<BlockerMessage additionalClass="no-wallet"><NotConnectedBlocker /></BlockerMessage>);
    else {
      content = <Route {...rest} render={props => (<Component {...props} />)} />;
      loadedRoute = true;
    }

    return (
      <DashboardSubHeaderWrapper
        requireCdp={requireCdp}
        SubheaderComponent={SubheaderComponent}
        canSelectCdp={canSelectCdp}
        showActions={showActions}
        showRatio={showRatio}
        loadedRoute={loadedRoute}
      >
        {content}
      </DashboardSubHeaderWrapper>
    );
  }
}

PrivateRoute.defaultProps = {
  account: '',
  accountType: '',
  cdp: null,
  subheaderComponent: null,
  canSelectCdp: false,
  requireCdp: false,
  requireOpenCdp: false,
  requireLogin: false,
  forceMigrate: false,
  requireProxyAddress: false,
  showActions: false,
  showRatio: false,
};

PrivateRoute.propTypes = {
  match: PropTypes.object.isRequired,
  accountType: PropTypes.string,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  connectingProvider: PropTypes.bool.isRequired,
  gettingCdp: PropTypes.bool.isRequired,
  gettingCdpError: PropTypes.string.isRequired,
  loggingIn: PropTypes.bool.isRequired,
  gettingProxyAddress: PropTypes.bool.isRequired,
  updateAssetPriceInterval: PropTypes.func.isRequired,
  clearAssetPriceTimeoutId: PropTypes.func.isRequired,
  account: PropTypes.string,
  cdp: PropTypes.object,

  silentLogin: PropTypes.func.isRequired,

  subheaderComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  canSelectCdp: PropTypes.bool,
  requireLogin: PropTypes.bool,
  requireCdp: PropTypes.bool,
  requireOpenCdp: PropTypes.bool,
  forceMigrate: PropTypes.bool,
  requireProxyAddress: PropTypes.bool,
  showActions: PropTypes.bool,
  showRatio: PropTypes.bool,
};

const mapStateToProps = ({ general, maker, assets }) => ({
  account: general.account,
  accountType: general.accountType,
  connectingProvider: general.connectingProvider,
  cdp: maker.cdp,
  gettingCdp: maker.gettingCdp,
  gettingCdpError: maker.gettingCdpError,
  gettingProxyAddress: maker.gettingProxyAddress,
  loggingIn: general.loggingIn,
});

const mapDispatchToProps = {
  silentLogin,
  updateAssetPriceInterval,
  clearAssetPriceTimeoutId,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(PrivateRoute),
);
