/* eslint-disable */
import React from 'react';
import { v4 as uuid } from 'uuid';

export default function ConnextBridgeIcon(props) {
  const id = `connext_bridge_icon${uuid()}`;
  const fill = `url(#${id})`;
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z" fill={fill} />
      <path d="M11.9057 9.19749C11.4625 8.75428 10.7439 8.75428 10.3007 9.19749C9.85745 9.6407 9.85745 10.3593 10.3007 10.8025L11.9057 9.19749ZM13.7054 14.2073C14.1486 14.6505 14.8672 14.6505 15.3104 14.2073C15.7537 13.7641 15.7537 13.0455 15.3104 12.6023L13.7054 14.2073ZM7 15.6746L6.19749 14.8721C5.75428 15.3153 5.75428 16.0339 6.19749 16.4771L7 15.6746ZM10.4753 20.7549C10.9185 21.1981 11.6371 21.1981 12.0803 20.7549C12.5235 20.3117 12.5235 19.5931 12.0803 19.1499L10.4753 20.7549ZM10.3007 10.8025L11.0864 11.5882L12.6914 9.9832L11.9057 9.19749L10.3007 10.8025ZM11.0864 11.5882L13.7054 14.2073L15.3104 12.6023L12.6914 9.9832L11.0864 11.5882ZM11.0864 9.9832L6.19749 14.8721L7.80251 16.4771L12.6914 11.5882L11.0864 9.9832ZM6.19749 16.4771L10.4753 20.7549L12.0803 19.1499L7.80251 14.8721L6.19749 16.4771Z" fill="white"/>
      <path d="M17.1975 21.8025C17.6407 22.2457 18.3593 22.2457 18.8025 21.8025C19.2457 21.3593 19.2457 20.6407 18.8025 20.1975L17.1975 21.8025ZM15.3977 16.7927C14.9545 16.3495 14.2359 16.3495 13.7927 16.7927C13.3495 17.2359 13.3495 17.9545 13.7927 18.3977L15.3977 16.7927ZM22.1031 15.3254L22.9057 16.1279C23.3489 15.6847 23.3489 14.9661 22.9057 14.5229L22.1031 15.3254ZM18.6279 10.2451C18.1847 9.80189 17.4661 9.80189 17.0229 10.2451C16.5796 10.6883 16.5796 11.4069 17.0229 11.8501L18.6279 10.2451ZM18.8025 20.1975L18.0168 19.4118L16.4118 21.0168L17.1975 21.8025L18.8025 20.1975ZM18.0168 19.4118L15.3977 16.7927L13.7927 18.3977L16.4118 21.0168L18.0168 19.4118ZM18.0168 21.0168L22.9057 16.1279L21.3006 14.5229L16.4118 19.4118L18.0168 21.0168ZM22.9057 14.5229L18.6279 10.2451L17.0229 11.8501L21.3006 16.1279L22.9057 14.5229Z" fill="white"/>
      <defs>
        <linearGradient id={id} x1="15" y1="0" x2="15" y2="30" gradientUnits="userSpaceOnUse">
          <stop stopColor="#7359F6"/>
          <stop offset="1" stopColor="#4936A8"/>
        </linearGradient>
      </defs>
    </svg>
  );
}
