import React from 'react';
import t from 'translate';
import PropTypes from 'prop-types';
import TooltipWrapper from '../TooltipWrapper/TooltipWrapper';
import NotAllowedIcon from '../Decorative/NotAllowedIcon';

const ActionItemSingleSelectBlocker = ({ additionalClass, label }) => (
  <div className={`select-blocker-wrapper ${additionalClass}`}>
    <TooltipWrapper title={t('errors.action_select_no_options')}>
      <div className="icon-wrapper">
        <NotAllowedIcon />
      </div>
    </TooltipWrapper>

    { label && (<div className="label-wrapper">{ label }</div>) }
  </div>
);

ActionItemSingleSelectBlocker.defaultProps = {
  additionalClass: '',
  label: '',
};

ActionItemSingleSelectBlocker.propTypes = {
  additionalClass: PropTypes.string,
  label: PropTypes.string,
};

export default ActionItemSingleSelectBlocker;
