import cloneDeep from 'lodash/cloneDeep';
import { executeRecipe } from './recipeActions';
import { openRecipeSuccessModal } from '../modalActions';

export const executePremadeRecipe = (actions, afterValues) => async (dispatch, getState) => {
  const { actionCalls, returnValues } = afterValues;
  const clonedActions = cloneDeep(actions);
  const clonedActionCalls = cloneDeep(actionCalls);
  const receipts = await dispatch(executeRecipe(actions, actionCalls));
  dispatch(openRecipeSuccessModal({ receipts, actions: clonedActions, actionCalls: clonedActionCalls }));
};
