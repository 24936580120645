import t from 'translate';
import clientConfig from '../../config/clientConfig.json';
import { getInstadappAccountsWithAuthorized } from '../../services/instaServices';
import { getAaveAccountDataV2 } from '../../services/aaveServices/aaveManageServiceV2';
import { AAVE_VERSIONS } from '../../constants/aaveMarkets';
import {
  AAVE_MIGRATE_APPROVALS_FAILURE,
  AAVE_MIGRATE_APPROVALS_REQUEST,
  AAVE_MIGRATE_APPROVALS_SUCCESS,
  AAVE_MIGRATE_FROM_INSTADAPP_FAILURE,
  AAVE_MIGRATE_FROM_INSTADAPP_REQUEST,
  AAVE_MIGRATE_FROM_INSTADAPP_SUCCESS,
  AAVE_MIGRATE_PROXY_AUTH_FAILURE,
  AAVE_MIGRATE_PROXY_AUTH_REQUEST,
  AAVE_MIGRATE_PROXY_AUTH_SUCCESS,
  ADD_AAVE_MIGRATE_INSTADAPP_DATA,
  ALLOW_AAVE_MIGRATE_INSTADAPP_DATA,
  DENY_AAVE_MIGRATE_INSTADAPP_DATA,
  MIGRATE_AAVE_TO_PROXY_FAILURE,
  MIGRATE_AAVE_TO_PROXY_REQUEST,
  MIGRATE_AAVE_TO_PROXY_SUCCESS,
  RESET_AAVE_MIGRATE_INSTADAPP_DATA,
} from '../../actionTypes/aaveActionTypes/aaveMigrateActionTypes';
import { trackEvent } from '../../services/analyticsService';
import { createDSProxyAction } from '../makerActions/makerActions';

import { authorizeProxyForInstadappAccount } from '../instaActions';
import { callMultipleTxAction, sendTx } from '../txNotificationActions';
import {
  filterBorrowedAssetsFromUsedAssets,
  filterCollateralAssetsFromUsedAssets,
} from '../../services/moneymarketCommonService';
import { callRecipeViaProxy } from '../../services/contractCallService';
import { captureException } from '../../sentry';
import * as aaveRecipes from '../../recipes/aaveRecipes';
import { fetchAaveAssetsData, switchToUserWallet } from './aaveManageActionsV2';
import { approveAssets } from '../assetsActions';

export const fetchAaveInstadappData = () => async (dispatch, getState) => {
  const { general: { account, network }, maker: { proxyAddress } } = getState();
  if (network !== 1) return;
  if (!account) {
    dispatch({ type: ADD_AAVE_MIGRATE_INSTADAPP_DATA, payload: [] });
    return false;
  }
  const instadappAccounts = await getInstadappAccountsWithAuthorized(account, proxyAddress);
  if (!instadappAccounts.length) {
    dispatch({ type: ADD_AAVE_MIGRATE_INSTADAPP_DATA, payload: [] });
    return false;
  }
  await dispatch(fetchAaveAssetsData());
  const selectedMarket = AAVE_VERSIONS()[1];
  const { assets } = getState();
  const { assetsData } = getState().aaveManage[selectedMarket.value];
  const promises = instadappAccounts.map(async (dsa) => {
    const data = await getAaveAccountDataV2(dsa.account, assetsData, assets, selectedMarket, 'dsa');
    return {
      dsa, data,
    };
  });
  return Promise.all(promises).then((positions) => {
    const filteredPositions = positions.filter(({ data: { suppliedUsd } }) => suppliedUsd > 100);
    dispatch({ type: ADD_AAVE_MIGRATE_INSTADAPP_DATA, payload: filteredPositions });
    return filteredPositions.length > 0;
  });
};
export const resetAaveInstadappData = () => (dispatch) => dispatch({ type: RESET_AAVE_MIGRATE_INSTADAPP_DATA });

export const migrateAaveFromEoa = (aaveData, closeModal) => async (dispatch, getState) => {
  trackEvent('aaveMigrate', 'migrateAavePositionFromEoa');
  let { proxyAddress } = getState().maker;
  if (!proxyAddress) {
    proxyAddress = await dispatch(createDSProxyAction());
    if (!proxyAddress) return;
  }
  try {
    const sendTxFunc = promise => sendTx(promise, t('aave.migrating_position_from_account'), 'Aave', dispatch, getState);
    const market = AAVE_VERSIONS()[1];
    const { general: { account, accountType } } = getState();
    const debtInDai = aaveData.borrowedUsd;
    const collAssets = filterCollateralAssetsFromUsedAssets(aaveData.usedAssets)
      .map(({ symbol, collateral }) => ({ symbol, collateral }));
    const debtAssets = filterBorrowedAssetsFromUsedAssets(aaveData.usedAssets)
      .map(({ symbol, interestMode }) => ({ symbol, interestMode }));
    dispatch({ type: AAVE_MIGRATE_APPROVALS_REQUEST });
    const recipe = await aaveRecipes.migrate(debtInDai, debtAssets, collAssets, market, account, proxyAddress, account, false);
    const approvals = await recipe.getAssetsToApprove();
    const approvalTxs = await dispatch(approveAssets(approvals));
    try {
      if (approvalTxs.length > 0) await dispatch(callMultipleTxAction(approvalTxs));
      dispatch({ type: AAVE_MIGRATE_APPROVALS_SUCCESS });
    } catch (err) {
      dispatch({ type: AAVE_MIGRATE_APPROVALS_FAILURE, payload: err.message });
      return;
    }
    dispatch({ type: MIGRATE_AAVE_TO_PROXY_REQUEST });
    await callRecipeViaProxy(accountType, sendTxFunc, proxyAddress, account, recipe, 0, recipe.extraGas);
    dispatch({ type: MIGRATE_AAVE_TO_PROXY_SUCCESS });
    dispatch(fetchAaveAssetsData());
    dispatch(switchToUserWallet());
    closeModal();
    trackEvent('aaveMigrate', 'migrateAavePositionFromEoaSuccess');
  } catch (err) {
    dispatch({ type: MIGRATE_AAVE_TO_PROXY_FAILURE, payload: err.message });
    captureException(err);
    trackEvent('aaveMigrate', 'migrateAavePositionFromEoaError');
  }
};

export const migrateAaveFromInstadapp = (dsaData, aaveData, closeModal) => async (dispatch, getState) => {
  trackEvent('aaveMigrate', 'migrateAavePositionFromInstadapp');

  let { proxyAddress } = getState().maker;
  if (!proxyAddress) {
    proxyAddress = await dispatch(createDSProxyAction());
    if (!proxyAddress) return;
  }
  if (!dsaData.isAuthorizedForProxy) {
    dispatch({ type: AAVE_MIGRATE_PROXY_AUTH_REQUEST, payload: dsaData.account });
    try {
      await dispatch(authorizeProxyForInstadappAccount(dsaData.account, dsaData.version));
      dispatch({ type: AAVE_MIGRATE_PROXY_AUTH_SUCCESS, payload: dsaData.account });
    } catch (err) {
      dispatch({ type: AAVE_MIGRATE_PROXY_AUTH_FAILURE, payload: { account: dsaData.account, error: err.message } });
      return;
    }
  }
  try {
    dispatch({ type: AAVE_MIGRATE_FROM_INSTADAPP_REQUEST, payload: dsaData.account });
    const sendTxFunc = promise => sendTx(promise, t('aave.migrating_position_from_instadapp'), 'Aave', dispatch, getState);
    const market = AAVE_VERSIONS()[1];
    const { general: { account, accountType } } = getState();
    const debtInDai = aaveData.borrowedUsd;
    const collAssets = filterCollateralAssetsFromUsedAssets(aaveData.usedAssets)
      .map(({ symbol, collateral }) => ({ symbol, collateral }));
    const debtAssets = filterBorrowedAssetsFromUsedAssets(aaveData.usedAssets)
      .map(({ symbol, interestMode }) => ({ symbol, interestMode }));
    const recipe = await aaveRecipes.migrate(debtInDai, debtAssets, collAssets, market, account, proxyAddress, dsaData.account, true);
    await callRecipeViaProxy(accountType, sendTxFunc, proxyAddress, account, recipe, 0, recipe.extraGas);
    dispatch({ type: AAVE_MIGRATE_FROM_INSTADAPP_SUCCESS, payload: dsaData.account });
    dispatch(fetchAaveAssetsData());
    dispatch(switchToUserWallet());
    closeModal();
    trackEvent('aaveMigrate', 'migrateInstaDappAaveSuccess');
  } catch (err) {
    dispatch({ type: AAVE_MIGRATE_FROM_INSTADAPP_FAILURE, payload: { account: dsaData.account, error: err.message } });
    captureException(err);
    trackEvent('aaveMigrate', 'migrateInstaDappAaveError');
  }
};
export const allowAaveInstadappData = () => (dispatch) => dispatch({ type: ALLOW_AAVE_MIGRATE_INSTADAPP_DATA });
export const denyAaveInstadappData = () => (dispatch) => dispatch({ type: DENY_AAVE_MIGRATE_INSTADAPP_DATA });
